import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./roomscounter.scss";
import {
  HOTELS,
  PACKAGES,
  ORGANIZED_TOURS,
  useSearch,
} from "../../../../../contexts/SearchContext";

const RoomsCounter = ({}) => {
  // --- Transelator
  const { t } = useTranslation();
  const { searchTab, setRoomInfo, roomInfo, getInitRoomInfo } = useSearch();

  const isHotelSearch = () => searchTab === HOTELS;
  const isPackageSearch = () => searchTab === PACKAGES;
  const isOrganizedTourSearch = () => searchTab === ORGANIZED_TOURS;

  const updateRoomData = (newData) => {
    setRoomInfo({ rooms: [...newData] });
  };

  // Add a new room
  const addRoom = (index) => {
    if (index > 0) {
      // const newRoom = { adults: 2, kids: 0, kidsAges: [] };
      const newRoom = getInitRoomInfo(true).rooms[0];
      updateRoomData([...roomInfo.rooms, newRoom]);
    }
  };

  // Remove a room with minimum of one room
  const removeRoom = (index) => {
    if (index >= 1) {
      const updatedRoomData = [...roomInfo.rooms];
      updatedRoomData.splice(index, 1);
      updateRoomData(updatedRoomData);
    }
  };

  // Increment the number of adults
  const increaseAdults = (e, index) => {
    e.preventDefault();
    const updatedRoomData = [...roomInfo.rooms];
    updatedRoomData[index].adults++;

    updateRoomData(updatedRoomData);
  };

  // Decrement the number of adults
  const decreaseAdults = (e, index) => {
    e.preventDefault();

    const updatedRoomData = [...roomInfo.rooms];
    if (updatedRoomData[index].adults > 1) {
      updatedRoomData[index].adults--;
      updateRoomData(updatedRoomData);
    }
  };

  // Increment the number of adults
  const increaseInfant = (e, index) => {
    e.preventDefault();
    const updatedRoomData = [...roomInfo.rooms];
    updatedRoomData[index].infants++;

    updateRoomData(updatedRoomData);
  };

  // Decrement the number of infants
  const decreaseInfant = (e, index) => {
    e.preventDefault();

    const updatedRoomData = [...roomInfo.rooms];
    if (updatedRoomData[index].infants > 0) {
      updatedRoomData[index].infants--;
      updateRoomData(updatedRoomData);
    }
  };

  // Increment the number of kids
  const increaseKids = (e, index) => {
    e.preventDefault();

    const updatedRoomData = [...roomInfo.rooms];
    updatedRoomData[index].kids++;
    updatedRoomData[index].kidsAges.push(2);
    updateRoomData(updatedRoomData);
  };

  // Decrement the number of kids
  const decreaseKids = (e, index) => {
    e.preventDefault();

    const updatedRoomData = [...roomInfo.rooms];
    if (updatedRoomData[index].kids > 0) {
      updatedRoomData[index].kids--;
      updatedRoomData[index].kidsAges.pop();
      updateRoomData(updatedRoomData);
    }
  };

  // Handle adding and updating kids' ages
  const addKidAge = (e, kidnumber, index) => {
    const updatedRoomData = [...roomInfo.rooms];

    updatedRoomData[index].kidsAges[kidnumber] = parseInt(e.target.value);

    updateRoomData(updatedRoomData);
  };

  return (
    <div className={`rooms-counter-wrapper`}>
      <div
        className={`rooms-counter-content ${
          document.documentElement.dir === "ltr"
            ? "rooms-counter-content-left-fix"
            : ""
        }`}
      >
        {roomInfo.rooms.map((room, index) => (
          <div
            className="rooms-counter-room-wrapper"
            key={"rooms-counter-room-wrapper-" + index}
          >
            {/* Shows only spectated room */}
            <div
              key={index + 1}
              id={`room-number-${index + 1}`}
              className={`rooms-counter-item`}
            >
              {/* Adults counter */}
              <div className="rooms-counter-adults-wrapper">
                <div>
                  <label className="rooms-counter-title">
                    {t("roomscounter.adults")}
                  </label>
                </div>

                <div className="rooms-counter-inc-dec">
                  <div>
                    <button
                      className="rooms-counter-button"
                      onClick={(e) => increaseAdults(e, index)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>

                  <div className="rooms-counter-inc-dec-value">
                    {room.adults}
                  </div>

                  <div>
                    <button
                      className="rooms-counter-button"
                      onClick={(e) => decreaseAdults(e, index)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                  </div>
                </div>
              </div>

              {/* Kid counter */}
              <div className="rooms-counter-kids-wrapper">
                <div className="rooms-counter-text-wrapper">
                  <label className="rooms-counter-title">
                    {t("roomscounter.kids")}
                  </label>
                  <label className="rooms-counter-sub-title">
                    {t("roomscounter.agerestriction")}&nbsp;
                    {isPackageSearch() ? "2-12" : "0-17"}
                  </label>
                </div>

                <div className="rooms-counter-inc-dec">
                  <div>
                    <button
                      className="rooms-counter-button"
                      onClick={(e) => increaseKids(e, index)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>

                  <div className="rooms-counter-inc-dec-value">{room.kids}</div>

                  <div>
                    <button
                      className="rooms-counter-button"
                      onClick={(e) => decreaseKids(e, index)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                  </div>
                </div>
              </div>

              {/* Kid Ages */}
              <div className="rooms-counter-kids-info-wrapper">
                {room.kidsAges.map((kidAge, kidnumber) => (
                  <div
                    key={kidnumber + 1}
                    className="rooms-counter-kid-wrapper"
                  >
                    <label className="rooms-counter-kid-number">
                      {t("roomscounter.kidnumber")} {kidnumber + 1}
                    </label>

                    <select
                      defaultValue={kidAge}
                      className="rooms-counter-kids-select"
                      onChange={(e) => addKidAge(e, kidnumber, index)}
                    >
                      {isHotelSearch()
                        ? Array.from({ length: 18 }, (_, index) => index).map(
                            (optionValue) => (
                              <option key={optionValue} value={optionValue}>
                                {optionValue}
                              </option>
                            )
                          )
                        : Array.from(
                            { length: 11 },
                            (_, index) => index + 2
                          ).map((optionValue) => (
                            <option key={optionValue} value={optionValue}>
                              {optionValue}
                            </option>
                          ))}
                    </select>
                  </div>
                ))}
              </div>

              {/* Infant counter */}
              {(isPackageSearch() || isOrganizedTourSearch()) && (
                <div className="rooms-counter-adults-wrapper">
                  <div>
                    <label className="rooms-counter-title">
                      {t("roomscounter.infant")}
                    </label>
                  </div>

                  <div className="rooms-counter-inc-dec">
                    <div>
                      <button
                        className="rooms-counter-button"
                        onClick={(e) => increaseInfant(e, index)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>

                    <div className="rooms-counter-inc-dec-value">
                      {room.infants}
                    </div>

                    <div>
                      <button
                        className="rooms-counter-button"
                        onClick={(e) => decreaseInfant(e, index)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Rooms Counter */}
      <div className="rooms-counter-item rooms-counter-rooms-wrapper">
        {/* Title */}
        <div>
          <label className="rooms-counter-title">
            {t("roomscounter.rooms")}
          </label>
        </div>

        {/* Add & remove */}
        <div className="rooms-counter-inc-dec">
          {/* Add */}
          <div>
            <button
              type="button"
              className="rooms-counter-button"
              onClick={() => addRoom(roomInfo.rooms.length + 1)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>

          {/* Display */}
          <div className="rooms-counter-inc-dec-value">
            {roomInfo.rooms.length}
          </div>

          {/* Remove */}
          <div>
            <button
              type="button"
              className="rooms-counter-button"
              onClick={() => removeRoom(roomInfo.rooms.length - 1)}
            >
              <FontAwesomeIcon icon={faMinus} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomsCounter;
