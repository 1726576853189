import React, { useEffect, useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { saveLDSSuppliers } from "../../services/fhpService";
import { Button, Input, Select, Table } from "antd";

const LDSSuppliers = () => {
  const { ldsSuppliers, setLdsSuppliers } = useAppContext();

  const columns = [
    {
      title: "שם ספק",
      dataIndex: "supplier_name",
      key: "supplier_name",
      render: (text, record, index) => {
        return (
          <Input
            value={text}
            onChange={(e) => {
              setLdsSuppliers((prev) => {
                const copy = [...prev];
                copy[index] = {
                  ...copy[index],
                  supplier_name: e.target.value,
                };
                return copy;
              });
            }}
          />
        );
      },
    },
    {
      title: "קוד ספק",
      dataIndex: "supplier_code",
      key: "supplier_code",
      render: (text, record, index) => {
        return (
          <Input
            value={text}
            onChange={(e) => {
              setLdsSuppliers((prev) => {
                const copy = [...prev];
                copy[index] = {
                  ...copy[index],
                  supplier_code: e.target.value,
                };
                return copy;
              });
            }}
          />
        );
      },
    },
    {
      title: "סוג LDS",
      dataIndex: "lds_type",
      key: "lds_type",
      render: (text, record, index) => {
        return (
          <Select
            value={text}
            onChange={(value) => {
              setLdsSuppliers((prev) => {
                const copy = [...prev];
                copy[index] = {
                  ...copy[index],
                  lds_type: value,
                };
                return copy;
              });
            }}
          >
            <Select.Option value="FlightsHotel">חבילות</Select.Option>
            <Select.Option value="OrganizedTour">טיול מאורגן</Select.Option>
          </Select>
        );
      },
    },
    {
      title: "יעד",
      dataIndex: "destination",
      key: "destination",
      render: (text, record, index) => {
        return (
          <Input
            value={text}
            onChange={(e) => {
              setLdsSuppliers((prev) => {
                const copy = [...prev];
                copy[index] = {
                  ...copy[index],
                  destination: e.target.value,
                };
                return copy;
              });
            }}
          />
        );
      },
    },
    {
      title: "אחוז עמלה",
      dataIndex: "commission_percentage",
      key: "commission_percentage",
      render: (text, record, index) => {
        return (
          <Input
            value={text}
            onChange={(e) => {
              setLdsSuppliers((prev) => {
                const copy = [...prev];
                copy[index] = {
                  ...copy[index],
                  commission_percentage: e.target.value,
                };
                return copy;
              });
            }}
            type="number"
            min={0}
            max={100}
          />
        );
      },
    },
    {
      title: "אחוז הנחה",
      dataIndex: "discount_percentage",
      key: "discount_percentage",
      render: (text, record, index) => {
        return (
          <Input
            value={text}
            onChange={(e) => {
              setLdsSuppliers((prev) => {
                const copy = [...prev];
                copy[index] = {
                  ...copy[index],
                  discount_percentage: e.target.value,
                };
                return copy;
              });
            }}
            type="number"
            min={0}
            max={100}
          />
        );
      },
    },
    {
      title: "פעולות",
      key: "actions",
      render: (text, record, index) => {
        return (
          <Button
            onClick={() => {
              setLdsSuppliers((prev) => {
                const copy = [...prev];
                copy.splice(index, 1);
                return copy;
              });
            }}
            danger
          >
            מחק
          </Button>
        );
      },
    },
  ];

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSave = async () => {
    setIsSaving(true);
    setErrorMessage(""); // Clear previous error message
    try {
      const response = await saveLDSSuppliers({
        suppliers: ldsSuppliers,
      });

      if (!response)
        throw new Error("An error occurred while saving the data.");

      if (response.data.status === "error") {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div>
      <h2>עמלות ספקים LDS</h2>
      <Table dataSource={ldsSuppliers} columns={columns} />
      <div
        className="buttons"
        style={{
          display: "flex",
          gap: "10px",
          paddingTop: "10px",
        }}
      >
        <Button
          onClick={() => {
            setLdsSuppliers((prev) => [
              ...prev,
              {
                id: undefined,
                supplier_name: "",
                supplier_code: "",
                lds_type: "",
                destination: "",
                commission_percentage: 0,
                discount_percentage: 0,
              },
            ]);
          }}
        >
          הוסף שורה
        </Button>
        <Button type="primary" onClick={handleSave} disabled={isSaving}>
          שמור
        </Button>
      </div>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  );
};

export default LDSSuppliers;
