import React, { createContext, useContext, useState } from "react";
import { getActiveDestinations, getLdsDates, searchHotels, searchLds } from "../services/fhpService";
import { getCurrentDate, getNextMonth } from "../utils/DatesHelpers";
import { isLdsResponseEmpty } from "../utils/LDSHelpers";
import isArray from "lodash/isArray";
import useTimer from "./TimerContext";
import { isNumber, round2, useAppContext } from "./AppContext";
import { LDS_AUTOCMPLETE_CITIES, LDS_AUTOCOMPLETE_MORE } from "../assets/Data/LdsAirportsAutocomplete";
import dayjs from "dayjs";
import { notification } from "antd";
// import { useTimer } from "./TimerContext";

const SearchContext = createContext();

export const PLANES = "planes";
export const HOTELS = "hotels";
export const PACKAGES = "FlightsHotel";
export const ORGANIZED_TOURS = "OrganizedTours";

export const ADULTS = "adults";
export const KIDS = "kids";
export const INFANTS = "infants";
export const KIDS_AGES = "kidsAges";

const defaultRoomInfo = {
  rooms: [{ [ADULTS]: 2, [KIDS]: 0, [INFANTS]: 0, [KIDS_AGES]: [] }],
};
const TWENTY_MIN = 1800;

export const SearchProvider = ({ totalPrice, children }) => {
  const { calcBaseCommission, currencyRate, ldsSuppliers } = useAppContext();

  const [searchTab, _setSearchTab] = useState(
    localStorage.getItem("searchTab") || HOTELS
  );

  const { setTimeLeft } = useTimer();

  const getInitRoomInfo = (fresh = false) => {
    if (!fresh) {
      if (sessionStorage.getItem("roomData")) {
        if (sessionStorage.getItem("roomData").length > 0) {
          const savedData = JSON.parse(sessionStorage.getItem("roomData"));
          return { rooms: savedData };
        }
      }
    }
    return JSON.parse(JSON.stringify(defaultRoomInfo));
  };

  // Search params
  const [roomInfo, setRoomInfo] = useState(getInitRoomInfo());
  const [tourMonth, setTourMonth] = useState("");
  const [calendarDateArrival, setCalendarDateArrival] = useState("");
  const [calendarDateLeave, setCalendarDateLeave] = useState("");
  const [dateRange, setDateRange] = useState(0);
  const [location, _setLocation] = useState(JSON.parse(localStorage.getItem("location")) || LDS_AUTOCOMPLETE_MORE[0]);
  const [categories, _setCategories] = useState(["ALL"]);
  const [isSearched, setIsSearched] = useState(false);
  const [destinations, setDestinations] = useState([]);
  const [subjects, setSubjects] = useState({
    "FlightsHotel": [],
    "OrganizedTours": [],
  });

  // Responses
  const [sessionId, setSessionId] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [rangeSearchResults, setRangeSearchResults] = useState(null);
  const [ldsDates, setLdsDates] = useState(null);
  const [passengerInfo, setPassengerInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingDates, setLoadingDates] = useState(false);
  const [datesError, setDatesError] = useState(false);

  React.useEffect(() => {
    getActiveDestinations().then((res) => {
      setDestinations(res);
      setSubjects({
        "FlightsHotel": res.filter((dest) => dest.dealType === "FlightsHotel").map((dest) => dest.subject).filter((value) => !!value).reduce((acc, curr) => {
          if (!acc.includes(curr)) acc.push(curr);
          return acc;
        }, ["ALL"]),
        "OrganizedTours": res.filter((dest) => dest.dealType === "OrganizedTour").map((dest) => dest.subject).filter((value) => !!value).reduce((acc, curr) => {
          if (!acc.includes(curr)) acc.push(curr);
          return acc;
        }
          , ["ALL"]),
      })
    });
  }, [])

  React.useEffect(() => {
    setCategories(["ALL"]);
  }, [searchTab]);

  React.useEffect(() => {
    if (roomInfo.rooms.length > 0) {
      sessionStorage.setItem("roomData", JSON.stringify(roomInfo.rooms));
    }
  }, [roomInfo]);

  React.useEffect(() => {
    if (error) {
      setSearchResults(null);
      setError(false);
    }
  }, [error, calendarDateLeave, calendarDateArrival, roomInfo]);

  function setLocation(newLocation) {
    localStorage.setItem("location", JSON.stringify(newLocation));
    setError(false);
    _setLocation(newLocation);
    setCalendarDateArrival("");
    setCalendarDateLeave("");
    setSearchResults(null);
    if (searchTab === PACKAGES || searchTab === ORGANIZED_TOURS) {
      fetchLdsDates(searchTab === ORGANIZED_TOURS, newLocation, categories);
    }
  }

  function setCategories(newCategories) {
    setError(false);
    _setLocation(LDS_AUTOCOMPLETE_MORE[0]);
    localStorage.setItem("location", JSON.stringify(LDS_AUTOCOMPLETE_MORE[0]));
    _setCategories(newCategories);
    setCalendarDateArrival("");
    setCalendarDateLeave("");
    setSearchResults(null);
    if (searchTab === PACKAGES || searchTab === ORGANIZED_TOURS) {
      fetchLdsDates(searchTab === ORGANIZED_TOURS, LDS_AUTOCOMPLETE_MORE[0], newCategories);
    }
  }

  function reRunSearch() {
    setTimeLeft(TWENTY_MIN);
    setSearchResults(null);
    setRangeSearchResults(null);
    setLoading(false);
    setDatesError(false);
    setLoadingDates(false);
    setError(false);
    setLoading(false);
    submitSearch();
  }

  function setSearchTab(newSearchTab) {
    localStorage.setItem("searchTab", newSearchTab);
    setSearchResults([]);
    setError(false);
    setLoading(false);
    setPassengerInfo(null);
    _setLocation("");
    setRoomInfo(getInitRoomInfo(true));
    _setSearchTab(newSearchTab);
    setLdsDates(null);
  }

  function _initBeforeSearch() {
    setError(false);
    setLoading(true);
    setPassengerInfo();
  }

  function _initAfterSearch() {
    setLoading(false);
  }

  function submitSearch() {
    setIsSearched(true);
    _initBeforeSearch();
    switch (searchTab) {
      case PLANES:
        fetchSearchData();
        return;
      case HOTELS:
        setTimeLeft(TWENTY_MIN);
        fetchSearchData();
        return;
      case PACKAGES:
        setTimeLeft(TWENTY_MIN);
        searchLDSResults();
        // fetchRangeLds();
        return;
      case ORGANIZED_TOURS:
        setTimeLeft(TWENTY_MIN);
        searchLDSResults(true);
        return;
      default:
        fetchSearchData();
        return;
    }
  }

  function getSearchPhrase() {
    const city = location.city;
    const country = location.country;
    const lat = location.latitude;
    const lon = location.longitude;
    const isHotel = location.hotelserched;

    const sortedRoomInfoJson = {
      rooms: roomInfo?.rooms.map((room) => {
        return {
          AdultsCount: room.adults,
          KidsAges: room.kidsAges,
        };
      }),
    };

    return {
      isHotel: isHotel,
      lat: lat,
      lon: lon,
      city: city,
      country: country,
      checkIn: calendarDateArrival,
      checkOut: calendarDateLeave,
      ...sortedRoomInfoJson,
      currency: "ILS",
    };
  }

  function fetchLdsDates(isOrganizedTour, newLocation, categories) {
    const all = !categories || categories.length === 0 || categories?.includes("ALL");
    const { year, month } = getCurrentDate();

    async function _fetch_all_dates(monthGap = 1, numOfMonths = 3) {
      let futureMonths = [];
      for (let i = 0; i < numOfMonths; i++) {
        if (i === 0) {
          futureMonths.push(getNextMonth(month, year, 0));
        } else {
          const prevMonth = futureMonths[i - 1].month;
          const prevYear = futureMonths[i - 1].year;
          futureMonths.push(getNextMonth(prevMonth, prevYear));
        }
      }

      const responses = await Promise.all(
        futureMonths.map(async (futureMonth) => {
          const response = await getLdsDates({
            type: isOrganizedTour ? "OrganizedTour" : "FlightsHotel",
            city: newLocation.AIRPORTCODE,
            categories: all ? [] : categories,
            date: `${futureMonth.month}/${futureMonth.year}`,
          });

          if (!isLdsResponseEmpty(response)) {
            return response.data.calendarLink;
          } else {
            return null;
          }
        })
      );

      const newLdsDates = responses.filter((res) => {
        return res !== null;
      }).flat();
      setLdsDates((prevState) => [...(Array.isArray(prevState) ? prevState : []), ...newLdsDates]);
    }

    setError(false);
    setLoadingDates(true);
    setDatesError(false);
    setLdsDates([]);
    _fetch_all_dates(1, 12).finally(() => {
      setLoadingDates(false);
    });
    // else
    //   getLdsDates({ city: newLocation.AIRPORTCODE, date: `${month}/${year}`, organized: organized })
    //     .catch((err) => {
    //       setDatesError(
    //         err.exception || "אירעה שגיאה, אנא חפש יעד אחר או נסה שוב מאוחר יותר."
    //       );
    //     })
    //     .then((res) => {
    //       _fetch_all_dates();
    //       _fetch_all_dates(4, 8);
    //       if (isLdsResponseEmpty(res)) {
    //         setDatesError(
    //           "לא נמצאו תוצאות, אנא חפש יעד אחר או נסה שנית מאוחר יותר."
    //         );
    //       } else {
    //         setLdsDates(res.data.calendarLink);
    //       }
    //     })
    //     .finally(() => {
    //       setLoadingDates(false);
    //     });
  }

  const calculateDistanceByGeolocation = async (lat1, lon1, lat2, lon2) => {
    var R = 6371; // km
    var dLat = ((lat2 - lat1) * Math.PI) / 180;
    var dLon = ((lon2 - lon1) * Math.PI) / 180;
    var a =
      0.5 -
      Math.cos(dLat) / 2 +
      (Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        (1 - Math.cos(dLon))) /
      2;

    return R * 2 * Math.asin(Math.sqrt(a));
  };

  async function fetchSearchData() {
    const searchPhrase = getSearchPhrase();
    searchHotels(searchPhrase).then(async (res) => {
      if (!res?.data || res.error) {
        setError("אירעה שגיאה");
        setSearchResults(null);
      } else {
        let { session, Result: result } = res.data;
        if (!result)
          result = [];
        if (result.length === 0)
          notification.error({
            message: "לא נמצאו תוצאות",
            description: "לא נמצאו תוצאות עבור התאריכים הספציפיים, ניתן לבצע חיפוש מחדש לתאריכים שונים.",
            duration: 5,
            placement: "bottomRight"
          });
        setSessionId(session);
        addCommissionToSearchResultGemonix(result);
        await Promise.all(result.map(async (hotel) => {
          hotel.distance = await calculateDistanceByGeolocation(
            hotel.GeoLocation.Latitude,
            hotel.GeoLocation.Longitude,
            searchPhrase.lat,
            searchPhrase.lon
          );
        }));
        result = result.sort((a, b) => a.distance - b.distance);
        setSearchResults(result);
        _initAfterSearch();
      }
    });
  }

  function addCommissionToSearchResultGemonix(result) {
    if (result === undefined) return;
    for (const hotel of result) {
      const price = parseFloat(hotel.LowestPackagePrice);
      hotel.ourPrice = calcBaseCommission(price);
    }
  }
  const buildLdsPayload = (isOrganizedTour = false) => {
    // const { adults, kids } = getPeoplesSum(roomInfo);
    if (isOrganizedTour) {
      return {
        city: location.AIRPORTCODE,
        categories: categories?.includes("ALL") ? [] : categories,
        min_dep_date: dayjs(tourMonth, "MM/YYYY").startOf("month").format("DD/MM/YYYY"),
        max_dep_date: dayjs(tourMonth, "MM/YYYY").endOf("month").format("DD/MM/YYYY"),
        min_ret_date: dayjs(tourMonth, "MM/YYYY").startOf("month").format("DD/MM/YYYY"),
        max_ret_date: dayjs(tourMonth, "MM/YYYY").endOf("month").format("DD/MM/YYYY"),
        room_compositions: roomInfo?.rooms,
      }
    } else {
      return {
        // num_infants=0, todo(sagiv): check if need
        dep_date: calendarDateArrival,
        ret_date: calendarDateLeave,
        city: location.AIRPORTCODE,
        categories: categories?.includes("ALL") ? [] : categories,
        date_range: dateRange,
        room_compositions: roomInfo?.rooms,
      };
    }
  };

  function searchLDSResults(isOrganizedTour = false) {
    const data = buildLdsPayload(isOrganizedTour);
    searchLds(data)
      .then((res) => res?.data)
      .then((res) => {
        if (res?.opStatus?.status === "NO_RESULTS") {
          setError("לא נמצאו תוצאות");
          notification.error({
            message: "לא נמצאו תוצאות",
            description: "לא נמצאו תוצאות עבור התאריכים הספציפיים, ניתן לבצע חיפוש מחדש לתאריכים שונים.",
            duration: 5,
            placement: "bottomRight"
          });
        } else if (res?.opStatus?.status === "SUCCESS") {
          if (isOrganizedTour) {
            const organizedTours = isArray(res.deal) ? res.deal : [res.deal];
            setSearchResults(organizedTours);
          } else {
            let dealsArr = isArray(res.dealGroup)
              ? res.dealGroup
              : isArray(res.deal) ? res.deal : [res.dealGroup];
            setSearchResults(dealsArr);
          }

          // // use for debug only
          // dealsArr = dealsArr.filter((item) => {
          //   return item.fullDeal.supplierDetails.supplierId != "KV";
          // });

          // // filter only rooms with infants
          // dealsArr = dealsArr.filter((item) => {
          //   const infantFare = item.fullDeal?.dealFare?.infantFare;
          //   return infantFare !== undefined && infantFare !== null;
          // });

        } else {
          setError("אירעה שגיאה אנא נסה שנית.");
        }
      })
      // .catch((err) => setError("אירעה שגיאה אנא נסה שנית."))
      .finally(() => {
        _initAfterSearch();
      });
  }

  function _hasZeroAge(rooms) {
    for (const room of rooms) {
      if (room.kidsAges.includes(0)) {
        return true;
      }
    }
    return false;
  }

  function isSearchDisabled() {
    if (error || loading) {
      return true;
    }

    if (searchTab === ORGANIZED_TOURS && tourMonth !== "" && location !== "")
      return false;

    return !(
      location !== "" &&
      calendarDateArrival !== "" &&
      calendarDateLeave !== "" &&
      !_hasZeroAge(roomInfo.rooms)
    );
  }

  function getSearchDisableReasons() {
    if (error) {
      return ["אירעה שגיאה."];
    }
    if (loading) {
      return ["טוען..."];
    }
    const reasons = [];

    if (location === "") {
      reasons.push("יש לבחור יעד.");
    }
    if (!(calendarDateArrival !== "" && calendarDateLeave !== "")) {
      reasons.push("יש לבחור תאריכים.");
    }

    if (_hasZeroAge(roomInfo.rooms)) {
      reasons.push("ילד לא יכול להיות בגיל 0.");

      return reasons;
    }

    return reasons.length > 0 ? reasons : null;
  }

  const calculateNetPrice = (price, commissionPercent) => {
    if (!price) return 0;
    const numPrice = Number(price);

    return round2(
      numPrice ? numPrice - (numPrice * commissionPercent) / 100 : 0
    );
  };

  const calculatePriceWithCommissions = (
    ldsPrice,
    currencyRate,
    ldsCommission = 0
  ) => {
    const netPrice = calculateNetPrice(ldsPrice, ldsCommission);
    const priceILS = round2(netPrice * currencyRate);
    const ourPrice = calcBaseCommission(priceILS);
    ourPrice.origCurrency = calcBaseCommission(netPrice);
    ourPrice.currencyRate = round2(currencyRate);
    ourPrice.netPrice = round2(netPrice);
    ourPrice.ldsPrice = round2(ldsPrice);
    ourPrice.ldsCommission = round2(ldsCommission);
    return ourPrice;
  };

  function calculateLDSDealFare({ dealType, dealFare, fareDetails, supplierDetails, hotel, tour }) {
    const { adultFare, totalFare, infantFare, childFareExt } = dealFare;
    const { commission, currency } = fareDetails;
    let { commissionType, commissionValue, commissionBase } = commission;
    let destination = null;
    const { supplierId } = supplierDetails;
    const childFare = childFareExt;

    if (hotel)
      destination = hotel.hotelCity;
    else if (tour)
      destination = tour.tourCities.val[0];

    const dealCurrencyRate =
      currency === "USD"
        ? currencyRate.USD
        : currency === "EUR"
          ? currencyRate.EUR
          : 0;

    if (!dealCurrencyRate) return null;

    let commissionPercent = 0;
    let cValue = 0;
    let totalFarePercent = 0;
    let adultFarePercent = 0;
    let infantFarePercent = 0;

    const ldsCommissionConditions = ldsSuppliers.filter((supplier) => (supplier && (supplier.destination === destination || supplier.destination === "ALL") && supplier.supplier_code === supplierId && supplier.lds_type === dealType));
    let ldsCommissionCondition = null;
    if (ldsCommissionConditions && ldsCommissionConditions.length > 0) {
      if (ldsCommissionConditions.length > 1) {
        ldsCommissionCondition = ldsCommissionConditions.find((condition) => condition.destination === destination);
        if (!ldsCommissionCondition)
          ldsCommissionCondition = ldsCommissionConditions.find((condition) => condition.destination === "ALL");
      }
      else
        ldsCommissionCondition = ldsCommissionConditions[0];
      commissionType = "PERCENT";
      commissionPercent = ldsCommissionCondition.commission_percentage + ldsCommissionCondition.discount_percentage;
    }

    if (commissionType === "AMOUNT" && commissionBase !== undefined) {
      cValue = Number(commissionValue);
      if (commissionBase === "TOTAL_FARE")
        totalFarePercent = (cValue / totalFare) * 100;
      else if (commissionBase === "ADULT_FARE")
        adultFarePercent = (cValue / adultFare) * 100;
      else if (commissionBase === "INFANT_FARE")
        infantFarePercent = (cValue / infantFare) * 100;
    } else if (commissionType === "PERCENT") {
      if (!ldsCommissionCondition)
        commissionPercent = Number(commissionValue);
    } else {
      console.log(commissionType);
    }

    const ourAdultFare = calculatePriceWithCommissions(
      adultFare,
      dealCurrencyRate,
      commissionPercent + adultFarePercent
    );

    const ourInfantFare = calculatePriceWithCommissions(
      infantFare,
      dealCurrencyRate,
      commissionPercent + infantFarePercent
    );

    const ourChildFare = [];

    if (Array.isArray(childFare))
      for (let i = 0; i < childFare.length; i++)
        ourChildFare.push(calculatePriceWithCommissions(
          childFare[i]?.fare,
          dealCurrencyRate,
          commissionPercent
        ));
    else if (typeof childFare === "object")
      ourChildFare.push(calculatePriceWithCommissions(
        childFare?.fare,
        dealCurrencyRate,
        commissionPercent
      ));
    else
      ourChildFare.push(calculatePriceWithCommissions(
        adultFare,
        dealCurrencyRate,
        commissionPercent));

    const ourTotalFare = calculatePriceWithCommissions(
      totalFare,
      dealCurrencyRate,
      commissionPercent + totalFarePercent
    );

    return {
      ourAdultFare,
      ourInfantFare,
      ourChildFare,
      ourTotalFare,
    };
  }

  function calculateLDSDealFareForRoomsComposition(fullDeal, shortenedDeal = null) {
    const { dealFare, fareDetails, dealType, hotel, tour, supplierDetails } = fullDeal;
    if (!dealFare) return null;
    const roomsPriceTable = roomInfo.rooms.map((roomComposition, index) => {
      let { adultFare, infantFare = 0, childFareExt, totalFare } = dealFare;
      const code1 = `${"A" + roomComposition.adults}${roomComposition.kids > 0 ? "C" + roomComposition.kids : ""}`;
      const code2 = `${"A" + (roomComposition.adults + roomComposition.kids)}`;
      if (shortenedDeal)
        if (Array.isArray(shortenedDeal)) {
          let sd = shortenedDeal.find((sd) => sd.roomSet === code1 || sd.roomSet === code2);
          if (sd) {
            adultFare = sd.adultFare;
            infantFare = sd.infantFare;
            childFareExt = sd.childFare;
            totalFare = sd.totalFare;
          } else {
            const adults = roomComposition.adults + roomComposition.kids;
            sd = shortenedDeal.find((sd) => sd.roomSet === 'A2');
            if (sd) {
              adultFare = sd.adultFare;
              infantFare = sd.infantFare;
              childFareExt = sd.childFare;
              totalFare = sd.totalFare;
            }
          }
        } else {
          adultFare = shortenedDeal.adultFare;
          infantFare = shortenedDeal.infantFare;
          childFareExt = shortenedDeal.childFare;
          totalFare = shortenedDeal.totalFare;
        }
      let childFare = childFareExt;

      if (!totalFare) {
        let childFareFR = 0;
        if (Array.isArray(childFare)) {
          for (let i = 0; i < childFare.length; i++) {
            let j = 0;
            for (j = 0; j < roomComposition.kidsAges.length; j++) {
              const age = roomComposition.kidsAges[j];
              if (age >= childFare[i].ageFrom && age <= childFare[i].ageTo) {
                childFareFR += Number(childFare[i].fare);
                break;
              }
            }
            if (j === roomComposition.kidsAges.length)
              childFareFR += Number(adultFare);
          }
        }
        else if (!childFare) {
          childFareFR = roomComposition.kids * Number(adultFare);
        }
        else {
          for (let j = 0; j < roomComposition.kidsAges.length; j++) {
            const age = roomComposition.kidsAges[j];
            if (age >= childFare.ageFrom && age <= childFare.ageTo)
              childFareFR += Number(childFare.fare);
            else
              childFareFR += Number(adultFare);
          }
        }
        totalFare =
          Number(adultFare) * roomComposition.adults +
          childFareFR +
          Number(infantFare) * roomComposition.infants;
      }

      const ourFareDetails = calculateLDSDealFare({
        dealFare: {
          adultFare,
          totalFare,
          childFareExt: childFare,
          infantFare,
        },
        fareDetails,
        dealType,
        hotel,
        tour,
        supplierDetails,
      });

      return { dealFare, ourFareDetails };
    });

    const ourSumTotalFare = {};
    for (const roomPrice of roomsPriceTable) {
      const ourTotalFare = roomPrice.ourFareDetails.ourTotalFare;
      for (let key in ourTotalFare) {
        if (!isNumber(ourTotalFare[key])) {
          continue;
        }
        if (ourSumTotalFare[key] === undefined) {
          ourSumTotalFare[key] = 0;
        }
        ourSumTotalFare[key] += ourTotalFare[key];
      }
    }
    const ourPrice = {
      ...roomsPriceTable[0].ourFareDetails,
      ourTotalFare: ourSumTotalFare,
    };
    return ourPrice;
  }

  return (
    <SearchContext.Provider
      value={{
        setCalendarDateArrival,
        setTourMonth,
        setCalendarDateLeave,
        calendarDateArrival,
        calendarDateLeave,
        setDateRange,
        setSearchTab: setSearchTab,
        setLocation: setLocation,
        setRoomInfo,
        searchTab,
        roomInfo,
        location,
        getSearchPhrase,
        submitSearch,
        searchResults,
        sessionId,
        loading,
        error,
        loadingDates,
        datesError,
        isSearchDisabled,
        getSearchDisableReasons,
        ldsDates,
        isSearched,
        rangeSearchResults,
        passengerInfo,
        setPassengerInfo,
        timeLeft: 202,
        reRunSearch,
        getInitRoomInfo,
        calculateLDSDealFare,
        calculateLDSDealFareForRoomsComposition,
        calculatePriceWithCommissions,
        categories,
        setCategories,
        destinations,
        subjects
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};

export default SearchContext;

export function parsePeopleCount(roomInfo) {
  const rooms = roomInfo?.rooms || [];
  const totalRooms = rooms.length;

  let totalPeople = 0;

  rooms.forEach((personData) => {
    totalPeople += personData.adults || 0;
    totalPeople += personData.kids || 0;
    totalPeople += personData.infants || 0;
  });

  return { totalRooms, totalPeople };
}

// export function getPeoplesSum(roomInfo) {
//   const rooms = roomInfo?.rooms || [];

//   let adults = 0;
//   let kids = 0;

//   rooms.forEach((personData) => {
//     adults += personData.adults || 0;
//     kids += personData.kids || 0;
//   });

//   return { adults, kids };
// }
