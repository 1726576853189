import React from "react";
import { filterByLanguage } from "../../utils/LDSHelpers";
import Tooltip from "@mui/material/Tooltip";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import "./HotelDetails.scss";
import TextOrLoader from "../TextOrLoader/TextOrLoader";
import ImagesCarousel from "../ImagesCrousel/ImagesCarousel";
import isArray from "lodash/isArray";

const LANGUAGE = "iw";

const HotelDetails = ({
  htlLocation,
  phone,
  webSite,
  address,
  hotelName,
  cityName,
  cityId,
  resource = [],
  ...props
}) => {
  const resourceUrls = isArray(resource)
    ? resource?.map((item) => item.resourceUrl)
    : resource?.resourceUrl;

  return (
    <div
      style={
        {
          /* { display: "flex" } */
        }
      }
      className="tw-flex tw-flex-col lg:tw-flex-column"
    >
      <div className="images-packages-carousel">
        <ImagesCarousel images={resourceUrls}></ImagesCarousel>
      </div>
      <div
        style={
          {
            // display: "flex",
            // flexDirection: "column",
            // paddingRight: "4vw",
            // alignItems: "center",
          }
        }
      >
        <h4 style={{ textAlign: "center" }}>
          <TextOrLoader loading={!address}>{address}</TextOrLoader>
        </h4>
        <TextOrLoader>
          {filterByLanguage(htlLocation, LANGUAGE)?.text}
        </TextOrLoader>
        <div
          style={{
            height: 30,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 15,
            marginTop: 15,
          }}
        >
          <Tooltip title={"פתח אתר"}>
            <span style={{ cursor: "pointer" }}>
              <WebAssetIcon
                onClick={() => window.open(webSite, "_blank")}
                style={{ fontSize: 40, cursor: "pointer" }}
              />
            </span>
          </Tooltip>
          <TextOrLoader>
            {phone ? (
              <div className="hotel-detail-row">
                <PhoneEnabledIcon />
                <a
                  href={`tel:${phone}`}
                  style={{ color: "blue", textDecoration: "none" }}
                >
                  {phone}
                </a>
              </div>
            ) : null}
          </TextOrLoader>
        </div>
      </div>
    </div>
  );
};

export default HotelDetails;
