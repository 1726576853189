import React, { useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { saveAirlines } from "../../services/fhpService";
import { Button, Input, Table, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const Airlines = () => {
  const { airlines, setAirlines } = useAppContext();

  const columns = [
    {
      title: "שם חברת תעופה",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        return (
          <Input
            value={text}
            onChange={(e) => {
              setAirlines((prev) => {
                const copy = [...prev];
                copy[index] = {
                  ...copy[index],
                  name: e.target.value,
                };
                return copy;
              });
            }}
          />
        );
      },
    },
    {
      title: "קוד חברת תעופה",
      dataIndex: "code",
      key: "code",
      render: (text, record, index) => {
        return (
          <Input
            value={text}
            onChange={(e) => {
              setAirlines((prev) => {
                const copy = [...prev];
                copy[index] = {
                  ...copy[index],
                  code: e.target.value,
                };
                return copy;
              });
            }}
          />
        );
      },
    },
    {
      title: "לוגו",
      dataIndex: "logo",
      key: "logo",
      render: (text, record, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "1px dashed #d9d9d9",
              padding: "10px",
              cursor: "pointer",
            }}
            onDrop={(e) => {
              e.preventDefault();
              const file = e.dataTransfer.files[0];
              if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setAirlines((prev) => {
                    const copy = [...prev];
                    copy[index] = {
                      ...copy[index],
                      logo: reader.result,
                    };
                    return copy;
                  });
                };
                reader.readAsDataURL(file);
              }
            }}
            onDragOver={(e) => e.preventDefault()}
            onClick={(e) => {
              e.stopPropagation();
              document.getElementById(`file-input-${index}`).click();
            }}
          >
            {text && (
              <img
                src={text}
                alt="logo"
                style={{
                  width: "300px",
                  marginBottom: "10px",
                }}
              />
            )}
            <Upload
              id={`file-input-${index}`}
              locale={{ emptyText: "העלה קובץ" }}
              showUploadList={false}
              accept="image/*"
              onClick={(e) => e.stopPropagation()}
              beforeUpload={(file) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setAirlines((prev) => {
                    const copy = [...prev];
                    copy[index] = {
                      ...copy[index],
                      logo: reader.result,
                    };
                    return copy;
                  });
                };
                reader.readAsDataURL(file);
                return false;
              }}
            >
              <Button icon={<UploadOutlined />}>העלה</Button>
            </Upload>
          </div>
        );
      },
    },
    {
      title: "פעולות",
      key: "actions",
      render: (text, record, index) => {
        return (
          <Button
            onClick={() => {
              setAirlines((prev) => {
                const copy = [...prev];
                copy.splice(index, 1);
                return copy;
              });
            }}
            danger
          >
            מחק
          </Button>
        );
      },
    },
  ];

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSave = async () => {
    setIsSaving(true);
    setErrorMessage(""); // Clear previous error message
    try {
      const response = await saveAirlines({
        airlines: airlines,
      });

      if (!response)
        throw new Error("An error occurred while saving the data.");

      if (response.data.status === "error") {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div>
      <h2>חברות תעופה</h2>
      <Table dataSource={airlines} columns={columns} />
      <div
        className="buttons"
        style={{
          display: "flex",
          gap: "10px",
          paddingTop: "10px",
        }}
      >
        <Button
          onClick={() => {
            setAirlines((prev) => [
              ...prev,
              {
                code: "",
                name: "",
                logo: "",
              },
            ]);
          }}
        >
          הוסף שורה
        </Button>
        <Button type="primary" onClick={handleSave} disabled={isSaving}>
          שמור
        </Button>
      </div>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  );
};

export default Airlines;
