import React from "react";
import { useSelectedResult } from "../../../contexts/SelectedResultContext";
import PackagesProfile from "./PackagesProfile";
import { useParams } from "react-router-dom";
import { getLDSOrderById, getLdsOrder } from "../../../services/fhpService";
import { Loader } from "../../../Components/Loader/Loader";
import { countAdultsAndKids, getBiggestRoom } from "../../../utils/LDSHelpers";
import { Helmet } from "react-helmet";

const SavedPackagesProfile = () => {
  const { id } = useParams();
  const {
    setRoomsAndPaxInfo,
    selectedResult,
    setSelectedResult,
    setOurSavedLDSOrder,
    ourSavedLDSOrder,
    setLdsSupplement,
    setHandleFeeData,
    setAgentData,
  } = useSelectedResult();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (id)
      getLdsOrder({ id })
        .then((res) => {
          setOurSavedLDSOrder(res.data);
          setAgentData(res.data.agent);

          const {
            passengersinfo,
            dealid,
            dep_date,
            ret_date,
            supplement,
            linkedflights,
          } = res.data;
          setRoomsAndPaxInfo(passengersinfo);
          setLdsSupplement(supplement);
          getLdsData(dealid, dep_date, ret_date, passengersinfo, linkedflights);
          if (res.data.ourprice?.handleFeeData) {
            setHandleFeeData(res.data.ourprice.handleFeeData);
          }
        })
        .catch((err) => {
          console.log("ERROR: " + err);
          setError(true);
        });
  }, [id]);

  const getLdsData = (
    dealid,
    dep_date,
    ret_date,
    passengersInfo,
    ourLinkedFlights
  ) => {
    const { totalAdults, totalKids, totalInfant } =
      getBiggestRoom(passengersInfo);

    getLDSOrderById({
      ldsDealId: dealid,
      depDate: dep_date,
      retDate: ret_date,
      numOfAdults: totalAdults,
      numOfChildren: totalKids,
      numOfInfants: totalInfant,
    }).then((res) => {
      const status = res.data?.opStatus?.status;
      if (status === "NO_RESULTS") {
        console.log("ERROR: No results");
        setError(true);
        return;
      }
      const deal = res.data.deal;
      setSelectedResult({ ...deal, linkedFlights: ourLinkedFlights });
      setLoading(false);
    });
  };

  if (error) {
    return (
      <>
        <Helmet>
          <title>הצעה מספר {ourSavedLDSOrder?.dealid || ""}</title>
          <meta
            name="description"
            content={"לקוח יקר, מצורפת הצעת מחיר לעיונך ואישורך"}
          />
          <meta
            property="og:title"
            content={`הצעת מחיר מספר ${ourSavedLDSOrder?.dealid || ""}`}
          />
          <meta
            property="og:image"
            content={selectedResult?.images?.[0]?.url || ""}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content={"לקוח יקר, מצורפת הצעת מחיר לעיונך ואישורך"}
          />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <div style={{ color: "red", fontSize: 30 }}>
          אירעה שגיאה, אנה נסה שנית מאוחר יותר
        </div>
      </>
    );
  }

  if (loading) {
    return (
      <>
        <Helmet>
          <title>הצעה מספר {ourSavedLDSOrder?.dealid || ""}</title>
          <meta
            name="description"
            content={"לקוח יקר, מצורפת הצעת מחיר לעיונך ואישורך"}
          />
          <meta
            property="og:title"
            content={`הצעת מחיר מספר ${ourSavedLDSOrder?.dealid || ""}`}
          />
          <meta
            property="og:image"
            content={selectedResult?.images?.[0]?.url || ""}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content={"לקוח יקר, מצורפת הצעת מחיר לעיונך ואישורך"}
          />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <Loader size="large" />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>הצעה מספר {ourSavedLDSOrder?.dealid || ""}</title>
        <meta
          name="description"
          content={"לקוח יקר, מצורפת הצעת מחיר לעיונך ואישורך"}
        />
        <meta
          property="og:title"
          content={`הצעת מחיר מספר ${ourSavedLDSOrder?.dealid || ""}`}
        />
        <meta
          property="og:image"
          content={selectedResult?.images?.[0]?.url || ""}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={"לקוח יקר, מצורפת הצעת מחיר לעיונך ואישורך"}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <PackagesProfile orderSaved={true} ourLdsId={id} />
    </>
  );
};

export default SavedPackagesProfile;
