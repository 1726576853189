import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

// CSS
import "./calendar.scss";
import classNames from "classnames";

const Calendar = ({
  calendarDateLeave,
  calendarDateArrival,
  saveArrivalDate,
  saveDepartureDate,
  enablePast = false,
  possibleArrivalDates,
  possibleDepartureDates,
}) => {
  // States & veriables
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const languageCode = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "he";

  const [arrivalDate, setArrivalDate] = useState(calendarDateArrival);
  const [departureDate, setDepartureDate] = useState(calendarDateLeave);

  const [selectedArrivalDate, setSelectedArrivalDate] = useState(null);
  const [selectedDepartureDate, setSelectedDepartureDate] = useState(null);

  const [defaultArrivalMonth, setDefaultArrivalMonth] = useState(currentMonth);
  const [defaultLeaveMonth, setDefaultLeaveMonth] = useState(currentMonth + 1);

  const isSettingArrivalDate =
    (!!departureDate && !!arrivalDate) || (!departureDate && !arrivalDate);

  // --- UseEffects

  // Read saved data from sessionStorage
  useEffect(() => {
    const savedData = sessionStorage.getItem("calendar-info");

    if (savedData) {
      const parsedData = JSON.parse(savedData);

      // Check if there anything else saved before
      if (parsedData.arrivalDate && parsedData.leaveDate) {
        // Set arrival date
        let dateParts = parsedData.arrivalDate.split("/");
        let formattedDate = new Date(
          `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
        );
        setArrivalDate(formattedDate);
        setSelectedArrivalDate(parsedData.arrivalDate);
        saveArrivalDate(parsedData.arrivalDate);

        // Set departure date
        dateParts = parsedData.leaveDate.split("/");
        formattedDate = new Date(
          `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
        );
        setDepartureDate(formattedDate);
        setSelectedDepartureDate(parsedData.leaveDate);
        saveDepartureDate(parsedData.leaveDate);
      }
    }
  }, []);

  // Save selected dates to sessionStorage
  useEffect(() => {
    const calendarInfo = JSON.stringify({
      arrivalDate: selectedArrivalDate,
      leaveDate: selectedDepartureDate,
    });
    sessionStorage.setItem("calendar-info", calendarInfo);
  }, [selectedArrivalDate, selectedDepartureDate]);

  // --- Functions

  // Get month name in any language and year
  function getMonthName(monthNumber) {
    const currentYear = currentDate.getFullYear();
    const additionalYears = Math.floor((monthNumber - 1) / 12); // Calculate the additional years

    if (0 < additionalYears) {
      // Month is in the past, get the next year
      const nextYear = currentYear + additionalYears;
      const adjustedMonthNumber = monthNumber + additionalYears * 12; // Adjust the month number

      const monthFormatter = new Intl.DateTimeFormat(languageCode, {
        month: "long",
      });
      const monthName = monthFormatter.format(
        new Date(nextYear, adjustedMonthNumber - 1, 1)
      );
      return { monthname: monthName, year: nextYear };
    } else {
      // Month is in the current year
      const adjustedMonthNumber = monthNumber + additionalYears * 12; // Adjust the month number

      const monthFormatter = new Intl.DateTimeFormat(languageCode, {
        month: "long",
      });
      const monthName = monthFormatter.format(
        new Date(currentYear, adjustedMonthNumber - 1, 1)
      );
      return { monthname: monthName, year: currentYear };
    }
  }

  function parseStupidDate(dateString) {
    const date = new Date(dateString);

    // Format the data for the API
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  // Select date
  const handleDateClick = (e) => {
    const dateString = e.target.id;
    const date = new Date(dateString);

    let formatedDate = parseStupidDate(e.target.id);

    if (!arrivalDate) {
      setArrivalDate(date);
      setSelectedArrivalDate(formatedDate);
      saveArrivalDate(formatedDate);
    } else if (!departureDate && date > arrivalDate) {
      setDepartureDate(date);
      setSelectedDepartureDate(formatedDate);
      saveDepartureDate(formatedDate);
    } else {
      setArrivalDate(date);
      setSelectedArrivalDate(formatedDate);
      saveArrivalDate(formatedDate);

      setDepartureDate(null);
      setSelectedDepartureDate("");
      saveDepartureDate("");
    }
  };

  // Render the calendar
  const renderCalendar = (targetMonth) => {
    targetMonth -= 1;

    const today = new Date();
    const startDate = new Date(today.getFullYear(), targetMonth, 1);
    const endDate = new Date(today.getFullYear(), targetMonth + 1, 0);

    const calendar = [];
    let currentDate = new Date(startDate);

    // Calculate the day of the week that the 1st of the month falls on (0 = Sunday, 1 = Monday, etc.)
    const startDayOfWeek = startDate.getDay(); // Sunday is 0

    // Add day names
    const dayNames = Array.from({ length: 7 }, (_, i) => {
      const dayFormatter = new Intl.DateTimeFormat(languageCode, {
        weekday: "short",
      });
      return dayFormatter.format(new Date(2023, 0, i + 1)); // January 1st is a Sunday
    });

    const idOfDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    for (let i = 0; i < 7; i++) {
      calendar.push(
        <div key={idOfDays[i]} className="calendar-day-name">
          {dayNames[i]}
        </div>
      );
    }

    // Add empty divs for the days before the 1st day of the month
    for (let i = 0; i < startDayOfWeek; i++) {
      calendar.push(<div key={`empty-${i}`} className="calendar-day-empty" />);
    }

    // Create calendar days
    today.setHours(0, 0, 0, 0);

    // Create calendar days
    while (currentDate <= endDate) {
      const isArrivalDate =
        arrivalDate &&
        currentDate?.toDateString() ===
          (typeof arrivalDate === "object"
            ? arrivalDate.toDateString()
            : arrivalDate);
      const isDepartureDate =
        departureDate &&
        currentDate?.toDateString() ===
          (typeof departureDate === "object"
            ? departureDate?.toDateString()
            : departureDate);

      const isBetweenDates =
        arrivalDate &&
        departureDate &&
        currentDate > arrivalDate &&
        currentDate < departureDate;
      const isPassedDay = !enablePast ? currentDate < today : false;
      const dateString = parseStupidDate(currentDate.toDateString());
      const disabled =
        isPassedDay ||
        !checkDatePresence(
          dateString,
          isSettingArrivalDate ? possibleArrivalDates : possibleDepartureDates
        );

      calendar.push(
        <div key={currentDate.toDateString() + "-wrapper"}>
          {/*<Tooltip*/}
          {/*  title={disabled ? "תאריך לא זמיו" : undefined}*/}
          {/*  onClick={(e) => e.preventDefault()}*/}
          {/*>*/}
          <div
            key={currentDate.toDateString()}
            id={currentDate.toDateString()}
            className={classNames("calendar-day", {
              "calendar-selected-arrival-departure":
                isArrivalDate || isDepartureDate,
              "calendar-selected-between": isBetweenDates,
              disabled,
            })}
            onClick={(e) => {
              disabled ? console.log("") : handleDateClick(e);
            }}
          >
            {currentDate.getDate()}
          </div>
          {/*</Tooltip>*/}
        </div>
      );

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return calendar;
  };

  const checkDatePresence = (date, dateList) => {
    if (!dateList) {
      return true;
    }
    return dateList.includes(date);
  };

  // Arrow functioning
  const arrowFunctionArrival = () => {
    setDefaultArrivalMonth(defaultArrivalMonth - 1);
    setDefaultLeaveMonth(defaultLeaveMonth - 1);
  };

  const arrowFunctionLeave = () => {
    setDefaultArrivalMonth(defaultArrivalMonth + 1);
    setDefaultLeaveMonth(defaultLeaveMonth + 1);
  };

  useEffect(() => {
    if (calendarDateArrival === "") setArrivalDate(calendarDateArrival);
    if (calendarDateLeave === "") setDepartureDate(calendarDateLeave);
  }, [calendarDateArrival, calendarDateLeave]);

  function clearDates() {
    saveDepartureDate("");
    saveArrivalDate("");
  }

  return (
    <div className="calendar-wrapper lg:tw-absolute ">
      <div className="calendar tw-flex tw-flex-col lg:tw-flex-row">
        <div className="calendar-days-wrapper">
          {document.documentElement.dir === "rtl" ? (
            <div className="calendar-arrows-wrapper">
              <FontAwesomeIcon
                onClick={arrowFunctionArrival}
                className="calendar-arrows calendar-left"
                icon={faChevronRight}
              />

              <label className="calendar-title calendar-left">
                {getMonthName(defaultArrivalMonth).monthname}{" "}
                {getMonthName(defaultArrivalMonth).year}
              </label>
            </div>
          ) : (
            <div className="calendar-arrows-wrapper direction-ltr">
              <FontAwesomeIcon
                onClick={arrowFunctionArrival}
                className="calendar-arrows calendar-right"
                icon={faChevronLeft}
              />

              <label className="calendar-title calendar-right">
                {getMonthName(defaultArrivalMonth).monthname}{" "}
                {getMonthName(defaultArrivalMonth).year}
              </label>
            </div>
          )}

          <div className="calendar-days">
            {renderCalendar(defaultArrivalMonth)}
          </div>
        </div>

        <div className="calendar-divider"></div>

        <div className="calendar-days-wrapper">
          {document.documentElement.dir === "rtl" ? (
            <div className="calendar-arrows-wrapper">
              <label className="calendar-title calendar-right">
                {getMonthName(defaultLeaveMonth).monthname}{" "}
                {getMonthName(defaultLeaveMonth).year}
              </label>

              <FontAwesomeIcon
                onClick={arrowFunctionLeave}
                className="calendar-arrows calendar-right"
                icon={faChevronLeft}
              />
            </div>
          ) : (
            <div className="calendar-arrows-wrapper direction-rtl">
              <FontAwesomeIcon
                onClick={arrowFunctionLeave}
                className="calendar-arrows calendar-left"
                icon={faChevronRight}
              />

              <label className="calendar-title calendar-left">
                {getMonthName(defaultLeaveMonth).monthname}{" "}
                {getMonthName(defaultLeaveMonth).year}
              </label>
            </div>
          )}

          <div className="calendar-days">
            {renderCalendar(defaultLeaveMonth)}
          </div>
        </div>
      </div>

      <div className="calendar-bottom">
        <p className="calendar-bottom-title">
          {arrivalDate ? selectedArrivalDate : "__/__/__"} -{" "}
          {departureDate ? selectedDepartureDate : "__/__/__"}
        </p>
        <div
          className={classNames("calendar-bottom-clear", {
            hidden: !arrivalDate,
          })}
          onClick={clearDates}
        >
          X
        </div>
      </div>
    </div>
  );
};

export default Calendar;
