import React from "react";
import { useTranslation } from "react-i18next";
import { convertPrice } from "../../../../services/currencyService";
import { isAdminUser } from "../../../../services/authService";

import "./PackageTable.scss";
import { ApiReqBaseUrl } from "../../../../assets/GlobalVariables";
import { Button, Dropdown, message } from "antd";
import dayjs from "dayjs";

const PassengersNamesRenderer = ({ roomsAndPaxInfo, divKey }) => {
  let kidsNames = [];
  let adultsNames = [];

  roomsAndPaxInfo?.forEach((group) => {
    adultsNames.push(...group.adults);
    kidsNames.push(...group.kids);
  });

  return (
    <div className="passenger-name" key={divKey}>
      {adultsNames?.map((adult, index) => (
        <div
          key={index}
        >{`${adult?.title}  ${adult?.firstname}  ${adult?.lastname}  `}</div>
      ))}

      {kidsNames.length ? <>ילדים:</> : <></>}
      {kidsNames?.map((kid, index) => (
        <div
          key={index}
        >{`${kid?.title} ${kid?.firstname} ${kid?.lastname}`}</div>
      ))}
    </div>
  );
};

const RoomsTypeRenderer = ({ rooms }) => {
  return rooms.map((room, index) => {
    const { roomType } = room;
    return (
      <div key={index} className="blue-name-cell">
        {roomType}
      </div>
    );
  });
};

const Supplements = ({ supplement }) => {
  const suppelments = Object.values(supplement)
    .filter((s) => s.checked)
    .map((s) => s.type)
    .reduce((acc, curr) => {
      if (acc[curr]) {
        acc[curr] += 1;
      } else {
        acc[curr] = 1;
      }
      return acc;
    }, {});

  return Object.entries(suppelments).map(([key, value]) => {
    return (
      <div key={key} className="blue-name-cell">
        {`${key} x${value}`}
      </div>
    );
  });
};

const CollapsedPackageRow = ({ row, orders, setOrders } = {}) => {
  const { t } = useTranslation();
  const [isRequesting, setIsRequesting] = React.useState(false);
  const {
    id,
    handlingfee,
    commission,
    handlingfeepercentage,
    sumpaid,
    ourprice,
    user_email,
    user_first_name,
    user_last_name,
    paid,
    cancelled,
    pending,
  } = row;
  const isAdmin = isAdminUser();

  const hotel = row?.order_response?.reservation?.hotel;
  const flights = row?.order_response?.reservation?.flight;

  const roomsAndPaxInfo = row?.passengersinfo;

  const mainContact = roomsAndPaxInfo?.[0]?.adults?.[0] || {};

  const hotelName = hotel?.hotelName;
  const firstWay = flights?.[0]?.leg || {};
  const returnWay = flights?.[1]?.leg || {};
  const supplierId = flights?.[0]?.leg?.supplierId;

  const supplement = row?.supplement || {};

  const changeOrderStatus = async ({ orderId, status }) => {
    try {
      const agentId = JSON.parse(localStorage.getItem("UserInfo")).id;
      setIsRequesting(true);
      const res = await fetch(ApiReqBaseUrl + `/lds_orders/${orderId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status, agent_id: agentId }),
      });
      if (res.status === 200) {
        message.success("הסטאטוס שונה בהצלחה");
        const order_res = await res.json();
        const newOrders = orders.map((order) =>
          order.id === orderId ? order_res : order
        );
        setOrders(newOrders);
      } else {
        message.error("שגיאה בשינוי סטאטוס");
      }
      setIsRequesting(false);
    } catch (error) {
      console.log("error", error);
      setIsRequesting(false);
    }
  };

  return (
    <div className="collapsed-wrapper">
      <div className="column-wrapper">
        {user_email ? (
          <>
            <div className="item-wrapper">
              <div className="item-header">{t("orders.agentName")}</div>
              <div>
                {user_first_name} {user_last_name}
              </div>
            </div>
            <div className="item-wrapper">
              <div className="item-header">{t("orders.agentEmail")}</div>
              <div>{user_email}</div>
            </div>
          </>
        ) : null}
        <div className="item-wrapper">
          <div className="item-header">{t("orders.hotelName")}</div>
          <div className="blue-name-cell">{hotelName}</div>
        </div>

        <div className="item-wrapper">
          <div className="item-header">{t("orders.roomType")}</div>
          <RoomsTypeRenderer rooms={roomsAndPaxInfo.map((rap) => rap.room)} />
        </div>

        {Object.values(supplement).filter((s) => s.checked).length ? (
          <div className="item-wrapper">
            <div className="item-header">כבודה</div>
            <Supplements supplement={supplement} />
          </div>
        ) : null}

        <div className="item-wrapper">
          <div className="item-header">{t("orders.passengersNames")}</div>
          <PassengersNamesRenderer
            roomsAndPaxInfo={roomsAndPaxInfo}
            divKey={row.id}
          />
        </div>

        <div className="item-wrapper">
          <div className="item-header">איש קשר ראשי</div>
          <div>
            {mainContact.title} {mainContact.firstname} {mainContact.lastname}
          </div>
          <div>{mainContact.email}</div>
          <div>{mainContact.cell}</div>
        </div>
      </div>

      <div className="column-wrapper">
        <div className="item-wrapper">
          <div className="item-header">תאריך הזמנה</div>
          <div>{dayjs(row.created_at).format("DD/MM/YYYY")}</div>
        </div>

        <div className="item-wrapper">
          <div className="item-header">טיסה הלוך</div>
          <div>{firstWay.airlineId}</div>
          <div>{firstWay.flightClass + firstWay.flightNumber}</div>
          <div>{firstWay.depDate}</div>
        </div>

        <div className="item-wrapper">
          <div className="item-header">טיסה חזור</div>
          <div>{returnWay.airlineId}</div>
          <div>{returnWay.flightClass + returnWay.flightNumber}</div>
          <div>{returnWay.depDate}</div>
        </div>

        <div className="item-wrapper">
          <div className="item-header">כניסה למלון</div>
          <div>{hotel?.checkInDate}</div>
        </div>

        <div className="item-wrapper">
          <div className="item-header">יציאה מהמלון</div>
          <div>{hotel?.checkOutDate}</div>
        </div>

        {isAdmin && (
          <div className="item-wrapper">
            <div className="item-header">קוד ספק</div>
            <div>{supplierId}</div>
          </div>
        )}
      </div>
      <div className="column-wrapper">
        {ourprice && (
          <div className="item-wrapper">
            <div className="item-header">{t("orders.toPay")}</div>
            <div>{convertPrice(ourprice.priceWithCreditCardFee)}</div>
          </div>
        )}

        <div className="item-wrapper">
          <div className="item-header">{t("orders.paid")}</div>
          <div>{convertPrice(sumpaid)}</div>
        </div>

        {ourprice && (
          <div className="item-wrapper">
            <div className="item-header">{t("orders.leftToPay")}</div>
            <div>{convertPrice(ourprice.priceWithCreditCardFee - sumpaid)}</div>
          </div>
        )}

        <div className="item-wrapper">
          <div className="item-header">{t("orders.myCommission")}</div>
          <div className="commisions-cell">{convertPrice(commission)}</div>
        </div>

        <div className="item-wrapper">
          <div className="item-header">{t("orders.handlingFee")}</div>
          <div className="commisions-cell">
            {handlingfee ? convertPrice(handlingfee) : "אין"}
          </div>
        </div>
        {isAdmin && (
          <div className="item-wrapper">
            <Dropdown
              menu={{
                onClick: ({ key }) => {
                  changeOrderStatus({ orderId: id, status: key });
                },
                items: [
                  {
                    key: "cancelled",
                    label: cancelled ? "פעילה" : "מבוטלת",
                    disabled: isRequesting,
                  },
                  {
                    key: "paid",
                    label: paid ? "לא שולמה" : "שולמה",
                    disabled: isRequesting,
                  },
                  {
                    key: "pending",
                    label: pending ? `מאושרת ע"י ספק` : "ממתינה לאישור ספק",
                    disabled: isRequesting,
                  },
                ],
              }}
            >
              <Button loading={isRequesting}>שנה סטאטוס</Button>
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default CollapsedPackageRow;
