import React, { useEffect, useState } from "react";
import withdrawalImg from "../../../assets/images/finance/withdrawal.png";
import StringInput from "../../Inputs/StringInput";
import UploadInput from "../../Inputs/UploadInput";
import Select from "../../Inputs/Select";

import "./WithdrawalWidget.css";
import { isAdminUser } from "../../../services/authService";
import { getAllUsersEmail } from "../../../services/fhpService";
import { Loader } from "../../Loader/Loader";

const WithdrawalWidget = ({
  pdfPath,
  setPdfPath,
  setWithdrawalSum,
  withdrawalSum,
  setInvoiceStrBase64,
  clickAddFinance,
  withdrawalEmail,
  setWithdrawalEmail,
  loading,
  openTable,
}) => {
  const isAdmin = isAdminUser();
  const [allUsersEmails, setAllUsersEmails] = useState([]);

  const loadUsersEmails = async () => {
    const response = await getAllUsersEmail();
    const emailsOptions = response?.map(({ email, firstname, lastname }) => ({
      name: `${firstname} ${lastname} - (${email})`,
      value: email,
    }));
    setAllUsersEmails(emailsOptions);
  };

  useEffect(() => {
    isAdmin && loadUsersEmails();
  }, []);

  return (
    <div className="withdrawal-widget">
      <div className="withdrawal-header">
        <img
          src={withdrawalImg}
          alt="Withdrawal logo"
          className="withdrawal-logo"
        />
        משיכת כספים
      </div>
      <div className="withdrawal-body">
        {isAdmin && (
          <div className="email-select">
            מייל
            <Select
              options={allUsersEmails}
              value={withdrawalEmail || []}
              placeholder="בחר מייל..."
              onChange={(e) => {
                setWithdrawalEmail(e?.target?.value);
              }}
            />
          </div>
        )}
        <div className="click" style={{ cursor: "pointer", width: "200px" }}>
          <StringInput
            title="סכום למשיכה"
            value={withdrawalSum}
            disabled={!isAdmin}
            onValueChange={(value) => setWithdrawalSum(value)}
          />
        </div>
        <UploadInput
          pdfPath={pdfPath}
          setPdfPath={setPdfPath}
          title="העלאת חשבונית"
          setFileStrBase64={setInvoiceStrBase64}
        />
        {loading ? (
          <div className="send-withrawal-loader">
            <Loader size="small" loaderText="בקשה פיננסית נשלחת..." />
          </div>
        ) : (
          <button className="send-withrawal-btn" onClick={clickAddFinance}>
            שלח בקשה
          </button>
        )}
      </div>
      <div className="withdrawal-footer">
        ההעברות מתבצעות בכל 20 לחודש עבור כל החשבוניות שהוגשו עד ה- 15 לחודש
      </div>
      <div className="withdrawal-footer">
        פניות להנהלה:{" "}
        <a href="mailto:accounts@hotelsriver.com">accounts@hotelsriver.com</a>
      </div>
    </div>
  );
};

export default WithdrawalWidget;
