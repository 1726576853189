export function textToUnicode(text) {
  return text.split('').map(char => {
    const code = char.charCodeAt(0).toString(16).padStart(4, '0');
    return `\\u${code}`;
  }).join('');
}

export const LDS_AUTOCOMPLETE_MORE = [
  {
    AIRPORTCODE: "",
    AIRPORTNAME: "",
    CITYCODE: "ALL",
    CITYNAME: "",
    CITYNAME_HEB: "",
    COUNTRYCODE: "",
    COUNTRYNAME: "All Destinations",
    COUNTRYNAME_HEB: "\u05db\u05dc \u05d4\u05d9\u05e2\u05d3\u05d9\u05dd",
  },
]

export const LDS_CATEGORIES = [
  "ALL",
  "ATTRACTIONS",
  "CRUISE",
  "FLIGHT ONLY",
  "FAMILY",
  "FOOTBALL",
  "INCLUDE BAGGAGE",
  "INCLUDE TRANSFER",
  "KOSHER",
  "LOW COST",
  "MASSORET",
  "MINI TOUR",
  "RUSSIAN",
  "SHOP AND STORE",
  "SKI KOSHER",
  "SPA AND WATERPARK",
  "SHOW",
  "SKI",
  "SPA",
  "SPORT"
]

export const LDS_AUTOCMPLETE_CITIES = [
  {
    AIRPORTCODE: "GRG",
    AIRPORTNAME: null,
    CITYCODE: "GRG",
    CITYNAME: "GARDEZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GRH",
    AIRPORTNAME: "Garuahi",
    CITYCODE: "GRH",
    CITYNAME: "GARUAHI",
    CITYNAME_HEB: "GARUAHI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GRI",
    AIRPORTNAME: "Grand Island",
    CITYCODE: "GRI",
    CITYNAME: "GRAND ISLAND",
    CITYNAME_HEB: "GRAND ISLAND",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ANG",
    AIRPORTNAME: "Brie-Champniers",
    CITYCODE: "ANG",
    CITYNAME: "ANGOULEME",
    CITYNAME_HEB: "ANGOULEME",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "GRJ",
    AIRPORTNAME: "George",
    CITYCODE: "GRJ",
    CITYNAME: "GEORGE",
    CITYNAME_HEB: "GEORGE",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "GRL",
    AIRPORTNAME: "Garasa",
    CITYCODE: "GRL",
    CITYNAME: "GARASA",
    CITYNAME_HEB: "GARASA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GRM",
    AIRPORTNAME: " ",
    CITYCODE: "GRM",
    CITYNAME: "GERMANY",
    CITYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GRN",
    AIRPORTNAME: "Gordon",
    CITYCODE: "GRN",
    CITYNAME: "GORDON",
    CITYNAME_HEB: "GORDON",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VAL",
    AIRPORTNAME: " ",
    CITYCODE: "VAL",
    CITYNAME: "SELVA VAL GARDENA",
    CITYNAME_HEB: "\u05d5\u05d0\u05dc \u05d2\u05e8\u05d3\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GRO",
    AIRPORTNAME: "Costa Brava",
    CITYCODE: "GRO",
    CITYNAME: "GERONA",
    CITYNAME_HEB: "\u05d7\u05d9\u05e8\u05d5\u05e0\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "GRP",
    AIRPORTNAME: null,
    CITYCODE: "GRP",
    CITYNAME: "GURUPI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GRQ",
    AIRPORTNAME: "Eelde",
    CITYCODE: "GRQ",
    CITYNAME: "GRONINGEN",
    CITYNAME_HEB: "GRONINGEN",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "GRR",
    AIRPORTNAME: "Kent County International",
    CITYCODE: "GRR",
    CITYNAME: "GRAND RAPIDS",
    CITYNAME_HEB: "GRAND RAPIDS",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GRS",
    AIRPORTNAME: "Baccarini",
    CITYCODE: "GRS",
    CITYNAME: "GROSSETO",
    CITYNAME_HEB: "\u05d2\u05e8\u05d5\u05e1\u05d8\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ANH",
    AIRPORTNAME: "Anuha Island Resort",
    CITYCODE: "ANH",
    CITYNAME: "ANUHA ISLAND RESORT",
    CITYNAME_HEB: "ANUHA ISLAND RESORT",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GRT",
    AIRPORTNAME: "Gujrat",
    CITYCODE: "GRT",
    CITYNAME: "GUJRAT",
    CITYNAME_HEB: "GUJRAT",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "GRV",
    AIRPORTNAME: "Groznyj",
    CITYCODE: "GRV",
    CITYNAME: "GROZNYJ",
    CITYNAME_HEB: "GROZNYJ",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GRW",
    AIRPORTNAME: "Graciosa Island",
    CITYCODE: "GRW",
    CITYNAME: "GRACIOSA ISLAND",
    CITYNAME_HEB: "GRACIOSA ISLAND",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "GRX",
    AIRPORTNAME: "Granada",
    CITYCODE: "GRX",
    CITYNAME: "GRANADA",
    CITYNAME_HEB: "\u05d2\u05e8\u05e0\u05d3\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "GRY",
    AIRPORTNAME: "Grimsey",
    CITYCODE: "GRY",
    CITYNAME: "GRIMSEY",
    CITYNAME_HEB: "GRIMSEY",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "GRZ",
    AIRPORTNAME: "Thalerhof",
    CITYCODE: "GRZ",
    CITYNAME: "GRAZ",
    CITYNAME_HEB: "\u05d2\u05e8\u05d0\u05e5",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GSA",
    AIRPORTNAME: "Long Pasia",
    CITYCODE: "GSA",
    CITYNAME: "LONG PASIA",
    CITYNAME_HEB: "LONG PASIA",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GSB",
    AIRPORTNAME: "Seymour Johnson AFB",
    CITYCODE: "GSB",
    CITYNAME: "GOLDSBORO",
    CITYNAME_HEB: "GOLDSBORO",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GSC",
    AIRPORTNAME: "Gascoyne Junction",
    CITYCODE: "GSC",
    CITYNAME: "GASCOYNE JUNCTION",
    CITYNAME_HEB: "GASCOYNE JUNCTION",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GSH",
    AIRPORTNAME: " ",
    CITYCODE: "GSH",
    CITYNAME: "GONNEVILLE SUR HONFL",
    CITYNAME_HEB:
      "\u05d2\u05d5\u05e0\u05d1\u05d9\u05dc \u05e1\u05d5\u05e8 \u05d0\u05d5\u05e0\u05e4\u05dc",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "ANI",
    AIRPORTNAME: "Aniak",
    CITYCODE: "ANI",
    CITYNAME: "ANIAK",
    CITYNAME_HEB: "Aniak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GSI",
    AIRPORTNAME: "Guadalcanal",
    CITYCODE: "GSI",
    CITYNAME: "GUADALCANAL",
    CITYNAME_HEB: "GUADALCANAL",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GSL",
    AIRPORTNAME: null,
    CITYCODE: "GSL",
    CITYNAME: "TALTHEILEI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GSN",
    AIRPORTNAME: "Mount Gunson",
    CITYCODE: "GSN",
    CITYNAME: "MOUNT GUNSON",
    CITYNAME_HEB: "MOUNT GUNSON",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GSO",
    AIRPORTNAME: "Piedmont Triad International Airport",
    CITYCODE: "GSO",
    CITYNAME: "GREENSBORO",
    CITYNAME_HEB: "GREENSBORO",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GDC",
    AIRPORTNAME: null,
    CITYCODE: "GSP",
    CITYNAME: "GREENVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GMU",
    AIRPORTNAME: null,
    CITYCODE: "GSP",
    CITYNAME: "GREENVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GSP",
    AIRPORTNAME: "Greenville-Spartanbur Airport",
    CITYCODE: "GSP",
    CITYNAME: "GREENVILLE",
    CITYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05d5\u05d5\u05d9\u05dc",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GSR",
    AIRPORTNAME: "Gardo",
    CITYCODE: "GSR",
    CITYNAME: "GARDO",
    CITYNAME_HEB: "GARDO",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GSS",
    AIRPORTNAME: "Sabi Sabi",
    CITYCODE: "GSS",
    CITYNAME: "SABI SABI",
    CITYNAME_HEB: "SABI SABI",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "ANJ",
    AIRPORTNAME: "Zanaga",
    CITYCODE: "ANJ",
    CITYNAME: "ZANAGA",
    CITYNAME_HEB: "ZANAGA",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "BQV",
    AIRPORTNAME: "Gustavus Bartlett SPB",
    CITYCODE: "GST",
    CITYNAME: "GUSTAVUS",
    CITYNAME_HEB: "Gustavus Bartlett SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GST",
    AIRPORTNAME: "Gustavus Airport",
    CITYCODE: "GST",
    CITYNAME: "GUSTAVUS",
    CITYNAME_HEB: "Gustavus Airport",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GSU",
    AIRPORTNAME: "Gedaref",
    CITYCODE: "GSU",
    CITYNAME: "GEDAREF",
    CITYNAME_HEB: "GEDAREF",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "GSY",
    AIRPORTNAME: "Binbrook",
    CITYCODE: "GSY",
    CITYNAME: "GRIMSBY",
    CITYNAME_HEB: "GRIMSBY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GTA",
    AIRPORTNAME: "Gatokae Aerodrom",
    CITYCODE: "GTA",
    CITYNAME: "GATOKAE",
    CITYNAME_HEB: "GATOKAE",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GTB",
    AIRPORTNAME: "Genting",
    CITYCODE: "GTB",
    CITYNAME: "GENTING",
    CITYNAME_HEB: "GENTING",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GTC",
    AIRPORTNAME: "Green Turtle",
    CITYCODE: "GTC",
    CITYNAME: "GREEN TURTLE",
    CITYNAME_HEB: "GREEN TURTLE",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GTE",
    AIRPORTNAME: "Alyangula",
    CITYCODE: "GTE",
    CITYNAME: "GROOTE EYLANDT",
    CITYNAME_HEB: "GROOTE EYLANDT",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GFA",
    AIRPORTNAME: null,
    CITYCODE: "GTF",
    CITYNAME: "GREAT FALLS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GTF",
    AIRPORTNAME: "International",
    CITYCODE: "GTF",
    CITYNAME: "GREAT FALLS",
    CITYNAME_HEB: "GREAT FALLS",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ANK",
    AIRPORTNAME: "Etimesgut",
    CITYCODE: "ANK",
    CITYNAME: "ANKARA",
    CITYNAME_HEB: "\u05d0\u05e0\u05e7\u05e8\u05d4",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GTG",
    AIRPORTNAME: "Municipal",
    CITYCODE: "GTG",
    CITYNAME: "GRANTSBURG",
    CITYNAME_HEB: "GRANTSBURG",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GTI",
    AIRPORTNAME: "Guettin",
    CITYCODE: "GTI",
    CITYNAME: "GUETTIN",
    CITYNAME_HEB: "GUETTIN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GTK",
    AIRPORTNAME: "Sungei Tekai",
    CITYCODE: "GTK",
    CITYNAME: "SUNGEI TEKAI",
    CITYNAME_HEB: "SUNGEI TEKAI",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GTO",
    AIRPORTNAME: "Tolotio",
    CITYCODE: "GTO",
    CITYNAME: "GORONTALO",
    CITYNAME_HEB: "GORONTALO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GTS",
    AIRPORTNAME: "Granites",
    CITYCODE: "GTS",
    CITYNAME: "GRANITES",
    CITYNAME_HEB: "GRANITES",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GTT",
    AIRPORTNAME: "Georgetown",
    CITYCODE: "GTT",
    CITYNAME: "GEORGETOWN",
    CITYNAME_HEB: "GEORGETOWN",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GTW",
    AIRPORTNAME: "Holesov",
    CITYCODE: "GTW",
    CITYNAME: "ZLIN",
    CITYNAME_HEB: "\u05d6\u05dc\u05d9\u05df",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GTY",
    AIRPORTNAME: "Gettysburg",
    CITYCODE: "GTY",
    CITYNAME: "GETTYSBURG",
    CITYNAME_HEB: "GETTYSBURG",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GUA",
    AIRPORTNAME: "La Aurora",
    CITYCODE: "GUA",
    CITYNAME: "GUATEMALA CITY",
    CITYNAME_HEB:
      "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4 \u05e1\u05d9\u05d8\u05d9",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "GUB",
    AIRPORTNAME: "Guerrero Negro",
    CITYCODE: "GUB",
    CITYNAME: "GUERRERO NEGRO",
    CITYNAME_HEB: "GUERRERO NEGRO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "ESB",
    AIRPORTNAME: null,
    CITYCODE: "ANK",
    CITYNAME: "ANKARA",
    CITYNAME_HEB: "\u05d0\u05e0\u05e7\u05e8\u05d4",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GUC",
    AIRPORTNAME: "Gunnison",
    CITYCODE: "GUC",
    CITYNAME: "GUNNISON",
    CITYNAME_HEB: "GUNNISON",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GUD",
    AIRPORTNAME: " ",
    CITYCODE: "GUD",
    CITYNAME: "GUDAURI",
    CITYNAME_HEB: "\u05d2\u05d5\u05d3\u05d4\u05d0\u05d5\u05e8\u05d9",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GUE",
    AIRPORTNAME: " ",
    CITYCODE: "GUE",
    CITYNAME: "GUATEMALA",
    CITYNAME_HEB: "\u05d2\u05d0\u05d5\u05d8\u05de\u05dc\u05d4",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d0\u05d8\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "GUF",
    AIRPORTNAME: "Edwards",
    CITYCODE: "GUF",
    CITYNAME: "GULF SHORES",
    CITYNAME_HEB: "Gulf Shores",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GUG",
    AIRPORTNAME: "Guari",
    CITYCODE: "GUG",
    CITYNAME: "GUARI",
    CITYNAME_HEB: "GUARI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GUH",
    AIRPORTNAME: "Gunnedah",
    CITYCODE: "GUH",
    CITYNAME: "GUNNEDAH",
    CITYNAME_HEB: "GUNNEDAH",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GUI",
    AIRPORTNAME: "Guiria",
    CITYCODE: "GUI",
    CITYNAME: "GUIRIA",
    CITYNAME_HEB: "GUIRIA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "GUJ",
    AIRPORTNAME: null,
    CITYCODE: "GUJ",
    CITYNAME: "GUARATINGUETA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GUL",
    AIRPORTNAME: "Goulburn",
    CITYCODE: "GUL",
    CITYNAME: "GOULBURN",
    CITYNAME_HEB: "GOULBURN",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GUM",
    AIRPORTNAME: "A.B. Won Pat International",
    CITYCODE: "GUM",
    CITYNAME: "GUAM",
    CITYNAME_HEB: "\u05d2\u05d5\u05d0\u05dd",
    COUNTRYCODE: "GU",
    COUNTRYNAME: "GUAM",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "ANL",
    AIRPORTNAME: "Andulo",
    CITYCODE: "ANL",
    CITYNAME: "ANDULO",
    CITYNAME_HEB: "ANDULO",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "NGM",
    AIRPORTNAME: null,
    CITYCODE: "GUM",
    CITYNAME: "GUAM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GU",
    COUNTRYNAME: "GUAM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NRV",
    AIRPORTNAME: null,
    CITYCODE: "GUM",
    CITYNAME: "GUAM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GU",
    COUNTRYNAME: "GUAM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UAM",
    AIRPORTNAME: null,
    CITYCODE: "GUM",
    CITYNAME: "GUAM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GU",
    COUNTRYNAME: "GUAM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GUO",
    AIRPORTNAME: "Gualaco",
    CITYCODE: "GUO",
    CITYNAME: "GUALACO",
    CITYNAME_HEB: "GUALACO",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "GUP",
    AIRPORTNAME: "Senator Clark",
    CITYCODE: "GUP",
    CITYNAME: "GALLUP",
    CITYNAME_HEB: "GALLUP",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GUQ",
    AIRPORTNAME: "Guanare",
    CITYCODE: "GUQ",
    CITYNAME: "GUANARE",
    CITYNAME_HEB: "GUANARE",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "GUR",
    AIRPORTNAME: "Gurney",
    CITYCODE: "GUR",
    CITYNAME: "ALOTAU",
    CITYNAME_HEB: "ALOTAU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GUS",
    AIRPORTNAME: "Grissom AFB",
    CITYCODE: "GUS",
    CITYNAME: "PERU",
    CITYNAME_HEB: "PERU",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GUT",
    AIRPORTNAME: "Guetersloh",
    CITYCODE: "GUT",
    CITYNAME: "GUETERSLOH",
    CITYNAME_HEB: "\u05d2\u05d5\u05d8\u05e8\u05e1\u05dc\u05d5",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GUU",
    AIRPORTNAME: "Grundarfjordur",
    CITYCODE: "GUU",
    CITYNAME: "GRUNDARFJORDUR",
    CITYNAME_HEB: "GRUNDARFJORDUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "DYS",
    AIRPORTNAME: "Abilene",
    CITYCODE: "ABI",
    CITYNAME: "ABILENE",
    CITYNAME_HEB: "Abilene",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ANM",
    AIRPORTNAME: "Antsirabato",
    CITYCODE: "ANM",
    CITYNAME: "ANTALAHA",
    CITYNAME_HEB: "ANTALAHA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "GUV",
    AIRPORTNAME: "Mougulu",
    CITYCODE: "GUV",
    CITYNAME: "MOUGULU",
    CITYNAME_HEB: "MOUGULU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GUW",
    AIRPORTNAME: "Atyrau",
    CITYCODE: "GUW",
    CITYNAME: "ATYRAU",
    CITYNAME_HEB: "ATYRAU",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "GUX",
    AIRPORTNAME: "Guna",
    CITYCODE: "GUX",
    CITYNAME: "GUNA",
    CITYNAME_HEB: "GUNA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "GUY",
    AIRPORTNAME: "Guymon",
    CITYCODE: "GUY",
    CITYNAME: "GUYMON",
    CITYNAME_HEB: "GUYMON",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GVA",
    AIRPORTNAME: "Cointrin",
    CITYCODE: "GVA",
    CITYNAME: "GENEVA",
    CITYNAME_HEB: "\u05d6\u05e0\u05d1\u05d4",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "GVE",
    AIRPORTNAME: "Municipal",
    CITYCODE: "GVE",
    CITYNAME: "GORDONSVILLE",
    CITYNAME_HEB: "GORDONSVILLE",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "GVI",
    AIRPORTNAME: "Green River",
    CITYCODE: "GVI",
    CITYNAME: "GREEN RIVER",
    CITYNAME_HEB: "GREEN RIVER",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GVL",
    AIRPORTNAME: "Lee Gilmer Memorial",
    CITYCODE: "GVL",
    CITYNAME: "GAINESVILLE",
    CITYNAME_HEB: "GAINESVILLE",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "GVP",
    AIRPORTNAME: "Greenvale",
    CITYCODE: "GVP",
    CITYNAME: "GREENVALE",
    CITYNAME_HEB: "GREENVALE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ANN",
    AIRPORTNAME: " ",
    CITYCODE: "ANN",
    CITYNAME: "ANNABERG",
    CITYNAME_HEB: "\u05d0\u05e0\u05d0\u05d1\u05e8\u05d2",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GVR",
    AIRPORTNAME: null,
    CITYCODE: "GVR",
    CITYNAME: "GOVERNADOR VALADA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GVT",
    AIRPORTNAME: "Majors Field",
    CITYCODE: "GVT",
    CITYNAME: "GREENVILLE",
    CITYNAME_HEB: "GREENVILLE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GVW",
    AIRPORTNAME: "Richards-Gebaur",
    CITYCODE: "GVW",
    CITYNAME: "GRANDVIEW",
    CITYNAME_HEB: "GRANDVIEW",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GVX",
    AIRPORTNAME: "Sandviken",
    CITYCODE: "GVX",
    CITYNAME: "GAVLE",
    CITYNAME_HEB: "GAVLE",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QYU",
    AIRPORTNAME: null,
    CITYCODE: "GVX",
    CITYNAME: "GAVLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GWA",
    AIRPORTNAME: "Gwa",
    CITYCODE: "GWA",
    CITYNAME: "GWA",
    CITYNAME_HEB: "GWA",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GWD",
    AIRPORTNAME: "Gwadar",
    CITYCODE: "GWD",
    CITYNAME: "GWADAR",
    CITYNAME_HEB: "GWADAR",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "KSH",
    CITYNAME: "KRYNICA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SDG",
    AIRPORTNAME: " ",
    CITYCODE: "SDG",
    CITYNAME: "SANDRIGO",
    CITYNAME_HEB: "\u05e1\u05e0\u05d3\u05e8\u05d9\u05d2\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "TIF",
    CITYNAME: "TIVOLI",
    CITYNAME_HEB: "\u05d8\u05d9\u05d1\u05d5\u05dc\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TEJ",
    AIRPORTNAME: " ",
    CITYCODE: "TEJ",
    CITYNAME: "TERRACINA",
    CITYNAME_HEB: "\u05d8\u05e8\u05e7\u05d9\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "LNL",
    CITYNAME: "LANDAL",
    CITYNAME_HEB: "\u05dc\u05e0\u05d3\u05dc",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GWE",
    AIRPORTNAME: "Gweru",
    CITYCODE: "GWE",
    CITYNAME: "GWERU",
    CITYNAME_HEB: "GWERU",
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: "\u05d6\u05d9\u05de\u05d1\u05d1\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GWL",
    AIRPORTNAME: "Gwalior",
    CITYCODE: "GWL",
    CITYNAME: "GWALIOR",
    CITYNAME_HEB: "GWALIOR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "GWN",
    AIRPORTNAME: "Gnarowein",
    CITYCODE: "GWN",
    CITYNAME: "GNAROWEIN",
    CITYNAME_HEB: "GNAROWEIN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ANO",
    AIRPORTNAME: "Angoche",
    CITYCODE: "ANO",
    CITYNAME: "ANGOCHE",
    CITYNAME_HEB: "ANGOCHE",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "GWO",
    AIRPORTNAME: "Leflore",
    CITYCODE: "GWO",
    CITYNAME: "GREENWOOD",
    CITYNAME_HEB: "GREENWOOD",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GWS",
    AIRPORTNAME: "Glenwood Springs",
    CITYCODE: "GWS",
    CITYNAME: "GLENWOOD SPRINGS",
    CITYNAME_HEB: "GLENWOOD SPRINGS",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GWT",
    AIRPORTNAME: "Westerland",
    CITYCODE: "GWT",
    CITYNAME: "WESTERLAND",
    CITYNAME_HEB: "WESTERLAND",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GWV",
    AIRPORTNAME: "Glendale",
    CITYCODE: "GWV",
    CITYNAME: "GLENDALE",
    CITYNAME_HEB: "GLENDALE",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "GWY",
    AIRPORTNAME: "Carnmore",
    CITYCODE: "GWY",
    CITYNAME: "GALWAY",
    CITYNAME_HEB: "\u05d2\u05d0\u05dc\u05d5\u05d5\u05d9",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "GXF",
    AIRPORTNAME: "Seiyun",
    CITYCODE: "GXF",
    CITYNAME: "SEIYUN",
    CITYNAME_HEB: "SEIYUN",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "KNR",
    CITYNAME: "KONINGSHOF",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MPJ",
    AIRPORTNAME: " ",
    CITYCODE: "MPJ",
    CITYNAME: "MONTECANTINI TERME",
    CITYNAME_HEB:
      "\u05de\u05d5\u05e0\u05d8\u05e7\u05e0\u05d8\u05d9\u05e0\u05d9 \u05d8\u05e8\u05de\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ISF",
    AIRPORTNAME: " ",
    CITYCODE: "ISF",
    CITYNAME: "Israir voucher",
    CITYNAME_HEB:
      "\u05d0\u05d5\u05e4\u05e6\u05d9\u05d4 \u05dc\u05d7\u05d5\u05e4\u05e9\u05d4",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "SQK",
    AIRPORTNAME: " ",
    CITYCODE: "SQK",
    CITYNAME: "SANT JOHANN IN TIROL",
    CITYNAME_HEB:
      "\u05e1\u05e0\u05d8 \u05d2\u05d5\u05df \u05d8\u05d9\u05e8\u05d5\u05dc",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "SRY",
    CITYNAME: "STRESA",
    CITYNAME_HEB: "\u05e1\u05d8\u05e8\u05e1\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DEE",
    AIRPORTNAME: " ",
    CITYCODE: "DEE",
    CITYNAME: "DESENZANO DEL GARDA",
    CITYNAME_HEB:
      "\u05d3\u05e1\u05e0\u05d6\u05d0\u05e0\u05d5 \u05d3\u05dc \u05d2\u05e8\u05d3\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GXG",
    AIRPORTNAME: "Negage",
    CITYCODE: "GXG",
    CITYNAME: "NEGAGE",
    CITYNAME_HEB: "NEGAGE",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "CDM",
    CITYNAME: "COURMAYEUR",
    CITYNAME_HEB: "\u05e7\u05d5\u05e8\u05de\u05d9\u05d9\u05e8",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FSE",
    AIRPORTNAME: " ",
    CITYCODE: "FSE",
    CITYNAME: "SCHLADMING",
    CITYNAME_HEB: "\u05e9\u05dc\u05d3\u05de\u05d9\u05e0\u05d2",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "FUF",
    CITYNAME: "FURSTENWALDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JGA",
    AIRPORTNAME: "Govardhanpur",
    CITYCODE: "JGA",
    CITYNAME: "JAMNAGAR",
    CITYNAME_HEB: "JAMNAGAR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "JGB",
    AIRPORTNAME: "Jagdalpur",
    CITYCODE: "JGB",
    CITYNAME: "JAGDALPUR",
    CITYNAME_HEB: "JAGDALPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "JGE",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JGE",
    CITYNAME: "GEOJE",
    CITYNAME_HEB: "GEOJE",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "JGN",
    AIRPORTNAME: "Jiayuguan",
    CITYCODE: "JGN",
    CITYNAME: "JIAYUGUAN",
    CITYNAME_HEB: "JIAYUGUAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "JGO",
    AIRPORTNAME: "Qeqertarsuaq",
    CITYCODE: "JGO",
    CITYNAME: "QEQERTARSUAQ",
    CITYNAME_HEB: "QEQERTARSUAQ",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "JGR",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JGR",
    CITYNAME: "GROENNEDAL",
    CITYNAME_HEB: "GROENNEDAL",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "JGX",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JGX",
    CITYNAME: "GLENDALE",
    CITYNAME_HEB: "GLENDALE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JHB",
    AIRPORTNAME: "Sultan Ismail International",
    CITYCODE: "JHB",
    CITYNAME: "JOHOR BAHRU",
    CITYNAME_HEB: "JOHOR BAHRU",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JHC",
    AIRPORTNAME: "Island Heliport",
    CITYCODE: "JHC",
    CITYNAME: "GARDEN CITY",
    CITYNAME_HEB: "GARDEN CITY",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JHG",
    AIRPORTNAME: "Gasa",
    CITYCODE: "JHG",
    CITYNAME: "JINGHONG",
    CITYNAME_HEB: "JINGHONG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ARF",
    AIRPORTNAME: "Acaricuara",
    CITYCODE: "ARF",
    CITYNAME: "ACARICUARA",
    CITYNAME_HEB: "ACARICUARA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JHM",
    AIRPORTNAME: "Kapalua",
    CITYCODE: "JHM",
    CITYNAME: "KAPALUA",
    CITYNAME_HEB: "KAPALUA",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JHQ",
    AIRPORTNAME: "Shute Harbour H/P",
    CITYCODE: "JHQ",
    CITYNAME: "SHUTE HARBOUR",
    CITYNAME_HEB: "SHUTE HARBOUR",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JHS",
    AIRPORTNAME: "Sisimiut",
    CITYCODE: "JHS",
    CITYNAME: "SISIMIUT",
    CITYNAME_HEB: "SISIMIUT",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "JHW",
    AIRPORTNAME: "Jamestown",
    CITYCODE: "JHW",
    CITYNAME: "JAMESTOWN",
    CITYNAME_HEB: "JAMESTOWN",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JHY",
    AIRPORTNAME: "Hyatt Regency H/P",
    CITYCODE: "JHY",
    CITYNAME: "CAMBRIDGE",
    CITYNAME_HEB: "CAMBRIDGE",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "JIA",
    AIRPORTNAME: null,
    CITYCODE: "JIA",
    CITYNAME: "JUINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JIB",
    AIRPORTNAME: null,
    CITYCODE: "JIB",
    CITYNAME: "DJIBOUTI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DJ",
    COUNTRYNAME: "DJIBOUTI",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JIJ",
    AIRPORTNAME: "Jigiga",
    CITYCODE: "JIJ",
    CITYNAME: "JIJIGA",
    CITYNAME_HEB: "JIJIGA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JIK",
    AIRPORTNAME: "Ikaria",
    CITYCODE: "JIK",
    CITYNAME: "IKARIA ISLAND",
    CITYNAME_HEB: "IKARIA ISLAND",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "JIL",
    AIRPORTNAME: "Jilin",
    CITYCODE: "JIL",
    CITYNAME: "JILIN",
    CITYNAME_HEB: "JILIN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ARG",
    AIRPORTNAME: " ",
    CITYCODE: "ARG",
    CITYNAME: "ARLBERG",
    CITYNAME_HEB: "\u05d0\u05e8\u05dc\u05d1\u05e8\u05d2",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "JIM",
    AIRPORTNAME: "Jimma",
    CITYCODE: "JIM",
    CITYNAME: "JIMMA",
    CITYNAME_HEB: "JIMMA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JIN",
    AIRPORTNAME: "Jinja",
    CITYCODE: "JIN",
    CITYNAME: "JINJA",
    CITYNAME_HEB: "\u05d2\u05d9\u05e0\u05d2\u05d4",
    COUNTRYCODE: "UG",
    COUNTRYNAME: "UGANDA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "JIP",
    AIRPORTNAME: "Jipijapa",
    CITYCODE: "JIP",
    CITYNAME: "JIPIJAPA",
    CITYNAME_HEB: "JIPIJAPA",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "JIR",
    AIRPORTNAME: "Jiri",
    CITYCODE: "JIR",
    CITYNAME: "JIRI",
    CITYNAME_HEB: "JIRI",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "JIU",
    AIRPORTNAME: "Jiujiang",
    CITYCODE: "JIU",
    CITYNAME: "JIUJIANG",
    CITYNAME_HEB: "JIUJIANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "JIW",
    AIRPORTNAME: "Jiwani",
    CITYCODE: "JIW",
    CITYNAME: "JIWANI",
    CITYNAME_HEB: "JIWANI",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "JJI",
    AIRPORTNAME: "Juanjui",
    CITYCODE: "JJI",
    CITYNAME: "JUANJUI",
    CITYNAME_HEB: "JUANJUI",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "JJN",
    AIRPORTNAME: "Jinjiang",
    CITYCODE: "JJN",
    CITYNAME: "JINJIANG",
    CITYNAME_HEB: "JINJIANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "JJU",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JJU",
    CITYNAME: "QAQORTOQ",
    CITYNAME_HEB: "QAQORTOQ",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "JKG",
    AIRPORTNAME: "Axamo",
    CITYCODE: "JKG",
    CITYNAME: "JONKOPING",
    CITYNAME_HEB: "JONKOPING",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ABP",
    AIRPORTNAME: "Atkamba",
    CITYCODE: "ABP",
    CITYNAME: "ATKAMBA",
    CITYNAME_HEB: "ATKAMBA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ARH",
    AIRPORTNAME: null,
    CITYCODE: "ARH",
    CITYNAME: "ARACHOVA",
    CITYNAME_HEB: "\u05d0\u05e8\u05d0\u05d7\u05d5\u05d1\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "JKH",
    AIRPORTNAME: "Chios",
    CITYCODE: "JKH",
    CITYNAME: "CHIOS",
    CITYNAME_HEB: "CHIOS",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "JKR",
    AIRPORTNAME: "Janakpur",
    CITYCODE: "JKR",
    CITYNAME: "JANAKPUR",
    CITYNAME_HEB: "JANAKPUR",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "CGK",
    AIRPORTNAME: "Soekarno-Hatta International",
    CITYCODE: "JKT",
    CITYNAME: "JAKARTA",
    CITYNAME_HEB: "\u05d2\u05e7\u05e8\u05d8\u05d4",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HLP",
    AIRPORTNAME: "Halim Perdana Kusuma",
    CITYCODE: "JKT",
    CITYNAME: "JAKARTA",
    CITYNAME_HEB: "\u05d2\u05e7\u05e8\u05d8\u05d4",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JKT",
    AIRPORTNAME: " ",
    CITYCODE: "JKT",
    CITYNAME: "JAKARTA",
    CITYNAME_HEB: "\u05d2\u05e7\u05e8\u05d8\u05d4",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JKV",
    AIRPORTNAME: "Jacksonville",
    CITYCODE: "JKV",
    CITYNAME: "JACKSONVILLE",
    CITYNAME_HEB: "JACKSONVILLE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JLA",
    AIRPORTNAME: "Quartz Creek",
    CITYCODE: "JLA",
    CITYNAME: "COOPER LODGE",
    CITYNAME_HEB: "Quartz Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JLD",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JLD",
    CITYNAME: "LANDSKRONA",
    CITYNAME_HEB: "LANDSKRONA",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JLH",
    AIRPORTNAME: "US Army Heliport",
    CITYCODE: "JLH",
    CITYNAME: "ARLINGTON HEIGHTS",
    CITYNAME_HEB: "ARLINGTON HEIGHTS",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JLN",
    AIRPORTNAME: "Joplin",
    CITYCODE: "JLN",
    CITYNAME: "JOPLIN",
    CITYNAME_HEB: "JOPLIN",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ARI",
    AIRPORTNAME: "Chacalluta International Airport",
    CITYCODE: "ARI",
    CITYNAME: "ARICA",
    CITYNAME_HEB: "ARICA",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "JLO",
    AIRPORTNAME: "Jesolo",
    CITYCODE: "JLO",
    CITYNAME: "JESOLO",
    CITYNAME_HEB: "\u05d2\u05e1\u05d5\u05dc\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JLP",
    AIRPORTNAME: "Juan Les Pins",
    CITYCODE: "JLP",
    CITYNAME: "JUAN LES PINS",
    CITYNAME_HEB: "JUAN LES PINS",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "JLR",
    AIRPORTNAME: "Jabalpur",
    CITYCODE: "JLR",
    CITYNAME: "JABALPUR",
    CITYNAME_HEB: "JABALPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "JLS",
    AIRPORTNAME: null,
    CITYCODE: "JLS",
    CITYNAME: "JALES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JMB",
    AIRPORTNAME: "Jamba",
    CITYCODE: "JMB",
    CITYNAME: "JAMBA",
    CITYNAME_HEB: "JAMBA",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "JMC",
    AIRPORTNAME: "Marin County",
    CITYCODE: "JMC",
    CITYNAME: "SAUSALITO",
    CITYNAME_HEB: "SAUSALITO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JMH",
    AIRPORTNAME: "Marriott Heliport",
    CITYCODE: "JMH",
    CITYNAME: "SCHAUMBURG",
    CITYNAME_HEB: "SCHAUMBURG",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JMK",
    AIRPORTNAME: "Mikonos",
    CITYCODE: "JMK",
    CITYNAME: "MYKONOS",
    CITYNAME_HEB: "\u05de\u05d9\u05e7\u05d5\u05e0\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "JMO",
    AIRPORTNAME: "Jomsom",
    CITYCODE: "JMO",
    CITYNAME: "JOMSOM",
    CITYNAME_HEB: "JOMSOM",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "JMS",
    AIRPORTNAME: "Jamestown",
    CITYCODE: "JMS",
    CITYNAME: "JAMESTOWN",
    CITYNAME_HEB: "JAMESTOWN",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ARJ",
    AIRPORTNAME: "Arso",
    CITYCODE: "ARJ",
    CITYNAME: "ARSO",
    CITYNAME_HEB: "ARSO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JMU",
    AIRPORTNAME: "Jiamusi",
    CITYCODE: "JMU",
    CITYNAME: "JIAMUSI",
    CITYNAME_HEB: "JIAMUSI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "JNA",
    AIRPORTNAME: null,
    CITYCODE: "JNA",
    CITYNAME: "JANUARIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JNB",
    AIRPORTNAME: "O.R. TAMBO INTERNATIONAL AIRPORT",
    CITYCODE: "JNB",
    CITYNAME: "JOHANNESBURG",
    CITYNAME_HEB: "\u05d9\u05d5\u05d4\u05e0\u05e1\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "GCJ",
    AIRPORTNAME: " ",
    CITYCODE: "JNB",
    CITYNAME: "JOHANNESBURG",
    CITYNAME_HEB: "\u05d9\u05d5\u05d4\u05e0\u05e1\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "HCS",
    AIRPORTNAME: " ",
    CITYCODE: "JNB",
    CITYNAME: "JOHANNESBURG",
    CITYNAME_HEB: "\u05d9\u05d5\u05d4\u05e0\u05e1\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "QRA",
    AIRPORTNAME: " ",
    CITYCODE: "JNB",
    CITYNAME: "JOHANNESBURG",
    CITYNAME_HEB: "\u05d9\u05d5\u05d4\u05e0\u05e1\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "JNG",
    AIRPORTNAME: "Jining",
    CITYCODE: "JNG",
    CITYNAME: "JINING",
    CITYNAME_HEB: "JINING",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "JNI",
    AIRPORTNAME: null,
    CITYCODE: "JNI",
    CITYNAME: "JUNIN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JNN",
    AIRPORTNAME: "Nanortalik",
    CITYCODE: "JNN",
    CITYNAME: "NANORTALIK",
    CITYNAME_HEB: "NANORTALIK",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "JNP",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JNP",
    CITYNAME: "NEWPORT BEACH",
    CITYNAME_HEB: "NEWPORT BEACH",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ARK",
    AIRPORTNAME: "Arusha",
    CITYCODE: "ARK",
    CITYNAME: "ARUSHA",
    CITYNAME_HEB: "\u05d0\u05e8\u05d5\u05e9\u05d4",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JNS",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JNS",
    CITYNAME: "NARSAQ",
    CITYNAME_HEB: "NARSAQ",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "JNU",
    AIRPORTNAME: "Juneau International",
    CITYCODE: "JNU",
    CITYNAME: "JUNEAU",
    CITYNAME_HEB: "Juneau International",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JNX",
    AIRPORTNAME: "Naxos Airport",
    CITYCODE: "JNX",
    CITYNAME: "NAXOS",
    CITYNAME_HEB: "\u05e0\u05e7\u05e1\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "JNZ",
    AIRPORTNAME: "Jinzhou",
    CITYCODE: "JNZ",
    CITYNAME: "JINZHOU",
    CITYNAME_HEB: "JINZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "JOE",
    AIRPORTNAME: "Joensuu",
    CITYCODE: "JOE",
    CITYNAME: "JOENSUU",
    CITYNAME_HEB: "JOENSUU",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "JOG",
    AIRPORTNAME: "Adisutjipto",
    CITYCODE: "JOG",
    CITYNAME: "YOGYAKARTA",
    CITYNAME_HEB: "YOGYAKARTA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JOH",
    AIRPORTNAME: "Port Saint Johns",
    CITYCODE: "JOH",
    CITYNAME: "PORT SAINT JOHNS",
    CITYNAME_HEB: "PORT SAINT JOHNS",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "JOI",
    AIRPORTNAME: null,
    CITYCODE: "JOI",
    CITYNAME: "JOINVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JOK",
    AIRPORTNAME: "Joshkar-Ola",
    CITYCODE: "JOK",
    CITYNAME: "JOSHKAR OLA",
    CITYNAME_HEB: "JOSHKAR OLA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JOL",
    AIRPORTNAME: "Jolo",
    CITYCODE: "JOL",
    CITYNAME: "JOLO",
    CITYNAME_HEB: "JOLO",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "ARL",
    AIRPORTNAME: "Arly",
    CITYCODE: "ARL",
    CITYNAME: "ARLY",
    CITYNAME_HEB: "ARLY",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "JOM",
    AIRPORTNAME: "Njombe",
    CITYCODE: "JOM",
    CITYNAME: "NJOMBE",
    CITYNAME_HEB: "NJOMBE",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JON",
    AIRPORTNAME: null,
    CITYCODE: "JON",
    CITYNAME: "JOHNSTON ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "UM",
    COUNTRYNAME: "U.S. MINOR OUTLYING ISLA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JOP",
    AIRPORTNAME: "Josephstaal",
    CITYCODE: "JOP",
    CITYNAME: "JOSEPHSTAAL",
    CITYNAME_HEB: "JOSEPHSTAAL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "JOR",
    AIRPORTNAME: "The City Heliport",
    CITYCODE: "JOR",
    CITYNAME: "ORANGE",
    CITYNAME_HEB: "ORANGE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JOS",
    AIRPORTNAME: "Jos",
    CITYCODE: "JOS",
    CITYNAME: "JOS",
    CITYNAME_HEB: "JOS",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JOT",
    AIRPORTNAME: "Municipal",
    CITYCODE: "JOT",
    CITYNAME: "JOLIET",
    CITYNAME_HEB: "JOLIET",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JPA",
    AIRPORTNAME: " ",
    CITYCODE: "JPA",
    CITYNAME: "JAPAN",
    CITYNAME_HEB: "\u05d9\u05e4\u05df",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "JPD",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JPD",
    CITYNAME: "PASADENA",
    CITYNAME_HEB: "PASADENA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JPR",
    AIRPORTNAME: null,
    CITYCODE: "JPR",
    CITYNAME: "JI PARANA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JQA",
    AIRPORTNAME: "Qaarsut",
    CITYCODE: "JQA",
    CITYNAME: "QAARSUT",
    CITYNAME_HEB: "QAARSUT",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ARM",
    AIRPORTNAME: " ",
    CITYCODE: "ARM",
    CITYNAME: "ARMENIA",
    CITYNAME_HEB: "\u05d0\u05e8\u05de\u05e0\u05d9\u05d4",
    COUNTRYCODE: "AM",
    COUNTRYNAME: "ARMENIA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JQE",
    AIRPORTNAME: "Jaque",
    CITYCODE: "JQE",
    CITYNAME: "JAQUE",
    CITYNAME_HEB: "JAQUE",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "JRH",
    AIRPORTNAME: "Rowriah",
    CITYCODE: "JRH",
    CITYNAME: "JORHAT",
    CITYNAME_HEB: "JORHAT",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "JRK",
    AIRPORTNAME: "Arsuk",
    CITYCODE: "JRK",
    CITYNAME: "ARSUK",
    CITYNAME_HEB: "ARSUK",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "JRN",
    AIRPORTNAME: null,
    CITYCODE: "JRN",
    CITYNAME: "JURUENA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JRO",
    AIRPORTNAME: "Kilimanjaro",
    CITYCODE: "JRO",
    CITYNAME: "KILIMANJARO",
    CITYNAME_HEB: "\u05e7\u05dc\u05d9\u05de\u05e0\u05d2\u05e8\u05d5",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JRS",
    AIRPORTNAME: " ",
    CITYCODE: "JRS",
    CITYNAME: "JERUSALEM",
    CITYNAME_HEB: "\u05d9\u05e8\u05d5\u05e9\u05dc\u05d9\u05dd",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "JSA",
    AIRPORTNAME: "Jaisalmer",
    CITYCODE: "JSA",
    CITYNAME: "JAISALMER",
    CITYNAME_HEB: "JAISALMER",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "JSD",
    AIRPORTNAME: "Sikorsky Heliport",
    CITYCODE: "JSD",
    CITYNAME: "STRATFORD",
    CITYNAME_HEB: "STRATFORD",
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05e0\u05d8\u05d9\u05e7\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JSH",
    AIRPORTNAME: "Sitia",
    CITYCODE: "JSH",
    CITYNAME: "SITIA",
    CITYNAME_HEB: "SITIA",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "JSI",
    AIRPORTNAME: "Skiathos",
    CITYCODE: "JSI",
    CITYNAME: "SKIATHOS",
    CITYNAME_HEB: "\u05e1\u05e7\u05d9\u05d0\u05ea\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "ARO",
    AIRPORTNAME: "Arboletas",
    CITYCODE: "ARO",
    CITYNAME: "ARBOLETAS",
    CITYNAME_HEB: "ARBOLETAS",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JSM",
    AIRPORTNAME: null,
    CITYCODE: "JSM",
    CITYNAME: "JOSE D SAN MARTIN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JSO",
    AIRPORTNAME: "Sodertalje Heliport",
    CITYCODE: "JSO",
    CITYNAME: "SODERTALJE",
    CITYNAME_HEB: "SODERTALJE",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XEZ",
    AIRPORTNAME: null,
    CITYCODE: "JSO",
    CITYNAME: "SODERTALJE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JSR",
    AIRPORTNAME: "Jessore",
    CITYCODE: "JSR",
    CITYNAME: "JESSORE",
    CITYNAME_HEB: "JESSORE",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "JSS",
    AIRPORTNAME: "Spetsai Island",
    CITYCODE: "JSS",
    CITYNAME: "SPETSAI ISLAND",
    CITYNAME_HEB: "SPETSAI ISLAND",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "JST",
    AIRPORTNAME: "Cambria County",
    CITYCODE: "JST",
    CITYNAME: "JOHNSTOWN",
    CITYNAME_HEB: "JOHNSTOWN",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JSU",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JSU",
    CITYNAME: "MANIITSOQ",
    CITYNAME_HEB: "MANIITSOQ",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "JSY",
    AIRPORTNAME: "Syros Island",
    CITYCODE: "JSY",
    CITYNAME: "SYROS ISLAND",
    CITYNAME_HEB: "SYROS ISLAND",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "JTI",
    AIRPORTNAME: null,
    CITYCODE: "JTI",
    CITYNAME: "JATAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JTO",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JTO",
    CITYNAME: "THOUSAND OAKS",
    CITYNAME_HEB: "THOUSAND OAKS",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ARP",
    AIRPORTNAME: "Aragip",
    CITYCODE: "ARP",
    CITYNAME: "ARAGIP",
    CITYNAME_HEB: "ARAGIP",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "JTR",
    AIRPORTNAME: "Thira",
    CITYCODE: "JTR",
    CITYNAME: "SANTORINI",
    CITYNAME_HEB: "\u05e1\u05e0\u05d8\u05d5\u05e8\u05d9\u05e0\u05d9",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "JTY",
    AIRPORTNAME: "Astypalaia",
    CITYCODE: "JTY",
    CITYNAME: "ASTYPALEA ISLAND",
    CITYNAME_HEB: "ASTYPALEA ISLAND",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "JUA",
    AIRPORTNAME: null,
    CITYCODE: "JUA",
    CITYNAME: "JUARA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JUB",
    AIRPORTNAME: "Juba",
    CITYCODE: "JUB",
    CITYNAME: "JUBA",
    CITYNAME_HEB: "JUBA",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "JUI",
    AIRPORTNAME: "Juist",
    CITYCODE: "JUI",
    CITYNAME: "JUIST",
    CITYNAME_HEB: "JUIST",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JUJ",
    AIRPORTNAME: null,
    CITYCODE: "JUJ",
    CITYNAME: "JUJUY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JUL",
    AIRPORTNAME: "Juliaca",
    CITYCODE: "JUL",
    CITYNAME: "JULIACA",
    CITYNAME_HEB: "JULIACA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "JUM",
    AIRPORTNAME: "Jumla",
    CITYCODE: "JUM",
    CITYNAME: "JUMLA",
    CITYNAME_HEB: "JUMLA",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "JUN",
    AIRPORTNAME: "Jundah",
    CITYCODE: "JUN",
    CITYNAME: "JUNDAH",
    CITYNAME_HEB: "JUNDAH",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JUO",
    AIRPORTNAME: "Jurado",
    CITYCODE: "JUO",
    CITYNAME: "JURADO",
    CITYNAME_HEB: "JURADO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ARQ",
    AIRPORTNAME: "Arauquita",
    CITYCODE: "ARQ",
    CITYNAME: "ARAUQUITA",
    CITYNAME_HEB: "ARAUQUITA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JUR",
    AIRPORTNAME: "Jurien Bay",
    CITYCODE: "JUR",
    CITYNAME: "JURIEN BAY",
    CITYNAME_HEB: "JURIEN BAY",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JUT",
    AIRPORTNAME: "Juticalpa",
    CITYCODE: "JUT",
    CITYNAME: "JUTICALPA",
    CITYNAME_HEB: "JUTICALPA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "ISQ",
    AIRPORTNAME: "Schoolcraft County",
    CITYCODE: "ISQ",
    CITYNAME: "MANISTIQUE",
    CITYNAME_HEB: "MANISTIQUE",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ISS",
    AIRPORTNAME: "Wiscasset",
    CITYCODE: "ISS",
    CITYNAME: "WISCASSET",
    CITYNAME_HEB: "WISCASSET",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IST",
    AIRPORTNAME: "Ataturk",
    CITYCODE: "IST",
    CITYNAME: "ISTANBUL",
    CITYNAME_HEB: "\u05d0\u05d9\u05e1\u05d8\u05e0\u05d1\u05d5\u05dc",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ISW",
    AIRPORTNAME: "Alexander Field",
    CITYCODE: "ISW",
    CITYNAME: "WISCONSIN RAPIDS",
    CITYNAME_HEB: "WISCONSIN RAPIDS",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "ITA",
    CITYNAME: "VIA LATTEA",
    CITYNAME_HEB: "\u05d5\u05d9\u05d4 \u05dc\u05d0\u05d8\u05d0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ITB",
    AIRPORTNAME: null,
    CITYCODE: "ITB",
    CITYNAME: "ITAITUBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AQB",
    AIRPORTNAME: "Quiche Airport",
    CITYCODE: "AQB",
    CITYNAME: "QUICHE",
    CITYNAME_HEB: "QUICHE",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "ITE",
    AIRPORTNAME: " ",
    CITYCODE: "ITE",
    CITYNAME: "ITEA",
    CITYNAME_HEB: "\u05d0\u05d9\u05d8\u05d0\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "ITH",
    AIRPORTNAME: "Tompkins County",
    CITYCODE: "ITH",
    CITYNAME: "ITHACA",
    CITYNAME_HEB: "ITHACA",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ITI",
    AIRPORTNAME: null,
    CITYCODE: "ITI",
    CITYNAME: "ITAMBACURI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ITJ",
    AIRPORTNAME: null,
    CITYCODE: "ITJ",
    CITYNAME: "ITAJAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ITK",
    AIRPORTNAME: "Itokama",
    CITYCODE: "ITK",
    CITYNAME: "ITOKAMA",
    CITYNAME_HEB: "ITOKAMA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ITN",
    AIRPORTNAME: null,
    CITYCODE: "ITN",
    CITYNAME: "ITABUNA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ITO",
    AIRPORTNAME: "Hilo International",
    CITYCODE: "ITO",
    CITYNAME: "HILO",
    CITYNAME_HEB: "HILO",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ITP",
    AIRPORTNAME: null,
    CITYCODE: "ITP",
    CITYNAME: "ITAPERUNA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ITQ",
    AIRPORTNAME: null,
    CITYCODE: "ITQ",
    CITYNAME: "ITAQUI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ITR",
    AIRPORTNAME: " ",
    CITYCODE: "ITR",
    CITYNAME: "ISTRIA",
    CITYNAME_HEB: "\u05d0\u05d9\u05e1\u05d8\u05e8\u05d9\u05d4",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AQG",
    AIRPORTNAME: "Anqing",
    CITYCODE: "AQG",
    CITYNAME: "ANQING",
    CITYNAME_HEB: "ANQING",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "IUE",
    AIRPORTNAME: null,
    CITYCODE: "IUE",
    CITYNAME: "NIUE ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NU",
    COUNTRYNAME: "NIUE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IUL",
    AIRPORTNAME: "Ilu",
    CITYCODE: "IUL",
    CITYNAME: "ILU",
    CITYNAME_HEB: "ILU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IUM",
    AIRPORTNAME: "Summit Lake",
    CITYCODE: "IUM",
    CITYNAME: "SUMMIT LAKE",
    CITYNAME_HEB: "SUMMIT LAKE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "IUS",
    AIRPORTNAME: "Inus",
    CITYCODE: "IUS",
    CITYNAME: "INUS",
    CITYNAME_HEB: "INUS",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "IVA",
    AIRPORTNAME: "Ambanja",
    CITYCODE: "IVA",
    CITYNAME: "AMBANJA",
    CITYNAME_HEB: "AMBANJA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "IVC",
    AIRPORTNAME: "Invercargill",
    CITYCODE: "IVC",
    CITYNAME: "INVERCARGILL",
    CITYNAME_HEB: "INVERCARGILL",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "IVG",
    AIRPORTNAME: "Dolac Airport",
    CITYCODE: "IVG",
    CITYNAME: "IVANGRAD",
    CITYNAME_HEB: "IVANGRAD",
    COUNTRYCODE: "ME",
    COUNTRYNAME: "MONTENEGRO",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "IVH",
    AIRPORTNAME: "Ivishak",
    CITYCODE: "IVH",
    CITYNAME: "IVISHAK",
    CITYNAME_HEB: "Ivishak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IVL",
    AIRPORTNAME: "Ivalo",
    CITYCODE: "IVL",
    CITYNAME: "IVALO",
    CITYNAME_HEB: "IVALO",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "IVO",
    AIRPORTNAME: "Chivolo",
    CITYCODE: "IVO",
    CITYNAME: "CHIVOLO",
    CITYNAME_HEB: "CHIVOLO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AQJ",
    AIRPORTNAME: "King Hussein International Airport",
    CITYCODE: "AQJ",
    CITYNAME: "AQABA",
    CITYNAME_HEB: "\u05e2\u05e7\u05d1\u05d4",
    COUNTRYCODE: "JO",
    COUNTRYNAME: "JORDAN",
    COUNTRYNAME_HEB: "\u05d9\u05e8\u05d3\u05df",
  },
  {
    AIRPORTCODE: "IVR",
    AIRPORTNAME: "Inverell",
    CITYCODE: "IVR",
    CITYNAME: "INVERELL",
    CITYNAME_HEB: "INVERELL",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IVW",
    AIRPORTNAME: "Inverway",
    CITYCODE: "IVW",
    CITYNAME: "INVERWAY",
    CITYNAME_HEB: "INVERWAY",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IWA",
    AIRPORTNAME: "Ivanova",
    CITYCODE: "IWA",
    CITYNAME: "IVANOVA",
    CITYNAME_HEB: "IVANOVA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IWD",
    AIRPORTNAME: "Gogebic County",
    CITYCODE: "IWD",
    CITYNAME: "IRONWOOD",
    CITYNAME_HEB: "IRONWOOD",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IWJ",
    AIRPORTNAME: "Iwami",
    CITYCODE: "IWJ",
    CITYNAME: "IWAMI",
    CITYNAME_HEB: "IWAMI",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "IWO",
    AIRPORTNAME: "Iwo Jima Airbase",
    CITYCODE: "IWO",
    CITYNAME: "IWO JIMA VOL",
    CITYNAME_HEB: "IWO JIMA VOL",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "IXA",
    AIRPORTNAME: "Singerbhil",
    CITYCODE: "IXA",
    CITYNAME: "AGARTALA",
    CITYNAME_HEB: "AGARTALA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXB",
    AIRPORTNAME: "Bagdogra",
    CITYCODE: "IXB",
    CITYNAME: "BAGDOGRA",
    CITYNAME_HEB: "BAGDOGRA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXC",
    AIRPORTNAME: "Chandigarh",
    CITYCODE: "IXC",
    CITYNAME: "CHANDIGARH",
    CITYNAME_HEB: "CHANDIGARH",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXD",
    AIRPORTNAME: "Bamrauli",
    CITYCODE: "IXD",
    CITYNAME: "ALLAHABAD",
    CITYNAME_HEB: "ALLAHABAD",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "AQM",
    AIRPORTNAME: null,
    CITYCODE: "AQM",
    CITYNAME: "ARIQUEMES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IXE",
    AIRPORTNAME: "Bajpe",
    CITYCODE: "IXE",
    CITYNAME: "MANGALORE",
    CITYNAME_HEB: "MANGALORE",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXG",
    AIRPORTNAME: "Sambre",
    CITYCODE: "IXG",
    CITYNAME: "BELGAUM",
    CITYNAME_HEB: "BELGAUM",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXH",
    AIRPORTNAME: "Kailashahar",
    CITYCODE: "IXH",
    CITYNAME: "KAILASHAHAR",
    CITYNAME_HEB: "KAILASHAHAR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXI",
    AIRPORTNAME: "Lilabari",
    CITYCODE: "IXI",
    CITYNAME: "LILABARI",
    CITYNAME_HEB: "LILABARI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXJ",
    AIRPORTNAME: "Satwari",
    CITYCODE: "IXJ",
    CITYNAME: "JAMMU",
    CITYNAME_HEB: "JAMMU",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXK",
    AIRPORTNAME: "Keshod",
    CITYCODE: "IXK",
    CITYNAME: "KESHOD",
    CITYNAME_HEB: "KESHOD",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXL",
    AIRPORTNAME: "Leh",
    CITYCODE: "IXL",
    CITYNAME: "LEH",
    CITYNAME_HEB: "LEH",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXM",
    AIRPORTNAME: "Madurai",
    CITYCODE: "IXM",
    CITYNAME: "MADURAI",
    CITYNAME_HEB: "\u05de\u05d0\u05d3\u05d5\u05e8\u05d0\u05d9",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXN",
    AIRPORTNAME: "Khowai",
    CITYCODE: "IXN",
    CITYNAME: "KHOWAI",
    CITYNAME_HEB: "KHOWAI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXP",
    AIRPORTNAME: "Pathankot",
    CITYCODE: "IXP",
    CITYNAME: "PATHANKOT",
    CITYNAME_HEB: "PATHANKOT",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "ABO",
    AIRPORTNAME: " ",
    CITYCODE: "ABO",
    CITYNAME: "ALBIR",
    CITYNAME_HEB: "\u05d0\u05dc\u05d1\u05d9\u05e8",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "AQP",
    AIRPORTNAME: "Rodriguez Ballon International Airport",
    CITYCODE: "AQP",
    CITYNAME: "AREQUIPA",
    CITYNAME_HEB: "AREQUIPA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "IXQ",
    AIRPORTNAME: "Kamalpur",
    CITYCODE: "IXQ",
    CITYNAME: "KAMALPUR",
    CITYNAME_HEB: "KAMALPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXR",
    AIRPORTNAME: "Ranchi",
    CITYCODE: "IXR",
    CITYNAME: "RANCHI",
    CITYNAME_HEB: "RANCHI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXS",
    AIRPORTNAME: "Kumbhirgram",
    CITYCODE: "IXS",
    CITYNAME: "SILCHAR",
    CITYNAME_HEB: "SILCHAR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXT",
    AIRPORTNAME: "Pasighat",
    CITYCODE: "IXT",
    CITYNAME: "PASIGHAT",
    CITYNAME_HEB: "PASIGHAT",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXU",
    AIRPORTNAME: "Chikkalthana",
    CITYCODE: "IXU",
    CITYNAME: "AURANGABAD",
    CITYNAME_HEB: "AURANGABAD",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXV",
    AIRPORTNAME: "Along",
    CITYCODE: "IXV",
    CITYNAME: "ALONG",
    CITYNAME_HEB: "ALONG",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXW",
    AIRPORTNAME: "Sonari",
    CITYCODE: "IXW",
    CITYNAME: "JAMSHEDPUR",
    CITYNAME_HEB: "JAMSHEDPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXY",
    AIRPORTNAME: "Kandla",
    CITYCODE: "IXY",
    CITYNAME: "KANDLA",
    CITYNAME_HEB: "KANDLA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IXZ",
    AIRPORTNAME: "Port Blair",
    CITYCODE: "IXZ",
    CITYNAME: "PORT BLAIR",
    CITYNAME_HEB: "PORT BLAIR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IYK",
    AIRPORTNAME: "Kern County",
    CITYCODE: "IYK",
    CITYNAME: "INYOKERN",
    CITYNAME_HEB: "INYOKERN",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AQS",
    AIRPORTNAME: "Saqani",
    CITYCODE: "AQS",
    CITYNAME: "SAQANI",
    CITYNAME_HEB: "SAQANI",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "ADB",
    AIRPORTNAME: "Adnan Menderes Airport",
    CITYCODE: "IZM",
    CITYNAME: "IZMIR",
    CITYNAME_HEB: "\u05d0\u05d9\u05d6\u05de\u05d9\u05e8",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IGL",
    AIRPORTNAME: " ",
    CITYCODE: "IZM",
    CITYNAME: "IZMIR",
    CITYNAME_HEB: "\u05d0\u05d9\u05d6\u05de\u05d9\u05e8",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IZM",
    AIRPORTNAME: " ",
    CITYCODE: "IZM",
    CITYNAME: "IZMIR",
    CITYNAME_HEB: "\u05d0\u05d9\u05d6\u05de\u05d9\u05e8",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IZO",
    AIRPORTNAME: "Izumo",
    CITYCODE: "IZO",
    CITYNAME: "IZUMO",
    CITYNAME_HEB: "IZUMO",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "IZT",
    AIRPORTNAME: "Ixtepec",
    CITYCODE: "IZT",
    CITYNAME: "IXTEPEC",
    CITYNAME_HEB: "IXTEPEC",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "JAA",
    AIRPORTNAME: "Jalalabad",
    CITYCODE: "JAA",
    CITYNAME: "JALALABAD",
    CITYNAME_HEB: "\u05d2'\u05dc\u05d0\u05dc\u05d0\u05d1\u05d3",
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05e4\u05d2\u05e0\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "JAB",
    AIRPORTNAME: "Jabiru",
    CITYCODE: "JAB",
    CITYNAME: "JABIRU",
    CITYNAME_HEB: "JABIRU",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JAC",
    AIRPORTNAME: "Jackson Hole",
    CITYCODE: "JAC",
    CITYNAME: "JACKSON",
    CITYNAME_HEB: "JACKSON",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JAD",
    AIRPORTNAME: "Jandakot",
    CITYCODE: "JAD",
    CITYNAME: "JANDAKOT",
    CITYNAME_HEB: "JANDAKOT",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JAF",
    AIRPORTNAME: "Kankesanturai",
    CITYCODE: "JAF",
    CITYNAME: "JAFFNA",
    CITYNAME_HEB: "JAFFNA",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "AQY",
    AIRPORTNAME: "Alyeska",
    CITYCODE: "AQY",
    CITYNAME: "ALYESKA",
    CITYNAME_HEB: "Alyeska",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JAG",
    AIRPORTNAME: "Jacobabad",
    CITYCODE: "JAG",
    CITYNAME: "JACOBABAD",
    CITYNAME_HEB: "JACOBABAD",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "JAH",
    AIRPORTNAME: "Agora Helipad",
    CITYCODE: "JAH",
    CITYNAME: "AUBAGNE",
    CITYNAME_HEB: "AUBAGNE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "JAI",
    AIRPORTNAME: "Sanganeer",
    CITYCODE: "JAI",
    CITYNAME: "JAIPUR",
    CITYNAME_HEB: "\u05d2\u05d9\u05d0\u05e4\u05d5\u05e8",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "JAK",
    AIRPORTNAME: "Jacmel",
    CITYCODE: "JAK",
    CITYNAME: "JACMEL",
    CITYNAME_HEB: "JACMEL",
    COUNTRYCODE: "HT",
    COUNTRYNAME: "HAITI",
    COUNTRYNAME_HEB: "\u05d4\u05d0\u05d9\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "JAL",
    AIRPORTNAME: "Jalapa",
    CITYCODE: "JAL",
    CITYNAME: "JALAPA",
    CITYNAME_HEB: "JALAPA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "JAM",
    AIRPORTNAME: "Jambol",
    CITYCODE: "JAM",
    CITYNAME: "JAMBOL",
    CITYNAME_HEB: "\u05d2\u05de\u05d1\u05d5\u05dc",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HKS",
    AIRPORTNAME: " ",
    CITYCODE: "JAN",
    CITYNAME: "JACKSON",
    CITYNAME_HEB: "\u05d2\u05e7\u05e1\u05d5\u05df",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JAN",
    AIRPORTNAME: "Jackson International",
    CITYCODE: "JAN",
    CITYNAME: "JACKSON",
    CITYNAME_HEB: "\u05d2'\u05e7\u05e1\u05d5\u05df",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JAP",
    AIRPORTNAME: "Punta Renes",
    CITYCODE: "JAP",
    CITYNAME: "PUNTA RENES",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05e0\u05d8\u05d4 \u05e8\u05d0\u05e0\u05d0\u05e1",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "JAQ",
    AIRPORTNAME: "Jacquinot Bay",
    CITYCODE: "JAQ",
    CITYNAME: "JACQUINOT BAY",
    CITYNAME_HEB: "JACQUINOT BAY",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ARA",
    AIRPORTNAME: " ",
    CITYCODE: "ARA",
    CITYNAME: "ARGENTINA",
    CITYNAME_HEB: "\u05d0\u05e8\u05d2\u05e0\u05d8\u05d9\u05e0\u05d4",
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05d2\u05e0\u05d8\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "JAR",
    AIRPORTNAME: null,
    CITYCODE: "JAR",
    CITYNAME: "ARCADIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JAS",
    AIRPORTNAME: "County",
    CITYCODE: "JAS",
    CITYNAME: "JASPER",
    CITYNAME_HEB: "JASPER",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JAT",
    AIRPORTNAME: "Jabot",
    CITYCODE: "JAT",
    CITYNAME: "JABOT",
    CITYNAME_HEB: "JABOT",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "JAU",
    AIRPORTNAME: "Jauja",
    CITYCODE: "JAU",
    CITYNAME: "JAUJA",
    CITYNAME_HEB: "JAUJA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "JAV",
    AIRPORTNAME: "Ilulissat",
    CITYCODE: "JAV",
    CITYNAME: "ILULISSAT",
    CITYNAME_HEB: "ILULISSAT",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "JAX",
    AIRPORTNAME: "International",
    CITYCODE: "JAX",
    CITYNAME: "JACKSONVILLE",
    CITYNAME_HEB: "\u05d2'\u05e7\u05e1\u05d5\u05e0\u05d5\u05d5\u05d9\u05dc",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CRG",
    AIRPORTNAME: null,
    CITYCODE: "JAX",
    CITYNAME: "JACKSONVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NIP",
    AIRPORTNAME: null,
    CITYCODE: "JAX",
    CITYNAME: "JACKSONVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NZC",
    AIRPORTNAME: null,
    CITYCODE: "JAX",
    CITYNAME: "JACKSONVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JBK",
    AIRPORTNAME: "Berkeley",
    CITYCODE: "JBK",
    CITYNAME: "BERKELEY",
    CITYNAME_HEB: "BERKELEY",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ARB",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ARB",
    CITYNAME: "ANN ARBOR",
    CITYNAME_HEB: "ANN ARBOR",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JBR",
    AIRPORTNAME: "Jonesboro",
    CITYCODE: "JBR",
    CITYNAME: "JONESBORO",
    CITYNAME_HEB: "JONESBORO",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JBS",
    AIRPORTNAME: "Hacienda Bus.Park H/P",
    CITYCODE: "JBS",
    CITYNAME: "PLEASANTON",
    CITYNAME_HEB: "PLEASANTON",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JCB",
    AIRPORTNAME: null,
    CITYCODE: "JCB",
    CITYNAME: "JOACABA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JCH",
    AIRPORTNAME: "Qasigiannguit",
    CITYCODE: "JCH",
    CITYNAME: "QASIGIANNGUIT",
    CITYNAME_HEB: "QASIGIANNGUIT",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "JCK",
    AIRPORTNAME: "Julia Creek",
    CITYCODE: "JCK",
    CITYNAME: "JULIA CREEK",
    CITYNAME_HEB: "JULIA CREEK",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JCM",
    AIRPORTNAME: null,
    CITYCODE: "JCM",
    CITYNAME: "JACOBINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JCN",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JCN",
    CITYNAME: "INCHEON",
    CITYNAME_HEB: "INCHEON",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "JCO",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JCO",
    CITYNAME: "COMINO",
    CITYNAME_HEB: "COMINO",
    COUNTRYCODE: "MT",
    COUNTRYNAME: "MALTA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d8\u05d4",
  },
  {
    AIRPORTCODE: "JCR",
    AIRPORTNAME: null,
    CITYCODE: "JCR",
    CITYNAME: "JACAREACANGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JCT",
    AIRPORTNAME: "Kimble County",
    CITYCODE: "JCT",
    CITYNAME: "JUNCTION",
    CITYNAME_HEB: "JUNCTION",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "ARC",
    CITYNAME: "ARC 1950",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JCU",
    AIRPORTNAME: "Ceuta Heliport",
    CITYCODE: "JCU",
    CITYNAME: "CEUTA",
    CITYNAME_HEB: "CEUTA",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "JCY",
    AIRPORTNAME: "Johnson",
    CITYCODE: "JCY",
    CITYNAME: "JOHNSON",
    CITYNAME_HEB: "JOHNSON",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JDA",
    AIRPORTNAME: "John Day",
    CITYCODE: "JDA",
    CITYNAME: "JOHN DAY",
    CITYNAME_HEB: "JOHN DAY",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JDF",
    AIRPORTNAME: null,
    CITYCODE: "JDF",
    CITYNAME: "JUIZ DE FORA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JDH",
    AIRPORTNAME: "Jodhpur",
    CITYCODE: "JDH",
    CITYNAME: "JODHPUR",
    CITYNAME_HEB: "JODHPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "JDN",
    AIRPORTNAME: "Jordan",
    CITYCODE: "JDN",
    CITYNAME: "JORDAN",
    CITYNAME_HEB: "JORDAN (US)",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JDO",
    AIRPORTNAME: null,
    CITYCODE: "JDO",
    CITYNAME: "JUAZEIRO DO NORTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JDY",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JDY",
    CITYNAME: "DOWNEY",
    CITYNAME_HEB: "DOWNEY",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JDZ",
    AIRPORTNAME: "Jingdezhen",
    CITYCODE: "JDZ",
    CITYNAME: "JINGDEZHEN",
    CITYNAME_HEB: "JINGDEZHEN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "JEE",
    AIRPORTNAME: "Jeremie",
    CITYCODE: "JEE",
    CITYNAME: "JEREMIE",
    CITYNAME_HEB: "JEREMIE",
    COUNTRYCODE: "HT",
    COUNTRYNAME: "HAITI",
    COUNTRYNAME_HEB: "\u05d4\u05d0\u05d9\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "ARD",
    AIRPORTNAME: " ",
    CITYCODE: "ARD",
    CITYNAME: "ARDENNES",
    CITYNAME_HEB: "\u05d0\u05e8\u05d3\u05d0\u05e0\u05e1",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JEF",
    AIRPORTNAME: "Jefferson Memorial",
    CITYCODE: "JEF",
    CITYNAME: "JEFFERSON CITY",
    CITYNAME_HEB: "JEFFERSON CITY",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JEG",
    AIRPORTNAME: "Aasiaat",
    CITYCODE: "JEG",
    CITYNAME: "AASIAAT",
    CITYNAME_HEB: "AASIAAT",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "JEJ",
    AIRPORTNAME: "Jeh",
    CITYCODE: "JEJ",
    CITYNAME: "JEH",
    CITYNAME_HEB: "JEH",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "JEM",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JEM",
    CITYNAME: "EMERYVILLE",
    CITYNAME_HEB: "EMERYVILLE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JEQ",
    AIRPORTNAME: null,
    CITYCODE: "JEQ",
    CITYNAME: "JEQUIE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JER",
    AIRPORTNAME: "States",
    CITYCODE: "JER",
    CITYNAME: "JERSEY",
    CITYNAME_HEB: "JERSEY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JEV",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JEV",
    CITYNAME: "EVRY",
    CITYNAME_HEB: "EVRY",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "JFM",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JFM",
    CITYNAME: "FREMANTLE",
    CITYNAME_HEB: "FREMANTLE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JFN",
    AIRPORTNAME: "Ashtabula",
    CITYCODE: "JFN",
    CITYNAME: "JEFFERSON",
    CITYNAME_HEB: "JEFFERSON",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JFR",
    AIRPORTNAME: "Paamiut",
    CITYCODE: "JFR",
    CITYNAME: "PAAMIUT",
    CITYNAME_HEB: "PAAMIUT",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ARE",
    AIRPORTNAME: "Antonio/Nery/Juarbe Pol",
    CITYCODE: "ARE",
    CITYNAME: "ARECIBO",
    CITYNAME_HEB: "ARECIBO",
    COUNTRYCODE: "PR",
    COUNTRYNAME: "PUERTO RICO",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e8\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "GAU",
    AIRPORTNAME: "Borjhar",
    CITYCODE: "GAU",
    CITYNAME: "GUWAHATI",
    CITYNAME_HEB: "GUWAHATI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "GAV",
    AIRPORTNAME: "Gag Island",
    CITYCODE: "GAV",
    CITYNAME: "GAG ISLAND",
    CITYNAME_HEB: "GAG ISLAND",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GAW",
    AIRPORTNAME: "Gangaw",
    CITYCODE: "GAW",
    CITYNAME: "GANGAW",
    CITYNAME_HEB: "GANGAW",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GAX",
    AIRPORTNAME: "Gamba",
    CITYCODE: "GAX",
    CITYNAME: "GAMBA",
    CITYNAME_HEB: "GAMBA",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "GAY",
    AIRPORTNAME: "Gaya",
    CITYCODE: "GAY",
    CITYNAME: "GAYA",
    CITYNAME_HEB: "GAYA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "GAZ",
    AIRPORTNAME: "Guasopa",
    CITYCODE: "GAZ",
    CITYNAME: "GUASOPA",
    CITYNAME_HEB: "GUASOPA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "AMO",
    AIRPORTNAME: "Mao",
    CITYCODE: "AMO",
    CITYNAME: "MAO",
    CITYNAME_HEB: "MAO",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "GBA",
    AIRPORTNAME: "Big Bay",
    CITYCODE: "GBA",
    CITYNAME: "BIG BAY",
    CITYNAME_HEB: "BIG BAY",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "GBB",
    AIRPORTNAME: "Gara Djebilet",
    CITYCODE: "GBB",
    CITYNAME: "GARA DJEBILET",
    CITYNAME_HEB: "GARA DJEBILET",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GBC",
    AIRPORTNAME: "Gasuke",
    CITYCODE: "GBC",
    CITYNAME: "GASUKE",
    CITYNAME_HEB: "GASUKE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GBD",
    AIRPORTNAME: "Great Bend",
    CITYCODE: "GBD",
    CITYNAME: "GREAT BEND",
    CITYNAME_HEB: "GREAT BEND",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GBE",
    AIRPORTNAME: "Sir Seretse Khama Int",
    CITYCODE: "GBE",
    CITYNAME: "GABORONE",
    CITYNAME_HEB: "\u05d2\u05d1\u05d5\u05e8\u05d5\u05e0\u05d4",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "GBF",
    AIRPORTNAME: "Negarbo",
    CITYCODE: "GBF",
    CITYNAME: "NEGARBO",
    CITYNAME_HEB: "NEGARBO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GBG",
    AIRPORTNAME: "Galesburg",
    CITYCODE: "GBG",
    CITYNAME: "GALESBURG",
    CITYNAME_HEB: "GALESBURG",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GBH",
    AIRPORTNAME: "Galbraith Lake",
    CITYCODE: "GBH",
    CITYNAME: "GALBRAITH LAKE",
    CITYNAME_HEB: "Galbraith Lake",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GBI",
    AIRPORTNAME: "Aux Ab",
    CITYCODE: "GBI",
    CITYNAME: "GRAND BAHAMA",
    CITYNAME_HEB: "GRAND BAHAMA",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GBJ",
    AIRPORTNAME: "Les Bases",
    CITYCODE: "GBJ",
    CITYNAME: "MARIE GALANTE",
    CITYNAME_HEB: "MARIE GALANTE",
    COUNTRYCODE: "GP",
    COUNTRYNAME: "GUADELOUPE",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d2\u05d5\u05d5\u05d3\u05d0\u05dc\u05d5\u05e4",
  },
  {
    AIRPORTCODE: "AMP",
    AIRPORTNAME: "Ampanihy",
    CITYCODE: "AMP",
    CITYNAME: "AMPANIHY",
    CITYNAME_HEB: "AMPANIHY",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "GBK",
    AIRPORTNAME: "Gbangbatok",
    CITYCODE: "GBK",
    CITYNAME: "GBANGBATOK",
    CITYNAME_HEB: "GBANGBATOK",
    COUNTRYCODE: "SL",
    COUNTRYNAME: "SIERRA LEONE",
    COUNTRYNAME_HEB:
      "\u05e1\u05d9\u05d9\u05e8\u05d4 \u05dc\u05d9\u05d0\u05d5\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "GBL",
    AIRPORTNAME: "Goulburn Island",
    CITYCODE: "GBL",
    CITYNAME: "GOULBURN ISLAND",
    CITYNAME_HEB: "GOULBURN ISLAND",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GBM",
    AIRPORTNAME: "Garbaharey",
    CITYCODE: "GBM",
    CITYNAME: "GARBAHAREY",
    CITYNAME_HEB: "GARBAHAREY",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GBN",
    AIRPORTNAME: "San Giovanni Rotondo",
    CITYCODE: "GBN",
    CITYNAME: "SAN GIOVANNI ROTONDO",
    CITYNAME_HEB:
      "\u05d2\u05d9\u05d1\u05d5\u05e0\u05e0\u05d9 \u05e8\u05d5\u05d8\u05d5\u05e0\u05d3\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GBP",
    AIRPORTNAME: "Gamboola",
    CITYCODE: "GBP",
    CITYNAME: "GAMBOOLA",
    CITYNAME_HEB: "GAMBOOLA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GBR",
    AIRPORTNAME: "Great Barrington",
    CITYCODE: "GBR",
    CITYNAME: "GREAT BARRINGTON",
    CITYNAME_HEB: "GREAT BARRINGTON",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "GBS",
    AIRPORTNAME: "Port Fitzroy",
    CITYCODE: "GBS",
    CITYNAME: "PORT FITZROY",
    CITYNAME_HEB: "PORT FITZROY",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "GBU",
    AIRPORTNAME: "Khashm El Girba",
    CITYCODE: "GBU",
    CITYNAME: "KHASHM EL GIRBA",
    CITYNAME_HEB: "KHASHM EL GIRBA",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "GBV",
    AIRPORTNAME: "Gibb River",
    CITYCODE: "GBV",
    CITYNAME: "GIBB RIVER",
    CITYNAME_HEB: "GIBB RIVER",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GBZ",
    AIRPORTNAME: "Great Barrier Island",
    CITYCODE: "GBZ",
    CITYNAME: "GREAT BARRIER ISL",
    CITYNAME_HEB: "GREAT BARRIER ISL",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "AMQ",
    AIRPORTNAME: "Pattimura",
    CITYCODE: "AMQ",
    CITYNAME: "AMBON",
    CITYNAME_HEB: "AMBON",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GCA",
    AIRPORTNAME: "Guacamaya",
    CITYCODE: "GCA",
    CITYNAME: "GUACAMAYA",
    CITYNAME_HEB: "GUACAMAYA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GCC",
    AIRPORTNAME: "Campbell County",
    CITYCODE: "GCC",
    CITYNAME: "GILLETTE",
    CITYNAME_HEB: "GILLETTE",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GCI",
    AIRPORTNAME: "Guernsey",
    CITYCODE: "GCI",
    CITYNAME: "GUERNSEY",
    CITYNAME_HEB: "GUERNSEY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GCK",
    AIRPORTNAME: "Municipal Airport",
    CITYCODE: "GCK",
    CITYNAME: "GARDEN CITY",
    CITYNAME_HEB: "GARDEN CITY",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GCM",
    AIRPORTNAME: "Owen Roberts International",
    CITYCODE: "GCM",
    CITYNAME: "GRAND CAYMAN IS",
    CITYNAME_HEB:
      "\u05d2\u05e8\u05d0\u05e0\u05d3 \u05e7\u05d9\u05d9\u05de\u05df",
    COUNTRYCODE: "KY",
    COUNTRYNAME: "CAYMAN ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e7\u05d9\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "GCN",
    AIRPORTNAME: "National Park",
    CITYCODE: "GCN",
    CITYNAME: "GRAND CANYON",
    CITYNAME_HEB: "GRAND CANYON",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IKL",
    AIRPORTNAME: "Ikela",
    CITYCODE: "IKL",
    CITYNAME: "IKELA",
    CITYNAME_HEB: "IKELA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "IKO",
    AIRPORTNAME: "Nikolski AFS",
    CITYCODE: "IKO",
    CITYNAME: "NIKOLSKI",
    CITYNAME_HEB: "Nikolski AFS",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IKP",
    AIRPORTNAME: "Inkerman",
    CITYCODE: "IKP",
    CITYNAME: "INKERMAN",
    CITYNAME_HEB: "INKERMAN",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IKS",
    AIRPORTNAME: "Tiksi",
    CITYCODE: "IKS",
    CITYNAME: "TIKSI",
    CITYNAME_HEB: "TIKSI",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IKT",
    AIRPORTNAME: "Irkutsk",
    CITYCODE: "IKT",
    CITYNAME: "IRKUTSK",
    CITYNAME_HEB: "IRKUTSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ILA",
    AIRPORTNAME: "Illaga",
    CITYCODE: "ILA",
    CITYNAME: "ILLAGA",
    CITYNAME_HEB: "ILLAGA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "APQ",
    AIRPORTNAME: null,
    CITYCODE: "APQ",
    CITYNAME: "ARAPIRACA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ILB",
    AIRPORTNAME: null,
    CITYCODE: "ILB",
    CITYNAME: "ILHA SOLTEIRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GRK",
    AIRPORTNAME: "Gray AAF",
    CITYCODE: "ILE",
    CITYNAME: "KILLEEN",
    CITYNAME_HEB: "KILLEEN",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HLR",
    AIRPORTNAME: null,
    CITYCODE: "ILE",
    CITYNAME: "KILLEEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ILE",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ILE",
    CITYNAME: "KILLEEN",
    CITYNAME_HEB: "KILLEEN",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ILF",
    AIRPORTNAME: "Ilford",
    CITYCODE: "ILF",
    CITYNAME: "ILFORD",
    CITYNAME_HEB: "ILFORD",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ILG",
    AIRPORTNAME: "Greater Wilmington",
    CITYCODE: "ILG",
    CITYNAME: "WILMINGTON",
    CITYNAME_HEB: "WILMINGTON",
    COUNTRYCODE: "USDE",
    COUNTRYNAME: "DELAWARE",
    COUNTRYNAME_HEB:
      '\u05d3\u05dc\u05d5\u05d5\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZWI",
    AIRPORTNAME: null,
    CITYCODE: "ILG",
    CITYNAME: "WILMINGTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USDE",
    COUNTRYNAME: "DELAWARE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ILH",
    AIRPORTNAME: "Illis Airbase",
    CITYCODE: "ILH",
    CITYNAME: "ILLISHEIM",
    CITYNAME_HEB: "ILLISHEIM",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ILI",
    AIRPORTNAME: "Iliamna",
    CITYCODE: "ILI",
    CITYNAME: "ILIAMNA",
    CITYNAME_HEB: "Iliamna",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ILK",
    AIRPORTNAME: "Ilaka",
    CITYCODE: "ILK",
    CITYNAME: "ILAKA",
    CITYNAME_HEB: "ILAKA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "APR",
    AIRPORTNAME: "April River",
    CITYCODE: "APR",
    CITYNAME: "APRIL RIVER",
    CITYNAME_HEB: "APRIL RIVER",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ILL",
    AIRPORTNAME: "Willmar",
    CITYCODE: "ILL",
    CITYNAME: "WILLMAR",
    CITYNAME_HEB: "WILLMAR",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ILM",
    AIRPORTNAME: "New Hanover County",
    CITYCODE: "ILM",
    CITYNAME: "WILMINGTON",
    CITYNAME_HEB: "WILMINGTON",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ILN",
    AIRPORTNAME: "Clinton Field",
    CITYCODE: "ILN",
    CITYNAME: "WILMINGTON",
    CITYNAME_HEB: "WILMINGTON",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ILO",
    AIRPORTNAME: "Mandurriao",
    CITYCODE: "ILO",
    CITYNAME: "ILOILO",
    CITYNAME_HEB: "ILOILO",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "ILP",
    AIRPORTNAME: "Ile Des Pins",
    CITYCODE: "ILP",
    CITYNAME: "ILE DES PINS",
    CITYNAME_HEB: "ILE DES PINS",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "ILQ",
    AIRPORTNAME: "Ilo",
    CITYCODE: "ILQ",
    CITYNAME: "ILO",
    CITYNAME_HEB: "ILO",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "ILR",
    AIRPORTNAME: "Ilorin",
    CITYCODE: "ILR",
    CITYNAME: "ILORIN",
    CITYNAME_HEB: "ILORIN",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ILU",
    AIRPORTNAME: "Kilaguni",
    CITYCODE: "ILU",
    CITYNAME: "KILAGUNI",
    CITYNAME_HEB: "KILAGUNI",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ILX",
    AIRPORTNAME: "Ileg",
    CITYCODE: "ILX",
    CITYNAME: "ILEG",
    CITYNAME_HEB: "ILEG",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ILY",
    AIRPORTNAME: "Glenegedale",
    CITYCODE: "ILY",
    CITYNAME: "ISLAY",
    CITYNAME_HEB: "ISLAY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "APS",
    AIRPORTNAME: null,
    CITYCODE: "APS",
    CITYNAME: "ANAPOLIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ILZ",
    AIRPORTNAME: "Zilina",
    CITYCODE: "ILZ",
    CITYNAME: "ZILINA",
    CITYNAME_HEB: "\u05d6\u05d9\u05dc\u05d9\u05e0\u05d4",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IMA",
    AIRPORTNAME: "Iamalele",
    CITYCODE: "IMA",
    CITYNAME: "IAMALELE",
    CITYNAME_HEB: "IAMALELE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "IMB",
    AIRPORTNAME: "Imbaimadai",
    CITYCODE: "IMB",
    CITYNAME: "IMBAIMADAI",
    CITYNAME_HEB: "IMBAIMADAI",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "IMD",
    AIRPORTNAME: "Imonda",
    CITYCODE: "IMD",
    CITYNAME: "IMONDA",
    CITYNAME_HEB: "IMONDA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "IMF",
    AIRPORTNAME: "Municipal",
    CITYCODE: "IMF",
    CITYNAME: "IMPHAL",
    CITYNAME_HEB: "IMPHAL",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IMG",
    AIRPORTNAME: "Inhaminga",
    CITYCODE: "IMG",
    CITYNAME: "INHAMINGA",
    CITYNAME_HEB: "INHAMINGA",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "IMI",
    AIRPORTNAME: "Ine Island",
    CITYCODE: "IMI",
    CITYNAME: "INE ISLAND",
    CITYNAME_HEB: "INE ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "IMK",
    AIRPORTNAME: "Simikot",
    CITYCODE: "IMK",
    CITYNAME: "SIMIKOT",
    CITYNAME_HEB: "SIMIKOT",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "IML",
    AIRPORTNAME: "Imperial",
    CITYCODE: "IML",
    CITYNAME: "IMPERIAL",
    CITYNAME_HEB: "IMPERIAL",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IMM",
    AIRPORTNAME: "Immokalee",
    CITYCODE: "IMM",
    CITYNAME: "IMMOKALEE",
    CITYNAME_HEB: "IMMOKALEE",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "APT",
    AIRPORTNAME: "Marion County",
    CITYCODE: "APT",
    CITYNAME: "JASPER",
    CITYNAME_HEB: "JASPER",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IMN",
    AIRPORTNAME: "Imane",
    CITYCODE: "IMN",
    CITYNAME: "IMANE",
    CITYNAME_HEB: "IMANE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "IMO",
    AIRPORTNAME: null,
    CITYCODE: "IMO",
    CITYNAME: "ZEMIO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IMP",
    AIRPORTNAME: null,
    CITYCODE: "IMP",
    CITYNAME: "IMPERATRIZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IMT",
    AIRPORTNAME: "Ford",
    CITYCODE: "IMT",
    CITYNAME: "IRON MOUNTAIN",
    CITYNAME_HEB: "IRON MOUNTAIN",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IMZ",
    AIRPORTNAME: null,
    CITYCODE: "IMZ",
    CITYNAME: "NIMROZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "INA",
    AIRPORTNAME: "Inta",
    CITYCODE: "INA",
    CITYNAME: "INTA",
    CITYNAME_HEB: "INTA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "INB",
    AIRPORTNAME: "Independence",
    CITYCODE: "INB",
    CITYNAME: "INDEPENDENCE",
    CITYNAME_HEB: "INDEPENDENCE",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "INC",
    AIRPORTNAME: null,
    CITYCODE: "INC",
    CITYNAME: "INDAN OCEAN",
    CITYNAME_HEB:
      "\u05d4\u05d0\u05d5\u05e7\u05d9\u05e0\u05d5\u05e1 \u05d4\u05d4\u05d5\u05d3\u05d9",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "INE",
    AIRPORTNAME: "Chinde",
    CITYCODE: "INE",
    CITYNAME: "CHINDE",
    CITYNAME_HEB: "CHINDE",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "APU",
    AIRPORTNAME: null,
    CITYCODE: "APU",
    CITYNAME: "APUCARANA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "INF",
    AIRPORTNAME: "In Guezzam",
    CITYCODE: "INF",
    CITYNAME: "IN GUEZZAM",
    CITYNAME_HEB: "IN GUEZZAM",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ING",
    AIRPORTNAME: null,
    CITYCODE: "ING",
    CITYNAME: "LAGO ARGENTINO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "INH",
    AIRPORTNAME: "Inhambane",
    CITYCODE: "INH",
    CITYNAME: "INHAMBANE",
    CITYNAME_HEB: "INHAMBANE",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "INI",
    AIRPORTNAME: "Nis",
    CITYCODE: "INI",
    CITYNAME: "NIS",
    CITYNAME_HEB: "NIS",
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "INJ",
    AIRPORTNAME: "Injune",
    CITYCODE: "INJ",
    CITYNAME: "INJUNE",
    CITYNAME_HEB: "INJUNE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "INK",
    AIRPORTNAME: "Wink",
    CITYCODE: "INK",
    CITYNAME: "WINK",
    CITYNAME_HEB: "WINK",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "INL",
    AIRPORTNAME: "Falls International",
    CITYCODE: "INL",
    CITYNAME: "INTERNATIONAL FALLS",
    CITYNAME_HEB: "INTERNATIONAL FALLS",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "INM",
    AIRPORTNAME: "Innamincka",
    CITYCODE: "INM",
    CITYNAME: "INNAMINCKA",
    CITYNAME_HEB: "INNAMINCKA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "INN",
    AIRPORTNAME: "Kranebitten",
    CITYCODE: "INN",
    CITYNAME: "INNSBRUCK",
    CITYNAME_HEB: "\u05d0\u05d9\u05e0\u05e1\u05d1\u05d5\u05e8\u05e7",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "INO",
    AIRPORTNAME: "Inongo",
    CITYCODE: "INO",
    CITYNAME: "INONGO",
    CITYNAME_HEB: "INONGO",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "APV",
    AIRPORTNAME: "Apple Valley",
    CITYCODE: "APV",
    CITYNAME: "APPLE VALLEY",
    CITYNAME_HEB: "APPLE VALLEY",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "INQ",
    AIRPORTNAME: "Inisheer",
    CITYCODE: "INQ",
    CITYNAME: "INISHEER",
    CITYNAME_HEB: "INISHEER",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "INS",
    AIRPORTNAME: "Af Aux",
    CITYCODE: "INS",
    CITYNAME: "INDIAN SPRINGS",
    CITYNAME_HEB: "INDIAN SPRINGS",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "INT",
    AIRPORTNAME: "Smith-Reynolds",
    CITYCODE: "INT",
    CITYNAME: "WINSTON SALEM",
    CITYNAME_HEB: "WINSTON SALEM",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "INU",
    AIRPORTNAME: "International",
    CITYCODE: "INU",
    CITYNAME: "NAURU ISLAND",
    CITYNAME_HEB: "\u05e0\u05d0\u05d5\u05e8\u05d5",
    COUNTRYCODE: "NR",
    COUNTRYNAME: "NAURU",
    COUNTRYNAME_HEB: "\u05e0\u05d0\u05d5\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "INV",
    AIRPORTNAME: "Inverness",
    CITYCODE: "INV",
    CITYNAME: "INVERNESS",
    CITYNAME_HEB: "\u05d0\u05d9\u05e0\u05d1\u05e8\u05e0\u05e1",
    COUNTRYCODE: "ST",
    COUNTRYNAME: "SCOTLAND",
    COUNTRYNAME_HEB: "\u05e1\u05e7\u05d5\u05d8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "INW",
    AIRPORTNAME: "Winslow",
    CITYCODE: "INW",
    CITYNAME: "WINSLOW",
    CITYNAME_HEB: "WINSLOW",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "INX",
    AIRPORTNAME: "Inanwatan",
    CITYCODE: "INX",
    CITYNAME: "INANWATAN",
    CITYNAME_HEB: "INANWATAN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "INY",
    AIRPORTNAME: "Inyati",
    CITYCODE: "INY",
    CITYNAME: "INYATI",
    CITYNAME_HEB: "INYATI",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "INZ",
    AIRPORTNAME: "In Salah",
    CITYCODE: "INZ",
    CITYNAME: "IN SALAH",
    CITYNAME_HEB: "IN SALAH",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ABN",
    AIRPORTNAME: "Albina",
    CITYCODE: "ABN",
    CITYNAME: "ALBINA",
    CITYNAME_HEB: "ALBINA",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "APW",
    AIRPORTNAME: "Faleolo International Airport",
    CITYCODE: "APW",
    CITYNAME: "APIA",
    CITYNAME_HEB: "APIA",
    COUNTRYCODE: "WS",
    COUNTRYNAME: "SAMOA-INDEPENDENT STATE",
    COUNTRYNAME_HEB:
      "\u05e1\u05de\u05d5\u05d0\u05d4 \u05d4\u05de\u05e2\u05e8\u05d1\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "IOA",
    AIRPORTNAME: "Ioannina",
    CITYCODE: "IOA",
    CITYNAME: "IOANNINA",
    CITYNAME_HEB: "\u05d9\u05d5\u05d0\u05e0\u05d9\u05e0\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "IOK",
    AIRPORTNAME: "Iokea",
    CITYCODE: "IOK",
    CITYNAME: "IOKEA",
    CITYNAME_HEB: "IOKEA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "IOM",
    AIRPORTNAME: "Ronaldsway",
    CITYCODE: "IOM",
    CITYNAME: "ISLE OF MAN",
    CITYNAME_HEB: "ISLE OF MAN",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ION",
    AIRPORTNAME: "Impfondo",
    CITYCODE: "ION",
    CITYNAME: "IMPFONDO",
    CITYNAME_HEB: "IMPFONDO",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "IOP",
    AIRPORTNAME: "Ioma",
    CITYCODE: "IOP",
    CITYNAME: "IOMA",
    CITYNAME_HEB: "IOMA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "IOR",
    AIRPORTNAME: "Kilronan",
    CITYCODE: "IOR",
    CITYNAME: "INISHMORE",
    CITYNAME_HEB: "INISHMORE",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "IOS",
    AIRPORTNAME: "Eduardo Gomes",
    CITYCODE: "IOS",
    CITYNAME: "IOS",
    CITYNAME_HEB: "\u05d0\u05d9\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "IOU",
    AIRPORTNAME: "Ile Ouen",
    CITYCODE: "IOU",
    CITYNAME: "ILE OUEN",
    CITYNAME_HEB: "ILE OUEN",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "IOW",
    AIRPORTNAME: "Iowa City",
    CITYCODE: "IOW",
    CITYNAME: "IOWA CITY",
    CITYNAME_HEB: "IOWA CITY",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IPA",
    AIRPORTNAME: "Ipota",
    CITYCODE: "IPA",
    CITYNAME: "IPOTA",
    CITYNAME_HEB: "IPOTA",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "FGI",
    AIRPORTNAME: null,
    CITYCODE: "APW",
    CITYNAME: "APIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "WS",
    COUNTRYNAME: "SAMOA-INDEPENDENT STATE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IPC",
    AIRPORTNAME: "Mataveri International",
    CITYCODE: "IPC",
    CITYNAME: "EASTER ISLAND",
    CITYNAME_HEB: "EASTER ISLAND",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "IPE",
    AIRPORTNAME: "Ipil",
    CITYCODE: "IPE",
    CITYNAME: "IPIL",
    CITYNAME_HEB: "IPIL",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "IPG",
    AIRPORTNAME: null,
    CITYCODE: "IPG",
    CITYNAME: "IPIRANGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IPH",
    AIRPORTNAME: "Ipoh",
    CITYCODE: "IPH",
    CITYNAME: "IPOH",
    CITYNAME_HEB: "IPOH",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IPI",
    AIRPORTNAME: "San Luis",
    CITYCODE: "IPI",
    CITYNAME: "IPIALES",
    CITYNAME_HEB: "IPIALES",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IPL",
    AIRPORTNAME: "Imperial County Airport",
    CITYCODE: "IPL",
    CITYNAME: "EL CENTRO",
    CITYNAME_HEB: "EL CENTRO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NJK",
    AIRPORTNAME: null,
    CITYCODE: "IPL",
    CITYNAME: "EL CENTRO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IPN",
    AIRPORTNAME: null,
    CITYCODE: "IPN",
    CITYNAME: "IPATINGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IPT",
    AIRPORTNAME: "Lycoming County",
    CITYCODE: "IPT",
    CITYNAME: "WILLIAMSPORT",
    CITYNAME_HEB: "WILLIAMSPORT",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IPU",
    AIRPORTNAME: null,
    CITYCODE: "IPU",
    CITYNAME: "IPIAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "APX",
    AIRPORTNAME: null,
    CITYCODE: "APX",
    CITYNAME: "ARAPONGAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IPW",
    AIRPORTNAME: "Ipswich",
    CITYCODE: "IPW",
    CITYNAME: "IPSWICH",
    CITYNAME_HEB: "IPSWICH",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IQM",
    AIRPORTNAME: "Qiemo",
    CITYCODE: "IQM",
    CITYNAME: "QIEMO",
    CITYNAME_HEB: "QIEMO",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "IQN",
    AIRPORTNAME: "Qingyang",
    CITYCODE: "IQN",
    CITYNAME: "QINGYANG",
    CITYNAME_HEB: "QINGYANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "IQQ",
    AIRPORTNAME: "Cavancha",
    CITYCODE: "IQQ",
    CITYNAME: "IQUIQUE",
    CITYNAME_HEB: "IQUIQUE",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "IQT",
    AIRPORTNAME: "C.F. Secada",
    CITYCODE: "IQT",
    CITYNAME: "IQUITOS",
    CITYNAME_HEB: "IQUITOS",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "IRA",
    AIRPORTNAME: "Kirakira",
    CITYCODE: "IRA",
    CITYNAME: "KIRAKIRA",
    CITYNAME_HEB: "KIRAKIRA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "IRB",
    AIRPORTNAME: "Municipal",
    CITYCODE: "IRB",
    CITYNAME: "IRAAN",
    CITYNAME_HEB: "IRAAN",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IRC",
    AIRPORTNAME: "Circle City",
    CITYCODE: "IRC",
    CITYNAME: "CIRCLE",
    CITYNAME_HEB: "Circle City",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IRD",
    AIRPORTNAME: "Ishurdi",
    CITYCODE: "IRD",
    CITYNAME: "ISHURDI",
    CITYNAME_HEB: "ISHURDI",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "IRE",
    AIRPORTNAME: null,
    CITYCODE: "IRE",
    CITYNAME: "IRECE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "APY",
    AIRPORTNAME: null,
    CITYCODE: "APY",
    CITYNAME: "ALTO PARNAIBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IRG",
    AIRPORTNAME: "Lockhart River",
    CITYCODE: "IRG",
    CITYNAME: "LOCKHART RIVER",
    CITYNAME_HEB: "LOCKHART RIVER",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IRI",
    AIRPORTNAME: "Nduli",
    CITYCODE: "IRI",
    CITYNAME: "IRINGA",
    CITYNAME_HEB: "IRINGA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IRJ",
    AIRPORTNAME: null,
    CITYCODE: "IRJ",
    CITYNAME: "LA RIOJA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IRK",
    AIRPORTNAME: "Municipal",
    CITYCODE: "IRK",
    CITYNAME: "KIRKSVILLE",
    CITYNAME_HEB: "KIRKSVILLE",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IRN",
    AIRPORTNAME: "Iriona",
    CITYCODE: "IRN",
    CITYNAME: "IRIONA",
    CITYNAME_HEB: "IRIONA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "IRO",
    AIRPORTNAME: null,
    CITYCODE: "IRO",
    CITYNAME: "BIRAO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IRP",
    AIRPORTNAME: "Matari",
    CITYCODE: "IRP",
    CITYNAME: "ISIRO",
    CITYNAME_HEB: "ISIRO",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "IRS",
    AIRPORTNAME: "Kirsch Municipal",
    CITYCODE: "IRS",
    CITYNAME: "STURGIS",
    CITYNAME_HEB: "STURGIS",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ISA",
    AIRPORTNAME: "Mount Isa",
    CITYCODE: "ISA",
    CITYNAME: "MOUNT ISA",
    CITYNAME_HEB: "MOUNT ISA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ISB",
    AIRPORTNAME: null,
    CITYCODE: "ISB",
    CITYNAME: "ISLAMABAD",
    CITYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05de\u05d1\u05d0\u05d3",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "APZ",
    AIRPORTNAME: null,
    CITYCODE: "APZ",
    CITYNAME: "ZAPALA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ISC",
    AIRPORTNAME: "St Marys",
    CITYCODE: "ISC",
    CITYNAME: "ISLES OF SCILLY",
    CITYNAME_HEB: "ISLES OF SCILLY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ISD",
    AIRPORTNAME: "Iscuande",
    CITYCODE: "ISD",
    CITYNAME: "ISCUANDE",
    CITYNAME_HEB: "ISCUANDE",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ISE",
    AIRPORTNAME: "Isparta",
    CITYCODE: "ISE",
    CITYNAME: "ISPARTA-DAVRAS",
    CITYNAME_HEB: "ISPARTA",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "ISG",
    CITYNAME: "ISCHGL",
    CITYNAME_HEB: "\u05d0\u05d9\u05e9\u05d2\u05d0\u05dc",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ISH",
    AIRPORTNAME: "Ischia",
    CITYCODE: "ISH",
    CITYNAME: "ISCHIA",
    CITYNAME_HEB: "ISCHIA",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ISI",
    AIRPORTNAME: "Isisford",
    CITYCODE: "ISI",
    CITYNAME: "ISISFORD",
    CITYNAME_HEB: "ISISFORD",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ISJ",
    AIRPORTNAME: "Isla Mujeres",
    CITYCODE: "ISJ",
    CITYNAME: "ISLA MUJERES",
    CITYNAME_HEB: "ISLA MUJERES",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "ISK",
    AIRPORTNAME: "Gandhinagar Airport",
    CITYCODE: "ISK",
    CITYNAME: "NASIK",
    CITYNAME_HEB: "NASIK",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "ISL",
    AIRPORTNAME: "Isabel Pass",
    CITYCODE: "ISL",
    CITYNAME: "ISABEL PASS",
    CITYNAME_HEB: "Isabel Pass",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AQA",
    AIRPORTNAME: null,
    CITYCODE: "AQA",
    CITYNAME: "ARARAQUARA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ISM",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ISM",
    CITYNAME: "KISSIMMEE",
    CITYNAME_HEB: "KISSIMMEE",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ISN",
    AIRPORTNAME: "Sloulin Field International",
    CITYCODE: "ISN",
    CITYNAME: "WILLISTON",
    CITYNAME_HEB: "WILLISTON",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ISO",
    AIRPORTNAME: "Stallings Field",
    CITYCODE: "ISO",
    CITYNAME: "KINSTON",
    CITYNAME_HEB: "KINSTON",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ISP",
    AIRPORTNAME: "Long Island Macarthur",
    CITYCODE: "ISP",
    CITYNAME: "ISLIP",
    CITYNAME_HEB: "ISLIP",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EPS",
    AIRPORTNAME: "El Portillo",
    CITYCODE: "EPS",
    CITYNAME: "EL PORTILLO/SAMANA",
    CITYNAME_HEB: "EL PORTILLO/SAMANA",
    COUNTRYCODE: "DO",
    COUNTRYNAME: "DOMINICAN REPUBLIC",
    COUNTRYNAME_HEB:
      "\u05d4\u05e8\u05e4\u05d5\u05d1\u05dc\u05d9\u05e7\u05d4 \u05d4\u05d3\u05d5\u05de\u05d9\u05e0\u05d9\u05e7\u05e0\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "EPT",
    AIRPORTNAME: "Eliptamin",
    CITYCODE: "EPT",
    CITYNAME: "ELIPTAMIN",
    CITYNAME_HEB: "ELIPTAMIN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "EQS",
    AIRPORTNAME: null,
    CITYCODE: "EQS",
    CITYNAME: "ESQUEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ERA",
    AIRPORTNAME: "Erigavo",
    CITYCODE: "ERA",
    CITYNAME: "ERIGAVO",
    CITYNAME_HEB: "ERIGAVO",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ERB",
    AIRPORTNAME: "Ernabella",
    CITYCODE: "ERB",
    CITYNAME: "ERNABELLA",
    CITYNAME_HEB: "ERNABELLA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ERC",
    AIRPORTNAME: "Erzincan",
    CITYCODE: "ERC",
    CITYNAME: "ERZINCAN",
    CITYNAME_HEB: "ERZINCAN",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ERD",
    AIRPORTNAME: "Berdyansk",
    CITYCODE: "ERD",
    CITYNAME: "BERDYANSK",
    CITYNAME_HEB: "BERDYANSK",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "ERE",
    AIRPORTNAME: " ",
    CITYCODE: "ERE",
    CITYNAME: "ERETRIA",
    CITYNAME_HEB: "\u05d0\u05e8\u05d8\u05e8\u05d9\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "ERF",
    AIRPORTNAME: "Erfurt",
    CITYCODE: "ERF",
    CITYNAME: "ERFURT",
    CITYNAME_HEB: "ERFURT",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ERH",
    AIRPORTNAME: "Errachidia",
    CITYCODE: "ERH",
    CITYNAME: "ERRACHIDIA",
    CITYNAME_HEB: "ERRACHIDIA",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "ALH",
    AIRPORTNAME: "Albany",
    CITYCODE: "ALH",
    CITYNAME: "ALBANY",
    CITYNAME_HEB: "ALBANY",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ERI",
    AIRPORTNAME: "International",
    CITYCODE: "ERI",
    CITYNAME: "ERIE",
    CITYNAME_HEB: "ERIE",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ERM",
    AIRPORTNAME: null,
    CITYCODE: "ERM",
    CITYNAME: "ERECHIM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ERN",
    AIRPORTNAME: null,
    CITYCODE: "ERN",
    CITYNAME: "EIRUNEPE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ERO",
    AIRPORTNAME: "Coast Guard",
    CITYCODE: "ERO",
    CITYNAME: "ELDRED ROCK",
    CITYNAME_HEB: "Coast Guard",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ERR",
    AIRPORTNAME: "Errol",
    CITYCODE: "ERR",
    CITYNAME: "ERROL",
    CITYNAME_HEB: "ERROL",
    COUNTRYCODE: "USNH",
    COUNTRYNAME: "NEW HAMPSHIRE",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d4\u05de\u05e4\u05e9\u05d9\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ERT",
    AIRPORTNAME: "Erdenet",
    CITYCODE: "ERT",
    CITYNAME: "ERDENET",
    CITYNAME_HEB: "ERDENET",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ERU",
    AIRPORTNAME: "Erume",
    CITYCODE: "ERU",
    CITYNAME: "ERUME",
    CITYNAME_HEB: "ERUME",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ERV",
    AIRPORTNAME: "Kerrville",
    CITYCODE: "ERV",
    CITYNAME: "KERRVILLE",
    CITYNAME_HEB: "KERRVILLE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ERZ",
    AIRPORTNAME: "Erzurum",
    CITYCODE: "ERZ",
    CITYNAME: "ERZURUM",
    CITYNAME_HEB: "ERZURUM",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ESA",
    AIRPORTNAME: "Esa'Ala",
    CITYCODE: "ESA",
    CITYNAME: "ESA'ALA",
    CITYNAME_HEB: "ESA'ALA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ALI",
    AIRPORTNAME: "Alice International Airport",
    CITYCODE: "ALI",
    CITYNAME: "ALICE",
    CITYNAME_HEB: "ALICE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ESC",
    AIRPORTNAME: "Delta County Airport",
    CITYCODE: "ESC",
    CITYNAME: "ESCANABA",
    CITYNAME_HEB: "ESCANABA",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ESD",
    AIRPORTNAME: "Orcas Island",
    CITYCODE: "ESD",
    CITYNAME: "EASTSOUND",
    CITYNAME_HEB: "EASTSOUND",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ESE",
    AIRPORTNAME: "Ensenada",
    CITYCODE: "ESE",
    CITYNAME: "ENSENADA",
    CITYNAME_HEB: "ENSENADA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "ESG",
    AIRPORTNAME: "Mariscal Estigarribia",
    CITYCODE: "ESG",
    CITYNAME: "MARISCAL ESTIGARR",
    CITYNAME_HEB: "MARISCAL ESTIGARR",
    COUNTRYCODE: "PY",
    COUNTRYNAME: "PARAGUAY",
    COUNTRYNAME_HEB: "\u05e4\u05d0\u05e8\u05d0\u05d2\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "ESH",
    AIRPORTNAME: "Shoreham",
    CITYCODE: "ESH",
    CITYNAME: "SHOREHAM BY SEA",
    CITYNAME_HEB: "SHOREHAM BY SEA",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ESI",
    AIRPORTNAME: null,
    CITYCODE: "ESI",
    CITYNAME: "ESPINOSA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AOE",
    AIRPORTNAME: "Anadolu University",
    CITYCODE: "ESK",
    CITYNAME: "ESKISEHIR",
    CITYNAME_HEB: "ESKISEHIR",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ESK",
    AIRPORTNAME: "Eskisehir",
    CITYCODE: "ESK",
    CITYNAME: "ESKISEHIR",
    CITYNAME_HEB: "ESKISEHIR",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ESL",
    AIRPORTNAME: "Elista",
    CITYCODE: "ESL",
    CITYNAME: "ELISTA",
    CITYNAME_HEB: "ELISTA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ESM",
    AIRPORTNAME: "Esmeraldas",
    CITYCODE: "ESM",
    CITYNAME: "ESMERALDAS",
    CITYNAME_HEB: "ESMERALDAS",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "ABA",
    AIRPORTNAME: "Abakan",
    CITYCODE: "ABA",
    CITYNAME: "ABAKAN",
    CITYNAME_HEB: "ABAKAN",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ALJ",
    AIRPORTNAME: "Kortdoorn",
    CITYCODE: "ALJ",
    CITYNAME: "ALEXANDER BAY",
    CITYNAME_HEB: "ALEXANDER BAY",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "ESN",
    AIRPORTNAME: "Easton",
    CITYCODE: "ESN",
    CITYNAME: "EASTON",
    CITYNAME_HEB: "EASTON",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ESO",
    AIRPORTNAME: "Espanola",
    CITYCODE: "ESO",
    CITYNAME: "ESPANOLA",
    CITYNAME_HEB: "ESPANOLA",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ESP",
    AIRPORTNAME: " ",
    CITYCODE: "ESP",
    CITYNAME: "SPAIN",
    CITYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "ESR",
    AIRPORTNAME: "El Salvador",
    CITYCODE: "ESR",
    CITYNAME: "EL SALVADOR",
    CITYNAME_HEB: "EL SALVADOR",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "ESS",
    AIRPORTNAME: "Essen",
    CITYCODE: "ESS",
    CITYNAME: "ESSEN",
    CITYNAME_HEB: "\u05d0\u05e1\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EST",
    AIRPORTNAME: " ",
    CITYCODE: "EST",
    CITYNAME: "ESTONIA",
    CITYNAME_HEB: "\u05d0\u05e1\u05d8\u05d5\u05e0\u05d9\u05d4",
    COUNTRYCODE: "EE",
    COUNTRYNAME: "ESTONIA",
    COUNTRYNAME_HEB: "\u05d0\u05e1\u05d8\u05d5\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ESU",
    AIRPORTNAME: "Essaouira",
    CITYCODE: "ESU",
    CITYNAME: "ESSAOUIRA",
    CITYNAME_HEB: "ESSAOUIRA",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "ESW",
    AIRPORTNAME: "State",
    CITYCODE: "ESW",
    CITYNAME: "EASTON",
    CITYNAME_HEB: "EASTON",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ETB",
    AIRPORTNAME: "West Bend",
    CITYCODE: "ETB",
    CITYNAME: "WEST BEND",
    CITYNAME_HEB: "WEST BEND",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ETD",
    AIRPORTNAME: "Etadunna",
    CITYCODE: "ETD",
    CITYNAME: "ETADUNNA",
    CITYNAME_HEB: "ETADUNNA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ALK",
    AIRPORTNAME: " ",
    CITYCODE: "ALK",
    CITYNAME: "ALASKA",
    CITYNAME_HEB: "\u05d0\u05dc\u05e1\u05e7\u05d4",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "ETE",
    AIRPORTNAME: "Genda Wuha",
    CITYCODE: "ETE",
    CITYNAME: "GENDA WUHA",
    CITYNAME_HEB: "GENDA WUHA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ETM",
    AIRPORTNAME: "\u05e8\u05de\u05d5\u05df",
    CITYCODE: "ETH",
    CITYNAME: "EILAT",
    CITYNAME_HEB: "\u05d0\u05d9\u05dc\u05ea",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "ETN",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ETN",
    CITYNAME: "EASTLAND",
    CITYNAME_HEB: "EASTLAND",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ETS",
    AIRPORTNAME: "Enterprise",
    CITYCODE: "ETS",
    CITYNAME: "ENTERPRISE",
    CITYNAME_HEB: "Enterprise",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ETZ",
    AIRPORTNAME: "Metz-Nancy-Lorraine",
    CITYCODE: "ETZ",
    CITYNAME: "METZ NANCY",
    CITYNAME_HEB: "\u05de\u05e5",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "EUA",
    AIRPORTNAME: "Kaufana",
    CITYCODE: "EUA",
    CITYNAME: "EUA",
    CITYNAME_HEB: "EUA",
    COUNTRYCODE: "TO",
    COUNTRYNAME: "TONGA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d2\u05d4",
  },
  {
    AIRPORTCODE: "EUC",
    AIRPORTNAME: "Eucla",
    CITYCODE: "EUC",
    CITYNAME: "EUCLA",
    CITYNAME_HEB: "EUCLA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EUE",
    AIRPORTNAME: "Eureka",
    CITYCODE: "EUE",
    CITYNAME: "EUREKA",
    CITYNAME_HEB: "EUREKA",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EUF",
    AIRPORTNAME: "Weedon Field",
    CITYCODE: "EUF",
    CITYNAME: "EUFAULA",
    CITYNAME_HEB: "Eufaula",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EUG",
    AIRPORTNAME: "Eugene",
    CITYCODE: "EUG",
    CITYNAME: "EUGENE",
    CITYNAME_HEB: "EUGENE",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EUM",
    AIRPORTNAME: "Neumuenster",
    CITYCODE: "EUM",
    CITYNAME: "NEUMUENSTER",
    CITYNAME_HEB: "NEUMUENSTER",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EUN",
    AIRPORTNAME: "Hassan I",
    CITYCODE: "EUN",
    CITYNAME: "LAAYOUNE",
    CITYNAME_HEB: "LAAYOUNE",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "EUO",
    AIRPORTNAME: "Paratebueno",
    CITYCODE: "EUO",
    CITYNAME: "PARATEBUENO",
    CITYNAME_HEB: "PARATEBUENO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EUX",
    AIRPORTNAME: null,
    CITYCODE: "EUX",
    CITYNAME: "SAINT EUSTATIUS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BQ",
    COUNTRYNAME: "BONAIRE, SAINT EUSTATIUS AND SABA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "EVA",
    AIRPORTNAME: "Landing Strip",
    CITYCODE: "EVA",
    CITYNAME: "EVADALE",
    CITYNAME_HEB: "EVADALE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EVD",
    AIRPORTNAME: "Eva Downs",
    CITYCODE: "EVD",
    CITYNAME: "EVA DOWNS",
    CITYNAME_HEB: "EVA DOWNS",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EVE",
    AIRPORTNAME: "Evenes",
    CITYCODE: "EVE",
    CITYNAME: "HARSTAD-NARVIK",
    CITYNAME_HEB: "HARSTAD-NARVIK",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EVG",
    AIRPORTNAME: "Sveg",
    CITYCODE: "EVG",
    CITYNAME: "SVEG",
    CITYNAME_HEB: "SVEG",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EVH",
    AIRPORTNAME: "Evans Head",
    CITYCODE: "EVH",
    CITYNAME: "EVANS HEAD",
    CITYNAME_HEB: "EVANS HEAD",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EVM",
    AIRPORTNAME: "Eveleth",
    CITYCODE: "EVM",
    CITYNAME: "EVELETH",
    CITYNAME_HEB: "EVELETH",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ALM",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ALM",
    CITYNAME: "ALAMOGORDO",
    CITYNAME_HEB: "ALAMOGORDO",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EVN",
    AIRPORTNAME: "Yerevan",
    CITYCODE: "EVN",
    CITYNAME: "YEREVAN",
    CITYNAME_HEB: "\u05d9\u05e8\u05d1\u05d0\u05df",
    COUNTRYCODE: "AM",
    COUNTRYNAME: "ARMENIA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EVV",
    AIRPORTNAME: "Dress Regional",
    CITYCODE: "EVV",
    CITYNAME: "EVANSVILLE",
    CITYNAME_HEB: "EVANSVILLE",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EVW",
    AIRPORTNAME: "Evanston",
    CITYCODE: "EVW",
    CITYNAME: "EVANSTON",
    CITYNAME_HEB: "EVANSTON",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EVX",
    AIRPORTNAME: "Evreux",
    CITYCODE: "EVX",
    CITYNAME: "EVREUX",
    CITYNAME_HEB: "EVREUX",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "EWB",
    AIRPORTNAME: "New Bedford",
    CITYCODE: "EWB",
    CITYNAME: "NEW BEDFORD",
    CITYNAME_HEB: "NEW BEDFORD",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "EWE",
    AIRPORTNAME: "Ewer",
    CITYCODE: "EWE",
    CITYNAME: "EWER",
    CITYNAME_HEB: "EWER",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EWI",
    AIRPORTNAME: "Enarotali",
    CITYCODE: "EWI",
    CITYNAME: "ENAROTALI",
    CITYNAME_HEB: "ENAROTALI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EWK",
    AIRPORTNAME: "City-County",
    CITYCODE: "EWK",
    CITYNAME: "NEWTON",
    CITYNAME_HEB: "NEWTON",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EWN",
    AIRPORTNAME: "Simmons Nott",
    CITYCODE: "EWN",
    CITYNAME: "NEW BERN",
    CITYNAME_HEB: "NEW BERN",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EWO",
    AIRPORTNAME: "Ewo",
    CITYCODE: "EWO",
    CITYNAME: "EWO",
    CITYNAME_HEB: "EWO",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "HMN",
    AIRPORTNAME: null,
    CITYCODE: "ALM",
    CITYNAME: "ALAMOGORDO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EWY",
    AIRPORTNAME: "Greenham RAF",
    CITYCODE: "EWY",
    CITYNAME: "NEWBURY",
    CITYNAME_HEB: "NEWBURY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EXI",
    AIRPORTNAME: "Excursion Inlet",
    CITYCODE: "EXI",
    CITYNAME: "EXCURSION INLET",
    CITYNAME_HEB: "Excursion Inlet",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EXM",
    AIRPORTNAME: "Exmouth Gulf",
    CITYCODE: "EXM",
    CITYNAME: "EXMOUTH GULF",
    CITYNAME_HEB: "EXMOUTH GULF",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EXT",
    AIRPORTNAME: "Exeter",
    CITYCODE: "EXT",
    CITYNAME: "EXETER",
    CITYNAME_HEB: "EXETER",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EYL",
    AIRPORTNAME: "Yelimane",
    CITYCODE: "EYL",
    CITYNAME: "YELIMANE",
    CITYNAME_HEB: "YELIMANE",
    COUNTRYCODE: "ML",
    COUNTRYNAME: "MALI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d9",
  },
  {
    AIRPORTCODE: "EYP",
    AIRPORTNAME: "El Yopal",
    CITYCODE: "EYP",
    CITYNAME: "EL YOPAL",
    CITYNAME_HEB: "EL YOPAL",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EYR",
    AIRPORTNAME: "Yerington",
    CITYCODE: "EYR",
    CITYNAME: "YERINGTON",
    CITYNAME_HEB: "YERINGTON",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EYS",
    AIRPORTNAME: "Eliye Springs",
    CITYCODE: "EYS",
    CITYNAME: "ELIYE SPRINGS",
    CITYNAME_HEB: "ELIYE SPRINGS",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EYW",
    AIRPORTNAME: "International",
    CITYCODE: "EYW",
    CITYNAME: "KEY WEST",
    CITYNAME_HEB: "\u05e7\u05d9 \u05d5\u05d5\u05e1\u05d8",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NQX",
    AIRPORTNAME: null,
    CITYCODE: "EYW",
    CITYNAME: "KEY WEST",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ALN",
    AIRPORTNAME: " ",
    CITYCODE: "ALN",
    CITYNAME: "ALBANIA",
    CITYNAME_HEB: "\u05d0\u05dc\u05d1\u05e0\u05d9\u05d4",
    COUNTRYCODE: "AL",
    COUNTRYNAME: "ALBANIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EZS",
    AIRPORTNAME: "Elazig",
    CITYCODE: "EZS",
    CITYNAME: "ELAZIG",
    CITYNAME_HEB: "ELAZIG",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FAA",
    AIRPORTNAME: "Faranah",
    CITYCODE: "FAA",
    CITYNAME: "FARANAH",
    CITYNAME_HEB: "FARANAH",
    COUNTRYCODE: "GN",
    COUNTRYNAME: "GUINEA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "FAB",
    AIRPORTNAME: "Farnborough",
    CITYCODE: "FAB",
    CITYNAME: "FARNBOROUGH",
    CITYNAME_HEB: "FARNBOROUGH",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FAC",
    AIRPORTNAME: "Faaite",
    CITYCODE: "FAC",
    CITYNAME: "FAAITE",
    CITYNAME_HEB: "FAAITE",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "FAE",
    AIRPORTNAME: "Vagar",
    CITYCODE: "FAE",
    CITYNAME: "FAROE ISLANDS",
    CITYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d0\u05e8\u05d5",
    COUNTRYCODE: "FO",
    COUNTRYNAME: "FAROE ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d0\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "FAF",
    AIRPORTNAME: "Felker AAF",
    CITYCODE: "FAF",
    CITYNAME: "FORT EUSTIS",
    CITYNAME_HEB: "FORT EUSTIS",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "FAG",
    AIRPORTNAME: "Fagurholsmyri",
    CITYCODE: "FAG",
    CITYNAME: "FAGURHOLSMYRI",
    CITYNAME_HEB: "FAGURHOLSMYRI",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "FAH",
    AIRPORTNAME: null,
    CITYCODE: "FAH",
    CITYNAME: "FARAH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FAI",
    AIRPORTNAME: "Fairbanks International",
    CITYCODE: "FAI",
    CITYNAME: "FAIRBANKS ALASKA",
    CITYNAME_HEB:
      "\u05e4\u05d9\u05d9\u05e8\u05d1\u05e0\u05e7\u05e1 - \u05d0\u05dc\u05e1\u05e7\u05d4",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "ALO",
    AIRPORTNAME: "Waterloo",
    CITYCODE: "ALO",
    CITYNAME: "WATERLOO",
    CITYNAME_HEB: "WATERLOO",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FAJ",
    AIRPORTNAME: "Fajardo",
    CITYCODE: "FAJ",
    CITYNAME: "FAJARDO",
    CITYNAME_HEB: "FAJARDO",
    COUNTRYCODE: "PR",
    COUNTRYNAME: "PUERTO RICO",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e8\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "FAK",
    AIRPORTNAME: "False Island",
    CITYCODE: "FAK",
    CITYNAME: "FALSE ISLAND",
    CITYNAME_HEB: "False Island",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FAL",
    AIRPORTNAME: "Falcon State",
    CITYCODE: "FAL",
    CITYNAME: "ROMA",
    CITYNAME_HEB: "ROMA (US)",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FAM",
    AIRPORTNAME: " ",
    CITYCODE: "FAM",
    CITYNAME: "FAMAGUSTA/TURKISH SIDE",
    CITYNAME_HEB:
      "\u05e4\u05de\u05d2\u05d5\u05e1\u05d8\u05d4/\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df \u05d4\u05ea\u05d5\u05e8\u05db\u05d9\u05ea",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "FAN",
    AIRPORTNAME: "Lista",
    CITYCODE: "FAN",
    CITYNAME: "FARSUND",
    CITYNAME_HEB: "FARSUND",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FAO",
    AIRPORTNAME: "Aeroporto Internacional de Faro",
    CITYCODE: "FAO",
    CITYNAME: "FARO",
    CITYNAME_HEB: "\u05e4\u05d0\u05e8\u05d5",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "FAQ",
    AIRPORTNAME: "Freida River",
    CITYCODE: "FAQ",
    CITYNAME: "FREIDA RIVER",
    CITYNAME_HEB: "FREIDA RIVER",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ALQ",
    AIRPORTNAME: null,
    CITYCODE: "ALQ",
    CITYNAME: "ALEGRETE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FAR",
    AIRPORTNAME: "Hector Field",
    CITYCODE: "FAR",
    CITYNAME: "FARGO",
    CITYNAME_HEB: "FARGO",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "FAS",
    CITYNAME: "VAL DE FASSA",
    CITYNAME_HEB: "\u05d5\u05d0\u05dc \u05d3\u05d9 \u05e4\u05d0\u05e1\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FAT",
    AIRPORTNAME: "Airterminal",
    CITYCODE: "FAT",
    CITYNAME: "FRESNO",
    CITYNAME_HEB: "\u05e4\u05e8\u05e0\u05e1\u05d5",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FCH",
    AIRPORTNAME: null,
    CITYCODE: "FAT",
    CITYNAME: "FRESNO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FAV",
    AIRPORTNAME: "Fakarava",
    CITYCODE: "FAV",
    CITYNAME: "FAKARAVA",
    CITYNAME_HEB: "FAKARAVA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "FAY",
    AIRPORTNAME: " ",
    CITYCODE: "FAY",
    CITYNAME: "FAYENCE",
    CITYNAME_HEB: "\u05e4\u05d9\u05d0\u05e0\u05e1",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "FBD",
    AIRPORTNAME: null,
    CITYCODE: "FBD",
    CITYNAME: "FAIZABAD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FBE",
    AIRPORTNAME: null,
    CITYCODE: "FBE",
    CITYNAME: "FRANCISCO BELTRAO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FBG",
    AIRPORTNAME: "Simmons AAF",
    CITYCODE: "FBG",
    CITYNAME: "FORT BRAGG",
    CITYNAME_HEB: "FORT BRAGG",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ALR",
    AIRPORTNAME: " ",
    CITYCODE: "ALR",
    CITYNAME: "AZAY LE RIDEAU",
    CITYNAME_HEB:
      "\u05d0\u05d6\u05d0\u05d9 \u05dc\u05d4 \u05e8\u05d9\u05d3\u05d5\u05d0\u05d4",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "FBL",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FBL",
    CITYNAME: "FARIBAULT",
    CITYNAME_HEB: "FARIBAULT",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FBM",
    AIRPORTNAME: "Luano",
    CITYCODE: "FBM",
    CITYNAME: "LUBUMBASHI",
    CITYNAME_HEB: "LUBUMBASHI",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "FBR",
    AIRPORTNAME: "Fort Bridger",
    CITYCODE: "FBR",
    CITYNAME: "FORT BRIDGER",
    CITYNAME_HEB: "FORT BRIDGER",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FBS",
    AIRPORTNAME: "Friday Harbor SPB",
    CITYCODE: "FBS",
    CITYNAME: "FRIDAY HARBOR SPB",
    CITYNAME_HEB: "FRIDAY HARBOR SPB",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FBY",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FBY",
    CITYNAME: "FAIRBURY",
    CITYNAME_HEB: "FAIRBURY",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FCA",
    AIRPORTNAME: "Glacier National Park",
    CITYCODE: "FCA",
    CITYNAME: "KALISPELL",
    CITYNAME_HEB: "KALISPELL",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FCB",
    AIRPORTNAME: "Ficksburg Sentra Oes",
    CITYCODE: "FCB",
    CITYNAME: "FICKSBURG",
    CITYNAME_HEB: "FICKSBURG",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "FCY",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FCY",
    CITYNAME: "FORREST CITY",
    CITYNAME_HEB: "FORREST CITY",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FDE",
    AIRPORTNAME: "Bringeland",
    CITYCODE: "FDE",
    CITYNAME: "FORDE",
    CITYNAME_HEB: "FORDE",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FDF",
    AIRPORTNAME: "Lamentin",
    CITYCODE: "FDF",
    CITYNAME: "FORT DE FRANCE",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05e8\u05d8 \u05d3\u05d4 \u05e4\u05e8\u05d0\u05e0\u05e1",
    COUNTRYCODE: "MQ",
    COUNTRYNAME: "MARTINIQUE",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d8\u05d9\u05e0\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "ALS",
    AIRPORTNAME: " ",
    CITYCODE: "ALS",
    CITYNAME: "ALSACE",
    CITYNAME_HEB: "\u05d7\u05d1\u05dc \u05d0\u05dc\u05d6\u05e1",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "FDH",
    AIRPORTNAME: "Friedrichshafen",
    CITYCODE: "FDH",
    CITYNAME: "FRIEDRICHSHAFEN",
    CITYNAME_HEB: "FRIEDRICHSHAFEN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FDK",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FDK",
    CITYNAME: "FREDERICK",
    CITYNAME_HEB: "FREDERICK",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FDR",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FDR",
    CITYNAME: "FREDERICK",
    CITYNAME_HEB: "FREDERICK",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FDU",
    AIRPORTNAME: "Bandundu",
    CITYCODE: "FDU",
    CITYNAME: "BANDUNDU",
    CITYNAME_HEB: "BANDUNDU",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "FDY",
    AIRPORTNAME: "Findlay",
    CITYCODE: "FDY",
    CITYNAME: "FINDLAY",
    CITYNAME_HEB: "FINDLAY",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FEA",
    AIRPORTNAME: "Fetlar",
    CITYCODE: "FEA",
    CITYNAME: "FETLAR",
    CITYNAME_HEB: "FETLAR",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FEB",
    AIRPORTNAME: "Sanfebagar",
    CITYCODE: "FEB",
    CITYNAME: "SANFEBAGAR",
    CITYNAME_HEB: "SANFEBAGAR",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "FEC",
    AIRPORTNAME: null,
    CITYCODE: "FEC",
    CITYNAME: "FEIRA DE SANTANA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FEG",
    AIRPORTNAME: "Fergana",
    CITYCODE: "FEG",
    CITYNAME: "FERGANA",
    CITYNAME_HEB: "FERGANA",
    COUNTRYCODE: "UZ",
    COUNTRYNAME: "UZBEKISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d6\u05d1\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "FEJ",
    AIRPORTNAME: null,
    CITYCODE: "FEJ",
    CITYNAME: "FEIJO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ABB",
    AIRPORTNAME: "RAF Station",
    CITYCODE: "ABB",
    CITYNAME: "ABINGDON",
    CITYNAME_HEB: "ABINGDON",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "ALT",
    CITYNAME: "ALUSHTA",
    CITYNAME_HEB: "\u05d0\u05dc\u05d5\u05e9\u05d8\u05d4",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "FEK",
    AIRPORTNAME: "Ferkessedougou",
    CITYCODE: "FEK",
    CITYNAME: "FERKESSEDOUGOU",
    CITYNAME_HEB: "FERKESSEDOUGOU",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "FEL",
    AIRPORTNAME: "Fuerstenfeldbruck",
    CITYCODE: "FEL",
    CITYNAME: "FUERSTENFELDBRUCK",
    CITYNAME_HEB: "FUERSTENFELDBRUCK",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FEN",
    AIRPORTNAME: null,
    CITYCODE: "FEN",
    CITYNAME: "FERNANDO DE NORON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FEP",
    AIRPORTNAME: "Albertus",
    CITYCODE: "FEP",
    CITYNAME: "FREEPORT",
    CITYNAME_HEB: "FREEPORT",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FER",
    AIRPORTNAME: "Fergusons Gulf",
    CITYCODE: "FER",
    CITYNAME: "FERGUSONS GULF",
    CITYNAME_HEB: "FERGUSONS GULF",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FET",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FET",
    CITYNAME: "FREMONT",
    CITYNAME_HEB: "\u05e4\u05e8\u05de\u05d5\u05e0\u05d8",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FEZ",
    AIRPORTNAME: "Sais",
    CITYCODE: "FEZ",
    CITYNAME: "FEZ",
    CITYNAME_HEB: "\u05e4\u05d6",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "FFA",
    AIRPORTNAME: "First Flight",
    CITYCODE: "FFA",
    CITYNAME: "KILL DEVIL HILLS",
    CITYNAME_HEB: "KILL DEVIL HILLS",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FFD",
    AIRPORTNAME: "RAF Station",
    CITYCODE: "FFD",
    CITYNAME: "FAIRFORD",
    CITYNAME_HEB: "FAIRFORD",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FFL",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FFL",
    CITYNAME: "FAIRFIELD",
    CITYNAME_HEB: "FAIRFIELD",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "ALU",
    CITYNAME: "ALLEGHE-CIVETTA/DOLOMITY",
    CITYNAME_HEB:
      "\u05d0\u05dc\u05d2\u05d7\u05d4-\u05d3\u05d5\u05dc\u05d5\u05de\u05d9\u05d8\u05d9\u05dd",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FFM",
    AIRPORTNAME: "Fergus Falls",
    CITYCODE: "FFM",
    CITYNAME: "FERGUS FALLS",
    CITYNAME_HEB: "FERGUS FALLS",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FFT",
    AIRPORTNAME: "Capital City",
    CITYCODE: "FFT",
    CITYNAME: "FRANKFORT",
    CITYNAME_HEB: "FRANKFORT (US)",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FFU",
    AIRPORTNAME: "Futaleufu",
    CITYCODE: "FFU",
    CITYNAME: "FUTALEUFU",
    CITYNAME_HEB: "FUTALEUFU",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "FGD",
    AIRPORTNAME: "Fderik",
    CITYCODE: "FGD",
    CITYNAME: "FDERIK",
    CITYNAME_HEB: "FDERIK",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FGL",
    AIRPORTNAME: "Fox Glacier",
    CITYCODE: "FGL",
    CITYNAME: "FOX GLACIER",
    CITYNAME_HEB: "FOX GLACIER",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "FGU",
    AIRPORTNAME: "Fangatau",
    CITYCODE: "FGU",
    CITYNAME: "FANGATAU",
    CITYNAME_HEB: "FANGATAU",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "FHU",
    AIRPORTNAME: "Fort Huachu Municipal Airport",
    CITYCODE: "FHU",
    CITYNAME: "FORT HUACHUCA",
    CITYNAME_HEB: "FORT HUACHUCA",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FHZ",
    AIRPORTNAME: "Fakahina",
    CITYCODE: "FHZ",
    CITYNAME: "FAKAHINA",
    CITYNAME_HEB: "FAKAHINA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "FIC",
    AIRPORTNAME: "Fire Cove",
    CITYCODE: "FIC",
    CITYNAME: "FIRE COVE",
    CITYNAME_HEB: "Fire Cove",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FID",
    AIRPORTNAME: "Elizabeth Field",
    CITYCODE: "FID",
    CITYNAME: "FISHERS ISLAND",
    CITYNAME_HEB: "FISHERS ISLAND",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ALV",
    AIRPORTNAME: "Andorra La Vella",
    CITYCODE: "ALV",
    CITYNAME: "ANDORRA LA VELLA",
    CITYNAME_HEB: "\u05d0\u05e0\u05d3\u05d5\u05e8\u05d4",
    COUNTRYCODE: "AD",
    COUNTRYNAME: "ANDORRA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d3\u05d5\u05e8\u05d4",
  },
  {
    AIRPORTCODE: "FIE",
    AIRPORTNAME: "Fair Isle",
    CITYCODE: "FIE",
    CITYNAME: "FAIR ISLE",
    CITYNAME_HEB: "FAIR ISLE",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FIG",
    AIRPORTNAME: "Fria",
    CITYCODE: "FIG",
    CITYNAME: "FRIA",
    CITYNAME_HEB: "FRIA",
    COUNTRYCODE: "GN",
    COUNTRYNAME: "GUINEA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "FIH",
    AIRPORTNAME: "N",
    CITYCODE: "FIH",
    CITYNAME: "KINSHASA",
    CITYNAME_HEB: "\u05e7\u05d9\u05e0\u05e9\u05e1\u05d4",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "NLO",
    AIRPORTNAME: "N",
    CITYCODE: "FIH",
    CITYNAME: "KINSHASA",
    CITYNAME_HEB: "\u05e7\u05d9\u05e0\u05e9\u05e1\u05d4",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "FIK",
    AIRPORTNAME: "Finke",
    CITYCODE: "FIK",
    CITYNAME: "FINKE",
    CITYNAME_HEB: "FINKE",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FIL",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FIL",
    CITYNAME: "FILLMORE",
    CITYNAME_HEB: "FILLMORE",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FIN",
    AIRPORTNAME: " ",
    CITYCODE: "FIN",
    CITYNAME: "FINLAND",
    CITYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "FIV",
    AIRPORTNAME: "Five Finger",
    CITYCODE: "FIV",
    CITYNAME: "FIVE FINGER",
    CITYNAME_HEB: "Five Finger",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FIZ",
    AIRPORTNAME: "Fitzroy Crossing",
    CITYCODE: "FIZ",
    CITYNAME: "FITZROY CROSSING",
    CITYNAME_HEB: "FITZROY CROSSING",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FJR",
    AIRPORTNAME: null,
    CITYCODE: "FJR",
    CITYNAME: "AL FUJAIRAH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AE",
    COUNTRYNAME: "UNITED ARAB EMIRATES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ALW",
    AIRPORTNAME: " ",
    CITYCODE: "ALW",
    CITYNAME: "ALTENSTEIG-WART",
    CITYNAME_HEB:
      "\u05d0\u05dc\u05d8\u05e0\u05e9\u05d8\u05d9\u05d9\u05d2 \u05d5\u05d5\u05e8\u05d8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FKB",
    AIRPORTNAME: "Soellingen",
    CITYCODE: "FKB",
    CITYNAME: "KARLSRUHE",
    CITYNAME_HEB: "\u05e7\u05d0\u05e8\u05dc\u05e1\u05d5\u05d0\u05d4",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XET",
    AIRPORTNAME: " ",
    CITYCODE: "FKB",
    CITYNAME: "KARLSRUHE",
    CITYNAME_HEB: "\u05e7\u05d0\u05e8\u05dc\u05e1\u05d5\u05d0\u05d4",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZCC",
    AIRPORTNAME: " ",
    CITYCODE: "FKB",
    CITYNAME: "KARLSRUHE",
    CITYNAME_HEB: "\u05e7\u05d0\u05e8\u05dc\u05e1\u05d5\u05d0\u05d4",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FKH",
    AIRPORTNAME: "Sculthorp RAF",
    CITYCODE: "FKH",
    CITYNAME: "FAKENHAM",
    CITYNAME_HEB: "FAKENHAM",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FKI",
    AIRPORTNAME: "Kisangani",
    CITYCODE: "FKI",
    CITYNAME: "KISANGANI",
    CITYNAME_HEB: "KISANGANI",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "FKJ",
    AIRPORTNAME: "Fukui",
    CITYCODE: "FKJ",
    CITYNAME: "FUKUI",
    CITYNAME_HEB: "FUKUI",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "FKL",
    AIRPORTNAME: "Chess-Lambertin",
    CITYCODE: "FKL",
    CITYNAME: "FRANKLIN",
    CITYNAME_HEB: "FRANKLIN",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FKN",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FKN",
    CITYNAME: "FRANKLIN",
    CITYNAME_HEB: "FRANKLIN",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "FKQ",
    AIRPORTNAME: "Fak Fak",
    CITYCODE: "FKQ",
    CITYNAME: "FAK FAK",
    CITYNAME_HEB: "FAK FAK",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FKS",
    AIRPORTNAME: "Fukushima Airport",
    CITYCODE: "FKS",
    CITYNAME: "FUKUSHIMA",
    CITYNAME_HEB: "FUKUSHIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "ALX",
    AIRPORTNAME: "Thomas C. Russell Field",
    CITYCODE: "ALX",
    CITYNAME: "ALEXANDER CITY",
    CITYNAME_HEB: "Alexander City",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FLA",
    AIRPORTNAME: "Capitolio",
    CITYCODE: "FLA",
    CITYNAME: "FLORENCIA",
    CITYNAME_HEB: "FLORENCIA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FLB",
    AIRPORTNAME: null,
    CITYCODE: "FLB",
    CITYNAME: "FLORIANO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FLC",
    AIRPORTNAME: " ",
    CITYCODE: "FLC",
    CITYNAME: "FLACHAU",
    CITYNAME_HEB: "\u05e4\u05dc\u05db\u05d0\u05d5",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FLG",
    AIRPORTNAME: null,
    CITYCODE: "GCN",
    CITYNAME: "GRAND CANYON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JGC",
    AIRPORTNAME: null,
    CITYCODE: "GCN",
    CITYNAME: "GRAND CANYON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NGC",
    AIRPORTNAME: null,
    CITYCODE: "GCN",
    CITYNAME: "GRAND CANYON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GCV",
    AIRPORTNAME: null,
    CITYCODE: "GCV",
    CITYNAME: "GRAVATAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AMR",
    AIRPORTNAME: " ",
    CITYCODE: "AMR",
    CITYNAME: "AMMERSCHWIHR",
    CITYNAME_HEB: "\u05d0\u05de\u05e8\u05e9\u05d5\u05d5\u05d9\u05e8",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "GCY",
    AIRPORTNAME: "Municipal",
    CITYCODE: "GCY",
    CITYNAME: "GREENVILLE",
    CITYNAME_HEB: "GREENVILLE",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GDA",
    AIRPORTNAME: null,
    CITYCODE: "GDA",
    CITYNAME: "GOUNDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GDD",
    AIRPORTNAME: "Gordon Downs",
    CITYCODE: "GDD",
    CITYNAME: "GORDON DOWNS",
    CITYNAME_HEB: "GORDON DOWNS",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GDE",
    AIRPORTNAME: "Gode/Iddidole",
    CITYCODE: "GDE",
    CITYNAME: "GODE IDDIDOLE",
    CITYNAME_HEB: "GODE IDDIDOLE",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GDG",
    AIRPORTNAME: "Magdagachi",
    CITYCODE: "GDG",
    CITYNAME: "MAGDAGACHI",
    CITYNAME_HEB: "MAGDAGACHI",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GDH",
    AIRPORTNAME: "Golden Horn",
    CITYCODE: "GDH",
    CITYNAME: "GOLDEN HORN",
    CITYNAME_HEB: "Golden Horn",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GDI",
    AIRPORTNAME: null,
    CITYCODE: "GDI",
    CITYNAME: "GORDIL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GDJ",
    AIRPORTNAME: "Gandajika",
    CITYCODE: "GDJ",
    CITYNAME: "GANDAJIKA",
    CITYNAME_HEB: "GANDAJIKA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "GDL",
    AIRPORTNAME: "Miguel Hidal",
    CITYCODE: "GDL",
    CITYNAME: "GUADALAJARA",
    CITYNAME_HEB: "\u05d2\u05d5\u05d5\u05d3\u05dc\u05d0\u05d7\u05e8\u05d4",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "GDM",
    AIRPORTNAME: "Municipal",
    CITYCODE: "GDM",
    CITYNAME: "GARDNER",
    CITYNAME_HEB: "GARDNER",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "AMS",
    AIRPORTNAME: "Schiphol",
    CITYCODE: "AMS",
    CITYNAME: "AMSTERDAM",
    CITYNAME_HEB: "\u05d0\u05de\u05e1\u05d8\u05e8\u05d3\u05dd",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "GDN",
    AIRPORTNAME: "Rebiechowo",
    CITYCODE: "GDN",
    CITYNAME: "GDANSK",
    CITYNAME_HEB: "\u05d2\u05d3\u05e0\u05e1\u05e7",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "GDO",
    AIRPORTNAME: "Vare Maria",
    CITYCODE: "GDO",
    CITYNAME: "GUASDUALITO",
    CITYNAME_HEB: "GUASDUALITO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "GDP",
    AIRPORTNAME: null,
    CITYCODE: "GDP",
    CITYNAME: "GUADALUPE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GDQ",
    AIRPORTNAME: "Gondar",
    CITYCODE: "GDQ",
    CITYNAME: "GONDAR",
    CITYNAME_HEB: "\u05d2\u05d5\u05e0\u05d3\u05e8",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GDT",
    AIRPORTNAME: null,
    CITYCODE: "GDT",
    CITYNAME: "GRAND TURK IS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TC",
    COUNTRYNAME: "TURKS AND CAICOS ISLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GDV",
    AIRPORTNAME: "Dawson Community",
    CITYCODE: "GDV",
    CITYNAME: "GLENDIVE",
    CITYNAME_HEB: "GLENDIVE",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GDW",
    AIRPORTNAME: "Gladwin",
    CITYCODE: "GDW",
    CITYNAME: "GLADWIN",
    CITYNAME_HEB: "GLADWIN",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GDX",
    AIRPORTNAME: "Magadan",
    CITYCODE: "GDX",
    CITYNAME: "MAGADAN",
    CITYNAME_HEB: "MAGADAN",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GDZ",
    AIRPORTNAME: "Gelendzik",
    CITYCODE: "GDZ",
    CITYNAME: "GELENDZIK",
    CITYNAME_HEB: "GELENDZIK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GEB",
    AIRPORTNAME: "Gebe",
    CITYCODE: "GEB",
    CITYNAME: "GEBE",
    CITYNAME_HEB: "GEBE",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AMT",
    AIRPORTNAME: "Amata",
    CITYCODE: "AMT",
    CITYNAME: "AMATA",
    CITYNAME_HEB: "AMATA",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GEC",
    AIRPORTNAME: "Gecitkale",
    CITYCODE: "GEC",
    CITYNAME: "GECITKALE",
    CITYNAME_HEB: "\u05d2\u05d8\u05d9\u05e7\u05dc\u05d4",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "GED",
    AIRPORTNAME: "Sussex County",
    CITYCODE: "GED",
    CITYNAME: "GEORGETOWN",
    CITYNAME_HEB: "GEORGETOWN",
    COUNTRYCODE: "USDE",
    COUNTRYNAME: "DELAWARE",
    COUNTRYNAME_HEB:
      '\u05d3\u05dc\u05d5\u05d5\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GEE",
    AIRPORTNAME: null,
    CITYCODE: "GEE",
    CITYNAME: "GEORGE TOWN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUTS",
    COUNTRYNAME: "TASMANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GEF",
    AIRPORTNAME: "Geva Airstrip",
    CITYCODE: "GEF",
    CITYNAME: "GEVA AIRSTRIP",
    CITYNAME_HEB: "GEVA AIRSTRIP",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GEG",
    AIRPORTNAME: "International",
    CITYCODE: "GEG",
    CITYNAME: "SPOKANE",
    CITYNAME_HEB: "SPOKANE",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SFF",
    AIRPORTNAME: null,
    CITYCODE: "GEG",
    CITYNAME: "SPOKANE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SKA",
    AIRPORTNAME: null,
    CITYCODE: "GEG",
    CITYNAME: "SPOKANE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GEI",
    AIRPORTNAME: "Green Islands",
    CITYCODE: "GEI",
    CITYNAME: "GREEN ISLANDS",
    CITYNAME_HEB: "GREEN ISLANDS",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GEK",
    AIRPORTNAME: "Ganes Creek",
    CITYCODE: "GEK",
    CITYNAME: "GANES CREEK",
    CITYNAME_HEB: "Ganes Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GEL",
    AIRPORTNAME: null,
    CITYCODE: "GEL",
    CITYNAME: "SANTO ANGELO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AMU",
    AIRPORTNAME: "Amanab",
    CITYCODE: "AMU",
    CITYNAME: "AMANAB",
    CITYNAME_HEB: "AMANAB",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GEO",
    AIRPORTNAME: " ",
    CITYCODE: "GEO",
    CITYNAME: "GEORGIA",
    CITYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GER",
    AIRPORTNAME: " ",
    CITYCODE: "GER",
    CITYNAME: "GERLITZEN",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: " ",
  },
  {
    AIRPORTCODE: "GES",
    AIRPORTNAME: "Buayan",
    CITYCODE: "GES",
    CITYNAME: "GENERAL SANTOS",
    CITYNAME_HEB: "GENERAL SANTOS",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "GET",
    AIRPORTNAME: "Geraldton",
    CITYCODE: "GET",
    CITYNAME: "GERALDTON",
    CITYNAME_HEB: "GERALDTON",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GEV",
    AIRPORTNAME: "Gallivare",
    CITYCODE: "GEV",
    CITYNAME: "GALLIVARE",
    CITYNAME_HEB: "GALLIVARE",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GEW",
    AIRPORTNAME: "Gewoia",
    CITYCODE: "GEW",
    CITYNAME: "GEWOIA",
    CITYNAME_HEB: "GEWOIA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GEX",
    AIRPORTNAME: "Geelong",
    CITYCODE: "GEX",
    CITYNAME: "GEELONG",
    CITYNAME_HEB: "GEELONG",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GEY",
    AIRPORTNAME: "South Big Horn County",
    CITYCODE: "GEY",
    CITYNAME: "GREYBULL",
    CITYNAME_HEB: "GREYBULL",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GFB",
    AIRPORTNAME: "Togiak Fish",
    CITYCODE: "GFB",
    CITYNAME: "TOGIAK FISH",
    CITYNAME_HEB: "Togiak Fish",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GFD",
    AIRPORTNAME: "Pope Field",
    CITYCODE: "GFD",
    CITYNAME: "GREENFIELD",
    CITYNAME_HEB: "GREENFIELD",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AMV",
    AIRPORTNAME: "Amderma",
    CITYCODE: "AMV",
    CITYNAME: "AMDERMA",
    CITYNAME_HEB: "AMDERMA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GFE",
    AIRPORTNAME: "Grenfell",
    CITYCODE: "GFE",
    CITYNAME: "GRENFELL",
    CITYNAME_HEB: "GRENFELL",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GFF",
    AIRPORTNAME: "Griffith",
    CITYCODE: "GFF",
    CITYNAME: "GRIFFITH",
    CITYNAME_HEB: "GRIFFITH",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GFK",
    AIRPORTNAME: "Grand Forks",
    CITYCODE: "GFK",
    CITYNAME: "GRAND FORKS",
    CITYNAME_HEB: "GRAND FORKS",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GFL",
    AIRPORTNAME: "Warren County",
    CITYCODE: "GFL",
    CITYNAME: "GLENS FALLS",
    CITYNAME_HEB: "GLENS FALLS",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GFN",
    AIRPORTNAME: "Grafton",
    CITYCODE: "GFN",
    CITYNAME: "GRAFTON",
    CITYNAME_HEB: "GRAFTON",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GFO",
    AIRPORTNAME: "Bartica",
    CITYCODE: "GFO",
    CITYNAME: "BARTICA",
    CITYNAME_HEB: "BARTICA",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "GFR",
    AIRPORTNAME: "Granville",
    CITYCODE: "GFR",
    CITYNAME: "GRANVILLE",
    CITYNAME_HEB: "GRANVILLE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "GFY",
    AIRPORTNAME: "Grootfontein",
    CITYCODE: "GFY",
    CITYNAME: "GROOTFONTEIN",
    CITYNAME_HEB: "GROOTFONTEIN",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GGC",
    AIRPORTNAME: "Lumbala",
    CITYCODE: "GGC",
    CITYNAME: "LUMBALA",
    CITYNAME_HEB: "LUMBALA",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "GGD",
    AIRPORTNAME: "Gregory Downs",
    CITYCODE: "GGD",
    CITYNAME: "GREGORY DOWNS",
    CITYNAME_HEB: "GREGORY DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AMW",
    AIRPORTNAME: "Ames",
    CITYCODE: "AMW",
    CITYNAME: "AMES",
    CITYNAME_HEB: "AMES",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GGE",
    AIRPORTNAME: "Georgetown",
    CITYCODE: "GGE",
    CITYNAME: "GEORGETOWN",
    CITYNAME_HEB: "\u05d2'\u05d5\u05e8\u05d2'\u05d8\u05d0\u05d5\u05df",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GGG",
    AIRPORTNAME: "Gregg County Airport",
    CITYCODE: "GGG",
    CITYNAME: "LONGVIEW",
    CITYNAME_HEB: "LONGVIEW",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GGL",
    AIRPORTNAME: "Gilgal",
    CITYCODE: "GGL",
    CITYNAME: "GILGAL",
    CITYNAME_HEB: "GILGAL",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GGN",
    AIRPORTNAME: "Gagnoa",
    CITYCODE: "GGN",
    CITYNAME: "GAGNOA",
    CITYNAME_HEB: "GAGNOA",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "GGO",
    AIRPORTNAME: "Guiglo",
    CITYCODE: "GGO",
    CITYNAME: "GUIGLO",
    CITYNAME_HEB: "GUIGLO",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "GGR",
    AIRPORTNAME: "Garoe",
    CITYCODE: "GGR",
    CITYNAME: "GAROE",
    CITYNAME_HEB: "GAROE",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GGS",
    AIRPORTNAME: null,
    CITYCODE: "GGS",
    CITYNAME: "GDOR  GREGORES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GGT",
    AIRPORTNAME: "Exuma International",
    CITYCODE: "GGT",
    CITYNAME: "GEORGE TOWN",
    CITYNAME_HEB: "GEORGE TOWN",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GGW",
    AIRPORTNAME: "International",
    CITYCODE: "GGW",
    CITYNAME: "GLASGOW",
    CITYNAME_HEB: "GLASGOW (US)",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GHA",
    AIRPORTNAME: "Noumerate",
    CITYCODE: "GHA",
    CITYNAME: "GHARDAIA",
    CITYNAME_HEB: "GHARDAIA",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ABH",
    AIRPORTNAME: null,
    CITYCODE: "ABH",
    CITYNAME: "ALPHA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AMX",
    AIRPORTNAME: "Ammaroo",
    CITYCODE: "AMX",
    CITYNAME: "AMMAROO",
    CITYNAME_HEB: "AMMAROO",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GHB",
    AIRPORTNAME: "Governors Harbour",
    CITYCODE: "GHB",
    CITYNAME: "GOVERNORS HARBOUR",
    CITYNAME_HEB: "GOVERNORS HARBOUR",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GHC",
    AIRPORTNAME: "Great Harbour",
    CITYCODE: "GHC",
    CITYNAME: "GREAT HARBOUR",
    CITYNAME_HEB: "GREAT HARBOUR",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GHD",
    AIRPORTNAME: "Ghimbi",
    CITYCODE: "GHD",
    CITYNAME: "GHIMBI",
    CITYNAME_HEB: "GHIMBI",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GHE",
    AIRPORTNAME: "Garachine",
    CITYCODE: "GHE",
    CITYNAME: "GARACHINE",
    CITYNAME_HEB: "GARACHINE",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GHF",
    AIRPORTNAME: "Giebelstadt",
    CITYCODE: "GHF",
    CITYNAME: "GIEBELSTADT",
    CITYNAME_HEB: "GIEBELSTADT",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GHK",
    AIRPORTNAME: null,
    CITYCODE: "GHK",
    CITYNAME: "GUSH KATIF",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GHM",
    AIRPORTNAME: "Municipal",
    CITYCODE: "GHM",
    CITYNAME: "CENTERVILLE",
    CITYNAME_HEB: "CENTERVILLE",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GHN",
    AIRPORTNAME: "Guanghan",
    CITYCODE: "GHN",
    CITYNAME: "GUANGHAN",
    CITYNAME_HEB: "GUANGHAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "GHR",
    CITYNAME: "GOHREN LEBBIN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GHT",
    AIRPORTNAME: "Ghat",
    CITYCODE: "GHT",
    CITYNAME: "GHAT",
    CITYNAME_HEB: "GHAT",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "AMY",
    AIRPORTNAME: "Ambatomainty",
    CITYCODE: "AMY",
    CITYNAME: "AMBATOMAINTY",
    CITYNAME_HEB: "AMBATOMAINTY",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "GHU",
    AIRPORTNAME: null,
    CITYCODE: "GHU",
    CITYNAME: "GUALEGUAYCHU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GIB",
    AIRPORTNAME: "North Front",
    CITYCODE: "GIB",
    CITYNAME: "GIBRALTAR",
    CITYNAME_HEB: "\u05d2\u05d9\u05d1\u05e8\u05dc\u05d8\u05e8",
    COUNTRYCODE: "GI",
    COUNTRYNAME: "GIBRALTAR",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d1\u05e8\u05dc\u05d8\u05e8",
  },
  {
    AIRPORTCODE: "GIC",
    AIRPORTNAME: "Boigu Island",
    CITYCODE: "GIC",
    CITYNAME: "BOIGU ISLAND",
    CITYNAME_HEB: "BOIGU ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GID",
    AIRPORTNAME: "Gitega",
    CITYCODE: "GID",
    CITYNAME: "GITEGA",
    CITYNAME_HEB: "GITEGA",
    COUNTRYCODE: "BI",
    COUNTRYNAME: "BURUNDI",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05d5\u05e0\u05d3\u05d9",
  },
  {
    AIRPORTCODE: "GIF",
    AIRPORTNAME: "Gilbert Field",
    CITYCODE: "GIF",
    CITYNAME: "WINTER HAVEN",
    CITYNAME_HEB: "WINTER HAVEN",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GII",
    AIRPORTNAME: "Siguiri",
    CITYCODE: "GII",
    CITYNAME: "SIGUIRI",
    CITYNAME_HEB: "SIGUIRI",
    COUNTRYCODE: "GN",
    COUNTRYNAME: "GUINEA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GIL",
    AIRPORTNAME: "Gilgit",
    CITYCODE: "GIL",
    CITYNAME: "GILGIT",
    CITYNAME_HEB: "GILGIT",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "GIM",
    AIRPORTNAME: "Miele Mimbale",
    CITYCODE: "GIM",
    CITYNAME: "MIELE MIMBALE",
    CITYNAME_HEB: "MIELE MIMBALE",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "GIR",
    AIRPORTNAME: "Girardot",
    CITYCODE: "GIR",
    CITYNAME: "GIRARDOT",
    CITYNAME_HEB: "GIRARDOT",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GIS",
    AIRPORTNAME: "Gisborne",
    CITYCODE: "GIS",
    CITYNAME: "GISBORNE",
    CITYNAME_HEB: "GISBORNE",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "AMZ",
    AIRPORTNAME: "Ardmore",
    CITYCODE: "AMZ",
    CITYNAME: "ARDMORE",
    CITYNAME_HEB: "ARDMORE",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "GIT",
    AIRPORTNAME: "Geita",
    CITYCODE: "GIT",
    CITYNAME: "GEITA",
    CITYNAME_HEB: "GEITA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GIY",
    AIRPORTNAME: "Giyani",
    CITYCODE: "GIY",
    CITYNAME: "GIYANI",
    CITYNAME_HEB: "GIYANI",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "GJA",
    AIRPORTNAME: "Guanaja",
    CITYCODE: "GJA",
    CITYNAME: "GUANAJA",
    CITYNAME_HEB: "GUANAJA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "GJL",
    AIRPORTNAME: "Jijel",
    CITYCODE: "GJL",
    CITYNAME: "JIJEL",
    CITYNAME_HEB: "JIJEL",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GJM",
    AIRPORTNAME: null,
    CITYCODE: "GJM",
    CITYNAME: "GUAJARA MIRIM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GJR",
    AIRPORTNAME: "Gjogur",
    CITYCODE: "GJR",
    CITYNAME: "GJOGUR",
    CITYNAME_HEB: "GJOGUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "GJT",
    AIRPORTNAME: "Walker Field",
    CITYCODE: "GJT",
    CITYNAME: "GRAND JUNCTION",
    CITYNAME_HEB: "GRAND JUNCTION",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GKA",
    AIRPORTNAME: "Goroka",
    CITYCODE: "GKA",
    CITYNAME: "GOROKA",
    CITYNAME_HEB: "GOROKA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GKE",
    AIRPORTNAME: "Geilenkirchen",
    CITYCODE: "GKE",
    CITYNAME: "GEILENKIRCHEN",
    CITYNAME_HEB: "GEILENKIRCHEN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GKH",
    AIRPORTNAME: "Gorkha",
    CITYCODE: "GKH",
    CITYNAME: "GORKHA",
    CITYNAME_HEB: "GORKHA",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "ANA",
    AIRPORTNAME: "Anaheim",
    CITYCODE: "ANA",
    CITYNAME: "ANAHEIM",
    CITYNAME_HEB: "\u05d0\u05e0\u05d4\u05d9\u05d9\u05dd",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GKL",
    AIRPORTNAME: "Great Keppel Island",
    CITYCODE: "GKL",
    CITYNAME: "GREAT KEPPEL ISLA",
    CITYNAME_HEB: "GREAT KEPPEL ISLA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GKN",
    AIRPORTNAME: "Gulkana",
    CITYCODE: "GKN",
    CITYNAME: "GULKANA",
    CITYNAME_HEB: "Gulkana",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GKO",
    AIRPORTNAME: "Kongoboumba",
    CITYCODE: "GKO",
    CITYNAME: "KONGOBOUMBA",
    CITYNAME_HEB: "KONGOBOUMBA",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "GKT",
    AIRPORTNAME: "Gatlinburg",
    CITYCODE: "GKT",
    CITYNAME: "GATLINBURG",
    CITYNAME_HEB: "GATLINBURG",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GLA",
    AIRPORTNAME: "Glasgow International",
    CITYCODE: "GLA",
    CITYNAME: "GLASGOW",
    CITYNAME_HEB: "\u05d2\u05dc\u05d0\u05d6\u05d2\u05d5",
    COUNTRYCODE: "ST",
    COUNTRYNAME: "SCOTLAND",
    COUNTRYNAME_HEB: "\u05e1\u05e7\u05d5\u05d8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "GLC",
    AIRPORTNAME: "Geladi",
    CITYCODE: "GLC",
    CITYNAME: "GELADI",
    CITYNAME_HEB: "GELADI",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GLD",
    AIRPORTNAME: "Renner Field",
    CITYCODE: "GLD",
    CITYNAME: "GOODLAND",
    CITYNAME_HEB: "GOODLAND",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GLE",
    AIRPORTNAME: "Municipal",
    CITYCODE: "GLE",
    CITYNAME: "GAINESVILLE",
    CITYNAME_HEB: "GAINESVILLE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ANB",
    AIRPORTNAME: "Anniston",
    CITYCODE: "ANB",
    CITYNAME: "ANNISTON",
    CITYNAME_HEB: "Anniston",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GLF",
    AIRPORTNAME: "Golfito",
    CITYCODE: "GLF",
    CITYNAME: "GOLFITO",
    CITYNAME_HEB: "GOLFITO",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "GLG",
    AIRPORTNAME: "Glengyle",
    CITYCODE: "GLG",
    CITYNAME: "GLENGYLE",
    CITYNAME_HEB: "GLENGYLE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GLH",
    AIRPORTNAME: "Greenville",
    CITYCODE: "GLH",
    CITYNAME: "GREENVILLE",
    CITYNAME_HEB: "GREENVILLE",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GLI",
    AIRPORTNAME: null,
    CITYCODE: "GLI",
    CITYNAME: "GALLIPOLI",
    CITYNAME_HEB: "\u05d2\u05dc\u05d9\u05e4\u05d5\u05dc\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GLK",
    AIRPORTNAME: "Galcaio",
    CITYCODE: "GLK",
    CITYNAME: "GALCAIO",
    CITYNAME_HEB: "GALCAIO",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GLL",
    AIRPORTNAME: "Klanten Airport",
    CITYCODE: "GLL",
    CITYNAME: "GOL",
    CITYNAME_HEB: "GOL",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GLM",
    AIRPORTNAME: "Glenormiston",
    CITYCODE: "GLM",
    CITYNAME: "GLENORMISTON",
    CITYNAME_HEB: "GLENORMISTON",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GLN",
    AIRPORTNAME: "Goulimime",
    CITYCODE: "GLN",
    CITYNAME: "GOULIMIME",
    CITYNAME_HEB: "GOULIMIME",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "GLO",
    AIRPORTNAME: "Gloucestershire",
    CITYCODE: "GLO",
    CITYNAME: "GLOUCESTER",
    CITYNAME_HEB: "GLOUCESTER",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GLP",
    AIRPORTNAME: "Gulgubip",
    CITYCODE: "GLP",
    CITYNAME: "GULGUBIP",
    CITYNAME_HEB: "GULGUBIP",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "RLI",
    AIRPORTNAME: "Reilly AHP",
    CITYCODE: "ANB",
    CITYNAME: "ANNISTON",
    CITYNAME_HEB: "Reilly",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GLQ",
    AIRPORTNAME: "Glennallen",
    CITYCODE: "GLQ",
    CITYNAME: "GLENNALLEN",
    CITYNAME_HEB: "Glennallen",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GLR",
    AIRPORTNAME: "Otsego County",
    CITYCODE: "GLR",
    CITYNAME: "GAYLORD",
    CITYNAME_HEB: "GAYLORD",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GLS",
    AIRPORTNAME: "Scholes Field",
    CITYCODE: "GLS",
    CITYNAME: "GALVESTON",
    CITYNAME_HEB: "GALVESTON",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GLT",
    AIRPORTNAME: "Gladstone",
    CITYCODE: "GLT",
    CITYNAME: "GLADSTONE",
    CITYNAME_HEB: "GLADSTONE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GLV",
    AIRPORTNAME: "Golovin",
    CITYCODE: "GLV",
    CITYNAME: "GOLOVIN",
    CITYNAME_HEB: "Golovin",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GLW",
    AIRPORTNAME: "Municipal",
    CITYCODE: "GLW",
    CITYNAME: "GLASGOW",
    CITYNAME_HEB: "GLASGOW (US)",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GLX",
    AIRPORTNAME: "Galela",
    CITYCODE: "GLX",
    CITYNAME: "GALELA",
    CITYNAME_HEB: "GALELA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GLY",
    AIRPORTNAME: "Goldsworthy",
    CITYCODE: "GLY",
    CITYNAME: "GOLDSWORTHY",
    CITYNAME_HEB: "GOLDSWORTHY",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GLZ",
    AIRPORTNAME: "Gilze-Rijen",
    CITYCODE: "GLZ",
    CITYNAME: "BREDA",
    CITYNAME_HEB: "BREDA",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "GMA",
    AIRPORTNAME: "Gemena",
    CITYCODE: "GMA",
    CITYNAME: "GEMENA",
    CITYNAME_HEB: "GEMENA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "ANC",
    AIRPORTNAME: "Ted Stevens Anchorage International Airport",
    CITYCODE: "ANC",
    CITYNAME: "ANCHORAGE",
    CITYNAME_HEB:
      "\u05d0\u05e0\u05e7\u05d5\u05e8\u05d2 - \u05d0\u05dc\u05e1\u05e7\u05d4",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "GMB",
    AIRPORTNAME: "Gambela",
    CITYCODE: "GMB",
    CITYNAME: "GAMBELA",
    CITYNAME_HEB: "GAMBELA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GMC",
    AIRPORTNAME: "Guerima",
    CITYCODE: "GMC",
    CITYNAME: "GUERIMA",
    CITYNAME_HEB: "GUERIMA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GME",
    AIRPORTNAME: "Gomel",
    CITYCODE: "GME",
    CITYNAME: "GOMEL",
    CITYNAME_HEB: "GOMEL",
    COUNTRYCODE: "BY",
    COUNTRYNAME: "BELARUS",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d0\u05e8\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "GMI",
    AIRPORTNAME: "Gasmata Island",
    CITYCODE: "GMI",
    CITYNAME: "GASMATA ISLAND",
    CITYNAME_HEB: "GASMATA ISLAND",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GMM",
    AIRPORTNAME: "Gamboma",
    CITYCODE: "GMM",
    CITYNAME: "GAMBOMA",
    CITYNAME_HEB: "GAMBOMA",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "GMN",
    AIRPORTNAME: "Greymouth",
    CITYCODE: "GMN",
    CITYNAME: "GREYMOUTH",
    CITYNAME_HEB: "GREYMOUTH",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "GMR",
    AIRPORTNAME: "Gambier Is",
    CITYCODE: "GMR",
    CITYNAME: "GAMBIER IS",
    CITYNAME_HEB: "GAMBIER IS",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "GMS",
    AIRPORTNAME: null,
    CITYCODE: "GMS",
    CITYNAME: "GUIMARAES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GMT",
    AIRPORTNAME: "Granite Mountain",
    CITYCODE: "GMT",
    CITYNAME: "GRANITE MOUNTAIN",
    CITYNAME_HEB: "Granite Mountain",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GMV",
    AIRPORTNAME: "Monument Valley",
    CITYCODE: "GMV",
    CITYNAME: "MONUMENT VALLEY",
    CITYNAME_HEB: "MONUMENT VALLEY",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GMY",
    AIRPORTNAME: "Rheindahlen",
    CITYCODE: "GMY",
    CITYNAME: "RHEINDAHLEN",
    CITYNAME_HEB: "RHEINDAHLEN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GMZ",
    AIRPORTNAME: "La Gomera",
    CITYCODE: "GMZ",
    CITYNAME: "SAN SEBASTIAN DE LA GOM",
    CITYNAME_HEB:
      "\u05e1\u05d0\u05df \u05e1\u05d1\u05e1\u05d8\u05d9\u05d0\u05df \u05d3\u05d4 \u05dc\u05d4 \u05d2\u05d5\u05de\u05e8\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "GNA",
    AIRPORTNAME: "Grodna",
    CITYCODE: "GNA",
    CITYNAME: "GRODNA",
    CITYNAME_HEB: "GRODNA",
    COUNTRYCODE: "BY",
    COUNTRYNAME: "BELARUS",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d0\u05e8\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "GNB",
    AIRPORTNAME: "Saint Geoirs",
    CITYCODE: "GNB",
    CITYNAME: "GRENOBLE",
    CITYNAME_HEB: "\u05d2\u05e8\u05e0\u05d5\u05d1\u05dc",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "GND",
    AIRPORTNAME: "Point Saline International",
    CITYCODE: "GND",
    CITYNAME: "GRENADA",
    CITYNAME_HEB: "GRENADA",
    COUNTRYCODE: "GD",
    COUNTRYNAME: "GRENADA",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "GNE",
    AIRPORTNAME: "Ghent",
    CITYCODE: "GNE",
    CITYNAME: "GHENT",
    CITYNAME_HEB: "\u05d2\u05e0\u05d8",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GNG",
    AIRPORTNAME: "Gooding",
    CITYCODE: "GNG",
    CITYNAME: "GOODING",
    CITYNAME_HEB: "GOODING",
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d9\u05d3\u05d4\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GNI",
    AIRPORTNAME: "Green Island",
    CITYCODE: "GNI",
    CITYNAME: "GREEN ISLAND",
    CITYNAME_HEB: "GREEN ISLAND",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "GNM",
    AIRPORTNAME: null,
    CITYCODE: "GNM",
    CITYNAME: "GUANAMBI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GNN",
    AIRPORTNAME: "Ghinnir",
    CITYCODE: "GNN",
    CITYNAME: "GHINNIR",
    CITYNAME_HEB: "GHINNIR",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GNR",
    AIRPORTNAME: null,
    CITYCODE: "GNR",
    CITYNAME: "GENERAL ROCA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GNS",
    AIRPORTNAME: "Gunungsitoli",
    CITYCODE: "GNS",
    CITYNAME: "GUNUNGSITOLI",
    CITYNAME_HEB: "GUNUNGSITOLI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GNT",
    AIRPORTNAME: "Milan",
    CITYCODE: "GNT",
    CITYNAME: "GRANTS",
    CITYNAME_HEB: "GRANTS",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GNU",
    AIRPORTNAME: "Goodnews Bay",
    CITYCODE: "GNU",
    CITYNAME: "GOODNEWS BAY",
    CITYNAME_HEB: "Goodnews Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GNV",
    AIRPORTNAME: "J R Alison Municipal",
    CITYCODE: "GNV",
    CITYNAME: "GAINESVILLE",
    CITYNAME_HEB: "GAINESVILLE",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GNZ",
    AIRPORTNAME: "Ghanzi",
    CITYCODE: "GNZ",
    CITYNAME: "GHANZI",
    CITYNAME_HEB: "GHANZI",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "GOA",
    AIRPORTNAME: "Cristoforo Colombo",
    CITYCODE: "GOA",
    CITYNAME: "GENOA",
    CITYNAME_HEB: "\u05d2\u05e0\u05d5\u05d0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GOB",
    AIRPORTNAME: "Goba",
    CITYCODE: "GOB",
    CITYNAME: "GOBA",
    CITYNAME_HEB: "GOBA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GOC",
    AIRPORTNAME: "Gora",
    CITYCODE: "GOC",
    CITYNAME: "GORA",
    CITYNAME_HEB: "GORA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "GOD",
    CITYNAME: "DUNAKESZI AND VAC",
    CITYNAME_HEB:
      "\u05d3\u05d5\u05e0\u05e7\u05e1\u05d6\u05d9 \u05d0\u05e0\u05d3 \u05d5\u05d0\u05e7",
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AND",
    AIRPORTNAME: "Anderson",
    CITYCODE: "AND",
    CITYNAME: "ANDERSON",
    CITYNAME_HEB: "ANDERSON",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GOE",
    AIRPORTNAME: "Gonalia",
    CITYCODE: "GOE",
    CITYNAME: "GONALIA",
    CITYNAME_HEB: "GONALIA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GOG",
    AIRPORTNAME: "Gobabis",
    CITYCODE: "GOG",
    CITYNAME: "GOBABIS",
    CITYNAME_HEB: "GOBABIS",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GOH",
    AIRPORTNAME: "Nuuk",
    CITYCODE: "GOH",
    CITYNAME: "NUUK",
    CITYNAME_HEB: "\u05e0\u05d5\u05d0\u05d5\u05e7",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "GOI",
    AIRPORTNAME: "Dabolim",
    CITYCODE: "GOI",
    CITYNAME: "GOA",
    CITYNAME_HEB: "\u05d2\u05d5\u05d0\u05d4",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "GOJ",
    AIRPORTNAME: "Nizhniy Novgorod",
    CITYCODE: "GOJ",
    CITYNAME: "NIZHNIY NOVGOROD",
    CITYNAME_HEB:
      "\u05e0\u05d9\u05d6\u05e0\u05d9 \u05e0\u05d5\u05d1\u05d2\u05d5\u05e8\u05d5\u05d3",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GOK",
    AIRPORTNAME: "Guthrie",
    CITYCODE: "GOK",
    CITYNAME: "GUTHRIE",
    CITYNAME_HEB: "GUTHRIE",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GOL",
    AIRPORTNAME: "State",
    CITYCODE: "GOL",
    CITYNAME: "GOLD BEACH",
    CITYNAME_HEB: "GOLD BEACH",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GOM",
    AIRPORTNAME: "Goma",
    CITYCODE: "GOM",
    CITYNAME: "GOMA",
    CITYNAME_HEB: "\u05d2\u05d5\u05de\u05d4",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "GON",
    AIRPORTNAME: "Groton-New London",
    CITYCODE: "GON",
    CITYNAME: "GROTON",
    CITYNAME_HEB: "GROTON",
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05e0\u05d8\u05d9\u05e7\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZGD",
    AIRPORTNAME: null,
    CITYCODE: "GON",
    CITYNAME: "GROTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ABI",
    AIRPORTNAME: "Abilene",
    CITYCODE: "ABI",
    CITYNAME: "ABILENE",
    CITYNAME_HEB: "Abilene",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ANE",
    AIRPORTNAME: "Marce",
    CITYCODE: "ANE",
    CITYNAME: "ANGERS",
    CITYNAME_HEB: "ANGERS",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "GOO",
    AIRPORTNAME: "Goondiwindi",
    CITYCODE: "GOO",
    CITYNAME: "GOONDIWINDI",
    CITYNAME_HEB: "GOONDIWINDI",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GOP",
    AIRPORTNAME: "Gorakhpur",
    CITYCODE: "GOP",
    CITYNAME: "GORAKHPUR",
    CITYNAME_HEB: "GORAKHPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "GOQ",
    AIRPORTNAME: "Golmud",
    CITYCODE: "GOQ",
    CITYNAME: "GOLMUD",
    CITYNAME_HEB: "GOLMUD",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "GOR",
    AIRPORTNAME: " ",
    CITYCODE: "GOR",
    CITYNAME: "GORWHIL",
    CITYNAME_HEB: "\u05d2\u05d5\u05e8\u05d5\u05d5\u05d9\u05dc",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GOS",
    AIRPORTNAME: "Gosford",
    CITYCODE: "GOS",
    CITYNAME: "GOSFORD",
    CITYNAME_HEB: "GOSFORD",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GOT",
    AIRPORTNAME: "Landvetter",
    CITYCODE: "GOT",
    CITYNAME: "GOTHENBURG",
    CITYNAME_HEB: "\u05d2\u05d5\u05d8\u05e0\u05d1\u05e8\u05d2",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GSE",
    AIRPORTNAME: "Saeve",
    CITYCODE: "GOT",
    CITYNAME: "GOTHENBURG",
    CITYNAME_HEB: "\u05d2\u05d5\u05d8\u05e0\u05d1\u05e8\u05d2",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XWL",
    AIRPORTNAME: " ",
    CITYCODE: "GOT",
    CITYNAME: "GOTHENBURG",
    CITYNAME_HEB: "\u05d2\u05d5\u05d8\u05e0\u05d1\u05e8\u05d2",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GOU",
    AIRPORTNAME: "Garoua",
    CITYCODE: "GOU",
    CITYNAME: "GAROUA",
    CITYNAME_HEB: "GAROUA",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "GOV",
    AIRPORTNAME: "Nhulunbuy",
    CITYCODE: "GOV",
    CITYNAME: "GOVE",
    CITYNAME_HEB: "GOVE",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QXG",
    AIRPORTNAME: " ",
    CITYCODE: "ANE",
    CITYNAME: "ANGERS",
    CITYNAME_HEB: "\u05d0\u05e0\u05d2\u05e8\u05e1",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GOY",
    AIRPORTNAME: "Amparai",
    CITYCODE: "GOY",
    CITYNAME: "GAL OYA",
    CITYNAME_HEB: "GAL OYA",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "GOZ",
    AIRPORTNAME: "Gorna Orechovitsa",
    CITYCODE: "GOZ",
    CITYNAME: "GORNA ORECHOVITSA",
    CITYNAME_HEB: "GORNA ORECHOVITSA",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GPA",
    AIRPORTNAME: "Araxos Airport",
    CITYCODE: "GPA",
    CITYNAME: "PELOPONENESE",
    CITYNAME_HEB: "\u05e4\u05dc\u05d5\u05e4\u05d5\u05e0\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "GPB",
    AIRPORTNAME: null,
    CITYCODE: "GPB",
    CITYNAME: "GUARAPUAVA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GPI",
    AIRPORTNAME: "Guapi",
    CITYCODE: "GPI",
    CITYNAME: "GUAPI",
    CITYNAME_HEB: "GUAPI",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GPL",
    AIRPORTNAME: "Guapiles",
    CITYCODE: "GPL",
    CITYNAME: "GUAPILES",
    CITYNAME_HEB: "GUAPILES",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "GPN",
    AIRPORTNAME: "Garden Point",
    CITYCODE: "GPN",
    CITYNAME: "GARDEN POINT",
    CITYNAME_HEB: "GARDEN POINT",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GPO",
    AIRPORTNAME: null,
    CITYCODE: "GPO",
    CITYNAME: "GENERAL PICO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GPS",
    AIRPORTNAME: "Baltra",
    CITYCODE: "GPS",
    CITYNAME: "GALAPAGOS IS",
    CITYNAME_HEB: "GALAPAGOS IS",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "GPT",
    AIRPORTNAME: "Biloxi Regional",
    CITYCODE: "GPT",
    CITYNAME: "GULFPORT",
    CITYNAME_HEB: "GULFPORT",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ANF",
    AIRPORTNAME: "Cerro Moreno International Airport",
    CITYCODE: "ANF",
    CITYNAME: "ANTOFAGASTA",
    CITYNAME_HEB: "ANTOFAGASTA",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "GPZ",
    AIRPORTNAME: "Grand Rapids",
    CITYCODE: "GPZ",
    CITYNAME: "GRAND RAPIDS",
    CITYNAME_HEB: "GRAND RAPIDS",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GQJ",
    AIRPORTNAME: "RAF Station",
    CITYCODE: "GQJ",
    CITYNAME: "MACHRIHANISH",
    CITYNAME_HEB: "MACHRIHANISH",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GQQ",
    AIRPORTNAME: "Galion",
    CITYCODE: "GQQ",
    CITYNAME: "GALION",
    CITYNAME_HEB: "GALION",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GRA",
    AIRPORTNAME: " ",
    CITYCODE: "GRA",
    CITYNAME: "GRANDVALIRA",
    CITYNAME_HEB:
      "\u05d2\u05e8\u05d0\u05e0\u05d3\u05d5\u05d0\u05dc\u05d9\u05e8\u05d4",
    COUNTRYCODE: "AD",
    COUNTRYNAME: "ANDORRA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d3\u05d5\u05e8\u05d4",
  },
  {
    AIRPORTCODE: "GRB",
    AIRPORTNAME: "Austin-Straubel Field",
    CITYCODE: "GRB",
    CITYNAME: "GREEN BAY",
    CITYNAME_HEB: "GREEN BAY",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GRC",
    AIRPORTNAME: "Grand Cess",
    CITYCODE: "GRC",
    CITYNAME: "GRAND CESS",
    CITYNAME_HEB: "GRAND CESS",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GRE",
    AIRPORTNAME: " ",
    CITYCODE: "GRE",
    CITYNAME: "GREECE",
    CITYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "HVG",
    AIRPORTNAME: "Valan",
    CITYCODE: "HVG",
    CITYNAME: "HONNINGSVAG",
    CITYNAME_HEB: "HONNINGSVAG",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "APC",
    AIRPORTNAME: "Napa County",
    CITYCODE: "APC",
    CITYNAME: "NAPA",
    CITYNAME_HEB: "NAPA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HVK",
    AIRPORTNAME: "Holmavik",
    CITYCODE: "HVK",
    CITYNAME: "HOLMAVIK",
    CITYNAME_HEB: "HOLMAVIK",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HVM",
    AIRPORTNAME: "Hvammstangi",
    CITYCODE: "HVM",
    CITYNAME: "HVAMMSTANGI",
    CITYNAME_HEB: "HVAMMSTANGI",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HVN",
    AIRPORTNAME: "New Haven",
    CITYCODE: "HVN",
    CITYNAME: "NEW HAVEN",
    CITYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d4\u05d9\u05d9\u05d1\u05df",
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05e0\u05d8\u05d9\u05e7\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZVE",
    AIRPORTNAME: " ",
    CITYCODE: "HVN",
    CITYNAME: "NEW HAVEN",
    CITYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d4\u05d9\u05d9\u05d1\u05df",
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "HVR",
    AIRPORTNAME: "City County",
    CITYCODE: "HVR",
    CITYNAME: "HAVRE",
    CITYNAME_HEB: "HAVRE",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HVS",
    AIRPORTNAME: "Municipal",
    CITYCODE: "HVS",
    CITYNAME: "HARTSVILLE",
    CITYNAME_HEB: "HARTSVILLE",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HWA",
    AIRPORTNAME: "Hawabango",
    CITYCODE: "HWA",
    CITYNAME: "HAWABANGO",
    CITYNAME_HEB: "HAWABANGO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HWD",
    AIRPORTNAME: "Air Terminal",
    CITYCODE: "HWD",
    CITYNAME: "HAYWARD",
    CITYNAME_HEB: "HAYWARD",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HWI",
    AIRPORTNAME: "Hawk Inlet",
    CITYCODE: "HWI",
    CITYNAME: "HAWK INLET",
    CITYNAME_HEB: "Hawk Inlet",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HWK",
    AIRPORTNAME: "Wilpena Pound",
    CITYCODE: "HWK",
    CITYNAME: "HAWKER",
    CITYNAME_HEB: "HAWKER",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "APE",
    AIRPORTNAME: "San Juan Aposento",
    CITYCODE: "APE",
    CITYNAME: "SAN JUAN APOSENTO",
    CITYNAME_HEB: "SAN JUAN APOSENTO",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "HWN",
    AIRPORTNAME: "Hwange Nat Park",
    CITYCODE: "HWN",
    CITYNAME: "HWANGE NAT PARK",
    CITYNAME_HEB: "HWANGE NAT PARK",
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: "\u05d6\u05d9\u05de\u05d1\u05d1\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HWO",
    AIRPORTNAME: "North Perry",
    CITYCODE: "HWO",
    CITYNAME: "HOLLYWOOD",
    CITYNAME_HEB: "HOLLYWOOD",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HXX",
    AIRPORTNAME: "Hay",
    CITYCODE: "HXX",
    CITYNAME: "HAY",
    CITYNAME_HEB: "HAY",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HYA",
    AIRPORTNAME: "Barnstable",
    CITYCODE: "HYA",
    CITYNAME: "HYANNIS",
    CITYNAME_HEB: "HYANNIS",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "HYC",
    AIRPORTNAME: "High Wycombe",
    CITYCODE: "HYC",
    CITYNAME: "HIGH WYCOMBE",
    CITYNAME_HEB: "HIGH WYCOMBE",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HYD",
    AIRPORTNAME: "Begumpet",
    CITYCODE: "HYD",
    CITYNAME: "HYDERABAD",
    CITYNAME_HEB: "HYDERABAD",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "HYF",
    AIRPORTNAME: "Hayfields",
    CITYCODE: "HYF",
    CITYNAME: "HAYFIELDS",
    CITYNAME_HEB: "HAYFIELDS",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HYG",
    AIRPORTNAME: "Hydaburg",
    CITYCODE: "HYG",
    CITYNAME: "HYDABURG",
    CITYNAME_HEB: "Hydaburg",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HYL",
    AIRPORTNAME: "Hollis",
    CITYCODE: "HYL",
    CITYNAME: "HOLLIS",
    CITYNAME_HEB: "Hollis",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HYN",
    AIRPORTNAME: "Huangyan",
    CITYCODE: "HYN",
    CITYNAME: "HUANGYAN",
    CITYNAME_HEB: "HUANGYAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HYR",
    AIRPORTNAME: "Municipal",
    CITYCODE: "HYR",
    CITYNAME: "HAYWARD",
    CITYNAME_HEB: "HAYWARD",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HYS",
    AIRPORTNAME: "Municipal",
    CITYCODE: "HYS",
    CITYNAME: "HAYS",
    CITYNAME_HEB: "HAYS",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HYV",
    AIRPORTNAME: "Hyvinkaa",
    CITYCODE: "HYV",
    CITYNAME: "HYVINKAA",
    CITYNAME_HEB: "HYVINKAA",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HZB",
    AIRPORTNAME: "Merville/Calonne",
    CITYCODE: "HZB",
    CITYNAME: "HAZEBROUCK",
    CITYNAME_HEB: "HAZEBROUCK",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "HZG",
    AIRPORTNAME: "Hanzhong",
    CITYCODE: "HZG",
    CITYNAME: "HANZHONG",
    CITYNAME_HEB: "HANZHONG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HZK",
    AIRPORTNAME: "Husavik",
    CITYCODE: "HZK",
    CITYNAME: "HUSAVIK",
    CITYNAME_HEB: "\u05d4\u05d5\u05e1\u05d5\u05d5\u05d9\u05e7",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HZL",
    AIRPORTNAME: "Hazleton",
    CITYCODE: "HZL",
    CITYNAME: "HAZLETON",
    CITYNAME_HEB: "HAZLETON",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HZV",
    AIRPORTNAME: "Hazyview",
    CITYCODE: "HZV",
    CITYNAME: "HAZYVIEW",
    CITYNAME_HEB: "HAZYVIEW",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "IAA",
    AIRPORTNAME: "Igarka",
    CITYCODE: "IAA",
    CITYNAME: "IGARKA",
    CITYNAME_HEB: "IGARKA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IAG",
    AIRPORTNAME: "International",
    CITYCODE: "IAG",
    CITYNAME: "NIAGARA FALLS",
    CITYNAME_HEB:
      "\u05de\u05e4\u05dc\u05d9 \u05e0\u05d9\u05d0\u05d2\u05e8\u05d4",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "APG",
    AIRPORTNAME: "Phillips AAF",
    CITYCODE: "APG",
    CITYNAME: "ABERDEEN",
    CITYNAME_HEB: "ABERDEEN",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IAM",
    AIRPORTNAME: "In Amenas",
    CITYCODE: "IAM",
    CITYNAME: "IN AMENAS",
    CITYNAME_HEB: "IN AMENAS",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IAN",
    AIRPORTNAME: "Bob Barker Memorial",
    CITYCODE: "IAN",
    CITYNAME: "KIANA",
    CITYNAME_HEB: "Bob Barker Memorial",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IAR",
    AIRPORTNAME: "Yaroslavl",
    CITYCODE: "IAR",
    CITYNAME: "YAROSLAVL",
    CITYNAME_HEB: "\u05d9\u05d0\u05e8\u05d5\u05e1\u05dc\u05d0\u05d1\u05dc",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IAS",
    AIRPORTNAME: "Iasi",
    CITYCODE: "IAS",
    CITYNAME: "IASI",
    CITYNAME_HEB: "\u05d9\u05d0\u05e1\u05d9",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IAT",
    AIRPORTNAME: null,
    CITYCODE: "IAT",
    CITYNAME: "IATA TRAFFIC SERV",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ZZ",
    COUNTRYNAME: "FICTITIOUS POINTS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IAU",
    AIRPORTNAME: "Iaura",
    CITYCODE: "IAU",
    CITYNAME: "IAURA",
    CITYNAME_HEB: "IAURA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "IBA",
    AIRPORTNAME: "Ibadan",
    CITYCODE: "IBA",
    CITYNAME: "IBADAN",
    CITYNAME_HEB: "IBADAN",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IBE",
    AIRPORTNAME: "Ibague",
    CITYCODE: "IBE",
    CITYNAME: "IBAGUE",
    CITYNAME_HEB: "IBAGUE",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IBI",
    AIRPORTNAME: "Iboki",
    CITYCODE: "IBI",
    CITYNAME: "IBOKI",
    CITYNAME_HEB: "IBOKI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "IBO",
    AIRPORTNAME: "Ibo",
    CITYCODE: "IBO",
    CITYNAME: "IBO",
    CITYNAME_HEB: "IBO",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "APH",
    AIRPORTNAME: "Camp A P Hill",
    CITYCODE: "APH",
    CITYNAME: "BOWLING GREEN",
    CITYNAME_HEB: "BOWLING GREEN",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "IBP",
    AIRPORTNAME: "Iberia",
    CITYCODE: "IBP",
    CITYNAME: "IBERIA",
    CITYNAME_HEB: "IBERIA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "IBZ",
    AIRPORTNAME: "Ibiza",
    CITYCODE: "IBZ",
    CITYNAME: "IBIZA",
    CITYNAME_HEB: "\u05d0\u05d9\u05d1\u05d9\u05d6\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "ICA",
    AIRPORTNAME: "Icabaru",
    CITYCODE: "ICA",
    CITYNAME: "ICABARU",
    CITYNAME_HEB: "ICABARU",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "ICI",
    AIRPORTNAME: "Cicia",
    CITYCODE: "ICI",
    CITYNAME: "CICIA",
    CITYNAME_HEB: "CICIA",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "ICK",
    AIRPORTNAME: "Nieuw Nickerie",
    CITYCODE: "ICK",
    CITYNAME: "NIEUW NICKERIE",
    CITYNAME_HEB: "NIEUW NICKERIE",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "ICL",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ICL",
    CITYNAME: "CLARINDA",
    CITYNAME_HEB: "CLARINDA",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ICO",
    AIRPORTNAME: "Sicogon Island",
    CITYCODE: "ICO",
    CITYNAME: "SICOGON ISLAND",
    CITYNAME_HEB: "SICOGON ISLAND",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "ICR",
    AIRPORTNAME: "Nicaro",
    CITYCODE: "ICR",
    CITYNAME: "NICARO",
    CITYNAME_HEB: "NICARO",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "ICS",
    CITYNAME: "SCIACCA",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "API",
    AIRPORTNAME: "Apiay",
    CITYCODE: "API",
    CITYNAME: "APIAY",
    CITYNAME_HEB: "APIAY",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CEA",
    AIRPORTNAME: null,
    CITYCODE: "ICT",
    CITYNAME: "WICHITA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IAB",
    AIRPORTNAME: null,
    CITYCODE: "ICT",
    CITYNAME: "WICHITA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ICT",
    AIRPORTNAME: "Mid-Continent",
    CITYCODE: "ICT",
    CITYNAME: "WICHITA",
    CITYNAME_HEB: "\u05d5\u05d9\u05e6'\u05d9\u05d8\u05d4",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ICY",
    AIRPORTNAME: "Icy Bay",
    CITYCODE: "ICY",
    CITYNAME: "ICY BAY",
    CITYNAME_HEB: "Icy Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IDA",
    AIRPORTNAME: "Fanning Field",
    CITYCODE: "IDA",
    CITYNAME: "IDAHO FALLS",
    CITYNAME_HEB: "IDAHO FALLS",
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d9\u05d3\u05d4\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IDB",
    AIRPORTNAME: "Idre",
    CITYCODE: "IDB",
    CITYNAME: "IDRE",
    CITYNAME_HEB: "IDRE",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IDF",
    AIRPORTNAME: "Idiofa",
    CITYCODE: "IDF",
    CITYNAME: "IDIOFA",
    CITYNAME_HEB: "IDIOFA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "IDG",
    AIRPORTNAME: "Municipal",
    CITYCODE: "IDG",
    CITYNAME: "IDA GROVE",
    CITYNAME_HEB: "IDA GROVE",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IDI",
    AIRPORTNAME: "Indiana",
    CITYCODE: "IDI",
    CITYNAME: "INDIANA",
    CITYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IDK",
    AIRPORTNAME: "Indulkana",
    CITYCODE: "IDK",
    CITYNAME: "INDULKANA",
    CITYNAME_HEB: "INDULKANA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "APK",
    AIRPORTNAME: "Apataki",
    CITYCODE: "APK",
    CITYNAME: "APATAKI",
    CITYNAME_HEB: "APATAKI",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "IDN",
    AIRPORTNAME: "Indagen",
    CITYCODE: "IDN",
    CITYNAME: "INDAGEN",
    CITYNAME_HEB: "INDAGEN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "IDO",
    AIRPORTNAME: null,
    CITYCODE: "IDO",
    CITYNAME: "SANTA ISABEL DO M",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IDP",
    AIRPORTNAME: "Independence",
    CITYCODE: "IDP",
    CITYNAME: "INDEPENDENCE",
    CITYNAME_HEB: "INDEPENDENCE",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IDR",
    AIRPORTNAME: "Indore",
    CITYCODE: "IDR",
    CITYNAME: "INDORE",
    CITYNAME_HEB: "INDORE",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "IDY",
    AIRPORTNAME: "Ile d'Yeu",
    CITYCODE: "IDY",
    CITYNAME: "ILE D'YEU",
    CITYNAME_HEB: "ILE D'YEU",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "IEG",
    AIRPORTNAME: "Babimost",
    CITYCODE: "IEG",
    CITYNAME: "ZIELONA GORA",
    CITYNAME_HEB: "ZIELONA GORA",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "IEJ",
    AIRPORTNAME: "Iejima",
    CITYCODE: "IEJ",
    CITYNAME: "IEJIMA",
    CITYNAME_HEB: "IEJIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "IES",
    AIRPORTNAME: "Riesa",
    CITYCODE: "IES",
    CITYNAME: "RIESA",
    CITYNAME_HEB: "RIESA",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IEV",
    AIRPORTNAME: "Zhulhany",
    CITYCODE: "IEV",
    CITYNAME: "KIEV",
    CITYNAME_HEB: "\u05e7\u05d9\u05d9\u05d1",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KBP",
    AIRPORTNAME: " ",
    CITYCODE: "IEV",
    CITYNAME: "KIEV",
    CITYNAME_HEB: "\u05e7\u05d9\u05d9\u05d1",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "ABM",
    AIRPORTNAME: null,
    CITYCODE: "ABM",
    CITYNAME: "BAMAGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "APL",
    AIRPORTNAME: "Nampula",
    CITYCODE: "APL",
    CITYNAME: "NAMPULA",
    CITYNAME_HEB: "NAMPULA",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "IFA",
    AIRPORTNAME: "Iowa Falls",
    CITYCODE: "IFA",
    CITYNAME: "IOWA FALLS",
    CITYNAME_HEB: "IOWA FALLS",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IFF",
    AIRPORTNAME: "Iffley",
    CITYCODE: "IFF",
    CITYNAME: "IFFLEY",
    CITYNAME_HEB: "IFFLEY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IFJ",
    AIRPORTNAME: "Isafjordur",
    CITYCODE: "IFJ",
    CITYNAME: "ISAFJORDUR",
    CITYNAME_HEB: "ISAFJORDUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "IFL",
    AIRPORTNAME: "Innisfail",
    CITYCODE: "IFL",
    CITYNAME: "INNISFAIL",
    CITYNAME_HEB: "INNISFAIL",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IFO",
    AIRPORTNAME: "Ivano-Frankovsk",
    CITYCODE: "IFO",
    CITYNAME: "IVANO FRANKOVSK",
    CITYNAME_HEB: "IVANO FRANKOVSK",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "IFP",
    AIRPORTNAME: "Laughlin Bullhead International",
    CITYCODE: "IFP",
    CITYNAME: "BULLHEAD CITY",
    CITYNAME_HEB: "BULLHEAD CITY",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IGA",
    AIRPORTNAME: "Inagua",
    CITYCODE: "IGA",
    CITYNAME: "INAGUA",
    CITYNAME_HEB: "INAGUA",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "IGB",
    AIRPORTNAME: null,
    CITYCODE: "IGB",
    CITYNAME: "INGENIERO JACOBACCI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "APN",
    AIRPORTNAME: " ",
    CITYCODE: "APN",
    CITYNAME: "ALPENDORF",
    CITYNAME_HEB: "\u05d0\u05dc\u05e4\u05e0\u05d3\u05d5\u05e8\u05e4",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IGE",
    AIRPORTNAME: "Iguela",
    CITYCODE: "IGE",
    CITYNAME: "IGUELA",
    CITYNAME_HEB: "IGUELA",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "IGG",
    AIRPORTNAME: "Igiugig",
    CITYCODE: "IGG",
    CITYNAME: "IGIUGIG",
    CITYNAME_HEB: "Igiugig",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IGH",
    AIRPORTNAME: "Ingham",
    CITYCODE: "IGH",
    CITYNAME: "INGHAM",
    CITYNAME_HEB: "INGHAM",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IGM",
    AIRPORTNAME: "Kingman",
    CITYCODE: "IGM",
    CITYNAME: "KINGMAN",
    CITYNAME_HEB: "KINGMAN",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IGN",
    AIRPORTNAME: "Maria Cristina",
    CITYCODE: "IGN",
    CITYNAME: "ILIGAN",
    CITYNAME_HEB: "ILIGAN",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "IGO",
    AIRPORTNAME: "Chigorodo",
    CITYCODE: "IGO",
    CITYNAME: "CHIGORODO",
    CITYNAME_HEB: "CHIGORODO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IGR",
    AIRPORTNAME: " ",
    CITYCODE: "IGR",
    CITYNAME: "IGUAZU",
    CITYNAME_HEB: "\u05d0\u05d9\u05d2\u05d0\u05e1\u05d5",
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05d2\u05e0\u05d8\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "IGU",
    AIRPORTNAME: " ",
    CITYCODE: "IGU",
    CITYNAME: "IGUASSU FALLS",
    CITYNAME_HEB:
      "\u05de\u05e4\u05dc\u05d9 \u05d0\u05d9\u05d2\u05d0\u05e1\u05d5",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "IHA",
    AIRPORTNAME: "Niihama",
    CITYCODE: "IHA",
    CITYNAME: "NIIHAMA",
    CITYNAME_HEB: "NIIHAMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "IHN",
    AIRPORTNAME: "Qishn",
    CITYCODE: "IHN",
    CITYNAME: "QISHN",
    CITYNAME_HEB: "QISHN",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "APO",
    AIRPORTNAME: "Apartado",
    CITYCODE: "APO",
    CITYNAME: "APARTADO",
    CITYNAME_HEB: "APARTADO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IHO",
    AIRPORTNAME: "Ihosy",
    CITYCODE: "IHO",
    CITYNAME: "IHOSY",
    CITYNAME_HEB: "IHOSY",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "IHU",
    AIRPORTNAME: "Ihu",
    CITYCODE: "IHU",
    CITYNAME: "IHU",
    CITYNAME_HEB: "IHU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "IIA",
    AIRPORTNAME: "Inishmaan",
    CITYCODE: "IIA",
    CITYNAME: "INISHMAAN",
    CITYNAME_HEB: "INISHMAAN",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SDA",
    AIRPORTNAME: " ",
    CITYCODE: "SDA",
    CITYNAME: "SAUZE DOULX",
    CITYNAME_HEB:
      "\u05e1\u05d0\u05d5\u05d6\u05d4 \u05d3\u05d0\u05d5\u05dc\u05e7\u05e1",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "III",
    AIRPORTNAME: null,
    CITYCODE: "III",
    CITYNAME: "CITY NAME TEST",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IIV",
    AIRPORTNAME: null,
    CITYCODE: "III",
    CITYNAME: "CITY NAME TEST",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VVA",
    AIRPORTNAME: null,
    CITYCODE: "III",
    CITYNAME: "CITY NAME TEST",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "IIN",
    AIRPORTNAME: "Nishinoomote",
    CITYCODE: "IIN",
    CITYNAME: "NISHINOOMOTE",
    CITYNAME_HEB: "NISHINOOMOTE",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "IIS",
    AIRPORTNAME: "Nissan Island",
    CITYCODE: "IIS",
    CITYNAME: "NISSAN ISLAND",
    CITYNAME_HEB: "NISSAN ISLAND",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "IJK",
    AIRPORTNAME: "Izhevsk",
    CITYCODE: "IJK",
    CITYNAME: "IZHEVSK",
    CITYNAME_HEB: "IZHEVSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IJU",
    AIRPORTNAME: null,
    CITYCODE: "IJU",
    CITYNAME: "IJUI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "APP",
    AIRPORTNAME: "Asapa",
    CITYCODE: "APP",
    CITYNAME: "ASAPA",
    CITYNAME_HEB: "ASAPA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "IJX",
    AIRPORTNAME: "Municipal",
    CITYCODE: "IJX",
    CITYNAME: "JACKSONVILLE",
    CITYNAME_HEB: "JACKSONVILLE",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IKB",
    AIRPORTNAME: "Wilkes County",
    CITYCODE: "IKB",
    CITYNAME: "WILKESBORO",
    CITYNAME_HEB: "WILKESBORO",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IKI",
    AIRPORTNAME: "Iki",
    CITYCODE: "IKI",
    CITYNAME: "IKI",
    CITYNAME_HEB: "IKI",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "IKK",
    AIRPORTNAME: "Greater Kankakee",
    CITYCODE: "IKK",
    CITYNAME: "KANKAKEE",
    CITYNAME_HEB: "KANKAKEE",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HGN",
    AIRPORTNAME: "Mae Hong Son",
    CITYCODE: "HGN",
    CITYNAME: "MAE HONG SON",
    CITYNAME_HEB: "MAE HONG SON",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ANX",
    AIRPORTNAME: "Andenes",
    CITYCODE: "ANX",
    CITYNAME: "ANDENES",
    CITYNAME_HEB: "ANDENES",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HGO",
    AIRPORTNAME: "Korhogo",
    CITYCODE: "HGO",
    CITYNAME: "KORHOGO",
    CITYNAME_HEB: "KORHOGO",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "HGR",
    AIRPORTNAME: "Wash. County Regional",
    CITYCODE: "HGR",
    CITYNAME: "HAGERSTOWN",
    CITYNAME_HEB: "HAGERSTOWN",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HGT",
    AIRPORTNAME: "Hunter AAF",
    CITYCODE: "HGT",
    CITYNAME: "JOLON",
    CITYNAME_HEB: "JOLON",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HGU",
    AIRPORTNAME: "Kagamuga",
    CITYCODE: "HGU",
    CITYNAME: "MOUNT HAGEN",
    CITYNAME_HEB: "MOUNT HAGEN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HGZ",
    AIRPORTNAME: "Hogatza",
    CITYCODE: "HGZ",
    CITYNAME: "HOGATZA",
    CITYNAME_HEB: "Hogatza",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HHE",
    AIRPORTNAME: "Hachinohe",
    CITYCODE: "HHE",
    CITYNAME: "HACHINOHE",
    CITYNAME_HEB: "HACHINOHE",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "HHH",
    AIRPORTNAME: "Hilton Head",
    CITYCODE: "HHH",
    CITYNAME: "HILTON HEAD",
    CITYNAME_HEB: "HILTON HEAD",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HHI",
    AIRPORTNAME: null,
    CITYCODE: "HHI",
    CITYNAME: "WAHIAWA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "HHQ",
    AIRPORTNAME: "Hua Hin Airport",
    CITYCODE: "HHQ",
    CITYNAME: "HUA HIN",
    CITYNAME_HEB: "\u05d4\u05d5\u05d5\u05d0\u05d9\u05df",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HHR",
    AIRPORTNAME: "Hawthorne",
    CITYCODE: "HHR",
    CITYNAME: "HAWTHORNE",
    CITYNAME_HEB: "HAWTHORNE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ANY",
    AIRPORTNAME: "Anthony",
    CITYCODE: "ANY",
    CITYNAME: "ANTHONY",
    CITYNAME_HEB: "ANTHONY",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HHZ",
    AIRPORTNAME: "Hikueru",
    CITYCODE: "HHZ",
    CITYNAME: "HIKUERU",
    CITYNAME_HEB: "HIKUERU",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "HIB",
    AIRPORTNAME: "Chisholm Airport",
    CITYCODE: "HIB",
    CITYNAME: "CHISHOLM",
    CITYNAME_HEB: "CHISHOLM",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XBJ",
    AIRPORTNAME: " ",
    CITYCODE: "XBJ",
    CITYNAME: "MAGOSA",
    CITYNAME_HEB: "\u05de\u05d2\u05d5\u05e1\u05d4",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HID",
    AIRPORTNAME: "Horn Island",
    CITYCODE: "HID",
    CITYNAME: "HORN ISLAND QLD",
    CITYNAME_HEB: "HORN ISLAND QLD",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HIE",
    AIRPORTNAME: "Regional",
    CITYCODE: "HIE",
    CITYNAME: "WHITEFIELD",
    CITYNAME_HEB: "WHITEFIELD",
    COUNTRYCODE: "USNH",
    COUNTRYNAME: "NEW HAMPSHIRE",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d4\u05de\u05e4\u05e9\u05d9\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HIG",
    AIRPORTNAME: "Highbury",
    CITYCODE: "HIG",
    CITYNAME: "HIGHBURY",
    CITYNAME_HEB: "HIGHBURY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HIH",
    AIRPORTNAME: "Hook Island",
    CITYCODE: "HIH",
    CITYNAME: "HOOK ISLAND",
    CITYNAME_HEB: "HOOK ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HII",
    AIRPORTNAME: "Municipal",
    CITYCODE: "HII",
    CITYNAME: "LAKE HAVASU CITY",
    CITYNAME_HEB: "LAKE HAVASU CITY",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HIJ",
    AIRPORTNAME: "International",
    CITYCODE: "HIJ",
    CITYNAME: "HIROSHIMA",
    CITYNAME_HEB: "\u05d4\u05d9\u05e8\u05d5\u05e9\u05d9\u05de\u05d4",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "HIL",
    AIRPORTNAME: "Shillavo",
    CITYCODE: "HIL",
    CITYNAME: "SHILAVO",
    CITYNAME_HEB: "SHILAVO",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ANZ",
    AIRPORTNAME: null,
    CITYCODE: "ANZ",
    CITYNAME: "NEW ZEALAND CRUISE",
    CITYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3 \u05d5\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HIN",
    AIRPORTNAME: "Sacheon",
    CITYCODE: "HIN",
    CITYNAME: "JINJU",
    CITYNAME_HEB: "JINJU",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "HIO",
    AIRPORTNAME: "Portland",
    CITYCODE: "HIO",
    CITYNAME: "HILLSBORO",
    CITYNAME_HEB: "HILLSBORO",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HIP",
    AIRPORTNAME: "Headingly",
    CITYCODE: "HIP",
    CITYNAME: "HEADINGLY",
    CITYNAME_HEB: "HEADINGLY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HIR",
    AIRPORTNAME: "Henderson International",
    CITYCODE: "HIR",
    CITYNAME: "HONIARA",
    CITYNAME_HEB: "\u05d0\u05d5\u05e0\u05d9\u05e8\u05d4",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "HIS",
    AIRPORTNAME: "Hayman Island",
    CITYCODE: "HIS",
    CITYNAME: "HAYMAN ISLAND",
    CITYNAME_HEB: "HAYMAN ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HIT",
    AIRPORTNAME: "Hivaro",
    CITYCODE: "HIT",
    CITYNAME: "HIVARO",
    CITYNAME_HEB: "HIVARO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HIX",
    AIRPORTNAME: "Hiva Oa",
    CITYCODE: "HIX",
    CITYNAME: "HIVA OA",
    CITYNAME_HEB: "HIVA OA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "HJR",
    AIRPORTNAME: "Khajuraho",
    CITYCODE: "HJR",
    CITYNAME: "KHAJURAHO",
    CITYNAME_HEB: "KHAJURAHO",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "HJT",
    AIRPORTNAME: "Khujirt",
    CITYCODE: "HJT",
    CITYNAME: "KHUJIRT",
    CITYNAME_HEB: "KHUJIRT",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HKB",
    AIRPORTNAME: "Healy Lake",
    CITYCODE: "HKB",
    CITYNAME: "HEALY LAKE",
    CITYNAME_HEB: "Healy Lake",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AOA",
    AIRPORTNAME: "Aroa",
    CITYCODE: "AOA",
    CITYNAME: "AROA",
    CITYNAME_HEB: "AROA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HKD",
    AIRPORTNAME: "Hakodate",
    CITYCODE: "HKD",
    CITYNAME: "HAKODATE",
    CITYNAME_HEB: "HAKODATE",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "HKG",
    AIRPORTNAME: " ",
    CITYCODE: "HKG",
    CITYNAME: "HONG KONG",
    CITYNAME_HEB: "\u05d4\u05d5\u05e0\u05d2 \u05e7\u05d5\u05e0\u05d2",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HKK",
    AIRPORTNAME: "Hokitika Airport",
    CITYCODE: "HKK",
    CITYNAME: "HOKITIKA",
    CITYNAME_HEB: "HOKITIKA",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HKN",
    AIRPORTNAME: "Hoskins",
    CITYCODE: "HKN",
    CITYNAME: "HOSKINS",
    CITYNAME_HEB: "HOSKINS",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HKP",
    AIRPORTNAME: null,
    CITYCODE: "HKP",
    CITYNAME: "KAANAPALI MAUI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "HKT",
    AIRPORTNAME: "International",
    CITYCODE: "HKT",
    CITYNAME: "PHUKET",
    CITYNAME_HEB: "\u05e4\u05d5\u05e7\u05d8",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HKV",
    AIRPORTNAME: "Haskovo",
    CITYCODE: "HKV",
    CITYNAME: "HASKOVO",
    CITYNAME_HEB: "\u05d0\u05e1\u05e7\u05d5\u05d1\u05d5",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HKY",
    AIRPORTNAME: "Hickory",
    CITYCODE: "HKY",
    CITYNAME: "HICKORY",
    CITYNAME_HEB: "HICKORY",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HLA",
    AIRPORTNAME: "Lanseria",
    CITYCODE: "HLA",
    CITYNAME: "LANSERIA",
    CITYNAME_HEB: "LANSERIA",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "AOB",
    AIRPORTNAME: "Annanberg",
    CITYCODE: "AOB",
    CITYNAME: "ANNANBERG",
    CITYNAME_HEB: "ANNANBERG",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HLB",
    AIRPORTNAME: "Hillenbrand",
    CITYCODE: "HLB",
    CITYNAME: "BATESVILLE",
    CITYNAME_HEB: "BATESVILLE",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HLC",
    AIRPORTNAME: "Hill City",
    CITYCODE: "HLC",
    CITYNAME: "HILL CITY",
    CITYNAME_HEB: "HILL CITY",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HLD",
    AIRPORTNAME: "Hailar",
    CITYCODE: "HLD",
    CITYNAME: "HAILAR",
    CITYNAME_HEB: "HAILAR",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HLF",
    AIRPORTNAME: "Hultsfred",
    CITYCODE: "HLF",
    CITYNAME: "HULTSFRED",
    CITYNAME_HEB: "HULTSFRED",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HLG",
    AIRPORTNAME: "Ohio County",
    CITYCODE: "HLG",
    CITYNAME: "WHEELING",
    CITYNAME_HEB: "WHEELING",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "HLH",
    AIRPORTNAME: "Ulanhot",
    CITYCODE: "HLH",
    CITYNAME: "ULANHOT",
    CITYNAME_HEB: "ULANHOT",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HLI",
    AIRPORTNAME: "Hollister",
    CITYCODE: "HLI",
    CITYNAME: "HOLLISTER",
    CITYNAME_HEB: "HOLLISTER",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HLJ",
    AIRPORTNAME: "Shauliaj",
    CITYCODE: "HLJ",
    CITYNAME: "SHAULIAJ",
    CITYNAME_HEB: "SHAULIAJ",
    COUNTRYCODE: "LT",
    COUNTRYNAME: "LITHUANIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d8\u05d0",
  },
  {
    AIRPORTCODE: "HLL",
    AIRPORTNAME: "Hillside",
    CITYCODE: "HLL",
    CITYNAME: "HILLSIDE",
    CITYNAME_HEB: "HILLSIDE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AOC",
    AIRPORTNAME: "Altenburg Nobitz",
    CITYCODE: "AOC",
    CITYNAME: "ALTENBURG",
    CITYNAME_HEB: "ALTENBURG",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HLN",
    AIRPORTNAME: "Helena",
    CITYCODE: "HLN",
    CITYNAME: "HELENA",
    CITYNAME_HEB: "HELENA",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HLS",
    AIRPORTNAME: null,
    CITYCODE: "HLS",
    CITYNAME: "SAINT HELENS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUTS",
    COUNTRYNAME: "TASMANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "HLT",
    AIRPORTNAME: " ",
    CITYCODE: "HLT",
    CITYNAME: "HOLTEN",
    CITYNAME_HEB: "\u05d4\u05d5\u05dc\u05d8\u05df",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HLU",
    AIRPORTNAME: "Houailou",
    CITYCODE: "HLU",
    CITYNAME: "HOUAILOU",
    CITYNAME_HEB: "HOUAILOU",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "HLV",
    AIRPORTNAME: "Helenvale",
    CITYCODE: "HLV",
    CITYNAME: "HELENVALE",
    CITYNAME_HEB: "HELENVALE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HLW",
    AIRPORTNAME: "Hluhluwe",
    CITYCODE: "HLW",
    CITYNAME: "HLUHLUWE",
    CITYNAME_HEB: "HLUHLUWE",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "HLY",
    AIRPORTNAME: "Holyhead",
    CITYCODE: "HLY",
    CITYNAME: "HOLYHEAD",
    CITYNAME_HEB: "HOLYHEAD",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HLZ",
    AIRPORTNAME: "Hamilton",
    CITYCODE: "HLZ",
    CITYNAME: "HAMILTON",
    CITYNAME_HEB: "HAMILTON",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HME",
    AIRPORTNAME: "Oued Irara Apt",
    CITYCODE: "HME",
    CITYNAME: "HASSI MESSAOUD",
    CITYNAME_HEB: "HASSI MESSAOUD",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HMG",
    AIRPORTNAME: "Hermannsburg",
    CITYCODE: "HMG",
    CITYNAME: "HERMANNSBURG",
    CITYNAME_HEB: "HERMANNSBURG",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AOD",
    AIRPORTNAME: "Abou Deia",
    CITYCODE: "AOD",
    CITYNAME: "ABOU DEIA",
    CITYNAME_HEB: "ABOU DEIA",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "HMI",
    AIRPORTNAME: "Hami",
    CITYCODE: "HMI",
    CITYNAME: "HAMI",
    CITYNAME_HEB: "\u05d7\u05de\u05d9",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HMJ",
    AIRPORTNAME: "Khmelnitskiy",
    CITYCODE: "HMJ",
    CITYNAME: "KHMELNITSKIY",
    CITYNAME_HEB: "KHMELNITSKIY",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "HMO",
    AIRPORTNAME: "Gen Pesqueira Garcia",
    CITYCODE: "HMO",
    CITYNAME: "HERMOSILLO",
    CITYNAME_HEB: "HERMOSILLO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "HMR",
    AIRPORTNAME: "Hamar Airport",
    CITYCODE: "HMR",
    CITYNAME: "HAMAR",
    CITYNAME_HEB: "\u05d4\u05de\u05d0\u05e8",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HMS",
    AIRPORTNAME: "Homeshore",
    CITYCODE: "HMS",
    CITYNAME: "HOMESHORE",
    CITYNAME_HEB: "Homeshore",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HMT",
    AIRPORTNAME: "Ryan Field",
    CITYCODE: "HMT",
    CITYNAME: "HEMET",
    CITYNAME_HEB: "HEMET",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HMV",
    AIRPORTNAME: "Hemavan",
    CITYCODE: "HMV",
    CITYNAME: "HEMAVAN",
    CITYNAME_HEB: "HEMAVAN",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HNA",
    AIRPORTNAME: "Hanamaki",
    CITYCODE: "HNA",
    CITYNAME: "MORIOKA",
    CITYNAME_HEB: "MORIOKA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "HNB",
    AIRPORTNAME: "Municipal",
    CITYCODE: "HNB",
    CITYNAME: "HUNTINGBURG",
    CITYNAME_HEB: "HUNTINGBURG",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HNC",
    AIRPORTNAME: "Hatteras",
    CITYCODE: "HNC",
    CITYNAME: "HATTERAS",
    CITYNAME_HEB: "HATTERAS",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AAF",
    AIRPORTNAME: "Apalachicola",
    CITYCODE: "AAF",
    CITYNAME: "APALACHICOLA",
    CITYNAME_HEB: "Apalachicola",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ABK",
    AIRPORTNAME: "Kabri Dar",
    CITYCODE: "ABK",
    CITYNAME: "KABRI DAR",
    CITYNAME_HEB: "KABRI DAR",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AOG",
    AIRPORTNAME: "Anshan",
    CITYCODE: "AOG",
    CITYNAME: "ANSHAN",
    CITYNAME_HEB: "ANSHAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HNE",
    AIRPORTNAME: "Tahneta Pass",
    CITYCODE: "HNE",
    CITYNAME: "TAHNETA PASS LODG",
    CITYNAME_HEB: "Tahneta Pass",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HNG",
    AIRPORTNAME: "Hienghene",
    CITYCODE: "HNG",
    CITYNAME: "HIENGHENE",
    CITYNAME_HEB: "HIENGHENE",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "HNH",
    AIRPORTNAME: "Hoonah",
    CITYCODE: "HNH",
    CITYNAME: "HOONAH",
    CITYNAME_HEB: "Hoonah",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HNI",
    AIRPORTNAME: "Heiweni",
    CITYCODE: "HNI",
    CITYNAME: "HEIWENI",
    CITYNAME_HEB: "HEIWENI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HNK",
    AIRPORTNAME: "Hinchinbrook Island",
    CITYCODE: "HNK",
    CITYNAME: "HINCHINBROOK ISL",
    CITYNAME_HEB: "HINCHINBROOK ISL",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HNL",
    AIRPORTNAME: "International",
    CITYCODE: "HNL",
    CITYNAME: "HONOLULU",
    CITYNAME_HEB:
      "\u05d4\u05d5\u05e0\u05d5\u05dc\u05d5\u05dc\u05d5 - \u05d4\u05d5\u05d5\u05d0\u05d9",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "HNM",
    AIRPORTNAME: "Hana",
    CITYCODE: "HNM",
    CITYNAME: "HANA",
    CITYNAME_HEB: "HANA",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HNN",
    AIRPORTNAME: "Honinabi",
    CITYCODE: "HNN",
    CITYNAME: "HONINABI",
    CITYNAME_HEB: "HONINABI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NMK",
    AIRPORTNAME: null,
    CITYCODE: "NMK",
    CITYNAME: "KAMNIK",
    CITYNAME_HEB: "\u05e7\u05de\u05e0\u05d9\u05e7",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AOH",
    AIRPORTNAME: "Allen County",
    CITYCODE: "AOH",
    CITYNAME: "LIMA",
    CITYNAME_HEB: "LIMA",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HNS",
    AIRPORTNAME: "Haines",
    CITYCODE: "HNS",
    CITYNAME: "HAINES",
    CITYNAME_HEB: "Haines",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HNX",
    AIRPORTNAME: "Hanna",
    CITYCODE: "HNX",
    CITYNAME: "HANNA",
    CITYNAME_HEB: "HANNA",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HNY",
    AIRPORTNAME: "Hengyang",
    CITYCODE: "HNY",
    CITYNAME: "HENGYANG",
    CITYNAME_HEB: "HENGYANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HOA",
    AIRPORTNAME: " ",
    CITYCODE: "HOA",
    CITYNAME: "HOHENTAUERN",
    CITYNAME_HEB: "\u05d4\u05d5\u05e0\u05d8\u05d4\u05d5\u05e8\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HBB",
    AIRPORTNAME: null,
    CITYCODE: "HOB",
    CITYNAME: "HOBBS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HOB",
    AIRPORTNAME: "Lea County",
    CITYCODE: "HOB",
    CITYNAME: "HOBBS",
    CITYNAME_HEB: "HOBBS",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HOC",
    AIRPORTNAME: "Komako",
    CITYCODE: "HOC",
    CITYNAME: "KOMAKO",
    CITYNAME_HEB: "KOMAKO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HOD",
    AIRPORTNAME: "Hodeidah Airport",
    CITYCODE: "HOD",
    CITYNAME: "HODEIDAH",
    CITYNAME_HEB: "HODEIDAH",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "HOE",
    AIRPORTNAME: "Houeisay",
    CITYCODE: "HOE",
    CITYNAME: "HOUEISAY",
    CITYNAME_HEB: "HOUEISAY",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "HOG",
    AIRPORTNAME: "Frank Pais",
    CITYCODE: "HOG",
    CITYNAME: "HOLGUIN",
    CITYNAME_HEB: "HOLGUIN",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "AOI",
    AIRPORTNAME: "Falconara",
    CITYCODE: "AOI",
    CITYNAME: "ANCONA",
    CITYNAME_HEB: "\u05d0\u05e0\u05e7\u05d5\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HOH",
    AIRPORTNAME: "Hohenems",
    CITYCODE: "HOH",
    CITYNAME: "HOHENEMS",
    CITYNAME_HEB: "HOHENEMS",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HOI",
    AIRPORTNAME: "Hao Island",
    CITYCODE: "HOI",
    CITYNAME: "HAO ISLAND",
    CITYNAME_HEB: "HAO ISLAND",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "HOK",
    AIRPORTNAME: "Hooker Creek",
    CITYCODE: "HOK",
    CITYNAME: "HOOKER CREEK",
    CITYNAME_HEB: "HOOKER CREEK",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HOL",
    AIRPORTNAME: " ",
    CITYCODE: "HOL",
    CITYNAME: "HOLAND",
    CITYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HOM",
    AIRPORTNAME: "Homer",
    CITYCODE: "HOM",
    CITYNAME: "HOMER",
    CITYNAME_HEB: "Homer",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HON",
    AIRPORTNAME: "Howes",
    CITYCODE: "HON",
    CITYNAME: "HURON",
    CITYNAME_HEB: "HURON",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HOO",
    AIRPORTNAME: "Nhon Co",
    CITYCODE: "HOO",
    CITYNAME: "QUANDUC",
    CITYNAME_HEB: "QUANDUC",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "HOP",
    AIRPORTNAME: "Campbell AAF",
    CITYCODE: "HOP",
    CITYNAME: "HOPKINSVILLE",
    CITYNAME_HEB: "HOPKINSVILLE",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HOQ",
    AIRPORTNAME: "Hof",
    CITYCODE: "HOQ",
    CITYNAME: "HOF",
    CITYNAME_HEB: "HOF",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HOR",
    AIRPORTNAME: "Horta",
    CITYCODE: "HOR",
    CITYNAME: "HORTA",
    CITYNAME_HEB: "HORTA",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "AOJ",
    AIRPORTNAME: "Aomori",
    CITYCODE: "AOJ",
    CITYNAME: "AOMORI",
    CITYNAME_HEB: "\u05d0\u05d0\u05d5\u05de\u05d5\u05e8\u05d9",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "HOS",
    AIRPORTNAME: null,
    CITYCODE: "HOS",
    CITYNAME: "CHOS MALAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "HOT",
    AIRPORTNAME: "Memorial Field",
    CITYCODE: "HOT",
    CITYNAME: "HOT SPRINGS",
    CITYNAME_HEB: "HOT SPRINGS",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HOU",
    AIRPORTNAME: "Hobby",
    CITYCODE: "HOU",
    CITYNAME: "HOUSTON",
    CITYNAME_HEB: "\u05d9\u05d5\u05e1\u05d8\u05d5\u05df",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "IAH",
    AIRPORTNAME: "George Bush Intercntl.",
    CITYCODE: "HOU",
    CITYNAME: "HOUSTON",
    CITYNAME_HEB: "\u05d9\u05d5\u05e1\u05d8\u05d5\u05df",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AOK",
    AIRPORTNAME: "Karpathos",
    CITYCODE: "AOK",
    CITYNAME: "KARPATHOS",
    CITYNAME_HEB: "\u05e7\u05e8\u05e4\u05d8\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "HOV",
    AIRPORTNAME: "Hovden",
    CITYCODE: "HOV",
    CITYNAME: "ORSTA VOLDA",
    CITYNAME_HEB: "ORSTA VOLDA",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HOW",
    AIRPORTNAME: "Howard AFB",
    CITYCODE: "HOW",
    CITYNAME: "FORT KOBBE",
    CITYNAME_HEB: "FORT KOBBE",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "HOX",
    AIRPORTNAME: "Homalin",
    CITYCODE: "HOX",
    CITYNAME: "HOMALIN",
    CITYNAME_HEB: "HOMALIN",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "HOY",
    AIRPORTNAME: "Hoy Island",
    CITYCODE: "HOY",
    CITYNAME: "HOY ISLAND",
    CITYNAME_HEB: "HOY ISLAND",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AOL",
    AIRPORTNAME: null,
    CITYCODE: "AOL",
    CITYNAME: "PASO DE LOS LIBRES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "HPA",
    AIRPORTNAME: "Salote Pilolevu",
    CITYCODE: "HPA",
    CITYNAME: "HA'APAI",
    CITYNAME_HEB: "HA'APAI",
    COUNTRYCODE: "TO",
    COUNTRYNAME: "TONGA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d2\u05d4",
  },
  {
    AIRPORTCODE: "HPB",
    AIRPORTNAME: "Hooper Bay",
    CITYCODE: "HPB",
    CITYNAME: "HOOPER BAY",
    CITYNAME_HEB: "Hooper Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HPE",
    AIRPORTNAME: "Hope Vale",
    CITYCODE: "HPE",
    CITYNAME: "HOPE VALE",
    CITYNAME_HEB: "HOPE VALE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HPH",
    AIRPORTNAME: "Catbi",
    CITYCODE: "HPH",
    CITYNAME: "HAIPHONG",
    CITYNAME_HEB: "\u05d4\u05d9\u05d9\u05e4\u05d4\u05d5\u05e0\u05d2",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "HPN",
    AIRPORTNAME: "Westchester County Apt",
    CITYCODE: "HPN",
    CITYNAME: "WESTCHESTER COUNTY",
    CITYNAME_HEB: "WESTCHESTER COUNTY",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZTF",
    AIRPORTNAME: null,
    CITYCODE: "HPN",
    CITYNAME: "WESTCHESTER COUNTY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "HPT",
    AIRPORTNAME: "Municipal",
    CITYCODE: "HPT",
    CITYNAME: "HAMPTON",
    CITYNAME_HEB: "HAMPTON",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HPY",
    AIRPORTNAME: "Baytown",
    CITYCODE: "HPY",
    CITYNAME: "BAYTOWN",
    CITYNAME_HEB: "BAYTOWN",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HQM",
    AIRPORTNAME: "Bowerman Airport",
    CITYCODE: "HQM",
    CITYNAME: "ABERDEEN",
    CITYNAME_HEB: "ABERDEEN (US)",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HRA",
    AIRPORTNAME: "Mansehra",
    CITYCODE: "HRA",
    CITYNAME: "MANSEHRA",
    CITYNAME_HEB: "MANSEHRA",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "AON",
    AIRPORTNAME: "Arona",
    CITYCODE: "AON",
    CITYNAME: "ARONA",
    CITYNAME_HEB: "ARONA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HRB",
    AIRPORTNAME: "Harbin",
    CITYCODE: "HRB",
    CITYNAME: "HARBIN",
    CITYNAME_HEB: "\u05d7\u05e8\u05d1\u05d9\u05df",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HRC",
    AIRPORTNAME: "Zhairem",
    CITYCODE: "HRC",
    CITYNAME: "ZHAIREM",
    CITYNAME_HEB: "ZHAIREM",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "HRD",
    AIRPORTNAME: null,
    CITYCODE: "HRD",
    CITYNAME: "HARSTAD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "HRE",
    AIRPORTNAME: "Harare",
    CITYCODE: "HRE",
    CITYNAME: "HARARE",
    CITYNAME_HEB: "\u05d4\u05e8\u05e8\u05d4",
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: "\u05d6\u05d9\u05de\u05d1\u05d1\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HRJ",
    AIRPORTNAME: "Chaurjhari",
    CITYCODE: "HRJ",
    CITYNAME: "CHAURJHARI",
    CITYNAME_HEB: "CHAURJHARI",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "HRK",
    AIRPORTNAME: "Kharkov",
    CITYCODE: "HRK",
    CITYNAME: "KHARKOV",
    CITYNAME_HEB: "KHARKOV",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "HRL",
    AIRPORTNAME: "Valley International",
    CITYCODE: "HRL",
    CITYNAME: "HARLINGEN",
    CITYNAME_HEB: "HARLINGEN",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HRM",
    AIRPORTNAME: "Tilrempt",
    CITYCODE: "HRM",
    CITYNAME: "HASSI R'MEL",
    CITYNAME_HEB: "HASSI R'MEL",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HRN",
    AIRPORTNAME: "Heliport",
    CITYCODE: "HRN",
    CITYNAME: "HERON ISLAND",
    CITYNAME_HEB: "HERON ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HRO",
    AIRPORTNAME: "Boone County",
    CITYCODE: "HRO",
    CITYNAME: "HARRISON",
    CITYNAME_HEB: "HARRISON",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AOO",
    AIRPORTNAME: "Martinsburg",
    CITYCODE: "AOO",
    CITYNAME: "ALTOONA",
    CITYNAME_HEB: "ALTOONA",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HRR",
    AIRPORTNAME: "Herrera",
    CITYCODE: "HRR",
    CITYNAME: "HERRERA",
    CITYNAME_HEB: "HERRERA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HRS",
    AIRPORTNAME: "Harrismith Airport",
    CITYCODE: "HRS",
    CITYNAME: "HARRISMITH",
    CITYNAME_HEB: "HARRISMITH",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "HRT",
    AIRPORTNAME: "Linton-On-Ouse",
    CITYCODE: "HRT",
    CITYNAME: "HARROGATE",
    CITYNAME_HEB: "HARROGATE",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HRY",
    AIRPORTNAME: "Henbury",
    CITYCODE: "HRY",
    CITYNAME: "HENBURY",
    CITYNAME_HEB: "HENBURY",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HRZ",
    AIRPORTNAME: null,
    CITYCODE: "HRZ",
    CITYNAME: "HORIZONTINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "HSB",
    AIRPORTNAME: "Raleigh",
    CITYCODE: "HSB",
    CITYNAME: "HARRISBURG",
    CITYNAME_HEB: "HARRISBURG",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HSC",
    AIRPORTNAME: "Shaoguan",
    CITYCODE: "HSC",
    CITYNAME: "SHAOGUAN",
    CITYNAME_HEB: "SHAOGUAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HSG",
    AIRPORTNAME: "Saga",
    CITYCODE: "HSG",
    CITYNAME: "SAGA",
    CITYNAME_HEB: "SAGA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "HSI",
    AIRPORTNAME: "Hastings",
    CITYCODE: "HSI",
    CITYNAME: "HASTINGS",
    CITYNAME_HEB: "HASTINGS",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HSL",
    AIRPORTNAME: "Huslia",
    CITYCODE: "HSL",
    CITYNAME: "HUSLIA",
    CITYNAME_HEB: "Huslia",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AOR",
    AIRPORTNAME: "Alor Setar",
    CITYCODE: "AOR",
    CITYNAME: "ALOR SETAR",
    CITYNAME_HEB: "ALOR SETAR",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HSM",
    AIRPORTNAME: "Horsham",
    CITYCODE: "HSM",
    CITYNAME: "HORSHAM",
    CITYNAME_HEB: "HORSHAM",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HSN",
    AIRPORTNAME: "Zhoushan",
    CITYCODE: "HSN",
    CITYNAME: "ZHOUSHAN",
    CITYNAME_HEB: "ZHOUSHAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HSP",
    AIRPORTNAME: "Ingalls Field",
    CITYCODE: "HSP",
    CITYNAME: "HOT SPRINGS",
    CITYNAME_HEB: "HOT SPRINGS",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "HSS",
    AIRPORTNAME: "Hissar",
    CITYCODE: "HSS",
    CITYNAME: "HISSAR",
    CITYNAME_HEB: "HISSAR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "HST",
    AIRPORTNAME: "AFB",
    CITYCODE: "HST",
    CITYNAME: "HOMESTEAD",
    CITYNAME_HEB: "HOMESTEAD",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HSV",
    AIRPORTNAME: "Madison County",
    CITYCODE: "HSV",
    CITYNAME: "HUNTSVILLE",
    CITYNAME_HEB: "Huntsville",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HUA",
    AIRPORTNAME: "Redstone AAF",
    CITYCODE: "HSV",
    CITYNAME: "HUNTSVILLE",
    CITYNAME_HEB: "Huntsville",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HSZ",
    AIRPORTNAME: "Hsinchu",
    CITYCODE: "HSZ",
    CITYNAME: "HSINCHU",
    CITYNAME_HEB: "HSINCHU",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "HTA",
    AIRPORTNAME: "Chita",
    CITYCODE: "HTA",
    CITYNAME: "CHITA",
    CITYNAME_HEB: "CHITA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HTB",
    AIRPORTNAME: "Terre-de-Bas",
    CITYCODE: "HTB",
    CITYNAME: "TERRE DE BAS",
    CITYNAME_HEB: "TERRE DE BAS",
    COUNTRYCODE: "GP",
    COUNTRYNAME: "GUADELOUPE",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d2\u05d5\u05d5\u05d3\u05d0\u05dc\u05d5\u05e4",
  },
  {
    AIRPORTCODE: "AOS",
    AIRPORTNAME: "Amook",
    CITYCODE: "AOS",
    CITYNAME: "AMOOK",
    CITYNAME_HEB: "Amook",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HTF",
    AIRPORTNAME: "Hatfield",
    CITYCODE: "HTF",
    CITYNAME: "HATFIELD",
    CITYNAME_HEB: "HATFIELD",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HTG",
    AIRPORTNAME: "Hatanga",
    CITYCODE: "HTG",
    CITYNAME: "HATANGA",
    CITYNAME_HEB: "HATANGA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HTH",
    AIRPORTNAME: "Hawthorne",
    CITYCODE: "HTH",
    CITYNAME: "HAWTHORNE",
    CITYNAME_HEB: "HAWTHORNE",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HTI",
    AIRPORTNAME: "Hamilton Island",
    CITYCODE: "HTI",
    CITYNAME: "HAMILTON ISLAND",
    CITYNAME_HEB: "HAMILTON ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HTL",
    AIRPORTNAME: "Roscommon County",
    CITYCODE: "HTL",
    CITYNAME: "HOUGHTON",
    CITYNAME_HEB: "HOUGHTON",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HTN",
    AIRPORTNAME: "Hotan",
    CITYCODE: "HTN",
    CITYNAME: "HOTAN",
    CITYNAME_HEB: "HOTAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HTO",
    AIRPORTNAME: "East Hampton",
    CITYCODE: "HTO",
    CITYNAME: "EAST HAMPTON",
    CITYNAME_HEB: "EAST HAMPTON",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HTR",
    AIRPORTNAME: "Hateruma",
    CITYCODE: "HTR",
    CITYNAME: "HATERUMA",
    CITYNAME_HEB: "HATERUMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "HTS",
    AIRPORTNAME: "Tri-State/Milton",
    CITYCODE: "HTS",
    CITYNAME: "HUNTINGTON",
    CITYNAME_HEB: "HUNTINGTON",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "HTU",
    AIRPORTNAME: "Hopetoun",
    CITYCODE: "HTU",
    CITYNAME: "HOPETOUN",
    CITYNAME_HEB: "HOPETOUN",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ABL",
    AIRPORTNAME: "Ambler",
    CITYCODE: "ABL",
    CITYNAME: "AMBLER",
    CITYNAME_HEB: "Ambler",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AOT",
    AIRPORTNAME: "Corrado Gex",
    CITYCODE: "AOT",
    CITYNAME: "AOSTA",
    CITYNAME_HEB: "AOSTA",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HTV",
    AIRPORTNAME: "Huntsville",
    CITYCODE: "HTV",
    CITYNAME: "HUNTSVILLE",
    CITYNAME_HEB: "HUNTSVILLE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HTW",
    AIRPORTNAME: "Huntington County",
    CITYCODE: "HTW",
    CITYNAME: "HUNTINGTON",
    CITYNAME_HEB: "HUNTINGTON",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HTZ",
    AIRPORTNAME: "Hato Corozal",
    CITYCODE: "HTZ",
    CITYNAME: "HATO COROZAL",
    CITYNAME_HEB: "HATO COROZAL",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HUB",
    AIRPORTNAME: "Humbert River",
    CITYCODE: "HUB",
    CITYNAME: "HUMBERT RIVER",
    CITYNAME_HEB: "HUMBERT RIVER",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HUC",
    AIRPORTNAME: "Humacao Airport",
    CITYCODE: "HUC",
    CITYNAME: "HUMACAO",
    CITYNAME_HEB: "HUMACAO",
    COUNTRYCODE: "PR",
    COUNTRYNAME: "PUERTO RICO",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e8\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PPD",
    AIRPORTNAME: "Palmas Del Mar",
    CITYCODE: "HUC",
    CITYNAME: "HUMACAO",
    CITYNAME_HEB: "HUMACAO",
    COUNTRYCODE: "PR",
    COUNTRYNAME: "PUERTO RICO",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e8\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "HUD",
    AIRPORTNAME: "Humboldt",
    CITYCODE: "HUD",
    CITYNAME: "HUMBOLDT",
    CITYNAME_HEB: "HUMBOLDT",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HUE",
    AIRPORTNAME: "Humera",
    CITYCODE: "HUE",
    CITYNAME: "HUMERA",
    CITYNAME_HEB: "HUMERA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HUF",
    AIRPORTNAME: "Hulman Field",
    CITYCODE: "HUF",
    CITYNAME: "TERRE HAUTE",
    CITYNAME_HEB: "TERRE HAUTE",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HUG",
    AIRPORTNAME: "Huehuetenango",
    CITYCODE: "HUG",
    CITYNAME: "HUEHUETENANGO",
    CITYNAME_HEB: "HUEHUETENANGO",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "AOU",
    AIRPORTNAME: "Attopeu",
    CITYCODE: "AOU",
    CITYNAME: "ATTOPEU",
    CITYNAME_HEB: "ATTOPEU",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "HUH",
    AIRPORTNAME: "Huahine",
    CITYCODE: "HUH",
    CITYNAME: "HUAHINE",
    CITYNAME_HEB: "HUAHINE",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "HUI",
    AIRPORTNAME: "Phu Bai",
    CITYCODE: "HUI",
    CITYNAME: "HUE",
    CITYNAME_HEB: "HUE",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "HUJ",
    AIRPORTNAME: "Hugo",
    CITYCODE: "HUJ",
    CITYNAME: "HUGO",
    CITYNAME_HEB: "HUGO",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HUK",
    AIRPORTNAME: "Hukuntsi",
    CITYCODE: "HUK",
    CITYNAME: "HUKUNTSI",
    CITYNAME_HEB: "HUKUNTSI",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "HUL",
    AIRPORTNAME: "International",
    CITYCODE: "HUL",
    CITYNAME: "HOULTON",
    CITYNAME_HEB: "HOULTON",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HUM",
    AIRPORTNAME: "Terrebonne",
    CITYCODE: "HUM",
    CITYNAME: "HOUMA",
    CITYNAME_HEB: "\u05d0\u05d5\u05de\u05d4\u05d4",
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB:
      '\u05dc\u05d5\u05d0\u05d9\u05d6\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HUN",
    AIRPORTNAME: " ",
    CITYCODE: "HUN",
    CITYNAME: "HUNGARY",
    CITYNAME_HEB: "\u05d4\u05d5\u05e0\u05d2\u05e8\u05d9\u05d4",
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HUQ",
    AIRPORTNAME: "Houn",
    CITYCODE: "HUQ",
    CITYNAME: "HOUN",
    CITYNAME_HEB: "HOUN",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "HUS",
    AIRPORTNAME: "Hughes",
    CITYCODE: "HUS",
    CITYNAME: "HUGHES",
    CITYNAME_HEB: "Hughes",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HUT",
    AIRPORTNAME: "Hutchinson",
    CITYCODE: "HUT",
    CITYNAME: "HUTCHINSON",
    CITYNAME_HEB: "HUTCHINSON",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "APB",
    AIRPORTNAME: "Apolo",
    CITYCODE: "APB",
    CITYNAME: "APOLO",
    CITYNAME_HEB: "APOLO",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HUU",
    AIRPORTNAME: "Huanuco",
    CITYCODE: "HUU",
    CITYNAME: "HUANUCO",
    CITYNAME_HEB: "HUANUCO",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "HUV",
    AIRPORTNAME: "Hudiksvall",
    CITYCODE: "HUV",
    CITYNAME: "HUDIKSVALL",
    CITYNAME_HEB: "HUDIKSVALL",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HUX",
    AIRPORTNAME: "Huatulco",
    CITYCODE: "HUX",
    CITYNAME: "HUATULCO",
    CITYNAME_HEB: "HUATULCO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "HUY",
    AIRPORTNAME: "Humberside Airport",
    CITYCODE: "HUY",
    CITYNAME: "HUMBERSIDE",
    CITYNAME_HEB: "HUMBERSIDE",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HUZ",
    AIRPORTNAME: "Huizhou",
    CITYCODE: "HUZ",
    CITYNAME: "HUIZHOU",
    CITYNAME_HEB: "HUIZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HVA",
    AIRPORTNAME: "Analalava",
    CITYCODE: "HVA",
    CITYNAME: "ANALALAVA",
    CITYNAME_HEB: "ANALALAVA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "HVB",
    AIRPORTNAME: "Hervey Bay",
    CITYCODE: "HVB",
    CITYNAME: "HERVEY BAY",
    CITYNAME_HEB: "HERVEY BAY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HVD",
    AIRPORTNAME: "Khovd",
    CITYCODE: "HVD",
    CITYNAME: "KHOVD",
    CITYNAME_HEB: "KHOVD",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HVE",
    AIRPORTNAME: "Intermediate",
    CITYCODE: "HVE",
    CITYNAME: "HANKSVILLE",
    CITYNAME_HEB: "HANKSVILLE",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DAC",
    AIRPORTNAME: "Zia International",
    CITYCODE: "DAC",
    CITYNAME: "DHAKA",
    CITYNAME_HEB: "\u05d3\u05e7\u05d4",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "DAD",
    AIRPORTNAME: "Da Nang",
    CITYCODE: "DAD",
    CITYNAME: "DA NANG",
    CITYNAME_HEB: "\u05d3\u05d4 \u05e0\u05d0\u05e0\u05d2",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "DAE",
    AIRPORTNAME: "Daparizo",
    CITYCODE: "DAE",
    CITYNAME: "DAPARIZO",
    CITYNAME_HEB: "DAPARIZO",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "DAF",
    AIRPORTNAME: "Daup",
    CITYCODE: "DAF",
    CITYNAME: "DAUP",
    CITYNAME_HEB: "DAUP",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "DAG",
    AIRPORTNAME: "Barstow-Daggett",
    CITYCODE: "DAG",
    CITYNAME: "DAGGETT",
    CITYNAME_HEB: "DAGGETT",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ACY",
    AIRPORTNAME: "Atlantic City International",
    CITYCODE: "AIY",
    CITYNAME: "ATLANTIC CITY",
    CITYNAME_HEB:
      "\u05d0\u05d8\u05dc\u05e0\u05d8\u05d9\u05e7 \u05e1\u05d9\u05d8\u05d9",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "DAH",
    AIRPORTNAME: "Dathina",
    CITYCODE: "DAH",
    CITYNAME: "DATHINA",
    CITYNAME_HEB: "DATHINA",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "DAI",
    AIRPORTNAME: "Darjeeling",
    CITYCODE: "DAI",
    CITYNAME: "DARJEELING",
    CITYNAME_HEB: "DARJEELING",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "DAJ",
    AIRPORTNAME: "Dauan Island",
    CITYCODE: "DAJ",
    CITYNAME: "DAUAN ISLAND",
    CITYNAME_HEB: "DAUAN ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DAM",
    AIRPORTNAME: "International",
    CITYCODE: "DAM",
    CITYNAME: "DAMASCUS",
    CITYNAME_HEB: "\u05d3\u05de\u05e9\u05e7",
    COUNTRYCODE: "SY",
    COUNTRYNAME: "SYRIAN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DAN",
    AIRPORTNAME: "Municipal",
    CITYCODE: "DAN",
    CITYNAME: "DANVILLE",
    CITYNAME_HEB: "DANVILLE",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "DAO",
    AIRPORTNAME: "Dabo",
    CITYCODE: "DAO",
    CITYNAME: "DABO",
    CITYNAME_HEB: "DABO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "DAP",
    AIRPORTNAME: "Darchula",
    CITYCODE: "DAP",
    CITYNAME: "DARCHULA",
    CITYNAME_HEB: "DARCHULA",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "DAR",
    AIRPORTNAME: "International",
    CITYCODE: "DAR",
    CITYNAME: "DAR ES SALAAM",
    CITYNAME_HEB: "\u05d3\u05e8 \u05d0\u05dc \u05e1\u05d0\u05dc\u05dd",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DAS",
    AIRPORTNAME: null,
    CITYCODE: "DAS",
    CITYNAME: "GREAT BEAR LAKE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DAT",
    AIRPORTNAME: "Datong",
    CITYCODE: "DAT",
    CITYNAME: "DATONG",
    CITYNAME_HEB: "DATONG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "AIY",
    AIRPORTNAME: "Bader Field",
    CITYCODE: "AIY",
    CITYNAME: "ATLANTIC CITY",
    CITYNAME_HEB: "Decatur-Pyor",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "DAU",
    AIRPORTNAME: "Daru",
    CITYCODE: "DAU",
    CITYNAME: "DARU",
    CITYNAME_HEB: "DARU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "DAV",
    AIRPORTNAME: "Enrique Malek",
    CITYCODE: "DAV",
    CITYNAME: "DAVID",
    CITYNAME_HEB: "DAVID",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "DAX",
    AIRPORTNAME: "Daxian",
    CITYCODE: "DAX",
    CITYNAME: "DAXIAN",
    CITYNAME_HEB: "DAXIAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "DAY",
    AIRPORTNAME: "James Cox Dayton International",
    CITYCODE: "DAY",
    CITYNAME: "DAYTON",
    CITYNAME_HEB: "\u05d3\u05d9\u05d9\u05d8\u05d5\u05df",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DWF",
    AIRPORTNAME: null,
    CITYCODE: "DAY",
    CITYNAME: "DAYTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FFO",
    AIRPORTNAME: null,
    CITYCODE: "DAY",
    CITYNAME: "DAYTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FLD",
    AIRPORTNAME: "Fond Du Lac",
    CITYCODE: "FLD",
    CITYNAME: "FOND DU LAC",
    CITYNAME_HEB: "FOND DU LAC",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FLF",
    AIRPORTNAME: "Schaferhaus",
    CITYCODE: "FLF",
    CITYNAME: "FLENSBURG",
    CITYNAME_HEB: "FLENSBURG",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FLH",
    AIRPORTNAME: "Flotta",
    CITYCODE: "FLH",
    CITYNAME: "FLOTTA",
    CITYNAME_HEB: "FLOTTA",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FLI",
    AIRPORTNAME: "Flateyri",
    CITYCODE: "FLI",
    CITYNAME: "FLATEYRI",
    CITYNAME_HEB: "FLATEYRI",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "FLJ",
    AIRPORTNAME: "Falls Bay",
    CITYCODE: "FLJ",
    CITYNAME: "FALLS BAY",
    CITYNAME_HEB: "Falls Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FLL",
    AIRPORTNAME: "International",
    CITYCODE: "FLL",
    CITYNAME: "FORT LAUDERDALE",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05e8\u05d8 \u05dc\u05d5\u05d3\u05e8\u05d3\u05d9\u05d9\u05dc",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FXE",
    AIRPORTNAME: null,
    CITYCODE: "FLL",
    CITYNAME: "FORT LAUDERDALE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ALZ",
    AIRPORTNAME: "Alitak SPB",
    CITYCODE: "ALZ",
    CITYNAME: "ALITAK",
    CITYNAME_HEB: "Alitak SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZFT",
    AIRPORTNAME: null,
    CITYCODE: "FLL",
    CITYNAME: "FORT LAUDERDALE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FLM",
    AIRPORTNAME: "Filadelfia",
    CITYCODE: "FLM",
    CITYNAME: "FILADELFIA",
    CITYNAME_HEB: "FILADELFIA",
    COUNTRYCODE: "PY",
    COUNTRYNAME: "PARAGUAY",
    COUNTRYNAME_HEB: "\u05e4\u05d0\u05e8\u05d0\u05d2\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "FLN",
    AIRPORTNAME: null,
    CITYCODE: "FLN",
    CITYNAME: "FLORIANOPOLIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FLP",
    AIRPORTNAME: "Flippin",
    CITYCODE: "FLP",
    CITYNAME: "FLIPPIN",
    CITYNAME_HEB: "FLIPPIN",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FLR",
    AIRPORTNAME: "Peretola",
    CITYCODE: "FLR",
    CITYNAME: "FLORENCE",
    CITYNAME_HEB: "\u05e4\u05d9\u05e8\u05e0\u05e6\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FLS",
    AIRPORTNAME: null,
    CITYCODE: "FLS",
    CITYNAME: "FLINDERS ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUTS",
    COUNTRYNAME: "TASMANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FLT",
    AIRPORTNAME: "Flat",
    CITYCODE: "FLT",
    CITYNAME: "FLAT",
    CITYNAME_HEB: "Flat",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AMA",
    AIRPORTNAME: "Rick Husband Amarillo International Airport",
    CITYCODE: "AMA",
    CITYNAME: "AMARILLO",
    CITYNAME_HEB: "AMARILLO",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FLV",
    AIRPORTNAME: "Sherman AAF",
    CITYCODE: "FLV",
    CITYNAME: "FORT LEAVENWORTH",
    CITYNAME_HEB: "FORT LEAVENWORTH",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FLW",
    AIRPORTNAME: "Santa Cruz",
    CITYCODE: "FLW",
    CITYNAME: "FLORES ISLAND",
    CITYNAME_HEB: "FLORES ISLAND",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "FLY",
    AIRPORTNAME: "Finley",
    CITYCODE: "FLY",
    CITYNAME: "FINLEY",
    CITYNAME_HEB: "FINLEY",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FMA",
    AIRPORTNAME: null,
    CITYCODE: "FMA",
    CITYNAME: "FORMOSA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FMC",
    AIRPORTNAME: "Five Mile",
    CITYCODE: "FMC",
    CITYNAME: "FIVE MILE",
    CITYNAME_HEB: "Five Mile",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FME",
    AIRPORTNAME: "Tipton AAF",
    CITYCODE: "FME",
    CITYNAME: "FORT MEADE",
    CITYNAME_HEB: "FORT MEADE",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FMG",
    AIRPORTNAME: "Flamingo",
    CITYCODE: "FMG",
    CITYNAME: "FLAMINGO",
    CITYNAME_HEB: "FLAMINGO",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "FMH",
    AIRPORTNAME: "Otis AFB",
    CITYCODE: "FMH",
    CITYNAME: "FALMOUTH",
    CITYNAME_HEB: "FALMOUTH",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "FMI",
    AIRPORTNAME: "Kalemie",
    CITYCODE: "FMI",
    CITYNAME: "KALEMIE",
    CITYNAME_HEB: "KALEMIE",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "FMN",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FMN",
    CITYNAME: "FARMINGTON",
    CITYNAME_HEB: "FARMINGTON",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TDW",
    AIRPORTNAME: null,
    CITYCODE: "AMA",
    CITYNAME: "AMARILLO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FMO",
    AIRPORTNAME: "Flughafen M?nster/Osnabr?ck",
    CITYCODE: "FMO",
    CITYNAME: "MUENSTER",
    CITYNAME_HEB: "\u05de\u05d5\u05e0\u05e1\u05d8\u05e8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FMS",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FMS",
    CITYNAME: "FORT MADISON",
    CITYNAME_HEB: "FORT MADISON",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FMY",
    AIRPORTNAME: "Page Field",
    CITYCODE: "FMY",
    CITYNAME: "FORT MYERS",
    CITYNAME_HEB: "FORT MYERS",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RSW",
    AIRPORTNAME: "Southwest Florida Reg",
    CITYCODE: "FMY",
    CITYNAME: "FORT MYERS",
    CITYNAME_HEB: "FORT MYERS",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FNA",
    AIRPORTNAME: "Lungi International",
    CITYCODE: "FNA",
    CITYNAME: "FREETOWN",
    CITYNAME_HEB: "\u05e4\u05e8\u05d9\u05d8\u05d0\u05d5\u05df",
    COUNTRYCODE: "SL",
    COUNTRYNAME: "SIERRA LEONE",
    COUNTRYNAME_HEB:
      "\u05e1\u05d9\u05d9\u05e8\u05d4 \u05dc\u05d9\u05d0\u05d5\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "JMY",
    AIRPORTNAME: null,
    CITYCODE: "FNA",
    CITYNAME: "FREETOWN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SL",
    COUNTRYNAME: "SIERRA LEONE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FNB",
    AIRPORTNAME: "Neubrandenburg",
    CITYCODE: "FNB",
    CITYNAME: "NEUBRANDENBURG",
    CITYNAME_HEB:
      "\u05e0\u05d9\u05d5\u05d1\u05e8\u05d0\u05e0\u05d3\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FNC",
    AIRPORTNAME: "Funchal",
    CITYCODE: "FNC",
    CITYNAME: "FUNCHAL",
    CITYNAME_HEB: "\u05e4\u05d5\u05e0\u05e6\u05d0\u05dc",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "AMB",
    AIRPORTNAME: "Ambilobe",
    CITYCODE: "AMB",
    CITYNAME: "AMBILOBE",
    CITYNAME_HEB: "AMBILOBE",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "FNE",
    CITYNAME: "FLAINE",
    CITYNAME_HEB: "\u05e4\u05dc\u05d0\u05d9\u05d9\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FNG",
    AIRPORTNAME: "Fada Ngourma",
    CITYCODE: "FNG",
    CITYNAME: "FADA NGOURMA",
    CITYNAME_HEB: "FADA NGOURMA",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "FNH",
    AIRPORTNAME: "Fincha",
    CITYCODE: "FNH",
    CITYNAME: "FINCHA",
    CITYNAME_HEB: "FINCHA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FNI",
    AIRPORTNAME: "Garons",
    CITYCODE: "FNI",
    CITYNAME: "NIMES",
    CITYNAME_HEB: "\u05e0\u05d9\u05de\u05e1",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "FNJ",
    AIRPORTNAME: "Sunan",
    CITYCODE: "FNJ",
    CITYNAME: "PYONGYANG",
    CITYNAME_HEB: "\u05e4\u05e0\u05d9\u05d5\u05e0\u05d2",
    COUNTRYCODE: "KP",
    COUNTRYNAME: "KOREA DEM PEOPLES REP OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "FNK",
    AIRPORTNAME: "Fin Creek",
    CITYCODE: "FNK",
    CITYNAME: "FIN CREEK",
    CITYNAME_HEB: "Fin Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FNL",
    AIRPORTNAME: "Municipal Airport",
    CITYCODE: "FNL",
    CITYNAME: "FORT COLLINS LOVE",
    CITYNAME_HEB: "FORT COLLINS LOVE",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QWF",
    AIRPORTNAME: null,
    CITYCODE: "FNL",
    CITYNAME: "FORT COLLINS LOVE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWH",
    AIRPORTNAME: null,
    CITYCODE: "FNL",
    CITYNAME: "FORT COLLINS LOVE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FNR",
    AIRPORTNAME: "Funter Bay",
    CITYCODE: "FNR",
    CITYNAME: "FUNTER BAY",
    CITYNAME_HEB: "Funter Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AMC",
    AIRPORTNAME: "Am Timan",
    CITYCODE: "AMC",
    CITYNAME: "AM TIMAN",
    CITYNAME_HEB: "AM TIMAN",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "FNT",
    AIRPORTNAME: "Bishop",
    CITYCODE: "FNT",
    CITYNAME: "FLINT",
    CITYNAME_HEB: "FLINT",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FOA",
    AIRPORTNAME: "Foula",
    CITYCODE: "FOA",
    CITYNAME: "FOULA",
    CITYNAME_HEB: "FOULA",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FOB",
    AIRPORTNAME: "Fort Bragg",
    CITYCODE: "FOB",
    CITYNAME: "FORT BRAGG",
    CITYNAME_HEB: "FORT BRAGG",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FOC",
    AIRPORTNAME: "Fuzhou",
    CITYCODE: "FOC",
    CITYNAME: "FUZHOU",
    CITYNAME_HEB: "FUZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "FOD",
    AIRPORTNAME: "Fort Dodge",
    CITYCODE: "FOD",
    CITYNAME: "FORT DODGE",
    CITYNAME_HEB: "FORT DODGE",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FOG",
    AIRPORTNAME: "Gino Lisa",
    CITYCODE: "FOG",
    CITYNAME: "FOGGIA",
    CITYNAME_HEB: "\u05e4\u05d5\u05d2\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FOK",
    AIRPORTNAME: "Suffolk County",
    CITYCODE: "FOK",
    CITYNAME: "WESTHAMPTON",
    CITYNAME_HEB: "WESTHAMPTON",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FOM",
    AIRPORTNAME: "Foumban",
    CITYCODE: "FOM",
    CITYNAME: "FOUMBAN",
    CITYNAME_HEB: "FOUMBAN",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "FON",
    AIRPORTNAME: "Fortuna",
    CITYCODE: "FON",
    CITYNAME: "FORTUNA",
    CITYNAME_HEB: "FORTUNA",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "FOO",
    AIRPORTNAME: "Numfoor",
    CITYCODE: "FOO",
    CITYNAME: "NUMFOOR",
    CITYNAME_HEB: "NUMFOOR",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ABE",
    AIRPORTNAME: "Allentown Bthlehm",
    CITYCODE: "ABE",
    CITYNAME: "ALLENTOWN BTHLEHM",
    CITYNAME_HEB: "Allentown Bthlehm",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AMD",
    AIRPORTNAME: "Sardar Vallabhbhai Patel International Airport",
    CITYCODE: "AMD",
    CITYNAME: "AHMEDABAD",
    CITYNAME_HEB: "AHMEDABAD",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "FOP",
    AIRPORTNAME: "Morris AAF",
    CITYCODE: "FOP",
    CITYNAME: "FOREST PARK",
    CITYNAME_HEB: "FOREST PARK",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "FOR",
    AIRPORTNAME: " ",
    CITYCODE: "FOR",
    CITYNAME: "FORTALEZA",
    CITYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d0\u05dc\u05d6\u05d4",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "FOS",
    AIRPORTNAME: "Forrest",
    CITYCODE: "FOS",
    CITYNAME: "FORREST",
    CITYNAME_HEB: "FORREST",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FOT",
    AIRPORTNAME: "Forster",
    CITYCODE: "FOT",
    CITYNAME: "FORSTER",
    CITYNAME_HEB: "FORSTER",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FOU",
    AIRPORTNAME: "Fougamou",
    CITYCODE: "FOU",
    CITYNAME: "FOUGAMOU",
    CITYNAME_HEB: "FOUGAMOU",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "FOX",
    AIRPORTNAME: "Fox",
    CITYCODE: "FOX",
    CITYNAME: "FOX",
    CITYNAME_HEB: "Fox",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FOY",
    AIRPORTNAME: "Foya",
    CITYCODE: "FOY",
    CITYNAME: "FOYA",
    CITYNAME_HEB: "FOYA",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FPO",
    AIRPORTNAME: "Grand Bahama International",
    CITYCODE: "FPO",
    CITYNAME: "FREEPORT",
    CITYNAME_HEB: "FREEPORT",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "FPR",
    AIRPORTNAME: "St Lucie County",
    CITYCODE: "FPR",
    CITYNAME: "FORT PIERCE",
    CITYNAME_HEB: "FORT PIERCE",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FPY",
    AIRPORTNAME: "Perry-Foley",
    CITYCODE: "FPY",
    CITYNAME: "PERRY",
    CITYNAME_HEB: "PERRY",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "AME",
    CITYNAME: "BAHAMAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FQQ",
    AIRPORTNAME: null,
    CITYCODE: "FQQ",
    CITYNAME: "SIGHTSEEING FLIGHT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FRA",
    AIRPORTNAME: null,
    CITYCODE: "FRA",
    CITYNAME: "FRANKFURT",
    CITYNAME_HEB: "\u05e4\u05e8\u05e0\u05e7\u05e4\u05d5\u05e8\u05d8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FRF",
    AIRPORTNAME: " ",
    CITYCODE: "FRA",
    CITYNAME: "FRANKFURT",
    CITYNAME_HEB: "\u05e4\u05e8\u05e0\u05e7\u05e4\u05d5\u05e8\u05d8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HHN",
    AIRPORTNAME: " ",
    CITYCODE: "FRA",
    CITYNAME: "FRANKFURT",
    CITYNAME_HEB: "\u05e4\u05e8\u05e0\u05e7\u05e4\u05d5\u05e8\u05d8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QGV",
    AIRPORTNAME: " ",
    CITYCODE: "FRA",
    CITYNAME: "FRANKFURT",
    CITYNAME_HEB: "\u05e4\u05e8\u05e0\u05e7\u05e4\u05d5\u05e8\u05d8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZRB",
    AIRPORTNAME: " ",
    CITYCODE: "FRA",
    CITYNAME: "FRANKFURT",
    CITYNAME_HEB: "\u05e4\u05e8\u05e0\u05e7\u05e4\u05d5\u05e8\u05d8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FRB",
    AIRPORTNAME: "Forbes",
    CITYCODE: "FRB",
    CITYNAME: "FORBES",
    CITYNAME_HEB: "FORBES",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FRC",
    AIRPORTNAME: null,
    CITYCODE: "FRC",
    CITYNAME: "FRANCA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FRD",
    AIRPORTNAME: "Friday Harbor",
    CITYCODE: "FRD",
    CITYNAME: "FRIDAY HARBOR",
    CITYNAME_HEB: "FRIDAY HARBOR",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FRE",
    AIRPORTNAME: " ",
    CITYCODE: "FRE",
    CITYNAME: "FIEBERBRUNN",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AMF",
    AIRPORTNAME: "Ama",
    CITYCODE: "AMF",
    CITYNAME: "AMA",
    CITYNAME_HEB: "AMA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "FRG",
    AIRPORTNAME: "Republic Field",
    CITYCODE: "FRG",
    CITYNAME: "FARMINGDALE",
    CITYNAME_HEB: "FARMINGDALE",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FRH",
    AIRPORTNAME: " ",
    CITYCODE: "FRH",
    CITYNAME: "FRANCE",
    CITYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "FRI",
    AIRPORTNAME: "Marshall AAF",
    CITYCODE: "FRI",
    CITYNAME: "FORT RILEY",
    CITYNAME_HEB: "FORT RILEY",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FRJ",
    AIRPORTNAME: "Frejus",
    CITYCODE: "FRJ",
    CITYNAME: "FREJUS",
    CITYNAME_HEB: "FREJUS",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "FRK",
    AIRPORTNAME: "Fregate Is",
    CITYCODE: "FRK",
    CITYNAME: "FREGATE IS",
    CITYNAME_HEB: "FREGATE IS",
    COUNTRYCODE: "SC",
    COUNTRYNAME: "SEYCHELLES ISLANDS",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05d9\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "FRL",
    AIRPORTNAME: "Luigi Ridolfi",
    CITYCODE: "FRL",
    CITYNAME: "FORLI",
    CITYNAME_HEB: "\u05e4\u05d5\u05e8\u05dc\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FRM",
    AIRPORTNAME: "Fairmont",
    CITYCODE: "FRM",
    CITYNAME: "FAIRMONT",
    CITYNAME_HEB: "\u05e4\u05d9\u05e8\u05de\u05d5\u05e0\u05d8",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FRN",
    AIRPORTNAME: " ",
    CITYCODE: "FRN",
    CITYNAME: "FRANTISKOVY LAZNE",
    CITYNAME_HEB:
      "\u05e4\u05e8\u05e0\u05d8\u05d9\u05e1\u05e7\u05d5\u05d1\u05d9 \u05dc\u05d6\u05e0\u05d4",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FRO",
    AIRPORTNAME: "Flora",
    CITYCODE: "FRO",
    CITYNAME: "FLORO",
    CITYNAME_HEB: "FLORO",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FRP",
    AIRPORTNAME: "Fresh Water Bay",
    CITYCODE: "FRP",
    CITYNAME: "FRESH WATER BAY",
    CITYNAME_HEB: "Fresh Water Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AMG",
    AIRPORTNAME: "Amboin",
    CITYCODE: "AMG",
    CITYNAME: "AMBOIN",
    CITYNAME_HEB: "AMBOIN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "FRQ",
    AIRPORTNAME: "Feramin",
    CITYCODE: "FRQ",
    CITYNAME: "FERAMIN",
    CITYNAME_HEB: "FERAMIN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "FRR",
    AIRPORTNAME: "Warren County",
    CITYCODE: "FRR",
    CITYNAME: "FRONT ROYAL",
    CITYNAME_HEB: "FRONT ROYAL",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "FRS",
    AIRPORTNAME: "Santa Elena",
    CITYCODE: "FRS",
    CITYNAME: "FLORES",
    CITYNAME_HEB: "FLORES",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "FRT",
    AIRPORTNAME: "Frutillar",
    CITYCODE: "FRT",
    CITYNAME: "FRUTILLAR",
    CITYNAME_HEB: "FRUTILLAR",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "FRU",
    AIRPORTNAME: "Manas International",
    CITYCODE: "FRU",
    CITYNAME: "BISHKEK",
    CITYNAME_HEB: "\u05d1\u05d9\u05e9\u05e7\u05e7",
    COUNTRYCODE: "KG",
    COUNTRYNAME: "KYRGYZSTAN",
    COUNTRYNAME_HEB:
      "\u05e7\u05d9\u05e8\u05d2\u05d9\u05e1\u05d9\u05e1\u05d8\u05d0\u05df",
  },
  {
    AIRPORTCODE: "FRW",
    AIRPORTNAME: "Francistown",
    CITYCODE: "FRW",
    CITYNAME: "FRANCISTOWN",
    CITYNAME_HEB: "FRANCISTOWN",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "FRY",
    AIRPORTNAME: "Fryeburg",
    CITYCODE: "FRY",
    CITYNAME: "FRYEBURG",
    CITYNAME_HEB: "FRYEBURG",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FRZ",
    AIRPORTNAME: "Fritzlar Airbase",
    CITYCODE: "FRZ",
    CITYNAME: "FRITZLAR",
    CITYNAME_HEB: "FRITZLAR",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FSC",
    AIRPORTNAME: "Sud Corse",
    CITYCODE: "FSC",
    CITYNAME: "FIGARI",
    CITYNAME_HEB: "\u05e4\u05d9\u05d2\u05d0\u05e8\u05d9",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "FSD",
    AIRPORTNAME: "Regional(Jo Foss Fld)",
    CITYCODE: "FSD",
    CITYNAME: "SIOUX FALLS",
    CITYNAME_HEB: "SIOUX FALLS",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AMH",
    AIRPORTNAME: "Arba Mintch",
    CITYCODE: "AMH",
    CITYNAME: "ARBA MINTCH",
    CITYNAME_HEB: "\u05d0\u05e8\u05d1\u05d4 \u05de\u05d9\u05e0\u05da",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FSI",
    AIRPORTNAME: "Henry Post AAF",
    CITYCODE: "FSI",
    CITYNAME: "FORT SILL",
    CITYNAME_HEB: "FORT SILL",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FSK",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FSK",
    CITYNAME: "FORT SCOTT",
    CITYNAME_HEB: "FORT SCOTT",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FSL",
    AIRPORTNAME: "Fossil Downs",
    CITYCODE: "FSL",
    CITYNAME: "FOSSIL DOWNS",
    CITYNAME_HEB: "FOSSIL DOWNS",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FSM",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FSM",
    CITYNAME: "FORT SMITH",
    CITYNAME_HEB: "FORT SMITH",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FSN",
    AIRPORTNAME: "Haley AAF",
    CITYCODE: "FSN",
    CITYNAME: "FORT SHERIDAN",
    CITYNAME_HEB: "FORT SHERIDAN",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FSP",
    AIRPORTNAME: null,
    CITYCODE: "FSP",
    CITYNAME: "SAINT PIERRE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PM",
    COUNTRYNAME: "ST. PIERRE AND MIQUELON",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FSS",
    AIRPORTNAME: "Kinloss",
    CITYCODE: "FSS",
    CITYNAME: "FORRES",
    CITYNAME_HEB: "FORRES",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FST",
    AIRPORTNAME: "Pecos County",
    CITYCODE: "FST",
    CITYNAME: "FORT STOCKTON",
    CITYNAME_HEB: "FORT STOCKTON",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FSU",
    AIRPORTNAME: "Fort Sumner",
    CITYCODE: "FSU",
    CITYNAME: "FORT SUMNER",
    CITYNAME_HEB: "FORT SUMNER",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FTA",
    AIRPORTNAME: "Futuna Airport",
    CITYCODE: "FTA",
    CITYNAME: "FUTUNA ISLAND",
    CITYNAME_HEB: "FUTUNA ISLAND",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "AMI",
    AIRPORTNAME: "Selaparang",
    CITYCODE: "AMI",
    CITYNAME: "MATARAM",
    CITYNAME_HEB: "MATARAM",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FTE",
    AIRPORTNAME: " ",
    CITYCODE: "FTE",
    CITYNAME: "EL CALAFATE",
    CITYNAME_HEB: "\u05d0\u05dc \u05e7\u05d0\u05dc\u05e4\u05d8\u05d4",
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05d2\u05e0\u05d8\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "FTI",
    AIRPORTNAME: "Fitiuta",
    CITYCODE: "FTI",
    CITYNAME: "FITIUTA",
    CITYNAME_HEB: "FITIUTA",
    COUNTRYCODE: "AS",
    COUNTRYNAME: "AMERICAN SAMOA",
    COUNTRYNAME_HEB:
      "\u05e1\u05de\u05d5\u05d0\u05d4 \u05d4\u05de\u05e2\u05e8\u05d1\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "FTK",
    AIRPORTNAME: "Godman AAF",
    CITYCODE: "FTK",
    CITYNAME: "FORT KNOX",
    CITYNAME_HEB: "FORT KNOX",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FTL",
    AIRPORTNAME: "Fortuna Ledge",
    CITYCODE: "FTL",
    CITYNAME: "FORTUNA LEDGE",
    CITYNAME_HEB: "Fortuna Ledge",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FTU",
    AIRPORTNAME: "Marillac",
    CITYCODE: "FTU",
    CITYNAME: "FORT DAUPHIN",
    CITYNAME_HEB: "FORT DAUPHIN",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "FTX",
    AIRPORTNAME: "Owando",
    CITYCODE: "FTX",
    CITYNAME: "OWANDO",
    CITYNAME_HEB: "OWANDO",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "FUB",
    AIRPORTNAME: "Fulleborn",
    CITYCODE: "FUB",
    CITYNAME: "FULLEBORN",
    CITYNAME_HEB: "FULLEBORN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "FUE",
    AIRPORTNAME: "Fuerteventura",
    CITYCODE: "FUE",
    CITYNAME: "FUERTEVENTURA",
    CITYNAME_HEB: "FUERTEVENTURA",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "FUG",
    AIRPORTNAME: " ",
    CITYCODE: "FUG",
    CITYNAME: "FUGEN",
    CITYNAME_HEB: "\u05e4\u05d5\u05d2\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FUJ",
    AIRPORTNAME: "Fukue",
    CITYCODE: "FUJ",
    CITYNAME: "FUKUE",
    CITYNAME_HEB: "FUKUE",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "AMJ",
    AIRPORTNAME: null,
    CITYCODE: "AMJ",
    CITYNAME: "ALMENARA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FUK",
    AIRPORTNAME: "Fukuoka",
    CITYCODE: "FUK",
    CITYNAME: "FUKUOKA",
    CITYNAME_HEB: "\u05e4\u05d5\u05e7\u05d5\u05d0\u05d5\u05e7\u05d4",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "FUL",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FUL",
    CITYNAME: "FULLERTON",
    CITYNAME_HEB: "FULLERTON",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FUM",
    AIRPORTNAME: "Fuma",
    CITYCODE: "FUM",
    CITYNAME: "FUMA",
    CITYNAME_HEB: "FUMA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "FUN",
    AIRPORTNAME: null,
    CITYCODE: "FUN",
    CITYNAME: "FUNAFUTI ATOL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TV",
    COUNTRYNAME: "TUVALU",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FUO",
    AIRPORTNAME: "Fuoshan",
    CITYCODE: "FUO",
    CITYNAME: "FUOSHAN",
    CITYNAME_HEB: "\u05e4\u05d5\u05e9\u05d0\u05df",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "FUR",
    CITYNAME: "BUKFURDO BIRDLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FUT",
    AIRPORTNAME: null,
    CITYCODE: "FUT",
    CITYNAME: "FUTUNA ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "WF",
    COUNTRYNAME: "WALLIS AND FUTUNA ISLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FVL",
    AIRPORTNAME: "Flora Valey",
    CITYCODE: "FVL",
    CITYNAME: "FLORA VALLEY",
    CITYNAME_HEB: "FLORA VALLEY",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FVR",
    AIRPORTNAME: "Forrest River Airport",
    CITYCODE: "FVR",
    CITYNAME: "FORREST RIVER",
    CITYNAME_HEB: "FORREST RIVER",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FWA",
    AIRPORTNAME: "Municipal/Baer Field",
    CITYCODE: "FWA",
    CITYNAME: "FORT WAYNE",
    CITYNAME_HEB: "FORT WAYNE",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AML",
    AIRPORTNAME: "Puerto Armuellas",
    CITYCODE: "AML",
    CITYNAME: "PUERTO ARMUELLAS",
    CITYNAME_HEB: "PUERTO ARMUELLAS",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "SMD",
    AIRPORTNAME: "Smith Field",
    CITYCODE: "FWA",
    CITYNAME: "FORT WAYNE",
    CITYNAME_HEB: "FORT WAYNE",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FWL",
    AIRPORTNAME: "Farewell",
    CITYCODE: "FWL",
    CITYNAME: "FAREWELL",
    CITYNAME_HEB: "Farewell",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FWM",
    AIRPORTNAME: "Heliport",
    CITYCODE: "FWM",
    CITYNAME: "FORT WILLIAM",
    CITYNAME_HEB: "FORT WILLIAM",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FXM",
    AIRPORTNAME: "Flaxman Island",
    CITYCODE: "FXM",
    CITYNAME: "FLAXMAN ISLAND",
    CITYNAME_HEB: "Flaxman Island",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FXO",
    AIRPORTNAME: "Cuamba",
    CITYCODE: "FXO",
    CITYNAME: "CUAMBA",
    CITYNAME_HEB: "CUAMBA",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "FXY",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FXY",
    CITYNAME: "FOREST CITY",
    CITYNAME_HEB: "FOREST CITY",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FYM",
    AIRPORTNAME: "Municipal",
    CITYCODE: "FYM",
    CITYNAME: "FAYETTEVILLE",
    CITYNAME_HEB: "FAYETTEVILLE",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FYN",
    AIRPORTNAME: "Fuyun",
    CITYCODE: "FYN",
    CITYNAME: "FUYUN",
    CITYNAME_HEB: "FUYUN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "FYT",
    AIRPORTNAME: "Faya",
    CITYCODE: "FYT",
    CITYNAME: "FAYA",
    CITYNAME_HEB: "FAYA",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "FYU",
    AIRPORTNAME: "Fort Yukon",
    CITYCODE: "FYU",
    CITYNAME: "FORT YUKON",
    CITYNAME_HEB: "Fort Yukon",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ADJ",
    AIRPORTNAME: "Marka International Airport",
    CITYCODE: "AMM",
    CITYNAME: "AMMAN",
    CITYNAME_HEB: "\u05e2\u05de\u05d0\u05df",
    COUNTRYCODE: "JO",
    COUNTRYNAME: "JORDAN",
    COUNTRYNAME_HEB: "\u05d9\u05e8\u05d3\u05df",
  },
  {
    AIRPORTCODE: "FYV",
    AIRPORTNAME: "Municipal (Drake Fld)",
    CITYCODE: "FYV",
    CITYNAME: "FAYETTEVILLE",
    CITYNAME_HEB: "FAYETTEVILLE",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XNA",
    AIRPORTNAME: "Northwest Arkansas Rgn",
    CITYCODE: "FYV",
    CITYNAME: "FAYETTEVILLE",
    CITYNAME_HEB: "FAYETTEVILLE",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FZO",
    AIRPORTNAME: "Filton",
    CITYCODE: "FZO",
    CITYNAME: "FILTON",
    CITYNAME_HEB: "FILTON",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GAA",
    AIRPORTNAME: "Guamal",
    CITYCODE: "GAA",
    CITYNAME: "GUAMAL",
    CITYNAME_HEB: "GUAMAL",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GAB",
    AIRPORTNAME: "Gabbs",
    CITYCODE: "GAB",
    CITYNAME: "GABBS",
    CITYNAME_HEB: "GABBS",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GAC",
    AIRPORTNAME: "Gracias",
    CITYCODE: "GAC",
    CITYNAME: "GRACIAS",
    CITYNAME_HEB: "GRACIAS",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "GAD",
    AIRPORTNAME: "Gadsden",
    CITYCODE: "GAD",
    CITYNAME: "GADSDEN",
    CITYNAME_HEB: "Gadsden",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GAE",
    AIRPORTNAME: "Gabes",
    CITYCODE: "GAE",
    CITYNAME: "GABES",
    CITYNAME_HEB: "GABES",
    COUNTRYCODE: "TN",
    COUNTRYNAME: "TUNISIA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d9\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GAF",
    AIRPORTNAME: "Gafsa",
    CITYCODE: "GAF",
    CITYNAME: "GAFSA",
    CITYNAME_HEB: "GAFSA",
    COUNTRYCODE: "TN",
    COUNTRYNAME: "TUNISIA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d9\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GAG",
    AIRPORTNAME: "Gage",
    CITYCODE: "GAG",
    CITYNAME: "GAGE",
    CITYNAME_HEB: "GAGE",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AMM",
    AIRPORTNAME: "Queen Alia International Airport",
    CITYCODE: "AMM",
    CITYNAME: "AMMAN",
    CITYNAME_HEB: "\u05e2\u05de\u05d0\u05df",
    COUNTRYCODE: "JO",
    COUNTRYNAME: "JORDAN",
    COUNTRYNAME_HEB: "\u05d9\u05e8\u05d3\u05df",
  },
  {
    AIRPORTCODE: "GAH",
    AIRPORTNAME: "Gayndah",
    CITYCODE: "GAH",
    CITYNAME: "GAYNDAH",
    CITYNAME_HEB: "GAYNDAH",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GAI",
    AIRPORTNAME: "Montgomery County",
    CITYCODE: "GAI",
    CITYNAME: "GAITHERSBURG",
    CITYNAME_HEB: "GAITHERSBURG",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GAJ",
    AIRPORTNAME: "Junmachi",
    CITYCODE: "GAJ",
    CITYNAME: "YAMAGATA",
    CITYNAME_HEB: "YAMAGATA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "GAK",
    AIRPORTNAME: "Gakona",
    CITYCODE: "GAK",
    CITYNAME: "GAKONA",
    CITYNAME_HEB: "Gakona",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GAL",
    AIRPORTNAME: " ",
    CITYCODE: "GAL",
    CITYNAME: "GALTUR",
    CITYNAME_HEB: "\u05d2\u05dc\u05d8\u05d5\u05e8",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GAM",
    AIRPORTNAME: "Gambell",
    CITYCODE: "GAM",
    CITYNAME: "GAMBELL",
    CITYNAME_HEB: "Gambell",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GAN",
    AIRPORTNAME: "Gan/Seenu",
    CITYCODE: "GAN",
    CITYNAME: "GAN ISLAND",
    CITYNAME_HEB: "GAN ISLAND",
    COUNTRYCODE: "MV",
    COUNTRYNAME: "MALDIVES ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d3\u05d9\u05d1\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "GAO",
    AIRPORTNAME: "Los Canos",
    CITYCODE: "GAO",
    CITYNAME: "GUANTANAMO",
    CITYNAME_HEB: "GUANTANAMO",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "NBW",
    AIRPORTNAME: null,
    CITYCODE: "GAO",
    CITYNAME: "GUANTANAMO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GAP",
    AIRPORTNAME: "Gusap",
    CITYCODE: "GAP",
    CITYNAME: "GUSAP",
    CITYNAME_HEB: "GUSAP",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ABG",
    AIRPORTNAME: null,
    CITYCODE: "ABG",
    CITYNAME: "ABINGDON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AMN",
    AIRPORTNAME: "Gratiot Community",
    CITYCODE: "AMN",
    CITYNAME: "ALMA",
    CITYNAME_HEB: "ALMA",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GAQ",
    AIRPORTNAME: "Gao",
    CITYCODE: "GAQ",
    CITYNAME: "GAO",
    CITYNAME_HEB: "GAO",
    COUNTRYCODE: "ML",
    COUNTRYNAME: "MALI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d9",
  },
  {
    AIRPORTCODE: "GAR",
    AIRPORTNAME: "Garaina",
    CITYCODE: "GAR",
    CITYNAME: "GARAINA",
    CITYNAME_HEB: "GARAINA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GAS",
    AIRPORTNAME: "Garissa",
    CITYCODE: "GAS",
    CITYNAME: "GARISSA",
    CITYNAME_HEB: "GARISSA",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GAT",
    AIRPORTNAME: "Tallard",
    CITYCODE: "GAT",
    CITYNAME: "GAP",
    CITYNAME_HEB: "GAP",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "FUA",
    CITYNAME: "SANTA CHRISTINA",
    CITYNAME_HEB:
      "\u05e1\u05e0\u05d8\u05d4 \u05db\u05e8\u05e1\u05d8\u05d9\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "FUC",
    CITYNAME: "CANMORE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "FUS",
    CITYNAME: "SUN PEAKS RESORT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "FUD",
    CITYNAME: "TORBOLE",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GXQ",
    AIRPORTNAME: "Ten. Vidal",
    CITYCODE: "GXQ",
    CITYNAME: "COYHAIQUE",
    CITYNAME_HEB: "COYHAIQUE",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "FUH",
    CITYNAME: "SCHUTTDORF",
    CITYNAME_HEB: "\u05e9\u05d8\u05d5\u05d3\u05e8\u05d5\u05e3",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FUI",
    AIRPORTNAME: " ",
    CITYCODE: "FUI",
    CITYNAME: "SMRADAKY",
    CITYNAME_HEB: "\u05e1\u05de\u05e8\u05d3\u05e7\u05d9",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TUI",
    AIRPORTNAME: " ",
    CITYCODE: "TUI",
    CITYNAME: "TAORMINA",
    CITYNAME_HEB: "\u05d8\u05d0\u05e8\u05d5\u05de\u05d9\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "FUP",
    CITYNAME: "MONIGA DEL GARDA",
    CITYNAME_HEB:
      "\u05de\u05d5\u05e0\u05d9\u05d2\u05d4 \u05d3\u05dc \u05d2\u05e8\u05d3\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "LLO",
    CITYNAME: "LAKE LOUISE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "FUQ",
    CITYNAME: "BELEM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FUU",
    AIRPORTNAME: " ",
    CITYCODE: "FUU",
    CITYNAME: "SAINT JAMES",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "BB",
    COUNTRYNAME: "BARBADOS",
    COUNTRYNAME_HEB: " ",
  },
  {
    AIRPORTCODE: "GXX",
    AIRPORTNAME: "Yagoua",
    CITYCODE: "GXX",
    CITYNAME: "YAGOUA",
    CITYNAME_HEB: "YAGOUA",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "FUV",
    CITYNAME: "GELDROP",
    CITYNAME_HEB: "\u05d2\u05dc\u05d3\u05e8\u05d5\u05e4",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "VCS",
    CITYNAME: "BUGIBBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "MT",
    COUNTRYNAME: "MALTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "VCJ",
    CITYNAME: "SAN SIMEON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "FUW",
    CITYNAME: "TOSSA DE MAR",
    CITYNAME_HEB: "\u05d8\u05d5\u05e1\u05e0 \u05d3\u05d4 \u05de\u05d0\u05e8",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "FUX",
    CITYNAME: "KOPAONIK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TIT",
    AIRPORTNAME: " ",
    CITYCODE: "TIT",
    CITYNAME: "GARDONE RIVIERA",
    CITYNAME_HEB: "\u05d2\u05e8\u05d3\u05d5\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "VIO",
    CITYNAME: "VIAREGGIO",
    CITYNAME_HEB: "\u05d5\u05d9\u05e8\u05d2\u05d9\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "XIT",
    CITYNAME: "STIGES",
    CITYNAME_HEB: "\u05e1\u05d8\u05d9\u05d2\u05e1",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "XIH",
    AIRPORTNAME: " ",
    CITYCODE: "XIH",
    CITYNAME: "CHIANCIANO TERME",
    CITYNAME_HEB:
      "\u05e6\u05d9\u05e0\u05e6\u05d9\u05e0\u05d5 \u05d8\u05e8\u05de\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "LOI",
    CITYNAME: "LOVERE",
    CITYNAME_HEB: "\u05dc\u05d5\u05d1\u05e8\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "XIR",
    CITYNAME: "LITSCHAU",
    CITYNAME_HEB: "\u05dc\u05d9\u05d8\u05e9\u05d0\u05d5",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XIV",
    AIRPORTNAME: " ",
    CITYCODE: "XIV",
    CITYNAME: "LIVIGNO",
    CITYNAME_HEB: "\u05dc\u05d9\u05d1\u05d5\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GXY",
    AIRPORTNAME: "Weld County",
    CITYCODE: "GXY",
    CITYNAME: "GREELEY",
    CITYNAME_HEB: "GREELEY",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ANP",
    AIRPORTNAME: "Lee",
    CITYCODE: "ANP",
    CITYNAME: "ANNAPOLIS",
    CITYNAME_HEB: "\u05d0\u05e0\u05d9\u05d0\u05e4\u05d5\u05dc\u05d9\u05e1",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GYA",
    AIRPORTNAME: "Guayaramerin",
    CITYCODE: "GYA",
    CITYNAME: "GUAYARAMERIN",
    CITYNAME_HEB: "GUAYARAMERIN",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GYE",
    AIRPORTNAME: "Simon Bolivar",
    CITYCODE: "GYE",
    CITYNAME: "GUAYAQUIL",
    CITYNAME_HEB: "GUAYAQUIL",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "PSD",
    CITYNAME: "PIESENDORF",
    CITYNAME_HEB: "\u05e4\u05d9\u05e1\u05e0\u05d3\u05e8\u05d5\u05e3",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "GPC",
    CITYNAME: "GARGNANO",
    CITYNAME_HEB: "\u05d2\u05d0\u05e8\u05d2\u05e0\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "ERG",
    CITYNAME: "ERICE",
    CITYNAME_HEB: "\u05d0\u05e8\u05d9\u05da",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "XIW",
    CITYNAME: "PRAIANO",
    CITYNAME_HEB: "\u05e4\u05e8\u05d0\u05d9\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "QAA",
    CITYNAME: "QAWRA",
    CITYNAME_HEB: "\u05e7\u05d5\u05d5\u05d0\u05e8\u05d4",
    COUNTRYCODE: "MT",
    COUNTRYNAME: "MALTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "XIZ",
    CITYNAME: "CHA AM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "XIU",
    CITYNAME: "CLUSANE SUL LAGO",
    CITYNAME_HEB:
      "\u05e7\u05dc\u05d5\u05e1\u05d0\u05e0\u05d4 \u05e1\u05d5\u05dc \u05dc\u05d0\u05d2\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "RDF",
    CITYNAME: "RIVA DEL GARDA",
    CITYNAME_HEB: "\u05e8\u05d9\u05d1 \u05d3\u05dc \u05d2\u05e8\u05d3\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "KLM",
    CITYNAME: "KOH LANTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "KMC",
    CITYNAME: "KHAO LAK-LAM RU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "FLZ",
    CITYNAME: "FLAGSTAFF",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "XIX",
    CITYNAME: "CARMEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAL",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "XCJ",
    CITYNAME: "MONT ST. MICHEL",
    CITYNAME_HEB:
      "\u05de\u05d5\u05e0\u05d8 \u05e1\u05e0\u05d8 \u05de\u05d9\u05e9\u05dc",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GYI",
    AIRPORTNAME: "Gisenyi",
    CITYCODE: "GYI",
    CITYNAME: "GISENYI",
    CITYNAME_HEB: "GISENYI",
    COUNTRYCODE: "RW",
    COUNTRYNAME: "RWANDA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05d0\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "GYL",
    AIRPORTNAME: "Argyle",
    CITYCODE: "GYL",
    CITYNAME: "ARGYLE",
    CITYNAME_HEB: "ARGYLE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GYM",
    AIRPORTNAME: "Gen Jose M Yanez",
    CITYCODE: "GYM",
    CITYNAME: "GUAYMAS",
    CITYNAME_HEB: "GUAYMAS",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "GYN",
    AIRPORTNAME: null,
    CITYCODE: "GYN",
    CITYNAME: "GOIANIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GYP",
    AIRPORTNAME: "Gympie",
    CITYCODE: "GYP",
    CITYNAME: "GYMPIE",
    CITYNAME_HEB: "GYMPIE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GYR",
    AIRPORTNAME: "Litchfield",
    CITYCODE: "GYR",
    CITYNAME: "GOODYEAR",
    CITYNAME_HEB: "GOODYEAR",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GYS",
    AIRPORTNAME: "Guang Yuan",
    CITYCODE: "GYS",
    CITYNAME: "GUANG YUAN",
    CITYNAME_HEB: "GUANG YUAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "GZA",
    AIRPORTNAME: null,
    CITYCODE: "GZA",
    CITYNAME: "GAZA CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PS",
    COUNTRYNAME: "PALESTINIAN OCC. TERRITO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ANQ",
    AIRPORTNAME: "Tri-State Steuben City",
    CITYCODE: "ANQ",
    CITYNAME: "ANGOLA",
    CITYNAME_HEB: "ANGOLA",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GZI",
    AIRPORTNAME: null,
    CITYCODE: "GZI",
    CITYNAME: "GHAZNI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "GZM",
    AIRPORTNAME: "Gozo",
    CITYCODE: "GZM",
    CITYNAME: "GOZO",
    CITYNAME_HEB: "\u05d2\u05d5\u05d6\u05d5",
    COUNTRYCODE: "MT",
    COUNTRYNAME: "MALTA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d8\u05d4",
  },
  {
    AIRPORTCODE: "GZO",
    AIRPORTNAME: "Gizo",
    CITYCODE: "GZO",
    CITYNAME: "GIZO",
    CITYNAME_HEB: "GIZO",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GZT",
    AIRPORTNAME: "Gaziantep",
    CITYCODE: "GZT",
    CITYNAME: "GAZIANTEP",
    CITYNAME_HEB: "\u05d2\u05d6\u05d9\u05d0\u05e0\u05d8\u05e4",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HAA",
    AIRPORTNAME: " ",
    CITYCODE: "HAA",
    CITYNAME: "HAARLEM",
    CITYNAME_HEB: "\u05d4\u05d0\u05e8\u05dc\u05dd",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HAB",
    AIRPORTNAME: "Marion County",
    CITYCODE: "HAB",
    CITYNAME: "HAMILTON",
    CITYNAME_HEB: "Hamilton",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HAC",
    AIRPORTNAME: "Hachijo Jima",
    CITYCODE: "HAC",
    CITYNAME: "HACHIJO JIMA",
    CITYNAME_HEB: "HACHIJO JIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "HAD",
    AIRPORTNAME: "Halmstad",
    CITYCODE: "HAD",
    CITYNAME: "HALMSTAD",
    CITYNAME_HEB: "HALMSTAD",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HAE",
    AIRPORTNAME: "Havasupai",
    CITYCODE: "HAE",
    CITYNAME: "HAVASUPAI",
    CITYNAME_HEB: "HAVASUPAI",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HAF",
    AIRPORTNAME: "Half Moon",
    CITYCODE: "HAF",
    CITYNAME: "HALF MOON",
    CITYNAME_HEB: "HALF MOON",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ANR",
    AIRPORTNAME: "Antwerpen International Airport (Deurne)",
    CITYCODE: "ANR",
    CITYNAME: "ANTWERP",
    CITYNAME_HEB: "\u05d0\u05e0\u05d8\u05d5\u05d5\u05e8\u05e4\u05df",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HAG",
    AIRPORTNAME: "The Hague",
    CITYCODE: "HAG",
    CITYNAME: "THE HAGUE",
    CITYNAME_HEB: "\u05d4\u05d0\u05d2",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HAI",
    AIRPORTNAME: "Dr Haines",
    CITYCODE: "HAI",
    CITYNAME: "THREE RIVERS",
    CITYNAME_HEB: "THREE RIVERS",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HAJ",
    AIRPORTNAME: "Hanover Airport",
    CITYCODE: "HAJ",
    CITYNAME: "HANNOVER",
    CITYNAME_HEB: "\u05d4\u05d0\u05e0\u05d5\u05d1\u05e8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HAK",
    AIRPORTNAME: "Haikou",
    CITYCODE: "HAK",
    CITYNAME: "HAIKOU",
    CITYNAME_HEB: "HAIKOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HAL",
    AIRPORTNAME: "Halali",
    CITYCODE: "HAL",
    CITYNAME: "HALALI",
    CITYNAME_HEB: "HALALI",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HAM",
    AIRPORTNAME: "Fuhlsbuettel",
    CITYCODE: "HAM",
    CITYNAME: "HAMBURG",
    CITYNAME_HEB: "\u05d4\u05de\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LBC",
    AIRPORTNAME: " ",
    CITYCODE: "HAM",
    CITYNAME: "HAMBURG",
    CITYNAME_HEB: "\u05d4\u05de\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HAN",
    AIRPORTNAME: "Noibai",
    CITYCODE: "HAN",
    CITYNAME: "HANOI",
    CITYNAME_HEB: "\u05d4\u05d0\u05e0\u05d5\u05d9",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "HAO",
    AIRPORTNAME: "Hamilton",
    CITYCODE: "HAO",
    CITYNAME: "HAMILTON",
    CITYNAME_HEB: "HAMILTON",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HAP",
    AIRPORTNAME: " ",
    CITYCODE: "HAP",
    CITYNAME: "HAPERT",
    CITYNAME_HEB: "\u05d4\u05d0\u05e4\u05e8\u05d8",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ZAY",
    AIRPORTNAME: " ",
    CITYCODE: "ANR",
    CITYNAME: "ANTWERP",
    CITYNAME_HEB: "\u05d0\u05e0\u05d8\u05d5\u05d5\u05e8\u05e4\u05df",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HAQ",
    AIRPORTNAME: "Hanimaadhoo",
    CITYCODE: "HAQ",
    CITYNAME: "HANIMAADHOO",
    CITYNAME_HEB: "HANIMAADHOO",
    COUNTRYCODE: "MV",
    COUNTRYNAME: "MALDIVES ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d3\u05d9\u05d1\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "HAR",
    AIRPORTNAME: " ",
    CITYCODE: "HAR",
    CITYNAME: "HARTZ",
    CITYNAME_HEB: "\u05d4\u05e8\u05e5",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HAT",
    AIRPORTNAME: "Heathlands",
    CITYCODE: "HAT",
    CITYNAME: "HEATHLANDS",
    CITYNAME_HEB: "HEATHLANDS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HAU",
    AIRPORTNAME: "Haugesund",
    CITYCODE: "HAU",
    CITYNAME: "HAUGESUND",
    CITYNAME_HEB: "HAUGESUND",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HAV",
    AIRPORTNAME: "Jose Marti International",
    CITYCODE: "HAV",
    CITYNAME: "HAVANA",
    CITYNAME_HEB: "\u05d4\u05d5\u05d5\u05d0\u05e0\u05d4",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "HAW",
    AIRPORTNAME: "Haverfordwest",
    CITYCODE: "HAW",
    CITYNAME: "HAVERFORDWEST",
    CITYNAME_HEB: "HAVERFORDWEST",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HAY",
    AIRPORTNAME: "Haycock",
    CITYCODE: "HAY",
    CITYNAME: "HAYCOCK",
    CITYNAME_HEB: "Haycock",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HAZ",
    AIRPORTNAME: "Hatzfeldthaven",
    CITYCODE: "HAZ",
    CITYNAME: "HATZFELDTHAVEN",
    CITYNAME_HEB: "HATZFELDTHAVEN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ZYZ",
    AIRPORTNAME: " ",
    CITYCODE: "ANR",
    CITYNAME: "ANTWERP",
    CITYNAME_HEB: "\u05d0\u05e0\u05d8\u05d5\u05d5\u05e8\u05e4\u05df",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HBA",
    AIRPORTNAME: "Hobart",
    CITYCODE: "HBA",
    CITYNAME: "HOBART",
    CITYNAME_HEB: "\u05d8\u05d6\u05de\u05e0\u05d9\u05d4",
    COUNTRYCODE: "AUTS",
    COUNTRYNAME: "TASMANIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HBC",
    AIRPORTNAME: "Hanus Bay",
    CITYCODE: "HBC",
    CITYNAME: "HANUS BAY",
    CITYNAME_HEB: "Hanus Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HBD",
    AIRPORTNAME: "Habi",
    CITYCODE: "HBD",
    CITYNAME: "HABI",
    CITYNAME_HEB: "HABI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HBG",
    AIRPORTNAME: "Bobby L. Chain Mun.",
    CITYCODE: "HBG",
    CITYNAME: "HATTIESBURG",
    CITYNAME_HEB: "HATTIESBURG",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HBH",
    AIRPORTNAME: "Hobart Bay",
    CITYCODE: "HBH",
    CITYNAME: "HOBART BAY",
    CITYNAME_HEB: "Hobart Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HBI",
    AIRPORTNAME: "Harbour Island",
    CITYCODE: "HBI",
    CITYNAME: "HARBOUR ISLAND",
    CITYNAME_HEB: "HARBOUR ISLAND",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "HBL",
    AIRPORTNAME: "Heliport",
    CITYCODE: "HBL",
    CITYNAME: "BABELEGI",
    CITYNAME_HEB: "BABELEGI",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "HBN",
    AIRPORTNAME: "Phu-bon",
    CITYCODE: "HBN",
    CITYNAME: "PHU BON",
    CITYNAME_HEB: "PHU BON",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "HBO",
    AIRPORTNAME: "Municipal",
    CITYCODE: "HBO",
    CITYNAME: "HUMBOLDT",
    CITYNAME_HEB: "HUMBOLDT",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HBR",
    AIRPORTNAME: "Hobart",
    CITYCODE: "HBR",
    CITYNAME: "HOBART",
    CITYNAME_HEB: "HOBART",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ANS",
    AIRPORTNAME: "Andahuaylas",
    CITYCODE: "ANS",
    CITYNAME: "ANDAHUAYLAS",
    CITYNAME_HEB: "ANDAHUAYLAS",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "HBX",
    AIRPORTNAME: "Hubli",
    CITYCODE: "HBX",
    CITYNAME: "HUBLI",
    CITYNAME_HEB: "HUBLI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "BGS",
    AIRPORTNAME: "Webb AFB",
    CITYCODE: "HCA",
    CITYNAME: "BIG SPRING",
    CITYNAME_HEB: "BIG SPRING",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HCA",
    AIRPORTNAME: "Howard County",
    CITYCODE: "HCA",
    CITYNAME: "BIG SPRING",
    CITYNAME_HEB: "BIG SPRING",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HCB",
    AIRPORTNAME: "Shoal Cove",
    CITYCODE: "HCB",
    CITYNAME: "SHOAL COVE",
    CITYNAME_HEB: "Shoal Cove",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HCC",
    AIRPORTNAME: "Columbia County",
    CITYCODE: "HCC",
    CITYNAME: "HUDSON",
    CITYNAME_HEB: "HUDSON",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HCM",
    AIRPORTNAME: "Eil",
    CITYCODE: "HCM",
    CITYNAME: "EIL",
    CITYNAME_HEB: "EIL",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HCN",
    AIRPORTNAME: "Hengchun",
    CITYCODE: "HCN",
    CITYNAME: "HENGCHUN",
    CITYNAME_HEB: "HENGCHUN",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "HCQ",
    AIRPORTNAME: "Halls Creek",
    CITYCODE: "HCQ",
    CITYNAME: "HALLS CREEK",
    CITYNAME_HEB: "HALLS CREEK",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HCR",
    AIRPORTNAME: "Holy Cross",
    CITYCODE: "HCR",
    CITYNAME: "HOLY CROSS",
    CITYNAME_HEB: "Holy Cross",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HCW",
    AIRPORTNAME: "Cheraw",
    CITYCODE: "HCW",
    CITYNAME: "CHERAW",
    CITYNAME_HEB: "CHERAW",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ANT",
    AIRPORTNAME: "St Anton",
    CITYCODE: "ANT",
    CITYNAME: "SAINT ANTON",
    CITYNAME_HEB: "\u05e1\u05d8. \u05d0\u05e0\u05d8\u05d5\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HDA",
    AIRPORTNAME: "Hidden Falls",
    CITYCODE: "HDA",
    CITYNAME: "HIDDEN FALLS",
    CITYNAME_HEB: "Hidden Falls",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HDB",
    AIRPORTNAME: null,
    CITYCODE: "HDB",
    CITYNAME: "HEIDELBERG",
    CITYNAME_HEB: "\u05d4\u05d9\u05d3\u05dc\u05d1\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HDD",
    AIRPORTNAME: "Hyderabad",
    CITYCODE: "HDD",
    CITYNAME: "HYDERABAD",
    CITYNAME_HEB: "HYDERABAD",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "HDE",
    AIRPORTNAME: "Brewster Field",
    CITYCODE: "HDE",
    CITYNAME: "HOLDREGE",
    CITYNAME_HEB: "HOLDREGE",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HDF",
    AIRPORTNAME: "Heringsdorf",
    CITYCODE: "HDF",
    CITYNAME: "HERINGSDORF",
    CITYNAME_HEB: "HERINGSDORF",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HDH",
    AIRPORTNAME: "Dillingham Airfield",
    CITYCODE: "HDH",
    CITYNAME: "OAHU",
    CITYNAME_HEB: "OAHU",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HDN",
    AIRPORTNAME: "Yampa Valley",
    CITYCODE: "HDN",
    CITYNAME: "HAYDEN",
    CITYNAME_HEB: "HAYDEN",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HDQ",
    AIRPORTNAME: null,
    CITYCODE: "HDQ",
    CITYNAME: "HEADQUARTERS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ZZ",
    COUNTRYNAME: "FICTITIOUS POINTS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "HDS",
    AIRPORTNAME: "Hoedspruit Airport",
    CITYCODE: "HDS",
    CITYNAME: "HOEDSPRUIT",
    CITYNAME_HEB: "HOEDSPRUIT",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "HDY",
    AIRPORTNAME: "Hat Yai",
    CITYCODE: "HDY",
    CITYNAME: "HAT YAI",
    CITYNAME_HEB: "HAT YAI",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ABJ",
    AIRPORTNAME: "Felix Houphouet Boigny International",
    CITYCODE: "ABJ",
    CITYNAME: "ABIDJAN",
    CITYNAME_HEB: "\u05d0\u05d1\u05d9\u05d2\u05df",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "ANU",
    AIRPORTNAME: "V.C. Bird International Airport",
    CITYCODE: "ANU",
    CITYNAME: "ANTIGUA",
    CITYNAME_HEB: "\u05d0\u05e0\u05d9\u05d8\u05d5\u05d2\u05d0\u05d4",
    COUNTRYCODE: "AG",
    COUNTRYNAME: "ANTIGUA AND BARBUDA",
    COUNTRYNAME_HEB:
      "\u05d0\u05e0\u05d8\u05d9\u05d2\u05d5\u05d0\u05d4 \u05d5\u05d1\u05d5\u05e8\u05e0\u05d0\u05d5",
  },
  {
    AIRPORTCODE: "HEA",
    AIRPORTNAME: null,
    CITYCODE: "HEA",
    CITYNAME: "HERAT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "REF",
    CITYNAME: "RENNWEG",
    CITYNAME_HEB: "\u05e8\u05e0\u05e0\u05d9\u05d5\u05d2",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HEB",
    AIRPORTNAME: "Henzada",
    CITYCODE: "HEB",
    CITYNAME: "HENZADA",
    CITYNAME_HEB: "HENZADA",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "GBX",
    CITYNAME: "GRASSAU IN BAVARIA",
    CITYNAME_HEB:
      "\u05d2\u05e8\u05e9\u05d5 \u05d0\u05d9\u05df \u05d1\u05d5\u05d5\u05e8\u05d9\u05d4",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HED",
    AIRPORTNAME: "Herendeen",
    CITYCODE: "HED",
    CITYNAME: "HERENDEEN",
    CITYNAME_HEB: "Herendeen",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "VIM",
    CITYNAME: "VISEGRAD",
    CITYNAME_HEB: "\u05d5\u05d9\u05e1\u05d2\u05d0\u05e8\u05d3",
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "HEE",
    AIRPORTNAME: "Thompson-Robbins",
    CITYCODE: "HEE",
    CITYNAME: "HELENA",
    CITYNAME_HEB: "HELENA",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HEH",
    AIRPORTNAME: "Heho",
    CITYCODE: "HEH",
    CITYNAME: "HEHO",
    CITYNAME_HEB: "\u05d0\u05d4\u05d0\u05d5",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "HEI",
    AIRPORTNAME: "Heide/Buesum",
    CITYCODE: "HEI",
    CITYNAME: "HEIDE BUESUM",
    CITYNAME_HEB: "HEIDE BUESUM",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HEK",
    AIRPORTNAME: "Heihe",
    CITYCODE: "HEK",
    CITYNAME: "HEIHE",
    CITYNAME_HEB: "HEIHE",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HEL",
    AIRPORTNAME: "Vantaa",
    CITYCODE: "HEL",
    CITYNAME: "HELSINKI",
    CITYNAME_HEB: "\u05d4\u05dc\u05e1\u05d9\u05e0\u05e7\u05d9",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HEN",
    AIRPORTNAME: "Hendon",
    CITYCODE: "HEN",
    CITYNAME: "HENDON",
    CITYNAME_HEB: "HENDON",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ANV",
    AIRPORTNAME: "Anvik",
    CITYCODE: "ANV",
    CITYNAME: "ANVIK",
    CITYNAME_HEB: "Anvik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HEO",
    AIRPORTNAME: "Haelogo",
    CITYCODE: "HEO",
    CITYNAME: "HAELOGO",
    CITYNAME_HEB: "HAELOGO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HER",
    AIRPORTNAME: "N. Kazantzakis Airport",
    CITYCODE: "HER",
    CITYNAME: "CRETE",
    CITYNAME_HEB: "\u05db\u05e8\u05ea\u05d9\u05dd",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "HES",
    AIRPORTNAME: "State",
    CITYCODE: "HES",
    CITYNAME: "HERMISTON",
    CITYNAME_HEB: "HERMISTON",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HET",
    AIRPORTNAME: "Hohhot",
    CITYCODE: "HET",
    CITYNAME: "HOHHOT",
    CITYNAME_HEB: "HOHHOT",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HEZ",
    AIRPORTNAME: "Hardy-Anders",
    CITYCODE: "HEZ",
    CITYNAME: "NATCHEZ",
    CITYNAME_HEB: "NATCHEZ",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HFA",
    AIRPORTNAME: null,
    CITYCODE: "HFA",
    CITYNAME: "HAIFA",
    CITYNAME_HEB: "\u05d7\u05d9\u05e4\u05d4",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "HFD",
    AIRPORTNAME: null,
    CITYCODE: "HFD",
    CITYNAME: "HARTFORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZRT",
    AIRPORTNAME: null,
    CITYCODE: "HFD",
    CITYNAME: "HARTFORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ANW",
    AIRPORTNAME: "Ainsworth",
    CITYCODE: "ANW",
    CITYNAME: "AINSWORTH",
    CITYNAME_HEB: "AINSWORTH",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HFE",
    AIRPORTNAME: "Hefei",
    CITYCODE: "HFE",
    CITYNAME: "HEFEI",
    CITYNAME_HEB: "HEFEI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HFF",
    AIRPORTNAME: "Mackall AAF",
    CITYCODE: "HFF",
    CITYNAME: "HOFFMAN",
    CITYNAME_HEB: "HOFFMAN",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HFN",
    AIRPORTNAME: "Hornafjordur",
    CITYCODE: "HFN",
    CITYNAME: "HORNAFJORDUR",
    CITYNAME_HEB: "HORNAFJORDUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HFS",
    AIRPORTNAME: "Hagfors",
    CITYCODE: "HFS",
    CITYNAME: "HAGFORS",
    CITYNAME_HEB: "HAGFORS",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HFT",
    AIRPORTNAME: "Hammerfest",
    CITYCODE: "HFT",
    CITYNAME: "HAMMERFEST",
    CITYNAME_HEB: "HAMMERFEST",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HGA",
    AIRPORTNAME: "Hargeisa",
    CITYCODE: "HGA",
    CITYNAME: "HARGEISA",
    CITYNAME_HEB: "HARGEISA",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HGD",
    AIRPORTNAME: "Hughenden",
    CITYCODE: "HGD",
    CITYNAME: "HUGHENDEN",
    CITYNAME_HEB: "HUGHENDEN",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HGH",
    AIRPORTNAME: "Hangzhou",
    CITYCODE: "HGH",
    CITYNAME: "HANGZHOU",
    CITYNAME_HEB: "\u05d4\u05e0\u05d2\u05d6\u05d5",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HGL",
    AIRPORTNAME: "Helgoland",
    CITYCODE: "HGL",
    CITYNAME: "HELGOLAND",
    CITYNAME_HEB: "HELGOLAND",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ECA",
    AIRPORTNAME: "Emmet County Airport",
    CITYCODE: "ECA",
    CITYNAME: "EAST TAWAS",
    CITYNAME_HEB: "EAST TAWAS",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ECG",
    AIRPORTNAME: "Elizabeth City",
    CITYCODE: "ECG",
    CITYNAME: "ELIZABETH CITY",
    CITYNAME_HEB: "ELIZABETH CITY",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ECH",
    AIRPORTNAME: "Echuca",
    CITYCODE: "ECH",
    CITYNAME: "ECHUCA",
    CITYNAME_HEB: "ECHUCA",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ECN",
    AIRPORTNAME: "Ercan",
    CITYCODE: "ECN",
    CITYNAME: "ERCAN",
    CITYNAME_HEB: "\u05d0\u05e8\u05e7\u05d0\u05df",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ECO",
    AIRPORTNAME: "El Encanto",
    CITYCODE: "ECO",
    CITYNAME: "EL ENCANTO",
    CITYNAME_HEB: "EL ENCANTO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AKS",
    AIRPORTNAME: null,
    CITYCODE: "AKS",
    CITYNAME: "ANYKSCIAI",
    CITYNAME_HEB: "\u05d0\u05e0\u05d9\u05e7\u05e9\u05d8",
    COUNTRYCODE: "LT",
    COUNTRYNAME: "LITHUANIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d8\u05d0",
  },
  {
    AIRPORTCODE: "ECR",
    AIRPORTNAME: "El Charco",
    CITYCODE: "ECR",
    CITYNAME: "EL CHARCO",
    CITYNAME_HEB: "EL CHARCO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ECS",
    AIRPORTNAME: "Mondell",
    CITYCODE: "ECS",
    CITYNAME: "NEWCASTLE",
    CITYNAME_HEB: "NEWCASTLE",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EDA",
    AIRPORTNAME: "Edna Bay",
    CITYCODE: "EDA",
    CITYNAME: "EDNA BAY",
    CITYNAME_HEB: "Edna Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EDB",
    AIRPORTNAME: "Eldebba",
    CITYCODE: "EDB",
    CITYNAME: "ELDEBBA",
    CITYNAME_HEB: "ELDEBBA",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "EDD",
    AIRPORTNAME: "Erldunda",
    CITYCODE: "EDD",
    CITYNAME: "ERLDUNDA",
    CITYNAME_HEB: "ERLDUNDA",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EDE",
    AIRPORTNAME: " ",
    CITYCODE: "EDE",
    CITYNAME: "EDESSA",
    CITYNAME_HEB: "\u05d0\u05d3\u05d0\u05e1\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "EDG",
    AIRPORTNAME: "Weide AAF",
    CITYCODE: "EDG",
    CITYNAME: "EDGEWOOD",
    CITYNAME_HEB: "EDGEWOOD",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EDI",
    AIRPORTNAME: "Turnhouse",
    CITYCODE: "EDI",
    CITYNAME: "EDINBURGH",
    CITYNAME_HEB: "\u05d0\u05d3\u05d9\u05e0\u05d1\u05d5\u05e8\u05d5",
    COUNTRYCODE: "ST",
    COUNTRYNAME: "SCOTLAND",
    COUNTRYNAME_HEB: "\u05e1\u05e7\u05d5\u05d8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "EDK",
    AIRPORTNAME: "El Dorado",
    CITYCODE: "EDK",
    CITYNAME: "EL DORADO",
    CITYNAME_HEB: "EL DORADO",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AKT",
    AIRPORTNAME: "Akrotiri RAF",
    CITYCODE: "AKT",
    CITYNAME: "AKROTIRI",
    CITYNAME_HEB: "\u05d0\u05e7\u05e8\u05d5\u05d8\u05d9\u05e8\u05d9",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "EDL",
    AIRPORTNAME: "Eldoret",
    CITYCODE: "EDL",
    CITYNAME: "ELDORET",
    CITYNAME_HEB: "ELDORET",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EDM",
    AIRPORTNAME: "Les Ajoncs",
    CITYCODE: "EDM",
    CITYNAME: "LA ROCHE",
    CITYNAME_HEB: "LA ROCHE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "EDO",
    AIRPORTNAME: "Edremit/Korfez",
    CITYCODE: "EDO",
    CITYNAME: "EDREMIT KORFEZ",
    CITYNAME_HEB: "EDREMIT KORFEZ",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EDQ",
    AIRPORTNAME: "Erandique",
    CITYCODE: "EDQ",
    CITYNAME: "ERANDIQUE",
    CITYNAME_HEB: "ERANDIQUE",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "EDR",
    AIRPORTNAME: "Edward River",
    CITYCODE: "EDR",
    CITYNAME: "EDWARD RIVER",
    CITYNAME_HEB: "EDWARD RIVER",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EDW",
    AIRPORTNAME: "Edwards AFB",
    CITYCODE: "EDW",
    CITYNAME: "EDWARDS AFB",
    CITYNAME_HEB: "EDWARDS AFB",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EED",
    AIRPORTNAME: "Needles",
    CITYCODE: "EED",
    CITYNAME: "NEEDLES",
    CITYNAME_HEB: "NEEDLES",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AAA",
    AIRPORTNAME: "Anna",
    CITYCODE: "AAA",
    CITYNAME: "ANAA",
    CITYNAME_HEB: "ANAA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "AAO",
    AIRPORTNAME: "Anzualegui",
    CITYCODE: "AAO",
    CITYNAME: "ANACO",
    CITYNAME_HEB: "ANACO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "ESF",
    AIRPORTNAME: null,
    CITYCODE: "AEX",
    CITYNAME: "ALEXANDRIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BTV",
    AIRPORTNAME: "International",
    CITYCODE: "BTV",
    CITYNAME: "BURLINGTON",
    CITYNAME_HEB: "\u05d1\u05e8\u05dc\u05d9\u05e0\u05d2\u05d8\u05d5\u05df",
    COUNTRYCODE: "USVT",
    COUNTRYNAME: "VERMONT",
    COUNTRYNAME_HEB:
      '\u05d5\u05d5\u05e8\u05de\u05d5\u05e0\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YTB",
    AIRPORTNAME: "Hartley Bay",
    CITYCODE: "YTB",
    CITYNAME: "HARTLEY BAY",
    CITYNAME_HEB: "HARTLEY BAY",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTC",
    AIRPORTNAME: "Sturdee",
    CITYCODE: "YTC",
    CITYNAME: "STURDEE",
    CITYNAME_HEB: "STURDEE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTD",
    AIRPORTNAME: "Thicket Portage",
    CITYCODE: "YTD",
    CITYNAME: "THICKET PORTAGE",
    CITYNAME_HEB: "THICKET PORTAGE",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTE",
    AIRPORTNAME: "Cape Dorset",
    CITYCODE: "YTE",
    CITYNAME: "CAPE DORSET",
    CITYNAME_HEB: "CAPE DORSET",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTF",
    AIRPORTNAME: "Alma",
    CITYCODE: "YTF",
    CITYNAME: "ALMA",
    CITYNAME_HEB: "ALMA",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTG",
    AIRPORTNAME: "Sullivan Bay",
    CITYCODE: "YTG",
    CITYNAME: "SULLIVAN BAY",
    CITYNAME_HEB: "SULLIVAN BAY",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTH",
    AIRPORTNAME: "Thompson",
    CITYCODE: "YTH",
    CITYNAME: "THOMPSON",
    CITYNAME_HEB: "THOMPSON",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTI",
    AIRPORTNAME: "Triple Island",
    CITYCODE: "YTI",
    CITYNAME: "TRIPLE ISLAND",
    CITYNAME_HEB: "TRIPLE ISLAND",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTJ",
    AIRPORTNAME: "Terrace Bay",
    CITYCODE: "YTJ",
    CITYNAME: "TERRACE BAY",
    CITYNAME_HEB: "TERRACE BAY",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTK",
    AIRPORTNAME: "Tulugak",
    CITYCODE: "YTK",
    CITYNAME: "TULUGAK",
    CITYNAME_HEB: "TULUGAK",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTW",
    AIRPORTNAME: "Batu Licin",
    CITYCODE: "BTW",
    CITYNAME: "BATU LICIN",
    CITYNAME_HEB: "BATU LICIN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YTL",
    AIRPORTNAME: "Big Trout",
    CITYCODE: "YTL",
    CITYNAME: "BIG TROUT",
    CITYNAME_HEB: "BIG TROUT",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTN",
    AIRPORTNAME: "Riviere Au Tonnerre",
    CITYCODE: "YTN",
    CITYNAME: "RIVIERE AU TONNER",
    CITYNAME_HEB: "RIVIERE AU TONNER",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTQ",
    AIRPORTNAME: "Tasiujuaq",
    CITYCODE: "YTQ",
    CITYNAME: "TASIUJUAQ",
    CITYNAME_HEB: "TASIUJUAQ",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTR",
    AIRPORTNAME: "Trenton",
    CITYCODE: "YTR",
    CITYNAME: "TRENTON",
    CITYNAME_HEB: "TRENTON",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTS",
    AIRPORTNAME: "Timmins",
    CITYCODE: "YTS",
    CITYNAME: "TIMMINS",
    CITYNAME_HEB: "TIMMINS",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTX",
    AIRPORTNAME: "Betoota",
    CITYCODE: "BTX",
    CITYNAME: "BETOOTA",
    CITYNAME_HEB: "BETOOTA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YTT",
    AIRPORTNAME: "Tisdale",
    CITYCODE: "YTT",
    CITYNAME: "TISDALE",
    CITYNAME_HEB: "TISDALE",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTU",
    AIRPORTNAME: "Tasu",
    CITYCODE: "YTU",
    CITYNAME: "TASU",
    CITYNAME_HEB: "TASU",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTX",
    AIRPORTNAME: "Telegraph Creek",
    CITYCODE: "YTX",
    CITYNAME: "TELEGRAPH CREEK",
    CITYNAME_HEB: "TELEGRAPH CREEK",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YUA",
    AIRPORTNAME: "Yuanmou",
    CITYCODE: "YUA",
    CITYNAME: "YUANMOU",
    CITYNAME_HEB: "YUANMOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "YUB",
    AIRPORTNAME: "Tuktoyaktuk",
    CITYCODE: "YUB",
    CITYNAME: "TUKTOYAKTUK",
    CITYNAME_HEB: "TUKTOYAKTUK",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YUD",
    AIRPORTNAME: "Umiujaq",
    CITYCODE: "YUD",
    CITYNAME: "UMIUJAQ",
    CITYNAME_HEB: "UMIUJAQ",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YUE",
    AIRPORTNAME: "Yuendumu",
    CITYCODE: "YUE",
    CITYNAME: "YUENDUMU",
    CITYNAME_HEB: "YUENDUMU",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LGF",
    AIRPORTNAME: null,
    CITYCODE: "YUM",
    CITYNAME: "YUMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YUM",
    AIRPORTNAME: "International",
    CITYCODE: "YUM",
    CITYNAME: "YUMA",
    CITYNAME_HEB: "YUMA",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YUT",
    AIRPORTNAME: "Repulse Bay",
    CITYCODE: "YUT",
    CITYNAME: "REPULSE BAY",
    CITYNAME_HEB: "REPULSE BAY",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTY",
    AIRPORTNAME: "Beatty",
    CITYCODE: "BTY",
    CITYNAME: "BEATTY",
    CITYNAME_HEB: "BEATTY",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YUX",
    AIRPORTNAME: "Hall Beach",
    CITYCODE: "YUX",
    CITYNAME: "HALL BEACH",
    CITYNAME_HEB: "HALL BEACH",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YUY",
    AIRPORTNAME: "Rouyn",
    CITYCODE: "YUY",
    CITYNAME: "ROUYN",
    CITYNAME_HEB: "ROUYN",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YVA",
    AIRPORTNAME: null,
    CITYCODE: "YVA",
    CITYNAME: "MORONI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KM",
    COUNTRYNAME: "COMOROS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YVB",
    AIRPORTNAME: "Bonaventure",
    CITYCODE: "YVB",
    CITYNAME: "BONAVENTURE",
    CITYNAME_HEB: "BONAVENTURE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YVC",
    AIRPORTNAME: "La Ronge",
    CITYCODE: "YVC",
    CITYNAME: "LA RONGE",
    CITYNAME_HEB: "LA RONGE",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YVD",
    AIRPORTNAME: "Yeva",
    CITYCODE: "YVD",
    CITYNAME: "YEVA",
    CITYNAME_HEB: "YEVA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "YVE",
    AIRPORTNAME: "Vernon",
    CITYCODE: "YVE",
    CITYNAME: "VERNON",
    CITYNAME_HEB: "VERNON",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YVG",
    AIRPORTNAME: "Vermilion",
    CITYCODE: "YVG",
    CITYNAME: "VERMILION",
    CITYNAME_HEB: "Vermilion",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YVM",
    AIRPORTNAME: "Qikiqtarjuaq",
    CITYCODE: "YVM",
    CITYNAME: "QIKIQTARJUAQ",
    CITYNAME_HEB: "QIKIQTARJUAQ",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YVO",
    AIRPORTNAME: "Val D",
    CITYCODE: "YVO",
    CITYNAME: "VAL D",
    CITYNAME_HEB: "\u05d5\u05d5\u05d0\u05dc \u05d3\u05d5\u05e8",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YVP",
    AIRPORTNAME: "Kuujjuaq",
    CITYCODE: "YVP",
    CITYNAME: "KUUJJUAQ",
    CITYNAME_HEB: "KUUJJUAQ",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YVQ",
    AIRPORTNAME: "Norman Wells",
    CITYCODE: "YVQ",
    CITYNAME: "NORMAN WELLS",
    CITYNAME_HEB: "NORMAN WELLS",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YVR",
    AIRPORTNAME: "Vancouver International",
    CITYCODE: "YVR",
    CITYNAME: "VANCOUVER",
    CITYNAME_HEB: "\u05d5\u05e0\u05e7\u05d5\u05d1\u05e8",
    COUNTRYCODE: "CA",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDT",
    AIRPORTNAME: " ",
    CITYCODE: "YVR",
    CITYNAME: "VANCOUVER",
    CITYNAME_HEB: "\u05d5\u05e0\u05e7\u05d5\u05d1\u05e8",
    COUNTRYCODE: "CA",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YVT",
    AIRPORTNAME: "Buffalo Narrows",
    CITYCODE: "YVT",
    CITYNAME: "BUFFALO NARROWS",
    CITYNAME_HEB: "BUFFALO NARROWS",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YVV",
    AIRPORTNAME: "Wiarton",
    CITYCODE: "YVV",
    CITYNAME: "WIARTON",
    CITYNAME_HEB: "WIARTON",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YVZ",
    AIRPORTNAME: "Deer Lake",
    CITYCODE: "YVZ",
    CITYNAME: "DEER LAKE",
    CITYNAME_HEB: "DEER LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BUA",
    AIRPORTNAME: "Buka",
    CITYCODE: "BUA",
    CITYNAME: "BUKA",
    CITYNAME_HEB: "BUKA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "YWA",
    AIRPORTNAME: "Petawawa",
    CITYCODE: "YWA",
    CITYNAME: "PETAWAWA",
    CITYNAME_HEB: "PETAWAWA",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YWB",
    AIRPORTNAME: "Kangiqsujuaq",
    CITYCODE: "YWB",
    CITYNAME: "KANGIQSUJUAQ",
    CITYNAME_HEB: "KANGIQSUJUAQ",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XEF",
    AIRPORTNAME: null,
    CITYCODE: "YWG",
    CITYNAME: "WINNIPEG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YWG",
    AIRPORTNAME: "Winnipeg",
    CITYCODE: "YWG",
    CITYNAME: "WINNIPEG",
    CITYNAME_HEB: "WINNIPEG",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YWJ",
    AIRPORTNAME: "Deline",
    CITYCODE: "YWJ",
    CITYNAME: "DELINE",
    CITYNAME_HEB: "DELINE",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YWK",
    AIRPORTNAME: "Wabush",
    CITYCODE: "YWK",
    CITYNAME: "WABUSH",
    CITYNAME_HEB: "WABUSH",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YWL",
    AIRPORTNAME: "Williams Lake",
    CITYCODE: "YWL",
    CITYNAME: "WILLIAMS LAKE",
    CITYNAME_HEB: "WILLIAMS LAKE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YWM",
    AIRPORTNAME: "Williams Harbour",
    CITYCODE: "YWM",
    CITYNAME: "WILLIAMS HARBOUR",
    CITYNAME_HEB: "WILLIAMS HARBOUR",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YWN",
    AIRPORTNAME: "Winisk",
    CITYCODE: "YWN",
    CITYNAME: "WINISK",
    CITYNAME_HEB: "WINISK",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YWO",
    AIRPORTNAME: "Lupin",
    CITYCODE: "YWO",
    CITYNAME: "LUPIN",
    CITYNAME_HEB: "LUPIN",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BUB",
    AIRPORTNAME: "Municipal",
    CITYCODE: "BUB",
    CITYNAME: "BURWELL",
    CITYNAME_HEB: "BURWELL",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YWP",
    AIRPORTNAME: "Webequie",
    CITYCODE: "YWP",
    CITYNAME: "WEBEQUIE",
    CITYNAME_HEB: "WEBEQUIE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YWQ",
    AIRPORTNAME: "Chute-Des-Passes",
    CITYCODE: "YWQ",
    CITYNAME: "CHUTE-DES-PASSES",
    CITYNAME_HEB: "CHUTE-DES-PASSES",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YWR",
    AIRPORTNAME: "White River",
    CITYCODE: "YWR",
    CITYNAME: "WHITE RIVER",
    CITYNAME_HEB: "WHITE RIVER",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YWS",
    AIRPORTNAME: "Whistler",
    CITYCODE: "YWS",
    CITYNAME: "WHISTLER",
    CITYNAME_HEB: "WHISTLER",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YWY",
    AIRPORTNAME: "Wrigley",
    CITYCODE: "YWY",
    CITYNAME: "WRIGLEY",
    CITYNAME_HEB: "WRIGLEY",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXC",
    AIRPORTNAME: "Cranbrook",
    CITYCODE: "YXC",
    CITYNAME: "CRANBROOK",
    CITYNAME_HEB: "CRANBROOK",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXE",
    AIRPORTNAME: "Saskatoon",
    CITYCODE: "YXE",
    CITYNAME: "SASKATOON",
    CITYNAME_HEB: "SASKATOON",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXF",
    AIRPORTNAME: "Snake River",
    CITYCODE: "YXF",
    CITYNAME: "SNAKE RIVER",
    CITYNAME_HEB: "SNAKE RIVER",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXH",
    AIRPORTNAME: "Medicine Hat",
    CITYCODE: "YXH",
    CITYNAME: "MEDICINE HAT",
    CITYNAME_HEB: "Medicine Hat",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXI",
    AIRPORTNAME: "Killaloe",
    CITYCODE: "YXI",
    CITYNAME: "KILLALOE",
    CITYNAME_HEB: "KILLALOE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BUC",
    AIRPORTNAME: "Burketown",
    CITYCODE: "BUC",
    CITYNAME: "BURKETOWN",
    CITYNAME_HEB: "BURKETOWN",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YXJ",
    AIRPORTNAME: "Fort St John",
    CITYCODE: "YXJ",
    CITYNAME: "FORT ST JOHN",
    CITYNAME_HEB: "FORT ST JOHN",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXK",
    AIRPORTNAME: "Rimouski",
    CITYCODE: "YXK",
    CITYNAME: "RIMOUSKI",
    CITYNAME_HEB: "RIMOUSKI",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXL",
    AIRPORTNAME: "Sioux Lookout",
    CITYCODE: "YXL",
    CITYNAME: "SIOUX LOOKOUT",
    CITYNAME_HEB: "SIOUX LOOKOUT",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXN",
    AIRPORTNAME: "Whale Cove",
    CITYCODE: "YXN",
    CITYNAME: "WHALE COVE",
    CITYNAME_HEB: "WHALE COVE",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXP",
    AIRPORTNAME: "Pangnirtung",
    CITYCODE: "YXP",
    CITYNAME: "PANGNIRTUNG",
    CITYNAME_HEB: "PANGNIRTUNG",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXQ",
    AIRPORTNAME: "Beaver Creek",
    CITYCODE: "YXQ",
    CITYNAME: "BEAVER CREEK",
    CITYNAME_HEB: "BEAVER CREEK",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXR",
    AIRPORTNAME: "Earlton",
    CITYCODE: "YXR",
    CITYNAME: "EARLTON",
    CITYNAME_HEB: "EARLTON",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XDV",
    AIRPORTNAME: null,
    CITYCODE: "YXS",
    CITYNAME: "PRINCE GEORGE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YXS",
    AIRPORTNAME: "Prince George",
    CITYCODE: "YXS",
    CITYNAME: "PRINCE GEORGE",
    CITYNAME_HEB: "PRINCE GEORGE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXT",
    AIRPORTNAME: "Terrace",
    CITYCODE: "YXT",
    CITYNAME: "TERRACE",
    CITYNAME_HEB: "TERRACE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BUD",
    AIRPORTNAME: "Ferihegy",
    CITYCODE: "BUD",
    CITYNAME: "BUDAPEST",
    CITYNAME_HEB: "\u05d1\u05d5\u05d3\u05e4\u05e9\u05d8",
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YXX",
    AIRPORTNAME: "Abbotsford",
    CITYCODE: "YXX",
    CITYNAME: "ABBOTSFORD",
    CITYNAME_HEB: "ABBOTSFORD",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXY",
    AIRPORTNAME: "Whitehorse",
    CITYCODE: "YXY",
    CITYNAME: "WHITEHORSE",
    CITYNAME_HEB: "WHITEHORSE",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YXZ",
    AIRPORTNAME: "Wawa",
    CITYCODE: "YXZ",
    CITYNAME: "WAWA",
    CITYNAME_HEB: "WAWA",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYA",
    AIRPORTNAME: "Big Bay Yacht Club",
    CITYCODE: "YYA",
    CITYNAME: "BIG BAY YACHT CLU",
    CITYNAME_HEB: "BIG BAY YACHT CLU",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYB",
    AIRPORTNAME: "North Bay",
    CITYCODE: "YYB",
    CITYNAME: "NORTH BAY",
    CITYNAME_HEB: "NORTH BAY",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYC",
    AIRPORTNAME: "CALGARY",
    CITYCODE: "YYC",
    CITYNAME: "CALGARY",
    CITYNAME_HEB: "\u05e7\u05dc\u05d2\u05e8\u05d9",
    COUNTRYCODE: "CA",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYD",
    AIRPORTNAME: "Smithers",
    CITYCODE: "YYD",
    CITYNAME: "SMITHERS",
    CITYNAME_HEB: "SMITHERS",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYE",
    AIRPORTNAME: "Fort Nelson",
    CITYCODE: "YYE",
    CITYNAME: "FORT NELSON",
    CITYNAME_HEB: "FORT NELSON",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "AEP",
    AIRPORTNAME: " ",
    CITYCODE: "BUE",
    CITYNAME: "BUENOS AIRES",
    CITYNAME_HEB:
      "\u05d1\u05d5\u05d0\u05e0\u05d5\u05e1 \u05d0\u05d9\u05d9\u05e8\u05e1",
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05d2\u05e0\u05d8\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "YYF",
    AIRPORTNAME: "Penticton",
    CITYCODE: "YYF",
    CITYNAME: "PENTICTON",
    CITYNAME_HEB: "PENTICTON",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYG",
    AIRPORTNAME: "Charlottetown",
    CITYCODE: "YYG",
    CITYNAME: "CHARLOTTETOWN",
    CITYNAME_HEB: "CHARLOTTETOWN",
    COUNTRYCODE: "CAPE",
    COUNTRYNAME: "PRINCE EDWARD ISLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYH",
    AIRPORTNAME: "Taloyoak",
    CITYCODE: "YYH",
    CITYNAME: "TALOYOAK",
    CITYNAME_HEB: "TALOYOAK",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYI",
    AIRPORTNAME: "Rivers",
    CITYCODE: "YYI",
    CITYNAME: "RIVERS",
    CITYNAME_HEB: "RIVERS",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYJ",
    AIRPORTNAME: "Victoria International Airport",
    CITYCODE: "YYJ",
    CITYNAME: "VICTORIA",
    CITYNAME_HEB: "VICTORIA",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YWH",
    AIRPORTNAME: "Victoria Inner Harbor",
    CITYCODE: "YYJ",
    CITYNAME: "VICTORIA",
    CITYNAME_HEB: "\u05d5\u05d9\u05e7\u05d8\u05d5\u05e8\u05d9\u05d4",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYL",
    AIRPORTNAME: "Lynn Lake",
    CITYCODE: "YYL",
    CITYNAME: "LYNN LAKE",
    CITYNAME_HEB: "LYNN LAKE",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYM",
    AIRPORTNAME: "Cowley",
    CITYCODE: "YYM",
    CITYNAME: "COWLEY",
    CITYNAME_HEB: "Cowley",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYN",
    AIRPORTNAME: "Swift Current",
    CITYCODE: "YYN",
    CITYNAME: "SWIFT CURRENT",
    CITYNAME_HEB: "SWIFT CURRENT",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XAD",
    AIRPORTNAME: null,
    CITYCODE: "YYQ",
    CITYNAME: "CHURCHILL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AEY",
    AIRPORTNAME: null,
    CITYCODE: "AEY",
    CITYNAME: "AKUREYRI",
    CITYNAME_HEB: "\u05d0\u05e7\u05d5\u05e8\u05d9\u05d9\u05e8\u05d9",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BUE",
    AIRPORTNAME: " ",
    CITYCODE: "BUE",
    CITYNAME: "BUENOS AIRES",
    CITYNAME_HEB:
      "\u05d1\u05d5\u05d0\u05e0\u05d5\u05e1 \u05d0\u05d9\u05d9\u05e8\u05e1",
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05d2\u05e0\u05d8\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "YYQ",
    AIRPORTNAME: "Metropolitan Area",
    CITYCODE: "YYQ",
    CITYNAME: "CHURCHILL",
    CITYNAME_HEB: "CHURCHILL",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYR",
    AIRPORTNAME: "Goose Bay",
    CITYCODE: "YYR",
    CITYNAME: "GOOSE BAY",
    CITYNAME_HEB: "GOOSE BAY",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYT",
    AIRPORTNAME: "St Johns",
    CITYCODE: "YYT",
    CITYNAME: "SAINT JOHNS",
    CITYNAME_HEB: "SAINT JOHNS",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYU",
    AIRPORTNAME: "Kapuskasing",
    CITYCODE: "YYU",
    CITYNAME: "KAPUSKASING",
    CITYNAME_HEB: "KAPUSKASING",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYW",
    AIRPORTNAME: "Armstrong",
    CITYCODE: "YYW",
    CITYNAME: "ARMSTRONG",
    CITYNAME_HEB: "ARMSTRONG",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YYY",
    AIRPORTNAME: "Mont Joli",
    CITYCODE: "YYY",
    CITYNAME: "MONT JOLI",
    CITYNAME_HEB: "MONT JOLI",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YZA",
    AIRPORTNAME: "Ashcroft",
    CITYCODE: "YZA",
    CITYNAME: "ASHCROFT",
    CITYNAME_HEB: "ASHCROFT",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YZC",
    AIRPORTNAME: "Beatton River",
    CITYCODE: "YZC",
    CITYNAME: "BEATTON RIVER",
    CITYNAME_HEB: "BEATTON RIVER",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YZE",
    AIRPORTNAME: "Gore Bay",
    CITYCODE: "YZE",
    CITYNAME: "GORE BAY",
    CITYNAME_HEB: "GORE BAY",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "CLA",
    AIRPORTNAME: "Comilla",
    CITYCODE: "CLA",
    CITYNAME: "COMILLA",
    CITYNAME_HEB: "COMILLA",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "CLB",
    AIRPORTNAME: "Castlebar",
    CITYCODE: "CLB",
    CITYNAME: "CASTLEBAR",
    CITYNAME_HEB: "CASTLEBAR",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "CLC",
    AIRPORTNAME: "Metroport",
    CITYCODE: "CLC",
    CITYNAME: "CLEARLAKE",
    CITYNAME_HEB: "CLEARLAKE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CLD",
    AIRPORTNAME: "Carlsbad",
    CITYCODE: "CLD",
    CITYNAME: "CARLSBAD",
    CITYNAME_HEB: "CARLSBAD",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CGF",
    AIRPORTNAME: null,
    CITYCODE: "CLE",
    CITYNAME: "CLEVELAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AHF",
    AIRPORTNAME: null,
    CITYCODE: "AHF",
    CITYNAME: "ARAPAHOE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CLE",
    AIRPORTNAME: "Hopkins International",
    CITYCODE: "CLE",
    CITYNAME: "CLEVELAND",
    CITYNAME_HEB: "\u05e7\u05dc\u05d9\u05d1\u05dc\u05e0\u05d3",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CLF",
    AIRPORTNAME: null,
    CITYCODE: "CLF",
    CITYNAME: "COLTISHALL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CLG",
    AIRPORTNAME: "Coalinga",
    CITYCODE: "CLG",
    CITYNAME: "COALINGA",
    CITYNAME_HEB: "COALINGA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CLH",
    AIRPORTNAME: "Coolah",
    CITYCODE: "CLH",
    CITYNAME: "COOLAH",
    CITYNAME_HEB: "COOLAH",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CLI",
    AIRPORTNAME: "Clintonville",
    CITYCODE: "CLI",
    CITYNAME: "CLINTONVILLE",
    CITYNAME_HEB: "CLINTONVILLE",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CLJ",
    AIRPORTNAME: "Napoca",
    CITYCODE: "CLJ",
    CITYNAME: "CLUJ",
    CITYNAME_HEB: "\u05e7\u05dc\u05d0\u05d5\u05d6",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CLL",
    AIRPORTNAME: "Easterwood Field",
    CITYCODE: "CLL",
    CITYNAME: "COLLEGE STATION",
    CITYNAME_HEB: "COLLEGE STATION",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CLM",
    AIRPORTNAME: "Fairchild International",
    CITYCODE: "CLM",
    CITYNAME: "PORT ANGELES",
    CITYNAME_HEB: "PORT ANGELES",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CLN",
    AIRPORTNAME: null,
    CITYCODE: "CLN",
    CITYNAME: "CAROLINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CLO",
    AIRPORTNAME: "Alfonso B. Aragon",
    CITYCODE: "CLO",
    CITYNAME: "CALI",
    CITYNAME_HEB: "CALI",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AHH",
    AIRPORTNAME: null,
    CITYCODE: "AHH",
    CITYNAME: "AMERY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CLP",
    AIRPORTNAME: "Clarks Point",
    CITYCODE: "CLP",
    CITYNAME: "CLARKS POINT",
    CITYNAME_HEB: "Clarks Point",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CLQ",
    AIRPORTNAME: "Colima",
    CITYCODE: "CLQ",
    CITYNAME: "COLIMA",
    CITYNAME_HEB: "COLIMA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CLR",
    AIRPORTNAME: "Calipatria",
    CITYCODE: "CLR",
    CITYNAME: "CALIPATRIA",
    CITYNAME_HEB: "CALIPATRIA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CLS",
    AIRPORTNAME: "Centralia",
    CITYCODE: "CLS",
    CITYNAME: "CHEHALIS",
    CITYNAME_HEB: "CHEHALIS",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CLT",
    AIRPORTNAME: "Douglas",
    CITYCODE: "CLT",
    CITYNAME: "CHARLOTTE",
    CITYNAME_HEB: "\u05e9\u05e8\u05dc\u05d5\u05d8",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CLU",
    AIRPORTNAME: "Columbus Municipal",
    CITYCODE: "CLU",
    CITYNAME: "COLUMBUS",
    CITYNAME_HEB: "COLUMBUS",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CLV",
    AIRPORTNAME: null,
    CITYCODE: "CLV",
    CITYNAME: "CALDAS NOVAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CLW",
    AIRPORTNAME: "Executive",
    CITYCODE: "CLW",
    CITYNAME: "CLEARWATER",
    CITYNAME_HEB: "CLEARWATER",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CLX",
    AIRPORTNAME: null,
    CITYCODE: "CLX",
    CITYNAME: "CLORINDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AHI",
    AIRPORTNAME: null,
    CITYCODE: "AHI",
    CITYNAME: "AMAHAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CLY",
    AIRPORTNAME: "Ste Catherine",
    CITYCODE: "CLY",
    CITYNAME: "CALVI CORSICA",
    CITYNAME_HEB: "\u05e7\u05d5\u05e8\u05e1\u05d9\u05e7\u05d4",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "CLZ",
    AIRPORTNAME: "Calabozo",
    CITYCODE: "CLZ",
    CITYNAME: "CALABOZO",
    CITYNAME_HEB: "CALABOZO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CMA",
    AIRPORTNAME: "Cunnamulla",
    CITYCODE: "CMA",
    CITYNAME: "CUNNAMULLA",
    CITYNAME_HEB: "CUNNAMULLA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CMB",
    AIRPORTNAME: "Bandaranayake International",
    CITYCODE: "CMB",
    CITYNAME: "COLOMBO",
    CITYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d5",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "CMC",
    AIRPORTNAME: null,
    CITYCODE: "CMC",
    CITYNAME: "CAMOCIM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CMD",
    AIRPORTNAME: "Cootamundra",
    CITYCODE: "CMD",
    CITYNAME: "COOTAMUNDRA",
    CITYNAME_HEB: "COOTAMUNDRA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CME",
    AIRPORTNAME: "Ciudad Del Carmen",
    CITYCODE: "CME",
    CITYNAME: "CIUDAD DEL CARMEN",
    CITYNAME_HEB: "CIUDAD DEL CARMEN",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CMF",
    AIRPORTNAME: "Chambery",
    CITYCODE: "CMF",
    CITYNAME: "CHAMBERY",
    CITYNAME_HEB: "CHAMBERY",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "CMG",
    AIRPORTNAME: null,
    CITYCODE: "CMG",
    CITYNAME: "CORUMBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AHL",
    AIRPORTNAME: null,
    CITYCODE: "AHL",
    CITYNAME: "AISHALTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CMH",
    AIRPORTNAME: "Port Columbus International",
    CITYCODE: "CMH",
    CITYNAME: "COLUMBUS",
    CITYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d5\u05e1",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LCK",
    AIRPORTNAME: null,
    CITYCODE: "CMH",
    CITYNAME: "COLUMBUS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OSU",
    AIRPORTNAME: null,
    CITYCODE: "CMH",
    CITYNAME: "COLUMBUS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CMI",
    AIRPORTNAME: "Willard University",
    CITYCODE: "CMI",
    CITYNAME: "CHAMPAIGN",
    CITYNAME_HEB: "CHAMPAIGN",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CMJ",
    AIRPORTNAME: "Chi Mei",
    CITYCODE: "CMJ",
    CITYNAME: "CHI MEI",
    CITYNAME_HEB: "CHI MEI",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "CMK",
    AIRPORTNAME: "Club Makokola",
    CITYCODE: "CMK",
    CITYNAME: "CLUB MAKOKOLA",
    CITYNAME_HEB: "CLUB MAKOKOLA",
    COUNTRYCODE: "MW",
    COUNTRYNAME: "MALAWI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "CML",
    AIRPORTNAME: "Camooweal",
    CITYCODE: "CML",
    CITYNAME: "CAMOOWEAL",
    CITYNAME_HEB: "CAMOOWEAL",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CMM",
    AIRPORTNAME: "Carmelita",
    CITYCODE: "CMM",
    CITYNAME: "CARMELITA",
    CITYNAME_HEB: "CARMELITA",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CMO",
    AIRPORTNAME: "Obbia",
    CITYCODE: "CMO",
    CITYNAME: "OBBIA",
    CITYNAME_HEB: "OBBIA",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CMP",
    AIRPORTNAME: null,
    CITYCODE: "CMP",
    CITYNAME: "SANTANA DO ARAGUA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CMQ",
    AIRPORTNAME: "Clermont",
    CITYCODE: "CMQ",
    CITYNAME: "CLERMONT",
    CITYNAME_HEB: "CLERMONT",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CMR",
    AIRPORTNAME: "Colmar-Houssen",
    CITYCODE: "CMR",
    CITYNAME: "COLMAR",
    CITYNAME_HEB: "\u05e7\u05d5\u05dc\u05de\u05d0\u05e8",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "CMS",
    AIRPORTNAME: "Scusciuban",
    CITYCODE: "CMS",
    CITYNAME: "SCUSCIUBAN",
    CITYNAME_HEB: "SCUSCIUBAN",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "XPE",
    CITYNAME: "PETRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JO",
    COUNTRYNAME: "JORDAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CAI",
    AIRPORTNAME: "Cairo International ",
    CITYCODE: "CAI",
    CITYNAME: "CAIRO",
    CITYNAME_HEB: "\u05e7\u05d4\u05d9\u05e8",
    COUNTRYCODE: "EG",
    COUNTRYNAME: "EGYPT",
    COUNTRYNAME_HEB: "\u05de\u05e6\u05e8\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "CMT",
    AIRPORTNAME: null,
    CITYCODE: "CMT",
    CITYNAME: "CAMETA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XDH",
    AIRPORTNAME: " ",
    CITYCODE: "XDH",
    CITYNAME: "DAHAB",
    CITYNAME_HEB: "\u05d3\u05d4\u05d1",
    COUNTRYCODE: "EG",
    COUNTRYNAME: "EGYPT",
    COUNTRYNAME_HEB: "\u05de\u05e6\u05e8\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "CMU",
    AIRPORTNAME: "Chimbu",
    CITYCODE: "CMU",
    CITYNAME: "KUNDIAWA",
    CITYNAME_HEB: "KUNDIAWA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "CMV",
    AIRPORTNAME: "Coromandel",
    CITYCODE: "CMV",
    CITYNAME: "COROMANDEL",
    CITYNAME_HEB: "COROMANDEL",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "CMW",
    AIRPORTNAME: "Ign Agramonte International",
    CITYCODE: "CMW",
    CITYNAME: "CAMAGUEY",
    CITYNAME_HEB: "CAMAGUEY",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "CMX",
    AIRPORTNAME: "Houghton County",
    CITYCODE: "CMX",
    CITYNAME: "HANCOCK",
    CITYNAME_HEB: "HANCOCK",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CMY",
    AIRPORTNAME: "Camp Mccoy AAF",
    CITYCODE: "CMY",
    CITYNAME: "SPARTA",
    CITYNAME_HEB: "SPARTA",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CMZ",
    AIRPORTNAME: "Caia",
    CITYCODE: "CMZ",
    CITYNAME: "CAIA",
    CITYNAME_HEB: "CAIA",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "AHO",
    AIRPORTNAME: "Fertilia",
    CITYCODE: "AHO",
    CITYNAME: "SARDINIA-ALGHERO",
    CITYNAME_HEB: "\u05e1\u05e8\u05d3\u05d9\u05e0\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CNA",
    AIRPORTNAME: "Cananea",
    CITYCODE: "CNA",
    CITYNAME: "CANANEA",
    CITYNAME_HEB: "CANANEA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CNB",
    AIRPORTNAME: " ",
    CITYCODE: "CNB",
    CITYNAME: "CANADA",
    CITYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
    COUNTRYCODE: "CA",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "CNC",
    AIRPORTNAME: "Coconut Island",
    CITYCODE: "CNC",
    CITYNAME: "COCONUT ISLAND",
    CITYNAME_HEB: "COCONUT ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CND",
    AIRPORTNAME: "Kogalniceanu",
    CITYCODE: "CND",
    CITYNAME: "CONSTANTA",
    CITYNAME_HEB: "\u05de\u05d0\u05de\u05d0\u05d9\u05d4",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CNE",
    AIRPORTNAME: null,
    CITYCODE: "CNE",
    CITYNAME: "CANADA NEW ENGLAND",
    CITYNAME_HEB:
      "\u05e7\u05e0\u05d3\u05d4 \u05e0\u05d9\u05d5 \u05d0\u05d9\u05e0\u05d2\u05dc\u05e0\u05d3",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CNG",
    AIRPORTNAME: "Parvaud",
    CITYCODE: "CNG",
    CITYNAME: "COGNAC",
    CITYNAME_HEB: "COGNAC",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "CNH",
    AIRPORTNAME: "Municipal",
    CITYCODE: "CNH",
    CITYNAME: "CLAREMONT",
    CITYNAME_HEB: "CLAREMONT",
    COUNTRYCODE: "USNH",
    COUNTRYNAME: "NEW HAMPSHIRE",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d4\u05de\u05e4\u05e9\u05d9\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CNI",
    AIRPORTNAME: "Changhai",
    CITYCODE: "CNI",
    CITYNAME: "CHANGHAI",
    CITYNAME_HEB: "CHANGHAI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CNJ",
    AIRPORTNAME: "Cloncurry",
    CITYCODE: "CNJ",
    CITYNAME: "CLONCURRY",
    CITYNAME_HEB: "CLONCURRY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CNK",
    AIRPORTNAME: "Blosser Municipal",
    CITYCODE: "CNK",
    CITYNAME: "CONCORDIA",
    CITYNAME_HEB: "CONCORDIA",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AHS",
    AIRPORTNAME: "Ahuas",
    CITYCODE: "AHS",
    CITYNAME: "AHUAS",
    CITYNAME_HEB: "AHUAS",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "CNL",
    AIRPORTNAME: "Sindal",
    CITYCODE: "CNL",
    CITYNAME: "SINDAL",
    CITYNAME_HEB: "SINDAL",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "CNM",
    AIRPORTNAME: "Carlsbad",
    CITYCODE: "CNM",
    CITYNAME: "CARLSBAD",
    CITYNAME_HEB: "CARLSBAD",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CNN",
    AIRPORTNAME: "Chulman",
    CITYCODE: "CNN",
    CITYNAME: "CHULMAN",
    CITYNAME_HEB: "CHULMAN",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CNO",
    AIRPORTNAME: "Chino",
    CITYCODE: "CNO",
    CITYNAME: "CHINO",
    CITYNAME_HEB: "CHINO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CNP",
    AIRPORTNAME: "Neerlerit Inaat",
    CITYCODE: "CNP",
    CITYNAME: "NEERLERIT INAAT",
    CITYNAME_HEB: "NEERLERIT INAAT",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "CNQ",
    AIRPORTNAME: null,
    CITYCODE: "CNQ",
    CITYNAME: "CORRIENTES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CNR",
    AIRPORTNAME: "Chanaral",
    CITYCODE: "CNR",
    CITYNAME: "CHANARAL",
    CITYNAME_HEB: "CHANARAL",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CNS",
    AIRPORTNAME: "Cairns",
    CITYCODE: "CNS",
    CITYNAME: "CAIRNS",
    CITYNAME_HEB: "CAIRNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CNT",
    AIRPORTNAME: null,
    CITYCODE: "CNT",
    CITYNAME: "CHARATA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CNU",
    AIRPORTNAME: "Martin Johnson",
    CITYCODE: "CNU",
    CITYNAME: "CHANUTE",
    CITYNAME_HEB: "CHANUTE",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AHT",
    AIRPORTNAME: "Amchitka",
    CITYCODE: "AHT",
    CITYNAME: "AMCHITKA",
    CITYNAME_HEB: "Amchitka",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CNV",
    AIRPORTNAME: null,
    CITYCODE: "CNV",
    CITYNAME: "CANAVIEIRAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CNX",
    AIRPORTNAME: "International",
    CITYCODE: "CNX",
    CITYNAME: "CHIANG MAI",
    CITYNAME_HEB: "\u05e6\u05d0\u05e0\u05d2 \u05de\u05d0\u05d9",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "CNY",
    AIRPORTNAME: "Canyonlands Field",
    CITYCODE: "CNY",
    CITYNAME: "MOAB",
    CITYNAME_HEB: "MOAB",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CNZ",
    AIRPORTNAME: "Cangamba",
    CITYCODE: "CNZ",
    CITYNAME: "CANGAMBA",
    CITYNAME_HEB: "CANGAMBA",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "COA",
    AIRPORTNAME: "Columbia",
    CITYCODE: "COA",
    CITYNAME: "COLUMBIA",
    CITYNAME_HEB: "COLUMBIA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "COB",
    AIRPORTNAME: "Coolibah",
    CITYCODE: "COB",
    CITYNAME: "COOLIBAH",
    CITYNAME_HEB: "COOLIBAH",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "COC",
    AIRPORTNAME: null,
    CITYCODE: "COC",
    CITYNAME: "COCHEN",
    CITYNAME_HEB: "\u05e7\u05d5\u05db\u05dd",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "COD",
    AIRPORTNAME: " ",
    CITYCODE: "COD",
    CITYNAME: "COSTA DORADA",
    CITYNAME_HEB:
      "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05d3\u05d5\u05e8\u05d3\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "COE",
    AIRPORTNAME: "Coeur D'Alene",
    CITYCODE: "COE",
    CITYNAME: "COEUR D'ALENE",
    CITYNAME_HEB: "COEUR D'ALENE",
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d9\u05d3\u05d4\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "COG",
    AIRPORTNAME: "Mandinga",
    CITYCODE: "COG",
    CITYNAME: "CONDOTO",
    CITYNAME_HEB: "CONDOTO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AHU",
    AIRPORTNAME: "Charif Al Idrissi",
    CITYCODE: "AHU",
    CITYNAME: "AL HOCEIMA",
    CITYNAME_HEB: "AL HOCEIMA",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "COH",
    AIRPORTNAME: "Cooch Behar",
    CITYCODE: "COH",
    CITYNAME: "COOCH BEHAR",
    CITYNAME_HEB: "COOCH BEHAR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "COI",
    AIRPORTNAME: " ",
    CITYCODE: "COI",
    CITYNAME: "COSTA RICA",
    CITYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "COJ",
    AIRPORTNAME: "Coonabarabran",
    CITYCODE: "COJ",
    CITYNAME: "COONABARABRAN",
    CITYNAME_HEB: "COONABARABRAN",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "COK",
    AIRPORTNAME: "Cochin",
    CITYCODE: "COK",
    CITYNAME: "COCHIN",
    CITYNAME_HEB: "\u05e7\u05d5\u05e6\u05d9\u05df",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "COL",
    AIRPORTNAME: "Coll Island",
    CITYCODE: "COL",
    CITYNAME: "COLL ISLAND",
    CITYNAME_HEB: "COLL ISLAND",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "COM",
    AIRPORTNAME: "Coleman",
    CITYCODE: "COM",
    CITYNAME: "COLEMAN",
    CITYNAME_HEB: "COLEMAN",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CON",
    AIRPORTNAME: "Concord",
    CITYCODE: "CON",
    CITYNAME: "CONCORD",
    CITYNAME_HEB: "CONCORD",
    COUNTRYCODE: "USNH",
    COUNTRYNAME: "NEW HAMPSHIRE",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d4\u05de\u05e4\u05e9\u05d9\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "COO",
    AIRPORTNAME: "Cotonou",
    CITYCODE: "COO",
    CITYNAME: "COTONOU",
    CITYNAME_HEB: "COTONOU",
    COUNTRYCODE: "BJ",
    COUNTRYNAME: "BENIN",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d9\u05df",
  },
  {
    AIRPORTCODE: "COP",
    AIRPORTNAME: "Cooperstown",
    CITYCODE: "COP",
    CITYNAME: "COOPERSTOWN",
    CITYNAME_HEB: "COOPERSTOWN",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AHY",
    AIRPORTNAME: "Ambatolahy",
    CITYCODE: "AHY",
    CITYNAME: "AMBATOLAHY",
    CITYNAME_HEB: "AMBATOLAHY",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "COQ",
    AIRPORTNAME: "Choibalsan",
    CITYCODE: "COQ",
    CITYNAME: "CHOIBALSAN",
    CITYNAME_HEB: "CHOIBALSAN",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "COR",
    AIRPORTNAME: " ",
    CITYCODE: "COR",
    CITYNAME: "CORDOVA",
    CITYNAME_HEB: "\u05e7\u05d5\u05e8\u05d3\u05d5\u05d1\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "COS",
    AIRPORTNAME: "Peterson Field",
    CITYCODE: "COS",
    CITYNAME: "COLORADO SPRINGS",
    CITYNAME_HEB:
      "\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 \u05e1\u05e4\u05e8\u05d9\u05e0\u05d2",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "COT",
    AIRPORTNAME: " ",
    CITYCODE: "COT",
    CITYNAME: "CAUTERETS",
    CITYNAME_HEB: "\u05e7\u05d5\u05d8\u05e8\u05d8",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "COU",
    AIRPORTNAME: "Columbia Regional",
    CITYCODE: "COU",
    CITYNAME: "COLUMBIA",
    CITYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "COV",
    AIRPORTNAME: " ",
    CITYCODE: "COV",
    CITYNAME: "COVASNA",
    CITYNAME_HEB: "\u05e7\u05d5\u05d1\u05e1\u05e0\u05d4",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "COW",
    AIRPORTNAME: "Coquimbo",
    CITYCODE: "COW",
    CITYNAME: "COQUIMBO",
    CITYNAME_HEB: "COQUIMBO",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "AAS",
    AIRPORTNAME: "Apalapsili",
    CITYCODE: "AAS",
    CITYNAME: "APALAPSILI",
    CITYNAME_HEB: "APALAPSILI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AHZ",
    AIRPORTNAME: "Alpe D Huez",
    CITYCODE: "AHZ",
    CITYNAME: "ALPE D HUEZ",
    CITYNAME_HEB: "\u05d0\u05dc\u05e4 \u05d3\u05d5\u05d0\u05d6",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "COX",
    AIRPORTNAME: "Congo Town",
    CITYCODE: "COX",
    CITYNAME: "CONGO TOWN",
    CITYNAME_HEB: "CONGO TOWN",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "COY",
    AIRPORTNAME: "Coolawanyah",
    CITYCODE: "COY",
    CITYNAME: "COOLAWANYAH",
    CITYNAME_HEB: "COOLAWANYAH",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "COZ",
    AIRPORTNAME: "Constanza",
    CITYCODE: "COZ",
    CITYNAME: "CONSTANZA",
    CITYNAME_HEB: "CONSTANZA",
    COUNTRYCODE: "DO",
    COUNTRYNAME: "DOMINICAN REPUBLIC",
    COUNTRYNAME_HEB:
      "\u05d4\u05e8\u05e4\u05d5\u05d1\u05dc\u05d9\u05e7\u05d4 \u05d4\u05d3\u05d5\u05de\u05d9\u05e0\u05d9\u05e7\u05e0\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "CPA",
    AIRPORTNAME: "A. Tubman",
    CITYCODE: "CPA",
    CITYNAME: "CAPE PALMAS",
    CITYNAME_HEB: "CAPE PALMAS",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CPB",
    AIRPORTNAME: "Capurgana",
    CITYCODE: "CPB",
    CITYNAME: "CAPURGANA",
    CITYNAME_HEB: "CAPURGANA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CPC",
    AIRPORTNAME: null,
    CITYCODE: "CPC",
    CITYNAME: "SAN MARTIN DE LOS ANDES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CPD",
    AIRPORTNAME: "Coober Pedy",
    CITYCODE: "CPD",
    CITYNAME: "COOBER PEDY",
    CITYNAME_HEB: "COOBER PEDY",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CPE",
    AIRPORTNAME: " ",
    CITYCODE: "CPE",
    CITYNAME: "CALPE",
    CITYNAME_HEB: "\u05e7\u05d0\u05dc\u05e4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "CPF",
    AIRPORTNAME: "Cepu",
    CITYCODE: "CPF",
    CITYNAME: "CEPU",
    CITYNAME_HEB: "CEPU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CPG",
    AIRPORTNAME: null,
    CITYCODE: "CPG",
    CITYNAME: "CARMEN DE PATAGONES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AIA",
    AIRPORTNAME: "Alliance",
    CITYCODE: "AIA",
    CITYNAME: "ALLIANCE",
    CITYNAME_HEB: "ALLIANCE",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CPH",
    AIRPORTNAME: null,
    CITYCODE: "CPH",
    CITYNAME: "COPENHAGEN",
    CITYNAME_HEB: "\u05e7\u05d5\u05e4\u05e0\u05d4\u05d2\u05df",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "RKE",
    AIRPORTNAME: "Roskilde Airport",
    CITYCODE: "CPH",
    CITYNAME: "COPENHAGEN",
    CITYNAME_HEB: "\u05e7\u05d5\u05e4\u05e0\u05d4\u05d2\u05df",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "ZGH",
    AIRPORTNAME: " ",
    CITYCODE: "CPH",
    CITYNAME: "COPENHAGEN",
    CITYNAME_HEB: "\u05e7\u05d5\u05e4\u05e0\u05d4\u05d2\u05df",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "CPI",
    AIRPORTNAME: "Cape Orford",
    CITYCODE: "CPI",
    CITYNAME: "CAPE ORFORD",
    CITYNAME_HEB: "CAPE ORFORD",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "CPL",
    CITYNAME: "CAMPITELLO DI FASSA",
    CITYNAME_HEB:
      "\u05e7\u05de\u05e4\u05d9\u05d8\u05dc\u05d5 \u05d3\u05d9 \u05e4\u05d0\u05e1\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CPM",
    AIRPORTNAME: "Compton",
    CITYCODE: "CPM",
    CITYNAME: "COMPTON",
    CITYNAME_HEB: "COMPTON",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CPN",
    AIRPORTNAME: "Cape Rodney",
    CITYCODE: "CPN",
    CITYNAME: "CAPE RODNEY",
    CITYNAME_HEB: "CAPE RODNEY",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "CPO",
    AIRPORTNAME: "Chamonate",
    CITYCODE: "CPO",
    CITYNAME: "COPIAPO",
    CITYNAME_HEB: "COPIAPO",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CPQ",
    AIRPORTNAME: null,
    CITYCODE: "CPQ",
    CITYNAME: "CAMPINAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CPR",
    AIRPORTNAME: "Casper",
    CITYCODE: "CPR",
    CITYNAME: "CASPER",
    CITYNAME_HEB: "CASPER",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AIB",
    AIRPORTNAME: "Anita Bay",
    CITYCODE: "AIB",
    CITYNAME: "ANITA BAY",
    CITYNAME_HEB: "Anita Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CPT",
    AIRPORTNAME: "Cape Town Internationa",
    CITYCODE: "CPT",
    CITYNAME: "CAPE TOWN",
    CITYNAME_HEB: "\u05e7\u05d9\u05d9\u05e4 \u05d8\u05d0\u05d5\u05df",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "CPU",
    AIRPORTNAME: null,
    CITYCODE: "CPU",
    CITYNAME: "CURURUPU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CPV",
    AIRPORTNAME: null,
    CITYCODE: "CPV",
    CITYNAME: "CAMPINA GRANDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CPX",
    AIRPORTNAME: "Culebra",
    CITYCODE: "CPX",
    CITYNAME: "CULEBRA",
    CITYNAME_HEB: "CULEBRA",
    COUNTRYCODE: "PR",
    COUNTRYNAME: "PUERTO RICO",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e8\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CQA",
    AIRPORTNAME: null,
    CITYCODE: "CQA",
    CITYNAME: "CANARANA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CQF",
    AIRPORTNAME: "Calais",
    CITYCODE: "CQF",
    CITYNAME: "CALAIS",
    CITYNAME_HEB: "CALAIS",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "XFF",
    AIRPORTNAME: null,
    CITYCODE: "CQF",
    CITYNAME: "CALAIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CQP",
    AIRPORTNAME: "Cape Flattery",
    CITYCODE: "CQP",
    CITYNAME: "CAPE FLATTERY",
    CITYNAME_HEB: "CAPE FLATTERY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CQS",
    AIRPORTNAME: null,
    CITYCODE: "CQS",
    CITYNAME: "COSTA MARQUES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CQT",
    AIRPORTNAME: "Caquetania",
    CITYCODE: "CQT",
    CITYNAME: "CAQUETANIA",
    CITYNAME_HEB: "CAQUETANIA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AIC",
    AIRPORTNAME: "Airok",
    CITYCODE: "AIC",
    CITYNAME: "AIROK",
    CITYNAME_HEB: "AIROK",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "CRA",
    AIRPORTNAME: "Craiova",
    CITYCODE: "CRA",
    CITYNAME: "CRAIOVA",
    CITYNAME_HEB: "CRAIOVA",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "LIN",
    CITYNAME: "LIMBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CRB",
    AIRPORTNAME: null,
    CITYCODE: "CRB",
    CITYNAME: "CARIBBEAN",
    CITYNAME_HEB: "\u05e7\u05e8\u05d9\u05d1\u05d9\u05d9\u05dd",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "TRF",
    CITYNAME: "TRUSKAVETS",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "BA1",
    CITYNAME: "BAT YAM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "AS1",
    CITYNAME: "ASHKELON",
    CITYNAME_HEB: "\u05d0\u05e9\u05e7\u05dc\u05d5\u05df",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "YEH",
    CITYNAME: "YEHUD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OBB",
    AIRPORTNAME: " ",
    CITYCODE: "OBB",
    CITYNAME: "OBERTRAUN",
    CITYNAME_HEB: "\u05d0\u05d5\u05d1\u05e8\u05d8\u05e8\u05d5\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "TUH",
    CITYNAME: "TUNIBERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "MRI",
    CITYNAME: "MONTGENEVRE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VTT",
    AIRPORTNAME: " ",
    CITYCODE: "VTT",
    CITYNAME: "VALETTA",
    CITYNAME_HEB: "\u05d5\u05d5\u05dc\u05d8\u05d4",
    COUNTRYCODE: "MT",
    COUNTRYNAME: "MALTA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d8\u05d4",
  },
  {
    AIRPORTCODE: "CRC",
    AIRPORTNAME: "Cartago",
    CITYCODE: "CRC",
    CITYNAME: "CARTAGO",
    CITYNAME_HEB: "CARTAGO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MAO",
    AIRPORTNAME: " ",
    CITYCODE: "MAO",
    CITYNAME: "MANAUS",
    CITYNAME_HEB: "\u05de\u05d0\u05e0\u05d0\u05d5\u05e1",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "CRD",
    AIRPORTNAME: null,
    CITYCODE: "CRD",
    CITYNAME: "COMODORO RIVADAVI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CRF",
    AIRPORTNAME: null,
    CITYCODE: "CRF",
    CITYNAME: "CARNOT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CRH",
    AIRPORTNAME: "Cherribah",
    CITYCODE: "CRH",
    CITYNAME: "CHERRIBAH",
    CITYNAME_HEB: "CHERRIBAH",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CRI",
    AIRPORTNAME: " ",
    CITYCODE: "CRI",
    CITYNAME: "CZECH REPUBLIC",
    CITYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CRJ",
    AIRPORTNAME: "Coorabie",
    CITYCODE: "CRJ",
    CITYNAME: "COORABIE",
    CITYNAME_HEB: "COORABIE",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "CRM",
    CITYNAME: "CRANS MONTANA",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CRN",
    AIRPORTNAME: "Cromarty",
    CITYCODE: "CRN",
    CITYNAME: "CROMARTY",
    CITYNAME_HEB: "CROMARTY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AID",
    AIRPORTNAME: "Municipal",
    CITYCODE: "AID",
    CITYNAME: "ANDERSON",
    CITYNAME_HEB: "ANDERSON",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CRO",
    AIRPORTNAME: "Corcoran",
    CITYCODE: "CRO",
    CITYNAME: "CORCORAN",
    CITYNAME_HEB: "CORCORAN",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CRP",
    AIRPORTNAME: "International",
    CITYCODE: "CRP",
    CITYNAME: "CORPUS CHRISTI",
    CITYNAME_HEB: "CORPUS CHRISTI",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CUX",
    AIRPORTNAME: null,
    CITYCODE: "CRP",
    CITYNAME: "CORPUS CHRISTI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NGP",
    AIRPORTNAME: null,
    CITYCODE: "CRP",
    CITYNAME: "CORPUS CHRISTI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NGW",
    AIRPORTNAME: null,
    CITYCODE: "CRP",
    CITYNAME: "CORPUS CHRISTI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CRQ",
    AIRPORTNAME: null,
    CITYCODE: "CRQ",
    CITYNAME: "CARAVELAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CRR",
    AIRPORTNAME: null,
    CITYCODE: "CRR",
    CITYNAME: "CERES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CRS",
    AIRPORTNAME: "Corsicana",
    CITYCODE: "CRS",
    CITYNAME: "CORSICANA",
    CITYNAME_HEB: "CORSICANA",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CRT",
    AIRPORTNAME: "Municipal",
    CITYCODE: "CRT",
    CITYNAME: "CROSSETT",
    CITYNAME_HEB: "CROSSETT",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CRU",
    AIRPORTNAME: " ",
    CITYCODE: "CRU",
    CITYNAME: "CRUISE",
    CITYNAME_HEB: "\u05e9\u05d9\u05d9\u05d8",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AIE",
    AIRPORTNAME: "Aiome",
    CITYCODE: "AIE",
    CITYNAME: "AIOME",
    CITYNAME_HEB: "AIOME",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "CRV",
    AIRPORTNAME: "Crotone",
    CITYCODE: "CRV",
    CITYNAME: "CROTONE",
    CITYNAME_HEB: "\u05e7\u05e8\u05d8\u05d5\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CRW",
    AIRPORTNAME: "Yeager",
    CITYCODE: "CRW",
    CITYNAME: "CHARLESTON",
    CITYNAME_HEB: "\u05e6'\u05e8\u05dc\u05e1\u05d8\u05d5\u05df",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "CRX",
    AIRPORTNAME: "Roscoe Turner",
    CITYCODE: "CRX",
    CITYNAME: "CORINTH",
    CITYNAME_HEB: "CORINTH",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CRY",
    AIRPORTNAME: "Carlton Hill",
    CITYCODE: "CRY",
    CITYNAME: "CARLTON HILL",
    CITYNAME_HEB: "CARLTON HILL",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CRZ",
    AIRPORTNAME: "Turkmenabad",
    CITYCODE: "CRZ",
    CITYNAME: "TURKMENABAD",
    CITYNAME_HEB: "TURKMENABAD",
    COUNTRYCODE: "TM",
    COUNTRYNAME: "TURKMENISTAN",
    COUNTRYNAME_HEB:
      "\u05d8\u05d5\u05e8\u05e7\u05d9\u05de\u05d9\u05e0\u05d9\u05e1\u05d8\u05d0\u05df",
  },
  {
    AIRPORTCODE: "CSA",
    AIRPORTNAME: "Colonsay Is",
    CITYCODE: "CSA",
    CITYNAME: "COLONSAY IS",
    CITYNAME_HEB: "COLONSAY IS",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "ZMF",
    CITYNAME: "MONTOVA",
    CITYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05d5\u05d1\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CSB",
    AIRPORTNAME: "Caransebes",
    CITYCODE: "CSB",
    CITYNAME: "CARANSEBES",
    CITYNAME_HEB: "CARANSEBES",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CSC",
    AIRPORTNAME: "Canas",
    CITYCODE: "CSC",
    CITYNAME: "CANAS",
    CITYNAME_HEB: "CANAS",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "CSD",
    AIRPORTNAME: "Cresswell Downs",
    CITYCODE: "CSD",
    CITYNAME: "CRESSWELL DOWNS",
    CITYNAME_HEB: "CRESSWELL DOWNS",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CSE",
    AIRPORTNAME: "Crested Butte",
    CITYCODE: "CSE",
    CITYNAME: "CRESTED BUTTE",
    CITYNAME_HEB: "CRESTED BUTTE",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AIF",
    AIRPORTNAME: null,
    CITYCODE: "AIF",
    CITYNAME: "ASSIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CSF",
    AIRPORTNAME: "Creil",
    CITYCODE: "CSF",
    CITYNAME: "CREIL",
    CITYNAME_HEB: "CREIL",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "CSG",
    AIRPORTNAME: null,
    CITYCODE: "CSG",
    CITYNAME: "COLUMBUS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MGY",
    AIRPORTNAME: null,
    CITYCODE: "DAY",
    CITYNAME: "DAYTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DAZ",
    AIRPORTNAME: null,
    CITYCODE: "DAZ",
    CITYNAME: "DARWAZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DBA",
    AIRPORTNAME: "Dalbandin",
    CITYCODE: "DBA",
    CITYNAME: "DALBANDIN",
    CITYNAME_HEB: "DALBANDIN",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "DBD",
    AIRPORTNAME: "Dhanbad",
    CITYCODE: "DBD",
    CITYNAME: "DHANBAD",
    CITYNAME_HEB: "DHANBAD",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "JSL",
    AIRPORTNAME: "Steel Pier Heliport",
    CITYCODE: "AIY",
    CITYNAME: "ATLANTIC CITY",
    CITYNAME_HEB: "Enterprise",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "DBM",
    AIRPORTNAME: "Debra Marcos",
    CITYCODE: "DBM",
    CITYNAME: "DEBRA MARCOS",
    CITYNAME_HEB: "DEBRA MARCOS",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DBO",
    AIRPORTNAME: "Dubbo",
    CITYCODE: "DBO",
    CITYNAME: "DUBBO",
    CITYNAME_HEB: "DUBBO",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DBP",
    AIRPORTNAME: "Debepare",
    CITYCODE: "DBP",
    CITYNAME: "DEBEPARE",
    CITYNAME_HEB: "DEBEPARE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "DBQ",
    AIRPORTNAME: "Municipal",
    CITYCODE: "DBQ",
    CITYNAME: "DUBUQUE",
    CITYNAME_HEB: "DUBUQUE",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DBS",
    AIRPORTNAME: "Dubois",
    CITYCODE: "DBS",
    CITYNAME: "DUBOIS",
    CITYNAME_HEB: "DUBOIS",
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d9\u05d3\u05d4\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DBT",
    AIRPORTNAME: "Debra Tabor",
    CITYCODE: "DBT",
    CITYNAME: "DEBRA TABOR",
    CITYNAME_HEB: "DEBRA TABOR",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DBV",
    AIRPORTNAME: "Dubrovnik",
    CITYCODE: "DBV",
    CITYNAME: "DUBROVNIK",
    CITYNAME_HEB: "\u05d3\u05d5\u05d1\u05e8\u05d5\u05d1\u05e0\u05d9\u05e7",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DBY",
    AIRPORTNAME: "Dalby",
    CITYCODE: "DBY",
    CITYNAME: "DALBY",
    CITYNAME_HEB: "DALBY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DCI",
    AIRPORTNAME: "Rafsu Decimomannu",
    CITYCODE: "DCI",
    CITYNAME: "DECIMOMANNU",
    CITYNAME_HEB:
      "\u05d3\u05d9\u05e6\u05d9\u05de\u05d5\u05de\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZRA",
    AIRPORTNAME: null,
    CITYCODE: "AIY",
    CITYNAME: "ATLANTIC CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DCK",
    AIRPORTNAME: "Dahl Creek Airport",
    CITYCODE: "DCK",
    CITYNAME: "DAHL CREEK",
    CITYNAME_HEB: "Dahl Creek Airport",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DCM",
    AIRPORTNAME: "Mazamet",
    CITYCODE: "DCM",
    CITYNAME: "CASTRES",
    CITYNAME_HEB: "CASTRES",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "DCR",
    AIRPORTNAME: "Decatur Hi-Way",
    CITYCODE: "DCR",
    CITYNAME: "DECATUR",
    CITYNAME_HEB: "DECATUR",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DCT",
    AIRPORTNAME: "Duncan Town",
    CITYCODE: "DCT",
    CITYNAME: "DUNCAN TOWN",
    CITYNAME_HEB: "DUNCAN TOWN",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "DCU",
    AIRPORTNAME: "Pyor",
    CITYCODE: "DCU",
    CITYNAME: "DECATUR",
    CITYNAME_HEB: "Decatur",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DDC",
    AIRPORTNAME: "Dodge City Municipal",
    CITYCODE: "DDC",
    CITYNAME: "DODGE CITY",
    CITYNAME_HEB: "DODGE CITY",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DDG",
    AIRPORTNAME: "Dandong",
    CITYCODE: "DDG",
    CITYNAME: "DANDONG",
    CITYNAME_HEB: "DANDONG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "DDI",
    AIRPORTNAME: "Daydream Is",
    CITYCODE: "DDI",
    CITYNAME: "DAYDREAM IS",
    CITYNAME_HEB: "DAYDREAM IS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DDM",
    AIRPORTNAME: "Dodoima",
    CITYCODE: "DDM",
    CITYNAME: "DODOIMA",
    CITYNAME_HEB: "DODOIMA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "AIZ",
    AIRPORTNAME: "Lee C Fine Memorial",
    CITYCODE: "AIZ",
    CITYNAME: "KAISER LAKE OZARK",
    CITYNAME_HEB: "Hamilton-Marion County",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DDN",
    AIRPORTNAME: "Delta Downs",
    CITYCODE: "DDN",
    CITYNAME: "DELTA DOWNS",
    CITYNAME_HEB: "DELTA DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DDP",
    AIRPORTNAME: "Dorado Beach",
    CITYCODE: "DDP",
    CITYNAME: "DORADO",
    CITYNAME_HEB: "DORADO",
    COUNTRYCODE: "PR",
    COUNTRYNAME: "PUERTO RICO",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e8\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "DDU",
    AIRPORTNAME: "Dadu",
    CITYCODE: "DDU",
    CITYNAME: "DADU",
    CITYNAME_HEB: "DADU",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "DEA",
    AIRPORTNAME: null,
    CITYCODE: "DEA",
    CITYNAME: "DEAD SEA",
    CITYNAME_HEB: "\u05d9\u05dd \u05d4\u05de\u05dc\u05d7",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "DEB",
    AIRPORTNAME: "Debrecen",
    CITYCODE: "DEB",
    CITYNAME: "DEBRECEN",
    CITYNAME_HEB: "\u05d3\u05d1\u05e8\u05e6\u05df",
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "DEG",
    CITYNAME: "MANDERA DEL GARDA",
    CITYNAME_HEB:
      "\u05de\u05e0\u05d3\u05e8\u05d4 \u05d3\u05dc \u05d2\u05e8\u05d3\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DEC",
    AIRPORTNAME: "Decatur Airport",
    CITYCODE: "DEC",
    CITYNAME: "DECATUR",
    CITYNAME_HEB: "DECATUR",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DED",
    AIRPORTNAME: "Dehra Dun",
    CITYCODE: "DED",
    CITYNAME: "DEHRA DUN",
    CITYNAME_HEB: "DEHRA DUN",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "DEH",
    AIRPORTNAME: "Municipal",
    CITYCODE: "DEH",
    CITYNAME: "DECORAH",
    CITYNAME_HEB: "DECORAH",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DEI",
    AIRPORTNAME: "Denis Island",
    CITYCODE: "DEI",
    CITYNAME: "DENIS ISLAND",
    CITYNAME_HEB: "DENIS ISLAND",
    COUNTRYCODE: "SC",
    COUNTRYNAME: "SEYCHELLES ISLANDS",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05d9\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "AJA",
    AIRPORTNAME: "Campo Dell Oro",
    CITYCODE: "AJA",
    CITYNAME: "AJACCIO",
    CITYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05e1\u05d9\u05e7\u05d4 - \u05d0\u05d6\u05d0\u05e7\u05e1\u05d9\u05d5 ",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "DEL",
    AIRPORTNAME: "Indira Gandhi International",
    CITYCODE: "DEL",
    CITYNAME: "DELHI",
    CITYNAME_HEB: "\u05d3\u05dc\u05d4\u05d9",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "DEM",
    AIRPORTNAME: "Dembidollo",
    CITYCODE: "DEM",
    CITYNAME: "DEMBIDOLLO",
    CITYNAME_HEB: "DEMBIDOLLO",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "APA",
    AIRPORTNAME: null,
    CITYCODE: "DEN",
    CITYNAME: "DENVER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFK",
    AIRPORTNAME: null,
    CITYCODE: "DEN",
    CITYNAME: "DENVER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DEN",
    AIRPORTNAME: "Denver International",
    CITYCODE: "DEN",
    CITYNAME: "DENVER",
    CITYNAME_HEB: "\u05d3\u05e0\u05d1\u05e8",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DEO",
    AIRPORTNAME: "Hyatt Regency H/P",
    CITYCODE: "DEO",
    CITYNAME: "DEARBORN",
    CITYNAME_HEB: "DEARBORN",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DEP",
    AIRPORTNAME: " ",
    CITYCODE: "DEP",
    CITYNAME: "DELPHI",
    CITYNAME_HEB: "\u05d3\u05dc\u05e4\u05d9",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "DES",
    AIRPORTNAME: "Desroches",
    CITYCODE: "DES",
    CITYNAME: "DESROCHES",
    CITYNAME_HEB: "DESROCHES",
    COUNTRYCODE: "SC",
    COUNTRYNAME: "SEYCHELLES ISLANDS",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05d9\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "DEZ",
    AIRPORTNAME: "Al Jafrah",
    CITYCODE: "DEZ",
    CITYNAME: "DEIREZZOR",
    CITYNAME_HEB: "DEIREZZOR",
    COUNTRYCODE: "SY",
    COUNTRYNAME: "SYRIAN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AJI",
    AIRPORTNAME: "Agri",
    CITYCODE: "AJI",
    CITYNAME: "AGRI",
    CITYNAME_HEB: "Montgomery-Dannelly Fld",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DFI",
    AIRPORTNAME: "Memorial",
    CITYCODE: "DFI",
    CITYNAME: "DEFIANCE",
    CITYNAME_HEB: "DEFIANCE",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DFP",
    AIRPORTNAME: "Drumduff",
    CITYCODE: "DFP",
    CITYNAME: "DRUMDUFF",
    CITYNAME_HEB: "DRUMDUFF",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ADS",
    AIRPORTNAME: "Addison Airport",
    CITYCODE: "DFW",
    CITYNAME: "DALLAS",
    CITYNAME_HEB: "\u05d3\u05d0\u05dc\u05d0\u05e1",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AFW",
    AIRPORTNAME: null,
    CITYCODE: "DFW",
    CITYNAME: "DALLAS",
    CITYNAME_HEB: "\u05d3\u05d0\u05dc\u05d0\u05e1",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DAL",
    AIRPORTNAME: "Love Field",
    CITYCODE: "DFW",
    CITYNAME: "DALLAS",
    CITYNAME_HEB: "\u05d3\u05d0\u05dc\u05d0\u05e1",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DFW",
    AIRPORTNAME: "Dallas/Fort Worth International Airport",
    CITYCODE: "DFW",
    CITYNAME: "DALLAS",
    CITYNAME_HEB: "\u05d3\u05d0\u05dc\u05d0\u05e1",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DNE",
    AIRPORTNAME: null,
    CITYCODE: "DFW",
    CITYNAME: "DALLAS",
    CITYNAME_HEB: "\u05d3\u05d0\u05dc\u05d0\u05e1",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FTW",
    AIRPORTNAME: null,
    CITYCODE: "DFW",
    CITYNAME: "DALLAS",
    CITYNAME_HEB: "\u05d3\u05d0\u05dc\u05d0\u05e1",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FWH",
    AIRPORTNAME: null,
    CITYCODE: "DFW",
    CITYNAME: "DALLAS",
    CITYNAME_HEB: "\u05d3\u05d0\u05dc\u05d0\u05e1",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JDB",
    AIRPORTNAME: null,
    CITYCODE: "DFW",
    CITYNAME: "DALLAS",
    CITYNAME_HEB: "\u05d3\u05d0\u05dc\u05d0\u05e1",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AJJ",
    AIRPORTNAME: "Akjoujt",
    CITYCODE: "AJJ",
    CITYNAME: "AKJOUJT",
    CITYNAME_HEB: "Mob Aerospace",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JMD",
    AIRPORTNAME: null,
    CITYCODE: "DFW",
    CITYNAME: "DALLAS",
    CITYNAME_HEB: "\u05d3\u05d0\u05dc\u05d0\u05e1",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JNH",
    AIRPORTNAME: null,
    CITYCODE: "DFW",
    CITYNAME: "DALLAS",
    CITYNAME_HEB: "\u05d3\u05d0\u05dc\u05d0\u05e1",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RBD",
    AIRPORTNAME: null,
    CITYCODE: "DFW",
    CITYNAME: "DALLAS",
    CITYNAME_HEB: "\u05d3\u05d0\u05dc\u05d0\u05e1",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DGA",
    AIRPORTNAME: "Dangriga",
    CITYCODE: "DGA",
    CITYNAME: "DANGRIGA",
    CITYNAME_HEB: "DANGRIGA",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "DGB",
    AIRPORTNAME: "Danger Bay",
    CITYCODE: "DGB",
    CITYNAME: "DANGER BAY",
    CITYNAME_HEB: "Danger Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DGC",
    AIRPORTNAME: "Degahbur",
    CITYCODE: "DGC",
    CITYNAME: "DEGAHBUR",
    CITYNAME_HEB: "DEGAHBUR",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DGD",
    AIRPORTNAME: "Dalgaranga",
    CITYCODE: "DGD",
    CITYNAME: "DALGARANGA",
    CITYNAME_HEB: "DALGARANGA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DGE",
    AIRPORTNAME: "Mudgee",
    CITYCODE: "DGE",
    CITYNAME: "MUDGEE",
    CITYNAME_HEB: "MUDGEE",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DGF",
    AIRPORTNAME: "Douglas Lake",
    CITYCODE: "DGF",
    CITYNAME: "DOUGLAS LAKE",
    CITYNAME_HEB: "DOUGLAS LAKE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "DGG",
    AIRPORTNAME: "Daugo",
    CITYCODE: "DGG",
    CITYNAME: "DAUGO",
    CITYNAME_HEB: "DAUGO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "AAV",
    AIRPORTNAME: "Alah",
    CITYCODE: "AAV",
    CITYNAME: "ALAH",
    CITYNAME_HEB: "ALAH",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "AJL",
    AIRPORTNAME: "Aizawl",
    CITYCODE: "AJL",
    CITYNAME: "AIZAWL",
    CITYNAME_HEB: "Florence/Muscle Sh/Sheffield Airport",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "DGM",
    AIRPORTNAME: "Dongguan",
    CITYCODE: "DGM",
    CITYNAME: "DONGGUAN",
    CITYNAME_HEB: "DONGGUAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "DGN",
    AIRPORTNAME: "NAF",
    CITYCODE: "DGN",
    CITYNAME: "DAHLGREN",
    CITYNAME_HEB: "DAHLGREN",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "DGO",
    AIRPORTNAME: "Guadalupe Victoria",
    CITYCODE: "DGO",
    CITYNAME: "DURANGO",
    CITYNAME_HEB: "DURANGO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "DGP",
    AIRPORTNAME: "Daugavpils",
    CITYCODE: "DGP",
    CITYNAME: "DAUGAVPILS",
    CITYNAME_HEB: "DAUGAVPILS",
    COUNTRYCODE: "LV",
    COUNTRYNAME: "LATVIA",
    COUNTRYNAME_HEB: "\u05dc\u05d8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DGR",
    AIRPORTNAME: "Dargaville",
    CITYCODE: "DGR",
    CITYNAME: "DARGAVILLE",
    CITYNAME_HEB: "DARGAVILLE",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "DGT",
    AIRPORTNAME: "Dumaguete",
    CITYCODE: "DGT",
    CITYNAME: "DUMAGUETE",
    CITYNAME_HEB: "DUMAGUETE",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "DGU",
    AIRPORTNAME: "Dedougou",
    CITYCODE: "DGU",
    CITYNAME: "DEDOUGOU",
    CITYNAME_HEB: "DEDOUGOU",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "DGW",
    AIRPORTNAME: "Converse County",
    CITYCODE: "DGW",
    CITYNAME: "DOUGLAS",
    CITYNAME_HEB: "DOUGLAS",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DHD",
    AIRPORTNAME: "Durham Downs",
    CITYCODE: "DHD",
    CITYNAME: "DURHAM DOWNS",
    CITYNAME_HEB: "DURHAM DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DHI",
    AIRPORTNAME: "Dhangarhi",
    CITYCODE: "DHI",
    CITYNAME: "DHANGARHI",
    CITYNAME_HEB: "DHANGARHI",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "AJN",
    AIRPORTNAME: null,
    CITYCODE: "AJN",
    CITYNAME: "ANJOUAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KM",
    COUNTRYNAME: "COMOROS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DHL",
    AIRPORTNAME: "Dhala",
    CITYCODE: "DHL",
    CITYNAME: "DHALA",
    CITYNAME_HEB: "\u05d3\u05d7\u05dc\u05d4",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "DHM",
    AIRPORTNAME: "Gaggal Airport",
    CITYCODE: "DHM",
    CITYNAME: "DHARAMSALA",
    CITYNAME_HEB: "DHARAMSALA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "DHN",
    AIRPORTNAME: "Dothan Airport",
    CITYCODE: "DHN",
    CITYNAME: "DOTHAN",
    CITYNAME_HEB: "Dothan",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DHR",
    AIRPORTNAME: "De Kooy",
    CITYCODE: "DHR",
    CITYNAME: "DEN HELDER",
    CITYNAME_HEB: "DEN HELDER",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "DHT",
    AIRPORTNAME: "Dalhart",
    CITYCODE: "DHT",
    CITYNAME: "DALHART",
    CITYNAME_HEB: "DALHART",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DIB",
    AIRPORTNAME: "Chabua",
    CITYCODE: "DIB",
    CITYNAME: "DIBRUGARH",
    CITYNAME_HEB: "DIBRUGARH",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "DIC",
    AIRPORTNAME: "Dili",
    CITYCODE: "DIC",
    CITYNAME: "DILI",
    CITYNAME_HEB: "DILI",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "DIE",
    AIRPORTNAME: "Antsiranana/Arrachart",
    CITYCODE: "DIE",
    CITYNAME: "ANTSIRANANA",
    CITYNAME_HEB: "ANTSIRANANA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "DIG",
    AIRPORTNAME: "Diqing",
    CITYCODE: "DIG",
    CITYNAME: "DIQING",
    CITYNAME_HEB: "DIQING",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "DIJ",
    AIRPORTNAME: "Dijon",
    CITYCODE: "DIJ",
    CITYNAME: "DIJON",
    CITYNAME_HEB: "\u05d3\u05d9\u05d6'\u05d5\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "AJO",
    AIRPORTNAME: "Aljouf",
    CITYCODE: "AJO",
    CITYNAME: "ALJOUF",
    CITYNAME_HEB: "Ozark-Lowe Army Heliport",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "DIK",
    AIRPORTNAME: "Dickinson",
    CITYCODE: "DIK",
    CITYNAME: "DICKINSON",
    CITYNAME_HEB: "DICKINSON",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DIL",
    AIRPORTNAME: "Comoro",
    CITYCODE: "DIL",
    CITYNAME: "DILI",
    CITYNAME_HEB: "DILI",
    COUNTRYCODE: "TP",
    COUNTRYNAME: "EAST TIMOR",
    COUNTRYNAME_HEB:
      "\u05d8\u05d9\u05de\u05d5\u05e8 \u05d4\u05de\u05d6\u05e8\u05d7\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "DIN",
    AIRPORTNAME: "Gialam",
    CITYCODE: "DIN",
    CITYNAME: "DIEN BIEN PHU",
    CITYNAME_HEB: "DIEN BIEN PHU",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "DIO",
    AIRPORTNAME: "Diomede Island",
    CITYCODE: "DIO",
    CITYNAME: "DIOMEDE ISLAND",
    CITYNAME_HEB: "Diomede Island",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DIP",
    AIRPORTNAME: "Diapaga",
    CITYCODE: "DIP",
    CITYNAME: "DIAPAGA",
    CITYNAME_HEB: "DIAPAGA",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "DIQ",
    AIRPORTNAME: null,
    CITYCODE: "DIQ",
    CITYNAME: "DIVINOPOLIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DIR",
    AIRPORTNAME: "Aba Tenna D Yilma",
    CITYCODE: "DIR",
    CITYNAME: "DIRE DAWA",
    CITYNAME_HEB: "DIRE DAWA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DIS",
    AIRPORTNAME: "Loubomo",
    CITYCODE: "DIS",
    CITYNAME: "LOUBOMO",
    CITYNAME_HEB: "LOUBOMO",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "DIU",
    AIRPORTNAME: "Diu",
    CITYCODE: "DIU",
    CITYNAME: "DIU",
    CITYNAME_HEB: "DIU",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "AJR",
    AIRPORTNAME: "Arvidsjaur",
    CITYCODE: "AJR",
    CITYNAME: "ARVIDSJAUR",
    CITYNAME_HEB: "Pell City-St Clair County",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DIV",
    AIRPORTNAME: "Divo",
    CITYCODE: "DIV",
    CITYNAME: "DIVO",
    CITYNAME_HEB: "DIVO",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "DIY",
    AIRPORTNAME: "Diyarbakir",
    CITYCODE: "DIY",
    CITYNAME: "DIYARBAKIR",
    CITYNAME_HEB: "DIYARBAKIR",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DJA",
    AIRPORTNAME: "Djougou",
    CITYCODE: "DJA",
    CITYNAME: "DJOUGOU",
    CITYNAME_HEB: "DJOUGOU",
    COUNTRYCODE: "BJ",
    COUNTRYNAME: "BENIN",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d9\u05df",
  },
  {
    AIRPORTCODE: "DJB",
    AIRPORTNAME: "Sultan Taha Syarifudn",
    CITYCODE: "DJB",
    CITYNAME: "JAMBI",
    CITYNAME_HEB: "JAMBI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DJE",
    AIRPORTNAME: "DJERBA Melita",
    CITYCODE: "DJE",
    CITYNAME: "DJERBA",
    CITYNAME_HEB: "\u05d2\u05e8\u05d1\u05d4",
    COUNTRYCODE: "TN",
    COUNTRYNAME: "TUNISIA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d9\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DJG",
    AIRPORTNAME: "Inedbirenne",
    CITYCODE: "DJG",
    CITYNAME: "DJANET",
    CITYNAME_HEB: "DJANET",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DJJ",
    AIRPORTNAME: "Sentani",
    CITYCODE: "DJJ",
    CITYNAME: "JAYAPURA",
    CITYNAME_HEB: "JAYAPURA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DJM",
    AIRPORTNAME: "Djambala",
    CITYCODE: "DJM",
    CITYNAME: "DJAMBALA",
    CITYNAME_HEB: "DJAMBALA",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "DJN",
    AIRPORTNAME: "Delta Junction",
    CITYCODE: "DJN",
    CITYNAME: "DELTA JUNCTION",
    CITYNAME_HEB: "Delta Junction",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DJO",
    AIRPORTNAME: "Daloa",
    CITYCODE: "DJO",
    CITYNAME: "DALOA",
    CITYNAME_HEB: "DALOA",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "AJS",
    AIRPORTNAME: "Abreojos",
    CITYCODE: "AJS",
    CITYNAME: "ABREOJOS",
    CITYNAME_HEB: "Selma-Craig AFB",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "DJU",
    AIRPORTNAME: "Djupivogur",
    CITYCODE: "DJU",
    CITYNAME: "DJUPIVOGUR",
    CITYNAME_HEB: "DJUPIVOGUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "DKI",
    AIRPORTNAME: "Dunk Island",
    CITYCODE: "DKI",
    CITYNAME: "DUNK ISLAND",
    CITYNAME_HEB: "DUNK ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DKK",
    AIRPORTNAME: "Dunkirk",
    CITYCODE: "DKK",
    CITYNAME: "DUNKIRK",
    CITYNAME_HEB: "DUNKIRK",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DKR",
    AIRPORTNAME: "Yoff",
    CITYCODE: "DKR",
    CITYNAME: "DAKAR",
    CITYNAME_HEB: "\u05d3\u05d0\u05e7\u05e8",
    COUNTRYCODE: "SN",
    COUNTRYNAME: "SENEGAL",
    COUNTRYNAME_HEB: "\u05e1\u05e0\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "DKS",
    AIRPORTNAME: "Dikson",
    CITYCODE: "DKS",
    CITYNAME: "DIKSON",
    CITYNAME_HEB: "DIKSON",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DKV",
    AIRPORTNAME: "Docker River",
    CITYCODE: "DKV",
    CITYNAME: "DOCKER RIVER",
    CITYNAME_HEB: "DOCKER RIVER",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DLA",
    AIRPORTNAME: "Douala",
    CITYCODE: "DLA",
    CITYNAME: "DOUALA",
    CITYNAME_HEB: "DOUALA",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "DLB",
    AIRPORTNAME: "Dalbertis",
    CITYCODE: "DLB",
    CITYNAME: "DALBERTIS",
    CITYNAME_HEB: "DALBERTIS",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "DLC",
    AIRPORTNAME: "Dalian",
    CITYCODE: "DLC",
    CITYNAME: "DALIAN",
    CITYNAME_HEB: "DALIAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "DLD",
    AIRPORTNAME: "Dagali Airport",
    CITYCODE: "DLD",
    CITYNAME: "GEILO",
    CITYNAME_HEB: "\u05d2\u05d9\u05d9\u05dc\u05d5",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AJU",
    AIRPORTNAME: null,
    CITYCODE: "AJU",
    CITYNAME: "ARACAJU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DLE",
    AIRPORTNAME: "Tavaux",
    CITYCODE: "DLE",
    CITYNAME: "DOLE",
    CITYNAME_HEB: "DOLE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "DLG",
    AIRPORTNAME: "Dillingham",
    CITYCODE: "DLG",
    CITYNAME: "DILLINGHAM",
    CITYNAME_HEB: "Dillingham",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DLH",
    AIRPORTNAME: "Duluth International",
    CITYCODE: "DLH",
    CITYNAME: "DULUTH",
    CITYNAME_HEB: "DULUTH",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LKI",
    AIRPORTNAME: null,
    CITYCODE: "DLH",
    CITYNAME: "DULUTH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SUW",
    AIRPORTNAME: null,
    CITYCODE: "DLH",
    CITYNAME: "DULUTH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DLI",
    AIRPORTNAME: "Lienkhang",
    CITYCODE: "DLI",
    CITYNAME: "DALAT",
    CITYNAME_HEB: "DALAT",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "DLK",
    AIRPORTNAME: "Dulkaninna",
    CITYCODE: "DLK",
    CITYNAME: "DULKANINNA",
    CITYNAME_HEB: "DULKANINNA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DLL",
    AIRPORTNAME: "Dillon",
    CITYCODE: "DLL",
    CITYNAME: "DILLON",
    CITYNAME_HEB: "DILLON",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DLM",
    AIRPORTNAME: "Dalaman",
    CITYCODE: "DLM",
    CITYNAME: "MARMARIS",
    CITYNAME_HEB: "\u05de\u05e8\u05de\u05e8\u05d9\u05e1",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DLN",
    AIRPORTNAME: "Dillon",
    CITYCODE: "DLN",
    CITYNAME: "DILLON",
    CITYNAME_HEB: "DILLON",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AJY",
    AIRPORTNAME: null,
    CITYCODE: "AJY",
    CITYNAME: "AGADES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NE",
    COUNTRYNAME: "NIGER",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DLO",
    AIRPORTNAME: "Dolomi",
    CITYCODE: "DLO",
    CITYNAME: "DOLOMI",
    CITYNAME_HEB: "Dolomi",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DLS",
    AIRPORTNAME: "The Dalles",
    CITYCODE: "DLS",
    CITYNAME: "THE DALLES",
    CITYNAME_HEB: "THE DALLES",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DLT",
    AIRPORTNAME: null,
    CITYCODE: "DLT",
    CITYNAME: "PHONEY CODE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ZZ",
    COUNTRYNAME: "FICTITIOUS POINTS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DLU",
    AIRPORTNAME: "Dali",
    CITYCODE: "DLU",
    CITYNAME: "DALI",
    CITYNAME_HEB: "DALI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "DLV",
    AIRPORTNAME: "Delissaville",
    CITYCODE: "DLV",
    CITYNAME: "DELISSAVILLE",
    CITYNAME_HEB: "DELISSAVILLE",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DLY",
    AIRPORTNAME: "Dillons Bay",
    CITYCODE: "DLY",
    CITYNAME: "DILLONS BAY",
    CITYNAME_HEB: "DILLONS BAY",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "DLZ",
    AIRPORTNAME: "Dalanzadgad",
    CITYCODE: "DLZ",
    CITYNAME: "DALANZAGDAD",
    CITYNAME_HEB: "DALANZAGDAD",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DMB",
    AIRPORTNAME: "Zhambyl",
    CITYCODE: "DMB",
    CITYNAME: "ZHAMBYL",
    CITYNAME_HEB: "ZHAMBYL",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "DMD",
    AIRPORTNAME: "Doomadgee",
    CITYCODE: "DMD",
    CITYNAME: "DOOMADGEE",
    CITYNAME_HEB: "DOOMADGEE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DMN",
    AIRPORTNAME: "Deming",
    CITYCODE: "DMN",
    CITYNAME: "DEMING",
    CITYNAME_HEB: "DEMING",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AKA",
    AIRPORTNAME: "Ankang",
    CITYCODE: "AKA",
    CITYNAME: "ANKANG",
    CITYNAME_HEB: "ANKANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "DMO",
    AIRPORTNAME: "Sedalia",
    CITYCODE: "DMO",
    CITYNAME: "SEDALIA",
    CITYNAME_HEB: "SEDALIA",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DMR",
    AIRPORTNAME: "Dhamar",
    CITYCODE: "DMR",
    CITYNAME: "DHAMAR",
    CITYNAME_HEB: "DHAMAR",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "DMT",
    AIRPORTNAME: null,
    CITYCODE: "DMT",
    CITYNAME: "DIAMANTINO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DMU",
    AIRPORTNAME: "Dimapur",
    CITYCODE: "DMU",
    CITYNAME: "DIMAPUR",
    CITYNAME_HEB: "DIMAPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "DNB",
    AIRPORTNAME: "Dunbar",
    CITYCODE: "DNB",
    CITYNAME: "DUNBAR",
    CITYNAME_HEB: "DUNBAR",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DNC",
    AIRPORTNAME: "Danane",
    CITYCODE: "DNC",
    CITYNAME: "DANANE",
    CITYNAME_HEB: "DANANE",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "DND",
    AIRPORTNAME: "Dundee",
    CITYCODE: "DND",
    CITYNAME: "DUNDEE",
    CITYNAME_HEB: "\u05d3\u05d0\u05e0\u05d3\u05d9",
    COUNTRYCODE: "ST",
    COUNTRYNAME: "SCOTLAND",
    COUNTRYNAME_HEB: "\u05e1\u05e7\u05d5\u05d8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "DNF",
    AIRPORTNAME: "Martuba",
    CITYCODE: "DNF",
    CITYNAME: "DERNA",
    CITYNAME_HEB: "DERNA",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "DNG",
    AIRPORTNAME: "Doongan",
    CITYCODE: "DNG",
    CITYNAME: "DOOGAN",
    CITYNAME_HEB: "DOOGAN",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AKB",
    AIRPORTNAME: "Atka",
    CITYCODE: "AKB",
    CITYNAME: "ATKA",
    CITYNAME_HEB: "Atka",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DNH",
    AIRPORTNAME: "Dunhuang",
    CITYCODE: "DNH",
    CITYNAME: "DUNHUANG",
    CITYNAME_HEB: "DUNHUANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "DNI",
    AIRPORTNAME: "Wad Medani",
    CITYCODE: "DNI",
    CITYNAME: "WAD MEDANI",
    CITYNAME_HEB: "WAD MEDANI",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "DNK",
    AIRPORTNAME: "Dnepropetrovsk",
    CITYCODE: "DNK",
    CITYNAME: "DNEPROPETROVSK",
    CITYNAME_HEB:
      "\u05d3\u05e0\u05e4\u05e8\u05d5\u05e4\u05e8\u05d5\u05d1\u05e1\u05e7",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "DNM",
    AIRPORTNAME: "Denham",
    CITYCODE: "DNM",
    CITYNAME: "DENHAM",
    CITYNAME_HEB: "DENHAM",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DNN",
    AIRPORTNAME: "Municipal",
    CITYCODE: "DNN",
    CITYNAME: "DALTON",
    CITYNAME_HEB: "DALTON",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "QJJ",
    AIRPORTNAME: null,
    CITYCODE: "DNN",
    CITYNAME: "DALTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DNO",
    AIRPORTNAME: null,
    CITYCODE: "DNO",
    CITYNAME: "DIANOPOLIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DNP",
    AIRPORTNAME: "Dang",
    CITYCODE: "DNP",
    CITYNAME: "DANG",
    CITYNAME_HEB: "DANG",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "DNQ",
    AIRPORTNAME: "Deniliquin",
    CITYCODE: "DNQ",
    CITYNAME: "DENILIQUIN",
    CITYNAME_HEB: "DENILIQUIN",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DNR",
    AIRPORTNAME: "Pleurtuit",
    CITYCODE: "DNR",
    CITYNAME: "DINARD",
    CITYNAME_HEB: "DINARD",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "AKD",
    AIRPORTNAME: "Akola",
    CITYCODE: "AKD",
    CITYNAME: "AKOLA",
    CITYNAME_HEB: "AKOLA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "DNS",
    AIRPORTNAME: "Municipal",
    CITYCODE: "DNS",
    CITYNAME: "DENISON",
    CITYNAME_HEB: "DENISON",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DNU",
    AIRPORTNAME: "Dinangat",
    CITYCODE: "DNU",
    CITYNAME: "DINANGAT",
    CITYNAME_HEB: "DINANGAT",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "DNV",
    AIRPORTNAME: "Vermilion County",
    CITYCODE: "DNV",
    CITYNAME: "DANVILLE",
    CITYNAME_HEB: "DANVILLE",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DNX",
    AIRPORTNAME: "Galegu",
    CITYCODE: "DNX",
    CITYNAME: "DINDER",
    CITYNAME_HEB: "DINDER",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "DNZ",
    AIRPORTNAME: "Cardak",
    CITYCODE: "DNZ",
    CITYNAME: "DENIZLI",
    CITYNAME_HEB: "DENIZLI",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DOA",
    AIRPORTNAME: "Doany",
    CITYCODE: "DOA",
    CITYNAME: "DOANY",
    CITYNAME_HEB: "DOANY",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "DOB",
    AIRPORTNAME: "Dobo Airport",
    CITYCODE: "DOB",
    CITYNAME: "DOBO",
    CITYNAME_HEB: "DOBO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DOC",
    AIRPORTNAME: "Dornoch",
    CITYCODE: "DOC",
    CITYNAME: "DORNOCH",
    CITYNAME_HEB: "DORNOCH",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DOD",
    AIRPORTNAME: "Dodoma",
    CITYCODE: "DOD",
    CITYNAME: "DODOMA",
    CITYNAME_HEB: "DODOMA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DOE",
    AIRPORTNAME: "Djoemoe",
    CITYCODE: "DOE",
    CITYNAME: "DJOEMOE",
    CITYNAME_HEB: "DJOEMOE",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "AAW",
    AIRPORTNAME: "Abbottabad",
    CITYCODE: "AAW",
    CITYNAME: "ABBOTTABAD",
    CITYNAME_HEB: "ABBOTTABAD",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "AKE",
    AIRPORTNAME: "Akieni",
    CITYCODE: "AKE",
    CITYNAME: "AKIENI",
    CITYNAME_HEB: "AKIENI",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "DOF",
    AIRPORTNAME: "Dora Bay",
    CITYCODE: "DOF",
    CITYNAME: "DORA BAY",
    CITYNAME_HEB: "Dora Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DOG",
    AIRPORTNAME: "Dongola",
    CITYCODE: "DOG",
    CITYNAME: "DONGOLA",
    CITYNAME_HEB: "DONGOLA",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "DOH",
    AIRPORTNAME: "Doha",
    CITYCODE: "DOH",
    CITYNAME: "DOHA",
    CITYNAME_HEB: "\u05d3\u05d5\u05d7\u05d0",
    COUNTRYCODE: "QA",
    COUNTRYNAME: "QATAR",
    COUNTRYNAME_HEB: "\u05e7\u05d8\u05d0\u05e8",
  },
  {
    AIRPORTCODE: "DOI",
    AIRPORTNAME: "Doini",
    CITYCODE: "DOI",
    CITYNAME: "DOINI",
    CITYNAME_HEB: "DOINI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "DOK",
    AIRPORTNAME: "Donetsk",
    CITYCODE: "DOK",
    CITYNAME: "DONETSK",
    CITYNAME_HEB: "\u05d3\u05d5\u05e0\u05e6\u05e7",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "DOL",
    AIRPORTNAME: "St Gatien",
    CITYCODE: "DOL",
    CITYNAME: "DEAUVILLE",
    CITYNAME_HEB: "DEAUVILLE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "DCF",
    AIRPORTNAME: null,
    CITYCODE: "DOM",
    CITYNAME: "DOMINICA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DM",
    COUNTRYNAME: "DOMINICA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DOM",
    AIRPORTNAME: null,
    CITYCODE: "DOM",
    CITYNAME: "DOMINICA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DM",
    COUNTRYNAME: "DOMINICA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DON",
    AIRPORTNAME: "Dos Lagunas",
    CITYCODE: "DON",
    CITYNAME: "DOS LAGUNAS",
    CITYNAME_HEB: "\u05d3\u05d5\u05e1 \u05dc\u05d0\u05d2\u05d5\u05e0\u05e1",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "DOO",
    AIRPORTNAME: "Dorobisoro",
    CITYCODE: "DOO",
    CITYNAME: "DOROBISORO",
    CITYNAME_HEB: "DOROBISORO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "AKF",
    AIRPORTNAME: "Kufrah",
    CITYCODE: "AKF",
    CITYNAME: "KUFRAH",
    CITYNAME_HEB: "KUFRAH",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "DOP",
    AIRPORTNAME: "Dolpa",
    CITYCODE: "DOP",
    CITYNAME: "DOLPA",
    CITYNAME_HEB: "DOLPA",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "DOR",
    AIRPORTNAME: "Dori",
    CITYCODE: "DOR",
    CITYNAME: "DORI",
    CITYNAME_HEB: "DORI",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "DOS",
    AIRPORTNAME: "Dios",
    CITYCODE: "DOS",
    CITYNAME: "DIOS",
    CITYNAME_HEB: "DIOS",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "DOU",
    AIRPORTNAME: null,
    CITYCODE: "DOU",
    CITYNAME: "DOURADOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DOV",
    AIRPORTNAME: "Dover AFB",
    CITYCODE: "DOV",
    CITYNAME: "DOVER CHESWOLD",
    CITYNAME_HEB: "DOVER CHESWOLD",
    COUNTRYCODE: "USDE",
    COUNTRYNAME: "DELAWARE",
    COUNTRYNAME_HEB:
      '\u05d3\u05dc\u05d5\u05d5\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DOX",
    AIRPORTNAME: "Dongara",
    CITYCODE: "DOX",
    CITYNAME: "DONGARA",
    CITYNAME_HEB: "DONGARA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DPE",
    AIRPORTNAME: "Dieppe",
    CITYCODE: "DPE",
    CITYNAME: "DIEPPE",
    CITYNAME_HEB: "DIEPPE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "DPG",
    AIRPORTNAME: "Michael AAF",
    CITYCODE: "DPG",
    CITYNAME: "DUGWAY",
    CITYNAME_HEB: "DUGWAY",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DPK",
    AIRPORTNAME: "Deer Park",
    CITYCODE: "DPK",
    CITYNAME: "DEER PARK",
    CITYNAME_HEB: "DEER PARK",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DPL",
    AIRPORTNAME: "Dipolog",
    CITYCODE: "DPL",
    CITYNAME: "DIPOLOG",
    CITYNAME_HEB: "DIPOLOG",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "AKG",
    AIRPORTNAME: "Anguganak",
    CITYCODE: "AKG",
    CITYNAME: "ANGUGANAK",
    CITYNAME_HEB: "ANGUGANAK",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "DPO",
    AIRPORTNAME: null,
    CITYCODE: "DPO",
    CITYNAME: "DEVONPORT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUTS",
    COUNTRYNAME: "TASMANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DPS",
    AIRPORTNAME: "Ngurah Rai",
    CITYCODE: "DPS",
    CITYNAME: "DENPASAR BALI",
    CITYNAME_HEB: "DENPASAR BALI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DPU",
    AIRPORTNAME: "Dumpu",
    CITYCODE: "DPU",
    CITYNAME: "DUMPU",
    CITYNAME_HEB: "DUMPU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "DRA",
    AIRPORTNAME: "Desert Rock",
    CITYCODE: "DRA",
    CITYNAME: "MERCURY",
    CITYNAME_HEB: "MERCURY",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DRB",
    AIRPORTNAME: "Derby",
    CITYCODE: "DRB",
    CITYNAME: "DERBY",
    CITYNAME_HEB: "DERBY",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DRC",
    AIRPORTNAME: "Dirico",
    CITYCODE: "DRC",
    CITYNAME: "DIRICO",
    CITYNAME_HEB: "DIRICO",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "DRD",
    AIRPORTNAME: "Dorunda Station",
    CITYCODE: "DRD",
    CITYNAME: "DORUNDA STATION",
    CITYNAME_HEB: "DORUNDA STATION",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DRE",
    AIRPORTNAME: "Drummond Island",
    CITYCODE: "DRE",
    CITYNAME: "DRUMMOND ISLAND",
    CITYNAME_HEB: "DRUMMOND ISLAND",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DRF",
    AIRPORTNAME: "Drift River",
    CITYCODE: "DRF",
    CITYNAME: "DRIFT RIVER",
    CITYNAME_HEB: "Drift River",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DRG",
    AIRPORTNAME: "Deering",
    CITYCODE: "DRG",
    CITYNAME: "DEERING",
    CITYNAME_HEB: "Deering",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AKI",
    AIRPORTNAME: "Akiak",
    CITYCODE: "AKI",
    CITYNAME: "AKIAK",
    CITYNAME_HEB: "Akiak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DRH",
    AIRPORTNAME: "Dabra",
    CITYCODE: "DRH",
    CITYNAME: "DABRA",
    CITYNAME_HEB: "DABRA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DRI",
    AIRPORTNAME: "Beauregard Parish",
    CITYCODE: "DRI",
    CITYNAME: "DE RIDDER",
    CITYNAME_HEB: "DE RIDDER",
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB:
      '\u05dc\u05d5\u05d0\u05d9\u05d6\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DRJ",
    AIRPORTNAME: "Drietabbetje",
    CITYCODE: "DRJ",
    CITYNAME: "DRIETABBETJE",
    CITYNAME_HEB: "DRIETABBETJE",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "DRM",
    AIRPORTNAME: "Drama",
    CITYCODE: "DRM",
    CITYNAME: "DRAMA",
    CITYNAME_HEB: "DRAMA",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "DRN",
    AIRPORTNAME: "Dirranbandi",
    CITYCODE: "DRN",
    CITYNAME: "DIRRANBANDI",
    CITYNAME_HEB: "DIRRANBANDI",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AMK",
    AIRPORTNAME: null,
    CITYCODE: "DRO",
    CITYNAME: "DURANGO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DRO",
    AIRPORTNAME: "La Plata",
    CITYCODE: "DRO",
    CITYNAME: "DURANGO",
    CITYNAME_HEB: "DURANGO",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DRR",
    AIRPORTNAME: "Durrie",
    CITYCODE: "DRR",
    CITYNAME: "DURRIE",
    CITYNAME_HEB: "DURRIE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DRS",
    AIRPORTNAME: "Dresden Airport",
    CITYCODE: "DRS",
    CITYNAME: "DRESDEN",
    CITYNAME_HEB: "\u05d3\u05e8\u05d6\u05d3\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DLF",
    AIRPORTNAME: null,
    CITYCODE: "DRT",
    CITYNAME: "DEL RIO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AKJ",
    AIRPORTNAME: "Asahikawa",
    CITYCODE: "AKJ",
    CITYNAME: "ASAHIKAWA",
    CITYNAME_HEB: "ASAHIKAWA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "DRT",
    AIRPORTNAME: "International",
    CITYCODE: "DRT",
    CITYNAME: "DEL RIO",
    CITYNAME_HEB: "DEL RIO",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DRU",
    AIRPORTNAME: "Drummond",
    CITYCODE: "DRU",
    CITYNAME: "DRUMMOND",
    CITYNAME_HEB: "DRUMMOND",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DRW",
    AIRPORTNAME: "Darwin",
    CITYCODE: "DRW",
    CITYNAME: "DARWIN",
    CITYNAME_HEB: "DARWIN",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DRY",
    AIRPORTNAME: "Drysdale River",
    CITYCODE: "DRY",
    CITYNAME: "DRYSDALE RIVER",
    CITYNAME_HEB: "DRYSDALE RIVER",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DSC",
    AIRPORTNAME: "Dschang",
    CITYCODE: "DSC",
    CITYNAME: "DSCHANG",
    CITYNAME_HEB: "DSCHANG",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "DSD",
    AIRPORTNAME: "La Desirade",
    CITYCODE: "DSD",
    CITYNAME: "LA DESIRADE",
    CITYNAME_HEB: "LA DESIRADE",
    COUNTRYCODE: "GP",
    COUNTRYNAME: "GUADELOUPE",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d2\u05d5\u05d5\u05d3\u05d0\u05dc\u05d5\u05e4",
  },
  {
    AIRPORTCODE: "DSE",
    AIRPORTNAME: "Combolcha",
    CITYCODE: "DSE",
    CITYNAME: "DESSIE",
    CITYNAME_HEB: "DESSIE",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DSG",
    AIRPORTNAME: "Dilasag",
    CITYCODE: "DSG",
    CITYNAME: "DILASAG",
    CITYNAME_HEB: "DILASAG",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "DSI",
    AIRPORTNAME: "Destin",
    CITYCODE: "DSI",
    CITYNAME: "DESTIN",
    CITYNAME_HEB: "DESTIN",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DSK",
    AIRPORTNAME: "Dera Ismail Khan",
    CITYCODE: "DSK",
    CITYNAME: "DERA ISMAIL KHAN",
    CITYNAME_HEB: "DERA ISMAIL KHAN",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "AKK",
    AIRPORTNAME: "Akhiok SPB",
    CITYCODE: "AKK",
    CITYNAME: "AKHIOK",
    CITYNAME_HEB: "Akhiok SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DSL",
    AIRPORTNAME: "Daru",
    CITYCODE: "DSL",
    CITYNAME: "DARU",
    CITYNAME_HEB: "DARU",
    COUNTRYCODE: "SL",
    COUNTRYNAME: "SIERRA LEONE",
    COUNTRYNAME_HEB:
      "\u05e1\u05d9\u05d9\u05e8\u05d4 \u05dc\u05d9\u05d0\u05d5\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "DSM",
    AIRPORTNAME: "Des Moines",
    CITYCODE: "DSM",
    CITYNAME: "DES MOINES",
    CITYNAME_HEB: "DES MOINES",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DSN",
    AIRPORTNAME: "Dongsheng",
    CITYCODE: "DSN",
    CITYNAME: "DONGSHENG",
    CITYNAME_HEB: "DONGSHENG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "DSV",
    AIRPORTNAME: "Dansville",
    CITYCODE: "DSV",
    CITYNAME: "DANSVILLE",
    CITYNAME_HEB: "DANSVILLE",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DTA",
    AIRPORTNAME: "Delta",
    CITYCODE: "DTA",
    CITYNAME: "DELTA",
    CITYNAME_HEB: "DELTA",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DTD",
    AIRPORTNAME: "Datadawai",
    CITYCODE: "DTD",
    CITYNAME: "DATADAWAI",
    CITYNAME_HEB: "DATADAWAI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DTE",
    AIRPORTNAME: "Camarines Norte",
    CITYCODE: "DTE",
    CITYNAME: "DAET",
    CITYNAME_HEB: "DAET",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "DTH",
    AIRPORTNAME: "Death Valley",
    CITYCODE: "DTH",
    CITYNAME: "DEATH VALLEY",
    CITYNAME_HEB: "DEATH VALLEY",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DTL",
    AIRPORTNAME: null,
    CITYCODE: "DTL",
    CITYNAME: "DETROIT LAKES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DTM",
    AIRPORTNAME: "Dortmund",
    CITYCODE: "DTM",
    CITYNAME: "DORTMUND",
    CITYNAME_HEB: "\u05d3\u05d5\u05e8\u05d8\u05de\u05d5\u05e0\u05d3",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AKL",
    AIRPORTNAME: null,
    CITYCODE: "AKL",
    CITYNAME: "AUCKLAND",
    CITYNAME_HEB: "\u05d0\u05d5\u05e7\u05dc\u05e0\u05d3",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "DTR",
    AIRPORTNAME: "Decatur Island",
    CITYCODE: "DTR",
    CITYNAME: "DECATUR ISLAND",
    CITYNAME_HEB: "DECATUR ISLAND",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DET",
    AIRPORTNAME: null,
    CITYCODE: "DTT",
    CITYNAME: "DETROIT",
    CITYNAME_HEB: "\u05d3\u05d9\u05d8\u05e8\u05d5\u05d9\u05d8",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DTT",
    AIRPORTNAME: null,
    CITYCODE: "DTT",
    CITYNAME: "DETROIT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DTW",
    AIRPORTNAME: "Wayne County",
    CITYCODE: "DTT",
    CITYNAME: "DETROIT",
    CITYNAME_HEB: "\u05d3\u05d9\u05d8\u05e8\u05d5\u05d9\u05d8",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YIP",
    AIRPORTNAME: "Willow Run",
    CITYCODE: "DTT",
    CITYNAME: "DETROIT",
    CITYNAME_HEB: "\u05d3\u05d9\u05d8\u05e8\u05d5\u05d9\u05d8",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DUA",
    AIRPORTNAME: "Eaker",
    CITYCODE: "DUA",
    CITYNAME: "DURANT",
    CITYNAME_HEB: "DURANT",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DUB",
    AIRPORTNAME: "DUBLIN",
    CITYCODE: "DUB",
    CITYNAME: "DUBLIN",
    CITYNAME_HEB: "\u05d3\u05d1\u05dc\u05d9\u05df",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "DUC",
    AIRPORTNAME: "Halliburton",
    CITYCODE: "DUC",
    CITYNAME: "DUNCAN",
    CITYNAME_HEB: "DUNCAN",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DUD",
    AIRPORTNAME: "Dunedin",
    CITYCODE: "DUD",
    CITYNAME: "DUNEDIN",
    CITYNAME_HEB: "DUNEDIN",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "DUF",
    AIRPORTNAME: "Pine Island Airport",
    CITYCODE: "DUF",
    CITYNAME: "DUKE",
    CITYNAME_HEB: "DUKE",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DGL",
    AIRPORTNAME: null,
    CITYCODE: "DUG",
    CITYNAME: "DOUGLAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DUG",
    AIRPORTNAME: "Bisbee-Douglas International",
    CITYCODE: "DUG",
    CITYNAME: "DOUGLAS",
    CITYNAME_HEB: "DOUGLAS",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DUI",
    AIRPORTNAME: null,
    CITYCODE: "DUI",
    CITYNAME: "DUISBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DUJ",
    AIRPORTNAME: "Jefferson County",
    CITYCODE: "DUJ",
    CITYNAME: "DUBOIS",
    CITYNAME_HEB: "DUBOIS",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DUK",
    AIRPORTNAME: "Dukuduk",
    CITYCODE: "DUK",
    CITYNAME: "DUKUDUK",
    CITYNAME_HEB: "DUKUDUK",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "DUM",
    AIRPORTNAME: "Pinang Kampai",
    CITYCODE: "DUM",
    CITYNAME: "DUMAI",
    CITYNAME_HEB: "DUMAI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DUN",
    AIRPORTNAME: "Dundas",
    CITYCODE: "DUN",
    CITYNAME: "DUNDAS",
    CITYNAME_HEB: "DUNDAS",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "DUQ",
    AIRPORTNAME: "Duncan/Quam",
    CITYCODE: "DUQ",
    CITYNAME: "DUNCAN QUAM",
    CITYNAME_HEB: "DUNCAN QUAM",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "DUR",
    AIRPORTNAME: "Durban International",
    CITYCODE: "DUR",
    CITYNAME: "DURBAN",
    CITYNAME_HEB: "\u05d3\u05d5\u05e8\u05d1\u05df",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "AKM",
    AIRPORTNAME: " ",
    CITYCODE: "AKM",
    CITYNAME: "ALKMAAR",
    CITYNAME_HEB: "\u05d0\u05dc\u05e7\u05de\u05d0\u05e8",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "DUS",
    AIRPORTNAME: "Flughafen Dusseldorf International",
    CITYCODE: "DUS",
    CITYNAME: "DUSSELDORF",
    CITYNAME_HEB: "\u05d3\u05d9\u05e1\u05dc\u05d3\u05d5\u05e8\u05e3",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MGL",
    AIRPORTNAME: " ",
    CITYCODE: "DUS",
    CITYNAME: "DUSSELDORF",
    CITYNAME_HEB: "\u05d3\u05d9\u05e1\u05dc\u05d3\u05d5\u05e8\u05e3",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QDU",
    AIRPORTNAME: " ",
    CITYCODE: "DUS",
    CITYNAME: "DUSSELDORF",
    CITYNAME_HEB: "\u05d3\u05d9\u05e1\u05dc\u05d3\u05d5\u05e8\u05e3",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DUT",
    AIRPORTNAME: "Dutch Harbor",
    CITYCODE: "DUT",
    CITYNAME: "DUTCH HARBOR",
    CITYNAME_HEB: "Dutch Harbor",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DVA",
    AIRPORTNAME: "Deva",
    CITYCODE: "DVA",
    CITYNAME: "DEVA",
    CITYNAME_HEB: "DEVA",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DVL",
    AIRPORTNAME: "Devils Lake",
    CITYCODE: "DVL",
    CITYNAME: "DEVILS LAKE",
    CITYNAME_HEB: "DEVILS LAKE",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DVN",
    AIRPORTNAME: "Davenport",
    CITYCODE: "DVN",
    CITYNAME: "DAVENPORT",
    CITYNAME_HEB: "DAVENPORT",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DVO",
    AIRPORTNAME: "Mati",
    CITYCODE: "DVO",
    CITYNAME: "DAVAO",
    CITYNAME_HEB: "DAVAO",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "DVP",
    AIRPORTNAME: "Davenport Downs",
    CITYCODE: "DVP",
    CITYNAME: "DAVENPORT DOWNS",
    CITYNAME_HEB: "DAVENPORT DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AKN",
    AIRPORTNAME: "King Salmon",
    CITYCODE: "AKN",
    CITYNAME: "KING SALMON",
    CITYNAME_HEB: "King Salmon",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DVR",
    AIRPORTNAME: "Daly River",
    CITYCODE: "DVR",
    CITYNAME: "DALY RIVER",
    CITYNAME_HEB: "DALY RIVER",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DVX",
    AIRPORTNAME: null,
    CITYCODE: "DVX",
    CITYNAME: "DOVER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USDE",
    COUNTRYNAME: "DELAWARE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DWA",
    AIRPORTNAME: "Dwangwa",
    CITYCODE: "DWA",
    CITYNAME: "DWANGWA",
    CITYNAME_HEB: "DWANGWA",
    COUNTRYCODE: "MW",
    COUNTRYNAME: "MALAWI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "DWB",
    AIRPORTNAME: "Soalala",
    CITYCODE: "DWB",
    CITYNAME: "SOALALA",
    CITYNAME_HEB: "SOALALA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "DXA",
    AIRPORTNAME: " ",
    CITYCODE: "DXA",
    CITYNAME: " LE DEUX ALPES",
    CITYNAME_HEB:
      "\u05dc\u05d4 \u05d3\u05d5\u05e7\u05e1 \u05d3\u05d0\u05dc\u05e4",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "DXB",
    AIRPORTNAME: "Dubai",
    CITYCODE: "DXB",
    CITYNAME: "DUBAI",
    CITYNAME_HEB: "\u05d3\u05d5\u05d1\u05d0\u05d9",
    COUNTRYCODE: "AE",
    COUNTRYNAME: "UNITED ARAB EMIRATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d7\u05d5\u05d3 \u05d4\u05d0\u05de\u05d9\u05e8\u05d5\u05d9\u05d5\u05ea",
  },
  {
    AIRPORTCODE: "DXD",
    AIRPORTNAME: "Dixie",
    CITYCODE: "DXD",
    CITYNAME: "DIXIE",
    CITYNAME_HEB: "DIXIE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DXR",
    AIRPORTNAME: "Danbury",
    CITYCODE: "DXR",
    CITYNAME: "DANBURY",
    CITYNAME_HEB: "DANBURY",
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05e0\u05d8\u05d9\u05e7\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DYA",
    AIRPORTNAME: "Dysart",
    CITYCODE: "DYA",
    CITYNAME: "DYSART",
    CITYNAME_HEB: "DYSART",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DYG",
    AIRPORTNAME: "Dayong",
    CITYCODE: "DYG",
    CITYNAME: "DAYONG",
    CITYNAME_HEB: "DAYONG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "AAB",
    AIRPORTNAME: null,
    CITYCODE: "AAB",
    CITYNAME: "ARRABURY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AAX",
    AIRPORTNAME: null,
    CITYCODE: "AAX",
    CITYNAME: "ARAXA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AKO",
    AIRPORTNAME: "Colorado Plains Regional Airport",
    CITYCODE: "AKO",
    CITYNAME: "AKRON",
    CITYNAME_HEB: "AKRON",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DYL",
    AIRPORTNAME: "Doylestown",
    CITYCODE: "DYL",
    CITYNAME: "DOYLESTOWN",
    CITYNAME_HEB: "DOYLESTOWN",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DYM",
    AIRPORTNAME: "Diamantina Lakes",
    CITYCODE: "DYM",
    CITYNAME: "DIAMANTINA LAKES",
    CITYNAME_HEB: "DIAMANTINA LAKES",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DYR",
    AIRPORTNAME: "Anadyr",
    CITYCODE: "DYR",
    CITYNAME: "ANADYR",
    CITYNAME_HEB: "ANADYR",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DYU",
    AIRPORTNAME: "Dushanbe",
    CITYCODE: "DYU",
    CITYNAME: "DUSHANBE",
    CITYNAME_HEB: "\u05d3\u05d5\u05e9\u05e0\u05d1\u05d4",
    COUNTRYCODE: "TJ",
    COUNTRYNAME: "TAJIKISTAN",
    COUNTRYNAME_HEB: "\u05d8\u05d2'\u05d9\u05e7\u05d9\u05e1\u05d8\u05d0\u05df",
  },
  {
    AIRPORTCODE: "DYW",
    AIRPORTNAME: "Daly Waters",
    CITYCODE: "DYW",
    CITYNAME: "DALY WATERS",
    CITYNAME_HEB: "DALY WATERS",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DZA",
    AIRPORTNAME: null,
    CITYCODE: "DZA",
    CITYNAME: "DZAOUDZI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "YT",
    COUNTRYNAME: "MAYOTTE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DZI",
    AIRPORTNAME: "Codazzi",
    CITYCODE: "DZI",
    CITYNAME: "CODAZZI",
    CITYNAME_HEB: "CODAZZI",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DZN",
    AIRPORTNAME: "Zhezhazgan",
    CITYCODE: "DZN",
    CITYNAME: "ZHEZKAZGAN",
    CITYNAME_HEB: "ZHEZKAZGAN",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "DZO",
    AIRPORTNAME: "Durazno",
    CITYCODE: "DZO",
    CITYNAME: "DURAZNO",
    CITYNAME_HEB: "DURAZNO",
    COUNTRYCODE: "UY",
    COUNTRYNAME: "URUGUAY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e8\u05d5\u05d2\u05d0\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "DZU",
    AIRPORTNAME: "Dazu",
    CITYCODE: "DZU",
    CITYNAME: "DAZU",
    CITYNAME_HEB: "DAZU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "AKP",
    AIRPORTNAME: "Anaktuvuk",
    CITYCODE: "AKP",
    CITYNAME: "ANAKTUVUK",
    CITYNAME_HEB: "Anaktuvuk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EAA",
    AIRPORTNAME: "Eagle",
    CITYCODE: "EAA",
    CITYNAME: "EAGLE",
    CITYNAME_HEB: "Eagle",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EAB",
    AIRPORTNAME: "Abbse",
    CITYCODE: "EAB",
    CITYNAME: "ABBSE",
    CITYNAME_HEB: "ABBSE",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "EAE",
    AIRPORTNAME: "Emae",
    CITYCODE: "EAE",
    CITYNAME: "EMAE",
    CITYNAME_HEB: "EMAE",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "EAN",
    AIRPORTNAME: "Phifer Field",
    CITYCODE: "EAN",
    CITYNAME: "WHEATLAND",
    CITYNAME_HEB: "WHEATLAND",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BSL",
    AIRPORTNAME: "EuroAirport Swiss",
    CITYCODE: "BSL",
    CITYNAME: "BASEL MULHOUSE",
    CITYNAME_HEB: "\u05d1\u05d0\u05d6\u05dc",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "EAR",
    AIRPORTNAME: "Kearney",
    CITYCODE: "EAR",
    CITYNAME: "KEARNEY",
    CITYNAME_HEB: "KEARNEY",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AKQ",
    AIRPORTNAME: "Gunung Batin",
    CITYCODE: "AKQ",
    CITYNAME: "ASTRAKSETRA",
    CITYNAME_HEB: "ASTRAKSETRA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EAS",
    AIRPORTNAME: "San Sebastian",
    CITYCODE: "EAS",
    CITYNAME: "SAN SEBASTIAN",
    CITYNAME_HEB:
      "\u05e1\u05d0\u05df \u05e1\u05d1\u05e1\u05d8\u05d9\u05d0\u05df",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "EAT",
    AIRPORTNAME: "Pangborn Field",
    CITYCODE: "EAT",
    CITYNAME: "WENATCHEE",
    CITYNAME_HEB: "WENATCHEE",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EAU",
    AIRPORTNAME: "Eau Claire",
    CITYCODE: "EAU",
    CITYNAME: "EAU CLAIRE",
    CITYNAME_HEB: "EAU CLAIRE",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EBA",
    AIRPORTNAME: "Marina di Campo",
    CITYCODE: "EBA",
    CITYNAME: "ELBA ISLAND",
    CITYNAME_HEB: "ELBA ISLAND",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EBB",
    AIRPORTNAME: "Entebbe",
    CITYCODE: "EBB",
    CITYNAME: "ENTEBBE",
    CITYNAME_HEB: "\u05d0\u05e0\u05d8\u05d1\u05d4",
    COUNTRYCODE: "UG",
    COUNTRYNAME: "UGANDA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "EBD",
    AIRPORTNAME: "El Obeid",
    CITYCODE: "EBD",
    CITYNAME: "EL OBEID",
    CITYNAME_HEB: "EL OBEID",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "EBG",
    AIRPORTNAME: "El Bagre",
    CITYCODE: "EBG",
    CITYNAME: "EL BAGRE",
    CITYNAME_HEB: "EL BAGRE",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EBJ",
    AIRPORTNAME: "Esbjerg",
    CITYCODE: "EBJ",
    CITYNAME: "ESBJERG",
    CITYNAME_HEB: "ESBJERG",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "ZBB",
    AIRPORTNAME: null,
    CITYCODE: "EBJ",
    CITYNAME: "ESBJERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "EBM",
    AIRPORTNAME: "El Borma",
    CITYCODE: "EBM",
    CITYNAME: "EL BORMA",
    CITYNAME_HEB: "EL BORMA",
    COUNTRYCODE: "TN",
    COUNTRYNAME: "TUNISIA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d9\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AKR",
    AIRPORTNAME: "Akure",
    CITYCODE: "AKR",
    CITYNAME: "AKURE",
    CITYNAME_HEB: "AKURE",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EBN",
    AIRPORTNAME: "Ebadon",
    CITYCODE: "EBN",
    CITYNAME: "EBADON",
    CITYNAME_HEB: "EBADON",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "EBO",
    AIRPORTNAME: "Ebon Airport",
    CITYCODE: "EBO",
    CITYNAME: "EBON",
    CITYNAME_HEB: "EBON",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "EBS",
    AIRPORTNAME: "Municipal",
    CITYCODE: "EBS",
    CITYNAME: "WEBSTER CITY",
    CITYNAME_HEB: "WEBSTER CITY",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EBU",
    AIRPORTNAME: "Boutheon",
    CITYCODE: "EBU",
    CITYNAME: "SAINT ETIENNE",
    CITYNAME_HEB: "\u05e1\u05d8. \u05d0\u05d8\u05d9\u05d9\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "EBW",
    AIRPORTNAME: "Ebolowa",
    CITYCODE: "EBW",
    CITYNAME: "EBOLOWA",
    CITYNAME_HEB: "EBOLOWA",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "HOF",
    AIRPORTNAME: " ",
    CITYCODE: "HOF",
    CITYNAME: "holiday village",
    CITYNAME_HEB:
      "\u05db\u05e4\u05e8\u05d9 \u05e0\u05d5\u05e4\u05e9 \u05d1\u05d4\u05d5\u05dc\u05e0\u05d3",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "AUE",
    AIRPORTNAME: " ",
    CITYCODE: "AUE",
    CITYNAME: "AUSTRIA",
    CITYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "BGW",
    CITYNAME: "BAD GOEGGING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "DPR",
    AIRPORTNAME: " ",
    CITYCODE: "DPR",
    CITYNAME: "EURODISNEY",
    CITYNAME_HEB:
      "\u05e4\u05e8\u05d9\u05d6-\u05d9\u05d5\u05e8\u05d5\u05d3\u05d9\u05e1\u05e0\u05d9",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "ENG",
    AIRPORTNAME: " ",
    CITYCODE: "ENG",
    CITYNAME: "ENGLAND",
    CITYNAME_HEB: "\u05d0\u05e0\u05d2\u05dc\u05d9\u05d4",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BWL",
    AIRPORTNAME: "Blackwell",
    CITYCODE: "BWL",
    CITYNAME: "BLACKWELL",
    CITYNAME_HEB: "BLACKWELL",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FIS",
    AIRPORTNAME: " ",
    CITYCODE: "FIS",
    CITYNAME: "SICILY",
    CITYNAME_HEB: "\u05e1\u05d9\u05e6\u05d9\u05dc\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "FLQ",
    CITYNAME: "FLORIDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KCT",
    AIRPORTNAME: " ",
    CITYCODE: "KCT",
    CITYNAME: "KOH CHANG",
    CITYNAME_HEB: "\u05e7\u05d5 \u05e6\u05d0\u05e0\u05d2",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "FBA",
    AIRPORTNAME: " ",
    CITYCODE: "FBA",
    CITYNAME: "ROGASKA SLATINA",
    CITYNAME_HEB:
      "\u05e8\u05d5\u05d2\u05e9\u05e7\u05d4 \u05e1\u05dc\u05d8\u05d9\u05e0\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FSO",
    AIRPORTNAME: " ",
    CITYCODE: "FSO",
    CITYNAME: "SLOVENIA",
    CITYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FBF",
    AIRPORTNAME: " ",
    CITYCODE: "FBF",
    CITYNAME: "SLOVAKIA",
    CITYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BWM",
    AIRPORTNAME: "Bowman",
    CITYCODE: "BWM",
    CITYNAME: "BOWMAN",
    CITYNAME_HEB: "BOWMAN",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FBH",
    AIRPORTNAME: " ",
    CITYCODE: "FBH",
    CITYNAME: "ROMANIA",
    CITYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FSB",
    AIRPORTNAME: " ",
    CITYCODE: "FSB",
    CITYNAME: "SINAIA",
    CITYNAME_HEB: "\u05e1\u05d9\u05e0\u05d9\u05d9\u05d4",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FBT",
    AIRPORTNAME: " ",
    CITYCODE: "FBT",
    CITYNAME: "SWITZERLAND",
    CITYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "FTR",
    AIRPORTNAME: " ",
    CITYCODE: "FTR",
    CITYNAME: "TIROL",
    CITYNAME_HEB: "\u05d7\u05d1\u05dc \u05d8\u05d9\u05e8\u05d5\u05dc",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "VCG",
    CITYNAME: "VENICE AREA",
    CITYNAME_HEB: "\u05d0\u05d6\u05d5\u05e8 \u05d5\u05e0\u05e6\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VCM",
    AIRPORTNAME: " ",
    CITYCODE: "VCM",
    CITYNAME: "VENICE MESTRE",
    CITYNAME_HEB:
      "\u05d5\u05e0\u05e6\u05d9\u05d4 \u05de\u05e1\u05d8\u05e8\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "VCL",
    CITYNAME: "VENICE LIDO",
    CITYNAME_HEB: "\u05d5\u05e0\u05e6\u05d9\u05d4 \u05dc\u05d9\u05d3\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "VCN",
    CITYNAME: "VENICE MARGHERA",
    CITYNAME_HEB:
      "\u05d5\u05e0\u05e6\u05d9\u05d4 \u05de\u05e8\u05d2\u05e8\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "VCI",
    CITYNAME: "VENICE PIAZZALE ROMA",
    CITYNAME_HEB:
      "\u05d5\u05e0\u05e6\u05d9\u05d4 \u05e4\u05d9\u05d0\u05e6\u05d4 \u05dc\u05d4 \u05e8\u05d5\u05de\u05d0",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BWN",
    AIRPORTNAME: "Brunei International",
    CITYCODE: "BWN",
    CITYNAME: "BANDAR SERI BEGAW",
    CITYNAME_HEB: "BANDAR SERI BEGAW",
    COUNTRYCODE: "BN",
    COUNTRYNAME: "BRUNEI DARUSSALAM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d5\u05e0\u05d9",
  },
  {
    AIRPORTCODE: "FEX",
    AIRPORTNAME: " ",
    CITYCODE: "FEX",
    CITYNAME: "FELIX",
    CITYNAME_HEB: "\u05e4\u05dc\u05d9\u05e7\u05e1",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "KIH",
    CITYNAME: "KILLARNEY",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "ISR",
    CITYNAME: "SIRMIONE",
    CITYNAME_HEB: "\u05e1\u05d9\u05e8\u05de\u05d9\u05d5\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SOS",
    AIRPORTNAME: " ",
    CITYCODE: "SOS",
    CITYNAME: "SOESTDUINEN",
    CITYNAME_HEB:
      "\u05e1\u05d5\u05d0\u05e1\u05d8\u05d3\u05d5\u05e0\u05d0\u05df",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ITC",
    AIRPORTNAME: " ",
    CITYCODE: "ITC",
    CITYNAME: "TARVISIO",
    CITYNAME_HEB: "\u05d8\u05e8\u05d5\u05d5\u05d9\u05d6\u05d9\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UMB",
    AIRPORTNAME: " ",
    CITYCODE: "UMB",
    CITYNAME: "UMBRIA",
    CITYNAME_HEB: "\u05d0\u05d5\u05de\u05d1\u05e8\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VAJ",
    AIRPORTNAME: " ",
    CITYCODE: "VAJ",
    CITYNAME: "VALLNORD",
    CITYNAME_HEB: "\u05d5\u05d5\u05d0\u05dc\u05e0\u05d5\u05e8\u05d3",
    COUNTRYCODE: "AD",
    COUNTRYNAME: "ANDORRA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d3\u05d5\u05e8\u05d4",
  },
  {
    AIRPORTCODE: "USA",
    AIRPORTNAME: " ",
    CITYCODE: "USA",
    CITYNAME: "UNITED STATES",
    CITYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "VCO",
    AIRPORTNAME: " ",
    CITYCODE: "VCO",
    CITYNAME: "BRASOV",
    CITYNAME_HEB: "\u05d1\u05e8\u05e9\u05d5\u05d1",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BWO",
    AIRPORTNAME: "Balakovo",
    CITYCODE: "BWO",
    CITYNAME: "BALAKOVO",
    CITYNAME_HEB: "BALAKOVO",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FCE",
    AIRPORTNAME: " ",
    CITYCODE: "FCE",
    CITYNAME: "CANNES AREA",
    CITYNAME_HEB:
      "\u05e7\u05d0\u05df \u05d5\u05d4\u05e1\u05d1\u05d9\u05d1\u05d4",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "KYR",
    AIRPORTNAME: " ",
    CITYCODE: "KYR",
    CITYNAME: "KYRENIA/TURKISH SIDE",
    CITYNAME_HEB:
      "\u05e7\u05d9\u05d9\u05e8\u05e0\u05d9\u05d4/\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df \u05d4\u05ea\u05d5\u05e8\u05db\u05d9\u05ea",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "IAC",
    AIRPORTNAME: " ",
    CITYCODE: "IAC",
    CITYNAME: "ACIREALE",
    CITYNAME_HEB: "\u05d0\u05e7\u05d9\u05e8\u05d0\u05dc\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "IAF",
    CITYNAME: "AFFI",
    CITYNAME_HEB: "\u05d0\u05e4\u05e4\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "ALP",
    CITYNAME: "ABUFERIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "BDH",
    CITYNAME: "ANGRA DOS REIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ASW",
    AIRPORTNAME: " ",
    CITYCODE: "ASW",
    CITYNAME: "ASSISI",
    CITYNAME_HEB: "\u05d0\u05e1\u05d9\u05e1\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ABD",
    AIRPORTNAME: " ",
    CITYCODE: "ABD",
    CITYNAME: "BAD BLANKENBURG",
    CITYNAME_HEB:
      "\u05d1\u05d0\u05d3 \u05d1\u05dc\u05e0\u05e7\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FBB",
    AIRPORTNAME: " ",
    CITYCODE: "FBB",
    CITYNAME: "BARDEJOV",
    CITYNAME_HEB: "\u05d1\u05e8\u05d3\u05e6\u05d5\u05d1",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IBD",
    AIRPORTNAME: " ",
    CITYCODE: "IBD",
    CITYNAME: "BASSANO DEL GRAPPA",
    CITYNAME_HEB:
      "\u05d1\u05e1\u05d0\u05e0\u05d5 \u05d3\u05dc \u05d2\u05e8\u05d0\u05e4\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BWP",
    AIRPORTNAME: "Bewani",
    CITYCODE: "BWP",
    CITYNAME: "BEWANI",
    CITYNAME_HEB: "BEWANI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LTJ",
    AIRPORTNAME: null,
    CITYCODE: "LTJ",
    CITYNAME: "BIRSTONAS",
    CITYNAME_HEB: "\u05d1\u05d9\u05e8\u05e9\u05d8\u05d5\u05e0\u05d0\u05e1",
    COUNTRYCODE: "LT",
    COUNTRYNAME: "LITHUANIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d8\u05d0",
  },
  {
    AIRPORTCODE: "CYD",
    AIRPORTNAME: " ",
    CITYCODE: "CYD",
    CITYNAME: "CYPRUS",
    CITYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "DOJ",
    AIRPORTNAME: " ",
    CITYCODE: "DOJ",
    CITYNAME: "DOLOMITE",
    CITYNAME_HEB: "\u05d3\u05d5\u05dc\u05d5\u05de\u05d9\u05d8\u05d9\u05dd",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GNO",
    AIRPORTNAME: " ",
    CITYCODE: "GNO",
    CITYNAME: "GENOVA",
    CITYNAME_HEB: "\u05d2\u05e0\u05d5\u05d1\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "ULF",
    CITYNAME: "ULUDAG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "PIJ",
    CITYNAME: "PISTOIA",
    CITYNAME_HEB: "\u05e4\u05d9\u05e1\u05d8\u05d5\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ALY",
    AIRPORTNAME: " ",
    CITYCODE: "ALY",
    CITYNAME: "ALBENA",
    CITYNAME_HEB: "\u05d0\u05dc\u05d1\u05e0\u05d4",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DUH",
    AIRPORTNAME: " ",
    CITYCODE: "DUH",
    CITYNAME: "DUDINCE",
    CITYNAME_HEB: "\u05d3\u05d5\u05d3\u05d9\u05e0\u05e5",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BND",
    AIRPORTNAME: " ",
    CITYCODE: "BND",
    CITYNAME: "BADEN BADEN",
    CITYNAME_HEB: "\u05d1\u05d0\u05d3\u05df \u05d1\u05d0\u05d3\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AFO",
    AIRPORTNAME: "Afton Municipal",
    CITYCODE: "AFO",
    CITYNAME: "AFTON",
    CITYNAME_HEB: "Afton Municipal",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BWQ",
    AIRPORTNAME: "Brewarrina",
    CITYCODE: "BWQ",
    CITYNAME: "BREWARRINA",
    CITYNAME_HEB: "BREWARRINA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ABT",
    AIRPORTNAME: " ",
    CITYCODE: "ABT",
    CITYNAME: "ABANO TERME",
    CITYNAME_HEB: "\u05d0\u05d1\u05d0\u05e0\u05d5 \u05d8\u05e8\u05de\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GBQ",
    AIRPORTNAME: " ",
    CITYCODE: "GBQ",
    CITYNAME: "BLACK FOREST",
    CITYNAME_HEB: "\u05d4\u05d9\u05e2\u05e8 \u05d4\u05e9\u05d7\u05d5\u05e8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BWS",
    AIRPORTNAME: "Blaine",
    CITYCODE: "BWS",
    CITYNAME: "BLAINE",
    CITYNAME_HEB: "BLAINE",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BWT",
    AIRPORTNAME: null,
    CITYCODE: "BWT",
    CITYNAME: "BURNIE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUTS",
    COUNTRYNAME: "TASMANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BWU",
    AIRPORTNAME: "Bankstown",
    CITYCODE: "BWU",
    CITYNAME: "BANKSTOWN",
    CITYNAME_HEB: "BANKSTOWN",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BWY",
    AIRPORTNAME: "Bentwaters St",
    CITYCODE: "BWY",
    CITYNAME: "WOODBRIDGE",
    CITYNAME_HEB: "WOODBRIDGE",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BXA",
    AIRPORTNAME: "George R Carr Air Field",
    CITYCODE: "BXA",
    CITYNAME: "BOGALUSA",
    CITYNAME_HEB: "BOGALUSA",
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB:
      '\u05dc\u05d5\u05d0\u05d9\u05d6\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BXB",
    AIRPORTNAME: "Babo",
    CITYCODE: "BXB",
    CITYNAME: "BABO",
    CITYNAME_HEB: "BABO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BXC",
    AIRPORTNAME: "Boxborough",
    CITYCODE: "BXC",
    CITYNAME: "BOXBOROUGH",
    CITYNAME_HEB: "BOXBOROUGH",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "BXD",
    AIRPORTNAME: "Bade",
    CITYCODE: "BXD",
    CITYNAME: "BADE",
    CITYNAME_HEB: "BADE",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BXE",
    AIRPORTNAME: "Bakel",
    CITYCODE: "BXE",
    CITYNAME: "BAKEL",
    CITYNAME_HEB: "BAKEL",
    COUNTRYCODE: "SN",
    COUNTRYNAME: "SENEGAL",
    COUNTRYNAME_HEB: "\u05e1\u05e0\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "AFR",
    AIRPORTNAME: "Afore",
    CITYCODE: "AFR",
    CITYNAME: "AFORE",
    CITYNAME_HEB: "AFORE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BXG",
    AIRPORTNAME: "Bendigo",
    CITYCODE: "BXG",
    CITYNAME: "BENDIGO",
    CITYNAME_HEB: "BENDIGO",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BXH",
    AIRPORTNAME: "Balhash",
    CITYCODE: "BXH",
    CITYNAME: "BALHASH",
    CITYNAME_HEB: "BALHASH",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "BXI",
    AIRPORTNAME: "Boundiali",
    CITYCODE: "BXI",
    CITYNAME: "BOUNDIALI",
    CITYNAME_HEB: "BOUNDIALI",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "BXJ",
    AIRPORTNAME: "Burundai",
    CITYCODE: "BXJ",
    CITYNAME: "BURUNDAI",
    CITYNAME_HEB: "BURUNDAI",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "BXK",
    AIRPORTNAME: "Buckeye",
    CITYCODE: "BXK",
    CITYNAME: "BUCKEYE",
    CITYNAME_HEB: "BUCKEYE",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BXL",
    AIRPORTNAME: "Blue Lagoon",
    CITYCODE: "BXL",
    CITYNAME: "BLUE LAGOON",
    CITYNAME_HEB: "BLUE LAGOON",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "BXM",
    AIRPORTNAME: "Batom",
    CITYCODE: "BXM",
    CITYNAME: "BATOM",
    CITYNAME_HEB: "BATOM",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BJV",
    AIRPORTNAME: "Milas Airport",
    CITYCODE: "BJV",
    CITYNAME: "BODRUM",
    CITYNAME_HEB: "\u05d1\u05d5\u05d3\u05e8\u05d5\u05dd",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BXS",
    AIRPORTNAME: "Borrego Springs",
    CITYCODE: "BXS",
    CITYNAME: "BORREGO SPRINGS",
    CITYNAME_HEB: "BORREGO SPRINGS",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AFT",
    AIRPORTNAME: "Afutara Aerodrome",
    CITYCODE: "AFT",
    CITYNAME: "AFUTARA",
    CITYNAME_HEB: "AFUTARA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e1\u05dc\u05d5\u05de\u05d5\u05df",
  },
  {
    AIRPORTCODE: "BXT",
    AIRPORTNAME: "Bontang",
    CITYCODE: "BXT",
    CITYNAME: "BONTANG",
    CITYNAME_HEB: "BONTANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BXU",
    AIRPORTNAME: "Butuan",
    CITYCODE: "BXU",
    CITYNAME: "BUTUAN",
    CITYNAME_HEB: "BUTUAN",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "BXV",
    AIRPORTNAME: "Breiddalsvik",
    CITYCODE: "BXV",
    CITYNAME: "BREIDDALSVIK",
    CITYNAME_HEB: "BREIDDALSVIK",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BXX",
    AIRPORTNAME: "Borama",
    CITYCODE: "BXX",
    CITYNAME: "BORAMA",
    CITYNAME_HEB: "BORAMA",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BXZ",
    AIRPORTNAME: "Bunsil",
    CITYCODE: "BXZ",
    CITYNAME: "BUNSIL",
    CITYNAME_HEB: "BUNSIL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BYA",
    AIRPORTNAME: "Boundary",
    CITYCODE: "BYA",
    CITYNAME: "BOUNDARY",
    CITYNAME_HEB: "Boundary",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BYB",
    AIRPORTNAME: null,
    CITYCODE: "BYB",
    CITYNAME: "DIBAA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "OM",
    COUNTRYNAME: "OMAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BYC",
    AIRPORTNAME: "Yacuiba",
    CITYCODE: "BYC",
    CITYNAME: "YACUIBA",
    CITYNAME_HEB: "YACUIBA",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BYD",
    AIRPORTNAME: "Beidah",
    CITYCODE: "BYD",
    CITYNAME: "BEIDAH",
    CITYNAME_HEB: "BEIDAH",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "BYG",
    AIRPORTNAME: "Municipal",
    CITYCODE: "BYG",
    CITYNAME: "BUFFALO",
    CITYNAME_HEB: "\u05d1\u05d0\u05e4\u05d0\u05dc\u05d5",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AAQ",
    AIRPORTNAME: "Vityazevo Airport",
    CITYCODE: "AAQ",
    CITYNAME: "ANAPA",
    CITYNAME_HEB: "ANAPA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AFY",
    AIRPORTNAME: "Afyon",
    CITYCODE: "AFY",
    CITYNAME: "AFYON",
    CITYNAME_HEB: "AFYON",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BYH",
    AIRPORTNAME: "Blytheville AFB",
    CITYCODE: "BYH",
    CITYNAME: "BLYTHEVILLE",
    CITYNAME_HEB: "BLYTHEVILLE",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HKA",
    AIRPORTNAME: "Municipal",
    CITYCODE: "BYH",
    CITYNAME: "BLYTHEVILLE",
    CITYNAME_HEB: "BLYTHEVILLE",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BYI",
    AIRPORTNAME: null,
    CITYCODE: "BYI",
    CITYNAME: "BURLEY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BYK",
    AIRPORTNAME: "Bouake",
    CITYCODE: "BYK",
    CITYNAME: "BOUAKE",
    CITYNAME_HEB: "BOUAKE",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "BYL",
    AIRPORTNAME: "Bella Yella",
    CITYCODE: "BYL",
    CITYNAME: "BELLA YELLA",
    CITYNAME_HEB: "BELLA YELLA",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BYM",
    AIRPORTNAME: "C.M. de Cespedes",
    CITYCODE: "BYM",
    CITYNAME: "BAYAMO",
    CITYNAME_HEB: "BAYAMO",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "BYN",
    AIRPORTNAME: "Bayankhongor",
    CITYCODE: "BYN",
    CITYNAME: "BAYANKHONGOR",
    CITYNAME_HEB: "BAYANKHONGOR",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BYQ",
    AIRPORTNAME: "Bunyu",
    CITYCODE: "BYQ",
    CITYNAME: "BUNYU",
    CITYNAME_HEB: "BUNYU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BYR",
    AIRPORTNAME: "Laeso Airport",
    CITYCODE: "BYR",
    CITYNAME: "LAESO AIRPORT",
    CITYNAME_HEB: "LAESO AIRPORT",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "BYS",
    AIRPORTNAME: "Bicycle Lake AAF",
    CITYCODE: "BYS",
    CITYNAME: "FORT IRWIN",
    CITYNAME_HEB: "FORT IRWIN",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AGA",
    AIRPORTNAME: "Agadir Almassira",
    CITYCODE: "AGA",
    CITYNAME: "AGADIR",
    CITYNAME_HEB: "\u05d0\u05d2\u05d0\u05d3\u05d9\u05e8",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "BYT",
    AIRPORTNAME: "Bantry",
    CITYCODE: "BYT",
    CITYNAME: "BANTRY",
    CITYNAME_HEB: "BANTRY",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BYU",
    AIRPORTNAME: "Bindlacher-Berg",
    CITYCODE: "BYU",
    CITYNAME: "BAYREUTH",
    CITYNAME_HEB: "BAYREUTH",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BYW",
    AIRPORTNAME: "Blakely Island",
    CITYCODE: "BYW",
    CITYNAME: "BLAKELY ISLAND",
    CITYNAME_HEB: "BLAKELY ISLAND",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BYX",
    AIRPORTNAME: "Baniyala",
    CITYCODE: "BYX",
    CITYNAME: "BANIYALA",
    CITYNAME_HEB: "BANIYALA",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BZA",
    AIRPORTNAME: "San Pedro",
    CITYCODE: "BZA",
    CITYNAME: "BONANZA",
    CITYNAME_HEB: "BONANZA",
    COUNTRYCODE: "NI",
    COUNTRYNAME: "NICARAGUA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05e7\u05e8\u05d0\u05d2\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BZB",
    AIRPORTNAME: "Bazaruto Island",
    CITYCODE: "BZB",
    CITYNAME: "BAZARUTO ISLAND",
    CITYNAME_HEB: "BAZARUTO ISLAND",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "BZC",
    AIRPORTNAME: null,
    CITYCODE: "BZC",
    CITYNAME: "BUZIOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BZD",
    AIRPORTNAME: "Balranald",
    CITYCODE: "BZD",
    CITYNAME: "BALRANALD",
    CITYNAME_HEB: "BALRANALD",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BZE",
    AIRPORTNAME: "Philip S.W.Goldson International",
    CITYCODE: "BZE",
    CITYNAME: "BELIZE CITY",
    CITYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6 \u05e1\u05d9\u05d8\u05d9",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "AGD",
    AIRPORTNAME: "Anggi",
    CITYCODE: "AGD",
    CITYNAME: "ANGGI",
    CITYNAME_HEB: "ANGGI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BZG",
    AIRPORTNAME: "Bydgoszcz",
    CITYCODE: "BZG",
    CITYNAME: "BYDGOSZCZ",
    CITYNAME_HEB: "BYDGOSZCZ",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "BZH",
    AIRPORTNAME: "Bumi Hills",
    CITYCODE: "BZH",
    CITYNAME: "BUMI HILLS",
    CITYNAME_HEB: "BUMI HILLS",
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: "\u05d6\u05d9\u05de\u05d1\u05d1\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BZI",
    AIRPORTNAME: "Balikesir",
    CITYCODE: "BZI",
    CITYNAME: "BALIKESIR",
    CITYNAME_HEB: "BALIKESIR",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BZK",
    AIRPORTNAME: "Briansk",
    CITYCODE: "BZK",
    CITYNAME: "BRIANSK",
    CITYNAME_HEB: "\u05d1\u05e8\u05d9\u05d0\u05e0\u05e1\u05e7",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BZL",
    AIRPORTNAME: "Barisal",
    CITYCODE: "BZL",
    CITYNAME: "BARISAL",
    CITYNAME_HEB: "BARISAL",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "BZM",
    AIRPORTNAME: "Woensdrecht",
    CITYCODE: "BZM",
    CITYNAME: "BERGEN OP ZOOM",
    CITYNAME_HEB: "BERGEN OP ZOOM",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BZN",
    AIRPORTNAME: "Gallatin Field",
    CITYCODE: "BZN",
    CITYNAME: "BOZEMAN",
    CITYNAME_HEB: "BOZEMAN",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BZO",
    AIRPORTNAME: "Bolzano",
    CITYCODE: "BZO",
    CITYNAME: "BOLZANO",
    CITYNAME_HEB: "\u05d1\u05d5\u05dc\u05d6\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BZP",
    AIRPORTNAME: "Bizant",
    CITYCODE: "BZP",
    CITYNAME: "BIZANT",
    CITYNAME_HEB: "BIZANT",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BZR",
    AIRPORTNAME: "Beziers Vias",
    CITYCODE: "BZR",
    CITYNAME: "BEZIERS",
    CITYNAME_HEB: "BEZIERS",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "AGE",
    AIRPORTNAME: "Flugplatz",
    CITYCODE: "AGE",
    CITYNAME: "WANGEROOGE",
    CITYNAME_HEB: "WANGEROOGE",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BZT",
    AIRPORTNAME: "Hinkles Ferry",
    CITYCODE: "BZT",
    CITYNAME: "BRAZORIA",
    CITYNAME_HEB: "BRAZORIA",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BZU",
    AIRPORTNAME: "Buta",
    CITYCODE: "BZU",
    CITYNAME: "BUTA",
    CITYNAME_HEB: "BUTA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "BZV",
    AIRPORTNAME: "Maya Maya",
    CITYCODE: "BZV",
    CITYNAME: "BRAZZAVILLE",
    CITYNAME_HEB: "\u05d1\u05e8\u05d6\u05d5\u05d5\u05d9\u05dc",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "BZY",
    AIRPORTNAME: "Beltsy",
    CITYCODE: "BZY",
    CITYNAME: "BELTSY",
    CITYNAME_HEB: "BELTSY",
    COUNTRYCODE: "MD",
    COUNTRYNAME: "MOLDOVA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05dc\u05d3\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "BZZ",
    AIRPORTNAME: "RAF Station",
    CITYCODE: "BZZ",
    CITYNAME: "BRIZE NORTON",
    CITYNAME_HEB: "BRIZE NORTON",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CAA",
    AIRPORTNAME: "Catacamas",
    CITYCODE: "CAA",
    CITYNAME: "CATACAMAS",
    CITYNAME_HEB: "CATACAMAS",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "CAB",
    AIRPORTNAME: " ",
    CITYCODE: "CAB",
    CITYNAME: "CAMBODIA",
    CITYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CAC",
    AIRPORTNAME: "Metropolitan Area",
    CITYCODE: "CAC",
    CITYNAME: "CACIULATA",
    CITYNAME_HEB: "\u05e7\u05d0\u05e6\u05d9\u05d5\u05dc\u05d0\u05d8\u05d4",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CAD",
    AIRPORTNAME: "Cadillac",
    CITYCODE: "CAD",
    CITYNAME: "CADILLAC",
    CITYNAME_HEB: "CADILLAC",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CAE",
    AIRPORTNAME: "Metropolitan Airport",
    CITYCODE: "CAE",
    CITYNAME: "COLUMBIA",
    CITYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AGF",
    AIRPORTNAME: "La Garenne",
    CITYCODE: "AGF",
    CITYNAME: "AGEN",
    CITYNAME_HEB: "\u05d0\u05d2\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "CEA",
    CITYNAME: "Caesarea",
    CITYNAME_HEB: "\u05e7\u05d9\u05e1\u05e8\u05d9\u05d4",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "CAF",
    AIRPORTNAME: null,
    CITYCODE: "CAF",
    CITYNAME: "CARAUARI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CAG",
    AIRPORTNAME: "Elmas",
    CITYCODE: "CAG",
    CITYNAME: "CAGLIARI",
    CITYNAME_HEB: "\u05e7\u05dc\u05d9\u05d0\u05e8\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CAH",
    AIRPORTNAME: "Ca Mau",
    CITYCODE: "CAH",
    CITYNAME: "CA MAU",
    CITYNAME_HEB: "CA MAU",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "CAJ",
    AIRPORTNAME: "Canaima",
    CITYCODE: "CAJ",
    CITYNAME: "CANAIMA",
    CITYNAME_HEB: "CANAIMA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "AKC",
    AIRPORTNAME: "Akron Fulton International Airport",
    CITYCODE: "CAK",
    CITYNAME: "CANTON AKRON",
    CITYNAME_HEB: "CANTON AKRON",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CAK",
    AIRPORTNAME: null,
    CITYCODE: "CAK",
    CITYNAME: "CANTON AKRON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CAL",
    AIRPORTNAME: "Machrihanish",
    CITYCODE: "CAL",
    CITYNAME: "CAMPBELTOWN",
    CITYNAME_HEB: "CAMPBELTOWN",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AGG",
    AIRPORTNAME: "Angoram",
    CITYCODE: "AGG",
    CITYNAME: "ANGORAM",
    CITYNAME_HEB: "ANGORAM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "CAM",
    AIRPORTNAME: " ",
    CITYCODE: "CAM",
    CITYNAME: "CORTINA DAMPEZZO",
    CITYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d8\u05d9\u05e0\u05d4 \u05d3\u05d0\u05de\u05e4\u05d0\u05d6\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CAN",
    AIRPORTNAME: "Baiyun",
    CITYCODE: "CAN",
    CITYNAME: "GUANGZHOU",
    CITYNAME_HEB: "\u05d2\u05d5\u05d0\u05e0\u05d6\u05d5",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CAO",
    AIRPORTNAME: "Clayton",
    CITYCODE: "CAO",
    CITYNAME: "CLAYTON",
    CITYNAME_HEB: "CLAYTON",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CAP",
    AIRPORTNAME: null,
    CITYCODE: "CAP",
    CITYNAME: "CAPPADOCIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CAQ",
    AIRPORTNAME: "Caucasia",
    CITYCODE: "CAQ",
    CITYNAME: "CAUCASIA",
    CITYNAME_HEB: "CAUCASIA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CAR",
    AIRPORTNAME: " ",
    CITYCODE: "CAR",
    CITYNAME: "CAORLE",
    CITYNAME_HEB: "\u05e7\u05d0\u05d5\u05e8\u05dc\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CAS",
    AIRPORTNAME: "Anfa",
    CITYCODE: "CAS",
    CITYNAME: "CASABLANCA",
    CITYNAME_HEB: "\u05e7\u05d6\u05d1\u05dc\u05e0\u05e7\u05d4",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CMN",
    AIRPORTNAME: "Mohamed V",
    CITYCODE: "CAS",
    CITYNAME: "CASABLANCA",
    CITYNAME_HEB: "\u05e7\u05d6\u05d1\u05dc\u05e0\u05e7\u05d4",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CAT",
    AIRPORTNAME: " ",
    CITYCODE: "CAT",
    CITYNAME: "CROATIA",
    CITYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CAU",
    AIRPORTNAME: null,
    CITYCODE: "CAU",
    CITYNAME: "CARUARU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AGH",
    AIRPORTNAME: "Angelholm",
    CITYCODE: "AGH",
    CITYNAME: "ANGELHOLM/HELSINGBORG",
    CITYNAME_HEB: "ANGELHOLM/HELSINGBORG",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "CAV",
    CITYNAME: "CAVALESE",
    CITYNAME_HEB: "\u05e7\u05d0\u05d1\u05dc\u05e1\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CAW",
    AIRPORTNAME: null,
    CITYCODE: "CAW",
    CITYNAME: "CAMPOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CAX",
    AIRPORTNAME: "Carlisle",
    CITYCODE: "CAX",
    CITYNAME: "CARLISLE",
    CITYNAME_HEB: "CARLISLE",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CAY",
    AIRPORTNAME: "Rochambeau",
    CITYCODE: "CAY",
    CITYNAME: "CAYENNE",
    CITYNAME_HEB: "CAYENNE",
    COUNTRYCODE: "GF",
    COUNTRYNAME: "FRENCH GUIANA",
    COUNTRYNAME_HEB:
      "\u05d2\u05d9\u05e0\u05d0\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "CAZ",
    AIRPORTNAME: " ",
    CITYCODE: "CAZ",
    CITYNAME: "CANAZEI DI FASSA",
    CITYNAME_HEB:
      "\u05e7\u05d0\u05e0\u05d6\u05d9 \u05d3\u05d9 \u05e4\u05d0\u05e1\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CBA",
    AIRPORTNAME: "Corner Bay",
    CITYCODE: "CBA",
    CITYNAME: "CORNER BAY",
    CITYNAME_HEB: "Corner Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CBB",
    AIRPORTNAME: "J Wilsterman",
    CITYCODE: "CBB",
    CITYNAME: "COCHABAMBA",
    CITYNAME_HEB: "COCHABAMBA",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CBC",
    AIRPORTNAME: "Cherrabun",
    CITYCODE: "CBC",
    CITYNAME: "CHERRABUN",
    CITYNAME_HEB: "CHERRABUN",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CBD",
    AIRPORTNAME: "Car Nicobar",
    CITYCODE: "CBD",
    CITYNAME: "CAR NICOBAR",
    CITYNAME_HEB: "CAR NICOBAR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "CBE",
    AIRPORTNAME: "Wiley Ford",
    CITYCODE: "CBE",
    CITYNAME: "CUMBERLAND",
    CITYNAME_HEB: "CUMBERLAND",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JHE",
    AIRPORTNAME: "Heliport",
    CITYCODE: "AGH",
    CITYNAME: "ANGELHOLM/HELSINGBORG",
    CITYNAME_HEB: "ANGELHOLM/HELSINGBORG",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CBF",
    AIRPORTNAME: "Municipal",
    CITYCODE: "CBF",
    CITYNAME: "COUNCIL BLUFFS",
    CITYNAME_HEB: "COUNCIL BLUFFS",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CBG",
    AIRPORTNAME: "Cambridge",
    CITYCODE: "CBG",
    CITYNAME: "CAMBRIDGE",
    CITYNAME_HEB: "\u05e7\u05de\u05d1\u05e8\u05d9\u05d3\u05d2'",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CBH",
    AIRPORTNAME: "Leger",
    CITYCODE: "CBH",
    CITYNAME: "BECHAR",
    CITYNAME_HEB: "BECHAR",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CBI",
    AIRPORTNAME: null,
    CITYCODE: "CBI",
    CITYNAME: "CAPE BARREN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUTS",
    COUNTRYNAME: "TASMANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CBJ",
    AIRPORTNAME: "Cabo Rojo",
    CITYCODE: "CBJ",
    CITYNAME: "CABO ROJO",
    CITYNAME_HEB: "CABO ROJO",
    COUNTRYCODE: "DO",
    COUNTRYNAME: "DOMINICAN REPUBLIC",
    COUNTRYNAME_HEB:
      "\u05d4\u05e8\u05e4\u05d5\u05d1\u05dc\u05d9\u05e7\u05d4 \u05d4\u05d3\u05d5\u05de\u05d9\u05e0\u05d9\u05e7\u05e0\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "CBK",
    AIRPORTNAME: "Municipal",
    CITYCODE: "CBK",
    CITYNAME: "COLBY",
    CITYNAME_HEB: "COLBY",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CBL",
    AIRPORTNAME: "Ciudad Bolivar",
    CITYCODE: "CBL",
    CITYNAME: "CIUDAD BOLIVAR",
    CITYNAME_HEB: "CIUDAD BOLIVAR",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CBN",
    AIRPORTNAME: "Penggung",
    CITYCODE: "CBN",
    CITYNAME: "CIREBON",
    CITYNAME_HEB: "CIREBON",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CBO",
    AIRPORTNAME: "Awang",
    CITYCODE: "CBO",
    CITYNAME: "COTABATO",
    CITYNAME_HEB: "COTABATO",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "CBP",
    AIRPORTNAME: "Coimbra",
    CITYCODE: "CBP",
    CITYNAME: "COIMBRA",
    CITYNAME_HEB: "\u05e7\u05d5\u05d0\u05d9\u05de\u05d1\u05e8\u05d4",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "AGI",
    AIRPORTNAME: "Wageningen",
    CITYCODE: "AGI",
    CITYNAME: "WAGENINGEN",
    CITYNAME_HEB: "WAGENINGEN",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "CBQ",
    AIRPORTNAME: "Calabar",
    CITYCODE: "CBQ",
    CITYNAME: "CALABAR",
    CITYNAME_HEB: "CALABAR",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CBR",
    AIRPORTNAME: "Canberra",
    CITYCODE: "CBR",
    CITYNAME: "CANBERRA",
    CITYNAME_HEB: "\u05e7\u05e0\u05d1\u05e8\u05d4",
    COUNTRYCODE: "AUAC",
    COUNTRYNAME: "AUSTRALIAN CAPITAL TER",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CBS",
    AIRPORTNAME: "Oro Negro",
    CITYCODE: "CBS",
    CITYNAME: "CABIMAS",
    CITYNAME_HEB: "CABIMAS",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CBT",
    AIRPORTNAME: "Catumbela",
    CITYCODE: "CBT",
    CITYNAME: "CATUMBELA",
    CITYNAME_HEB: "CATUMBELA",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CBU",
    AIRPORTNAME: "Cottbus",
    CITYCODE: "CBU",
    CITYNAME: "COTTBUS",
    CITYNAME_HEB: "COTTBUS",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CBV",
    AIRPORTNAME: "Coban",
    CITYCODE: "CBV",
    CITYNAME: "COBAN",
    CITYNAME_HEB: "COBAN",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CBW",
    AIRPORTNAME: null,
    CITYCODE: "CBW",
    CITYNAME: "CAMPO MOURAO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CBX",
    AIRPORTNAME: "Condobolin",
    CITYCODE: "CBX",
    CITYNAME: "CONDOBOLIN",
    CITYNAME_HEB: "CONDOBOLIN",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CBY",
    AIRPORTNAME: "Canobie",
    CITYCODE: "CBY",
    CITYNAME: "CANOBIE",
    CITYNAME_HEB: "CANOBIE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CBZ",
    AIRPORTNAME: "Cabin Creek",
    CITYCODE: "CBZ",
    CITYNAME: "CABIN CREEK",
    CITYNAME_HEB: "Cabin Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AGJ",
    AIRPORTNAME: "Aguni",
    CITYCODE: "AGJ",
    CITYNAME: "AGUNI",
    CITYNAME_HEB: "AGUNI",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "CCA",
    AIRPORTNAME: "Chaffee AFB",
    CITYCODE: "CCA",
    CITYNAME: "FORT CHAFFEE",
    CITYNAME_HEB: "FORT CHAFFEE",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CCB",
    AIRPORTNAME: null,
    CITYCODE: "CCB",
    CITYNAME: "UPLAND CABLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JUP",
    AIRPORTNAME: null,
    CITYCODE: "CCB",
    CITYNAME: "UPLAND CABLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CCC",
    AIRPORTNAME: "Cayo Coco",
    CITYCODE: "CCC",
    CITYNAME: "CAYO COCO",
    CITYNAME_HEB: "CAYO COCO",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "CCF",
    AIRPORTNAME: "Salvaza",
    CITYCODE: "CCF",
    CITYNAME: "CARCASSONNE",
    CITYNAME_HEB: "\u05e7\u05e8\u05e7\u05e1\u05d5\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "CCG",
    AIRPORTNAME: "Crane County Airport",
    CITYCODE: "CCG",
    CITYNAME: "CRANE",
    CITYNAME_HEB: "CRANE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CCH",
    AIRPORTNAME: "Chile Chico",
    CITYCODE: "CCH",
    CITYNAME: "CHILE CHICO",
    CITYNAME_HEB: "CHILE CHICO",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CCI",
    AIRPORTNAME: null,
    CITYCODE: "CCI",
    CITYNAME: "CONCORDIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CCJ",
    AIRPORTNAME: "Kozhikode",
    CITYCODE: "CCJ",
    CITYNAME: "KOZHIKODE",
    CITYNAME_HEB: "KOZHIKODE",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "CCK",
    AIRPORTNAME: null,
    CITYCODE: "CCK",
    CITYNAME: "COCOS ISLANDS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CC",
    COUNTRYNAME: "COCOS ISLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AAR",
    AIRPORTNAME: "Tirstrup",
    CITYCODE: "AAR",
    CITYNAME: "AARHUS",
    CITYNAME_HEB: "\u05d0\u05d5\u05e8\u05d4\u05d5\u05e1",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "AGK",
    AIRPORTNAME: "Kagua",
    CITYCODE: "AGK",
    CITYNAME: "KAGUA",
    CITYNAME_HEB: "KAGUA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "CCL",
    AIRPORTNAME: "Chinchilla",
    CITYCODE: "CCL",
    CITYNAME: "CHINCHILLA",
    CITYNAME_HEB: "CHINCHILLA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CCM",
    AIRPORTNAME: null,
    CITYCODE: "CCM",
    CITYNAME: "CRICIUMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CCN",
    AIRPORTNAME: null,
    CITYCODE: "CCN",
    CITYNAME: "CHAKCHARAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CCO",
    AIRPORTNAME: "Carimagua",
    CITYCODE: "CCO",
    CITYNAME: "CARIMAGUA",
    CITYNAME_HEB: "CARIMAGUA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CCP",
    AIRPORTNAME: "Carriel Sur",
    CITYCODE: "CCP",
    CITYNAME: "CONCEPCION",
    CITYNAME_HEB: "CONCEPCION",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CCQ",
    AIRPORTNAME: null,
    CITYCODE: "CCQ",
    CITYNAME: "CACHOEIRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CCR",
    AIRPORTNAME: "Buchanan Fld",
    CITYCODE: "CCR",
    CITYNAME: "CONCORD",
    CITYNAME_HEB: "CONCORD",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CCS",
    AIRPORTNAME: "Simon Bolivar",
    CITYCODE: "CCS",
    CITYNAME: "CARACAS",
    CITYNAME_HEB: "\u05e7\u05e8\u05d0\u05e7\u05e1",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CCT",
    AIRPORTNAME: null,
    CITYCODE: "CCT",
    CITYNAME: "COLONIA CATRIEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CCU",
    AIRPORTNAME: "Netaji Subhas Chandra",
    CITYCODE: "CCU",
    CITYNAME: "KOLKATA",
    CITYNAME_HEB: "KOLKATA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "AGL",
    AIRPORTNAME: "Wanigela",
    CITYCODE: "AGL",
    CITYNAME: "WANIGELA",
    CITYNAME_HEB: "WANIGELA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "CCV",
    AIRPORTNAME: "Craig Cove",
    CITYCODE: "CCV",
    CITYNAME: "CRAIG COVE",
    CITYNAME_HEB: "CRAIG COVE",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "CCW",
    AIRPORTNAME: "Cowell",
    CITYCODE: "CCW",
    CITYNAME: "COWELL",
    CITYNAME_HEB: "COWELL",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CCX",
    AIRPORTNAME: null,
    CITYCODE: "CCX",
    CITYNAME: "CACERES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CCY",
    AIRPORTNAME: "Municipal",
    CITYCODE: "CCY",
    CITYNAME: "CHARLES CITY",
    CITYNAME_HEB: "CHARLES CITY",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CCZ",
    AIRPORTNAME: "Chub Cay",
    CITYCODE: "CCZ",
    CITYNAME: "CHUB CAY",
    CITYNAME_HEB: "CHUB CAY",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "CDA",
    AIRPORTNAME: "Cooinda",
    CITYCODE: "CDA",
    CITYNAME: "COOINDA",
    CITYNAME_HEB: "COOINDA",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CDB",
    AIRPORTNAME: "Cold Bay",
    CITYCODE: "CDB",
    CITYNAME: "COLD BAY",
    CITYNAME_HEB: "Cold Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HLK",
    AIRPORTNAME: " ",
    CITYCODE: "HLK",
    CITYNAME: "CHALKIDIKI",
    CITYNAME_HEB: "\u05d7\u05dc\u05e7\u05d9\u05d3\u05d9\u05e7\u05d9",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "CDC",
    AIRPORTNAME: "Cedar City",
    CITYCODE: "CDC",
    CITYNAME: "CEDAR CITY",
    CITYNAME_HEB: "CEDAR CITY",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CDD",
    AIRPORTNAME: "Cauquira",
    CITYCODE: "CDD",
    CITYNAME: "CAUQUIRA",
    CITYNAME_HEB: "CAUQUIRA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "CDE",
    AIRPORTNAME: "Caledonia",
    CITYCODE: "CDE",
    CITYNAME: "CALEDONIA",
    CITYNAME_HEB: "CALEDONIA",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "AGM",
    AIRPORTNAME: "Tasiilaq",
    CITYCODE: "AGM",
    CITYNAME: "TASIILAQ",
    CITYNAME_HEB: "TASIILAQ",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "CDF",
    CITYNAME: "VAL DI FIEMME",
    CITYNAME_HEB:
      "\u05d5\u05d0\u05dc \u05d3\u05d9 \u05e4\u05d9\u05d0\u05de\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CDH",
    AIRPORTNAME: "Harrell Fld",
    CITYCODE: "CDH",
    CITYNAME: "CAMDEN",
    CITYNAME_HEB: "CAMDEN",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CDI",
    AIRPORTNAME: null,
    CITYCODE: "CDI",
    CITYNAME: "CACHOEIRO DE ITAPEMIRIM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CDJ",
    AIRPORTNAME: null,
    CITYCODE: "CDJ",
    CITYNAME: "CONCEICAO DO ARAG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CDK",
    AIRPORTNAME: "Lewis",
    CITYCODE: "CDK",
    CITYNAME: "CEDAR KEY",
    CITYNAME_HEB: "CEDAR KEY",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CDL",
    AIRPORTNAME: "Candle",
    CITYCODE: "CDL",
    CITYNAME: "CANDLE",
    CITYNAME_HEB: "Candle",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CDN",
    AIRPORTNAME: "Woodward Fld",
    CITYCODE: "CDN",
    CITYNAME: "CAMDEN",
    CITYNAME_HEB: "CAMDEN",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CDO",
    AIRPORTNAME: "Cradock",
    CITYCODE: "CDO",
    CITYNAME: "CRADOCK",
    CITYNAME_HEB: "CRADOCK",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "CDP",
    AIRPORTNAME: "Cuddapah",
    CITYCODE: "CDP",
    CITYNAME: "CUDDAPAH",
    CITYNAME_HEB: "CUDDAPAH",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "CDQ",
    AIRPORTNAME: "Croydon",
    CITYCODE: "CDQ",
    CITYNAME: "CROYDON",
    CITYNAME_HEB: "CROYDON",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AGN",
    AIRPORTNAME: "Angoon",
    CITYCODE: "AGN",
    CITYNAME: "ANGOON",
    CITYNAME_HEB: "Angoon",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CDR",
    AIRPORTNAME: "Chadron",
    CITYCODE: "CDR",
    CITYNAME: "CHADRON",
    CITYNAME_HEB: "CHADRON",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CDS",
    AIRPORTNAME: "Childress",
    CITYCODE: "CDS",
    CITYNAME: "CHILDRESS",
    CITYNAME_HEB: "CHILDRESS",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CDU",
    AIRPORTNAME: "Camden",
    CITYCODE: "CDU",
    CITYNAME: "CAMDEN",
    CITYNAME_HEB: "CAMDEN",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CDV",
    AIRPORTNAME: "Cordova-Mudhole Smith",
    CITYCODE: "CDV",
    CITYNAME: "CORDOVA",
    CITYNAME_HEB: "Cordova-Mudhole Smith",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CKU",
    AIRPORTNAME: "Cordova City",
    CITYCODE: "CDV",
    CITYNAME: "CORDOVA",
    CITYNAME_HEB: "Cordova City",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CDW",
    AIRPORTNAME: "Caldwell Wright",
    CITYCODE: "CDW",
    CITYNAME: "CALDWELL",
    CITYNAME_HEB: "CALDWELL",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "CDY",
    AIRPORTNAME: "Cagayan De Sulu",
    CITYCODE: "CDY",
    CITYNAME: "CAGAYAN DE SULU",
    CITYNAME_HEB: "CAGAYAN DE SULU",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "CEB",
    AIRPORTNAME: "Cebu",
    CITYCODE: "CEB",
    CITYNAME: "CEBU",
    CITYNAME_HEB: "CEBU",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "CEC",
    AIRPORTNAME: "Mc Namara Fld",
    CITYCODE: "CEC",
    CITYNAME: "CRESCENT CITY",
    CITYNAME_HEB: "CRESCENT CITY",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CED",
    AIRPORTNAME: "Ceduna",
    CITYCODE: "CED",
    CITYNAME: "CEDUNA",
    CITYNAME_HEB: "CEDUNA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AGO",
    AIRPORTNAME: null,
    CITYCODE: "AGO",
    CITYNAME: "MAGNOLIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CEE",
    AIRPORTNAME: "Cherepovets",
    CITYCODE: "CEE",
    CITYNAME: "CHEREPOVETS",
    CITYNAME_HEB: "CHEREPOVETS",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CEG",
    AIRPORTNAME: "Chester",
    CITYCODE: "CEG",
    CITYNAME: "CHESTER",
    CITYNAME_HEB: "\u05e6\u05e1\u05d8\u05e8",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CEH",
    AIRPORTNAME: "Chelinda",
    CITYCODE: "CEH",
    CITYNAME: "CHELINDA",
    CITYNAME_HEB: "CHELINDA",
    COUNTRYCODE: "MW",
    COUNTRYNAME: "MALAWI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "CEI",
    AIRPORTNAME: "Chiang Rai",
    CITYCODE: "CEI",
    CITYNAME: "CHIANG RAI",
    CITYNAME_HEB: "\u05e6\u05d0\u05e0\u05d2 \u05e8\u05d0\u05d9",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "CEJ",
    AIRPORTNAME: "Chernigov",
    CITYCODE: "CEJ",
    CITYNAME: "CHERNIGOV",
    CITYNAME_HEB: "CHERNIGOV",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "LSF",
    AIRPORTNAME: "Lawson AAF",
    CITYCODE: "CSG",
    CITYNAME: "COLUMBUS",
    CITYNAME_HEB: "COLUMBUS",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MKF",
    AIRPORTNAME: "Mckenna AAF",
    CITYCODE: "CSG",
    CITYNAME: "COLUMBUS",
    CITYNAME_HEB: "COLUMBUS",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "CSI",
    AIRPORTNAME: "Casino",
    CITYCODE: "CSI",
    CITYNAME: "CASINO",
    CITYNAME_HEB: "CASINO",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VLB",
    AIRPORTNAME: " ",
    CITYCODE: "VLB",
    CITYNAME: "VELINGRAD",
    CITYNAME_HEB: "\u05d5\u05dc\u05d9\u05e0\u05d2\u05e8\u05d0\u05d3",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CSJ",
    AIRPORTNAME: null,
    CITYCODE: "CSJ",
    CITYNAME: "CANARIES ISLAND",
    CITYNAME_HEB:
      "\u05d4\u05d0\u05d9\u05d9\u05dd \u05d4\u05e7\u05e0\u05e8\u05d9\u05d9\u05dd",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CSK",
    AIRPORTNAME: "Cap Skirring",
    CITYCODE: "CSK",
    CITYNAME: "CAP SKIRRING",
    CITYNAME_HEB: "CAP SKIRRING",
    COUNTRYCODE: "SN",
    COUNTRYNAME: "SENEGAL",
    COUNTRYNAME_HEB: "\u05e1\u05e0\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "CSL",
    AIRPORTNAME: null,
    CITYCODE: "CSL",
    CITYNAME: "SAN LUIS OBISPO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SBP",
    AIRPORTNAME: "County Airport",
    CITYCODE: "CSL",
    CITYNAME: "SAN LUIS OBISPO",
    CITYNAME_HEB: "SAN LUIS OBISPO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AIG",
    AIRPORTNAME: null,
    CITYCODE: "AIG",
    CITYNAME: "YALINGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CSM",
    AIRPORTNAME: " ",
    CITYCODE: "CSM",
    CITYNAME: "COSTA SMERALDA",
    CITYNAME_HEB:
      "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e1\u05de\u05e8\u05d0\u05dc\u05d3\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NEB",
    AIRPORTNAME: " ",
    CITYCODE: "NEB",
    CITYNAME: "NESSEBAR",
    CITYNAME_HEB: "\u05e0\u05e1\u05d1\u05d0\u05e8",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CSN",
    AIRPORTNAME: "Carson City",
    CITYCODE: "CSN",
    CITYNAME: "CARSON CITY",
    CITYNAME_HEB: "CARSON CITY",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CSO",
    AIRPORTNAME: "Cochstedt",
    CITYCODE: "CSO",
    CITYNAME: "COCHSTEDT",
    CITYNAME_HEB: "COCHSTEDT",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CSP",
    AIRPORTNAME: "Coast Guard Heliport",
    CITYCODE: "CSP",
    CITYNAME: "CAPE SPENCER",
    CITYNAME_HEB: "Coast Guard Heliport",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CSQ",
    AIRPORTNAME: "Municipal",
    CITYCODE: "CSQ",
    CITYNAME: "CRESTON",
    CITYNAME_HEB: "CRESTON",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CSR",
    AIRPORTNAME: "Casuarito",
    CITYCODE: "CSR",
    CITYNAME: "CASUARITO",
    CITYNAME_HEB: "CASUARITO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CSS",
    AIRPORTNAME: null,
    CITYCODE: "CSS",
    CITYNAME: "CASSILANDIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CST",
    AIRPORTNAME: "Castaway",
    CITYCODE: "CST",
    CITYNAME: "CASTAWAY",
    CITYNAME_HEB: "CASTAWAY",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "CSU",
    AIRPORTNAME: null,
    CITYCODE: "CSU",
    CITYNAME: "SANTA CRUZ DO SUL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CSV",
    AIRPORTNAME: "Memorial",
    CITYCODE: "CSV",
    CITYNAME: "CROSSVILLE",
    CITYNAME_HEB: "CROSSVILLE",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AIH",
    AIRPORTNAME: "Aiambak",
    CITYCODE: "AIH",
    CITYNAME: "AIAMBAK",
    CITYNAME_HEB: "AIAMBAK",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "CSW",
    AIRPORTNAME: null,
    CITYCODE: "CSW",
    CITYNAME: "COLORADO DO OESTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CSX",
    AIRPORTNAME: "Changsha",
    CITYCODE: "CSX",
    CITYNAME: "CHANGSHA",
    CITYNAME_HEB: "CHANGSHA",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "HHA",
    AIRPORTNAME: "Changsha Huanghua",
    CITYCODE: "CSX",
    CITYNAME: "CHANGSHA",
    CITYNAME_HEB: "CHANGSHA",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CSY",
    AIRPORTNAME: "Cheboksary",
    CITYCODE: "CSY",
    CITYNAME: "CHEBOKSARY",
    CITYNAME_HEB: "CHEBOKSARY",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CSZ",
    AIRPORTNAME: null,
    CITYCODE: "CSZ",
    CITYNAME: "CORONEL SUAREZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "GDF",
    CITYNAME: "SIEGSDORF",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "LBG",
    CITYNAME: "LINDENBERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CTA",
    AIRPORTNAME: "Fontanarossa",
    CITYCODE: "CTA",
    CITYNAME: "CATANIA",
    CITYNAME_HEB:
      "\u05e7\u05d8\u05e0\u05d9\u05d4-\u05e1\u05d9\u05e6\u05d9\u05dc\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CTB",
    AIRPORTNAME: "Cut Bank",
    CITYCODE: "CTB",
    CITYNAME: "CUT BANK",
    CITYNAME_HEB: "CUT BANK",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CTC",
    AIRPORTNAME: null,
    CITYCODE: "CTC",
    CITYNAME: "CATAMARCA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CTD",
    AIRPORTNAME: "Chitre",
    CITYCODE: "CTD",
    CITYNAME: "CHITRE",
    CITYNAME_HEB: "CHITRE",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "CTE",
    AIRPORTNAME: "Carti",
    CITYCODE: "CTE",
    CITYNAME: "CARTI",
    CITYNAME_HEB: "CARTI",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "AII",
    AIRPORTNAME: null,
    CITYCODE: "AII",
    CITYNAME: "ALISABIEH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DJ",
    COUNTRYNAME: "DJIBOUTI",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CTF",
    AIRPORTNAME: "Coatepeque Airport",
    CITYCODE: "CTF",
    CITYNAME: "COATEPEQUE",
    CITYNAME_HEB: "COATEPEQUE",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CTG",
    AIRPORTNAME: "Rafael Nunez",
    CITYCODE: "CTG",
    CITYNAME: "CARTAGENA",
    CITYNAME_HEB: "CARTAGENA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CTH",
    AIRPORTNAME: "Chestercounty Carlson",
    CITYCODE: "CTH",
    CITYNAME: "COATESVILLE",
    CITYNAME_HEB: "COATESVILLE",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CTI",
    AIRPORTNAME: " ",
    CITYCODE: "CTI",
    CITYNAME: "CASTIGLIONE DI SICILA",
    CITYNAME_HEB:
      "\u05e7\u05d0\u05e1\u05d8\u05dc\u05d9\u05d5\u05df \u05d3\u05d9 \u05e1\u05e6\u05d9\u05dc\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "CTJ",
    CITYNAME: "MOKRICE GOLF",
    CITYNAME_HEB:
      "\u05de\u05d5\u05e7\u05e8\u05d9\u05e6\u05d4 \u05d2\u05dc\u05e3",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CTK",
    AIRPORTNAME: "Canton",
    CITYCODE: "CTK",
    CITYNAME: "CANTON",
    CITYNAME_HEB: "CANTON",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CTL",
    AIRPORTNAME: "Charleville",
    CITYCODE: "CTL",
    CITYNAME: "CHARLEVILLE",
    CITYNAME_HEB: "CHARLEVILLE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CTM",
    AIRPORTNAME: "Chetumal",
    CITYCODE: "CTM",
    CITYNAME: "CHETUMAL",
    CITYNAME_HEB: "CHETUMAL",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CTN",
    AIRPORTNAME: "Cooktown",
    CITYCODE: "CTN",
    CITYNAME: "COOKTOWN",
    CITYNAME_HEB: "COOKTOWN",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CTO",
    AIRPORTNAME: "Peconic River",
    CITYCODE: "CTO",
    CITYNAME: "CALVERTON",
    CITYNAME_HEB: "CALVERTON",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AAT",
    AIRPORTNAME: "Altay",
    CITYCODE: "AAT",
    CITYNAME: "ALTAY",
    CITYNAME_HEB: "ALTAY",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "AIK",
    AIRPORTNAME: "Municipal",
    CITYCODE: "AIK",
    CITYNAME: "AIKEN",
    CITYNAME_HEB: "AIKEN",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CTP",
    AIRPORTNAME: null,
    CITYCODE: "CTP",
    CITYNAME: "CARUTAPERA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CTQ",
    AIRPORTNAME: null,
    CITYCODE: "CTQ",
    CITYNAME: "SANTA VITORIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CTR",
    AIRPORTNAME: "Cattle Creek",
    CITYCODE: "CTR",
    CITYNAME: "CATTLE CREEK",
    CITYNAME_HEB: "CATTLE CREEK",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CTT",
    AIRPORTNAME: "Le Castellet",
    CITYCODE: "CTT",
    CITYNAME: "LE CASTELLET",
    CITYNAME_HEB: "LE CASTELLET",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "CTU",
    AIRPORTNAME: "Chengdu",
    CITYCODE: "CTU",
    CITYNAME: "CHENGDU",
    CITYNAME_HEB: "\u05e6\u05e0\u05d2\u05d3\u05d5",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CTW",
    AIRPORTNAME: "Cottonwood",
    CITYCODE: "CTW",
    CITYNAME: "COTTONWOOD",
    CITYNAME_HEB: "COTTONWOOD",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CTX",
    AIRPORTNAME: "Cortland",
    CITYCODE: "CTX",
    CITYNAME: "CORTLAND",
    CITYNAME_HEB: "CORTLAND",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CTY",
    AIRPORTNAME: "Cross City",
    CITYCODE: "CTY",
    CITYNAME: "CROSS CITY",
    CITYNAME_HEB: "CROSS CITY",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CTZ",
    AIRPORTNAME: " ",
    CITYCODE: "CTZ",
    CITYNAME: "CATEZ",
    CITYNAME_HEB: "\u05e7\u05d0\u05d8\u05d6",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CUA",
    AIRPORTNAME: "Ciudad Constitucion",
    CITYCODE: "CUA",
    CITYNAME: "CIUDAD CONSTITUCION",
    CITYNAME_HEB: "CIUDAD CONSTITUCION",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "AIL",
    AIRPORTNAME: "Ailigandi",
    CITYCODE: "AIL",
    CITYNAME: "AILIGANDI",
    CITYNAME_HEB: "AILIGANDI",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "CUC",
    AIRPORTNAME: "Camilo Dazo",
    CITYCODE: "CUC",
    CITYNAME: "CUCUTA",
    CITYNAME_HEB: "CUCUTA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CUD",
    AIRPORTNAME: "Caloundra",
    CITYCODE: "CUD",
    CITYNAME: "CALOUNDRA",
    CITYNAME_HEB: "CALOUNDRA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CUE",
    AIRPORTNAME: "Cuenca",
    CITYCODE: "CUE",
    CITYNAME: "CUENCA",
    CITYNAME_HEB: "CUENCA",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "CUF",
    AIRPORTNAME: "Levaldigi",
    CITYCODE: "CUF",
    CITYNAME: "CUNEO",
    CITYNAME_HEB: "CUNEO",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CUG",
    AIRPORTNAME: "Cudal",
    CITYCODE: "CUG",
    CITYNAME: "CUDAL",
    CITYNAME_HEB: "CUDAL",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CUI",
    AIRPORTNAME: "Currillo",
    CITYCODE: "CUI",
    CITYNAME: "CURRILLO",
    CITYNAME_HEB: "CURRILLO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CUJ",
    AIRPORTNAME: "Culion",
    CITYCODE: "CUJ",
    CITYNAME: "CULION",
    CITYNAME_HEB: "CULION",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "CUK",
    AIRPORTNAME: "Caye Caulker",
    CITYCODE: "CUK",
    CITYNAME: "CAYE CAULKER",
    CITYNAME_HEB: "CAYE CAULKER",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "CUL",
    AIRPORTNAME: "Fedl De Bachigualato",
    CITYCODE: "CUL",
    CITYNAME: "CULIACAN",
    CITYNAME_HEB: "CULIACAN",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "AIM",
    AIRPORTNAME: "Ailuk Island",
    CITYCODE: "AIM",
    CITYNAME: "AILUK ISLAND",
    CITYNAME_HEB: "AILUK ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "CUM",
    AIRPORTNAME: "Cumana",
    CITYCODE: "CUM",
    CITYNAME: "CUMANA",
    CITYNAME_HEB: "CUMANA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CUN",
    AIRPORTNAME: "Cancun",
    CITYCODE: "CUN",
    CITYNAME: "CANCUN",
    CITYNAME_HEB: "\u05e7\u05e0\u05e7\u05d5\u05df",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CUO",
    AIRPORTNAME: "Caruru",
    CITYCODE: "CUO",
    CITYNAME: "CARURU",
    CITYNAME_HEB: "CARURU",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CUP",
    AIRPORTNAME: "Carupano",
    CITYCODE: "CUP",
    CITYNAME: "CARUPANO",
    CITYNAME_HEB: "CARUPANO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CUQ",
    AIRPORTNAME: "Coen",
    CITYCODE: "CUQ",
    CITYNAME: "COEN",
    CITYNAME_HEB: "COEN",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CUR",
    AIRPORTNAME: null,
    CITYCODE: "CUR",
    CITYNAME: "CURACAO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CW",
    COUNTRYNAME: "CURACAO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CUS",
    AIRPORTNAME: "Municipal",
    CITYCODE: "CUS",
    CITYNAME: "COLUMBUS",
    CITYNAME_HEB: "COLUMBUS",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CUT",
    AIRPORTNAME: null,
    CITYCODE: "CUT",
    CITYNAME: "CUTRAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CUU",
    AIRPORTNAME: "Gen Fierro Villalobos",
    CITYCODE: "CUU",
    CITYNAME: "CHIHUAHUA",
    CITYNAME_HEB: "CHIHUAHUA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CUV",
    AIRPORTNAME: "Casigua",
    CITYCODE: "CUV",
    CITYNAME: "CASIGUA",
    CITYNAME_HEB: "CASIGUA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "AIN",
    AIRPORTNAME: "Wainwright",
    CITYCODE: "AIN",
    CITYNAME: "WAINWRIGHT",
    CITYNAME_HEB: "Wainwright",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CUW",
    AIRPORTNAME: "Cube Cove",
    CITYCODE: "CUW",
    CITYNAME: "CUBE COVE",
    CITYNAME_HEB: "Cube Cove",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CUY",
    AIRPORTNAME: "Cue",
    CITYCODE: "CUY",
    CITYNAME: "CUE",
    CITYNAME_HEB: "CUE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CUZ",
    AIRPORTNAME: "Velazco Astete",
    CITYCODE: "CUZ",
    CITYNAME: "CUZCO",
    CITYNAME_HEB: "CUZCO",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "CVB",
    AIRPORTNAME: "Chungribu",
    CITYCODE: "CVB",
    CITYNAME: "CHUNGRIBU",
    CITYNAME_HEB: "CHUNGRIBU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "CVC",
    AIRPORTNAME: "Cleve",
    CITYCODE: "CVC",
    CITYNAME: "CLEVE",
    CITYNAME_HEB: "CLEVE",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CVE",
    AIRPORTNAME: "Covenas",
    CITYCODE: "CVE",
    CITYNAME: "COVENAS",
    CITYNAME_HEB: "COVENAS",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CVF",
    AIRPORTNAME: "Courchevel",
    CITYCODE: "CVF",
    CITYNAME: "COURCHEVEL",
    CITYNAME_HEB: "\u05e7\u05d5\u05e8\u05e9\u05d1\u05dc",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "CVG",
    AIRPORTNAME: "Cincinnati International",
    CITYCODE: "CVG",
    CITYNAME: "CINCINNATI",
    CITYNAME_HEB: "\u05e1\u05d9\u05e0\u05e1\u05e0\u05d8\u05d9",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CVH",
    AIRPORTNAME: null,
    CITYCODE: "CVH",
    CITYNAME: "CAVIAHUE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AIO",
    AIRPORTNAME: "Municipal",
    CITYCODE: "AIO",
    CITYNAME: "ATLANTIC",
    CITYNAME_HEB: "ATLANTIC",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CVI",
    AIRPORTNAME: null,
    CITYCODE: "CVI",
    CITYNAME: "CALETA OLIVIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CVJ",
    AIRPORTNAME: "Cuernavaca",
    CITYCODE: "CVJ",
    CITYNAME: "CUERNAVACA",
    CITYNAME_HEB: "CUERNAVACA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CVL",
    AIRPORTNAME: "Cape Vogel",
    CITYCODE: "CVL",
    CITYNAME: "CAPE VOGEL",
    CITYNAME_HEB: "CAPE VOGEL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "CVM",
    AIRPORTNAME: "Ciudad Victoria",
    CITYCODE: "CVM",
    CITYNAME: "CIUDAD VICTORIA",
    CITYNAME_HEB: "CIUDAD VICTORIA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CVN",
    AIRPORTNAME: "Municipal",
    CITYCODE: "CVN",
    CITYNAME: "CLOVIS",
    CITYNAME_HEB: "CLOVIS",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CVS",
    AIRPORTNAME: null,
    CITYCODE: "CVN",
    CITYNAME: "CLOVIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CVO",
    AIRPORTNAME: "Albany/Corvallis Airport",
    CITYCODE: "CVO",
    CITYNAME: "CORVALLIS",
    CITYNAME_HEB: "CORVALLIS",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QWY",
    AIRPORTNAME: null,
    CITYCODE: "CVO",
    CITYNAME: "CORVALLIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CVQ",
    AIRPORTNAME: "Carnarvon",
    CITYCODE: "CVQ",
    CITYNAME: "CARNARVON",
    CITYNAME_HEB: "CARNARVON",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CVR",
    AIRPORTNAME: "Hughes",
    CITYCODE: "CVR",
    CITYNAME: "CULVER CITY",
    CITYNAME_HEB: "CULVER CITY",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AIP",
    AIRPORTNAME: "Ailinglapalap Island",
    CITYCODE: "AIP",
    CITYNAME: "AILINGLAPALAP ISL",
    CITYNAME_HEB: "AILINGLAPALAP ISL",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "CVT",
    AIRPORTNAME: "Baginton",
    CITYCODE: "CVT",
    CITYNAME: "COVENTRY",
    CITYNAME_HEB: "COVENTRY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CVU",
    AIRPORTNAME: "Corvo Island",
    CITYCODE: "CVU",
    CITYNAME: "CORVO ISLAND",
    CITYNAME_HEB: "CORVO ISLAND",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "BFH",
    AIRPORTNAME: null,
    CITYCODE: "CWB",
    CITYNAME: "CURITIBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CWB",
    AIRPORTNAME: null,
    CITYCODE: "CWB",
    CITYNAME: "CURITIBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CWC",
    AIRPORTNAME: "Chernovtsy",
    CITYCODE: "CWC",
    CITYNAME: "CHERNOVTSY",
    CITYNAME_HEB: "\u05e6\u05e8\u05e0\u05d5\u05d1\u05d9\u05e5",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "CWG",
    AIRPORTNAME: "Callaway Gardens",
    CITYCODE: "CWG",
    CITYNAME: "CALLAWAY GARDENS",
    CITYNAME_HEB: "CALLAWAY GARDENS",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "CWI",
    AIRPORTNAME: "Clinton",
    CITYCODE: "CWI",
    CITYNAME: "CLINTON",
    CITYNAME_HEB: "CLINTON",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CWL",
    AIRPORTNAME: "Cardiff-Wales Airport",
    CITYCODE: "CWL",
    CITYNAME: "CARDIFF",
    CITYNAME_HEB: "\u05e7\u05e8\u05d3\u05d9\u05e3",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CWR",
    AIRPORTNAME: "Cowarie",
    CITYCODE: "CWR",
    CITYNAME: "COWARIE",
    CITYNAME_HEB: "COWARIE",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CWS",
    AIRPORTNAME: "Center Island",
    CITYCODE: "CWS",
    CITYNAME: "CENTER ISLAND",
    CITYNAME_HEB: "CENTER ISLAND",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AIR",
    AIRPORTNAME: null,
    CITYCODE: "AIR",
    CITYNAME: "ARIPUANA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CWT",
    AIRPORTNAME: "Cowra",
    CITYCODE: "CWT",
    CITYNAME: "COWRA",
    CITYNAME_HEB: "COWRA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CWW",
    AIRPORTNAME: "Corowa",
    CITYCODE: "CWW",
    CITYNAME: "COROWA",
    CITYNAME_HEB: "COROWA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CXA",
    AIRPORTNAME: "Caicara De Oro",
    CITYCODE: "CXA",
    CITYNAME: "CAICARA DE ORO",
    CITYNAME_HEB: "CAICARA DE ORO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CXB",
    AIRPORTNAME: "Cox's Bazar",
    CITYCODE: "CXB",
    CITYNAME: "COX'S BAZAR",
    CITYNAME_HEB: "COX'S BAZAR",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "CXC",
    AIRPORTNAME: "Chitina Airport",
    CITYCODE: "CXC",
    CITYNAME: "CHITINA",
    CITYNAME_HEB: "Chitina Airport",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CXF",
    AIRPORTNAME: "Coldfoot",
    CITYCODE: "CXF",
    CITYNAME: "COLDFOOT",
    CITYNAME_HEB: "Coldfoot",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CXI",
    AIRPORTNAME: "Christmas Island",
    CITYCODE: "CXI",
    CITYNAME: "CHRISTMAS ISLAND",
    CITYNAME_HEB: "CHRISTMAS ISLAND",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "CXJ",
    AIRPORTNAME: null,
    CITYCODE: "CXJ",
    CITYNAME: "CAXIAS DO SUL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CXL",
    AIRPORTNAME: "International",
    CITYCODE: "CXL",
    CITYNAME: "CALEXICO",
    CITYNAME_HEB: "CALEXICO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CXN",
    AIRPORTNAME: "Candala",
    CITYCODE: "CXN",
    CITYNAME: "CANDALA",
    CITYNAME_HEB: "CANDALA",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AIS",
    AIRPORTNAME: "Arorae Island",
    CITYCODE: "AIS",
    CITYNAME: "ARORAE ISLAND",
    CITYNAME_HEB: "ARORAE ISLAND",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "CXO",
    AIRPORTNAME: "Montgomery Co",
    CITYCODE: "CXO",
    CITYNAME: "CONROE",
    CITYNAME_HEB: "CONROE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CXP",
    AIRPORTNAME: "Tunggul Wulung",
    CITYCODE: "CXP",
    CITYNAME: "CILACAP",
    CITYNAME_HEB: "CILACAP",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CXQ",
    AIRPORTNAME: "Christmas Creek",
    CITYCODE: "CXQ",
    CITYNAME: "CHRISTMAS CREEK",
    CITYNAME_HEB: "CHRISTMAS CREEK",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CXT",
    AIRPORTNAME: "Charters Towers",
    CITYCODE: "CXT",
    CITYNAME: "CHARTERS TOWERS",
    CITYNAME_HEB: "CHARTERS TOWERS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CXY",
    AIRPORTNAME: "Cat Cays",
    CITYCODE: "CXY",
    CITYNAME: "CAT CAYS",
    CITYNAME_HEB: "CAT CAYS",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "CYA",
    AIRPORTNAME: "Les Cayes",
    CITYCODE: "CYA",
    CITYNAME: "LES CAYES",
    CITYNAME_HEB: "LES CAYES",
    COUNTRYCODE: "HT",
    COUNTRYNAME: "HAITI",
    COUNTRYNAME_HEB: "\u05d4\u05d0\u05d9\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "CYB",
    AIRPORTNAME: "Gerrard-Smith",
    CITYCODE: "CYB",
    CITYNAME: "CAYMAN BRAC IS",
    CITYNAME_HEB: "CAYMAN BRAC ISLAND",
    COUNTRYCODE: "KY",
    COUNTRYNAME: "CAYMAN ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e7\u05d9\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "CYC",
    AIRPORTNAME: "Caye Chapel",
    CITYCODE: "CYC",
    CITYNAME: "CAYE CHAPEL",
    CITYNAME_HEB: "CAYE CHAPEL",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "CYE",
    AIRPORTNAME: "Crystal Lake",
    CITYCODE: "CYE",
    CITYNAME: "CRYSTAL LAKE",
    CITYNAME_HEB: "CRYSTAL LAKE",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CYF",
    AIRPORTNAME: "Chefornak SPB",
    CITYCODE: "CYF",
    CITYNAME: "CHEFORNAK",
    CITYNAME_HEB: "Chefornak SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AIT",
    AIRPORTNAME: "Aitutaki",
    CITYCODE: "AIT",
    CITYNAME: "AITUTAKI",
    CITYNAME_HEB: "AITUTAKI",
    COUNTRYCODE: "CK",
    COUNTRYNAME: "COOK ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e7\u05d5\u05e7",
  },
  {
    AIRPORTCODE: "CYG",
    AIRPORTNAME: "Corryong",
    CITYCODE: "CYG",
    CITYNAME: "CORRYONG",
    CITYNAME_HEB: "CORRYONG",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CYI",
    AIRPORTNAME: "Chiayi",
    CITYCODE: "CYI",
    CITYNAME: "CHIAYI",
    CITYNAME_HEB: "\u05e6\u05d9\u05d0\u05d9\u05d9",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "CYL",
    AIRPORTNAME: "Coyoles",
    CITYCODE: "CYL",
    CITYNAME: "COYOLES",
    CITYNAME_HEB: "COYOLES",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "CYM",
    AIRPORTNAME: "Chatham SPB",
    CITYCODE: "CYM",
    CITYNAME: "CHATHAM",
    CITYNAME_HEB: "Chatham SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CYO",
    AIRPORTNAME: "Cayo Largo Del Sur",
    CITYCODE: "CYO",
    CITYNAME: "CAYO LARGO DEL SUR",
    CITYNAME_HEB: "CAYO LARGO DEL SUR",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "CYP",
    AIRPORTNAME: "Calbayog",
    CITYCODE: "CYP",
    CITYNAME: "CALBAYOG",
    CITYNAME_HEB: "CALBAYOG",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "CYR",
    AIRPORTNAME: "Colonia",
    CITYCODE: "CYR",
    CITYNAME: "COLONIA",
    CITYNAME_HEB: "COLONIA",
    COUNTRYCODE: "UY",
    COUNTRYNAME: "URUGUAY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e8\u05d5\u05d2\u05d0\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "CYS",
    AIRPORTNAME: "Cheyenne",
    CITYCODE: "CYS",
    CITYNAME: "CHEYENNE",
    CITYNAME_HEB: "CHEYENNE",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FEW",
    AIRPORTNAME: "Warren AFB",
    CITYCODE: "CYS",
    CITYNAME: "CHEYENNE",
    CITYNAME_HEB: "CHEYENNE",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CYT",
    AIRPORTNAME: " ",
    CITYCODE: "CYT",
    CITYNAME: "TURKISH SIDE OF CY",
    CITYNAME_HEB:
      "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "AIU",
    AIRPORTNAME: "Atiu Island",
    CITYCODE: "AIU",
    CITYNAME: "ATIU ISLAND",
    CITYNAME_HEB: "ATIU ISLAND",
    COUNTRYCODE: "CK",
    COUNTRYNAME: "COOK ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e7\u05d5\u05e7",
  },
  {
    AIRPORTCODE: "CYU",
    AIRPORTNAME: "Cuyo",
    CITYCODE: "CYU",
    CITYNAME: "CUYO",
    CITYNAME_HEB: "CUYO",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "CYX",
    AIRPORTNAME: "Cherskiy",
    CITYCODE: "CYX",
    CITYNAME: "CHERSKIY",
    CITYNAME_HEB: "CHERSKIY",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CYZ",
    AIRPORTNAME: "Cauayan",
    CITYCODE: "CYZ",
    CITYNAME: "CAUAYAN",
    CITYNAME_HEB: "CAUAYAN",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "CZA",
    AIRPORTNAME: "Chichen Itza",
    CITYCODE: "CZA",
    CITYNAME: "CHICHEN ITZA",
    CITYNAME_HEB: "CHICHEN ITZA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CZB",
    AIRPORTNAME: null,
    CITYCODE: "CZB",
    CITYNAME: "CRUZ ALTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CZC",
    AIRPORTNAME: "Copper Centre",
    CITYCODE: "CZC",
    CITYNAME: "COPPER CENTRE",
    CITYNAME_HEB: "Copper Centre",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CZE",
    AIRPORTNAME: "Coro",
    CITYCODE: "CZE",
    CITYNAME: "CORO",
    CITYNAME_HEB: "CORO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CZF",
    AIRPORTNAME: "Cape Romanzof",
    CITYCODE: "CZF",
    CITYNAME: "CAPE ROMANZOF",
    CITYNAME_HEB: "Cape Romanzof",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CZH",
    AIRPORTNAME: "Corozal",
    CITYCODE: "CZH",
    CITYNAME: "COROZAL",
    CITYNAME_HEB: "COROZAL",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "CZJ",
    AIRPORTNAME: "Corazon De Jesus",
    CITYCODE: "CZJ",
    CITYNAME: "CORAZON DE JESUS",
    CITYNAME_HEB: "CORAZON DE JESUS",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "AAU",
    AIRPORTNAME: "Asau",
    CITYCODE: "AAU",
    CITYNAME: "ASAU",
    CITYNAME_HEB: "ASAU",
    COUNTRYCODE: "WS",
    COUNTRYNAME: "SAMOA-INDEPENDENT STATE",
    COUNTRYNAME_HEB:
      "\u05e1\u05de\u05d5\u05d0\u05d4 \u05d4\u05de\u05e2\u05e8\u05d1\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "AIV",
    AIRPORTNAME: null,
    CITYCODE: "AIV",
    CITYNAME: "ALICEVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CZK",
    AIRPORTNAME: "Cascade Locks/Stevens",
    CITYCODE: "CZK",
    CITYNAME: "CASCADE LOCKS",
    CITYNAME_HEB: "CASCADE LOCKS",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CZL",
    AIRPORTNAME: "Ain El Bey",
    CITYCODE: "CZL",
    CITYNAME: "CONSTANTINE",
    CITYNAME_HEB: "CONSTANTINE",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CZM",
    AIRPORTNAME: "Cozumel",
    CITYCODE: "CZM",
    CITYNAME: "COZUMEL",
    CITYNAME_HEB: "\u05e7\u05d5\u05d6\u05d5\u05de\u05dc",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CZN",
    AIRPORTNAME: "Chisana Field",
    CITYCODE: "CZN",
    CITYNAME: "CHISANA",
    CITYNAME_HEB: "Chisana Field",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CZO",
    AIRPORTNAME: "Chistochina",
    CITYCODE: "CZO",
    CITYNAME: "CHISTOCHINA",
    CITYNAME_HEB: "Chistochina",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CZP",
    AIRPORTNAME: "Cape Pole",
    CITYCODE: "CZP",
    CITYNAME: "CAPE POLE",
    CITYNAME_HEB: "Cape Pole",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CZS",
    AIRPORTNAME: null,
    CITYCODE: "CZS",
    CITYNAME: "CRUZEIRO DO SUL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CZT",
    AIRPORTNAME: "Carrizo Springs",
    CITYCODE: "CZT",
    CITYNAME: "CARRIZO SPRINGS",
    CITYNAME_HEB: "CARRIZO SPRINGS",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CZU",
    AIRPORTNAME: "Corozal",
    CITYCODE: "CZU",
    CITYNAME: "COROZAL",
    CITYNAME_HEB: "COROZAL",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CZW",
    AIRPORTNAME: "Czestochowa",
    CITYCODE: "CZW",
    CITYNAME: "CZESTOCHOWA",
    CITYNAME_HEB: "\u05e6\u05e1\u05d8\u05d5\u05db\u05d5\u05d1\u05d4",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "AIW",
    AIRPORTNAME: "Ai-Ais",
    CITYCODE: "AIW",
    CITYNAME: "AI AIS",
    CITYNAME_HEB: "Reilly AHP",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CZX",
    AIRPORTNAME: "Changzhou",
    CITYCODE: "CZX",
    CITYNAME: "CHANGZHOU",
    CITYNAME_HEB: "CHANGZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CZY",
    AIRPORTNAME: "Cluny",
    CITYCODE: "CZY",
    CITYNAME: "CLUNY",
    CITYNAME_HEB: "CLUNY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CZZ",
    AIRPORTNAME: "Campo",
    CITYCODE: "CZZ",
    CITYNAME: "CAMPO",
    CITYNAME_HEB: "CAMPO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DAA",
    AIRPORTNAME: "Davison AAF",
    CITYCODE: "DAA",
    CITYNAME: "FORT BELVOIR",
    CITYNAME_HEB: "FORT BELVOIR",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "DAB",
    AIRPORTNAME: "Regional",
    CITYCODE: "DAB",
    CITYNAME: "DAYTONA BEACH",
    CITYNAME_HEB: "DAYTONA BEACH",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZPU",
    AIRPORTNAME: null,
    CITYCODE: "ZPU",
    CITYNAME: "SALZGITTER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPV",
    AIRPORTNAME: null,
    CITYCODE: "ZPV",
    CITYNAME: "SCHWAEBISCH GMUEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPW",
    AIRPORTNAME: null,
    CITYCODE: "ZPW",
    CITYNAME: "SCHWEINFURT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPX",
    AIRPORTNAME: null,
    CITYCODE: "ZPX",
    CITYNAME: "SCHWERTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPY",
    AIRPORTNAME: null,
    CITYCODE: "ZPY",
    CITYNAME: "SIEGBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPZ",
    AIRPORTNAME: null,
    CITYCODE: "ZPZ",
    CITYNAME: "SINDELFINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQA",
    AIRPORTNAME: null,
    CITYCODE: "ZQA",
    CITYNAME: "SINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQB",
    AIRPORTNAME: null,
    CITYCODE: "ZQB",
    CITYNAME: "SOLINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQC",
    AIRPORTNAME: null,
    CITYCODE: "ZQC",
    CITYNAME: "SPEYER",
    CITYNAME_HEB: "\u05d6\u05e4\u05d9\u05d9\u05e8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZQD",
    AIRPORTNAME: null,
    CITYCODE: "ZQD",
    CITYNAME: "STADE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVM",
    AIRPORTNAME: null,
    CITYCODE: "BVM",
    CITYNAME: "BELMONTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQE",
    AIRPORTNAME: null,
    CITYCODE: "ZQE",
    CITYNAME: "STOLBERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQF",
    AIRPORTNAME: null,
    CITYCODE: "ZQF",
    CITYNAME: "TRIER",
    CITYNAME_HEB: "\u05d8\u05e8\u05d9\u05d9\u05e8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZQG",
    AIRPORTNAME: null,
    CITYCODE: "ZQG",
    CITYNAME: "TROISDORF",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQH",
    AIRPORTNAME: null,
    CITYCODE: "ZQH",
    CITYNAME: "TUEBINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQI",
    AIRPORTNAME: null,
    CITYCODE: "ZQI",
    CITYNAME: "UNNA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQJ",
    AIRPORTNAME: null,
    CITYCODE: "ZQJ",
    CITYNAME: "VELBERT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQK",
    AIRPORTNAME: null,
    CITYCODE: "ZQK",
    CITYNAME: "VIERSEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQL",
    AIRPORTNAME: null,
    CITYCODE: "ZQL",
    CITYNAME: "VILLINGEN SCHWENNINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQM",
    AIRPORTNAME: " ",
    CITYCODE: "ZQM",
    CITYNAME: "VOELKLINGEN",
    CITYNAME_HEB: "\u05d5\u05dc\u05e7\u05dc\u05d9\u05e0\u05d2\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZQN",
    AIRPORTNAME: "Frankton",
    CITYCODE: "ZQN",
    CITYNAME: "QUEENSTOWN",
    CITYNAME_HEB:
      "\u05e7\u05d5\u05d5\u05d9\u05e0\u05e1\u05d8\u05d0\u05d5\u05df",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BVO",
    AIRPORTNAME: "Bartlesville",
    CITYCODE: "BVO",
    CITYNAME: "BARTLESVILLE",
    CITYNAME_HEB: "BARTLESVILLE",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZQO",
    AIRPORTNAME: null,
    CITYCODE: "ZQO",
    CITYNAME: "WAIBLINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQP",
    AIRPORTNAME: null,
    CITYCODE: "ZQP",
    CITYNAME: "WESEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQQ",
    AIRPORTNAME: null,
    CITYCODE: "ZQQ",
    CITYNAME: "WETZLAR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQR",
    AIRPORTNAME: null,
    CITYCODE: "ZQR",
    CITYNAME: "WITTEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQS",
    AIRPORTNAME: "Queen Charlotte Is",
    CITYCODE: "ZQS",
    CITYNAME: "QUEEN CHARLOTTE IS",
    CITYNAME_HEB: "QUEEN CHARLOTTE IS",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZQT",
    AIRPORTNAME: null,
    CITYCODE: "ZQT",
    CITYNAME: "WOLFENBUETTEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQU",
    AIRPORTNAME: null,
    CITYCODE: "ZQU",
    CITYNAME: "WOLFSBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQV",
    AIRPORTNAME: null,
    CITYCODE: "ZQV",
    CITYNAME: "WORMS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZQX",
    AIRPORTNAME: null,
    CITYCODE: "ZQX",
    CITYNAME: "NIAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZRC",
    AIRPORTNAME: null,
    CITYCODE: "ZRC",
    CITYNAME: "SAN PEDRO ALCANTARA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVP",
    AIRPORTNAME: "Bolovip",
    CITYCODE: "BVP",
    CITYNAME: "BOLOVIP",
    CITYNAME_HEB: "BOLOVIP",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ZRE",
    AIRPORTNAME: null,
    CITYCODE: "ZRE",
    CITYNAME: "RETHYMNO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZRH",
    AIRPORTNAME: null,
    CITYCODE: "ZRH",
    CITYNAME: "ZURICH",
    CITYNAME_HEB: "\u05e6\u05d9\u05e8\u05d9\u05da",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZRI",
    AIRPORTNAME: "Serui",
    CITYCODE: "ZRI",
    CITYNAME: "SERUI",
    CITYNAME_HEB: "SERUI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZRJ",
    AIRPORTNAME: "Round Lake",
    CITYCODE: "ZRJ",
    CITYNAME: "ROUND LAKE",
    CITYNAME_HEB: "ROUND LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZRM",
    AIRPORTNAME: "Sarmi",
    CITYCODE: "ZRM",
    CITYNAME: "SARMI",
    CITYNAME_HEB: "SARMI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZRN",
    AIRPORTNAME: " ",
    CITYCODE: "ZRN",
    CITYNAME: "NYON",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZRO",
    AIRPORTNAME: " ",
    CITYCODE: "ZRO",
    CITYNAME: "REGGIO NELL EMILIA",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZRS",
    AIRPORTNAME: "Flexenpass Heliport",
    CITYCODE: "ZRS",
    CITYNAME: "ZURS LECH",
    CITYNAME_HEB: "\u05e6\u05d5\u05e8\u05da \u05dc\u05da",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BVR",
    AIRPORTNAME: null,
    CITYCODE: "BVR",
    CITYNAME: "BRAVA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CV",
    COUNTRYNAME: "CAPE VERDE-REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZRW",
    AIRPORTNAME: null,
    CITYCODE: "ZRW",
    CITYNAME: "RASTATT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OEI",
    AIRPORTNAME: null,
    CITYCODE: "OEI",
    CITYNAME: "OEIRAS",
    CITYNAME_HEB: "\u05d0\u05d5\u05d0\u05d9\u05e8\u05d6",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTOGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "ZSA",
    AIRPORTNAME: "San Salvador",
    CITYCODE: "ZSA",
    CITYNAME: "SAN SALVADOR",
    CITYNAME_HEB: "\u05e1\u05d0\u05df \u05e1\u05dc\u05d1\u05d3\u05d5\u05e8",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "ZSC",
    AIRPORTNAME: null,
    CITYCODE: "ZSC",
    CITYNAME: "SCHOENA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZSD",
    AIRPORTNAME: null,
    CITYCODE: "ZSD",
    CITYNAME: "SCHWANHEIDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZSE",
    AIRPORTNAME: null,
    CITYCODE: "ZSE",
    CITYNAME: "ST PIERRE DE LA REUNION",
    CITYNAME_HEB: null,
    COUNTRYCODE: "RE",
    COUNTRYNAME: "REUNION ISLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZSG",
    AIRPORTNAME: null,
    CITYCODE: "ZSG",
    CITYNAME: "SONNEBERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FIR",
    AIRPORTNAME: null,
    CITYCODE: "FIR",
    CITYNAME: "FIER",
    CITYNAME_HEB: "\u05e4\u05d9\u05d0\u05e8",
    COUNTRYCODE: "AL",
    COUNTRYNAME: "ALBANIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZSI",
    AIRPORTNAME: null,
    CITYCODE: "ZSI",
    CITYNAME: "SASSNITZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AFL",
    AIRPORTNAME: null,
    CITYCODE: "AFL",
    CITYNAME: "ALTA FLORESTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVS",
    AIRPORTNAME: null,
    CITYCODE: "BVS",
    CITYNAME: "BREVES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZSJ",
    AIRPORTNAME: null,
    CITYCODE: "ZSJ",
    CITYNAME: "SANDY LAKE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZSK",
    AIRPORTNAME: null,
    CITYCODE: "ZSK",
    CITYNAME: "PASEWALK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZSM",
    AIRPORTNAME: null,
    CITYCODE: "ZSM",
    CITYNAME: "SANTA CLARA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZSN",
    AIRPORTNAME: null,
    CITYCODE: "ZSN",
    CITYNAME: "STENDAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZSO",
    AIRPORTNAME: null,
    CITYCODE: "ZSO",
    CITYNAME: "SUHL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZSP",
    AIRPORTNAME: "St Paul",
    CITYCODE: "ZSP",
    CITYNAME: "SAINT PAUL",
    CITYNAME_HEB: "SAINT PAUL",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZSQ",
    AIRPORTNAME: null,
    CITYCODE: "ZSQ",
    CITYNAME: "SALZWEDEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZSS",
    AIRPORTNAME: "Sassandra",
    CITYCODE: "ZSS",
    CITYNAME: "SASSANDRA",
    CITYNAME_HEB: "SASSANDRA",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "ZST",
    AIRPORTNAME: null,
    CITYCODE: "ZST",
    CITYNAME: "STEWART",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVU",
    AIRPORTNAME: "Beluga",
    CITYCODE: "BVU",
    CITYNAME: "BELUGA",
    CITYNAME_HEB: "Beluga",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZSU",
    AIRPORTNAME: null,
    CITYCODE: "ZSU",
    CITYNAME: "DESSAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZSX",
    AIRPORTNAME: null,
    CITYCODE: "ZSX",
    CITYNAME: "STRALSUND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZSY",
    AIRPORTNAME: null,
    CITYCODE: "ZSY",
    CITYNAME: "SCOTTSDALE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZTA",
    AIRPORTNAME: "Tureira",
    CITYCODE: "ZTA",
    CITYNAME: "TUREIRA",
    CITYNAME_HEB: "TUREIRA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "ZTB",
    AIRPORTNAME: "Tete-a-La Baleine",
    CITYCODE: "ZTB",
    CITYNAME: "TETE A LA BALEINE",
    CITYNAME_HEB: "TETE A LA BALEINE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZTH",
    AIRPORTNAME: "Zakynthos International Airport",
    CITYCODE: "ZTH",
    CITYNAME: "ZAKINTHOS IS",
    CITYNAME_HEB: "\u05d6\u05e7\u05d9\u05e0\u05d8\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "ZTK",
    AIRPORTNAME: " ",
    CITYCODE: "ZTK",
    CITYNAME: "THUN",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZTM",
    AIRPORTNAME: "Shamattawa",
    CITYCODE: "ZTM",
    CITYNAME: "SHAMATTAWA",
    CITYNAME_HEB: "SHAMATTAWA",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BVW",
    AIRPORTNAME: "Batavia Downs",
    CITYCODE: "BVW",
    CITYNAME: "BATAVIA DOWNS",
    CITYNAME_HEB: "BATAVIA DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZTP",
    AIRPORTNAME: null,
    CITYCODE: "ZTP",
    CITYNAME: "ITAPETININGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZTR",
    AIRPORTNAME: "Zhitomir",
    CITYCODE: "ZTR",
    CITYNAME: "ZHITOMIR",
    CITYNAME_HEB: "\u05d6\u05d9\u05d8\u05d5\u05de\u05d9\u05e8",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "ZTS",
    AIRPORTNAME: "Tahsis",
    CITYCODE: "ZTS",
    CITYNAME: "TAHSIS",
    CITYNAME_HEB: "TAHSIS",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZTW",
    AIRPORTNAME: " ",
    CITYCODE: "ZTW",
    CITYNAME: "TSUEN WAN",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ZTZ",
    AIRPORTNAME: null,
    CITYCODE: "ZTZ",
    CITYNAME: "CHEMNITZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZUC",
    AIRPORTNAME: "Ignace",
    CITYCODE: "ZUC",
    CITYNAME: "IGNACE",
    CITYNAME_HEB: "IGNACE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZUD",
    AIRPORTNAME: "Ancud",
    CITYCODE: "ZUD",
    CITYNAME: "ANCUD",
    CITYNAME_HEB: "ANCUD",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "ZUE",
    AIRPORTNAME: "Zuenoula",
    CITYCODE: "ZUE",
    CITYNAME: "ZUENOULA",
    CITYNAME_HEB: "ZUENOULA",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "ZUF",
    AIRPORTNAME: null,
    CITYCODE: "ZUF",
    CITYNAME: "MYSTERY EXPLORER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVX",
    AIRPORTNAME: "Municipal",
    CITYCODE: "BVX",
    CITYNAME: "BATESVILLE",
    CITYNAME_HEB: "BATESVILLE",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZUH",
    AIRPORTNAME: "Zhuhai Airport",
    CITYCODE: "ZUH",
    CITYNAME: "ZHUHAI",
    CITYNAME_HEB: "ZHUHAI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ZUM",
    AIRPORTNAME: "Churchill Falls",
    CITYCODE: "ZUM",
    CITYNAME: "CHURCHILL FALLS",
    CITYNAME_HEB: "CHURCHILL FALLS",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZUO",
    AIRPORTNAME: null,
    CITYCODE: "ZUO",
    CITYNAME: "DUTCH RAIL Z 16",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZUP",
    AIRPORTNAME: null,
    CITYCODE: "ZUP",
    CITYNAME: "DUTCH RAIL Z 17",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZUQ",
    AIRPORTNAME: null,
    CITYCODE: "ZUQ",
    CITYNAME: "DUTCH RAIL Z 18",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZUR",
    AIRPORTNAME: null,
    CITYCODE: "ZUR",
    CITYNAME: "DUTCH RAIL Z 19",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZUS",
    AIRPORTNAME: null,
    CITYCODE: "ZUS",
    CITYNAME: "DUTCH RAIL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZUT",
    AIRPORTNAME: null,
    CITYCODE: "ZUS",
    CITYNAME: "DUTCH RAIL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZUU",
    AIRPORTNAME: null,
    CITYCODE: "ZUU",
    CITYNAME: "NOORD-BRABANT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZUV",
    AIRPORTNAME: null,
    CITYCODE: "ZUV",
    CITYNAME: "ZUID HOLLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVY",
    AIRPORTNAME: "Beverly",
    CITYCODE: "BVY",
    CITYNAME: "BEVERLY",
    CITYNAME_HEB: "BEVERLY",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "ZUW",
    AIRPORTNAME: null,
    CITYCODE: "ZUW",
    CITYNAME: "NOORD HOLLAND",
    CITYNAME_HEB: "\u05e0\u05d5\u05e8\u05d3 \u05d4\u05d5\u05dc\u05e0\u05d3",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ZUX",
    AIRPORTNAME: null,
    CITYCODE: "ZUX",
    CITYNAME: "DUTCH RAIL Z 25",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZUY",
    AIRPORTNAME: null,
    CITYCODE: "ZUY",
    CITYNAME: "DUTCH RAIL Z 26",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZUZ",
    AIRPORTNAME: null,
    CITYCODE: "ZUZ",
    CITYNAME: "DUTCH RAIL Z 27",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVA",
    AIRPORTNAME: "Miandrivazo",
    CITYCODE: "ZVA",
    CITYNAME: "MIANDRIVAZO",
    CITYNAME_HEB: "MIANDRIVAZO",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "ZVC",
    AIRPORTNAME: null,
    CITYCODE: "ZVC",
    CITYNAME: "VILLARRICA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVG",
    AIRPORTNAME: "Springvale",
    CITYCODE: "ZVG",
    CITYNAME: "SPRINGVALE",
    CITYNAME_HEB: "SPRINGVALE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZVH",
    AIRPORTNAME: " ",
    CITYCODE: "ZVH",
    CITYNAME: "VELDOVEN",
    CITYNAME_HEB: "\u05d5\u05d5\u05dc\u05d3\u05d5\u05d1\u05df",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ZVK",
    AIRPORTNAME: "Savannakhet",
    CITYCODE: "ZVK",
    CITYNAME: "SAVANNAKHET",
    CITYNAME_HEB: "SAVANNAKHET",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "ZVL",
    AIRPORTNAME: null,
    CITYCODE: "ZVL",
    CITYNAME: "DUTCH RAIL Z 01",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVZ",
    AIRPORTNAME: "Beverley Springs",
    CITYCODE: "BVZ",
    CITYNAME: "BEVERLEY SPRINGS",
    CITYNAME_HEB: "BEVERLEY SPRINGS",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZVM",
    AIRPORTNAME: null,
    CITYCODE: "ZVM",
    CITYNAME: "HANOVER MESSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVN",
    AIRPORTNAME: null,
    CITYCODE: "ZVN",
    CITYNAME: "DUTCH RAIL Z 03",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVO",
    AIRPORTNAME: null,
    CITYCODE: "ZVO",
    CITYNAME: "DUTCH RAIL Z 04",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVP",
    AIRPORTNAME: null,
    CITYCODE: "ZVP",
    CITYNAME: "DUTCH RAIL Z 05",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVQ",
    AIRPORTNAME: null,
    CITYCODE: "ZVQ",
    CITYNAME: "DUTCH RAIL Z 06",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVR",
    AIRPORTNAME: null,
    CITYCODE: "ZVR",
    CITYNAME: "HANOVER HBF",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVS",
    AIRPORTNAME: null,
    CITYCODE: "ZVS",
    CITYNAME: "DUTCH RAIL Z 08",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVT",
    AIRPORTNAME: null,
    CITYCODE: "ZVT",
    CITYNAME: "DUTCH RAIL Z 09",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVU",
    AIRPORTNAME: null,
    CITYCODE: "ZVU",
    CITYNAME: "DUTCH RAIL Z 10",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVV",
    AIRPORTNAME: null,
    CITYCODE: "ZVV",
    CITYNAME: "DUTCH RAIL Z 11",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BWA",
    AIRPORTNAME: "Bhairawa",
    CITYCODE: "BWA",
    CITYNAME: "BHAIRAWA",
    CITYNAME_HEB: "BHAIRAWA",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "ZVW",
    AIRPORTNAME: null,
    CITYCODE: "ZVW",
    CITYNAME: "DUTCH RAIL Z 12",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVX",
    AIRPORTNAME: null,
    CITYCODE: "ZVX",
    CITYNAME: "DUTCH RAIL Z 13",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVY",
    AIRPORTNAME: null,
    CITYCODE: "ZVY",
    CITYNAME: "DUTCH RAIL Z 14",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZVZ",
    AIRPORTNAME: null,
    CITYCODE: "ZVZ",
    CITYNAME: "DUTCH RAIL Z 15",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZWA",
    AIRPORTNAME: "Andapa",
    CITYCODE: "ZWA",
    CITYNAME: "ANDAPA",
    CITYNAME_HEB: "ANDAPA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "ZWD",
    AIRPORTNAME: null,
    CITYCODE: "ZWD",
    CITYNAME: "WARNEMUENDE",
    CITYNAME_HEB: "\u05d5\u05e8\u05e0\u05de\u05d5\u05e0\u05d3\u05d4",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZWG",
    AIRPORTNAME: null,
    CITYCODE: "ZWG",
    CITYNAME: "WEINGARTEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZWK",
    AIRPORTNAME: null,
    CITYCODE: "ZWK",
    CITYNAME: "SUWALKI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZWL",
    AIRPORTNAME: "Wollaston Lake",
    CITYCODE: "ZWL",
    CITYNAME: "WOLLASTON LAKE",
    CITYNAME_HEB: "WOLLASTON LAKE",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZWM",
    AIRPORTNAME: null,
    CITYCODE: "ZWM",
    CITYNAME: "WISMAR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BWB",
    AIRPORTNAME: "Barrow Island",
    CITYCODE: "BWB",
    CITYNAME: "BARROW ISLAND",
    CITYNAME_HEB: "BARROW ISLAND",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZWN",
    AIRPORTNAME: null,
    CITYCODE: "ZWN",
    CITYNAME: "WITTENBERGE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZWO",
    AIRPORTNAME: null,
    CITYCODE: "ZWO",
    CITYNAME: "DUTCH RAIL Z 02",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZWQ",
    AIRPORTNAME: null,
    CITYCODE: "ZWQ",
    CITYNAME: "DUTCH RAIL Z 07",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZWT",
    AIRPORTNAME: null,
    CITYCODE: "ZWT",
    CITYNAME: "WITTENBERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZWX",
    AIRPORTNAME: null,
    CITYCODE: "ZWX",
    CITYNAME: "DUTCH RAIL Z 28",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZWY",
    AIRPORTNAME: null,
    CITYCODE: "ZWY",
    CITYNAME: "DUTCH RAIL Z 29",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZWZ",
    AIRPORTNAME: null,
    CITYCODE: "ZWZ",
    CITYNAME: "DUTCH RAIL Z 30",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZXB",
    AIRPORTNAME: null,
    CITYCODE: "ZXB",
    CITYNAME: "JAN MAYEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZXC",
    AIRPORTNAME: null,
    CITYCODE: "ZXC",
    CITYNAME: "BANGSUND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZXD",
    AIRPORTNAME: null,
    CITYCODE: "ZXD",
    CITYNAME: "VIKNA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BWC",
    AIRPORTNAME: "Brawley",
    CITYCODE: "BWC",
    CITYNAME: "BRAWLEY",
    CITYNAME_HEB: "BRAWLEY",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZXG",
    AIRPORTNAME: null,
    CITYCODE: "ZXG",
    CITYNAME: "GRAVIK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZXH",
    AIRPORTNAME: null,
    CITYCODE: "ZXH",
    CITYNAME: "SOERFJORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZXI",
    AIRPORTNAME: null,
    CITYCODE: "ZXI",
    CITYNAME: "ROEDDEY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZXJ",
    AIRPORTNAME: null,
    CITYCODE: "ZXJ",
    CITYNAME: "FORE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZXK",
    AIRPORTNAME: null,
    CITYCODE: "ZXK",
    CITYNAME: "BAASMO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZXL",
    AIRPORTNAME: null,
    CITYCODE: "ZXL",
    CITYNAME: "SKJERSTAD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZXM",
    AIRPORTNAME: null,
    CITYCODE: "ZXM",
    CITYNAME: "ROGNAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZXN",
    AIRPORTNAME: null,
    CITYCODE: "ZXN",
    CITYNAME: "LEKA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZXO",
    AIRPORTNAME: null,
    CITYCODE: "ZXO",
    CITYNAME: "FAUSKE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BWD",
    AIRPORTNAME: "Brownwood",
    CITYCODE: "BWD",
    CITYNAME: "BROWNWOOD",
    CITYNAME_HEB: "BROWNWOOD",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZXQ",
    AIRPORTNAME: null,
    CITYCODE: "ZXQ",
    CITYNAME: "SOLSTAD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZXR",
    AIRPORTNAME: null,
    CITYCODE: "ZXR",
    CITYNAME: "HEMNES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZYC",
    AIRPORTNAME: null,
    CITYCODE: "ZYC",
    CITYNAME: "GIZYCKO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZYI",
    AIRPORTNAME: "Zunyi",
    CITYCODE: "ZYI",
    CITYNAME: "ZUNYI",
    CITYNAME_HEB: "ZUNYI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ZYL",
    AIRPORTNAME: "Osmany International Airport",
    CITYCODE: "ZYL",
    CITYNAME: "SYLHET",
    CITYNAME_HEB: "SYLHET",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "ZYO",
    AIRPORTNAME: " ",
    CITYCODE: "ZYO",
    CITYNAME: "ROOSENDAAL",
    CITYNAME_HEB: "\u05e8\u05d5\u05d6\u05e0\u05d3\u05d0\u05dc",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "AFN",
    AIRPORTNAME: null,
    CITYCODE: "AFN",
    CITYNAME: "JAFFREY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNH",
    COUNTRYNAME: "NEW HAMPSHIRE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BWE",
    AIRPORTNAME: "Braunschweig",
    CITYCODE: "BWE",
    CITYNAME: "BRAUNSCHWEIG",
    CITYNAME_HEB: "BRAUNSCHWEIG",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZZA",
    AIRPORTNAME: null,
    CITYCODE: "ZZA",
    CITYNAME: "AZZAZGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZB",
    AIRPORTNAME: null,
    CITYCODE: "ZZB",
    CITYNAME: "BARIKA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZC",
    AIRPORTNAME: null,
    CITYCODE: "ZZC",
    CITYNAME: "CHERCHELL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZD",
    AIRPORTNAME: null,
    CITYCODE: "ZZD",
    CITYNAME: "DRA EL MIZAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZF",
    AIRPORTNAME: null,
    CITYCODE: "ZZF",
    CITYNAME: "MYSTERY FLIGHT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZH",
    AIRPORTNAME: null,
    CITYCODE: "ZZH",
    CITYNAME: "CRANZAHL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZI",
    AIRPORTNAME: null,
    CITYCODE: "ZZI",
    CITYNAME: "ELITE MYSTERY NIGHT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BWF",
    AIRPORTNAME: "Walney Island",
    CITYCODE: "BWF",
    CITYNAME: "BARROW IN FURNESS",
    CITYNAME_HEB: "BARROW IN FURNESS",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZZJ",
    AIRPORTNAME: null,
    CITYCODE: "ZZJ",
    CITYNAME: "MYSTERY NIGHT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZK",
    AIRPORTNAME: null,
    CITYCODE: "ZZK",
    CITYNAME: "KHEMIS MILIANA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZL",
    AIRPORTNAME: " ",
    CITYCODE: "ZZL",
    CITYNAME: "ZALZBURGERLAND",
    CITYNAME_HEB:
      "\u05d6\u05dc\u05e6\u05d1\u05d5\u05e8\u05d2\u05dc\u05e0\u05d3",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZZM",
    AIRPORTNAME: null,
    CITYCODE: "ZZM",
    CITYNAME: "BORDJ MENAIEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZP",
    AIRPORTNAME: null,
    CITYCODE: "ZZP",
    CITYNAME: "POZAREVAC",
    CITYNAME_HEB: null,
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZQ",
    AIRPORTNAME: " ",
    CITYCODE: "ZZQ",
    CITYNAME: "CHEUNG SHA WAN",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ZZR",
    AIRPORTNAME: null,
    CITYCODE: "ZZR",
    CITYNAME: "KHERRATA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZS",
    AIRPORTNAME: null,
    CITYCODE: "ZZS",
    CITYNAME: "M SILA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZT",
    AIRPORTNAME: null,
    CITYCODE: "ZZT",
    CITYNAME: "TAHER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZU",
    AIRPORTNAME: null,
    CITYCODE: "ZZU",
    CITYNAME: "MZUZU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "MW",
    COUNTRYNAME: "MALAWI",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BWG",
    AIRPORTNAME: "Warren County",
    CITYCODE: "BWG",
    CITYNAME: "BOWLING GREEN",
    CITYNAME_HEB: "BOWLING GREEN",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZZV",
    AIRPORTNAME: "Zanesville",
    CITYCODE: "ZZV",
    CITYNAME: "ZANESVILLE",
    CITYNAME_HEB: "ZANESVILLE",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZZW",
    AIRPORTNAME: null,
    CITYCODE: "ZZW",
    CITYNAME: "DAY TRIP MYSTERY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZZZ",
    AIRPORTNAME: null,
    CITYCODE: "ZZZ",
    CITYNAME: "GREENWICH MEAN TM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ZZ",
    COUNTRYNAME: "FICTITIOUS POINTS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TSS",
    AIRPORTNAME: " ",
    CITYCODE: "TSS",
    CITYNAME: "SESTRIER",
    CITYNAME_HEB: "\u05e1\u05e1\u05d8\u05e8\u05d9\u05d9\u05e8",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BCQ",
    AIRPORTNAME: " ",
    CITYCODE: "BCQ",
    CITYNAME: "COSTA BLANCA",
    CITYNAME_HEB:
      "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05d1\u05dc\u05d0\u05e0\u05e7\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "CPJ",
    AIRPORTNAME: " ",
    CITYCODE: "CPJ",
    CITYNAME: "PLATERS",
    CITYNAME_HEB: "\u05e4\u05dc\u05d8\u05d0\u05e8\u05e1",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CPK",
    AIRPORTNAME: " ",
    CITYCODE: "CPK",
    CITYNAME: "PLITVICE",
    CITYNAME_HEB: "\u05e4\u05dc\u05d8\u05d1\u05d9\u05e6\u05d4",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "FBC",
    CITYNAME: "RABAC",
    CITYNAME_HEB: null,
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BWJ",
    AIRPORTNAME: "Bawan",
    CITYCODE: "BWJ",
    CITYNAME: "BAWAN",
    CITYNAME_HEB: "BAWAN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "FPA",
    AIRPORTNAME: " ",
    CITYCODE: "FPA",
    CITYNAME: "PROVANCE",
    CITYNAME_HEB: "\u05e4\u05e8\u05d5\u05d1\u05d0\u05e0\u05e1",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "NOF",
    AIRPORTNAME: " ",
    CITYCODE: "NOF",
    CITYNAME: "NORMANDY",
    CITYNAME_HEB: "\u05e0\u05d5\u05e8\u05de\u05e0\u05d3\u05d9",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "TPB",
    CITYNAME: "PAMUKKALE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "LDD",
    CITYNAME: "MAGGIORE LAKE",
    CITYNAME_HEB: "\u05d0\u05d2\u05dd \u05de\u05d2\u05d5\u05e8\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PMJ",
    AIRPORTNAME: " ",
    CITYCODE: "PMJ",
    CITYNAME: "MADEIRA",
    CITYNAME_HEB: "\u05de\u05d3\u05e8\u05d4",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "ITT",
    AIRPORTNAME: " ",
    CITYCODE: "ITT",
    CITYNAME: "ITALY",
    CITYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IRF",
    AIRPORTNAME: " ",
    CITYCODE: "IRF",
    CITYNAME: "IRELAND",
    CITYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "LTE",
    AIRPORTNAME: " ",
    CITYCODE: "LTE",
    CITYNAME: "LUTRAKI",
    CITYNAME_HEB: "\u05dc\u05d5\u05d8\u05e8\u05d0\u05e7\u05d9",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "BWK",
    AIRPORTNAME: "Bol",
    CITYCODE: "BWK",
    CITYNAME: "BOL",
    CITYNAME_HEB: "BOL",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZJU",
    AIRPORTNAME: null,
    CITYCODE: "ZJU",
    CITYNAME: "OLTEN",
    CITYNAME_HEB: "\u05d0\u05d5\u05dc\u05d8\u05df",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZJV",
    AIRPORTNAME: null,
    CITYCODE: "ZJV",
    CITYNAME: "PONTRESINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZJW",
    AIRPORTNAME: null,
    CITYCODE: "ZJW",
    CITYNAME: "RAPPERSWIL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZJZ",
    AIRPORTNAME: null,
    CITYCODE: "ZJZ",
    CITYNAME: "RORSCHACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZKA",
    AIRPORTNAME: null,
    CITYCODE: "ZKA",
    CITYNAME: "SARGANS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZKB",
    AIRPORTNAME: "Kasaba Bay",
    CITYCODE: "ZKB",
    CITYNAME: "KASABA BAY",
    CITYNAME_HEB: "KASABA BAY",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZKC",
    AIRPORTNAME: null,
    CITYCODE: "ZKC",
    CITYNAME: "SARNEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZKE",
    AIRPORTNAME: "Kaschechewan",
    CITYCODE: "ZKE",
    CITYNAME: "KASCHECHEWAN",
    CITYNAME_HEB: "KASCHECHEWAN",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZKF",
    AIRPORTNAME: null,
    CITYCODE: "ZKF",
    CITYNAME: "SAINT MARGRETHEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUW",
    AIRPORTNAME: "Baubau",
    CITYCODE: "BUW",
    CITYNAME: "BAUBAU",
    CITYNAME_HEB: "BAUBAU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZKG",
    AIRPORTNAME: "Kegaska",
    CITYCODE: "ZKG",
    CITYNAME: "KEGASKA",
    CITYNAME_HEB: "KEGASKA",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZKI",
    AIRPORTNAME: " ",
    CITYCODE: "ZKI",
    CITYNAME: "SAAS FEE",
    CITYNAME_HEB: "\u05e1\u05d0\u05e1 \u05e4\u05d4",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "XSA",
    CITYNAME: "SAUTENS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZKJ",
    AIRPORTNAME: null,
    CITYCODE: "ZKJ",
    CITYNAME: "SCHAFFHAUSEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZKK",
    AIRPORTNAME: null,
    CITYCODE: "ZKK",
    CITYNAME: "SCHWYZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZKL",
    AIRPORTNAME: "Steenkool",
    CITYCODE: "ZKL",
    CITYNAME: "STEENKOOL",
    CITYNAME_HEB: "STEENKOOL",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "VIP",
    CITYNAME: "VILLACH",
    CITYNAME_HEB: "\u05d5\u05d9\u05dc\u05d0\u05da",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "XSP",
    CITYNAME: "GRAINAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "XSD",
    CITYNAME: "MADERNO",
    CITYNAME_HEB: " \u05de\u05d0\u05d3\u05e8\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "VXL",
    CITYNAME: "VOLENDAM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZKM",
    AIRPORTNAME: "Sette Cama",
    CITYCODE: "ZKM",
    CITYNAME: "SETTE CAMA",
    CITYNAME_HEB: "SETTE CAMA",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "ZKO",
    AIRPORTNAME: null,
    CITYCODE: "ZKO",
    CITYNAME: "SIERRE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZKP",
    AIRPORTNAME: "Kasompe",
    CITYCODE: "ZKP",
    CITYNAME: "KASOMPE",
    CITYNAME_HEB: "KASOMPE",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZKQ",
    AIRPORTNAME: " ",
    CITYCODE: "ZKQ",
    CITYNAME: "KOTOR",
    CITYNAME_HEB: "\u05e7\u05d5\u05d8\u05d5\u05e8",
    COUNTRYCODE: "ME",
    COUNTRYNAME: "MONTENEGRO",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "BUX",
    AIRPORTNAME: "Bunia",
    CITYCODE: "BUX",
    CITYNAME: "BUNIA",
    CITYNAME_HEB: "BUNIA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "ZKR",
    AIRPORTNAME: " ",
    CITYCODE: "ZKR",
    CITYNAME: "KARLOVASI",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "ZKS",
    AIRPORTNAME: null,
    CITYCODE: "ZKS",
    CITYNAME: "SOLOTHURN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZKT",
    AIRPORTNAME: null,
    CITYCODE: "ZKT",
    CITYNAME: "KOMOTINI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZKU",
    AIRPORTNAME: " ",
    CITYCODE: "ZKU",
    CITYNAME: "SURSEE",
    CITYNAME_HEB: "\u05e1\u05d5\u05e8\u05e1\u05d9",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZKV",
    AIRPORTNAME: null,
    CITYCODE: "ZKV",
    CITYNAME: "THALWIL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "RSM",
    CITYNAME: "LOVRAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "OOS",
    CITYNAME: "OOSTERHOUT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "ZAY",
    CITYNAME: "ZANDVOORT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZKW",
    AIRPORTNAME: null,
    CITYCODE: "ZKW",
    CITYNAME: "WETZIKON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZKX",
    AIRPORTNAME: null,
    CITYCODE: "ZKX",
    CITYNAME: "UZWIL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZKY",
    AIRPORTNAME: " ",
    CITYCODE: "ZKY",
    CITYNAME: "VERBIER",
    CITYNAME_HEB: "\u05d5\u05d5\u05e8\u05d1\u05d9\u05d4",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZKZ",
    AIRPORTNAME: null,
    CITYCODE: "ZKZ",
    CITYNAME: "VEVEY BVB",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZLA",
    AIRPORTNAME: null,
    CITYCODE: "ZLA",
    CITYNAME: "VILLARS BVB",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUY",
    AIRPORTNAME: "Bunbury",
    CITYCODE: "BUY",
    CITYNAME: "BUNBURY",
    CITYNAME_HEB: "BUNBURY",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZLB",
    AIRPORTNAME: " ",
    CITYCODE: "ZLB",
    CITYNAME: "VISP",
    CITYNAME_HEB: "\u05d5\u05d9\u05e1\u05e4",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZLC",
    AIRPORTNAME: null,
    CITYCODE: "ZLC",
    CITYNAME: "WAEDENSWIL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZLD",
    AIRPORTNAME: null,
    CITYCODE: "ZLD",
    CITYNAME: "WEINFELDEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZLE",
    AIRPORTNAME: " ",
    CITYCODE: "ZLE",
    CITYNAME: "WENGEN",
    CITYNAME_HEB: "\u05d5\u05d5\u05e0\u05d2\u05df",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZLF",
    AIRPORTNAME: null,
    CITYCODE: "ZLF",
    CITYNAME: "WETTINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZLG",
    AIRPORTNAME: "El Gouera",
    CITYCODE: "ZLG",
    CITYNAME: "EL GOUERA",
    CITYNAME_HEB: "EL GOUERA",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZLH",
    AIRPORTNAME: null,
    CITYCODE: "ZLH",
    CITYNAME: "WIL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZLI",
    AIRPORTNAME: null,
    CITYCODE: "ZLI",
    CITYNAME: "WINTERTHUR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZLJ",
    AIRPORTNAME: null,
    CITYCODE: "ZLJ",
    CITYNAME: "YVERDON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZLL",
    AIRPORTNAME: null,
    CITYCODE: "ZLL",
    CITYNAME: "ZOFINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVB",
    AIRPORTNAME: null,
    CITYCODE: "BVB",
    CITYNAME: "BOA VISTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZLM",
    AIRPORTNAME: null,
    CITYCODE: "ZLM",
    CITYNAME: "ZUG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZLO",
    AIRPORTNAME: "Playa de Oro International",
    CITYCODE: "ZLO",
    CITYNAME: "MANZANILLO",
    CITYNAME_HEB: "MANZANILLO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "ZLR",
    AIRPORTNAME: " ",
    CITYCODE: "ZLR",
    CITYNAME: "ZILLERTAL",
    CITYNAME_HEB: "\u05d6\u05d9\u05dc\u05e8\u05d8\u05d0\u05dc",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZLS",
    AIRPORTNAME: null,
    CITYCODE: "ZLS",
    CITYNAME: "LIVERPOOL STREET",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZLT",
    AIRPORTNAME: "La Tabatiere",
    CITYCODE: "ZLT",
    CITYNAME: "LA TABATIERE",
    CITYNAME_HEB: "LA TABATIERE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZLU",
    AIRPORTNAME: null,
    CITYCODE: "ZLU",
    CITYNAME: "LUDWIGSLUST",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZLV",
    AIRPORTNAME: " ",
    CITYCODE: "ZLV",
    CITYNAME: "LUOSTO",
    CITYNAME_HEB: "\u05dc\u05d5\u05e1\u05d8\u05d5",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05d3",
  },
  {
    AIRPORTCODE: "ZMA",
    AIRPORTNAME: null,
    CITYCODE: "ZMA",
    CITYNAME: "MANSFIELD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZMB",
    AIRPORTNAME: null,
    CITYCODE: "ZMB",
    CITYNAME: "HAMBURG HBF",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZMC",
    AIRPORTNAME: null,
    CITYCODE: "ZMC",
    CITYNAME: "MIEDZYZDROJE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVC",
    AIRPORTNAME: null,
    CITYCODE: "BVC",
    CITYNAME: "BOA VISTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CV",
    COUNTRYNAME: "CAPE VERDE-REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZMD",
    AIRPORTNAME: null,
    CITYCODE: "ZMD",
    CITYNAME: "SENA MADUREIRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZMG",
    AIRPORTNAME: null,
    CITYCODE: "ZMG",
    CITYNAME: "MAGDEBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZMH",
    AIRPORTNAME: null,
    CITYCODE: "ZMH",
    CITYNAME: "MILE RANCH 108",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZMM",
    AIRPORTNAME: "Zamora",
    CITYCODE: "ZMM",
    CITYNAME: "ZAMORA",
    CITYNAME_HEB: "\u05d6\u05de\u05d5\u05e8\u05d4",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "ZMN",
    AIRPORTNAME: null,
    CITYCODE: "ZMN",
    CITYNAME: "MONTE SIAO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZMO",
    AIRPORTNAME: " ",
    CITYCODE: "ZMO",
    CITYNAME: "MODENA",
    CITYNAME_HEB: "\u05de\u05d5\u05d3\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZMR",
    AIRPORTNAME: null,
    CITYCODE: "ZMR",
    CITYNAME: "MERAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZMT",
    AIRPORTNAME: "Masset",
    CITYCODE: "ZMT",
    CITYNAME: "MASSET",
    CITYNAME_HEB: "MASSET",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZMV",
    AIRPORTNAME: null,
    CITYCODE: "ZMV",
    CITYNAME: "MELVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNB",
    AIRPORTNAME: null,
    CITYCODE: "ZNB",
    CITYNAME: "HAMM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVD",
    AIRPORTNAME: "Beaver Inlet-Sea Port",
    CITYCODE: "BVD",
    CITYNAME: "BEAVER INLET",
    CITYNAME_HEB: "Beaver Inlet-Sea Port",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZNC",
    AIRPORTNAME: "Nyac",
    CITYCODE: "ZNC",
    CITYNAME: "NYAC",
    CITYNAME_HEB: "Nyac",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZND",
    AIRPORTNAME: null,
    CITYCODE: "ZND",
    CITYNAME: "ZINDER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NE",
    COUNTRYNAME: "NIGER",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNE",
    AIRPORTNAME: "Newman",
    CITYCODE: "ZNE",
    CITYNAME: "NEWMAN",
    CITYNAME_HEB: "NEWMAN",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZNF",
    AIRPORTNAME: null,
    CITYCODE: "ZNF",
    CITYNAME: "HANAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNG",
    AIRPORTNAME: "Negginan",
    CITYCODE: "ZNG",
    CITYNAME: "NEGGINAN",
    CITYNAME_HEB: "NEGGINAN",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZNH",
    AIRPORTNAME: null,
    CITYCODE: "ZNH",
    CITYNAME: "HETTLINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNI",
    AIRPORTNAME: " ",
    CITYCODE: "ZNI",
    CITYNAME: "HEIDENHEIM",
    CITYNAME_HEB: "\u05d4\u05d9\u05d9\u05d3\u05e0\u05d4\u05d9\u05d9\u05dd",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZNJ",
    AIRPORTNAME: " ",
    CITYCODE: "ZNJ",
    CITYNAME: "HEILBRONN",
    CITYNAME_HEB: "\u05d4\u05d9\u05dc\u05d1\u05d5\u05e8\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZNK",
    AIRPORTNAME: null,
    CITYCODE: "ZNK",
    CITYNAME: "HERFORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNL",
    AIRPORTNAME: null,
    CITYCODE: "ZNL",
    CITYNAME: "HERNE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVE",
    AIRPORTNAME: "Laroche",
    CITYCODE: "BVE",
    CITYNAME: "BRIVE LA GAILLARD",
    CITYNAME_HEB: "BRIVE LA GAILLARD",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "ZNM",
    AIRPORTNAME: null,
    CITYCODE: "ZNM",
    CITYNAME: "HERTEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNN",
    AIRPORTNAME: null,
    CITYCODE: "ZNN",
    CITYNAME: "HILDEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNO",
    AIRPORTNAME: null,
    CITYCODE: "ZNO",
    CITYNAME: "HILDESHEIM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNP",
    AIRPORTNAME: null,
    CITYCODE: "ZNP",
    CITYNAME: "HUERTH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNQ",
    AIRPORTNAME: null,
    CITYCODE: "ZNQ",
    CITYNAME: "INGOLSTADT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNR",
    AIRPORTNAME: null,
    CITYCODE: "ZNR",
    CITYNAME: "ISERLOHN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNS",
    AIRPORTNAME: null,
    CITYCODE: "ZNS",
    CITYNAME: "KEMPTEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNT",
    AIRPORTNAME: null,
    CITYCODE: "ZNT",
    CITYNAME: "KERPEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNU",
    AIRPORTNAME: "Namu",
    CITYCODE: "ZNU",
    CITYNAME: "NAMU",
    CITYNAME_HEB: "NAMU",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZNV",
    AIRPORTNAME: " ",
    CITYCODE: "ZNV",
    CITYNAME: "KOBLENZ",
    CITYNAME_HEB: "\u05e7\u05d5\u05d1\u05dc\u05e0\u05e5",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AFI",
    AIRPORTNAME: " ",
    CITYCODE: "AFI",
    CITYNAME: "AMALFI",
    CITYNAME_HEB: "\u05d0\u05de\u05d0\u05dc\u05e4\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BVF",
    AIRPORTNAME: "Dama",
    CITYCODE: "BVF",
    CITYNAME: "BUA",
    CITYNAME_HEB: "BUA",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "ZNW",
    AIRPORTNAME: null,
    CITYCODE: "ZNW",
    CITYNAME: "LIMBURG",
    CITYNAME_HEB: "\u05dc\u05d9\u05de\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZNX",
    AIRPORTNAME: null,
    CITYCODE: "ZNX",
    CITYNAME: "LAGENFELD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNY",
    AIRPORTNAME: null,
    CITYCODE: "ZNY",
    CITYNAME: "LANGENHAGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZNZ",
    AIRPORTNAME: "Kisauni",
    CITYCODE: "ZNZ",
    CITYNAME: "ZANZIBAR",
    CITYNAME_HEB: "\u05d6\u05e0\u05d6\u05d9\u05d1\u05e8",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZOA",
    AIRPORTNAME: " ",
    CITYCODE: "ZOA",
    CITYNAME: "LEVERKUSEN",
    CITYNAME_HEB: "\u05dc\u05d1\u05e8\u05e7\u05d5\u05d6\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZOB",
    AIRPORTNAME: null,
    CITYCODE: "ZOB",
    CITYNAME: "LIPPSTADT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOC",
    AIRPORTNAME: null,
    CITYCODE: "ZOC",
    CITYNAME: "LUEDENSCHEID",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOD",
    AIRPORTNAME: " ",
    CITYCODE: "ZOD",
    CITYNAME: "LUDWIGSBURG",
    CITYNAME_HEB:
      "\u05dc\u05d5\u05d3\u05d5\u05d5\u05d9\u05d2\u05e1\u05d1\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZOE",
    AIRPORTNAME: null,
    CITYCODE: "ZOE",
    CITYNAME: "LUDWIGSHAFEN",
    CITYNAME_HEB:
      "\u05dc\u05d5\u05d3\u05d5\u05d5\u05d9\u05d2\u05e1\u05d4\u05d0\u05e4\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZOF",
    AIRPORTNAME: "Ocean Falls",
    CITYCODE: "ZOF",
    CITYNAME: "OCEAN FALLS",
    CITYNAME_HEB: "OCEAN FALLS",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BVG",
    AIRPORTNAME: "Berlevag",
    CITYCODE: "BVG",
    CITYNAME: "BERLEVAG",
    CITYNAME_HEB: "BERLEVAG",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZOG",
    AIRPORTNAME: null,
    CITYCODE: "ZOG",
    CITYNAME: "LUENEBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOH",
    AIRPORTNAME: null,
    CITYCODE: "ZOH",
    CITYNAME: "LUENEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOI",
    AIRPORTNAME: null,
    CITYCODE: "ZOI",
    CITYNAME: "MARBURG AN DER LAHN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOJ",
    AIRPORTNAME: null,
    CITYCODE: "ZOJ",
    CITYNAME: "MARL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOK",
    AIRPORTNAME: null,
    CITYCODE: "ZOK",
    CITYNAME: "MEERBUSCH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOL",
    AIRPORTNAME: null,
    CITYCODE: "ZOL",
    CITYNAME: "MENDEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOM",
    AIRPORTNAME: null,
    CITYCODE: "ZOM",
    CITYNAME: "MINDEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZON",
    AIRPORTNAME: null,
    CITYCODE: "ZON",
    CITYNAME: "MOERS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOO",
    AIRPORTNAME: null,
    CITYCODE: "ZOO",
    CITYNAME: "MUELHEIM AN DER RUHR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOP",
    AIRPORTNAME: null,
    CITYCODE: "ZOP",
    CITYNAME: "NEUNKIRCHEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVH",
    AIRPORTNAME: null,
    CITYCODE: "BVH",
    CITYNAME: "VILHENA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOQ",
    AIRPORTNAME: null,
    CITYCODE: "ZOQ",
    CITYNAME: "NEUSS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOR",
    AIRPORTNAME: null,
    CITYCODE: "ZOR",
    CITYNAME: "NEUSTADT WEINSTRASSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOS",
    AIRPORTNAME: "Canal Balo",
    CITYCODE: "ZOS",
    CITYNAME: "OSORNO",
    CITYNAME_HEB: "OSORNO",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "ZOT",
    AIRPORTNAME: null,
    CITYCODE: "ZOT",
    CITYNAME: "NEU ULM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOU",
    AIRPORTNAME: null,
    CITYCODE: "ZOU",
    CITYNAME: "NEUWIED",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOV",
    AIRPORTNAME: null,
    CITYCODE: "ZOV",
    CITYNAME: "NORDERSTEDT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOW",
    AIRPORTNAME: null,
    CITYCODE: "ZOW",
    CITYNAME: "NORDHORN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOX",
    AIRPORTNAME: null,
    CITYCODE: "ZOX",
    CITYNAME: "OBERAMMERGAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOY",
    AIRPORTNAME: null,
    CITYCODE: "ZOY",
    CITYNAME: "OBERHAUSEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZOZ",
    AIRPORTNAME: null,
    CITYCODE: "ZOZ",
    CITYNAME: "OFFENBACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVI",
    AIRPORTNAME: "Birdsville",
    CITYCODE: "BVI",
    CITYNAME: "BIRDSVILLE",
    CITYNAME_HEB: "BIRDSVILLE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZPA",
    AIRPORTNAME: null,
    CITYCODE: "ZPA",
    CITYNAME: "OFFENBURG",
    CITYNAME_HEB: "\u05d0\u05d5\u05e4\u05e0\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZPB",
    AIRPORTNAME: null,
    CITYCODE: "ZPB",
    CITYNAME: "SACHIGO LAKE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPC",
    AIRPORTNAME: "Pucon",
    CITYCODE: "ZPC",
    CITYNAME: "PUCON",
    CITYNAME_HEB: "\u05e4\u05d5\u05e7\u05d5\u05df",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6",
  },
  {
    AIRPORTCODE: "ZPD",
    AIRPORTNAME: null,
    CITYCODE: "ZPD",
    CITYNAME: "OLDENBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPE",
    AIRPORTNAME: null,
    CITYCODE: "ZPE",
    CITYNAME: "OSNABRUECK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPF",
    AIRPORTNAME: null,
    CITYCODE: "ZPF",
    CITYNAME: "PASSAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPG",
    AIRPORTNAME: null,
    CITYCODE: "ZPG",
    CITYNAME: "PEINE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPH",
    AIRPORTNAME: "Zephyrhills",
    CITYCODE: "ZPH",
    CITYNAME: "ZEPHYRHILLS",
    CITYNAME_HEB: "ZEPHYRHILLS",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZPI",
    AIRPORTNAME: null,
    CITYCODE: "ZPI",
    CITYNAME: "PIRMASENS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPJ",
    AIRPORTNAME: null,
    CITYCODE: "ZPJ",
    CITYNAME: "RATINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVK",
    AIRPORTNAME: "Huacaraje",
    CITYCODE: "BVK",
    CITYNAME: "HUACARAJE",
    CITYNAME_HEB: "HUACARAJE",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZPL",
    AIRPORTNAME: null,
    CITYCODE: "ZPL",
    CITYNAME: "RECKLINGHAUSEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPM",
    AIRPORTNAME: null,
    CITYCODE: "ZPM",
    CITYNAME: "REGENSBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPN",
    AIRPORTNAME: null,
    CITYCODE: "ZPN",
    CITYNAME: "REMSCHEID",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPO",
    AIRPORTNAME: null,
    CITYCODE: "ZPO",
    CITYNAME: "PINE HOUSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPP",
    AIRPORTNAME: null,
    CITYCODE: "ZPP",
    CITYNAME: "REUTLINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPQ",
    AIRPORTNAME: null,
    CITYCODE: "ZPQ",
    CITYNAME: "RHEINE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPR",
    AIRPORTNAME: null,
    CITYCODE: "ZPR",
    CITYNAME: "ROSENHEIM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPS",
    AIRPORTNAME: null,
    CITYCODE: "ZPS",
    CITYNAME: "RUESSELSHEIM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZPT",
    AIRPORTNAME: null,
    CITYCODE: "ZPT",
    CITYNAME: "SAARLOUIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BVL",
    AIRPORTNAME: "Baures",
    CITYCODE: "BVL",
    CITYNAME: "BAURES",
    CITYNAME_HEB: "BAURES",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BEY",
    AIRPORTNAME: "International",
    CITYCODE: "BEY",
    CITYNAME: "BEIRUT",
    CITYNAME_HEB: "\u05d1\u05d9\u05d9\u05e8\u05d5\u05ea",
    COUNTRYCODE: "LB",
    COUNTRYNAME: "LEBANON",
    COUNTRYNAME_HEB: "\u05dc\u05d1\u05e0\u05d5\u05df",
  },
  {
    AIRPORTCODE: "QCR",
    AIRPORTNAME: null,
    CITYCODE: "QCR",
    CITYNAME: "CURITIBANOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCS",
    AIRPORTNAME: null,
    CITYCODE: "QCS",
    CITYNAME: "COSENZA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCT",
    AIRPORTNAME: null,
    CITYCODE: "QCT",
    CITYNAME: "BACITA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCU",
    AIRPORTNAME: null,
    CITYCODE: "QCU",
    CITYNAME: "AKUNNAAQ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCV",
    AIRPORTNAME: null,
    CITYCODE: "QCV",
    CITYNAME: "GUARULHOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCW",
    AIRPORTNAME: null,
    CITYCODE: "QCW",
    CITYNAME: "WILTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCX",
    AIRPORTNAME: null,
    CITYCODE: "QCX",
    CITYNAME: "SAO CAETANO DO SUL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCY",
    AIRPORTNAME: null,
    CITYCODE: "QCY",
    CITYNAME: "CONINGSBY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCZ",
    AIRPORTNAME: null,
    CITYCODE: "QCZ",
    CITYNAME: "CATANZARO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDA",
    AIRPORTNAME: null,
    CITYCODE: "QDA",
    CITYNAME: "CHARQUEADA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BEZ",
    AIRPORTNAME: "Beru",
    CITYCODE: "BEZ",
    CITYNAME: "BERU",
    CITYNAME_HEB: "BERU",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "QDB",
    AIRPORTNAME: null,
    CITYCODE: "QDB",
    CITYNAME: "CACHOEIRA DO SUL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDC",
    AIRPORTNAME: null,
    CITYCODE: "QDC",
    CITYNAME: "DRACENA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDD",
    AIRPORTNAME: " ",
    CITYCODE: "QDD",
    CITYNAME: "BOTOSANI",
    CITYNAME_HEB: "\u05d1\u05d5\u05e1\u05d8\u05d0\u05e0\u05d9",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QDE",
    AIRPORTNAME: null,
    CITYCODE: "QDE",
    CITYNAME: "CATANDUVA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDF",
    AIRPORTNAME: null,
    CITYCODE: "QDF",
    CITYNAME: "CONSELHEIRO LAFAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDG",
    AIRPORTNAME: null,
    CITYCODE: "QDG",
    CITYNAME: "OSTROW WIEKLOPOSK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDH",
    AIRPORTNAME: null,
    CITYCODE: "QDH",
    CITYNAME: "ASHFORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDI",
    AIRPORTNAME: " ",
    CITYCODE: "QDI",
    CITYNAME: "DORNBIRN",
    CITYNAME_HEB: "\u05d3\u05d5\u05e8\u05d1\u05e8\u05d9\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QDJ",
    AIRPORTNAME: null,
    CITYCODE: "QDJ",
    CITYNAME: "DJELFA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDM",
    AIRPORTNAME: "Shek Mum",
    CITYCODE: "QDM",
    CITYNAME: "SHEK MUM",
    CITYNAME_HEB: "SHEK MUM",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "BFB",
    AIRPORTNAME: "Blue Fox Bay",
    CITYCODE: "BFB",
    CITYNAME: "BLUE FOX BAY",
    CITYNAME_HEB: "Blue Fox Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QDN",
    AIRPORTNAME: null,
    CITYCODE: "QDN",
    CITYNAME: "EDEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDO",
    AIRPORTNAME: null,
    CITYCODE: "QDO",
    CITYNAME: "ICOARACI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDP",
    AIRPORTNAME: null,
    CITYCODE: "QDP",
    CITYNAME: "DOM PEDRITO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDQ",
    AIRPORTNAME: null,
    CITYCODE: "QDQ",
    CITYNAME: "DUQUE DE CAXIAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDR",
    AIRPORTNAME: null,
    CITYCODE: "QDR",
    CITYNAME: "DERA'A",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SY",
    COUNTRYNAME: "SYRIAN ARAB REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDS",
    AIRPORTNAME: null,
    CITYCODE: "QDS",
    CITYNAME: "ITAJUBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDT",
    AIRPORTNAME: null,
    CITYCODE: "QDT",
    CITYNAME: "SEDRATA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDV",
    AIRPORTNAME: null,
    CITYCODE: "QDV",
    CITYNAME: "JUNDIAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDW",
    AIRPORTNAME: null,
    CITYCODE: "QDW",
    CITYNAME: "DIADEMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QDY",
    AIRPORTNAME: null,
    CITYCODE: "QDY",
    CITYNAME: "ANDONG",
    CITYNAME_HEB: "\u05d0\u05e0\u05d3\u05d5\u05e0\u05d2",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFC",
    AIRPORTNAME: "Bloomfield",
    CITYCODE: "BFC",
    CITYNAME: "BLOOMFIELD",
    CITYNAME_HEB: "BLOOMFIELD",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QDZ",
    AIRPORTNAME: null,
    CITYCODE: "QDZ",
    CITYNAME: "SAIDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEA",
    AIRPORTNAME: null,
    CITYCODE: "QEA",
    CITYNAME: "TERAMO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEB",
    AIRPORTNAME: null,
    CITYCODE: "QEB",
    CITYNAME: "MAEBASHI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEC",
    AIRPORTNAME: null,
    CITYCODE: "QEC",
    CITYNAME: "ELMARJ CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QED",
    AIRPORTNAME: null,
    CITYCODE: "QED",
    CITYNAME: "MEDEA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEE",
    AIRPORTNAME: null,
    CITYCODE: "QEE",
    CITYNAME: "EBEYE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEF",
    AIRPORTNAME: null,
    CITYCODE: "QEF",
    CITYNAME: "EGELSBACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEG",
    AIRPORTNAME: null,
    CITYCODE: "QEG",
    CITYNAME: "RIBEIRA GRANDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEH",
    AIRPORTNAME: null,
    CITYCODE: "QEH",
    CITYNAME: "EL HADJAR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEI",
    AIRPORTNAME: null,
    CITYCODE: "QEI",
    CITYNAME: "CRAILSHEIM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFD",
    AIRPORTNAME: "Bradford",
    CITYCODE: "BFD",
    CITYNAME: "BRADFORD",
    CITYNAME_HEB: "BRADFORD",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QEJ",
    AIRPORTNAME: null,
    CITYCODE: "QEJ",
    CITYNAME: "ELGARHBOLLI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEL",
    AIRPORTNAME: null,
    CITYCODE: "QEL",
    CITYNAME: "WELLINGTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEO",
    AIRPORTNAME: null,
    CITYCODE: "QEO",
    CITYNAME: "BIELSKO BIALA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEP",
    AIRPORTNAME: null,
    CITYCODE: "QEP",
    CITYNAME: "TARNOBRZEG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEQ",
    AIRPORTNAME: null,
    CITYCODE: "QEQ",
    CITYNAME: "EMBRACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QER",
    AIRPORTNAME: null,
    CITYCODE: "QER",
    CITYNAME: "SHEHR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QET",
    AIRPORTNAME: null,
    CITYCODE: "QET",
    CITYNAME: "TAEDOK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEU",
    AIRPORTNAME: null,
    CITYCODE: "QEU",
    CITYNAME: "PARNAMIRIM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEV",
    AIRPORTNAME: null,
    CITYCODE: "QEV",
    CITYNAME: "COURBEVOIE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEW",
    AIRPORTNAME: null,
    CITYCODE: "QEW",
    CITYNAME: "LEICESTER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFE",
    AIRPORTNAME: "Bielefeld",
    CITYCODE: "BFE",
    CITYNAME: "BIELEFELD",
    CITYNAME_HEB: "BIELEFELD",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QEX",
    AIRPORTNAME: null,
    CITYCODE: "QEX",
    CITYNAME: "EMMERICH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEY",
    AIRPORTNAME: null,
    CITYCODE: "QEY",
    CITYNAME: "QEQERTARSUATSIAAT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QEZ",
    AIRPORTNAME: null,
    CITYCODE: "QEZ",
    CITYNAME: "POMEZIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFA",
    AIRPORTNAME: null,
    CITYCODE: "QFA",
    CITYNAME: "AALSMEER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFB",
    AIRPORTNAME: " ",
    CITYCODE: "QFB",
    CITYNAME: "FREIBURG",
    CITYNAME_HEB: "\u05e4\u05e8\u05d9\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QFC",
    AIRPORTNAME: null,
    CITYCODE: "QFC",
    CITYNAME: "CRETEIL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFD",
    AIRPORTNAME: null,
    CITYCODE: "QFD",
    CITYNAME: "BOUFARIK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFE",
    AIRPORTNAME: null,
    CITYCODE: "QFE",
    CITYNAME: "FORT  BENNING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFF",
    AIRPORTNAME: null,
    CITYCODE: "QFF",
    CITYNAME: "BROOKLYN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFG",
    AIRPORTNAME: null,
    CITYCODE: "QFG",
    CITYNAME: "EQALUGAARSUIT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFF",
    AIRPORTNAME: "Scotts Bluff County",
    CITYCODE: "BFF",
    CITYNAME: "SCOTTSBLUFF",
    CITYNAME_HEB: "SCOTTSBLUFF",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QFH",
    AIRPORTNAME: null,
    CITYCODE: "QFH",
    CITYNAME: "FREDERIKSHAVN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFI",
    AIRPORTNAME: null,
    CITYCODE: "QFI",
    CITYNAME: "IGINNIARFIK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFJ",
    AIRPORTNAME: null,
    CITYCODE: "QFJ",
    CITYNAME: "ALLUITSUP",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFK",
    AIRPORTNAME: null,
    CITYCODE: "QFK",
    CITYNAME: "SELJE HARBOUR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFL",
    AIRPORTNAME: " ",
    CITYCODE: "QFL",
    CITYNAME: "FREILASSING",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QFN",
    AIRPORTNAME: null,
    CITYCODE: "QFN",
    CITYNAME: "NARSAQ KUJALLEQ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFO",
    AIRPORTNAME: null,
    CITYCODE: "QFO",
    CITYNAME: "DUXFORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFQ",
    AIRPORTNAME: null,
    CITYCODE: "QFQ",
    CITYNAME: "MALOY HARBOUR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFR",
    AIRPORTNAME: null,
    CITYCODE: "QFR",
    CITYNAME: "FROSINONE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFS",
    AIRPORTNAME: null,
    CITYCODE: "QFS",
    CITYNAME: "SAO FRANCISCO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFG",
    AIRPORTNAME: "Bullfrog Basin",
    CITYCODE: "BFG",
    CITYNAME: "BULLFROG BASIN",
    CITYNAME_HEB: "BULLFROG BASIN",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QFT",
    AIRPORTNAME: null,
    CITYCODE: "QFT",
    CITYNAME: "QASSIARSUK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFU",
    AIRPORTNAME: null,
    CITYCODE: "QFU",
    CITYNAME: "CORRALEJO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFV",
    AIRPORTNAME: null,
    CITYCODE: "QFV",
    CITYNAME: "BERGEN HARBOUR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFW",
    AIRPORTNAME: null,
    CITYCODE: "QFW",
    CITYNAME: "FORT WASHINGTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFX",
    AIRPORTNAME: null,
    CITYCODE: "QFX",
    CITYNAME: "IGALIKU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QFY",
    AIRPORTNAME: null,
    CITYCODE: "QFY",
    CITYNAME: "FUKUYAMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGA",
    AIRPORTNAME: null,
    CITYCODE: "QGA",
    CITYNAME: "GUAIRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGB",
    AIRPORTNAME: null,
    CITYCODE: "QGB",
    CITYNAME: "LIMEIRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGC",
    AIRPORTNAME: null,
    CITYCODE: "QGC",
    CITYNAME: "LENCOIS PAULISTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFJ",
    AIRPORTNAME: "Ba",
    CITYCODE: "BFJ",
    CITYNAME: "BA",
    CITYNAME_HEB: "BA",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "QGE",
    AIRPORTNAME: null,
    CITYCODE: "QGE",
    CITYNAME: "GUELMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGF",
    AIRPORTNAME: null,
    CITYCODE: "QGF",
    CITYNAME: "MONTE NEGRO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGG",
    AIRPORTNAME: null,
    CITYCODE: "QGG",
    CITYNAME: "AGEDABIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGH",
    AIRPORTNAME: null,
    CITYCODE: "QGH",
    CITYNAME: "GHERIAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGI",
    AIRPORTNAME: null,
    CITYCODE: "QGI",
    CITYNAME: "ILHA DO GOVERNADOR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGJ",
    AIRPORTNAME: null,
    CITYCODE: "QGJ",
    CITYNAME: "NOVA FRIBURGO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGK",
    AIRPORTNAME: null,
    CITYCODE: "QGK",
    CITYNAME: "PALMARES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGL",
    AIRPORTNAME: null,
    CITYCODE: "QGL",
    CITYNAME: "SAINT GALLEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGM",
    AIRPORTNAME: null,
    CITYCODE: "QGM",
    CITYNAME: "CHELGHOUM LAID",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGN",
    AIRPORTNAME: null,
    CITYCODE: "QGN",
    CITYNAME: "TARRAGONA",
    CITYNAME_HEB: "\u05d8\u05e8\u05d2\u05d5\u05e0\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "BFL",
    AIRPORTNAME: "Meadows Field",
    CITYCODE: "BFL",
    CITYNAME: "BAKERSFIELD",
    CITYNAME_HEB:
      "\u05d1\u05d9\u05d9\u05e7\u05e8\u05e1\u05e4\u05d9\u05dc\u05d3",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "QGO",
    AIRPORTNAME: null,
    CITYCODE: "QGO",
    CITYNAME: "GORIZIA",
    CITYNAME_HEB: "\u05d2\u05d5\u05e8\u05d9\u05d6\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QGP",
    AIRPORTNAME: null,
    CITYCODE: "QGP",
    CITYNAME: "GARANHUNS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGQ",
    AIRPORTNAME: " ",
    CITYCODE: "QGQ",
    CITYNAME: "ATTU",
    CITYNAME_HEB: "\u05d0\u05d8\u05d8\u05d5",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d7\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "QGR",
    AIRPORTNAME: null,
    CITYCODE: "QGR",
    CITYNAME: "KANGERLUK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGS",
    AIRPORTNAME: null,
    CITYCODE: "QGS",
    CITYNAME: "ALAGOINHAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGT",
    AIRPORTNAME: null,
    CITYCODE: "QGT",
    CITYNAME: "MORIGUCHI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGU",
    AIRPORTNAME: null,
    CITYCODE: "QGU",
    CITYNAME: "GIFU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGY",
    AIRPORTNAME: null,
    CITYCODE: "QGY",
    CITYNAME: "GYOR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QGZ",
    AIRPORTNAME: null,
    CITYCODE: "QGZ",
    CITYNAME: "YOKKAICHI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHA",
    AIRPORTNAME: " ",
    CITYCODE: "QHA",
    CITYNAME: "HASSELT",
    CITYNAME_HEB: "\u05d4\u05d0\u05e1\u05dc\u05d8",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ADC",
    AIRPORTNAME: "Andakombe",
    CITYCODE: "ADC",
    CITYNAME: "ANDAKOMBE",
    CITYNAME_HEB: "ANDAKOMBE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BFN",
    AIRPORTNAME: "Bloemfontein International",
    CITYCODE: "BFN",
    CITYNAME: "BLOEMFONTEIN",
    CITYNAME_HEB: "BLOEMFONTEIN",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "QHB",
    AIRPORTNAME: null,
    CITYCODE: "QHB",
    CITYNAME: "PIRACICABA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHC",
    AIRPORTNAME: null,
    CITYCODE: "QHC",
    CITYNAME: "ROLANDIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHD",
    AIRPORTNAME: null,
    CITYCODE: "QHD",
    CITYNAME: "HAHNWEIDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHE",
    AIRPORTNAME: null,
    CITYCODE: "QHE",
    CITYNAME: "SAO BENTO DO SUL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHF",
    AIRPORTNAME: null,
    CITYCODE: "QHF",
    CITYNAME: "SAO SEBASTIAO DO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHG",
    AIRPORTNAME: null,
    CITYCODE: "QHG",
    CITYNAME: "SETE LAGOAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHH",
    AIRPORTNAME: null,
    CITYCODE: "QHH",
    CITYNAME: "EL HARROUCHE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHI",
    AIRPORTNAME: null,
    CITYCODE: "QHI",
    CITYNAME: "CHONBURI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHJ",
    AIRPORTNAME: null,
    CITYCODE: "QHJ",
    CITYNAME: "HJOERRING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHL",
    AIRPORTNAME: null,
    CITYCODE: "QHL",
    CITYNAME: "CASTANHAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFO",
    AIRPORTNAME: "Buffalo Range",
    CITYCODE: "BFO",
    CITYNAME: "BUFFALO RANGE",
    CITYNAME_HEB: "BUFFALO RANGE",
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: "\u05d6\u05d9\u05de\u05d1\u05d1\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "QHM",
    AIRPORTNAME: null,
    CITYCODE: "QHM",
    CITYNAME: "HAMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SY",
    COUNTRYNAME: "SYRIAN ARAB REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHN",
    AIRPORTNAME: null,
    CITYCODE: "QHN",
    CITYNAME: "TAGUATINGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHO",
    AIRPORTNAME: null,
    CITYCODE: "QHO",
    CITYNAME: "OAK BROOK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHP",
    AIRPORTNAME: null,
    CITYCODE: "QHP",
    CITYNAME: "TAUBATE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHQ",
    AIRPORTNAME: null,
    CITYCODE: "QHQ",
    CITYNAME: "CHAM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHR",
    AIRPORTNAME: null,
    CITYCODE: "QHR",
    CITYNAME: "HARAR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHS",
    AIRPORTNAME: null,
    CITYCODE: "QHS",
    CITYNAME: "HOMS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SY",
    COUNTRYNAME: "SYRIAN ARAB REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHT",
    AIRPORTNAME: null,
    CITYCODE: "QHT",
    CITYNAME: "TEREZOPOLIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHU",
    AIRPORTNAME: null,
    CITYCODE: "QHU",
    CITYNAME: "HUSUM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHV",
    AIRPORTNAME: null,
    CITYCODE: "QHV",
    CITYNAME: "NOVO HAMBURGO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFP",
    AIRPORTNAME: "Beaver Falls",
    CITYCODE: "BFP",
    CITYNAME: "BEAVER FALLS",
    CITYNAME_HEB: "BEAVER FALLS",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QHY",
    AIRPORTNAME: null,
    CITYCODE: "QHY",
    CITYNAME: "HACHIOJI CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QHZ",
    AIRPORTNAME: null,
    CITYCODE: "QHZ",
    CITYNAME: "HOOFDDORP",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIA",
    AIRPORTNAME: null,
    CITYCODE: "QIA",
    CITYNAME: "ITAUNA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIB",
    AIRPORTNAME: null,
    CITYCODE: "QIB",
    CITYNAME: "IBIRUBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIC",
    AIRPORTNAME: null,
    CITYCODE: "QIC",
    CITYNAME: "SIRACUSA",
    CITYNAME_HEB: "\u05e1\u05d9\u05e8\u05e7\u05d5\u05d6\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QID",
    AIRPORTNAME: null,
    CITYCODE: "QID",
    CITYNAME: "TRES CORACOES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIE",
    AIRPORTNAME: null,
    CITYCODE: "QIE",
    CITYNAME: "ISTRES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIF",
    AIRPORTNAME: null,
    CITYCODE: "QIF",
    CITYNAME: "ISLEWORTH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIG",
    AIRPORTNAME: null,
    CITYCODE: "QIG",
    CITYNAME: "IGUATU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFQ",
    AIRPORTNAME: "Bahia Pinas",
    CITYCODE: "BFQ",
    CITYNAME: "BAHIA PINAS",
    CITYNAME_HEB: "BAHIA PINAS",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "QIH",
    AIRPORTNAME: null,
    CITYCODE: "QIH",
    CITYNAME: "TRES RIOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QII",
    AIRPORTNAME: null,
    CITYCODE: "QII",
    CITYNAME: "LINDAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIJ",
    AIRPORTNAME: null,
    CITYCODE: "QIJ",
    CITYNAME: "GIJON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIK",
    AIRPORTNAME: null,
    CITYCODE: "QIK",
    CITYNAME: "IKOYI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIL",
    AIRPORTNAME: null,
    CITYCODE: "QIL",
    CITYNAME: "SIG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIM",
    AIRPORTNAME: null,
    CITYCODE: "QIM",
    CITYNAME: "AIN MLILA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIN",
    AIRPORTNAME: null,
    CITYCODE: "QIN",
    CITYNAME: "MERSIN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIO",
    AIRPORTNAME: null,
    CITYCODE: "QIO",
    CITYNAME: "AIN TEMOUCHENT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIP",
    AIRPORTNAME: null,
    CITYCODE: "QIP",
    CITYNAME: "SIMBACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIQ",
    AIRPORTNAME: null,
    CITYCODE: "QIQ",
    CITYNAME: "RIO CLARO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFR",
    AIRPORTNAME: "Virgil I Grissom Muni",
    CITYCODE: "BFR",
    CITYNAME: "BEDFORD",
    CITYNAME_HEB: "BEDFORD",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QIR",
    AIRPORTNAME: null,
    CITYCODE: "QIR",
    CITYNAME: "IRBID",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JO",
    COUNTRYNAME: "JORDAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIS",
    AIRPORTNAME: null,
    CITYCODE: "QIS",
    CITYNAME: "MITO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIT",
    AIRPORTNAME: null,
    CITYCODE: "QIT",
    CITYNAME: "ITAPETINGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIU",
    AIRPORTNAME: null,
    CITYCODE: "QIU",
    CITYNAME: "CIUDADELA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIW",
    AIRPORTNAME: null,
    CITYCODE: "QIW",
    CITYNAME: "UMM ALQUWAIN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AE",
    COUNTRYNAME: "UNITED ARAB EMIRATES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIX",
    AIRPORTNAME: null,
    CITYCODE: "QIX",
    CITYNAME: "QUIXADA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIY",
    AIRPORTNAME: null,
    CITYCODE: "QIY",
    CITYNAME: "BEDARRA ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QIZ",
    AIRPORTNAME: null,
    CITYCODE: "QIZ",
    CITYNAME: "BIZERTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TN",
    COUNTRYNAME: "TUNISIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJA",
    AIRPORTNAME: null,
    CITYCODE: "QJA",
    CITYNAME: "JARAGUA DO SUL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJC",
    AIRPORTNAME: null,
    CITYCODE: "QJC",
    CITYNAME: "THIMBU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BT",
    COUNTRYNAME: "BHUTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFS",
    AIRPORTNAME: "Belfast International Airport",
    CITYCODE: "BFS",
    CITYNAME: "BELFAST",
    CITYNAME_HEB: "\u05d1\u05dc\u05e4\u05e1\u05d8",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "QJD",
    AIRPORTNAME: null,
    CITYCODE: "QJD",
    CITYNAME: "JINDABYNE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJE",
    AIRPORTNAME: null,
    CITYCODE: "QJE",
    CITYNAME: "KITSISSUARSUIT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJF",
    AIRPORTNAME: " ",
    CITYCODE: "QJF",
    CITYNAME: "ATAMMIK",
    CITYNAME_HEB: "\u05d0\u05ea\u05de\u05d9\u05e7",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "QJG",
    AIRPORTNAME: null,
    CITYCODE: "QJG",
    CITYNAME: "ITILLEQ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJH",
    AIRPORTNAME: null,
    CITYCODE: "QJH",
    CITYNAME: "QASSIMIUT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJI",
    AIRPORTNAME: null,
    CITYCODE: "QJI",
    CITYNAME: "IKAMIUT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJK",
    AIRPORTNAME: null,
    CITYCODE: "QJK",
    CITYNAME: "AJAOKUTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJL",
    AIRPORTNAME: null,
    CITYCODE: "QJL",
    CITYNAME: "KJOELLEFJORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJM",
    AIRPORTNAME: null,
    CITYCODE: "QJM",
    CITYNAME: "BRUSQUE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJN",
    AIRPORTNAME: null,
    CITYCODE: "QJN",
    CITYNAME: "JOUNIEH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LB",
    COUNTRYNAME: "LEBANON",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJO",
    AIRPORTNAME: null,
    CITYCODE: "QJO",
    CITYNAME: "CAMPOS DO JORDAO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJP",
    AIRPORTNAME: null,
    CITYCODE: "QJP",
    CITYNAME: "BUCHEON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJQ",
    AIRPORTNAME: null,
    CITYCODE: "QJQ",
    CITYNAME: "JAL EDIB",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LB",
    COUNTRYNAME: "LEBANON",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJR",
    AIRPORTNAME: null,
    CITYCODE: "QJR",
    CITYNAME: "LA JUNQUERA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJS",
    AIRPORTNAME: null,
    CITYCODE: "QJS",
    CITYNAME: "SAEBY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJT",
    AIRPORTNAME: null,
    CITYCODE: "QJT",
    CITYNAME: "NAPASOQ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJU",
    AIRPORTNAME: null,
    CITYCODE: "QJU",
    CITYNAME: "JULLUNDUR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJV",
    AIRPORTNAME: null,
    CITYCODE: "QJV",
    CITYNAME: "SKAGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJW",
    AIRPORTNAME: null,
    CITYCODE: "QJW",
    CITYNAME: "KJELLERUP",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QJX",
    AIRPORTNAME: null,
    CITYCODE: "QJX",
    CITYNAME: "NONG KHAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFT",
    AIRPORTNAME: "County",
    CITYCODE: "BFT",
    CITYNAME: "BEAUFORT",
    CITYNAME_HEB: "BEAUFORT",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QJY",
    AIRPORTNAME: null,
    CITYCODE: "QJY",
    CITYNAME: "KOLOBRZEG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKA",
    AIRPORTNAME: null,
    CITYCODE: "QKA",
    CITYNAME: "CACHOEIRINHA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKB",
    AIRPORTNAME: null,
    CITYCODE: "QKB",
    CITYNAME: "BRECKENRIDGE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKD",
    AIRPORTNAME: null,
    CITYCODE: "QKD",
    CITYNAME: "ELK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKE",
    AIRPORTNAME: null,
    CITYCODE: "QKE",
    CITYNAME: "KABWE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKF",
    AIRPORTNAME: null,
    CITYCODE: "QKF",
    CITYNAME: "KREFELD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKG",
    AIRPORTNAME: null,
    CITYCODE: "QKG",
    CITYNAME: "CHALKIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKH",
    AIRPORTNAME: null,
    CITYCODE: "QKH",
    CITYNAME: "KHARIAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKI",
    AIRPORTNAME: null,
    CITYCODE: "QKI",
    CITYNAME: "KIELCE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKJ",
    AIRPORTNAME: null,
    CITYCODE: "QKJ",
    CITYNAME: "KHENCHELA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFU",
    AIRPORTNAME: "Bengbu",
    CITYCODE: "BFU",
    CITYNAME: "BENGBU",
    CITYNAME_HEB: "BENGBU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "QKK",
    AIRPORTNAME: null,
    CITYCODE: "QKK",
    CITYNAME: "KARASJOK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKM",
    AIRPORTNAME: null,
    CITYCODE: "QKM",
    CITYNAME: "GUMI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKN",
    AIRPORTNAME: null,
    CITYCODE: "QKN",
    CITYNAME: "KAIROUAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TN",
    COUNTRYNAME: "TUNISIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKO",
    AIRPORTNAME: null,
    CITYCODE: "QKO",
    CITYNAME: "KHOMS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKP",
    AIRPORTNAME: null,
    CITYCODE: "QKP",
    CITYNAME: "KRUGER NATIONAL P",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKQ",
    AIRPORTNAME: null,
    CITYCODE: "QKQ",
    CITYNAME: "ANKLAM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKR",
    AIRPORTNAME: null,
    CITYCODE: "QKR",
    CITYNAME: "KOUROU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GF",
    COUNTRYNAME: "FRENCH GUIANA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKS",
    AIRPORTNAME: null,
    CITYCODE: "QKS",
    CITYNAME: "KEYSTONE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKT",
    AIRPORTNAME: null,
    CITYCODE: "QKT",
    CITYNAME: "KANGAAMIUT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKV",
    AIRPORTNAME: null,
    CITYCODE: "QKV",
    CITYNAME: "SAKAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BFV",
    AIRPORTNAME: "Buri Ram",
    CITYCODE: "BFV",
    CITYNAME: "BURI RAM",
    CITYNAME_HEB: "BURI RAM",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "QKW",
    AIRPORTNAME: " ",
    CITYCODE: "QKW",
    CITYNAME: "KANAZAWA",
    CITYNAME_HEB: "\u05e7\u05e0\u05d0\u05d6\u05d5\u05d5\u05d4",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "QKX",
    AIRPORTNAME: null,
    CITYCODE: "QKX",
    CITYNAME: "KAUTOKEINO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKY",
    AIRPORTNAME: null,
    CITYCODE: "QKY",
    CITYNAME: "WAKAYAMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QKZ",
    AIRPORTNAME: " ",
    CITYCODE: "QKZ",
    CITYNAME: "KONSTANZ",
    CITYNAME_HEB: "\u05e7\u05d5\u05e0\u05e1\u05d8\u05d0\u05e0\u05e5",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QLA",
    AIRPORTNAME: null,
    CITYCODE: "QLA",
    CITYNAME: "LASHAM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLB",
    AIRPORTNAME: null,
    CITYCODE: "QLB",
    CITYNAME: "LAJEADO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLC",
    AIRPORTNAME: null,
    CITYCODE: "QLC",
    CITYNAME: "GLIWICE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLD",
    AIRPORTNAME: null,
    CITYCODE: "QLD",
    CITYNAME: "BLIDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLE",
    AIRPORTNAME: null,
    CITYCODE: "QLE",
    CITYNAME: "LEETON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLF",
    AIRPORTNAME: null,
    CITYCODE: "QLF",
    CITYNAME: "LAHTI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ADD",
    AIRPORTNAME: "Bole International Airport",
    CITYCODE: "ADD",
    CITYNAME: "ADDIS ABABA",
    CITYNAME_HEB: "\u05d0\u05d3\u05d9\u05e1 \u05d0\u05d1\u05d1\u05d4",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BFX",
    AIRPORTNAME: "Bafoussam",
    CITYCODE: "BFX",
    CITYNAME: "BAFOUSSAM",
    CITYNAME_HEB: "BAFOUSSAM",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "QLG",
    AIRPORTNAME: null,
    CITYCODE: "QLG",
    CITYNAME: "LANDSHUT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLH",
    AIRPORTNAME: null,
    CITYCODE: "QLH",
    CITYNAME: "KELSTERBACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLI",
    AIRPORTNAME: null,
    CITYCODE: "QLI",
    CITYNAME: "LIMASSOL",
    CITYNAME_HEB: "\u05dc\u05d9\u05de\u05e1\u05d5\u05dc",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "QLJ",
    AIRPORTNAME: " ",
    CITYCODE: "QLJ",
    CITYNAME: "LUZERN",
    CITYNAME_HEB: "\u05dc\u05d5\u05e6\u05e8\u05df",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "QLK",
    AIRPORTNAME: null,
    CITYCODE: "QLK",
    CITYNAME: "EL KALA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLL",
    AIRPORTNAME: null,
    CITYCODE: "QLL",
    CITYNAME: "SAO LEOPOLDO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLM",
    AIRPORTNAME: null,
    CITYCODE: "QLM",
    CITYNAME: "LA MUNOZA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLN",
    AIRPORTNAME: null,
    CITYCODE: "QLN",
    CITYNAME: "SULMONA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLO",
    AIRPORTNAME: null,
    CITYCODE: "QLO",
    CITYNAME: "LOERRACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLP",
    AIRPORTNAME: null,
    CITYCODE: "QLP",
    CITYNAME: "LA SPEZIA",
    CITYNAME_HEB: "\u05dc\u05d4 \u05e1\u05e4\u05e6\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BGA",
    AIRPORTNAME: "Palo Negro",
    CITYCODE: "BGA",
    CITYNAME: "BUCARAMANGA",
    CITYNAME_HEB: "BUCARAMANGA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QLQ",
    AIRPORTNAME: null,
    CITYCODE: "QLQ",
    CITYNAME: "LERIDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLR",
    AIRPORTNAME: null,
    CITYCODE: "QLR",
    CITYNAME: "LEIRIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLS",
    AIRPORTNAME: " ",
    CITYCODE: "QLS",
    CITYNAME: "LAUSANNE",
    CITYNAME_HEB: "\u05dc\u05d5\u05d6\u05df",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "QLT",
    AIRPORTNAME: null,
    CITYCODE: "QLT",
    CITYNAME: "LATINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLV",
    AIRPORTNAME: null,
    CITYCODE: "QLV",
    CITYNAME: "OLIVOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLW",
    AIRPORTNAME: null,
    CITYCODE: "QLW",
    CITYNAME: "LAVRAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QLX",
    AIRPORTNAME: "Lauterach",
    CITYCODE: "QLX",
    CITYNAME: "LAUTERACH",
    CITYNAME_HEB: "LAUTERACH",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QLY",
    AIRPORTNAME: " ",
    CITYCODE: "QLY",
    CITYNAME: "PLAYA BLANCA",
    CITYNAME_HEB:
      "\u05e4\u05dc\u05d0\u05d9\u05d4 \u05d1\u05dc\u05e0\u05e7\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "QLZ",
    AIRPORTNAME: null,
    CITYCODE: "QLZ",
    CITYNAME: "IKAST",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGB",
    AIRPORTNAME: "Booue",
    CITYCODE: "BGB",
    CITYNAME: "BOOUE",
    CITYNAME_HEB: "BOOUE",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "QMB",
    AIRPORTNAME: null,
    CITYCODE: "QMB",
    CITYNAME: "PANAMBI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QMC",
    AIRPORTNAME: null,
    CITYCODE: "QMC",
    CITYNAME: "MAIRIPORA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QMD",
    AIRPORTNAME: null,
    CITYCODE: "QMD",
    CITYNAME: "MADABA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JO",
    COUNTRYNAME: "JORDAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QME",
    AIRPORTNAME: " ",
    CITYCODE: "QME",
    CITYNAME: "MESSINA",
    CITYNAME_HEB: "\u05de\u05e1\u05d9\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QMF",
    AIRPORTNAME: null,
    CITYCODE: "QMF",
    CITYNAME: "MAFRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QMG",
    AIRPORTNAME: null,
    CITYCODE: "QMG",
    CITYNAME: "MAGHNIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QMH",
    AIRPORTNAME: null,
    CITYCODE: "QMH",
    CITYNAME: "OUM EL BOUAGHI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QMI",
    AIRPORTNAME: null,
    CITYCODE: "QMI",
    CITYNAME: "MOGI DAS CRUZES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QMK",
    AIRPORTNAME: null,
    CITYCODE: "QMK",
    CITYNAME: "NIAQORNAARSUK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGC",
    AIRPORTNAME: "Braganca",
    CITYCODE: "BGC",
    CITYNAME: "BRAGANCA",
    CITYNAME_HEB: "BRAGANCA",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "QML",
    AIRPORTNAME: null,
    CITYCODE: "QML",
    CITYNAME: "MIRPUR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QMM",
    AIRPORTNAME: "Marina Di Massa",
    CITYCODE: "QMM",
    CITYNAME: "MARINA DI MASSA",
    CITYNAME_HEB: "MARINA DI MASSA",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QMN",
    AIRPORTNAME: null,
    CITYCODE: "QMN",
    CITYNAME: "MBABANE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SZ",
    COUNTRYNAME: "SWAZILAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QMO",
    AIRPORTNAME: " ",
    CITYCODE: "QMO",
    CITYNAME: "MONS",
    CITYNAME_HEB: "\u05de\u05d5\u05e0\u05e1",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QMQ",
    AIRPORTNAME: "Murzuq",
    CITYCODE: "QMQ",
    CITYNAME: "MURZUQ",
    CITYNAME_HEB: "MURZUQ",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "QMR",
    AIRPORTNAME: " ",
    CITYCODE: "QMR",
    CITYNAME: "MARSALA",
    CITYNAME_HEB: "\u05de\u05e8\u05e1\u05dc\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QMS",
    AIRPORTNAME: null,
    CITYCODE: "QMS",
    CITYNAME: "MASAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QMU",
    AIRPORTNAME: null,
    CITYCODE: "QMU",
    CITYNAME: "MOUTIERS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QMV",
    AIRPORTNAME: "Montvale",
    CITYCODE: "QMV",
    CITYNAME: "MONTVALE",
    CITYNAME_HEB: "MONTVALE",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "BGD",
    AIRPORTNAME: "Borger",
    CITYCODE: "BGD",
    CITYNAME: "BORGER",
    CITYNAME_HEB: "BORGER",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QMW",
    AIRPORTNAME: null,
    CITYCODE: "QMW",
    CITYNAME: "MOHAMMADIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FAP",
    AIRPORTNAME: null,
    CITYCODE: "FAP",
    CITYNAME: "FAR EAST",
    CITYNAME_HEB:
      "\u05d4\u05de\u05d6\u05e8\u05d7 \u05d4\u05e8\u05d7\u05d5\u05e7",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QMZ",
    AIRPORTNAME: null,
    CITYCODE: "QMZ",
    CITYNAME: "MAINZ",
    CITYNAME_HEB: "\u05de\u05d9\u05d9\u05e0\u05e5",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QNB",
    AIRPORTNAME: null,
    CITYCODE: "QNB",
    CITYNAME: "ANAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNC",
    AIRPORTNAME: null,
    CITYCODE: "QNC",
    CITYNAME: "NEUCHATEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QND",
    AIRPORTNAME: null,
    CITYCODE: "QND",
    CITYNAME: "NOVI SAD",
    CITYNAME_HEB: "\u05e0\u05d5\u05d1\u05d9 \u05e1\u05d0\u05d3",
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QNE",
    AIRPORTNAME: null,
    CITYCODE: "QNE",
    CITYNAME: "RIO NEGRINHO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNF",
    AIRPORTNAME: null,
    CITYCODE: "QNF",
    CITYNAME: "FARIDABAD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNG",
    AIRPORTNAME: " ",
    CITYCODE: "QNG",
    CITYNAME: "NAGANO",
    CITYNAME_HEB: "\u05e0\u05d0\u05d2\u05d0\u05e0\u05d5",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "QNH",
    AIRPORTNAME: null,
    CITYCODE: "QNH",
    CITYNAME: "CANOINHAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNI",
    AIRPORTNAME: null,
    CITYCODE: "QNI",
    CITYNAME: "ONITSHA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNJ",
    AIRPORTNAME: null,
    CITYCODE: "QNJ",
    CITYNAME: "ANNEMASSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNK",
    AIRPORTNAME: null,
    CITYCODE: "QNK",
    CITYNAME: "NSUKKA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNL",
    AIRPORTNAME: null,
    CITYCODE: "QNL",
    CITYNAME: "NEUILLY SUR SEINE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNM",
    AIRPORTNAME: " ",
    CITYCODE: "QNM",
    CITYNAME: "NAMUR",
    CITYNAME_HEB: "\u05e0\u05d0\u05de\u05d5\u05e8",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QNN",
    AIRPORTNAME: null,
    CITYCODE: "QNN",
    CITYNAME: "MARINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNO",
    AIRPORTNAME: null,
    CITYCODE: "QNO",
    CITYNAME: "ASCOLI PICENO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NIL",
    AIRPORTNAME: null,
    CITYCODE: "NIL",
    CITYNAME: "NICOLOSI",
    CITYNAME_HEB: "\u05e0\u05d9\u05e7\u05d5\u05dc\u05d5\u05e1\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QNQ",
    AIRPORTNAME: null,
    CITYCODE: "QNQ",
    CITYNAME: "MALMO TOWN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGF",
    AIRPORTNAME: null,
    CITYCODE: "BGF",
    CITYNAME: "BANGUI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNR",
    AIRPORTNAME: null,
    CITYCODE: "QNR",
    CITYNAME: "SANTA CRUZ RIO PARDO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNS",
    AIRPORTNAME: null,
    CITYCODE: "QNS",
    CITYNAME: "CANOAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNT",
    AIRPORTNAME: null,
    CITYCODE: "QNT",
    CITYNAME: "NITEROI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNU",
    AIRPORTNAME: null,
    CITYCODE: "QNU",
    CITYNAME: "NUORO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNV",
    AIRPORTNAME: null,
    CITYCODE: "QNV",
    CITYNAME: "NOVA IGUACU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNW",
    AIRPORTNAME: null,
    CITYCODE: "QNW",
    CITYNAME: "NAWANSHAHAR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNX",
    AIRPORTNAME: null,
    CITYCODE: "QNX",
    CITYNAME: "MACON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QNZ",
    AIRPORTNAME: null,
    CITYCODE: "QNZ",
    CITYNAME: "NARA CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOA",
    AIRPORTNAME: null,
    CITYCODE: "QOA",
    CITYNAME: "MOCOCA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOB",
    AIRPORTNAME: null,
    CITYCODE: "QOB",
    CITYNAME: "ANSBACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGG",
    AIRPORTNAME: "Bongouanou",
    CITYCODE: "BGG",
    CITYNAME: "BONGOUANOU",
    CITYNAME_HEB: "BONGOUANOU",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "QOC",
    AIRPORTNAME: null,
    CITYCODE: "QOC",
    CITYNAME: "OSASCO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOD",
    AIRPORTNAME: null,
    CITYCODE: "QOD",
    CITYNAME: "OSVALDO CRUZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOG",
    AIRPORTNAME: null,
    CITYCODE: "QOG",
    CITYNAME: "HOMBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOI",
    AIRPORTNAME: null,
    CITYCODE: "QOI",
    CITYNAME: "COTIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOJ",
    AIRPORTNAME: null,
    CITYCODE: "QOJ",
    CITYNAME: "SAO BORJA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOK",
    AIRPORTNAME: null,
    CITYCODE: "QOK",
    CITYNAME: "OEKSFJORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOL",
    AIRPORTNAME: null,
    CITYCODE: "QOL",
    CITYNAME: "COLLO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOM",
    AIRPORTNAME: null,
    CITYCODE: "QOM",
    CITYNAME: "OMIYA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QON",
    AIRPORTNAME: " ",
    CITYCODE: "QON",
    CITYNAME: "ARLON",
    CITYNAME_HEB: "\u05d0\u05e8\u05dc\u05d5\u05df",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QOO",
    AIRPORTNAME: null,
    CITYCODE: "QOO",
    CITYNAME: "OTSU CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGH",
    AIRPORTNAME: "Abbaye",
    CITYCODE: "BGH",
    CITYNAME: "BOGHE",
    CITYNAME_HEB: "BOGHE",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QOP",
    AIRPORTNAME: null,
    CITYCODE: "QOP",
    CITYNAME: "CODROIPO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOQ",
    AIRPORTNAME: null,
    CITYCODE: "QOQ",
    CITYNAME: "SAARLOQ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOR",
    AIRPORTNAME: null,
    CITYCODE: "QOR",
    CITYNAME: "ORDU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOT",
    AIRPORTNAME: null,
    CITYCODE: "QOT",
    CITYNAME: "OTARU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOU",
    AIRPORTNAME: null,
    CITYCODE: "QOU",
    CITYNAME: "OUED RHIOU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOV",
    AIRPORTNAME: null,
    CITYCODE: "QOV",
    CITYNAME: "COMUNA PROVIDENCI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOW",
    AIRPORTNAME: null,
    CITYCODE: "QOW",
    CITYNAME: "OWERRI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QOY",
    AIRPORTNAME: null,
    CITYCODE: "QOY",
    CITYNAME: "LOMZA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGI",
    AIRPORTNAME: "Grantley Adams International",
    CITYCODE: "BGI",
    CITYNAME: "BRIDGETOWN",
    CITYNAME_HEB: "\u05d1\u05e8\u05d9\u05d3\u05d2' \u05d8\u05d0\u05d5\u05df",
    COUNTRYCODE: "BB",
    COUNTRYNAME: "BARBADOS",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d1\u05d3\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "QOZ",
    AIRPORTNAME: null,
    CITYCODE: "QOZ",
    CITYNAME: "OUED ZENATI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QPA",
    AIRPORTNAME: " ",
    CITYCODE: "QPA",
    CITYNAME: "PADOVA",
    CITYNAME_HEB: "\u05e4\u05d3\u05d5\u05d1\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QPB",
    AIRPORTNAME: null,
    CITYCODE: "QPB",
    CITYNAME: "CAMPOBASSO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QPC",
    AIRPORTNAME: null,
    CITYCODE: "QPC",
    CITYNAME: "PLOCK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QPD",
    AIRPORTNAME: " ",
    CITYCODE: "QPD",
    CITYNAME: "PINAR DEL RIO",
    CITYNAME_HEB:
      "\u05e4\u05d9\u05e0\u05d0\u05e8 \u05d3\u05dc \u05e8\u05d9\u05d5",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "QPE",
    AIRPORTNAME: null,
    CITYCODE: "QPE",
    CITYNAME: "PETROPOLIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QPF",
    AIRPORTNAME: null,
    CITYCODE: "QPF",
    CITYNAME: "POMPEIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QPH",
    AIRPORTNAME: null,
    CITYCODE: "QPH",
    CITYNAME: "PALAPYE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QPI",
    AIRPORTNAME: null,
    CITYCODE: "QPI",
    CITYNAME: "PALMIRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ADF",
    AIRPORTNAME: "Adiyaman",
    CITYCODE: "ADF",
    CITYNAME: "ADIYAMAN",
    CITYNAME_HEB: "ADIYAMAN",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BGJ",
    AIRPORTNAME: "Borgarfjordur Eystri",
    CITYCODE: "BGJ",
    CITYNAME: "BORGARFJORDUR EYSTRI",
    CITYNAME_HEB: "BORGARFJORDUR EYSTRI",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "QPK",
    AIRPORTNAME: " ",
    CITYCODE: "QPK",
    CITYNAME: "STRAUSBERG",
    CITYNAME_HEB: "\u05e1\u05d8\u05e8\u05d5\u05e1\u05d1\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QPL",
    AIRPORTNAME: " ",
    CITYCODE: "QPL",
    CITYNAME: "PLOIESTI",
    CITYNAME_HEB: "\u05e4\u05dc\u05d5\u05d0\u05e9\u05d8\u05d9",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QPM",
    AIRPORTNAME: null,
    CITYCODE: "QPM",
    CITYNAME: "OPOLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CEK",
    AIRPORTNAME: "Chelyabinsk",
    CITYCODE: "CEK",
    CITYNAME: "CHELYABINSK",
    CITYNAME_HEB: "CHELYABINSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "CEL",
    CITYNAME: "CASELLE",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CEM",
    AIRPORTNAME: "Central",
    CITYCODE: "CEM",
    CITYNAME: "CENTRAL",
    CITYNAME_HEB: "Central",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CEO",
    AIRPORTNAME: "Waco Kungo",
    CITYCODE: "CEO",
    CITYNAME: "WACO KUNGO",
    CITYNAME_HEB: "WACO KUNGO",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "AGP",
    AIRPORTNAME: null,
    CITYCODE: "AGP",
    CITYNAME: "MALAGA",
    CITYNAME_HEB: "\u05de\u05dc\u05d2\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "CEP",
    AIRPORTNAME: "Concepcion",
    CITYCODE: "CEP",
    CITYNAME: "CONCEPCION",
    CITYNAME_HEB: "CONCEPCION",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CEQ",
    AIRPORTNAME: "Mandelieu",
    CITYCODE: "CEQ",
    CITYNAME: "CANNES",
    CITYNAME_HEB: "\u05e7\u05d0\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "CER",
    CITYNAME: "CERVINIA",
    CITYNAME_HEB: "\u05e1\u05e8\u05d1\u05d9\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CES",
    AIRPORTNAME: "Cessnock",
    CITYCODE: "CES",
    CITYNAME: "CESSNOCK",
    CITYNAME_HEB: "CESSNOCK",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CET",
    AIRPORTNAME: "Le Pontreau",
    CITYCODE: "CET",
    CITYNAME: "CHOLET",
    CITYNAME_HEB: "CHOLET",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "CEU",
    AIRPORTNAME: "Oconee County",
    CITYCODE: "CEU",
    CITYNAME: "CLEMSON",
    CITYNAME_HEB: "CLEMSON",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CEV",
    AIRPORTNAME: "Mettle Field",
    CITYCODE: "CEV",
    CITYNAME: "CONNERSVILLE",
    CITYNAME_HEB: "CONNERSVILLE",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CEW",
    AIRPORTNAME: "Bob Sikes",
    CITYCODE: "CEW",
    CITYNAME: "CRESTVIEW",
    CITYNAME_HEB: "CRESTVIEW",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CEX",
    AIRPORTNAME: "Chena Hot Springs",
    CITYCODE: "CEX",
    CITYNAME: "CHENA HOT SPRINGS",
    CITYNAME_HEB: "Chena Hot Springs",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CEY",
    AIRPORTNAME: "Calloway County",
    CITYCODE: "CEY",
    CITYNAME: "MURRAY",
    CITYNAME_HEB: "MURRAY",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CEZ",
    AIRPORTNAME: "Montezuma County",
    CITYCODE: "CEZ",
    CITYNAME: "CORTEZ",
    CITYNAME_HEB: "CORTEZ",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CFA",
    AIRPORTNAME: "Coffee Point",
    CITYCODE: "CFA",
    CITYNAME: "COFFEE POINT",
    CITYNAME_HEB: "Coffee Point",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CFB",
    AIRPORTNAME: null,
    CITYCODE: "CFB",
    CITYNAME: "CABO FRIO",
    CITYNAME_HEB: "\u05e7\u05d0\u05d1\u05d5 \u05e4\u05e8\u05d9\u05d5",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CFC",
    AIRPORTNAME: null,
    CITYCODE: "CFC",
    CITYNAME: "CACADOR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CFD",
    AIRPORTNAME: "Coulter Field",
    CITYCODE: "CFD",
    CITYNAME: "BRYAN",
    CITYNAME_HEB: "BRYAN",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CFE",
    AIRPORTNAME: "Aulnat",
    CITYCODE: "CFE",
    CITYNAME: "CLERMONT FERRAND",
    CITYNAME_HEB: "CLERMONT FERRAND",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "CFF",
    AIRPORTNAME: "Cafunfo",
    CITYCODE: "CFF",
    CITYNAME: "CAFUNFO",
    CITYNAME_HEB: "CAFUNFO",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CFG",
    AIRPORTNAME: "Cienfuegos",
    CITYCODE: "CFG",
    CITYNAME: "CIENFUEGOS",
    CITYNAME_HEB:
      "\u05e1\u05d9\u05d9\u05e0\u05e4\u05d5\u05d0\u05d2\u05d5\u05e1",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "AGQ",
    AIRPORTNAME: null,
    CITYCODE: "AGQ",
    CITYNAME: "AGRINION",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CFH",
    AIRPORTNAME: "Clifton Hills",
    CITYCODE: "CFH",
    CITYNAME: "CLIFTON HILLS",
    CITYNAME_HEB: "CLIFTON HILLS",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CFI",
    AIRPORTNAME: "Camfield",
    CITYCODE: "CFI",
    CITYNAME: "CAMFIELD",
    CITYNAME_HEB: "CAMFIELD",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CFN",
    AIRPORTNAME: "Donegal",
    CITYCODE: "CFN",
    CITYNAME: "DONEGAL",
    CITYNAME_HEB: "DONEGAL",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "CFO",
    AIRPORTNAME: null,
    CITYCODE: "CFO",
    CITYNAME: "CONFREZA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CFP",
    AIRPORTNAME: "Carpentaria Downs",
    CITYCODE: "CFP",
    CITYNAME: "CARPENTARIA DOWNS",
    CITYNAME_HEB: "CARPENTARIA DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CFQ",
    AIRPORTNAME: "Creston",
    CITYCODE: "CFQ",
    CITYNAME: "CRESTON",
    CITYNAME_HEB: "CRESTON",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "CFR",
    AIRPORTNAME: "Carpiquet",
    CITYCODE: "CFR",
    CITYNAME: "CAEN",
    CITYNAME_HEB: "\u05e7\u05d4\u05d0\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "CFS",
    AIRPORTNAME: "Coffs Harbour",
    CITYCODE: "CFS",
    CITYNAME: "COFFS HARBOUR",
    CITYNAME_HEB: "COFFS HARBOUR",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CFT",
    AIRPORTNAME: "Morenci",
    CITYCODE: "CFT",
    CITYNAME: "CLIFTON",
    CITYNAME_HEB: "CLIFTON",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CFU",
    AIRPORTNAME: "I. Kapodistrias",
    CITYCODE: "CFU",
    CITYNAME: "CORFU",
    CITYNAME_HEB: "\u05e7\u05d5\u05e8\u05e4\u05d5",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "AGR",
    AIRPORTNAME: "Kheria",
    CITYCODE: "AGR",
    CITYNAME: "AGRA",
    CITYNAME_HEB: "\u05d0\u05d2\u05e8\u05d0",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "CFV",
    AIRPORTNAME: "Municipal",
    CITYCODE: "CFV",
    CITYNAME: "COFFEYVILLE",
    CITYNAME_HEB: "COFFEYVILLE",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CGA",
    AIRPORTNAME: "Craig SPB",
    CITYCODE: "CGA",
    CITYNAME: "CRAIG",
    CITYNAME_HEB: "Craig SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CGB",
    AIRPORTNAME: null,
    CITYCODE: "CGB",
    CITYNAME: "CUIABA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CGC",
    AIRPORTNAME: "Cape Gloucester",
    CITYCODE: "CGC",
    CITYNAME: "CAPE GLOUCESTER",
    CITYNAME_HEB: "CAPE GLOUCESTER",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "CGD",
    AIRPORTNAME: "Changde",
    CITYCODE: "CGD",
    CITYNAME: "CHANGDE",
    CITYNAME_HEB: "CHANGDE",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CGE",
    AIRPORTNAME: "Cambridge",
    CITYCODE: "CGE",
    CITYNAME: "CAMBRIDGE",
    CITYNAME_HEB: "\u05e7\u05de\u05d1\u05e8\u05d9\u05d3\u05d2'",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CGG",
    AIRPORTNAME: "Casiguran",
    CITYCODE: "CGG",
    CITYNAME: "CASIGURAN",
    CITYNAME_HEB: "CASIGURAN",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "CGI",
    AIRPORTNAME: "Cape Girardeau",
    CITYCODE: "CGI",
    CITYNAME: "CAPE GIRARDEAU",
    CITYNAME_HEB: "CAPE GIRARDEAU",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CGJ",
    AIRPORTNAME: "Chingola",
    CITYCODE: "CGJ",
    CITYNAME: "CHINGOLA",
    CITYNAME_HEB: "CHINGOLA",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CGM",
    AIRPORTNAME: "Mambajao",
    CITYCODE: "CGM",
    CITYNAME: "CAMIGUIN",
    CITYNAME_HEB: "CAMIGUIN",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "AGS",
    AIRPORTNAME: null,
    CITYCODE: "AGS",
    CITYNAME: "AUGUSTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CGN",
    AIRPORTNAME: "Cologne/Bonn",
    CITYCODE: "CGN",
    CITYNAME: "COLOGNE",
    CITYNAME_HEB: "\u05e7\u05dc\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CGO",
    AIRPORTNAME: "Zhengzhou",
    CITYCODE: "CGO",
    CITYNAME: "ZHENGZHOU",
    CITYNAME_HEB: "ZHENGZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CGP",
    AIRPORTNAME: "Patenga",
    CITYCODE: "CGP",
    CITYNAME: "CHITTAGONG",
    CITYNAME_HEB: "CHITTAGONG",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "CGQ",
    AIRPORTNAME: "Changchun",
    CITYCODE: "CGQ",
    CITYNAME: "CHANGCHUN",
    CITYNAME_HEB: "CHANGCHUN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CGR",
    AIRPORTNAME: null,
    CITYCODE: "CGR",
    CITYNAME: "CAMPO GRANDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CGS",
    AIRPORTNAME: "College Park",
    CITYCODE: "CGS",
    CITYNAME: "COLLEGE PARK",
    CITYNAME_HEB: "COLLEGE PARK",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CGT",
    AIRPORTNAME: "Chinguitti",
    CITYCODE: "CGT",
    CITYNAME: "CHINGUITTI",
    CITYNAME_HEB: "CHINGUITTI",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CGU",
    AIRPORTNAME: "Ciudad Guayana",
    CITYCODE: "CGU",
    CITYNAME: "CIUDAD GUAYANA",
    CITYNAME_HEB: "CIUDAD GUAYANA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "DNL",
    AIRPORTNAME: null,
    CITYCODE: "AGS",
    CITYNAME: "AUGUSTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CGV",
    AIRPORTNAME: "Caiguna",
    CITYCODE: "CGV",
    CITYNAME: "CAIGUNA",
    CITYNAME_HEB: "CAIGUNA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CGY",
    AIRPORTNAME: "Lumbia",
    CITYCODE: "CGY",
    CITYNAME: "CAGAYAN DE ORO",
    CITYNAME_HEB: "CAGAYAN DE ORO",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "CGZ",
    AIRPORTNAME: "Municipal",
    CITYCODE: "CGZ",
    CITYNAME: "CASA GRANDE",
    CITYNAME_HEB: "CASA GRANDE",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CHB",
    AIRPORTNAME: "Chilas",
    CITYCODE: "CHB",
    CITYNAME: "CHILAS",
    CITYNAME_HEB: "CHILAS",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "CHC",
    AIRPORTNAME: "International",
    CITYCODE: "CHC",
    CITYNAME: "CHRISTCHURCH",
    CITYNAME_HEB: "\u05e7\u05e8\u05d9\u05e1\u05e6\u05e8\u05e5",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "CHD",
    AIRPORTNAME: null,
    CITYCODE: "CHD",
    CITYNAME: "CHANDLER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CHE",
    AIRPORTNAME: "Reenroe",
    CITYCODE: "CHE",
    CITYNAME: "CAHERCIVEEN",
    CITYNAME_HEB: "CAHERCIVEEN",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "AGT",
    AIRPORTNAME: null,
    CITYCODE: "AGT",
    CITYNAME: "CIUDAD DEL ESTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PY",
    COUNTRYNAME: "PARAGUAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CHF",
    AIRPORTNAME: "Chinhae",
    CITYCODE: "CHF",
    CITYNAME: "JINHAE",
    CITYNAME_HEB: "JINHAE",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "CHG",
    AIRPORTNAME: "Chaoyang Airport",
    CITYCODE: "CHG",
    CITYNAME: "CHAOYANG",
    CITYNAME_HEB: "\u05e6\u05d0\u05d5\u05d9\u05d0\u05e0\u05d2",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CHH",
    AIRPORTNAME: "Chachapoyas",
    CITYCODE: "CHH",
    CITYNAME: "CHACHAPOYAS",
    CITYNAME_HEB: "CHACHAPOYAS",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "CHI",
    AIRPORTNAME: "Chicago",
    CITYCODE: "CHI",
    CITYNAME: "CHICAGO",
    CITYNAME_HEB: "\u05e9\u05d9\u05e7\u05d2\u05d5",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "AGU",
    AIRPORTNAME: null,
    CITYCODE: "AGU",
    CITYNAME: "AGUASCALIENTES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CHJ",
    AIRPORTNAME: "Chipinge",
    CITYCODE: "CHJ",
    CITYNAME: "CHIPINGE",
    CITYNAME_HEB: "CHIPINGE",
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: "\u05d6\u05d9\u05de\u05d1\u05d1\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "CHK",
    AIRPORTNAME: "Municipal",
    CITYCODE: "CHK",
    CITYNAME: "CHICKASHA",
    CITYNAME_HEB: "CHICKASHA",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CHL",
    AIRPORTNAME: " ",
    CITYCODE: "CHL",
    CITYNAME: "CHILE",
    CITYNAME_HEB: "\u05e6\u05d9\u05dc\u05d4",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CHM",
    AIRPORTNAME: "Chimbote",
    CITYCODE: "CHM",
    CITYNAME: "CHIMBOTE",
    CITYNAME_HEB: "CHIMBOTE",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "CHN",
    AIRPORTNAME: " ",
    CITYCODE: "CHN",
    CITYNAME: "CHINA",
    CITYNAME_HEB: "\u05e1\u05d9\u05df",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CHO",
    AIRPORTNAME: "Albemarle",
    CITYCODE: "CHO",
    CITYNAME: "CHARLOTTESVILLE",
    CITYNAME_HEB: "CHARLOTTESVILLE",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "CHP",
    AIRPORTNAME: "Circle Hot Springs",
    CITYCODE: "CHP",
    CITYNAME: "CIRCLE HOT SPRING",
    CITYNAME_HEB: "Circle Hot Springs",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CHQ",
    AIRPORTNAME: "Souda",
    CITYCODE: "CHQ",
    CITYNAME: "CHANIA",
    CITYNAME_HEB: "\u05d7\u05d0\u05e0\u05d9\u05d9\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "AGV",
    AIRPORTNAME: null,
    CITYCODE: "AGV",
    CITYNAME: "ACARIGUA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CHR",
    AIRPORTNAME: "Chateauroux",
    CITYCODE: "CHR",
    CITYNAME: "CHATEAUROUX",
    CITYNAME_HEB: "CHATEAUROUX",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "CHS",
    AIRPORTNAME: "AFB Municipal",
    CITYCODE: "CHS",
    CITYNAME: "CHARLESTON",
    CITYNAME_HEB: "CHARLESTON",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CHT",
    AIRPORTNAME: "Karewa",
    CITYCODE: "CHT",
    CITYNAME: "CHATHAM ISLAND",
    CITYNAME_HEB: "CHATHAM ISLAND",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "CHU",
    AIRPORTNAME: "Chuathbaluk",
    CITYCODE: "CHU",
    CITYNAME: "CHUATHBALUK",
    CITYNAME_HEB: "Chuathbaluk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CHV",
    AIRPORTNAME: "Chaves",
    CITYCODE: "CHV",
    CITYNAME: "CHAVES",
    CITYNAME_HEB: "CHAVES",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "CHW",
    AIRPORTNAME: "Jiuquan",
    CITYCODE: "CHW",
    CITYNAME: "JIUQUAN",
    CITYNAME_HEB: "JIUQUAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CHX",
    AIRPORTNAME: "Changuinola",
    CITYCODE: "CHX",
    CITYNAME: "CHANGUINOLA",
    CITYNAME_HEB: "CHANGUINOLA",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "CHY",
    AIRPORTNAME: "Choiseul Bay",
    CITYCODE: "CHY",
    CITYNAME: "CHOISEUL BAY",
    CITYNAME_HEB: "CHOISEUL BAY",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "CHZ",
    AIRPORTNAME: "State",
    CITYCODE: "CHZ",
    CITYNAME: "CHILOQUIN",
    CITYNAME_HEB: "CHILOQUIN",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CIC",
    AIRPORTNAME: "Chico",
    CITYCODE: "CIC",
    CITYNAME: "CHICO",
    CITYNAME_HEB: "CHICO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AGW",
    AIRPORTNAME: "Agnew",
    CITYCODE: "AGW",
    CITYNAME: "AGNEW",
    CITYNAME_HEB: "Agnew",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CID",
    AIRPORTNAME: "Cedar Rapids",
    CITYCODE: "CID",
    CITYNAME: "CEDAR RAPIDS",
    CITYNAME_HEB: "CEDAR RAPIDS",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CIE",
    AIRPORTNAME: "Collie",
    CITYCODE: "CIE",
    CITYNAME: "COLLIE",
    CITYNAME_HEB: "COLLIE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CIF",
    AIRPORTNAME: "Chifeng",
    CITYCODE: "CIF",
    CITYNAME: "CHIFENG",
    CITYNAME_HEB: "CHIFENG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CIG",
    AIRPORTNAME: "Craig-Moffat",
    CITYCODE: "CIG",
    CITYNAME: "CRAIG",
    CITYNAME_HEB: "CRAIG",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CIH",
    AIRPORTNAME: "Changzhi",
    CITYCODE: "CIH",
    CITYNAME: "CHANGZHI",
    CITYNAME_HEB: "CHANGZHI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CIJ",
    AIRPORTNAME: "E. Beltram",
    CITYCODE: "CIJ",
    CITYNAME: "COBIJA",
    CITYNAME_HEB: "COBIJA",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CIK",
    AIRPORTNAME: "Chalkyitsik",
    CITYCODE: "CIK",
    CITYNAME: "CHALKYITSIK",
    CITYNAME_HEB: "Chalkyitsik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CIL",
    AIRPORTNAME: "Melsing Creek",
    CITYCODE: "CIL",
    CITYNAME: "COUNCIL",
    CITYNAME_HEB: "Melsing Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CIM",
    AIRPORTNAME: "Cimitarra",
    CITYCODE: "CIM",
    CITYNAME: "CIMITARRA",
    CITYNAME_HEB: "CIMITARRA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CIN",
    AIRPORTNAME: "Carroll",
    CITYCODE: "CIN",
    CITYNAME: "CARROLL",
    CITYNAME_HEB: "CARROLL",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AGX",
    AIRPORTNAME: null,
    CITYCODE: "AGX",
    CITYNAME: "AGATTI ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CIO",
    AIRPORTNAME: "MCAL Lopez",
    CITYCODE: "CIO",
    CITYNAME: "CONCEPCION",
    CITYNAME_HEB: "CONCEPCION",
    COUNTRYCODE: "PY",
    COUNTRYNAME: "PARAGUAY",
    COUNTRYNAME_HEB: "\u05e4\u05d0\u05e8\u05d0\u05d2\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "CIP",
    AIRPORTNAME: "Chipata",
    CITYCODE: "CIP",
    CITYNAME: "CHIPATA",
    CITYNAME_HEB: "CHIPATA",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CIQ",
    AIRPORTNAME: "Chiquimula",
    CITYCODE: "CIQ",
    CITYNAME: "CHIQUIMULA",
    CITYNAME_HEB: "CHIQUIMULA",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CIR",
    AIRPORTNAME: "Cairo",
    CITYCODE: "CIR",
    CITYNAME: "CAIRO",
    CITYNAME_HEB: "CAIRO (US)",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CIS",
    AIRPORTNAME: "Canton Island",
    CITYCODE: "CIS",
    CITYNAME: "CANTON ISLAND",
    CITYNAME_HEB: "CANTON ISLAND",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "CIT",
    AIRPORTNAME: "Shimkent",
    CITYCODE: "CIT",
    CITYNAME: "SHIMKENT",
    CITYNAME_HEB: "SHIMKENT",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "CIV",
    AIRPORTNAME: "Chomley",
    CITYCODE: "CIV",
    CITYNAME: "CHOMLEY",
    CITYNAME_HEB: "Chomley",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CIW",
    AIRPORTNAME: null,
    CITYCODE: "CIW",
    CITYNAME: "CANOUAN ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "VC",
    COUNTRYNAME: "ST. VINCENT",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CIX",
    AIRPORTNAME: "Cornel Ruiz",
    CITYCODE: "CIX",
    CITYNAME: "CHICLAYO",
    CITYNAME_HEB: "CHICLAYO",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "CIY",
    AIRPORTNAME: "Comiso",
    CITYCODE: "CIY",
    CITYNAME: "COMISO",
    CITYNAME_HEB: "COMISO",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AGY",
    AIRPORTNAME: null,
    CITYCODE: "AGY",
    CITYNAME: "ARGYLE DOWNS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CIZ",
    AIRPORTNAME: null,
    CITYCODE: "CIZ",
    CITYNAME: "COARI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CJA",
    AIRPORTNAME: "Cajamarca",
    CITYCODE: "CJA",
    CITYNAME: "CAJAMARCA",
    CITYNAME_HEB: "CAJAMARCA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "CJB",
    AIRPORTNAME: "Peelamedu",
    CITYCODE: "CJB",
    CITYNAME: "COIMBATORE",
    CITYNAME_HEB: "COIMBATORE",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "CJC",
    AIRPORTNAME: "El Loa",
    CITYCODE: "CJC",
    CITYNAME: "CALAMA",
    CITYNAME_HEB: "CALAMA",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "CJD",
    AIRPORTNAME: "Candilejas",
    CITYCODE: "CJD",
    CITYNAME: "CANDILEJAS",
    CITYNAME_HEB: "CANDILEJAS",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CJH",
    AIRPORTNAME: "Chilko Lake",
    CITYCODE: "CJH",
    CITYNAME: "CHILKO LAKE",
    CITYNAME_HEB: "CHILKO LAKE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "CJJ",
    AIRPORTNAME: "Cheong Ju Airport",
    CITYCODE: "CJJ",
    CITYNAME: "CHEONGJU",
    CITYNAME_HEB: "CHEONGJU",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "CJL",
    AIRPORTNAME: "Chitral",
    CITYCODE: "CJL",
    CITYNAME: "CHITRAL",
    CITYNAME_HEB: "CHITRAL",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "CJM",
    AIRPORTNAME: "Chumphon Airport",
    CITYCODE: "CJM",
    CITYNAME: "CHUMPHON",
    CITYNAME_HEB: "CHUMPHON",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "CJN",
    AIRPORTNAME: "El Cajon",
    CITYCODE: "CJN",
    CITYNAME: "EL CAJON",
    CITYNAME_HEB: "EL CAJON",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AGZ",
    AIRPORTNAME: null,
    CITYCODE: "AGZ",
    CITYNAME: "AGGENEYS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CJS",
    AIRPORTNAME: "International Abraham Gonzalez",
    CITYCODE: "CJS",
    CITYNAME: "CIUDAD JUAREZ",
    CITYNAME_HEB: "CIUDAD JUAREZ",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CJT",
    AIRPORTNAME: "Copalar",
    CITYCODE: "CJT",
    CITYNAME: "COMITAN",
    CITYNAME_HEB: "COMITAN",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "CJU",
    AIRPORTNAME: "Cheju Airport",
    CITYCODE: "CJU",
    CITYNAME: "JEJU",
    CITYNAME_HEB: "\u05d2\u05d0\u05d2\u05d5",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "CKA",
    AIRPORTNAME: "Kegelman AF",
    CITYCODE: "CKA",
    CITYNAME: "CHEROKEE",
    CITYNAME_HEB: "CHEROKEE",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CKB",
    AIRPORTNAME: "Benedum",
    CITYCODE: "CKB",
    CITYNAME: "CLARKSBURG",
    CITYNAME_HEB: "CLARKSBURG",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "CKC",
    AIRPORTNAME: "Cherkassy",
    CITYCODE: "CKC",
    CITYNAME: "CHERKASSY",
    CITYNAME_HEB: "CHERKASSY",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "CKD",
    AIRPORTNAME: "Crooked Creek",
    CITYCODE: "CKD",
    CITYNAME: "CROOKED CREEK",
    CITYNAME_HEB: "Crooked Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CKE",
    AIRPORTNAME: "Clear Lake",
    CITYCODE: "CKE",
    CITYNAME: "CLEAR LAKE",
    CITYNAME_HEB: "CLEAR LAKE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AHC",
    AIRPORTNAME: null,
    CITYCODE: "AHC",
    CITYNAME: "HERLONG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CKG",
    AIRPORTNAME: "Chongqing",
    CITYCODE: "CKG",
    CITYNAME: "CHONGQING",
    CITYNAME_HEB: "CHONGQING",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "CKH",
    AIRPORTNAME: "Chokurdah",
    CITYCODE: "CKH",
    CITYNAME: "CHOKURDAH",
    CITYNAME_HEB: "CHOKURDAH",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CKI",
    AIRPORTNAME: "Croker Island",
    CITYCODE: "CKI",
    CITYNAME: "CROKER ISLAND",
    CITYNAME_HEB: "CROKER ISLAND",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CKK",
    AIRPORTNAME: "Cherokee",
    CITYCODE: "CKK",
    CITYNAME: "CHEROKEE",
    CITYNAME_HEB: "CHEROKEE",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CKL",
    AIRPORTNAME: "Chkalovsky",
    CITYCODE: "CKL",
    CITYNAME: "CHKALOVSKIY",
    CITYNAME_HEB: "CHKALOVSKIY",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CKM",
    AIRPORTNAME: "Fletcher Field",
    CITYCODE: "CKM",
    CITYNAME: "CLARKSDALE",
    CITYNAME_HEB: "CLARKSDALE",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CKN",
    AIRPORTNAME: "Municipal",
    CITYCODE: "CKN",
    CITYNAME: "CROOKSTON",
    CITYNAME_HEB: "CROOKSTON",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CKO",
    AIRPORTNAME: null,
    CITYCODE: "CKO",
    CITYNAME: "CORNELIO PROCOPIO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CKR",
    AIRPORTNAME: "Crane Island",
    CITYCODE: "CKR",
    CITYNAME: "CRANE ISLAND",
    CITYNAME_HEB: "CRANE ISLAND",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CKS",
    AIRPORTNAME: null,
    CITYCODE: "CKS",
    CITYNAME: "CARAJAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AHE",
    AIRPORTNAME: null,
    CITYCODE: "AHE",
    CITYNAME: "AHE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CKV",
    AIRPORTNAME: "Outlaw Field",
    CITYCODE: "CKV",
    CITYNAME: "CLARKSVILLE",
    CITYNAME_HEB: "CLARKSVILLE",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CKX",
    AIRPORTNAME: "Chicken",
    CITYCODE: "CKX",
    CITYNAME: "CHICKEN",
    CITYNAME_HEB: "Chicken",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CKY",
    AIRPORTNAME: "Conakry",
    CITYCODE: "CKY",
    CITYNAME: "CONAKRY",
    CITYNAME_HEB: "\u05e7\u05d5\u05e0\u05e7\u05e8\u05d9",
    COUNTRYCODE: "GN",
    COUNTRYNAME: "GUINEA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "CKZ",
    AIRPORTNAME: "Canakkale",
    CITYCODE: "CKZ",
    CITYNAME: "CANAKKALE",
    CITYNAME_HEB: "CANAKKALE",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PLV",
    AIRPORTNAME: "Poltava",
    CITYCODE: "PLV",
    CITYNAME: "POLTAVA",
    CITYNAME_HEB: "POLTAVA",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "YZF",
    AIRPORTNAME: "Yellowknife",
    CITYCODE: "YZF",
    CITYNAME: "YELLOWKNIFE",
    CITYNAME_HEB: "YELLOWKNIFE",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "EZE",
    AIRPORTNAME: " ",
    CITYCODE: "BUE",
    CITYNAME: "BUENOS AIRES",
    CITYNAME_HEB:
      "\u05d1\u05d5\u05d0\u05e0\u05d5\u05e1 \u05d0\u05d9\u05d9\u05e8\u05e1",
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05d2\u05e0\u05d8\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "YZH",
    AIRPORTNAME: "Slave Lake",
    CITYCODE: "YZH",
    CITYNAME: "SLAVE LAKE",
    CITYNAME_HEB: "Slave Lake",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YZL",
    AIRPORTNAME: null,
    CITYCODE: "YZL",
    CITYNAME: "LIARD RIVER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YZM",
    AIRPORTNAME: "Buchans",
    CITYCODE: "YZM",
    CITYNAME: "BUCHANS",
    CITYNAME_HEB: "BUCHANS",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YZP",
    AIRPORTNAME: "Sandspit",
    CITYCODE: "YZP",
    CITYNAME: "SANDSPIT",
    CITYNAME_HEB: "SANDSPIT",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XDX",
    AIRPORTNAME: null,
    CITYCODE: "YZR",
    CITYNAME: "SARNIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YZR",
    AIRPORTNAME: "Sarnia",
    CITYCODE: "YZR",
    CITYNAME: "SARNIA",
    CITYNAME_HEB: "SARNIA",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YZS",
    AIRPORTNAME: "Coral Harbour",
    CITYCODE: "YZS",
    CITYNAME: "CORAL HARBOUR",
    CITYNAME_HEB: "CORAL HARBOUR",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YZT",
    AIRPORTNAME: "Port Hardy",
    CITYCODE: "YZT",
    CITYNAME: "PORT HARDY",
    CITYNAME_HEB: "PORT HARDY",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YZU",
    AIRPORTNAME: "Whitecourt",
    CITYCODE: "YZU",
    CITYNAME: "WHITECOURT",
    CITYNAME_HEB: "Whitecourt",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BUF",
    AIRPORTNAME: "Greater Buffalo International",
    CITYCODE: "BUF",
    CITYNAME: "BUFFALO",
    CITYNAME_HEB: "\u05d1\u05d0\u05e4\u05d0\u05dc\u05d5",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YZV",
    AIRPORTNAME: "Sept-Iles",
    CITYCODE: "YZV",
    CITYNAME: "SEPT ILES",
    CITYNAME_HEB: "SEPT ILES",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YZW",
    AIRPORTNAME: "Teslin",
    CITYCODE: "YZW",
    CITYNAME: "TESLIN",
    CITYNAME_HEB: "TESLIN",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YZX",
    AIRPORTNAME: "Greenwood",
    CITYCODE: "YZX",
    CITYNAME: "GREENWOOD",
    CITYNAME_HEB: "GREENWOOD",
    COUNTRYCODE: "CANS",
    COUNTRYNAME: "NOVA SCOTIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZAA",
    AIRPORTNAME: "Alice Arm",
    CITYCODE: "ZAA",
    CITYNAME: "ALICE ARM",
    CITYNAME_HEB: "ALICE ARM",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZAB",
    AIRPORTNAME: " ",
    CITYCODE: "ZAB",
    CITYNAME: "ZAMBIA",
    CITYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZAC",
    AIRPORTNAME: "York Landing",
    CITYCODE: "ZAC",
    CITYNAME: "YORK LANDING",
    CITYNAME_HEB: "YORK LANDING",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZAD",
    AIRPORTNAME: "Zadar",
    CITYCODE: "ZAD",
    CITYNAME: "ZADAR",
    CITYNAME_HEB: "\u05d6\u05d3\u05d0\u05e8",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZAE",
    AIRPORTNAME: null,
    CITYCODE: "ZAE",
    CITYNAME: "EL EULMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZAF",
    AIRPORTNAME: null,
    CITYCODE: "ZAF",
    CITYNAME: "ARLES",
    CITYNAME_HEB: "\u05d0\u05e8\u05dc\u05d4",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "ZAG",
    AIRPORTNAME: "Pleso",
    CITYCODE: "ZAG",
    CITYNAME: "ZAGREB",
    CITYNAME_HEB: "\u05d6\u05d2\u05e8\u05d1",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZFZ",
    AIRPORTNAME: null,
    CITYCODE: "BUF",
    CITYNAME: "BUFFALO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZAI",
    AIRPORTNAME: null,
    CITYCODE: "ZAI",
    CITYNAME: "EMBARCACION",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZAJ",
    AIRPORTNAME: null,
    CITYCODE: "ZAJ",
    CITYNAME: "ZARANJ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZAK",
    AIRPORTNAME: "Bus Station",
    CITYCODE: "ZAK",
    CITYNAME: "CHIUSA KLAUSEN",
    CITYNAME_HEB: "CHIUSA KLAUSEN",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZAL",
    AIRPORTNAME: "Pichoy",
    CITYCODE: "ZAL",
    CITYNAME: "VALDIVIA",
    CITYNAME_HEB: "\u05d5\u05dc\u05d3\u05d9\u05d5\u05d9\u05d4",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6",
  },
  {
    AIRPORTCODE: "ZAM",
    AIRPORTNAME: " ",
    CITYCODE: "ZAM",
    CITYNAME: "ZAMORA",
    CITYNAME_HEB: "\u05d6\u05de\u05d5\u05e8\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "ZAN",
    AIRPORTNAME: null,
    CITYCODE: "ZAN",
    CITYNAME: "AGHIOS NICOLAOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZAO",
    AIRPORTNAME: "Laberandie",
    CITYCODE: "ZAO",
    CITYNAME: "CAHORS",
    CITYNAME_HEB: "CAHORS",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "ZAP",
    AIRPORTNAME: " ",
    CITYCODE: "ZAP",
    CITYNAME: "APPENZELL",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZAR",
    AIRPORTNAME: "Zaria",
    CITYCODE: "ZAR",
    CITYNAME: "ZARIA",
    CITYNAME_HEB: "ZARIA",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZAS",
    AIRPORTNAME: " ",
    CITYCODE: "ZAS",
    CITYNAME: "ZELL AM SEE",
    CITYNAME_HEB: "\u05e6\u05dc \u05d0\u05de \u05d6\u05d4",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZXS",
    AIRPORTNAME: null,
    CITYCODE: "BUF",
    CITYNAME: "BUFFALO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZAT",
    AIRPORTNAME: "Zhaotong",
    CITYCODE: "ZAT",
    CITYNAME: "ZHAOTONG",
    CITYNAME_HEB: "ZHAOTONG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ZAU",
    AIRPORTNAME: null,
    CITYCODE: "ZAU",
    CITYNAME: "AUE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZAV",
    AIRPORTNAME: null,
    CITYCODE: "ZAV",
    CITYNAME: "AVEIRO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZAW",
    AIRPORTNAME: null,
    CITYCODE: "ZAW",
    CITYNAME: "NYKOBING MORS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZAX",
    AIRPORTNAME: null,
    CITYCODE: "ZAX",
    CITYNAME: "ANGERMUENDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZAZ",
    AIRPORTNAME: "Zaragoza",
    CITYCODE: "ZAZ",
    CITYNAME: "ZARAGOZA",
    CITYNAME_HEB: "\u05d6\u05d0\u05e8\u05d0\u05d2\u05d5\u05d6\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "ZBD",
    AIRPORTNAME: null,
    CITYCODE: "ZBD",
    CITYNAME: "BAD BRAMBACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZBE",
    AIRPORTNAME: "Dolni Benesov",
    CITYCODE: "ZBE",
    CITYNAME: "ZABREH",
    CITYNAME_HEB: "\u05d6\u05d1\u05e8\u05da",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZBF",
    AIRPORTNAME: "Bathurst",
    CITYCODE: "ZBF",
    CITYNAME: "BATHURST",
    CITYNAME_HEB: "BATHURST",
    COUNTRYCODE: "CANB",
    COUNTRYNAME: "NEW BRUNSWICK",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BUG",
    AIRPORTNAME: "Gen. V. Deslandes",
    CITYCODE: "BUG",
    CITYNAME: "BENGUELA",
    CITYNAME_HEB: "BENGUELA",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "ZBG",
    AIRPORTNAME: null,
    CITYCODE: "ZBG",
    CITYNAME: "ELBLAG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZBH",
    AIRPORTNAME: null,
    CITYCODE: "ZBH",
    CITYNAME: "SEVERAC LECHATEAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZBJ",
    AIRPORTNAME: null,
    CITYCODE: "ZBJ",
    CITYNAME: "FREDERICIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZBK",
    AIRPORTNAME: "Zabljak",
    CITYCODE: "ZBK",
    CITYNAME: "ZABLJAK",
    CITYNAME_HEB: "\u05d6\u05d1\u05dc\u05d9\u05d0\u05e7",
    COUNTRYCODE: "ME",
    COUNTRYNAME: "MONTENEGRO",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "ZBL",
    AIRPORTNAME: "Biloela",
    CITYCODE: "ZBL",
    CITYNAME: "BILOELA",
    CITYNAME_HEB: "BILOELA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZBM",
    AIRPORTNAME: "Bromont",
    CITYCODE: "ZBM",
    CITYNAME: "BROMONT",
    CITYNAME_HEB: "BROMONT",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZBN",
    AIRPORTNAME: " ",
    CITYCODE: "ZBN",
    CITYNAME: "BOZEN",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZBO",
    AIRPORTNAME: "Bowen",
    CITYCODE: "ZBO",
    CITYNAME: "BOWEN",
    CITYNAME_HEB: "BOWEN",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZBT",
    AIRPORTNAME: null,
    CITYCODE: "ZBT",
    CITYNAME: "KOLDING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZBU",
    AIRPORTNAME: null,
    CITYCODE: "ZBU",
    CITYNAME: "AARHUS LIMO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BBU",
    AIRPORTNAME: "Baneasa",
    CITYCODE: "BUH",
    CITYNAME: "BUCHAREST",
    CITYNAME_HEB: "\u05d1\u05d5\u05e7\u05e8\u05e9\u05d8",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZBV",
    AIRPORTNAME: null,
    CITYCODE: "ZBV",
    CITYNAME: "BEAVER CREEK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZBX",
    AIRPORTNAME: null,
    CITYCODE: "ZBX",
    CITYNAME: "SZOMBATHELY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZBY",
    AIRPORTNAME: "Sayaboury",
    CITYCODE: "ZBY",
    CITYNAME: "SAYABOURY",
    CITYNAME_HEB: "SAYABOURY",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "ZBZ",
    AIRPORTNAME: null,
    CITYCODE: "ZBZ",
    CITYNAME: "BAD SALZUNGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCA",
    AIRPORTNAME: null,
    CITYCODE: "ZCA",
    CITYNAME: "ARNSBERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCB",
    AIRPORTNAME: null,
    CITYCODE: "ZCB",
    CITYNAME: "ASCHAFFENBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCD",
    AIRPORTNAME: null,
    CITYCODE: "ZCD",
    CITYNAME: "BAMBERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCE",
    AIRPORTNAME: null,
    CITYCODE: "ZCE",
    CITYNAME: "BERCHTESGADEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCF",
    AIRPORTNAME: " ",
    CITYCODE: "ZCF",
    CITYNAME: "BERGHEIM",
    CITYNAME_HEB: "\u05d1\u05e8\u05d2\u05d4\u05d9\u05d9\u05dd",
    COUNTRYCODE: "FC",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "BUH",
    AIRPORTNAME: " ",
    CITYCODE: "BUH",
    CITYNAME: "BUCHAREST",
    CITYNAME_HEB: "\u05d1\u05d5\u05e7\u05e8\u05e9\u05d8",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZCG",
    AIRPORTNAME: null,
    CITYCODE: "ZCG",
    CITYNAME: "BERGISCH GLADBACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCH",
    AIRPORTNAME: null,
    CITYCODE: "ZCH",
    CITYNAME: "BERGKAMEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCI",
    AIRPORTNAME: null,
    CITYCODE: "ZCI",
    CITYNAME: "BOCHOLT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCJ",
    AIRPORTNAME: null,
    CITYCODE: "ZCJ",
    CITYNAME: "BOTTROP",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCK",
    AIRPORTNAME: null,
    CITYCODE: "ZCK",
    CITYNAME: "BRUEHL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCL",
    AIRPORTNAME: "Zacatecas International Airport (La Calera)",
    CITYCODE: "ZCL",
    CITYNAME: "ZACATECAS",
    CITYNAME_HEB: "ZACATECAS",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "ZCM",
    AIRPORTNAME: null,
    CITYCODE: "ZCM",
    CITYNAME: "CASTROP RAUXEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCN",
    AIRPORTNAME: null,
    CITYCODE: "ZCN",
    CITYNAME: "CELLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCO",
    AIRPORTNAME: "Maquehue",
    CITYCODE: "ZCO",
    CITYNAME: "TEMUCO",
    CITYNAME_HEB: "TEMUCO",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "ZCP",
    AIRPORTNAME: null,
    CITYCODE: "ZCP",
    CITYNAME: "COBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OTP",
    AIRPORTNAME: "Otopeni International",
    CITYCODE: "BUH",
    CITYNAME: "BUCHAREST",
    CITYNAME_HEB: "\u05d1\u05d5\u05e7\u05e8\u05e9\u05d8",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZCQ",
    AIRPORTNAME: null,
    CITYCODE: "ZCQ",
    CITYNAME: "CURICO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCR",
    AIRPORTNAME: null,
    CITYCODE: "ZCR",
    CITYNAME: "DACHAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCS",
    AIRPORTNAME: null,
    CITYCODE: "ZCS",
    CITYNAME: "DARMSTADT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCT",
    AIRPORTNAME: null,
    CITYCODE: "ZCT",
    CITYNAME: "DELMENHORST",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCU",
    AIRPORTNAME: null,
    CITYCODE: "ZCU",
    CITYNAME: "DETMOLD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCV",
    AIRPORTNAME: null,
    CITYCODE: "ZCV",
    CITYNAME: "DINSLAKEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCW",
    AIRPORTNAME: null,
    CITYCODE: "ZCW",
    CITYNAME: "DORMAGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCX",
    AIRPORTNAME: null,
    CITYCODE: "ZCX",
    CITYNAME: "DORSTEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCY",
    AIRPORTNAME: null,
    CITYCODE: "ZCY",
    CITYNAME: "DUEREN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZCZ",
    AIRPORTNAME: null,
    CITYCODE: "ZCZ",
    CITYNAME: "ERLANGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUI",
    AIRPORTNAME: "Bokondini",
    CITYCODE: "BUI",
    CITYNAME: "BOKONDINI",
    CITYNAME_HEB: "BOKONDINI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZDA",
    AIRPORTNAME: " ",
    CITYCODE: "ZDA",
    CITYNAME: "AARAU",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZDB",
    AIRPORTNAME: " ",
    CITYCODE: "ZDB",
    CITYNAME: "ADELBODEN",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZDC",
    AIRPORTNAME: " ",
    CITYCODE: "ZDC",
    CITYNAME: "AIGLE",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZDD",
    AIRPORTNAME: " ",
    CITYCODE: "ZDD",
    CITYNAME: "ARBON",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZDE",
    AIRPORTNAME: " ",
    CITYCODE: "ZDE",
    CITYNAME: "AROSA",
    CITYNAME_HEB: "\u05d0\u05e8\u05d5\u05e1\u05d4",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZDF",
    AIRPORTNAME: null,
    CITYCODE: "ZDF",
    CITYNAME: "NABLUS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZDG",
    AIRPORTNAME: " ",
    CITYCODE: "ZDG",
    CITYNAME: "BADEN",
    CITYNAME_HEB: "\u05d1\u05d0\u05d3\u05df",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZDI",
    AIRPORTNAME: null,
    CITYCODE: "ZDI",
    CITYNAME: "BELLINZONA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZDK",
    AIRPORTNAME: null,
    CITYCODE: "ZDK",
    CITYNAME: "BIEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZDL",
    AIRPORTNAME: null,
    CITYCODE: "ZDL",
    CITYNAME: "BRIG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AFA",
    AIRPORTNAME: null,
    CITYCODE: "AFA",
    CITYNAME: "SAN RAFAEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUJ",
    AIRPORTNAME: "Ain Eddis",
    CITYCODE: "BUJ",
    CITYNAME: "BOUSSAADA",
    CITYNAME_HEB: "BOUSSAADA",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZDM",
    AIRPORTNAME: null,
    CITYCODE: "ZDM",
    CITYNAME: "RAMALLAH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZDO",
    AIRPORTNAME: null,
    CITYCODE: "ZDO",
    CITYNAME: "BUCHS SG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZDP",
    AIRPORTNAME: null,
    CITYCODE: "ZDP",
    CITYNAME: "BURGDORF",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZDQ",
    AIRPORTNAME: null,
    CITYCODE: "ZDQ",
    CITYNAME: "CHAMPERY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZDR",
    AIRPORTNAME: null,
    CITYCODE: "ZDR",
    CITYNAME: "CHATEAU D OEX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZDS",
    AIRPORTNAME: null,
    CITYCODE: "ZDS",
    CITYNAME: "CHIASSO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZDT",
    AIRPORTNAME: null,
    CITYCODE: "ZDT",
    CITYNAME: "CHUR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZDV",
    AIRPORTNAME: " ",
    CITYCODE: "ZDV",
    CITYNAME: "DAVOS",
    CITYNAME_HEB: "\u05d3\u05d0\u05d1\u05d5\u05e1",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZDW",
    AIRPORTNAME: null,
    CITYCODE: "ZDW",
    CITYNAME: "DELEMONT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZDX",
    AIRPORTNAME: null,
    CITYCODE: "ZDX",
    CITYNAME: "DIETIKON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUK",
    AIRPORTNAME: " ",
    CITYCODE: "BUK",
    CITYNAME: "BUK",
    CITYNAME_HEB: "\u05d1\u05d5\u05e7",
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZDZ",
    AIRPORTNAME: null,
    CITYCODE: "ZDZ",
    CITYNAME: "EINSIEDELN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZEA",
    AIRPORTNAME: null,
    CITYCODE: "ZEA",
    CITYNAME: "ESCHWEILER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZEB",
    AIRPORTNAME: null,
    CITYCODE: "ZEB",
    CITYNAME: "ESSLINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZEC",
    AIRPORTNAME: "Secunda",
    CITYCODE: "ZEC",
    CITYNAME: "SECUNDA",
    CITYNAME_HEB: "SECUNDA",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "ZED",
    AIRPORTNAME: " ",
    CITYCODE: "ZED",
    CITYNAME: "EUSKIRCHEN",
    CITYNAME_HEB: "\u05d0\u05d5\u05e1\u05e7\u05e8\u05d9\u05db\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZEE",
    AIRPORTNAME: null,
    CITYCODE: "ZEE",
    CITYNAME: "FULDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZEG",
    AIRPORTNAME: "Senggo",
    CITYCODE: "ZEG",
    CITYNAME: "SENGGO",
    CITYNAME_HEB: "SENGGO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZEH",
    AIRPORTNAME: null,
    CITYCODE: "ZEH",
    CITYNAME: "GARBSEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUL",
    AIRPORTNAME: " ",
    CITYCODE: "BUL",
    CITYNAME: "BULGARIA",
    CITYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZEI",
    AIRPORTNAME: " ",
    CITYCODE: "ZEI",
    CITYNAME: "GARMISCH PARTENKIRCHEN",
    CITYNAME_HEB: "\u05d2\u05e8\u05de\u05d9\u05e9",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZEJ",
    AIRPORTNAME: null,
    CITYCODE: "ZEJ",
    CITYNAME: "GELSENKIRCHEN",
    CITYNAME_HEB:
      "\u05d2\u05dc\u05d9\u05d9\u05d6\u05e8\u05e7\u05d9\u05e8\u05e9\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZEK",
    AIRPORTNAME: null,
    CITYCODE: "ZEK",
    CITYNAME: "GLADBECK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZEL",
    AIRPORTNAME: "Bella Bella",
    CITYCODE: "ZEL",
    CITYNAME: "BELLA BELLA",
    CITYNAME_HEB: "BELLA BELLA",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZEM",
    AIRPORTNAME: "East Main",
    CITYCODE: "ZEM",
    CITYNAME: "EAST MAIN",
    CITYNAME_HEB: "EAST MAIN",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZEN",
    AIRPORTNAME: "Zenag",
    CITYCODE: "ZEN",
    CITYNAME: "ZENAG",
    CITYNAME_HEB: "ZENAG",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ZEO",
    AIRPORTNAME: null,
    CITYCODE: "ZEO",
    CITYNAME: "SAVI RAGHA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZEQ",
    AIRPORTNAME: null,
    CITYCODE: "ZEQ",
    CITYNAME: "DEWSBURY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZER",
    AIRPORTNAME: "Zero",
    CITYCODE: "ZER",
    CITYNAME: "ZERO",
    CITYNAME_HEB: "ZERO",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "ZES",
    AIRPORTNAME: null,
    CITYCODE: "ZES",
    CITYNAME: "GOEPPINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUM",
    AIRPORTNAME: "Butler",
    CITYCODE: "BUM",
    CITYNAME: "BUTLER",
    CITYNAME_HEB: "BUTLER",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZET",
    AIRPORTNAME: null,
    CITYCODE: "ZET",
    CITYNAME: "GOSLAR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZEU",
    AIRPORTNAME: null,
    CITYCODE: "ZEU",
    CITYNAME: "GOETTINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZEV",
    AIRPORTNAME: null,
    CITYCODE: "ZEV",
    CITYNAME: "GREVENBROICH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZEW",
    AIRPORTNAME: null,
    CITYCODE: "ZEW",
    CITYNAME: "GUMMERSBACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZEY",
    AIRPORTNAME: null,
    CITYCODE: "ZEY",
    CITYNAME: "HAGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZEZ",
    AIRPORTNAME: null,
    CITYCODE: "ZEZ",
    CITYNAME: "HAMELN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZFA",
    AIRPORTNAME: "Faro",
    CITYCODE: "ZFA",
    CITYNAME: "FARO",
    CITYNAME_HEB: "FARO",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZFB",
    AIRPORTNAME: "Old Fort Bay",
    CITYCODE: "ZFB",
    CITYNAME: "OLD FORT BAY",
    CITYNAME_HEB: "OLD FORT BAY",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZFD",
    AIRPORTNAME: null,
    CITYCODE: "ZFD",
    CITYNAME: "FOND DU LAC",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUN",
    AIRPORTNAME: "Buenaventura",
    CITYCODE: "BUN",
    CITYNAME: "BUENAVENTURA",
    CITYNAME_HEB: "BUENAVENTURA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZFE",
    AIRPORTNAME: null,
    CITYCODE: "ZFE",
    CITYNAME: "BELTSVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZFI",
    AIRPORTNAME: null,
    CITYCODE: "ZFI",
    CITYNAME: "CHESTERFIELD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZFK",
    AIRPORTNAME: null,
    CITYCODE: "ZFK",
    CITYNAME: "GUDERUP",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZFL",
    AIRPORTNAME: "South Trout Lake",
    CITYCODE: "ZFL",
    CITYNAME: "SOUTH TROUT LAKE",
    CITYNAME_HEB: "SOUTH TROUT LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZFM",
    AIRPORTNAME: "Fort Mcpherson",
    CITYCODE: "ZFM",
    CITYNAME: "FORT MCPHERSON",
    CITYNAME_HEB: "FORT MCPHERSON",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZFN",
    AIRPORTNAME: "Tulita",
    CITYCODE: "ZFN",
    CITYNAME: "TULITA FORT NORMAN",
    CITYNAME_HEB: "TULITA FORT NORMAN",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZFO",
    AIRPORTNAME: null,
    CITYCODE: "ZFO",
    CITYNAME: "FRANCONIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZFP",
    AIRPORTNAME: null,
    CITYCODE: "ZFP",
    CITYNAME: "VESZPREM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZFR",
    AIRPORTNAME: null,
    CITYCODE: "ZFR",
    CITYNAME: "FRANKFURT ODER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZFU",
    AIRPORTNAME: null,
    CITYCODE: "ZFU",
    CITYNAME: "ARUJA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUO",
    AIRPORTNAME: "Burao",
    CITYCODE: "BUO",
    CITYNAME: "BURAO",
    CITYNAME_HEB: "BURAO",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZFW",
    AIRPORTNAME: "Fairview",
    CITYCODE: "ZFW",
    CITYNAME: "FAIRVIEW",
    CITYNAME_HEB: "Fairview",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZGA",
    AIRPORTNAME: null,
    CITYCODE: "ZGA",
    CITYNAME: "GERA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZGE",
    AIRPORTNAME: null,
    CITYCODE: "ZGE",
    CITYNAME: "GOERLITZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZGF",
    AIRPORTNAME: "Grand Forks",
    CITYCODE: "ZGF",
    CITYNAME: "GRAND FORKS",
    CITYNAME_HEB: "GRAND FORKS",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZGI",
    AIRPORTNAME: null,
    CITYCODE: "ZGI",
    CITYNAME: "GODS RIVER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZGJ",
    AIRPORTNAME: " ",
    CITYCODE: "ZGJ",
    CITYNAME: "BRUGGE",
    CITYNAME_HEB: "\u05d1\u05e8\u05d5\u05d6",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZGK",
    AIRPORTNAME: " ",
    CITYCODE: "ZGK",
    CITYNAME: "LEUVEN",
    CITYNAME_HEB: "\u05dc\u05d0\u05d5\u05d1\u05df",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZGL",
    AIRPORTNAME: "South Galway",
    CITYCODE: "ZGL",
    CITYNAME: "SOUTH GALWAY",
    CITYNAME_HEB: "SOUTH GALWAY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZGM",
    AIRPORTNAME: "Ngoma",
    CITYCODE: "ZGM",
    CITYNAME: "NGOMA",
    CITYNAME_HEB: "NGOMA",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZGN",
    AIRPORTNAME: null,
    CITYCODE: "ZGN",
    CITYNAME: "GUTENFUERST",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUP",
    AIRPORTNAME: "Bhatinda",
    CITYCODE: "BUP",
    CITYNAME: "BHATINDA",
    CITYNAME_HEB: "BHATINDA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "ZGO",
    AIRPORTNAME: null,
    CITYCODE: "ZGO",
    CITYNAME: "GOTHA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZGP",
    AIRPORTNAME: " ",
    CITYCODE: "ZGP",
    CITYNAME: "MECHELEN",
    CITYNAME_HEB: "\u05de\u05db\u05dc\u05df",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZGQ",
    AIRPORTNAME: " ",
    CITYCODE: "ZGQ",
    CITYNAME: "TOURNAI",
    CITYNAME_HEB: "\u05d8\u05d5\u05e8\u05e0\u05d0\u05d9",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZGR",
    AIRPORTNAME: "Little Grand Rapids",
    CITYCODE: "ZGR",
    CITYNAME: "LITTLE GRAND RAPIDS",
    CITYNAME_HEB: "LITTLE GRAND RAPIDS",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZGS",
    AIRPORTNAME: "Gethsemani",
    CITYCODE: "ZGS",
    CITYNAME: "GETHSEMANI",
    CITYNAME_HEB: "GETHSEMANI",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZGT",
    AIRPORTNAME: null,
    CITYCODE: "ZGT",
    CITYNAME: "GERSTUNGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZGU",
    AIRPORTNAME: "Gaua",
    CITYCODE: "ZGU",
    CITYNAME: "GAUA",
    CITYNAME_HEB: "GAUA",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "ZGV",
    AIRPORTNAME: " ",
    CITYCODE: "ZGV",
    CITYNAME: "WAVRE",
    CITYNAME_HEB: "\u05d5\u05d5\u05d1\u05e8",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZGW",
    AIRPORTNAME: null,
    CITYCODE: "ZGW",
    CITYNAME: "GREIFSWALD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZGX",
    AIRPORTNAME: null,
    CITYCODE: "ZGX",
    CITYNAME: "VIBORG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUQ",
    AIRPORTNAME: "Bulawayo",
    CITYCODE: "BUQ",
    CITYNAME: "BULAWAYO",
    CITYNAME_HEB: "BULAWAYO",
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: "\u05d6\u05d9\u05de\u05d1\u05d1\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ZGZ",
    AIRPORTNAME: null,
    CITYCODE: "ZGZ",
    CITYNAME: "FICTITIOUS POINT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHA",
    AIRPORTNAME: "Zhanjiang",
    CITYCODE: "ZHA",
    CITYNAME: "ZHANJIANG",
    CITYNAME_HEB: "ZHANJIANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ZHB",
    AIRPORTNAME: " ",
    CITYCODE: "ZHB",
    CITYNAME: "ENGELBERG",
    CITYNAME_HEB: "\u05d0\u05e0\u05d2\u05dc\u05d1\u05e8\u05d2",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZHD",
    AIRPORTNAME: " ",
    CITYCODE: "ZHD",
    CITYNAME: "FLUELEN",
    CITYNAME_HEB: "\u05e4\u05dc\u05d5\u05dc\u05d0\u05df",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZHE",
    AIRPORTNAME: null,
    CITYCODE: "ZHE",
    CITYNAME: "FRAUENFELD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHF",
    AIRPORTNAME: " ",
    CITYCODE: "ZHF",
    CITYNAME: "FRIBOURG",
    CITYNAME_HEB: "\u05e4\u05e8\u05d9\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZHG",
    AIRPORTNAME: null,
    CITYCODE: "ZHG",
    CITYNAME: "GLARUS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHH",
    AIRPORTNAME: null,
    CITYCODE: "ZHH",
    CITYNAME: "GOSSAU SG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHI",
    AIRPORTNAME: null,
    CITYCODE: "ZHI",
    CITYNAME: "GRENCHEN SUED",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUR",
    AIRPORTNAME: "Burbank",
    CITYCODE: "BUR",
    CITYNAME: "BURBANK",
    CITYNAME_HEB: "BURBANK",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZHJ",
    AIRPORTNAME: null,
    CITYCODE: "ZHJ",
    CITYNAME: "GRINDELWALD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHK",
    AIRPORTNAME: null,
    CITYCODE: "ZHK",
    CITYNAME: "GSTAAD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHL",
    AIRPORTNAME: null,
    CITYCODE: "ZHL",
    CITYNAME: "HEERBRUGG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHM",
    AIRPORTNAME: "Shamshernagar",
    CITYCODE: "ZHM",
    CITYNAME: "SHAMSHERNAGAR",
    CITYNAME_HEB: "SHAMSHERNAGAR",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "ZHN",
    AIRPORTNAME: null,
    CITYCODE: "ZHN",
    CITYNAME: "HERZOGENBUCHSEE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHO",
    AIRPORTNAME: null,
    CITYCODE: "ZHO",
    CITYNAME: "HOUSTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHP",
    AIRPORTNAME: "High Prairie",
    CITYCODE: "ZHP",
    CITYNAME: "HIGH PRAIRIE",
    CITYNAME_HEB: "High Prairie",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZHQ",
    AIRPORTNAME: null,
    CITYCODE: "ZHQ",
    CITYNAME: "HALBERSTADT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHR",
    AIRPORTNAME: null,
    CITYCODE: "ZHR",
    CITYNAME: "KANDERSTEG",
    CITYNAME_HEB: "\u05e7\u05e0\u05d3\u05e8\u05e1\u05d8\u05d2",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZHS",
    AIRPORTNAME: null,
    CITYCODE: "ZHS",
    CITYNAME: "KLOSTERS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUS",
    AIRPORTNAME: "\u05d1\u05d8\u05d5\u05de\u05d9",
    CITYCODE: "BUS",
    CITYNAME: "BATUMI",
    CITYNAME_HEB: "\u05d1\u05d8\u05d5\u05de\u05d9",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZHT",
    AIRPORTNAME: null,
    CITYCODE: "ZHT",
    CITYNAME: "GENEVA CORNAVIN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHU",
    AIRPORTNAME: null,
    CITYCODE: "ZHU",
    CITYNAME: "KREUZLINGEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHV",
    AIRPORTNAME: null,
    CITYCODE: "ZHV",
    CITYNAME: "LA CHAUX DE FONDS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHW",
    AIRPORTNAME: " ",
    CITYCODE: "ZHW",
    CITYNAME: "LANGENTHAL",
    CITYNAME_HEB: "\u05dc\u05d0\u05e0\u05d2\u05e0\u05d8\u05dc",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZHX",
    AIRPORTNAME: null,
    CITYCODE: "ZHX",
    CITYNAME: "TUBARAO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZHZ",
    AIRPORTNAME: null,
    CITYCODE: "ZHZ",
    CITYNAME: "HALLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZIB",
    AIRPORTNAME: null,
    CITYCODE: "ZIB",
    CITYNAME: "NYBORG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZIC",
    AIRPORTNAME: "Victoria",
    CITYCODE: "ZIC",
    CITYNAME: "VICTORIA",
    CITYNAME_HEB: "VICTORIA",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "AFD",
    AIRPORTNAME: null,
    CITYCODE: "AFD",
    CITYNAME: "PORT ALFRED",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUT",
    AIRPORTNAME: "Burtonwood",
    CITYCODE: "BUT",
    CITYNAME: "BURTONWOOD",
    CITYNAME_HEB: "BURTONWOOD",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZIG",
    AIRPORTNAME: "Ziguinchor",
    CITYCODE: "ZIG",
    CITYNAME: "ZIGUINCHOR",
    CITYNAME_HEB: "ZIGUINCHOR",
    COUNTRYCODE: "SN",
    COUNTRYNAME: "SENEGAL",
    COUNTRYNAME_HEB: "\u05e1\u05e0\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "ZIH",
    AIRPORTNAME: "Ixtapa-Zihuatanejo International Airport",
    CITYCODE: "ZIH",
    CITYNAME: "IXTAPA ZIHUATANEJ",
    CITYNAME_HEB: "IXTAPA ZIHUATANEJ",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "ZIJ",
    AIRPORTNAME: null,
    CITYCODE: "ZIJ",
    CITYNAME: "SJAELLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZIN",
    AIRPORTNAME: " ",
    CITYCODE: "ZIN",
    CITYNAME: "INTERLAKEN",
    CITYNAME_HEB: "\u05d0\u05d9\u05e0\u05d8\u05e8\u05dc\u05d0\u05e7\u05df",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZIR",
    AIRPORTNAME: null,
    CITYCODE: "ZIR",
    CITYNAME: "RANDERS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZIS",
    AIRPORTNAME: null,
    CITYCODE: "ZIS",
    CITYNAME: "ZHONGSHAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZIT",
    AIRPORTNAME: null,
    CITYCODE: "ZIT",
    CITYNAME: "ZITTAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZJA",
    AIRPORTNAME: null,
    CITYCODE: "ZJA",
    CITYNAME: "LE LOCLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZJC",
    AIRPORTNAME: " ",
    CITYCODE: "ZJC",
    CITYNAME: "LENZBURG",
    CITYNAME_HEB: "\u05dc\u05e0\u05e6\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "BUU",
    AIRPORTNAME: "Buyo",
    CITYCODE: "BUU",
    CITYNAME: "BUYO",
    CITYNAME_HEB: "BUYO",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "ZJD",
    AIRPORTNAME: null,
    CITYCODE: "ZJD",
    CITYNAME: "LENZERHEIDE LAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZJF",
    AIRPORTNAME: null,
    CITYCODE: "ZJF",
    CITYNAME: "JEBEL ALI FREE ZONE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AE",
    COUNTRYNAME: "UNITED ARAB EMIRATES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZJG",
    AIRPORTNAME: "Jenpeg",
    CITYCODE: "ZJG",
    CITYNAME: "JENPEG",
    CITYNAME_HEB: "JENPEG",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZJI",
    AIRPORTNAME: " ",
    CITYCODE: "ZJI",
    CITYNAME: "LOCARNO",
    CITYNAME_HEB: "\u05dc\u05d5\u05e7\u05d0\u05e8\u05e0\u05d5",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ZJK",
    AIRPORTNAME: " ",
    CITYCODE: "ZJK",
    CITYNAME: "CHEK LAP KOK",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ZJL",
    AIRPORTNAME: null,
    CITYCODE: "ZJL",
    CITYNAME: "LYSS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZJM",
    AIRPORTNAME: null,
    CITYCODE: "ZJM",
    CITYNAME: "MARTIGNY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZJN",
    AIRPORTNAME: null,
    CITYCODE: "ZJN",
    CITYNAME: "SWAN RIVER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZJP",
    AIRPORTNAME: null,
    CITYCODE: "ZJP",
    CITYNAME: "MONTREUX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZJQ",
    AIRPORTNAME: null,
    CITYCODE: "ZJQ",
    CITYNAME: "MORGES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BUV",
    AIRPORTNAME: "Bella Union",
    CITYCODE: "BUV",
    CITYNAME: "BELLA UNION",
    CITYNAME_HEB: "BELLA UNION",
    COUNTRYCODE: "UY",
    COUNTRYNAME: "URUGUAY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e8\u05d5\u05d2\u05d0\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "ZJS",
    AIRPORTNAME: null,
    CITYCODE: "ZJS",
    CITYNAME: "JENA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PSO",
    AIRPORTNAME: "Cano",
    CITYCODE: "PSO",
    CITYNAME: "PASTO",
    CITYNAME_HEB: "PASTO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PSP",
    AIRPORTNAME: "Municipal",
    CITYCODE: "PSP",
    CITYNAME: "PALM SPRINGS",
    CITYNAME_HEB: "PALM SPRINGS",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UDD",
    AIRPORTNAME: "Bermuda Dunes",
    CITYCODE: "PSP",
    CITYNAME: "PALM SPRINGS",
    CITYNAME_HEB: "PALM SPRINGS",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BEN",
    AIRPORTNAME: "Benina International",
    CITYCODE: "BEN",
    CITYNAME: "BENIDORM",
    CITYNAME_HEB: "\u05d1\u05e0\u05d9\u05d3\u05e8\u05d5\u05dd",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "PSR",
    AIRPORTNAME: "Liberi",
    CITYCODE: "PSR",
    CITYNAME: "PESCARA",
    CITYNAME_HEB: "\u05e4\u05d0\u05e1\u05e7\u05e8\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PSS",
    AIRPORTNAME: null,
    CITYCODE: "PSS",
    CITYNAME: "POSADAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PST",
    AIRPORTNAME: "Preston",
    CITYCODE: "PST",
    CITYNAME: "PRESTON",
    CITYNAME_HEB: "PRESTON",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "PSU",
    AIRPORTNAME: "Putussibau",
    CITYCODE: "PSU",
    CITYNAME: "PUTUSSIBAU",
    CITYNAME_HEB: "PUTUSSIBAU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PSV",
    AIRPORTNAME: "Papa Stour",
    CITYCODE: "PSV",
    CITYNAME: "PAPA STOUR",
    CITYNAME_HEB: "PAPA STOUR",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PSW",
    AIRPORTNAME: null,
    CITYCODE: "PSW",
    CITYNAME: "PASSOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PSX",
    AIRPORTNAME: "Palacios",
    CITYCODE: "PSX",
    CITYNAME: "PALACIOS",
    CITYNAME_HEB: "PALACIOS",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PSY",
    AIRPORTNAME: "Port Stanley",
    CITYCODE: "PSY",
    CITYNAME: "PORT STANLEY",
    CITYNAME_HEB: "PORT STANLEY",
    COUNTRYCODE: "FK",
    COUNTRYNAME: "FALKLAND ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d5\u05e7\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PSZ",
    AIRPORTNAME: "Puerto Suarez",
    CITYCODE: "PSZ",
    CITYNAME: "PUERTO SUAREZ",
    CITYNAME_HEB: "PUERTO SUAREZ",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PTA",
    AIRPORTNAME: "Port Alsworth",
    CITYCODE: "PTA",
    CITYNAME: "PORT ALSWORTH",
    CITYNAME_HEB: "Port Alsworth",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BEP",
    AIRPORTNAME: "Bellary",
    CITYCODE: "BEP",
    CITYNAME: "BELLARY",
    CITYNAME_HEB: "BELLARY",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "PTB",
    AIRPORTNAME: "Municipal",
    CITYCODE: "PTB",
    CITYNAME: "PETERSBURG",
    CITYNAME_HEB: "\u05e4\u05d9\u05d8\u05e8\u05e1\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "PTC",
    AIRPORTNAME: "Port Alice",
    CITYCODE: "PTC",
    CITYNAME: "PORT ALICE",
    CITYNAME_HEB: "Port Alice",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PTD",
    AIRPORTNAME: "Port Alexander",
    CITYCODE: "PTD",
    CITYNAME: "PORT ALEXANDER",
    CITYNAME_HEB: "Port Alexander",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PTE",
    AIRPORTNAME: "Port Stephens",
    CITYCODE: "PTE",
    CITYNAME: "PORT STEPHENS",
    CITYNAME_HEB: "PORT STEPHENS",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PTF",
    AIRPORTNAME: "Malololailai",
    CITYCODE: "PTF",
    CITYNAME: "MALOLOLAILAI",
    CITYNAME_HEB: "MALOLOLAILAI",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "PTG",
    AIRPORTNAME: "Pietersburg",
    CITYCODE: "PTG",
    CITYNAME: "PIETERSBURG",
    CITYNAME_HEB: "PIETERSBURG",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "PTH",
    AIRPORTNAME: "Port Heiden",
    CITYCODE: "PTH",
    CITYNAME: "PORT HEIDEN",
    CITYNAME_HEB: "Port Heiden",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PTI",
    AIRPORTNAME: "Port Douglas",
    CITYCODE: "PTI",
    CITYNAME: "PORT DOUGLAS",
    CITYNAME_HEB: "PORT DOUGLAS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PTJ",
    AIRPORTNAME: "Portland",
    CITYCODE: "PTJ",
    CITYNAME: "PORTLAND",
    CITYNAME_HEB: "PORTLAND",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BEQ",
    AIRPORTNAME: "Honington",
    CITYCODE: "BEQ",
    CITYNAME: "BURY ST EDMUNDS",
    CITYNAME_HEB: "BURY ST EDMUNDS",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PTK",
    AIRPORTNAME: "Pontiac",
    CITYCODE: "PTK",
    CITYNAME: "PONTIAC",
    CITYNAME_HEB: "PONTIAC",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PTL",
    AIRPORTNAME: "Port Armstrong",
    CITYCODE: "PTL",
    CITYNAME: "PORT ARMSTRONG",
    CITYNAME_HEB: "Port Armstrong",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PTM",
    AIRPORTNAME: "Palmarito",
    CITYCODE: "PTM",
    CITYNAME: "PALMARITO",
    CITYNAME_HEB: "PALMARITO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PTN",
    AIRPORTNAME: "Williams Memorial",
    CITYCODE: "PTN",
    CITYNAME: "MORGAN CITY",
    CITYNAME_HEB: "MORGAN CITY",
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB:
      '\u05dc\u05d5\u05d0\u05d9\u05d6\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PTO",
    AIRPORTNAME: null,
    CITYCODE: "PTO",
    CITYNAME: "PATO BRANCO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PTP",
    AIRPORTNAME: "Le Raizet",
    CITYCODE: "PTP",
    CITYNAME: "POINTE A PITRE",
    CITYNAME_HEB: "POINTE A PITRE",
    COUNTRYCODE: "GP",
    COUNTRYNAME: "GUADELOUPE",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d2\u05d5\u05d5\u05d3\u05d0\u05dc\u05d5\u05e4",
  },
  {
    AIRPORTCODE: "PTQ",
    AIRPORTNAME: null,
    CITYCODE: "PTQ",
    CITYNAME: "PORTO DE MOZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PTR",
    AIRPORTNAME: "Pleasant Harbour",
    CITYCODE: "PTR",
    CITYNAME: "PLEASANT HARBOUR",
    CITYNAME_HEB: "Pleasant Harbour",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PTS",
    AIRPORTNAME: "Municipal",
    CITYCODE: "PTS",
    CITYNAME: "PITTSBURG",
    CITYNAME_HEB: "\u05e4\u05d9\u05d8\u05e1\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PTT",
    AIRPORTNAME: " ",
    CITYCODE: "PTT",
    CITYNAME: "PANAMA",
    CITYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "BER",
    AIRPORTNAME: " ",
    CITYCODE: "BER",
    CITYNAME: "BERLIN",
    CITYNAME_HEB: "\u05d1\u05e8\u05dc\u05d9\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PTU",
    AIRPORTNAME: "Platinum",
    CITYCODE: "PTU",
    CITYNAME: "PLATINUM",
    CITYNAME_HEB: "Platinum",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PTV",
    AIRPORTNAME: "Porterville",
    CITYCODE: "PTV",
    CITYNAME: "PORTERVILLE",
    CITYNAME_HEB: "PORTERVILLE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PTW",
    AIRPORTNAME: "Pottstown/Limerick",
    CITYCODE: "PTW",
    CITYNAME: "POTTSTOWN",
    CITYNAME_HEB: "POTTSTOWN",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PTX",
    AIRPORTNAME: "Pitalito",
    CITYCODE: "PTX",
    CITYNAME: "PITALITO",
    CITYNAME_HEB: "PITALITO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PTY",
    AIRPORTNAME: "Tocumen International",
    CITYCODE: "PTY",
    CITYNAME: "PANAMA CITY",
    CITYNAME_HEB: "\u05e4\u05e0\u05de\u05d4 \u05e1\u05d9\u05d8\u05d9",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PTZ",
    AIRPORTNAME: "Pastaza",
    CITYCODE: "PTZ",
    CITYNAME: "PASTAZA",
    CITYNAME_HEB: "PASTAZA",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "PUA",
    AIRPORTNAME: "Puas",
    CITYCODE: "PUA",
    CITYNAME: "PUAS",
    CITYNAME_HEB: "PUAS",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "PUB",
    AIRPORTNAME: "Memorial",
    CITYCODE: "PUB",
    CITYNAME: "PUEBLO",
    CITYNAME_HEB: "PUEBLO",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PUC",
    AIRPORTNAME: "Carbon County",
    CITYCODE: "PUC",
    CITYNAME: "PRICE",
    CITYNAME_HEB: "PRICE",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SXF",
    AIRPORTNAME: "Schoenefeld",
    CITYCODE: "BER",
    CITYNAME: "BERLIN",
    CITYNAME_HEB: "\u05d1\u05e8\u05dc\u05d9\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PUD",
    AIRPORTNAME: null,
    CITYCODE: "PUD",
    CITYNAME: "PUERTO DESEADO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PUE",
    AIRPORTNAME: "Puerto Obaldia",
    CITYCODE: "PUE",
    CITYNAME: "PUERTO OBALDIA",
    CITYNAME_HEB: "PUERTO OBALDIA",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PUF",
    AIRPORTNAME: "Uzein",
    CITYCODE: "PUF",
    CITYNAME: "PAU",
    CITYNAME_HEB: "PAU",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "PUG",
    AIRPORTNAME: "Port Augusta",
    CITYCODE: "PUG",
    CITYNAME: "PORT AUGUSTA",
    CITYNAME_HEB: "PORT AUGUSTA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PUH",
    AIRPORTNAME: "Pochutla",
    CITYCODE: "PUH",
    CITYNAME: "POCHUTLA",
    CITYNAME_HEB: "POCHUTLA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PUI",
    AIRPORTNAME: "Pureni",
    CITYCODE: "PUI",
    CITYNAME: "PURENI",
    CITYNAME_HEB: "PURENI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "PUJ",
    AIRPORTNAME: "Punta Cana",
    CITYCODE: "PUJ",
    CITYNAME: "PUNTA CANA",
    CITYNAME_HEB: "\u05e4\u05d5\u05e0\u05d8\u05d4 \u05e7\u05d0\u05e0\u05d4",
    COUNTRYCODE: "DO",
    COUNTRYNAME: "DOMINICAN REPUBLIC",
    COUNTRYNAME_HEB:
      "\u05d4\u05e8\u05e4\u05d5\u05d1\u05dc\u05d9\u05e7\u05d4 \u05d4\u05d3\u05d5\u05de\u05d9\u05e0\u05d9\u05e7\u05e0\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "PUK",
    AIRPORTNAME: "Pukarua",
    CITYCODE: "PUK",
    CITYNAME: "PUKARUA",
    CITYNAME_HEB: "PUKARUA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "PUL",
    AIRPORTNAME: "Poulsbo",
    CITYCODE: "PUL",
    CITYNAME: "POULSBO",
    CITYNAME_HEB: "POULSBO",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PUM",
    AIRPORTNAME: "Pomala",
    CITYCODE: "PUM",
    CITYNAME: "POMALA",
    CITYNAME_HEB: "POMALA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "THF",
    AIRPORTNAME: "Tempelhof",
    CITYCODE: "BER",
    CITYNAME: "BERLIN",
    CITYNAME_HEB: "\u05d1\u05e8\u05dc\u05d9\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PUN",
    AIRPORTNAME: "Punia",
    CITYCODE: "PUN",
    CITYNAME: "PUNIA",
    CITYNAME_HEB: "PUNIA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "PUO",
    AIRPORTNAME: "Prudhoe Bay",
    CITYCODE: "PUO",
    CITYNAME: "PRUDHOE BAY",
    CITYNAME_HEB: "Prudhoe Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PUQ",
    AIRPORTNAME: "Pres Ibanez",
    CITYCODE: "PUQ",
    CITYNAME: "PUNTA ARENAS",
    CITYNAME_HEB: "\u05e4\u05d5\u05e0\u05d8\u05d4 \u05d0\u05e8\u05e0\u05e1",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PUR",
    AIRPORTNAME: "Puerto Rico",
    CITYCODE: "PUR",
    CITYNAME: "PUERTO RICO",
    CITYNAME_HEB: "PUERTO RICO",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PUS",
    AIRPORTNAME: "Kimhae",
    CITYCODE: "PUS",
    CITYNAME: "BUSAN",
    CITYNAME_HEB: "\u05d1\u05d5\u05e1\u05d0\u05df",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "PUT",
    AIRPORTNAME: "Puttaprathe",
    CITYCODE: "PUT",
    CITYNAME: "PUTTAPARTHI",
    CITYNAME_HEB: "PUTTAPARTHI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "PUU",
    AIRPORTNAME: "Puerto Asis",
    CITYCODE: "PUU",
    CITYNAME: "PUERTO ASIS",
    CITYNAME_HEB: "PUERTO ASIS",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PUV",
    AIRPORTNAME: "Poum",
    CITYCODE: "PUV",
    CITYNAME: "POUM",
    CITYNAME_HEB: "POUM",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "PUW",
    AIRPORTNAME: "Moscow Regional",
    CITYCODE: "PUW",
    CITYNAME: "PULLMAN",
    CITYNAME_HEB: "PULLMAN",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PUX",
    AIRPORTNAME: "Puerto Varas",
    CITYCODE: "PUX",
    CITYNAME: "PUERTO VARAS",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05d0\u05e8\u05d8\u05d5 \u05d5\u05d5\u05d0\u05e8\u05e1",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "TXL",
    AIRPORTNAME: "Tegel",
    CITYCODE: "BER",
    CITYNAME: "BERLIN",
    CITYNAME_HEB: "\u05d1\u05e8\u05dc\u05d9\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PUY",
    AIRPORTNAME: "Pula",
    CITYCODE: "PUY",
    CITYNAME: "PULA",
    CITYNAME_HEB: "\u05e4\u05d5\u05dc\u05d4",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PUZ",
    AIRPORTNAME: "Puerto Cabezas",
    CITYCODE: "PUZ",
    CITYNAME: "PUERTO CABEZAS",
    CITYNAME_HEB: "PUERTO CABEZAS",
    COUNTRYCODE: "NI",
    COUNTRYNAME: "NICARAGUA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05e7\u05e8\u05d0\u05d2\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "PVA",
    AIRPORTNAME: "Providencia",
    CITYCODE: "PVA",
    CITYNAME: "PROVIDENCIA",
    CITYNAME_HEB: "PROVIDENCIA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PVC",
    AIRPORTNAME: "Provincetown",
    CITYCODE: "PVC",
    CITYNAME: "PROVINCETOWN",
    CITYNAME_HEB: "PROVINCETOWN",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "PVD",
    AIRPORTNAME: "Theodore Francis",
    CITYCODE: "PVD",
    CITYNAME: "PROVIDENCE",
    CITYNAME_HEB: "PROVIDENCE",
    COUNTRYCODE: "USRI",
    COUNTRYNAME: "RHODE ISLAND",
    COUNTRYNAME_HEB:
      '\u05e8\u05d5\u05d3 \u05d0\u05d9\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZRV",
    AIRPORTNAME: null,
    CITYCODE: "PVD",
    CITYNAME: "PROVIDENCE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USRI",
    COUNTRYNAME: "RHODE ISLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PVE",
    AIRPORTNAME: "El Porvenir",
    CITYCODE: "PVE",
    CITYNAME: "EL PORVENIR",
    CITYNAME_HEB: "EL PORVENIR",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PVF",
    AIRPORTNAME: "Placerville",
    CITYCODE: "PVF",
    CITYNAME: "PLACERVILLE",
    CITYNAME_HEB: "PLACERVILLE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PVH",
    AIRPORTNAME: null,
    CITYCODE: "PVH",
    CITYNAME: "PORTO VELHO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PVI",
    AIRPORTNAME: null,
    CITYCODE: "PVI",
    CITYNAME: "PARANAVAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PVK",
    AIRPORTNAME: "Aktion",
    CITYCODE: "PVK",
    CITYNAME: "LEFKADA",
    CITYNAME_HEB: "\u05dc\u05e4\u05e7\u05d3\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "PVN",
    AIRPORTNAME: "Pleven",
    CITYCODE: "PVN",
    CITYNAME: "PLEVEN",
    CITYNAME_HEB: "\u05e4\u05dc\u05d1\u05df",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PVO",
    AIRPORTNAME: "Portoviejo",
    CITYCODE: "PVO",
    CITYNAME: "PORTOVIEJO",
    CITYNAME_HEB: "PORTOVIEJO",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "PVR",
    AIRPORTNAME: "Ordaz",
    CITYCODE: "PVR",
    CITYNAME: "PUERTO VALLARTA",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05d5\u05d9\u05d0\u05e8\u05d8\u05d4",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PVS",
    AIRPORTNAME: "Provideniya",
    CITYCODE: "PVS",
    CITYNAME: "PROVIDENIYA",
    CITYNAME_HEB: "PROVIDENIYA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PVU",
    AIRPORTNAME: "Provo",
    CITYCODE: "PVU",
    CITYNAME: "PROVO",
    CITYNAME_HEB: "PROVO",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PVW",
    AIRPORTNAME: "Hale County",
    CITYCODE: "PVW",
    CITYNAME: "PLAINVIEW",
    CITYNAME_HEB: "PLAINVIEW",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PVX",
    AIRPORTNAME: "Provedenia",
    CITYCODE: "PVX",
    CITYNAME: "PROVEDENIA",
    CITYNAME_HEB: "PROVEDENIA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PVY",
    AIRPORTNAME: "Pope Vanoy",
    CITYCODE: "PVY",
    CITYNAME: "POPE VANOY",
    CITYNAME_HEB: "Pope Vanoy",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PVZ",
    AIRPORTNAME: "Casement",
    CITYCODE: "PVZ",
    CITYNAME: "PAINESVILLE",
    CITYNAME_HEB: "PAINESVILLE",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EKA",
    AIRPORTNAME: "Arcata Murray Field",
    CITYCODE: "ACV",
    CITYNAME: "ARCATA",
    CITYNAME_HEB: "Arcata Murray Field",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PWD",
    AIRPORTNAME: "Sherwood",
    CITYCODE: "PWD",
    CITYNAME: "PLENTYWOOD",
    CITYNAME_HEB: "PLENTYWOOD",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PWE",
    AIRPORTNAME: "Pevek",
    CITYCODE: "PWE",
    CITYNAME: "PEVEK",
    CITYNAME_HEB: "PEVEK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PWI",
    AIRPORTNAME: "Beles",
    CITYCODE: "PWI",
    CITYNAME: "PAWI",
    CITYNAME_HEB: "PAWI",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PWL",
    AIRPORTNAME: "Purwokerto",
    CITYCODE: "PWL",
    CITYNAME: "PURWOKERTO",
    CITYNAME_HEB: "PURWOKERTO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PWM",
    AIRPORTNAME: "International Jetport",
    CITYCODE: "PWM",
    CITYNAME: "PORTLAND",
    CITYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05dc\u05e0\u05d3",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PWN",
    AIRPORTNAME: "Pitts Town",
    CITYCODE: "PWN",
    CITYNAME: "PITTS TOWN",
    CITYNAME_HEB: "PITTS TOWN",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PWO",
    AIRPORTNAME: "Pweto",
    CITYCODE: "PWO",
    CITYNAME: "PWETO",
    CITYNAME_HEB: "PWETO",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "PWQ",
    AIRPORTNAME: "Pavlodar",
    CITYCODE: "PWQ",
    CITYNAME: "PAVLODAR",
    CITYNAME_HEB: "PAVLODAR",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "PWR",
    AIRPORTNAME: "Port Walter",
    CITYCODE: "PWR",
    CITYNAME: "PORT WALTER",
    CITYNAME_HEB: "Port Walter",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PWT",
    AIRPORTNAME: "Bremerton",
    CITYCODE: "PWT",
    CITYNAME: "BREMERTON",
    CITYNAME_HEB: "BREMERTON",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PXL",
    AIRPORTNAME: "Polacca",
    CITYCODE: "PXL",
    CITYNAME: "POLACCA",
    CITYNAME_HEB: "POLACCA",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PXM",
    AIRPORTNAME: "Puerto Escondido",
    CITYCODE: "PXM",
    CITYNAME: "PUERTO ESCONDIDO",
    CITYNAME_HEB: "PUERTO ESCONDIDO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PXO",
    AIRPORTNAME: "Porto Santo",
    CITYCODE: "PXO",
    CITYNAME: "PORTO SANTO",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e1\u05d0\u05e0\u05d8\u05d5",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "PXU",
    AIRPORTNAME: "Pleiku",
    CITYCODE: "PXU",
    CITYNAME: "PLEIKU",
    CITYNAME_HEB: "PLEIKU",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "PYA",
    AIRPORTNAME: "Puerto Boyaca",
    CITYCODE: "PYA",
    CITYNAME: "PUERTO BOYACA",
    CITYNAME_HEB: "PUERTO BOYACA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PYB",
    AIRPORTNAME: "Jeypore",
    CITYCODE: "PYB",
    CITYNAME: "JEYPORE",
    CITYNAME_HEB: "JEYPORE",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "PYC",
    AIRPORTNAME: "Playon Chico",
    CITYCODE: "PYC",
    CITYNAME: "PLAYON CHICO",
    CITYNAME_HEB: "PLAYON CHICO",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PYE",
    AIRPORTNAME: "Penrhyn Island",
    CITYCODE: "PYE",
    CITYNAME: "PENRHYN ISLAND",
    CITYNAME_HEB: "PENRHYN ISLAND",
    COUNTRYCODE: "CK",
    COUNTRYNAME: "COOK ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e7\u05d5\u05e7",
  },
  {
    AIRPORTCODE: "PYH",
    AIRPORTNAME: "Puerto Ayacucho",
    CITYCODE: "PYH",
    CITYNAME: "PUERTO AYACUCHO",
    CITYNAME_HEB: "PUERTO AYACUCHO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PYJ",
    AIRPORTNAME: "Polyarnyj",
    CITYCODE: "PYJ",
    CITYNAME: "POLYARNYJ",
    CITYNAME_HEB: "POLYARNYJ",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PYL",
    AIRPORTNAME: "Perry SPB",
    CITYCODE: "PYL",
    CITYNAME: "PERRY ISLAND",
    CITYNAME_HEB: "Perry SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PYM",
    AIRPORTNAME: "Plymouth",
    CITYCODE: "PYM",
    CITYNAME: "PLYMOUTH",
    CITYNAME_HEB: "PLYMOUTH",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "PYN",
    AIRPORTNAME: " ",
    CITYCODE: "PYN",
    CITYNAME: "PYRENEES",
    CITYNAME_HEB: "\u05e4\u05e8\u05d9\u05e0\u05d0\u05d9\u05dd",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "PYO",
    AIRPORTNAME: "Putumayo",
    CITYCODE: "PYO",
    CITYNAME: "PUTUMAYO",
    CITYNAME_HEB: "PUTUMAYO",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "PYR",
    AIRPORTNAME: "Andravida",
    CITYCODE: "PYR",
    CITYNAME: "PYRGOS",
    CITYNAME_HEB: "\u05e4\u05d9\u05e8\u05d2\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "PYV",
    AIRPORTNAME: "Yaviza",
    CITYCODE: "PYV",
    CITYNAME: "YAVIZA",
    CITYNAME_HEB: "YAVIZA",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PYX",
    AIRPORTNAME: "Pattaya",
    CITYCODE: "PYX",
    CITYNAME: "PATTAYA",
    CITYNAME_HEB: "\u05e4\u05d8\u05d0\u05d9\u05d9\u05d4",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PZA",
    AIRPORTNAME: "Casanare",
    CITYCODE: "PZA",
    CITYNAME: "PAZ DE ARIPORO",
    CITYNAME_HEB: "PAZ DE ARIPORO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PZB",
    AIRPORTNAME: "Pietermaritzburg",
    CITYCODE: "PZB",
    CITYNAME: "PIETERMARITZBURG",
    CITYNAME_HEB: "PIETERMARITZBURG",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "PZE",
    AIRPORTNAME: "Penzance",
    CITYCODE: "PZE",
    CITYNAME: "PENZANCE",
    CITYNAME_HEB: "PENZANCE",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BES",
    AIRPORTNAME: "Guipavas",
    CITYCODE: "BES",
    CITYNAME: "BREST",
    CITYNAME_HEB: "BREST",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "PZH",
    AIRPORTNAME: "Zhob",
    CITYCODE: "PZH",
    CITYNAME: "ZHOB",
    CITYNAME_HEB: "ZHOB",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "PZK",
    AIRPORTNAME: "Puka Puka Island/Attol",
    CITYCODE: "PZK",
    CITYNAME: "PUKA PUKA ISLD",
    CITYNAME_HEB: "PUKA PUKA ISLD",
    COUNTRYCODE: "CK",
    COUNTRYNAME: "COOK ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e7\u05d5\u05e7",
  },
  {
    AIRPORTCODE: "BAD",
    AIRPORTNAME: " ",
    CITYCODE: "BAD",
    CITYNAME: "BADEN",
    CITYNAME_HEB: "\u05d1\u05d0\u05d3\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PZO",
    AIRPORTNAME: "Puerto Ordaz",
    CITYCODE: "PZO",
    CITYNAME: "PUERTO ORDAZ",
    CITYNAME_HEB: "PUERTO ORDAZ",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PZU",
    AIRPORTNAME: "Port Sudan",
    CITYCODE: "PZU",
    CITYNAME: "PORT SUDAN",
    CITYNAME_HEB: "PORT SUDAN",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "PZY",
    AIRPORTNAME: "Piestany",
    CITYCODE: "PZY",
    CITYNAME: "PIESTANY",
    CITYNAME_HEB: "\u05e4\u05d9\u05e9\u05d8\u05e0\u05d9",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QAC",
    AIRPORTNAME: null,
    CITYCODE: "QAC",
    CITYNAME: "CASTRO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAD",
    AIRPORTNAME: null,
    CITYCODE: "QAD",
    CITYNAME: "PORDENONE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAE",
    AIRPORTNAME: null,
    CITYCODE: "QAE",
    CITYNAME: "ARZEW",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAF",
    AIRPORTNAME: null,
    CITYCODE: "QAF",
    CITYNAME: "OFFLINE POINT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAG",
    AIRPORTNAME: null,
    CITYCODE: "QAG",
    CITYNAME: "EL GHAZAOUET",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BET",
    AIRPORTNAME: "Bethel Airport",
    CITYCODE: "BET",
    CITYNAME: "BETHEL",
    CITYNAME_HEB: "Bethel Airport",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QAH",
    AIRPORTNAME: null,
    CITYCODE: "QAH",
    CITYNAME: "ALCANTARA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAI",
    AIRPORTNAME: null,
    CITYCODE: "QAI",
    CITYNAME: "AIME",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAJ",
    AIRPORTNAME: null,
    CITYCODE: "QAJ",
    CITYNAME: "AJMAN CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AE",
    COUNTRYNAME: "UNITED ARAB EMIRATES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAK",
    AIRPORTNAME: null,
    CITYCODE: "QAK",
    CITYNAME: "BARBACENA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAL",
    AIRPORTNAME: null,
    CITYCODE: "QAL",
    CITYNAME: "ALESSANDRIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAM",
    AIRPORTNAME: null,
    CITYCODE: "QAM",
    CITYNAME: "AMIENS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAN",
    AIRPORTNAME: null,
    CITYCODE: "QAN",
    CITYNAME: "NEDROMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAO",
    AIRPORTNAME: " ",
    CITYCODE: "QAO",
    CITYNAME: "AGRIGENTO",
    CITYNAME_HEB: "\u05d0\u05d2\u05e8\u05d9\u05d2\u05e0\u05d8\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QAP",
    AIRPORTNAME: null,
    CITYCODE: "QAP",
    CITYNAME: "APAPA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAQ",
    AIRPORTNAME: null,
    CITYCODE: "QAQ",
    CITYNAME: "LAQUILA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JBT",
    AIRPORTNAME: "Bethel-City Landing",
    CITYCODE: "BET",
    CITYNAME: "BETHEL",
    CITYNAME_HEB: "Bethel-City Landing",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QAR",
    AIRPORTNAME: null,
    CITYCODE: "QAR",
    CITYNAME: "ARNHEM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAS",
    AIRPORTNAME: null,
    CITYCODE: "QAS",
    CITYNAME: "ECH CHELIFF",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAT",
    AIRPORTNAME: null,
    CITYCODE: "QAT",
    CITYNAME: "ABEOKUTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAU",
    AIRPORTNAME: null,
    CITYCODE: "QAU",
    CITYNAME: "BEBEDOURO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAV",
    AIRPORTNAME: null,
    CITYCODE: "QAV",
    CITYNAME: "BENJAMIN CONSTANT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAX",
    AIRPORTNAME: null,
    CITYCODE: "QAX",
    CITYNAME: "ABA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QAY",
    AIRPORTNAME: " ",
    CITYCODE: "QAY",
    CITYNAME: "ALBA IULIA",
    CITYNAME_HEB:
      "\u05d0\u05dc\u05d1\u05d4 \u05d0\u05d9\u05d5\u05dc\u05d9\u05d4",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QAZ",
    AIRPORTNAME: " ",
    CITYCODE: "QAZ",
    CITYNAME: "ZAKOPANE",
    CITYNAME_HEB: "\u05d6\u05e7\u05d5\u05e4\u05d0\u05e0\u05d4",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "QBA",
    AIRPORTNAME: " ",
    CITYCODE: "QBA",
    CITYNAME: "BUDVA",
    CITYNAME_HEB: "\u05d1\u05d5\u05d3\u05d5\u05d5\u05d4",
    COUNTRYCODE: "ME",
    COUNTRYNAME: "MONTENEGRO",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "BEU",
    AIRPORTNAME: "Bedourie",
    CITYCODE: "BEU",
    CITYNAME: "BEDOURIE",
    CITYNAME_HEB: "BEDOURIE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QBB",
    AIRPORTNAME: null,
    CITYCODE: "QBB",
    CITYNAME: "BEL ABBES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBC",
    AIRPORTNAME: "Bella Coola",
    CITYCODE: "QBC",
    CITYNAME: "BELLA COOLA",
    CITYNAME_HEB: "BELLA COOLA",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "QBD",
    AIRPORTNAME: null,
    CITYCODE: "QBD",
    CITYNAME: "BARRA DO PIRAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBE",
    AIRPORTNAME: null,
    CITYCODE: "QBE",
    CITYNAME: "BEGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBG",
    AIRPORTNAME: null,
    CITYCODE: "QBG",
    CITYNAME: "PANCEVO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBH",
    AIRPORTNAME: null,
    CITYCODE: "QBH",
    CITYNAME: "LEVALLOIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBI",
    AIRPORTNAME: " ",
    CITYCODE: "QBI",
    CITYNAME: "BITOLA",
    CITYNAME_HEB: "\u05d1\u05d9\u05d8\u05d5\u05dc\u05d4",
    COUNTRYCODE: "MK",
    COUNTRYNAME: "MACEDONIA",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05d3\u05d5\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QBJ",
    AIRPORTNAME: null,
    CITYCODE: "QBJ",
    CITYNAME: "BORDJ BOU ARRERI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBK",
    AIRPORTNAME: null,
    CITYCODE: "QBK",
    CITYNAME: "BETIM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BEV",
    AIRPORTNAME: null,
    CITYCODE: "BEV",
    CITYNAME: "BEER SHEBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBL",
    AIRPORTNAME: null,
    CITYCODE: "QBL",
    CITYNAME: "BANI WALID",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBM",
    AIRPORTNAME: null,
    CITYCODE: "QBM",
    CITYNAME: "BOURG ST MAURICE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBN",
    AIRPORTNAME: null,
    CITYCODE: "QBN",
    CITYNAME: "BARRA MANSA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBO",
    AIRPORTNAME: null,
    CITYCODE: "QBO",
    CITYNAME: "BOCHUM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBP",
    AIRPORTNAME: null,
    CITYCODE: "QBP",
    CITYNAME: "AIN BEIDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBQ",
    AIRPORTNAME: null,
    CITYCODE: "QBQ",
    CITYNAME: "BESANCON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBT",
    AIRPORTNAME: null,
    CITYCODE: "QBT",
    CITYNAME: "BETTIOUA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBV",
    AIRPORTNAME: null,
    CITYCODE: "QBV",
    CITYNAME: "BENEVENTO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BEW",
    AIRPORTNAME: "Beira",
    CITYCODE: "BEW",
    CITYNAME: "BEIRA",
    CITYNAME_HEB: "BEIRA",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "QBW",
    AIRPORTNAME: null,
    CITYCODE: "QBW",
    CITYNAME: "BATEMANS BAY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBX",
    AIRPORTNAME: null,
    CITYCODE: "QBX",
    CITYNAME: "SOBRAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QBY",
    AIRPORTNAME: " ",
    CITYCODE: "QBY",
    CITYNAME: "BISTRITA NASAUD",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QBZ",
    AIRPORTNAME: null,
    CITYCODE: "QBZ",
    CITYNAME: "BOUIRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCB",
    AIRPORTNAME: null,
    CITYCODE: "QCB",
    CITYNAME: "CHIBA CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCC",
    AIRPORTNAME: null,
    CITYCODE: "QCC",
    CITYNAME: "CAMACARI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCD",
    AIRPORTNAME: null,
    CITYCODE: "QCD",
    CITYNAME: "CAMPO BOM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCE",
    AIRPORTNAME: null,
    CITYCODE: "QCE",
    CITYNAME: "COPPER MOUNTAIN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCF",
    AIRPORTNAME: null,
    CITYCODE: "QCF",
    CITYNAME: "BIRIGUI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCG",
    AIRPORTNAME: null,
    CITYCODE: "QCG",
    CITYNAME: "CATAGUASES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BEX",
    AIRPORTNAME: "RAF Station",
    CITYCODE: "BEX",
    CITYNAME: "BENSON",
    CITYNAME_HEB: "BENSON",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QCH",
    AIRPORTNAME: null,
    CITYCODE: "QCH",
    CITYNAME: "COLATINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCI",
    AIRPORTNAME: " ",
    CITYCODE: "QCI",
    CITYNAME: "PLAYA DELOS CRIST",
    CITYNAME_HEB:
      "\u05e4\u05dc\u05d0\u05d9\u05d4 \u05d3\u05dc\u05d5\u05e1 \u05db\u05e8\u05d9\u05e1\u05d8",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "QCJ",
    AIRPORTNAME: null,
    CITYCODE: "QCJ",
    CITYNAME: "BOTUCATU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCL",
    AIRPORTNAME: null,
    CITYCODE: "QCL",
    CITYNAME: "CALTANISSETTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCM",
    AIRPORTNAME: " ",
    CITYCODE: "QCM",
    CITYNAME: "COMO",
    CITYNAME_HEB: "\u05e7\u05d5\u05de\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QCN",
    AIRPORTNAME: null,
    CITYCODE: "QCN",
    CITYNAME: "CANELA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCO",
    AIRPORTNAME: null,
    CITYCODE: "QCO",
    CITYNAME: "COLON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCP",
    AIRPORTNAME: null,
    CITYCODE: "QCP",
    CITYNAME: "CURRAIS NOVOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QCQ",
    AIRPORTNAME: null,
    CITYCODE: "QCQ",
    CITYNAME: "CARAGUATATUBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ADA",
    AIRPORTNAME: "Adana",
    CITYCODE: "ADA",
    CITYNAME: "ADANA",
    CITYNAME_HEB: "\u05d0\u05d3\u05d0\u05e0\u05d4",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OBM",
    AIRPORTNAME: "Morobe",
    CITYCODE: "OBM",
    CITYNAME: "MOROBE",
    CITYNAME_HEB: "MOROBE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "OBN",
    AIRPORTNAME: "Connel",
    CITYCODE: "OBN",
    CITYNAME: "OBAN",
    CITYNAME_HEB: "OBAN",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OHP",
    AIRPORTNAME: "Heliport",
    CITYCODE: "OBN",
    CITYNAME: "OBAN",
    CITYNAME_HEB: "OBAN",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OBO",
    AIRPORTNAME: "Obihiro",
    CITYCODE: "OBO",
    CITYNAME: "OBIHIRO",
    CITYNAME_HEB: "OBIHIRO",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "OBS",
    AIRPORTNAME: "Vals-Lanas",
    CITYCODE: "OBS",
    CITYNAME: "AUBENAS",
    CITYNAME_HEB: "AUBENAS",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "ODM",
    AIRPORTNAME: "Oakland/Coliseum Stat",
    CITYCODE: "ODM",
    CITYNAME: "OAKLAND",
    CITYNAME_HEB: "\u05d0\u05d5\u05e7\u05dc\u05e0\u05d3",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OBU",
    AIRPORTNAME: "Kobuk/Wien",
    CITYCODE: "OBU",
    CITYNAME: "KOBUK",
    CITYNAME_HEB: "Kobuk/Wien",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OBX",
    AIRPORTNAME: "Obo",
    CITYCODE: "OBX",
    CITYNAME: "OBO",
    CITYNAME_HEB: "OBO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "OBY",
    AIRPORTNAME: "Ittoqqortoormiit",
    CITYCODE: "OBY",
    CITYNAME: "ITTOQQORTOORMIIT",
    CITYNAME_HEB: "ITTOQQORTOORMIIT",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "OCA",
    AIRPORTNAME: "Ocean Reef",
    CITYCODE: "OCA",
    CITYNAME: "OCEAN REEF",
    CITYNAME_HEB: "OCEAN REEF",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BBS",
    AIRPORTNAME: "Blackbush",
    CITYCODE: "BBS",
    CITYNAME: "BLACKBUSH",
    CITYNAME_HEB: "BLACKBUSH",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OCC",
    AIRPORTNAME: "Coca",
    CITYCODE: "OCC",
    CITYNAME: "COCA",
    CITYNAME_HEB: "COCA",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "OCE",
    AIRPORTNAME: "Municipal",
    CITYCODE: "OCE",
    CITYNAME: "OCEAN CITY",
    CITYNAME_HEB: "OCEAN CITY",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OCF",
    AIRPORTNAME: "Taylor Field",
    CITYCODE: "OCF",
    CITYNAME: "OCALA",
    CITYNAME_HEB: "OCALA",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LFK",
    AIRPORTNAME: "Lufkin Angelina County",
    CITYCODE: "OCH",
    CITYNAME: "NACOGDOCHES",
    CITYNAME_HEB: "NACOGDOCHES",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OCH",
    AIRPORTNAME: "Lufkin Nacogdoches",
    CITYCODE: "OCH",
    CITYNAME: "NACOGDOCHES",
    CITYNAME_HEB: "NACOGDOCHES",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OCI",
    AIRPORTNAME: "Oceanic",
    CITYCODE: "OCI",
    CITYNAME: "OCEANIC",
    CITYNAME_HEB: "Oceanic",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OCJ",
    AIRPORTNAME: "Boscobel",
    CITYCODE: "OCJ",
    CITYNAME: "OCHO RIOS",
    CITYNAME_HEB: "\u05d0\u05d5\u05e6\u05d5 \u05e8\u05d9\u05d5\u05e1",
    COUNTRYCODE: "JM",
    COUNTRYNAME: "JAMAICA",
    COUNTRYNAME_HEB: "\u05d2\u05de\u05d9\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "OCN",
    AIRPORTNAME: "Municipal",
    CITYCODE: "OCN",
    CITYNAME: "OCEANSIDE",
    CITYNAME_HEB: "OCEANSIDE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OCV",
    AIRPORTNAME: "Aguasclaras",
    CITYCODE: "OCV",
    CITYNAME: "OCANA",
    CITYNAME_HEB: "OCANA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OCW",
    AIRPORTNAME: "Warren Field",
    CITYCODE: "OCW",
    CITYNAME: "WASHINGTON",
    CITYNAME_HEB: "WASHINGTON",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BBT",
    AIRPORTNAME: null,
    CITYCODE: "BBT",
    CITYNAME: "BERBERATI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ODA",
    AIRPORTNAME: null,
    CITYCODE: "ODA",
    CITYNAME: "OUADDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ODB",
    AIRPORTNAME: "Cordoba",
    CITYCODE: "ODB",
    CITYNAME: "CORDOBA",
    CITYNAME_HEB: "\u05e7\u05d5\u05e8\u05d3\u05d5\u05d1\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "ODD",
    AIRPORTNAME: "Oodnadatta",
    CITYCODE: "ODD",
    CITYNAME: "OODNADATTA",
    CITYNAME_HEB: "OODNADATTA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ODE",
    AIRPORTNAME: "Beldringe",
    CITYCODE: "ODE",
    CITYNAME: "ODENSE",
    CITYNAME_HEB: "ODENSE",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "ZBQ",
    AIRPORTNAME: null,
    CITYCODE: "ODE",
    CITYNAME: "ODENSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZIM",
    AIRPORTNAME: null,
    CITYCODE: "ODE",
    CITYNAME: "ODENSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ODH",
    AIRPORTNAME: "RAF Station",
    CITYCODE: "ODH",
    CITYNAME: "ODIHAM",
    CITYNAME_HEB: "ODIHAM",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ODJ",
    AIRPORTNAME: null,
    CITYCODE: "ODJ",
    CITYNAME: "OUANDA DJALLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ODL",
    AIRPORTNAME: "Cordillo Downs",
    CITYCODE: "ODL",
    CITYNAME: "CORDILLO DOWNS",
    CITYNAME_HEB: "CORDILLO DOWNS",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BBV",
    AIRPORTNAME: "Bereby",
    CITYCODE: "BBV",
    CITYNAME: "BEREBY",
    CITYNAME_HEB: "BEREBY",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "ODN",
    AIRPORTNAME: " ",
    CITYCODE: "ODN",
    CITYNAME: "ORDINO ARCALIS",
    CITYNAME_HEB:
      "\u05d0\u05d5\u05e8\u05d3\u05d9\u05e0\u05d5 \u05d0\u05e8\u05e7\u05d9\u05dc\u05e1",
    COUNTRYCODE: "AD",
    COUNTRYNAME: "ANDORRA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d3\u05d5\u05e8\u05d4",
  },
  {
    AIRPORTCODE: "ODR",
    AIRPORTNAME: "Ord River",
    CITYCODE: "ODR",
    CITYNAME: "ORD RIVER",
    CITYNAME_HEB: "ORD RIVER",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ODS",
    AIRPORTNAME: "Central",
    CITYCODE: "ODS",
    CITYNAME: "ODESSA",
    CITYNAME_HEB: "\u05d0\u05d5\u05d3\u05e1\u05d4",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "ODW",
    AIRPORTNAME: "Oak Harbor",
    CITYCODE: "ODW",
    CITYNAME: "OAK HARBOR",
    CITYNAME_HEB: "OAK HARBOR",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ODY",
    AIRPORTNAME: "Oudomxay",
    CITYCODE: "ODY",
    CITYNAME: "OUDOMXAY",
    CITYNAME_HEB: "OUDOMXAY",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "OEA",
    AIRPORTNAME: "Oneal",
    CITYCODE: "OEA",
    CITYNAME: "VINCENNES",
    CITYNAME_HEB: "VINCENNES",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OEC",
    AIRPORTNAME: "Ocussi",
    CITYCODE: "OEC",
    CITYNAME: "OCUSSI",
    CITYNAME_HEB: "OCUSSI",
    COUNTRYCODE: "TP",
    COUNTRYNAME: "EAST TIMOR",
    COUNTRYNAME_HEB:
      "\u05d8\u05d9\u05de\u05d5\u05e8 \u05d4\u05de\u05d6\u05e8\u05d7\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "OEL",
    AIRPORTNAME: "Orel",
    CITYCODE: "OEL",
    CITYNAME: "OREL",
    CITYNAME_HEB: "OREL",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OEM",
    AIRPORTNAME: "Vincent Fayks",
    CITYCODE: "OEM",
    CITYNAME: "PALOEMEU",
    CITYNAME_HEB: "PALOEMEU",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "OEO",
    AIRPORTNAME: "Municipal",
    CITYCODE: "OEO",
    CITYNAME: "OSCEOLA",
    CITYNAME_HEB: "OSCEOLA",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BBW",
    AIRPORTNAME: "Broken Bow",
    CITYCODE: "BBW",
    CITYNAME: "BROKEN BOW",
    CITYNAME_HEB: "BROKEN BOW",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OER",
    AIRPORTNAME: "Ornskoldsvik",
    CITYCODE: "OER",
    CITYNAME: "ORNSKOLDSVIK",
    CITYNAME_HEB: "ORNSKOLDSVIK",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OES",
    AIRPORTNAME: null,
    CITYCODE: "OES",
    CITYNAME: "SAN ANTONIO OESTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OFI",
    AIRPORTNAME: "Ouango Fitini",
    CITYCODE: "OFI",
    CITYNAME: "OUANGO FITINI",
    CITYNAME_HEB: "OUANGO FITINI",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "OFJ",
    AIRPORTNAME: "Olafsfjordur",
    CITYCODE: "OFJ",
    CITYNAME: "OLAFSFJORDUR",
    CITYNAME_HEB: "OLAFSFJORDUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "OFK",
    AIRPORTNAME: "Stefan Field",
    CITYCODE: "OFK",
    CITYNAME: "NORFOLK",
    CITYNAME_HEB: "NORFOLK",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OFU",
    AIRPORTNAME: "Ofu",
    CITYCODE: "OFU",
    CITYNAME: "OFU",
    CITYNAME_HEB: "\u05d0\u05d5\u05e4\u05d5",
    COUNTRYCODE: "AS",
    COUNTRYNAME: "AMERICAN SAMOA",
    COUNTRYNAME_HEB:
      "\u05e1\u05de\u05d5\u05d0\u05d4 \u05d4\u05de\u05e2\u05e8\u05d1\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "OGA",
    AIRPORTNAME: "Searle Field",
    CITYCODE: "OGA",
    CITYNAME: "OGALLALA",
    CITYNAME_HEB: "OGALLALA",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OGB",
    AIRPORTNAME: "Municipal",
    CITYCODE: "OGB",
    CITYNAME: "ORANGEBURG",
    CITYNAME_HEB: "ORANGEBURG",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HIF",
    AIRPORTNAME: "Hill AFB",
    CITYCODE: "OGD",
    CITYNAME: "OGDEN",
    CITYNAME_HEB: "OGDEN",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OGD",
    AIRPORTNAME: "Municipal",
    CITYCODE: "OGD",
    CITYNAME: "OGDEN",
    CITYNAME_HEB: "OGDEN",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BBY",
    AIRPORTNAME: null,
    CITYCODE: "BBY",
    CITYNAME: "BAMBARI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OGE",
    AIRPORTNAME: "Ogeranang",
    CITYCODE: "OGE",
    CITYNAME: "OGERANANG",
    CITYNAME_HEB: "OGERANANG",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "OGG",
    AIRPORTNAME: "Kahului",
    CITYCODE: "OGG",
    CITYNAME: "KAHULUI",
    CITYNAME_HEB: "KAHULUI",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OGL",
    AIRPORTNAME: "Ogle",
    CITYCODE: "OGL",
    CITYNAME: "OGLE",
    CITYNAME_HEB: "OGLE",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "OGN",
    AIRPORTNAME: "Yonaguni Jima",
    CITYCODE: "OGN",
    CITYNAME: "YONAGUNI JIMA",
    CITYNAME_HEB: "YONAGUNI JIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "QPN",
    AIRPORTNAME: " ",
    CITYCODE: "QPN",
    CITYNAME: "PIATRA NEAMT",
    CITYNAME_HEB:
      "\u05e4\u05d9\u05d9\u05d8\u05e8\u05d4 \u05e0\u05d0\u05de\u05d8",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QPO",
    AIRPORTNAME: null,
    CITYCODE: "QPO",
    CITYNAME: "POTENZA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QPQ",
    AIRPORTNAME: null,
    CITYCODE: "QPQ",
    CITYNAME: "PINAMAR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QPR",
    AIRPORTNAME: null,
    CITYCODE: "QPR",
    CITYNAME: "PRATO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QPS",
    AIRPORTNAME: " ",
    CITYCODE: "QPS",
    CITYNAME: "PIRASSUNUNGA",
    CITYNAME_HEB:
      "\u05e4\u05d9\u05e8\u05e1\u05d5\u05e0\u05d0\u05e0\u05d2\u05d4",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "BGK",
    AIRPORTNAME: "Big Creek",
    CITYCODE: "BGK",
    CITYNAME: "BIG CREEK",
    CITYNAME_HEB: "BIG CREEK",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "QPV",
    AIRPORTNAME: null,
    CITYCODE: "QPV",
    CITYNAME: "PERISHER VALLEY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QPW",
    AIRPORTNAME: null,
    CITYCODE: "QPW",
    CITYNAME: "KANGAATSIAQ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QPZ",
    AIRPORTNAME: " ",
    CITYCODE: "QPZ",
    CITYNAME: "PIACENZA",
    CITYNAME_HEB: "\u05e4\u05d9\u05d0\u05e6\u05e0\u05d6\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQA",
    AIRPORTNAME: null,
    CITYCODE: "QQA",
    CITYNAME: "BRITRAIL RAIL ZONE A",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQB",
    AIRPORTNAME: null,
    CITYCODE: "QQB",
    CITYNAME: "BRITRAIL RAIL ZONE B",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQC",
    AIRPORTNAME: null,
    CITYCODE: "QQC",
    CITYNAME: "BRITRAIL RAIL ZONE C",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQD",
    AIRPORTNAME: null,
    CITYCODE: "QQD",
    CITYNAME: "DOVER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQE",
    AIRPORTNAME: null,
    CITYCODE: "QQE",
    CITYNAME: "BRITRAIL RAIL ZONE E",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGL",
    AIRPORTNAME: "Baglung",
    CITYCODE: "BGL",
    CITYNAME: "BAGLUNG",
    CITYNAME_HEB: "BAGLUNG",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "QQF",
    AIRPORTNAME: null,
    CITYCODE: "QQF",
    CITYNAME: "BRITRAIL RAIL ZONE F",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQG",
    AIRPORTNAME: null,
    CITYCODE: "QQG",
    CITYNAME: "BRITRAIL RAIL ZONE G",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQH",
    AIRPORTNAME: null,
    CITYCODE: "QQH",
    CITYNAME: "HARWICH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQI",
    AIRPORTNAME: null,
    CITYCODE: "QQI",
    CITYNAME: "BRITRAIL RAIL ZON I",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQJ",
    AIRPORTNAME: null,
    CITYCODE: "QQJ",
    CITYNAME: "BRITRAIL RAIL ZONE J",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQL",
    AIRPORTNAME: null,
    CITYCODE: "QQL",
    CITYNAME: "BRITRAIL RAIL ZONE L",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQO",
    AIRPORTNAME: null,
    CITYCODE: "QQO",
    CITYNAME: "BRITRAIL RAIL ZONE O",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQR",
    AIRPORTNAME: null,
    CITYCODE: "QQR",
    CITYNAME: "RAMSGATE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQT",
    AIRPORTNAME: null,
    CITYCODE: "QQT",
    CITYNAME: "BRITRAIL RAIL ZONE T",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQV",
    AIRPORTNAME: null,
    CITYCODE: "QQV",
    CITYNAME: "BRITRAIL RAIL ZONE V",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGM",
    AIRPORTNAME: "Johnson City",
    CITYCODE: "BGM",
    CITYNAME: "BINGHAMTON",
    CITYNAME_HEB: "BINGHAMTON",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QQX",
    AIRPORTNAME: null,
    CITYCODE: "QQX",
    CITYNAME: "BATH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QQY",
    AIRPORTNAME: " ",
    CITYCODE: "QQY",
    CITYNAME: "YORK",
    CITYNAME_HEB: "\u05d9\u05d5\u05e8\u05e7",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QQZ",
    AIRPORTNAME: null,
    CITYCODE: "QQZ",
    CITYNAME: "BRITRAIL RAIL ZONE Z",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRB",
    AIRPORTNAME: null,
    CITYCODE: "QRB",
    CITYNAME: "RAVENSBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRC",
    AIRPORTNAME: null,
    CITYCODE: "QRC",
    CITYNAME: "RANCAGUA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRD",
    AIRPORTNAME: null,
    CITYCODE: "QRD",
    CITYNAME: "ANDRADAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRE",
    AIRPORTNAME: null,
    CITYCODE: "QRE",
    CITYNAME: "CARAZINHO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRF",
    AIRPORTNAME: null,
    CITYCODE: "QRF",
    CITYNAME: "BRAGADO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "EEK",
    AIRPORTNAME: "Eek",
    CITYCODE: "EEK",
    CITYNAME: "EEK",
    CITYNAME_HEB: "Eek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "EEL",
    CITYNAME: "LLANEELI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "EEN",
    AIRPORTNAME: "Dillant-Hopkins",
    CITYCODE: "EEN",
    CITYNAME: "KEENE",
    CITYNAME_HEB: "KEENE",
    COUNTRYCODE: "USNH",
    COUNTRYNAME: "NEW HAMPSHIRE",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d4\u05de\u05e4\u05e9\u05d9\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AKU",
    AIRPORTNAME: "Aksu",
    CITYCODE: "AKU",
    CITYNAME: "AKSU",
    CITYNAME_HEB: "AKSU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "EFB",
    AIRPORTNAME: "Eight Fathom Bight",
    CITYCODE: "EFB",
    CITYNAME: "EIGHT FATHOM BIGH",
    CITYNAME_HEB: "Eight Fathom Bight",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EFG",
    AIRPORTNAME: "Efogi",
    CITYCODE: "EFG",
    CITYNAME: "EFOGI",
    CITYNAME_HEB: "EFOGI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "EFK",
    AIRPORTNAME: "Newport",
    CITYCODE: "EFK",
    CITYNAME: "NEWPORT",
    CITYNAME_HEB: "NEWPORT",
    COUNTRYCODE: "USVT",
    COUNTRYNAME: "VERMONT",
    COUNTRYNAME_HEB:
      '\u05d5\u05d5\u05e8\u05de\u05d5\u05e0\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EFL",
    AIRPORTNAME: "Argostolion",
    CITYCODE: "EFL",
    CITYNAME: "KEFALLINIA",
    CITYNAME_HEB: "\u05e7\u05e4\u05dc\u05d5\u05e0\u05d9\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "EFO",
    AIRPORTNAME: "East Fork",
    CITYCODE: "EFO",
    CITYNAME: "EAST FORK",
    CITYNAME_HEB: "East Fork",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EFW",
    AIRPORTNAME: "Municipal",
    CITYCODE: "EFW",
    CITYNAME: "JEFFERSON",
    CITYNAME_HEB: "\u05d2'\u05e4\u05e8\u05e1\u05d5\u05df",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EGA",
    AIRPORTNAME: "Engati",
    CITYCODE: "EGA",
    CITYNAME: "ENGATI",
    CITYNAME_HEB: "ENGATI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "EGC",
    AIRPORTNAME: "Roumanieres",
    CITYCODE: "EGC",
    CITYNAME: "BERGERAC",
    CITYNAME_HEB: "BERGERAC",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "EGE",
    AIRPORTNAME: "Eagle County",
    CITYCODE: "EGE",
    CITYNAME: "VAIL EAGLE",
    CITYNAME_HEB: "VAIL EAGLE",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EGL",
    AIRPORTNAME: "Neghelli",
    CITYCODE: "EGL",
    CITYNAME: "NEGHELLI",
    CITYNAME_HEB: "NEGHELLI",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AKV",
    AIRPORTNAME: "Akulivik",
    CITYCODE: "AKV",
    CITYNAME: "AKULIVIK",
    CITYNAME_HEB: "AKULIVIK",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "EGM",
    AIRPORTNAME: "Sege",
    CITYCODE: "EGM",
    CITYNAME: "SEGE",
    CITYNAME_HEB: "SEGE",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "EGN",
    AIRPORTNAME: "Geneina",
    CITYCODE: "EGN",
    CITYNAME: "GENEINA",
    CITYNAME_HEB: "GENEINA",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "EGO",
    AIRPORTNAME: "Belgorod",
    CITYCODE: "EGO",
    CITYNAME: "BELGOROD",
    CITYNAME_HEB: "BELGOROD",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EGP",
    AIRPORTNAME: "Maverick Co",
    CITYCODE: "EGP",
    CITYNAME: "EAGLE PASS",
    CITYNAME_HEB: "EAGLE PASS",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EGS",
    AIRPORTNAME: "Egilsstadir",
    CITYCODE: "EGS",
    CITYNAME: "EGILSSTADIR",
    CITYNAME_HEB: "EGILSSTADIR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "EGV",
    AIRPORTNAME: "Eagle River",
    CITYCODE: "EGV",
    CITYNAME: "EAGLE RIVER",
    CITYNAME_HEB: "EAGLE RIVER",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EGX",
    AIRPORTNAME: "Egegik",
    CITYCODE: "EGX",
    CITYNAME: "EGEGIK",
    CITYNAME_HEB: "Egegik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EHL",
    AIRPORTNAME: null,
    CITYCODE: "EHL",
    CITYNAME: "EL BOLSON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "EHM",
    AIRPORTNAME: "Cape Newenham",
    CITYCODE: "EHM",
    CITYNAME: "CAPE NEWENHAM",
    CITYNAME_HEB: "Cape Newenham",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AKX",
    AIRPORTNAME: "Aktyubinsk",
    CITYCODE: "AKX",
    CITYNAME: "AKTYUBINSK",
    CITYNAME_HEB: "AKTYUBINSK",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "EHT",
    AIRPORTNAME: "Rentschler",
    CITYCODE: "EHT",
    CITYNAME: "EAST HARTFORD",
    CITYNAME_HEB: "EAST HARTFORD",
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05e0\u05d8\u05d9\u05e7\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EIA",
    AIRPORTNAME: "Popondetta",
    CITYCODE: "EIA",
    CITYNAME: "EIA",
    CITYNAME_HEB: "EIA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "EIB",
    AIRPORTNAME: "Eisenach",
    CITYCODE: "EIB",
    CITYNAME: "EISENACH",
    CITYNAME_HEB: "EISENACH",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EIE",
    AIRPORTNAME: "Eniseysk",
    CITYCODE: "EIE",
    CITYNAME: "ENISEYSK",
    CITYNAME_HEB: "ENISEYSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EIH",
    AIRPORTNAME: "Einasleigh",
    CITYCODE: "EIH",
    CITYNAME: "EINASLEIGH",
    CITYNAME_HEB: "EINASLEIGH",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EIN",
    AIRPORTNAME: "Eindhoven",
    CITYCODE: "EIN",
    CITYNAME: "EINDHOVEN",
    CITYNAME_HEB: "\u05d0\u05d9\u05d9\u05e0\u05d3\u05d4\u05d5\u05d1\u05df",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "QWZ",
    AIRPORTNAME: " ",
    CITYCODE: "EIN",
    CITYNAME: "EINDHOVEN",
    CITYNAME_HEB: "\u05d0\u05d9\u05d9\u05e0\u05d3\u05d4\u05d5\u05d1\u05df",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "EIS",
    AIRPORTNAME: "Beef Island",
    CITYCODE: "EIS",
    CITYNAME: "BEEF ISLAND",
    CITYNAME_HEB: "BEEF ISLAND",
    COUNTRYCODE: "VG",
    COUNTRYNAME: "VIRGIN ISLANDS-BRITISH",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05ea\u05d5\u05dc\u05d4 \u05d4\u05d1\u05e8\u05d9\u05d8\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "EIY",
    AIRPORTNAME: null,
    CITYCODE: "EIY",
    CITYNAME: "EIN YAHAV",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "EJA",
    AIRPORTNAME: "Variguies",
    CITYCODE: "EJA",
    CITYNAME: "BARRANCABERMEJA",
    CITYNAME_HEB: "BARRANCABERMEJA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AKY",
    AIRPORTNAME: "Civil",
    CITYCODE: "AKY",
    CITYNAME: "SITTWE",
    CITYNAME_HEB: "SITTWE",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "EKB",
    AIRPORTNAME: "Ekibastuz",
    CITYCODE: "EKB",
    CITYNAME: "EKIBASTUZ",
    CITYNAME_HEB: "EKIBASTUZ",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "EKD",
    AIRPORTNAME: "Elkedra",
    CITYCODE: "EKD",
    CITYNAME: "ELKEDRA",
    CITYNAME_HEB: "ELKEDRA",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EKE",
    AIRPORTNAME: "Ekereku",
    CITYCODE: "EKE",
    CITYNAME: "EKEREKU",
    CITYNAME_HEB: "EKEREKU",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "EKI",
    AIRPORTNAME: "Municipal",
    CITYCODE: "EKI",
    CITYNAME: "ELKHART",
    CITYNAME_HEB: "ELKHART",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EKN",
    AIRPORTNAME: "Elkins",
    CITYCODE: "EKN",
    CITYNAME: "ELKINS",
    CITYNAME_HEB: "ELKINS",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "EKO",
    AIRPORTNAME: "Elko",
    CITYCODE: "EKO",
    CITYNAME: "ELKO",
    CITYNAME_HEB: "ELKO",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EKT",
    AIRPORTNAME: "Eskilstuna",
    CITYCODE: "EKT",
    CITYNAME: "ESKILSTUNA",
    CITYNAME_HEB: "ESKILSTUNA",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XFJ",
    AIRPORTNAME: null,
    CITYCODE: "EKT",
    CITYNAME: "ESKILSTUNA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "EKX",
    AIRPORTNAME: "Elizabethtown",
    CITYCODE: "EKX",
    CITYNAME: "ELIZABETHTOWN",
    CITYNAME_HEB: "ELIZABETHTOWN",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ELA",
    AIRPORTNAME: "Eagle Lake",
    CITYCODE: "ELA",
    CITYNAME: "EAGLE LAKE",
    CITYNAME_HEB: "EAGLE LAKE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AAZ",
    AIRPORTNAME: "Quetzaltenango Airport",
    CITYCODE: "AAZ",
    CITYNAME: "QUETZALTENANGO",
    CITYNAME_HEB: "QUETZALTENANGO",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d0\u05d8\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "ALA",
    AIRPORTNAME: "Almaty International Airport",
    CITYCODE: "ALA",
    CITYNAME: "ALMATY",
    CITYNAME_HEB: "\u05d0\u05dc\u05de\u05d8\u05d9",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "ELB",
    AIRPORTNAME: "San Bernado",
    CITYCODE: "ELB",
    CITYNAME: "EL BANCO",
    CITYNAME_HEB: "EL BANCO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ELC",
    AIRPORTNAME: "Elcho Island",
    CITYCODE: "ELC",
    CITYNAME: "ELCHO ISLAND",
    CITYNAME_HEB: "ELCHO ISLAND",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ELD",
    AIRPORTNAME: "Goodwin Field",
    CITYCODE: "ELD",
    CITYNAME: "EL DORADO",
    CITYNAME_HEB: "EL DORADO",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ELE",
    AIRPORTNAME: "El Real",
    CITYCODE: "ELE",
    CITYNAME: "EL REAL",
    CITYNAME_HEB: "EL REAL",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "ELF",
    AIRPORTNAME: "El Fasher",
    CITYCODE: "ELF",
    CITYNAME: "EL FASHER",
    CITYNAME_HEB: "EL FASHER",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "ELG",
    AIRPORTNAME: "El Golea",
    CITYCODE: "ELG",
    CITYNAME: "EL GOLEA",
    CITYNAME_HEB: "EL GOLEA",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ELH",
    AIRPORTNAME: "International",
    CITYCODE: "ELH",
    CITYNAME: "NORTH ELEUTHERA",
    CITYNAME_HEB: "NORTH ELEUTHERA",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "ELI",
    AIRPORTNAME: "Elim",
    CITYCODE: "ELI",
    CITYNAME: "ELIM",
    CITYNAME_HEB: "Elim",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ELJ",
    AIRPORTNAME: "El Recreo",
    CITYCODE: "ELJ",
    CITYNAME: "EL RECREO",
    CITYNAME_HEB: "EL RECREO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ELK",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ELK",
    CITYNAME: "ELK CITY",
    CITYNAME_HEB: "ELK CITY",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ELL",
    AIRPORTNAME: " ",
    CITYCODE: "ELL",
    CITYNAME: "ELLMAU",
    CITYNAME_HEB: "\u05d0\u05dc\u05de\u05d0\u05d5",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ELM",
    AIRPORTNAME: "Corning/Elmina Regional Airport",
    CITYCODE: "ELM",
    CITYNAME: "ELMIRA",
    CITYNAME_HEB: "ELMIRA",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ELN",
    AIRPORTNAME: "Bowers Field",
    CITYCODE: "ELN",
    CITYNAME: "ELLENSBURG",
    CITYNAME_HEB: "ELLENSBURG",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ELO",
    AIRPORTNAME: null,
    CITYCODE: "ELO",
    CITYNAME: "ELDORADO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BIF",
    AIRPORTNAME: null,
    CITYCODE: "ELP",
    CITYNAME: "EL PASO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ELP",
    AIRPORTNAME: "International",
    CITYCODE: "ELP",
    CITYNAME: "EL PASO",
    CITYNAME_HEB: "\u05d0\u05dc \u05e4\u05d0\u05e1\u05d5",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ELR",
    AIRPORTNAME: "Elelim",
    CITYCODE: "ELR",
    CITYNAME: "ELELIM",
    CITYNAME_HEB: "ELELIM",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ELS",
    AIRPORTNAME: "East London",
    CITYCODE: "ELS",
    CITYNAME: "EAST LONDON",
    CITYNAME_HEB: "EAST LONDON",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "ELU",
    AIRPORTNAME: "Guemar",
    CITYCODE: "ELU",
    CITYNAME: "EL OUED",
    CITYNAME_HEB: "EL OUED",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ELV",
    AIRPORTNAME: "Elfin Cove SPB",
    CITYCODE: "ELV",
    CITYNAME: "ELFIN COVE",
    CITYNAME_HEB: "Elfin Cove SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ELW",
    AIRPORTNAME: "Ellamar",
    CITYCODE: "ELW",
    CITYNAME: "ELLAMAR",
    CITYNAME_HEB: "Ellamar",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ELX",
    AIRPORTNAME: "El Tigre",
    CITYCODE: "ELX",
    CITYNAME: "EL TIGRE",
    CITYNAME_HEB: "EL TIGRE",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "ELY",
    AIRPORTNAME: "Yelland",
    CITYCODE: "ELY",
    CITYNAME: "ELY",
    CITYNAME_HEB: "ELY",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ELZ",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ELZ",
    CITYNAME: "WELLSVILLE",
    CITYNAME_HEB: "WELLSVILLE",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EMD",
    AIRPORTNAME: "Emerald",
    CITYCODE: "EMD",
    CITYNAME: "EMERALD",
    CITYNAME_HEB: "EMERALD",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EME",
    AIRPORTNAME: "Emden",
    CITYCODE: "EME",
    CITYNAME: "EMDEN",
    CITYNAME_HEB: "EMDEN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EMG",
    AIRPORTNAME: "Empangeni",
    CITYCODE: "EMG",
    CITYNAME: "EMPANGENI",
    CITYNAME_HEB: "EMPANGENI",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "EMI",
    AIRPORTNAME: "Emirau",
    CITYCODE: "EMI",
    CITYNAME: "EMIRAU",
    CITYNAME_HEB: "EMIRAU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "EMK",
    AIRPORTNAME: "Emmonak",
    CITYCODE: "EMK",
    CITYNAME: "EMMONAK",
    CITYNAME_HEB: "Emmonak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EML",
    AIRPORTNAME: "Emmen",
    CITYCODE: "EML",
    CITYNAME: "EMMEN",
    CITYNAME_HEB: "\u05d0\u05de\u05d0\u05df",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ALC",
    AIRPORTNAME: "El Altet Airport",
    CITYCODE: "ALC",
    CITYNAME: "ALICANTE",
    CITYNAME_HEB: "\u05d0\u05dc\u05d9\u05e7\u05e0\u05d8\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "EMM",
    AIRPORTNAME: "Kemerer",
    CITYCODE: "EMM",
    CITYNAME: "EMMENBRUCKE",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "EMN",
    AIRPORTNAME: "Nema",
    CITYCODE: "EMN",
    CITYNAME: "NEMA",
    CITYNAME_HEB: "NEMA",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EMO",
    AIRPORTNAME: "Emo",
    CITYCODE: "EMO",
    CITYNAME: "EMO",
    CITYNAME_HEB: "EMO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "EMP",
    AIRPORTNAME: "Emporia",
    CITYCODE: "EMP",
    CITYNAME: "EMPORIA",
    CITYNAME_HEB: "EMPORIA",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EMS",
    AIRPORTNAME: "Embessa",
    CITYCODE: "EMS",
    CITYNAME: "EMBESSA",
    CITYNAME_HEB: "EMBESSA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "EMT",
    AIRPORTNAME: "El Monte",
    CITYCODE: "EMT",
    CITYNAME: "EL MONTE",
    CITYNAME_HEB: "EL MONTE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EMX",
    AIRPORTNAME: null,
    CITYCODE: "EMX",
    CITYNAME: "EL MAITEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ENA",
    AIRPORTNAME: "Kenai",
    CITYCODE: "ENA",
    CITYNAME: "KENAI",
    CITYNAME_HEB: "Kenai",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ENB",
    AIRPORTNAME: "Eneabba West",
    CITYCODE: "ENB",
    CITYNAME: "ENEABBA WEST",
    CITYNAME_HEB: "ENEABBA WEST",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ENC",
    AIRPORTNAME: "Essey",
    CITYCODE: "ENC",
    CITYNAME: "NANCY ESSEY",
    CITYNAME_HEB: "NANCY ESSEY",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "ALD",
    AIRPORTNAME: "Alerta",
    CITYCODE: "ALD",
    CITYNAME: "ALERTA",
    CITYNAME_HEB: "ALERTA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "ENM",
    AIRPORTNAME: " ",
    CITYCODE: "ENM",
    CITYNAME: "ENCAMP",
    CITYNAME_HEB: "\u05d0\u05d9\u05d9\u05e0\u05e7\u05d0\u05de\u05e4",
    COUNTRYCODE: "AD",
    COUNTRYNAME: "ANDORRA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d3\u05d5\u05e8\u05d4",
  },
  {
    AIRPORTCODE: "ENE",
    AIRPORTNAME: "Ende",
    CITYCODE: "ENE",
    CITYNAME: "ENDE",
    CITYNAME_HEB: "ENDE",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ENF",
    AIRPORTNAME: "Enontekio",
    CITYCODE: "ENF",
    CITYNAME: "ENONTEKIO",
    CITYNAME_HEB: "ENONTEKIO",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ENH",
    AIRPORTNAME: "Enshi",
    CITYCODE: "ENH",
    CITYNAME: "ENSHI",
    CITYNAME_HEB: "ENSHI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ENI",
    AIRPORTNAME: "El Nido",
    CITYCODE: "ENI",
    CITYNAME: "EL NIDO",
    CITYNAME_HEB: "\u05d0\u05dc \u05e0\u05d9\u05d3\u05d5",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "ENJ",
    AIRPORTNAME: "El Naranjo",
    CITYCODE: "ENJ",
    CITYNAME: "EL NARANJO",
    CITYNAME_HEB: "EL NARANJO",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "ENK",
    AIRPORTNAME: "St Angelo",
    CITYCODE: "ENK",
    CITYNAME: "ENNISKILLEN",
    CITYNAME_HEB: "ENNISKILLEN",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ENL",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ENL",
    CITYNAME: "CENTRALIA",
    CITYNAME_HEB: "CENTRALIA",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ENN",
    AIRPORTNAME: "Nenana",
    CITYCODE: "ENN",
    CITYNAME: "NENANA",
    CITYNAME_HEB: "Nenana",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ENO",
    AIRPORTNAME: "Teniente Prim Alarcon",
    CITYCODE: "ENO",
    CITYNAME: "ENCARNACION",
    CITYNAME_HEB: "ENCARNACION",
    COUNTRYCODE: "PY",
    COUNTRYNAME: "PARAGUAY",
    COUNTRYNAME_HEB: "\u05e4\u05d0\u05e8\u05d0\u05d2\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "ALE",
    AIRPORTNAME: "Alpine",
    CITYCODE: "ALE",
    CITYNAME: "ALPINE",
    CITYNAME_HEB: "ALPINE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ENQ",
    AIRPORTNAME: "Coronel E Soto Cano AB",
    CITYCODE: "ENQ",
    CITYNAME: "CORONEL E SOTO A",
    CITYNAME_HEB: "CORONEL E SOTO A",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "ENS",
    AIRPORTNAME: "Twente",
    CITYCODE: "ENS",
    CITYNAME: "ENSCHEDE",
    CITYNAME_HEB: "\u05d0\u05e0\u05e1\u05d7\u05d3\u05d4",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ENT",
    AIRPORTNAME: "Enewetak Island",
    CITYCODE: "ENT",
    CITYNAME: "ENEWETAK ISLAND",
    CITYNAME_HEB: "ENEWETAK ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "ENU",
    AIRPORTNAME: "Enugu",
    CITYCODE: "ENU",
    CITYNAME: "ENUGU",
    CITYNAME_HEB: "ENUGU",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ENV",
    AIRPORTNAME: "Wendover",
    CITYCODE: "ENV",
    CITYNAME: "WENDOVER",
    CITYNAME_HEB: "WENDOVER",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ENW",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ENW",
    CITYNAME: "KENOSHA",
    CITYNAME_HEB: "KENOSHA",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ENY",
    AIRPORTNAME: "Yan'an",
    CITYCODE: "ENY",
    CITYNAME: "YAN'AN",
    CITYNAME_HEB: "YAN'AN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ENZ",
    AIRPORTNAME: null,
    CITYCODE: "ENZ",
    CITYNAME: "MYSTERY BREAK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "EOI",
    AIRPORTNAME: "Eday",
    CITYCODE: "EOI",
    CITYNAME: "EDAY",
    CITYNAME_HEB: "EDAY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EOK",
    AIRPORTNAME: "Keokuk",
    CITYCODE: "EOK",
    CITYNAME: "KEOKUK",
    CITYNAME_HEB: "KEOKUK",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ALF",
    AIRPORTNAME: "Alta",
    CITYCODE: "ALF",
    CITYNAME: "ALTA",
    CITYNAME_HEB: "ALTA",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EOR",
    AIRPORTNAME: "El Dorado",
    CITYCODE: "EOR",
    CITYNAME: "EL DORADO",
    CITYNAME_HEB: "EL DORADO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "EOS",
    AIRPORTNAME: "Neosho",
    CITYCODE: "EOS",
    CITYNAME: "NEOSHO",
    CITYNAME_HEB: "NEOSHO",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EOZ",
    AIRPORTNAME: "Elorza",
    CITYCODE: "EOZ",
    CITYNAME: "ELORZA",
    CITYNAME_HEB: "ELORZA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "EPG",
    AIRPORTNAME: "Browns",
    CITYCODE: "EPG",
    CITYNAME: "WEEPING WATER",
    CITYNAME_HEB: "WEEPING WATER",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EPH",
    AIRPORTNAME: "Ephrata",
    CITYCODE: "EPH",
    CITYNAME: "EPHRATA",
    CITYNAME_HEB: "EPHRATA",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EPI",
    AIRPORTNAME: "Epi",
    CITYCODE: "EPI",
    CITYNAME: "EPI",
    CITYNAME_HEB: "EPI",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "EPK",
    AIRPORTNAME: "Episkopi",
    CITYCODE: "EPK",
    CITYNAME: "EPISKOPI",
    CITYNAME_HEB: "\u05d0\u05e4\u05d9\u05e1\u05e7\u05d5\u05e4\u05d9",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "EPL",
    AIRPORTNAME: "Mirecourt",
    CITYCODE: "EPL",
    CITYNAME: "EPINAL",
    CITYNAME_HEB: "EPINAL",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "EPN",
    AIRPORTNAME: "Epena",
    CITYCODE: "EPN",
    CITYNAME: "EPENA",
    CITYNAME_HEB: "EPENA",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "EPR",
    AIRPORTNAME: "Esperance",
    CITYCODE: "EPR",
    CITYNAME: "ESPERANCE",
    CITYNAME_HEB: "ESPERANCE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ALG",
    AIRPORTNAME: "Houari Boumedienne Airport",
    CITYCODE: "ALG",
    CITYNAME: "ALGIERS",
    CITYNAME_HEB: "\u05d0\u05dc\u05d2\u05d9\u05e8\u05e1",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JUV",
    AIRPORTNAME: "Heliport",
    CITYCODE: "JUV",
    CITYNAME: "UPERNAVIK",
    CITYNAME_HEB: "UPERNAVIK",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PLW",
    AIRPORTNAME: "Mutiara",
    CITYCODE: "PLW",
    CITYNAME: "PALU",
    CITYNAME_HEB: "PALU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PLX",
    AIRPORTNAME: "Semipalatinsk",
    CITYCODE: "PLX",
    CITYNAME: "SEMIPALATINSK",
    CITYNAME_HEB: "SEMIPALATINSK",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "PLY",
    AIRPORTNAME: "Plymouth",
    CITYCODE: "PLY",
    CITYNAME: "PLYMOUTH",
    CITYNAME_HEB: "PLYMOUTH",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BDY",
    AIRPORTNAME: "State",
    CITYCODE: "BDY",
    CITYNAME: "BANDON",
    CITYNAME_HEB: "BANDON",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PLZ",
    AIRPORTNAME: "Port Elizabeth",
    CITYCODE: "PLZ",
    CITYNAME: "PORT ELIZABETH",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05e8\u05d8 \u05d0\u05dc\u05d9\u05d6\u05d1\u05d8",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "PMA",
    AIRPORTNAME: "Wawi",
    CITYCODE: "PMA",
    CITYNAME: "PEMBA",
    CITYNAME_HEB: "PEMBA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PMB",
    AIRPORTNAME: "Intermediate",
    CITYCODE: "PMB",
    CITYNAME: "PEMBINA",
    CITYNAME_HEB: "PEMBINA",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PMC",
    AIRPORTNAME: "Tepual",
    CITYCODE: "PMC",
    CITYNAME: "PUERTO MONTT",
    CITYNAME_HEB: "PUERTO MONTT",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PMD",
    AIRPORTNAME: "Air Force 42",
    CITYCODE: "PMD",
    CITYNAME: "PALMDALE",
    CITYNAME_HEB: "PALMDALE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PME",
    AIRPORTNAME: "Portsmouth",
    CITYCODE: "PME",
    CITYNAME: "PORTSMOUTH",
    CITYNAME_HEB: "PORTSMOUTH",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PMG",
    AIRPORTNAME: null,
    CITYCODE: "PMG",
    CITYNAME: "PONTA PORA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PMH",
    AIRPORTNAME: "Regional",
    CITYCODE: "PMH",
    CITYNAME: "PORTSMOUTH",
    CITYNAME_HEB: "PORTSMOUTH",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PMI",
    AIRPORTNAME: "Palma Mallorca",
    CITYCODE: "PMI",
    CITYNAME: "PALMA MALLORCA",
    CITYNAME_HEB:
      "\u05e4\u05dc\u05de\u05d4 \u05d3\u05d4 \u05de\u05d9\u05d5\u05e8\u05e7\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "PMK",
    AIRPORTNAME: "Palm Island",
    CITYCODE: "PMK",
    CITYNAME: "PALM ISLAND",
    CITYNAME_HEB: "PALM ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BDZ",
    AIRPORTNAME: "Baindoung",
    CITYCODE: "BDZ",
    CITYNAME: "BAINDOUNG",
    CITYNAME_HEB: "BAINDOUNG",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "PML",
    AIRPORTNAME: "Port Moller",
    CITYCODE: "PML",
    CITYNAME: "PORT MOLLER",
    CITYNAME_HEB: "Port Moller",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PMM",
    AIRPORTNAME: "Phanom Sarakham",
    CITYCODE: "PMM",
    CITYNAME: "PHANOM SARAKHAM",
    CITYNAME_HEB: "PHANOM SARAKHAM",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PMN",
    AIRPORTNAME: "Pumani",
    CITYCODE: "PMN",
    CITYNAME: "PUMANI",
    CITYNAME_HEB: "PUMANI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "PMO",
    AIRPORTNAME: "Punta Raisi",
    CITYCODE: "PMO",
    CITYNAME: "PALERMO",
    CITYNAME_HEB:
      "\u05e4\u05dc\u05e8\u05de\u05d5-\u05e1\u05d9\u05e6\u05d9\u05dc\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PMP",
    AIRPORTNAME: " ",
    CITYCODE: "PMP",
    CITYNAME: "PAMPOROVO",
    CITYNAME_HEB: "\u05e4\u05d0\u05de\u05e4\u05d5\u05e8\u05d5\u05d1\u05d5",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PMQ",
    AIRPORTNAME: null,
    CITYCODE: "PMQ",
    CITYNAME: "PERITO MORENO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PMR",
    AIRPORTNAME: "Palmerston North",
    CITYCODE: "PMR",
    CITYNAME: "PALMERSTON NORTH",
    CITYNAME_HEB: "PALMERSTON NORTH",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PMS",
    AIRPORTNAME: "Palmyra",
    CITYCODE: "PMS",
    CITYNAME: "PALMYRA",
    CITYNAME_HEB: "PALMYRA",
    COUNTRYCODE: "SY",
    COUNTRYNAME: "SYRIAN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PMT",
    AIRPORTNAME: "Paramakotoi",
    CITYCODE: "PMT",
    CITYNAME: "PARAMAKOTOI",
    CITYNAME_HEB: "PARAMAKOTOI",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "PMU",
    AIRPORTNAME: "Paimiut",
    CITYCODE: "PMU",
    CITYNAME: "PAIMIUT",
    CITYNAME_HEB: "Paimiut",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BEA",
    AIRPORTNAME: "Bereina",
    CITYCODE: "BEA",
    CITYNAME: "BEREINA",
    CITYNAME_HEB: "BEREINA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "PMV",
    AIRPORTNAME: "DelCaribe Gen S Marino",
    CITYCODE: "PMV",
    CITYNAME: "PORLAMAR",
    CITYNAME_HEB: "PORLAMAR",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PMW",
    AIRPORTNAME: null,
    CITYCODE: "PMW",
    CITYNAME: "PALMAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PMX",
    AIRPORTNAME: "Metropolitan",
    CITYCODE: "PMX",
    CITYNAME: "PALMER",
    CITYNAME_HEB: "PALMER",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "PMY",
    AIRPORTNAME: null,
    CITYCODE: "PMY",
    CITYNAME: "PUERTO MADRYN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PMZ",
    AIRPORTNAME: "Palmar Sur",
    CITYCODE: "PMZ",
    CITYNAME: "PALMAR",
    CITYNAME_HEB: "PALMAR",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "PNA",
    AIRPORTNAME: "Pamplona",
    CITYCODE: "PNA",
    CITYNAME: "PAMPLONA",
    CITYNAME_HEB: "\u05e4\u05de\u05e4\u05dc\u05d5\u05e0\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "PNB",
    AIRPORTNAME: null,
    CITYCODE: "PNB",
    CITYNAME: "PORTO NACIONAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PNC",
    AIRPORTNAME: "Ponca City",
    CITYCODE: "PNC",
    CITYNAME: "PONCA CITY",
    CITYNAME_HEB: "PONCA CITY",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PND",
    AIRPORTNAME: "Punta Gorda",
    CITYCODE: "PND",
    CITYNAME: "PUNTA GORDA",
    CITYNAME_HEB: "PUNTA GORDA",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "PNF",
    AIRPORTNAME: "Peterson's Point",
    CITYCODE: "PNF",
    CITYNAME: "PETERSON'S POINT",
    CITYNAME_HEB: "Peterson's Point",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ACU",
    AIRPORTNAME: "Achutupo",
    CITYCODE: "ACU",
    CITYNAME: "ACHUTUPO",
    CITYNAME_HEB: "ACHUTUPO",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "BEB",
    AIRPORTNAME: "Benbecula",
    CITYCODE: "BEB",
    CITYNAME: "BENBECULA",
    CITYNAME_HEB: "BENBECULA",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PNG",
    AIRPORTNAME: null,
    CITYCODE: "PNG",
    CITYNAME: "PARANAGUA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PNH",
    AIRPORTNAME: "Pochentong",
    CITYCODE: "PNH",
    CITYNAME: "PHNOM PENH",
    CITYNAME_HEB: "\u05e4\u05e0\u05d5\u05dd \u05e4\u05df",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PNI",
    AIRPORTNAME: "Pohnpei",
    CITYCODE: "PNI",
    CITYNAME: "POHNPEI",
    CITYNAME_HEB: "POHNPEI",
    COUNTRYCODE: "FM",
    COUNTRYNAME: "MICRONESIA",
    COUNTRYNAME_HEB: "\u05de\u05d9\u05e7\u05e8\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PNK",
    AIRPORTNAME: "Supadio",
    CITYCODE: "PNK",
    CITYNAME: "PONTIANAK",
    CITYNAME_HEB: "PONTIANAK",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PNL",
    AIRPORTNAME: "Pantelleria",
    CITYCODE: "PNL",
    CITYNAME: "PANTELLERIA",
    CITYNAME_HEB: "PANTELLERIA",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PNN",
    AIRPORTNAME: "Princeton",
    CITYCODE: "PNN",
    CITYNAME: "PRINCETON",
    CITYNAME_HEB: "PRINCETON",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PNO",
    AIRPORTNAME: "Pinotepa Nacional",
    CITYCODE: "PNO",
    CITYNAME: "PINOTEPA NACIONAL",
    CITYNAME_HEB: "PINOTEPA NACIONAL",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PNP",
    AIRPORTNAME: "Girua",
    CITYCODE: "PNP",
    CITYNAME: "POPONDETTA",
    CITYNAME_HEB: "POPONDETTA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "PNQ",
    AIRPORTNAME: "Lohegaon",
    CITYCODE: "PNQ",
    CITYNAME: "PUNE",
    CITYNAME_HEB: "\u05e4\u05d5\u05e0\u05d4",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "PNR",
    AIRPORTNAME: "Pointe Noire",
    CITYCODE: "PNR",
    CITYNAME: "POINTE NOIRE",
    CITYNAME_HEB: "POINTE NOIRE",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "BED",
    AIRPORTNAME: "Hanscom Field",
    CITYCODE: "BED",
    CITYNAME: "BEDFORD",
    CITYNAME_HEB: "BEDFORD",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "PNS",
    AIRPORTNAME: "Regional",
    CITYCODE: "PNS",
    CITYNAME: "PENSACOLA",
    CITYNAME_HEB: "PENSACOLA",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NDP",
    AIRPORTNAME: null,
    CITYCODE: "PNS",
    CITYNAME: "PENSACOLA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NPA",
    AIRPORTNAME: null,
    CITYCODE: "PNS",
    CITYNAME: "PENSACOLA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NUN",
    AIRPORTNAME: null,
    CITYCODE: "PNS",
    CITYNAME: "PENSACOLA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PNT",
    AIRPORTNAME: "Teniente J. Gallardo",
    CITYCODE: "PNT",
    CITYNAME: "PUERTO NATALES",
    CITYNAME_HEB: "PUERTO NATALES",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PNU",
    AIRPORTNAME: "Panguitch",
    CITYCODE: "PNU",
    CITYNAME: "PANGUITCH",
    CITYNAME_HEB: "PANGUITCH",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PNV",
    AIRPORTNAME: "Panevezys",
    CITYCODE: "PNV",
    CITYNAME: "PANEVEZYS",
    CITYNAME_HEB: "PANEVEZYS",
    COUNTRYCODE: "LT",
    COUNTRYNAME: "LITHUANIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d8\u05d0",
  },
  {
    AIRPORTCODE: "PNX",
    AIRPORTNAME: "Grayson County",
    CITYCODE: "PNX",
    CITYNAME: "SHERMAN DENISON",
    CITYNAME_HEB: "SHERMAN DENISON",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PNY",
    AIRPORTNAME: "Pondicherry",
    CITYCODE: "PNY",
    CITYNAME: "PONDICHERRY",
    CITYNAME_HEB: "PONDICHERRY",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "PNZ",
    AIRPORTNAME: null,
    CITYCODE: "PNZ",
    CITYNAME: "PETROLINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BEE",
    AIRPORTNAME: "Beagle Bay",
    CITYCODE: "BEE",
    CITYNAME: "BEAGLE BAY",
    CITYNAME_HEB: "BEAGLE BAY",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "POA",
    AIRPORTNAME: " ",
    CITYCODE: "POA",
    CITYNAME: "PORTO ALEGRE",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05d0\u05dc\u05d2\u05e8\u05d4",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "POB",
    AIRPORTNAME: " ",
    CITYCODE: "POB",
    CITYNAME: "POIANA BRASHOV",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05d9\u05d0\u05e0\u05d4 \u05d1\u05e8\u05e9\u05d5\u05d1",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "POC",
    AIRPORTNAME: " ",
    CITYCODE: "POC",
    CITYNAME: "POREC",
    CITYNAME_HEB: "\u05e4\u05d5\u05e8\u05d0\u05e6",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "POD",
    AIRPORTNAME: " ",
    CITYCODE: "POD",
    CITYNAME: "POLAND",
    CITYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "POE",
    AIRPORTNAME: "Polk AAF",
    CITYCODE: "POE",
    CITYNAME: "FORT POLK",
    CITYNAME_HEB: "FORT POLK",
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB:
      '\u05dc\u05d5\u05d0\u05d9\u05d6\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "POF",
    AIRPORTNAME: "Earl Fields Memorial",
    CITYCODE: "POF",
    CITYNAME: "POPLAR BLUFF",
    CITYNAME_HEB: "POPLAR BLUFF",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "POG",
    AIRPORTNAME: "Port Gentil",
    CITYCODE: "POG",
    CITYNAME: "PORT GENTIL",
    CITYNAME_HEB: "PORT GENTIL",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "POH",
    AIRPORTNAME: "Municipal",
    CITYCODE: "POH",
    CITYNAME: "POCAHONTAS",
    CITYNAME_HEB: "POCAHONTAS",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "POI",
    AIRPORTNAME: "Potosi",
    CITYCODE: "POI",
    CITYNAME: "POTOSI",
    CITYNAME_HEB: "POTOSI",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "POJ",
    AIRPORTNAME: null,
    CITYCODE: "POJ",
    CITYNAME: "PATOS DE MINAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BEF",
    AIRPORTNAME: "Bluefields",
    CITYCODE: "BEF",
    CITYNAME: "BLUEFIELDS",
    CITYNAME_HEB: "BLUEFIELDS",
    COUNTRYCODE: "NI",
    COUNTRYNAME: "NICARAGUA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05e7\u05e8\u05d0\u05d2\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "POL",
    AIRPORTNAME: " ",
    CITYCODE: "POL",
    CITYNAME: "POLIS",
    CITYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05e1",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "POM",
    AIRPORTNAME: " ",
    CITYCODE: "POM",
    CITYNAME: "POMORIE",
    CITYNAME_HEB: "\u05e4\u05d5\u05de\u05d5\u05e8\u05d9",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PON",
    AIRPORTNAME: "Poptun",
    CITYCODE: "PON",
    CITYNAME: "POPTUN",
    CITYNAME_HEB: "POPTUN",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "POO",
    AIRPORTNAME: null,
    CITYCODE: "POO",
    CITYNAME: "POCOS DE CALDAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "POP",
    AIRPORTNAME: "La Union",
    CITYCODE: "POP",
    CITYNAME: "PUERTO PLATA",
    CITYNAME_HEB: "PUERTO PLATA",
    COUNTRYCODE: "DO",
    COUNTRYNAME: "DOMINICAN REPUBLIC",
    COUNTRYNAME_HEB:
      "\u05d4\u05e8\u05e4\u05d5\u05d1\u05dc\u05d9\u05e7\u05d4 \u05d4\u05d3\u05d5\u05de\u05d9\u05e0\u05d9\u05e7\u05e0\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "POQ",
    AIRPORTNAME: "Polk Inlet",
    CITYCODE: "POQ",
    CITYNAME: "POLK INLET",
    CITYNAME_HEB: "Polk Inlet",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "POR",
    AIRPORTNAME: "Pori",
    CITYCODE: "POR",
    CITYNAME: "PORI",
    CITYNAME_HEB: "\u05e4\u05d5\u05e8\u05d9",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "POS",
    AIRPORTNAME: "Piarco International Airport",
    CITYCODE: "POS",
    CITYNAME: "PORT OF SPAIN",
    CITYNAME_HEB: "PORT OF SPAIN",
    COUNTRYCODE: "TT",
    COUNTRYNAME: "TRINIDAD AND TOBAGO",
    COUNTRYNAME_HEB:
      "\u05d8\u05e8\u05d9\u05e0\u05d9\u05d3\u05d3 \u05d5\u05d8\u05d5\u05d1\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "POT",
    AIRPORTNAME: "Ken Jones",
    CITYCODE: "POT",
    CITYNAME: "PORT ANTONIO",
    CITYNAME_HEB: "PORT ANTONIO",
    COUNTRYCODE: "JM",
    COUNTRYNAME: "JAMAICA",
    COUNTRYNAME_HEB: "\u05d2'\u05de\u05d9\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "POU",
    AIRPORTNAME: "Dutchess County",
    CITYCODE: "POU",
    CITYNAME: "POUGHKEEPSIE",
    CITYNAME_HEB: "POUGHKEEPSIE",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BEG",
    AIRPORTNAME: "Aerodrom Beograd",
    CITYCODE: "BEG",
    CITYNAME: "BELGRADE",
    CITYNAME_HEB: "\u05d1\u05dc\u05d2\u05e8\u05d3",
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SWF",
    AIRPORTNAME: null,
    CITYCODE: "POU",
    CITYNAME: "POUGHKEEPSIE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "POV",
    AIRPORTNAME: "Presov",
    CITYCODE: "POV",
    CITYNAME: "PRESOV",
    CITYNAME_HEB: "\u05e4\u05e8\u05e9\u05d5\u05d1",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "POW",
    AIRPORTNAME: "Portoroz",
    CITYCODE: "POW",
    CITYNAME: "PORTOROZ",
    CITYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05e8\u05d5\u05d6",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "POY",
    AIRPORTNAME: "Lovell",
    CITYCODE: "POY",
    CITYNAME: "POWELL",
    CITYNAME_HEB: "POWELL",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "POZ",
    AIRPORTNAME: "Lawica",
    CITYCODE: "POZ",
    CITYNAME: "POZNAN",
    CITYNAME_HEB: "\u05e4\u05d5\u05d6\u05e0\u05df",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "NRN",
    AIRPORTNAME: " ",
    CITYCODE: "NRN",
    CITYNAME: "WEEZE",
    CITYNAME_HEB: "\u05d5\u05d5\u05d9\u05e5",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PPA",
    AIRPORTNAME: "Perry Lefors Field",
    CITYCODE: "PPA",
    CITYNAME: "PAMPA",
    CITYNAME_HEB: "PAMPA",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PPB",
    AIRPORTNAME: null,
    CITYCODE: "PPB",
    CITYNAME: "PRESIDENTE PRUDEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PPC",
    AIRPORTNAME: "Prospect Creek",
    CITYCODE: "PPC",
    CITYNAME: "PROSPECT CREEK",
    CITYNAME_HEB: "Prospect Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PPE",
    AIRPORTNAME: "Puerto Penasco",
    CITYCODE: "PPE",
    CITYNAME: "PUERTO PENASCO",
    CITYNAME_HEB: "PUERTO PENASCO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PPF",
    AIRPORTNAME: "Tri-City",
    CITYCODE: "PPF",
    CITYNAME: "Parsons",
    CITYNAME_HEB: "Parsons",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PPG",
    AIRPORTNAME: "International",
    CITYCODE: "PPG",
    CITYNAME: "PAGO PAGO",
    CITYNAME_HEB: "PAGO PAGO",
    COUNTRYCODE: "AS",
    COUNTRYNAME: "AMERICAN SAMOA",
    COUNTRYNAME_HEB:
      "\u05e1\u05de\u05d5\u05d0\u05d4 \u05d4\u05de\u05e2\u05e8\u05d1\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "PPH",
    AIRPORTNAME: "Peraitepuy",
    CITYCODE: "PPH",
    CITYNAME: "PERAITEPUY",
    CITYNAME_HEB: "PERAITEPUY",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PPI",
    AIRPORTNAME: "Port Pirie",
    CITYCODE: "PPI",
    CITYNAME: "PORT PIRIE",
    CITYNAME_HEB: "PORT PIRIE",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PPJ",
    AIRPORTNAME: "Pulau Panjang",
    CITYCODE: "PPJ",
    CITYNAME: "PULAU PANJANG",
    CITYNAME_HEB: "PULAU PANJANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PPK",
    AIRPORTNAME: "Petropavlovsk",
    CITYCODE: "PPK",
    CITYNAME: "PETROPAVLOVSK",
    CITYNAME_HEB: "PETROPAVLOVSK",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "PPL",
    AIRPORTNAME: "Phaplu",
    CITYCODE: "PPL",
    CITYNAME: "PHAPLU",
    CITYNAME_HEB: "PHAPLU",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "PPM",
    AIRPORTNAME: "Pompano Beach",
    CITYCODE: "PPM",
    CITYNAME: "POMPANO BEACH",
    CITYNAME_HEB: "POMPANO BEACH",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PPN",
    AIRPORTNAME: "Machangara",
    CITYCODE: "PPN",
    CITYNAME: "POPAYAN",
    CITYNAME_HEB: "POPAYAN",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PPO",
    AIRPORTNAME: "Powell Point",
    CITYCODE: "PPO",
    CITYNAME: "POWELL POINT",
    CITYNAME_HEB: "POWELL POINT",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PPP",
    AIRPORTNAME: "Proserpine",
    CITYCODE: "PPP",
    CITYNAME: "PROSERPINE",
    CITYNAME_HEB: "PROSERPINE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BEH",
    AIRPORTNAME: "Ross Field",
    CITYCODE: "BEH",
    CITYNAME: "BENTON HARBOR",
    CITYNAME_HEB: "BENTON HARBOR",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PPQ",
    AIRPORTNAME: "Paraparaumu",
    CITYCODE: "PPQ",
    CITYNAME: "PARAPARAUMU",
    CITYNAME_HEB: "PARAPARAUMU",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PPR",
    AIRPORTNAME: "Pasir Pangarayan",
    CITYCODE: "PPR",
    CITYNAME: "PASIR PANGARAYAN",
    CITYNAME_HEB: "PASIR PANGARAYAN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PPS",
    AIRPORTNAME: "Puerto Princesa",
    CITYCODE: "PPS",
    CITYNAME: "PUERTO PRINCESA",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e4\u05e8\u05d9\u05e0\u05e1\u05e1\u05d4",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "PPT",
    AIRPORTNAME: "Faaa",
    CITYCODE: "PPT",
    CITYNAME: "PAPEETE",
    CITYNAME_HEB: "PAPEETE",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "PPU",
    AIRPORTNAME: "Papun",
    CITYCODE: "PPU",
    CITYNAME: "PAPUN",
    CITYNAME_HEB: "PAPUN",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PPV",
    AIRPORTNAME: "Port Protection",
    CITYCODE: "PPV",
    CITYNAME: "PORT PROTECTION",
    CITYNAME_HEB: "Port Protection",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PPW",
    AIRPORTNAME: "Papa Westray",
    CITYCODE: "PPW",
    CITYNAME: "PAPA WESTRAY",
    CITYNAME_HEB: "PAPA WESTRAY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PPX",
    AIRPORTNAME: "Param",
    CITYCODE: "PPX",
    CITYNAME: "PARAM",
    CITYNAME_HEB: "PARAM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "PPY",
    AIRPORTNAME: null,
    CITYCODE: "PPY",
    CITYNAME: "POUSO ALEGRE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PPZ",
    AIRPORTNAME: "Puerto Paez",
    CITYCODE: "PPZ",
    CITYNAME: "PUERTO PAEZ",
    CITYNAME_HEB: "PUERTO PAEZ",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "BEI",
    AIRPORTNAME: "Beica",
    CITYCODE: "BEI",
    CITYNAME: "BEICA",
    CITYNAME_HEB: "BEICA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PQC",
    AIRPORTNAME: "Duong Dang",
    CITYCODE: "PQC",
    CITYNAME: "PHU QUOC",
    CITYNAME_HEB: "\u05e4\u05d5 \u05e7\u05d5\u05d5\u05e7",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "PQI",
    AIRPORTNAME: "Municipal",
    CITYCODE: "PQI",
    CITYNAME: "PRESQUE ISLE",
    CITYNAME_HEB: "PRESQUE ISLE",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PQM",
    AIRPORTNAME: "Palenque",
    CITYCODE: "PQM",
    CITYNAME: "PALENQUE",
    CITYNAME_HEB: "\u05e4\u05dc\u05e0\u05e7\u05d4",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PQQ",
    AIRPORTNAME: "Port Macquarie",
    CITYCODE: "PQQ",
    CITYNAME: "PORT MACQUARIE",
    CITYNAME_HEB: "PORT MACQUARIE",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PQS",
    AIRPORTNAME: "Pilot Station",
    CITYCODE: "PQS",
    CITYNAME: "PILOT STATION",
    CITYNAME_HEB: "Pilot Station",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PRA",
    AIRPORTNAME: null,
    CITYCODE: "PRA",
    CITYNAME: "PARANA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PRB",
    AIRPORTNAME: "Paso Robles",
    CITYCODE: "PRB",
    CITYNAME: "PASO ROBLES",
    CITYNAME_HEB: "PASO ROBLES",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PRC",
    AIRPORTNAME: "Prescott",
    CITYCODE: "PRC",
    CITYNAME: "PRESCOTT",
    CITYNAME_HEB: "PRESCOTT",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PRD",
    AIRPORTNAME: " ",
    CITYCODE: "PRD",
    CITYNAME: "PREDEAL",
    CITYNAME_HEB: "\u05e4\u05e8\u05d3\u05d0\u05dc",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PRE",
    AIRPORTNAME: " ",
    CITYCODE: "PRE",
    CITYNAME: "PRADEL",
    CITYNAME_HEB: "\u05e4\u05e8\u05d0\u05d3\u05dc",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BEJ",
    AIRPORTNAME: "Berau",
    CITYCODE: "BEJ",
    CITYNAME: "BERAU",
    CITYNAME_HEB: "BERAU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PRF",
    AIRPORTNAME: "Port Johnson",
    CITYCODE: "PRF",
    CITYNAME: "PORT JOHNSON",
    CITYNAME_HEB: "Port Johnson",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PRG",
    AIRPORTNAME: "Ruzyne",
    CITYCODE: "PRG",
    CITYNAME: "PRAGUE",
    CITYNAME_HEB: "\u05e4\u05e8\u05d0\u05d2",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PRH",
    AIRPORTNAME: "Phrae",
    CITYCODE: "PRH",
    CITYNAME: "PHRAE",
    CITYNAME_HEB: "PHRAE",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PRI",
    AIRPORTNAME: "Praslin Island",
    CITYCODE: "PRI",
    CITYNAME: "PRASLIN ISLAND",
    CITYNAME_HEB: "\u05e4\u05e8\u05e1\u05dc\u05d9\u05df",
    COUNTRYCODE: "SC",
    COUNTRYNAME: "SEYCHELLES ISLANDS",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05d9\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "PRJ",
    AIRPORTNAME: "Capri",
    CITYCODE: "PRJ",
    CITYNAME: "CAPRI",
    CITYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PRK",
    AIRPORTNAME: "Prieska",
    CITYCODE: "PRK",
    CITYNAME: "PRIESKA",
    CITYNAME_HEB: "PRIESKA",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "PRL",
    AIRPORTNAME: "Port Oceanic",
    CITYCODE: "PRL",
    CITYNAME: "PORT OCEANIC",
    CITYNAME_HEB: "Port Oceanic",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PRM",
    AIRPORTNAME: "Portimao",
    CITYCODE: "PRM",
    CITYNAME: "PORTIMAO",
    CITYNAME_HEB: "PORTIMAO",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "PRN",
    AIRPORTNAME: "Pristina",
    CITYCODE: "PRN",
    CITYNAME: "PRISTINA",
    CITYNAME_HEB: "PRISTINA",
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PRO",
    AIRPORTNAME: " ",
    CITYCODE: "PRO",
    CITYNAME: "PROLOM BANJA",
    CITYNAME_HEB: "\u05e4\u05e8\u05d5\u05dc\u05d5\u05dd",
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BEK",
    AIRPORTNAME: "Bareli",
    CITYCODE: "BEK",
    CITYNAME: "BARELI",
    CITYNAME_HEB: "BARELI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "PRP",
    AIRPORTNAME: "Propriano",
    CITYCODE: "PRP",
    CITYNAME: "PROPRIANO",
    CITYNAME_HEB: "PROPRIANO",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "PRQ",
    AIRPORTNAME: null,
    CITYCODE: "PRQ",
    CITYNAME: "PRES  ROQUE SAENZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PRR",
    AIRPORTNAME: "Paruima",
    CITYCODE: "PRR",
    CITYNAME: "PARUIMA",
    CITYNAME_HEB: "PARUIMA",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "PRS",
    AIRPORTNAME: "Parasi",
    CITYCODE: "PRS",
    CITYNAME: "PARASI",
    CITYNAME_HEB: "PARASI",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PRT",
    AIRPORTNAME: "Coast Guard Heliport",
    CITYCODE: "PRT",
    CITYNAME: "POINT RETREAT",
    CITYNAME_HEB: "Coast Guard Heliport",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PRU",
    AIRPORTNAME: "Prome",
    CITYCODE: "PRU",
    CITYNAME: "PROME",
    CITYNAME_HEB: "PROME",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PRV",
    AIRPORTNAME: "Prerov",
    CITYCODE: "PRV",
    CITYNAME: "PREROV",
    CITYNAME_HEB: "\u05e4\u05e8\u05d0\u05e8\u05d5\u05d1",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PRW",
    AIRPORTNAME: "Prentice",
    CITYCODE: "PRW",
    CITYNAME: "PRENTICE",
    CITYNAME_HEB: "PRENTICE",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "HIC",
    AIRPORTNAME: null,
    CITYCODE: "PRY",
    CITYNAME: "PRETORIA PRY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ACV",
    AIRPORTNAME: "Arcata",
    CITYCODE: "ACV",
    CITYNAME: "ARCATA",
    CITYNAME_HEB: "Arcata",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BEL",
    AIRPORTNAME: " ",
    CITYCODE: "BEL",
    CITYNAME: "BELEM",
    CITYNAME_HEB: "\u05d1\u05dc\u05d0\u05dd",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "HPR",
    AIRPORTNAME: "Central Hpr",
    CITYCODE: "PRY",
    CITYNAME: "PRETORIA PRY",
    CITYNAME_HEB: "PRETORIA PRY",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "PRY",
    AIRPORTNAME: "Wonderboom Airport",
    CITYCODE: "PRY",
    CITYNAME: "PRETORIA PRY",
    CITYNAME_HEB: "PRETORIA PRY",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "PRZ",
    AIRPORTNAME: " ",
    CITYCODE: "PRZ",
    CITYNAME: "PRAVETS",
    CITYNAME_HEB: "\u05e4\u05e8\u05d1\u05e5",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PSA",
    AIRPORTNAME: " ",
    CITYCODE: "PSA",
    CITYNAME: "PISA",
    CITYNAME_HEB: "\u05e4\u05d9\u05d6\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PSB",
    AIRPORTNAME: "Mid-State",
    CITYCODE: "PSB",
    CITYNAME: "PHILIPSBURG",
    CITYNAME_HEB: "PHILIPSBURG",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PSC",
    AIRPORTNAME: "Tri-Cities",
    CITYCODE: "PSC",
    CITYNAME: "PASCO",
    CITYNAME_HEB: "PASCO",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PSE",
    AIRPORTNAME: "Mercedita",
    CITYCODE: "PSE",
    CITYNAME: "PONCE",
    CITYNAME_HEB: "PONCE",
    COUNTRYCODE: "PR",
    COUNTRYNAME: "PUERTO RICO",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e8\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PSF",
    AIRPORTNAME: "Pittsfield",
    CITYCODE: "PSF",
    CITYNAME: "PITTSFIELD",
    CITYNAME_HEB: "PITTSFIELD",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "PSG",
    AIRPORTNAME: "Petersburg",
    CITYCODE: "PSG",
    CITYNAME: "PETERSBURG",
    CITYNAME_HEB: "Petersburg",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PSH",
    AIRPORTNAME: "St Peter",
    CITYCODE: "PSH",
    CITYNAME: "SAINT PETER",
    CITYNAME_HEB: "SAINT PETER",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BEM",
    AIRPORTNAME: null,
    CITYCODE: "BEM",
    CITYNAME: "BOSSEMBELE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PSI",
    AIRPORTNAME: "Pasni",
    CITYCODE: "PSI",
    CITYNAME: "PASNI",
    CITYNAME_HEB: "PASNI",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "PSJ",
    AIRPORTNAME: "Poso",
    CITYCODE: "PSJ",
    CITYNAME: "POSO",
    CITYNAME_HEB: "POSO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PSK",
    AIRPORTNAME: "New River Valley",
    CITYCODE: "PSK",
    CITYNAME: "DUBLIN",
    CITYNAME_HEB: "DUBLIN (US)",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "PSL",
    AIRPORTNAME: "Perth",
    CITYCODE: "PSL",
    CITYNAME: "PERTH",
    CITYNAME_HEB: "\u05e4\u05e8\u05ea",
    COUNTRYCODE: "ST",
    COUNTRYNAME: "SCOTLAND",
    COUNTRYNAME_HEB: "\u05e1\u05e7\u05d5\u05d8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PSM",
    AIRPORTNAME: "Pease AFB",
    CITYCODE: "PSM",
    CITYNAME: "PORTSMOUTH",
    CITYNAME_HEB: "PORTSMOUTH (US)",
    COUNTRYCODE: "USNH",
    COUNTRYNAME: "NEW HAMPSHIRE",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d4\u05de\u05e4\u05e9\u05d9\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PSN",
    AIRPORTNAME: "Palestine",
    CITYCODE: "PSN",
    CITYNAME: "PALESTINE",
    CITYNAME_HEB: "PALESTINE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PCM",
    AIRPORTNAME: "Playa del Carmen",
    CITYCODE: "PCM",
    CITYNAME: "PLAYA DEL CARMEN",
    CITYNAME_HEB: "PLAYA DEL CARMEN",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PCN",
    AIRPORTNAME: "Koromiko",
    CITYCODE: "PCN",
    CITYNAME: "PICTON",
    CITYNAME_HEB: "PICTON",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PCO",
    AIRPORTNAME: "Punta Colorada",
    CITYCODE: "PCO",
    CITYNAME: "PUNTA COLORADA",
    CITYNAME_HEB: "PUNTA COLORADA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PCP",
    AIRPORTNAME: " ",
    CITYCODE: "PCP",
    CITYNAME: "SCOTLAND",
    CITYNAME_HEB: "\u05e1\u05e7\u05d5\u05d8\u05dc\u05e0\u05d3",
    COUNTRYCODE: "ST",
    COUNTRYNAME: "SCOTLAND",
    COUNTRYNAME_HEB: "\u05e1\u05e7\u05d5\u05d8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PCR",
    AIRPORTNAME: "Puerto Carreno",
    CITYCODE: "PCR",
    CITYNAME: "PUERTO CARRENO",
    CITYNAME_HEB: "PUERTO CARRENO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PCS",
    AIRPORTNAME: null,
    CITYCODE: "PCS",
    CITYNAME: "PICOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PCT",
    AIRPORTNAME: "Princeton",
    CITYCODE: "PCT",
    CITYNAME: "PRINCETON",
    CITYNAME_HEB: "PRINCETON",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "BDD",
    AIRPORTNAME: "Badu Island",
    CITYCODE: "BDD",
    CITYNAME: "BADU ISLAND",
    CITYNAME_HEB: "BADU ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZTJ",
    AIRPORTNAME: null,
    CITYCODE: "PCT",
    CITYNAME: "PRINCETON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PCU",
    AIRPORTNAME: "Pearl River County",
    CITYCODE: "PCU",
    CITYNAME: "PICAYUNE",
    CITYNAME_HEB: "PICAYUNE",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PCV",
    AIRPORTNAME: "Punta Chivato",
    CITYCODE: "PCV",
    CITYNAME: "PUNTA CHIVATO",
    CITYNAME_HEB: "PUNTA CHIVATO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PDA",
    AIRPORTNAME: "Puerto Inirida",
    CITYCODE: "PDA",
    CITYNAME: "PUERTO INIRIDA",
    CITYNAME_HEB: "PUERTO INIRIDA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PDB",
    AIRPORTNAME: "Pedro Bay",
    CITYCODE: "PDB",
    CITYNAME: "PEDRO BAY",
    CITYNAME_HEB: "Pedro Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PDC",
    AIRPORTNAME: "Mueo",
    CITYCODE: "PDC",
    CITYNAME: "MUEO",
    CITYNAME_HEB: "MUEO",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "PDE",
    AIRPORTNAME: "Pandie Pandie",
    CITYCODE: "PDE",
    CITYNAME: "PANDIE PANDIE",
    CITYNAME_HEB: "PANDIE PANDIE",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PDF",
    AIRPORTNAME: null,
    CITYCODE: "PDF",
    CITYNAME: "PRADO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PDG",
    AIRPORTNAME: "Tabing",
    CITYCODE: "PDG",
    CITYNAME: "PADANG",
    CITYNAME_HEB: "PADANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PDI",
    AIRPORTNAME: " ",
    CITYCODE: "PDI",
    CITYNAME: "PLAYA DEL INGLES",
    CITYNAME_HEB:
      "\u05e4\u05dc\u05d0\u05d9\u05d4 \u05d3\u05d4 \u05d0\u05d9\u05e0\u05d2\u05dc\u05e1",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "BDE",
    AIRPORTNAME: "Baudette",
    CITYCODE: "BDE",
    CITYNAME: "BAUDETTE",
    CITYNAME_HEB: "BAUDETTE",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PDL",
    AIRPORTNAME: "Nordela",
    CITYCODE: "PDL",
    CITYNAME: "PONTA DELGADA AZORES",
    CITYNAME_HEB: "PONTA DELGADA AZORES",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "PDN",
    AIRPORTNAME: "Parndana",
    CITYCODE: "PDN",
    CITYNAME: "PARNDANA",
    CITYNAME_HEB: "PARNDANA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PDO",
    AIRPORTNAME: "Pendopo",
    CITYCODE: "PDO",
    CITYNAME: "PENDOPO",
    CITYNAME_HEB: "PENDOPO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PDP",
    AIRPORTNAME: "Punta Del Este",
    CITYCODE: "PDP",
    CITYNAME: "PUNTA DEL ESTE",
    CITYNAME_HEB: "PUNTA DEL ESTE",
    COUNTRYCODE: "UY",
    COUNTRYNAME: "URUGUAY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e8\u05d5\u05d2\u05d0\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "PDR",
    AIRPORTNAME: null,
    CITYCODE: "PDR",
    CITYNAME: "PRESIDENTE DUTRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PDS",
    AIRPORTNAME: "Piedras Negras",
    CITYCODE: "PDS",
    CITYNAME: "PIEDRAS NEGRAS",
    CITYNAME_HEB: "PIEDRAS NEGRAS",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PDT",
    AIRPORTNAME: "Pendleton",
    CITYCODE: "PDT",
    CITYNAME: "PENDLETON",
    CITYNAME_HEB: "PENDLETON",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PDU",
    AIRPORTNAME: "Paysandu",
    CITYCODE: "PDU",
    CITYNAME: "PAYSANDU",
    CITYNAME_HEB: "PAYSANDU",
    COUNTRYCODE: "UY",
    COUNTRYNAME: "URUGUAY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e8\u05d5\u05d2\u05d0\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "PDV",
    AIRPORTNAME: "Plovdiv",
    CITYCODE: "PDV",
    CITYNAME: "PLOVDIV",
    CITYNAME_HEB: "\u05e4\u05dc\u05d1\u05d5\u05d3\u05d9\u05d1",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PDX",
    AIRPORTNAME: "Portland OR International",
    CITYCODE: "PDX",
    CITYNAME: "PORTLAND",
    CITYNAME_HEB: "PORTLAND",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AAI",
    AIRPORTNAME: null,
    CITYCODE: "AAI",
    CITYNAME: "ARRAIAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ACT",
    AIRPORTNAME: "Waco",
    CITYCODE: "ACT",
    CITYNAME: "WACO",
    CITYNAME_HEB: "Waco",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BDF",
    AIRPORTNAME: "Rinkenberger",
    CITYCODE: "BDF",
    CITYNAME: "BRADFORD",
    CITYNAME_HEB: "BRADFORD",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PDZ",
    AIRPORTNAME: "Pedernales",
    CITYCODE: "PDZ",
    CITYNAME: "PEDERNALES",
    CITYNAME_HEB: "PEDERNALES",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PEA",
    AIRPORTNAME: "Penneshaw",
    CITYCODE: "PEA",
    CITYNAME: "PENNESHAW",
    CITYNAME_HEB: "PENNESHAW",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PEB",
    AIRPORTNAME: "Pebane",
    CITYCODE: "PEB",
    CITYNAME: "PEBANE",
    CITYNAME_HEB: "PEBANE",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "PEC",
    AIRPORTNAME: "Pelican SPB",
    CITYCODE: "PEC",
    CITYNAME: "PELICAN",
    CITYNAME_HEB: "Pelican SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PED",
    AIRPORTNAME: "Pardubice",
    CITYCODE: "PED",
    CITYNAME: "PARDUBICE",
    CITYNAME_HEB: "\u05e4\u05e8\u05d3\u05d5\u05d1\u05d9\u05e5",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PEE",
    AIRPORTNAME: "Perm",
    CITYCODE: "PEE",
    CITYNAME: "PERM",
    CITYNAME_HEB: "PERM",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PEF",
    AIRPORTNAME: "Peenemuende",
    CITYCODE: "PEF",
    CITYNAME: "PEENEMUENDE",
    CITYNAME_HEB: "PEENEMUENDE",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PEG",
    AIRPORTNAME: "Sant Egidio",
    CITYCODE: "PEG",
    CITYNAME: "PERUGIA",
    CITYNAME_HEB: "\u05e4\u05e8\u05d5\u05d2\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PEH",
    AIRPORTNAME: null,
    CITYCODE: "PEH",
    CITYNAME: "PEHUAJO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PEI",
    AIRPORTNAME: "Matecana",
    CITYCODE: "PEI",
    CITYNAME: "PEREIRA",
    CITYNAME_HEB: "PEREIRA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BDG",
    AIRPORTNAME: "Blanding",
    CITYCODE: "BDG",
    CITYNAME: "BLANDING",
    CITYNAME_HEB: "BLANDING",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PEJ",
    AIRPORTNAME: "Peschiei",
    CITYCODE: "PEJ",
    CITYNAME: "PESCHIEI",
    CITYNAME_HEB: "\u05e4\u05e1\u05d0\u05d9\u05e6\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PEM",
    AIRPORTNAME: "Puerto Maldonado",
    CITYCODE: "PEM",
    CITYNAME: "PUERTO MALDONADO",
    CITYNAME_HEB:
      "\u05e4\u05d0\u05d5\u05e8\u05d8\u05d5 \u05de\u05dc\u05d3\u05d5\u05e0\u05d0\u05d3\u05d5",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "PEN",
    AIRPORTNAME: "Penang International",
    CITYCODE: "PEN",
    CITYNAME: "PENANG",
    CITYNAME_HEB: "PENANG",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BWH",
    AIRPORTNAME: "Butterworth",
    CITYCODE: "PEN",
    CITYNAME: "PENANG",
    CITYNAME_HEB: "PENANG",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PEP",
    AIRPORTNAME: "Peppimenarti",
    CITYCODE: "PEP",
    CITYNAME: "PEPPIMENARTI",
    CITYNAME_HEB: "PEPPIMENARTI",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PEQ",
    AIRPORTNAME: "Pecos City",
    CITYCODE: "PEQ",
    CITYNAME: "PECOS CITY",
    CITYNAME_HEB: "PECOS CITY",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PER",
    AIRPORTNAME: "Perth",
    CITYCODE: "PER",
    CITYNAME: "PERTH",
    CITYNAME_HEB: "PERTH",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BHH",
    AIRPORTNAME: " ",
    CITYCODE: "BHH",
    CITYNAME: "BAD HOFGASTEIN",
    CITYNAME_HEB:
      "\u05d1\u05d0\u05d3 \u05d4\u05d5\u05e4\u05d2\u05e9\u05d8\u05d9\u05d9\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FLE",
    AIRPORTNAME: " ",
    CITYCODE: "FLE",
    CITYNAME: "FLEESENSEE",
    CITYNAME_HEB: "\u05e4\u05dc\u05e1\u05e0\u05e1\u05d9",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "SVN",
    CITYNAME: "SAVOGNIN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "LRX",
    CITYNAME: "LERMOOS ZUGSPITZ ARENA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSRTIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PES",
    AIRPORTNAME: "Petrozavodsk",
    CITYCODE: "PES",
    CITYNAME: "PETROZAVODSK",
    CITYNAME_HEB: "PETROZAVODSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PET",
    AIRPORTNAME: null,
    CITYCODE: "PET",
    CITYNAME: "PELOTAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BDI",
    AIRPORTNAME: "Bird Island",
    CITYCODE: "BDI",
    CITYNAME: "BIRD ISLAND",
    CITYNAME_HEB: "BIRD ISLAND",
    COUNTRYCODE: "SC",
    COUNTRYNAME: "SEYCHELLES ISLANDS",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05d9\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "PEU",
    AIRPORTNAME: "Puerto Lempira",
    CITYCODE: "PEU",
    CITYNAME: "PUERTO LEMPIRA",
    CITYNAME_HEB: "PUERTO LEMPIRA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "PEW",
    AIRPORTNAME: "Peshawar",
    CITYCODE: "PEW",
    CITYNAME: "PESHAWAR",
    CITYNAME_HEB: "PESHAWAR",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "PEX",
    AIRPORTNAME: "Pechora",
    CITYCODE: "PEX",
    CITYNAME: "PECHORA",
    CITYNAME_HEB: "PECHORA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PEY",
    AIRPORTNAME: "Penong",
    CITYCODE: "PEY",
    CITYNAME: "PENONG",
    CITYNAME_HEB: "PENONG",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PEZ",
    AIRPORTNAME: "Penza",
    CITYCODE: "PEZ",
    CITYNAME: "PENZA",
    CITYNAME_HEB: "PENZA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PFA",
    AIRPORTNAME: "Paf Warren",
    CITYCODE: "PFA",
    CITYNAME: "PAF WARREN",
    CITYNAME_HEB: "Paf Warren",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PFB",
    AIRPORTNAME: null,
    CITYCODE: "PFB",
    CITYNAME: "PASSO FUNDO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PFC",
    AIRPORTNAME: "State",
    CITYCODE: "PFC",
    CITYNAME: "PACIFIC CITY",
    CITYNAME_HEB: "PACIFIC CITY",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PFD",
    AIRPORTNAME: "Port Frederick",
    CITYCODE: "PFD",
    CITYNAME: "PORT FREDERICK",
    CITYNAME_HEB: "Port Frederick",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PFJ",
    AIRPORTNAME: "Patreksfjordur",
    CITYCODE: "PFJ",
    CITYNAME: "PATREKSFJORDUR",
    CITYNAME_HEB: "PATREKSFJORDUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BDJ",
    AIRPORTNAME: "Sjamsudin Noor",
    CITYCODE: "BDJ",
    CITYNAME: "BANJARMASIN",
    CITYNAME_HEB: "BANJARMASIN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HEV",
    AIRPORTNAME: null,
    CITYCODE: "HEV",
    CITYNAME: "HEVIZ",
    CITYNAME_HEB: "\u05d4\u05d5\u05d5\u05d9\u05d6",
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PFO",
    AIRPORTNAME: "International",
    CITYCODE: "PFO",
    CITYNAME: "PAPHOS",
    CITYNAME_HEB: "\u05e4\u05d0\u05e4\u05d5\u05e1",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "PFR",
    AIRPORTNAME: "Ilebo",
    CITYCODE: "PFR",
    CITYNAME: "ILEBO",
    CITYNAME_HEB: "ILEBO",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "PGA",
    AIRPORTNAME: " ",
    CITYCODE: "PGA",
    CITYNAME: "PARGA",
    CITYNAME_HEB: "\u05e4\u05d0\u05e8\u05d2\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "PGB",
    AIRPORTNAME: "Pangoa",
    CITYCODE: "PGB",
    CITYNAME: "PANGOA",
    CITYNAME_HEB: "PANGOA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "PGC",
    AIRPORTNAME: "Grant County",
    CITYCODE: "PGC",
    CITYNAME: "PETERSBURG",
    CITYNAME_HEB: "PETERSBURG",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "PGD",
    AIRPORTNAME: "Charlotte County",
    CITYCODE: "PGD",
    CITYNAME: "PUNTA GORDA",
    CITYNAME_HEB: "PUNTA GORDA",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PGE",
    AIRPORTNAME: "Yegepa",
    CITYCODE: "PGE",
    CITYNAME: "YEGEPA",
    CITYNAME_HEB: "YEGEPA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "PGF",
    AIRPORTNAME: "Llabanere",
    CITYCODE: "PGF",
    CITYNAME: "PERPIGNAN",
    CITYNAME_HEB: "\u05e4\u05e8\u05e4\u05d9\u05e0\u05d9\u05d0\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "BDK",
    AIRPORTNAME: "Bondoukou",
    CITYCODE: "BDK",
    CITYNAME: "BONDOUKOU",
    CITYNAME_HEB: "BONDOUKOU",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "PGG",
    AIRPORTNAME: null,
    CITYCODE: "PGG",
    CITYNAME: "PROGRESSO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PGH",
    AIRPORTNAME: "Pantnagar",
    CITYCODE: "PGH",
    CITYNAME: "PANTNAGAR",
    CITYNAME_HEB: "PANTNAGAR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "PGI",
    AIRPORTNAME: "Chitato",
    CITYCODE: "PGI",
    CITYNAME: "CHITATO",
    CITYNAME_HEB: "CHITATO",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PGK",
    AIRPORTNAME: "Pangkalpinang",
    CITYCODE: "PGK",
    CITYNAME: "PANGKALPINANG",
    CITYNAME_HEB: "PANGKALPINANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PGL",
    AIRPORTNAME: " ",
    CITYCODE: "PGL",
    CITYNAME: "PORTUGAL",
    CITYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "PGM",
    AIRPORTNAME: "Port Graham",
    CITYCODE: "PGM",
    CITYNAME: "PORT GRAHAM",
    CITYNAME_HEB: "Port Graham",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PGN",
    AIRPORTNAME: "Pangia",
    CITYCODE: "PGN",
    CITYNAME: "PANGIA",
    CITYNAME_HEB: "PANGIA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "PGO",
    AIRPORTNAME: "Stevens Field",
    CITYCODE: "PGO",
    CITYNAME: "PAGOSA SPRINGS",
    CITYNAME_HEB: "PAGOSA SPRINGS",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PGR",
    AIRPORTNAME: "Municipal",
    CITYCODE: "PGR",
    CITYNAME: "PARAGOULD",
    CITYNAME_HEB: "PARAGOULD",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BDM",
    AIRPORTNAME: "Bandirma",
    CITYCODE: "BDM",
    CITYNAME: "BANDIRMA",
    CITYNAME_HEB: "BANDIRMA",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PGS",
    AIRPORTNAME: "Peach Springs",
    CITYCODE: "PGS",
    CITYNAME: "PEACH SPRINGS",
    CITYNAME_HEB: "PEACH SPRINGS",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PGV",
    AIRPORTNAME: "Pitt-Greenville",
    CITYCODE: "PGV",
    CITYNAME: "GREENVILLE",
    CITYNAME_HEB: "GREENVILLE",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PGX",
    AIRPORTNAME: "Perigueux",
    CITYCODE: "PGX",
    CITYNAME: "PERIGUEUX",
    CITYNAME_HEB: "PERIGUEUX",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "PGZ",
    AIRPORTNAME: null,
    CITYCODE: "PGZ",
    CITYNAME: "PONTA GROSSA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PHA",
    AIRPORTNAME: "Phan Rang",
    CITYCODE: "PHA",
    CITYNAME: "PHAN RANG",
    CITYNAME_HEB: "PHAN RANG",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "PHB",
    AIRPORTNAME: null,
    CITYCODE: "PHB",
    CITYNAME: "PARNAIBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PHC",
    AIRPORTNAME: "Port Harcourt",
    CITYCODE: "PHC",
    CITYNAME: "PORT HARCOURT",
    CITYNAME_HEB: "PORT HARCOURT",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PHD",
    AIRPORTNAME: "Harry Clever",
    CITYCODE: "PHD",
    CITYNAME: "NEW PHILADELPHIA",
    CITYNAME_HEB: "NEW PHILADELPHIA",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PHE",
    AIRPORTNAME: "Port Hedland",
    CITYCODE: "PHE",
    CITYNAME: "PORT HEDLAND",
    CITYNAME_HEB: "PORT HEDLAND",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LFI",
    AIRPORTNAME: null,
    CITYCODE: "PHF",
    CITYNAME: "NEWPORT NEWS HAMP",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BDN",
    AIRPORTNAME: "Talhar",
    CITYCODE: "BDN",
    CITYNAME: "BADIN",
    CITYNAME_HEB: "BADIN",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "PHF",
    AIRPORTNAME: "Newport News Williamsburg International",
    CITYCODE: "PHF",
    CITYNAME: "NEWPORT NEWS HAMP",
    CITYNAME_HEB: "\u05e0\u05d9\u05d5 \u05e4\u05d5\u05e8\u05d8",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "ZWB",
    AIRPORTNAME: null,
    CITYCODE: "PHF",
    CITYNAME: "NEWPORT NEWS HAMP",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZWW",
    AIRPORTNAME: null,
    CITYCODE: "PHF",
    CITYNAME: "NEWPORT NEWS HAMP",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PHH",
    AIRPORTNAME: "Phan Thiet",
    CITYCODE: "PHH",
    CITYNAME: "PHAN THIET",
    CITYNAME_HEB: "PHAN THIET",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "PHI",
    AIRPORTNAME: null,
    CITYCODE: "PHI",
    CITYNAME: "PINHEIRO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PHJ",
    AIRPORTNAME: "Port Hunter",
    CITYCODE: "PHJ",
    CITYNAME: "PORT HUNTER",
    CITYNAME_HEB: "PORT HUNTER",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PHK",
    AIRPORTNAME: "Palm Beach Co Glades",
    CITYCODE: "PHK",
    CITYNAME: "PAHOKEE",
    CITYNAME_HEB: "PAHOKEE",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PHL",
    AIRPORTNAME: "International",
    CITYCODE: "PHL",
    CITYNAME: "PHILADELPHIA",
    CITYNAME_HEB: "\u05e4\u05d9\u05dc\u05d3\u05dc\u05e4\u05d9\u05d4",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "BDO",
    AIRPORTNAME: "Husein Sastranegara",
    CITYCODE: "BDO",
    CITYNAME: "BANDUNG",
    CITYNAME_HEB: "BANDUNG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PHM",
    AIRPORTNAME: "Boeblingen",
    CITYCODE: "PHM",
    CITYNAME: "BOEBLINGEN",
    CITYNAME_HEB: "BOEBLINGEN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PHN",
    AIRPORTNAME: "St Clair County International",
    CITYCODE: "PHN",
    CITYNAME: "PORT HURON",
    CITYNAME_HEB: "PORT HURON",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PHO",
    AIRPORTNAME: "Point Hope",
    CITYCODE: "PHO",
    CITYNAME: "POINT HOPE",
    CITYNAME_HEB: "Point Hope",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PHP",
    AIRPORTNAME: "Philip",
    CITYCODE: "PHP",
    CITYNAME: "PHILIP",
    CITYNAME_HEB: "PHILIP",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PHR",
    AIRPORTNAME: "Pacific Harbor",
    CITYCODE: "PHR",
    CITYNAME: "PACIFIC HARBOR",
    CITYNAME_HEB: "PACIFIC HARBOR",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "BDP",
    AIRPORTNAME: "Bhadrapur",
    CITYCODE: "BDP",
    CITYNAME: "BHADRAPUR",
    CITYNAME_HEB: "BHADRAPUR",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "PHS",
    AIRPORTNAME: "Phitsanulok",
    CITYCODE: "PHS",
    CITYNAME: "PHITSANULOK",
    CITYNAME_HEB: "PHITSANULOK",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PHU",
    AIRPORTNAME: "Phu Vinh",
    CITYCODE: "PHU",
    CITYNAME: "PHU VINH",
    CITYNAME_HEB: "PHU VINH",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "PHW",
    AIRPORTNAME: "Phalaborwa",
    CITYCODE: "PHW",
    CITYNAME: "PHALABORWA",
    CITYNAME_HEB: "PHALABORWA",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "PHX",
    AIRPORTNAME: "Sky Harbor International",
    CITYCODE: "PHX",
    CITYNAME: "PHOENIX",
    CITYNAME_HEB: "\u05e4\u05d9\u05e0\u05d9\u05e7\u05e1",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DVT",
    AIRPORTNAME: null,
    CITYCODE: "PHX",
    CITYNAME: "PHOENIX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LUF",
    AIRPORTNAME: null,
    CITYCODE: "PHX",
    CITYNAME: "PHOENIX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SCF",
    AIRPORTNAME: null,
    CITYCODE: "PHX",
    CITYNAME: "PHOENIX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PHY",
    AIRPORTNAME: "Phetchabun",
    CITYCODE: "PHY",
    CITYNAME: "PHETCHABUN",
    CITYNAME_HEB: "PHETCHABUN",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PHZ",
    AIRPORTNAME: "Phi Phi Island",
    CITYCODE: "PHZ",
    CITYNAME: "PHI PHI ISLAND",
    CITYNAME_HEB: "\u05e7\u05d5 \u05e4\u05d9 \u05e4\u05d9",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BDQ",
    AIRPORTNAME: "Vadodara",
    CITYCODE: "BDQ",
    CITYNAME: "VADODARA",
    CITYNAME_HEB: "VADODARA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "PIA",
    AIRPORTNAME: "Greater Peoria",
    CITYCODE: "PIA",
    CITYNAME: "PEORIA",
    CITYNAME_HEB: "PEORIA",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PIC",
    AIRPORTNAME: null,
    CITYCODE: "PIC",
    CITYNAME: "PINE CAY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TC",
    COUNTRYNAME: "TURKS AND CAICOS ISLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PIE",
    AIRPORTNAME: "St. Petersburg-Clearwater International",
    CITYCODE: "PIE",
    CITYNAME: "SAINT PETERSBURG",
    CITYNAME_HEB: "SAINT PETERSBURG",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SPG",
    AIRPORTNAME: null,
    CITYCODE: "PIE",
    CITYNAME: "SAINT PETERSBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PIF",
    AIRPORTNAME: "Pingtung",
    CITYCODE: "PIF",
    CITYNAME: "PINGTUNG",
    CITYNAME_HEB: "PINGTUNG",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "PIG",
    AIRPORTNAME: null,
    CITYCODE: "PIG",
    CITYNAME: "PITINGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PIH",
    AIRPORTNAME: "Pocatello",
    CITYCODE: "PIH",
    CITYNAME: "POCATELLO",
    CITYNAME_HEB: "POCATELLO",
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d9\u05d3\u05d4\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PIL",
    AIRPORTNAME: " ",
    CITYCODE: "PIL",
    CITYNAME: "PILION AREA",
    CITYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05d5\u05df",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "PIM",
    AIRPORTNAME: "Garden Harris County",
    CITYCODE: "PIM",
    CITYNAME: "PINE MOUNTAIN",
    CITYNAME_HEB: "PINE MOUNTAIN",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "PIN",
    AIRPORTNAME: null,
    CITYCODE: "PIN",
    CITYNAME: "PARINTINS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CNW",
    AIRPORTNAME: "Waco James Connall",
    CITYCODE: "ACT",
    CITYNAME: "WACO",
    CITYNAME_HEB: "Waco James Connall",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BDR",
    AIRPORTNAME: "Igor I. Sikorsky Mem.",
    CITYCODE: "BDR",
    CITYNAME: "BRIDGEPORT",
    CITYNAME_HEB: "BRIDGEPORT",
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05e0\u05d8\u05d9\u05e7\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PIO",
    AIRPORTNAME: "Pisco",
    CITYCODE: "PIO",
    CITYNAME: "PISCO",
    CITYNAME_HEB: "PISCO",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "PIP",
    AIRPORTNAME: "Pilot Point Airport",
    CITYCODE: "PIP",
    CITYNAME: "PILOT POINT",
    CITYNAME_HEB: "Pilot Point Airport",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UGB",
    AIRPORTNAME: "Ugashik Bay",
    CITYCODE: "PIP",
    CITYNAME: "PILOT POINT",
    CITYNAME_HEB: "Ugashik Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PIQ",
    AIRPORTNAME: "Pipillipai",
    CITYCODE: "PIQ",
    CITYNAME: "PIPILLIPAI",
    CITYNAME_HEB: "PIPILLIPAI",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "PIR",
    AIRPORTNAME: " ",
    CITYCODE: "PIR",
    CITYNAME: "PIRAN",
    CITYNAME_HEB: "\u05e4\u05d9\u05e8\u05d0\u05df",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PIS",
    AIRPORTNAME: "Biard",
    CITYCODE: "PIS",
    CITYNAME: "POITIERS",
    CITYNAME_HEB: "POITIERS",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "XOP",
    AIRPORTNAME: null,
    CITYCODE: "PIS",
    CITYNAME: "POITIERS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PIT",
    AIRPORTNAME: "Pittsburgh International Airport",
    CITYCODE: "PIT",
    CITYNAME: "PITTSBURGH",
    CITYNAME_HEB: "\u05e4\u05d9\u05d8\u05e1\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AGC",
    AIRPORTNAME: null,
    CITYCODE: "PIT",
    CITYNAME: "PITTSBURGH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BDS",
    AIRPORTNAME: "Papola Casale",
    CITYCODE: "BDS",
    CITYNAME: "BRINDISI",
    CITYNAME_HEB: "\u05d1\u05e8\u05d9\u05e0\u05d3\u05d9\u05d6\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PIU",
    AIRPORTNAME: "Piura",
    CITYCODE: "PIU",
    CITYNAME: "PIURA",
    CITYNAME_HEB: "PIURA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "PIV",
    AIRPORTNAME: null,
    CITYCODE: "PIV",
    CITYNAME: "PIRAPORA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PIW",
    AIRPORTNAME: "Pikwitonei",
    CITYCODE: "PIW",
    CITYNAME: "PIKWITONEI",
    CITYNAME_HEB: "PIKWITONEI",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "PIX",
    AIRPORTNAME: "Pico Island",
    CITYCODE: "PIX",
    CITYNAME: "PICO ISLAND",
    CITYNAME_HEB: "PICO ISLAND",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "PIZ",
    AIRPORTNAME: "Dew Station",
    CITYCODE: "PIZ",
    CITYNAME: "POINT LAY",
    CITYNAME_HEB: "Dew Station",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PJA",
    AIRPORTNAME: "Pajala",
    CITYCODE: "PJA",
    CITYNAME: "PAJALA",
    CITYNAME_HEB: "PAJALA",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PJB",
    AIRPORTNAME: "Payson",
    CITYCODE: "PJB",
    CITYNAME: "PAYSON",
    CITYNAME_HEB: "PAYSON",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PJC",
    AIRPORTNAME: "Pedro Juan Caballero",
    CITYCODE: "PJC",
    CITYNAME: "PEDRO JUAN CABALL",
    CITYNAME_HEB: "PEDRO JUAN CABALL",
    COUNTRYCODE: "PY",
    COUNTRYNAME: "PARAGUAY",
    COUNTRYNAME_HEB: "\u05e4\u05d0\u05e8\u05d0\u05d2\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "PJG",
    AIRPORTNAME: "Panjgur",
    CITYCODE: "PJG",
    CITYNAME: "PANJGUR",
    CITYNAME_HEB: "PANJGUR",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "PJM",
    AIRPORTNAME: "Puerto Jimenez",
    CITYCODE: "PJM",
    CITYNAME: "PUERTO JIMENEZ",
    CITYNAME_HEB: "PUERTO JIMENEZ",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "BDT",
    AIRPORTNAME: "Gbadolite",
    CITYCODE: "BDT",
    CITYNAME: "GBADOLITE",
    CITYNAME_HEB: "GBADOLITE",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "PJS",
    AIRPORTNAME: "Port San Juan",
    CITYCODE: "PJS",
    CITYNAME: "PORT SAN JUAN",
    CITYNAME_HEB: "Port San Juan",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PJZ",
    AIRPORTNAME: "Puerto Juarez",
    CITYCODE: "PJZ",
    CITYNAME: "PUERTO JUAREZ",
    CITYNAME_HEB: "PUERTO JUAREZ",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PKA",
    AIRPORTNAME: "Napaskiak",
    CITYCODE: "PKA",
    CITYNAME: "NAPASKIAK",
    CITYNAME_HEB: "Napaskiak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PKB",
    AIRPORTNAME: "Wood County Airport",
    CITYCODE: "PKB",
    CITYNAME: "PARKERSBURG",
    CITYNAME_HEB: "PARKERSBURG",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "PKC",
    AIRPORTNAME: "Petropavlovsk-Kamchats",
    CITYCODE: "PKC",
    CITYNAME: "PETROPAVLOVSK KAM",
    CITYNAME_HEB: "PETROPAVLOVSK KAM",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PKD",
    AIRPORTNAME: "Park Rapids",
    CITYCODE: "PKD",
    CITYNAME: "PARK RAPIDS",
    CITYNAME_HEB: "PARK RAPIDS",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PKE",
    AIRPORTNAME: "Parkes",
    CITYCODE: "PKE",
    CITYNAME: "PARKES",
    CITYNAME_HEB: "PARKES",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PKF",
    AIRPORTNAME: "Park Falls",
    CITYCODE: "PKF",
    CITYNAME: "PARK FALLS",
    CITYNAME_HEB: "PARK FALLS",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PKG",
    AIRPORTNAME: "Pangkor Airport",
    CITYCODE: "PKG",
    CITYNAME: "PANGKOR PERAK",
    CITYNAME_HEB: "PANGKOR PERAK",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PKH",
    AIRPORTNAME: "Alexion",
    CITYCODE: "PKH",
    CITYNAME: "PORTO KHELI",
    CITYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e7\u05d0\u05dc\u05d9",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "PKJ",
    AIRPORTNAME: "Playa Grande",
    CITYCODE: "PKJ",
    CITYNAME: "PLAYA GRANDE",
    CITYNAME_HEB: "PLAYA GRANDE",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PKK",
    AIRPORTNAME: "Pakokku",
    CITYCODE: "PKK",
    CITYNAME: "PAKOKKU",
    CITYNAME_HEB: "PAKOKKU",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PKL",
    AIRPORTNAME: "Pakatoa Island",
    CITYCODE: "PKL",
    CITYNAME: "PAKATOA ISLAND",
    CITYNAME_HEB: "PAKATOA ISLAND",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PKM",
    AIRPORTNAME: "Port Kaituma",
    CITYCODE: "PKM",
    CITYNAME: "PORT KAITUMA",
    CITYNAME_HEB: "PORT KAITUMA",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "PKN",
    AIRPORTNAME: "Pangkalanbuun",
    CITYCODE: "PKN",
    CITYNAME: "PANGKALANBUUN",
    CITYNAME_HEB: "PANGKALANBUUN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PKO",
    AIRPORTNAME: "Parakou",
    CITYCODE: "PKO",
    CITYNAME: "PARAKOU",
    CITYNAME_HEB: "PARAKOU",
    COUNTRYCODE: "BJ",
    COUNTRYNAME: "BENIN",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d9\u05df",
  },
  {
    AIRPORTCODE: "PKP",
    AIRPORTNAME: "Puka Puka",
    CITYCODE: "PKP",
    CITYNAME: "PUKA PUKA",
    CITYNAME_HEB: "PUKA PUKA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "PKR",
    AIRPORTNAME: "Pokhara",
    CITYCODE: "PKR",
    CITYNAME: "POKHARA",
    CITYNAME_HEB: "POKHARA",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "PKS",
    AIRPORTNAME: "Paksane",
    CITYCODE: "PKS",
    CITYNAME: "PAKSANE",
    CITYNAME_HEB: "PAKSANE",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "PKT",
    AIRPORTNAME: "Port Keats",
    CITYCODE: "PKT",
    CITYNAME: "PORT KEATS",
    CITYNAME_HEB: "PORT KEATS",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BDV",
    AIRPORTNAME: "Moba",
    CITYCODE: "BDV",
    CITYNAME: "MOBA",
    CITYNAME_HEB: "MOBA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "PKU",
    AIRPORTNAME: "Simpang Tiga",
    CITYCODE: "PKU",
    CITYNAME: "PEKANBARU",
    CITYNAME_HEB: "PEKANBARU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PKV",
    AIRPORTNAME: "Pskov",
    CITYCODE: "PKV",
    CITYNAME: "PSKOV",
    CITYNAME_HEB: "PSKOV",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PKW",
    AIRPORTNAME: "Selebi-Phikwe",
    CITYCODE: "PKW",
    CITYNAME: "SELEBI PHIKWE",
    CITYNAME_HEB: "SELEBI PHIKWE",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "PKY",
    AIRPORTNAME: "Palangkaraya",
    CITYCODE: "PKY",
    CITYNAME: "PALANGKARAYA",
    CITYNAME_HEB: "PALANGKARAYA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PKZ",
    AIRPORTNAME: "Pakse",
    CITYCODE: "PKZ",
    CITYNAME: "PAKSE",
    CITYNAME_HEB: "PAKSE",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAOS",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "PLA",
    CITYNAME: "PILA",
    CITYNAME_HEB: "\u05e4\u05d9\u05dc\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PLC",
    AIRPORTNAME: "Planeta Rica",
    CITYCODE: "PLC",
    CITYNAME: "PLANETA RICA",
    CITYNAME_HEB: "PLANETA RICA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PLD",
    AIRPORTNAME: "Playa Samara",
    CITYCODE: "PLD",
    CITYNAME: "PLAYA SAMARA",
    CITYNAME_HEB: "PLAYA SAMARA",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "BDW",
    AIRPORTNAME: "Bedford Downs",
    CITYCODE: "BDW",
    CITYNAME: "BEDFORD DOWNS",
    CITYNAME_HEB: "BEDFORD DOWNS",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PLE",
    AIRPORTNAME: "Paiela",
    CITYCODE: "PLE",
    CITYNAME: "PAIELA",
    CITYNAME_HEB: "PAIELA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "PLF",
    AIRPORTNAME: "Pala",
    CITYCODE: "PLF",
    CITYNAME: "PALA",
    CITYNAME_HEB: "PALA",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "PLG",
    AIRPORTNAME: "La Plagne",
    CITYCODE: "PLG",
    CITYNAME: "LA PLAGNE",
    CITYNAME_HEB: "\u05dc\u05d4 \u05e4\u05dc\u05d0\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "PLH",
    AIRPORTNAME: "Plymouth",
    CITYCODE: "PLH",
    CITYNAME: "PLYMOUTH",
    CITYNAME_HEB: "PLYMOUTH",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PLJ",
    AIRPORTNAME: "Placencia",
    CITYCODE: "PLJ",
    CITYNAME: "PLACENCIA",
    CITYNAME_HEB: "PLACENCIA",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "PLK",
    AIRPORTNAME: "M Graham Clark",
    CITYCODE: "PLK",
    CITYNAME: "BRANSON/POINT LOOKOUT",
    CITYNAME_HEB: "BRANSON/POINT LOOKOUT",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PLL",
    AIRPORTNAME: null,
    CITYCODE: "PLL",
    CITYNAME: "PONTA PELADA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PLM",
    AIRPORTNAME: "Mahmud Badaruddin Ii",
    CITYCODE: "PLM",
    CITYNAME: "PALEMBANG",
    CITYNAME_HEB: "PALEMBANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PLN",
    AIRPORTNAME: "Emmet County",
    CITYCODE: "PLN",
    CITYNAME: "PELLSTON",
    CITYNAME_HEB: "PELLSTON",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BDX",
    AIRPORTNAME: "Broadus",
    CITYCODE: "BDX",
    CITYNAME: "BROADUS",
    CITYNAME_HEB: "BROADUS",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PLO",
    AIRPORTNAME: "Port Lincoln",
    CITYCODE: "PLO",
    CITYNAME: "PORT LINCOLN",
    CITYNAME_HEB: "PORT LINCOLN",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PLP",
    AIRPORTNAME: "La Palma",
    CITYCODE: "PLP",
    CITYNAME: "LA PALMA",
    CITYNAME_HEB: "LA PALMA",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PLQ",
    AIRPORTNAME: "Palanga",
    CITYCODE: "PLQ",
    CITYNAME: "PALANGA",
    CITYNAME_HEB: "\u05e4\u05dc\u05d0\u05e0\u05d2\u05d4",
    COUNTRYCODE: "LT",
    COUNTRYNAME: "LITHUANIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d8\u05d0",
  },
  {
    AIRPORTCODE: "PLR",
    AIRPORTNAME: "St Clair County",
    CITYCODE: "PLR",
    CITYNAME: "PELL CITY",
    CITYNAME_HEB: "Pell City",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PLS",
    AIRPORTNAME: null,
    CITYCODE: "PLS",
    CITYNAME: "PROVIDENCIALES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TC",
    COUNTRYNAME: "TURKS AND CAICOS ISLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PLT",
    AIRPORTNAME: "Plato",
    CITYCODE: "PLT",
    CITYNAME: "PLATO",
    CITYNAME_HEB: "PLATO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NAA",
    AIRPORTNAME: "Narrabri",
    CITYCODE: "NAA",
    CITYNAME: "NARRABRI",
    CITYNAME_HEB: "NARRABRI",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NAC",
    AIRPORTNAME: "Naracoorte",
    CITYCODE: "NAC",
    CITYNAME: "NARACOORTE",
    CITYNAME_HEB: "NARACOORTE",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NAD",
    AIRPORTNAME: "Macanal",
    CITYCODE: "NAD",
    CITYNAME: "MACANAL",
    CITYNAME_HEB: "MACANAL",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NAE",
    AIRPORTNAME: "Natitingou",
    CITYCODE: "NAE",
    CITYNAME: "NATITINGOU",
    CITYNAME_HEB: "NATITINGOU",
    COUNTRYCODE: "BJ",
    COUNTRYNAME: "BENIN",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d9\u05df",
  },
  {
    AIRPORTCODE: "NAF",
    AIRPORTNAME: " ",
    CITYCODE: "NAF",
    CITYNAME: "NAFPLIO",
    CITYNAME_HEB: "\u05e0\u05e4\u05e4\u05dc\u05d9\u05d5",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "BAJ",
    AIRPORTNAME: "Bali",
    CITYCODE: "BAJ",
    CITYNAME: "BALI",
    CITYNAME_HEB: "BALI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NAG",
    AIRPORTNAME: "Sonegaon",
    CITYCODE: "NAG",
    CITYNAME: "NAGPUR",
    CITYNAME_HEB: "NAGPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "NAH",
    AIRPORTNAME: "Naha",
    CITYCODE: "NAH",
    CITYNAME: "NAHA",
    CITYNAME_HEB: "NAHA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NAI",
    AIRPORTNAME: "Annai",
    CITYCODE: "NAI",
    CITYNAME: "ANNAI",
    CITYNAME_HEB: "ANNAI",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "NAJ",
    AIRPORTNAME: "Nakhichevan",
    CITYCODE: "NAJ",
    CITYNAME: "NAKHICHEVAN",
    CITYNAME_HEB: "\u05e0\u05d7\u05e6\u05d9\u05d1\u05d0\u05df",
    COUNTRYCODE: "AZ",
    COUNTRYNAME: "AZERBAIJAN",
    COUNTRYNAME_HEB: "\u05d0\u05d6\u05e8\u05d1\u05d9\u05d9\u05d2\u05df",
  },
  {
    AIRPORTCODE: "NAK",
    AIRPORTNAME: "Nakhon Ratchasima",
    CITYCODE: "NAK",
    CITYNAME: "NAKHON RATCHASIMA",
    CITYNAME_HEB: "NAKHON RATCHASIMA",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "NAL",
    AIRPORTNAME: "Nalchik",
    CITYCODE: "NAL",
    CITYNAME: "NALCHIK",
    CITYNAME_HEB: "NALCHIK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NAM",
    AIRPORTNAME: "Namlea",
    CITYCODE: "NAM",
    CITYNAME: "NAMLEA",
    CITYNAME_HEB: "NAMLEA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NAN",
    AIRPORTNAME: "International",
    CITYCODE: "NAN",
    CITYNAME: "NADI",
    CITYNAME_HEB: "NADI",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "NAO",
    AIRPORTNAME: "Nanchong",
    CITYCODE: "NAO",
    CITYNAME: "NANCHONG",
    CITYNAME_HEB: "NANCHONG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "NAP",
    AIRPORTNAME: "Naples",
    CITYCODE: "NAP",
    CITYNAME: "NAPLES",
    CITYNAME_HEB: "\u05e0\u05d0\u05e4\u05d5\u05dc\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NAQ",
    AIRPORTNAME: "Qaanaaq",
    CITYCODE: "NAQ",
    CITYNAME: "QAANAAQ",
    CITYNAME_HEB: "QAANAAQ",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "NAR",
    AIRPORTNAME: "Nare",
    CITYCODE: "NAR",
    CITYNAME: "NARBONNE",
    CITYNAME_HEB: "\u05e0\u05e8\u05d1\u05d5\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "NAS",
    AIRPORTNAME: "Nassau International",
    CITYCODE: "NAS",
    CITYNAME: "NASSAU",
    CITYNAME_HEB: "\u05e0\u05e1\u05d0\u05d5",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "WZY",
    AIRPORTNAME: null,
    CITYCODE: "NAS",
    CITYNAME: "NASSAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NAT",
    AIRPORTNAME: null,
    CITYCODE: "NAT",
    CITYNAME: "NATAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NAU",
    AIRPORTNAME: "Napuka Island",
    CITYCODE: "NAU",
    CITYNAME: "NAPUKA ISLAND",
    CITYNAME_HEB: "NAPUKA ISLAND",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "NAV",
    AIRPORTNAME: "Nevsehir",
    CITYCODE: "NAV",
    CITYNAME: "NEVSEHIR-CAPPADOCIA",
    CITYNAME_HEB: "\u05e7\u05e4\u05d3\u05d5\u05e7\u05d9\u05d4",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NAW",
    AIRPORTNAME: "Narathiwat",
    CITYCODE: "NAW",
    CITYNAME: "NARATHIWAT",
    CITYNAME_HEB: "NARATHIWAT",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ACK",
    AIRPORTNAME: "Nantucket",
    CITYCODE: "ACK",
    CITYNAME: "NANTUCKET",
    CITYNAME_HEB: "Nantucket",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "BAL",
    AIRPORTNAME: "Batman",
    CITYCODE: "BAL",
    CITYNAME: "BATMAN",
    CITYNAME_HEB: "BATMAN",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NAX",
    AIRPORTNAME: "Barbers Point",
    CITYCODE: "NAX",
    CITYNAME: "BARBERS POINT",
    CITYNAME_HEB: "BARBERS POINT",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NBA",
    AIRPORTNAME: "Nambaiyufa",
    CITYCODE: "NBA",
    CITYNAME: "NAMBAIYUFA",
    CITYNAME_HEB: "NAMBAIYUFA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NBB",
    AIRPORTNAME: "Barrancominas",
    CITYCODE: "NBB",
    CITYNAME: "BARRANCOMINAS",
    CITYNAME_HEB: "BARRANCOMINAS",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NBC",
    AIRPORTNAME: "Naberevnye Chelny",
    CITYCODE: "NBC",
    CITYNAME: "NABEREVNYE CHELNY",
    CITYNAME_HEB: "NABEREVNYE CHELNY",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NBH",
    AIRPORTNAME: "Nambucca Heads",
    CITYCODE: "NBH",
    CITYNAME: "NAMBUCCA HEADS",
    CITYNAME_HEB: "NAMBUCCA HEADS",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NBL",
    AIRPORTNAME: "San Blas",
    CITYCODE: "NBL",
    CITYNAME: "SAN BLAS",
    CITYNAME_HEB: "SAN BLAS",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "NBR",
    AIRPORTNAME: "Nambour",
    CITYCODE: "NBR",
    CITYNAME: "NAMBOUR",
    CITYNAME_HEB: "NAMBOUR",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NBU",
    AIRPORTNAME: "NAS",
    CITYCODE: "NBU",
    CITYNAME: "GLENVIEW",
    CITYNAME_HEB: "GLENVIEW",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BAM",
    AIRPORTNAME: "Lander County",
    CITYCODE: "BAM",
    CITYNAME: "BATTLE MOUNTAIN",
    CITYNAME_HEB: "BATTLE MOUNTAIN",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NBV",
    AIRPORTNAME: null,
    CITYCODE: "NBV",
    CITYNAME: "CANA BRAVA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NBX",
    AIRPORTNAME: "Nabire",
    CITYCODE: "NBX",
    CITYNAME: "NABIRE",
    CITYNAME_HEB: "NABIRE",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NCA",
    AIRPORTNAME: null,
    CITYCODE: "NCA",
    CITYNAME: "NORTH CAICOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TC",
    COUNTRYNAME: "TURKS AND CAICOS ISLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NCE",
    AIRPORTNAME: "Cote D'Azur",
    CITYCODE: "NCE",
    CITYNAME: "NICE",
    CITYNAME_HEB: "\u05e0\u05d9\u05e1",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "NCG",
    AIRPORTNAME: "Nueva Casas Grandes",
    CITYCODE: "NCG",
    CITYNAME: "NUEVA CASAS GRANDES",
    CITYNAME_HEB: "NUEVA CASAS GRANDES",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "NCH",
    AIRPORTNAME: "Nachingwea",
    CITYCODE: "NCH",
    CITYNAME: "NACHINGWEA",
    CITYNAME_HEB: "NACHINGWEA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NCI",
    AIRPORTNAME: "Necocli",
    CITYCODE: "NCI",
    CITYNAME: "NECOCLI",
    CITYNAME_HEB: "NECOCLI",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NCL",
    AIRPORTNAME: "Newcastle Airport",
    CITYCODE: "NCL",
    CITYNAME: "NEWCASTLE",
    CITYNAME_HEB: "\u05e0\u05d9\u05d5\u05e7\u05d0\u05e1\u05dc",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NCN",
    AIRPORTNAME: "New Chenega",
    CITYCODE: "NCN",
    CITYNAME: "NEW CHENEGA",
    CITYNAME_HEB: "New Chenega",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NCO",
    AIRPORTNAME: "NAS",
    CITYCODE: "NCO",
    CITYNAME: "QUONSET POINT",
    CITYNAME_HEB: "QUONSET POINT",
    COUNTRYCODE: "USRI",
    COUNTRYNAME: "RHODE ISLAND",
    COUNTRYNAME_HEB:
      '\u05e8\u05d5\u05d3 \u05d0\u05d9\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BAN",
    AIRPORTNAME: " ",
    CITYCODE: "BAN",
    CITYNAME: "BANSKO",
    CITYNAME_HEB: "\u05d1\u05e0\u05e1\u05e7\u05d5",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CRK",
    AIRPORTNAME: "Clark Field",
    CITYCODE: "NCP",
    CITYNAME: "LUZON IS NCP",
    CITYNAME_HEB: "LUZON IS NCP",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "NCP",
    AIRPORTNAME: "Cubi Pt NAS",
    CITYCODE: "NCP",
    CITYNAME: "LUZON IS NCP",
    CITYNAME_HEB: "LUZON IS NCP",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "NCR",
    AIRPORTNAME: "San Carlos",
    CITYCODE: "NCR",
    CITYNAME: "SAN CARLOS",
    CITYNAME_HEB: "SAN CARLOS",
    COUNTRYCODE: "NI",
    COUNTRYNAME: "NICARAGUA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05e7\u05e8\u05d0\u05d2\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NCS",
    AIRPORTNAME: "Newcastle",
    CITYCODE: "NCS",
    CITYNAME: "NEWCASTLE",
    CITYNAME_HEB: "NEWCASTLE",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "NCT",
    AIRPORTNAME: "Guanacaste",
    CITYCODE: "NCT",
    CITYNAME: "NICOYA",
    CITYNAME_HEB: "NICOYA",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "NCU",
    AIRPORTNAME: "Nukus",
    CITYCODE: "NCU",
    CITYNAME: "NUKUS",
    CITYNAME_HEB: "NUKUS",
    COUNTRYCODE: "UZ",
    COUNTRYNAME: "UZBEKISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d6\u05d1\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "NCY",
    AIRPORTNAME: "Annecy-Meythe",
    CITYCODE: "NCY",
    CITYNAME: "ANNECY",
    CITYNAME_HEB: "\u05d0\u05e0\u05e1\u05d9",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "NDA",
    AIRPORTNAME: "Bandanaira",
    CITYCODE: "NDA",
    CITYNAME: "BANDANAIRA",
    CITYNAME_HEB: "BANDANAIRA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NDB",
    AIRPORTNAME: "Nouadhibou",
    CITYCODE: "NDB",
    CITYNAME: "NOUADHIBOU",
    CITYNAME_HEB: "\u05e0\u05d5\u05d0\u05db\u05e9\u05d5\u05d8",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NDC",
    AIRPORTNAME: "Nanded",
    CITYCODE: "NDC",
    CITYNAME: "NANDED",
    CITYNAME_HEB: "NANDED",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "BAO",
    AIRPORTNAME: "Udorn",
    CITYCODE: "BAO",
    CITYNAME: "BAN MAK KHAEN",
    CITYNAME_HEB: "BAN MAK KHAEN",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "NDE",
    AIRPORTNAME: "Mandera",
    CITYCODE: "NDE",
    CITYNAME: "MANDERA",
    CITYNAME_HEB: "MANDERA",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NDF",
    AIRPORTNAME: "Ndalatandos",
    CITYCODE: "NDF",
    CITYNAME: "NDALATANDOS",
    CITYNAME_HEB: "NDALATANDOS",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "NDG",
    AIRPORTNAME: "Qiqihar",
    CITYCODE: "NDG",
    CITYNAME: "QIQIHAR",
    CITYNAME_HEB: "QIQIHAR",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "NDI",
    AIRPORTNAME: "Namudi",
    CITYCODE: "NDI",
    CITYNAME: "NAMUDI",
    CITYNAME_HEB: "NAMUDI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NDJ",
    AIRPORTNAME: "Ndjamena",
    CITYCODE: "NDJ",
    CITYNAME: "NDJAMENA",
    CITYNAME_HEB: "\u05e0\u05d2\u05de\u05e0\u05d4",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "NDK",
    AIRPORTNAME: "Namdrik Island",
    CITYCODE: "NDK",
    CITYNAME: "NAMDRIK ISLAND",
    CITYNAME_HEB: "NAMDRIK ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "NDL",
    AIRPORTNAME: null,
    CITYCODE: "NDL",
    CITYNAME: "NDELE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NDM",
    AIRPORTNAME: "Mendi",
    CITYCODE: "NDM",
    CITYNAME: "MENDI",
    CITYNAME_HEB: "MENDI",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NDN",
    AIRPORTNAME: "Nadunumu",
    CITYCODE: "NDN",
    CITYNAME: "NADUNUMU",
    CITYNAME_HEB: "NADUNUMU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BAP",
    AIRPORTNAME: "Baibara",
    CITYCODE: "BAP",
    CITYNAME: "BAIBARA",
    CITYNAME_HEB: "BAIBARA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NDR",
    AIRPORTNAME: "Nador",
    CITYCODE: "NDR",
    CITYNAME: "NADOR",
    CITYNAME_HEB: "NADOR",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "NDS",
    AIRPORTNAME: "Sandstone",
    CITYCODE: "NDS",
    CITYNAME: "SANDSTONE",
    CITYNAME_HEB: "SANDSTONE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NDU",
    AIRPORTNAME: "Rundu",
    CITYCODE: "NDU",
    CITYNAME: "RUNDU",
    CITYNAME_HEB: "RUNDU",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NDV",
    AIRPORTNAME: null,
    CITYCODE: "NDV",
    CITYNAME: "ANACOSTIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USDC",
    COUNTRYNAME: "DISTRICT OF COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NDY",
    AIRPORTNAME: "Sanday",
    CITYCODE: "NDY",
    CITYNAME: "SANDAY",
    CITYNAME_HEB: "SANDAY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NDZ",
    AIRPORTNAME: "Cuxhaven",
    CITYCODE: "NDZ",
    CITYNAME: "NORDHOLZ SPIEKA",
    CITYNAME_HEB:
      "\u05e0\u05d5\u05e8\u05d3\u05d5\u05dc\u05e5 \u05e1\u05e4\u05d9\u05e7\u05d4",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NEA",
    AIRPORTNAME: "NAS",
    CITYCODE: "NEA",
    CITYNAME: "GLYNCO",
    CITYNAME_HEB: "GLYNCO",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "NEC",
    AIRPORTNAME: null,
    CITYCODE: "NEC",
    CITYNAME: "NECOCHEA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NEF",
    AIRPORTNAME: "Neftekamsk",
    CITYCODE: "NEF",
    CITYNAME: "NEFTEKAMSK",
    CITYNAME_HEB: "NEFTEKAMSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NEG",
    AIRPORTNAME: "Negril",
    CITYCODE: "NEG",
    CITYNAME: "NEGRIL",
    CITYNAME_HEB: "\u05e0\u05d2\u05e8\u05d9\u05dc",
    COUNTRYCODE: "JM",
    COUNTRYNAME: "JAMAICA",
    COUNTRYNAME_HEB: "\u05d2'\u05de\u05d9\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "BAQ",
    AIRPORTNAME: "E Cortissoz",
    CITYCODE: "BAQ",
    CITYNAME: "BARRANQUILLA",
    CITYNAME_HEB: "BARRANQUILLA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NEJ",
    AIRPORTNAME: "Nejjo",
    CITYCODE: "NEJ",
    CITYNAME: "NEJJO",
    CITYNAME_HEB: "NEJJO",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NEK",
    AIRPORTNAME: "Nekemt",
    CITYCODE: "NEK",
    CITYNAME: "NEKEMT",
    CITYNAME_HEB: "NEKEMT",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NEL",
    AIRPORTNAME: "Naec",
    CITYCODE: "NEL",
    CITYNAME: "LAKEHURST",
    CITYNAME_HEB: "LAKEHURST",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "NEN",
    AIRPORTNAME: "Olf Usn",
    CITYCODE: "NEN",
    CITYNAME: "WHITEHOUSE",
    CITYNAME_HEB: "WHITEHOUSE",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NER",
    AIRPORTNAME: "Neryungri",
    CITYCODE: "NER",
    CITYNAME: "NERYUNGRI",
    CITYNAME_HEB: "NERYUNGRI",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "NEU",
    CITYNAME: "NEUSTIFT IM STUBAITAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NEV",
    AIRPORTNAME: null,
    CITYCODE: "NEV",
    CITYNAME: "NEVIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KN",
    COUNTRYNAME: "ST. KITTS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "NEV",
    CITYNAME: "Neve Aviv",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NFG",
    AIRPORTNAME: "Nefteyugansk",
    CITYCODE: "NFG",
    CITYNAME: "NEFTEYUGANSK",
    CITYNAME_HEB: "NEFTEYUGANSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BAR",
    AIRPORTNAME: "Baker AAF",
    CITYCODE: "BAR",
    CITYNAME: "BAKER ISLAND",
    CITYNAME_HEB: "Baker AAF",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NFL",
    AIRPORTNAME: null,
    CITYCODE: "NFL",
    CITYNAME: "FALLON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NFO",
    AIRPORTNAME: "Mata'aho",
    CITYCODE: "NFO",
    CITYNAME: "NIUAFO'OU",
    CITYNAME_HEB: "NIUAFO'OU",
    COUNTRYCODE: "TO",
    COUNTRYNAME: "TONGA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d2\u05d4",
  },
  {
    AIRPORTCODE: "NFR",
    AIRPORTNAME: "Nafoora",
    CITYCODE: "NFR",
    CITYNAME: "NAFOORA",
    CITYNAME_HEB: "NAFOORA",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "NGA",
    AIRPORTNAME: "Young",
    CITYCODE: "NGA",
    CITYNAME: "YOUNG",
    CITYNAME_HEB: "YOUNG",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NGB",
    AIRPORTNAME: "Ningbo",
    CITYCODE: "NGB",
    CITYNAME: "NINGBO",
    CITYNAME_HEB: "NINGBO",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "NGD",
    AIRPORTNAME: "Anegada",
    CITYCODE: "NGD",
    CITYNAME: "ANEGADA",
    CITYNAME_HEB: "ANEGADA",
    COUNTRYCODE: "VG",
    COUNTRYNAME: "VIRGIN ISLANDS-BRITISH",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05ea\u05d5\u05dc\u05d4 \u05d4\u05d1\u05e8\u05d9\u05d8\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "NGE",
    AIRPORTNAME: "Ngaoundere",
    CITYCODE: "NGE",
    CITYNAME: "NGAOUNDERE",
    CITYNAME_HEB: "NGAOUNDERE",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "NGI",
    AIRPORTNAME: "Ngau Island",
    CITYCODE: "NGI",
    CITYNAME: "NGAU ISLAND",
    CITYNAME_HEB: "NGAU ISLAND",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "NGL",
    AIRPORTNAME: "Ngala Airfield",
    CITYCODE: "NGL",
    CITYNAME: "NGALA",
    CITYNAME_HEB: "NGALA",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "NGN",
    AIRPORTNAME: "Nargana",
    CITYCODE: "NGN",
    CITYNAME: "NARGANA",
    CITYNAME_HEB: "NARGANA",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "BAS",
    AIRPORTNAME: " ",
    CITYCODE: "BAS",
    CITYNAME: "BAD SAAROW",
    CITYNAME_HEB: "\u05d1\u05d0\u05d3 \u05e1\u05d0\u05e8\u05d5",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NGO",
    AIRPORTNAME: "Komaki AFB",
    CITYCODE: "NGO",
    CITYNAME: "NAGOYA",
    CITYNAME_HEB: "\u05e0\u05d0\u05d2\u05d5\u05d9\u05d4",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "NGR",
    AIRPORTNAME: "Ningerum",
    CITYCODE: "NGR",
    CITYNAME: "NINGERUM",
    CITYNAME_HEB: "NINGERUM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NGS",
    AIRPORTNAME: "Nagasaki",
    CITYCODE: "NGS",
    CITYNAME: "NAGASAKI",
    CITYNAME_HEB: "\u05e0\u05d2\u05d0\u05e1\u05d0\u05e7\u05d9",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "NGV",
    AIRPORTNAME: "Ngiva",
    CITYCODE: "NGV",
    CITYNAME: "NGIVA",
    CITYNAME_HEB: "NGIVA",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "NGX",
    AIRPORTNAME: "Manang",
    CITYCODE: "NGX",
    CITYNAME: "MANANG",
    CITYNAME_HEB: "MANANG",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "NGZ",
    AIRPORTNAME: "NAS",
    CITYCODE: "NGZ",
    CITYNAME: "ALAMEDA",
    CITYNAME_HEB: "ALAMEDA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NHA",
    AIRPORTNAME: "Nha Trang",
    CITYCODE: "NHA",
    CITYNAME: "NHA TRANG",
    CITYNAME_HEB: "NHA TRANG",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "NHD",
    AIRPORTNAME: null,
    CITYCODE: "NHD",
    CITYNAME: "MINHAD AB",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AE",
    COUNTRYNAME: "UNITED ARAB EMIRATES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NHF",
    AIRPORTNAME: "New Halfa",
    CITYCODE: "NHF",
    CITYNAME: "NEW HALFA",
    CITYNAME_HEB: "NEW HALFA",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "NHK",
    AIRPORTNAME: "NAS",
    CITYCODE: "NHK",
    CITYNAME: "PATUXENT RIVER",
    CITYNAME_HEB: "PATUXENT RIVER",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OGO",
    AIRPORTNAME: "Abengourou",
    CITYCODE: "OGO",
    CITYNAME: "ABENGOUROU",
    CITYNAME_HEB: "ABENGOUROU",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "OGR",
    AIRPORTNAME: "Bongor",
    CITYCODE: "OGR",
    CITYNAME: "BONGOR",
    CITYNAME_HEB: "BONGOR",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "OGS",
    AIRPORTNAME: "Ogdensburg",
    CITYCODE: "OGS",
    CITYNAME: "OGDENSBURG",
    CITYNAME_HEB: "OGDENSBURG",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OGV",
    AIRPORTNAME: "Ongava Game Reserve",
    CITYCODE: "OGV",
    CITYNAME: "ONGAVA GAME RSV",
    CITYNAME_HEB: "ONGAVA GAME RSV",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OGX",
    AIRPORTNAME: "Ain Beida",
    CITYCODE: "OGX",
    CITYNAME: "OUARGLA",
    CITYNAME_HEB: "OUARGLA",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OGZ",
    AIRPORTNAME: "Vladikavkaz",
    CITYCODE: "OGZ",
    CITYNAME: "VLADIKAVKAZ",
    CITYNAME_HEB: "\u05d5\u05dc\u05d3\u05d9\u05e7\u05d5\u05d5\u05e7\u05d6",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BBZ",
    AIRPORTNAME: "Zambezi",
    CITYCODE: "BBZ",
    CITYNAME: "ZAMBEZI",
    CITYNAME_HEB: "ZAMBEZI",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OHA",
    AIRPORTNAME: "Royal Air Force Base",
    CITYCODE: "OHA",
    CITYNAME: "OHAKEA",
    CITYNAME_HEB: "OHAKEA",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "OHC",
    AIRPORTNAME: "Northeast Cape",
    CITYCODE: "OHC",
    CITYNAME: "NORTHEAST CAPE",
    CITYNAME_HEB: "Northeast Cape",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OHI",
    AIRPORTNAME: "Oshakati",
    CITYCODE: "OHI",
    CITYNAME: "OSHAKATI",
    CITYNAME_HEB: "OSHAKATI",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OHO",
    AIRPORTNAME: "Okhotsk",
    CITYCODE: "OHO",
    CITYNAME: "OKHOTSK",
    CITYNAME_HEB: "OKHOTSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OHR",
    AIRPORTNAME: "Wyk auf Foehr",
    CITYCODE: "OHR",
    CITYNAME: "WYK AUF FOEHR",
    CITYNAME_HEB: "WYK AUF FOEHR",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OHT",
    AIRPORTNAME: "Kohat",
    CITYCODE: "OHT",
    CITYNAME: "KOHAT",
    CITYNAME_HEB: "KOHAT",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "OIA",
    AIRPORTNAME: null,
    CITYCODE: "OIA",
    CITYNAME: "OURILANDIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OIC",
    AIRPORTNAME: "Eaton",
    CITYCODE: "OIC",
    CITYNAME: "NORWICH",
    CITYNAME_HEB: "NORWICH",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OIL",
    AIRPORTNAME: "Splane Memorial",
    CITYCODE: "OIL",
    CITYNAME: "OIL CITY",
    CITYNAME_HEB: "OIL CITY",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BCA",
    AIRPORTNAME: "Baracoa",
    CITYCODE: "BCA",
    CITYNAME: "BARACOA",
    CITYNAME_HEB: "BARACOA",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "OIM",
    AIRPORTNAME: "Oshima",
    CITYCODE: "OIM",
    CITYNAME: "OSHIMA",
    CITYNAME_HEB: "OSHIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "OIR",
    AIRPORTNAME: "Okushiri",
    CITYCODE: "OIR",
    CITYNAME: "OKUSHIRI",
    CITYNAME_HEB: "OKUSHIRI",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "OIT",
    AIRPORTNAME: "Oita",
    CITYCODE: "OIT",
    CITYNAME: "OITA",
    CITYNAME_HEB: "OITA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "AHA",
    AIRPORTNAME: null,
    CITYCODE: "OKA",
    CITYNAME: "OKINAWA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OKA",
    AIRPORTNAME: "Naha",
    CITYCODE: "OKA",
    CITYNAME: "OKINAWA",
    CITYNAME_HEB: "\u05d0\u05d5\u05e7\u05d9\u05e0\u05d0\u05d4",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "OKB",
    AIRPORTNAME: "Fraser Island",
    CITYCODE: "OKB",
    CITYNAME: "ORCHID BEACH",
    CITYNAME_HEB: "ORCHID BEACH",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OKC",
    AIRPORTNAME: "Will Rogers World",
    CITYCODE: "OKC",
    CITYNAME: "OKLAHOMA CITY",
    CITYNAME_HEB:
      "\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 \u05e1\u05d9\u05d8\u05d9",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "DWN",
    AIRPORTNAME: null,
    CITYCODE: "OKC",
    CITYNAME: "OKLAHOMA CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BCB",
    AIRPORTNAME: "Virginia Tech",
    CITYCODE: "BCB",
    CITYNAME: "BLACKSBURG",
    CITYNAME_HEB: "BLACKSBURG",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "OKE",
    AIRPORTNAME: "Okino Erabu",
    CITYCODE: "OKE",
    CITYNAME: "OKINO ERABU",
    CITYNAME_HEB: "OKINO ERABU",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "OKF",
    AIRPORTNAME: "Okaukuejo",
    CITYCODE: "OKF",
    CITYNAME: "OKAUKUEJO",
    CITYNAME_HEB: "OKAUKUEJO",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OKG",
    AIRPORTNAME: "Okoyo",
    CITYCODE: "OKG",
    CITYNAME: "OKOYO",
    CITYNAME_HEB: "OKOYO",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "OKH",
    AIRPORTNAME: "Cottesmor RAF",
    CITYCODE: "OKH",
    CITYNAME: "OAKHAM",
    CITYNAME_HEB: "OAKHAM",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OKI",
    AIRPORTNAME: "Oki Island",
    CITYCODE: "OKI",
    CITYNAME: "OKI ISLAND",
    CITYNAME_HEB: "OKI ISLAND",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "OKJ",
    AIRPORTNAME: "Okayama",
    CITYCODE: "OKJ",
    CITYNAME: "OKAYAMA",
    CITYNAME_HEB: "\u05d0\u05d5\u05e7\u05d9\u05d0\u05de\u05d4",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "OKK",
    AIRPORTNAME: "Kokomo",
    CITYCODE: "OKK",
    CITYNAME: "KOKOMO",
    CITYNAME_HEB: "KOKOMO",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OKL",
    AIRPORTNAME: "Oksibil",
    CITYCODE: "OKL",
    CITYNAME: "OKSIBIL",
    CITYNAME_HEB: "OKSIBIL",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OKM",
    AIRPORTNAME: "Okmulgee",
    CITYCODE: "OKM",
    CITYNAME: "OKMULGEE",
    CITYNAME_HEB: "OKMULGEE",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ACO",
    AIRPORTNAME: "Ascona",
    CITYCODE: "ACO",
    CITYNAME: "ASCONA",
    CITYNAME_HEB: "\u05d0\u05e1\u05e7\u05d5\u05e0\u05d4",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "BCC",
    AIRPORTNAME: "Bear Creek",
    CITYCODE: "BCC",
    CITYNAME: "BEAR CREEK",
    CITYNAME_HEB: "Bear Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OKN",
    AIRPORTNAME: "Okondja",
    CITYCODE: "OKN",
    CITYNAME: "OKONDJA",
    CITYNAME_HEB: "OKONDJA",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "OKP",
    AIRPORTNAME: "Oksapmin",
    CITYCODE: "OKP",
    CITYNAME: "OKSAPMIN",
    CITYNAME_HEB: "OKSAPMIN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "OKQ",
    AIRPORTNAME: "Okaba",
    CITYCODE: "OKQ",
    CITYNAME: "OKABA",
    CITYNAME_HEB: "OKABA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OKR",
    AIRPORTNAME: "Yorke Island",
    CITYCODE: "OKR",
    CITYNAME: "YORKE ISLAND",
    CITYNAME_HEB: "YORKE ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OKS",
    AIRPORTNAME: "Oshkosh",
    CITYCODE: "OKS",
    CITYNAME: "OSHKOSH",
    CITYNAME_HEB: "OSHKOSH",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OKT",
    AIRPORTNAME: "Oktiabrskij",
    CITYCODE: "OKT",
    CITYNAME: "OKTIABRSKIJ",
    CITYNAME_HEB: "OKTIABRSKIJ",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OKU",
    AIRPORTNAME: "Mokuti Lodge",
    CITYCODE: "OKU",
    CITYNAME: "MOKUTI LODGE",
    CITYNAME_HEB: "MOKUTI LODGE",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OKV",
    AIRPORTNAME: "Okao",
    CITYCODE: "OKV",
    CITYNAME: "OKAO",
    CITYNAME_HEB: "OKAO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "OKY",
    AIRPORTNAME: "Oakey",
    CITYCODE: "OKY",
    CITYNAME: "OAKEY",
    CITYNAME_HEB: "OAKEY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OLA",
    AIRPORTNAME: "Orland",
    CITYCODE: "OLA",
    CITYNAME: "ORLAND",
    CITYNAME_HEB: "ORLAND",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BCD",
    AIRPORTNAME: "Bacolod",
    CITYCODE: "BCD",
    CITYNAME: "BACOLOD",
    CITYNAME_HEB: "BACOLOD",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "OLB",
    AIRPORTNAME: "Costa Smeralda",
    CITYCODE: "OLB",
    CITYNAME: "OLBIA",
    CITYNAME_HEB: "\u05d0\u05d5\u05dc\u05d1\u05d9\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OLD",
    AIRPORTNAME: "Old Town",
    CITYCODE: "OLD",
    CITYNAME: "OLD TOWN",
    CITYNAME_HEB: "OLD TOWN",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OLE",
    AIRPORTNAME: "Municipal",
    CITYCODE: "OLE",
    CITYNAME: "OLEAN",
    CITYNAME_HEB: "OLEAN",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OLF",
    AIRPORTNAME: "International",
    CITYCODE: "OLF",
    CITYNAME: "WOLF POINT",
    CITYNAME_HEB: "WOLF POINT",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OLH",
    AIRPORTNAME: "Old Harbor Airport and SPB",
    CITYCODE: "OLH",
    CITYNAME: "OLD HARBOR",
    CITYNAME_HEB: "Old Harbor Airport and SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OLI",
    AIRPORTNAME: "Rif",
    CITYCODE: "OLI",
    CITYNAME: "OLAFSVIK",
    CITYNAME_HEB: "OLAFSVIK",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "OLJ",
    AIRPORTNAME: "Olpoi",
    CITYCODE: "OLJ",
    CITYNAME: "OLPOI",
    CITYNAME_HEB: "OLPOI",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "OLK",
    AIRPORTNAME: null,
    CITYCODE: "OLK",
    CITYNAME: "FUERTE OLIMPO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PY",
    COUNTRYNAME: "PARAGUAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OLM",
    AIRPORTNAME: "Olympia",
    CITYCODE: "OLM",
    CITYNAME: "OLYMPIA",
    CITYNAME_HEB: "OLYMPIA",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YYZ",
    AIRPORTNAME: "Pearson International",
    CITYCODE: "YTO",
    CITYNAME: "TORONTO",
    CITYNAME_HEB: "\u05d8\u05d5\u05e8\u05d5\u05e0\u05d8\u05d5",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "OLN",
    AIRPORTNAME: null,
    CITYCODE: "OLN",
    CITYNAME: "COLONIA SARMIENTO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "AMK",
    CITYNAME: "AMADE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "ZUI",
    CITYNAME: "ZURES AM ALBERG",
    CITYNAME_HEB:
      "\u05e6\u05d5\u05e8\u05e1 \u05d0\u05de \u05d0\u05dc\u05d1\u05e8\u05d2",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "VUL",
    CITYNAME: "VULPERA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BCE",
    AIRPORTNAME: "Bryce",
    CITYCODE: "BCE",
    CITYNAME: "BRYCE",
    CITYNAME_HEB: "BRYCE",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OLO",
    AIRPORTNAME: "Olomouc",
    CITYCODE: "OLO",
    CITYNAME: "OLOMOUC",
    CITYNAME_HEB: "\u05d0\u05d5\u05dc\u05d5\u05de\u05e5",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PDH",
    AIRPORTNAME: " ",
    CITYCODE: "PDH",
    CITYNAME: "PASSO DEL TONALE",
    CITYNAME_HEB:
      "\u05e4\u05d0\u05e1\u05d5 \u05d3\u05dc \u05d8\u05d5\u05e0\u05dc\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "ROE",
    CITYNAME: "BORMIO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OLP",
    AIRPORTNAME: "Olympic Dam",
    CITYCODE: "OLP",
    CITYNAME: "OLYMPIC DAM",
    CITYNAME_HEB: "OLYMPIC DAM",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OLQ",
    AIRPORTNAME: "Olsobip",
    CITYCODE: "OLQ",
    CITYNAME: "OLSOBIP",
    CITYNAME_HEB: "OLSOBIP",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "OLS",
    AIRPORTNAME: "International",
    CITYCODE: "OLS",
    CITYNAME: "NOGALES",
    CITYNAME_HEB: "NOGALES",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OLU",
    AIRPORTNAME: "Columbus",
    CITYCODE: "OLU",
    CITYNAME: "COLUMBUS",
    CITYNAME_HEB: "COLUMBUS",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OLV",
    AIRPORTNAME: "Olive Branch Airport",
    CITYCODE: "OLV",
    CITYNAME: "OLIVE BRANCH",
    CITYNAME_HEB: "OLIVE BRANCH",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MIQ",
    AIRPORTNAME: null,
    CITYCODE: "OMA",
    CITYNAME: "OMAHA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OMA",
    AIRPORTNAME: "Eppley Airfield",
    CITYCODE: "OMA",
    CITYNAME: "OMAHA",
    CITYNAME_HEB: "\u05d0\u05d5\u05de\u05d4\u05d4",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OMB",
    AIRPORTNAME: "Omboue",
    CITYCODE: "OMB",
    CITYNAME: "OMBOUE",
    CITYNAME_HEB: "OMBOUE",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "BCF",
    AIRPORTNAME: null,
    CITYCODE: "BCF",
    CITYNAME: "BOUCA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OMC",
    AIRPORTNAME: "Ormoc",
    CITYCODE: "OMC",
    CITYNAME: "ORMOC",
    CITYNAME_HEB: "ORMOC",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "OMD",
    AIRPORTNAME: "Oranjemund",
    CITYCODE: "OMD",
    CITYNAME: "ORANJEMUND",
    CITYNAME_HEB: "ORANJEMUND",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OME",
    AIRPORTNAME: "Nome",
    CITYCODE: "OME",
    CITYNAME: "NOME",
    CITYNAME_HEB: "Nome",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OMF",
    AIRPORTNAME: "King Hussein",
    CITYCODE: "OMF",
    CITYNAME: "MAFRAQ",
    CITYNAME_HEB: "MAFRAQ",
    COUNTRYCODE: "JO",
    COUNTRYNAME: "JORDAN",
    COUNTRYNAME_HEB: "\u05d9\u05e8\u05d3\u05df",
  },
  {
    AIRPORTCODE: "OMG",
    AIRPORTNAME: "Omega",
    CITYCODE: "OMG",
    CITYNAME: "OMEGA",
    CITYNAME_HEB: "OMEGA",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OMJ",
    AIRPORTNAME: "Omura",
    CITYCODE: "OMJ",
    CITYNAME: "OMURA",
    CITYNAME_HEB: "OMURA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "OMK",
    AIRPORTNAME: "Municipal",
    CITYCODE: "OMK",
    CITYNAME: "OMAK",
    CITYNAME_HEB: "OMAK",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OML",
    AIRPORTNAME: "Omkalai",
    CITYCODE: "OML",
    CITYNAME: "OMKALAI",
    CITYNAME_HEB: "OMKALAI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "OMM",
    AIRPORTNAME: null,
    CITYCODE: "OMM",
    CITYNAME: "MARMUL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "OM",
    COUNTRYNAME: "OMAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OMN",
    AIRPORTNAME: "Osmanabad",
    CITYCODE: "OMN",
    CITYNAME: "OSMANABAD",
    CITYNAME_HEB: "OSMANABAD",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "BCG",
    AIRPORTNAME: "Bemichi",
    CITYCODE: "BCG",
    CITYNAME: "BEMICHI",
    CITYNAME_HEB: "BEMICHI",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "OMO",
    AIRPORTNAME: "Mostar",
    CITYCODE: "OMO",
    CITYNAME: "MOSTAR",
    CITYNAME_HEB: "\u05de\u05d5\u05e1\u05d8\u05e8",
    COUNTRYCODE: "BA",
    COUNTRYNAME: "BOSNIA-HERZEGOVINA",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e1\u05e0\u05d9\u05d4 \u05d4\u05e8\u05e6\u05d2\u05d5\u05d1\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "OMR",
    AIRPORTNAME: "Oradea",
    CITYCODE: "OMR",
    CITYNAME: "ORADEA",
    CITYNAME_HEB: "\u05d0\u05d5\u05e8\u05d3\u05d9\u05d4",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OMS",
    AIRPORTNAME: "Omsk",
    CITYCODE: "OMS",
    CITYNAME: "OMSK",
    CITYNAME_HEB: "OMSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OMY",
    AIRPORTNAME: "Oddor Meanche",
    CITYCODE: "OMY",
    CITYNAME: "ODDOR MEANCHE",
    CITYNAME_HEB: "ODDOR MEANCHE",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ONA",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ONA",
    CITYNAME: "WINONA",
    CITYNAME_HEB: "WINONA",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ONB",
    AIRPORTNAME: "Ononge",
    CITYCODE: "ONB",
    CITYNAME: "ONONGE",
    CITYNAME_HEB: "ONONGE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "OND",
    AIRPORTNAME: "Ondangwa",
    CITYCODE: "OND",
    CITYNAME: "ONDANGWA",
    CITYNAME_HEB: "ONDANGWA",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ONE",
    AIRPORTNAME: "Onepusu",
    CITYCODE: "ONE",
    CITYNAME: "ONEPUSU",
    CITYNAME_HEB: "ONEPUSU",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "ONG",
    AIRPORTNAME: "Mornington",
    CITYCODE: "ONG",
    CITYNAME: "MORNINGTON",
    CITYNAME_HEB: "MORNINGTON",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ONH",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ONH",
    CITYNAME: "ONEONTA",
    CITYNAME_HEB: "ONEONTA",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BCH",
    AIRPORTNAME: "English Madeira",
    CITYCODE: "BCH",
    CITYNAME: "BAUCAU",
    CITYNAME_HEB: "BAUCAU",
    COUNTRYCODE: "TP",
    COUNTRYNAME: "EAST TIMOR",
    COUNTRYNAME_HEB:
      "\u05d8\u05d9\u05de\u05d5\u05e8 \u05d4\u05de\u05d6\u05e8\u05d7\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "ONI",
    AIRPORTNAME: "Moanamani",
    CITYCODE: "ONI",
    CITYNAME: "MOANAMANI",
    CITYNAME_HEB: "MOANAMANI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ONJ",
    AIRPORTNAME: "Odate Noshiro",
    CITYCODE: "ONJ",
    CITYNAME: "ODATE NOSHIRO",
    CITYNAME_HEB: "ODATE NOSHIRO",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "ONL",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ONL",
    CITYNAME: "ONEILL",
    CITYNAME_HEB: "ONEILL",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ONM",
    AIRPORTNAME: "Socorro",
    CITYCODE: "ONM",
    CITYNAME: "SOCORRO",
    CITYNAME_HEB: "SOCORRO",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ONN",
    AIRPORTNAME: "Onion Bay Lodge SPB",
    CITYCODE: "ONN",
    CITYNAME: "ONION BAY",
    CITYNAME_HEB: "Onion Bay Lodge SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ONO",
    AIRPORTNAME: "Ontario",
    CITYCODE: "ONO",
    CITYNAME: "ONTARIO",
    CITYNAME_HEB: "ONTARIO",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ONP",
    AIRPORTNAME: "Newport",
    CITYCODE: "ONP",
    CITYNAME: "NEWPORT",
    CITYNAME_HEB: "NEWPORT",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ONQ",
    AIRPORTNAME: "Zonguldak",
    CITYCODE: "ONQ",
    CITYNAME: "ZONGULDAK",
    CITYNAME_HEB: "ZONGULDAK",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ONR",
    AIRPORTNAME: "Monkira",
    CITYCODE: "ONR",
    CITYNAME: "MONKIRA",
    CITYNAME_HEB: "MONKIRA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ONS",
    AIRPORTNAME: "Onslow",
    CITYCODE: "ONS",
    CITYNAME: "ONSLOW",
    CITYNAME_HEB: "ONSLOW",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BCI",
    AIRPORTNAME: "Barcaldine",
    CITYCODE: "BCI",
    CITYNAME: "BARCALDINE",
    CITYNAME_HEB: "BARCALDINE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JIO",
    AIRPORTNAME: null,
    CITYCODE: "ONT",
    CITYNAME: "ONTARIO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ONT",
    AIRPORTNAME: "International",
    CITYCODE: "ONT",
    CITYNAME: "ONTARIO",
    CITYNAME_HEB: "\u05d0\u05d5\u05e0\u05d8\u05e8\u05d9\u05d5",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ONU",
    AIRPORTNAME: "Ono I Lau",
    CITYCODE: "ONU",
    CITYNAME: "ONO I LAU",
    CITYNAME_HEB: "ONO I LAU",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "ONX",
    AIRPORTNAME: "Colon",
    CITYCODE: "ONX",
    CITYNAME: "COLON",
    CITYNAME_HEB: "COLON",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "ONY",
    AIRPORTNAME: "Olney",
    CITYCODE: "ONY",
    CITYNAME: "OLNEY",
    CITYNAME_HEB: "OLNEY",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OOA",
    AIRPORTNAME: "Municipal",
    CITYCODE: "OOA",
    CITYNAME: "OSKALOOSA",
    CITYNAME_HEB: "OSKALOOSA",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OOK",
    AIRPORTNAME: "Toksook Bay",
    CITYCODE: "OOK",
    CITYNAME: "TOKSOOK BAY",
    CITYNAME_HEB: "Toksook Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OOL",
    AIRPORTNAME: "Coolangatta",
    CITYCODE: "OOL",
    CITYNAME: "GOLD COAST",
    CITYNAME_HEB: "GOLD COAST",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OOM",
    AIRPORTNAME: "Cooma",
    CITYCODE: "OOM",
    CITYNAME: "COOMA",
    CITYNAME_HEB: "COOMA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OOR",
    AIRPORTNAME: "Mooraberree",
    CITYCODE: "OOR",
    CITYNAME: "MOORABERREE",
    CITYNAME_HEB: "MOORABERREE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BCJ",
    AIRPORTNAME: "Baca Grande",
    CITYCODE: "BCJ",
    CITYNAME: "BACA GRANDE",
    CITYNAME_HEB: "BACA GRANDE",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OOT",
    AIRPORTNAME: "Onotoa",
    CITYCODE: "OOT",
    CITYNAME: "ONOTOA",
    CITYNAME_HEB: "ONOTOA",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "OPA",
    AIRPORTNAME: "Kopasker",
    CITYCODE: "OPA",
    CITYNAME: "KOPASKER",
    CITYNAME_HEB: "KOPASKER",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "OPB",
    AIRPORTNAME: "Open Bay",
    CITYCODE: "OPB",
    CITYNAME: "OPEN BAY",
    CITYNAME_HEB: "OPEN BAY",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "OPI",
    AIRPORTNAME: "Oenpelli",
    CITYCODE: "OPI",
    CITYNAME: "OENPELLI",
    CITYNAME_HEB: "OENPELLI",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OPL",
    AIRPORTNAME: "St Landry Parish",
    CITYCODE: "OPL",
    CITYNAME: "OPELOUSAS",
    CITYNAME_HEB: "OPELOUSAS",
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB:
      '\u05dc\u05d5\u05d0\u05d9\u05d6\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OPO",
    AIRPORTNAME: "Porto",
    CITYCODE: "OPO",
    CITYNAME: "PORTO",
    CITYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "OPS",
    AIRPORTNAME: null,
    CITYCODE: "OPS",
    CITYNAME: "SINOP",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OPT",
    AIRPORTNAME: " ",
    CITYCODE: "OPT",
    CITYNAME: "OPATIA",
    CITYNAME_HEB: "\u05d0\u05d5\u05e4\u05d8\u05d9\u05d4",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OPU",
    AIRPORTNAME: "Balimo",
    CITYCODE: "OPU",
    CITYNAME: "BALIMO",
    CITYNAME_HEB: "BALIMO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "OPW",
    AIRPORTNAME: "Opuwa",
    CITYCODE: "OPW",
    CITYNAME: "OPUWA",
    CITYNAME_HEB: "OPUWA",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BCK",
    AIRPORTNAME: "Bolwarra",
    CITYCODE: "BCK",
    CITYNAME: "BOLWARRA",
    CITYNAME_HEB: "BOLWARRA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ORA",
    AIRPORTNAME: null,
    CITYCODE: "ORA",
    CITYNAME: "ORAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ORB",
    AIRPORTNAME: "Orebro-Bofors",
    CITYCODE: "ORB",
    CITYNAME: "OREBRO",
    CITYNAME_HEB: "OREBRO",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XWR",
    AIRPORTNAME: null,
    CITYCODE: "ORB",
    CITYNAME: "OREBRO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "HOZ",
    CITYNAME: "HOPFGARTEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ORC",
    AIRPORTNAME: " ",
    CITYCODE: "ORC",
    CITYNAME: "ORCIERESS MERLETTE",
    CITYNAME_HEB:
      "\u05d0\u05d5\u05e8\u05db\u05d9\u05e8\u05d4 \u05de\u05e8\u05dc\u05d4",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "ORE",
    AIRPORTNAME: "Orleans",
    CITYCODE: "ORE",
    CITYNAME: "ORLEANS",
    CITYNAME_HEB: "ORLEANS",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "NGU",
    AIRPORTNAME: null,
    CITYCODE: "ORF",
    CITYNAME: "NORFOLK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ORF",
    AIRPORTNAME: "International",
    CITYCODE: "ORF",
    CITYNAME: "NORFOLK",
    CITYNAME_HEB: "\u05e0\u05d5\u05e8\u05e4\u05dc\u05e7",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "ORH",
    AIRPORTNAME: "Worcester",
    CITYCODE: "ORH",
    CITYNAME: "WORCESTER",
    CITYNAME_HEB: "WORCESTER",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "ORJ",
    AIRPORTNAME: "Orinduik",
    CITYCODE: "ORJ",
    CITYNAME: "ORINDUIK",
    CITYNAME_HEB: "ORINDUIK",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "BCL",
    AIRPORTNAME: "Barra Colorado",
    CITYCODE: "BCL",
    CITYNAME: "BARRA COLORADO",
    CITYNAME_HEB: "BARRA COLORADO",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "ORK",
    AIRPORTNAME: "Cork",
    CITYCODE: "ORK",
    CITYNAME: "CORK",
    CITYNAME_HEB: "\u05e7\u05d5\u05e8\u05e7",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "MCO",
    AIRPORTNAME: " ",
    CITYCODE: "ORL",
    CITYNAME: "ORLANDO",
    CITYNAME_HEB: "\u05d0\u05d5\u05e8\u05dc\u05e0\u05d3\u05d5",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "JAW",
    AIRPORTNAME: " ",
    CITYCODE: "JAW",
    CITYNAME: "JASNA",
    CITYNAME_HEB: "\u05d2\u05e1\u05e0\u05d4",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ORM",
    AIRPORTNAME: "Northampton",
    CITYCODE: "ORM",
    CITYNAME: "NORTHAMPTON",
    CITYNAME_HEB: "NORTHAMPTON",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ORN",
    AIRPORTNAME: "Es Senia",
    CITYCODE: "ORN",
    CITYNAME: "ORAN",
    CITYNAME_HEB: "ORAN",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TAF",
    AIRPORTNAME: "Tafaraoui",
    CITYCODE: "ORN",
    CITYNAME: "ORAN",
    CITYNAME_HEB: "ORAN",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ORO",
    AIRPORTNAME: "Yoro",
    CITYCODE: "ORO",
    CITYNAME: "YORO",
    CITYNAME_HEB: "YORO",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "ORP",
    AIRPORTNAME: "Orapa",
    CITYCODE: "ORP",
    CITYNAME: "ORAPA",
    CITYNAME_HEB: "ORAPA",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "ACR",
    AIRPORTNAME: "Araracuara",
    CITYCODE: "ACR",
    CITYNAME: "ARARACUARA",
    CITYNAME_HEB: "ARARACUARA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BCM",
    AIRPORTNAME: "Bacau",
    CITYCODE: "BCM",
    CITYNAME: "BACAU",
    CITYNAME_HEB: "\u05d1\u05e7\u05d0\u05d5",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ORQ",
    AIRPORTNAME: "Heliport",
    CITYCODE: "ORQ",
    CITYNAME: "NORWALK",
    CITYNAME_HEB: "NORWALK",
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05e0\u05d8\u05d9\u05e7\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ORR",
    AIRPORTNAME: "Yorketown",
    CITYCODE: "ORR",
    CITYNAME: "YORKETOWN",
    CITYNAME_HEB: "YORKETOWN",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ORS",
    AIRPORTNAME: "Waterport",
    CITYCODE: "ORS",
    CITYNAME: "ORPHEUS ISLAND",
    CITYNAME_HEB: "ORPHEUS ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ORT",
    AIRPORTNAME: " ",
    CITYCODE: "ORT",
    CITYNAME: "ORTISEI",
    CITYNAME_HEB: "\u05d0\u05d5\u05e8\u05d8\u05d9\u05d6\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ORU",
    AIRPORTNAME: "Oruro",
    CITYCODE: "ORU",
    CITYNAME: "ORURO",
    CITYNAME_HEB: "ORURO",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "SKV",
    CITYNAME: "SKIWELT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ORV",
    AIRPORTNAME: "Curtis Memorial",
    CITYCODE: "ORV",
    CITYNAME: "NOORVIK",
    CITYNAME_HEB: "Curtis Memorial",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ORW",
    AIRPORTNAME: "Ormara",
    CITYCODE: "ORW",
    CITYNAME: "ORMARA",
    CITYNAME_HEB: "ORMARA",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "ORX",
    AIRPORTNAME: null,
    CITYCODE: "ORX",
    CITYNAME: "ORIXIMINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ORZ",
    AIRPORTNAME: "Orange Walk",
    CITYCODE: "ORZ",
    CITYNAME: "ORANGE WALK",
    CITYNAME_HEB: "ORANGE WALK",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "OSA",
    AIRPORTNAME: null,
    CITYCODE: "OSA",
    CITYNAME: "OSAKA",
    CITYNAME_HEB: "\u05d0\u05d5\u05e1\u05e7\u05d4",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "BCN",
    AIRPORTNAME: " ",
    CITYCODE: "BCN",
    CITYNAME: "BARCELONA",
    CITYNAME_HEB: "\u05d1\u05e8\u05e6\u05dc\u05d5\u05e0\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "ITM",
    AIRPORTNAME: "Itami",
    CITYCODE: "OSA",
    CITYNAME: "OSAKA",
    CITYNAME_HEB: "\u05d0\u05d5\u05e1\u05e7\u05d4",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "KIX",
    AIRPORTNAME: "Kansai International",
    CITYCODE: "OSA",
    CITYNAME: "OSAKA",
    CITYNAME_HEB: "\u05d0\u05d5\u05e1\u05e7\u05d4",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "OSB",
    AIRPORTNAME: "Osage Beach",
    CITYCODE: "OSB",
    CITYNAME: "OSAGE BEACH",
    CITYNAME_HEB: "OSAGE BEACH",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OSC",
    AIRPORTNAME: "Wurtsmith AFB",
    CITYCODE: "OSC",
    CITYNAME: "OSCODA",
    CITYNAME_HEB: "OSCODA",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OSD",
    AIRPORTNAME: "Froesoe",
    CITYCODE: "OSD",
    CITYNAME: "OSTERSUND",
    CITYNAME_HEB: "OSTERSUND",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OSE",
    AIRPORTNAME: "Omora",
    CITYCODE: "OSE",
    CITYNAME: "OMORA",
    CITYNAME_HEB: "OMORA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "OSG",
    AIRPORTNAME: "Ossima",
    CITYCODE: "OSG",
    CITYNAME: "OSSIMA",
    CITYNAME_HEB: "OSSIMA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "OSH",
    AIRPORTNAME: "Wittman Field",
    CITYCODE: "OSH",
    CITYNAME: "OSHKOSH",
    CITYNAME_HEB: "OSHKOSH",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OSI",
    AIRPORTNAME: "Osijek",
    CITYCODE: "OSI",
    CITYNAME: "OSIJEK",
    CITYNAME_HEB: "OSIJEK",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OSK",
    AIRPORTNAME: "Oskarshamn",
    CITYCODE: "OSK",
    CITYNAME: "OSKARSHAMN",
    CITYNAME_HEB: "OSKARSHAMN",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OSL",
    AIRPORTNAME: null,
    CITYCODE: "OSL",
    CITYNAME: "OSLO",
    CITYNAME_HEB: "\u05d0\u05d5\u05e1\u05dc\u05d5",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OSN",
    AIRPORTNAME: "Ab",
    CITYCODE: "OSN",
    CITYNAME: "OSAN",
    CITYNAME_HEB: "OSAN",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "OSP",
    AIRPORTNAME: "Redzikowo",
    CITYCODE: "OSP",
    CITYNAME: "SLUPSK",
    CITYNAME_HEB: "SLUPSK",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "OSR",
    AIRPORTNAME: "Mosnov",
    CITYCODE: "OSR",
    CITYNAME: "OSTRAVA",
    CITYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d1\u05d4",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OSS",
    AIRPORTNAME: "Osh",
    CITYCODE: "OSS",
    CITYNAME: "OSH",
    CITYNAME_HEB: "OSH",
    COUNTRYCODE: "KG",
    COUNTRYNAME: "KYRGYZSTAN",
    COUNTRYNAME_HEB:
      "\u05e7\u05d9\u05e8\u05d2\u05d9\u05e1\u05d9\u05e1\u05d8\u05d0\u05df",
  },
  {
    AIRPORTCODE: "OST",
    AIRPORTNAME: "Ostend",
    CITYCODE: "OST",
    CITYNAME: "OSTEND",
    CITYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e0\u05d3",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OSW",
    AIRPORTNAME: "Orsk",
    CITYCODE: "OSW",
    CITYNAME: "ORSK",
    CITYNAME_HEB: "ORSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OSX",
    AIRPORTNAME: "Attala County",
    CITYCODE: "OSX",
    CITYNAME: "KOSCIUSKO",
    CITYNAME_HEB: "KOSCIUSKO",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BCO",
    AIRPORTNAME: "Jinka",
    CITYCODE: "BCO",
    CITYNAME: "JINKA",
    CITYNAME_HEB: "JINKA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OSY",
    AIRPORTNAME: "Namsos",
    CITYCODE: "OSY",
    CITYNAME: "NAMSOS",
    CITYNAME_HEB: "NAMSOS",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OSZ",
    AIRPORTNAME: "Koszalin",
    CITYCODE: "OSZ",
    CITYNAME: "KOSZALIN",
    CITYNAME_HEB: "KOSZALIN",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "OTA",
    AIRPORTNAME: "Mota",
    CITYCODE: "OTA",
    CITYNAME: "MOTA",
    CITYNAME_HEB: "MOTA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OTC",
    AIRPORTNAME: "Bol",
    CITYCODE: "OTC",
    CITYNAME: "BOL",
    CITYNAME_HEB: "BOL",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "OTD",
    AIRPORTNAME: "Contadora",
    CITYCODE: "OTD",
    CITYNAME: "CONTADORA",
    CITYNAME_HEB: "CONTADORA",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "OTG",
    AIRPORTNAME: "Worthington",
    CITYCODE: "OTG",
    CITYNAME: "WORTHINGTON",
    CITYNAME_HEB: "WORTHINGTON",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OTH",
    AIRPORTNAME: "North Bend",
    CITYCODE: "OTH",
    CITYNAME: "NORTH BEND",
    CITYNAME_HEB: "NORTH BEND",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OTI",
    AIRPORTNAME: "Morotai Island",
    CITYCODE: "OTI",
    CITYNAME: "MOROTAI ISLAND",
    CITYNAME_HEB: "MOROTAI ISLAND",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OTJ",
    AIRPORTNAME: "Otjiwarongo",
    CITYCODE: "OTJ",
    CITYNAME: "OTJIWARONGO",
    CITYNAME_HEB: "OTJIWARONGO",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OTL",
    AIRPORTNAME: "Boutilimit",
    CITYCODE: "OTL",
    CITYNAME: "BOUTILIMIT",
    CITYNAME_HEB: "BOUTILIMIT",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BCP",
    AIRPORTNAME: "Bambu",
    CITYCODE: "BCP",
    CITYNAME: "BAMBU",
    CITYNAME_HEB: "BAMBU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "OTM",
    AIRPORTNAME: "Industrial",
    CITYCODE: "OTM",
    CITYNAME: "OTTUMWA",
    CITYNAME_HEB: "OTTUMWA",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OTN",
    AIRPORTNAME: "Green",
    CITYCODE: "OTN",
    CITYNAME: "OAKTOWN",
    CITYNAME_HEB: "OAKTOWN",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OTO",
    AIRPORTNAME: "Vor",
    CITYCODE: "OTO",
    CITYNAME: "OTTO",
    CITYNAME_HEB: "OTTO",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OTR",
    AIRPORTNAME: "Coto 47",
    CITYCODE: "OTR",
    CITYNAME: "COTO 47",
    CITYNAME_HEB: "COTO 47",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "OTS",
    AIRPORTNAME: "Anacortes",
    CITYCODE: "OTS",
    CITYNAME: "ANACORTES",
    CITYNAME_HEB: "ANACORTES",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OTU",
    AIRPORTNAME: "Otu",
    CITYCODE: "OTU",
    CITYNAME: "OTU",
    CITYNAME_HEB: "OTU",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OTV",
    AIRPORTNAME: "Ontong Java",
    CITYCODE: "OTV",
    CITYNAME: "OTONG JAVA",
    CITYNAME_HEB: "OTONG JAVA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "OTY",
    AIRPORTNAME: "Oria",
    CITYCODE: "OTY",
    CITYNAME: "ORIA",
    CITYNAME_HEB: "ORIA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "OTZ",
    CITYNAME: "OTZTAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OUA",
    AIRPORTNAME: "Ouagadougou",
    CITYCODE: "OUA",
    CITYNAME: "OUAGADOUGOU",
    CITYNAME_HEB: "\u05d5\u05d0\u05d2\u05d0\u05d3\u05d5\u05d2\u05d5",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "BCR",
    AIRPORTNAME: null,
    CITYCODE: "BCR",
    CITYNAME: "BOCA DO ACRE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OUD",
    AIRPORTNAME: "Les Angades",
    CITYCODE: "OUD",
    CITYNAME: "OUJDA",
    CITYNAME_HEB: "OUJDA",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "OUE",
    AIRPORTNAME: "Ouesso",
    CITYCODE: "OUE",
    CITYNAME: "OUESSO",
    CITYNAME_HEB: "OUESSO",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "OUG",
    AIRPORTNAME: "Ouahigouya",
    CITYCODE: "OUG",
    CITYNAME: "OUAHIGOUYA",
    CITYNAME_HEB: "OUAHIGOUYA",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "OUH",
    AIRPORTNAME: "Oudtshoorn",
    CITYCODE: "OUH",
    CITYNAME: "OUDTSHOORN",
    CITYNAME_HEB: "\u05d0\u05d5\u05d3\u05e1\u05d4\u05d5\u05e8\u05df",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "OUI",
    AIRPORTNAME: "Ban Houei",
    CITYCODE: "OUI",
    CITYNAME: "BAN HOUEI",
    CITYNAME_HEB: "BAN HOUEI",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "OUK",
    AIRPORTNAME: "Outer Skerries",
    CITYCODE: "OUK",
    CITYNAME: "OUTER SKERRIES",
    CITYNAME_HEB: "OUTER SKERRIES",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OUL",
    AIRPORTNAME: "Oulu",
    CITYCODE: "OUL",
    CITYNAME: "OULU",
    CITYNAME_HEB: "OULU",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "OUM",
    AIRPORTNAME: "Oum Hadjer",
    CITYCODE: "OUM",
    CITYNAME: "OUM HADJER",
    CITYNAME_HEB: "OUM HADJER",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "OUN",
    AIRPORTNAME: "Max Westheimer",
    CITYCODE: "OUN",
    CITYNAME: "NORMAN",
    CITYNAME_HEB: "NORMAN",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OUR",
    AIRPORTNAME: " ",
    CITYCODE: "OUR",
    CITYNAME: "VALLEE DU DOURO",
    CITYNAME_HEB:
      "\u05d5\u05d0\u05dc\u05d4 \u05d3\u05d4 \u05d3\u05d5\u05d0\u05e8\u05d5",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "BCS",
    AIRPORTNAME: "Southern Seaplane",
    CITYCODE: "BCS",
    CITYNAME: "BELLE CHASSE",
    CITYNAME_HEB: "BELLE CHASSE",
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB:
      '\u05dc\u05d5\u05d0\u05d9\u05d6\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OUS",
    AIRPORTNAME: null,
    CITYCODE: "OUS",
    CITYNAME: "OURINHOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OUT",
    AIRPORTNAME: "Bousso",
    CITYCODE: "OUT",
    CITYNAME: "BOUSSO",
    CITYNAME_HEB: "BOUSSO",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "OUU",
    AIRPORTNAME: "Ouanga",
    CITYCODE: "OUU",
    CITYNAME: "OUANGA",
    CITYNAME_HEB: "OUANGA",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "OUZ",
    AIRPORTNAME: "Zouerate",
    CITYCODE: "OUZ",
    CITYNAME: "ZOUERATE",
    CITYNAME_HEB: "ZOUERATE",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OVA",
    AIRPORTNAME: "Bekily",
    CITYCODE: "OVA",
    CITYNAME: "BEKILY",
    CITYNAME_HEB: "BEKILY",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "OVB",
    AIRPORTNAME: "Tolmachevo",
    CITYCODE: "OVB",
    CITYNAME: "NOVOSIBIRSK",
    CITYNAME_HEB:
      "\u05e0\u05d5\u05d1\u05d5\u05e1\u05d1\u05e8\u05d9\u05e1\u05e7",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OVD",
    AIRPORTNAME: "Oviedo",
    CITYCODE: "OVD",
    CITYNAME: "OVIEDO-ASTURIAS",
    CITYNAME_HEB: "ASTURIAS",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "OVE",
    AIRPORTNAME: "Oroville",
    CITYCODE: "OVE",
    CITYNAME: "OROVILLE",
    CITYNAME_HEB: "OROVILLE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OVL",
    AIRPORTNAME: "Ovalle",
    CITYCODE: "OVL",
    CITYNAME: "OVALLE",
    CITYNAME_HEB: "OVALLE",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "OVR",
    AIRPORTNAME: " ",
    CITYCODE: "OVR",
    CITYNAME: "OVERLOON",
    CITYNAME_HEB: "\u05d0\u05d5\u05d1\u05e8\u05dc\u05d5\u05df",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BCT",
    AIRPORTNAME: "Public",
    CITYCODE: "BCT",
    CITYNAME: "BOCA RATON",
    CITYNAME_HEB: "BOCA RATON",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OWA",
    AIRPORTNAME: "Owatonna",
    CITYCODE: "OWA",
    CITYNAME: "OWATONNA",
    CITYNAME_HEB: "OWATONNA",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OWB",
    AIRPORTNAME: "Daviess County",
    CITYCODE: "OWB",
    CITYNAME: "OWENSBORO",
    CITYNAME_HEB: "OWENSBORO",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OWD",
    AIRPORTNAME: "Memorial",
    CITYCODE: "OWD",
    CITYNAME: "NORWOOD",
    CITYNAME_HEB: "NORWOOD",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "OWE",
    AIRPORTNAME: "Owendo",
    CITYCODE: "OWE",
    CITYNAME: "OWENDO",
    CITYNAME_HEB: "OWENDO",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "OWK",
    AIRPORTNAME: "Central Maine",
    CITYCODE: "OWK",
    CITYNAME: "NORRIDGEWOCK",
    CITYNAME_HEB: "NORRIDGEWOCK",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OXB",
    AIRPORTNAME: null,
    CITYCODE: "OXB",
    CITYNAME: "BISSAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GW",
    COUNTRYNAME: "GUINEA BISSAU",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OXC",
    AIRPORTNAME: "Waterbury-Oxford",
    CITYCODE: "OXC",
    CITYNAME: "OXFORD",
    CITYNAME_HEB: "OXFORD",
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05e0\u05d8\u05d9\u05e7\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OXD",
    AIRPORTNAME: "Miami University",
    CITYCODE: "OXD",
    CITYNAME: "OXFORD",
    CITYNAME_HEB: "OXFORD",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OXF",
    AIRPORTNAME: "Kidlington",
    CITYCODE: "OXF",
    CITYNAME: "OXFORD",
    CITYNAME_HEB: "\u05d0\u05d5\u05e7\u05e1\u05e4\u05d5\u05e8\u05d3",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BCU",
    AIRPORTNAME: null,
    CITYCODE: "BCU",
    CITYNAME: "BRUCOLI-SICILY",
    CITYNAME_HEB: "\u05d1\u05e8\u05d5\u05e7\u05d5\u05dc\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OXO",
    AIRPORTNAME: "Orientos",
    CITYCODE: "OXO",
    CITYNAME: "ORIENTOS",
    CITYNAME_HEB: "ORIENTOS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OXR",
    AIRPORTNAME: "Oxnard/Ventura Airport",
    CITYCODE: "OXR",
    CITYNAME: "OXNARD",
    CITYNAME_HEB: "OXNARD",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OXY",
    AIRPORTNAME: "Morney",
    CITYCODE: "OXY",
    CITYNAME: "MORNEY",
    CITYNAME_HEB: "MORNEY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OYA",
    AIRPORTNAME: null,
    CITYCODE: "OYA",
    CITYNAME: "GOYA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OYE",
    AIRPORTNAME: "Oyem",
    CITYCODE: "OYE",
    CITYNAME: "OYEM",
    CITYNAME_HEB: "OYEM",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "OYG",
    AIRPORTNAME: "Moyo",
    CITYCODE: "OYG",
    CITYNAME: "MOYO",
    CITYNAME_HEB: "MOYO",
    COUNTRYCODE: "UG",
    COUNTRYNAME: "UGANDA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "OYK",
    AIRPORTNAME: null,
    CITYCODE: "OYK",
    CITYNAME: "OIAPOQUE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OYL",
    AIRPORTNAME: "Moyale",
    CITYCODE: "OYL",
    CITYNAME: "MOYALE",
    CITYNAME_HEB: "MOYALE",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OYN",
    AIRPORTNAME: "Ouyen",
    CITYCODE: "OYN",
    CITYNAME: "OUYEN",
    CITYNAME_HEB: "OUYEN",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OYO",
    AIRPORTNAME: null,
    CITYCODE: "OYO",
    CITYNAME: "TRES ARROYOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BCV",
    AIRPORTNAME: "Belmopan",
    CITYCODE: "BCV",
    CITYNAME: "BELMOPAN",
    CITYNAME_HEB: "BELMOPAN",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "OYP",
    AIRPORTNAME: "St-Georges de Loyapock",
    CITYCODE: "OYP",
    CITYNAME: "SAINT GEORGES DE LOY",
    CITYNAME_HEB: "SAINT GEORGES DE LOY",
    COUNTRYCODE: "GF",
    COUNTRYNAME: "FRENCH GUIANA",
    COUNTRYNAME_HEB:
      "\u05d2\u05d9\u05e0\u05d0\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "OYS",
    AIRPORTNAME: "Yosemite Ntl Park",
    CITYCODE: "OYS",
    CITYNAME: "YOSEMITE NTL PARK",
    CITYNAME_HEB:
      "\u05d9\u05d5\u05e1\u05d9\u05de\u05d9\u05d8\u05d9 \u05e4\u05d0\u05e8\u05e7",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "OZA",
    AIRPORTNAME: "Ozona",
    CITYCODE: "OZA",
    CITYNAME: "OZONA",
    CITYNAME_HEB: "OZONA",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OZC",
    AIRPORTNAME: "Labo",
    CITYCODE: "OZC",
    CITYNAME: "OZAMIS CITY",
    CITYNAME_HEB: "OZAMIS CITY",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "OZH",
    AIRPORTNAME: "Zaporozhye",
    CITYCODE: "OZH",
    CITYNAME: "ZAPOROZHYE",
    CITYNAME_HEB: "ZAPOROZHYE",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "OZP",
    AIRPORTNAME: "Mor?n Air Base",
    CITYCODE: "OZP",
    CITYNAME: "MORON",
    CITYNAME_HEB: "MORON",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "HEY",
    AIRPORTNAME: "Hanchey Army Heliport",
    CITYCODE: "OZR",
    CITYNAME: "OZARK",
    CITYNAME_HEB: "Ozark",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LOR",
    AIRPORTNAME: "Lowe Army Heliport",
    CITYCODE: "OZR",
    CITYNAME: "OZARK",
    CITYNAME_HEB: "Ozark",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OZR",
    AIRPORTNAME: "Cairns AAF",
    CITYCODE: "OZR",
    CITYNAME: "OZARK",
    CITYNAME_HEB: "Ozark",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OZZ",
    AIRPORTNAME: "Ouarzazate",
    CITYCODE: "OZZ",
    CITYNAME: "OUARZAZATE",
    CITYNAME_HEB: "\u05d0\u05d5\u05d0\u05e8\u05e6\u05d0\u05e6\u05d8\u05d4",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "ACS",
    AIRPORTNAME: "Achinsk",
    CITYCODE: "ACS",
    CITYNAME: "ACHINSK",
    CITYNAME_HEB: "ACHINSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BCW",
    AIRPORTNAME: "Benguera Island",
    CITYCODE: "BCW",
    CITYNAME: "BENGUERA ISLAND",
    CITYNAME_HEB: "BENGUERA ISLAND",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "PAA",
    AIRPORTNAME: "Pa-an",
    CITYCODE: "PAA",
    CITYNAME: "PA AN",
    CITYNAME_HEB: "PA AN",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PAB",
    AIRPORTNAME: "Bilaspur",
    CITYCODE: "PAB",
    CITYNAME: "BILASPUR",
    CITYNAME_HEB: "BILASPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "PAD",
    AIRPORTNAME: " ",
    CITYCODE: "PAD",
    CITYNAME: "PADENGHE",
    CITYNAME_HEB: "\u05e4\u05d0\u05d3\u05e0\u05d2\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PAE",
    AIRPORTNAME: " ",
    CITYCODE: "PAE",
    CITYNAME: "PAPENDRECHT",
    CITYNAME_HEB: "\u05e4\u05d0\u05e4\u05e0\u05d3\u05e8\u05d0\u05db\u05d8",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PAF",
    AIRPORTNAME: "Pakuba",
    CITYCODE: "PAF",
    CITYNAME: "PAKUBA",
    CITYNAME_HEB: "PAKUBA",
    COUNTRYCODE: "UG",
    COUNTRYNAME: "UGANDA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "PAG",
    AIRPORTNAME: "Pagadian",
    CITYCODE: "PAG",
    CITYNAME: "PAGADIAN",
    CITYNAME_HEB: "PAGADIAN",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "PAH",
    AIRPORTNAME: "Barkley Regional",
    CITYCODE: "PAH",
    CITYNAME: "PADUCAH",
    CITYNAME_HEB: "PADUCAH",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PAI",
    AIRPORTNAME: "Pailin",
    CITYCODE: "PAI",
    CITYNAME: "PAILIN",
    CITYNAME_HEB: "PAILIN",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PAJ",
    AIRPORTNAME: "Para Chinar",
    CITYCODE: "PAJ",
    CITYNAME: "PARA CHINAR",
    CITYNAME_HEB: "PARA CHINAR",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "PAK",
    AIRPORTNAME: "Port Allen",
    CITYCODE: "PAK",
    CITYNAME: "HANAPEPE",
    CITYNAME_HEB: "HANAPEPE",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BCX",
    AIRPORTNAME: "Beloreck",
    CITYCODE: "BCX",
    CITYNAME: "BELORECK",
    CITYNAME_HEB: "BELORECK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PAL",
    AIRPORTNAME: "Palanquero",
    CITYCODE: "PAL",
    CITYNAME: "PALANQUERO",
    CITYNAME_HEB: "PALANQUERO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PAN",
    AIRPORTNAME: "Pattani",
    CITYCODE: "PAN",
    CITYNAME: "PATTANI",
    CITYNAME_HEB: "PATTANI",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PAO",
    AIRPORTNAME: "Palo Alto",
    CITYCODE: "PAO",
    CITYNAME: "PALO ALTO",
    CITYNAME_HEB: "PALO ALTO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PAP",
    AIRPORTNAME: "Mais Gate",
    CITYCODE: "PAP",
    CITYNAME: "PORT AU PRINCE",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05e8\u05d8 \u05d0\u05d5 \u05e4\u05e8\u05d9\u05e0\u05e1",
    COUNTRYCODE: "HT",
    COUNTRYNAME: "HAITI",
    COUNTRYNAME_HEB: "\u05d4\u05d0\u05d9\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "PAQ",
    AIRPORTNAME: "Palmer",
    CITYCODE: "PAQ",
    CITYNAME: "PALMER",
    CITYNAME_HEB: "Palmer",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CDG",
    AIRPORTNAME: "PARIS-Ch. De Gaulle",
    CITYCODE: "PAR",
    CITYNAME: "PARIS",
    CITYNAME_HEB: "\u05e4\u05e8\u05d9\u05d6",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "ORY",
    AIRPORTNAME: "PARIS-Orly",
    CITYCODE: "PAR",
    CITYNAME: "PARIS",
    CITYNAME_HEB: "\u05e4\u05e8\u05d9\u05d6",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "PAR",
    AIRPORTNAME: "PARIS",
    CITYCODE: "PAR",
    CITYNAME: "PARIS",
    CITYNAME_HEB: "\u05e4\u05e8\u05d9\u05d6",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "BCY",
    AIRPORTNAME: "Bulchi",
    CITYCODE: "BCY",
    CITYNAME: "BULCHI",
    CITYNAME_HEB: "BULCHI",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BCZ",
    AIRPORTNAME: "Bickerton Island",
    CITYCODE: "BCZ",
    CITYNAME: "BICKERTON ISLAND",
    CITYNAME_HEB: "BICKERTON ISLAND",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PAS",
    AIRPORTNAME: "Paros",
    CITYCODE: "PAS",
    CITYNAME: "PAROS",
    CITYNAME_HEB: "\u05e4\u05d0\u05e8\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "PAT",
    AIRPORTNAME: "Patna",
    CITYCODE: "PAT",
    CITYNAME: "PATNA",
    CITYNAME_HEB: "PATNA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "PAU",
    AIRPORTNAME: "Pauk",
    CITYCODE: "PAU",
    CITYNAME: "PAUK",
    CITYNAME_HEB: "PAUK",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PAV",
    AIRPORTNAME: null,
    CITYCODE: "PAV",
    CITYNAME: "PAULO AFONSO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PAW",
    AIRPORTNAME: "Pambwa",
    CITYCODE: "PAW",
    CITYNAME: "PAMBWA",
    CITYNAME_HEB: "PAMBWA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "PAX",
    AIRPORTNAME: "Port De Paix",
    CITYCODE: "PAX",
    CITYNAME: "PORT DE PAIX",
    CITYNAME_HEB: "PORT DE PAIX",
    COUNTRYCODE: "HT",
    COUNTRYNAME: "HAITI",
    COUNTRYNAME_HEB: "\u05d4\u05d0\u05d9\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "PAY",
    AIRPORTNAME: "Pamol",
    CITYCODE: "PAY",
    CITYNAME: "PAMOL",
    CITYNAME_HEB: "PAMOL",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PAZ",
    AIRPORTNAME: "Tajin",
    CITYCODE: "PAZ",
    CITYNAME: "POZA RICA",
    CITYNAME_HEB: "POZA RICA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "PBB",
    AIRPORTNAME: null,
    CITYCODE: "PBB",
    CITYNAME: "PARANAIBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PBC",
    AIRPORTNAME: "Huejotsingo",
    CITYCODE: "PBC",
    CITYNAME: "PUEBLA",
    CITYNAME_HEB: "PUEBLA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "BDA",
    AIRPORTNAME: "Bermuda International",
    CITYCODE: "BDA",
    CITYNAME: "BERMUDA",
    CITYNAME_HEB: "\u05d1\u05e8\u05de\u05d5\u05d3\u05d4",
    COUNTRYCODE: "BM",
    COUNTRYNAME: "BERMUDA",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05de\u05d5\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "PBD",
    AIRPORTNAME: "Porbandar",
    CITYCODE: "PBD",
    CITYNAME: "PORBANDAR",
    CITYNAME_HEB: "PORBANDAR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "PBE",
    AIRPORTNAME: "Puerto Berrio",
    CITYCODE: "PBE",
    CITYNAME: "PUERTO BERRIO",
    CITYNAME_HEB: "PUERTO BERRIO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PBF",
    AIRPORTNAME: "Grider Field",
    CITYCODE: "PBF",
    CITYNAME: "PINE BLUFF",
    CITYNAME_HEB: "PINE BLUFF",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PBH",
    AIRPORTNAME: "Paro",
    CITYCODE: "PBH",
    CITYNAME: "PARO",
    CITYNAME_HEB: "\u05e4\u05d0\u05e8\u05d5",
    COUNTRYCODE: "BT",
    COUNTRYNAME: "BHUTAN",
    COUNTRYNAME_HEB: "\u05d1\u05d0\u05d5\u05d8\u05df",
  },
  {
    AIRPORTCODE: "PBI",
    AIRPORTNAME: "International",
    CITYCODE: "PBI",
    CITYNAME: "WEST PALM BEACH",
    CITYNAME_HEB:
      "\u05d5\u05d5\u05e1\u05d8 \u05e4\u05d0\u05dc\u05dd \u05d1\u05d9\u05e5",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZWP",
    AIRPORTNAME: null,
    CITYCODE: "PBI",
    CITYNAME: "WEST PALM BEACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PBJ",
    AIRPORTNAME: "Paama",
    CITYCODE: "PBJ",
    CITYNAME: "PAAMA",
    CITYNAME_HEB: "PAAMA",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "PBK",
    AIRPORTNAME: "Pack Creek",
    CITYCODE: "PBK",
    CITYNAME: "PACK CREEK",
    CITYNAME_HEB: "Pack Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PBL",
    AIRPORTNAME: "Puerto Cabello",
    CITYCODE: "PBL",
    CITYNAME: "PUERTO CABELLO",
    CITYNAME_HEB: "PUERTO CABELLO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "NWU",
    AIRPORTNAME: null,
    CITYCODE: "BDA",
    CITYNAME: "BERMUDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BM",
    COUNTRYNAME: "BERMUDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ORG",
    AIRPORTNAME: "Zorg En Hoop",
    CITYCODE: "PBM",
    CITYNAME: "PARAMARIBO",
    CITYNAME_HEB: "PARAMARIBO",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "PBM",
    AIRPORTNAME: "Zanderij International",
    CITYCODE: "PBM",
    CITYNAME: "PARAMARIBO",
    CITYNAME_HEB: "PARAMARIBO",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "PBN",
    AIRPORTNAME: "Porto Amboim",
    CITYCODE: "PBN",
    CITYNAME: "PORTO AMBOIM",
    CITYNAME_HEB: "PORTO AMBOIM",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PBO",
    AIRPORTNAME: "Paraburdoo",
    CITYCODE: "PBO",
    CITYNAME: "PARABURDOO",
    CITYNAME_HEB: "PARABURDOO",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PBP",
    AIRPORTNAME: "Punta Islita",
    CITYCODE: "PBP",
    CITYNAME: "PUNTA ISLITA",
    CITYNAME_HEB: "PUNTA ISLITA",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "PBQ",
    AIRPORTNAME: null,
    CITYCODE: "PBQ",
    CITYNAME: "PIMENTA BUENO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PBR",
    AIRPORTNAME: "Puerto Barrios",
    CITYCODE: "PBR",
    CITYNAME: "PUERTO BARRIOS",
    CITYNAME_HEB: "PUERTO BARRIOS",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PBS",
    AIRPORTNAME: "Patong Beach",
    CITYCODE: "PBS",
    CITYNAME: "PATONG BEACH",
    CITYNAME_HEB: "\u05e4\u05d0\u05d8\u05d5\u05e0\u05d2 \u05d1\u05d9\u05e5",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PBU",
    AIRPORTNAME: "Putao",
    CITYCODE: "PBU",
    CITYNAME: "PUTAO",
    CITYNAME_HEB: "PUTAO",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "PBV",
    AIRPORTNAME: null,
    CITYCODE: "PBV",
    CITYNAME: "PORTOS DOS GAUCHOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BDB",
    AIRPORTNAME: "Bundaberg",
    CITYCODE: "BDB",
    CITYNAME: "BUNDABERG",
    CITYNAME_HEB: "BUNDABERG",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PBW",
    AIRPORTNAME: "Palibelo",
    CITYCODE: "PBW",
    CITYNAME: "PALIBELO",
    CITYNAME_HEB: "PALIBELO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PBX",
    AIRPORTNAME: null,
    CITYCODE: "PBX",
    CITYNAME: "PORTO ALEGRE DO NORTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PBZ",
    AIRPORTNAME: "Plettenberg Bay",
    CITYCODE: "PBZ",
    CITYNAME: "PLETTENBERG BAY",
    CITYNAME_HEB: "PLETTENBERG BAY",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "PCA",
    AIRPORTNAME: "Portage Creek",
    CITYCODE: "PCA",
    CITYNAME: "PORTAGE CREEK",
    CITYNAME_HEB: "Portage Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PCB",
    AIRPORTNAME: "Pondok Cabe",
    CITYCODE: "PCB",
    CITYNAME: "PONDOK CABE",
    CITYNAME_HEB: "PONDOK CABE",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PCC",
    AIRPORTNAME: "Puerto Rico",
    CITYCODE: "PCC",
    CITYNAME: "PUERTO RICO",
    CITYNAME_HEB: "PUERTO RICO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PCD",
    AIRPORTNAME: "Municipal",
    CITYCODE: "PCD",
    CITYNAME: "PRAIRIE DU CHIEN",
    CITYNAME_HEB: "PRAIRIE DU CHIEN",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PCE",
    AIRPORTNAME: "Painter Creek",
    CITYCODE: "PCE",
    CITYNAME: "PAINTER CREEK",
    CITYNAME_HEB: "Painter Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PCG",
    AIRPORTNAME: "Paso Caballos",
    CITYCODE: "PCG",
    CITYNAME: "PASO CABALLOS",
    CITYNAME_HEB: "PASO CABALLOS",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "PCH",
    AIRPORTNAME: "Palacios",
    CITYCODE: "PCH",
    CITYNAME: "PALACIOS",
    CITYNAME_HEB: "PALACIOS",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "BDC",
    AIRPORTNAME: null,
    CITYCODE: "BDC",
    CITYNAME: "BARRA DO CORDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PCJ",
    AIRPORTNAME: null,
    CITYCODE: "PCJ",
    CITYNAME: "PUERTO LA VICTORIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PY",
    COUNTRYNAME: "PARAGUAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PCK",
    AIRPORTNAME: "Porcupine Creek",
    CITYCODE: "PCK",
    CITYNAME: "PORCUPINE CREEK",
    CITYNAME_HEB: "Porcupine Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "PCL",
    AIRPORTNAME: "Capitan Rolden",
    CITYCODE: "PCL",
    CITYNAME: "PUCALLPA",
    CITYNAME_HEB: "PUCALLPA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "ROB",
    AIRPORTNAME: "Roberts International Airport",
    CITYCODE: "MLW",
    CITYNAME: "MONROVIA",
    CITYNAME_HEB: "MONROVIA",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ACD",
    AIRPORTNAME: "Acandi",
    CITYCODE: "ACD",
    CITYNAME: "ACANDI",
    CITYNAME_HEB: "Acandi",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AXR",
    AIRPORTNAME: "Arutua",
    CITYCODE: "AXR",
    CITYNAME: "ARUTUA",
    CITYNAME_HEB: "ARUTUA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "MLX",
    AIRPORTNAME: "Malatya",
    CITYCODE: "MLX",
    CITYNAME: "MALATYA",
    CITYNAME_HEB: "MALATYA",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MLY",
    AIRPORTNAME: "Manley Hot Springs",
    CITYCODE: "MLY",
    CITYNAME: "MANLEY HOT SPRING",
    CITYNAME_HEB: "Manley Hot Springs",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MLZ",
    AIRPORTNAME: null,
    CITYCODE: "MLZ",
    CITYNAME: "MELO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "UY",
    COUNTRYNAME: "URUGUAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MMA",
    AIRPORTNAME: null,
    CITYCODE: "MMA",
    CITYNAME: "MALMO",
    CITYNAME_HEB: "\u05de\u05dc\u05de\u05d5",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JMM",
    AIRPORTNAME: null,
    CITYCODE: "MMA",
    CITYNAME: "MALMO",
    CITYNAME_HEB: "\u05de\u05dc\u05de\u05d5",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MMX",
    AIRPORTNAME: "Sturup",
    CITYCODE: "MMA",
    CITYNAME: "MALMO",
    CITYNAME_HEB: "\u05de\u05dc\u05de\u05d5",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XFP",
    AIRPORTNAME: null,
    CITYCODE: "MMA",
    CITYNAME: "MALMO",
    CITYNAME_HEB: "\u05de\u05dc\u05de\u05d5",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XFR",
    AIRPORTNAME: null,
    CITYCODE: "MMA",
    CITYNAME: "MALMO",
    CITYNAME_HEB: "\u05de\u05dc\u05de\u05d5",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MMB",
    AIRPORTNAME: "Memanbetsu",
    CITYCODE: "MMB",
    CITYNAME: "MEMANBETSU",
    CITYNAME_HEB: "MEMANBETSU",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "MMC",
    AIRPORTNAME: "Ciudad Mante",
    CITYCODE: "MMC",
    CITYNAME: "CIUDAD MANTE",
    CITYNAME_HEB: "CIUDAD MANTE",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "AXT",
    AIRPORTNAME: "Akita",
    CITYCODE: "AXT",
    CITYNAME: "AKITA",
    CITYNAME_HEB: "AKITA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "MMD",
    AIRPORTNAME: "Maridor",
    CITYCODE: "MMD",
    CITYNAME: "MINAMI DAITO",
    CITYNAME_HEB: "MINAMI DAITO",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "MME",
    AIRPORTNAME: "Tees Valley",
    CITYCODE: "MME",
    CITYNAME: "Durham",
    CITYNAME_HEB: "\u05d3\u05d5\u05e8\u05d4\u05d0\u05dd",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MMF",
    AIRPORTNAME: "Mamfe",
    CITYCODE: "MMF",
    CITYNAME: "MAMFE",
    CITYNAME_HEB: "MAMFE",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MMG",
    AIRPORTNAME: "Mount Magnet",
    CITYCODE: "MMG",
    CITYNAME: "MOUNT MAGNET",
    CITYNAME_HEB: "MOUNT MAGNET",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MMH",
    AIRPORTNAME: "Mammoth Lakes",
    CITYCODE: "MMH",
    CITYNAME: "MAMMOTH LAKES",
    CITYNAME_HEB: "MAMMOTH LAKES",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MMJ",
    AIRPORTNAME: "Matsumoto",
    CITYCODE: "MMJ",
    CITYNAME: "MATSUMOTO",
    CITYNAME_HEB: "\u05de\u05d0\u05d8\u05e1\u05d5\u05de\u05d5\u05d8\u05d5",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "MMK",
    AIRPORTNAME: "Murmansk",
    CITYCODE: "MMK",
    CITYNAME: "MURMANSK",
    CITYNAME_HEB: "MURMANSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MML",
    AIRPORTNAME: "Municipal-Ryan Field",
    CITYCODE: "MML",
    CITYNAME: "MARSHALL",
    CITYNAME_HEB: "MARSHALL",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MMM",
    AIRPORTNAME: "Middlemount",
    CITYCODE: "MMM",
    CITYNAME: "MIDDLEMOUNT",
    CITYNAME_HEB: "MIDDLEMOUNT",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AXU",
    AIRPORTNAME: "Axum",
    CITYCODE: "AXU",
    CITYNAME: "AXUM",
    CITYNAME_HEB: "AXUM",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MMN",
    AIRPORTNAME: "Minute Man Airfield",
    CITYCODE: "MMN",
    CITYNAME: "STOW",
    CITYNAME_HEB: "STOW",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MMO",
    AIRPORTNAME: null,
    CITYCODE: "MMO",
    CITYNAME: "MAIO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CV",
    COUNTRYNAME: "CAPE VERDE-REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MMP",
    AIRPORTNAME: "Mompos",
    CITYCODE: "MMP",
    CITYNAME: "MOMPOS",
    CITYNAME_HEB: "MOMPOS",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MMQ",
    AIRPORTNAME: "Mbala",
    CITYCODE: "MMQ",
    CITYNAME: "MBALA",
    CITYNAME_HEB: "MBALA",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MMS",
    AIRPORTNAME: "Selfs",
    CITYCODE: "MMS",
    CITYNAME: "MARKS",
    CITYNAME_HEB: "MARKS",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MMU",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MMU",
    CITYNAME: "MORRISTOWN",
    CITYNAME_HEB: "MORRISTOWN",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MMV",
    AIRPORTNAME: "Mal",
    CITYCODE: "MMV",
    CITYNAME: "MAL",
    CITYNAME_HEB: "MAL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MMW",
    AIRPORTNAME: "Moma",
    CITYCODE: "MMW",
    CITYNAME: "MOMA",
    CITYNAME_HEB: "MOMA",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "MMY",
    AIRPORTNAME: "Hirara",
    CITYCODE: "MMY",
    CITYNAME: "MIYAKO JIMA",
    CITYNAME_HEB: "\u05de\u05d9\u05d0\u05e7\u05df",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "MMZ",
    AIRPORTNAME: null,
    CITYCODE: "MMZ",
    CITYNAME: "MAIMANA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AXV",
    AIRPORTNAME: "Neil Armstrong",
    CITYCODE: "AXV",
    CITYNAME: "WAPAKONETA",
    CITYNAME_HEB: "WAPAKONETA",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MNA",
    AIRPORTNAME: "Melangguane",
    CITYCODE: "MNA",
    CITYNAME: "MELANGGUANE",
    CITYNAME_HEB: "MELANGGUANE",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MNB",
    AIRPORTNAME: "Moanda",
    CITYCODE: "MNB",
    CITYNAME: "MOANDA",
    CITYNAME_HEB: "MOANDA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "MNC",
    AIRPORTNAME: "Nacala",
    CITYCODE: "MNC",
    CITYNAME: "NACALA",
    CITYNAME_HEB: "NACALA",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "MND",
    AIRPORTNAME: "Medina",
    CITYCODE: "MND",
    CITYNAME: "MEDINA",
    CITYNAME_HEB: "MEDINA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MNE",
    AIRPORTNAME: "Mungeranie",
    CITYCODE: "MNE",
    CITYNAME: "MUNGERANIE",
    CITYNAME_HEB: "MUNGERANIE",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MNF",
    AIRPORTNAME: "Mana Island Airstrip",
    CITYCODE: "MNF",
    CITYNAME: "MANA ISLANDS",
    CITYNAME_HEB: "MANA ISLANDS",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "MNH",
    AIRPORTNAME: "Minneriya",
    CITYCODE: "MNH",
    CITYNAME: "MINNERIYA",
    CITYNAME_HEB: "MINNERIYA",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "MNI",
    AIRPORTNAME: " ",
    CITYCODE: "MNI",
    CITYNAME: "MONTSERRAT",
    CITYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e1\u05e8\u05d0\u05d8",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "MNJ",
    AIRPORTNAME: "Mananjary",
    CITYCODE: "MNJ",
    CITYNAME: "MANANJARY",
    CITYNAME_HEB: "MANANJARY",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "AXX",
    AIRPORTNAME: "Angel Fire",
    CITYCODE: "AXX",
    CITYNAME: "ANGEL FIRE",
    CITYNAME_HEB: "ANGEL FIRE",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MNK",
    AIRPORTNAME: "Maiana",
    CITYCODE: "MNK",
    CITYNAME: "MAIANA",
    CITYNAME_HEB: "MAIANA",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "MNL",
    AIRPORTNAME: "Ninoy Aquino International",
    CITYCODE: "MNL",
    CITYNAME: "MANILA",
    CITYNAME_HEB: "\u05de\u05e0\u05d9\u05dc\u05d4",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "MNM",
    AIRPORTNAME: "Menominee",
    CITYCODE: "MNM",
    CITYNAME: "MENOMINEE",
    CITYNAME_HEB: "MENOMINEE",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MNN",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MNN",
    CITYNAME: "MEDITERRANEAN",
    CITYNAME_HEB: "\u05d4\u05d9\u05dd \u05d4\u05ea\u05d9\u05db\u05d5\u05df",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MNO",
    AIRPORTNAME: "Manono",
    CITYCODE: "MNO",
    CITYNAME: "MANONO",
    CITYNAME_HEB: "MANONO",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "MNP",
    AIRPORTNAME: "Maron",
    CITYCODE: "MNP",
    CITYNAME: "MARON",
    CITYNAME_HEB: "MARON",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MNQ",
    AIRPORTNAME: "Monto",
    CITYCODE: "MNQ",
    CITYNAME: "MONTO",
    CITYNAME_HEB: "MONTO",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MNR",
    AIRPORTNAME: "Mongu",
    CITYCODE: "MNR",
    CITYNAME: "MONGU",
    CITYNAME_HEB: "MONGU",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MNS",
    AIRPORTNAME: "Mansa",
    CITYCODE: "MNS",
    CITYNAME: "MANSA",
    CITYNAME_HEB: "MANSA",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AYA",
    AIRPORTNAME: " ",
    CITYCODE: "AYA",
    CITYNAME: "AYA NAPA",
    CITYNAME_HEB: "\u05d0\u05d9\u05d4 \u05e0\u05d0\u05e4\u05d4",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "MNU",
    AIRPORTNAME: "Maulmyine",
    CITYCODE: "MNU",
    CITYNAME: "MAULMYINE",
    CITYNAME_HEB: "MAULMYINE",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MNV",
    AIRPORTNAME: "Mountain Valley",
    CITYCODE: "MNV",
    CITYNAME: "MOUNTAIN VALLEY",
    CITYNAME_HEB: "MOUNTAIN VALLEY",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MNW",
    AIRPORTNAME: "Macdonald Downs",
    CITYCODE: "MNW",
    CITYNAME: "MACDONALD DOWNS",
    CITYNAME_HEB: "MACDONALD DOWNS",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MNX",
    AIRPORTNAME: null,
    CITYCODE: "MNX",
    CITYNAME: "MANICORE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MNY",
    AIRPORTNAME: "Mono",
    CITYCODE: "MNY",
    CITYNAME: "MONO",
    CITYNAME_HEB: "MONO",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MNZ",
    AIRPORTNAME: "Manassas",
    CITYCODE: "MNZ",
    CITYNAME: "MANASSAS",
    CITYNAME_HEB: "MANASSAS",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MOA",
    AIRPORTNAME: "Orestes Acosta",
    CITYCODE: "MOA",
    CITYNAME: "MOA",
    CITYNAME_HEB: "MOA",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "BFM",
    AIRPORTNAME: "Mob Aerospace",
    CITYCODE: "MOB",
    CITYNAME: "MOBILE",
    CITYNAME_HEB: "Mobile",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MOB",
    AIRPORTNAME: "Municipal Airport",
    CITYCODE: "MOB",
    CITYNAME: "MOBILE",
    CITYNAME_HEB: "Mobile",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AYC",
    AIRPORTNAME: "Ayacucho",
    CITYCODE: "AYC",
    CITYNAME: "AYACUCHO",
    CITYNAME_HEB: "AYACUCHO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MOC",
    AIRPORTNAME: null,
    CITYCODE: "MOC",
    CITYNAME: "MONTES CLAROS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MOD",
    AIRPORTNAME: " ",
    CITYCODE: "MOD",
    CITYNAME: "MODRA",
    CITYNAME_HEB: "\u05de\u05d5\u05d3\u05e8\u05d4",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MOE",
    AIRPORTNAME: "Momeik",
    CITYCODE: "MOE",
    CITYNAME: "MOMEIK",
    CITYNAME_HEB: "MOMEIK",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MOF",
    AIRPORTNAME: " ",
    CITYCODE: "MOF",
    CITYNAME: "MONFLANQUIN",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "MOG",
    AIRPORTNAME: "Mong Hsat",
    CITYCODE: "MOG",
    CITYNAME: "MONG HSAT",
    CITYNAME_HEB: "MONG HSAT",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MOH",
    AIRPORTNAME: "Mohanbari",
    CITYCODE: "MOH",
    CITYNAME: "MOHANBARI",
    CITYNAME_HEB: "MOHANBARI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "MOI",
    AIRPORTNAME: "Mitiaro Island",
    CITYCODE: "MOI",
    CITYNAME: "MITIARO ISLAND",
    CITYNAME_HEB: "MITIARO ISLAND",
    COUNTRYCODE: "CK",
    COUNTRYNAME: "COOK ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e7\u05d5\u05e7",
  },
  {
    AIRPORTCODE: "MOJ",
    AIRPORTNAME: "Moengo",
    CITYCODE: "MOJ",
    CITYNAME: "MOENGO",
    CITYNAME_HEB: "MOENGO",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "MOK",
    AIRPORTNAME: "Mankono",
    CITYCODE: "MOK",
    CITYNAME: "MANKONO",
    CITYNAME_HEB: "MANKONO",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "POK",
    AIRPORTNAME: " ",
    CITYCODE: "POK",
    CITYNAME: "POSITANO",
    CITYNAME_HEB: "\u05e4\u05d5\u05e1\u05d9\u05d8\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MOL",
    AIRPORTNAME: "Aro",
    CITYCODE: "MOL",
    CITYNAME: "MOLDE",
    CITYNAME_HEB: "MOLDE",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AYD",
    AIRPORTNAME: "Alroy Downs",
    CITYCODE: "AYD",
    CITYNAME: "ALROY DOWNS",
    CITYNAME_HEB: "ALROY DOWNS",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MOM",
    AIRPORTNAME: "Moudjeria",
    CITYCODE: "MOM",
    CITYNAME: "MOUDJERIA",
    CITYNAME_HEB: "MOUDJERIA",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MON",
    AIRPORTNAME: " ",
    CITYCODE: "MON",
    CITYNAME: "MONTENEGRO",
    CITYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5",
    COUNTRYCODE: "ME",
    COUNTRYNAME: "MONTENEGRO",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "FPC",
    CITYNAME: "PAESTUM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MOO",
    AIRPORTNAME: " ",
    CITYCODE: "MOO",
    CITYNAME: "MOOSLARGUE",
    CITYNAME_HEB: "\u05de\u05d5\u05e1\u05dc\u05e8\u05d2",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "MOP",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MOP",
    CITYNAME: "MOUNT PLEASANT",
    CITYNAME_HEB: "MOUNT PLEASANT",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MOQ",
    AIRPORTNAME: "Morondava",
    CITYCODE: "MOQ",
    CITYNAME: "MORONDAVA",
    CITYNAME_HEB: "MORONDAVA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "MOR",
    AIRPORTNAME: "Moore-Murrell",
    CITYCODE: "MOR",
    CITYNAME: "MORRISTOWN",
    CITYNAME_HEB: "MORRISTOWN",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "MOS",
    CITYNAME: "MOSCENISKA DRAGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MIB",
    AIRPORTNAME: null,
    CITYCODE: "MOT",
    CITYNAME: "MINOT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MOT",
    AIRPORTNAME: "International",
    CITYCODE: "MOT",
    CITYNAME: "MINOT",
    CITYNAME_HEB: "MINOT",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MOU",
    AIRPORTNAME: "Mountain Village",
    CITYCODE: "MOU",
    CITYNAME: "MOUNTAIN VILLAGE",
    CITYNAME_HEB: "Mountain Village",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AYE",
    AIRPORTNAME: "AAF Heliport",
    CITYCODE: "AYE",
    CITYNAME: "FORT DEVENS",
    CITYNAME_HEB: "FORT DEVENS",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MOV",
    AIRPORTNAME: "Moranbah",
    CITYCODE: "MOV",
    CITYNAME: "MORANBAH",
    CITYNAME_HEB: "MORANBAH",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MOW",
    AIRPORTNAME: null,
    CITYCODE: "MOW",
    CITYNAME: "MOSCOW",
    CITYNAME_HEB: "\u05de\u05d5\u05e1\u05e7\u05d1\u05d4",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BKA",
    AIRPORTNAME: "Bykovo",
    CITYCODE: "MOW",
    CITYNAME: "MOSCOW",
    CITYNAME_HEB: "\u05de\u05d5\u05e1\u05e7\u05d1\u05d4",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DME",
    AIRPORTNAME: "Domodedovo",
    CITYCODE: "MOW",
    CITYNAME: "MOSCOW",
    CITYNAME_HEB: "\u05de\u05d5\u05e1\u05e7\u05d1\u05d4",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SVO",
    AIRPORTNAME: "Sheremetyevo",
    CITYCODE: "MOW",
    CITYNAME: "MOSCOW",
    CITYNAME_HEB: "\u05de\u05d5\u05e1\u05e7\u05d1\u05d4",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VKO",
    AIRPORTNAME: "Vnukovo",
    CITYCODE: "MOW",
    CITYNAME: "MOSCOW",
    CITYNAME_HEB: "\u05de\u05d5\u05e1\u05e7\u05d1\u05d4",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZKD",
    AIRPORTNAME: " ",
    CITYCODE: "MOW",
    CITYNAME: "MOSCOW",
    CITYNAME_HEB: "\u05de\u05d5\u05e1\u05e7\u05d1\u05d4",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MOX",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MOX",
    CITYNAME: "MORRIS",
    CITYNAME_HEB: "MORRIS",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MOY",
    AIRPORTNAME: "Monterrey",
    CITYCODE: "MOY",
    CITYNAME: "MONTERREY",
    CITYNAME_HEB: "MONTERREY",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MOZ",
    AIRPORTNAME: "Temae",
    CITYCODE: "MOZ",
    CITYNAME: "MOOREA",
    CITYNAME_HEB: "MOOREA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "AYG",
    AIRPORTNAME: "Yaguara",
    CITYCODE: "AYG",
    CITYNAME: "YAGUARA",
    CITYNAME_HEB: "YAGUARA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MPA",
    AIRPORTNAME: "Mpacha",
    CITYCODE: "MPA",
    CITYNAME: "MPACHA",
    CITYNAME_HEB: "MPACHA",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MPC",
    AIRPORTNAME: "Muko-Muko",
    CITYCODE: "MPC",
    CITYNAME: "MUKO MUKO",
    CITYNAME_HEB: "MUKO MUKO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MPD",
    AIRPORTNAME: " ",
    CITYCODE: "MPD",
    CITYNAME: "MASPOLOMAS",
    CITYNAME_HEB:
      "\u05de\u05d0\u05e1\u05e4\u05d5\u05dc\u05d5\u05de\u05d0\u05e1",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "MPE",
    AIRPORTNAME: "Griswold",
    CITYCODE: "MPE",
    CITYNAME: "MADISON",
    CITYNAME_HEB: "MADISON",
    COUNTRYCODE: "USCT",
    COUNTRYNAME: "CONNECTICUT",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05e0\u05d8\u05d9\u05e7\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MPF",
    AIRPORTNAME: "Mapoda",
    CITYCODE: "MPF",
    CITYNAME: "MAPODA",
    CITYNAME_HEB: "MAPODA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MPG",
    AIRPORTNAME: "Makini",
    CITYCODE: "MPG",
    CITYNAME: "MAKINI",
    CITYNAME_HEB: "MAKINI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MPH",
    AIRPORTNAME: "Malay",
    CITYCODE: "MPH",
    CITYNAME: "BORACAY",
    CITYNAME_HEB: "\u05d1\u05d5\u05e8\u05e7\u05d0\u05d9",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "MPI",
    AIRPORTNAME: "Mamitupo",
    CITYCODE: "MPI",
    CITYNAME: "MAMITUPO",
    CITYNAME_HEB: "MAMITUPO",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MPK",
    AIRPORTNAME: "Mokpo",
    CITYCODE: "MPK",
    CITYNAME: "MOKPO",
    CITYNAME_HEB: "MOKPO",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "MPL",
    AIRPORTNAME: "Frejorgues",
    CITYCODE: "MPL",
    CITYNAME: "MONTPELLIER",
    CITYNAME_HEB: "MONTPELLIER",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "AAH",
    AIRPORTNAME: "Aachen/Merzbr?ck",
    CITYCODE: "AAH",
    CITYNAME: "AACHEN",
    CITYNAME_HEB: "\u05d0\u05d0\u05db\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ACE",
    AIRPORTNAME: "Lanzarote",
    CITYCODE: "ACE",
    CITYNAME: "CANARY ISLAND - LANZAROTE",
    CITYNAME_HEB: "\u05dc\u05e0\u05d6\u05e8\u05d5\u05d8\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "AYH",
    AIRPORTNAME: "RAF Station",
    CITYCODE: "AYH",
    CITYNAME: "ALCONBURY",
    CITYNAME_HEB: "ALCONBURY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MPM",
    AIRPORTNAME: "Maputo International",
    CITYCODE: "MPM",
    CITYNAME: "MAPUTO",
    CITYNAME_HEB: "\u05de\u05e4\u05d5\u05d8\u05d5",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "MPN",
    AIRPORTNAME: "Mount Pleasant",
    CITYCODE: "MPN",
    CITYNAME: "MOUNT PLEASANT",
    CITYNAME_HEB: "MOUNT PLEASANT",
    COUNTRYCODE: "FK",
    COUNTRYNAME: "FALKLAND ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d5\u05e7\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "MPO",
    AIRPORTNAME: "Mt Pocono",
    CITYCODE: "MPO",
    CITYNAME: "MOUNT POCONO",
    CITYNAME_HEB: "MOUNT POCONO",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MPP",
    AIRPORTNAME: "Mulatupo",
    CITYCODE: "MPP",
    CITYNAME: "MULATUPO",
    CITYNAME_HEB: "MULATUPO",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MPQ",
    AIRPORTNAME: "Maan",
    CITYCODE: "MPQ",
    CITYNAME: "MAAN",
    CITYNAME_HEB: "MAAN",
    COUNTRYCODE: "JO",
    COUNTRYNAME: "JORDAN",
    COUNTRYNAME_HEB: "\u05d9\u05e8\u05d3\u05df",
  },
  {
    AIRPORTCODE: "MPR",
    AIRPORTNAME: "Mcpherson",
    CITYCODE: "MPR",
    CITYNAME: "MCPHERSON",
    CITYNAME_HEB: "MCPHERSON",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MPS",
    AIRPORTNAME: "Mount Pleasant",
    CITYCODE: "MPS",
    CITYNAME: "MOUNT PLEASANT",
    CITYNAME_HEB: "MOUNT PLEASANT",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MPT",
    AIRPORTNAME: "Maliana",
    CITYCODE: "MPT",
    CITYNAME: "MALIANA",
    CITYNAME_HEB: "MALIANA",
    COUNTRYCODE: "TP",
    COUNTRYNAME: "EAST TIMOR",
    COUNTRYNAME_HEB:
      "\u05d8\u05d9\u05de\u05d5\u05e8 \u05d4\u05de\u05d6\u05e8\u05d7\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "MPU",
    AIRPORTNAME: "Mapua",
    CITYCODE: "MPU",
    CITYNAME: "MAPUA",
    CITYNAME_HEB: "MAPUA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MPV",
    AIRPORTNAME: "Edward F Knapp State",
    CITYCODE: "MPV",
    CITYNAME: "MONTPELIER",
    CITYNAME_HEB: "MONTPELIER",
    COUNTRYCODE: "USVT",
    COUNTRYNAME: "VERMONT",
    COUNTRYNAME_HEB:
      '\u05d5\u05d5\u05e8\u05de\u05d5\u05e0\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AYI",
    AIRPORTNAME: "Yari",
    CITYCODE: "AYI",
    CITYNAME: "YARI",
    CITYNAME_HEB: "YARI",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MPW",
    AIRPORTNAME: "Mariupol",
    CITYCODE: "MPW",
    CITYNAME: "MARIUPOL",
    CITYNAME_HEB: "MARIUPOL",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "MPX",
    AIRPORTNAME: "Miyanmin",
    CITYCODE: "MPX",
    CITYNAME: "MIYANMIN",
    CITYNAME_HEB: "MIYANMIN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MPY",
    AIRPORTNAME: "Maripasoula",
    CITYCODE: "MPY",
    CITYNAME: "MARIPASOULA",
    CITYNAME_HEB: "MARIPASOULA",
    COUNTRYCODE: "GF",
    COUNTRYNAME: "FRENCH GUIANA",
    COUNTRYNAME_HEB:
      "\u05d2\u05d9\u05e0\u05d0\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "MPZ",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MPZ",
    CITYNAME: "MOUNT PLEASANT",
    CITYNAME_HEB: "MOUNT PLEASANT",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MQA",
    AIRPORTNAME: "Mandora",
    CITYCODE: "MQA",
    CITYNAME: "MANDORA",
    CITYNAME_HEB: "MANDORA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MQB",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MQB",
    CITYNAME: "MACOMB",
    CITYNAME_HEB: "MACOMB",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MQC",
    AIRPORTNAME: null,
    CITYCODE: "MQC",
    CITYNAME: "MIQUELON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PM",
    COUNTRYNAME: "ST. PIERRE AND MIQUELON",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MQD",
    AIRPORTNAME: null,
    CITYCODE: "MQD",
    CITYNAME: "MAQUINCHAO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MQE",
    AIRPORTNAME: "Marqua",
    CITYCODE: "MQE",
    CITYNAME: "MARQUA",
    CITYNAME_HEB: "MARQUA",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MQF",
    AIRPORTNAME: "Magnitogorsk",
    CITYCODE: "MQF",
    CITYNAME: "MAGNITOGORSK",
    CITYNAME_HEB: "MAGNITOGORSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AYK",
    AIRPORTNAME: "Arkalyk",
    CITYCODE: "AYK",
    CITYNAME: "ARKALYK",
    CITYNAME_HEB: "ARKALYK",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "MQG",
    AIRPORTNAME: "Midgard",
    CITYCODE: "MQG",
    CITYNAME: "MIDGARD",
    CITYNAME_HEB: "MIDGARD",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MQH",
    AIRPORTNAME: null,
    CITYCODE: "MQH",
    CITYNAME: "MINACU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MQI",
    AIRPORTNAME: "Quincy",
    CITYCODE: "MQI",
    CITYNAME: "QUINCY",
    CITYNAME_HEB: "QUINCY",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MQK",
    AIRPORTNAME: "San Matias",
    CITYCODE: "MQK",
    CITYNAME: "SAN MATIAS",
    CITYNAME_HEB: "SAN MATIAS",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MQL",
    AIRPORTNAME: "Mildura",
    CITYCODE: "MQL",
    CITYNAME: "MILDURA",
    CITYNAME_HEB: "MILDURA",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MQM",
    AIRPORTNAME: "Mardin",
    CITYCODE: "MQM",
    CITYNAME: "MARDIN",
    CITYNAME_HEB: "MARDIN",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MQN",
    AIRPORTNAME: "Mo I Rana",
    CITYCODE: "MQN",
    CITYNAME: "MO I RANA",
    CITYNAME_HEB: "MO I RANA",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MQO",
    AIRPORTNAME: "Malam",
    CITYCODE: "MQO",
    CITYNAME: "MALAM",
    CITYNAME_HEB: "MALAM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MQQ",
    AIRPORTNAME: "Moundou",
    CITYCODE: "MQQ",
    CITYNAME: "MOUNDOU",
    CITYNAME_HEB: "MOUNDOU",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "AYL",
    AIRPORTNAME: "Anthony Lagoon",
    CITYCODE: "AYL",
    CITYNAME: "ANTHONY LAGOON",
    CITYNAME_HEB: "ANTHONY LAGOON",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MQR",
    AIRPORTNAME: "Mosquera",
    CITYCODE: "MQR",
    CITYNAME: "MOSQUERA",
    CITYNAME_HEB: "MOSQUERA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MQS",
    AIRPORTNAME: null,
    CITYCODE: "MQS",
    CITYNAME: "MUSTIQUE ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "VC",
    COUNTRYNAME: "ST. VINCENT",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MQT",
    AIRPORTNAME: "Sawyer International",
    CITYCODE: "MQT",
    CITYNAME: "MARQUETTE",
    CITYNAME_HEB: "MARQUETTE",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MQU",
    AIRPORTNAME: "Mariquita",
    CITYCODE: "MQU",
    CITYNAME: "MARIQUITA",
    CITYNAME_HEB: "MARIQUITA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MQW",
    AIRPORTNAME: "Telfair-Wheeler",
    CITYCODE: "MQW",
    CITYNAME: "MC RAE",
    CITYNAME_HEB: "MC RAE",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MQX",
    AIRPORTNAME: "Makale",
    CITYCODE: "MQX",
    CITYNAME: "MAKALE",
    CITYNAME_HEB: "MAKALE",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MQY",
    AIRPORTNAME: "Smyrna",
    CITYCODE: "MQY",
    CITYNAME: "SMYRNA",
    CITYNAME_HEB: "SMYRNA",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MQZ",
    AIRPORTNAME: "Margaret River",
    CITYCODE: "MQZ",
    CITYNAME: "MARGARET RIVER",
    CITYNAME_HEB: "MARGARET RIVER",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MRA",
    AIRPORTNAME: "Misurata",
    CITYCODE: "MRA",
    CITYNAME: "MISURATA",
    CITYNAME_HEB: "MISURATA",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "MRB",
    AIRPORTNAME: "Martinsburg",
    CITYCODE: "MRB",
    CITYNAME: "MARTINSBURG",
    CITYNAME_HEB: "MARTINSBURG",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "AYN",
    AIRPORTNAME: "Anyang",
    CITYCODE: "AYN",
    CITYNAME: "ANYANG",
    CITYNAME_HEB: "ANYANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "MRC",
    AIRPORTNAME: "Maury County",
    CITYCODE: "MRC",
    CITYNAME: "COLUMBIA",
    CITYNAME_HEB: "COLUMBIA",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MRD",
    AIRPORTNAME: "A Carnevalli",
    CITYCODE: "MRD",
    CITYNAME: "MERIDA",
    CITYNAME_HEB: "MERIDA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "MRE",
    AIRPORTNAME: "Mara Lodges",
    CITYCODE: "MRE",
    CITYNAME: "MARA LODGES",
    CITYNAME_HEB: "MARA LODGES",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MRF",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MRF",
    CITYNAME: "MARFA",
    CITYNAME_HEB: "MARFA",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MRG",
    AIRPORTNAME: "Mareeba",
    CITYCODE: "MRG",
    CITYNAME: "MAREEBA",
    CITYNAME_HEB: "MAREEBA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MRH",
    AIRPORTNAME: "May River",
    CITYCODE: "MRH",
    CITYNAME: "MAY RIVER",
    CITYNAME_HEB: "MAY RIVER",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MRJ",
    AIRPORTNAME: "Marcala",
    CITYCODE: "MRJ",
    CITYNAME: "MARCALA",
    CITYNAME_HEB: "MARCALA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "MRK",
    AIRPORTNAME: "Marco Island",
    CITYCODE: "MRK",
    CITYNAME: "MARCO ISLAND",
    CITYNAME_HEB: "MARCO ISLAND",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MRL",
    AIRPORTNAME: "Miners Lake",
    CITYCODE: "MRL",
    CITYNAME: "MINERS LAKE",
    CITYNAME_HEB: "MINERS LAKE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MRM",
    AIRPORTNAME: " ",
    CITYCODE: "MRM",
    CITYNAME: "MARMARI",
    CITYNAME_HEB: "\u05de\u05e8\u05de\u05e8\u05d9",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "AYO",
    AIRPORTNAME: "Ayolas",
    CITYCODE: "AYO",
    CITYNAME: "AYOLAS",
    CITYNAME_HEB: "AYOLAS",
    COUNTRYCODE: "PY",
    COUNTRYNAME: "PARAGUAY",
    COUNTRYNAME_HEB: "\u05e4\u05d0\u05e8\u05d0\u05d2\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "MRN",
    AIRPORTNAME: "Lenoir",
    CITYCODE: "MRN",
    CITYNAME: "MORGANTON",
    CITYNAME_HEB: "MORGANTON",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MRO",
    AIRPORTNAME: "Masterton",
    CITYCODE: "MRO",
    CITYNAME: "MASTERTON",
    CITYNAME_HEB: "MASTERTON",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "MRP",
    AIRPORTNAME: "Marla",
    CITYCODE: "MRP",
    CITYNAME: "MARLA",
    CITYNAME_HEB: "MARLA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MRQ",
    AIRPORTNAME: "Marinduque",
    CITYCODE: "MRQ",
    CITYNAME: "MARINDUQUE",
    CITYNAME_HEB: "MARINDUQUE",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "MRR",
    AIRPORTNAME: "Macara",
    CITYCODE: "MRR",
    CITYNAME: "MACARA",
    CITYNAME_HEB: "MACARA",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "MRS",
    AIRPORTNAME: "MARSEILLE",
    CITYCODE: "MRS",
    CITYNAME: "MARSEILLE",
    CITYNAME_HEB: "\u05de\u05e8\u05e1\u05d9\u05d9",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "MRT",
    AIRPORTNAME: "Moroak",
    CITYCODE: "MRT",
    CITYNAME: "MOROAK",
    CITYNAME_HEB: "MOROAK",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MRU",
    AIRPORTNAME: "Seewoosagur Ramgoolam ",
    CITYCODE: "MRU",
    CITYNAME: "MAURITIUS",
    CITYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05e6\u05d9\u05d5\u05e1",
    COUNTRYCODE: "MU",
    COUNTRYNAME: "MAURITIUS ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05e6\u05d9\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "MRV",
    AIRPORTNAME: "Mineralnye Vody",
    CITYCODE: "MRV",
    CITYNAME: "MINERALNYE VODY",
    CITYNAME_HEB:
      "\u05de\u05d9\u05e0\u05e8\u05dc\u05e0\u05d9 \u05d5\u05d5\u05d3\u05d9",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AYP",
    AIRPORTNAME: "Yanamilla",
    CITYCODE: "AYP",
    CITYNAME: "AYACUCHO",
    CITYNAME_HEB: "AYACUCHO",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "MRW",
    AIRPORTNAME: "Maribo",
    CITYCODE: "MRW",
    CITYNAME: "MARIBO",
    CITYNAME_HEB: "MARIBO",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "MRY",
    AIRPORTNAME: "Monterey Peninsula",
    CITYCODE: "MRY",
    CITYNAME: "MONTEREY",
    CITYNAME_HEB: "MONTEREY",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OAR",
    AIRPORTNAME: null,
    CITYCODE: "MRY",
    CITYNAME: "MONTEREY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MRZ",
    AIRPORTNAME: "Moree",
    CITYCODE: "MRZ",
    CITYNAME: "MOREE",
    CITYNAME_HEB: "MOREE",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MSA",
    AIRPORTNAME: "Muskrat Dam",
    CITYCODE: "MSA",
    CITYNAME: "MUSKRAT DAM",
    CITYNAME_HEB: "MUSKRAT DAM",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "MSC",
    AIRPORTNAME: "Falcon Field",
    CITYCODE: "MSC",
    CITYNAME: "MESA",
    CITYNAME_HEB: "MESA",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZBS",
    AIRPORTNAME: null,
    CITYCODE: "MSC",
    CITYNAME: "MESA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MSD",
    AIRPORTNAME: "Mt Pleasant",
    CITYCODE: "MSD",
    CITYNAME: "MOUNT PLEASANT",
    CITYNAME_HEB: "MOUNT PLEASANT",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MSE",
    AIRPORTNAME: "Kent International",
    CITYCODE: "MSE",
    CITYNAME: "MANSTON",
    CITYNAME_HEB: "MANSTON",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MSF",
    AIRPORTNAME: "Mount Swan",
    CITYCODE: "MSF",
    CITYNAME: "MOUNT SWAN",
    CITYNAME_HEB: "MOUNT SWAN",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AYQ",
    AIRPORTNAME: "Connellan",
    CITYCODE: "AYQ",
    CITYNAME: "AYERS ROCK",
    CITYNAME_HEB: "AYERS ROCK",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MSG",
    AIRPORTNAME: "Matsaile",
    CITYCODE: "MSG",
    CITYNAME: "MATSAILE",
    CITYNAME_HEB: "MATSAILE",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "MSH",
    AIRPORTNAME: null,
    CITYCODE: "MSH",
    CITYNAME: "MASIRAH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "OM",
    COUNTRYNAME: "OMAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MSI",
    AIRPORTNAME: "Masalembo",
    CITYCODE: "MSI",
    CITYNAME: "MASALEMBO",
    CITYNAME_HEB: "MASALEMBO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MSJ",
    AIRPORTNAME: "Misawa",
    CITYCODE: "MSJ",
    CITYNAME: "MISAWA",
    CITYNAME_HEB: "MISAWA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "MSK",
    AIRPORTNAME: "Mastic Point",
    CITYCODE: "MSK",
    CITYNAME: "MASTIC POINT",
    CITYNAME_HEB: "MASTIC POINT",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MSL",
    AIRPORTNAME: "Florence/Muscle Sh/Sheffield Airport",
    CITYCODE: "MSL",
    CITYNAME: "MUSCLE SHOALS",
    CITYNAME_HEB: "Sheffield",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MSM",
    AIRPORTNAME: "Masi Manimba",
    CITYCODE: "MSM",
    CITYNAME: "MASI MANIMBA",
    CITYNAME_HEB: "MASI MANIMBA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "MSN",
    AIRPORTNAME: "Dane County Regional",
    CITYCODE: "MSN",
    CITYNAME: "MADISON",
    CITYNAME_HEB: "MADISON",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MSO",
    AIRPORTNAME: "Johnson-Bell Field",
    CITYCODE: "MSO",
    CITYNAME: "MISSOULA",
    CITYNAME_HEB: "MISSOULA",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MSP",
    AIRPORTNAME: "St Paul International",
    CITYCODE: "MSP",
    CITYNAME: "MINNEAPOLIS",
    CITYNAME_HEB:
      "\u05de\u05d9\u05e0\u05d9\u05d0\u05e4\u05d5\u05dc\u05d9\u05e1",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AYR",
    AIRPORTNAME: "Ayr",
    CITYCODE: "AYR",
    CITYNAME: "AYR",
    CITYNAME_HEB: "AYR",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FCM",
    AIRPORTNAME: null,
    CITYCODE: "MSP",
    CITYNAME: "MINNEAPOLIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JDT",
    AIRPORTNAME: null,
    CITYCODE: "MSP",
    CITYNAME: "MINNEAPOLIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MIC",
    AIRPORTNAME: null,
    CITYCODE: "MSP",
    CITYNAME: "MINNEAPOLIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MSQ",
    AIRPORTNAME: "International 2",
    CITYCODE: "MSQ",
    CITYNAME: "MINSK",
    CITYNAME_HEB: "\u05de\u05d9\u05e0\u05e1\u05e7",
    COUNTRYCODE: "BY",
    COUNTRYNAME: "BELARUS",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d0\u05e8\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "MSR",
    AIRPORTNAME: "Mus",
    CITYCODE: "MSR",
    CITYNAME: "MUS",
    CITYNAME_HEB: "MUS",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MSS",
    AIRPORTNAME: "Richards Field",
    CITYCODE: "MSS",
    CITYNAME: "MASSENA",
    CITYNAME_HEB: "MASSENA",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MST",
    AIRPORTNAME: "Maastricht/Aachen",
    CITYCODE: "MST",
    CITYNAME: "MAASTRICHT",
    CITYNAME_HEB: "MAASTRICHT",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ZYT",
    AIRPORTNAME: null,
    CITYCODE: "MST",
    CITYNAME: "MAASTRICHT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MSU",
    AIRPORTNAME: "Moshoeshoe International",
    CITYCODE: "MSU",
    CITYNAME: "MASERU",
    CITYNAME_HEB: "MASERU",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "AYS",
    AIRPORTNAME: "Ware County",
    CITYCODE: "AYS",
    CITYNAME: "WAYCROSS",
    CITYNAME_HEB: "WAYCROSS",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MSV",
    AIRPORTNAME: "Sullivan County International",
    CITYCODE: "MSV",
    CITYNAME: "MONTICELLO",
    CITYNAME_HEB: "MONTICELLO",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MSW",
    AIRPORTNAME: "Massawa",
    CITYCODE: "MSW",
    CITYNAME: "MASSAWA",
    CITYNAME_HEB: "MASSAWA",
    COUNTRYCODE: "ER",
    COUNTRYNAME: "ERITREA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05d9\u05ea\u05e8\u05d9\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MSX",
    AIRPORTNAME: "Mossendjo",
    CITYCODE: "MSX",
    CITYNAME: "MOSSENDJO",
    CITYNAME_HEB: "MOSSENDJO",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "MSY",
    AIRPORTNAME: "International",
    CITYCODE: "MSY",
    CITYNAME: "NEW ORLEANS",
    CITYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d0\u05d5\u05e8\u05dc\u05d9\u05e0\u05e1",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "MSZ",
    AIRPORTNAME: "Namibe",
    CITYCODE: "MSZ",
    CITYNAME: "NAMIBE",
    CITYNAME_HEB: "NAMIBE",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "MTA",
    AIRPORTNAME: "Matamata",
    CITYCODE: "MTA",
    CITYNAME: "MATAMATA",
    CITYNAME_HEB: "MATAMATA",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "MTB",
    AIRPORTNAME: "Monte Libano",
    CITYCODE: "MTB",
    CITYNAME: "MONTE LIBANO",
    CITYNAME_HEB: "MONTE LIBANO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MTC",
    AIRPORTNAME: "Selfridge ANGB",
    CITYCODE: "MTC",
    CITYNAME: "MOUNT CLEMENS",
    CITYNAME_HEB: "MOUNT CLEMENS",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ACH",
    AIRPORTNAME: "Altenrhein",
    CITYCODE: "ACH",
    CITYNAME: "ALTENRHEIN",
    CITYNAME_HEB: "\u05d0\u05dc\u05d8\u05e0\u05e8\u05d9\u05d9\u05df",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "AYT",
    AIRPORTNAME: "Antalya",
    CITYCODE: "AYT",
    CITYNAME: "ANTALYA",
    CITYNAME_HEB: "\u05d0\u05e0\u05d8\u05dc\u05d9\u05d4",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NFB",
    AIRPORTNAME: null,
    CITYCODE: "MTC",
    CITYNAME: "MOUNT CLEMENS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MTD",
    AIRPORTNAME: "Mt Sandford",
    CITYCODE: "MTD",
    CITYNAME: "MOUNT SANDFORD",
    CITYNAME_HEB: "MOUNT SANDFORD",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MTE",
    AIRPORTNAME: null,
    CITYCODE: "MTE",
    CITYNAME: "MONTE ALEGRE",
    CITYNAME_HEB: "\u05de\u05d5\u05d8\u05d4 \u05d0\u05dc\u05d2\u05e8\u05d4",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MTF",
    AIRPORTNAME: "Mizan Teferi",
    CITYCODE: "MTF",
    CITYNAME: "MIZAN TEFERI",
    CITYNAME_HEB: "MIZAN TEFERI",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MTG",
    AIRPORTNAME: null,
    CITYCODE: "MTG",
    CITYNAME: "MATO GROSSO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MTH",
    AIRPORTNAME: "Marathon Flight Strip",
    CITYCODE: "MTH",
    CITYNAME: "MARATHON",
    CITYNAME_HEB: "MARATHON",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MTI",
    AIRPORTNAME: null,
    CITYCODE: "MTI",
    CITYNAME: "MOSTEIROS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CV",
    COUNTRYNAME: "CAPE VERDE-REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MTJ",
    AIRPORTNAME: "Montrose County",
    CITYCODE: "MTJ",
    CITYNAME: "MONTROSE",
    CITYNAME_HEB: "MONTROSE",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MTK",
    AIRPORTNAME: "Makin Island",
    CITYCODE: "MTK",
    CITYNAME: "MAKIN ISLAND",
    CITYNAME_HEB: "MAKIN ISLAND",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "MTL",
    AIRPORTNAME: "Maitland",
    CITYCODE: "MTL",
    CITYNAME: "MAITLAND",
    CITYNAME_HEB: "MAITLAND",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AYU",
    AIRPORTNAME: "Aiyura",
    CITYCODE: "AYU",
    CITYNAME: "AIYURA",
    CITYNAME_HEB: "AIYURA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MTM",
    AIRPORTNAME: "Metlakatla",
    CITYCODE: "MTM",
    CITYNAME: "METLAKATLA",
    CITYNAME_HEB: "Metlakatla",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MTO",
    AIRPORTNAME: "Coles County Memorial",
    CITYCODE: "MTO",
    CITYNAME: "MATTOON",
    CITYNAME_HEB: "MATTOON",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MTP",
    AIRPORTNAME: "Sky Portal",
    CITYCODE: "MTP",
    CITYNAME: "MONTAUK",
    CITYNAME_HEB: "MONTAUK",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MTQ",
    AIRPORTNAME: "Mitchell",
    CITYCODE: "MTQ",
    CITYNAME: "MITCHELL",
    CITYNAME_HEB: "MITCHELL",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MTR",
    AIRPORTNAME: "S. Jeronimo",
    CITYCODE: "MTR",
    CITYNAME: "MONTERIA",
    CITYNAME_HEB: "MONTERIA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MTS",
    AIRPORTNAME: "Matsapha International",
    CITYCODE: "MTS",
    CITYNAME: "MANZINI",
    CITYNAME_HEB: "\u05de\u05e0\u05d6\u05d9\u05e0\u05d9",
    COUNTRYCODE: "SZ",
    COUNTRYNAME: "SWAZILAND",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d5\u05d9\u05d6\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "MTT",
    AIRPORTNAME: "Minatitlan",
    CITYCODE: "MTT",
    CITYNAME: "MINATITLAN",
    CITYNAME_HEB: "MINATITLAN",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "MTU",
    AIRPORTNAME: "Montepuez",
    CITYCODE: "MTU",
    CITYNAME: "MONTEPUEZ",
    CITYNAME_HEB: "MONTEPUEZ",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "BAT",
    AIRPORTNAME: " ",
    CITYCODE: "BAT",
    CITYNAME: "BEATENBERG",
    CITYNAME_HEB: "\u05d1\u05d9\u05d8\u05e0\u05d1\u05e8\u05d2",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "NHS",
    AIRPORTNAME: "Nushki",
    CITYCODE: "NHS",
    CITYNAME: "NUSHKI",
    CITYNAME_HEB: "NUSHKI",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "NHT",
    AIRPORTNAME: "Northolt",
    CITYCODE: "NHT",
    CITYNAME: "NORTHOLT",
    CITYNAME_HEB: "NORTHOLT",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NHV",
    AIRPORTNAME: "Nuku Hiva",
    CITYCODE: "NHV",
    CITYNAME: "NUKU HIVA",
    CITYNAME_HEB: "NUKU HIVA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "NHX",
    AIRPORTNAME: "Barin OLF Osn",
    CITYCODE: "NHX",
    CITYNAME: "FOLEY",
    CITYNAME_HEB: "Foley",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NHZ",
    AIRPORTNAME: "NAS",
    CITYCODE: "NHZ",
    CITYNAME: "BRUNSWICK",
    CITYNAME_HEB: "BRUNSWICK",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NIB",
    AIRPORTNAME: "Nikolai",
    CITYCODE: "NIB",
    CITYNAME: "NIKOLAI",
    CITYNAME_HEB: "Nikolai",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NIC",
    AIRPORTNAME: "Nicosia",
    CITYCODE: "NIC",
    CITYNAME: "NICOSIA",
    CITYNAME_HEB: "\u05e0\u05d9\u05e7\u05d5\u05e1\u05d9\u05d4",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "NIE",
    AIRPORTNAME: " ",
    CITYCODE: "NIE",
    CITYNAME: "NIEDERAU",
    CITYNAME_HEB: "\u05e0\u05d9\u05d3\u05e8\u05d0\u05d5",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NIF",
    AIRPORTNAME: "Nifty",
    CITYCODE: "NIF",
    CITYNAME: "NIFTY",
    CITYNAME_HEB: "NIFTY",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NIG",
    AIRPORTNAME: "Nikunau",
    CITYCODE: "NIG",
    CITYNAME: "NIKUNAU",
    CITYNAME_HEB: "NIKUNAU",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "NIK",
    AIRPORTNAME: "Niokolo Koba",
    CITYCODE: "NIK",
    CITYNAME: "NIOKOLO KOBA",
    CITYNAME_HEB: "NIOKOLO KOBA",
    COUNTRYCODE: "SN",
    COUNTRYNAME: "SENEGAL",
    COUNTRYNAME_HEB: "\u05e1\u05e0\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "NIM",
    AIRPORTNAME: null,
    CITYCODE: "NIM",
    CITYNAME: "NIAMEY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NE",
    COUNTRYNAME: "NIGER",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NIN",
    AIRPORTNAME: "Ninilchik",
    CITYCODE: "NIN",
    CITYNAME: "NINILCHIK",
    CITYNAME_HEB: "Ninilchik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NIO",
    AIRPORTNAME: "Nioki",
    CITYCODE: "NIO",
    CITYNAME: "NIOKI",
    CITYNAME_HEB: "NIOKI",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "NIR",
    AIRPORTNAME: "Chase Field NAS",
    CITYCODE: "NIR",
    CITYNAME: "BEEVILLE",
    CITYNAME_HEB: "BEEVILLE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NIS",
    AIRPORTNAME: "Simberi",
    CITYCODE: "NIS",
    CITYNAME: "SIMBERI ISLAND",
    CITYNAME_HEB: "SIMBERI ISLAND",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NIT",
    AIRPORTNAME: "Niort",
    CITYCODE: "NIT",
    CITYNAME: "NIORT",
    CITYNAME_HEB: "NIORT",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "NIX",
    AIRPORTNAME: "Nioro",
    CITYCODE: "NIX",
    CITYNAME: "NIORO",
    CITYNAME_HEB: "NIORO",
    COUNTRYCODE: "ML",
    COUNTRYNAME: "MALI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d9",
  },
  {
    AIRPORTCODE: "NJA",
    AIRPORTNAME: "NAS",
    CITYCODE: "NJA",
    CITYNAME: "ATSUGI",
    CITYNAME_HEB: "ATSUGI",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "ACL",
    AIRPORTNAME: "Aguaclara",
    CITYCODE: "ACL",
    CITYNAME: "AGUACLARA",
    CITYNAME_HEB: "AGUACLARA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BAV",
    AIRPORTNAME: "Baotou",
    CITYCODE: "BAV",
    CITYNAME: "BAOTOU",
    CITYNAME_HEB: "BAOTOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "NJC",
    AIRPORTNAME: "Nizhnevartovsk",
    CITYCODE: "NJC",
    CITYNAME: "NIZHNEVARTOVSK",
    CITYNAME_HEB: "NIZHNEVARTOVSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NKA",
    AIRPORTNAME: "Nkan",
    CITYCODE: "NKA",
    CITYNAME: "NKAN",
    CITYNAME_HEB: "NKAN",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "NKB",
    AIRPORTNAME: "Noonkanbah",
    CITYCODE: "NKB",
    CITYNAME: "NOONKANBAH",
    CITYNAME_HEB: "NOONKANBAH",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NKC",
    AIRPORTNAME: "Nouakchott",
    CITYCODE: "NKC",
    CITYNAME: "NOUAKCHOTT",
    CITYNAME_HEB: "NOUAKCHOTT",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NKD",
    AIRPORTNAME: "Sinak",
    CITYCODE: "NKD",
    CITYNAME: "SINAK",
    CITYNAME_HEB: "SINAK",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NKG",
    AIRPORTNAME: "Nanking/Nanjing",
    CITYCODE: "NKG",
    CITYNAME: "NANJING",
    CITYNAME_HEB: "NANJING",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "NKI",
    CITYNAME: "ACHENKIRCH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NKL",
    AIRPORTNAME: "Nkolo",
    CITYCODE: "NKL",
    CITYNAME: "NKOLO",
    CITYNAME_HEB: "NKOLO",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "NKN",
    AIRPORTNAME: "Nankina",
    CITYCODE: "NKN",
    CITYNAME: "NANKINA",
    CITYNAME_HEB: "NANKINA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NKS",
    AIRPORTNAME: "Nkongsamba",
    CITYCODE: "NKS",
    CITYNAME: "NKONGSAMBA",
    CITYNAME_HEB: "NKONGSAMBA",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "BAW",
    AIRPORTNAME: " ",
    CITYCODE: "BAW",
    CITYNAME: "BAD WIESSE",
    CITYNAME_HEB: "\u05d1\u05d0\u05d3 \u05d5\u05d5\u05d9\u05d6",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NKU",
    AIRPORTNAME: "Nkaus",
    CITYCODE: "NKU",
    CITYNAME: "NKAUS",
    CITYNAME_HEB: "NKAUS",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "NKV",
    AIRPORTNAME: "Nichen Cove",
    CITYCODE: "NKV",
    CITYNAME: "NICHEN COVE",
    CITYNAME_HEB: "Nichen Cove",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NKY",
    AIRPORTNAME: "Nkayi",
    CITYCODE: "NKY",
    CITYNAME: "NKAYI",
    CITYNAME_HEB: "NKAYI",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "NLA",
    AIRPORTNAME: "Ndola",
    CITYCODE: "NLA",
    CITYNAME: "NDOLA",
    CITYNAME_HEB: "NDOLA",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NLC",
    AIRPORTNAME: "NAS/Reeves Field",
    CITYCODE: "NLC",
    CITYNAME: "LEMOORE",
    CITYNAME_HEB: "LEMOORE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NLD",
    AIRPORTNAME: "International Quetzalcoatl",
    CITYCODE: "NLD",
    CITYNAME: "NUEVO LAREDO",
    CITYNAME_HEB: "NUEVO LAREDO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "NLE",
    AIRPORTNAME: "Jerry Tyler Memorial",
    CITYCODE: "NLE",
    CITYNAME: "NILES",
    CITYNAME_HEB: "NILES",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NLF",
    AIRPORTNAME: "Darnley Island",
    CITYCODE: "NLF",
    CITYNAME: "DARNLEY ISLAND",
    CITYNAME_HEB: "DARNLEY ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NLG",
    AIRPORTNAME: "Nelson Lagoon",
    CITYCODE: "NLG",
    CITYNAME: "NELSON LAGOON",
    CITYNAME_HEB: "Nelson Lagoon",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NLK",
    AIRPORTNAME: "Norfolk Island",
    CITYCODE: "NLK",
    CITYNAME: "NORFOLK ISLAND",
    CITYNAME_HEB: "NORFOLK ISLAND",
    COUNTRYCODE: "NF",
    COUNTRYNAME: "NORFOLK ISLAND",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05e0\u05d5\u05e8\u05e4\u05dc\u05d5\u05e7",
  },
  {
    AIRPORTCODE: "BAX",
    AIRPORTNAME: "Barnaul",
    CITYCODE: "BAX",
    CITYNAME: "BARNAUL",
    CITYNAME_HEB: "BARNAUL",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NLL",
    AIRPORTNAME: "Nullagine",
    CITYCODE: "NLL",
    CITYNAME: "NULLAGINE",
    CITYNAME_HEB: "NULLAGINE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NLP",
    AIRPORTNAME: "Nelspruit",
    CITYCODE: "NLP",
    CITYNAME: "NELSPRUIT",
    CITYNAME_HEB: "NELSPRUIT",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "NLS",
    AIRPORTNAME: "Nicholson",
    CITYCODE: "NLS",
    CITYNAME: "NICHOLSON",
    CITYNAME_HEB: "NICHOLSON",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NLV",
    AIRPORTNAME: "Nikolaev",
    CITYCODE: "NLV",
    CITYNAME: "NIKOLAEV",
    CITYNAME_HEB: "NIKOLAEV",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "NMA",
    AIRPORTNAME: "Namangan",
    CITYCODE: "NMA",
    CITYNAME: "NAMANGAN",
    CITYNAME_HEB: "NAMANGAN",
    COUNTRYCODE: "UZ",
    COUNTRYNAME: "UZBEKISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d6\u05d1\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "NMB",
    AIRPORTNAME: "Daman",
    CITYCODE: "NMB",
    CITYNAME: "DAMAN",
    CITYNAME_HEB: "DAMAN",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "NMC",
    AIRPORTNAME: "Norman's Cay",
    CITYCODE: "NMC",
    CITYNAME: "NORMAN'S CAY",
    CITYNAME_HEB: "NORMAN'S CAY",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "NME",
    AIRPORTNAME: "Nightmute",
    CITYCODE: "NME",
    CITYNAME: "NIGHTMUTE",
    CITYNAME_HEB: "Nightmute",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NMG",
    AIRPORTNAME: "San Miguel",
    CITYCODE: "NMG",
    CITYNAME: "SAN MIGUEL",
    CITYNAME_HEB: "SAN MIGUEL",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "NMN",
    AIRPORTNAME: "Nomane",
    CITYCODE: "NMN",
    CITYNAME: "NOMANE",
    CITYNAME_HEB: "NOMANE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BAY",
    AIRPORTNAME: "Baia Mare",
    CITYCODE: "BAY",
    CITYNAME: "BAIA MARE",
    CITYNAME_HEB: "BAIA MARE",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NMP",
    AIRPORTNAME: "New Moon",
    CITYCODE: "NMP",
    CITYNAME: "NEW MOON",
    CITYNAME_HEB: "NEW MOON",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NMR",
    AIRPORTNAME: "Nappa Merry",
    CITYCODE: "NMR",
    CITYNAME: "NAPPA MERRY",
    CITYNAME_HEB: "NAPPA MERRY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NMS",
    AIRPORTNAME: "Namsang",
    CITYCODE: "NMS",
    CITYNAME: "NAMSANG",
    CITYNAME_HEB: "NAMSANG",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "NMT",
    AIRPORTNAME: "Namtu",
    CITYCODE: "NMT",
    CITYNAME: "NAMTU",
    CITYNAME_HEB: "NAMTU",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "NMU",
    AIRPORTNAME: "Namu",
    CITYCODE: "NMU",
    CITYNAME: "NAMU",
    CITYNAME_HEB: "NAMU",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "NNA",
    AIRPORTNAME: "NAF",
    CITYCODE: "NNA",
    CITYNAME: "KENITRA",
    CITYNAME_HEB: "KENITRA",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "NNB",
    AIRPORTNAME: "Santa Ana",
    CITYCODE: "NNB",
    CITYNAME: "SANTA ANA",
    CITYNAME_HEB: "SANTA ANA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "NND",
    AIRPORTNAME: "Nangade",
    CITYCODE: "NND",
    CITYNAME: "NANGADE",
    CITYNAME_HEB: "NANGADE",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "NNG",
    AIRPORTNAME: "Nanning",
    CITYCODE: "NNG",
    CITYNAME: "NANNING",
    CITYNAME_HEB: "NANNING",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "NNI",
    AIRPORTNAME: "Namutoni",
    CITYCODE: "NNI",
    CITYNAME: "NAMUTONI",
    CITYNAME_HEB: "NAMUTONI",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "BAZ",
    CITYNAME: "BAD ZURACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NNK",
    AIRPORTNAME: "Naknek",
    CITYCODE: "NNK",
    CITYNAME: "NAKNEK",
    CITYNAME_HEB: "Naknek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NNL",
    AIRPORTNAME: "Nondalton",
    CITYCODE: "NNL",
    CITYNAME: "NONDALTON",
    CITYNAME_HEB: "Nondalton",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NNM",
    AIRPORTNAME: "Naryan-Mar",
    CITYCODE: "NNM",
    CITYNAME: "NARYAN MAR",
    CITYNAME_HEB: "NARYAN MAR",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NNR",
    AIRPORTNAME: "Connemara",
    CITYCODE: "NNR",
    CITYNAME: "SPIDDAL",
    CITYNAME_HEB: "\u05e1\u05e4\u05d9\u05d3\u05d0\u05dc",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "NNT",
    AIRPORTNAME: "Nan",
    CITYCODE: "NNT",
    CITYNAME: "NAN",
    CITYNAME_HEB: "NAN",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "NNU",
    AIRPORTNAME: null,
    CITYCODE: "NNU",
    CITYNAME: "NANUQUE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NNX",
    AIRPORTNAME: "Nunukan",
    CITYCODE: "NNX",
    CITYNAME: "NUNUKAN",
    CITYNAME_HEB: "NUNUKAN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NNY",
    AIRPORTNAME: "Nanyang",
    CITYCODE: "NNY",
    CITYNAME: "NANYANG",
    CITYNAME_HEB: "NANYANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "NOA",
    AIRPORTNAME: "Nowra",
    CITYCODE: "NOA",
    CITYNAME: "NOWRA",
    CITYNAME_HEB: "NOWRA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BBA",
    AIRPORTNAME: "Teniente Vidal",
    CITYCODE: "BBA",
    CITYNAME: "BALMACEDA",
    CITYNAME_HEB: "BALMACEDA",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "NOC",
    AIRPORTNAME: "Knock International",
    CITYCODE: "NOC",
    CITYNAME: "KNOCK",
    CITYNAME_HEB: "KNOCK",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "NOD",
    AIRPORTNAME: "Norden",
    CITYCODE: "NOD",
    CITYNAME: "NORDEN",
    CITYNAME_HEB: "\u05e0\u05d5\u05e8\u05d3\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NOE",
    AIRPORTNAME: "Norddeich",
    CITYCODE: "NOE",
    CITYNAME: "NORDDEICH",
    CITYNAME_HEB: "\u05e0\u05d5\u05e8\u05d3\u05d9\u05da",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NOG",
    AIRPORTNAME: "Nogales",
    CITYCODE: "NOG",
    CITYNAME: "NOGALES",
    CITYNAME_HEB: "NOGALES",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "NOI",
    AIRPORTNAME: "Novorossijsk",
    CITYCODE: "NOI",
    CITYNAME: "NOVOROSSIJSK",
    CITYNAME_HEB: "NOVOROSSIJSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NOJ",
    AIRPORTNAME: "Nojabrxsk",
    CITYCODE: "NOJ",
    CITYNAME: "NOJABRXSK",
    CITYNAME_HEB: "NOJABRXSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NOK",
    AIRPORTNAME: null,
    CITYCODE: "NOK",
    CITYNAME: "NOVA XAVANTINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NOL",
    AIRPORTNAME: "Nakolik River",
    CITYCODE: "NOL",
    CITYNAME: "NAKOLIK RIVER",
    CITYNAME_HEB: "Nakolik River",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NOM",
    AIRPORTNAME: "Nomad River",
    CITYCODE: "NOM",
    CITYNAME: "NOMAD RIVER",
    CITYNAME_HEB: "NOMAD RIVER",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NON",
    AIRPORTNAME: "Nonouti",
    CITYCODE: "NON",
    CITYNAME: "NONOUTI",
    CITYNAME_HEB: "NONOUTI",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "BBB",
    AIRPORTNAME: "Municipal",
    CITYCODE: "BBB",
    CITYNAME: "BENSON",
    CITYNAME_HEB: "BENSON",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "NOO",
    CITYNAME: "NOORDWIJKERHOUT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NOR",
    AIRPORTNAME: "Nordfjordur",
    CITYCODE: "NOR",
    CITYNAME: "NORDFJORDUR",
    CITYNAME_HEB:
      "\u05d4\u05e4\u05d9\u05d5\u05e8\u05d3 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "NOS",
    AIRPORTNAME: "Fascene",
    CITYCODE: "NOS",
    CITYNAME: "NOSSI BE",
    CITYNAME_HEB: "NOSSI BE",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "NOT",
    AIRPORTNAME: "Novato",
    CITYCODE: "NOT",
    CITYNAME: "NOVATO",
    CITYNAME_HEB: "NOVATO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "GEA",
    AIRPORTNAME: "Magenta",
    CITYCODE: "NOU",
    CITYNAME: "NOUMEA",
    CITYNAME_HEB: "NOUMEA",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "NOU",
    AIRPORTNAME: "Tontouta",
    CITYCODE: "NOU",
    CITYNAME: "NOUMEA",
    CITYNAME_HEB: "NOUMEA",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "NOV",
    CITYNAME: "NOVI VINODOLSKI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NOZ",
    AIRPORTNAME: "Novokuznetsk",
    CITYCODE: "NOZ",
    CITYNAME: "NOVOKUZNETSK",
    CITYNAME_HEB: "NOVOKUZNETSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NPE",
    AIRPORTNAME: "Hawkes Bay",
    CITYCODE: "NPE",
    CITYNAME: "NAPIER",
    CITYNAME_HEB: "NAPIER",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BBC",
    AIRPORTNAME: "Bay City",
    CITYCODE: "BBC",
    CITYNAME: "BAY CITY",
    CITYNAME_HEB: "BAY CITY",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NPG",
    AIRPORTNAME: "Nipa",
    CITYCODE: "NPG",
    CITYNAME: "NIPA",
    CITYNAME_HEB: "NIPA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NPH",
    AIRPORTNAME: "Nephi",
    CITYCODE: "NPH",
    CITYNAME: "NEPHI",
    CITYNAME_HEB: "NEPHI",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NPL",
    AIRPORTNAME: "New Plymouth",
    CITYCODE: "NPL",
    CITYNAME: "NEW PLYMOUTH",
    CITYNAME_HEB: "NEW PLYMOUTH",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "NPO",
    AIRPORTNAME: "Nangapinoh",
    CITYCODE: "NPO",
    CITYNAME: "NANGAPINOH",
    CITYNAME_HEB: "NANGAPINOH",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NPP",
    AIRPORTNAME: "Napperby",
    CITYCODE: "NPP",
    CITYNAME: "NAPPERBY",
    CITYNAME_HEB: "NAPPERBY",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NPT",
    AIRPORTNAME: "State",
    CITYCODE: "NPT",
    CITYNAME: "NEWPORT",
    CITYNAME_HEB: "NEWPORT",
    COUNTRYCODE: "USRI",
    COUNTRYNAME: "RHODE ISLAND",
    COUNTRYNAME_HEB:
      '\u05e8\u05d5\u05d3 \u05d0\u05d9\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NPU",
    AIRPORTNAME: "San Pedro Uraba",
    CITYCODE: "NPU",
    CITYNAME: "SAN PEDRO URABA",
    CITYNAME_HEB: "SAN PEDRO URABA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NQI",
    AIRPORTNAME: "NAS",
    CITYCODE: "NQI",
    CITYNAME: "KINGSVILLE",
    CITYNAME_HEB: "KINGSVILLE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NQL",
    AIRPORTNAME: null,
    CITYCODE: "NQL",
    CITYNAME: "NIQUELANDIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NQN",
    AIRPORTNAME: null,
    CITYCODE: "NQN",
    CITYNAME: "NEUQUEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BBD",
    AIRPORTNAME: "Curtis Field",
    CITYCODE: "BBD",
    CITYNAME: "BRADY",
    CITYNAME_HEB: "BRADY",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EMA",
    AIRPORTNAME: "East Midlands",
    CITYCODE: "NQT",
    CITYNAME: "NOTTINGHAM",
    CITYNAME_HEB: "NOTTINGHAM",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NQT",
    AIRPORTNAME: "Nottingham Airport",
    CITYCODE: "NQT",
    CITYNAME: "NOTTINGHAM",
    CITYNAME_HEB: "NOTTINGHAM",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XNM",
    AIRPORTNAME: null,
    CITYCODE: "NQT",
    CITYNAME: "NOTTINGHAM",
    CITYNAME_HEB: "NOTTINGHAM",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XQH",
    AIRPORTNAME: null,
    CITYCODE: "NQT",
    CITYNAME: "NOTTINGHAM",
    CITYNAME_HEB: "NOTTINGHAM",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XQI",
    AIRPORTNAME: null,
    CITYCODE: "NQT",
    CITYNAME: "NOTTINGHAM",
    CITYNAME_HEB: "NOTTINGHAM",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZGB",
    AIRPORTNAME: null,
    CITYCODE: "NQT",
    CITYNAME: "NOTTINGHAM",
    CITYNAME_HEB: "NOTTINGHAM",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NQU",
    AIRPORTNAME: "Nuqui",
    CITYCODE: "NQU",
    CITYNAME: "NUQUI",
    CITYNAME_HEB: "NUQUI",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NQY",
    AIRPORTNAME: "St Mawgan",
    CITYCODE: "NQY",
    CITYNAME: "NEWQUAY",
    CITYNAME_HEB: "NEWQUAY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NRA",
    AIRPORTNAME: "Narrandera",
    CITYCODE: "NRA",
    CITYNAME: "NARRANDERA",
    CITYNAME_HEB: "NARRANDERA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NRB",
    AIRPORTNAME: "Ns",
    CITYCODE: "NRB",
    CITYNAME: "MAYPORT",
    CITYNAME_HEB: "MAYPORT",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BBE",
    AIRPORTNAME: "Big Bell",
    CITYCODE: "BBE",
    CITYNAME: "BIG BELL",
    CITYNAME_HEB: "BIG BELL",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NRC",
    AIRPORTNAME: "Aux Field",
    CITYCODE: "NRC",
    CITYNAME: "CROWS LANDING",
    CITYNAME_HEB: "CROWS LANDING",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NRD",
    AIRPORTNAME: "Norderney",
    CITYCODE: "NRD",
    CITYNAME: "NORDERNEY",
    CITYNAME_HEB: "\u05e0\u05d5\u05e8\u05d3\u05e8\u05e0\u05d9",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NRE",
    AIRPORTNAME: "Namrole",
    CITYCODE: "NRE",
    CITYNAME: "NAMROLE",
    CITYNAME_HEB: "NAMROLE",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NRG",
    AIRPORTNAME: "Narrogin",
    CITYCODE: "NRG",
    CITYNAME: "NARROGIN",
    CITYNAME_HEB: "NARROGIN",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NRI",
    AIRPORTNAME: "Shangri-la",
    CITYCODE: "NRI",
    CITYNAME: "SHANGRI LA",
    CITYNAME_HEB: "SHANGRI LA",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NRK",
    AIRPORTNAME: "Kungsangen",
    CITYCODE: "NRK",
    CITYNAME: "NORRKOPING",
    CITYNAME_HEB: "NORRKOPING",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XYK",
    AIRPORTNAME: null,
    CITYCODE: "NRK",
    CITYNAME: "NORRKOPING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NRL",
    AIRPORTNAME: "North Ronaldsay",
    CITYCODE: "NRL",
    CITYNAME: "NORTH RONALDSAY",
    CITYNAME_HEB: "NORTH RONALDSAY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NRM",
    AIRPORTNAME: "Nara",
    CITYCODE: "NRM",
    CITYNAME: "NARA",
    CITYNAME_HEB: "NARA",
    COUNTRYCODE: "ML",
    COUNTRYNAME: "MALI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d9",
  },
  {
    AIRPORTCODE: "NRQ",
    AIRPORTNAME: "N'Riquinha",
    CITYCODE: "NRQ",
    CITYNAME: "N'RIQUINHA",
    CITYNAME_HEB: "N'RIQUINHA",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "ACM",
    AIRPORTNAME: "Arica",
    CITYCODE: "ACM",
    CITYNAME: "ARICA",
    CITYNAME_HEB: "ARICA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BBF",
    AIRPORTNAME: "Burlington",
    CITYCODE: "BBF",
    CITYNAME: "BURLINGTON",
    CITYNAME_HEB: "BURLINGTON",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "NRR",
    AIRPORTNAME: "Roosevelt NAS",
    CITYCODE: "NRR",
    CITYNAME: "ROOSEVELT ROADS",
    CITYNAME_HEB: "ROOSEVELT ROADS",
    COUNTRYCODE: "PR",
    COUNTRYNAME: "PUERTO RICO",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e8\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "NRS",
    AIRPORTNAME: "NAS",
    CITYCODE: "NRS",
    CITYNAME: "IMPERIAL BEACH",
    CITYNAME_HEB: "IMPERIAL BEACH",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NRY",
    AIRPORTNAME: "Newry",
    CITYCODE: "NRY",
    CITYNAME: "NEWRY",
    CITYNAME_HEB: "NEWRY",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NSA",
    AIRPORTNAME: "Noosa",
    CITYCODE: "NSA",
    CITYNAME: "NOOSA",
    CITYNAME_HEB: "NOOSA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NSE",
    AIRPORTNAME: "Whiting Field NAS",
    CITYCODE: "NSE",
    CITYNAME: "MILTON",
    CITYNAME_HEB: "MILTON",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "NSF",
    CITYNAME: "NASSFELD",
    CITYNAME_HEB: "\u05e0\u05d0\u05e1\u05e4\u05dc\u05d3",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NSK",
    AIRPORTNAME: "Noril'sk",
    CITYCODE: "NSK",
    CITYNAME: "NORILSK",
    CITYNAME_HEB: "NORILSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NSM",
    AIRPORTNAME: "Norseman",
    CITYCODE: "NSM",
    CITYNAME: "NORSEMAN",
    CITYNAME_HEB: "NORSEMAN",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NSN",
    AIRPORTNAME: "Nelson",
    CITYCODE: "NSN",
    CITYNAME: "NELSON",
    CITYNAME_HEB: "\u05e0\u05dc\u05e1\u05d5\u05df",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "NSO",
    AIRPORTNAME: "Scone",
    CITYCODE: "NSO",
    CITYNAME: "SCONE",
    CITYNAME_HEB: "SCONE",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BBG",
    AIRPORTNAME: "Butaritari",
    CITYCODE: "BBG",
    CITYNAME: "BUTARITARI",
    CITYNAME_HEB: "BUTARITARI",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "NSP",
    AIRPORTNAME: "NAF",
    CITYCODE: "NSP",
    CITYNAME: "SANGLEY POINT",
    CITYNAME_HEB: "SANGLEY POINT",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "NST",
    AIRPORTNAME: "Nakhon Si Thammarat",
    CITYCODE: "NST",
    CITYNAME: "NAKON SI THAMMARAT",
    CITYNAME_HEB: "NAKON SI THAMMARAT",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "NSV",
    AIRPORTNAME: "Noosaville",
    CITYCODE: "NSV",
    CITYNAME: "NOOSAVILLE",
    CITYNAME_HEB: "NOOSAVILLE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NSX",
    AIRPORTNAME: "Hovercraft/Launch Pt",
    CITYCODE: "NSX",
    CITYNAME: "NORTH SOUND",
    CITYNAME_HEB: "NORTH SOUND",
    COUNTRYCODE: "VG",
    COUNTRYNAME: "VIRGIN ISLANDS-BRITISH",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05ea\u05d5\u05dc\u05d4 \u05d4\u05d1\u05e8\u05d9\u05d8\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "NSY",
    AIRPORTNAME: "Sigonella NAF",
    CITYCODE: "NSY",
    CITYNAME: "SIGONELLA",
    CITYNAME_HEB: "\u05e1\u05d9\u05d2\u05d5\u05e0\u05dc\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NTA",
    AIRPORTNAME: "Natadola",
    CITYCODE: "NTA",
    CITYNAME: "NATADOLA",
    CITYNAME_HEB: "NATADOLA",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "NTB",
    AIRPORTNAME: "Notodden",
    CITYCODE: "NTB",
    CITYNAME: "NOTODDEN",
    CITYNAME_HEB: "NOTODDEN",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NTC",
    AIRPORTNAME: "Santa Carolina",
    CITYCODE: "NTC",
    CITYNAME: "SANTA CAROLINA",
    CITYNAME_HEB: "SANTA CAROLINA",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "NTD",
    AIRPORTNAME: "Point Mugu NAS",
    CITYCODE: "NTD",
    CITYNAME: "PORT HUENEME",
    CITYNAME_HEB: "PORT HUENEME",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NTE",
    AIRPORTNAME: "Nantes Atlantique",
    CITYCODE: "NTE",
    CITYNAME: "NANTES",
    CITYNAME_HEB: "NANTES",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "BBH",
    AIRPORTNAME: "Barth",
    CITYCODE: "BBH",
    CITYNAME: "BARTH",
    CITYNAME_HEB: "BARTH",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QJZ",
    AIRPORTNAME: null,
    CITYCODE: "NTE",
    CITYNAME: "NANTES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NTG",
    AIRPORTNAME: "Nantong",
    CITYCODE: "NTG",
    CITYNAME: "NANTONG",
    CITYNAME_HEB: "NANTONG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "NTI",
    AIRPORTNAME: "Bintuni",
    CITYCODE: "NTI",
    CITYNAME: "BINTUNI",
    CITYNAME_HEB: "BINTUNI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NTJ",
    AIRPORTNAME: "Manti-Ephraim",
    CITYCODE: "NTJ",
    CITYNAME: "MANTI",
    CITYNAME_HEB: "MANTI",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BEO",
    AIRPORTNAME: "Belmont",
    CITYCODE: "NTL",
    CITYNAME: "NEWCASTLE",
    CITYNAME_HEB: "NEWCASTLE",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NTL",
    AIRPORTNAME: "Williamtown",
    CITYCODE: "NTL",
    CITYNAME: "NEWCASTLE",
    CITYNAME_HEB: "NEWCASTLE",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NTM",
    AIRPORTNAME: null,
    CITYCODE: "NTM",
    CITYNAME: "MIRACEMA DO NORTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NTN",
    AIRPORTNAME: "Normanton",
    CITYCODE: "NTN",
    CITYNAME: "NORMANTON",
    CITYNAME_HEB: "NORMANTON",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NTO",
    AIRPORTNAME: null,
    CITYCODE: "NTO",
    CITYNAME: "SANTO ANTAO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CV",
    COUNTRYNAME: "CAPE VERDE-REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NTT",
    AIRPORTNAME: "Kuini Lavenia",
    CITYCODE: "NTT",
    CITYNAME: "NIUATOPUTAPU",
    CITYNAME_HEB: "NIUATOPUTAPU",
    COUNTRYCODE: "TO",
    COUNTRYNAME: "TONGA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d2\u05d4",
  },
  {
    AIRPORTCODE: "BBI",
    AIRPORTNAME: " ",
    CITYCODE: "BBI",
    CITYNAME: "BOHINJSKA BISTRICA",
    CITYNAME_HEB:
      "\u05d1\u05d5\u05d0\u05d9\u05e0\u05e1\u05e7\u05d4 \u05d1\u05e1\u05d8\u05e8\u05d9\u05e6\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NTU",
    AIRPORTNAME: "NAS",
    CITYCODE: "NTU",
    CITYNAME: "OCEANA",
    CITYNAME_HEB: "OCEANA",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "NTX",
    AIRPORTNAME: "Natuna Ranai",
    CITYCODE: "NTX",
    CITYNAME: "NATUNA RANAI",
    CITYNAME_HEB: "NATUNA RANAI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NTY",
    AIRPORTNAME: "Pilansberg",
    CITYCODE: "NTY",
    CITYNAME: "SUN CITY",
    CITYNAME_HEB: "SUN CITY",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "NUB",
    AIRPORTNAME: "Numbulwar",
    CITYCODE: "NUB",
    CITYNAME: "NUMBULWAR",
    CITYNAME_HEB: "NUMBULWAR",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NUD",
    AIRPORTNAME: "En Nahud",
    CITYCODE: "NUD",
    CITYNAME: "EN NAHUD",
    CITYNAME_HEB: "EN NAHUD",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "NUE",
    AIRPORTNAME: " ",
    CITYCODE: "NUE",
    CITYNAME: "NUREMBERG",
    CITYNAME_HEB: "\u05e0\u05d9\u05e8\u05e0\u05d1\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NUG",
    AIRPORTNAME: "Nuguria",
    CITYCODE: "NUG",
    CITYNAME: "NUGURIA",
    CITYNAME_HEB: "NUGURIA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NUH",
    AIRPORTNAME: "Nunchia",
    CITYCODE: "NUH",
    CITYNAME: "NUNCHIA",
    CITYNAME_HEB: "NUNCHIA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NUI",
    AIRPORTNAME: "Nuiqsut",
    CITYCODE: "NUI",
    CITYNAME: "NUIQSUT",
    CITYNAME_HEB: "Nuiqsut",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BBJ",
    AIRPORTNAME: "Bitburg Air Base",
    CITYCODE: "BBJ",
    CITYNAME: "BITBURG",
    CITYNAME_HEB: "BITBURG",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NUK",
    AIRPORTNAME: "Nukutavake",
    CITYCODE: "NUK",
    CITYNAME: "NUKUTAVAKE",
    CITYNAME_HEB: "NUKUTAVAKE",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "NUL",
    AIRPORTNAME: "Nulato",
    CITYCODE: "NUL",
    CITYNAME: "NULATO",
    CITYNAME_HEB: "Nulato",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NUP",
    AIRPORTNAME: "Nunapitchuk",
    CITYCODE: "NUP",
    CITYNAME: "NUNAPITCHUK",
    CITYNAME_HEB: "Nunapitchuk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NUQ",
    AIRPORTNAME: "Moffett Field",
    CITYCODE: "NUQ",
    CITYNAME: "MOUNTAIN VIEW",
    CITYNAME_HEB: "MOUNTAIN VIEW",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NUR",
    AIRPORTNAME: "Nullarbor",
    CITYCODE: "NUR",
    CITYNAME: "NULLARBOR",
    CITYNAME_HEB: "NULLARBOR",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NUS",
    AIRPORTNAME: "Norsup",
    CITYCODE: "NUS",
    CITYNAME: "NORSUP",
    CITYNAME_HEB: "NORSUP",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "NUT",
    AIRPORTNAME: "Nutuve",
    CITYCODE: "NUT",
    CITYNAME: "NUTUVE",
    CITYNAME_HEB: "NUTUVE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NUU",
    AIRPORTNAME: "Nakuru",
    CITYCODE: "NUU",
    CITYNAME: "NAKURU",
    CITYNAME_HEB: "NAKURU",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NUW",
    AIRPORTNAME: "Ault Field",
    CITYCODE: "NUW",
    CITYNAME: "WHIDBEY ISLAND",
    CITYNAME_HEB: "WHIDBEY ISLAND",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NUX",
    AIRPORTNAME: "Novy Urengoy",
    CITYCODE: "NUX",
    CITYNAME: "NOVY URENGOV",
    CITYNAME_HEB: "NOVY URENGOV",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BBK",
    AIRPORTNAME: "Kasane",
    CITYCODE: "BBK",
    CITYNAME: "KASANE",
    CITYNAME_HEB: "KASANE",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "NVA",
    AIRPORTNAME: "La Marguita",
    CITYCODE: "NVA",
    CITYNAME: "NEIVA",
    CITYNAME_HEB: "NEIVA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NVD",
    AIRPORTNAME: "Nevada",
    CITYCODE: "NVD",
    CITYNAME: "NEVADA",
    CITYNAME_HEB: "\u05e0\u05d1\u05d3\u05d4",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NVG",
    AIRPORTNAME: "Nueva Guinea",
    CITYCODE: "NVG",
    CITYNAME: "NUEVA GUINEA",
    CITYNAME_HEB: "NUEVA GUINEA",
    COUNTRYCODE: "NI",
    COUNTRYNAME: "NICARAGUA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05e7\u05e8\u05d0\u05d2\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NVK",
    AIRPORTNAME: "Framnes",
    CITYCODE: "NVK",
    CITYNAME: "NARVIK",
    CITYNAME_HEB: "NARVIK",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NVP",
    AIRPORTNAME: null,
    CITYCODE: "NVP",
    CITYNAME: "NOVO ARIPUANA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NVR",
    AIRPORTNAME: "Novgorod",
    CITYCODE: "NVR",
    CITYNAME: "NOVGOROD",
    CITYNAME_HEB: "NOVGOROD",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NVS",
    AIRPORTNAME: "Nevers",
    CITYCODE: "NVS",
    CITYNAME: "NEVERS",
    CITYNAME_HEB: "NEVERS",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "NVT",
    AIRPORTNAME: null,
    CITYCODE: "NVT",
    CITYNAME: "NAVEGANTES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NVY",
    AIRPORTNAME: "Neyveli",
    CITYCODE: "NVY",
    CITYNAME: "NEYVELI",
    CITYNAME_HEB: "NEYVELI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "NWA",
    AIRPORTNAME: null,
    CITYCODE: "NWA",
    CITYNAME: "MOHELI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KM",
    COUNTRYNAME: "COMOROS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BBM",
    AIRPORTNAME: "Battambang",
    CITYCODE: "BBM",
    CITYNAME: "BATTAMBANG",
    CITYNAME_HEB: "BATTAMBANG",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NWH",
    AIRPORTNAME: "Parlin Field",
    CITYCODE: "NWH",
    CITYNAME: "NEWPORT",
    CITYNAME_HEB: "NEWPORT",
    COUNTRYCODE: "USNH",
    COUNTRYNAME: "NEW HAMPSHIRE",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d4\u05de\u05e4\u05e9\u05d9\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NWI",
    AIRPORTNAME: "Norwich Airport",
    CITYCODE: "NWI",
    CITYNAME: "NORWICH",
    CITYNAME_HEB: "NORWICH",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NWP",
    AIRPORTNAME: "Great Circle Airport NS",
    CITYCODE: "NWP",
    CITYNAME: "ARGENTIA",
    CITYNAME_HEB: "ARGENTIA (CA)",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "NWT",
    AIRPORTNAME: "Nowata",
    CITYCODE: "NWT",
    CITYNAME: "NOWATA",
    CITYNAME_HEB: "NOWATA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NXX",
    AIRPORTNAME: "Willow Grove NAS",
    CITYCODE: "NXX",
    CITYNAME: "WILLOW GROVE",
    CITYNAME_HEB: "WILLOW GROVE",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "EWR",
    AIRPORTNAME: "Newark International",
    CITYCODE: "NYC",
    CITYNAME: "NEW YORK",
    CITYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "NYC",
    AIRPORTNAME: " ",
    CITYCODE: "NYC",
    CITYNAME: "NEW YORK",
    CITYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "JFK",
    AIRPORTNAME: "John F Kennedy International",
    CITYCODE: "NYC",
    CITYNAME: "NEW YORK",
    CITYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "BBN",
    AIRPORTNAME: "Bario",
    CITYCODE: "BBN",
    CITYNAME: "BARIO",
    CITYNAME_HEB: "BARIO",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LGA",
    AIRPORTNAME: "La Guardia",
    CITYCODE: "NYC",
    CITYNAME: "NEW YORK",
    CITYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "BBO",
    AIRPORTNAME: "Berbera",
    CITYCODE: "BBO",
    CITYNAME: "BERBERA",
    CITYNAME_HEB: "BERBERA",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NYE",
    AIRPORTNAME: "Nyeri",
    CITYCODE: "NYE",
    CITYNAME: "NYERI",
    CITYNAME_HEB: "NYERI",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NYG",
    AIRPORTNAME: "Quantanico NAS",
    CITYCODE: "NYG",
    CITYNAME: "QUANTICO",
    CITYNAME_HEB: "QUANTICO",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "NYI",
    AIRPORTNAME: "Sunyani",
    CITYCODE: "NYI",
    CITYNAME: "SUNYANI",
    CITYNAME_HEB: "SUNYANI",
    COUNTRYCODE: "GH",
    COUNTRYNAME: "GHANA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "NYK",
    AIRPORTNAME: "Nanyuki",
    CITYCODE: "NYK",
    CITYNAME: "NANYUKI",
    CITYNAME_HEB: "NANYUKI",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NYM",
    AIRPORTNAME: "Nadym",
    CITYCODE: "NYM",
    CITYNAME: "NADYM",
    CITYNAME_HEB: "NADYM",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NYN",
    AIRPORTNAME: "Nyngan",
    CITYCODE: "NYN",
    CITYNAME: "NYNGAN",
    CITYNAME_HEB: "NYNGAN",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NYU",
    AIRPORTNAME: "NyaungU",
    CITYCODE: "NYU",
    CITYNAME: "NYAUNG U",
    CITYNAME_HEB: "NYAUNG U",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "NZA",
    AIRPORTNAME: "Nzagi",
    CITYCODE: "NZA",
    CITYNAME: "NZAGI",
    CITYNAME_HEB: "NZAGI",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "BBP",
    AIRPORTNAME: "Bembridge",
    CITYCODE: "BBP",
    CITYNAME: "BEMBRIDGE",
    CITYNAME_HEB: "BEMBRIDGE",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NZE",
    AIRPORTNAME: "Nzerekore",
    CITYCODE: "NZE",
    CITYNAME: "NZEREKORE",
    CITYNAME_HEB: "NZEREKORE",
    COUNTRYCODE: "GN",
    COUNTRYNAME: "GUINEA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "NZO",
    AIRPORTNAME: "Nzoia",
    CITYCODE: "NZO",
    CITYNAME: "NZOIA",
    CITYNAME_HEB: "NZOIA",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "NZR",
    CITYNAME: "Nazareth",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NZW",
    AIRPORTNAME: "South Weymouth",
    CITYCODE: "NZW",
    CITYNAME: "SOUTH WEYMOUTH",
    CITYNAME_HEB: "SOUTH WEYMOUTH",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "OAG",
    AIRPORTNAME: "Springhill",
    CITYCODE: "OAG",
    CITYNAME: "ORANGE SPRINGHILL",
    CITYNAME_HEB: "ORANGE SPRINGHILL",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OAJ",
    AIRPORTNAME: "Albert J Ellis",
    CITYCODE: "OAJ",
    CITYNAME: "JACKSONVILLE",
    CITYNAME_HEB: "JACKSONVILLE",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OAK",
    AIRPORTNAME: "Metropolitan Oak International",
    CITYCODE: "OAK",
    CITYNAME: "OAKLAND",
    CITYNAME_HEB: "\u05d0\u05d5\u05e7\u05dc\u05e0\u05d3",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JCE",
    AIRPORTNAME: null,
    CITYCODE: "OAK",
    CITYNAME: "OAKLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OAL",
    AIRPORTNAME: null,
    CITYCODE: "OAL",
    CITYNAME: "CACOAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OAM",
    AIRPORTNAME: "Oamaru",
    CITYCODE: "OAM",
    CITYNAME: "OAMARU",
    CITYNAME_HEB: "OAMARU",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "DRV",
    AIRPORTNAME: " ",
    CITYCODE: "DRV",
    CITYNAME: "DRUSKININKAI",
    CITYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05e1\u05e7\u05d9\u05e0\u05d9\u05e0\u05e7\u05d9",
    COUNTRYCODE: "LT",
    COUNTRYNAME: "LITHUANIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d8\u05d0",
  },
  {
    AIRPORTCODE: "ACN",
    AIRPORTNAME: "International",
    CITYCODE: "ACN",
    CITYNAME: "CIUDAD ACUNA",
    CITYNAME_HEB: "CIUDAD ACUNA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "BBQ",
    AIRPORTNAME: "Barbuda",
    CITYCODE: "BBQ",
    CITYNAME: "BARBUDA",
    CITYNAME_HEB: "\u05d1\u05e8\u05d1\u05d5\u05d3\u05d4",
    COUNTRYCODE: "AG",
    COUNTRYNAME: "ANTIGUA AND BARBUDA",
    COUNTRYNAME_HEB:
      "\u05d0\u05e0\u05d8\u05d9\u05d2\u05d5\u05d0\u05d4 \u05d5\u05d1\u05d5\u05e8\u05e0\u05d0\u05d5",
  },
  {
    AIRPORTCODE: "OAN",
    AIRPORTNAME: "Olanchito",
    CITYCODE: "OAN",
    CITYNAME: "OLANCHITO",
    CITYNAME_HEB: "OLANCHITO",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "OAX",
    AIRPORTNAME: "Xoxocotlan",
    CITYCODE: "OAX",
    CITYNAME: "OAXACA",
    CITYNAME_HEB: "OAXACA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "OBA",
    AIRPORTNAME: "Oban",
    CITYCODE: "OBA",
    CITYNAME: "OBAN",
    CITYNAME_HEB: "OBAN",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OBC",
    AIRPORTNAME: null,
    CITYCODE: "OBC",
    CITYNAME: "OBOCK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DJ",
    COUNTRYNAME: "DJIBOUTI",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OBD",
    AIRPORTNAME: "Obano",
    CITYCODE: "OBD",
    CITYNAME: "OBANO",
    CITYNAME_HEB: "OBANO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OBE",
    AIRPORTNAME: " ",
    CITYCODE: "OBE",
    CITYNAME: "OBERNAI",
    CITYNAME_HEB: "\u05d0\u05d5\u05d1\u05e8\u05e0\u05d4",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "OBF",
    AIRPORTNAME: "Oberpfaffenhofen",
    CITYCODE: "OBF",
    CITYNAME: "OBERPFAFFENHOFEN",
    CITYNAME_HEB: "OBERPFAFFENHOFEN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OBI",
    AIRPORTNAME: null,
    CITYCODE: "OBI",
    CITYNAME: "OBIDOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "OBK",
    AIRPORTNAME: "Sky Harbor",
    CITYCODE: "OBK",
    CITYNAME: "NORTHBROOK",
    CITYNAME_HEB: "NORTHBROOK",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OBL",
    AIRPORTNAME: "Zoersel",
    CITYCODE: "OBL",
    CITYNAME: "ZOERSEL",
    CITYNAME_HEB: "\u05d6\u05d5\u05e8\u05e1\u05dc",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BBR",
    AIRPORTNAME: "Baillif",
    CITYCODE: "BBR",
    CITYNAME: "BASSE TERRE",
    CITYNAME_HEB: "\u05d1\u05d0\u05e1 \u05d8\u05e8",
    COUNTRYCODE: "GP",
    COUNTRYNAME: "GUADELOUPE",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d2\u05d5\u05d5\u05d3\u05d0\u05dc\u05d5\u05e4",
  },
  {
    AIRPORTCODE: "MJK",
    AIRPORTNAME: "Shark Bay",
    CITYCODE: "MJK",
    CITYNAME: "MONKEY MIA",
    CITYNAME_HEB: "MONKEY MIA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AXE",
    AIRPORTNAME: null,
    CITYCODE: "AXE",
    CITYNAME: "XANXERE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MJL",
    AIRPORTNAME: "Mouila",
    CITYCODE: "MJL",
    CITYNAME: "MOUILA",
    CITYNAME_HEB: "MOUILA",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MJM",
    AIRPORTNAME: "Mbuji Mayi",
    CITYCODE: "MJM",
    CITYNAME: "MBUJI MAYI",
    CITYNAME_HEB: "MBUJI MAYI",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "MJN",
    AIRPORTNAME: "Amborovy",
    CITYCODE: "MJN",
    CITYNAME: "MAJUNGA",
    CITYNAME_HEB: "MAJUNGA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "MJO",
    AIRPORTNAME: "Mount Etjo Lodge",
    CITYCODE: "MJO",
    CITYNAME: "MOUNT ETJO LODGE",
    CITYNAME_HEB: "MOUNT ETJO LODGE",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MJP",
    AIRPORTNAME: "Manjimup",
    CITYCODE: "MJP",
    CITYNAME: "MANJIMUP",
    CITYNAME_HEB: "MANJIMUP",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MJQ",
    AIRPORTNAME: "Jackson",
    CITYCODE: "MJQ",
    CITYNAME: "JACKSON",
    CITYNAME_HEB: "JACKSON",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MJR",
    AIRPORTNAME: null,
    CITYCODE: "MJR",
    CITYNAME: "MIRAMAR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MJS",
    AIRPORTNAME: "Maganja Da Costa",
    CITYCODE: "MJS",
    CITYNAME: "MAGANJA DA COSTA",
    CITYNAME_HEB: "MAGANJA DA COSTA",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "MJT",
    AIRPORTNAME: "MYTILENE",
    CITYCODE: "MJT",
    CITYNAME: "LESVOS",
    CITYNAME_HEB: "\u05dc\u05e1\u05d1\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MJU",
    AIRPORTNAME: "Mamuju",
    CITYCODE: "MJU",
    CITYNAME: "MAMUJU",
    CITYNAME_HEB: "MAMUJU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AXG",
    AIRPORTNAME: "Algona",
    CITYCODE: "AXG",
    CITYNAME: "ALGONA",
    CITYNAME_HEB: "ALGONA",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MJV",
    AIRPORTNAME: "San Javier",
    CITYCODE: "MJV",
    CITYNAME: "MURCIA",
    CITYNAME_HEB: "MURCIA",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "MJW",
    AIRPORTNAME: "Mahenye",
    CITYCODE: "MJW",
    CITYNAME: "MAHENYE",
    CITYNAME_HEB: "MAHENYE",
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: "\u05d6\u05d9\u05de\u05d1\u05d1\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MJX",
    AIRPORTNAME: "Robert J. Miller",
    CITYCODE: "MJX",
    CITYNAME: "TOMS RIVER",
    CITYNAME_HEB: "TOMS RIVER",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MJY",
    AIRPORTNAME: "Mangunjaya",
    CITYCODE: "MJY",
    CITYNAME: "MANGUNJAYA",
    CITYNAME_HEB: "MANGUNJAYA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MJZ",
    AIRPORTNAME: "Mirnyj",
    CITYCODE: "MJZ",
    CITYNAME: "MIRNYJ",
    CITYNAME_HEB: "MIRNYJ",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MKA",
    AIRPORTNAME: "Marianske Lazne",
    CITYCODE: "MKA",
    CITYNAME: "MARIANSKE LAZNE",
    CITYNAME_HEB:
      "\u05de\u05e8\u05d9\u05e0\u05e1\u05e7\u05d4 \u05dc\u05e0\u05d6\u05d4",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MKB",
    AIRPORTNAME: "Mekambo",
    CITYCODE: "MKB",
    CITYNAME: "MEKAMBO",
    CITYNAME_HEB: "MEKAMBO",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "JCI",
    AIRPORTNAME: null,
    CITYCODE: "MKC",
    CITYNAME: "KANSAS CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MCI",
    AIRPORTNAME: "International",
    CITYCODE: "MKC",
    CITYNAME: "KANSAS CITY",
    CITYNAME_HEB: "\u05e7\u05e0\u05d6\u05e1 \u05e1\u05d9\u05d8\u05d9",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MKC",
    AIRPORTNAME: null,
    CITYCODE: "MKC",
    CITYNAME: "KANSAS CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AXK",
    AIRPORTNAME: "Ataq",
    CITYCODE: "AXK",
    CITYNAME: "ATAQ",
    CITYNAME_HEB: "ATAQ",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "OJC",
    AIRPORTNAME: null,
    CITYCODE: "MKC",
    CITYNAME: "KANSAS CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MKD",
    AIRPORTNAME: "Chagni",
    CITYCODE: "MKD",
    CITYNAME: "CHAGNI",
    CITYNAME_HEB: "CHAGNI",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MKE",
    AIRPORTNAME: "General Mitchell",
    CITYCODE: "MKE",
    CITYNAME: "MILWAUKEE",
    CITYNAME_HEB: "\u05de\u05d9\u05dc\u05d5\u05d5\u05e7\u05d9",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MWC",
    AIRPORTNAME: null,
    CITYCODE: "MKE",
    CITYNAME: "MILWAUKEE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZML",
    AIRPORTNAME: null,
    CITYCODE: "MKE",
    CITYNAME: "MILWAUKEE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MKG",
    AIRPORTNAME: "Muskegon",
    CITYCODE: "MKG",
    CITYNAME: "MUSKEGON",
    CITYNAME_HEB: "MUSKEGON",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MKH",
    AIRPORTNAME: "Mokhotlong",
    CITYCODE: "MKH",
    CITYNAME: "MOKHOTLONG",
    CITYNAME_HEB: "MOKHOTLONG",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "MKI",
    AIRPORTNAME: null,
    CITYCODE: "MKI",
    CITYNAME: "M'BOKI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MKJ",
    AIRPORTNAME: "Makoua",
    CITYCODE: "MKJ",
    CITYNAME: "MAKOUA",
    CITYNAME_HEB: "MAKOUA",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "MKK",
    AIRPORTNAME: "Molokai",
    CITYCODE: "MKK",
    CITYNAME: "HOOLEHUA",
    CITYNAME_HEB: "HOOLEHUA",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AXL",
    AIRPORTNAME: "Alexandria",
    CITYCODE: "AXL",
    CITYNAME: "ALEXANDRIA",
    CITYNAME_HEB: "ALEXANDRIA",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MKL",
    AIRPORTNAME: "Mckellar",
    CITYCODE: "MKL",
    CITYNAME: "JACKSON",
    CITYNAME_HEB: "JACKSON",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MKM",
    AIRPORTNAME: "Mukah",
    CITYCODE: "MKM",
    CITYNAME: "MUKAH",
    CITYNAME_HEB: "MUKAH",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MKN",
    AIRPORTNAME: "Malekolon",
    CITYCODE: "MKN",
    CITYNAME: "MALEKOLON",
    CITYNAME_HEB: "MALEKOLON",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "HAX",
    AIRPORTNAME: "Hatbox Field",
    CITYCODE: "MKO",
    CITYNAME: "MUSKOGEE",
    CITYNAME_HEB: "MUSKOGEE",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MKO",
    AIRPORTNAME: "Davis Field",
    CITYCODE: "MKO",
    CITYNAME: "MUSKOGEE",
    CITYNAME_HEB: "MUSKOGEE",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MKP",
    AIRPORTNAME: "Makemo",
    CITYCODE: "MKP",
    CITYNAME: "MAKEMO",
    CITYNAME_HEB: "MAKEMO",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "MKQ",
    AIRPORTNAME: "Mopah",
    CITYCODE: "MKQ",
    CITYNAME: "MERAUKE",
    CITYNAME_HEB: "MERAUKE",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MKR",
    AIRPORTNAME: "Meekatharra",
    CITYCODE: "MKR",
    CITYNAME: "MEEKATHARRA",
    CITYNAME_HEB: "MEEKATHARRA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MKS",
    AIRPORTNAME: "Mekane Selam",
    CITYCODE: "MKS",
    CITYNAME: "MEKANE SELAM",
    CITYNAME_HEB: "MEKANE SELAM",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JMN",
    AIRPORTNAME: null,
    CITYCODE: "MKT",
    CITYNAME: "MANKATO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AXM",
    AIRPORTNAME: "El Eden",
    CITYCODE: "AXM",
    CITYNAME: "ARMENIA",
    CITYNAME_HEB: "ARMENIA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MKT",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MKT",
    CITYNAME: "MANKATO",
    CITYNAME_HEB: "MANKATO",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MKU",
    AIRPORTNAME: "Makokou",
    CITYCODE: "MKU",
    CITYNAME: "MAKOKOU",
    CITYNAME_HEB: "MAKOKOU",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MKV",
    AIRPORTNAME: "Mt Cavenagh",
    CITYCODE: "MKV",
    CITYNAME: "MOUNT CAVENAGH",
    CITYNAME_HEB: "MOUNT CAVENAGH",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MKW",
    AIRPORTNAME: "Rendani",
    CITYCODE: "MKW",
    CITYNAME: "MANOKWARI",
    CITYNAME_HEB: "MANOKWARI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MKX",
    AIRPORTNAME: "Mukalla",
    CITYCODE: "MKX",
    CITYNAME: "MUKALLA",
    CITYNAME_HEB: "MUKALLA",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "MKY",
    AIRPORTNAME: "Mackay",
    CITYCODE: "MKY",
    CITYNAME: "MACKAY",
    CITYNAME_HEB: "MACKAY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MKZ",
    AIRPORTNAME: "Batu Berendum",
    CITYCODE: "MKZ",
    CITYNAME: "MALACCA",
    CITYNAME_HEB: "MALACCA",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MLA",
    AIRPORTNAME: "Luqa",
    CITYCODE: "MLA",
    CITYNAME: "MALTA",
    CITYNAME_HEB: "\u05de\u05dc\u05d8\u05d4",
    COUNTRYCODE: "MT",
    COUNTRYNAME: "MALTA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d8\u05d4",
  },
  {
    AIRPORTCODE: "MLB",
    AIRPORTNAME: "Melbourne International",
    CITYCODE: "MLB",
    CITYNAME: "MELBOURNE",
    CITYNAME_HEB: "MELBOURNE",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MLC",
    AIRPORTNAME: "Mc Alester",
    CITYCODE: "MLC",
    CITYNAME: "MC ALESTER",
    CITYNAME_HEB: "MC ALESTER",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AXN",
    AIRPORTNAME: "Alexandria",
    CITYCODE: "AXN",
    CITYNAME: "ALEXANDRIA",
    CITYNAME_HEB: "ALEXANDRIA (US)",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MLD",
    AIRPORTNAME: "Malad City",
    CITYCODE: "MLD",
    CITYNAME: "MALAD CITY",
    CITYNAME_HEB: "MALAD CITY",
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d9\u05d3\u05d4\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MLE",
    AIRPORTNAME: "International",
    CITYCODE: "MLE",
    CITYNAME: "MALE",
    CITYNAME_HEB:
      "\u05de\u05dc\u05d3\u05d9\u05d1\u05d9\u05d9\u05dd \u05de\u05d0\u05dc\u05d4",
    COUNTRYCODE: "MV",
    COUNTRYNAME: "MALDIVES ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d3\u05d9\u05d1\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "MLF",
    AIRPORTNAME: "Milford",
    CITYCODE: "MLF",
    CITYNAME: "MILFORD",
    CITYNAME_HEB: "MILFORD",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MLG",
    AIRPORTNAME: "Malang",
    CITYCODE: "MLG",
    CITYNAME: "MALANG",
    CITYNAME_HEB: "MALANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MLI",
    AIRPORTNAME: "Quad-City",
    CITYCODE: "MLI",
    CITYNAME: "MOLINE",
    CITYNAME_HEB: "MOLINE",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MLJ",
    AIRPORTNAME: "Baldwin County",
    CITYCODE: "MLJ",
    CITYNAME: "MILLEDGEVILLE",
    CITYNAME_HEB: "MILLEDGEVILLE",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MLK",
    AIRPORTNAME: "Malta",
    CITYCODE: "MLK",
    CITYNAME: "MALTA",
    CITYNAME_HEB: "MALTA (US)",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MLL",
    AIRPORTNAME: "Marshall",
    CITYCODE: "MLL",
    CITYNAME: "MARSHALL",
    CITYNAME_HEB: "Marshall",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MLM",
    AIRPORTNAME: "Morelia",
    CITYCODE: "MLM",
    CITYNAME: "MORELIA",
    CITYNAME_HEB: "MORELIA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "MLN",
    AIRPORTNAME: "Melilla",
    CITYCODE: "MLN",
    CITYNAME: "MELILLA",
    CITYNAME_HEB: "MELILLA",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "AXP",
    AIRPORTNAME: "Springpoint Airport",
    CITYCODE: "AXP",
    CITYNAME: "SPRING POINT",
    CITYNAME_HEB: "SPRING POINT",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MLO",
    AIRPORTNAME: "Milos",
    CITYCODE: "MLO",
    CITYNAME: "MILOS",
    CITYNAME_HEB: "MILOS",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MLP",
    AIRPORTNAME: "Malabang",
    CITYCODE: "MLP",
    CITYNAME: "MALABANG",
    CITYNAME_HEB: "MALABANG",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "MLQ",
    AIRPORTNAME: "Malalaua",
    CITYCODE: "MLQ",
    CITYNAME: "MALALAUA",
    CITYNAME_HEB: "MALALAUA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MLR",
    AIRPORTNAME: "Millicent",
    CITYCODE: "MLR",
    CITYNAME: "MILLICENT",
    CITYNAME_HEB: "MILLICENT",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MLS",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MLS",
    CITYNAME: "MILES CITY",
    CITYNAME_HEB: "MILES CITY",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MLT",
    AIRPORTNAME: "Millinocket",
    CITYCODE: "MLT",
    CITYNAME: "MILLINOCKET",
    CITYNAME_HEB: "MILLINOCKET",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MLU",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MLU",
    CITYNAME: "MONROE",
    CITYNAME_HEB: "MONROE",
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB:
      '\u05dc\u05d5\u05d0\u05d9\u05d6\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MLV",
    AIRPORTNAME: "Merluna",
    CITYCODE: "MLV",
    CITYNAME: "MERLUNA",
    CITYNAME_HEB: "MERLUNA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MLW",
    AIRPORTNAME: "Sprigg Payne",
    CITYCODE: "MLW",
    CITYNAME: "MONROVIA",
    CITYNAME_HEB: "MONROVIA",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MBV",
    AIRPORTNAME: "Masa",
    CITYCODE: "MBV",
    CITYNAME: "MASA",
    CITYNAME_HEB: "MASA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MBW",
    AIRPORTNAME: "Moorabbin",
    CITYCODE: "MBW",
    CITYNAME: "MOORABBIN",
    CITYNAME_HEB: "MOORABBIN",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MBX",
    AIRPORTNAME: "Maribor",
    CITYCODE: "MBX",
    CITYNAME: "MARIBOR",
    CITYNAME_HEB: "\u05de\u05d0\u05e8\u05d9\u05d1\u05d5\u05e8",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MBY",
    AIRPORTNAME: "Moberly",
    CITYCODE: "MBY",
    CITYNAME: "MOBERLY",
    CITYNAME_HEB: "MOBERLY",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AVU",
    AIRPORTNAME: "Avu Avu",
    CITYCODE: "AVU",
    CITYNAME: "AVU AVU",
    CITYNAME_HEB: "AVU AVU",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MBZ",
    AIRPORTNAME: null,
    CITYCODE: "MBZ",
    CITYNAME: "MAUES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MCA",
    AIRPORTNAME: "Macenta",
    CITYCODE: "MCA",
    CITYNAME: "MACENTA",
    CITYNAME_HEB: "MACENTA",
    COUNTRYCODE: "GN",
    COUNTRYNAME: "GUINEA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MCB",
    AIRPORTNAME: "Pike County",
    CITYCODE: "MCB",
    CITYNAME: "MCCOMB",
    CITYNAME_HEB: "MCCOMB",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MCD",
    AIRPORTNAME: " ",
    CITYCODE: "MCD",
    CITYNAME: "MACEDONIA",
    CITYNAME_HEB: "\u05de\u05e7\u05d3\u05d5\u05e0\u05d9\u05d4",
    COUNTRYCODE: "MK",
    COUNTRYNAME: "MACEDONIA",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05d3\u05d5\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MCE",
    AIRPORTNAME: " ",
    CITYCODE: "MCE",
    CITYNAME: "MILOCER",
    CITYNAME_HEB: "\u05de\u05d9\u05dc\u05d5\u05e7\u05e8",
    COUNTRYCODE: "ME",
    COUNTRYNAME: "MONTENEGRO",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "MCG",
    AIRPORTNAME: "Mcgrath",
    CITYCODE: "MCG",
    CITYNAME: "MCGRATH",
    CITYNAME_HEB: "Mcgrath",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MCH",
    AIRPORTNAME: "Machala",
    CITYCODE: "MCH",
    CITYNAME: "MACHALA",
    CITYNAME_HEB: "MACHALA",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "MCJ",
    AIRPORTNAME: "Maicao",
    CITYCODE: "MCJ",
    CITYNAME: "MAICAO",
    CITYNAME_HEB: "MAICAO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MCK",
    AIRPORTNAME: "Mccook",
    CITYCODE: "MCK",
    CITYNAME: "MCCOOK",
    CITYNAME_HEB: "MCCOOK",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AVV",
    AIRPORTNAME: "Avalon",
    CITYCODE: "AVV",
    CITYNAME: "AVALON",
    CITYNAME_HEB: "AVALON",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MCL",
    AIRPORTNAME: "Mt Mckinley",
    CITYCODE: "MCL",
    CITYNAME: "MOUNT MCKINLEY",
    CITYNAME_HEB: "Mt Mckinley",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MCM",
    AIRPORTNAME: " ",
    CITYCODE: "MCM",
    CITYNAME: "MONTE CARLO",
    CITYNAME_HEB:
      "\u05de\u05d5\u05e0\u05d8\u05d4 \u05e7\u05d0\u05e8\u05dc\u05d5",
    COUNTRYCODE: "MC",
    COUNTRYNAME: "MONACO",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d0\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "MCN",
    AIRPORTNAME: "Lewis B Wilson",
    CITYCODE: "MCN",
    CITYNAME: "MACON",
    CITYNAME_HEB: "MACON",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "QMP",
    AIRPORTNAME: null,
    CITYCODE: "MCN",
    CITYNAME: "MACON",
    CITYNAME_HEB: "MACON",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "WRB",
    AIRPORTNAME: null,
    CITYCODE: "MCN",
    CITYNAME: "MACON",
    CITYNAME_HEB: "MACON",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MCP",
    AIRPORTNAME: null,
    CITYCODE: "MCP",
    CITYNAME: "MACAPA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MCQ",
    AIRPORTNAME: "Miskolc",
    CITYCODE: "MCQ",
    CITYNAME: "MISKOLC",
    CITYNAME_HEB: "MISKOLC",
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MCR",
    AIRPORTNAME: "Melchor De Menco",
    CITYCODE: "MCR",
    CITYNAME: "MELCHOR DE MENCO",
    CITYNAME_HEB: "MELCHOR DE MENCO",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "MCS",
    AIRPORTNAME: null,
    CITYCODE: "MCS",
    CITYNAME: "MONTE CASEROS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AVX",
    AIRPORTNAME: "Avalon Bay",
    CITYCODE: "AVX",
    CITYNAME: "CATALINA ISLAND",
    CITYNAME_HEB: "CATALINA ISLAND",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MCT",
    AIRPORTNAME: null,
    CITYCODE: "MCT",
    CITYNAME: "MUSCAT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "OM",
    COUNTRYNAME: "OMAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MCU",
    AIRPORTNAME: "Gueret (Lepaud)",
    CITYCODE: "MCU",
    CITYNAME: "MONTLUCON",
    CITYNAME_HEB: "MONTLUCON",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "MCV",
    AIRPORTNAME: "Mcarthur River",
    CITYCODE: "MCV",
    CITYNAME: "MCARTHUR RIVER",
    CITYNAME_HEB: "MCARTHUR RIVER",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MCW",
    AIRPORTNAME: "Mason City",
    CITYCODE: "MCW",
    CITYNAME: "MASON CITY",
    CITYNAME_HEB: "MASON CITY",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MCX",
    AIRPORTNAME: "Makhachkala",
    CITYCODE: "MCX",
    CITYNAME: "MAKHACHKALA",
    CITYNAME_HEB: "MAKHACHKALA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MCY",
    AIRPORTNAME: "Maroochydore",
    CITYCODE: "MCY",
    CITYNAME: "SUNSHINE COAST",
    CITYNAME_HEB: "SUNSHINE COAST",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MCZ",
    AIRPORTNAME: null,
    CITYCODE: "MCZ",
    CITYNAME: "MACEIO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MDB",
    AIRPORTNAME: "Melinda",
    CITYCODE: "MDB",
    CITYNAME: "MELINDA",
    CITYNAME_HEB: "MELINDA",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "MDC",
    AIRPORTNAME: " ",
    CITYCODE: "MDC",
    CITYNAME: "MADDONA DI CAMPIGLIO",
    CITYNAME_HEB:
      "\u05de\u05d3\u05d5\u05e0\u05d4 \u05d3\u05d9 \u05e7\u05d0\u05de\u05e4\u05dc\u05d9\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EOH",
    AIRPORTNAME: "Enrique Olaya Herrera",
    CITYCODE: "MDE",
    CITYNAME: "MEDELLIN",
    CITYNAME_HEB: "MEDELLIN",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MDE",
    AIRPORTNAME: "Jose Marie Cordova",
    CITYCODE: "MDE",
    CITYNAME: "MEDELLIN",
    CITYNAME_HEB: "MEDELLIN",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MDF",
    AIRPORTNAME: "Medford",
    CITYCODE: "MDF",
    CITYNAME: "MEDFORD",
    CITYNAME_HEB: "MEDFORD",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MDG",
    AIRPORTNAME: "Mudanjiang",
    CITYCODE: "MDG",
    CITYNAME: "MUDANJIANG",
    CITYNAME_HEB: "MUDANJIANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "MDH",
    AIRPORTNAME: "Southern Illinois",
    CITYCODE: "MDH",
    CITYNAME: "CARBONDALE",
    CITYNAME_HEB: "CARBONDALE",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MDI",
    AIRPORTNAME: "Makurdi",
    CITYCODE: "MDI",
    CITYNAME: "MAKURDI",
    CITYNAME_HEB: "MAKURDI",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MDJ",
    AIRPORTNAME: "City-county",
    CITYCODE: "MDJ",
    CITYNAME: "MADRAS",
    CITYNAME_HEB: "MADRAS",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MDK",
    AIRPORTNAME: "Mbandaka",
    CITYCODE: "MDK",
    CITYNAME: "MBANDAKA",
    CITYNAME_HEB: "MBANDAKA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "MDL",
    AIRPORTNAME: "Annisaton",
    CITYCODE: "MDL",
    CITYNAME: "MANDALAY",
    CITYNAME_HEB: "\u05de\u05e0\u05d3\u05dc\u05d9\u05d9",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MDM",
    AIRPORTNAME: "Munduku",
    CITYCODE: "MDM",
    CITYNAME: "MUNDUKU",
    CITYNAME_HEB: "MUNDUKU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MDN",
    AIRPORTNAME: "Jefferson Proving Grd",
    CITYCODE: "MDN",
    CITYNAME: "MADISON",
    CITYNAME_HEB: "MADISON",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MDO",
    AIRPORTNAME: "Middleton Island",
    CITYCODE: "MDO",
    CITYNAME: "MIDDLETON ISLAND",
    CITYNAME_HEB: "Middleton Island",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MDP",
    AIRPORTNAME: "Mindiptana",
    CITYCODE: "MDP",
    CITYNAME: "MINDIPTANA",
    CITYNAME_HEB: "MINDIPTANA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MDQ",
    AIRPORTNAME: null,
    CITYCODE: "MDQ",
    CITYNAME: "MAR DEL PLATA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MDR",
    AIRPORTNAME: "Medfra",
    CITYCODE: "MDR",
    CITYNAME: "MEDFRA",
    CITYNAME_HEB: "Medfra",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MDS",
    AIRPORTNAME: null,
    CITYCODE: "MDS",
    CITYNAME: "MIDDLE CAICOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TC",
    COUNTRYNAME: "TURKS AND CAICOS ISLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MDU",
    AIRPORTNAME: "Mendi",
    CITYCODE: "MDU",
    CITYNAME: "MENDI",
    CITYNAME_HEB: "MENDI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MDV",
    AIRPORTNAME: "Medouneu",
    CITYCODE: "MDV",
    CITYNAME: "MEDOUNEU",
    CITYNAME_HEB: "MEDOUNEU",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MDX",
    AIRPORTNAME: null,
    CITYCODE: "MDX",
    CITYNAME: "MERCEDES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MDY",
    AIRPORTNAME: null,
    CITYCODE: "MDY",
    CITYNAME: "MIDWAY ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "UM",
    COUNTRYNAME: "U.S. MINOR OUTLYING ISLA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MDZ",
    AIRPORTNAME: null,
    CITYCODE: "MDZ",
    CITYNAME: "MENDOZA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TWH",
    AIRPORTNAME: null,
    CITYCODE: "AVX",
    CITYNAME: "CATALINA ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MEA",
    AIRPORTNAME: null,
    CITYCODE: "MEA",
    CITYNAME: "MACAE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MEC",
    AIRPORTNAME: "Manta",
    CITYCODE: "MEC",
    CITYNAME: "MANTA",
    CITYNAME_HEB: "MANTA",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "MEE",
    AIRPORTNAME: "Mare",
    CITYCODE: "MEE",
    CITYNAME: "MARE",
    CITYNAME_HEB: "MARE",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "MEF",
    AIRPORTNAME: "Melfi",
    CITYCODE: "MEF",
    CITYNAME: "MELFI",
    CITYNAME_HEB: "MELFI",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "MEG",
    AIRPORTNAME: "Malange",
    CITYCODE: "MEG",
    CITYNAME: "MALANGE",
    CITYNAME_HEB: "MALANGE",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "MEH",
    AIRPORTNAME: "Mehamn",
    CITYCODE: "MEH",
    CITYNAME: "MEHAMN",
    CITYNAME_HEB: "MEHAMN",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MEI",
    AIRPORTNAME: " ",
    CITYCODE: "MEI",
    CITYNAME: "MEIRINGEN",
    CITYNAME_HEB: "\u05de\u05d0\u05d9\u05e8\u05d9\u05e0\u05d2\u05df",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "MEJ",
    AIRPORTNAME: "Meadville",
    CITYCODE: "MEJ",
    CITYNAME: "MEADVILLE",
    CITYNAME_HEB: "MEADVILLE",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MEK",
    AIRPORTNAME: "Meknes",
    CITYCODE: "MEK",
    CITYNAME: "MEKNES",
    CITYNAME_HEB: "\u05de\u05e7\u05e0\u05e1",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "MEL",
    AIRPORTNAME: "Tullamarine",
    CITYCODE: "MEL",
    CITYNAME: "MELBOURNE",
    CITYNAME_HEB: "\u05de\u05dc\u05d1\u05d5\u05e8\u05df",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "AUSTRLIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AWA",
    AIRPORTNAME: "Awassa",
    CITYCODE: "AWA",
    CITYNAME: "AWASSA",
    CITYNAME_HEB: "\u05d0\u05d5\u05d5\u05d0\u05e1\u05d4",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MEM",
    AIRPORTNAME: "International",
    CITYCODE: "MEM",
    CITYNAME: "MEMPHIS",
    CITYNAME_HEB: "\u05de\u05de\u05e4\u05d9\u05e1 - \u05d8\u05e0\u05e1\u05d9",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "MEN",
    AIRPORTNAME: "Brenoux",
    CITYCODE: "MEN",
    CITYNAME: "MENDE",
    CITYNAME_HEB: "MENDE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "MEO",
    AIRPORTNAME: " ",
    CITYCODE: "MEO",
    CITYNAME: "METSOVO AREA",
    CITYNAME_HEB: "\u05de\u05d8\u05e1\u05d5\u05d1\u05d5",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MEP",
    AIRPORTNAME: "Mersing",
    CITYCODE: "MEP",
    CITYNAME: "MERSING",
    CITYNAME_HEB: "MERSING",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MEQ",
    AIRPORTNAME: "Seunagan",
    CITYCODE: "MEQ",
    CITYNAME: "MEULABOH",
    CITYNAME_HEB: "MEULABOH",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MES",
    AIRPORTNAME: " ",
    CITYCODE: "MES",
    CITYNAME: "MESTIA",
    CITYNAME_HEB: "\u05de\u05e1\u05d8\u05d9\u05d4",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ACB",
    AIRPORTNAME: "Bellaire Antrim County",
    CITYCODE: "ACB",
    CITYNAME: "BELLAIRE",
    CITYNAME_HEB: "Bellaire Antrim County",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AWB",
    AIRPORTNAME: "Awaba",
    CITYCODE: "AWB",
    CITYNAME: "AWABA",
    CITYNAME_HEB: "AWABA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MET",
    AIRPORTNAME: "Moreton",
    CITYCODE: "MET",
    CITYNAME: "MORETON",
    CITYNAME_HEB: "MORETON",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MEU",
    AIRPORTNAME: null,
    CITYCODE: "MEU",
    CITYNAME: "MONTE DOURADO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MEV",
    AIRPORTNAME: "Douglas County",
    CITYCODE: "MEV",
    CITYNAME: "MINDEN",
    CITYNAME_HEB: "MINDEN",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MEW",
    AIRPORTNAME: "Mweka",
    CITYCODE: "MEW",
    CITYNAME: "MWEKA",
    CITYNAME_HEB: "MWEKA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "MEX",
    AIRPORTNAME: "Juarez International",
    CITYCODE: "MEX",
    CITYNAME: "MEXICO CITY",
    CITYNAME_HEB:
      "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5 \u05e1\u05d9\u05d8\u05d9",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "MEY",
    AIRPORTNAME: "Meghauli",
    CITYCODE: "MEY",
    CITYNAME: "MEGHAULI",
    CITYNAME_HEB: "MEGHAULI",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "MEZ",
    AIRPORTNAME: "Messina",
    CITYCODE: "MEZ",
    CITYNAME: "MESSINA",
    CITYNAME_HEB: "MESSINA",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "AWD",
    AIRPORTNAME: "Aniwa",
    CITYCODE: "AWD",
    CITYNAME: "ANIWA",
    CITYNAME_HEB: "ANIWA",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "MFA",
    AIRPORTNAME: "Mafia",
    CITYCODE: "MFA",
    CITYNAME: "MAFIA",
    CITYNAME_HEB: "MAFIA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MFB",
    AIRPORTNAME: "Monfort",
    CITYCODE: "MFB",
    CITYNAME: "MONFORT",
    CITYNAME_HEB: "MONFORT",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MFC",
    AIRPORTNAME: "Mafeteng",
    CITYCODE: "MFC",
    CITYNAME: "MAFETENG",
    CITYNAME_HEB: "MAFETENG",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "MFD",
    AIRPORTNAME: "Lahm Municipal",
    CITYCODE: "MFD",
    CITYNAME: "MANSFIELD",
    CITYNAME_HEB: "MANSFIELD",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MFE",
    AIRPORTNAME: "Miller International",
    CITYCODE: "MFE",
    CITYNAME: "MCALLEN",
    CITYNAME_HEB: "MCALLEN",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MFF",
    AIRPORTNAME: "Moanda",
    CITYCODE: "MFF",
    CITYNAME: "MOANDA",
    CITYNAME_HEB: "MOANDA",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MFG",
    AIRPORTNAME: "Muzaffarabad",
    CITYCODE: "MFG",
    CITYNAME: "MUZAFFARABAD",
    CITYNAME_HEB: "MUZAFFARABAD",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "MFH",
    AIRPORTNAME: "Mesquite",
    CITYCODE: "MFH",
    CITYNAME: "MESQUITE",
    CITYNAME_HEB: "MESQUITE",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MFI",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MFI",
    CITYNAME: "MARSHFIELD",
    CITYNAME_HEB: "MARSHFIELD",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MFJ",
    AIRPORTNAME: "Moala",
    CITYCODE: "MFJ",
    CITYNAME: "MOALA",
    CITYNAME_HEB: "MOALA",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "AWE",
    AIRPORTNAME: "Alowe",
    CITYCODE: "AWE",
    CITYNAME: "ALOWE",
    CITYNAME_HEB: "ALOWE",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MFK",
    AIRPORTNAME: "Matsu",
    CITYCODE: "MFK",
    CITYNAME: "MATSU",
    CITYNAME_HEB: "\u05de\u05d0\u05d8\u05e1\u05d5",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "MFL",
    AIRPORTNAME: "Mount Full Stop",
    CITYCODE: "MFL",
    CITYNAME: "MOUNT FULL STOP",
    CITYNAME_HEB: "MOUNT FULL STOP",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MFM",
    AIRPORTNAME: "Macau",
    CITYCODE: "MFM",
    CITYNAME: "MACAU",
    CITYNAME_HEB: "\u05de\u05e7\u05d0\u05d5",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "MFN",
    AIRPORTNAME: "Milford Sound",
    CITYCODE: "MFN",
    CITYNAME: "MILFORD SOUND",
    CITYNAME_HEB: "MILFORD SOUND",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "MFO",
    AIRPORTNAME: "Manguna",
    CITYCODE: "MFO",
    CITYNAME: "MANGUNA",
    CITYNAME_HEB: "MANGUNA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MFP",
    AIRPORTNAME: "Manners Creek",
    CITYCODE: "MFP",
    CITYNAME: "MANNERS CREEK",
    CITYNAME_HEB: "MANNERS CREEK",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MFQ",
    AIRPORTNAME: null,
    CITYCODE: "MFQ",
    CITYNAME: "MARADI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NE",
    COUNTRYNAME: "NIGER",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MFR",
    AIRPORTNAME: "Jackson County",
    CITYCODE: "MFR",
    CITYNAME: "MEDFORD",
    CITYNAME_HEB: "MEDFORD",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MFS",
    AIRPORTNAME: "Miraflores",
    CITYCODE: "MFS",
    CITYNAME: "MIRAFLORES",
    CITYNAME_HEB: "MIRAFLORES",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MFT",
    AIRPORTNAME: "Machu Picchu",
    CITYCODE: "MFT",
    CITYNAME: "MACHU PICCHU",
    CITYNAME_HEB: "\u05de\u05d0\u05e6\u05d5 \u05e4\u05d9\u05e6\u05d5",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "AWH",
    AIRPORTNAME: "Awareh",
    CITYCODE: "AWH",
    CITYNAME: "AWAREH",
    CITYNAME_HEB: "AWAREH",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MFU",
    AIRPORTNAME: "Mfuwe",
    CITYCODE: "MFU",
    CITYNAME: "MFUWE",
    CITYNAME_HEB: "MFUWE",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MFV",
    AIRPORTNAME: "Accomack County",
    CITYCODE: "MFV",
    CITYNAME: "MELFA",
    CITYNAME_HEB: "MELFA",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MFW",
    AIRPORTNAME: "Magaruque",
    CITYCODE: "MFW",
    CITYNAME: "MAGARUQUE",
    CITYNAME_HEB: "MAGARUQUE",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "MFY",
    AIRPORTNAME: "Mayfa'ah",
    CITYCODE: "MFY",
    CITYNAME: "MAYFA'AH",
    CITYNAME_HEB: "MAYFA'AH",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "MFZ",
    AIRPORTNAME: "Mesalia",
    CITYCODE: "MFZ",
    CITYNAME: "MESELIA",
    CITYNAME_HEB: "MESELIA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MGA",
    AIRPORTNAME: "Augusto C Sandino",
    CITYCODE: "MGA",
    CITYNAME: "MANAGUA",
    CITYNAME_HEB: "\u05de\u05e0\u05d2\u05d5\u05d0\u05d4",
    COUNTRYCODE: "NI",
    COUNTRYNAME: "NICARAGUA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05e7\u05e8\u05d0\u05d2\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MGB",
    AIRPORTNAME: "Mount Gambier",
    CITYCODE: "MGB",
    CITYNAME: "MOUNT GAMBIER",
    CITYNAME_HEB: "MOUNT GAMBIER",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MGC",
    AIRPORTNAME: "Michigan City Airport",
    CITYCODE: "MGC",
    CITYNAME: "MICHIGAN CITY",
    CITYNAME_HEB:
      "\u05de\u05d9\u05e9\u05d9\u05d2\u05df \u05e1\u05d9\u05d8\u05d9",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MGD",
    AIRPORTNAME: "Magdalena",
    CITYCODE: "MGD",
    CITYNAME: "MAGDALENA",
    CITYNAME_HEB: "MAGDALENA",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AWK",
    AIRPORTNAME: null,
    CITYCODE: "AWK",
    CITYNAME: "WAKE ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "UM",
    COUNTRYNAME: "U.S. MINOR OUTLYING ISLA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MGE",
    AIRPORTNAME: null,
    CITYCODE: "MGE",
    CITYNAME: "MARIETTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NCQ",
    AIRPORTNAME: "Atlanta NAS",
    CITYCODE: "MGE",
    CITYNAME: "MARIETTA",
    CITYNAME_HEB: "MARIETTA",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MGF",
    AIRPORTNAME: null,
    CITYCODE: "MGF",
    CITYNAME: "MARINGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MGG",
    AIRPORTNAME: "Margarima",
    CITYCODE: "MGG",
    CITYNAME: "MARGARIMA",
    CITYNAME_HEB: "MARGARIMA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MGH",
    AIRPORTNAME: "Margate",
    CITYCODE: "MGH",
    CITYNAME: "MARGATE",
    CITYNAME_HEB: "MARGATE",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "MGI",
    AIRPORTNAME: "Matagorda AFB",
    CITYCODE: "MGI",
    CITYNAME: "MATAGORDA ISL",
    CITYNAME_HEB: "MATAGORDA ISL",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MGJ",
    AIRPORTNAME: "Orange County",
    CITYCODE: "MGJ",
    CITYNAME: "MONTGOMERY",
    CITYNAME_HEB: "MONTGOMERY",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MGK",
    AIRPORTNAME: "Mong Ton",
    CITYCODE: "MGK",
    CITYNAME: "MONG TON",
    CITYNAME_HEB: "MONG TON",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "GUN",
    AIRPORTNAME: "Gunter AFB",
    CITYCODE: "MGM",
    CITYNAME: "MONTGOMERY",
    CITYNAME_HEB: "Montgomery",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MGM",
    AIRPORTNAME: "Dannelly Fld",
    CITYCODE: "MGM",
    CITYNAME: "MONTGOMERY",
    CITYNAME_HEB: "Montgomery",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AWM",
    AIRPORTNAME: "Municipal",
    CITYCODE: "AWM",
    CITYNAME: "WEST MEMPHIS",
    CITYNAME_HEB: "WEST MEMPHIS",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MXF",
    AIRPORTNAME: "Maxwell AFB",
    CITYCODE: "MGM",
    CITYNAME: "MONTGOMERY",
    CITYNAME_HEB: "Montgomery",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MGN",
    AIRPORTNAME: "Baracoa",
    CITYCODE: "MGN",
    CITYNAME: "MAGANGUE",
    CITYNAME_HEB: "MAGANGUE",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MGO",
    AIRPORTNAME: "Manega",
    CITYCODE: "MGO",
    CITYNAME: "MANEGA",
    CITYNAME_HEB: "MANEGA",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MGP",
    AIRPORTNAME: "Manga",
    CITYCODE: "MGP",
    CITYNAME: "MANGA",
    CITYNAME_HEB: "MANGA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MGQ",
    AIRPORTNAME: "International",
    CITYCODE: "MGQ",
    CITYNAME: "MOGADISHU",
    CITYNAME_HEB: "\u05de\u05d5\u05d2\u05d3\u05d9\u05e9\u05d5",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MGR",
    AIRPORTNAME: null,
    CITYCODE: "MGR",
    CITYNAME: "MOULTRIE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MGS",
    AIRPORTNAME: "Mangaia Island",
    CITYCODE: "MGS",
    CITYNAME: "MANGAIA ISLAND",
    CITYNAME_HEB: "MANGAIA ISLAND",
    COUNTRYCODE: "CK",
    COUNTRYNAME: "COOK ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e7\u05d5\u05e7",
  },
  {
    AIRPORTCODE: "MGT",
    AIRPORTNAME: "Milingimbi",
    CITYCODE: "MGT",
    CITYNAME: "MILINGIMBI",
    CITYNAME_HEB: "MILINGIMBI",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MGU",
    AIRPORTNAME: "Manaung",
    CITYCODE: "MGU",
    CITYNAME: "MANAUNG",
    CITYNAME_HEB: "MANAUNG",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "AWN",
    AIRPORTNAME: "Alton Downs",
    CITYCODE: "AWN",
    CITYNAME: "ALTON DOWNS",
    CITYNAME_HEB: "ALTON DOWNS",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MGV",
    AIRPORTNAME: "Margaret River",
    CITYCODE: "MGV",
    CITYNAME: "MARGARET RIVER STATION",
    CITYNAME_HEB: "MARGARET RIVER STATION",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MGW",
    AIRPORTNAME: "Morgantown",
    CITYCODE: "MGW",
    CITYNAME: "MORGANTOWN",
    CITYNAME_HEB: "MORGANTOWN",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MGX",
    AIRPORTNAME: "Moabi",
    CITYCODE: "MGX",
    CITYNAME: "MOABI",
    CITYNAME_HEB: "MOABI",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MGZ",
    AIRPORTNAME: "Myeik",
    CITYCODE: "MGZ",
    CITYNAME: "MYEIK",
    CITYNAME_HEB: "MYEIK",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MHA",
    AIRPORTNAME: "Mahdia",
    CITYCODE: "MHA",
    CITYNAME: "MAHDIA",
    CITYNAME_HEB: "MAHDIA",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "MHC",
    AIRPORTNAME: "Macmahon Camp 4",
    CITYCODE: "MHC",
    CITYNAME: "MACMAHON CAMP 4",
    CITYNAME_HEB: "MACMAHON CAMP 4",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MHE",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MHE",
    CITYNAME: "MITCHELL",
    CITYNAME_HEB: "MITCHELL",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MHF",
    AIRPORTNAME: " ",
    CITYCODE: "MHF",
    CITYNAME: "MAYRHOFEN",
    CITYNAME_HEB: "\u05de\u05d9\u05d9\u05e8\u05d4\u05d5\u05e4\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MHG",
    AIRPORTNAME: "Mannheim Airport",
    CITYCODE: "MHG",
    CITYNAME: "MANNHEIM",
    CITYNAME_HEB: "\u05de\u05e0\u05d4\u05d9\u05d9\u05dd",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MHH",
    AIRPORTNAME: "International",
    CITYCODE: "MHH",
    CITYNAME: "MARSH HARBOUR",
    CITYNAME_HEB: "MARSH HARBOUR",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "AWP",
    AIRPORTNAME: "Austral Downs",
    CITYCODE: "AWP",
    CITYNAME: "AUSTRAL DOWNS",
    CITYNAME_HEB: "AUSTRAL DOWNS",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MHI",
    AIRPORTNAME: null,
    CITYCODE: "MHI",
    CITYNAME: "MUSHA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DJ",
    COUNTRYNAME: "DJIBOUTI",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MHJ",
    AIRPORTNAME: "Misrak Gashamo",
    CITYCODE: "MHJ",
    CITYNAME: "MISRAK GASHAMO",
    CITYNAME_HEB: "MISRAK GASHAMO",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MHK",
    AIRPORTNAME: null,
    CITYCODE: "MHK",
    CITYNAME: "MANHATTAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MHL",
    AIRPORTNAME: "Memorial Municipal",
    CITYCODE: "MHL",
    CITYNAME: "MARSHALL",
    CITYNAME_HEB: "MARSHALL",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MHM",
    AIRPORTNAME: "Minchumina",
    CITYCODE: "MHM",
    CITYNAME: "MINCHUMINA",
    CITYNAME_HEB: "Minchumina",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MHN",
    AIRPORTNAME: "Mullen",
    CITYCODE: "MHN",
    CITYNAME: "MULLEN",
    CITYNAME_HEB: "MULLEN",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MHO",
    AIRPORTNAME: "Mount House",
    CITYCODE: "MHO",
    CITYNAME: "MOUNT HOUSE",
    CITYNAME_HEB: "MOUNT HOUSE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MHQ",
    AIRPORTNAME: "Mariehamn",
    CITYCODE: "MHQ",
    CITYNAME: "MARIEHAMN",
    CITYNAME_HEB: "MARIEHAMN",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "MHS",
    AIRPORTNAME: "Rep",
    CITYCODE: "MHS",
    CITYNAME: "MOUNT SHASTA",
    CITYNAME_HEB: "MOUNT SHASTA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MHT",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MHT",
    CITYNAME: "MANCHESTER",
    CITYNAME_HEB: "MANCHESTER (US)",
    COUNTRYCODE: "USNH",
    COUNTRYNAME: "NEW HAMPSHIRE",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d4\u05de\u05e4\u05e9\u05d9\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AWR",
    AIRPORTNAME: "Awar",
    CITYCODE: "AWR",
    CITYNAME: "AWAR",
    CITYNAME_HEB: "AWAR",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MHU",
    AIRPORTNAME: "Mount Hotham",
    CITYCODE: "MHU",
    CITYNAME: "MOUNT HOTHAM",
    CITYNAME_HEB: "MOUNT HOTHAM",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MHV",
    AIRPORTNAME: "Kern County",
    CITYCODE: "MHV",
    CITYNAME: "MOJAVE",
    CITYNAME_HEB: "MOJAVE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MHW",
    AIRPORTNAME: "Monteagudo",
    CITYCODE: "MHW",
    CITYNAME: "MONTEAGUDO",
    CITYNAME_HEB: "MONTEAGUDO",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MHX",
    AIRPORTNAME: "Manihiki Island",
    CITYCODE: "MHX",
    CITYNAME: "MANIHIKI ISLAND",
    CITYNAME_HEB: "MANIHIKI ISLAND",
    COUNTRYCODE: "CK",
    COUNTRYNAME: "COOK ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e7\u05d5\u05e7",
  },
  {
    AIRPORTCODE: "MHY",
    AIRPORTNAME: "Morehead",
    CITYCODE: "MHY",
    CITYNAME: "MOREHEAD",
    CITYNAME_HEB: "MOREHEAD",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "GXH",
    AIRPORTNAME: "NAF",
    CITYCODE: "MHZ",
    CITYNAME: "MILDENHALL",
    CITYNAME_HEB: "MILDENHALL",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MHZ",
    AIRPORTNAME: "Mildenhall Airport",
    CITYCODE: "MHZ",
    CITYNAME: "MILDENHALL",
    CITYNAME_HEB: "MILDENHALL",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MIA",
    AIRPORTNAME: " ",
    CITYCODE: "MIA",
    CITYNAME: "MIAMI",
    CITYNAME_HEB: "\u05de\u05d9\u05d0\u05de\u05d9",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "MPB",
    AIRPORTNAME: " ",
    CITYCODE: "MIA",
    CITYNAME: "MIAMI",
    CITYNAME_HEB: "\u05de\u05d9\u05d0\u05de\u05d9",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "AXA",
    AIRPORTNAME: " ",
    CITYCODE: "AXA",
    CITYNAME: "AXAMS",
    CITYNAME_HEB: "\u05d0\u05e7\u05e1\u05de\u05e1",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TMB",
    AIRPORTNAME: " ",
    CITYCODE: "MIA",
    CITYNAME: "MIAMI",
    CITYNAME_HEB: "\u05de\u05d9\u05d0\u05de\u05d9",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "MID",
    AIRPORTNAME: "Rejon",
    CITYCODE: "MID",
    CITYNAME: "MERIDA",
    CITYNAME_HEB: "\u05de\u05e8\u05d9\u05d3\u05d4",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "MIE",
    AIRPORTNAME: "Delaware County",
    CITYCODE: "MIE",
    CITYNAME: "MUNCIE",
    CITYNAME_HEB: "MUNCIE",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MIF",
    AIRPORTNAME: "Roy Hurd Memorial",
    CITYCODE: "MIF",
    CITYNAME: "MONAHANS",
    CITYNAME_HEB: "MONAHANS",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MIG",
    AIRPORTNAME: "Mian Yang",
    CITYCODE: "MIG",
    CITYNAME: "MIAN YANG",
    CITYNAME_HEB: "MIAN YANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "MIH",
    AIRPORTNAME: "Mitchell Plateau",
    CITYCODE: "MIH",
    CITYNAME: "MITCHELL PLATEAU",
    CITYNAME_HEB: "MITCHELL PLATEAU",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MII",
    AIRPORTNAME: null,
    CITYCODE: "MII",
    CITYNAME: "MARILIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MIJ",
    AIRPORTNAME: "Mili Island",
    CITYCODE: "MIJ",
    CITYNAME: "MILI ISLAND",
    CITYNAME_HEB: "MILI ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "ACC",
    AIRPORTNAME: "Kotoka International Airport",
    CITYCODE: "ACC",
    CITYNAME: "ACCRA",
    CITYNAME_HEB: "\u05d0\u05e7\u05e8\u05d4",
    COUNTRYCODE: "GH",
    COUNTRYNAME: "GHANA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "AXB",
    AIRPORTNAME: "Alexandria Bay",
    CITYCODE: "AXB",
    CITYNAME: "ALEXANDRIA BAY",
    CITYNAME_HEB: "ALEXANDRIA BAY",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MIK",
    AIRPORTNAME: "Mikkeli",
    CITYCODE: "MIK",
    CITYNAME: "MIKKELI",
    CITYNAME_HEB: "MIKKELI",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "MIL",
    AIRPORTNAME: null,
    CITYCODE: "MIL",
    CITYNAME: "MILAN",
    CITYNAME_HEB: "\u05de\u05d9\u05dc\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BGY",
    AIRPORTNAME: "Orio Al Serio",
    CITYCODE: "BGY",
    CITYNAME: "BERGAMO",
    CITYNAME_HEB: "\u05d1\u05e8\u05d2\u05de\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LIN",
    AIRPORTNAME: "Linate",
    CITYCODE: "MIL",
    CITYNAME: "MILAN",
    CITYNAME_HEB: "\u05de\u05d9\u05dc\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MXP",
    AIRPORTNAME: "Malpensa",
    CITYCODE: "MIL",
    CITYNAME: "MILAN",
    CITYNAME_HEB: "\u05de\u05d9\u05dc\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MIM",
    AIRPORTNAME: "Merimbula",
    CITYCODE: "MIM",
    CITYNAME: "MERIMBULA",
    CITYNAME_HEB: "MERIMBULA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "MIN",
    CITYNAME: "MINORI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AXC",
    AIRPORTNAME: "Aramac",
    CITYCODE: "AXC",
    CITYNAME: "ARAMAC",
    CITYNAME_HEB: "ARAMAC",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MIP",
    AIRPORTNAME: null,
    CITYCODE: "MIP",
    CITYNAME: "MITSPEH RAMON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MIR",
    AIRPORTNAME: "Habib Bourguiba International",
    CITYCODE: "MIR",
    CITYNAME: "MONASTIR",
    CITYNAME_HEB: "MONASTIR",
    COUNTRYCODE: "TN",
    COUNTRYNAME: "TUNISIA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d9\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MIS",
    AIRPORTNAME: "Misima Island",
    CITYCODE: "MIS",
    CITYNAME: "MISIMA ISLAND",
    CITYNAME_HEB: "MISIMA ISLAND",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MIT",
    AIRPORTNAME: "Kern County",
    CITYCODE: "MIT",
    CITYNAME: "SHAFTER",
    CITYNAME_HEB: "SHAFTER",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MIU",
    AIRPORTNAME: "Maiduguri",
    CITYCODE: "MIU",
    CITYNAME: "MAIDUGURI",
    CITYNAME_HEB: "MAIDUGURI",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MIV",
    AIRPORTNAME: "Millville",
    CITYCODE: "MIV",
    CITYNAME: "MILLVILLE",
    CITYNAME_HEB: "MILLVILLE",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MIW",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MIW",
    CITYNAME: "MARSHALLTOWN",
    CITYNAME_HEB: "MARSHALLTOWN",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MIX",
    AIRPORTNAME: "Miriti",
    CITYCODE: "MIX",
    CITYNAME: "MIRITI",
    CITYNAME_HEB: "MIRITI",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MIY",
    AIRPORTNAME: "Mittiebah",
    CITYCODE: "MIY",
    CITYNAME: "MITTIEBAH",
    CITYNAME_HEB: "MITTIEBAH",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MIZ",
    AIRPORTNAME: "Mainoru",
    CITYCODE: "MIZ",
    CITYNAME: "MAINORU",
    CITYNAME_HEB: "MAINORU",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AXD",
    AIRPORTNAME: "Demokritos Airport",
    CITYCODE: "AXD",
    CITYNAME: "ALEXANDROUPOLIS",
    CITYNAME_HEB: "ALEXANDROUPOLIS",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MJA",
    AIRPORTNAME: "Manja",
    CITYCODE: "MJA",
    CITYNAME: "MANJA",
    CITYNAME_HEB: "MANJA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "MJB",
    AIRPORTNAME: "Mejit Island",
    CITYCODE: "MJB",
    CITYNAME: "MEJIT ISLAND",
    CITYNAME_HEB: "MEJIT ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "MJC",
    AIRPORTNAME: "Man",
    CITYCODE: "MJC",
    CITYNAME: "MAN",
    CITYNAME_HEB: "MAN",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "MJD",
    AIRPORTNAME: "Mohenjodaro",
    CITYCODE: "MJD",
    CITYNAME: "MOHENJODARO",
    CITYNAME_HEB: "MOHENJODARO",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "MJE",
    AIRPORTNAME: "Majkin",
    CITYCODE: "MJE",
    CITYNAME: "MAJKIN",
    CITYNAME_HEB: "MAJKIN",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "MJF",
    AIRPORTNAME: "Kjaerstad",
    CITYCODE: "MJF",
    CITYNAME: "MOSJOEN",
    CITYNAME_HEB: "MOSJOEN",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MJG",
    AIRPORTNAME: "Mayajigua",
    CITYCODE: "MJG",
    CITYNAME: "MAYAJIGUA",
    CITYNAME_HEB: "MAYAJIGUA",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "MJI",
    AIRPORTNAME: "Mitiga",
    CITYCODE: "MJI",
    CITYNAME: "MITIGA",
    CITYNAME_HEB: "MITIGA",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "MJJ",
    AIRPORTNAME: "Moki",
    CITYCODE: "MJJ",
    CITYNAME: "MOKI",
    CITYNAME_HEB: "MOKI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KWD",
    AIRPORTNAME: null,
    CITYCODE: "KWD",
    CITYNAME: "KAWADJIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ATL",
    AIRPORTNAME: "Hartsfield-Jackson Atlanta International Airport",
    CITYCODE: "ATL",
    CITYNAME: "ATLANTA",
    CITYNAME_HEB: "\u05d0\u05d8\u05dc\u05e0\u05d8\u05d4",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "KWE",
    AIRPORTNAME: "Guiyang",
    CITYCODE: "KWE",
    CITYNAME: "GUIYANG",
    CITYNAME_HEB: "GUIYANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KWF",
    AIRPORTNAME: "Waterfall SPB",
    CITYCODE: "KWF",
    CITYNAME: "WATERFALL",
    CITYNAME_HEB: "Waterfall SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KWG",
    AIRPORTNAME: "Krivoy Rog",
    CITYCODE: "KWG",
    CITYNAME: "KRIVOY ROG",
    CITYNAME_HEB: "KRIVOY ROG",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KWH",
    AIRPORTNAME: null,
    CITYCODE: "KWH",
    CITYNAME: "KHWAHAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KWI",
    AIRPORTNAME: "International",
    CITYCODE: "KWI",
    CITYNAME: "KUWAIT",
    CITYNAME_HEB: "\u05db\u05d5\u05d5\u05d9\u05ea",
    COUNTRYCODE: "KW",
    COUNTRYNAME: "KUWAIT",
    COUNTRYNAME_HEB: "\u05db\u05d5\u05d5\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "KWJ",
    AIRPORTNAME: "Kwangju",
    CITYCODE: "KWJ",
    CITYNAME: "GWANGJU",
    CITYNAME_HEB: "GWANGJU",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "KWK",
    AIRPORTNAME: "Kwigillingok",
    CITYCODE: "KWK",
    CITYNAME: "KWIGILLINGOK",
    CITYNAME_HEB: "Kwigillingok",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KWL",
    AIRPORTNAME: "Guilin",
    CITYCODE: "KWL",
    CITYNAME: "GUILIN",
    CITYNAME_HEB: "\u05d2\u05d5\u05d0\u05d9\u05dc\u05d9\u05df",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KWM",
    AIRPORTNAME: "Kowanyama",
    CITYCODE: "KWM",
    CITYNAME: "KOWANYAMA",
    CITYNAME_HEB: "KOWANYAMA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KWN",
    AIRPORTNAME: "Kwinhagak",
    CITYCODE: "KWN",
    CITYNAME: "QUINHAGAK",
    CITYNAME_HEB: "Kwinhagak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "FTY",
    AIRPORTNAME: null,
    CITYCODE: "ATL",
    CITYNAME: "ATLANTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KWO",
    AIRPORTNAME: "Kawito",
    CITYCODE: "KWO",
    CITYNAME: "KAWITO",
    CITYNAME_HEB: "KAWITO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KWP",
    AIRPORTNAME: "Village SPB",
    CITYCODE: "KWP",
    CITYNAME: "WEST POINT",
    CITYNAME_HEB: "Village SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KWR",
    AIRPORTNAME: "Kwai Harbour",
    CITYCODE: "KWR",
    CITYNAME: "KWAI HARBOUR",
    CITYNAME_HEB: "KWAI HARBOUR",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "KWS",
    AIRPORTNAME: "Kwailabesi Aerodrom",
    CITYCODE: "KWS",
    CITYNAME: "KWAILABESI",
    CITYNAME_HEB: "KWAILABESI",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "KWT",
    AIRPORTNAME: "Kwethluk",
    CITYCODE: "KWT",
    CITYNAME: "KWETHLUK",
    CITYNAME_HEB: "Kwethluk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KWU",
    AIRPORTNAME: "Mansion House",
    CITYCODE: "KWU",
    CITYNAME: "MANSION HOUSE",
    CITYNAME_HEB: "MANSION HOUSE",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KWV",
    AIRPORTNAME: "Kurwina",
    CITYCODE: "KWV",
    CITYNAME: "KURWINA",
    CITYNAME_HEB: "KURWINA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KWX",
    AIRPORTNAME: "Kiwai Island",
    CITYCODE: "KWX",
    CITYNAME: "KIWAI ISLAND",
    CITYNAME_HEB: "KIWAI ISLAND",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KWY",
    AIRPORTNAME: "Kiwayu",
    CITYCODE: "KWY",
    CITYNAME: "KIWAYU",
    CITYNAME_HEB: "KIWAYU",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KWZ",
    AIRPORTNAME: "Kolwezi",
    CITYCODE: "KWZ",
    CITYNAME: "KOLWEZI",
    CITYNAME_HEB: "KOLWEZI",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KXA",
    AIRPORTNAME: "Kasaan SPB",
    CITYCODE: "KXA",
    CITYNAME: "KASAAN",
    CITYNAME_HEB: "Kasaan SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KXE",
    AIRPORTNAME: "Klerksdorp",
    CITYCODE: "KXE",
    CITYNAME: "KLERKSDORP",
    CITYNAME_HEB: "KLERKSDORP",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "KXF",
    AIRPORTNAME: "Koro Island",
    CITYCODE: "KXF",
    CITYNAME: "KORO ISLAND",
    CITYNAME_HEB: "KORO ISLAND",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "KXK",
    AIRPORTNAME: "Komsomolsk Na Amure",
    CITYCODE: "KXK",
    CITYNAME: "KOMSOMOLSK NA AMURE",
    CITYNAME_HEB: "KOMSOMOLSK NA AMURE",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KXR",
    AIRPORTNAME: "Karoola",
    CITYCODE: "KXR",
    CITYNAME: "KAROOLA",
    CITYNAME_HEB: "KAROOLA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KYA",
    AIRPORTNAME: "Konya",
    CITYCODE: "KYA",
    CITYNAME: "KONYA",
    CITYNAME_HEB: "\u05e7\u05d5\u05e0\u05d9\u05d4",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KYB",
    AIRPORTNAME: "Yangoonabie",
    CITYCODE: "KYB",
    CITYNAME: "YANGOONABIE",
    CITYNAME_HEB: "YANGOONABIE",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KYD",
    AIRPORTNAME: "Orchid Island",
    CITYCODE: "KYD",
    CITYNAME: "ORCHID ISLAND",
    CITYNAME_HEB: "ORCHID ISLAND",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "KYE",
    AIRPORTNAME: "Kleyate",
    CITYCODE: "KYE",
    CITYNAME: "TRIPOLI",
    CITYNAME_HEB: "\u05d8\u05e8\u05d9\u05e4\u05d5\u05dc\u05d9",
    COUNTRYCODE: "LB",
    COUNTRYNAME: "LEBANON",
    COUNTRYNAME_HEB: "\u05dc\u05d1\u05e0\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KYF",
    AIRPORTNAME: "Yeelirrie",
    CITYCODE: "KYF",
    CITYNAME: "YEELIRRIE",
    CITYNAME_HEB: "YEELIRRIE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JAJ",
    AIRPORTNAME: null,
    CITYCODE: "ATL",
    CITYNAME: "ATLANTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KYI",
    AIRPORTNAME: "Yalata Mission",
    CITYCODE: "KYI",
    CITYNAME: "YALATA MISSION",
    CITYNAME_HEB: "YALATA MISSION",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KYK",
    AIRPORTNAME: "Karluk",
    CITYCODE: "KYK",
    CITYNAME: "KARLUK",
    CITYNAME_HEB: "Karluk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KYL",
    AIRPORTNAME: "Port Largo",
    CITYCODE: "KYL",
    CITYNAME: "KEY LARGO",
    CITYNAME_HEB: "KEY LARGO",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KYN",
    AIRPORTNAME: "Milton Keynes",
    CITYCODE: "KYN",
    CITYNAME: "MILTON KEYNES",
    CITYNAME_HEB: "MILTON KEYNES",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZFH",
    AIRPORTNAME: null,
    CITYCODE: "KYN",
    CITYNAME: "MILTON KEYNES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KYP",
    AIRPORTNAME: "Kyaukpyu",
    CITYCODE: "KYP",
    CITYNAME: "KYAUKPYU",
    CITYNAME_HEB: "KYAUKPYU",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "KYS",
    AIRPORTNAME: "Kayes",
    CITYCODE: "KYS",
    CITYNAME: "KAYES",
    CITYNAME_HEB: "KAYES",
    COUNTRYCODE: "ML",
    COUNTRYNAME: "MALI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d9",
  },
  {
    AIRPORTCODE: "KYT",
    AIRPORTNAME: "Kyauktaw",
    CITYCODE: "KYT",
    CITYNAME: "KYAUKTAW",
    CITYNAME_HEB: "KYAUKTAW",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "KYU",
    AIRPORTNAME: "Koyukuk",
    CITYCODE: "KYU",
    CITYNAME: "KOYUKUK",
    CITYNAME_HEB: "Koyukuk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KYX",
    AIRPORTNAME: "Yalumet",
    CITYCODE: "KYX",
    CITYNAME: "YALUMET",
    CITYNAME_HEB: "YALUMET",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "JAO",
    AIRPORTNAME: null,
    CITYCODE: "ATL",
    CITYNAME: "ATLANTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KYZ",
    AIRPORTNAME: "Kyzyl",
    CITYCODE: "KYZ",
    CITYNAME: "KYZYL",
    CITYNAME_HEB: "KYZYL",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KZB",
    AIRPORTNAME: "Zachar Bay SPB",
    CITYCODE: "KZB",
    CITYNAME: "ZACHAR BAY",
    CITYNAME_HEB: "Zachar Bay SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KZC",
    AIRPORTNAME: "Kompong-Chhna",
    CITYCODE: "KZC",
    CITYNAME: "KOMPONG CHHNA",
    CITYNAME_HEB: "KOMPONG CHHNA",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KZD",
    AIRPORTNAME: "Krakor",
    CITYCODE: "KZD",
    CITYNAME: "KRAKOR",
    CITYNAME_HEB: "KRAKOR",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KZF",
    AIRPORTNAME: "Kaintiba",
    CITYCODE: "KZF",
    CITYNAME: "KAINTIBA",
    CITYNAME_HEB: "KAINTIBA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KZG",
    AIRPORTNAME: "Kitzingen",
    CITYCODE: "KZG",
    CITYNAME: "KITZINGEN",
    CITYNAME_HEB: "KITZINGEN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KZH",
    AIRPORTNAME: "Kizhuyak",
    CITYCODE: "KZH",
    CITYNAME: "KIZHUYAK",
    CITYNAME_HEB: "Kizhuyak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KZI",
    AIRPORTNAME: "Philippos Airport",
    CITYCODE: "KZI",
    CITYNAME: "KOZANI",
    CITYNAME_HEB: "KOZANI",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KZK",
    AIRPORTNAME: "Kompong Thom",
    CITYCODE: "KZK",
    CITYNAME: "KOMPONG THOM",
    CITYNAME_HEB: "KOMPONG THOM",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KZN",
    AIRPORTNAME: "Kazan",
    CITYCODE: "KZN",
    CITYNAME: "KAZAN",
    CITYNAME_HEB: "\u05e7\u05d0\u05d6\u05d0\u05df",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JGL",
    AIRPORTNAME: null,
    CITYCODE: "ATL",
    CITYNAME: "ATLANTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KZO",
    AIRPORTNAME: "Kzyl-Orda",
    CITYCODE: "KZO",
    CITYNAME: "KZYL ORDA",
    CITYNAME_HEB: "KZYL ORDA",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "KZS",
    AIRPORTNAME: "Kastelorizo",
    CITYCODE: "KZS",
    CITYNAME: "KASTELORIZO",
    CITYNAME_HEB: "KASTELORIZO",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "LAA",
    AIRPORTNAME: "Lamar Field",
    CITYCODE: "LAA",
    CITYNAME: "LAMAR",
    CITYNAME_HEB: "LAMAR",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LAB",
    AIRPORTNAME: "Lablab",
    CITYCODE: "LAB",
    CITYNAME: "LABLAB",
    CITYNAME_HEB: "LABLAB",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LAC",
    AIRPORTNAME: "Layang-Layang Airstrip",
    CITYCODE: "LAC",
    CITYNAME: "PULAU LAYANG",
    CITYNAME_HEB: "PULAU LAYANG",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LAD",
    AIRPORTNAME: "4 de Fevereiro",
    CITYCODE: "LAD",
    CITYNAME: "LUANDA",
    CITYNAME_HEB: "\u05dc\u05d5\u05d0\u05e0\u05d3\u05d4",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LAE",
    AIRPORTNAME: "Nadzab",
    CITYCODE: "LAE",
    CITYNAME: "LAE",
    CITYNAME_HEB: "LAE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LAF",
    AIRPORTNAME: "Purdue University",
    CITYCODE: "LAF",
    CITYNAME: "LAFAYETTE",
    CITYNAME_HEB: "LAFAYETTE",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LAG",
    AIRPORTNAME: "La Guaira",
    CITYCODE: "LAG",
    CITYNAME: "LA GUAIRA",
    CITYNAME_HEB: "LA GUAIRA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LAH",
    AIRPORTNAME: " ",
    CITYCODE: "LAH",
    CITYNAME: "LAHNSTEIN",
    CITYNAME_HEB: "\u05dc\u05db\u05e0\u05e9\u05d8\u05d9\u05d9\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ABX",
    AIRPORTNAME: "Albury",
    CITYCODE: "ABX",
    CITYNAME: "ALBURY",
    CITYNAME_HEB: "NEW SOUTH WALES",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PDK",
    AIRPORTNAME: null,
    CITYCODE: "ATL",
    CITYNAME: "ATLANTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LAI",
    AIRPORTNAME: "Servel",
    CITYCODE: "LAI",
    CITYNAME: "LANNION",
    CITYNAME_HEB: "LANNION",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LAJ",
    AIRPORTNAME: null,
    CITYCODE: "LAJ",
    CITYNAME: "LAGES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LAK",
    AIRPORTNAME: null,
    CITYCODE: "LAK",
    CITYNAME: "AKLAVIK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LAL",
    AIRPORTNAME: "Municipal",
    CITYCODE: "LAL",
    CITYNAME: "LAKELAND",
    CITYNAME_HEB: "LAKELAND",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "LAM",
    CITYNAME: "LA MOLINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LAN",
    AIRPORTNAME: "Capital City",
    CITYCODE: "LAN",
    CITYNAME: "LANSING",
    CITYNAME_HEB: "LANSING",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LAO",
    AIRPORTNAME: "Laoag",
    CITYCODE: "LAO",
    CITYNAME: "LAOAG",
    CITYNAME_HEB: "LAOAG",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "LAP",
    AIRPORTNAME: "Leon",
    CITYCODE: "LAP",
    CITYNAME: "LA PAZ",
    CITYNAME_HEB: "LA PAZ",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "LAQ",
    AIRPORTNAME: "La Braq",
    CITYCODE: "LAQ",
    CITYNAME: "BEIDA",
    CITYNAME_HEB: "BEIDA",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "ATM",
    AIRPORTNAME: null,
    CITYCODE: "ATM",
    CITYNAME: "ALTAMIRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LAS",
    AIRPORTNAME: "Mccarran International",
    CITYCODE: "LAS",
    CITYNAME: "LAS VEGAS",
    CITYNAME_HEB: "\u05dc\u05d0\u05e1 \u05d5\u05d2\u05d0\u05e1",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "LAT",
    AIRPORTNAME: " ",
    CITYCODE: "LAT",
    CITYNAME: "LA TOUSSUIRE",
    CITYNAME_HEB: "\u05dc\u05d4 \u05d8\u05d5\u05e1\u05d9\u05d9\u05e8",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LAU",
    AIRPORTNAME: "Lamu",
    CITYCODE: "LAU",
    CITYNAME: "LAMU",
    CITYNAME_HEB: "LAMU",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LAV",
    AIRPORTNAME: "Lalomalava",
    CITYCODE: "LAV",
    CITYNAME: "LALOMALAVA",
    CITYNAME_HEB: "LALOMALAVA",
    COUNTRYCODE: "WS",
    COUNTRYNAME: "SAMOA-INDEPENDENT STATE",
    COUNTRYNAME_HEB:
      "\u05e1\u05de\u05d5\u05d0\u05d4 \u05d4\u05de\u05e2\u05e8\u05d1\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "LAW",
    AIRPORTNAME: "Municipal",
    CITYCODE: "LAW",
    CITYNAME: "LAWTON",
    CITYNAME_HEB: "LAWTON",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LAX",
    AIRPORTNAME: " ",
    CITYCODE: "LAX",
    CITYNAME: "LOS ANGELES",
    CITYNAME_HEB: "\u05dc\u05d5\u05e1 \u05d0\u05e0\u05d2\u05dc\u05e1",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "ATN",
    AIRPORTNAME: "Namatanai",
    CITYCODE: "ATN",
    CITYNAME: "NAMATANAI",
    CITYNAME_HEB: "NAMATANAI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LAY",
    AIRPORTNAME: "Ladysmith",
    CITYCODE: "LAY",
    CITYNAME: "LADYSMITH",
    CITYNAME_HEB: "LADYSMITH",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "LAZ",
    AIRPORTNAME: " ",
    CITYCODE: "LAZ",
    CITYNAME: "LAZE V TUHINJU",
    CITYNAME_HEB: "\u05dc\u05d0\u05d6\u05d4 \u05d5\u05d9",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LBA",
    AIRPORTNAME: "Leeds/Bradford",
    CITYCODE: "LBA",
    CITYNAME: "LEEDS",
    CITYNAME_HEB: "\u05dc\u05d9\u05d3\u05e1",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZLZ",
    AIRPORTNAME: null,
    CITYCODE: "LBA",
    CITYNAME: "LEEDS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LBB",
    AIRPORTNAME: "International",
    CITYCODE: "LBB",
    CITYNAME: "LUBBOCK",
    CITYNAME_HEB: "LUBBOCK",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LBD",
    AIRPORTNAME: "Khudzhand",
    CITYCODE: "LBD",
    CITYNAME: "KHUDZHAND",
    CITYNAME_HEB: "KHUDZHAND",
    COUNTRYCODE: "TJ",
    COUNTRYNAME: "TAJIKISTAN",
    COUNTRYNAME_HEB: "\u05d8\u05d2'\u05d9\u05e7\u05d9\u05e1\u05d8\u05d0\u05df",
  },
  {
    AIRPORTCODE: "LBE",
    AIRPORTNAME: "Westmoreland County",
    CITYCODE: "LBE",
    CITYNAME: "LATROBE",
    CITYNAME_HEB: "LATROBE",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LBF",
    AIRPORTNAME: "Lee Bird Field",
    CITYCODE: "LBF",
    CITYNAME: "NORTH PLATTE",
    CITYNAME_HEB: "NORTH PLATTE",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LBI",
    AIRPORTNAME: "Le Sequestre",
    CITYCODE: "LBI",
    CITYNAME: "ALBI",
    CITYNAME_HEB: "ALBI",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LBJ",
    AIRPORTNAME: "Mutiara",
    CITYCODE: "LBJ",
    CITYNAME: "LABUAN BAJO",
    CITYNAME_HEB: "LABUAN BAJO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ATP",
    AIRPORTNAME: "Airstrip",
    CITYCODE: "ATP",
    CITYNAME: "AITAPE",
    CITYNAME_HEB: "AITAPE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LBK",
    AIRPORTNAME: "Liboi",
    CITYCODE: "LBK",
    CITYNAME: "LIBOI",
    CITYNAME_HEB: "LIBOI",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LBL",
    AIRPORTNAME: "Municipal",
    CITYCODE: "LBL",
    CITYNAME: "LIBERAL",
    CITYNAME_HEB: "LIBERAL",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LBM",
    AIRPORTNAME: "Luabo",
    CITYCODE: "LBM",
    CITYNAME: "LUABO",
    CITYNAME_HEB: "LUABO",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "LBN",
    AIRPORTNAME: "Lake Baringo",
    CITYCODE: "LBN",
    CITYNAME: "LAKE BARINGO",
    CITYNAME_HEB: "LAKE BARINGO",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LBO",
    AIRPORTNAME: "Lusambo",
    CITYCODE: "LBO",
    CITYNAME: "LUSAMBO",
    CITYNAME_HEB: "LUSAMBO",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "LBP",
    AIRPORTNAME: "Long Banga Airfield",
    CITYCODE: "LBP",
    CITYNAME: "LONG BANGA",
    CITYNAME_HEB: "LONG BANGA",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LBQ",
    AIRPORTNAME: "Lambarene",
    CITYCODE: "LBQ",
    CITYNAME: "LAMBARENE",
    CITYNAME_HEB: "LAMBARENE",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "LBR",
    AIRPORTNAME: null,
    CITYCODE: "LBR",
    CITYNAME: "LABREA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LBS",
    AIRPORTNAME: "Labasa",
    CITYCODE: "LBS",
    CITYNAME: "LABASA",
    CITYNAME_HEB: "LABASA",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "LBT",
    AIRPORTNAME: "Lumberton",
    CITYCODE: "LBT",
    CITYNAME: "LUMBERTON",
    CITYNAME_HEB: "LUMBERTON",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ATQ",
    AIRPORTNAME: "Raja Sansi International Airport",
    CITYCODE: "ATQ",
    CITYNAME: "AMRITSAR",
    CITYNAME_HEB: "\u05d0\u05de\u05e8\u05d9\u05d8\u05e1\u05d0\u05e8",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "LBU",
    AIRPORTNAME: "Labuan",
    CITYCODE: "LBU",
    CITYNAME: "LABUAN",
    CITYNAME_HEB: "LABUAN",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LBV",
    AIRPORTNAME: "Libreville",
    CITYCODE: "LBV",
    CITYNAME: "LIBREVILLE",
    CITYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d5\u05d5\u05d9\u05dc",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "LBW",
    AIRPORTNAME: "Long Bawan",
    CITYCODE: "LBW",
    CITYNAME: "LONG BAWAN",
    CITYNAME_HEB: "LONG BAWAN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MTV",
    AIRPORTNAME: "Mota Lava",
    CITYCODE: "MTV",
    CITYNAME: "MOTA LAVA",
    CITYNAME_HEB: "MOTA LAVA",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "MTW",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MTW",
    CITYNAME: "MANITOWOC",
    CITYNAME_HEB: "MANITOWOC",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AYW",
    AIRPORTNAME: "Ayawasi",
    CITYCODE: "AYW",
    CITYNAME: "AYAWASI",
    CITYNAME_HEB: "AYAWASI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MTY",
    AIRPORTNAME: "Gen Mariano Escobedo",
    CITYCODE: "MTY",
    CITYNAME: "MONTERREY",
    CITYNAME_HEB: "MONTERREY",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "NTR",
    AIRPORTNAME: "Aeropuerto Del Norte",
    CITYCODE: "MTY",
    CITYNAME: "MONTERREY",
    CITYNAME_HEB: "MONTERREY",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "MTZ",
    AIRPORTNAME: null,
    CITYCODE: "MTZ",
    CITYNAME: "MASADA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MUA",
    AIRPORTNAME: "Munda",
    CITYCODE: "MUA",
    CITYNAME: "MUNDA",
    CITYNAME_HEB: "MUNDA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MUB",
    AIRPORTNAME: "Maun",
    CITYCODE: "MUB",
    CITYNAME: "MAUN",
    CITYNAME_HEB: "MAUN",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "MUC",
    AIRPORTNAME: "Franz Josef Strauss",
    CITYCODE: "MUC",
    CITYNAME: "MUNICH",
    CITYNAME_HEB: "\u05de\u05d9\u05e0\u05db\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MUD",
    AIRPORTNAME: "Mueda",
    CITYCODE: "MUD",
    CITYNAME: "MUEDA",
    CITYNAME_HEB: "MUEDA",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "MUE",
    AIRPORTNAME: "Kamuela",
    CITYCODE: "MUE",
    CITYNAME: "KAMUELA",
    CITYNAME_HEB: "KAMUELA",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AYZ",
    AIRPORTNAME: "Zahns",
    CITYCODE: "AYZ",
    CITYNAME: "AMITYVILLE",
    CITYNAME_HEB: "AMITYVILLE",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MUF",
    AIRPORTNAME: "Muting",
    CITYCODE: "MUF",
    CITYNAME: "MUTING",
    CITYNAME_HEB: "MUTING",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MUG",
    AIRPORTNAME: "Mulege",
    CITYCODE: "MUG",
    CITYNAME: "MULEGE",
    CITYNAME_HEB: "MULEGE",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "MUI",
    AIRPORTNAME: "Muir AAF",
    CITYCODE: "MUI",
    CITYNAME: "FORT INDIANTOWN",
    CITYNAME_HEB: "FORT INDIANTOWN",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MUJ",
    AIRPORTNAME: "Mui",
    CITYCODE: "MUJ",
    CITYNAME: "MUI",
    CITYNAME_HEB: "MUI",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MUK",
    AIRPORTNAME: "Mauke Island",
    CITYCODE: "MUK",
    CITYNAME: "MAUKE ISLAND",
    CITYNAME_HEB: "MAUKE ISLAND",
    COUNTRYCODE: "CK",
    COUNTRYNAME: "COOK ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e7\u05d5\u05e7",
  },
  {
    AIRPORTCODE: "MUM",
    AIRPORTNAME: "Mumias",
    CITYCODE: "MUM",
    CITYNAME: "MUMIAS",
    CITYNAME_HEB: "MUMIAS",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MUN",
    AIRPORTNAME: "Quiriquire",
    CITYCODE: "MUN",
    CITYNAME: "MATURIN",
    CITYNAME_HEB: "MATURIN",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "MUO",
    AIRPORTNAME: "AFB",
    CITYCODE: "MUO",
    CITYNAME: "MOUNTAIN HOME",
    CITYNAME_HEB: "MOUNTAIN HOME",
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d9\u05d3\u05d4\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MUP",
    AIRPORTNAME: "Mulga Park",
    CITYCODE: "MUP",
    CITYNAME: "MULGA PARK",
    CITYNAME_HEB: "MULGA PARK",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MUQ",
    AIRPORTNAME: "Muccan",
    CITYCODE: "MUQ",
    CITYNAME: "MUCCAN",
    CITYNAME_HEB: "MUCCAN",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AZB",
    AIRPORTNAME: "Amazon Bay",
    CITYCODE: "AZB",
    CITYNAME: "AMAZON BAY",
    CITYNAME_HEB: "AMAZON BAY",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MUR",
    AIRPORTNAME: "Marudi",
    CITYCODE: "MUR",
    CITYNAME: "MARUDI",
    CITYNAME_HEB: "MARUDI",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MUS",
    AIRPORTNAME: "Marcus Island",
    CITYCODE: "MUS",
    CITYNAME: "MARCUS ISLAND",
    CITYNAME_HEB: "MARCUS ISLAND",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "MUT",
    AIRPORTNAME: "Muscatine",
    CITYCODE: "MUT",
    CITYNAME: "MUSCATINE",
    CITYNAME_HEB: "MUSCATINE",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MUU",
    AIRPORTNAME: "Mount Union",
    CITYCODE: "MUU",
    CITYNAME: "MOUNT UNION",
    CITYNAME_HEB: "MOUNT UNION",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MUW",
    AIRPORTNAME: "Mascara",
    CITYCODE: "MUW",
    CITYNAME: "MASCARA",
    CITYNAME_HEB: "MASCARA",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MUX",
    AIRPORTNAME: "Multan",
    CITYCODE: "MUX",
    CITYNAME: "MULTAN",
    CITYNAME_HEB: "MULTAN",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "MUY",
    AIRPORTNAME: "Mouyondzi",
    CITYCODE: "MUY",
    CITYNAME: "MOUYONDZI",
    CITYNAME_HEB: "MOUYONDZI",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "MUZ",
    AIRPORTNAME: "Musoma",
    CITYCODE: "MUZ",
    CITYNAME: "MUSOMA",
    CITYNAME_HEB: "MUSOMA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MVA",
    AIRPORTNAME: "Reykiahlid",
    CITYCODE: "MVA",
    CITYNAME: "MYVATN",
    CITYNAME_HEB: "MYVATN",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "MVB",
    AIRPORTNAME: "Franceville/Mvengue",
    CITYCODE: "MVB",
    CITYNAME: "FRANCEVILLE",
    CITYNAME_HEB: "FRANCEVILLE",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "AZG",
    AIRPORTNAME: "Apatzingan",
    CITYCODE: "AZG",
    CITYNAME: "APATZINGAN",
    CITYNAME_HEB: "APATZINGAN",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "MVC",
    AIRPORTNAME: "Monroe County",
    CITYCODE: "MVC",
    CITYNAME: "MONROEVILLE",
    CITYNAME_HEB: "Monroe County",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MVD",
    AIRPORTNAME: "Carrasco",
    CITYCODE: "MVD",
    CITYNAME: "MONTEVIDEO",
    CITYNAME_HEB:
      "\u05de\u05d5\u05e0\u05d8\u05d9\u05d1\u05d9\u05d3\u05d0\u05d5",
    COUNTRYCODE: "UY",
    COUNTRYNAME: "URUGUAY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e8\u05d5\u05d2\u05d0\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "MVE",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MVE",
    CITYNAME: "MONTEVIDEO",
    CITYNAME_HEB: "MONTEVIDEO (US)",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MVF",
    AIRPORTNAME: null,
    CITYCODE: "MVF",
    CITYNAME: "MOSSORO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MVG",
    AIRPORTNAME: "Mevang",
    CITYCODE: "MVG",
    CITYNAME: "MEVANG",
    CITYNAME_HEB: "MEVANG",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MVH",
    AIRPORTNAME: "Macksville",
    CITYCODE: "MVH",
    CITYNAME: "MACKSVILLE",
    CITYNAME_HEB: "MACKSVILLE",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MVI",
    AIRPORTNAME: "Manetai",
    CITYCODE: "MVI",
    CITYNAME: "MANETAI",
    CITYNAME_HEB: "MANETAI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MVJ",
    AIRPORTNAME: "Marlboro",
    CITYCODE: "MVJ",
    CITYNAME: "MANDEVILLE",
    CITYNAME_HEB: "MANDEVILLE",
    COUNTRYCODE: "JM",
    COUNTRYNAME: "JAMAICA",
    COUNTRYNAME_HEB: "\u05d2'\u05de\u05d9\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "MVK",
    AIRPORTNAME: "Mulka",
    CITYCODE: "MVK",
    CITYNAME: "MULKA",
    CITYNAME_HEB: "MULKA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MVL",
    AIRPORTNAME: "Morrisville-Stowe",
    CITYCODE: "MVL",
    CITYNAME: "STOWE",
    CITYNAME_HEB: "STOWE",
    COUNTRYCODE: "USVT",
    COUNTRYNAME: "VERMONT",
    COUNTRYNAME_HEB:
      '\u05d5\u05d5\u05e8\u05de\u05d5\u05e0\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AZN",
    AIRPORTNAME: "Andizhan",
    CITYCODE: "AZN",
    CITYNAME: "ANDIZHAN",
    CITYNAME_HEB: "ANDIZHAN",
    COUNTRYCODE: "UZ",
    COUNTRYNAME: "UZBEKISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d6\u05d1\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "MVM",
    AIRPORTNAME: "Monument Valley",
    CITYCODE: "MVM",
    CITYNAME: "KAYENTA",
    CITYNAME_HEB: "KAYENTA",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MVN",
    AIRPORTNAME: "Mt Vernon-Outland",
    CITYCODE: "MVN",
    CITYNAME: "MOUNT VERNON",
    CITYNAME_HEB: "MOUNT VERNON",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MVO",
    AIRPORTNAME: "Mongo",
    CITYCODE: "MVO",
    CITYNAME: "MONGO",
    CITYNAME_HEB: "MONGO",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "JUZ",
    AIRPORTNAME: "Juzhou",
    CITYCODE: "JUZ",
    CITYNAME: "JUZHOU",
    CITYNAME_HEB: "JUZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "JVA",
    AIRPORTNAME: "Ankavandra",
    CITYCODE: "JVA",
    CITYNAME: "ANKAVANDRA",
    CITYNAME_HEB: "ANKAVANDRA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "JVI",
    AIRPORTNAME: "Kupper",
    CITYCODE: "JVI",
    CITYNAME: "MANVILLE",
    CITYNAME_HEB: "MANVILLE",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "JVL",
    AIRPORTNAME: "Rock County",
    CITYCODE: "JVL",
    CITYNAME: "JANESVILLE",
    CITYNAME_HEB: "JANESVILLE",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JWA",
    AIRPORTNAME: "Jwaneng",
    CITYCODE: "JWA",
    CITYNAME: "JWANENG",
    CITYNAME_HEB: "JWANENG",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "JXN",
    AIRPORTNAME: "Reynolds Municipal",
    CITYCODE: "JXN",
    CITYNAME: "JACKSON",
    CITYNAME_HEB: "JACKSON",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JYV",
    AIRPORTNAME: "Jyvaskyla",
    CITYCODE: "JYV",
    CITYNAME: "JYVASKYLA",
    CITYNAME_HEB: "JYVASKYLA",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ARR",
    AIRPORTNAME: null,
    CITYCODE: "ARR",
    CITYNAME: "ALTO RIO SENGUERR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KAA",
    AIRPORTNAME: "Kasama",
    CITYCODE: "KAA",
    CITYNAME: "KASAMA",
    CITYNAME_HEB: "KASAMA",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KAB",
    AIRPORTNAME: "Kariba",
    CITYCODE: "KAB",
    CITYNAME: "KARIBA",
    CITYNAME_HEB: "KARIBA",
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: "\u05d6\u05d9\u05de\u05d1\u05d1\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KAC",
    AIRPORTNAME: "Kameshli",
    CITYCODE: "KAC",
    CITYNAME: "KAMESHLI",
    CITYNAME_HEB: "KAMESHLI",
    COUNTRYCODE: "SY",
    COUNTRYNAME: "SYRIAN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KAD",
    AIRPORTNAME: "Kaduna",
    CITYCODE: "KAD",
    CITYNAME: "KADUNA",
    CITYNAME_HEB: "KADUNA",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KAE",
    AIRPORTNAME: "Kake",
    CITYCODE: "KAE",
    CITYNAME: "KAKE",
    CITYNAME_HEB: "Kake",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KAF",
    AIRPORTNAME: "Karato",
    CITYCODE: "KAF",
    CITYNAME: "KARATO",
    CITYNAME_HEB: "KARATO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KAG",
    AIRPORTNAME: "Kangnung",
    CITYCODE: "KAG",
    CITYNAME: "GANGNEUNG",
    CITYNAME_HEB: "GANGNEUNG",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "KAI",
    AIRPORTNAME: "Kaieteur",
    CITYCODE: "KAI",
    CITYNAME: "KAIETEUR",
    CITYNAME_HEB: "KAIETEUR",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KAJ",
    AIRPORTNAME: "Kajaani",
    CITYCODE: "KAJ",
    CITYNAME: "KAJAANI",
    CITYNAME_HEB: "KAJAANI",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KAK",
    AIRPORTNAME: "Kar",
    CITYCODE: "KAK",
    CITYNAME: "KAR",
    CITYNAME_HEB: "KAR",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ABQ",
    AIRPORTNAME: "International Sunport",
    CITYCODE: "ABQ",
    CITYNAME: "ALBUQUERQUE",
    CITYNAME_HEB: "Albuquerque",
    COUNTRYCODE: "US",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ARS",
    AIRPORTNAME: " ",
    CITYCODE: "ARS",
    CITYNAME: "ARINSAL",
    CITYNAME_HEB: "\u05d0\u05e8\u05d9\u05e0\u05e1\u05dc",
    COUNTRYCODE: "AD",
    COUNTRYNAME: "ANDORRA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d3\u05d5\u05e8\u05d4",
  },
  {
    AIRPORTCODE: "KAL",
    AIRPORTNAME: "Kaltag",
    CITYCODE: "KAL",
    CITYNAME: "KALTAG",
    CITYNAME_HEB:
      "\u05e7\u05d0\u05dc\u05d8\u05d2 - \u05d0\u05dc\u05e1\u05e7\u05d4",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "KAM",
    AIRPORTNAME: " ",
    CITYCODE: "KAM",
    CITYNAME: "KAMENA VOURLA",
    CITYNAME_HEB: "\u05e7\u05d0\u05de\u05e0\u05d4 \u05d5\u05e8\u05dc\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KAN",
    AIRPORTNAME: "Aminu Kano International Apt",
    CITYCODE: "KAN",
    CITYNAME: "KANO",
    CITYNAME_HEB: "KANO",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KAO",
    AIRPORTNAME: "Kuusamo",
    CITYCODE: "KAO",
    CITYNAME: "KUUSAMO",
    CITYNAME_HEB: "KUUSAMO",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "KAP",
    CITYNAME: "KAPRUN",
    CITYNAME_HEB: "\u05e7\u05e4\u05e8\u05d5\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KAQ",
    AIRPORTNAME: "Kamulai",
    CITYCODE: "KAQ",
    CITYNAME: "KAMULAI",
    CITYNAME_HEB: "KAMULAI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KAR",
    AIRPORTNAME: null,
    CITYCODE: "KAR",
    CITYNAME: "KARPENISI",
    CITYNAME_HEB: "\u05e7\u05e8\u05e4\u05e0\u05d9\u05e1\u05d9",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KAT",
    AIRPORTNAME: null,
    CITYCODE: "KAT",
    CITYNAME: "KATSCHBERG",
    CITYNAME_HEB: "\u05e7\u05e6\u05d1\u05e8\u05d2",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KAU",
    AIRPORTNAME: "Kauhava",
    CITYCODE: "KAU",
    CITYNAME: "KAUHAVA",
    CITYNAME_HEB: "KAUHAVA",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ART",
    AIRPORTNAME: "Watertown",
    CITYCODE: "ART",
    CITYNAME: "WATERTOWN",
    CITYNAME_HEB: "WATERTOWN",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KAV",
    AIRPORTNAME: "Kavanayen",
    CITYCODE: "KAV",
    CITYNAME: "KAVANAYEN",
    CITYNAME_HEB: "KAVANAYEN",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "KAW",
    AIRPORTNAME: "Kawthaung",
    CITYCODE: "KAW",
    CITYNAME: "KAWTHAUNG",
    CITYNAME_HEB: "KAWTHAUNG",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "KAX",
    AIRPORTNAME: "Kalbarri",
    CITYCODE: "KAX",
    CITYNAME: "KALBARRI",
    CITYNAME_HEB: "KALBARRI",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KAY",
    AIRPORTNAME: "Wakaya Island",
    CITYCODE: "KAY",
    CITYNAME: "WAKAYA ISLAND",
    CITYNAME_HEB: "WAKAYA ISLAND",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "KAZ",
    AIRPORTNAME: "Kau",
    CITYCODE: "KAZ",
    CITYNAME: "KAU",
    CITYNAME_HEB: "KAU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KBA",
    AIRPORTNAME: "Kabala",
    CITYCODE: "KBA",
    CITYNAME: "KABALA",
    CITYNAME_HEB: "KABALA",
    COUNTRYCODE: "SL",
    COUNTRYNAME: "SIERRA LEONE",
    COUNTRYNAME_HEB:
      "\u05e1\u05d9\u05d9\u05e8\u05d4 \u05dc\u05d9\u05d0\u05d5\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KBB",
    AIRPORTNAME: "Kirkimbie",
    CITYCODE: "KBB",
    CITYNAME: "KIRKIMBIE",
    CITYNAME_HEB: "KIRKIMBIE",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KBC",
    AIRPORTNAME: "Birch Creek",
    CITYCODE: "KBC",
    CITYNAME: "BIRCH CREEK",
    CITYNAME_HEB: "Birch Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KBD",
    AIRPORTNAME: "Kimberley Downs",
    CITYCODE: "KBD",
    CITYNAME: "KIMBERLEY DOWNS",
    CITYNAME_HEB: "KIMBERLEY DOWNS",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KBE",
    AIRPORTNAME: "Hot Springs SPB",
    CITYCODE: "KBE",
    CITYNAME: "BELL ISLAND",
    CITYNAME_HEB: "Hot Springs SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ARU",
    AIRPORTNAME: null,
    CITYCODE: "ARU",
    CITYNAME: "ARACATUBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KBF",
    AIRPORTNAME: "Karubaga",
    CITYCODE: "KBF",
    CITYNAME: "KARUBAGA",
    CITYNAME_HEB: "KARUBAGA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KBG",
    AIRPORTNAME: "Kabalega Falls",
    CITYCODE: "KBG",
    CITYNAME: "KABALEGA FALLS",
    CITYNAME_HEB: "KABALEGA FALLS",
    COUNTRYCODE: "UG",
    COUNTRYNAME: "UGANDA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "KBH",
    AIRPORTNAME: "Kalat",
    CITYCODE: "KBH",
    CITYNAME: "KALAT",
    CITYNAME_HEB: "KALAT",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "KBI",
    AIRPORTNAME: "Kribi",
    CITYCODE: "KBI",
    CITYNAME: "KRIBI",
    CITYNAME_HEB: "KRIBI",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KBJ",
    AIRPORTNAME: "King Canyon",
    CITYCODE: "KBJ",
    CITYNAME: "KINGS CANYON",
    CITYNAME_HEB: "KINGS CANYON",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KBK",
    AIRPORTNAME: "Klag Bay",
    CITYCODE: "KBK",
    CITYNAME: "KLAG BAY",
    CITYNAME_HEB: "Klag Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KBL",
    AIRPORTNAME: " ",
    CITYCODE: "KBL",
    CITYNAME: "KITZBUHEL",
    CITYNAME_HEB: "\u05e7\u05d9\u05e6\u05d1\u05d4\u05d9\u05dc",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KBM",
    AIRPORTNAME: "Kabwum",
    CITYCODE: "KBM",
    CITYNAME: "KABWUM",
    CITYNAME_HEB: "KABWUM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KBN",
    AIRPORTNAME: "Kabinda",
    CITYCODE: "KBN",
    CITYNAME: "KABINDA",
    CITYNAME_HEB: "KABINDA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KBO",
    AIRPORTNAME: "Kabalo",
    CITYCODE: "KBO",
    CITYNAME: "KABALO",
    CITYNAME_HEB: "KABALO",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "ARV",
    AIRPORTNAME: "Noble F. Lee",
    CITYCODE: "ARV",
    CITYNAME: "MINOCQUA",
    CITYNAME_HEB: "MINOCQUA",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KBQ",
    AIRPORTNAME: "Kasungu",
    CITYCODE: "KBQ",
    CITYNAME: "KASUNGU",
    CITYNAME_HEB: "KASUNGU",
    COUNTRYCODE: "MW",
    COUNTRYNAME: "MALAWI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "KBR",
    AIRPORTNAME: "Pengkalan Chepa",
    CITYCODE: "KBR",
    CITYNAME: "KOTA BHARU",
    CITYNAME_HEB: "KOTA BHARU",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KBT",
    AIRPORTNAME: "Kaben",
    CITYCODE: "KBT",
    CITYNAME: "KABEN",
    CITYNAME_HEB: "KABEN",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "KBU",
    AIRPORTNAME: "Kotabaru",
    CITYCODE: "KBU",
    CITYNAME: "KOTABARU",
    CITYNAME_HEB: "KOTABARU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KBV",
    AIRPORTNAME: "Krabi",
    CITYCODE: "KBV",
    CITYNAME: "KRABI",
    CITYNAME_HEB: "\u05e7\u05e8\u05d0\u05d1\u05d9",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KBX",
    AIRPORTNAME: "Kambuaya",
    CITYCODE: "KBX",
    CITYNAME: "KAMBUAYA",
    CITYNAME_HEB: "KAMBUAYA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KBY",
    AIRPORTNAME: "Streaky Bay",
    CITYCODE: "KBY",
    CITYNAME: "STREAKY BAY",
    CITYNAME_HEB: "STREAKY BAY",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KBZ",
    AIRPORTNAME: "Kaikoura",
    CITYCODE: "KBZ",
    CITYNAME: "KAIKOURA",
    CITYNAME_HEB: "KAIKOURA",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KCA",
    AIRPORTNAME: "Kuqa",
    CITYCODE: "KCA",
    CITYNAME: "KUQA",
    CITYNAME_HEB: "KUQA",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KCB",
    AIRPORTNAME: "Tepoe Airstrip",
    CITYCODE: "KCB",
    CITYNAME: "KASIKASIMA",
    CITYNAME_HEB: "KASIKASIMA",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "ARW",
    AIRPORTNAME: "Arad",
    CITYCODE: "ARW",
    CITYNAME: "ARAD",
    CITYNAME_HEB: "ARAD",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KCC",
    AIRPORTNAME: "Coffman Cove SPB",
    CITYCODE: "KCC",
    CITYNAME: "COFFMAN COVE",
    CITYNAME_HEB: "Coffman Cove SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KCD",
    AIRPORTNAME: "Kamur",
    CITYCODE: "KCD",
    CITYNAME: "KAMUR",
    CITYNAME_HEB: "KAMUR",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KCE",
    AIRPORTNAME: "Collinsville",
    CITYCODE: "KCE",
    CITYNAME: "COLLINSVILLE",
    CITYNAME_HEB: "COLLINSVILLE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KCF",
    AIRPORTNAME: "Kadanwari",
    CITYCODE: "KCF",
    CITYNAME: "KADANWARI",
    CITYNAME_HEB: "KADANWARI",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "KCH",
    AIRPORTNAME: "Kuching",
    CITYCODE: "KCH",
    CITYNAME: "KUCHING",
    CITYNAME_HEB: "KUCHING",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KCI",
    AIRPORTNAME: "Kon",
    CITYCODE: "KCI",
    CITYNAME: "KON",
    CITYNAME_HEB: "KON",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KCJ",
    AIRPORTNAME: "Komaio",
    CITYCODE: "KCJ",
    CITYNAME: "KOMAIO",
    CITYNAME_HEB: "KOMAIO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KCK",
    AIRPORTNAME: "Fairfax Municipal",
    CITYCODE: "KCK",
    CITYNAME: "KANSAS CITY",
    CITYNAME_HEB: "\u05e7\u05e0\u05d6\u05e1 \u05e1\u05d9\u05d8\u05d9",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KBW",
    AIRPORTNAME: "Chignik Bay",
    CITYCODE: "KCL",
    CITYNAME: "CHIGNIK",
    CITYNAME_HEB: "Chignik Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KCG",
    AIRPORTNAME: "Fisheries Chignik",
    CITYCODE: "KCL",
    CITYNAME: "CHIGNIK",
    CITYNAME_HEB: "Fisheries Chignik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ARX",
    AIRPORTNAME: "Asbury Park (closed 1977)",
    CITYCODE: "ARX",
    CITYNAME: "ASBURY PARK",
    CITYNAME_HEB: "ASBURY PARK",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "KCL",
    AIRPORTNAME: "Chignik Lagoon ",
    CITYCODE: "KCL",
    CITYNAME: "CHIGNIK",
    CITYNAME_HEB: "Chignik Lagoon ",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KCQ",
    AIRPORTNAME: "Chignik",
    CITYCODE: "KCL",
    CITYNAME: "CHIGNIK",
    CITYNAME_HEB: "Chignik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KCM",
    AIRPORTNAME: "Kahramanmaras",
    CITYCODE: "KCM",
    CITYNAME: "KAHRAMANMARAS",
    CITYNAME_HEB: "KAHRAMANMARAS",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KCN",
    AIRPORTNAME: "Chernofski",
    CITYCODE: "KCN",
    CITYNAME: "CHERNOFSKI",
    CITYNAME_HEB: "Chernofski",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KCO",
    AIRPORTNAME: "Cengiz Topel",
    CITYCODE: "KCO",
    CITYNAME: "KOCAELI",
    CITYNAME_HEB: "KOCAELI",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KCP",
    AIRPORTNAME: "Kamenets-Podolskiy",
    CITYCODE: "KCP",
    CITYNAME: "KAMENETS PODOLSKI",
    CITYNAME_HEB: "KAMENETS PODOLSKI",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KCR",
    AIRPORTNAME: "Colorado Creek",
    CITYCODE: "KCR",
    CITYNAME: "COLORADO CREEK",
    CITYNAME_HEB: "Colorado Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KCS",
    AIRPORTNAME: "Kings Creek Station",
    CITYCODE: "KCS",
    CITYNAME: "KINGS CREEK STATI",
    CITYNAME_HEB: "KINGS CREEK STATI",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KCU",
    AIRPORTNAME: "Masindi",
    CITYCODE: "KCU",
    CITYNAME: "MASINDI",
    CITYNAME_HEB: "MASINDI",
    COUNTRYCODE: "UG",
    COUNTRYNAME: "UGANDA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "KCZ",
    AIRPORTNAME: "Kochi",
    CITYCODE: "KCZ",
    CITYNAME: "KOCHI",
    CITYNAME_HEB: "KOCHI",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "ARY",
    AIRPORTNAME: "Ararat",
    CITYCODE: "ARY",
    CITYNAME: "ARARAT",
    CITYNAME_HEB: "ARARAT",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KDA",
    AIRPORTNAME: "Kolda",
    CITYCODE: "KDA",
    CITYNAME: "KOLDA",
    CITYNAME_HEB: "KOLDA",
    COUNTRYCODE: "SN",
    COUNTRYNAME: "SENEGAL",
    COUNTRYNAME_HEB: "\u05e1\u05e0\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "KDB",
    AIRPORTNAME: "Kambalda",
    CITYCODE: "KDB",
    CITYNAME: "KAMBALDA",
    CITYNAME_HEB: "KAMBALDA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KDC",
    AIRPORTNAME: "Kandi",
    CITYCODE: "KDC",
    CITYNAME: "KANDI",
    CITYNAME_HEB: "KANDI",
    COUNTRYCODE: "BJ",
    COUNTRYNAME: "BENIN",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KDD",
    AIRPORTNAME: "Khuzdar",
    CITYCODE: "KDD",
    CITYNAME: "KHUZDAR",
    CITYNAME_HEB: "KHUZDAR",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "KDE",
    AIRPORTNAME: "Koroba",
    CITYCODE: "KDE",
    CITYNAME: "KOROBA",
    CITYNAME_HEB: "KOROBA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KDF",
    AIRPORTNAME: "Kouba",
    CITYCODE: "KDF",
    CITYNAME: "KOUBA",
    CITYNAME_HEB: "KOUBA",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KDG",
    AIRPORTNAME: "Kardjali",
    CITYCODE: "KDG",
    CITYNAME: "KARDJALI",
    CITYNAME_HEB: "KARDJALI",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KDH",
    AIRPORTNAME: "Kandahar",
    CITYCODE: "KDH",
    CITYNAME: "KANDAHAR",
    CITYNAME_HEB: "\u05e7\u05d0\u05e0\u05d3\u05d4\u05d0\u05e8",
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05e4\u05d2\u05e0\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "KDI",
    AIRPORTNAME: "Wolter Monginsidi",
    CITYCODE: "KDI",
    CITYNAME: "KENDARI",
    CITYNAME_HEB: "KENDARI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KDJ",
    AIRPORTNAME: "N'Djole",
    CITYCODE: "KDJ",
    CITYNAME: "N'DJOLE",
    CITYNAME_HEB: "N'DJOLE",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "ARZ",
    AIRPORTNAME: null,
    CITYCODE: "ARZ",
    CITYNAME: "ARENZANO",
    CITYNAME_HEB: "\u05d0\u05e8\u05d0\u05e0\u05d6\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KDL",
    AIRPORTNAME: "Kardla",
    CITYCODE: "KDL",
    CITYNAME: "KARDLA",
    CITYNAME_HEB: "KARDLA",
    COUNTRYCODE: "EE",
    COUNTRYNAME: "ESTONIA",
    COUNTRYNAME_HEB: "\u05d0\u05e1\u05d8\u05d5\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KDM",
    AIRPORTNAME: "Kaadedhdhoo",
    CITYCODE: "KDM",
    CITYNAME: "KAADEDHDHOO",
    CITYNAME_HEB: "KAADEDHDHOO",
    COUNTRYCODE: "MV",
    COUNTRYNAME: "MALDIVES ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d3\u05d9\u05d1\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "KDN",
    AIRPORTNAME: "Ndende",
    CITYCODE: "KDN",
    CITYNAME: "NDENDE",
    CITYNAME_HEB: "NDENDE",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KDO",
    AIRPORTNAME: "Kadhdhoo",
    CITYCODE: "KDO",
    CITYNAME: "KADHDHOO",
    CITYNAME_HEB: "KADHDHOO",
    COUNTRYCODE: "MV",
    COUNTRYNAME: "MALDIVES ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d3\u05d9\u05d1\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "KDP",
    AIRPORTNAME: "Kandep",
    CITYCODE: "KDP",
    CITYNAME: "KANDEP",
    CITYNAME_HEB: "KANDEP",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KDQ",
    AIRPORTNAME: "Kamberatoro",
    CITYCODE: "KDQ",
    CITYNAME: "KAMBERATORO",
    CITYNAME_HEB: "KAMBERATORO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KDR",
    AIRPORTNAME: "Kandrian",
    CITYCODE: "KDR",
    CITYNAME: "KANDRIAN",
    CITYNAME_HEB: "KANDRIAN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KDS",
    AIRPORTNAME: "Kamaran Downs",
    CITYCODE: "KDS",
    CITYNAME: "KAMARAN DOWNS",
    CITYNAME_HEB: "KAMARAN DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KDU",
    AIRPORTNAME: "Skardu",
    CITYCODE: "KDU",
    CITYNAME: "SKARDU",
    CITYNAME_HEB: "SKARDU",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "KDV",
    AIRPORTNAME: "Kandavu",
    CITYCODE: "KDV",
    CITYNAME: "KANDAVU",
    CITYNAME_HEB: "KANDAVU",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "ASA",
    AIRPORTNAME: "Assab International Airport",
    CITYCODE: "ASA",
    CITYNAME: "ASSAB",
    CITYNAME_HEB: "ASSAB",
    COUNTRYCODE: "ER",
    COUNTRYNAME: "ERITREA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05d9\u05ea\u05e8\u05d9\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KEA",
    AIRPORTNAME: "Keisah",
    CITYCODE: "KEA",
    CITYNAME: "KEISAH",
    CITYNAME_HEB: "KEISAH",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KEB",
    AIRPORTNAME: "Nanwalek",
    CITYCODE: "KEB",
    CITYNAME: "NANWALEK",
    CITYNAME_HEB: "Nanwalek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KEC",
    AIRPORTNAME: "Kasenga",
    CITYCODE: "KEC",
    CITYNAME: "KASENGA",
    CITYNAME_HEB: "KASENGA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KED",
    AIRPORTNAME: "Kaedi",
    CITYCODE: "KED",
    CITYNAME: "KAEDI",
    CITYNAME_HEB: "KAEDI",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KEE",
    AIRPORTNAME: "Kelle",
    CITYCODE: "KEE",
    CITYNAME: "KELLE",
    CITYNAME_HEB: "KELLE",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KEG",
    AIRPORTNAME: "Keglsugl",
    CITYCODE: "KEG",
    CITYNAME: "KEGLSUGL",
    CITYNAME_HEB: "KEGLSUGL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KEH",
    AIRPORTNAME: "Kenmore Air Harbor",
    CITYCODE: "KEH",
    CITYNAME: "KENMORE AIR HARBO",
    CITYNAME_HEB: "KENMORE AIR HARBO",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KEI",
    AIRPORTNAME: "Kepi",
    CITYCODE: "KEI",
    CITYNAME: "KEPI",
    CITYNAME_HEB: "KEPI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KEJ",
    AIRPORTNAME: "Kemerovo",
    CITYCODE: "KEJ",
    CITYNAME: "KEMEROVO",
    CITYNAME_HEB: "KEMEROVO",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KEK",
    AIRPORTNAME: "Ekwok",
    CITYCODE: "KEK",
    CITYNAME: "EKWOK",
    CITYNAME_HEB: "Ekwok",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ASB",
    AIRPORTNAME: "Ashgabat Airport",
    CITYCODE: "ASB",
    CITYNAME: "ASHGABAD",
    CITYNAME_HEB: "ASHGABAD",
    COUNTRYCODE: "TM",
    COUNTRYNAME: "TURKMENISTAN",
    COUNTRYNAME_HEB:
      "\u05d8\u05d5\u05e8\u05e7\u05d9\u05de\u05d9\u05e0\u05d9\u05e1\u05d8\u05d0\u05df",
  },
  {
    AIRPORTCODE: "KEL",
    AIRPORTNAME: "Holtenau",
    CITYCODE: "KEL",
    CITYNAME: "KIEL",
    CITYNAME_HEB: "\u05e7\u05d9\u05d9\u05dc",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KEM",
    AIRPORTNAME: "Kemi/Tornio",
    CITYCODE: "KEM",
    CITYNAME: "KEMI TORNIO",
    CITYNAME_HEB: "KEMI TORNIO",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KEN",
    AIRPORTNAME: "Kenema",
    CITYCODE: "KEN",
    CITYNAME: "KENEMA",
    CITYNAME_HEB: "KENEMA",
    COUNTRYCODE: "SL",
    COUNTRYNAME: "SIERRA LEONE",
    COUNTRYNAME_HEB:
      "\u05e1\u05d9\u05d9\u05e8\u05d4 \u05dc\u05d9\u05d0\u05d5\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KEO",
    AIRPORTNAME: "Odienne",
    CITYCODE: "KEO",
    CITYNAME: "ODIENNE",
    CITYNAME_HEB: "ODIENNE",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "KEP",
    AIRPORTNAME: "Nepalganj",
    CITYCODE: "KEP",
    CITYNAME: "NEPALGANJ",
    CITYNAME_HEB: "NEPALGANJ",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "KEQ",
    AIRPORTNAME: "Kebar",
    CITYCODE: "KEQ",
    CITYNAME: "KEBAR",
    CITYNAME_HEB: "KEBAR",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KES",
    AIRPORTNAME: "Kelsey",
    CITYCODE: "KES",
    CITYNAME: "KELSEY",
    CITYNAME_HEB: "KELSEY",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "KET",
    AIRPORTNAME: "Keng Tung",
    CITYCODE: "KET",
    CITYNAME: "KENG TUNG",
    CITYNAME_HEB: "KENG TUNG",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "AUX",
    AIRPORTNAME: null,
    CITYCODE: "AUX",
    CITYNAME: "ARAGUAINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XPZ",
    AIRPORTNAME: null,
    CITYCODE: "LTT",
    CITYNAME: "SAINT TROPEZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LTV",
    AIRPORTNAME: "Lotusvale",
    CITYCODE: "LTV",
    CITYNAME: "LOTUSVALE",
    CITYNAME_HEB: "LOTUSVALE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LTW",
    AIRPORTNAME: "St Marys County",
    CITYCODE: "LTW",
    CITYNAME: "LEONARDTOWN",
    CITYNAME_HEB: "LEONARDTOWN",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LUA",
    AIRPORTNAME: "Lukla",
    CITYCODE: "LUA",
    CITYNAME: "LUKLA",
    CITYNAME_HEB: "LUKLA",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "LUB",
    AIRPORTNAME: "Lumid Pau",
    CITYCODE: "LUB",
    CITYNAME: "LUMID PAU",
    CITYNAME_HEB: "LUMID PAU",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "LUC",
    AIRPORTNAME: "Laucala Is",
    CITYCODE: "LUC",
    CITYNAME: "LAUCALA IS",
    CITYNAME_HEB: "LAUCALA IS",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "LUD",
    AIRPORTNAME: "Luderitz",
    CITYCODE: "LUD",
    CITYNAME: "LUDERITZ",
    CITYNAME_HEB: "LUDERITZ",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LUE",
    AIRPORTNAME: "Lucenec",
    CITYCODE: "LUE",
    CITYNAME: "LUCENEC",
    CITYNAME_HEB: "\u05dc\u05d5\u05e6\u05e0\u05e5",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LUG",
    AIRPORTNAME: "Lugano",
    CITYCODE: "LUG",
    CITYNAME: "LUGANO",
    CITYNAME_HEB: "\u05dc\u05d5\u05d2\u05d0\u05e0\u05d5",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "LUH",
    AIRPORTNAME: "Ludhiana",
    CITYCODE: "LUH",
    CITYNAME: "LUDHIANA",
    CITYNAME_HEB: "LUDHIANA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "AUY",
    AIRPORTNAME: "Aneityum",
    CITYCODE: "AUY",
    CITYNAME: "ANEITYUM",
    CITYNAME_HEB: "ANEITYUM",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "LUI",
    AIRPORTNAME: "La Union",
    CITYCODE: "LUI",
    CITYNAME: "LA UNION",
    CITYNAME_HEB: "LA UNION",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "LUJ",
    AIRPORTNAME: "Lusikisiki",
    CITYCODE: "LUJ",
    CITYNAME: "LUSIKISIKI",
    CITYNAME_HEB: "LUSIKISIKI",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "LUL",
    AIRPORTNAME: "Hesler-Noble Field",
    CITYCODE: "LUL",
    CITYNAME: "LAUREL",
    CITYNAME_HEB: "LAUREL",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LUM",
    AIRPORTNAME: "Mangshi",
    CITYCODE: "LUM",
    CITYNAME: "LUXI",
    CITYNAME_HEB: "LUXI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LUN",
    AIRPORTNAME: "Lusaka",
    CITYCODE: "LUN",
    CITYNAME: "LUSAKA",
    CITYNAME_HEB: "LUSAKA",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LUO",
    AIRPORTNAME: "Luena",
    CITYCODE: "LUO",
    CITYNAME: "LUENA",
    CITYNAME_HEB: "LUENA",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LUP",
    AIRPORTNAME: "Kalaupapa",
    CITYCODE: "LUP",
    CITYNAME: "KALAUPAPA",
    CITYNAME_HEB: "KALAUPAPA",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LUQ",
    AIRPORTNAME: null,
    CITYCODE: "LUQ",
    CITYNAME: "SAN LUIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LUR",
    AIRPORTNAME: "Cape Lisburne",
    CITYCODE: "LUR",
    CITYNAME: "CAPE LISBURNE",
    CITYNAME_HEB: "Cape Lisburne",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AUZ",
    AIRPORTNAME: "Municipal Airport",
    CITYCODE: "AUZ",
    CITYNAME: "AURORA",
    CITYNAME_HEB: "AURORA",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LUS",
    AIRPORTNAME: "Lusanga",
    CITYCODE: "LUS",
    CITYNAME: "LUSANGA",
    CITYNAME_HEB: "LUSANGA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "LUT",
    AIRPORTNAME: "Laura Station",
    CITYCODE: "LUT",
    CITYNAME: "LAURA STATION",
    CITYNAME_HEB: "LAURA STATION",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LUU",
    AIRPORTNAME: "Laura",
    CITYCODE: "LUU",
    CITYNAME: "LAURA",
    CITYNAME_HEB: "LAURA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LUV",
    AIRPORTNAME: "Langgur",
    CITYCODE: "LUV",
    CITYNAME: "LANGGUR",
    CITYNAME_HEB: "LANGGUR",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LUW",
    AIRPORTNAME: "Luwuk",
    CITYCODE: "LUW",
    CITYNAME: "LUWUK",
    CITYNAME_HEB: "LUWUK",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LUX",
    AIRPORTNAME: "Luxembourg",
    CITYCODE: "LUX",
    CITYNAME: "LUXEMBOURG",
    CITYNAME_HEB: "\u05dc\u05d5\u05e7\u05e1\u05de\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "LU",
    COUNTRYNAME: "LUXEMBOURG",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05e7\u05e1\u05de\u05d1\u05d5\u05e8\u05d2",
  },
  {
    AIRPORTCODE: "LUY",
    AIRPORTNAME: "Lushoto",
    CITYCODE: "LUY",
    CITYNAME: "LUSHOTO",
    CITYNAME_HEB: "LUSHOTO",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LVA",
    AIRPORTNAME: "Entrammes",
    CITYCODE: "LVA",
    CITYNAME: "LAVAL",
    CITYNAME_HEB: "LAVAL",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LVB",
    AIRPORTNAME: null,
    CITYCODE: "LVB",
    CITYNAME: "LIVRAMENTO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AVB",
    AIRPORTNAME: "Aviano",
    CITYCODE: "AVB",
    CITYNAME: "AVIANO",
    CITYNAME_HEB: "\u05d0\u05d1\u05d9\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "LVD",
    CITYNAME: "LES VILLAGE DARC1800",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LVI",
    AIRPORTNAME: "Livingstone",
    CITYCODE: "LVI",
    CITYNAME: "LIVINGSTONE",
    CITYNAME_HEB:
      "\u05dc\u05d9\u05d1\u05d9\u05e0\u05d2\u05e1\u05d8\u05d5\u05df",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LVK",
    AIRPORTNAME: "Livermore",
    CITYCODE: "LVK",
    CITYNAME: "LIVERMORE",
    CITYNAME_HEB: "LIVERMORE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LVL",
    AIRPORTNAME: "Lawrenceville",
    CITYCODE: "LVL",
    CITYNAME: "LAWRENCEVILLE",
    CITYNAME_HEB: "LAWRENCEVILLE",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "LVM",
    AIRPORTNAME: "Mission Field",
    CITYCODE: "LVM",
    CITYNAME: "LIVINGSTON",
    CITYNAME_HEB: "LIVINGSTON",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LVO",
    AIRPORTNAME: "Laverton",
    CITYCODE: "LVO",
    CITYNAME: "LAVERTON",
    CITYNAME_HEB: "LAVERTON",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LWA",
    AIRPORTNAME: "Lwbak",
    CITYCODE: "LWA",
    CITYNAME: "LWBAK",
    CITYNAME_HEB: "LWBAK",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "LWB",
    AIRPORTNAME: "Greenbrier Valley",
    CITYCODE: "LWB",
    CITYNAME: "LEWISBURG",
    CITYNAME_HEB: "\u05dc\u05d5\u05d0\u05d9\u05e1\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "LWC",
    AIRPORTNAME: "Lawrence",
    CITYCODE: "LWC",
    CITYNAME: "LAWRENCE",
    CITYNAME_HEB: "LAWRENCE",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AVF",
    AIRPORTNAME: "Avoriaz",
    CITYCODE: "AVF",
    CITYNAME: "AVORIAZ",
    CITYNAME_HEB: "\u05d0\u05d1\u05d5\u05e8\u05d9\u05d0\u05d6",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LWE",
    AIRPORTNAME: "Lewoleba",
    CITYCODE: "LWE",
    CITYNAME: "LEWOLEBA",
    CITYNAME_HEB: "LEWOLEBA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LWH",
    AIRPORTNAME: "Lawn Hill",
    CITYCODE: "LWH",
    CITYNAME: "LAWN HILL",
    CITYNAME_HEB: "LAWN HILL",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LWI",
    AIRPORTNAME: "Lowai",
    CITYCODE: "LWI",
    CITYNAME: "LOWAI",
    CITYNAME_HEB: "LOWAI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LWL",
    AIRPORTNAME: "Harriet Field",
    CITYCODE: "LWL",
    CITYNAME: "WELLS",
    CITYNAME_HEB: "WELLS",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LWM",
    AIRPORTNAME: "Lawrence",
    CITYCODE: "LWM",
    CITYNAME: "LAWRENCE",
    CITYNAME_HEB: "LAWRENCE",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "LWO",
    AIRPORTNAME: "Snilow",
    CITYCODE: "LWO",
    CITYNAME: "LVOV",
    CITYNAME_HEB: "\u05dc\u05d1\u05d5\u05d1",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "LWR",
    AIRPORTNAME: "Leeuwarden",
    CITYCODE: "LWR",
    CITYNAME: "LEEUWARDEN",
    CITYNAME_HEB: "LEEUWARDEN",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "LWS",
    AIRPORTNAME: "Nez Perce County Rgnl",
    CITYCODE: "LWS",
    CITYNAME: "LEWISTON",
    CITYNAME_HEB: "LEWISTON",
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d9\u05d3\u05d4\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LWT",
    AIRPORTNAME: "Municipal",
    CITYCODE: "LWT",
    CITYNAME: "LEWISTOWN",
    CITYNAME_HEB: "LEWISTOWN",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AVG",
    AIRPORTNAME: "Auvergne",
    CITYCODE: "AVG",
    CITYNAME: "AUVERGNE",
    CITYNAME_HEB: "AUVERGNE",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LWV",
    AIRPORTNAME: "Municipal",
    CITYCODE: "LWV",
    CITYNAME: "LAWRENCEVILLE",
    CITYNAME_HEB: "LAWRENCEVILLE",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LWY",
    AIRPORTNAME: "Lawas",
    CITYCODE: "LWY",
    CITYNAME: "LAWAS",
    CITYNAME_HEB: "LAWAS",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LXA",
    AIRPORTNAME: "Lhasa",
    CITYCODE: "LXA",
    CITYNAME: "LHASA",
    CITYNAME_HEB: "LHASA",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LXG",
    AIRPORTNAME: null,
    CITYCODE: "LXG",
    CITYNAME: "LUANG NAMTHA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LXI",
    AIRPORTNAME: "Linxi",
    CITYCODE: "LXI",
    CITYNAME: "LINXI",
    CITYNAME_HEB: "LINXI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LXN",
    AIRPORTNAME: "Lexington",
    CITYCODE: "LXN",
    CITYNAME: "LEXINGTON",
    CITYNAME_HEB: "LEXINGTON",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LXS",
    AIRPORTNAME: "Lemnos",
    CITYCODE: "LXS",
    CITYNAME: "LEMNOS",
    CITYNAME_HEB: "LEMNOS",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "LXU",
    AIRPORTNAME: "Lukulu",
    CITYCODE: "LXU",
    CITYNAME: "LUKULU",
    CITYNAME_HEB: "LUKULU",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LXV",
    AIRPORTNAME: "Leadville",
    CITYCODE: "LXV",
    CITYNAME: "LEADVILLE",
    CITYNAME_HEB: "LEADVILLE",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LYA",
    AIRPORTNAME: "Luoyang",
    CITYCODE: "LYA",
    CITYNAME: "LUOYANG",
    CITYNAME_HEB: "LUOYANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "AVI",
    AIRPORTNAME: "Maximo Gomez",
    CITYCODE: "AVI",
    CITYNAME: "CIEGO DE AVILA",
    CITYNAME_HEB: "CIEGO DE AVILA",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "LYB",
    AIRPORTNAME: "Little Cayman",
    CITYCODE: "LYB",
    CITYNAME: "LITTLE CAYMAN",
    CITYNAME_HEB: "LITTLE CAYMAN",
    COUNTRYCODE: "KY",
    COUNTRYNAME: "CAYMAN ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e7\u05d9\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "LYC",
    AIRPORTNAME: "Lycksele",
    CITYCODE: "LYC",
    CITYNAME: "LYCKSELE",
    CITYNAME_HEB: "LYCKSELE",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LYE",
    AIRPORTNAME: "RAF Station",
    CITYCODE: "LYE",
    CITYNAME: "LYNEHAM",
    CITYNAME_HEB: "LYNEHAM",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LYG",
    AIRPORTNAME: "Lianyungang",
    CITYCODE: "LYG",
    CITYNAME: "LIANYUNGANG",
    CITYNAME_HEB: "LIANYUNGANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LYH",
    AIRPORTNAME: "Preston-Glenn Field",
    CITYCODE: "LYH",
    CITYNAME: "LYNCHBURG",
    CITYNAME_HEB: "LYNCHBURG",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "LYI",
    AIRPORTNAME: "Linyi",
    CITYCODE: "LYI",
    CITYNAME: "LINYI",
    CITYNAME_HEB: "LINYI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LYK",
    AIRPORTNAME: "Lunyuk",
    CITYCODE: "LYK",
    CITYNAME: "LUNYUK",
    CITYNAME_HEB: "LUNYUK",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LYO",
    AIRPORTNAME: "Rice County Municipal",
    CITYCODE: "LYO",
    CITYNAME: "LYONS",
    CITYNAME_HEB: "LYONS",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LYP",
    AIRPORTNAME: "Faisalabad",
    CITYCODE: "LYP",
    CITYNAME: "FAISALABAD",
    CITYNAME_HEB: "FAISALABAD",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "LYR",
    AIRPORTNAME: "Svalbard",
    CITYCODE: "LYR",
    CITYNAME: "LONGYEARBYEN",
    CITYNAME_HEB: "LONGYEARBYEN",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AVK",
    AIRPORTNAME: "Arvaikheer",
    CITYCODE: "AVK",
    CITYNAME: "ARVAIKHEER",
    CITYNAME_HEB: "ARVAIKHEER",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LYS",
    AIRPORTNAME: "Satolas",
    CITYCODE: "LYS",
    CITYNAME: "LYON",
    CITYNAME_HEB: "\u05dc\u05d9\u05d5\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LYN",
    AIRPORTNAME: "Bron",
    CITYCODE: "LYS",
    CITYNAME: "LYON",
    CITYNAME_HEB: "\u05dc\u05d9\u05d5\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LYT",
    AIRPORTNAME: "Lady Elliot Island",
    CITYCODE: "LYT",
    CITYNAME: "LADY ELLIOT ISLAN",
    CITYNAME_HEB: "LADY ELLIOT ISLAN",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LYU",
    AIRPORTNAME: "Ely",
    CITYCODE: "LYU",
    CITYNAME: "ELY",
    CITYNAME_HEB: "ELY",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LYX",
    AIRPORTNAME: "Lydd International Airport",
    CITYCODE: "LYX",
    CITYNAME: "LYDD",
    CITYNAME_HEB: "LYDD",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LZA",
    AIRPORTNAME: "Luiza",
    CITYCODE: "LZA",
    CITYNAME: "LUIZA",
    CITYNAME_HEB: "LUIZA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "AVL",
    AIRPORTNAME: "Hendersonville",
    CITYCODE: "AVL",
    CITYNAME: "ASHEVILLE",
    CITYNAME_HEB: "\u05d0\u05e9\u05d5\u05d5\u05d9\u05dc",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LZC",
    AIRPORTNAME: "Lazaro Cardenas",
    CITYCODE: "LZC",
    CITYNAME: "LAZARO CARDENAS",
    CITYNAME_HEB: "LAZARO CARDENAS",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "LZH",
    AIRPORTNAME: "Liuzhou",
    CITYCODE: "LZH",
    CITYNAME: "LIUZHOU",
    CITYNAME_HEB: "LIUZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LZI",
    AIRPORTNAME: "Luozi",
    CITYCODE: "LZI",
    CITYNAME: "LUOZI",
    CITYNAME_HEB: "LUOZI",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "LZM",
    AIRPORTNAME: "Luzamba",
    CITYCODE: "LZM",
    CITYNAME: "LUZAMBA",
    CITYNAME_HEB: "LUZAMBA",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LZO",
    AIRPORTNAME: "Luzhou",
    CITYCODE: "LZO",
    CITYNAME: "LUZHOU",
    CITYNAME_HEB: "LUZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LZR",
    AIRPORTNAME: "Lizard Island",
    CITYCODE: "LZR",
    CITYNAME: "LIZARD ISLAND",
    CITYNAME_HEB: "LIZARD ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MAA",
    AIRPORTNAME: "Chennai",
    CITYCODE: "MAA",
    CITYNAME: "CHENNAI",
    CITYNAME_HEB: "CHENNAI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "MAB",
    AIRPORTNAME: null,
    CITYCODE: "MAB",
    CITYNAME: "MARABA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MAD",
    AIRPORTNAME: "Barajas",
    CITYCODE: "MAD",
    CITYNAME: "MADRID",
    CITYNAME_HEB: "\u05de\u05d3\u05e8\u05d9\u05d3",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "AVN",
    AIRPORTNAME: "Avignon-Caum",
    CITYCODE: "AVN",
    CITYNAME: "AVIGNON",
    CITYNAME_HEB: "\u05d0\u05d1\u05d9\u05e0\u05d9\u05d5\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "MAE",
    AIRPORTNAME: "Madera",
    CITYCODE: "MAE",
    CITYNAME: "MADERA",
    CITYNAME_HEB: "MADERA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MAF",
    AIRPORTNAME: "Odessa Regional",
    CITYCODE: "MAF",
    CITYNAME: "MIDLAND",
    CITYNAME_HEB: "MIDLAND",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MDD",
    AIRPORTNAME: null,
    CITYCODE: "MAF",
    CITYNAME: "MIDLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MAG",
    AIRPORTNAME: " ",
    CITYCODE: "MAG",
    CITYNAME: "MALGA CIAPELA",
    CITYNAME_HEB:
      "\u05de\u05dc\u05d2\u05d4 \u05e7\u05d9\u05d0\u05e4\u05dc\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MAH",
    AIRPORTNAME: "Menorca",
    CITYCODE: "MAH",
    CITYNAME: "MENORCA",
    CITYNAME_HEB: "MENORCA",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "MAI",
    CITYNAME: "MAISHOFEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MAJ",
    AIRPORTNAME: "Amata Kabua International",
    CITYCODE: "MAJ",
    CITYNAME: "MAJURO",
    CITYNAME_HEB: "MAJURO",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "MAK",
    AIRPORTNAME: "Malakal",
    CITYCODE: "MAK",
    CITYNAME: "MALAKAL",
    CITYNAME_HEB: "MALAKAL",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "MAM",
    AIRPORTNAME: "Matamoros",
    CITYCODE: "MAM",
    CITYNAME: "MATAMOROS",
    CITYNAME_HEB: "MATAMOROS",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "AVO",
    AIRPORTNAME: "Municipal",
    CITYCODE: "AVO",
    CITYNAME: "AVON PARK",
    CITYNAME_HEB: "AVON PARK",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MAN",
    AIRPORTNAME: "International",
    CITYCODE: "MAN",
    CITYNAME: "MANCHESTER",
    CITYNAME_HEB: "\u05de\u05e0\u05e6\u05e1\u05d8\u05e8",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QQM",
    AIRPORTNAME: " ",
    CITYCODE: "MAN",
    CITYNAME: "MANCHESTER",
    CITYNAME_HEB: "\u05de\u05e0\u05e6\u05e1\u05d8\u05e8",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZMP",
    AIRPORTNAME: " ",
    CITYCODE: "MAN",
    CITYNAME: "MANCHESTER",
    CITYNAME_HEB: "\u05de\u05e0\u05e6\u05e1\u05d8\u05e8",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MAC",
    AIRPORTNAME: " ",
    CITYCODE: "MAC",
    CITYNAME: "MAIORI",
    CITYNAME_HEB: "\u05de\u05d0\u05d9\u05d5\u05e8\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MAP",
    AIRPORTNAME: "Mamai",
    CITYCODE: "MAP",
    CITYNAME: "MAMAI",
    CITYNAME_HEB: "MAMAI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MAQ",
    AIRPORTNAME: "Mae Sot",
    CITYCODE: "MAQ",
    CITYNAME: "MAE SOT",
    CITYNAME_HEB: "MAE SOT",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "MAR",
    AIRPORTNAME: " ",
    CITYCODE: "MAR",
    CITYNAME: "MOROCCO",
    CITYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "MAS",
    AIRPORTNAME: " ",
    CITYCODE: "MAS",
    CITYNAME: "MASSALUBRENSE",
    CITYNAME_HEB:
      "\u05de\u05d0\u05e1\u05d0\u05dc\u05d5\u05d1\u05e8\u05e0\u05e1\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MAT",
    AIRPORTNAME: "Matadi",
    CITYCODE: "MAT",
    CITYNAME: "MATADI",
    CITYNAME_HEB: "MATADI",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "MAU",
    AIRPORTNAME: "Maupiti",
    CITYCODE: "MAU",
    CITYNAME: "MAUPITI",
    CITYNAME_HEB: "MAUPITI",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "ACA",
    AIRPORTNAME: "Acapulco International Airport",
    CITYCODE: "ACA",
    CITYNAME: "ACAPULCO",
    CITYNAME_HEB: "\u05d0\u05e7\u05e4\u05d5\u05dc\u05e7\u05d5",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "AVP",
    AIRPORTNAME: null,
    CITYCODE: "AVP",
    CITYNAME: "SCRANTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "MAV",
    CITYNAME: "MARNE LA VALLEE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MAW",
    AIRPORTNAME: "Malden",
    CITYCODE: "MAW",
    CITYNAME: "MALDEN",
    CITYNAME_HEB: "MALDEN",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MAX",
    AIRPORTNAME: "Matam",
    CITYCODE: "MAX",
    CITYNAME: "MATAM",
    CITYNAME_HEB: "MATAM",
    COUNTRYCODE: "SN",
    COUNTRYNAME: "SENEGAL",
    COUNTRYNAME_HEB: "\u05e1\u05e0\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "MAY",
    AIRPORTNAME: "Mangrove Cay",
    CITYCODE: "MAY",
    CITYNAME: "MANGROVE CAY",
    CITYNAME_HEB: "MANGROVE CAY",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MAZ",
    AIRPORTNAME: "Eugenio M De Hostos",
    CITYCODE: "MAZ",
    CITYNAME: "MAYAGUEZ",
    CITYNAME_HEB: "MAYAGUEZ",
    COUNTRYCODE: "PR",
    COUNTRYNAME: "PUERTO RICO",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e8\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "MBA",
    AIRPORTNAME: "Moi International",
    CITYCODE: "MBA",
    CITYNAME: "MOMBASA",
    CITYNAME_HEB: "\u05de\u05d5\u05de\u05d1\u05e1\u05d4",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MBB",
    AIRPORTNAME: "Marble Bar",
    CITYCODE: "MBB",
    CITYNAME: "MARBLE BAR",
    CITYNAME_HEB: "MARBLE BAR",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MBC",
    AIRPORTNAME: "Mbigou",
    CITYCODE: "MBC",
    CITYNAME: "MBIGOU",
    CITYNAME_HEB: "MBIGOU",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MBD",
    AIRPORTNAME: "International",
    CITYCODE: "MBD",
    CITYNAME: "MMABATHO",
    CITYNAME_HEB: "MMABATHO",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "MBE",
    AIRPORTNAME: "Monbetsu",
    CITYCODE: "MBE",
    CITYNAME: "MONBETSU",
    CITYNAME_HEB: "MONBETSU",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "SCR",
    AIRPORTNAME: null,
    CITYCODE: "AVP",
    CITYNAME: "SCRANTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MBF",
    AIRPORTNAME: "Mount Buffalo",
    CITYCODE: "MBF",
    CITYNAME: "MOUNT BUFFALO",
    CITYNAME_HEB: "MOUNT BUFFALO",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MBG",
    AIRPORTNAME: "Mobridge",
    CITYCODE: "MBG",
    CITYNAME: "MOBRIDGE",
    CITYNAME_HEB: "MOBRIDGE",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MBH",
    AIRPORTNAME: "Maryborough",
    CITYCODE: "MBH",
    CITYNAME: "MARYBOROUGH",
    CITYNAME_HEB: "MARYBOROUGH",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MBI",
    AIRPORTNAME: "Mbeya",
    CITYCODE: "MBI",
    CITYNAME: "MBEYA",
    CITYNAME_HEB: "MBEYA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MBJ",
    AIRPORTNAME: "Sangster International",
    CITYCODE: "MBJ",
    CITYNAME: "MONTEGO BAY",
    CITYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05d2\u05d5 \u05d1\u05d9\u05d9",
    COUNTRYCODE: "JM",
    COUNTRYNAME: "JAMAICA",
    COUNTRYNAME_HEB: "\u05d2'\u05de\u05d9\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "MBK",
    AIRPORTNAME: null,
    CITYCODE: "MBK",
    CITYNAME: "MATUPA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MBL",
    AIRPORTNAME: "Blacker",
    CITYCODE: "MBL",
    CITYNAME: "MANISTEE",
    CITYNAME_HEB: "MANISTEE",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MBM",
    AIRPORTNAME: "Mkambati",
    CITYCODE: "MBM",
    CITYNAME: "MKAMBATI",
    CITYNAME_HEB: "MKAMBATI",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "MBN",
    AIRPORTNAME: "Mt Barnett",
    CITYCODE: "MBN",
    CITYNAME: "MOUNT BARNETT",
    CITYNAME_HEB: "MOUNT BARNETT",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MBO",
    AIRPORTNAME: "Mamburao",
    CITYCODE: "MBO",
    CITYNAME: "MAMBURAO",
    CITYNAME_HEB: "MAMBURAO",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "WBW",
    AIRPORTNAME: "Wyoming Valle",
    CITYCODE: "AVP",
    CITYNAME: "SCRANTON",
    CITYNAME_HEB: "SCRANTON",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MBP",
    AIRPORTNAME: "Moyobamba",
    CITYCODE: "MBP",
    CITYNAME: "MOYOBAMBA",
    CITYNAME_HEB: "MOYOBAMBA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "MBQ",
    AIRPORTNAME: "Mbarara",
    CITYCODE: "MBQ",
    CITYNAME: "MBARARA",
    CITYNAME_HEB: "MBARARA",
    COUNTRYCODE: "UG",
    COUNTRYNAME: "UGANDA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "MBR",
    AIRPORTNAME: "Mbout",
    CITYCODE: "MBR",
    CITYNAME: "MBOUT",
    CITYNAME_HEB: "MBOUT",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MBS",
    AIRPORTNAME: "Tri City Airport",
    CITYCODE: "MBS",
    CITYNAME: "SAGINAW",
    CITYNAME_HEB: "SAGINAW",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MBT",
    AIRPORTNAME: "Masbate",
    CITYCODE: "MBT",
    CITYNAME: "MASBATE",
    CITYNAME_HEB: "MASBATE",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "MBU",
    AIRPORTNAME: "Mbambanakira",
    CITYCODE: "MBU",
    CITYNAME: "MBAMBANAKIRA",
    CITYNAME_HEB: "MBAMBANAKIRA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "LRV",
    AIRPORTNAME: "Los Roques",
    CITYCODE: "LRV",
    CITYNAME: "LOS ROQUES",
    CITYNAME_HEB: "LOS ROQUES",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "AUU",
    AIRPORTNAME: "Aurukun Mission",
    CITYCODE: "AUU",
    CITYNAME: "AURUKUN MISSION",
    CITYNAME_HEB: "AURUKUN MISSION",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LSA",
    AIRPORTNAME: "Losuia",
    CITYCODE: "LSA",
    CITYNAME: "LOSUIA",
    CITYNAME_HEB: "LOSUIA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LSB",
    AIRPORTNAME: " ",
    CITYCODE: "LSB",
    CITYNAME: "LIGNANO SABBIADORO",
    CITYNAME_HEB:
      "\u05dc\u05d9\u05d2\u05d0\u05e0\u05d5 \u05e1\u05d0\u05d1\u05d9\u05d3\u05d5\u05e8\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LSC",
    AIRPORTNAME: "La Florida",
    CITYCODE: "LSC",
    CITYNAME: "LA SERENA",
    CITYNAME_HEB: "LA SERENA",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LSE",
    AIRPORTNAME: "Municipal",
    CITYCODE: "LSE",
    CITYNAME: "LA CROSSE",
    CITYNAME_HEB: "LA CROSSE",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LSH",
    AIRPORTNAME: "Lashio",
    CITYCODE: "LSH",
    CITYNAME: "LASHIO",
    CITYNAME_HEB: "LASHIO",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "LSJ",
    AIRPORTNAME: "Long Island",
    CITYCODE: "LSJ",
    CITYNAME: "LONG ISLAND",
    CITYNAME_HEB: "LONG ISLAND",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LSK",
    AIRPORTNAME: "Lusk",
    CITYCODE: "LSK",
    CITYNAME: "LUSK",
    CITYNAME_HEB: "LUSK",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LSL",
    AIRPORTNAME: "Los Chiles",
    CITYCODE: "LSL",
    CITYNAME: "LOS CHILES",
    CITYNAME_HEB: "LOS CHILES",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "LSM",
    AIRPORTNAME: "Lawas",
    CITYCODE: "LSM",
    CITYNAME: "LONG SEMADO",
    CITYNAME_HEB: "LONG SEMADO",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LSN",
    AIRPORTNAME: " ",
    CITYCODE: "LSN",
    CITYNAME: "VELKE LOSINY",
    CITYNAME_HEB:
      "\u05d5\u05dc\u05e7\u05d4 \u05dc\u05d5\u05e1\u05d9\u05e0\u05d9",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AUV",
    AIRPORTNAME: "Aumo",
    CITYCODE: "AUV",
    CITYNAME: "AUMO",
    CITYNAME_HEB: "AUMO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LSO",
    AIRPORTNAME: "Talmont",
    CITYCODE: "LSO",
    CITYNAME: "LES SABLES",
    CITYNAME_HEB: "LES SABLES",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LSP",
    AIRPORTNAME: "Josefa Camejo",
    CITYCODE: "LSP",
    CITYNAME: "LAS PIEDRAS",
    CITYNAME_HEB: "LAS PIEDRAS",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LSQ",
    AIRPORTNAME: "Los Angeles",
    CITYCODE: "LSQ",
    CITYNAME: "LOS ANGELES",
    CITYNAME_HEB: "\u05dc\u05d5\u05e1 \u05d0\u05e0\u05d2\u05dc\u05e1",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LSR",
    AIRPORTNAME: "Lost River",
    CITYCODE: "LSR",
    CITYNAME: "LOST RIVER",
    CITYNAME_HEB: "Lost River",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LSS",
    AIRPORTNAME: "Terre-de-Haut",
    CITYCODE: "LSS",
    CITYNAME: "TERRE DE HAUT",
    CITYNAME_HEB: "TERRE DE HAUT",
    COUNTRYCODE: "GP",
    COUNTRYNAME: "GUADELOUPE",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d2\u05d5\u05d5\u05d3\u05d0\u05dc\u05d5\u05e4",
  },
  {
    AIRPORTCODE: "LST",
    AIRPORTNAME: null,
    CITYCODE: "LST",
    CITYNAME: "LAUNCESTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUTS",
    COUNTRYNAME: "TASMANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LSU",
    AIRPORTNAME: "Long Sukang",
    CITYCODE: "LSU",
    CITYNAME: "LONG SUKANG",
    CITYNAME_HEB: "LONG SUKANG",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LSW",
    AIRPORTNAME: "Lhoksumawe",
    CITYCODE: "LSW",
    CITYNAME: "LHOKSUMAWE",
    CITYNAME_HEB: "LHOKSUMAWE",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LSX",
    AIRPORTNAME: "Lhok Sukon",
    CITYCODE: "LSX",
    CITYNAME: "LHOK SUKON",
    CITYNAME_HEB: "LHOK SUKON",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LSY",
    AIRPORTNAME: "Lismore",
    CITYCODE: "LSY",
    CITYNAME: "LISMORE",
    CITYNAME_HEB: "LISMORE",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AUW",
    AIRPORTNAME: "Municipal",
    CITYCODE: "AUW",
    CITYNAME: "WAUSAU",
    CITYNAME_HEB: "WAUSAU",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LSZ",
    AIRPORTNAME: "Losinj Airport",
    CITYCODE: "LSZ",
    CITYNAME: "MALI LOSINJ",
    CITYNAME_HEB: "MALI LOSINJ",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LTA",
    AIRPORTNAME: "Letaba",
    CITYCODE: "LTA",
    CITYNAME: "TZANEEN",
    CITYNAME_HEB: "TZANEEN",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "LTB",
    AIRPORTNAME: null,
    CITYCODE: "LTB",
    CITYNAME: "LATROBE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUTS",
    COUNTRYNAME: "TASMANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LTC",
    AIRPORTNAME: "Lai",
    CITYCODE: "LTC",
    CITYNAME: "LAI",
    CITYNAME_HEB: "LAI",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "LTD",
    AIRPORTNAME: "Ghadames",
    CITYCODE: "LTD",
    CITYNAME: "GHADAMES",
    CITYNAME_HEB: "GHADAMES",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "LTF",
    AIRPORTNAME: "Leitre",
    CITYCODE: "LTF",
    CITYNAME: "LEITRE",
    CITYNAME_HEB: "LEITRE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LTG",
    AIRPORTNAME: "Langtang",
    CITYCODE: "LTG",
    CITYNAME: "LANGTANG",
    CITYNAME_HEB: "LANGTANG",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "LTH",
    AIRPORTNAME: "Lathrop Wells",
    CITYCODE: "LTH",
    CITYNAME: "LATHROP WELLS",
    CITYNAME_HEB: "LATHROP WELLS",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LTI",
    AIRPORTNAME: "Altai",
    CITYCODE: "LTI",
    CITYNAME: "ALTAI",
    CITYNAME_HEB: "ALTAI",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA                -",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LTK",
    AIRPORTNAME: "Latakia",
    CITYCODE: "LTK",
    CITYNAME: "LATAKIA",
    CITYNAME_HEB: "LATAKIA",
    COUNTRYCODE: "SY",
    COUNTRYNAME: "SYRIAN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CWA",
    AIRPORTNAME: "Central Wisconsin",
    CITYCODE: "AUW",
    CITYNAME: "WAUSAU",
    CITYNAME_HEB: "WAUSAU",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LTL",
    AIRPORTNAME: "Lastourville",
    CITYCODE: "LTL",
    CITYNAME: "LASTOURVILLE",
    CITYNAME_HEB: "LASTOURVILLE",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "LTM",
    AIRPORTNAME: " ",
    CITYCODE: "LTM",
    CITYNAME: "LETOJANNI",
    CITYNAME_HEB: "\u05dc\u05d8\u05d5\u05d0\u05e0\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LTO",
    AIRPORTNAME: "Loreto",
    CITYCODE: "LTO",
    CITYNAME: "LORETO",
    CITYNAME_HEB: "LORETO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "LTP",
    AIRPORTNAME: "Lyndhurst",
    CITYCODE: "LTP",
    CITYNAME: "LYNDHURST",
    CITYNAME_HEB: "LYNDHURST",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LTQ",
    AIRPORTNAME: "Le Touquet",
    CITYCODE: "LTQ",
    CITYNAME: "LE TOUQUET",
    CITYNAME_HEB: "LE TOUQUET",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LTR",
    AIRPORTNAME: "Letterkenny",
    CITYCODE: "LTR",
    CITYNAME: "LETTERKENNY",
    CITYNAME_HEB: "LETTERKENNY",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "AXS",
    AIRPORTNAME: "Municipal",
    CITYCODE: "LTS",
    CITYNAME: "ALTUS",
    CITYNAME_HEB: "ALTUS",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LTS",
    AIRPORTNAME: null,
    CITYCODE: "LTS",
    CITYNAME: "ALTUS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "JSZ",
    AIRPORTNAME: null,
    CITYCODE: "LTT",
    CITYNAME: "SAINT TROPEZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LTT",
    AIRPORTNAME: "La Mole",
    CITYCODE: "LTT",
    CITYNAME: "SAINT TROPEZ",
    CITYNAME_HEB: "\u05e1\u05d0\u05d5 \u05d8\u05e8\u05d5\u05e4\u05d6",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "SNN",
    AIRPORTNAME: "Shannon",
    CITYCODE: "SNN",
    CITYNAME: "SHANNON",
    CITYNAME_HEB: "\u05e9\u05d0\u05e0\u05d5\u05df",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SNO",
    AIRPORTNAME: "Sakon Nakhon",
    CITYCODE: "SNO",
    CITYNAME: "SAKON NAKHON",
    CITYNAME_HEB: "SAKON NAKHON",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SNP",
    AIRPORTNAME: "Saint Paul Island",
    CITYCODE: "SNP",
    CITYNAME: "SAINT PAUL ISLAND",
    CITYNAME_HEB: "Saint Paul Island",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SNQ",
    AIRPORTNAME: "San Quintin",
    CITYCODE: "SNQ",
    CITYNAME: "SAN QUINTIN",
    CITYNAME_HEB: "SAN QUINTIN",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "SNR",
    AIRPORTNAME: "Montoir",
    CITYCODE: "SNR",
    CITYNAME: "SAINT NAZAIRE",
    CITYNAME_HEB: "SAINT NAZAIRE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "SNS",
    AIRPORTNAME: "Salinas",
    CITYCODE: "SNS",
    CITYNAME: "SALINAS",
    CITYNAME_HEB: "SALINAS",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SNT",
    AIRPORTNAME: "Sabana De Torres",
    CITYCODE: "SNT",
    CITYNAME: "SABANA DE TORRES",
    CITYNAME_HEB: "SABANA DE TORRES",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SNU",
    AIRPORTNAME: "Santa Clara",
    CITYCODE: "SNU",
    CITYNAME: "SANTA CLARA",
    CITYNAME_HEB: "\u05e1\u05e0\u05d8\u05d4 \u05e7\u05dc\u05e8\u05d4",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "SNV",
    AIRPORTNAME: "Santa Elena",
    CITYCODE: "SNV",
    CITYNAME: "SANTA ELENA",
    CITYNAME_HEB: "SANTA ELENA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "BKJ",
    AIRPORTNAME: "Boke",
    CITYCODE: "BKJ",
    CITYNAME: "BOKE",
    CITYNAME_HEB: "BOKE",
    COUNTRYCODE: "GN",
    COUNTRYNAME: "GUINEA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SNW",
    AIRPORTNAME: "Thandwe",
    CITYCODE: "SNW",
    CITYNAME: "THANDWE",
    CITYNAME_HEB: "THANDWE",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "SNX",
    AIRPORTNAME: "Sabana De Mar",
    CITYCODE: "SNX",
    CITYNAME: "SABANA DE MAR",
    CITYNAME_HEB: "SABANA DE MAR",
    COUNTRYCODE: "DO",
    COUNTRYNAME: "DOMINICAN REPUBLIC",
    COUNTRYNAME_HEB:
      "\u05d4\u05e8\u05e4\u05d5\u05d1\u05dc\u05d9\u05e7\u05d4 \u05d4\u05d3\u05d5\u05de\u05d9\u05e0\u05d9\u05e7\u05e0\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "SNY",
    AIRPORTNAME: "Sidney",
    CITYCODE: "SNY",
    CITYNAME: "SIDNEY",
    CITYNAME_HEB: "SIDNEY (US)",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SNZ",
    AIRPORTNAME: null,
    CITYCODE: "SNZ",
    CITYNAME: "SANTA CRUZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SOA",
    AIRPORTNAME: " ",
    CITYCODE: "SOA",
    CITYNAME: "SOUTH AFRICA",
    CITYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "SOB",
    AIRPORTNAME: "Saarmelleek/Balaton",
    CITYCODE: "SOB",
    CITYNAME: "SAARMELLEEK",
    CITYNAME_HEB: "SAARMELLEEK",
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SOC",
    AIRPORTNAME: "Adi Sumarmo",
    CITYCODE: "SOC",
    CITYNAME: "SOLO CITY",
    CITYNAME_HEB: "SOLO CITY",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SOD",
    AIRPORTNAME: null,
    CITYCODE: "SOD",
    CITYNAME: "SOROCABA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SOE",
    AIRPORTNAME: " ",
    CITYCODE: "SOE",
    CITYNAME: "SOELL",
    CITYNAME_HEB: "\u05e1\u05d5\u05d0\u05dc",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SOF",
    AIRPORTNAME: "Vrazhdebna",
    CITYCODE: "SOF",
    CITYNAME: "SOFIA",
    CITYNAME_HEB: "\u05e1\u05d5\u05e4\u05d9\u05d4",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BKK",
    AIRPORTNAME: "Suvarnabhumi International",
    CITYCODE: "BKK",
    CITYNAME: "BANGKOK",
    CITYNAME_HEB: "\u05d1\u05e0\u05d2\u05e7\u05d5\u05e7",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SOG",
    AIRPORTNAME: "Haukasen",
    CITYCODE: "SOG",
    CITYNAME: "SOGNDAL",
    CITYNAME_HEB: "SOGNDAL",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SOH",
    AIRPORTNAME: "Solita",
    CITYCODE: "SOH",
    CITYNAME: "SOLITA",
    CITYNAME_HEB: "SOLITA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SOI",
    AIRPORTNAME: "South Molle Island",
    CITYCODE: "SOI",
    CITYNAME: "SOUTH MOLLE ISLAN",
    CITYNAME_HEB: "SOUTH MOLLE ISLAN",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SOJ",
    AIRPORTNAME: "Sorkjosen",
    CITYCODE: "SOJ",
    CITYNAME: "SORKJOSEN",
    CITYNAME_HEB: "SORKJOSEN",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SOK",
    AIRPORTNAME: "Semongkong",
    CITYCODE: "SOK",
    CITYNAME: "SEMONGKONG",
    CITYNAME_HEB: "SEMONGKONG",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "SOL",
    AIRPORTNAME: " ",
    CITYCODE: "SOL",
    CITYNAME: "SOLDEU EL TARTER",
    CITYNAME_HEB: "\u05e1\u05d5\u05dc\u05d3\u05d0\u05d5",
    COUNTRYCODE: "AD",
    COUNTRYNAME: "ANDORRA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d3\u05d5\u05e8\u05d4",
  },
  {
    AIRPORTCODE: "SOM",
    AIRPORTNAME: "El Tigre",
    CITYCODE: "SOM",
    CITYNAME: "SAN TOME",
    CITYNAME_HEB: "SAN TOME",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "SON",
    AIRPORTNAME: "Pekoa",
    CITYCODE: "SON",
    CITYNAME: "ESPIRITU SANTO",
    CITYNAME_HEB: "ESPIRITU SANTO",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "SOO",
    AIRPORTNAME: "Soderhamn",
    CITYCODE: "SOO",
    CITYNAME: "SODERHAMN",
    CITYNAME_HEB: "SODERHAMN",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BKM",
    AIRPORTNAME: "Bakalalan",
    CITYCODE: "BKM",
    CITYNAME: "BAKALALAN",
    CITYNAME_HEB: "BAKALALAN",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "SOP",
    CITYNAME: "SOPRON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SOQ",
    AIRPORTNAME: "Jefman",
    CITYCODE: "SOQ",
    CITYNAME: "SORONG",
    CITYNAME_HEB: "SORONG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SOR",
    AIRPORTNAME: "Al Thaurah",
    CITYCODE: "SOR",
    CITYNAME: "AL THAURAH",
    CITYNAME_HEB: "AL THAURAH",
    COUNTRYCODE: "SY",
    COUNTRYNAME: "SYRIAN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SOT",
    AIRPORTNAME: "Sodankyla",
    CITYCODE: "SOT",
    CITYNAME: "SODANKYLA",
    CITYNAME_HEB: "SODANKYLA",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SOU",
    AIRPORTNAME: "Eastleigh",
    CITYCODE: "SOU",
    CITYNAME: "SOUTHAMPTON",
    CITYNAME_HEB:
      "\u05e1\u05d0\u05d5\u05d8\u05d4\u05de\u05e4\u05d8\u05d5\u05df",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SOV",
    AIRPORTNAME: "Seldovia",
    CITYCODE: "SOV",
    CITYNAME: "SELDOVIA",
    CITYNAME_HEB: "Seldovia",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SOW",
    AIRPORTNAME: "Show Low",
    CITYCODE: "SOW",
    CITYNAME: "SHOW LOW",
    CITYNAME_HEB: "SHOW LOW",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SOX",
    AIRPORTNAME: "Sogamoso",
    CITYCODE: "SOX",
    CITYNAME: "SOGAMOSO",
    CITYNAME_HEB: "SOGAMOSO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SOY",
    AIRPORTNAME: "Stronsay",
    CITYCODE: "SOY",
    CITYNAME: "STRONSAY",
    CITYNAME_HEB: "STRONSAY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SOZ",
    AIRPORTNAME: "Solenzara",
    CITYCODE: "SOZ",
    CITYNAME: "SOLENZARA",
    CITYNAME_HEB: "SOLENZARA",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "BKN",
    AIRPORTNAME: null,
    CITYCODE: "BKN",
    CITYNAME: "BIRNI NKONI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NE",
    COUNTRYNAME: "NIGER",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SPC",
    AIRPORTNAME: "La Palma",
    CITYCODE: "SPC",
    CITYNAME: "SANTA CRUZ PALMA",
    CITYNAME_HEB: "SANTA CRUZ PALMA",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "SPD",
    AIRPORTNAME: "Saidpur",
    CITYCODE: "SPD",
    CITYNAME: "SAIDPUR",
    CITYNAME_HEB: "SAIDPUR",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "SPE",
    AIRPORTNAME: "Sepulot",
    CITYCODE: "SPE",
    CITYNAME: "SEPULOT",
    CITYNAME_HEB: "SEPULOT",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SPF",
    AIRPORTNAME: "Black Hills",
    CITYCODE: "SPF",
    CITYNAME: "SPEARFISH",
    CITYNAME_HEB: "SPEARFISH",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SPH",
    AIRPORTNAME: "Sopu",
    CITYCODE: "SPH",
    CITYNAME: "SOPU",
    CITYNAME_HEB: "SOPU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SPI",
    AIRPORTNAME: "Capital",
    CITYCODE: "SPI",
    CITYNAME: "SPRINGFIELD",
    CITYNAME_HEB: "SPRINGFIELD",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SPJ",
    AIRPORTNAME: "Sparta",
    CITYCODE: "SPJ",
    CITYNAME: "SPARTA",
    CITYNAME_HEB: "SPARTA",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "SPK",
    AIRPORTNAME: "Metropolitan Area",
    CITYCODE: "SPK",
    CITYNAME: "SAPPORO",
    CITYNAME_HEB: "\u05e1\u05d0\u05e4\u05d5\u05e8\u05d5",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "CTS",
    AIRPORTNAME: "Chitose",
    CITYCODE: "SPK",
    CITYNAME: "SAPPORO",
    CITYNAME_HEB: "\u05e1\u05d0\u05e4\u05d5\u05e8\u05d5",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "OKD",
    AIRPORTNAME: "Okadama",
    CITYCODE: "SPK",
    CITYNAME: "SAPPORO",
    CITYNAME_HEB: "\u05e1\u05d0\u05e4\u05d5\u05e8\u05d5",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "BKO",
    AIRPORTNAME: "Bamako",
    CITYCODE: "BKO",
    CITYNAME: "BAMAKO",
    CITYNAME_HEB: "\u05d1\u05de\u05d0\u05e7\u05d5",
    COUNTRYCODE: "ML",
    COUNTRYNAME: "MALI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d9",
  },
  {
    AIRPORTCODE: "SPL",
    AIRPORTNAME: null,
    CITYCODE: "SPL",
    CITYNAME: "SCHIPHOL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SPM",
    AIRPORTNAME: "Spangdahlem",
    CITYCODE: "SPM",
    CITYNAME: "SPANGDAHLEM",
    CITYNAME_HEB: "SPANGDAHLEM",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SPN",
    AIRPORTNAME: " ",
    CITYCODE: "SPN",
    CITYNAME: "SPINDLERUV MLYN",
    CITYNAME_HEB:
      "\u05e1\u05e4\u05dc\u05d9\u05e0\u05d3\u05e8\u05d5 \u05de\u05dc\u05d9\u05d9\u05df",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SPO",
    AIRPORTNAME: null,
    CITYCODE: "SPO",
    CITYNAME: "SAN PABLO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SPP",
    AIRPORTNAME: "Menongue",
    CITYCODE: "SPP",
    CITYNAME: "MENONGUE",
    CITYNAME_HEB: "MENONGUE",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "SPQ",
    AIRPORTNAME: "Catalina SPB",
    CITYCODE: "SPQ",
    CITYNAME: "SAN PEDRO",
    CITYNAME_HEB: "SAN PEDRO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SPR",
    AIRPORTNAME: "San Pedro",
    CITYCODE: "SPR",
    CITYNAME: "SAN PEDRO",
    CITYNAME_HEB: "SAN PEDRO",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "KIP",
    AIRPORTNAME: "Kickapoo",
    CITYCODE: "SPS",
    CITYNAME: "WICHITA FALLS",
    CITYNAME_HEB: "WICHITA FALLS",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SPS",
    AIRPORTNAME: "Sheppard AFB",
    CITYCODE: "SPS",
    CITYNAME: "WICHITA FALLS",
    CITYNAME_HEB: "WICHITA FALLS",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SPT",
    AIRPORTNAME: "Sipitang",
    CITYCODE: "SPT",
    CITYNAME: "SIPITANG",
    CITYNAME_HEB: "SIPITANG",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ADP",
    AIRPORTNAME: "Anuradhapura",
    CITYCODE: "ADP",
    CITYNAME: "ANURADHAPURA",
    CITYNAME_HEB: "ANURADHAPURA",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "BKP",
    AIRPORTNAME: "Barkly Downs",
    CITYCODE: "BKP",
    CITYNAME: "BARKLY DOWNS",
    CITYNAME_HEB: "BARKLY DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SPU",
    AIRPORTNAME: "Split",
    CITYCODE: "SPU",
    CITYNAME: "SPLIT",
    CITYNAME_HEB: "\u05e1\u05e4\u05dc\u05d9\u05d8",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SPV",
    AIRPORTNAME: "Sepik Plains",
    CITYCODE: "SPV",
    CITYNAME: "SEPIK PLAINS",
    CITYNAME_HEB: "SEPIK PLAINS",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SPW",
    AIRPORTNAME: "Municipal",
    CITYCODE: "SPW",
    CITYNAME: "SPENCER",
    CITYNAME_HEB: "SPENCER",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SPY",
    AIRPORTNAME: "San Pedro",
    CITYCODE: "SPY",
    CITYNAME: "SAN PEDRO",
    CITYNAME_HEB: "SAN PEDRO",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "SPZ",
    AIRPORTNAME: "Springdale Muni",
    CITYCODE: "SPZ",
    CITYNAME: "SPRINGDALE",
    CITYNAME_HEB: "SPRINGDALE",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SQA",
    AIRPORTNAME: "Santa Ynez",
    CITYCODE: "SQA",
    CITYNAME: "SANTA YNEZ",
    CITYNAME_HEB: "SANTA YNEZ",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SQB",
    AIRPORTNAME: "Santa Ana",
    CITYCODE: "SQB",
    CITYNAME: "SANTA ANA",
    CITYNAME_HEB: "SANTA ANA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SQC",
    AIRPORTNAME: "Southern Cross",
    CITYCODE: "SQC",
    CITYNAME: "SOUTHERN CROSS",
    CITYNAME_HEB: "SOUTHERN CROSS",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SQE",
    AIRPORTNAME: "San Luis De Pale",
    CITYCODE: "SQE",
    CITYNAME: "SAN LUIS DE PALE",
    CITYNAME_HEB: "SAN LUIS DE PALE",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BKQ",
    AIRPORTNAME: "Blackall",
    CITYCODE: "BKQ",
    CITYNAME: "BLACKALL",
    CITYNAME_HEB: "BLACKALL",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SQF",
    AIRPORTNAME: "Solano",
    CITYCODE: "SQF",
    CITYNAME: "SOLANO",
    CITYNAME_HEB: "SOLANO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SQG",
    AIRPORTNAME: "Sintang",
    CITYCODE: "SQG",
    CITYNAME: "SINTANG",
    CITYNAME_HEB: "SINTANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SQH",
    AIRPORTNAME: "Na-San",
    CITYCODE: "SQH",
    CITYNAME: "SON LA",
    CITYNAME_HEB: "SON LA",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "SQI",
    AIRPORTNAME: "Whiteside County",
    CITYCODE: "SQI",
    CITYNAME: "STERLING ROCKFLS",
    CITYNAME_HEB: "STERLING ROCKFLS",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SQJ",
    AIRPORTNAME: "Shehdi",
    CITYCODE: "SQJ",
    CITYNAME: "SHEHDI",
    CITYNAME_HEB: "SHEHDI",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SQL",
    AIRPORTNAME: "San Carlos",
    CITYCODE: "SQL",
    CITYNAME: "SAN CARLOS",
    CITYNAME_HEB: "SAN CARLOS",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SQM",
    AIRPORTNAME: null,
    CITYCODE: "SQM",
    CITYNAME: "SAO MIGUEL ARAGUAIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SQN",
    AIRPORTNAME: "Sanana",
    CITYCODE: "SQN",
    CITYNAME: "SANANA",
    CITYNAME_HEB: "SANANA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SQO",
    AIRPORTNAME: "Gunnarn",
    CITYCODE: "SQO",
    CITYNAME: "STORUMAN",
    CITYNAME_HEB: "STORUMAN",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SQP",
    AIRPORTNAME: "Starcke",
    CITYCODE: "SQP",
    CITYNAME: "STARCKE",
    CITYNAME_HEB: "STARCKE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BKR",
    AIRPORTNAME: "Bokoro",
    CITYCODE: "BKR",
    CITYNAME: "BOKORO",
    CITYNAME_HEB: "BOKORO",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "SQQ",
    AIRPORTNAME: "Siauliai",
    CITYCODE: "SQQ",
    CITYNAME: "SIAULIAI",
    CITYNAME_HEB: "SIAULIAI",
    COUNTRYCODE: "LT",
    COUNTRYNAME: "LITHUANIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d8\u05d0",
  },
  {
    AIRPORTCODE: "SQR",
    AIRPORTNAME: "Soroako",
    CITYCODE: "SQR",
    CITYNAME: "SOROAKO",
    CITYNAME_HEB: "SOROAKO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SQS",
    AIRPORTNAME: "Matthew Spain",
    CITYCODE: "SQS",
    CITYNAME: "SAN IGNACIA",
    CITYNAME_HEB: "SAN IGNACIA",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "SQT",
    AIRPORTNAME: "China Straits Airstrip",
    CITYCODE: "SQT",
    CITYNAME: "SAMARAI ISL",
    CITYNAME_HEB: "SAMARAI ISL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SQU",
    AIRPORTNAME: "Saposoa",
    CITYCODE: "SQU",
    CITYNAME: "SAPOSOA",
    CITYNAME_HEB: "SAPOSOA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "SQV",
    AIRPORTNAME: "Sequim Valley Airport",
    CITYCODE: "SQV",
    CITYNAME: "SEQUIM",
    CITYNAME_HEB: "SEQUIM",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SQW",
    AIRPORTNAME: "Skive Airport",
    CITYCODE: "SQW",
    CITYNAME: "SKIVE",
    CITYNAME_HEB: "SKIVE",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "ZKN",
    AIRPORTNAME: null,
    CITYCODE: "SQW",
    CITYNAME: "SKIVE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SQY",
    AIRPORTNAME: null,
    CITYCODE: "SQY",
    CITYNAME: "SAO LOURENCO DO SUL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SQZ",
    AIRPORTNAME: "RAF Station",
    CITYCODE: "SQZ",
    CITYNAME: "SCAMPTON",
    CITYNAME_HEB: "SCAMPTON",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BKS",
    AIRPORTNAME: "Padangkemiling",
    CITYCODE: "BKS",
    CITYNAME: "BENGKULU",
    CITYNAME_HEB: "BENGKULU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SRA",
    AIRPORTNAME: null,
    CITYCODE: "SRA",
    CITYNAME: "SANTA ROSA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SRB",
    AIRPORTNAME: "Santa Rosa",
    CITYCODE: "SRB",
    CITYNAME: "SANTA ROSA",
    CITYNAME_HEB: "SANTA ROSA",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SRC",
    AIRPORTNAME: "Searcy",
    CITYCODE: "SRC",
    CITYNAME: "SEARCY",
    CITYNAME_HEB: "SEARCY",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SRD",
    AIRPORTNAME: "San Ramon",
    CITYCODE: "SRD",
    CITYNAME: "SAN RAMON",
    CITYNAME_HEB: "SAN RAMON",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SRE",
    AIRPORTNAME: "Sucre",
    CITYCODE: "SRE",
    CITYNAME: "SUCRE",
    CITYNAME_HEB: "SUCRE",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JSG",
    AIRPORTNAME: "San Rafael H/P",
    CITYCODE: "SRF",
    CITYNAME: "SAN RAFAEL",
    CITYNAME_HEB: "SAN RAFAEL",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SRF",
    AIRPORTNAME: "Hamilton Field",
    CITYCODE: "SRF",
    CITYNAME: "SAN RAFAEL",
    CITYNAME_HEB: "SAN RAFAEL",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SRG",
    AIRPORTNAME: "Achmad Uani",
    CITYCODE: "SRG",
    CITYNAME: "SEMARANG",
    CITYNAME_HEB: "SEMARANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SRH",
    AIRPORTNAME: "Sarh",
    CITYCODE: "SRH",
    CITYNAME: "SARH",
    CITYNAME_HEB: "SARH",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "SRI",
    AIRPORTNAME: " ",
    CITYCODE: "SRI",
    CITYNAME: "SRI LANKA",
    CITYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "BKT",
    AIRPORTNAME: "Blackstone AAF",
    CITYCODE: "BKT",
    CITYNAME: "BLACKSTONE",
    CITYNAME_HEB: "BLACKSTONE",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "SRJ",
    AIRPORTNAME: "Capitan G Q Guardia",
    CITYCODE: "SRJ",
    CITYNAME: "SAN BORJA",
    CITYNAME_HEB: "SAN BORJA",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SRK",
    AIRPORTNAME: null,
    CITYCODE: "SRK",
    CITYNAME: "SIERRA LEONE",
    CITYNAME_HEB:
      "\u05e1\u05d9\u05d9\u05e8\u05d4 \u05dc\u05d9\u05d0\u05d5\u05e0\u05d4",
    COUNTRYCODE: "SL",
    COUNTRYNAME: "SIERRA LEONE",
    COUNTRYNAME_HEB:
      "\u05e1\u05d9\u05d9\u05e8\u05d4 \u05dc\u05d9\u05d0\u05d5\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "SRL",
    AIRPORTNAME: "Santa Rosalia",
    CITYCODE: "SRL",
    CITYNAME: "SANTA ROSALIA",
    CITYNAME_HEB: "SANTA ROSALIA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "SRM",
    AIRPORTNAME: "Sandringham",
    CITYCODE: "SRM",
    CITYNAME: "SANDRINGHAM",
    CITYNAME_HEB: "SANDRINGHAM",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SRN",
    AIRPORTNAME: null,
    CITYCODE: "SRN",
    CITYNAME: "STRAHAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUTS",
    COUNTRYNAME: "TASMANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SRO",
    AIRPORTNAME: " ",
    CITYCODE: "SRO",
    CITYNAME: "SELLA RONDA",
    CITYNAME_HEB: "\u05e1\u05dc\u05d4 \u05e8\u05d5\u05e0\u05d3\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SRP",
    AIRPORTNAME: "Stord Airport",
    CITYCODE: "SRP",
    CITYNAME: "STORD",
    CITYNAME_HEB: "STORD",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SRQ",
    AIRPORTNAME: "Bradenton",
    CITYCODE: "SRQ",
    CITYNAME: "SARASOTA",
    CITYNAME_HEB: "SARASOTA",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SRR",
    AIRPORTNAME: "Dunwich",
    CITYCODE: "SRR",
    CITYNAME: "STRADBROKE ISL",
    CITYNAME_HEB: "STRADBROKE ISL",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SRS",
    AIRPORTNAME: "San Marcos",
    CITYCODE: "SRS",
    CITYNAME: "SAN MARCOS",
    CITYNAME_HEB: "SAN MARCOS",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BKU",
    AIRPORTNAME: " ",
    CITYCODE: "BKU",
    CITYNAME: "BAKURIANI",
    CITYNAME_HEB: "\u05d1\u05d0\u05e7\u05d5\u05e8\u05d9\u05d0\u05e0\u05d9",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SRT",
    AIRPORTNAME: "Soroti",
    CITYCODE: "SRT",
    CITYNAME: "SOROTI",
    CITYNAME_HEB: "SOROTI",
    COUNTRYCODE: "UG",
    COUNTRYNAME: "UGANDA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "SRU",
    AIRPORTNAME: "Skypark",
    CITYCODE: "SRU",
    CITYNAME: "SANTA CRUZ",
    CITYNAME_HEB: "SANTA CRUZ",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SRV",
    AIRPORTNAME: "Stony River",
    CITYCODE: "SRV",
    CITYNAME: "STONY RIVER",
    CITYNAME_HEB: "Stony River",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SRW",
    AIRPORTNAME: "Rowan County",
    CITYCODE: "SRW",
    CITYNAME: "SALISBURY",
    CITYNAME_HEB: "SALISBURY",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SRX",
    AIRPORTNAME: "Sert",
    CITYCODE: "SRX",
    CITYNAME: "SERT",
    CITYNAME_HEB: "SERT",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "SRZ",
    AIRPORTNAME: "El Trompillo",
    CITYCODE: "SRZ",
    CITYNAME: "SANTA CRUZ",
    CITYNAME_HEB: "SANTA CRUZ",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VVI",
    AIRPORTNAME: "Viru Viru International",
    CITYCODE: "SRZ",
    CITYNAME: "SANTA CRUZ",
    CITYNAME_HEB: "SANTA CRUZ",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KEU",
    AIRPORTNAME: "Kelly Bar",
    CITYCODE: "KEU",
    CITYNAME: "KELLY BAR",
    CITYNAME_HEB: "Kelly Bar",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KEV",
    AIRPORTNAME: "Halli",
    CITYCODE: "KEV",
    CITYNAME: "KUOREVESI",
    CITYNAME_HEB: "KUOREVESI",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ABR",
    AIRPORTNAME: "Aberdeen",
    CITYCODE: "ABR",
    CITYNAME: "ABERDEEN",
    CITYNAME_HEB: "Aberdeen",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ASC",
    AIRPORTNAME: "Ascension",
    CITYCODE: "ASC",
    CITYNAME: "ASCENSION",
    CITYNAME_HEB: "\u05d0\u05e1\u05e1\u05d9\u05d5\u05df",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KEW",
    AIRPORTNAME: "Keewaywin",
    CITYCODE: "KEW",
    CITYNAME: "KEEWAYWIN",
    CITYNAME_HEB: "KEEWAYWIN",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "KEX",
    AIRPORTNAME: "Kanabea",
    CITYCODE: "KEX",
    CITYNAME: "KANABEA",
    CITYNAME_HEB: "KANABEA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KEY",
    AIRPORTNAME: "Kericho",
    CITYCODE: "KEY",
    CITYNAME: "KERICHO",
    CITYNAME_HEB: "KERICHO",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KFA",
    AIRPORTNAME: "Kiffa",
    CITYCODE: "KFA",
    CITYNAME: "KIFFA",
    CITYNAME_HEB: "KIFFA",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KFG",
    AIRPORTNAME: "Kalkurung",
    CITYCODE: "KFG",
    CITYNAME: "KALKURUNG",
    CITYNAME_HEB: "KALKURUNG",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KFP",
    AIRPORTNAME: "False Pass",
    CITYCODE: "KFP",
    CITYNAME: "FALSE PASS",
    CITYNAME_HEB: "False Pass",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KFS",
    AIRPORTNAME: "Kastamonu",
    CITYCODE: "KFS",
    CITYNAME: "KASTAMONU",
    CITYNAME_HEB: "KASTAMONU",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KGA",
    AIRPORTNAME: "Kananga",
    CITYCODE: "KGA",
    CITYNAME: "KANANGA",
    CITYNAME_HEB: "KANANGA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KGB",
    AIRPORTNAME: "Konge",
    CITYCODE: "KGB",
    CITYNAME: "KONGE",
    CITYNAME_HEB: "KONGE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KGC",
    AIRPORTNAME: "Kingscote",
    CITYCODE: "KGC",
    CITYNAME: "KINGSCOTE",
    CITYNAME_HEB: "KINGSCOTE",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ASD",
    AIRPORTNAME: "Andros Town International Airport",
    CITYCODE: "ASD",
    CITYNAME: "ANDROS TOWN",
    CITYNAME_HEB: "ANDROS TOWN",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "KGD",
    AIRPORTNAME: "Kaliningrad",
    CITYCODE: "KGD",
    CITYNAME: "KALININGRAD",
    CITYNAME_HEB: "KALININGRAD",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KGE",
    AIRPORTNAME: "Kagau",
    CITYCODE: "KGE",
    CITYNAME: "KAGAU",
    CITYNAME_HEB: "KAGAU",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "KGF",
    AIRPORTNAME: "Karaganda",
    CITYCODE: "KGF",
    CITYNAME: "KARAGANDA",
    CITYNAME_HEB: "\u05e7\u05e8\u05d2\u05e0\u05d3\u05d4",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "KGG",
    AIRPORTNAME: "Kedougou",
    CITYCODE: "KGG",
    CITYNAME: "KEDOUGOU",
    CITYNAME_HEB: "KEDOUGOU",
    COUNTRYCODE: "SN",
    COUNTRYNAME: "SENEGAL",
    COUNTRYNAME_HEB: "\u05e1\u05e0\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "KGH",
    AIRPORTNAME: "Yongai",
    CITYCODE: "KGH",
    CITYNAME: "YONGAI",
    CITYNAME_HEB: "YONGAI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KGI",
    AIRPORTNAME: "Kalgoorlie",
    CITYCODE: "KGI",
    CITYNAME: "KALGOORLIE",
    CITYNAME_HEB: "KALGOORLIE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KGJ",
    AIRPORTNAME: "Karonga",
    CITYCODE: "KGJ",
    CITYNAME: "KARONGA",
    CITYNAME_HEB: "KARONGA",
    COUNTRYCODE: "MW",
    COUNTRYNAME: "MALAWI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "KGK",
    AIRPORTNAME: "New Koliganek",
    CITYCODE: "KGK",
    CITYNAME: "NEW KOLIGANEK",
    CITYNAME_HEB: "New Koliganek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KGL",
    AIRPORTNAME: "Gregoire Kayibanda",
    CITYCODE: "KGL",
    CITYNAME: "KIGALI",
    CITYNAME_HEB: "\u05e7\u05d9\u05d2\u05dc\u05d9",
    COUNTRYCODE: "RW",
    COUNTRYNAME: "RWANDA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05d0\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "KGM",
    AIRPORTNAME: "Kungum",
    CITYCODE: "KGM",
    CITYNAME: "KUNGUM",
    CITYNAME_HEB: "KUNGUM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ASE",
    AIRPORTNAME: "Aspen",
    CITYCODE: "ASE",
    CITYNAME: "ASPEN",
    CITYNAME_HEB: "\u05d0\u05e1\u05e4\u05df",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KGN",
    AIRPORTNAME: "Kasongo Lunda",
    CITYCODE: "KGN",
    CITYNAME: "KASONGO LUNDA",
    CITYNAME_HEB: "KASONGO LUNDA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KGO",
    AIRPORTNAME: "Kirovograd",
    CITYCODE: "KGO",
    CITYNAME: "KIROVOGRAD",
    CITYNAME_HEB: "KIROVOGRAD",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KGP",
    AIRPORTNAME: "Kogalym International",
    CITYCODE: "KGP",
    CITYNAME: "KOGALYM",
    CITYNAME_HEB: "KOGALYM",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KGR",
    AIRPORTNAME: "Kulgera",
    CITYCODE: "KGR",
    CITYNAME: "KULGERA",
    CITYNAME_HEB: "KULGERA",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KGU",
    AIRPORTNAME: "Keningau",
    CITYCODE: "KGU",
    CITYNAME: "KENINGAU",
    CITYNAME_HEB: "KENINGAU",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KGW",
    AIRPORTNAME: "Kagi",
    CITYCODE: "KGW",
    CITYNAME: "KAGI",
    CITYNAME_HEB: "KAGI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KGX",
    AIRPORTNAME: "Grayling",
    CITYCODE: "KGX",
    CITYNAME: "GRAYLING",
    CITYNAME_HEB: "Grayling",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KGY",
    AIRPORTNAME: "Kingaroy",
    CITYCODE: "KGY",
    CITYNAME: "KINGAROY",
    CITYNAME_HEB: "KINGAROY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KGZ",
    AIRPORTNAME: "Glacier Creek",
    CITYCODE: "KGZ",
    CITYNAME: "GLACIER CREEK",
    CITYNAME_HEB: "Glacier Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ASF",
    AIRPORTNAME: "Astrakhan",
    CITYCODE: "ASF",
    CITYNAME: "ASTRAKHAN",
    CITYNAME_HEB: "ASTRAKHAN",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KHC",
    AIRPORTNAME: "Kerch",
    CITYCODE: "KHC",
    CITYNAME: "KERCH",
    CITYNAME_HEB: "KERCH",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KHE",
    AIRPORTNAME: "Kherson",
    CITYCODE: "KHE",
    CITYNAME: "KHERSON",
    CITYNAME_HEB: "KHERSON",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KHG",
    AIRPORTNAME: "Kashi",
    CITYCODE: "KHG",
    CITYNAME: "KASHI",
    CITYNAME_HEB: "KASHI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KHH",
    AIRPORTNAME: "International",
    CITYCODE: "KHH",
    CITYNAME: "KAOHSIUNG",
    CITYNAME_HEB: "\u05e7\u05d0\u05d5\u05e9\u05d9\u05d5\u05e0\u05d2",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "KHI",
    AIRPORTNAME: "Quaid-E-Azam International",
    CITYCODE: "KHI",
    CITYNAME: "KARACHI",
    CITYNAME_HEB: "KARACHI",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "KHJ",
    AIRPORTNAME: "Kauhajoki",
    CITYCODE: "KHJ",
    CITYNAME: "KAUHAJOKI",
    CITYNAME_HEB: "KAUHAJOKI",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KHL",
    AIRPORTNAME: "Khulna",
    CITYCODE: "KHL",
    CITYNAME: "KHULNA",
    CITYNAME_HEB: "KHULNA",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "KHM",
    AIRPORTNAME: "Khamti",
    CITYCODE: "KHM",
    CITYNAME: "KHAMTI",
    CITYNAME_HEB: "KHAMTI",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "KHN",
    AIRPORTNAME: "Nanchang",
    CITYCODE: "KHN",
    CITYNAME: "NANCHANG",
    CITYNAME_HEB: "NANCHANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KHO",
    AIRPORTNAME: "Khoka Moya",
    CITYCODE: "KHO",
    CITYNAME: "KHOKA MOYA",
    CITYNAME_HEB: "KHOKA MOYA",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "ASG",
    AIRPORTNAME: "Ashburton",
    CITYCODE: "ASG",
    CITYNAME: "ASHBURTON",
    CITYNAME_HEB: "ASHBURTON",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KHR",
    AIRPORTNAME: "Kharkhorin",
    CITYCODE: "KHR",
    CITYNAME: "KHARKHORIN",
    CITYNAME_HEB: "KHARKHORIN",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KHS",
    AIRPORTNAME: null,
    CITYCODE: "KHS",
    CITYNAME: "KHASAB",
    CITYNAME_HEB: null,
    COUNTRYCODE: "OM",
    COUNTRYNAME: "OMAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KHT",
    AIRPORTNAME: null,
    CITYCODE: "KHT",
    CITYNAME: "KHOST",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KHU",
    AIRPORTNAME: "Kremenchug",
    CITYCODE: "KHU",
    CITYNAME: "KREMENCHUG",
    CITYNAME_HEB: "KREMENCHUG",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KHV",
    AIRPORTNAME: "Novyy",
    CITYCODE: "KHV",
    CITYNAME: "KHABAROVSK",
    CITYNAME_HEB: "KHABAROVSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KHW",
    AIRPORTNAME: "Khwai River Lodge",
    CITYCODE: "KHW",
    CITYNAME: "KHWAI RIVER LODGE",
    CITYNAME_HEB: "KHWAI RIVER LODGE",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KHZ",
    AIRPORTNAME: "Kauehi",
    CITYCODE: "KHZ",
    CITYNAME: "KAUEHI",
    CITYNAME_HEB: "KAUEHI",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "KIA",
    AIRPORTNAME: "Kaiapit",
    CITYCODE: "KIA",
    CITYNAME: "KAIAPIT",
    CITYNAME_HEB: "KAIAPIT",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KIB",
    AIRPORTNAME: "Ivanof Bay",
    CITYCODE: "KIB",
    CITYNAME: "IVANOF BAY",
    CITYNAME_HEB: "Ivanof Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KIC",
    AIRPORTNAME: "Mesa Del Rey",
    CITYCODE: "KIC",
    CITYNAME: "KING CITY",
    CITYNAME_HEB: "KING CITY",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ASH",
    AIRPORTNAME: "Boire Field",
    CITYCODE: "ASH",
    CITYNAME: "NASHUA",
    CITYNAME_HEB: "NASHUA",
    COUNTRYCODE: "USNH",
    COUNTRYNAME: "NEW HAMPSHIRE",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d4\u05de\u05e4\u05e9\u05d9\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KID",
    AIRPORTNAME: "Kristianstad",
    CITYCODE: "KID",
    CITYNAME: "KRISTIANSTAD",
    CITYNAME_HEB: "KRISTIANSTAD",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KIE",
    AIRPORTNAME: "Aropa",
    CITYCODE: "KIE",
    CITYNAME: "KIETA",
    CITYNAME_HEB: "KIETA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KIF",
    AIRPORTNAME: "Kingfisher Lake",
    CITYCODE: "KIF",
    CITYNAME: "KINGFISHER LAKE",
    CITYNAME_HEB: "KINGFISHER LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "KIG",
    AIRPORTNAME: "Koinghaas",
    CITYCODE: "KIG",
    CITYNAME: "KOINGHAAS",
    CITYNAME_HEB: "KOINGHAAS",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "KII",
    AIRPORTNAME: "Kibuli",
    CITYCODE: "KII",
    CITYNAME: "KIBULI",
    CITYNAME_HEB: "KIBULI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KIJ",
    AIRPORTNAME: "Niigata",
    CITYCODE: "KIJ",
    CITYNAME: "NIIGATA",
    CITYNAME_HEB: "NIIGATA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "LBX",
    AIRPORTNAME: "Lubang",
    CITYCODE: "LBX",
    CITYNAME: "LUBANG",
    CITYNAME_HEB: "LUBANG",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "LBY",
    AIRPORTNAME: "Montoir",
    CITYCODE: "LBY",
    CITYNAME: "LA BAULE",
    CITYNAME_HEB: "LA BAULE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LBZ",
    AIRPORTNAME: "Lukapa",
    CITYCODE: "LBZ",
    CITYNAME: "LUKAPA",
    CITYNAME_HEB: "LUKAPA",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LCA",
    AIRPORTNAME: "Larnaca",
    CITYCODE: "LCA",
    CITYNAME: "LARNACA",
    CITYNAME_HEB: "\u05dc\u05e8\u05e0\u05e7\u05d4",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LCB",
    AIRPORTNAME: null,
    CITYCODE: "LCB",
    CITYNAME: "PONTES E LACERDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LCC",
    AIRPORTNAME: "Galatina",
    CITYCODE: "LCC",
    CITYNAME: "LECCE",
    CITYNAME_HEB: "\u05dc\u05e6\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LCD",
    AIRPORTNAME: "Louis Trichardt",
    CITYCODE: "LCD",
    CITYNAME: "LOUIS TRICHARDT",
    CITYNAME_HEB: "LOUIS TRICHARDT",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "ATR",
    AIRPORTNAME: "Atar Mouakchott",
    CITYCODE: "ATR",
    CITYNAME: "ATAR",
    CITYNAME_HEB: "ATAR",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LCE",
    AIRPORTNAME: "Goloson International",
    CITYCODE: "LCE",
    CITYNAME: "LA CEIBA",
    CITYNAME_HEB: "LA CEIBA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "LCF",
    AIRPORTNAME: "Las Vegas",
    CITYCODE: "LCF",
    CITYNAME: "RIO DULCE",
    CITYNAME_HEB: "RIO DULCE",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LCG",
    AIRPORTNAME: "La Coruna",
    CITYCODE: "LCG",
    CITYNAME: "LA CORUNA",
    CITYNAME_HEB: "LA CORUNA",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "LCH",
    CITYNAME: "LECH",
    CITYNAME_HEB: "\u05dc\u05da",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LCI",
    AIRPORTNAME: "Municipal",
    CITYCODE: "LCI",
    CITYNAME: "LACONIA",
    CITYNAME_HEB: "LACONIA",
    COUNTRYCODE: "USNH",
    COUNTRYNAME: "NEW HAMPSHIRE",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d4\u05de\u05e4\u05e9\u05d9\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LCJ",
    AIRPORTNAME: "Lodz Lublinek",
    CITYCODE: "LCJ",
    CITYNAME: "LODZ",
    CITYNAME_HEB: "\u05dc\u05d5\u05d3\u05d6'",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LCL",
    AIRPORTNAME: "La Coloma",
    CITYCODE: "LCL",
    CITYNAME: "LA COLOMA",
    CITYNAME_HEB: "LA COLOMA",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "LCM",
    AIRPORTNAME: null,
    CITYCODE: "LCM",
    CITYNAME: "LA CUMBRE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LCN",
    AIRPORTNAME: "Balcanoona",
    CITYCODE: "LCN",
    CITYNAME: "BALCANOONA",
    CITYNAME_HEB: "BALCANOONA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LCO",
    AIRPORTNAME: "Lague",
    CITYCODE: "LCO",
    CITYNAME: "LAGUE",
    CITYNAME_HEB: "LAGUE",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "ATS",
    AIRPORTNAME: "Artesia",
    CITYCODE: "ATS",
    CITYNAME: "ARTESIA",
    CITYNAME_HEB: "ARTESIA",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LCP",
    AIRPORTNAME: null,
    CITYCODE: "LCP",
    CITYNAME: "LONCOPUE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LCR",
    AIRPORTNAME: "La Chorrera",
    CITYCODE: "LCR",
    CITYNAME: "LA CHORRERA",
    CITYNAME_HEB: "LA CHORRERA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LCS",
    AIRPORTNAME: "Las Canas",
    CITYCODE: "LCS",
    CITYNAME: "LAS CANAS",
    CITYNAME_HEB: "LAS CANAS",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "LCV",
    AIRPORTNAME: "Lucca",
    CITYCODE: "LCV",
    CITYNAME: "LUCCA",
    CITYNAME_HEB: "\u05dc\u05d5\u05e7\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LDA",
    AIRPORTNAME: "Malda",
    CITYCODE: "LDA",
    CITYNAME: "MALDA",
    CITYNAME_HEB: "MALDA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "LDB",
    AIRPORTNAME: null,
    CITYCODE: "LDB",
    CITYNAME: "LONDRINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LDC",
    AIRPORTNAME: "Lindeman Island",
    CITYCODE: "LDC",
    CITYNAME: "LINDEMAN ISLAND",
    CITYNAME_HEB: "LINDEMAN ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LDE",
    AIRPORTNAME: "Tarbes Ossun Lourdes",
    CITYCODE: "LDE",
    CITYNAME: "LOURDES TARBES",
    CITYNAME_HEB: "LOURDES TARBES",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LDH",
    AIRPORTNAME: "Lord Howe Island",
    CITYCODE: "LDH",
    CITYNAME: "LORD HOWE ISLAND",
    CITYNAME_HEB: "LORD HOWE ISLAND",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ATT",
    AIRPORTNAME: "Atmautluak",
    CITYCODE: "ATT",
    CITYNAME: "ATMAUTLUAK",
    CITYNAME_HEB: "Atmautluak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LDI",
    AIRPORTNAME: "Kikwetu",
    CITYCODE: "LDI",
    CITYNAME: "LINDI",
    CITYNAME_HEB: "LINDI",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LDJ",
    AIRPORTNAME: "Linden",
    CITYCODE: "LDJ",
    CITYNAME: "LINDEN",
    CITYNAME_HEB: "LINDEN",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "LDK",
    AIRPORTNAME: "Hovby",
    CITYCODE: "LDK",
    CITYNAME: "LIDKOPING",
    CITYNAME_HEB: "LIDKOPING",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LDM",
    AIRPORTNAME: "Mason County",
    CITYCODE: "LDM",
    CITYNAME: "LUDINGTON",
    CITYNAME_HEB: "LUDINGTON",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LDN",
    AIRPORTNAME: "Lamidanda",
    CITYCODE: "LDN",
    CITYNAME: "LAMIDANDA",
    CITYNAME_HEB: "LAMIDANDA",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "LDO",
    AIRPORTNAME: "Ladouanie",
    CITYCODE: "LDO",
    CITYNAME: "LADOUANIE",
    CITYNAME_HEB: "LADOUANIE",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "LDR",
    AIRPORTNAME: "Lodar",
    CITYCODE: "LDR",
    CITYNAME: "LODAR",
    CITYNAME_HEB: "LODAR",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "LDS",
    AIRPORTNAME: null,
    CITYCODE: "LDS",
    CITYNAME: "FICTITIOUS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ZZ",
    COUNTRYNAME: "FICTITIOUS POINTS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LDU",
    AIRPORTNAME: "Lahad Datu",
    CITYCODE: "LDU",
    CITYNAME: "LAHAD DATU",
    CITYNAME_HEB: "LAHAD DATU",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LDV",
    AIRPORTNAME: "Landivisiau",
    CITYCODE: "LDV",
    CITYNAME: "LANDIVISIAU",
    CITYNAME_HEB: "LANDIVISIAU",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "ATU",
    AIRPORTNAME: "Attu Island-Casco Cove",
    CITYCODE: "ATU",
    CITYNAME: "ATTU ISLAND",
    CITYNAME_HEB: "Attu Island-Casco Cove",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LDW",
    AIRPORTNAME: "Lansdowne",
    CITYCODE: "LDW",
    CITYNAME: "LANSDOWNE",
    CITYNAME_HEB: "LANSDOWNE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LDX",
    AIRPORTNAME: "St-Laurent du Maroni",
    CITYCODE: "LDX",
    CITYNAME: "SAINT LAURENT DU MAR",
    CITYNAME_HEB: "SAINT LAURENT DU MAR",
    COUNTRYCODE: "GF",
    COUNTRYNAME: "FRENCH GUIANA",
    COUNTRYNAME_HEB:
      "\u05d2\u05d9\u05e0\u05d0\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "LDY",
    AIRPORTNAME: "Eglinton",
    CITYCODE: "LDY",
    CITYNAME: "LONDONDERRY",
    CITYNAME_HEB: "LONDONDERRY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LDZ",
    AIRPORTNAME: "Londolozi",
    CITYCODE: "LDZ",
    CITYNAME: "LONDOLOZI",
    CITYNAME_HEB: "LONDOLOZI",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "LEA",
    AIRPORTNAME: "Learmonth",
    CITYCODE: "LEA",
    CITYNAME: "LEARMONTH",
    CITYNAME_HEB: "LEARMONTH",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LEB",
    AIRPORTNAME: "Hanover/Lebanon/White River Airport",
    CITYCODE: "LEB",
    CITYNAME: "LEBANON",
    CITYNAME_HEB: "LEBANON (US)",
    COUNTRYCODE: "USNH",
    COUNTRYNAME: "NEW HAMPSHIRE",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d4\u05de\u05e4\u05e9\u05d9\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LEC",
    AIRPORTNAME: null,
    CITYCODE: "LEC",
    CITYNAME: "LENCOIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LED",
    AIRPORTNAME: "Pulkovo",
    CITYCODE: "LED",
    CITYNAME: "SAINT PETERSBURG",
    CITYNAME_HEB:
      "\u05e1\u05e0\u05d8 \u05e4\u05d8\u05e1\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NAB",
    AIRPORTNAME: " ",
    CITYCODE: "NAB",
    CITYNAME: "NAGOLD",
    CITYNAME_HEB: "\u05e0\u05d0\u05d2\u05d5\u05dc\u05d3",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ATV",
    AIRPORTNAME: "Ati",
    CITYCODE: "ATV",
    CITYNAME: "ATI",
    CITYNAME_HEB: "ATI",
    COUNTRYCODE: "TD",
    COUNTRYNAME: "CHAD",
    COUNTRYNAME_HEB: "\u05e6'\u05d0\u05d3",
  },
  {
    AIRPORTCODE: "LEE",
    AIRPORTNAME: "Leesburg",
    CITYCODE: "LEE",
    CITYNAME: "LEESBURG",
    CITYNAME_HEB: "LEESBURG",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LEG",
    AIRPORTNAME: " ",
    CITYCODE: "LEG",
    CITYNAME: "LEGNANO",
    CITYNAME_HEB: "\u05dc\u05d0\u05d2\u05e0\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LEH",
    AIRPORTNAME: "Octeville",
    CITYCODE: "LEH",
    CITYNAME: "LE HAVRE",
    CITYNAME_HEB: "LE HAVRE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LEI",
    AIRPORTNAME: "Almeria",
    CITYCODE: "LEI",
    CITYNAME: "ALMERIA",
    CITYNAME_HEB: "\u05d0\u05dc\u05de\u05d9\u05d9\u05e8\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "LEJ",
    AIRPORTNAME: "Leipzig Airport",
    CITYCODE: "LEJ",
    CITYNAME: "LEIPZIG",
    CITYNAME_HEB: "\u05dc\u05d9\u05e4\u05e6\u05d9\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LEK",
    AIRPORTNAME: "Labe",
    CITYCODE: "LEK",
    CITYNAME: "LABE",
    CITYNAME_HEB: "LABE",
    COUNTRYCODE: "GN",
    COUNTRYNAME: "GUINEA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LEL",
    AIRPORTNAME: "Lake Evella",
    CITYCODE: "LEL",
    CITYNAME: "LAKE EVELLA",
    CITYNAME_HEB: "LAKE EVELLA",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LEM",
    AIRPORTNAME: " ",
    CITYCODE: "LEM",
    CITYNAME: "LES MENUIRES",
    CITYNAME_HEB: "\u05dc\u05d4 \u05de\u05e0\u05d5\u05d9\u05d9\u05e8",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LEN",
    AIRPORTNAME: "Leon",
    CITYCODE: "LEN",
    CITYNAME: "LEON",
    CITYNAME_HEB: "\u05dc\u05d0\u05d5\u05df",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "ATW",
    AIRPORTNAME: "Outagamie County",
    CITYCODE: "ATW",
    CITYNAME: "APPLETON",
    CITYNAME_HEB: "APPLETON",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "LEO",
    CITYNAME: "LEOGANG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LEP",
    AIRPORTNAME: null,
    CITYCODE: "LEP",
    CITYNAME: "LEOPOLDINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LEQ",
    AIRPORTNAME: "Lands End",
    CITYCODE: "LEQ",
    CITYNAME: "LANDS END",
    CITYNAME_HEB: "LANDS END",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LER",
    AIRPORTNAME: "Leinster",
    CITYCODE: "LER",
    CITYNAME: "LEINSTER",
    CITYNAME_HEB: "LEINSTER",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LES",
    AIRPORTNAME: "Lesobeng",
    CITYCODE: "LES",
    CITYNAME: "LESOBENG",
    CITYNAME_HEB: "LESOBENG",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "LEU",
    AIRPORTNAME: "Aeroport De La Seu",
    CITYCODE: "LEU",
    CITYNAME: "SEO DE URGEL",
    CITYNAME_HEB: "SEO DE URGEL",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "LEV",
    AIRPORTNAME: "Levuka Airfield",
    CITYCODE: "LEV",
    CITYNAME: "BURETA",
    CITYNAME_HEB: "BURETA",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "LEW",
    AIRPORTNAME: "Auburn Airport",
    CITYCODE: "LEW",
    CITYNAME: "LEWISTON",
    CITYNAME_HEB: "LEWISTON",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ATX",
    AIRPORTNAME: "Atbasar",
    CITYCODE: "ATX",
    CITYNAME: "ATBASAR",
    CITYNAME_HEB: "ATBASAR",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "LEX",
    AIRPORTNAME: "Blue Grass",
    CITYCODE: "LEX",
    CITYNAME: "LEXINGTON",
    CITYNAME_HEB: "LEXINGTON",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LSD",
    AIRPORTNAME: null,
    CITYCODE: "LEX",
    CITYNAME: "LEXINGTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LEY",
    AIRPORTNAME: "Lelystad",
    CITYCODE: "LEY",
    CITYNAME: "LELYSTAD",
    CITYNAME_HEB: "\u05dc\u05d9\u05dc\u05e1\u05d8\u05d3",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "LEZ",
    AIRPORTNAME: "La Esperanza",
    CITYCODE: "LEZ",
    CITYNAME: "LA ESPERANZA",
    CITYNAME_HEB: "LA ESPERANZA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "LFN",
    AIRPORTNAME: "Franklin",
    CITYCODE: "LFN",
    CITYNAME: "LOUISBURG",
    CITYNAME_HEB: "LOUISBURG",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LFO",
    AIRPORTNAME: "Kelafo",
    CITYCODE: "LFO",
    CITYNAME: "KELAFO CALLAF",
    CITYNAME_HEB: "KELAFO CALLAF",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LFP",
    AIRPORTNAME: "Lakefield",
    CITYCODE: "LFP",
    CITYNAME: "LAKEFIELD",
    CITYNAME_HEB: "LAKEFIELD",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LFR",
    AIRPORTNAME: "La Fria",
    CITYCODE: "LFR",
    CITYNAME: "LA FRIA",
    CITYNAME_HEB: "LA FRIA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LFT",
    AIRPORTNAME: "Lafayette Regional",
    CITYCODE: "LFT",
    CITYNAME: "LAFAYETTE",
    CITYNAME_HEB: "LAFAYETTE",
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB:
      '\u05dc\u05d5\u05d0\u05d9\u05d6\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LFW",
    AIRPORTNAME: "Lome",
    CITYCODE: "LFW",
    CITYNAME: "LOME",
    CITYNAME_HEB: "\u05dc\u05d5\u05de\u05d4",
    COUNTRYCODE: "TG",
    COUNTRYNAME: "TOGO",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "ATY",
    AIRPORTNAME: "Watertown",
    CITYCODE: "ATY",
    CITYNAME: "WATERTOWN",
    CITYNAME_HEB: "WATERTOWN",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "JLB",
    AIRPORTNAME: "Heliport",
    CITYCODE: "LGB",
    CITYNAME: "LONG BEACH",
    CITYNAME_HEB: "LONG BEACH",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LGB",
    AIRPORTNAME: "Long Beach Municipal",
    CITYCODE: "LGB",
    CITYNAME: "LONG BEACH",
    CITYNAME_HEB: "LONG BEACH",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LGC",
    AIRPORTNAME: "Calloway",
    CITYCODE: "LGC",
    CITYNAME: "LA GRANGE",
    CITYNAME_HEB: "LA GRANGE",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "LGD",
    AIRPORTNAME: "La Grande",
    CITYCODE: "LGD",
    CITYNAME: "LA GRANDE",
    CITYNAME_HEB: "LA GRANDE",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LGE",
    AIRPORTNAME: "Lake Gregory",
    CITYCODE: "LGE",
    CITYNAME: "LAKE GREGORY",
    CITYNAME_HEB: "LAKE GREGORY",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LGG",
    AIRPORTNAME: "Bierset",
    CITYCODE: "LGG",
    CITYNAME: "LIEGE",
    CITYNAME_HEB: "\u05dc\u05d9\u05d0\u05d6",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LGH",
    AIRPORTNAME: "Leigh Creek",
    CITYCODE: "LGH",
    CITYNAME: "LEIGH CREEK",
    CITYNAME_HEB: "LEIGH CREEK",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LGI",
    AIRPORTNAME: "Deadmans Cay",
    CITYCODE: "LGI",
    CITYNAME: "DEADMANS CAY",
    CITYNAME_HEB: "DEADMANS CAY",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "LGK",
    AIRPORTNAME: "Langkawi",
    CITYCODE: "LGK",
    CITYNAME: "LANGKAWI",
    CITYNAME_HEB: "LANGKAWI",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LGL",
    AIRPORTNAME: "Long Lellang",
    CITYCODE: "LGL",
    CITYNAME: "LONG LELLANG",
    CITYNAME_HEB: "LONG LELLANG",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AUA",
    AIRPORTNAME: "Queen Beatrix International Airport",
    CITYCODE: "AUA",
    CITYNAME: "ARUBA",
    CITYNAME_HEB: "\u05d0\u05e8\u05d5\u05d1\u05d4",
    COUNTRYCODE: "AW",
    COUNTRYNAME: "ARUBA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "LGM",
    AIRPORTNAME: "Laiagam",
    CITYCODE: "LGM",
    CITYNAME: "LAIAGAM",
    CITYNAME_HEB: "LAIAGAM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LGN",
    AIRPORTNAME: "Linga Linga",
    CITYCODE: "LGN",
    CITYNAME: "LINGA LINGA",
    CITYNAME_HEB: "LINGA LINGA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LGO",
    AIRPORTNAME: "Langeoog",
    CITYCODE: "LGO",
    CITYNAME: "LANGEOOG",
    CITYNAME_HEB: "LANGEOOG",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LGP",
    AIRPORTNAME: "Legaspi",
    CITYCODE: "LGP",
    CITYNAME: "LEGASPI",
    CITYNAME_HEB: "LEGASPI",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "LGQ",
    AIRPORTNAME: "Lago Agrio",
    CITYCODE: "LGQ",
    CITYNAME: "LAGO AGRIO",
    CITYNAME_HEB: "LAGO AGRIO",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "LGR",
    AIRPORTNAME: "Cochrane",
    CITYCODE: "LGR",
    CITYNAME: "COCHRANE",
    CITYNAME_HEB: "COCHRANE",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LGS",
    AIRPORTNAME: null,
    CITYCODE: "LGS",
    CITYNAME: "MALARGUE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LGT",
    AIRPORTNAME: "Las Gaviotas",
    CITYCODE: "LGT",
    CITYNAME: "LAS GAVIOTAS",
    CITYNAME_HEB: "LAS GAVIOTAS",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LGU",
    AIRPORTNAME: "Cache",
    CITYCODE: "LGU",
    CITYNAME: "LOGAN",
    CITYNAME_HEB: "LOGAN",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LGX",
    AIRPORTNAME: "Lugh Ganane",
    CITYCODE: "LGX",
    CITYNAME: "LUGH GANANE",
    CITYNAME_HEB: "LUGH GANANE",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AUB",
    AIRPORTNAME: null,
    CITYCODE: "AUB",
    CITYNAME: "ITAUBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LGY",
    AIRPORTNAME: "Lagunillas",
    CITYCODE: "LGY",
    CITYNAME: "LAGUNILLAS",
    CITYNAME_HEB: "LAGUNILLAS",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LGZ",
    AIRPORTNAME: "Leguizamo",
    CITYCODE: "LGZ",
    CITYNAME: "LEGUIZAMO",
    CITYNAME_HEB: "LEGUIZAMO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LHA",
    AIRPORTNAME: "Lahr",
    CITYCODE: "LHA",
    CITYNAME: "LAHR",
    CITYNAME_HEB: "LAHR",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LHB",
    AIRPORTNAME: "Lost Harbor Sea Port",
    CITYCODE: "LHB",
    CITYNAME: "LOST HARBOR",
    CITYNAME_HEB: "Lost Harbor Sea Port",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LHE",
    AIRPORTNAME: "Lahore",
    CITYCODE: "LHE",
    CITYNAME: "LAHORE",
    CITYNAME_HEB: "LAHORE",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "LHG",
    AIRPORTNAME: "Lightning Ridge",
    CITYCODE: "LHG",
    CITYNAME: "LIGHTNING RIDGE",
    CITYNAME_HEB: "LIGHTNING RIDGE",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LHI",
    AIRPORTNAME: "Lereh",
    CITYCODE: "LHI",
    CITYNAME: "LEREH",
    CITYNAME_HEB: "LEREH",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LHK",
    AIRPORTNAME: "Guanghua",
    CITYCODE: "LHK",
    CITYNAME: "GUANGHUA",
    CITYNAME_HEB: "\u05d2\u05d0\u05e0\u05d2\u05d5",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LHN",
    AIRPORTNAME: "Lishan",
    CITYCODE: "LHN",
    CITYNAME: "LISHAN",
    CITYNAME_HEB: "LISHAN",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "LHP",
    AIRPORTNAME: "Lehu",
    CITYCODE: "LHP",
    CITYNAME: "LEHU",
    CITYNAME_HEB: "LEHU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "AUC",
    AIRPORTNAME: "Arauca",
    CITYCODE: "AUC",
    CITYNAME: "ARAUCA",
    CITYNAME_HEB: "ARAUCA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LHS",
    AIRPORTNAME: null,
    CITYCODE: "LHS",
    CITYNAME: "LAS HERAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LHV",
    AIRPORTNAME: "W T Piper Memorial",
    CITYCODE: "LHV",
    CITYNAME: "LOCK HAVEN",
    CITYNAME_HEB: "LOCK HAVEN",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LHW",
    AIRPORTNAME: "Lanzhou Airport",
    CITYCODE: "LHW",
    CITYNAME: "LANZHOU",
    CITYNAME_HEB: "LANZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LZD",
    AIRPORTNAME: "Lanzhoudong",
    CITYCODE: "LHW",
    CITYNAME: "LANZHOU",
    CITYNAME_HEB: "LANZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ZGC",
    AIRPORTNAME: "Zhongchuan",
    CITYCODE: "LHW",
    CITYNAME: "LANZHOU",
    CITYNAME_HEB: "LANZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LIA",
    AIRPORTNAME: "Liangping",
    CITYCODE: "LIA",
    CITYNAME: "LIANGPING",
    CITYNAME_HEB: "LIANGPING",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LIB",
    AIRPORTNAME: " ",
    CITYCODE: "LIB",
    CITYNAME: "LIMBIATE",
    CITYNAME_HEB: "\u05dc\u05d9\u05de\u05d1\u05d9\u05d0\u05d8\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LIC",
    AIRPORTNAME: "Municipal",
    CITYCODE: "LIC",
    CITYNAME: "LIMON",
    CITYNAME_HEB: "LIMON",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LID",
    AIRPORTNAME: "Valkenburg",
    CITYCODE: "LID",
    CITYNAME: "LEIDEN",
    CITYNAME_HEB: "\u05dc\u05d9\u05d9\u05d3\u05df",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "LIE",
    AIRPORTNAME: "Libenge",
    CITYCODE: "LIE",
    CITYNAME: "LIBENGE",
    CITYNAME_HEB: "LIBENGE",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "AUD",
    AIRPORTNAME: "Augustus Downs",
    CITYCODE: "AUD",
    CITYNAME: "AUGUSTUS DOWNS",
    CITYNAME_HEB: "AUGUSTUS DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LIF",
    AIRPORTNAME: "Lifou",
    CITYCODE: "LIF",
    CITYNAME: "LIFOU",
    CITYNAME_HEB: "LIFOU",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "LIG",
    AIRPORTNAME: "Bellegarde",
    CITYCODE: "LIG",
    CITYNAME: "LIMOGES",
    CITYNAME_HEB: "LIMOGES",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "HPV",
    AIRPORTNAME: "Princeville",
    CITYCODE: "LIH",
    CITYNAME: "KAUAI ISLAND",
    CITYNAME_HEB: "KAUAI ISLAND",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LIH",
    AIRPORTNAME: "Lihue",
    CITYCODE: "LIH",
    CITYNAME: "KAUAI ISLAND",
    CITYNAME_HEB: "KAUAI ISLAND",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LII",
    AIRPORTNAME: "Mulia",
    CITYCODE: "LII",
    CITYNAME: "MULIA",
    CITYNAME_HEB: "MULIA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LIJ",
    AIRPORTNAME: "Long Island",
    CITYCODE: "LIJ",
    CITYNAME: "LONG ISLAND",
    CITYNAME_HEB: "Long Island",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LIK",
    AIRPORTNAME: "Likiep Island",
    CITYCODE: "LIK",
    CITYNAME: "LIKIEP ISLAND",
    CITYNAME_HEB: "LIKIEP ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "LIL",
    AIRPORTNAME: "Lesquin",
    CITYCODE: "LIL",
    CITYNAME: "LILLE",
    CITYNAME_HEB: "\u05dc\u05d9\u05dc",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "XDB",
    AIRPORTNAME: null,
    CITYCODE: "LIL",
    CITYNAME: "LILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LIM",
    AIRPORTNAME: "J Chavez International",
    CITYCODE: "LIM",
    CITYNAME: "LIMA",
    CITYNAME_HEB: "\u05dc\u05d9\u05de\u05d4",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "AUF",
    AIRPORTNAME: "Auxerre Branches",
    CITYCODE: "AUF",
    CITYNAME: "AUXERRE",
    CITYNAME_HEB: "AUXERRE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LIO",
    AIRPORTNAME: "Limon",
    CITYCODE: "LIO",
    CITYNAME: "LIMON",
    CITYNAME_HEB: "LIMON",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "LIP",
    AIRPORTNAME: null,
    CITYCODE: "LIP",
    CITYNAME: "LINS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LIQ",
    AIRPORTNAME: "Lisala",
    CITYCODE: "LIQ",
    CITYNAME: "LISALA",
    CITYNAME_HEB: "LISALA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "LIR",
    AIRPORTNAME: "Liberia",
    CITYCODE: "LIR",
    CITYNAME: "LIBERIA",
    CITYNAME_HEB: "LIBERIA",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "LIS",
    AIRPORTNAME: "Lisboa",
    CITYCODE: "LIS",
    CITYNAME: "LISBON",
    CITYNAME_HEB: "\u05dc\u05d9\u05e1\u05d1\u05d5\u05df",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "LIT",
    AIRPORTNAME: " ",
    CITYCODE: "LIT",
    CITYNAME: "LITHUANIA",
    CITYNAME_HEB: "\u05dc\u05d9\u05d8\u05d0",
    COUNTRYCODE: "LT",
    COUNTRYNAME: "LITHUANIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d8\u05d0",
  },
  {
    AIRPORTCODE: "LIU",
    AIRPORTNAME: "Linosa Heliport",
    CITYCODE: "LIU",
    CITYNAME: "LINOSA",
    CITYNAME_HEB: "\u05dc\u05d9\u05e0\u05d5\u05d6\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LIV",
    AIRPORTNAME: "Livengood",
    CITYCODE: "LIV",
    CITYNAME: "LIVENGOOD",
    CITYNAME_HEB: "Livengood",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LIW",
    AIRPORTNAME: "Loikaw",
    CITYCODE: "LIW",
    CITYNAME: "LOIKAW",
    CITYNAME_HEB: "LOIKAW",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "LIX",
    AIRPORTNAME: "Likoma",
    CITYCODE: "LIX",
    CITYNAME: "LIKOMA ISLAND",
    CITYNAME_HEB: "LIKOMA ISLAND",
    COUNTRYCODE: "MW",
    COUNTRYNAME: "MALAWI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "AUG",
    AIRPORTNAME: "Augusta",
    CITYCODE: "AUG",
    CITYNAME: "AUGUSTA",
    CITYNAME_HEB: "AUGUSTA",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LIY",
    AIRPORTNAME: "Wright AAF",
    CITYCODE: "LIY",
    CITYNAME: "HINESVILLE",
    CITYNAME_HEB: "HINESVILLE",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "LIZ",
    AIRPORTNAME: "Loring AFB",
    CITYCODE: "LIZ",
    CITYNAME: "LIMESTONE",
    CITYNAME_HEB: "LIMESTONE",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LJA",
    AIRPORTNAME: "Lodja",
    CITYCODE: "LJA",
    CITYNAME: "LODJA",
    CITYNAME_HEB: "LODJA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "LJG",
    AIRPORTNAME: "Lijiang",
    CITYCODE: "LJG",
    CITYNAME: "LIJIANG CITY",
    CITYNAME_HEB: "\u05dc\u05d9\u05d2\u05d9\u05d0\u05e0\u05d2",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "LJN",
    AIRPORTNAME: "Brazoria County",
    CITYCODE: "LJN",
    CITYNAME: "LAKE JACKSON",
    CITYNAME_HEB: "LAKE JACKSON",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LJU",
    AIRPORTNAME: "Brnik",
    CITYCODE: "LJU",
    CITYNAME: "LJUBLJANA",
    CITYNAME_HEB: "\u05dc\u05d5\u05d1\u05dc\u05d9\u05d0\u05e0\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LKA",
    AIRPORTNAME: "Larantuka",
    CITYCODE: "LKA",
    CITYNAME: "LARANTUKA",
    CITYNAME_HEB: "LARANTUKA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LKB",
    AIRPORTNAME: "Lakeba",
    CITYCODE: "LKB",
    CITYNAME: "LAKEBA",
    CITYNAME_HEB: "LAKEBA",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "LKC",
    AIRPORTNAME: "Lekana",
    CITYCODE: "LKC",
    CITYNAME: "LEKANA",
    CITYNAME_HEB: "LEKANA",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "LKD",
    AIRPORTNAME: "Lakeland Downs",
    CITYCODE: "LKD",
    CITYNAME: "LAKELAND DOWNS",
    CITYNAME_HEB: "LAKELAND DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ABY",
    AIRPORTNAME: "Albany Dougherty County",
    CITYCODE: "ABY",
    CITYNAME: "ALBANY",
    CITYNAME_HEB: "Albany",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "AUH",
    AIRPORTNAME: "Abu Dhabi International",
    CITYCODE: "AUH",
    CITYNAME: "ABU DHABI",
    CITYNAME_HEB: "\u05d0\u05d1\u05d5 \u05d3\u05d0\u05d1\u05d9",
    COUNTRYCODE: "AE",
    COUNTRYNAME: "UNITED ARAB EMIRATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d7\u05d5\u05d3 \u05d4\u05d0\u05de\u05d9\u05e8\u05d5\u05d9\u05d5\u05ea",
  },
  {
    AIRPORTCODE: "LKG",
    AIRPORTNAME: "Lokichoggio",
    CITYCODE: "LKG",
    CITYNAME: "LOKICHOGGIO",
    CITYNAME_HEB: "LOKICHOGGIO",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LKK",
    AIRPORTNAME: "Kulik Lake",
    CITYCODE: "LKK",
    CITYNAME: "KULIK LAKE",
    CITYNAME_HEB: "Kulik Lake",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LKL",
    AIRPORTNAME: "Banak",
    CITYCODE: "LKL",
    CITYNAME: "LAKSELV",
    CITYNAME_HEB: "LAKSELV",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LKN",
    AIRPORTNAME: "Leknes",
    CITYCODE: "LKN",
    CITYNAME: "LEKNES",
    CITYNAME_HEB: "LEKNES",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LKO",
    AIRPORTNAME: "Amausi",
    CITYCODE: "LKO",
    CITYNAME: "LUCKNOW",
    CITYNAME_HEB: "LUCKNOW",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "LKP",
    AIRPORTNAME: "Lake Placid",
    CITYCODE: "LKP",
    CITYNAME: "LAKE PLACID",
    CITYNAME_HEB: "LAKE PLACID",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LKR",
    AIRPORTNAME: "Las Khoreh",
    CITYCODE: "LKR",
    CITYNAME: "LAS KHOREH",
    CITYNAME_HEB: "LAS KHOREH",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LKS",
    AIRPORTNAME: "Lakeside",
    CITYCODE: "LKS",
    CITYNAME: "LAKESIDE",
    CITYNAME_HEB: "LAKESIDE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LKT",
    AIRPORTNAME: "Lakota",
    CITYCODE: "LKT",
    CITYNAME: "LAKOTA",
    CITYNAME_HEB: "LAKOTA",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "LKU",
    AIRPORTNAME: "Lake Rudolf",
    CITYCODE: "LKU",
    CITYNAME: "LAKE RUDOLF",
    CITYNAME_HEB: "LAKE RUDOLF",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AZI",
    AIRPORTNAME: null,
    CITYCODE: "AUH",
    CITYNAME: "ABU DHABI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AE",
    COUNTRYNAME: "UNITED ARAB EMIRATES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LKV",
    AIRPORTNAME: "Lake County",
    CITYCODE: "LKV",
    CITYNAME: "LAKEVIEW",
    CITYNAME_HEB: "LAKEVIEW",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LKY",
    AIRPORTNAME: "Lake Manyara",
    CITYCODE: "LKY",
    CITYNAME: "LAKE MANYARA",
    CITYNAME_HEB: "LAKE MANYARA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LKZ",
    AIRPORTNAME: "Lakenheath RAF",
    CITYCODE: "LKZ",
    CITYNAME: "BRANDON",
    CITYNAME_HEB: "BRANDON",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LLA",
    AIRPORTNAME: "Kallax",
    CITYCODE: "LLA",
    CITYNAME: "LULEA",
    CITYNAME_HEB: "LULEA",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LLE",
    AIRPORTNAME: "Malelane",
    CITYCODE: "LLE",
    CITYNAME: "MALELANE",
    CITYNAME_HEB: "MALELANE",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "LLG",
    AIRPORTNAME: "Chillagoe",
    CITYCODE: "LLG",
    CITYNAME: "CHILLAGOE",
    CITYNAME_HEB: "CHILLAGOE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LLH",
    AIRPORTNAME: "Las Limas",
    CITYCODE: "LLH",
    CITYNAME: "LAS LIMAS",
    CITYNAME_HEB: "LAS LIMAS",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "LLI",
    AIRPORTNAME: "Lalibela",
    CITYCODE: "LLI",
    CITYNAME: "LALIBELA",
    CITYNAME_HEB: "\u05dc\u05dc\u05d9\u05d1\u05dc\u05d4",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LLL",
    AIRPORTNAME: "Lissadell",
    CITYCODE: "LLL",
    CITYNAME: "LISSADELL",
    CITYNAME_HEB: "LISSADELL",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LLM",
    AIRPORTNAME: "Long Lama",
    CITYCODE: "LLM",
    CITYNAME: "LONG LAMA",
    CITYNAME_HEB: "LONG LAMA",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AUI",
    AIRPORTNAME: "Aua Island",
    CITYCODE: "AUI",
    CITYNAME: "AUA ISLAND",
    CITYNAME_HEB: "AUA ISLAND",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LLN",
    AIRPORTNAME: "Kelila",
    CITYCODE: "LLN",
    CITYNAME: "KELILA",
    CITYNAME_HEB: "KELILA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LLP",
    AIRPORTNAME: "Linda Downs",
    CITYCODE: "LLP",
    CITYNAME: "LINDA DOWNS",
    CITYNAME_HEB: "LINDA DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LLS",
    AIRPORTNAME: null,
    CITYCODE: "LLS",
    CITYNAME: "LAS LOMITAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LLU",
    AIRPORTNAME: "Alluitsup Paa",
    CITYCODE: "LLU",
    CITYNAME: "ALLUITSUP PAA",
    CITYNAME_HEB: "ALLUITSUP PAA",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "LLW",
    AIRPORTNAME: "Lilongwe International",
    CITYCODE: "LLW",
    CITYNAME: "LILONGWE",
    CITYNAME_HEB: "\u05dc\u05d9\u05dc\u05d5\u05e0\u05e0\u05d2\u05d4",
    COUNTRYCODE: "MW",
    COUNTRYNAME: "MALAWI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "LLX",
    AIRPORTNAME: "Lyndonville",
    CITYCODE: "LLX",
    CITYNAME: "LYNDONVILLE",
    CITYNAME_HEB: "LYNDONVILLE",
    COUNTRYCODE: "USVT",
    COUNTRYNAME: "VERMONT",
    COUNTRYNAME_HEB:
      '\u05d5\u05d5\u05e8\u05de\u05d5\u05e0\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LLY",
    AIRPORTNAME: "Burlington County",
    CITYCODE: "LLY",
    CITYNAME: "MOUNT HOLLY",
    CITYNAME_HEB: "MOUNT HOLLY",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "LMA",
    AIRPORTNAME: "Lake Minchumina",
    CITYCODE: "LMA",
    CITYNAME: "LAKE MINCHUMINA",
    CITYNAME_HEB: "Lake Minchumina",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LMB",
    AIRPORTNAME: "Salima",
    CITYCODE: "LMB",
    CITYNAME: "SALIMA",
    CITYNAME_HEB: "SALIMA",
    COUNTRYCODE: "MW",
    COUNTRYNAME: "MALAWI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "LMC",
    AIRPORTNAME: "Lamacarena",
    CITYCODE: "LMC",
    CITYNAME: "LAMACARENA",
    CITYNAME_HEB: "LAMACARENA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AUJ",
    AIRPORTNAME: "Ambunti",
    CITYCODE: "AUJ",
    CITYNAME: "AMBUNTI",
    CITYNAME_HEB: "AMBUNTI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LMD",
    AIRPORTNAME: null,
    CITYCODE: "LMD",
    CITYNAME: "LOS MENUCOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LME",
    AIRPORTNAME: "Arnage",
    CITYCODE: "LME",
    CITYNAME: "LE MANS",
    CITYNAME_HEB: "LE MANS",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "ZLN",
    AIRPORTNAME: null,
    CITYCODE: "LME",
    CITYNAME: "LE MANS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LMG",
    AIRPORTNAME: "Lamassa",
    CITYCODE: "LMG",
    CITYNAME: "LAMASSA",
    CITYNAME_HEB: "LAMASSA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LMH",
    AIRPORTNAME: "Limon",
    CITYCODE: "LMH",
    CITYNAME: "LIMON",
    CITYNAME_HEB: "LIMON",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "LMI",
    AIRPORTNAME: "Lumi",
    CITYCODE: "LMI",
    CITYNAME: "LUMI",
    CITYNAME_HEB: "LUMI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LMK",
    AIRPORTNAME: " ",
    CITYCODE: "LMK",
    CITYNAME: "LIMERICK",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "LML",
    AIRPORTNAME: "Lae Island",
    CITYCODE: "LML",
    CITYNAME: "LAE ISLAND",
    CITYNAME_HEB: "LAE ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "LMM",
    AIRPORTNAME: "Federal",
    CITYCODE: "LMM",
    CITYNAME: "LOS MOCHIS",
    CITYNAME_HEB: "LOS MOCHIS",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "LMN",
    AIRPORTNAME: "Limbang",
    CITYCODE: "LMN",
    CITYNAME: "LIMBANG",
    CITYNAME_HEB: "LIMBANG",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AUK",
    AIRPORTNAME: "Alakanuk",
    CITYCODE: "AUK",
    CITYNAME: "ALAKANUK",
    CITYNAME_HEB: "Alakanuk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LMO",
    AIRPORTNAME: "RAF Station",
    CITYCODE: "LMO",
    CITYNAME: "LOSSIEMOUTH",
    CITYNAME_HEB: "LOSSIEMOUTH",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LMP",
    AIRPORTNAME: "Lampedusa",
    CITYCODE: "LMP",
    CITYNAME: "LAMPEDUSA",
    CITYNAME_HEB: "LAMPEDUSA",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LMQ",
    AIRPORTNAME: "Marsa Brega",
    CITYCODE: "LMQ",
    CITYNAME: "MARSA BREGA",
    CITYNAME_HEB: "MARSA BREGA",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "LMR",
    AIRPORTNAME: "Lime Acres",
    CITYCODE: "LMR",
    CITYNAME: "LIME ACRES",
    CITYNAME_HEB: "LIME ACRES",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "LMS",
    AIRPORTNAME: " ",
    CITYCODE: "LMS",
    CITYNAME: "LA MASSANA",
    CITYNAME_HEB: "\u05dc\u05d4 \u05de\u05d0\u05e1\u05d0\u05e0\u05d4",
    COUNTRYCODE: "AD",
    COUNTRYNAME: "ANDORRA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d3\u05d5\u05e8\u05d4",
  },
  {
    AIRPORTCODE: "LMT",
    AIRPORTNAME: "Kingsley Field",
    CITYCODE: "LMT",
    CITYNAME: "KLAMATH FALLS",
    CITYNAME_HEB: "KLAMATH FALLS",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LMX",
    AIRPORTNAME: "Lopez De Micay",
    CITYCODE: "LMX",
    CITYNAME: "LOPEZ DE MICAY",
    CITYNAME_HEB: "LOPEZ DE MICAY",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LMY",
    AIRPORTNAME: "Lake Murray",
    CITYCODE: "LMY",
    CITYNAME: "LAKE MURRAY",
    CITYNAME_HEB: "LAKE MURRAY",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LMZ",
    AIRPORTNAME: "Palma",
    CITYCODE: "LMZ",
    CITYNAME: "PALMA",
    CITYNAME_HEB: "PALMA",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "LNB",
    AIRPORTNAME: "Lamen Bay",
    CITYCODE: "LNB",
    CITYNAME: "LAMEN BAY",
    CITYNAME_HEB: "LAMEN BAY",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "AUL",
    AIRPORTNAME: "Aur Island",
    CITYCODE: "AUL",
    CITYNAME: "AUR ISLAND",
    CITYNAME_HEB: "AUR ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "LNC",
    AIRPORTNAME: "Lengbati",
    CITYCODE: "LNC",
    CITYNAME: "LENGBATI",
    CITYNAME_HEB: "LENGBATI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LND",
    AIRPORTNAME: "Hunt Field",
    CITYCODE: "LND",
    CITYNAME: "LANDER",
    CITYNAME_HEB: "LANDER",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LNE",
    AIRPORTNAME: "Lonorore",
    CITYCODE: "LNE",
    CITYNAME: "LONORORE",
    CITYNAME_HEB: "LONORORE",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "LNF",
    AIRPORTNAME: "Munbil",
    CITYCODE: "LNF",
    CITYNAME: "MUNBIL",
    CITYNAME_HEB: "MUNBIL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LNG",
    AIRPORTNAME: "Lese",
    CITYCODE: "LNG",
    CITYNAME: "LESE",
    CITYNAME_HEB: "LESE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LNH",
    AIRPORTNAME: "Lake Nash",
    CITYCODE: "LNH",
    CITYNAME: "LAKE NASH",
    CITYNAME_HEB: "LAKE NASH",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LNI",
    AIRPORTNAME: "Dew Station",
    CITYCODE: "LNI",
    CITYNAME: "LONELY",
    CITYNAME_HEB: "Dew Station",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LNJ",
    AIRPORTNAME: null,
    CITYCODE: "LNJ",
    CITYNAME: "LINCANG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LNK",
    AIRPORTNAME: "Municipal",
    CITYCODE: "LNK",
    CITYNAME: "LINCOLN",
    CITYNAME_HEB: "LINCOLN",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LNM",
    AIRPORTNAME: "Langimar",
    CITYCODE: "LNM",
    CITYNAME: "LANGIMAR",
    CITYNAME_HEB: "LANGIMAR",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "AUM",
    AIRPORTNAME: "Austin",
    CITYCODE: "AUM",
    CITYNAME: "AUSTIN",
    CITYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05d9\u05df",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LNN",
    AIRPORTNAME: "Lost Nation",
    CITYCODE: "LNN",
    CITYNAME: "WILLOUGHBY",
    CITYNAME_HEB: "WILLOUGHBY",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LNO",
    AIRPORTNAME: "Leonora",
    CITYCODE: "LNO",
    CITYNAME: "LEONORA",
    CITYNAME_HEB: "LEONORA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LNP",
    AIRPORTNAME: "Wise",
    CITYCODE: "LNP",
    CITYNAME: "WISE",
    CITYNAME_HEB: "WISE",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "LNQ",
    AIRPORTNAME: "Loani",
    CITYCODE: "LNQ",
    CITYNAME: "LOANI",
    CITYNAME_HEB: "LOANI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LNR",
    AIRPORTNAME: "Tri County",
    CITYCODE: "LNR",
    CITYNAME: "LONE ROCK",
    CITYNAME_HEB: "LONE ROCK",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LNS",
    AIRPORTNAME: "Lancaster",
    CITYCODE: "LNS",
    CITYNAME: "LANCASTER",
    CITYNAME_HEB: "LANCASTER",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZRL",
    AIRPORTNAME: null,
    CITYCODE: "LNS",
    CITYNAME: "LANCASTER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LNV",
    AIRPORTNAME: "Lihir Island",
    CITYCODE: "LNV",
    CITYNAME: "LIHIR ISLAND",
    CITYNAME_HEB: "LIHIR ISLAND",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "LNX",
    AIRPORTNAME: "Smolensk",
    CITYCODE: "LNX",
    CITYNAME: "SMOLENSK",
    CITYNAME_HEB: "\u05e1\u05de\u05d5\u05dc\u05d0\u05e0\u05e1\u05e7",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LNY",
    AIRPORTNAME: "Lanai City",
    CITYCODE: "LNY",
    CITYNAME: "LANAI CITY",
    CITYNAME_HEB: "LANAI CITY",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AUN",
    AIRPORTNAME: "Auburn",
    CITYCODE: "AUN",
    CITYNAME: "AUBURN",
    CITYNAME_HEB: "AUBURN",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LNZ",
    AIRPORTNAME: "Hoersching",
    CITYCODE: "LNZ",
    CITYNAME: "LINZ",
    CITYNAME_HEB: "\u05dc\u05d9\u05e0\u05e5",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LOA",
    AIRPORTNAME: "Lorraine",
    CITYCODE: "LOA",
    CITYNAME: "LORRAINE",
    CITYNAME_HEB: "LORRAINE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LOB",
    AIRPORTNAME: "Los Andes",
    CITYCODE: "LOB",
    CITYNAME: "LOS ANDES",
    CITYNAME_HEB: "LOS ANDES",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LOC",
    AIRPORTNAME: "Lock",
    CITYCODE: "LOC",
    CITYNAME: "LOCK",
    CITYNAME_HEB: "LOCK",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LOD",
    AIRPORTNAME: "Longana",
    CITYCODE: "LOD",
    CITYNAME: "LONGANA",
    CITYNAME_HEB: "LONGANA",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "LOE",
    AIRPORTNAME: "Loei",
    CITYCODE: "LOE",
    CITYNAME: "LOEI",
    CITYNAME_HEB: "LOEI",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "LOF",
    AIRPORTNAME: "Loen",
    CITYCODE: "LOF",
    CITYNAME: "LOEN",
    CITYNAME_HEB: "LOEN",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "LOG",
    AIRPORTNAME: "Longview",
    CITYCODE: "LOG",
    CITYNAME: "LONGVIEW",
    CITYNAME_HEB: "LONGVIEW",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LOH",
    AIRPORTNAME: "Loja",
    CITYCODE: "LOH",
    CITYNAME: "LOJA",
    CITYNAME_HEB: "LOJA",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "LOK",
    AIRPORTNAME: "Lodwar",
    CITYCODE: "LOK",
    CITYNAME: "LODWAR",
    CITYNAME_HEB: "LODWAR",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AUO",
    AIRPORTNAME: "Auburn-Opelika",
    CITYCODE: "AUO",
    CITYNAME: "AUBURN",
    CITYNAME_HEB: "Auburn",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LOL",
    AIRPORTNAME: "Derby Field",
    CITYCODE: "LOL",
    CITYNAME: "LOVELOCK",
    CITYNAME_HEB: "LOVELOCK",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LOM",
    AIRPORTNAME: "Francisco P. V. y R.",
    CITYCODE: "LOM",
    CITYNAME: "LAGOS DE MORENO",
    CITYNAME_HEB: "LAGOS DE MORENO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "LON",
    AIRPORTNAME: null,
    CITYCODE: "LON",
    CITYNAME: "LONDON",
    CITYNAME_HEB: "\u05dc\u05d5\u05e0\u05d3\u05d5\u05df",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LTN",
    AIRPORTNAME: "Luton Airport",
    CITYCODE: "LON",
    CITYNAME: "LONDON",
    CITYNAME_HEB: "\u05dc\u05d5\u05e0\u05d3\u05d5\u05df",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LCY",
    AIRPORTNAME: "City Airport",
    CITYCODE: "LON",
    CITYNAME: "LONDON",
    CITYNAME_HEB: "\u05dc\u05d5\u05e0\u05d3\u05d5\u05df",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LGW",
    AIRPORTNAME: "Gatwick",
    CITYCODE: "LON",
    CITYNAME: "LONDON",
    CITYNAME_HEB: "\u05dc\u05d5\u05e0\u05d3\u05d5\u05df",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LHR",
    AIRPORTNAME: "Heathrow",
    CITYCODE: "LON",
    CITYNAME: "LONDON",
    CITYNAME_HEB: "\u05dc\u05d5\u05e0\u05d3\u05d5\u05df",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AUP",
    AIRPORTNAME: "Agaun",
    CITYCODE: "AUP",
    CITYNAME: "AGAUN",
    CITYNAME_HEB: "AGAUN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "STN",
    AIRPORTNAME: "Stansted",
    CITYCODE: "LON",
    CITYNAME: "LONDON",
    CITYNAME_HEB: "\u05dc\u05d5\u05e0\u05d3\u05d5\u05df",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LOO",
    AIRPORTNAME: "L'Mekrareg",
    CITYCODE: "LOO",
    CITYNAME: "LAGHOUAT",
    CITYNAME_HEB: "LAGHOUAT",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LOQ",
    AIRPORTNAME: "Lobatse",
    CITYCODE: "LOQ",
    CITYNAME: "LOBATSE",
    CITYNAME_HEB: "LOBATSE",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "LOS",
    AIRPORTNAME: "Murtala Muhammed",
    CITYCODE: "LOS",
    CITYNAME: "LAGOS",
    CITYNAME_HEB: "\u05dc\u05d0\u05d2\u05d5\u05e1",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LOT",
    AIRPORTNAME: "Lewis Lockport",
    CITYCODE: "LOT",
    CITYNAME: "LOCKPORT",
    CITYNAME_HEB: "LOCKPORT",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ABZ",
    AIRPORTNAME: "Dyce",
    CITYCODE: "ABZ",
    CITYNAME: "ABERDEEN",
    CITYNAME_HEB: "\u05d0\u05d1\u05e8\u05d3\u05d9\u05df",
    COUNTRYCODE: "ST",
    COUNTRYNAME: "SCOTLAND",
    COUNTRYNAME_HEB: "\u05e1\u05e7\u05d5\u05d8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "AUQ",
    AIRPORTNAME: "Atuona",
    CITYCODE: "AUQ",
    CITYNAME: "ATUONA",
    CITYNAME_HEB: "ATUONA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "LOV",
    AIRPORTNAME: "Monclova",
    CITYCODE: "LOV",
    CITYNAME: "MONCLOVA",
    CITYNAME_HEB: "MONCLOVA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "LOW",
    AIRPORTNAME: "Louisa",
    CITYCODE: "LOW",
    CITYNAME: "LOUISA",
    CITYNAME_HEB: "LOUISA",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "LOX",
    AIRPORTNAME: "Los Tablones Airport",
    CITYCODE: "LOX",
    CITYNAME: "LOS TABLONES",
    CITYNAME_HEB: "LOS TABLONES",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LOY",
    AIRPORTNAME: "Loyangalani",
    CITYCODE: "LOY",
    CITYNAME: "LOYANGALANI",
    CITYNAME_HEB: "LOYANGALANI",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LPA",
    AIRPORTNAME: "Airport de Gran Canaria",
    CITYCODE: "LPA",
    CITYNAME: "GRAN CANARIA",
    CITYNAME_HEB:
      "\u05d0\u05d9\u05d9\u05dd \u05d4\u05e7\u05e0\u05e8\u05d9\u05d9\u05dd",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "LPB",
    AIRPORTNAME: "El Alto",
    CITYCODE: "LPB",
    CITYNAME: "LA PAZ",
    CITYNAME_HEB: "\u05dc\u05d4 \u05e4\u05d0\u05d6",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LPC",
    AIRPORTNAME: "Lompoc Airport",
    CITYCODE: "LPC",
    CITYNAME: "LOMPOC",
    CITYNAME_HEB: "LOMPOC",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VBG",
    AIRPORTNAME: "Vandenberg AFB",
    CITYCODE: "LPC",
    CITYNAME: "LOMPOC",
    CITYNAME_HEB: "LOMPOC",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LPD",
    AIRPORTNAME: "La Pedrera",
    CITYCODE: "LPD",
    CITYNAME: "LA PEDRERA",
    CITYNAME_HEB: "LA PEDRERA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AUR",
    AIRPORTNAME: "Aurillac",
    CITYCODE: "AUR",
    CITYNAME: "AURILLAC",
    CITYNAME_HEB: "AURILLAC",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LPE",
    AIRPORTNAME: "La Primavera",
    CITYCODE: "LPE",
    CITYNAME: "LA PRIMAVERA",
    CITYNAME_HEB: "LA PRIMAVERA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LPG",
    AIRPORTNAME: null,
    CITYCODE: "LPG",
    CITYNAME: "LA PLATA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LPH",
    AIRPORTNAME: "Heliport",
    CITYCODE: "LPH",
    CITYNAME: "LOCHGILPHEAD",
    CITYNAME_HEB: "LOCHGILPHEAD",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LPI",
    AIRPORTNAME: "Linkoping",
    CITYCODE: "LPI",
    CITYNAME: "LINKOPING",
    CITYNAME_HEB: "LINKOPING",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LPJ",
    AIRPORTNAME: "Pijiguaos",
    CITYCODE: "LPJ",
    CITYNAME: "PIJIGUAOS",
    CITYNAME_HEB: "PIJIGUAOS",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "LPK",
    AIRPORTNAME: "Lipetsk",
    CITYCODE: "LPK",
    CITYNAME: "LIPETSK",
    CITYNAME_HEB: "LIPETSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LPL",
    AIRPORTNAME: "Liverpool International Airport",
    CITYCODE: "LPL",
    CITYNAME: "LIVERPOOL",
    CITYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05e4\u05d5\u05dc",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LPM",
    AIRPORTNAME: "Lamap",
    CITYCODE: "LPM",
    CITYNAME: "LAMAP",
    CITYNAME_HEB: "LAMAP",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "LPN",
    AIRPORTNAME: " ",
    CITYCODE: "LPN",
    CITYNAME: "LAPLAND",
    CITYNAME_HEB: "\u05dc\u05e4\u05dc\u05e0\u05d3",
    COUNTRYCODE: "LP",
    COUNTRYNAME: "LAPLAND",
    COUNTRYNAME_HEB: "\u05dc\u05e4\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "LPO",
    AIRPORTNAME: "Municipal",
    CITYCODE: "LPO",
    CITYNAME: "LAPORTE",
    CITYNAME_HEB: "LAPORTE",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AUS",
    AIRPORTNAME: "Austin-Bergstrom International",
    CITYCODE: "AUS",
    CITYNAME: "AUSTIN",
    CITYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05d9\u05df",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LPP",
    AIRPORTNAME: "Lappeenranta",
    CITYCODE: "LPP",
    CITYNAME: "LAPPEENRANTA",
    CITYNAME_HEB: "LAPPEENRANTA",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "LPQ",
    AIRPORTNAME: "Luang Prabang",
    CITYCODE: "LPQ",
    CITYNAME: "LUANG PRABANG",
    CITYNAME_HEB:
      "\u05dc\u05d5\u05d0\u05e0\u05d2 \u05e4\u05e8\u05d1\u05d0\u05e0\u05d2",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAOS",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "LPS",
    AIRPORTNAME: "Lopez Island",
    CITYCODE: "LPS",
    CITYNAME: "LOPEZ ISLAND",
    CITYNAME_HEB: "LOPEZ ISLAND",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LPT",
    AIRPORTNAME: "Lampang",
    CITYCODE: "LPT",
    CITYNAME: "LAMPANG",
    CITYNAME_HEB: "LAMPANG",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "LPU",
    AIRPORTNAME: "Long Apung",
    CITYCODE: "LPU",
    CITYNAME: "LONG APUNG",
    CITYNAME_HEB: "LONG APUNG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LPW",
    AIRPORTNAME: "Little Port Walter",
    CITYCODE: "LPW",
    CITYNAME: "LITTLE PORT WALTE",
    CITYNAME_HEB: "Little Port Walter",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LPX",
    AIRPORTNAME: "Liepaya",
    CITYCODE: "LPX",
    CITYNAME: "LIEPAYA",
    CITYNAME_HEB: "LIEPAYA",
    COUNTRYCODE: "LV",
    COUNTRYNAME: "LATVIA",
    COUNTRYNAME_HEB: "\u05dc\u05d8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LPY",
    AIRPORTNAME: "Loudes",
    CITYCODE: "LPY",
    CITYNAME: "LE PUY",
    CITYNAME_HEB: "LE PUY",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LQK",
    AIRPORTNAME: "Pickens",
    CITYCODE: "LQK",
    CITYNAME: "PICKENS",
    CITYNAME_HEB: "PICKENS",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LQM",
    AIRPORTNAME: "Puerto Leguizamo",
    CITYCODE: "LQM",
    CITYNAME: "PUERTO LEGUIZAMO",
    CITYNAME_HEB: "PUERTO LEGUIZAMO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LQN",
    AIRPORTNAME: null,
    CITYCODE: "LQN",
    CITYNAME: "QALA NAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LRA",
    AIRPORTNAME: "Larisa",
    CITYCODE: "LRA",
    CITYNAME: "LARISA",
    CITYNAME_HEB: "LARISA",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "LRB",
    AIRPORTNAME: "Leribe",
    CITYCODE: "LRB",
    CITYNAME: "LERIBE",
    CITYNAME_HEB: "LERIBE",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "LRC",
    AIRPORTNAME: "R.A.F.",
    CITYCODE: "LRC",
    CITYNAME: "LAARBRUCH",
    CITYNAME_HEB: "LAARBRUCH",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LRD",
    AIRPORTNAME: "International",
    CITYCODE: "LRD",
    CITYNAME: "LAREDO",
    CITYNAME_HEB: "LAREDO",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LRE",
    AIRPORTNAME: "Longreach",
    CITYCODE: "LRE",
    CITYNAME: "LONGREACH",
    CITYNAME_HEB: "LONGREACH",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LRF",
    AIRPORTNAME: "Little Rock AFB",
    CITYCODE: "LRF",
    CITYNAME: "JACKSONVILLE",
    CITYNAME_HEB: "JACKSONVILLE",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LRG",
    AIRPORTNAME: " ",
    CITYCODE: "LRG",
    CITYNAME: "LEONBERG",
    CITYNAME_HEB: "\u05dc\u05d0\u05d5\u05e0\u05d1\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LRH",
    AIRPORTNAME: "Laleu",
    CITYCODE: "LRH",
    CITYNAME: "LA ROCHELLE",
    CITYNAME_HEB: "LA ROCHELLE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LRI",
    AIRPORTNAME: "Lorica",
    CITYCODE: "LRI",
    CITYNAME: "LORICA",
    CITYNAME_HEB: "LORICA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AUT",
    AIRPORTNAME: "Atauro",
    CITYCODE: "AUT",
    CITYNAME: "ATAURO",
    CITYNAME_HEB: "ATAURO",
    COUNTRYCODE: "TP",
    COUNTRYNAME: "EAST TIMOR",
    COUNTRYNAME_HEB:
      "\u05d8\u05d9\u05de\u05d5\u05e8 \u05d4\u05de\u05d6\u05e8\u05d7\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "LRJ",
    AIRPORTNAME: "Municipal",
    CITYCODE: "LRJ",
    CITYNAME: "LEMARS",
    CITYNAME_HEB: "LEMARS",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LRK",
    AIRPORTNAME: "Coast Guard",
    CITYCODE: "LRK",
    CITYNAME: "LINCOLN ROCK",
    CITYNAME_HEB: "Coast Guard",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LRL",
    AIRPORTNAME: "Lama-Kara",
    CITYCODE: "LRL",
    CITYNAME: "LAMA KARA",
    CITYNAME_HEB: "LAMA KARA",
    COUNTRYCODE: "TG",
    COUNTRYNAME: "TOGO",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "LRM",
    AIRPORTNAME: "La Romana",
    CITYCODE: "LRM",
    CITYNAME: "LA ROMANA",
    CITYNAME_HEB: "LA ROMANA",
    COUNTRYCODE: "DO",
    COUNTRYNAME: "DOMINICAN REPUBLIC",
    COUNTRYNAME_HEB:
      "\u05d4\u05e8\u05e4\u05d5\u05d1\u05dc\u05d9\u05e7\u05d4 \u05d4\u05d3\u05d5\u05de\u05d9\u05e0\u05d9\u05e7\u05e0\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "LRO",
    AIRPORTNAME: "Sharpe AAF",
    CITYCODE: "LRO",
    CITYNAME: "LATHROP",
    CITYNAME_HEB: "LATHROP",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LRQ",
    AIRPORTNAME: "Laurie River",
    CITYCODE: "LRQ",
    CITYNAME: "LAURIE RIVER",
    CITYNAME_HEB: "LAURIE RIVER",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "LRS",
    AIRPORTNAME: "Leros",
    CITYCODE: "LRS",
    CITYNAME: "LEROS",
    CITYNAME_HEB: "LEROS",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "LRT",
    AIRPORTNAME: "Lann Bihoue",
    CITYCODE: "LRT",
    CITYNAME: "LORIENT",
    CITYNAME_HEB: "LORIENT",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LRU",
    AIRPORTNAME: "Municipal",
    CITYCODE: "LRU",
    CITYNAME: "LAS CRUCES",
    CITYNAME_HEB: "LAS CRUCES",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BMF",
    AIRPORTNAME: null,
    CITYCODE: "BMF",
    CITYNAME: "BAKOUMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TGT",
    AIRPORTNAME: "Tanga",
    CITYCODE: "TGT",
    CITYNAME: "TANGA",
    CITYNAME_HEB: "TANGA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TGU",
    AIRPORTNAME: "Toncontin",
    CITYCODE: "TGU",
    CITYNAME: "TEGUCIGALPA",
    CITYNAME_HEB: "\u05d8\u05d2\u05d5\u05e1\u05d9\u05d2\u05dc\u05e4\u05d4",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "TGV",
    AIRPORTNAME: "Targovishte",
    CITYCODE: "TGV",
    CITYNAME: "TARGOVISHTE",
    CITYNAME_HEB: "\u05d8\u05e8\u05d2\u05d5\u05d1\u05d9\u05e5",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TGX",
    AIRPORTNAME: "Tingrela",
    CITYCODE: "TGX",
    CITYNAME: "TINGRELA",
    CITYNAME_HEB: "TINGRELA",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "TGZ",
    AIRPORTNAME: "Llano San Juan",
    CITYCODE: "TGZ",
    CITYNAME: "TUXTLA GUTIERREZ",
    CITYNAME_HEB: "TUXTLA GUTIERREZ",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "THA",
    AIRPORTNAME: "Northern",
    CITYCODE: "THA",
    CITYNAME: "TULLAHOMA",
    CITYNAME_HEB: "TULLAHOMA",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TUH",
    AIRPORTNAME: "Arnold AFS",
    CITYCODE: "THA",
    CITYNAME: "TULLAHOMA",
    CITYNAME_HEB: "TULLAHOMA",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "THB",
    AIRPORTNAME: "Thaba-Tseka",
    CITYCODE: "THB",
    CITYNAME: "THABA TSEKA",
    CITYNAME_HEB: "THABA TSEKA",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "THC",
    AIRPORTNAME: "Tchien",
    CITYCODE: "THC",
    CITYNAME: "TCHIEN",
    CITYNAME_HEB: "TCHIEN",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "THE",
    AIRPORTNAME: null,
    CITYCODE: "THE",
    CITYNAME: "TERESINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BMG",
    AIRPORTNAME: "Bloomington",
    CITYCODE: "BMG",
    CITYNAME: "BLOOMINGTON",
    CITYNAME_HEB: "BLOOMINGTON",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "THG",
    AIRPORTNAME: "Thangool",
    CITYCODE: "THG",
    CITYNAME: "THANGOOL",
    CITYNAME_HEB: "THANGOOL",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "THH",
    AIRPORTNAME: "Taharoa",
    CITYCODE: "THH",
    CITYNAME: "TAHAROA",
    CITYNAME_HEB: "TAHAROA",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "THI",
    AIRPORTNAME: " ",
    CITYCODE: "THI",
    CITYNAME: "THAILAND",
    CITYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "THK",
    AIRPORTNAME: "Thakhek",
    CITYCODE: "THK",
    CITYNAME: "THAKHEK",
    CITYNAME_HEB: "THAKHEK",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "THM",
    AIRPORTNAME: "Thompsonfield",
    CITYCODE: "THM",
    CITYNAME: "THOMPSONFIELD",
    CITYNAME_HEB: "THOMPSONFIELD",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "THN",
    AIRPORTNAME: "Trollhattan",
    CITYCODE: "THN",
    CITYNAME: "TROLLHATTAN",
    CITYNAME_HEB: "TROLLHATTAN",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "THO",
    AIRPORTNAME: "Thorshofn",
    CITYCODE: "THO",
    CITYNAME: "THORSHOFN",
    CITYNAME_HEB: "THORSHOFN",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "THP",
    AIRPORTNAME: "Hot Springs",
    CITYCODE: "THP",
    CITYNAME: "THERMOPOLIS",
    CITYNAME_HEB: "THERMOPOLIS",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "THS",
    AIRPORTNAME: "Sukhothai",
    CITYCODE: "THS",
    CITYNAME: "SUKHOTHAI",
    CITYNAME_HEB: "SUKHOTHAI",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BMH",
    AIRPORTNAME: "Bomai",
    CITYCODE: "BMH",
    CITYNAME: "BOMAI",
    CITYNAME_HEB: "BOMAI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "THT",
    AIRPORTNAME: "Tamchakett",
    CITYCODE: "THT",
    CITYNAME: "TAMCHAKETT",
    CITYNAME_HEB: "TAMCHAKETT",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "THU",
    AIRPORTNAME: "Pituffik",
    CITYCODE: "THU",
    CITYNAME: "THULE",
    CITYNAME_HEB: "THULE",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "THV",
    AIRPORTNAME: "York",
    CITYCODE: "THV",
    CITYNAME: "YORK",
    CITYNAME_HEB: "YORK",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "THY",
    AIRPORTNAME: "Thohoyandou",
    CITYCODE: "THY",
    CITYNAME: "THOHOYANDOU",
    CITYNAME_HEB: "THOHOYANDOU",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "THZ",
    AIRPORTNAME: null,
    CITYCODE: "THZ",
    CITYNAME: "TAHOUA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NE",
    COUNTRYNAME: "NIGER",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TIA",
    AIRPORTNAME: "Rinas",
    CITYCODE: "TIA",
    CITYNAME: "TIRANA",
    CITYNAME_HEB:
      "\u05d8\u05d9\u05e8\u05d0\u05e0\u05d4-\u05d0\u05dc\u05d1\u05e0\u05d9\u05d4",
    COUNTRYCODE: "AL",
    COUNTRYNAME: "ALBANIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "TIB",
    CITYNAME: "Tiberias",
    CITYNAME_HEB: "\u05d8\u05d1\u05e8\u05d9\u05d4",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "TIC",
    AIRPORTNAME: "Tinak Island",
    CITYCODE: "TIC",
    CITYNAME: "TINAK ISLAND",
    CITYNAME_HEB: "TINAK ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "TID",
    AIRPORTNAME: "Bouchekif",
    CITYCODE: "TID",
    CITYNAME: "TIARET",
    CITYNAME_HEB: "TIARET",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BMI",
    AIRPORTNAME: "Bloomington-Normal",
    CITYCODE: "BMI",
    CITYNAME: "BLOOMINGTON",
    CITYNAME_HEB: "BLOOMINGTON",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TIE",
    AIRPORTNAME: "Tippi",
    CITYCODE: "TIE",
    CITYNAME: "TIPPI",
    CITYNAME_HEB: "TIPPI",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TIG",
    AIRPORTNAME: "Tingwon",
    CITYCODE: "TIG",
    CITYNAME: "TINGWON",
    CITYNAME_HEB: "TINGWON",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TIH",
    AIRPORTNAME: "Tikehau Atoll",
    CITYCODE: "TIH",
    CITYNAME: "TIKEHAU ATOLL",
    CITYNAME_HEB: "TIKEHAU ATOLL",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "TII",
    AIRPORTNAME: null,
    CITYCODE: "TII",
    CITYNAME: "TIRINKOT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TIJ",
    AIRPORTNAME: "Rodriguez",
    CITYCODE: "TIJ",
    CITYNAME: "TIJUANA",
    CITYNAME_HEB: "TIJUANA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "TIL",
    AIRPORTNAME: "Inverlake",
    CITYCODE: "TIL",
    CITYNAME: "INVERLAKE",
    CITYNAME_HEB: "Inverlake",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "TIM",
    AIRPORTNAME: "Timika",
    CITYCODE: "TIM",
    CITYNAME: "TEMBAGAPURA",
    CITYNAME_HEB: "TEMBAGAPURA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TIN",
    AIRPORTNAME: "Tindouf",
    CITYCODE: "TIN",
    CITYNAME: "TINDOUF",
    CITYNAME_HEB: "TINDOUF",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TIO",
    AIRPORTNAME: "Tilin",
    CITYCODE: "TIO",
    CITYNAME: "TILIN",
    CITYNAME_HEB: "TILIN",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "TIP",
    AIRPORTNAME: "International",
    CITYCODE: "TIP",
    CITYNAME: "TRIPOLI",
    CITYNAME_HEB: "\u05d8\u05e8\u05d9\u05e4\u05d5\u05dc\u05d9",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "BMJ",
    AIRPORTNAME: "Baramita",
    CITYCODE: "BMJ",
    CITYNAME: "BARAMITA",
    CITYNAME_HEB: "BARAMITA",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "TIQ",
    AIRPORTNAME: null,
    CITYCODE: "TIQ",
    CITYNAME: "TINIAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "MP",
    COUNTRYNAME: "NORTHERN MARIANA ISLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TIR",
    AIRPORTNAME: "Tirupati",
    CITYCODE: "TIR",
    CITYNAME: "TIRUPATI",
    CITYNAME_HEB: "TIRUPATI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "TIS",
    AIRPORTNAME: "Thursday Island",
    CITYCODE: "TIS",
    CITYNAME: "THURSDAY ISLAND",
    CITYNAME_HEB: "THURSDAY ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TIU",
    AIRPORTNAME: "Timaru",
    CITYCODE: "TIU",
    CITYNAME: "TIMARU",
    CITYNAME_HEB: "TIMARU",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "TCM",
    AIRPORTNAME: "Mc Chord AFB",
    CITYCODE: "TIW",
    CITYNAME: "TACOMA",
    CITYNAME_HEB: "TACOMA",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TIW",
    AIRPORTNAME: "Industrial",
    CITYCODE: "TIW",
    CITYNAME: "TACOMA",
    CITYNAME_HEB: "TACOMA",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TIX",
    AIRPORTNAME: "Space Center Exect.",
    CITYCODE: "TIX",
    CITYNAME: "TITUSVILLE",
    CITYNAME_HEB: "TITUSVILLE",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TIY",
    AIRPORTNAME: " ",
    CITYCODE: "TIY",
    CITYNAME: "TITISEE",
    CITYNAME_HEB: "\u05d8\u05d9\u05d8\u05e1\u05d9",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BMK",
    AIRPORTNAME: "Borkum",
    CITYCODE: "BMK",
    CITYNAME: "BORKUM",
    CITYNAME_HEB: "BORKUM",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TIZ",
    AIRPORTNAME: "Tari",
    CITYCODE: "TIZ",
    CITYNAME: "TARI",
    CITYNAME_HEB: "TARI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TJA",
    AIRPORTNAME: "Tarija",
    CITYCODE: "TJA",
    CITYNAME: "TARIJA",
    CITYNAME_HEB: "TARIJA",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TJB",
    AIRPORTNAME: "Tanjung Balai",
    CITYCODE: "TJB",
    CITYNAME: "TANJUNG BALAI",
    CITYNAME_HEB: "TANJUNG BALAI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TJC",
    AIRPORTNAME: "Ticantiki",
    CITYCODE: "TJC",
    CITYNAME: "TICANTIKI",
    CITYNAME_HEB: "TICANTIKI",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "TJG",
    AIRPORTNAME: "Tanjung Warukin",
    CITYCODE: "TJG",
    CITYNAME: "TANJUNG WARUKIN",
    CITYNAME_HEB: "TANJUNG WARUKIN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TJH",
    AIRPORTNAME: "Tajima",
    CITYCODE: "TJH",
    CITYNAME: "TOYOOKA",
    CITYNAME_HEB: "TOYOOKA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "TJI",
    AIRPORTNAME: "Capiro",
    CITYCODE: "TJI",
    CITYNAME: "TRUJILLO",
    CITYNAME_HEB: "TRUJILLO",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "TJK",
    AIRPORTNAME: "Tokat",
    CITYCODE: "TJK",
    CITYNAME: "TOKAT",
    CITYNAME_HEB: "TOKAT",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TJM",
    AIRPORTNAME: "Tyumen",
    CITYCODE: "TJM",
    CITYNAME: "TYUMEN",
    CITYNAME_HEB: "TYUMEN",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TJN",
    AIRPORTNAME: "Takume",
    CITYCODE: "TJN",
    CITYNAME: "TAKUME",
    CITYNAME_HEB: "TAKUME",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "BML",
    AIRPORTNAME: "Berlin",
    CITYCODE: "BML",
    CITYNAME: "BERLIN",
    CITYNAME_HEB: "BERLIN (US)",
    COUNTRYCODE: "USNH",
    COUNTRYNAME: "NEW HAMPSHIRE",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d4\u05de\u05e4\u05e9\u05d9\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TJQ",
    AIRPORTNAME: "Bulutumbang",
    CITYCODE: "TJQ",
    CITYNAME: "TANJUNG PENDAN",
    CITYNAME_HEB: "TANJUNG PENDAN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TJS",
    AIRPORTNAME: "Tanjung Selor",
    CITYCODE: "TJS",
    CITYNAME: "TANJUNG SELOR",
    CITYNAME_HEB: "TANJUNG SELOR",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TJV",
    AIRPORTNAME: "Thanjavur",
    CITYCODE: "TJV",
    CITYNAME: "THANJAVUR",
    CITYNAME_HEB: "THANJAVUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "TKA",
    AIRPORTNAME: "Talkeetna",
    CITYCODE: "TKA",
    CITYNAME: "TALKEETNA",
    CITYNAME_HEB: "Talkeetna",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TKB",
    AIRPORTNAME: "Tekadu",
    CITYCODE: "TKB",
    CITYNAME: "TEKADU",
    CITYNAME_HEB: "TEKADU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TKC",
    AIRPORTNAME: "Tiko",
    CITYCODE: "TKC",
    CITYNAME: "TIKO",
    CITYNAME_HEB: "TIKO",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "TKD",
    AIRPORTNAME: "Takoradi",
    CITYCODE: "TKD",
    CITYNAME: "TAKORADI",
    CITYNAME_HEB: "TAKORADI",
    COUNTRYCODE: "GH",
    COUNTRYNAME: "GHANA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "TKE",
    AIRPORTNAME: "Tenakee SPB",
    CITYCODE: "TKE",
    CITYNAME: "TENAKEE SPRINGS",
    CITYNAME_HEB: "Tenakee SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TKF",
    AIRPORTNAME: "Truckee",
    CITYCODE: "TKF",
    CITYNAME: "TRUCKEE",
    CITYNAME_HEB: "TRUCKEE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TKG",
    AIRPORTNAME: "Branti",
    CITYCODE: "TKG",
    CITYNAME: "BANDAR LAMPUNG",
    CITYNAME_HEB: "BANDAR LAMPUNG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BMM",
    AIRPORTNAME: "Bitam",
    CITYCODE: "BMM",
    CITYNAME: "BITAM",
    CITYNAME_HEB: "BITAM",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "TKH",
    AIRPORTNAME: "Takhli",
    CITYCODE: "TKH",
    CITYNAME: "TAKHLI",
    CITYNAME_HEB: "TAKHLI",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "TKI",
    AIRPORTNAME: "Tokeen",
    CITYCODE: "TKI",
    CITYNAME: "TOKEEN",
    CITYNAME_HEB: "Tokeen",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TKJ",
    AIRPORTNAME: "Tok",
    CITYCODE: "TKJ",
    CITYNAME: "TOK",
    CITYNAME_HEB: "Tok",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TKK",
    AIRPORTNAME: "Truk",
    CITYCODE: "TKK",
    CITYNAME: "TRUK",
    CITYNAME_HEB: "TRUK",
    COUNTRYCODE: "FM",
    COUNTRYNAME: "MICRONESIA",
    COUNTRYNAME_HEB: "\u05de\u05d9\u05e7\u05e8\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TKL",
    AIRPORTNAME: "Taku SPB",
    CITYCODE: "TKL",
    CITYNAME: "TAKU LODGE",
    CITYNAME_HEB: "Taku SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TKM",
    AIRPORTNAME: "El Peten",
    CITYCODE: "TKM",
    CITYNAME: "TIKAL",
    CITYNAME_HEB: "\u05d8\u05d9\u05e7\u05d0\u05dc",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "TKN",
    AIRPORTNAME: "Tokunoshima",
    CITYCODE: "TKN",
    CITYNAME: "TOKUNOSHIMA",
    CITYNAME_HEB: "TOKUNOSHIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "TKO",
    AIRPORTNAME: "Tlokoeng",
    CITYCODE: "TKO",
    CITYNAME: "TLOKOENG",
    CITYNAME_HEB: "TLOKOENG",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "TKP",
    AIRPORTNAME: "Takapoto",
    CITYCODE: "TKP",
    CITYNAME: "TAKAPOTO",
    CITYNAME_HEB: "TAKAPOTO",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "TKQ",
    AIRPORTNAME: "Kigoma",
    CITYCODE: "TKQ",
    CITYNAME: "KIGOMA",
    CITYNAME_HEB: "KIGOMA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BMO",
    AIRPORTNAME: "Bhamo",
    CITYCODE: "BMO",
    CITYNAME: "BHAMO",
    CITYNAME_HEB: "BHAMO",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "TKR",
    AIRPORTNAME: "Thakurgaon",
    CITYCODE: "TKR",
    CITYNAME: "THAKURGAON",
    CITYNAME_HEB: "THAKURGAON",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "TKS",
    AIRPORTNAME: "Tokushima",
    CITYCODE: "TKS",
    CITYNAME: "TOKUSHIMA",
    CITYNAME_HEB: "TOKUSHIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "TKT",
    AIRPORTNAME: "Tak",
    CITYCODE: "TKT",
    CITYNAME: "TAK",
    CITYNAME_HEB: "TAK",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "TKU",
    AIRPORTNAME: "Turku",
    CITYCODE: "TKU",
    CITYNAME: "TURKU",
    CITYNAME_HEB: "TURKU",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "TKV",
    AIRPORTNAME: "Tatakoto",
    CITYCODE: "TKV",
    CITYNAME: "TATAKOTO",
    CITYNAME_HEB: "TATAKOTO",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "TKW",
    AIRPORTNAME: "Tekin",
    CITYCODE: "TKW",
    CITYNAME: "TEKIN",
    CITYNAME_HEB: "TEKIN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TKX",
    AIRPORTNAME: "Takaroa",
    CITYCODE: "TKX",
    CITYNAME: "TAKAROA",
    CITYNAME_HEB: "TAKAROA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "TKY",
    AIRPORTNAME: " ",
    CITYCODE: "TKY",
    CITYNAME: "TAKAYAMA",
    CITYNAME_HEB: "\u05d8\u05d0\u05e7\u05d9\u05d0\u05de\u05d4",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "TKZ",
    AIRPORTNAME: "Tokoroa",
    CITYCODE: "TKZ",
    CITYNAME: "TOKOROA",
    CITYNAME_HEB: "TOKOROA",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "TLA",
    AIRPORTNAME: "Teller",
    CITYCODE: "TLA",
    CITYNAME: "TELLER",
    CITYNAME_HEB: "Teller",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BMP",
    AIRPORTNAME: "Brampton Island",
    CITYCODE: "BMP",
    CITYNAME: "BRAMPTON ISLAND",
    CITYNAME_HEB: "BRAMPTON ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TLB",
    AIRPORTNAME: "Tarbela",
    CITYCODE: "TLB",
    CITYNAME: "TARBELA",
    CITYNAME_HEB: "TARBELA",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "TLD",
    AIRPORTNAME: " ",
    CITYCODE: "TLD",
    CITYNAME: "TOLEDO",
    CITYNAME_HEB: "\u05d8\u05d5\u05dc\u05d3\u05d5",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "TLE",
    AIRPORTNAME: "Tulear",
    CITYCODE: "TLE",
    CITYNAME: "TULEAR",
    CITYNAME_HEB: "TULEAR",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "TLF",
    AIRPORTNAME: "Telida",
    CITYCODE: "TLF",
    CITYNAME: "TELIDA",
    CITYNAME_HEB: "Telida",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TLG",
    AIRPORTNAME: "Tulagi Island",
    CITYCODE: "TLG",
    CITYNAME: "TULAGI ISLAND",
    CITYNAME_HEB: "TULAGI ISLAND",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "TLH",
    AIRPORTNAME: "Municipal",
    CITYCODE: "TLH",
    CITYNAME: "TALLAHASSEE",
    CITYNAME_HEB: "TALLAHASSEE",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TLI",
    AIRPORTNAME: "Tolitoli",
    CITYCODE: "TLI",
    CITYNAME: "TOLITOLI",
    CITYNAME_HEB: "TOLITOLI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TLJ",
    AIRPORTNAME: "Tatalina AFS",
    CITYCODE: "TLJ",
    CITYNAME: "TATALINA",
    CITYNAME_HEB: "Tatalina AFS",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TLK",
    AIRPORTNAME: "Talknafjordur",
    CITYCODE: "TLK",
    CITYNAME: "TALKNAFJORDUR",
    CITYNAME_HEB: "TALKNAFJORDUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ADT",
    AIRPORTNAME: "Ada",
    CITYCODE: "ADT",
    CITYNAME: "ADA",
    CITYNAME_HEB: "Ada",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BMQ",
    AIRPORTNAME: "Bamburi",
    CITYCODE: "BMQ",
    CITYNAME: "BAMBURI",
    CITYNAME_HEB: "BAMBURI",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TLL",
    AIRPORTNAME: "Ulemiste",
    CITYCODE: "TLL",
    CITYNAME: "TALLINN",
    CITYNAME_HEB: "\u05d8\u05d0\u05dc\u05d9\u05df",
    COUNTRYCODE: "EE",
    COUNTRYNAME: "ESTONIA",
    COUNTRYNAME_HEB: "\u05d0\u05e1\u05d8\u05d5\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TLM",
    AIRPORTNAME: "Zenata",
    CITYCODE: "TLM",
    CITYNAME: "TLEMCEN",
    CITYNAME_HEB: "TLEMCEN",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TLN",
    AIRPORTNAME: "Hyeres",
    CITYCODE: "TLN",
    CITYNAME: "TOULON",
    CITYNAME_HEB: "\u05d8\u05d5\u05dc\u05d5\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "TLO",
    AIRPORTNAME: "Tol",
    CITYCODE: "TLO",
    CITYNAME: "TOL",
    CITYNAME_HEB: "TOL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TLP",
    AIRPORTNAME: "Tumolbil",
    CITYCODE: "TLP",
    CITYNAME: "TUMOLBIL",
    CITYNAME_HEB: "TUMOLBIL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TLR",
    AIRPORTNAME: "Tulare",
    CITYCODE: "TLR",
    CITYNAME: "TULARE",
    CITYNAME_HEB: "TULARE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TLS",
    AIRPORTNAME: "Blagnac",
    CITYCODE: "TLS",
    CITYNAME: "TOULOUSE",
    CITYNAME_HEB: "\u05d8\u05d5\u05dc\u05d5\u05d6",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "TLT",
    AIRPORTNAME: "Tuluksak",
    CITYCODE: "TLT",
    CITYNAME: "TULUKSAK",
    CITYNAME_HEB: "Tuluksak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TLU",
    AIRPORTNAME: "Tolu",
    CITYCODE: "TLU",
    CITYNAME: "TOLU",
    CITYNAME_HEB: "TOLU",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BMR",
    AIRPORTNAME: "Baltrum",
    CITYCODE: "BMR",
    CITYNAME: "BALTRUM",
    CITYNAME_HEB: "BALTRUM",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TLV",
    AIRPORTNAME: '\u05e0\u05ea\u05d1"\u05d2',
    CITYCODE: "TLV",
    CITYNAME: "TEL AVIV",
    CITYNAME_HEB: "\u05ea\u05dc \u05d0\u05d1\u05d9\u05d1",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "TLW",
    AIRPORTNAME: "Talasea",
    CITYCODE: "TLW",
    CITYNAME: "TALASEA",
    CITYNAME_HEB: "TALASEA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TLX",
    AIRPORTNAME: "Talca",
    CITYCODE: "TLX",
    CITYNAME: "TALCA",
    CITYNAME_HEB: "TALCA",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "TLZ",
    AIRPORTNAME: null,
    CITYCODE: "TLZ",
    CITYNAME: "CATALAO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TMA",
    AIRPORTNAME: "Henry Tift Myers",
    CITYCODE: "TMA",
    CITYNAME: "TIFTON",
    CITYNAME_HEB: "TIFTON",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "TMC",
    AIRPORTNAME: "Tambolaka",
    CITYCODE: "TMC",
    CITYNAME: "TAMBOLAKA",
    CITYNAME_HEB: "TAMBOLAKA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TMD",
    AIRPORTNAME: "Timbedra",
    CITYCODE: "TMD",
    CITYNAME: "TIMBEDRA",
    CITYNAME_HEB: "TIMBEDRA",
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TME",
    AIRPORTNAME: "Tame",
    CITYCODE: "TME",
    CITYNAME: "TAME",
    CITYNAME_HEB: "TAME",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TMG",
    AIRPORTNAME: "Tomanggong",
    CITYCODE: "TMG",
    CITYNAME: "TOMANGGONG",
    CITYNAME_HEB: "TOMANGGONG",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BMS",
    AIRPORTNAME: null,
    CITYCODE: "BMS",
    CITYNAME: "BRUMADO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TMH",
    AIRPORTNAME: "Tanahmerah",
    CITYCODE: "TMH",
    CITYNAME: "TANAHMERAH",
    CITYNAME_HEB: "TANAHMERAH",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TMI",
    AIRPORTNAME: "Tumling Tar",
    CITYCODE: "TMI",
    CITYNAME: "TUMLING TAR",
    CITYNAME_HEB: "TUMLING TAR",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "TMJ",
    AIRPORTNAME: "Termez",
    CITYCODE: "TMJ",
    CITYNAME: "TERMEZ UZ",
    CITYNAME_HEB: "TERMEZ UZ",
    COUNTRYCODE: "UZ",
    COUNTRYNAME: "UZBEKISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d6\u05d1\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "TMK",
    AIRPORTNAME: "Tamky",
    CITYCODE: "TMK",
    CITYNAME: "TAMKY",
    CITYNAME_HEB: "TAMKY",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "TML",
    AIRPORTNAME: "Tamale",
    CITYCODE: "TML",
    CITYNAME: "TAMALE",
    CITYNAME_HEB: "TAMALE",
    COUNTRYCODE: "GH",
    COUNTRYNAME: "GHANA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "TMM",
    AIRPORTNAME: "Tamatave",
    CITYCODE: "TMM",
    CITYNAME: "TAMATAVE",
    CITYNAME_HEB: "TAMATAVE",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "TMN",
    AIRPORTNAME: "Tamana Island",
    CITYCODE: "TMN",
    CITYNAME: "TAMANA ISLAND",
    CITYNAME_HEB: "TAMANA ISLAND",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "TMO",
    AIRPORTNAME: "Tumeremo",
    CITYCODE: "TMO",
    CITYNAME: "TUMEREMO",
    CITYNAME_HEB: "TUMEREMO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "TMP",
    AIRPORTNAME: "Tampere-Pirkkala",
    CITYCODE: "TMP",
    CITYNAME: "TAMPERE",
    CITYNAME_HEB: "TAMPERE",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "TMQ",
    AIRPORTNAME: "Tambao",
    CITYCODE: "TMQ",
    CITYNAME: "TAMBAO",
    CITYNAME_HEB: "TAMBAO",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "BMU",
    AIRPORTNAME: "Bima",
    CITYCODE: "BMU",
    CITYNAME: "BIMA",
    CITYNAME_HEB: "BIMA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TMR",
    AIRPORTNAME: "Aguemar",
    CITYCODE: "TMR",
    CITYNAME: "TAMANRASSET",
    CITYNAME_HEB: "TAMANRASSET",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TMS",
    AIRPORTNAME: " ",
    CITYCODE: "TMS",
    CITYNAME: "GERLACHOV",
    CITYNAME_HEB: "\u05d2\u05e8\u05dc\u05e6\u05d5\u05d1",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TMT",
    AIRPORTNAME: null,
    CITYCODE: "TMT",
    CITYNAME: "TROMBETAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TMU",
    AIRPORTNAME: "Tambor",
    CITYCODE: "TMU",
    CITYNAME: "TAMBOR",
    CITYNAME_HEB: "TAMBOR",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "TMW",
    AIRPORTNAME: "Tamworth",
    CITYCODE: "TMW",
    CITYNAME: "TAMWORTH",
    CITYNAME_HEB: "TAMWORTH",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TMX",
    AIRPORTNAME: "Timimoun",
    CITYCODE: "TMX",
    CITYNAME: "TIMIMOUN",
    CITYNAME_HEB: "TIMIMOUN",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TMY",
    AIRPORTNAME: "Tiom",
    CITYCODE: "TMY",
    CITYNAME: "TIOM",
    CITYNAME_HEB: "TIOM",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TMZ",
    AIRPORTNAME: "Thames",
    CITYCODE: "TMZ",
    CITYNAME: "THAMES",
    CITYNAME_HEB: "THAMES",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "TNA",
    AIRPORTNAME: "Jinan",
    CITYCODE: "TNA",
    CITYNAME: "JINAN",
    CITYNAME_HEB: "JINAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "TNB",
    AIRPORTNAME: "Tanah Grogot",
    CITYCODE: "TNB",
    CITYNAME: "TANAH GROGOT",
    CITYNAME_HEB: "TANAH GROGOT",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BMV",
    AIRPORTNAME: "Phung-Duc",
    CITYCODE: "BMV",
    CITYNAME: "BANMETHUOT",
    CITYNAME_HEB: "BANMETHUOT",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "TNC",
    AIRPORTNAME: "Tin City AFS",
    CITYCODE: "TNC",
    CITYNAME: "TIN CITY",
    CITYNAME_HEB: "Tin City AFS",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TND",
    AIRPORTNAME: "Trinidad",
    CITYCODE: "TND",
    CITYNAME: "TRINIDAD",
    CITYNAME_HEB: "\u05d8\u05e8\u05d9\u05e0\u05d9\u05d3\u05d3",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "TNE",
    AIRPORTNAME: "Tanegashima",
    CITYCODE: "TNE",
    CITYNAME: "TANEGASHIMA",
    CITYNAME_HEB: "TANEGASHIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "TNG",
    AIRPORTNAME: "Boukhalef",
    CITYCODE: "TNG",
    CITYNAME: "TANGIER",
    CITYNAME_HEB: "\u05d8\u05e0\u05d2\u05d9\u05e8",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "TNH",
    AIRPORTNAME: "Tonghua Liuhe",
    CITYCODE: "TNH",
    CITYNAME: "TONGHUA",
    CITYNAME_HEB: "TONGHUA",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "TNI",
    AIRPORTNAME: "Satna",
    CITYCODE: "TNI",
    CITYNAME: "SATNA",
    CITYNAME_HEB: "SATNA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "TNJ",
    AIRPORTNAME: "Kidjang",
    CITYCODE: "TNJ",
    CITYNAME: "TANJUNG PINANG",
    CITYNAME_HEB: "TANJUNG PINANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TNK",
    AIRPORTNAME: "Tununak",
    CITYCODE: "TNK",
    CITYNAME: "TUNUNAK",
    CITYNAME_HEB: "Tununak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TNL",
    AIRPORTNAME: "Ternopol",
    CITYCODE: "TNL",
    CITYNAME: "TERNOPOL",
    CITYNAME_HEB: "TERNOPOL",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "TNM",
    AIRPORTNAME: "Teniente R. Marsh",
    CITYCODE: "TNM",
    CITYNAME: "TENIENTE R  MARSH",
    CITYNAME_HEB: "TENIENTE R  MARSH",
    COUNTRYCODE: "AQ",
    COUNTRYNAME: "ANTARTICA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d8\u05e8\u05d8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "BMW",
    AIRPORTNAME: "Bordj Badji Mokhtar",
    CITYCODE: "BMW",
    CITYNAME: "BORDJ BADJI MOKHTAR",
    CITYNAME_HEB: "BORDJ BADJI MOKHTAR",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TNN",
    AIRPORTNAME: "Tainan",
    CITYCODE: "TNN",
    CITYNAME: "TAINAN",
    CITYNAME_HEB: "\u05d8\u05d0\u05d9\u05e0\u05d0\u05df",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "TNO",
    AIRPORTNAME: "Tamarindo",
    CITYCODE: "TNO",
    CITYNAME: "TAMARINDO",
    CITYNAME_HEB: "TAMARINDO",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "TNP",
    AIRPORTNAME: "Twentynine Palms",
    CITYCODE: "TNP",
    CITYNAME: "TWENTYNINE PALMS",
    CITYNAME_HEB: "TWENTYNINE PALMS",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TNQ",
    AIRPORTNAME: "Teraina",
    CITYCODE: "TNQ",
    CITYNAME: "TERAINA",
    CITYNAME_HEB: "TERAINA",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "TNR",
    AIRPORTNAME: "Antananarivo",
    CITYCODE: "TNR",
    CITYNAME: "MADAGASCAR ANTANANARIVO ",
    CITYNAME_HEB:
      "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8 \u05d0\u05e0\u05d8\u05e0\u05e0\u05e8\u05d9\u05d1\u05d5",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "TNS",
    AIRPORTNAME: null,
    CITYCODE: "TNS",
    CITYNAME: "TUNGSTEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TNU",
    AIRPORTNAME: "Municipal",
    CITYCODE: "TNU",
    CITYNAME: "NEWTON",
    CITYNAME_HEB: "NEWTON",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TNV",
    AIRPORTNAME: "Tabuaeran",
    CITYCODE: "TNV",
    CITYNAME: "TABUAERAN",
    CITYNAME_HEB: "TABUAERAN",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "TNX",
    AIRPORTNAME: "Stung Treng",
    CITYCODE: "TNX",
    CITYNAME: "STUNG TRENG",
    CITYNAME_HEB: "STUNG TRENG",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TOA",
    AIRPORTNAME: "Torrance",
    CITYCODE: "TOA",
    CITYNAME: "TORRANCE",
    CITYNAME_HEB: "TORRANCE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BMX",
    AIRPORTNAME: "Big Mountain",
    CITYCODE: "BMX",
    CITYNAME: "BIG MOUNTAIN",
    CITYNAME_HEB: "Big Mountain",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TOB",
    AIRPORTNAME: "Tobruk",
    CITYCODE: "TOB",
    CITYNAME: "TOBRUK",
    CITYNAME_HEB: "TOBRUK",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "TOC",
    AIRPORTNAME: "Toccoa",
    CITYCODE: "TOC",
    CITYNAME: "TOCCOA",
    CITYNAME_HEB: "TOCCOA",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "TOD",
    AIRPORTNAME: "Tioman",
    CITYCODE: "TOD",
    CITYNAME: "TIOMAN",
    CITYNAME_HEB: "TIOMAN",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TOE",
    AIRPORTNAME: "Tozeur",
    CITYCODE: "TOE",
    CITYNAME: "TOZEUR",
    CITYNAME_HEB: "TOZEUR",
    COUNTRYCODE: "TN",
    COUNTRYNAME: "TUNISIA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d9\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TOF",
    AIRPORTNAME: "Tomsk",
    CITYCODE: "TOF",
    CITYNAME: "TOMSK",
    CITYNAME_HEB: "TOMSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TOG",
    AIRPORTNAME: "Togiak Village",
    CITYCODE: "TOG",
    CITYNAME: "TOGIAK VILLAGE",
    CITYNAME_HEB: "Togiak Village",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TOH",
    AIRPORTNAME: "Torres Airstrip",
    CITYCODE: "TOH",
    CITYNAME: "TORRES",
    CITYNAME_HEB: "TORRES",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "TOI",
    AIRPORTNAME: "Troy Municipal",
    CITYCODE: "TOI",
    CITYNAME: "TROY",
    CITYNAME_HEB: "Troy ",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TOK",
    AIRPORTNAME: "Torokina",
    CITYCODE: "TOK",
    CITYNAME: "TOROKINA",
    CITYNAME_HEB: "TOROKINA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TOL",
    AIRPORTNAME: "Express",
    CITYCODE: "TOL",
    CITYNAME: "TOLEDO",
    CITYNAME_HEB: "TOLEDO",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BMY",
    AIRPORTNAME: "Belep Island",
    CITYCODE: "BMY",
    CITYNAME: "BELEP ISLAND",
    CITYNAME_HEB: "BELEP ISLAND",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "TOM",
    AIRPORTNAME: "Tombouctou",
    CITYCODE: "TOM",
    CITYNAME: "TOMBOUCTOU",
    CITYNAME_HEB: "TOMBOUCTOU",
    COUNTRYCODE: "ML",
    COUNTRYNAME: "MALI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d9",
  },
  {
    AIRPORTCODE: "TON",
    AIRPORTNAME: "Tonu",
    CITYCODE: "TON",
    CITYNAME: "TONU",
    CITYNAME_HEB: "TONU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TOO",
    AIRPORTNAME: "San Vito",
    CITYCODE: "TOO",
    CITYNAME: "SAN VITO",
    CITYNAME_HEB: "SAN VITO",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "FOE",
    AIRPORTNAME: null,
    CITYCODE: "TOP",
    CITYNAME: "TOPEKA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TOP",
    AIRPORTNAME: "Philip Billard",
    CITYCODE: "TOP",
    CITYNAME: "TOPEKA",
    CITYNAME_HEB: "\u05d8\u05d5\u05e4\u05e7\u05d4",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TOQ",
    AIRPORTNAME: "Barriles",
    CITYCODE: "TOQ",
    CITYNAME: "TOCOPILLA",
    CITYNAME_HEB: "TOCOPILLA",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "TOR",
    AIRPORTNAME: "Municipal",
    CITYCODE: "TOR",
    CITYNAME: "TORRINGTON",
    CITYNAME_HEB: "TORRINGTON",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TOS",
    AIRPORTNAME: " ",
    CITYCODE: "TOS",
    CITYNAME: "TOSCANA",
    CITYNAME_HEB: "\u05d8\u05d5\u05e1\u05e7\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TOT",
    AIRPORTNAME: "Coronie",
    CITYCODE: "TOT",
    CITYNAME: "TOTNESS",
    CITYNAME_HEB: "TOTNESS",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "BMZ",
    AIRPORTNAME: "Bamu",
    CITYCODE: "BMZ",
    CITYNAME: "BAMU",
    CITYNAME_HEB: "BAMU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TOU",
    AIRPORTNAME: "Touho",
    CITYCODE: "TOU",
    CITYNAME: "TOUHO",
    CITYNAME_HEB: "TOUHO",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "TOV",
    AIRPORTNAME: "West End SPB",
    CITYCODE: "TOV",
    CITYNAME: "TORTOLA",
    CITYNAME_HEB: "TORTOLA",
    COUNTRYCODE: "VG",
    COUNTRYNAME: "VIRGIN ISLANDS-BRITISH",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05ea\u05d5\u05dc\u05d4 \u05d4\u05d1\u05e8\u05d9\u05d8\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "TOW",
    AIRPORTNAME: null,
    CITYCODE: "TOW",
    CITYNAME: "TOLEDO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TOX",
    AIRPORTNAME: "Tobolsk",
    CITYCODE: "TOX",
    CITYNAME: "TOBOLSK",
    CITYNAME_HEB: "TOBOLSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TOY",
    AIRPORTNAME: "Toyama",
    CITYCODE: "TOY",
    CITYNAME: "TOYAMA",
    CITYNAME_HEB: "TOYAMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "TOZ",
    AIRPORTNAME: "Touba",
    CITYCODE: "TOZ",
    CITYNAME: "TOUBA",
    CITYNAME_HEB: "TOUBA",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "TPA",
    AIRPORTNAME: "Tampa International",
    CITYCODE: "TPA",
    CITYNAME: "TAMPA",
    CITYNAME_HEB: "\u05d8\u05de\u05e4\u05d4",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KYO",
    AIRPORTNAME: null,
    CITYCODE: "TPA",
    CITYNAME: "TAMPA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MCF",
    AIRPORTNAME: null,
    CITYCODE: "TPA",
    CITYNAME: "TAMPA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BNA",
    AIRPORTNAME: "Metropolitan",
    CITYCODE: "BNA",
    CITYNAME: "NASHVILLE",
    CITYNAME_HEB: "\u05e0\u05e9\u05d5\u05d5\u05d9\u05dc",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TPF",
    AIRPORTNAME: null,
    CITYCODE: "TPA",
    CITYNAME: "TAMPA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TPC",
    AIRPORTNAME: "Tarapoa",
    CITYCODE: "TPC",
    CITYNAME: "TARAPOA",
    CITYNAME_HEB: "TARAPOA",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "TPE",
    AIRPORTNAME: "Taoyuan International Airport",
    CITYCODE: "TPE",
    CITYNAME: "TAIPEI",
    CITYNAME_HEB: "\u05d8\u05d9\u05d9\u05e4\u05d4",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "TPG",
    AIRPORTNAME: "Taiping",
    CITYCODE: "TPG",
    CITYNAME: "TAIPING",
    CITYNAME_HEB: "TAIPING",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TPH",
    AIRPORTNAME: "Tonopah Airport",
    CITYCODE: "TPH",
    CITYNAME: "TONOPAH",
    CITYNAME_HEB: "TONOPAH",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XSD",
    AIRPORTNAME: null,
    CITYCODE: "TPH",
    CITYNAME: "TONOPAH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TPI",
    AIRPORTNAME: "Tapini",
    CITYCODE: "TPI",
    CITYNAME: "TAPINI",
    CITYNAME_HEB: "TAPINI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TPJ",
    AIRPORTNAME: "Taplejung",
    CITYCODE: "TPJ",
    CITYNAME: "TAPLEJUNG",
    CITYNAME_HEB: "TAPLEJUNG",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "TPK",
    AIRPORTNAME: "Tapaktuan",
    CITYCODE: "TPK",
    CITYNAME: "TAPAKTUAN",
    CITYNAME_HEB: "TAPAKTUAN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ADV",
    AIRPORTNAME: "Andover",
    CITYCODE: "ADV",
    CITYNAME: "ANDOVER",
    CITYNAME_HEB: "ANDOVER",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BNB",
    AIRPORTNAME: "Boende",
    CITYCODE: "BNB",
    CITYNAME: "BOENDE",
    CITYNAME_HEB: "BOENDE",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "TPL",
    AIRPORTNAME: "Draughon-Miller",
    CITYCODE: "TPL",
    CITYNAME: "TEMPLE",
    CITYNAME_HEB: "TEMPLE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TPN",
    AIRPORTNAME: "Tiputini",
    CITYCODE: "TPN",
    CITYNAME: "TIPUTINI",
    CITYNAME_HEB: "TIPUTINI",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "TPO",
    AIRPORTNAME: "Tanalian Point",
    CITYCODE: "TPO",
    CITYNAME: "TANALIAN POINT",
    CITYNAME_HEB: "Tanalian Point",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TPP",
    AIRPORTNAME: "Tarapoto",
    CITYCODE: "TPP",
    CITYNAME: "TARAPOTO",
    CITYNAME_HEB: "TARAPOTO",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "TPQ",
    AIRPORTNAME: "Tepic",
    CITYCODE: "TPQ",
    CITYNAME: "TEPIC",
    CITYNAME_HEB: "TEPIC",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "TPR",
    AIRPORTNAME: "Tom Price",
    CITYCODE: "TPR",
    CITYNAME: "TOM PRICE",
    CITYNAME_HEB: "TOM PRICE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TPS",
    AIRPORTNAME: "Birgi",
    CITYCODE: "TPS",
    CITYNAME: "TRAPANI",
    CITYNAME_HEB: "\u05d8\u05e8\u05d0\u05e4\u05d0\u05e0\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TPT",
    AIRPORTNAME: "Tapeta",
    CITYCODE: "TPT",
    CITYNAME: "TAPETA",
    CITYNAME_HEB: "TAPETA",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TPU",
    AIRPORTNAME: "Tikapur",
    CITYCODE: "TPU",
    CITYNAME: "TIKAPUR",
    CITYNAME_HEB: "TIKAPUR",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "TQN",
    AIRPORTNAME: null,
    CITYCODE: "TQN",
    CITYNAME: "TALUQAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BNC",
    AIRPORTNAME: "Beni",
    CITYCODE: "BNC",
    CITYNAME: "BENI",
    CITYNAME_HEB: "BENI",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "TQR",
    AIRPORTNAME: null,
    CITYCODE: "TQR",
    CITYNAME: "SAN DOMINO (TREMITI ISL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AIT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TQS",
    AIRPORTNAME: "Tres Esquinas",
    CITYCODE: "TQS",
    CITYNAME: "TRES ESQUINAS",
    CITYNAME_HEB: "TRES ESQUINAS",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TRA",
    AIRPORTNAME: "Tarama",
    CITYCODE: "TRA",
    CITYNAME: "TARAMAJIMA",
    CITYNAME_HEB: "TARAMAJIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "TRB",
    AIRPORTNAME: "Gonzalo",
    CITYCODE: "TRB",
    CITYNAME: "TURBO",
    CITYNAME_HEB: "TURBO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TRC",
    AIRPORTNAME: "Torreon",
    CITYCODE: "TRC",
    CITYNAME: "TORREON",
    CITYNAME_HEB: "TORREON",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "TRD",
    AIRPORTNAME: "Vaernes",
    CITYCODE: "TRD",
    CITYNAME: "TRONDHEIM",
    CITYNAME_HEB: "TRONDHEIM",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XZT",
    AIRPORTNAME: null,
    CITYCODE: "TRD",
    CITYNAME: "TRONDHEIM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TRE",
    AIRPORTNAME: "Tiree",
    CITYCODE: "TRE",
    CITYNAME: "TIREE",
    CITYNAME_HEB: "TIREE",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TRG",
    AIRPORTNAME: "Tauranga",
    CITYCODE: "TRG",
    CITYNAME: "TAURANGA",
    CITYNAME_HEB: "TAURANGA",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "MVP",
    AIRPORTNAME: "Mitu",
    CITYCODE: "MVP",
    CITYNAME: "MITU",
    CITYNAME_HEB: "MITU",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MVQ",
    AIRPORTNAME: "Mogilev",
    CITYCODE: "MVQ",
    CITYNAME: "MOGILEV",
    CITYNAME_HEB: "MOGILEV",
    COUNTRYCODE: "BY",
    COUNTRYNAME: "BELARUS",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d0\u05e8\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "MVR",
    AIRPORTNAME: "Salam",
    CITYCODE: "MVR",
    CITYNAME: "MAROUA",
    CITYNAME_HEB: "MAROUA",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MVS",
    AIRPORTNAME: null,
    CITYCODE: "MVS",
    CITYNAME: "MUCURI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MVT",
    AIRPORTNAME: "Mataiva",
    CITYCODE: "MVT",
    CITYNAME: "MATAIVA",
    CITYNAME_HEB: "MATAIVA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "MVU",
    AIRPORTNAME: "Musgrave",
    CITYCODE: "MVU",
    CITYNAME: "MUSGRAVE",
    CITYNAME_HEB: "MUSGRAVE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MVV",
    AIRPORTNAME: "Megeve",
    CITYCODE: "MVV",
    CITYNAME: "MEGEVE",
    CITYNAME_HEB: "MEGEVE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "AZO",
    AIRPORTNAME: "Battle Creek International",
    CITYCODE: "AZO",
    CITYNAME: "KALAMAZOO",
    CITYNAME_HEB: "KALAMAZOO",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MVW",
    AIRPORTNAME: "Skagit Regional",
    CITYCODE: "MVW",
    CITYNAME: "MOUNT VERNON",
    CITYNAME_HEB: "MOUNT VERNON",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MVX",
    AIRPORTNAME: "Minvoul",
    CITYCODE: "MVX",
    CITYNAME: "MINVOUL",
    CITYNAME_HEB: "MINVOUL",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MVY",
    AIRPORTNAME: "Martha's Vineyard",
    CITYCODE: "MVY",
    CITYNAME: "MARTHAS VINEYARD",
    CITYNAME_HEB: "MARTHAS VINEYARD",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MVZ",
    AIRPORTNAME: "Masvingo",
    CITYCODE: "MVZ",
    CITYNAME: "MASVINGO",
    CITYNAME_HEB: "MASVINGO",
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: "\u05d6\u05d9\u05de\u05d1\u05d1\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MWA",
    AIRPORTNAME: "Williamson County",
    CITYCODE: "MWA",
    CITYNAME: "MARION",
    CITYNAME_HEB: "MARION",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MWB",
    AIRPORTNAME: "Morawa",
    CITYCODE: "MWB",
    CITYNAME: "MORAWA",
    CITYNAME_HEB: "MORAWA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MWD",
    AIRPORTNAME: "Mianwali",
    CITYCODE: "MWD",
    CITYNAME: "MIANWALI",
    CITYNAME_HEB: "MIANWALI",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "MWE",
    AIRPORTNAME: "Merowe",
    CITYCODE: "MWE",
    CITYNAME: "MEROWE",
    CITYNAME_HEB: "MEROWE",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "HIA",
    AIRPORTNAME: " ",
    CITYCODE: "HIA",
    CITYNAME: "HISSARYA",
    CITYNAME_HEB: "\u05d4\u05d9\u05e1\u05e8\u05d9\u05d4",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MWF",
    AIRPORTNAME: "Maewo",
    CITYCODE: "MWF",
    CITYNAME: "MAEWO",
    CITYNAME_HEB: "MAEWO",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "MWG",
    AIRPORTNAME: "Marawaka",
    CITYCODE: "MWG",
    CITYNAME: "MARAWAKA",
    CITYNAME_HEB: "MARAWAKA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "AZR",
    AIRPORTNAME: "Adrar",
    CITYCODE: "AZR",
    CITYNAME: "ADRAR",
    CITYNAME_HEB: "ADRAR",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LRN",
    AIRPORTNAME: "Larson AFB",
    CITYCODE: "MWH",
    CITYNAME: "MOSES LAKE",
    CITYNAME_HEB: "MOSES LAKE",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MWH",
    AIRPORTNAME: "Grant County",
    CITYCODE: "MWH",
    CITYNAME: "MOSES LAKE",
    CITYNAME_HEB: "MOSES LAKE",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MWI",
    AIRPORTNAME: "Maramuni",
    CITYCODE: "MWI",
    CITYNAME: "MARAMUNI",
    CITYNAME_HEB: "MARAMUNI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MWJ",
    AIRPORTNAME: "Matthews Ridge",
    CITYCODE: "MWJ",
    CITYNAME: "MATTHEWS RIDGE",
    CITYNAME_HEB: "MATTHEWS RIDGE",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "MWK",
    AIRPORTNAME: "Matak",
    CITYCODE: "MWK",
    CITYNAME: "MATAK",
    CITYNAME_HEB: "MATAK",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CWO",
    AIRPORTNAME: null,
    CITYCODE: "MWL",
    CITYNAME: "MINERAL WELLS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MWL",
    AIRPORTNAME: "Mineral Wells Airport",
    CITYCODE: "MWL",
    CITYNAME: "MINERAL WELLS",
    CITYNAME_HEB: "MINERAL WELLS",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MWM",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MWM",
    CITYNAME: "WINDOM",
    CITYNAME_HEB: "WINDOM",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MWN",
    AIRPORTNAME: "Mwadui",
    CITYCODE: "MWN",
    CITYNAME: "MWADUI",
    CITYNAME_HEB: "MWADUI",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MWO",
    AIRPORTNAME: "Hook Field",
    CITYCODE: "MWO",
    CITYNAME: "MIDDLETOWN",
    CITYNAME_HEB: "MIDDLETOWN",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AZT",
    AIRPORTNAME: "Zapatoca",
    CITYCODE: "AZT",
    CITYNAME: "ZAPATOCA",
    CITYNAME_HEB: "ZAPATOCA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MWP",
    AIRPORTNAME: "Mountain",
    CITYCODE: "MWP",
    CITYNAME: "MOUNTAIN",
    CITYNAME_HEB: "MOUNTAIN",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "MWQ",
    AIRPORTNAME: "Magwe",
    CITYCODE: "MWQ",
    CITYNAME: "MAGWE",
    CITYNAME_HEB: "MAGWE",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MWR",
    AIRPORTNAME: "Motswari Airfield",
    CITYCODE: "MWR",
    CITYNAME: "MOTSWARI",
    CITYNAME_HEB: "MOTSWARI",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "MWS",
    AIRPORTNAME: "Mount Wilson",
    CITYCODE: "MWS",
    CITYNAME: "MOUNT WILSON",
    CITYNAME_HEB: "MOUNT WILSON",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MWT",
    AIRPORTNAME: "Moolawatana",
    CITYCODE: "MWT",
    CITYNAME: "MOOLAWATANA",
    CITYNAME_HEB: "MOOLAWATANA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MWU",
    AIRPORTNAME: "Mussau",
    CITYCODE: "MWU",
    CITYNAME: "MUSSAU",
    CITYNAME_HEB: "MUSSAU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MWV",
    AIRPORTNAME: "Mundulkiri",
    CITYCODE: "MWV",
    CITYNAME: "MUNDULKIRI",
    CITYNAME_HEB: "MUNDULKIRI",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MWY",
    AIRPORTNAME: "Miranda Downs",
    CITYCODE: "MWY",
    CITYNAME: "MIRANDA DOWNS",
    CITYNAME_HEB: "MIRANDA DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MWZ",
    AIRPORTNAME: "Mwanza",
    CITYCODE: "MWZ",
    CITYNAME: "MWANZA",
    CITYNAME_HEB: "MWANZA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ACI",
    AIRPORTNAME: "The Blaye",
    CITYCODE: "ACI",
    CITYNAME: "ALDERNEY",
    CITYNAME_HEB: "ALDERNEY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AZZ",
    AIRPORTNAME: "Ambriz",
    CITYCODE: "AZZ",
    CITYNAME: "AMBRIZ",
    CITYNAME_HEB: "AMBRIZ",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "MXB",
    AIRPORTNAME: "Masamba",
    CITYCODE: "MXB",
    CITYNAME: "MASAMBA",
    CITYNAME_HEB: "MASAMBA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MXC",
    AIRPORTNAME: "San Juan County",
    CITYCODE: "MXC",
    CITYNAME: "MONTICELLO",
    CITYNAME_HEB: "MONTICELLO",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MXD",
    AIRPORTNAME: "Marion Downs",
    CITYCODE: "MXD",
    CITYNAME: "MARION DOWNS",
    CITYNAME_HEB: "MARION DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MXE",
    AIRPORTNAME: " ",
    CITYCODE: "MXE",
    CITYNAME: "MEXICO",
    CITYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "MXG",
    AIRPORTNAME: "Marlborough",
    CITYCODE: "MXG",
    CITYNAME: "MARLBOROUGH",
    CITYNAME_HEB: "MARLBOROUGH",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "MXH",
    AIRPORTNAME: "Moro",
    CITYCODE: "MXH",
    CITYNAME: "MORO",
    CITYNAME_HEB: "MORO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MXI",
    AIRPORTNAME: "Mati",
    CITYCODE: "MXI",
    CITYNAME: "MATI",
    CITYNAME_HEB: "MATI",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "MXJ",
    AIRPORTNAME: "Minna",
    CITYCODE: "MXJ",
    CITYNAME: "MINNA",
    CITYNAME_HEB: "MINNA",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MXK",
    AIRPORTNAME: "Mindik",
    CITYCODE: "MXK",
    CITYNAME: "MINDIK",
    CITYNAME_HEB: "MINDIK",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MXL",
    AIRPORTNAME: "Mexicali",
    CITYCODE: "MXL",
    CITYNAME: "MEXICALI",
    CITYNAME_HEB: "MEXICALI",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "BAA",
    AIRPORTNAME: "Bialla",
    CITYCODE: "BAA",
    CITYNAME: "BIALLA",
    CITYNAME_HEB: "BIALLA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MXM",
    AIRPORTNAME: "Morombe",
    CITYCODE: "MXM",
    CITYNAME: "MOROMBE",
    CITYNAME_HEB: "MOROMBE",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "MXN",
    AIRPORTNAME: "Morlaix",
    CITYCODE: "MXN",
    CITYNAME: "MORLAIX",
    CITYNAME_HEB: "MORLAIX",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "MXO",
    AIRPORTNAME: "Municipal",
    CITYCODE: "MXO",
    CITYNAME: "MONTICELLO",
    CITYNAME_HEB: "MONTICELLO",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MXQ",
    AIRPORTNAME: "Mitchell River",
    CITYCODE: "MXQ",
    CITYNAME: "MITCHELL RIVER",
    CITYNAME_HEB: "MITCHELL RIVER",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MXR",
    AIRPORTNAME: "Mirgorod",
    CITYCODE: "MXR",
    CITYNAME: "MIRGOROD",
    CITYNAME_HEB: "MIRGOROD",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "MXS",
    AIRPORTNAME: "Maota Savaii Is",
    CITYCODE: "MXS",
    CITYNAME: "MAOTA SAVAII IS",
    CITYNAME_HEB: "MAOTA SAVAII IS",
    COUNTRYCODE: "WS",
    COUNTRYNAME: "SAMOA-INDEPENDENT STATE",
    COUNTRYNAME_HEB:
      "\u05e1\u05de\u05d5\u05d0\u05d4 \u05d4\u05de\u05e2\u05e8\u05d1\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "MXT",
    AIRPORTNAME: "Maintirano",
    CITYCODE: "MXT",
    CITYNAME: "MAINTIRANO",
    CITYNAME_HEB: "MAINTIRANO",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "MXU",
    AIRPORTNAME: "Mullewa",
    CITYCODE: "MXU",
    CITYNAME: "MULLEWA",
    CITYNAME_HEB: "MULLEWA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MXV",
    AIRPORTNAME: "Moron",
    CITYCODE: "MXV",
    CITYNAME: "MORON",
    CITYNAME_HEB: "MORON",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MXW",
    AIRPORTNAME: "Mandalgobi",
    CITYCODE: "MXW",
    CITYNAME: "MANDALGOBI",
    CITYNAME_HEB: "MANDALGOBI",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BAC",
    AIRPORTNAME: "Barranca De Upia",
    CITYCODE: "BAC",
    CITYNAME: "BARRANCA DE UPIA",
    CITYNAME_HEB: "BARRANCA DE UPIA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MXX",
    AIRPORTNAME: "Mora",
    CITYCODE: "MXX",
    CITYNAME: "MORA",
    CITYNAME_HEB: "MORA",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MXY",
    AIRPORTNAME: "Mccarthy",
    CITYCODE: "MXY",
    CITYNAME: "MCCARTHY",
    CITYNAME_HEB: "Mccarthy",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MXZ",
    AIRPORTNAME: "Meixian",
    CITYCODE: "MXZ",
    CITYNAME: "MEIXIAN",
    CITYNAME_HEB: "MEIXIAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "MYA",
    AIRPORTNAME: "Moruya",
    CITYCODE: "MYA",
    CITYNAME: "MORUYA",
    CITYNAME_HEB: "MORUYA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MYB",
    AIRPORTNAME: "Mayoumba",
    CITYCODE: "MYB",
    CITYNAME: "MAYOUMBA",
    CITYNAME_HEB: "MAYOUMBA",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MYC",
    AIRPORTNAME: "Maracay",
    CITYCODE: "MYC",
    CITYNAME: "MARACAY",
    CITYNAME_HEB: "MARACAY",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "MYD",
    AIRPORTNAME: "Malindi",
    CITYCODE: "MYD",
    CITYNAME: "MALINDI",
    CITYNAME_HEB: "MALINDI",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MYE",
    AIRPORTNAME: "Miyake Jima",
    CITYCODE: "MYE",
    CITYNAME: "MIYAKE JIMA",
    CITYNAME_HEB: "MIYAKE JIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "MYG",
    AIRPORTNAME: "Mayaguana",
    CITYCODE: "MYG",
    CITYNAME: "MAYAGUANA",
    CITYNAME_HEB: "MAYAGUANA",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MYH",
    AIRPORTNAME: "Marble Canyon",
    CITYCODE: "MYH",
    CITYNAME: "MARBLE CANYON",
    CITYNAME_HEB: "MARBLE CANYON",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BAE",
    AIRPORTNAME: "Barcelonnette",
    CITYCODE: "BAE",
    CITYNAME: "BARCELONNETTE",
    CITYNAME_HEB: "BARCELONNETTE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "MYI",
    AIRPORTNAME: "Murray Island",
    CITYCODE: "MYI",
    CITYNAME: "MURRAY ISLAND",
    CITYNAME_HEB: "MURRAY ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MYJ",
    AIRPORTNAME: "Matsuyama",
    CITYCODE: "MYJ",
    CITYNAME: "MATSUYAMA",
    CITYNAME_HEB: "\u05de\u05d0\u05d8\u05e1\u05d5\u05d9\u05d0\u05de\u05d4",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "MYK",
    AIRPORTNAME: "May Creek",
    CITYCODE: "MYK",
    CITYNAME: "MAY CREEK",
    CITYNAME_HEB: "May Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MYL",
    AIRPORTNAME: "Mccall",
    CITYCODE: "MYL",
    CITYNAME: "MCCALL",
    CITYNAME_HEB: "MCCALL",
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d9\u05d3\u05d4\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MYM",
    AIRPORTNAME: "Monkey Mountain",
    CITYCODE: "MYM",
    CITYNAME: "MONKEY MOUNTAIN",
    CITYNAME_HEB: "MONKEY MOUNTAIN",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "MYN",
    AIRPORTNAME: "Mareb",
    CITYCODE: "MYN",
    CITYNAME: "MAREB",
    CITYNAME_HEB: "MAREB",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "MYO",
    AIRPORTNAME: "Myroodah",
    CITYCODE: "MYO",
    CITYNAME: "MYROODAH",
    CITYNAME_HEB: "MYROODAH",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MYP",
    AIRPORTNAME: "Mary",
    CITYCODE: "MYP",
    CITYNAME: "MARY",
    CITYNAME_HEB: "MARY",
    COUNTRYCODE: "TM",
    COUNTRYNAME: "TURKMENISTAN",
    COUNTRYNAME_HEB:
      "\u05d8\u05d5\u05e8\u05e7\u05d9\u05de\u05d9\u05e0\u05d9\u05e1\u05d8\u05d0\u05df",
  },
  {
    AIRPORTCODE: "MYQ",
    AIRPORTNAME: "Mysore",
    CITYCODE: "MYQ",
    CITYNAME: "MYSORE",
    CITYNAME_HEB: "MYSORE",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "MYR",
    AIRPORTNAME: null,
    CITYCODE: "MYR",
    CITYNAME: "MYRTLE BEACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BAF",
    AIRPORTNAME: "Barnes",
    CITYCODE: "BAF",
    CITYNAME: "WESTFIELD",
    CITYNAME_HEB: "WESTFIELD",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "KIL",
    AIRPORTNAME: "Kilwa",
    CITYCODE: "KIL",
    CITYNAME: "KILWA",
    CITYNAME_HEB: "KILWA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KIM",
    AIRPORTNAME: "Kimberley",
    CITYCODE: "KIM",
    CITYNAME: "KIMBERLEY",
    CITYNAME_HEB: "KIMBERLEY",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "KIN",
    AIRPORTNAME: "Norman Manley",
    CITYCODE: "KIN",
    CITYNAME: "KINGSTON",
    CITYNAME_HEB: "KINGSTON",
    COUNTRYCODE: "JM",
    COUNTRYNAME: "JAMAICA",
    COUNTRYNAME_HEB: "\u05d2'\u05de\u05d9\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "KTP",
    AIRPORTNAME: "Tinson",
    CITYCODE: "KIN",
    CITYNAME: "KINGSTON",
    CITYNAME_HEB: "\u05e7\u05d9\u05e0\u05d2\u05e1\u05d8\u05d5\u05df",
    COUNTRYCODE: "JM",
    COUNTRYNAME: "JAMAICA",
    COUNTRYNAME_HEB: "\u05d2'\u05de\u05d9\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "ASI",
    AIRPORTNAME: null,
    CITYCODE: "ASI",
    CITYNAME: "GEORGETOWN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SH",
    COUNTRYNAME: "ST. HELENA ISLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KIO",
    AIRPORTNAME: "Kili Island",
    CITYCODE: "KIO",
    CITYNAME: "KILI ISLAND",
    CITYNAME_HEB: "KILI ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "KIQ",
    AIRPORTNAME: "Kira",
    CITYCODE: "KIQ",
    CITYNAME: "KIRA",
    CITYNAME_HEB: "KIRA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KIR",
    AIRPORTNAME: " ",
    CITYCODE: "KIR",
    CITYNAME: "KIRCHBERG",
    CITYNAME_HEB: "\u05e7\u05d9\u05e8\u05e9\u05d1\u05e8\u05d2",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KIS",
    AIRPORTNAME: "Kisumu",
    CITYCODE: "KIS",
    CITYNAME: "KISUMU",
    CITYNAME_HEB: "KISUMU",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KIT",
    AIRPORTNAME: "Kithira",
    CITYCODE: "KIT",
    CITYNAME: "KITHIRA",
    CITYNAME_HEB: "KITHIRA",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KIU",
    AIRPORTNAME: "Kiunga",
    CITYCODE: "KIU",
    CITYNAME: "KIUNGA",
    CITYNAME_HEB: "KIUNGA",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KIV",
    AIRPORTNAME: "Chisinau",
    CITYCODE: "KIV",
    CITYNAME: "CHISINAU",
    CITYNAME_HEB: "\u05e7\u05d9\u05e9\u05d9\u05e0\u05d1",
    COUNTRYCODE: "MD",
    COUNTRYNAME: "MOLDOVA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05dc\u05d3\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "KIW",
    AIRPORTNAME: "Southdowns",
    CITYCODE: "KIW",
    CITYNAME: "KITWE",
    CITYNAME_HEB: "KITWE",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KIY",
    AIRPORTNAME: "Kilwa",
    CITYCODE: "KIY",
    CITYNAME: "KILWA",
    CITYNAME_HEB: "KILWA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ASJ",
    AIRPORTNAME: "Amami-Oshima Airport",
    CITYCODE: "ASJ",
    CITYNAME: "AMAMI O SHIMA",
    CITYNAME_HEB: "AMAMI O SHIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "KIZ",
    AIRPORTNAME: "Kikinonda",
    CITYCODE: "KIZ",
    CITYNAME: "KIKINONDA",
    CITYNAME_HEB: "KIKINONDA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KJA",
    AIRPORTNAME: "Krasnojarsk",
    CITYCODE: "KJA",
    CITYNAME: "KRASNOJARSK",
    CITYNAME_HEB: "KRASNOJARSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KJE",
    AIRPORTNAME: null,
    CITYCODE: "KJE",
    CITYNAME: "KJELLER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KJK",
    AIRPORTNAME: "Kortrijk",
    CITYCODE: "KJK",
    CITYNAME: "KORTRIJK",
    CITYNAME_HEB: "\u05e7\u05d5\u05e8\u05d8\u05e8\u05d9\u05d2\u05e7",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KJP",
    AIRPORTNAME: "Kerama",
    CITYCODE: "KJP",
    CITYNAME: "KERAMA",
    CITYNAME_HEB: "KERAMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "KJU",
    AIRPORTNAME: "Kamiraba",
    CITYCODE: "KJU",
    CITYNAME: "KAMIRABA",
    CITYNAME_HEB: "KAMIRABA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KKA",
    AIRPORTNAME: "Koyuk",
    CITYCODE: "KKA",
    CITYNAME: "KOYUK",
    CITYNAME_HEB: "Koyuk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KKB",
    AIRPORTNAME: "Kitoi Bay",
    CITYCODE: "KKB",
    CITYNAME: "KITOI BAY",
    CITYNAME_HEB: "Kitoi Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KKC",
    AIRPORTNAME: "Khon Kaen",
    CITYCODE: "KKC",
    CITYNAME: "KHON KAEN",
    CITYNAME_HEB: "KHON KAEN",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KKD",
    AIRPORTNAME: "Kokoda",
    CITYCODE: "KKD",
    CITYNAME: "KOKODA",
    CITYNAME_HEB: "KOKODA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ASK",
    AIRPORTNAME: "Yamoussoukro",
    CITYCODE: "ASK",
    CITYNAME: "YAMOUSSOUKRO",
    CITYNAME_HEB: "YAMOUSSOUKRO",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "KKE",
    AIRPORTNAME: "Kerikeri",
    CITYCODE: "KKE",
    CITYNAME: "KERIKERI",
    CITYNAME_HEB: "KERIKERI",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KKF",
    AIRPORTNAME: "Kagvik Creek",
    CITYCODE: "KKF",
    CITYNAME: "KAGVIK CREEK",
    CITYNAME_HEB: "Kagvik Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KKG",
    AIRPORTNAME: "Konawaruk",
    CITYCODE: "KKG",
    CITYNAME: "KONAWARUK",
    CITYNAME_HEB: "KONAWARUK",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KKH",
    AIRPORTNAME: "Kongiganak",
    CITYCODE: "KKH",
    CITYNAME: "KONGIGANAK",
    CITYNAME_HEB: "Kongiganak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KKI",
    AIRPORTNAME: "Akiachak",
    CITYCODE: "KKI",
    CITYNAME: "AKIACHAK",
    CITYNAME_HEB: "Akiachak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KKJ",
    AIRPORTNAME: "Kokura",
    CITYCODE: "KKJ",
    CITYNAME: "KITA KYUSHU",
    CITYNAME_HEB: "KITA KYUSHU",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "KKK",
    AIRPORTNAME: "Kalakaket AFS",
    CITYCODE: "KKK",
    CITYNAME: "KALAKAKET",
    CITYNAME_HEB: "Kalakaket AFS",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KKL",
    AIRPORTNAME: "Karluk Lake SPB",
    CITYCODE: "KKL",
    CITYNAME: "KARLUK LAKE",
    CITYNAME_HEB: "Karluk Lake SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KKM",
    AIRPORTNAME: "Lop Buri",
    CITYCODE: "KKM",
    CITYNAME: "LOP BURI",
    CITYNAME_HEB: "LOP BURI",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KKN",
    AIRPORTNAME: "Hoeybuktmoen",
    CITYCODE: "KKN",
    CITYNAME: "KIRKENES",
    CITYNAME_HEB: "KIRKENES",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ASL",
    AIRPORTNAME: "Harrison County",
    CITYCODE: "ASL",
    CITYNAME: "MARSHALL",
    CITYNAME_HEB: "MARSHALL",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KKO",
    AIRPORTNAME: "Kaikohe",
    CITYCODE: "KKO",
    CITYNAME: "KAIKOHE",
    CITYNAME_HEB: "KAIKOHE",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KKP",
    AIRPORTNAME: "Koolburra",
    CITYCODE: "KKP",
    CITYNAME: "KOOLBURRA",
    CITYNAME_HEB: "KOOLBURRA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KKR",
    AIRPORTNAME: "Kaukura Atoll",
    CITYCODE: "KKR",
    CITYNAME: "KAUKURA ATOLL",
    CITYNAME_HEB: "KAUKURA ATOLL",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "KKT",
    AIRPORTNAME: "Kentland",
    CITYCODE: "KKT",
    CITYNAME: "KENTLAND",
    CITYNAME_HEB: "KENTLAND",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KKU",
    AIRPORTNAME: "Ekuk",
    CITYCODE: "KKU",
    CITYNAME: "EKUK",
    CITYNAME_HEB: "Ekuk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KKW",
    AIRPORTNAME: "Kikwit",
    CITYCODE: "KKW",
    CITYNAME: "KIKWIT",
    CITYNAME_HEB: "KIKWIT",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KKX",
    AIRPORTNAME: "Kikaiga Shima",
    CITYCODE: "KKX",
    CITYNAME: "KIKAIGA SHIMA",
    CITYNAME_HEB: "KIKAIGA SHIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "KKY",
    AIRPORTNAME: "Kilkenny",
    CITYCODE: "KKY",
    CITYNAME: "KILKENNY",
    CITYNAME_HEB: "KILKENNY",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KKZ",
    AIRPORTNAME: "Koh Kong",
    CITYCODE: "KKZ",
    CITYNAME: "KOH KONG",
    CITYNAME_HEB: "KOH KONG",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KLA",
    AIRPORTNAME: null,
    CITYCODE: "KLA",
    CITYNAME: "KAMPALA",
    CITYNAME_HEB: "\u05e7\u05de\u05e4\u05dc\u05d4",
    COUNTRYCODE: "UG",
    COUNTRYNAME: "UGANDA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ABU",
    AIRPORTNAME: "Atambua",
    CITYCODE: "ABU",
    CITYNAME: "ATAMBUA",
    CITYNAME_HEB: "ATAMBUA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ASM",
    AIRPORTNAME: "Asmara International Airport",
    CITYCODE: "ASM",
    CITYNAME: "ASMARA",
    CITYNAME_HEB: "\u05d0\u05e1\u05de\u05e8\u05d4",
    COUNTRYCODE: "ER",
    COUNTRYNAME: "ERITREA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05d9\u05ea\u05e8\u05d9\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KLB",
    AIRPORTNAME: "Kalabo",
    CITYCODE: "KLB",
    CITYNAME: "KALABO",
    CITYNAME_HEB: "KALABO",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KLC",
    AIRPORTNAME: "Kaolack",
    CITYCODE: "KLC",
    CITYNAME: "KAOLACK",
    CITYNAME_HEB: "KAOLACK",
    COUNTRYCODE: "SN",
    COUNTRYNAME: "SENEGAL",
    COUNTRYNAME_HEB: "\u05e1\u05e0\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "KLD",
    AIRPORTNAME: "Migalovo",
    CITYCODE: "KLD",
    CITYNAME: "KALININ",
    CITYNAME_HEB: "KALININ",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KLE",
    AIRPORTNAME: "Kaele",
    CITYCODE: "KLE",
    CITYNAME: "KAELE",
    CITYNAME_HEB: "KAELE",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KLF",
    AIRPORTNAME: "Kaluga",
    CITYCODE: "KLF",
    CITYNAME: "KALUGA",
    CITYNAME_HEB: "KALUGA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KLG",
    AIRPORTNAME: "Kalskag",
    CITYCODE: "KLG",
    CITYNAME: "KALSKAG",
    CITYNAME_HEB: "Kalskag",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KLH",
    AIRPORTNAME: "Kolhapur",
    CITYCODE: "KLH",
    CITYNAME: "KOLHAPUR",
    CITYNAME_HEB: "KOLHAPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "KLI",
    AIRPORTNAME: "Kota Koli",
    CITYCODE: "KLI",
    CITYNAME: "KOTA KOLI",
    CITYNAME_HEB: "KOTA KOLI",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KLJ",
    AIRPORTNAME: "Klaipeda",
    CITYCODE: "KLJ",
    CITYNAME: "KLAIPEDA",
    CITYNAME_HEB: "KLAIPEDA",
    COUNTRYCODE: "LT",
    COUNTRYNAME: "LITHUANIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d8\u05d0",
  },
  {
    AIRPORTCODE: "KLK",
    AIRPORTNAME: "Kalokol",
    CITYCODE: "KLK",
    CITYNAME: "KALOKOL",
    CITYNAME_HEB: "KALOKOL",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ASN",
    AIRPORTNAME: "Talladega",
    CITYCODE: "ASN",
    CITYNAME: "TALLADEGA",
    CITYNAME_HEB: "Talladega",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KLL",
    AIRPORTNAME: "Levelock",
    CITYCODE: "KLL",
    CITYNAME: "LEVELOCK",
    CITYNAME_HEB: "Levelock",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "KLN",
    CITYNAME: "KLEINARL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KLO",
    AIRPORTNAME: "Kalibo",
    CITYCODE: "KLO",
    CITYNAME: "KALIBO",
    CITYNAME_HEB: "KALIBO",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "KLP",
    AIRPORTNAME: "Kelp Bay",
    CITYCODE: "KLP",
    CITYNAME: "KELP BAY",
    CITYNAME_HEB: "Kelp Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KLQ",
    AIRPORTNAME: "Keluang",
    CITYCODE: "KLQ",
    CITYNAME: "KELUANG",
    CITYNAME_HEB: "KELUANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KLR",
    AIRPORTNAME: "Kalmar",
    CITYCODE: "KLR",
    CITYNAME: "KALMAR",
    CITYNAME_HEB: "KALMAR",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KLS",
    AIRPORTNAME: "Longview",
    CITYCODE: "KLS",
    CITYNAME: "KELSO",
    CITYNAME_HEB: "KELSO",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KLT",
    AIRPORTNAME: "Kaiserslautern",
    CITYCODE: "KLT",
    CITYNAME: "KAISERSLAUTER",
    CITYNAME_HEB: "KAISERSLAUTER",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KLU",
    AIRPORTNAME: "Klagenfurt",
    CITYCODE: "KLU",
    CITYNAME: "KLAGENFURT",
    CITYNAME_HEB: "\u05e7\u05dc\u05d2\u05e0\u05e4\u05d5\u05e8\u05d8",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KLV",
    AIRPORTNAME: "Karlovy Vary",
    CITYCODE: "KLV",
    CITYNAME: "KARLOVY VARY",
    CITYNAME_HEB:
      "\u05e7\u05e8\u05dc\u05d5\u05d1\u05d9 \u05d5\u05d0\u05e8\u05d9",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ASO",
    AIRPORTNAME: "Asosa",
    CITYCODE: "ASO",
    CITYNAME: "ASOSA",
    CITYNAME_HEB: "ASOSA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KLW",
    AIRPORTNAME: "Klawock",
    CITYCODE: "KLW",
    CITYNAME: "KLAWOCK",
    CITYNAME_HEB: "Klawock",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KLX",
    AIRPORTNAME: "Kalamata",
    CITYCODE: "KLX",
    CITYNAME: "KALAMATA",
    CITYNAME_HEB: "\u05e7\u05dc\u05de\u05d8\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KLY",
    AIRPORTNAME: "Kalima",
    CITYCODE: "KLY",
    CITYNAME: "KALIMA",
    CITYNAME_HEB: "KALIMA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KLZ",
    AIRPORTNAME: "Kleinzee",
    CITYCODE: "KLZ",
    CITYNAME: "KLEINZEE",
    CITYNAME_HEB: "KLEINZEE",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "KMA",
    AIRPORTNAME: "Kerema",
    CITYCODE: "KMA",
    CITYNAME: "KEREMA",
    CITYNAME_HEB: "KEREMA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KMB",
    AIRPORTNAME: "Koinambe",
    CITYCODE: "KMB",
    CITYNAME: "KOINAMBE",
    CITYNAME_HEB: "KOINAMBE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KMD",
    AIRPORTNAME: "Mandji",
    CITYCODE: "KMD",
    CITYNAME: "MANDJI",
    CITYNAME_HEB: "MANDJI",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KME",
    AIRPORTNAME: "Kamembe",
    CITYCODE: "KME",
    CITYNAME: "KAMEMBE",
    CITYNAME_HEB: "KAMEMBE",
    COUNTRYCODE: "RW",
    COUNTRYNAME: "RWANDA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05d0\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "KMF",
    AIRPORTNAME: "Kamina",
    CITYCODE: "KMF",
    CITYNAME: "KAMINA",
    CITYNAME_HEB: "KAMINA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KMG",
    AIRPORTNAME: "Kunming",
    CITYCODE: "KMG",
    CITYNAME: "KUNMING",
    CITYNAME_HEB: "\u05e7\u05d5\u05e0\u05de\u05d9\u05e0\u05d2",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ASP",
    AIRPORTNAME: "Alice Springs",
    CITYCODE: "ASP",
    CITYNAME: "ALICE SPRINGS",
    CITYNAME_HEB:
      "\u05d0\u05dc\u05d9\u05e1 \u05e1\u05e4\u05e8\u05d9\u05e0\u05d2",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KMH",
    AIRPORTNAME: "Kuruman",
    CITYCODE: "KMH",
    CITYNAME: "KURUMAN",
    CITYNAME_HEB: "KURUMAN",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "KMI",
    AIRPORTNAME: "Miyazaki",
    CITYCODE: "KMI",
    CITYNAME: "MIYAZAKI",
    CITYNAME_HEB: "MIYAZAKI",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "KMJ",
    AIRPORTNAME: "Kumamoto",
    CITYCODE: "KMJ",
    CITYNAME: "KUMAMOTO",
    CITYNAME_HEB: "KUMAMOTO",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "KMK",
    AIRPORTNAME: "Makabana",
    CITYCODE: "KMK",
    CITYNAME: "MAKABANA",
    CITYNAME_HEB: "MAKABANA",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KML",
    AIRPORTNAME: "Kamileroi",
    CITYCODE: "KML",
    CITYNAME: "KAMILEROI",
    CITYNAME_HEB: "KAMILEROI",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KMM",
    AIRPORTNAME: "Kimam",
    CITYCODE: "KMM",
    CITYNAME: "KIMAM",
    CITYNAME_HEB: "KIMAM",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KMN",
    AIRPORTNAME: "Kamina",
    CITYCODE: "KMN",
    CITYNAME: "KAMINA",
    CITYNAME_HEB: "KAMINA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KMO",
    AIRPORTNAME: "Manokotak SPB",
    CITYCODE: "KMO",
    CITYNAME: "MANOKOTAK",
    CITYNAME_HEB: "Manokotak SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KMP",
    AIRPORTNAME: "J.G.H. Van Der Wath",
    CITYCODE: "KMP",
    CITYNAME: "KEETMANSHOOP",
    CITYNAME_HEB: "KEETMANSHOOP",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KMQ",
    AIRPORTNAME: "Komatsu",
    CITYCODE: "KMQ",
    CITYNAME: "KOMATSU",
    CITYNAME_HEB: "KOMATSU",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "ASQ",
    AIRPORTNAME: "Austin",
    CITYCODE: "ASQ",
    CITYNAME: "AUSTIN",
    CITYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05d9\u05df",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KMR",
    AIRPORTNAME: "Karimui",
    CITYCODE: "KMR",
    CITYNAME: "KARIMUI",
    CITYNAME_HEB: "KARIMUI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KMS",
    AIRPORTNAME: "Kumasi",
    CITYCODE: "KMS",
    CITYNAME: "KUMASI",
    CITYNAME_HEB: "KUMASI",
    COUNTRYCODE: "GH",
    COUNTRYNAME: "GHANA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KMT",
    AIRPORTNAME: "Kampot",
    CITYCODE: "KMT",
    CITYNAME: "KAMPOT",
    CITYNAME_HEB: "KAMPOT",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KMU",
    AIRPORTNAME: "Kismayu",
    CITYCODE: "KMU",
    CITYNAME: "KISMAYU",
    CITYNAME_HEB: "KISMAYU",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KMV",
    AIRPORTNAME: "Kalemyo",
    CITYCODE: "KMV",
    CITYNAME: "KALEMYO",
    CITYNAME_HEB: "KALEMYO",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "KMW",
    AIRPORTNAME: "Kostroma",
    CITYCODE: "KMW",
    CITYNAME: "KOSTROMA",
    CITYNAME_HEB: "KOSTROMA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KMY",
    AIRPORTNAME: "Moser Bay",
    CITYCODE: "KMY",
    CITYNAME: "MOSER BAY",
    CITYNAME_HEB: "Moser Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KMZ",
    AIRPORTNAME: "Kaoma",
    CITYCODE: "KMZ",
    CITYNAME: "KAOMA",
    CITYNAME_HEB: "KAOMA",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KNA",
    AIRPORTNAME: "Vina del Mar",
    CITYCODE: "KNA",
    CITYNAME: "VINA DEL MAR",
    CITYNAME_HEB: "VINA DEL MAR",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "KNB",
    AIRPORTNAME: "Kanab",
    CITYCODE: "KNB",
    CITYNAME: "KANAB",
    CITYNAME_HEB: "\u05e7\u05e0\u05d0\u05d1",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ASR",
    AIRPORTNAME: "Kayseri",
    CITYCODE: "ASR",
    CITYNAME: "KAYSERI",
    CITYNAME_HEB: "\u05e7\u05d9\u05d9\u05e1\u05e8\u05d9",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KND",
    AIRPORTNAME: "Kindu",
    CITYCODE: "KND",
    CITYNAME: "KINDU",
    CITYNAME_HEB: "KINDU",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KNE",
    AIRPORTNAME: "Kanainj",
    CITYCODE: "KNE",
    CITYNAME: "KANAINJ",
    CITYNAME_HEB: "KANAINJ",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KNF",
    AIRPORTNAME: "Marham RAF",
    CITYCODE: "KNF",
    CITYNAME: "KINGS LYNN",
    CITYNAME_HEB: "KINGS LYNN",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KNG",
    AIRPORTNAME: "Kaimana",
    CITYCODE: "KNG",
    CITYNAME: "KAIMANA",
    CITYNAME_HEB: "KAIMANA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KNH",
    AIRPORTNAME: "Shang-Yi",
    CITYCODE: "KNH",
    CITYNAME: "KINMEN",
    CITYNAME_HEB: "KINMEN",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "KNI",
    AIRPORTNAME: "Katanning",
    CITYCODE: "KNI",
    CITYNAME: "KATANNING",
    CITYNAME_HEB: "KATANNING",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KNJ",
    AIRPORTNAME: "Kindamba",
    CITYCODE: "KNJ",
    CITYNAME: "KINDAMBA",
    CITYNAME_HEB: "KINDAMBA",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KNK",
    AIRPORTNAME: "Kakhonak",
    CITYCODE: "KNK",
    CITYNAME: "KAKHONAK",
    CITYNAME_HEB: "Kakhonak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KNL",
    AIRPORTNAME: "Kelanoa",
    CITYCODE: "KNL",
    CITYNAME: "KELANOA",
    CITYNAME_HEB: "KELANOA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "AST",
    AIRPORTNAME: " ",
    CITYCODE: "AST",
    CITYNAME: "ASTEN",
    CITYNAME_HEB: "\u05d0\u05e1\u05d8\u05df",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KNM",
    AIRPORTNAME: "Kaniama",
    CITYCODE: "KNM",
    CITYNAME: "KANIAMA",
    CITYNAME_HEB: "KANIAMA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KNN",
    AIRPORTNAME: "Kankan",
    CITYCODE: "KNN",
    CITYNAME: "KANKAN",
    CITYNAME_HEB: "KANKAN",
    COUNTRYCODE: "GN",
    COUNTRYNAME: "GUINEA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KNO",
    AIRPORTNAME: "Knokke/Het Zoute",
    CITYCODE: "KNO",
    CITYNAME: "KNOKKE HET ZOUTE",
    CITYNAME_HEB: "\u05e0\u05d5\u05e7 \u05d4\u05d8 \u05d6\u05d5\u05d8",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KNP",
    AIRPORTNAME: "Capanda",
    CITYCODE: "KNP",
    CITYNAME: "CAPANDA",
    CITYNAME_HEB: "CAPANDA",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "KNQ",
    AIRPORTNAME: "Kone",
    CITYCODE: "KNQ",
    CITYNAME: "KONE",
    CITYNAME_HEB: "KONE",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "KNS",
    AIRPORTNAME: " ",
    CITYCODE: "KNS",
    CITYNAME: "KNYSNA",
    CITYNAME_HEB: "\u05e7\u05e0\u05d9\u05e1\u05e0\u05d4",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "KNT",
    AIRPORTNAME: "Municipal",
    CITYCODE: "KNT",
    CITYNAME: "KENNETT",
    CITYNAME_HEB: "KENNETT",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KNU",
    AIRPORTNAME: "Kanpur",
    CITYCODE: "KNU",
    CITYNAME: "KANPUR",
    CITYNAME_HEB: "KANPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "KNV",
    AIRPORTNAME: "Knights Inlet",
    CITYCODE: "KNV",
    CITYNAME: "KNIGHTS INLET",
    CITYNAME_HEB: "KNIGHTS INLET",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "KNW",
    AIRPORTNAME: "New Stuyahok",
    CITYCODE: "KNW",
    CITYNAME: "NEW STUYAHOK",
    CITYNAME_HEB: "New Stuyahok",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ASU",
    AIRPORTNAME: "Silvio Pettirossi International Airport",
    CITYCODE: "ASU",
    CITYNAME: "ASUNCION",
    CITYNAME_HEB: "\u05d0\u05d5\u05e1\u05d0\u05e0\u05e1\u05d9\u05d5\u05df",
    COUNTRYCODE: "PY",
    COUNTRYNAME: "PARAGUAY",
    COUNTRYNAME_HEB: "\u05e4\u05d0\u05e8\u05d0\u05d2\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "KNX",
    AIRPORTNAME: "Kununurra",
    CITYCODE: "KNX",
    CITYNAME: "KUNUNURRA",
    CITYNAME_HEB: "KUNUNURRA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KNY",
    AIRPORTNAME: "Kinoosao",
    CITYCODE: "KNY",
    CITYNAME: "KINOOSAO",
    CITYNAME_HEB: "KINOOSAO",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "KNZ",
    AIRPORTNAME: "Kenieba",
    CITYCODE: "KNZ",
    CITYNAME: "KENIEBA",
    CITYNAME_HEB: "KENIEBA",
    COUNTRYCODE: "ML",
    COUNTRYNAME: "MALI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d9",
  },
  {
    AIRPORTCODE: "KOA",
    AIRPORTNAME: "Keahole",
    CITYCODE: "KOA",
    CITYNAME: "KONA",
    CITYNAME_HEB: "KONA",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KOB",
    AIRPORTNAME: "Koutaba",
    CITYCODE: "KOB",
    CITYNAME: "KOUTABA",
    CITYNAME_HEB: "KOUTABA",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KOC",
    AIRPORTNAME: "Koumac",
    CITYCODE: "KOC",
    CITYNAME: "KOUMAC",
    CITYNAME_HEB: "KOUMAC",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "KOD",
    AIRPORTNAME: "Kotabangun",
    CITYCODE: "KOD",
    CITYNAME: "KOTABANGUN",
    CITYNAME_HEB: "KOTABANGUN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KOE",
    AIRPORTNAME: "Eltari",
    CITYCODE: "KOE",
    CITYNAME: "KUPANG",
    CITYNAME_HEB: "KUPANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KOF",
    AIRPORTNAME: "Komatipoort",
    CITYCODE: "KOF",
    CITYNAME: "KOMATIPOORT",
    CITYNAME_HEB: "KOMATIPOORT",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "KOG",
    AIRPORTNAME: "Khong",
    CITYCODE: "KOG",
    CITYNAME: "KHONG",
    CITYNAME_HEB: "KHONG",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "ASV",
    AIRPORTNAME: "Amboseli",
    CITYCODE: "ASV",
    CITYNAME: "AMBOSELI",
    CITYNAME_HEB: "\u05d0\u05de\u05d1\u05d5\u05e1\u05dc\u05d9",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "KOH",
    CITYNAME: "KOH PANGAN",
    CITYNAME_HEB: "\u05e7\u05d5\u05e4\u05e0\u05d2\u05df",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KOI",
    AIRPORTNAME: "Kirkwall Airport",
    CITYCODE: "KOI",
    CITYNAME: "KIRKWALL",
    CITYNAME_HEB: "KIRKWALL",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KOJ",
    AIRPORTNAME: "Kagoshima Airport",
    CITYCODE: "KOJ",
    CITYNAME: "KAGOSHIMA",
    CITYNAME_HEB: "KAGOSHIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "KOK",
    AIRPORTNAME: "Kruunupyy",
    CITYCODE: "KOK",
    CITYNAME: "KOKKOLA PIETARSAA",
    CITYNAME_HEB: "KOKKOLA PIETARSAA",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KOL",
    AIRPORTNAME: null,
    CITYCODE: "KOL",
    CITYNAME: "KOUMALA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KOM",
    AIRPORTNAME: "Komo-Manda",
    CITYCODE: "KOM",
    CITYNAME: "KOMO MANDA",
    CITYNAME_HEB: "KOMO MANDA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KON",
    AIRPORTNAME: "Kontum",
    CITYCODE: "KON",
    CITYNAME: "KONTUM",
    CITYNAME_HEB: "KONTUM",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "KOO",
    AIRPORTNAME: "Kongolo",
    CITYCODE: "KOO",
    CITYNAME: "KONGOLO",
    CITYNAME_HEB: "KONGOLO",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "KOP",
    AIRPORTNAME: "Nakhon Phanom",
    CITYCODE: "KOP",
    CITYNAME: "NAKHON PHANOM",
    CITYNAME_HEB: "NAKHON PHANOM",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KOQ",
    AIRPORTNAME: "Koethen",
    CITYCODE: "KOQ",
    CITYNAME: "KOETHEN DE",
    CITYNAME_HEB: "KOETHEN DE",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ASX",
    AIRPORTNAME: "Ashland",
    CITYCODE: "ASX",
    CITYNAME: "ASHLAND",
    CITYNAME_HEB: "ASHLAND",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KOR",
    AIRPORTNAME: " ",
    CITYCODE: "KOR",
    CITYNAME: "KOREA",
    CITYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05e7\u05d5\u05e8\u05d9\u05d0\u05d4",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "KOT",
    AIRPORTNAME: "Kotlik",
    CITYCODE: "KOT",
    CITYNAME: "KOTLIK",
    CITYNAME_HEB: "Kotlik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KOU",
    AIRPORTNAME: "Koulamoutou",
    CITYCODE: "KOU",
    CITYNAME: "KOULAMOUTOU",
    CITYNAME_HEB: "KOULAMOUTOU",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KOV",
    AIRPORTNAME: "Kokshetau",
    CITYCODE: "KOV",
    CITYNAME: "KOKSHETAU",
    CITYNAME_HEB: "KOKSHETAU",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "KOW",
    AIRPORTNAME: "Ganzhou",
    CITYCODE: "KOW",
    CITYNAME: "GANZHOU",
    CITYNAME_HEB: "GANZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KOX",
    AIRPORTNAME: "Kokonao",
    CITYCODE: "KOX",
    CITYNAME: "KOKONAO",
    CITYNAME_HEB: "KOKONAO",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KOY",
    AIRPORTNAME: "Olga Bay SPB",
    CITYCODE: "KOY",
    CITYNAME: "OLGA BAY",
    CITYNAME_HEB: "Olga Bay SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KOZ",
    AIRPORTNAME: "Ouzinkie SPB",
    CITYCODE: "KOZ",
    CITYNAME: "OUZINKIE",
    CITYNAME_HEB: "Ouzinkie SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KPA",
    AIRPORTNAME: "Kopiago",
    CITYCODE: "KPA",
    CITYNAME: "KOPIAGO",
    CITYNAME_HEB: "KOPIAGO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ABV",
    AIRPORTNAME: "Nnamdi Azikiwe International Airport",
    CITYCODE: "ABV",
    CITYNAME: "ABUJA",
    CITYNAME_HEB: "\u05d0\u05d1\u05d5\u05d2\u05d4",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ASY",
    AIRPORTNAME: "Ashley",
    CITYCODE: "ASY",
    CITYNAME: "ASHLEY",
    CITYNAME_HEB: "ASHLEY",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KPB",
    AIRPORTNAME: "Point Baker SPB",
    CITYCODE: "KPB",
    CITYNAME: "POINT BAKER",
    CITYNAME_HEB: "Point Baker SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KPC",
    AIRPORTNAME: "Port Clarence",
    CITYCODE: "KPC",
    CITYNAME: "PORT CLARENCE",
    CITYNAME_HEB: "Port Clarence",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KPD",
    AIRPORTNAME: "King Of Prussia",
    CITYCODE: "KPD",
    CITYNAME: "KING OF PRUSSIA",
    CITYNAME_HEB: "KING OF PRUSSIA",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KPE",
    AIRPORTNAME: "Yapsiei",
    CITYCODE: "KPE",
    CITYNAME: "YAPSIEI",
    CITYNAME_HEB: "YAPSIEI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KPF",
    AIRPORTNAME: "Kondubol",
    CITYCODE: "KPF",
    CITYNAME: "KINDUBOL",
    CITYNAME_HEB: "KINDUBOL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KPG",
    AIRPORTNAME: "Kurupung",
    CITYCODE: "KPG",
    CITYNAME: "KURUPUNG",
    CITYNAME_HEB: "KURUPUNG",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KPH",
    AIRPORTNAME: "Pauloff Harbor SPB",
    CITYCODE: "KPH",
    CITYNAME: "PAULOFF HARBOR",
    CITYNAME_HEB: "Pauloff Harbor SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KPI",
    AIRPORTNAME: "Kapit",
    CITYCODE: "KPI",
    CITYNAME: "KAPIT",
    CITYNAME_HEB: "KAPIT",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KPK",
    AIRPORTNAME: "Parks SPB",
    CITYCODE: "KPK",
    CITYNAME: "PARKS",
    CITYNAME_HEB: "Parks SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KPL",
    AIRPORTNAME: "Kapal",
    CITYCODE: "KPL",
    CITYNAME: "KAPAL",
    CITYNAME_HEB: "KAPAL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ASZ",
    AIRPORTNAME: "Asirim",
    CITYCODE: "ASZ",
    CITYNAME: "ASIRIM",
    CITYNAME_HEB: "ASIRIM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KPM",
    AIRPORTNAME: "Kompiam",
    CITYCODE: "KPM",
    CITYNAME: "KOMPIAM",
    CITYNAME_HEB: "KOMPIAM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KPN",
    AIRPORTNAME: "Kipnuk SPB",
    CITYCODE: "KPN",
    CITYNAME: "KIPNUK",
    CITYNAME_HEB: "Kipnuk SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KPO",
    AIRPORTNAME: "Pohang",
    CITYCODE: "KPO",
    CITYNAME: "POHANG",
    CITYNAME_HEB: "POHANG",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "KPP",
    AIRPORTNAME: "Kalpowar",
    CITYCODE: "KPP",
    CITYNAME: "KALPOWAR",
    CITYNAME_HEB: "KALPOWAR",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KPR",
    AIRPORTNAME: "Port Williams SPB",
    CITYCODE: "KPR",
    CITYNAME: "PORT WILLIAMS",
    CITYNAME_HEB: "Port Williams SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KPS",
    AIRPORTNAME: "Kempsey",
    CITYCODE: "KPS",
    CITYNAME: "KEMPSEY",
    CITYNAME_HEB: "KEMPSEY",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KPT",
    AIRPORTNAME: "Jackpot",
    CITYCODE: "KPT",
    CITYNAME: "JACKPOT",
    CITYNAME_HEB: "JACKPOT",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KPV",
    AIRPORTNAME: "Perryville SPB",
    CITYCODE: "KPV",
    CITYNAME: "PERRYVILLE",
    CITYNAME_HEB: "Perryville SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KPY",
    AIRPORTNAME: "Port Bailey SPB",
    CITYCODE: "KPY",
    CITYNAME: "PORT BAILEY",
    CITYNAME_HEB: "Port Bailey SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KQA",
    AIRPORTNAME: "Akutan",
    CITYCODE: "KQA",
    CITYNAME: "AKUTAN",
    CITYNAME_HEB: "Akutan",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ATA",
    AIRPORTNAME: "Anta",
    CITYCODE: "ATA",
    CITYNAME: "ANTA",
    CITYNAME_HEB: "ANTA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "KQB",
    AIRPORTNAME: "Koonibba",
    CITYCODE: "KQB",
    CITYNAME: "KOONIBBA",
    CITYNAME_HEB: "KOONIBBA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KQL",
    AIRPORTNAME: "Kol",
    CITYCODE: "KQL",
    CITYNAME: "KOL",
    CITYNAME_HEB: "KOL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KRA",
    AIRPORTNAME: "Kerang",
    CITYCODE: "KRA",
    CITYNAME: "KERANG",
    CITYNAME_HEB: "KERANG",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KRB",
    AIRPORTNAME: "Karumba",
    CITYCODE: "KRB",
    CITYNAME: "KARUMBA",
    CITYNAME_HEB: "KARUMBA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KRC",
    AIRPORTNAME: "Kerinci",
    CITYCODE: "KRC",
    CITYNAME: "KERINCI",
    CITYNAME_HEB: "KERINCI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KRD",
    AIRPORTNAME: "Kurundi",
    CITYCODE: "KRD",
    CITYNAME: "KURUNDI",
    CITYNAME_HEB: "KURUNDI",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KRE",
    AIRPORTNAME: "Kirundo",
    CITYCODE: "KRE",
    CITYNAME: "KIRUNDO",
    CITYNAME_HEB: "KIRUNDO",
    COUNTRYCODE: "BI",
    COUNTRYNAME: "BURUNDI",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05d5\u05e0\u05d3\u05d9",
  },
  {
    AIRPORTCODE: "KRF",
    AIRPORTNAME: "Kramfors",
    CITYCODE: "KRF",
    CITYNAME: "KRAMFORS",
    CITYNAME_HEB: "KRAMFORS",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KRH",
    AIRPORTNAME: null,
    CITYCODE: "KRH",
    CITYNAME: "REDHILL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ATB",
    AIRPORTNAME: "Atbara",
    CITYCODE: "ATB",
    CITYNAME: "ATBARA",
    CITYNAME_HEB: "ATBARA",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "KRI",
    AIRPORTNAME: "Kikori",
    CITYCODE: "KRI",
    CITYNAME: "KIKORI",
    CITYNAME_HEB: "KIKORI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KRJ",
    AIRPORTNAME: "Karawari",
    CITYCODE: "KRJ",
    CITYNAME: "KARAWARI",
    CITYNAME_HEB: "KARAWARI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KRK",
    AIRPORTNAME: "J. Paul II Balice International",
    CITYCODE: "KRK",
    CITYNAME: "KRAKOW",
    CITYNAME_HEB: "\u05e7\u05e8\u05e7\u05d5\u05d1",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KRL",
    AIRPORTNAME: "Korla",
    CITYCODE: "KRL",
    CITYNAME: "KORLA",
    CITYNAME_HEB: "KORLA",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KRM",
    AIRPORTNAME: "Karanambo",
    CITYCODE: "KRM",
    CITYNAME: "KARANAMBO",
    CITYNAME_HEB: "KARANAMBO",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KRN",
    AIRPORTNAME: "Kiruna",
    CITYCODE: "KRN",
    CITYNAME: "KIRUNA",
    CITYNAME_HEB: "KIRUNA",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "KRO",
    CITYNAME: "KRONPLATZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KRP",
    AIRPORTNAME: "Karup",
    CITYCODE: "KRP",
    CITYNAME: "KARUP",
    CITYNAME_HEB: "KARUP",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "KRQ",
    AIRPORTNAME: "Kramatorsk",
    CITYCODE: "KRQ",
    CITYNAME: "KRAMATORSK",
    CITYNAME_HEB: "KRAMATORSK",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "ATC",
    AIRPORTNAME: "Arthur's Town",
    CITYCODE: "ATC",
    CITYNAME: "ARTHUR'S TOWN",
    CITYNAME_HEB: "ARTHUR'S TOWN",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "KRR",
    AIRPORTNAME: "Krasnodar",
    CITYCODE: "KRR",
    CITYNAME: "KRASNODAR",
    CITYNAME_HEB: "KRASNODAR",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KRS",
    AIRPORTNAME: "Kjevik",
    CITYCODE: "KRS",
    CITYNAME: "KRISTIANSAND",
    CITYNAME_HEB:
      "\u05e7\u05e8\u05d9\u05e1\u05d8\u05d9\u05d0\u05e0\u05e1\u05e0\u05d3",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XKR",
    AIRPORTNAME: null,
    CITYCODE: "KRS",
    CITYNAME: "KRISTIANSAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KRT",
    AIRPORTNAME: "Civil",
    CITYCODE: "KRT",
    CITYNAME: "KHARTOUM",
    CITYNAME_HEB: "\u05d7\u05e8\u05d8\u05d5\u05dd",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "KRU",
    AIRPORTNAME: "Kerau",
    CITYCODE: "KRU",
    CITYNAME: "KERAU",
    CITYNAME_HEB: "KERAU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KRV",
    AIRPORTNAME: "Kerio Valley",
    CITYCODE: "KRV",
    CITYNAME: "KERIO VALLEY",
    CITYNAME_HEB: "KERIO VALLEY",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KRW",
    AIRPORTNAME: "Turkmanbashi",
    CITYCODE: "KRW",
    CITYNAME: "TURKMANBASHI",
    CITYNAME_HEB: "TURKMANBASHI",
    COUNTRYCODE: "TM",
    COUNTRYNAME: "TURKMENISTAN",
    COUNTRYNAME_HEB:
      "\u05d8\u05d5\u05e8\u05e7\u05d9\u05de\u05d9\u05e0\u05d9\u05e1\u05d8\u05d0\u05df",
  },
  {
    AIRPORTCODE: "KRX",
    AIRPORTNAME: "Kar Kar",
    CITYCODE: "KRX",
    CITYNAME: "KAR KAR",
    CITYNAME_HEB: "KAR KAR",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KRY",
    AIRPORTNAME: "Karamay",
    CITYCODE: "KRY",
    CITYNAME: "KARAMAY",
    CITYNAME_HEB: "KARAMAY",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KRZ",
    AIRPORTNAME: "Kiri",
    CITYCODE: "KRZ",
    CITYNAME: "KIRI",
    CITYNAME_HEB: "KIRI",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "ATD",
    AIRPORTNAME: "Atoifi",
    CITYCODE: "ATD",
    CITYNAME: "ATOIFI",
    CITYNAME_HEB: "ATOIFI",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "KSA",
    AIRPORTNAME: "Kosrae",
    CITYCODE: "KSA",
    CITYNAME: "KOSRAE",
    CITYNAME_HEB: "KOSRAE",
    COUNTRYCODE: "FM",
    COUNTRYNAME: "MICRONESIA",
    COUNTRYNAME_HEB: "\u05de\u05d9\u05e7\u05e8\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KSB",
    AIRPORTNAME: "Kasanombe",
    CITYCODE: "KSB",
    CITYNAME: "KASANOMBE",
    CITYNAME_HEB: "KASANOMBE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KSC",
    AIRPORTNAME: "Barca",
    CITYCODE: "KSC",
    CITYNAME: "KOSICE",
    CITYNAME_HEB: "\u05e7\u05d5\u05e1\u05d9\u05e7",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KSD",
    AIRPORTNAME: "Karlstad",
    CITYCODE: "KSD",
    CITYNAME: "KARLSTAD",
    CITYNAME_HEB: "\u05e7\u05e8\u05dc\u05e1\u05d8\u05d3",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KSE",
    AIRPORTNAME: "Kasese",
    CITYCODE: "KSE",
    CITYNAME: "KASESE",
    CITYNAME_HEB: "\u05e7\u05d9\u05e0\u05e1\u05e9\u05d4",
    COUNTRYCODE: "UG",
    COUNTRYNAME: "UGANDA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "KSF",
    AIRPORTNAME: "Kassel-Calden",
    CITYCODE: "KSF",
    CITYNAME: "KASSEL",
    CITYNAME_HEB: "\u05e7\u05d0\u05e1\u05dc",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KSG",
    AIRPORTNAME: "Kisengan",
    CITYCODE: "KSG",
    CITYNAME: "KISENGAN",
    CITYNAME_HEB: "KISENGAN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KSI",
    AIRPORTNAME: "Kissidougou",
    CITYCODE: "KSI",
    CITYNAME: "KISSIDOUGOU",
    CITYNAME_HEB: "KISSIDOUGOU",
    COUNTRYCODE: "GN",
    COUNTRYNAME: "GUINEA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KSJ",
    AIRPORTNAME: "Kasos Island",
    CITYCODE: "KSJ",
    CITYNAME: "KASOS ISLAND",
    CITYNAME_HEB: "KASOS ISLAND",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KSK",
    AIRPORTNAME: "Karlskoga",
    CITYCODE: "KSK",
    CITYNAME: "KARLSKOGA",
    CITYNAME_HEB: "KARLSKOGA",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ATE",
    AIRPORTNAME: "Antlers",
    CITYCODE: "ATE",
    CITYNAME: "ANTLERS",
    CITYNAME_HEB: "ANTLERS",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KSL",
    AIRPORTNAME: "Kassala",
    CITYCODE: "KSL",
    CITYNAME: "KASSALA",
    CITYNAME_HEB: "KASSALA",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "KSM",
    AIRPORTNAME: "Saint Marys",
    CITYCODE: "KSM",
    CITYNAME: "SAINT MARYS",
    CITYNAME_HEB: "Saint Marys",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KSN",
    AIRPORTNAME: "Kostanay",
    CITYCODE: "KSN",
    CITYNAME: "KOSTANAY",
    CITYNAME_HEB: "KOSTANAY",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "KSO",
    AIRPORTNAME: "Aristoteles Airport",
    CITYCODE: "KSO",
    CITYNAME: "KASTORIA",
    CITYNAME_HEB: "KASTORIA",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KSP",
    AIRPORTNAME: "Kosipe",
    CITYCODE: "KSP",
    CITYNAME: "KOSIPE",
    CITYNAME_HEB: "KOSIPE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KSQ",
    AIRPORTNAME: "Karshi",
    CITYCODE: "KSQ",
    CITYNAME: "KARSHI",
    CITYNAME_HEB: "KARSHI",
    COUNTRYCODE: "UZ",
    COUNTRYNAME: "UZBEKISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d6\u05d1\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "KSR",
    AIRPORTNAME: "Sandy River",
    CITYCODE: "KSR",
    CITYNAME: "SANDY RIVER",
    CITYNAME_HEB: "Sandy River",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KSS",
    AIRPORTNAME: "Sikasso",
    CITYCODE: "KSS",
    CITYNAME: "SIKASSO",
    CITYNAME_HEB: "SIKASSO",
    COUNTRYCODE: "ML",
    COUNTRYNAME: "MALI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d9",
  },
  {
    AIRPORTCODE: "KST",
    AIRPORTNAME: "Kosti",
    CITYCODE: "KST",
    CITYNAME: "KOSTI",
    CITYNAME_HEB: "KOSTI",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "KSU",
    AIRPORTNAME: "Kvernberget",
    CITYCODE: "KSU",
    CITYNAME: "KRISTIANSUND",
    CITYNAME_HEB: "KRISTIANSUND",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ATF",
    AIRPORTNAME: "Chachoan",
    CITYCODE: "ATF",
    CITYNAME: "AMBATO",
    CITYNAME_HEB: "AMBATO",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "KSV",
    AIRPORTNAME: "Springvale",
    CITYCODE: "KSV",
    CITYNAME: "SPRINGVALE",
    CITYNAME_HEB: "SPRINGVALE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KSW",
    AIRPORTNAME: null,
    CITYCODE: "KSW",
    CITYNAME: "KIRYAT SHMONA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KSX",
    AIRPORTNAME: "Yasuru",
    CITYCODE: "KSX",
    CITYNAME: "YASURU",
    CITYNAME_HEB: "YASURU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KSY",
    AIRPORTNAME: "Kars",
    CITYCODE: "KSY",
    CITYNAME: "KARS",
    CITYNAME_HEB: "KARS",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KSZ",
    AIRPORTNAME: "Kotlas",
    CITYCODE: "KSZ",
    CITYNAME: "KOTLAS",
    CITYNAME_HEB: "KOTLAS",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KTA",
    AIRPORTNAME: "Karratha",
    CITYCODE: "KTA",
    CITYNAME: "KARRATHA",
    CITYNAME_HEB: "KARRATHA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KTB",
    AIRPORTNAME: "Thorne Bay",
    CITYCODE: "KTB",
    CITYNAME: "THORNE BAY",
    CITYNAME_HEB: "Thorne Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KTC",
    AIRPORTNAME: "Katiola",
    CITYCODE: "KTC",
    CITYNAME: "KATIOLA",
    CITYNAME_HEB: "KATIOLA",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "KTD",
    AIRPORTNAME: "Kitadaito",
    CITYCODE: "KTD",
    CITYNAME: "KITADAITO",
    CITYNAME_HEB: "KITADAITO",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "KTE",
    AIRPORTNAME: "Kerteh",
    CITYCODE: "KTE",
    CITYNAME: "KERTEH",
    CITYNAME_HEB: "KERTEH",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ATG",
    AIRPORTNAME: "Attock",
    CITYCODE: "ATG",
    CITYNAME: "ATTOCK",
    CITYNAME_HEB: "ATTOCK",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "KTF",
    AIRPORTNAME: "Takaka",
    CITYCODE: "KTF",
    CITYNAME: "TAKAKA",
    CITYNAME_HEB: "TAKAKA",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KTG",
    AIRPORTNAME: "Ketapang",
    CITYCODE: "KTG",
    CITYNAME: "KETAPANG",
    CITYNAME_HEB: "KETAPANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KTH",
    AIRPORTNAME: "Tikchik",
    CITYCODE: "KTH",
    CITYNAME: "TIKCHIK",
    CITYNAME_HEB: "Tikchik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KTI",
    AIRPORTNAME: "Kratie",
    CITYCODE: "KTI",
    CITYNAME: "KRATIE",
    CITYNAME_HEB: "KRATIE",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KTK",
    AIRPORTNAME: "Kanua",
    CITYCODE: "KTK",
    CITYNAME: "KANUA",
    CITYNAME_HEB: "KANUA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KTL",
    AIRPORTNAME: "Kitale",
    CITYCODE: "KTL",
    CITYNAME: "KITALE",
    CITYNAME_HEB: "KITALE",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KTM",
    AIRPORTNAME: "Tribhuvan",
    CITYCODE: "KTM",
    CITYNAME: "KATHMANDU",
    CITYNAME_HEB: "\u05e7\u05d8\u05de\u05e0\u05d3\u05d5",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "KTN",
    AIRPORTNAME: "Ketchikan International",
    CITYCODE: "KTN",
    CITYNAME: "KETCHIKAN",
    CITYNAME_HEB: "Ketchikan International",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WFB",
    AIRPORTNAME: "Ketchikan Waterfront SPB",
    CITYCODE: "KTN",
    CITYNAME: "KETCHIKAN",
    CITYNAME_HEB: "Ketchikan Waterfront SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KTO",
    AIRPORTNAME: "Kato",
    CITYCODE: "KTO",
    CITYNAME: "KATO",
    CITYNAME_HEB: "KATO",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "ATH",
    AIRPORTNAME: "Eleftherios Venizelos",
    CITYCODE: "ATH",
    CITYNAME: "ATHENS",
    CITYNAME_HEB: "\u05d0\u05ea\u05d5\u05e0\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
    ORGANIZED: true
  },
  {
    AIRPORTCODE: "KTQ",
    AIRPORTNAME: "Kitee",
    CITYCODE: "KTQ",
    CITYNAME: "KITEE",
    CITYNAME_HEB: "KITEE",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KTR",
    AIRPORTNAME: "Tindal",
    CITYCODE: "KTR",
    CITYNAME: "KATHERINE",
    CITYNAME_HEB: "KATHERINE",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KTS",
    AIRPORTNAME: "Brevig Mission",
    CITYCODE: "KTS",
    CITYNAME: "TELLER MISSION",
    CITYNAME_HEB: "Brevig Mission",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KTT",
    AIRPORTNAME: "Kittila",
    CITYCODE: "KTT",
    CITYNAME: "KITTILA",
    CITYNAME_HEB: "KITTILA",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KTU",
    AIRPORTNAME: "Kota",
    CITYCODE: "KTU",
    CITYNAME: "KOTA",
    CITYNAME_HEB: "KOTA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "KTV",
    AIRPORTNAME: "Kamarata",
    CITYCODE: "KTV",
    CITYNAME: "KAMARATA",
    CITYNAME_HEB: "KAMARATA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "KTW",
    AIRPORTNAME: "Pyrzowice",
    CITYCODE: "KTW",
    CITYNAME: "KATOWICE",
    CITYNAME_HEB: "\u05e7\u05d8\u05d5\u05d1\u05d9\u05e5",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KTX",
    AIRPORTNAME: "Koutiala",
    CITYCODE: "KTX",
    CITYNAME: "KOUTIALA",
    CITYNAME_HEB: "KOUTIALA",
    COUNTRYCODE: "ML",
    COUNTRYNAME: "MALI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d9",
  },
  {
    AIRPORTCODE: "KTZ",
    AIRPORTNAME: "Kwun Tong",
    CITYCODE: "KTZ",
    CITYNAME: "KWUN TONG",
    CITYNAME_HEB: "KWUN TONG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KUA",
    AIRPORTNAME: "Kuantan",
    CITYCODE: "KUA",
    CITYNAME: "KUANTAN",
    CITYNAME_HEB: "KUANTAN",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AAG",
    AIRPORTNAME: null,
    CITYCODE: "AAG",
    CITYNAME: "ARAPOTI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ABW",
    AIRPORTNAME: "Abau",
    CITYCODE: "ABW",
    CITYNAME: "ABAU",
    CITYNAME_HEB: "ABAU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KUB",
    AIRPORTNAME: null,
    CITYCODE: "KUB",
    CITYNAME: "KUALA BELAIT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BN",
    COUNTRYNAME: "BRUNEI DARUSSALAM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KUC",
    AIRPORTNAME: "Kuria",
    CITYCODE: "KUC",
    CITYNAME: "KURIA",
    CITYNAME_HEB: "KURIA",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "KUD",
    AIRPORTNAME: " ",
    CITYCODE: "KUD",
    CITYNAME: "KUDOWA ZDROJ",
    CITYNAME_HEB:
      "\u05e7\u05d5\u05d3\u05d5\u05d1\u05d4 \u05d6\u05d3\u05e8\u05d5\u05d2",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KUE",
    AIRPORTNAME: "Kukundu",
    CITYCODE: "KUE",
    CITYNAME: "KUKUNDU",
    CITYNAME_HEB: "KUKUNDU",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "KUF",
    AIRPORTNAME: "Samara",
    CITYCODE: "KUF",
    CITYNAME: "SAMARA",
    CITYNAME_HEB: "\u05e1\u05de\u05e8\u05d4",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KUG",
    AIRPORTNAME: "Kubin Island",
    CITYCODE: "KUG",
    CITYNAME: "KUBIN ISLAND",
    CITYNAME_HEB: "KUBIN ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KUH",
    AIRPORTNAME: "Kushiro",
    CITYCODE: "KUH",
    CITYNAME: "KUSHIRO",
    CITYNAME_HEB: "KUSHIRO",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "KUI",
    AIRPORTNAME: "Kawau Island",
    CITYCODE: "KUI",
    CITYNAME: "KAWAU ISLAND",
    CITYNAME_HEB: "KAWAU ISLAND",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KUJ",
    AIRPORTNAME: "Kushimoto",
    CITYCODE: "KUJ",
    CITYNAME: "KUSHIMOTO",
    CITYNAME_HEB: "KUSHIMOTO",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "KUK",
    AIRPORTNAME: "Kasigluk",
    CITYCODE: "KUK",
    CITYNAME: "KASIGLUK",
    CITYNAME_HEB: "Kasigluk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ATI",
    AIRPORTNAME: "Artigas",
    CITYCODE: "ATI",
    CITYNAME: "ARTIGAS",
    CITYNAME_HEB: "ARTIGAS",
    COUNTRYCODE: "UY",
    COUNTRYNAME: "URUGUAY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e8\u05d5\u05d2\u05d0\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "KUL",
    AIRPORTNAME: "Kuala Lumpur International",
    CITYCODE: "KUL",
    CITYNAME: "KUALA LUMPUR",
    CITYNAME_HEB:
      "\u05e7\u05d5\u05d0\u05dc\u05d4 \u05dc\u05de\u05e4\u05d5\u05e8",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SZB",
    AIRPORTNAME: null,
    CITYCODE: "KUL",
    CITYNAME: "KUALA LUMPUR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKL",
    AIRPORTNAME: null,
    CITYCODE: "KUL",
    CITYNAME: "KUALA LUMPUR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KUM",
    AIRPORTNAME: "Yakushima",
    CITYCODE: "KUM",
    CITYNAME: "YAKUSHIMA",
    CITYNAME_HEB: "YAKUSHIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "KUN",
    AIRPORTNAME: "Kaunas",
    CITYCODE: "KUN",
    CITYNAME: "KAUNAS",
    CITYNAME_HEB: "\u05e7\u05d5\u05d1\u05e0\u05d4",
    COUNTRYCODE: "LT",
    COUNTRYNAME: "LITHUANIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d8\u05d0",
  },
  {
    AIRPORTCODE: "KUO",
    AIRPORTNAME: "Kuopio",
    CITYCODE: "KUO",
    CITYNAME: "KUOPIO",
    CITYNAME_HEB: "KUOPIO",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KUP",
    AIRPORTNAME: "Kupiano",
    CITYCODE: "KUP",
    CITYNAME: "KUPIANO",
    CITYNAME_HEB: "KUPIANO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KUQ",
    AIRPORTNAME: "Kuri",
    CITYCODE: "KUQ",
    CITYNAME: "KURI",
    CITYNAME_HEB: "KURI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KUR",
    AIRPORTNAME: null,
    CITYCODE: "KUR",
    CITYNAME: "KURAN O MUNJAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KUS",
    AIRPORTNAME: "Kulusuk",
    CITYCODE: "KUS",
    CITYNAME: "KULUSUK",
    CITYNAME_HEB: "KULUSUK",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ATJ",
    AIRPORTNAME: "Antsirabe",
    CITYCODE: "ATJ",
    CITYNAME: "ANTSIRABE",
    CITYNAME_HEB: "ANTSIRABE",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "KUT",
    AIRPORTNAME: "Kutaisi",
    CITYCODE: "KUT",
    CITYNAME: "KUTAISI",
    CITYNAME_HEB: "\u05e7\u05d5\u05d8\u05d0\u05d9\u05e1\u05d9",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KUU",
    AIRPORTNAME: "Bhuntar",
    CITYCODE: "KUU",
    CITYNAME: "KULU",
    CITYNAME_HEB: "KULU",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "KUV",
    AIRPORTNAME: "Kunsan",
    CITYCODE: "KUV",
    CITYNAME: "GUNSAN",
    CITYNAME_HEB: "\u05d2\u05d5\u05e0\u05e1\u05d0\u05df",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "KUW",
    AIRPORTNAME: "Kugururok River",
    CITYCODE: "KUW",
    CITYNAME: "KUGURUROK RIVER",
    CITYNAME_HEB: "Kugururok River",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KUX",
    AIRPORTNAME: "Kuyol",
    CITYCODE: "KUX",
    CITYNAME: "KUYOL",
    CITYNAME_HEB: "KUYOL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KUY",
    AIRPORTNAME: "Kamusi Airport",
    CITYCODE: "KUY",
    CITYNAME: "KAMUSI",
    CITYNAME_HEB: "KAMUSI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KVA",
    AIRPORTNAME: "Megas Alexandros Airport",
    CITYCODE: "KVA",
    CITYNAME: "KAVALA",
    CITYNAME_HEB: "KAVALA",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "KVB",
    AIRPORTNAME: "Skovde",
    CITYCODE: "KVB",
    CITYNAME: "SKOVDE",
    CITYNAME_HEB: "SKOVDE",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KVC",
    AIRPORTNAME: "King Cove",
    CITYCODE: "KVC",
    CITYNAME: "KING COVE",
    CITYNAME_HEB: "King Cove",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ATK",
    AIRPORTNAME: "Atqasuk",
    CITYCODE: "ATK",
    CITYNAME: "ATQASUK",
    CITYNAME_HEB: "Atqasuk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KVD",
    AIRPORTNAME: "Gyandzha",
    CITYCODE: "KVD",
    CITYNAME: "GYANDZHA",
    CITYNAME_HEB: "\u05d2\u05d0\u05e0\u05d2\u05d4",
    COUNTRYCODE: "AZ",
    COUNTRYNAME: "AZERBAIJAN",
    COUNTRYNAME_HEB: "\u05d0\u05d6\u05e8\u05d1\u05d9\u05d9\u05d2\u05df",
  },
  {
    AIRPORTCODE: "KVE",
    AIRPORTNAME: "Kitava",
    CITYCODE: "KVE",
    CITYNAME: "KITAVA",
    CITYNAME_HEB: "KITAVA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KVG",
    AIRPORTNAME: "Kavieng",
    CITYCODE: "KVG",
    CITYNAME: "KAVIENG",
    CITYNAME_HEB: "KAVIENG",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "KVK",
    AIRPORTNAME: "Kirovsk",
    CITYCODE: "KVK",
    CITYNAME: "KIROVSK",
    CITYNAME_HEB: "KIROVSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KVL",
    AIRPORTNAME: "Kivalina",
    CITYCODE: "KVL",
    CITYNAME: "KIVALINA",
    CITYNAME_HEB: "Kivalina",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "KVU",
    AIRPORTNAME: "Korolevu",
    CITYCODE: "KVU",
    CITYNAME: "KOROLEVU",
    CITYNAME_HEB: "KOROLEVU",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "KVX",
    AIRPORTNAME: "Kirov",
    CITYCODE: "KVX",
    CITYNAME: "KIROV",
    CITYNAME_HEB: "KIROV",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KWA",
    AIRPORTNAME: "Kwajalein",
    CITYCODE: "KWA",
    CITYNAME: "KWAJALEIN",
    CITYNAME_HEB: "KWAJALEIN",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "KWB",
    AIRPORTNAME: "Karimunjawa",
    CITYCODE: "KWB",
    CITYNAME: "KARIMUNJAWA",
    CITYNAME_HEB: "KARIMUNJAWA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TGH",
    AIRPORTNAME: "Tongoa",
    CITYCODE: "TGH",
    CITYNAME: "TONGOA",
    CITYNAME_HEB: "TONGOA",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "TGI",
    AIRPORTNAME: "Tingo Maria",
    CITYCODE: "TGI",
    CITYNAME: "TINGO MARIA",
    CITYNAME_HEB: "TINGO MARIA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "TGJ",
    AIRPORTNAME: "Tiga",
    CITYCODE: "TGJ",
    CITYNAME: "TIGA",
    CITYNAME_HEB: "TIGA",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "TGL",
    AIRPORTNAME: "Tagula",
    CITYCODE: "TGL",
    CITYNAME: "TAGULA",
    CITYNAME_HEB: "TAGULA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TGM",
    AIRPORTNAME: "Tirgu Mures",
    CITYCODE: "TGM",
    CITYNAME: "TIRGU MURES",
    CITYNAME_HEB: "\u05d8\u05d9\u05e8\u05d2\u05d5 \u05de\u05d5\u05e8\u05e1",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TGN",
    AIRPORTNAME: "La Trobe Regional",
    CITYCODE: "TGN",
    CITYNAME: "TRARALGON",
    CITYNAME_HEB: "TRARALGON",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TGO",
    AIRPORTNAME: "Tongliao",
    CITYCODE: "TGO",
    CITYNAME: "TONGLIAO",
    CITYNAME_HEB: "TONGLIAO",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "TGQ",
    AIRPORTNAME: null,
    CITYCODE: "TGQ",
    CITYNAME: "TANGARA DA SERRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TGR",
    AIRPORTNAME: "Touggourt",
    CITYCODE: "TGR",
    CITYNAME: "TOUGGOURT",
    CITYNAME_HEB: "TOUGGOURT",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TGS",
    AIRPORTNAME: "Chokwe",
    CITYCODE: "TGS",
    CITYNAME: "CHOKWE",
    CITYNAME_HEB: "CHOKWE",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "ADR",
    AIRPORTNAME: "Andrews",
    CITYCODE: "ADR",
    CITYNAME: "ANDREWS",
    CITYNAME_HEB: "Andrews",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SZD",
    AIRPORTNAME: "Sheffield City Airport",
    CITYCODE: "SZD",
    CITYNAME: "SHEFFIELD",
    CITYNAME_HEB: "\u05e9\u05e4\u05d9\u05dc\u05d3",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZFG",
    AIRPORTNAME: null,
    CITYCODE: "SZD",
    CITYNAME: "SHEFFIELD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SZE",
    AIRPORTNAME: "Semera Airport",
    CITYCODE: "SZE",
    CITYNAME: "SEMERA",
    CITYNAME_HEB: "SEMERA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SZG",
    AIRPORTNAME: "Salzburg W.A. Mozart",
    CITYCODE: "SZG",
    CITYNAME: "SALZBURG",
    CITYNAME_HEB: "\u05d6\u05dc\u05e6\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SZH",
    AIRPORTNAME: "Senipah",
    CITYCODE: "SZH",
    CITYNAME: "SENIPAH",
    CITYNAME_HEB: "SENIPAH",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BLP",
    AIRPORTNAME: "Bellavista",
    CITYCODE: "BLP",
    CITYNAME: "BELLAVISTA",
    CITYNAME_HEB: "BELLAVISTA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "SZI",
    AIRPORTNAME: "Zaisan",
    CITYCODE: "SZI",
    CITYNAME: "ZAISAN",
    CITYNAME_HEB: "ZAISAN",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "SZJ",
    AIRPORTNAME: "Siguanea",
    CITYCODE: "SZJ",
    CITYNAME: "SIGUANEA",
    CITYNAME_HEB: "SIGUANEA",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "SZK",
    AIRPORTNAME: "Skukuza",
    CITYCODE: "SZK",
    CITYNAME: "SKUKUZA",
    CITYNAME_HEB: "SKUKUZA",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "SZL",
    AIRPORTNAME: "Whiteman AFB",
    CITYCODE: "SZL",
    CITYNAME: "WARRENSBURG",
    CITYNAME_HEB: "WARRENSBURG",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SZM",
    AIRPORTNAME: "Sesriem",
    CITYCODE: "SZM",
    CITYNAME: "SESRIEM",
    CITYNAME_HEB: "SESRIEM",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SZO",
    AIRPORTNAME: "Shanzhou",
    CITYCODE: "SZO",
    CITYNAME: "SHANZHOU",
    CITYNAME_HEB: "SHANZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SZP",
    AIRPORTNAME: "Santa Paula",
    CITYCODE: "SZP",
    CITYNAME: "SANTA PAULA",
    CITYNAME_HEB: "SANTA PAULA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SZQ",
    AIRPORTNAME: null,
    CITYCODE: "SZQ",
    CITYNAME: "SAENZ PENA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SZR",
    AIRPORTNAME: "Stara Zagora",
    CITYCODE: "SZR",
    CITYNAME: "STARA ZAGORA",
    CITYNAME_HEB:
      "\u05e1\u05d8\u05e8\u05d4 \u05d6\u05e8\u05d2\u05d5\u05e8\u05d4",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SZS",
    AIRPORTNAME: "Stewart Island",
    CITYCODE: "SZS",
    CITYNAME: "STEWART ISLAND",
    CITYNAME_HEB: "STEWART ISLAND",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BLQ",
    AIRPORTNAME: "Guglielmo Marconi",
    CITYCODE: "BLQ",
    CITYNAME: "BOLOGNA",
    CITYNAME_HEB: "\u05d1\u05d5\u05dc\u05d5\u05e0\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SZT",
    AIRPORTNAME: "San Cristobal Airport",
    CITYCODE: "SZT",
    CITYNAME: "SAN CRISTOBAL DE LAS CA",
    CITYNAME_HEB:
      "\u05e1\u05df \u05e7\u05e8\u05d9\u05e1\u05d8\u05d5\u05d1\u05dc",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "SZU",
    AIRPORTNAME: "Segou",
    CITYCODE: "SZU",
    CITYNAME: "SEGOU",
    CITYNAME_HEB: "SEGOU",
    COUNTRYCODE: "ML",
    COUNTRYNAME: "MALI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d9",
  },
  {
    AIRPORTCODE: "SZV",
    AIRPORTNAME: "Suzhou",
    CITYCODE: "SZV",
    CITYNAME: "SUZHOU",
    CITYNAME_HEB: "\u05e1\u05d5\u05d2\u05d5\u05d0\u05d5",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SZW",
    AIRPORTNAME: "Parchim Airport",
    CITYCODE: "SZW",
    CITYNAME: "SCHWERIN",
    CITYNAME_HEB: "SCHWERIN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZSR",
    AIRPORTNAME: null,
    CITYCODE: "SZW",
    CITYNAME: "SCHWERIN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SZX",
    AIRPORTNAME: "Shenzhen",
    CITYCODE: "SZX",
    CITYNAME: "SHENZHEN",
    CITYNAME_HEB: "\u05e9\u05d9\u05e0\u05d6\u05df",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SZY",
    AIRPORTNAME: "Mazury",
    CITYCODE: "SZY",
    CITYNAME: "SZYMANY",
    CITYNAME_HEB: "SZYMANY",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SZZ",
    AIRPORTNAME: "Goleniow",
    CITYCODE: "SZZ",
    CITYNAME: "SZCZECIN",
    CITYNAME_HEB: "SZCZECIN",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "TAA",
    AIRPORTNAME: "Tarapaina",
    CITYCODE: "TAA",
    CITYNAME: "TARAPAINA",
    CITYNAME_HEB: "TARAPAINA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "BLR",
    AIRPORTNAME: "Bengaluru International Airport",
    CITYCODE: "BLR",
    CITYNAME: "BANGALORE",
    CITYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d0\u05d5\u05e8",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "TAC",
    AIRPORTNAME: "D.Z. Romualdez",
    CITYCODE: "TAC",
    CITYNAME: "TACLOBAN",
    CITYNAME_HEB: "TACLOBAN",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "TAD",
    AIRPORTNAME: "Las Animas",
    CITYCODE: "TAD",
    CITYNAME: "TRINIDAD",
    CITYNAME_HEB: "TRINIDAD",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TAE",
    AIRPORTNAME: "Daegu",
    CITYCODE: "TAE",
    CITYNAME: "DAEGU",
    CITYNAME_HEB: "DAEGU",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "TAG",
    AIRPORTNAME: "Tagbilaran",
    CITYCODE: "TAG",
    CITYNAME: "TAGBILARAN",
    CITYNAME_HEB: "\u05d8\u05d0\u05d2\u05d1\u05d9\u05dc\u05e8\u05d0\u05df",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "TAH",
    AIRPORTNAME: "Tanna",
    CITYCODE: "TAH",
    CITYNAME: "TANNA",
    CITYNAME_HEB: "TANNA",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "TAI",
    AIRPORTNAME: "Al Janad",
    CITYCODE: "TAI",
    CITYNAME: "TAIZ",
    CITYNAME_HEB: "TAIZ",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "TAJ",
    AIRPORTNAME: "Tadji",
    CITYCODE: "TAJ",
    CITYNAME: "TADJI",
    CITYNAME_HEB: "TADJI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TAK",
    AIRPORTNAME: "Takamatsu",
    CITYCODE: "TAK",
    CITYNAME: "TAKAMATSU",
    CITYNAME_HEB: "\u05d8\u05d0\u05e7\u05de\u05d0\u05d8\u05e1\u05d5",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "TAL",
    AIRPORTNAME: "Ralph Calhoun",
    CITYCODE: "TAL",
    CITYNAME: "TANANA",
    CITYNAME_HEB: "Ralph Calhoun",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TAM",
    AIRPORTNAME: "Gen F Javier Mina",
    CITYCODE: "TAM",
    CITYNAME: "TAMPICO",
    CITYNAME_HEB: "TAMPICO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "BLS",
    AIRPORTNAME: "Bollon",
    CITYCODE: "BLS",
    CITYNAME: "BOLLON",
    CITYNAME_HEB: "BOLLON",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TAN",
    AIRPORTNAME: "Tangalooma",
    CITYCODE: "TAN",
    CITYNAME: "TANGALOOMA",
    CITYNAME_HEB: "TANGALOOMA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TAO",
    AIRPORTNAME: "Qingdao",
    CITYCODE: "TAO",
    CITYNAME: "QINGDAO",
    CITYNAME_HEB: "QINGDAO",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "TAP",
    AIRPORTNAME: "International",
    CITYCODE: "TAP",
    CITYNAME: "TAPACHULA",
    CITYNAME_HEB: "TAPACHULA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "TAQ",
    AIRPORTNAME: "Tarcoola",
    CITYCODE: "TAQ",
    CITYNAME: "TARCOOLA",
    CITYNAME_HEB: "TARCOOLA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TAR",
    AIRPORTNAME: "M. A. Grottag",
    CITYCODE: "TAR",
    CITYNAME: "TARANTO",
    CITYNAME_HEB: "TARANTO",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TAS",
    AIRPORTNAME: "Vostochny",
    CITYCODE: "TAS",
    CITYNAME: "TASHKENT",
    CITYNAME_HEB: "\u05d8\u05e9\u05e7\u05e0\u05d8",
    COUNTRYCODE: "UZ",
    COUNTRYNAME: "UZBEKISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d6\u05d1\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "TAT",
    AIRPORTNAME: "Poprad/Tatry",
    CITYCODE: "TAT",
    CITYNAME: "TATRA",
    CITYNAME_HEB: "\u05d4\u05e8\u05d9 \u05d4\u05d8\u05d8\u05e8\u05d4",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TAU",
    AIRPORTNAME: "Tauramena",
    CITYCODE: "TAU",
    CITYNAME: "TAURAMENA",
    CITYNAME_HEB: "TAURAMENA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TAV",
    AIRPORTNAME: "Tau",
    CITYCODE: "TAV",
    CITYNAME: "TAU",
    CITYNAME_HEB: "TAU",
    COUNTRYCODE: "AS",
    COUNTRYNAME: "AMERICAN SAMOA",
    COUNTRYNAME_HEB:
      "\u05e1\u05de\u05d5\u05d0\u05d4 \u05d4\u05de\u05e2\u05e8\u05d1\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "TAW",
    AIRPORTNAME: "Tacuarembo",
    CITYCODE: "TAW",
    CITYNAME: "TACUAREMBO",
    CITYNAME_HEB: "TACUAREMBO",
    COUNTRYCODE: "UY",
    COUNTRYNAME: "URUGUAY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e8\u05d5\u05d2\u05d0\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "BLT",
    AIRPORTNAME: null,
    CITYCODE: "BLT",
    CITYNAME: "BALTIC SEA",
    CITYNAME_HEB: "\u05d4\u05d9\u05dd \u05d4\u05d1\u05dc\u05d8\u05d9",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TAX",
    AIRPORTNAME: "Taliabu",
    CITYCODE: "TAX",
    CITYNAME: "TALIABU",
    CITYNAME_HEB: "TALIABU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TAY",
    AIRPORTNAME: "Tartu",
    CITYCODE: "TAY",
    CITYNAME: "TARTU",
    CITYNAME_HEB: "TARTU",
    COUNTRYCODE: "EE",
    COUNTRYNAME: "ESTONIA",
    COUNTRYNAME_HEB: "\u05d0\u05e1\u05d8\u05d5\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TAZ",
    AIRPORTNAME: "Dashoguz",
    CITYCODE: "TAZ",
    CITYNAME: "DASHOGUZ",
    CITYNAME_HEB: "DASHOGUZ",
    COUNTRYCODE: "TM",
    COUNTRYNAME: "TURKMENISTAN",
    COUNTRYNAME_HEB:
      "\u05d8\u05d5\u05e8\u05e7\u05d9\u05de\u05d9\u05e0\u05d9\u05e1\u05d8\u05d0\u05df",
  },
  {
    AIRPORTCODE: "TBA",
    AIRPORTNAME: null,
    CITYCODE: "TBA",
    CITYNAME: "TABA",
    CITYNAME_HEB: "\u05d8\u05d0\u05d1\u05d4",
    COUNTRYCODE: "EG",
    COUNTRYNAME: "EGYPT",
    COUNTRYNAME_HEB: "\u05de\u05e6\u05e8\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "TBB",
    AIRPORTNAME: "Tuy Hoa",
    CITYCODE: "TBB",
    CITYNAME: "TUY HOA",
    CITYNAME_HEB: "TUY HOA",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "TBC",
    AIRPORTNAME: "Tuba City",
    CITYCODE: "TBC",
    CITYNAME: "TUBA CITY",
    CITYNAME_HEB: "TUBA CITY",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TBD",
    AIRPORTNAME: "Timbiqui",
    CITYCODE: "TBD",
    CITYNAME: "TIMBIQUI",
    CITYNAME_HEB: "TIMBIQUI",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TBE",
    AIRPORTNAME: "Timbunke",
    CITYCODE: "TBE",
    CITYNAME: "TIMBUNKE",
    CITYNAME_HEB: "TIMBUNKE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TBF",
    AIRPORTNAME: "Tabiteuea North",
    CITYCODE: "TBF",
    CITYNAME: "TABITEUEA NORTH",
    CITYNAME_HEB: "TABITEUEA NORTH",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "TBG",
    AIRPORTNAME: "Tabubil",
    CITYCODE: "TBG",
    CITYNAME: "TABUBIL",
    CITYNAME_HEB: "TABUBIL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WDY",
    AIRPORTNAME: "Kodiak Fss",
    CITYCODE: "ADQ",
    CITYNAME: "KODIAK",
    CITYNAME_HEB: "Kodiak Fss",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BLU",
    AIRPORTNAME: "Blue Canyon",
    CITYCODE: "BLU",
    CITYNAME: "BLUE CANYON",
    CITYNAME_HEB: "BLUE CANYON",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TBH",
    AIRPORTNAME: "Tablas",
    CITYCODE: "TBH",
    CITYNAME: "TABLAS",
    CITYNAME_HEB: "TABLAS",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "TBI",
    AIRPORTNAME: "The Bight",
    CITYCODE: "TBI",
    CITYNAME: "THE BIGHT",
    CITYNAME_HEB: "THE BIGHT",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "TBJ",
    AIRPORTNAME: "Tabarka",
    CITYCODE: "TBJ",
    CITYNAME: "TABARKA",
    CITYNAME_HEB: "TABARKA",
    COUNTRYCODE: "TN",
    COUNTRYNAME: "TUNISIA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d9\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TBK",
    AIRPORTNAME: "Timber Creek",
    CITYCODE: "TBK",
    CITYNAME: "TIMBER CREEK",
    CITYNAME_HEB: "TIMBER CREEK",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TBL",
    AIRPORTNAME: "Tableland",
    CITYCODE: "TBL",
    CITYNAME: "TABLELAND",
    CITYNAME_HEB: "TABLELAND",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TBM",
    AIRPORTNAME: "Tumbang Samba",
    CITYCODE: "TBM",
    CITYNAME: "TUMBANG SAMBA",
    CITYNAME_HEB: "TUMBANG SAMBA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TBN",
    AIRPORTNAME: "Forney AAF",
    CITYCODE: "TBN",
    CITYNAME: "FORT LEONARD WOOD",
    CITYNAME_HEB: "FORT LEONARD WOOD",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TBO",
    AIRPORTNAME: "Tabora",
    CITYCODE: "TBO",
    CITYNAME: "TABORA",
    CITYNAME_HEB: "TABORA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TBP",
    AIRPORTNAME: "Tumbes",
    CITYCODE: "TBP",
    CITYNAME: "TUMBES",
    CITYNAME_HEB: "TUMBES",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "TBQ",
    AIRPORTNAME: "Tarabo",
    CITYCODE: "TBQ",
    CITYNAME: "TARABO",
    CITYNAME_HEB: "TARABO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BLV",
    AIRPORTNAME: "Scott AFB/Mid America",
    CITYCODE: "BLV",
    CITYNAME: "BELLEVILLE",
    CITYNAME_HEB: "BELLEVILLE",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TBR",
    AIRPORTNAME: "Municipal",
    CITYCODE: "TBR",
    CITYNAME: "STATESBORO",
    CITYNAME_HEB: "STATESBORO",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "TBS",
    AIRPORTNAME: "Novo Alexeyevka",
    CITYCODE: "TBS",
    CITYNAME: "TBILISI",
    CITYNAME_HEB: "\u05d8\u05d1\u05d9\u05dc\u05d9\u05e1\u05d9",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TBT",
    AIRPORTNAME: null,
    CITYCODE: "TBT",
    CITYNAME: "TABATINGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TBU",
    AIRPORTNAME: "Fua'Amotu Internationa",
    CITYCODE: "TBU",
    CITYNAME: "TONGATAPU",
    CITYNAME_HEB: "TONGATAPU",
    COUNTRYCODE: "TO",
    COUNTRYNAME: "TONGA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d2\u05d4",
  },
  {
    AIRPORTCODE: "TBV",
    AIRPORTNAME: "Tabal",
    CITYCODE: "TBV",
    CITYNAME: "TABAL",
    CITYNAME_HEB: "TABAL",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "TBW",
    AIRPORTNAME: "Tambov",
    CITYCODE: "TBW",
    CITYNAME: "TAMBOV",
    CITYNAME_HEB: "TAMBOV",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TBX",
    AIRPORTNAME: "Taabo",
    CITYCODE: "TBX",
    CITYNAME: "TAABO",
    CITYNAME_HEB: "TAABO",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "TBY",
    AIRPORTNAME: "Tsabong",
    CITYCODE: "TBY",
    CITYNAME: "TSABONG",
    CITYNAME_HEB: "TSABONG",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "TCA",
    AIRPORTNAME: "Tennant Creek",
    CITYCODE: "TCA",
    CITYNAME: "TENNANT CREEK",
    CITYNAME_HEB: "TENNANT CREEK",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TCB",
    AIRPORTNAME: "Treasure Cay",
    CITYCODE: "TCB",
    CITYNAME: "TREASURE CAY",
    CITYNAME_HEB: "TREASURE CAY",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "BLW",
    AIRPORTNAME: "Bellows Field",
    CITYCODE: "BLW",
    CITYNAME: "WAIMANALO",
    CITYNAME_HEB: "WAIMANALO",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TCC",
    AIRPORTNAME: "Tucumcari",
    CITYCODE: "TCC",
    CITYNAME: "TUCUMCARI",
    CITYNAME_HEB: "TUCUMCARI",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TCD",
    AIRPORTNAME: "Tarapaca",
    CITYCODE: "TCD",
    CITYNAME: "TARAPACA",
    CITYNAME_HEB: "TARAPACA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TCE",
    AIRPORTNAME: "Tulcea",
    CITYCODE: "TCE",
    CITYNAME: "TULCEA",
    CITYNAME_HEB: "TULCEA",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TCF",
    AIRPORTNAME: "Tocoa",
    CITYCODE: "TCF",
    CITYNAME: "TOCOA",
    CITYNAME_HEB: "TOCOA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "TCG",
    AIRPORTNAME: "Tacheng",
    CITYCODE: "TCG",
    CITYNAME: "TACHENG",
    CITYNAME_HEB: "TACHENG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "TCH",
    AIRPORTNAME: "Tchibanga",
    CITYCODE: "TCH",
    CITYNAME: "TCHIBANGA",
    CITYNAME_HEB: "TCHIBANGA",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "TCI",
    AIRPORTNAME: "Metropolitan Area",
    CITYCODE: "TCI",
    CITYNAME: "TENERIFE",
    CITYNAME_HEB: "\u05d8\u05e0\u05e8\u05d9\u05e3",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "TFS",
    AIRPORTNAME: "Sur Reina Sofia",
    CITYCODE: "TCI",
    CITYNAME: "TENERIFE",
    CITYNAME_HEB: "\u05d8\u05e0\u05e8\u05d9\u05e3",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "TFN",
    AIRPORTNAME: "Norte Los Rodeos",
    CITYCODE: "TCI",
    CITYNAME: "TENERIFE",
    CITYNAME_HEB: "\u05d8\u05e0\u05e8\u05d9\u05e3",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "TCJ",
    AIRPORTNAME: "Torembi Airport",
    CITYCODE: "TCJ",
    CITYNAME: "TOREMBI",
    CITYNAME_HEB: "TOREMBI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BLX",
    AIRPORTNAME: "Belluno",
    CITYCODE: "BLX",
    CITYNAME: "BELLUNO",
    CITYNAME_HEB: "BELLUNO",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TCK",
    AIRPORTNAME: "Tinboli Airport",
    CITYCODE: "TCK",
    CITYNAME: "TINBOLI",
    CITYNAME_HEB: "TINBOLI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TCL",
    AIRPORTNAME: "Van De Graaf",
    CITYCODE: "TCL",
    CITYNAME: "TUSCALOOSA",
    CITYNAME_HEB: "Tuscaloosa",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TCN",
    AIRPORTNAME: "Tehuacan",
    CITYCODE: "TCN",
    CITYNAME: "TEHUACAN",
    CITYNAME_HEB: "TEHUACAN",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "TCO",
    AIRPORTNAME: "La Florida",
    CITYCODE: "TCO",
    CITYNAME: "TUMACO",
    CITYNAME_HEB: "TUMACO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TCQ",
    AIRPORTNAME: "Tacna",
    CITYCODE: "TCQ",
    CITYNAME: "TACNA",
    CITYNAME_HEB: "TACNA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "TCR",
    AIRPORTNAME: "Tuticorin",
    CITYCODE: "TCR",
    CITYNAME: "TUTICORIN",
    CITYNAME_HEB: "TUTICORIN",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "TCS",
    AIRPORTNAME: "Municipal",
    CITYCODE: "TCS",
    CITYNAME: "TRUTH OR CONSEQUE",
    CITYNAME_HEB: "TRUTH OR CONSEQUE",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TCT",
    AIRPORTNAME: "Takotna",
    CITYCODE: "TCT",
    CITYNAME: "TAKOTNA",
    CITYNAME_HEB: "Takotna",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TCU",
    AIRPORTNAME: "Thaba Nchu",
    CITYCODE: "TCU",
    CITYNAME: "THABA NCHU",
    CITYNAME_HEB: "THABA NCHU",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "BLY",
    AIRPORTNAME: "Belmullet",
    CITYCODE: "BLY",
    CITYNAME: "BELMULLET",
    CITYNAME_HEB: "BELMULLET",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "TCW",
    AIRPORTNAME: "Tocumwal",
    CITYCODE: "TCW",
    CITYNAME: "TOCUMWAL",
    CITYNAME_HEB: "TOCUMWAL",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TCY",
    AIRPORTNAME: "Terrace Bay",
    CITYCODE: "TCY",
    CITYNAME: "TERRACE BAY",
    CITYNAME_HEB: "TERRACE BAY",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TDA",
    AIRPORTNAME: "Trinidad",
    CITYCODE: "TDA",
    CITYNAME: "TRINIDAD",
    CITYNAME_HEB: "TRINIDAD",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TDB",
    AIRPORTNAME: "Tetabedi",
    CITYCODE: "TDB",
    CITYNAME: "TETABEDI",
    CITYNAME_HEB: "TETABEDI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TDD",
    AIRPORTNAME: "Trinidad",
    CITYCODE: "TDD",
    CITYNAME: "TRINIDAD",
    CITYNAME_HEB: "TRINIDAD",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TDG",
    AIRPORTNAME: "Tandag",
    CITYCODE: "TDG",
    CITYNAME: "TANDAG",
    CITYNAME_HEB: "TANDAG",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "TDJ",
    AIRPORTNAME: null,
    CITYCODE: "TDJ",
    CITYNAME: "TADJOURA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DJ",
    COUNTRYNAME: "DJIBOUTI",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TDK",
    AIRPORTNAME: "Taldy-Kurgan",
    CITYCODE: "TDK",
    CITYNAME: "TALDY KURGAN",
    CITYNAME_HEB: "TALDY KURGAN",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "TDL",
    AIRPORTNAME: null,
    CITYCODE: "TDL",
    CITYNAME: "TANDIL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TDN",
    AIRPORTNAME: "Theda Station",
    CITYCODE: "TDN",
    CITYNAME: "THEDA",
    CITYNAME_HEB: "THEDA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BLZ",
    AIRPORTNAME: "Chileka",
    CITYCODE: "BLZ",
    CITYNAME: "BLANTYRE",
    CITYNAME_HEB: "BLANTYRE",
    COUNTRYCODE: "MW",
    COUNTRYNAME: "MALAWI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "TDO",
    AIRPORTNAME: "Winlock",
    CITYCODE: "TDO",
    CITYNAME: "TOLEDO",
    CITYNAME_HEB: "\u05d8\u05d5\u05dc\u05d3\u05d5",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TDR",
    AIRPORTNAME: "Theodore",
    CITYCODE: "TDR",
    CITYNAME: "THEODORE",
    CITYNAME_HEB: "THEODORE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TDT",
    AIRPORTNAME: "Tanda Tula",
    CITYCODE: "TDT",
    CITYNAME: "TANDA TULA",
    CITYNAME_HEB: "TANDA TULA",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "TDV",
    AIRPORTNAME: "Tanandava",
    CITYCODE: "TDV",
    CITYNAME: "TANANDAVA",
    CITYNAME_HEB: "TANANDAVA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "TEA",
    AIRPORTNAME: "Tela",
    CITYCODE: "TEA",
    CITYNAME: "TELA",
    CITYNAME_HEB: "TELA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "TEB",
    AIRPORTNAME: "Teterboro",
    CITYCODE: "TEB",
    CITYNAME: "TETERBORO",
    CITYNAME_HEB: "TETERBORO",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "TEC",
    AIRPORTNAME: " ",
    CITYCODE: "TEC",
    CITYNAME: "TERME CATEZ",
    CITYNAME_HEB: "\u05d8\u05e8\u05de\u05d4 \u05e6\u05d0\u05d8\u05d6",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TED",
    AIRPORTNAME: "Thisted",
    CITYCODE: "TED",
    CITYNAME: "THISTED",
    CITYNAME_HEB: "THISTED",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "TEE",
    AIRPORTNAME: "Tbessa",
    CITYCODE: "TEE",
    CITYNAME: "TBESSA",
    CITYNAME_HEB: "TBESSA",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TEF",
    AIRPORTNAME: "Telfer",
    CITYCODE: "TEF",
    CITYNAME: "TELFER",
    CITYNAME_HEB: "TELFER",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BMB",
    AIRPORTNAME: "Bumba",
    CITYCODE: "BMB",
    CITYNAME: "BUMBA",
    CITYNAME_HEB: "BUMBA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "TEG",
    AIRPORTNAME: "Tenkodogo",
    CITYCODE: "TEG",
    CITYNAME: "TENKODOGO",
    CITYNAME_HEB: "TENKODOGO",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "TEH",
    AIRPORTNAME: "Tetlin",
    CITYCODE: "TEH",
    CITYNAME: "TETLIN",
    CITYNAME_HEB: "Tetlin",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TEI",
    AIRPORTNAME: "Tezu",
    CITYCODE: "TEI",
    CITYNAME: "TEZU",
    CITYNAME_HEB: "TEZU",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "TEK",
    AIRPORTNAME: " ",
    CITYCODE: "TEK",
    CITYNAME: "TERME KRKA",
    CITYNAME_HEB: "\u05d8\u05e8\u05de\u05d4 \u05e7\u05e8\u05e7\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TEL",
    AIRPORTNAME: null,
    CITYCODE: "TEL",
    CITYNAME: "TERME LENDAVA",
    CITYNAME_HEB: "\u05d8\u05e8\u05de\u05d4 \u05dc\u05e0\u05d3\u05d1\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TEM",
    AIRPORTNAME: "Temora",
    CITYCODE: "TEM",
    CITYNAME: "TEMORA",
    CITYNAME_HEB: "TEMORA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TEN",
    AIRPORTNAME: "Tongren",
    CITYCODE: "TEN",
    CITYNAME: "TONGREN",
    CITYNAME_HEB: "TONGREN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "TEO",
    AIRPORTNAME: "Terapo",
    CITYCODE: "TEO",
    CITYNAME: "TERAPO",
    CITYNAME_HEB: "TERAPO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TEP",
    AIRPORTNAME: " ",
    CITYCODE: "TEP",
    CITYNAME: "TEPLICE",
    CITYNAME_HEB: "\u05d8\u05e4\u05dc\u05d9\u05e6\u05d4",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TEQ",
    AIRPORTNAME: "Corlu",
    CITYCODE: "TEQ",
    CITYNAME: "TEKIRDAG",
    CITYNAME_HEB: "TEKIRDAG",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BMC",
    AIRPORTNAME: "Brigham City",
    CITYCODE: "BMC",
    CITYNAME: "BRIGHAM CITY",
    CITYNAME_HEB: "BRIGHAM CITY",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TER",
    AIRPORTNAME: "Lajes",
    CITYCODE: "TER",
    CITYNAME: "TERCEIRA ISLAND",
    CITYNAME_HEB: "TERCEIRA ISLAND",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "TES",
    AIRPORTNAME: "Tessenei",
    CITYCODE: "TES",
    CITYNAME: "TESSENEI",
    CITYNAME_HEB: "TESSENEI",
    COUNTRYCODE: "ER",
    COUNTRYNAME: "ERITREA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05d9\u05ea\u05e8\u05d9\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TET",
    AIRPORTNAME: "Matunda",
    CITYCODE: "TET",
    CITYNAME: "TETE",
    CITYNAME_HEB: "TETE",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "TEU",
    AIRPORTNAME: "Manapouri",
    CITYCODE: "TEU",
    CITYNAME: "TE ANAU",
    CITYNAME_HEB: "TE ANAU",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "TEX",
    AIRPORTNAME: "Telluride",
    CITYCODE: "TEX",
    CITYNAME: "TELLURIDE",
    CITYNAME_HEB: "TELLURIDE",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TEY",
    AIRPORTNAME: "Thingeyri",
    CITYCODE: "TEY",
    CITYNAME: "THINGEYRI",
    CITYNAME_HEB: "THINGEYRI",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "TEZ",
    AIRPORTNAME: "Salonibari",
    CITYCODE: "TEZ",
    CITYNAME: "TEZPUR",
    CITYNAME_HEB: "TEZPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "TFB",
    AIRPORTNAME: "Tifalmin",
    CITYCODE: "TFB",
    CITYNAME: "TIFALMIN",
    CITYNAME_HEB: "TIFALMIN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TFF",
    AIRPORTNAME: null,
    CITYCODE: "TFF",
    CITYNAME: "TEFE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TFI",
    AIRPORTNAME: "Tufi",
    CITYCODE: "TFI",
    CITYNAME: "TUFI",
    CITYNAME_HEB: "TUFI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BMD",
    AIRPORTNAME: "Belo",
    CITYCODE: "BMD",
    CITYNAME: "BELO",
    CITYNAME_HEB: "BELO",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "TFL",
    AIRPORTNAME: null,
    CITYCODE: "TFL",
    CITYNAME: "TEOFILO OTONI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TFM",
    AIRPORTNAME: "Telefomin",
    CITYCODE: "TFM",
    CITYNAME: "TELEFOMIN",
    CITYNAME_HEB: "TELEFOMIN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TFT",
    AIRPORTNAME: "Taftan",
    CITYCODE: "TFT",
    CITYNAME: "TAFTAN",
    CITYNAME_HEB: "TAFTAN",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "TFY",
    AIRPORTNAME: "Tarfaya",
    CITYCODE: "TFY",
    CITYNAME: "TARFAYA",
    CITYNAME_HEB: "TARFAYA",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "TGA",
    AIRPORTNAME: null,
    CITYCODE: "TGA",
    CITYNAME: "TENGAH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SG",
    COUNTRYNAME: "SINGAPORE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TGB",
    AIRPORTNAME: "Tagbita",
    CITYCODE: "TGB",
    CITYNAME: "TAGBITA",
    CITYNAME_HEB: "TAGBITA",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "TGE",
    AIRPORTNAME: "Sharpe Field",
    CITYCODE: "TGE",
    CITYNAME: "TUSKEGEE",
    CITYNAME_HEB: "Tuskegee",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TGF",
    AIRPORTNAME: "Tignes",
    CITYCODE: "TGF",
    CITYNAME: "TIGNES",
    CITYNAME_HEB: "\u05d8\u05d9\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "TGG",
    AIRPORTNAME: "Sultan Mahmood",
    CITYCODE: "TGG",
    CITYNAME: "KUALA TERENGGANU",
    CITYNAME_HEB: "KUALA TERENGGANU",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BME",
    AIRPORTNAME: "Broome",
    CITYCODE: "BME",
    CITYNAME: "BROOME",
    CITYNAME_HEB: "BROOME",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RST",
    AIRPORTNAME: "Municipal",
    CITYCODE: "RST",
    CITYNAME: "ROCHESTER",
    CITYNAME_HEB: "ROCHESTER",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RSU",
    AIRPORTNAME: "Yeosu",
    CITYCODE: "RSU",
    CITYNAME: "YEOSU",
    CITYNAME_HEB: "YEOSU",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "RSX",
    AIRPORTNAME: "Rouses Point",
    CITYCODE: "RSX",
    CITYNAME: "ROUSES POINT",
    CITYNAME_HEB: "ROUSES POINT",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RTA",
    AIRPORTNAME: "Rotuma Island",
    CITYCODE: "RTA",
    CITYNAME: "ROTUMA ISLAND",
    CITYNAME_HEB: "ROTUMA ISLAND",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "RTB",
    AIRPORTNAME: "Roatan",
    CITYCODE: "RTB",
    CITYNAME: "ROATAN",
    CITYNAME_HEB: "\u05e8\u05d5\u05d0\u05d8\u05df",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "RTC",
    AIRPORTNAME: "Ratnagiri",
    CITYCODE: "RTC",
    CITYNAME: "RATNAGIRI",
    CITYNAME_HEB: "RATNAGIRI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "RTD",
    AIRPORTNAME: "Rotunda",
    CITYCODE: "RTD",
    CITYNAME: "ROTUNDA",
    CITYNAME_HEB: "ROTUNDA",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RTE",
    AIRPORTNAME: "Marguerite Bay",
    CITYCODE: "RTE",
    CITYNAME: "MARGUERITE BAY",
    CITYNAME_HEB: "Marguerite Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ADL",
    AIRPORTNAME: "Adelaide International Airport",
    CITYCODE: "ADL",
    CITYNAME: "ADELAIDE",
    CITYNAME_HEB: "\u05d0\u05d3\u05dc\u05d9\u05d9\u05d3",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BIL",
    AIRPORTNAME: "Billings",
    CITYCODE: "BIL",
    CITYNAME: "BILLINGS",
    CITYNAME_HEB: "BILLINGS",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RTG",
    AIRPORTNAME: "Ruteng",
    CITYCODE: "RTG",
    CITYNAME: "RUTENG",
    CITYNAME_HEB: "RUTENG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RTI",
    AIRPORTNAME: "Roti",
    CITYCODE: "RTI",
    CITYNAME: "ROTI",
    CITYNAME_HEB: "ROTI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RTL",
    AIRPORTNAME: "Spirit Lake",
    CITYCODE: "RTL",
    CITYNAME: "SPIRIT LAKE",
    CITYNAME_HEB: "SPIRIT LAKE",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RTM",
    AIRPORTNAME: "Rotterdam",
    CITYCODE: "RTM",
    CITYNAME: "ROTTERDAM",
    CITYNAME_HEB: "\u05e8\u05d5\u05d8\u05e8\u05d3\u05dd",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "RTN",
    AIRPORTNAME: "Crews Field",
    CITYCODE: "RTN",
    CITYNAME: "RATON",
    CITYNAME_HEB: "RATON",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RTP",
    AIRPORTNAME: "Rutland Plains",
    CITYCODE: "RTP",
    CITYNAME: "RUTLAND PLAINS",
    CITYNAME_HEB: "RUTLAND PLAINS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RTS",
    AIRPORTNAME: "Rottnest Island",
    CITYCODE: "RTS",
    CITYNAME: "ROTTNEST ISLAND",
    CITYNAME_HEB: "ROTTNEST ISLAND",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RTW",
    AIRPORTNAME: "Saratov",
    CITYCODE: "RTW",
    CITYNAME: "SARATOV",
    CITYNAME_HEB: "SARATOV",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RTY",
    AIRPORTNAME: "Merty",
    CITYCODE: "RTY",
    CITYNAME: "MERTY",
    CITYNAME_HEB: "MERTY",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BIM",
    AIRPORTNAME: "International",
    CITYCODE: "BIM",
    CITYNAME: "BIMINI",
    CITYNAME_HEB: "BIMINI",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "RUA",
    AIRPORTNAME: "Arua",
    CITYCODE: "RUA",
    CITYNAME: "ARUA",
    CITYNAME_HEB: "ARUA",
    COUNTRYCODE: "UG",
    COUNTRYNAME: "UGANDA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "RUF",
    AIRPORTNAME: "Yuruf",
    CITYCODE: "RUF",
    CITYNAME: "YURUF",
    CITYNAME_HEB: "YURUF",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RUG",
    AIRPORTNAME: "Rugao",
    CITYCODE: "RUG",
    CITYNAME: "RUGAO",
    CITYNAME_HEB: "RUGAO",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "RUI",
    AIRPORTNAME: "Municipal",
    CITYCODE: "RUI",
    CITYNAME: "RUIDOSO",
    CITYNAME_HEB: "RUIDOSO",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RUK",
    AIRPORTNAME: "Rukumkot",
    CITYCODE: "RUK",
    CITYNAME: "RUKUMKOT",
    CITYNAME_HEB: "RUKUMKOT",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "RUM",
    AIRPORTNAME: "Rumjatar",
    CITYCODE: "RUM",
    CITYNAME: "RUMJATAR",
    CITYNAME_HEB: "RUMJATAR",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "RUN",
    AIRPORTNAME: null,
    CITYCODE: "RUN",
    CITYNAME: "SAINT DENIS REUNION",
    CITYNAME_HEB: null,
    COUNTRYCODE: "RE",
    COUNTRYNAME: "REUNION ISLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RUP",
    AIRPORTNAME: "Rupsi",
    CITYCODE: "RUP",
    CITYNAME: "RUPSI",
    CITYNAME_HEB: "RUPSI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "RUR",
    AIRPORTNAME: "Rurutu",
    CITYCODE: "RUR",
    CITYNAME: "RURUTU",
    CITYNAME_HEB: "RURUTU",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "RUS",
    AIRPORTNAME: " ",
    CITYCODE: "RUS",
    CITYNAME: "RUST",
    CITYNAME_HEB: "\u05e8\u05d5\u05e1\u05d8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NSB",
    AIRPORTNAME: "North SPB",
    CITYCODE: "BIM",
    CITYNAME: "BIMINI",
    CITYNAME_HEB: "BIMINI",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "RUT",
    AIRPORTNAME: "Rutland",
    CITYCODE: "RUT",
    CITYNAME: "RUTLAND",
    CITYNAME_HEB: "RUTLAND",
    COUNTRYCODE: "USVT",
    COUNTRYNAME: "VERMONT",
    COUNTRYNAME_HEB:
      '\u05d5\u05d5\u05e8\u05de\u05d5\u05e0\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RUU",
    AIRPORTNAME: "Ruti",
    CITYCODE: "RUU",
    CITYNAME: "RUTI",
    CITYNAME_HEB: "RUTI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "RUV",
    AIRPORTNAME: "Rubelsanto",
    CITYCODE: "RUV",
    CITYNAME: "RUBELSANTO",
    CITYNAME_HEB: "RUBELSANTO",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "RUY",
    AIRPORTNAME: "Copan",
    CITYCODE: "RUY",
    CITYNAME: "COPAN",
    CITYNAME_HEB: "COPAN",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "RVA",
    AIRPORTNAME: "Farafangana",
    CITYCODE: "RVA",
    CITYNAME: "FARAFANGANA",
    CITYNAME_HEB: "FARAFANGANA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "RVC",
    AIRPORTNAME: "Rivercess",
    CITYCODE: "RVC",
    CITYNAME: "RIVERCESS",
    CITYNAME_HEB: "RIVERCESS",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RVD",
    AIRPORTNAME: null,
    CITYCODE: "RVD",
    CITYNAME: "RIO VERDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RVE",
    AIRPORTNAME: "Saravena",
    CITYCODE: "RVE",
    CITYNAME: "SARAVENA",
    CITYNAME_HEB: "SARAVENA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RVK",
    AIRPORTNAME: "Ryumsjoen Airport",
    CITYCODE: "RVK",
    CITYNAME: "ROERVIK",
    CITYNAME_HEB: "ROERVIK",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RVN",
    AIRPORTNAME: "Rovaniemi",
    CITYCODE: "RVN",
    CITYNAME: "ROVANIEMI",
    CITYNAME_HEB: "\u05e8\u05d5\u05d1\u05e0\u05d9\u05d9\u05de\u05d9",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "Finland",
    COUNTRYNAME_HEB: "\u05dc\u05e4\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BIN",
    AIRPORTNAME: " ",
    CITYCODE: "BIN",
    CITYNAME: "BINGEN AM RHEIN",
    CITYNAME_HEB:
      "\u05d1\u05d9\u05e0\u05d2\u05d0\u05df \u05d0\u05de \u05e8\u05d9\u05d9\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RVO",
    AIRPORTNAME: "Reivilo",
    CITYCODE: "RVO",
    CITYNAME: "REIVILO",
    CITYNAME_HEB: "REIVILO",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "RVR",
    AIRPORTNAME: "Green River",
    CITYCODE: "RVR",
    CITYNAME: "GREEN RIVER",
    CITYNAME_HEB: "GREEN RIVER",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RVY",
    AIRPORTNAME: "Rivera",
    CITYCODE: "RVY",
    CITYNAME: "RIVERA",
    CITYNAME_HEB: "RIVERA",
    COUNTRYCODE: "UY",
    COUNTRYNAME: "URUGUAY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e8\u05d5\u05d2\u05d0\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "RWB",
    AIRPORTNAME: "Rowan Bay",
    CITYCODE: "RWB",
    CITYNAME: "ROWAN BAY",
    CITYNAME_HEB: "Rowan Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RWF",
    AIRPORTNAME: "Redwood Falls Municipal",
    CITYCODE: "RWF",
    CITYNAME: "REDWOOD FALLS",
    CITYNAME_HEB: "REDWOOD FALLS",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RWI",
    AIRPORTNAME: "Rocky Mount-Wilson",
    CITYCODE: "RWI",
    CITYNAME: "ROCKY MOUNT",
    CITYNAME_HEB: "ROCKY MOUNT",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RWL",
    AIRPORTNAME: "Rawlins",
    CITYCODE: "RWL",
    CITYNAME: "RAWLINS",
    CITYNAME_HEB: "RAWLINS",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RWN",
    AIRPORTNAME: "Rovno",
    CITYCODE: "RWN",
    CITYNAME: "ROVNO",
    CITYNAME_HEB: "ROVNO",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "RWP",
    AIRPORTNAME: null,
    CITYCODE: "RWP",
    CITYNAME: "RAWALPINDI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RXA",
    AIRPORTNAME: "Raudha",
    CITYCODE: "RXA",
    CITYNAME: "RAUDHA",
    CITYNAME_HEB: "RAUDHA",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "BIO",
    AIRPORTNAME: "Bilbao",
    CITYCODE: "BIO",
    CITYNAME: "BILBAO",
    CITYNAME_HEB: "\u05d1\u05d9\u05dc\u05d1\u05d0\u05d5",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "RXS",
    AIRPORTNAME: "Roxas City",
    CITYCODE: "RXS",
    CITYNAME: "ROXAS CITY",
    CITYNAME_HEB: "ROXAS CITY",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "RYB",
    AIRPORTNAME: "Rybinsk",
    CITYCODE: "RYB",
    CITYNAME: "RYBINSK",
    CITYNAME_HEB: "RYBINSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RYK",
    AIRPORTNAME: "Rahim Yar Khan",
    CITYCODE: "RYK",
    CITYNAME: "RAHIM YAR KHAN",
    CITYNAME_HEB: "RAHIM YAR KHAN",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "RYN",
    AIRPORTNAME: "Medis",
    CITYCODE: "RYN",
    CITYNAME: "ROYAN",
    CITYNAME_HEB: "ROYAN",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "RYO",
    AIRPORTNAME: null,
    CITYCODE: "RYO",
    CITYNAME: "RIO TURBIO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RZA",
    AIRPORTNAME: null,
    CITYCODE: "RZA",
    CITYNAME: "SANTA CRUZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RZE",
    AIRPORTNAME: "Jasionka",
    CITYCODE: "RZE",
    CITYNAME: "RZESZOW",
    CITYNAME_HEB: "\u05d6\u05e9\u05d5\u05d1",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "RZN",
    AIRPORTNAME: "Ryazan",
    CITYCODE: "RZN",
    CITYNAME: "RYAZAN",
    CITYNAME_HEB: "RYAZAN",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RZZ",
    AIRPORTNAME: "Halifax County",
    CITYCODE: "RZZ",
    CITYNAME: "ROANOKE RAPIDS",
    CITYNAME_HEB: "ROANOKE RAPIDS",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BIP",
    AIRPORTNAME: "Bulimba",
    CITYCODE: "BIP",
    CITYNAME: "BULIMBA",
    CITYNAME_HEB: "BULIMBA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SAA",
    AIRPORTNAME: " ",
    CITYCODE: "SAA",
    CITYNAME: "SAALBACH",
    CITYNAME_HEB: "\u05e1\u05dc\u05d0\u05d1\u05d0\u05da",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SAB",
    AIRPORTNAME: null,
    CITYCODE: "SAB",
    CITYNAME: "SABA ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BQ",
    COUNTRYNAME: "BONAIRE, SAINT EUSTATIUS AND SABA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MCC",
    AIRPORTNAME: null,
    CITYCODE: "SAC",
    CITYNAME: "SACRAMENTO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SAC",
    AIRPORTNAME: null,
    CITYCODE: "SAC",
    CITYNAME: "SACRAMENTO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SMF",
    AIRPORTNAME: "Metropolitan",
    CITYCODE: "SAC",
    CITYNAME: "SACRAMENTO",
    CITYNAME_HEB: "\u05e1\u05e7\u05e8\u05de\u05e0\u05d8\u05d5",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SAD",
    AIRPORTNAME: "Safford",
    CITYCODE: "SAD",
    CITYNAME: "SAFFORD",
    CITYNAME_HEB: "SAFFORD",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SAE",
    AIRPORTNAME: "Sangir",
    CITYCODE: "SAE",
    CITYNAME: "SANGIR",
    CITYNAME_HEB: "SANGIR",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SAF",
    AIRPORTNAME: "Santa Fe",
    CITYCODE: "SAF",
    CITYNAME: "SANTA FE",
    CITYNAME_HEB: "\u05e1\u05e0\u05d8\u05d4 \u05e4\u05d4",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SAG",
    AIRPORTNAME: "Sagwon",
    CITYCODE: "SAG",
    CITYNAME: "SAGWON",
    CITYNAME_HEB: "Sagwon",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BIQ",
    AIRPORTNAME: "Biarritz Parme",
    CITYCODE: "BIQ",
    CITYNAME: "BIARRITZ",
    CITYNAME_HEB: "BIARRITZ",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "SAH",
    AIRPORTNAME: "Sana'a International",
    CITYCODE: "SAH",
    CITYNAME: "SANAA",
    CITYNAME_HEB: "\u05e6\u05e0\u05e2\u05d4",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "SAI",
    AIRPORTNAME: "San Marino",
    CITYCODE: "SAI",
    CITYNAME: "SAN MARINO",
    CITYNAME_HEB: "\u05e1\u05d0\u05df \u05de\u05e8\u05d9\u05e0\u05d5",
    COUNTRYCODE: "SM",
    COUNTRYNAME: "SAN MARINO",
    COUNTRYNAME_HEB: "\u05e1\u05d0\u05df \u05de\u05e8\u05d9\u05e0\u05d5",
  },
  {
    AIRPORTCODE: "SAJ",
    AIRPORTNAME: "Sirajganj",
    CITYCODE: "SAJ",
    CITYNAME: "SIRAJGANJ",
    CITYNAME_HEB: "SIRAJGANJ",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "SAK",
    AIRPORTNAME: "Saudarkrokur",
    CITYCODE: "SAK",
    CITYNAME: "SAUDARKROKUR",
    CITYNAME_HEB: "SAUDARKROKUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SAL",
    AIRPORTNAME: "Comalapa International",
    CITYCODE: "SAL",
    CITYNAME: "SAN SALVADOR",
    CITYNAME_HEB: "\u05e1\u05d0\u05df \u05e1\u05dc\u05d1\u05d3\u05d5\u05e8",
    COUNTRYCODE: "SV",
    COUNTRYNAME: "EL SALVADOR",
    COUNTRYNAME_HEB: "\u05d0\u05dc \u05e1\u05dc\u05d1\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "CRE",
    AIRPORTNAME: "Grand Strand Airport",
    CITYCODE: "MYR",
    CITYNAME: "MYRTLE BEACH",
    CITYNAME_HEB: "MYRTLE BEACH",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MYS",
    AIRPORTNAME: "Moyale",
    CITYCODE: "MYS",
    CITYNAME: "MOYALE",
    CITYNAME_HEB: "MOYALE",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MYT",
    AIRPORTNAME: "Myitkyina",
    CITYCODE: "MYT",
    CITYNAME: "MYITKYINA",
    CITYNAME_HEB: "MYITKYINA",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "MYU",
    AIRPORTNAME: "Ellis Field",
    CITYCODE: "MYU",
    CITYNAME: "MEKORYUK",
    CITYNAME_HEB: "Ellis Field",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BAB",
    AIRPORTNAME: "Beale AFB",
    CITYCODE: "MYV",
    CITYNAME: "MARYSVILLE",
    CITYNAME_HEB: "MARYSVILLE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MYV",
    AIRPORTNAME: "Yuba County",
    CITYCODE: "MYV",
    CITYNAME: "MARYSVILLE",
    CITYNAME_HEB: "MARYSVILLE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MYW",
    AIRPORTNAME: "Mtwara",
    CITYCODE: "MYW",
    CITYNAME: "MTWARA",
    CITYNAME_HEB: "MTWARA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MYX",
    AIRPORTNAME: "Menyamya",
    CITYCODE: "MYX",
    CITYNAME: "MENYAMYA",
    CITYNAME_HEB: "MENYAMYA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MYY",
    AIRPORTNAME: "Miri",
    CITYCODE: "MYY",
    CITYNAME: "MIRI",
    CITYNAME_HEB: "MIRI",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MYZ",
    AIRPORTNAME: "Monkey Bay",
    CITYCODE: "MYZ",
    CITYNAME: "MONKEY BAY",
    CITYNAME_HEB: "MONKEY BAY",
    COUNTRYCODE: "MW",
    COUNTRYNAME: "MALAWI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "BAG",
    AIRPORTNAME: "Loakan",
    CITYCODE: "BAG",
    CITYNAME: "BAGUIO",
    CITYNAME_HEB: "BAGUIO",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "MZA",
    AIRPORTNAME: "Muzaffarnagar",
    CITYCODE: "MZA",
    CITYNAME: "MUZAFFARNAGAR",
    CITYNAME_HEB: "MUZAFFARNAGAR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "MZB",
    AIRPORTNAME: "Mocimboa Praia",
    CITYCODE: "MZB",
    CITYNAME: "MOCIMBOA PRAIA",
    CITYNAME_HEB: "MOCIMBOA PRAIA",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "MZC",
    AIRPORTNAME: "Mitzic",
    CITYCODE: "MZC",
    CITYNAME: "MITZIC",
    CITYNAME_HEB: "MITZIC",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "MZD",
    AIRPORTNAME: "Mendez",
    CITYCODE: "MZD",
    CITYNAME: "MENDEZ",
    CITYNAME_HEB: "MENDEZ",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "MZE",
    AIRPORTNAME: "Manatee",
    CITYCODE: "MZE",
    CITYNAME: "MANATEE",
    CITYNAME_HEB: "MANATEE",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "MZF",
    AIRPORTNAME: "Mzamba",
    CITYCODE: "MZF",
    CITYNAME: "MZAMBA WILD COAST SUN",
    CITYNAME_HEB: "MZAMBA WILD COAST SUN",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "MZG",
    AIRPORTNAME: "Makung",
    CITYCODE: "MZG",
    CITYNAME: "MAKUNG",
    CITYNAME_HEB: "MAKUNG",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "MZH",
    AIRPORTNAME: "Merzifon",
    CITYCODE: "MZH",
    CITYNAME: "MERZIFON",
    CITYNAME_HEB: "MERZIFON",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MZI",
    AIRPORTNAME: "Mopti",
    CITYCODE: "MZI",
    CITYNAME: "MOPTI",
    CITYNAME_HEB: "MOPTI",
    COUNTRYCODE: "ML",
    COUNTRYNAME: "MALI",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05dc\u05d9",
  },
  {
    AIRPORTCODE: "MZJ",
    AIRPORTNAME: "Marana",
    CITYCODE: "MZJ",
    CITYNAME: "MARANA",
    CITYNAME_HEB: "MARANA",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BAH",
    AIRPORTNAME: "Bahrain International",
    CITYCODE: "BAH",
    CITYNAME: "BAHRAIN",
    CITYNAME_HEB: "\u05d1\u05d7\u05e8\u05d9\u05d9\u05df",
    COUNTRYCODE: "BH",
    COUNTRYNAME: "BAHRAIN",
    COUNTRYNAME_HEB: "\u05d1\u05d7\u05e8\u05d9\u05d9\u05df",
  },
  {
    AIRPORTCODE: "MZK",
    AIRPORTNAME: "Marakei",
    CITYCODE: "MZK",
    CITYNAME: "MARAKEI",
    CITYNAME_HEB: "MARAKEI",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "MZL",
    AIRPORTNAME: "Santaguida",
    CITYCODE: "MZL",
    CITYNAME: "MANIZALES",
    CITYNAME_HEB: "MANIZALES",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MZM",
    AIRPORTNAME: "Frescaty",
    CITYCODE: "MZM",
    CITYNAME: "METZ FRESCATY",
    CITYNAME_HEB: "METZ FRESCATY",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "MZN",
    AIRPORTNAME: "Minj",
    CITYCODE: "MZN",
    CITYNAME: "MINJ",
    CITYNAME_HEB: "MINJ",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "MZO",
    AIRPORTNAME: "Sierra Maestra",
    CITYCODE: "MZO",
    CITYNAME: "MANZANILLO",
    CITYNAME_HEB: "MANZANILLO",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "MZP",
    AIRPORTNAME: "Motueka",
    CITYCODE: "MZP",
    CITYNAME: "MOTUEKA",
    CITYNAME_HEB: "MOTUEKA",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "MZQ",
    AIRPORTNAME: "Mkuze",
    CITYCODE: "MZQ",
    CITYNAME: "MKUZE",
    CITYNAME_HEB: "MKUZE",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "MZR",
    AIRPORTNAME: null,
    CITYCODE: "MZR",
    CITYNAME: "MAZAR I SHARIF",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "MZS",
    AIRPORTNAME: "Mostyn",
    CITYCODE: "MZS",
    CITYNAME: "MOSTYN",
    CITYNAME_HEB: "MOSTYN",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MZT",
    AIRPORTNAME: "Gen. Rafael Buelna",
    CITYCODE: "MZT",
    CITYNAME: "MAZATLAN",
    CITYNAME_HEB: "\u05de\u05e1\u05d8\u05dc\u05d0\u05df",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "BAI",
    AIRPORTNAME: "Buenos Aires",
    CITYCODE: "BAI",
    CITYNAME: "BUENOS AIRES",
    CITYNAME_HEB: "BUENOS AIRES",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "MZU",
    AIRPORTNAME: "Muzaffarpur",
    CITYCODE: "MZU",
    CITYNAME: "MUZAFFARPUR",
    CITYNAME_HEB: "MUZAFFARPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "MZV",
    AIRPORTNAME: "Mulu",
    CITYCODE: "MZV",
    CITYNAME: "MULU",
    CITYNAME_HEB: "MULU",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MZX",
    AIRPORTNAME: "Mena",
    CITYCODE: "MZX",
    CITYNAME: "MENA",
    CITYNAME_HEB: "MENA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MZY",
    AIRPORTNAME: "Mossel Bay",
    CITYCODE: "MZY",
    CITYNAME: "MOSSEL BAY",
    CITYNAME_HEB: "MOSSEL BAY",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "MZZ",
    AIRPORTNAME: "Marion",
    CITYCODE: "MZZ",
    CITYNAME: "MARION",
    CITYNAME_HEB: "MARION",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QRG",
    AIRPORTNAME: null,
    CITYCODE: "QRG",
    CITYNAME: "RAGUSA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRI",
    AIRPORTNAME: null,
    CITYCODE: "QRI",
    CITYNAME: "RIZE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRJ",
    AIRPORTNAME: null,
    CITYCODE: "QRJ",
    CITYNAME: "CARIACICA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRK",
    AIRPORTNAME: null,
    CITYCODE: "QRK",
    CITYNAME: "ARCOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRL",
    AIRPORTNAME: " ",
    CITYCODE: "QRL",
    CITYNAME: "MARBELLA",
    CITYNAME_HEB: "\u05de\u05e8\u05d1\u05dc\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "QRM",
    AIRPORTNAME: null,
    CITYCODE: "QRM",
    CITYNAME: "NARROMINE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRN",
    AIRPORTNAME: null,
    CITYCODE: "QRN",
    CITYNAME: "MURORAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRO",
    AIRPORTNAME: "Queretaro",
    CITYCODE: "QRO",
    CITYNAME: "QUERETARO",
    CITYNAME_HEB: "QUERETARO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "QRP",
    AIRPORTNAME: null,
    CITYCODE: "QRP",
    CITYNAME: "GRAMADO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRR",
    AIRPORTNAME: null,
    CITYCODE: "QRR",
    CITYNAME: "WARREN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRS",
    AIRPORTNAME: " ",
    CITYCODE: "QRS",
    CITYNAME: "RESITA",
    CITYNAME_HEB: "\u05e8\u05d0\u05d9\u05e1\u05d8\u05d4",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BGO",
    AIRPORTNAME: "Flesland",
    CITYCODE: "BGO",
    CITYNAME: "BERGEN",
    CITYNAME_HEB: "\u05d1\u05e8\u05d2\u05df",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QRT",
    AIRPORTNAME: null,
    CITYCODE: "QRT",
    CITYNAME: "RIETI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRU",
    AIRPORTNAME: null,
    CITYCODE: "QRU",
    CITYNAME: "RIO DO SUL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRV",
    AIRPORTNAME: null,
    CITYCODE: "QRV",
    CITYNAME: "ARRAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRW",
    AIRPORTNAME: null,
    CITYCODE: "QRW",
    CITYNAME: "WARRI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRX",
    AIRPORTNAME: null,
    CITYCODE: "QRX",
    CITYNAME: "NAROOMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QRY",
    AIRPORTNAME: null,
    CITYCODE: "QRY",
    CITYNAME: "IKERASAARSUK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSA",
    AIRPORTNAME: " ",
    CITYCODE: "QSA",
    CITYNAME: "SABADELL",
    CITYNAME_HEB: "\u05e1\u05d0\u05d1\u05d3\u05dc",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "QSB",
    AIRPORTNAME: null,
    CITYCODE: "QSB",
    CITYNAME: "SAO BERNARDO DO C",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSC",
    AIRPORTNAME: null,
    CITYCODE: "QSC",
    CITYNAME: "SAO CARLOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGP",
    AIRPORTNAME: "Bongo",
    CITYCODE: "BGP",
    CITYNAME: "BONGO",
    CITYNAME_HEB: "BONGO",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "QSD",
    AIRPORTNAME: null,
    CITYCODE: "QSD",
    CITYNAME: "SAO GONCALO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSE",
    AIRPORTNAME: null,
    CITYCODE: "QSE",
    CITYNAME: "SANTO ANDRE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSF",
    AIRPORTNAME: null,
    CITYCODE: "QSF",
    CITYNAME: "SETIF",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSH",
    AIRPORTNAME: null,
    CITYCODE: "QSH",
    CITYNAME: "SEEHEIM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSI",
    AIRPORTNAME: null,
    CITYCODE: "QSI",
    CITYNAME: "MOSHI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA-UNITED REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSJ",
    AIRPORTNAME: null,
    CITYCODE: "QSJ",
    CITYNAME: "SAO JOAO DEL REI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSK",
    AIRPORTNAME: null,
    CITYCODE: "QSK",
    CITYNAME: "SOUK AHRAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSL",
    AIRPORTNAME: null,
    CITYCODE: "QSL",
    CITYNAME: "SURU LERE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSM",
    AIRPORTNAME: null,
    CITYCODE: "QSM",
    CITYNAME: "UETERSEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSN",
    AIRPORTNAME: null,
    CITYCODE: "QSN",
    CITYNAME: "SAN NICOLAS BARI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGQ",
    AIRPORTNAME: "Big Lake",
    CITYCODE: "BGQ",
    CITYNAME: "BIG LAKE",
    CITYNAME_HEB: "Big Lake",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QSO",
    AIRPORTNAME: null,
    CITYCODE: "QSO",
    CITYNAME: "SOUSSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TN",
    COUNTRYNAME: "TUNISIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSQ",
    AIRPORTNAME: null,
    CITYCODE: "QSQ",
    CITYNAME: "SIDON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LB",
    COUNTRYNAME: "LEBANON",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSR",
    AIRPORTNAME: " ",
    CITYCODE: "QSR",
    CITYNAME: "SALERNO",
    CITYNAME_HEB: "\u05e1\u05d0\u05dc\u05e8\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QSS",
    AIRPORTNAME: null,
    CITYCODE: "QSS",
    CITYNAME: "SASSARI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QST",
    AIRPORTNAME: null,
    CITYCODE: "QST",
    CITYNAME: "IZMIT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSV",
    AIRPORTNAME: " ",
    CITYCODE: "QSV",
    CITYNAME: "SOVATA",
    CITYNAME_HEB: "\u05e1\u05d5\u05d1\u05d8\u05d4",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QSW",
    AIRPORTNAME: null,
    CITYCODE: "QSW",
    CITYNAME: "SWEIDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SY",
    COUNTRYNAME: "SYRIAN ARAB REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSX",
    AIRPORTNAME: null,
    CITYCODE: "QSX",
    CITYNAME: "NEW AMSTERDAM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSY",
    AIRPORTNAME: null,
    CITYCODE: "QSY",
    CITYNAME: "RUEDESHEIM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QSZ",
    AIRPORTNAME: null,
    CITYCODE: "QSZ",
    CITYNAME: "SHIZUOKA CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGR",
    AIRPORTNAME: "International",
    CITYCODE: "BGR",
    CITYNAME: "BANGOR",
    CITYNAME_HEB: "BANGOR",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QTA",
    AIRPORTNAME: null,
    CITYCODE: "QTA",
    CITYNAME: "ATIBAIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTC",
    AIRPORTNAME: " ",
    CITYCODE: "QTC",
    CITYNAME: "CASERTA",
    CITYNAME_HEB: "\u05e7\u05d6\u05e8\u05d8\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QTD",
    AIRPORTNAME: null,
    CITYCODE: "QTD",
    CITYNAME: "TIMBAUBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTE",
    AIRPORTNAME: null,
    CITYCODE: "QTE",
    CITYNAME: "SAO GONCALO AMARA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTG",
    AIRPORTNAME: null,
    CITYCODE: "QTG",
    CITYNAME: "TUPI PAULISTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTH",
    AIRPORTNAME: null,
    CITYCODE: "QTH",
    CITYNAME: "THREDBO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTI",
    AIRPORTNAME: null,
    CITYCODE: "QTI",
    CITYNAME: "TERMINI IMERE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTJ",
    AIRPORTNAME: null,
    CITYCODE: "QTJ",
    CITYNAME: "CHARTRES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTK",
    AIRPORTNAME: null,
    CITYCODE: "QTK",
    CITYNAME: "ROTHENBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGT",
    AIRPORTNAME: "Bagdad",
    CITYCODE: "BGT",
    CITYNAME: "BAGDAD",
    CITYNAME_HEB: "BAGDAD",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QTL",
    AIRPORTNAME: null,
    CITYCODE: "QTL",
    CITYNAME: "CARATINGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTM",
    AIRPORTNAME: null,
    CITYCODE: "QTM",
    CITYNAME: "TOMAKOMAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTN",
    AIRPORTNAME: null,
    CITYCODE: "QTN",
    CITYNAME: "SAN ANTONIO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTO",
    AIRPORTNAME: null,
    CITYCODE: "QTO",
    CITYNAME: "SKITUBE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTP",
    AIRPORTNAME: null,
    CITYCODE: "QTP",
    CITYNAME: "TANA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTQ",
    AIRPORTNAME: null,
    CITYCODE: "QTQ",
    CITYNAME: "TRENTO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTR",
    AIRPORTNAME: null,
    CITYCODE: "QTR",
    CITYNAME: "TARTOUS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SY",
    COUNTRYNAME: "SYRIAN ARAB REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTS",
    AIRPORTNAME: null,
    CITYCODE: "QTS",
    CITYNAME: "ENGLEWOOD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTU",
    AIRPORTNAME: null,
    CITYCODE: "QTU",
    CITYNAME: "ITU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ADG",
    AIRPORTNAME: "Adrian Lenawee County",
    CITYCODE: "ADG",
    CITYNAME: "ADRIAN",
    CITYNAME_HEB: "Adrian Lenawee County",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BGU",
    AIRPORTNAME: null,
    CITYCODE: "BGU",
    CITYNAME: "BANGASSOU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTW",
    AIRPORTNAME: null,
    CITYCODE: "QTW",
    CITYNAME: "DAEJEON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTX",
    AIRPORTNAME: null,
    CITYCODE: "QTX",
    CITYNAME: "ARBATAX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTY",
    AIRPORTNAME: null,
    CITYCODE: "QTY",
    CITYNAME: "TSU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QTZ",
    AIRPORTNAME: null,
    CITYCODE: "QTZ",
    CITYNAME: "COATZACOALCOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUA",
    AIRPORTNAME: null,
    CITYCODE: "QUA",
    CITYNAME: "PUTTGARDEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUB",
    AIRPORTNAME: "Ubari",
    CITYCODE: "QUB",
    CITYNAME: "UBARI",
    CITYNAME_HEB: "UBARI",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "QUC",
    AIRPORTNAME: null,
    CITYCODE: "QUC",
    CITYNAME: "PUERTO LA CRUZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUE",
    AIRPORTNAME: null,
    CITYCODE: "QUE",
    CITYNAME: "QULLISSAT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUG",
    AIRPORTNAME: null,
    CITYCODE: "QUG",
    CITYNAME: "CHICHESTER GOODWOOD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUI",
    AIRPORTNAME: null,
    CITYCODE: "QUI",
    CITYNAME: "CHUQUICAMATA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGV",
    AIRPORTNAME: null,
    CITYCODE: "BGV",
    CITYNAME: "BENTO GONCALVES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUJ",
    AIRPORTNAME: null,
    CITYCODE: "QUJ",
    CITYNAME: "UIJEONGBU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUL",
    AIRPORTNAME: null,
    CITYCODE: "QUL",
    CITYNAME: "ULM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUN",
    AIRPORTNAME: null,
    CITYCODE: "QUN",
    CITYNAME: "CHUNCHEON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUO",
    AIRPORTNAME: null,
    CITYCODE: "QUO",
    CITYNAME: "UYO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUP",
    AIRPORTNAME: null,
    CITYCODE: "QUP",
    CITYNAME: "SAQQAQ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUQ",
    AIRPORTNAME: null,
    CITYCODE: "QUQ",
    CITYNAME: "CACERES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUR",
    AIRPORTNAME: null,
    CITYCODE: "QUR",
    CITYNAME: "MURIAE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUS",
    AIRPORTNAME: null,
    CITYCODE: "QUS",
    CITYNAME: "GUSAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUT",
    AIRPORTNAME: null,
    CITYCODE: "QUT",
    CITYNAME: "UTSUNOMIYA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGX",
    AIRPORTNAME: null,
    CITYCODE: "BGX",
    CITYNAME: "BAGE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUU",
    AIRPORTNAME: null,
    CITYCODE: "QUU",
    CITYNAME: "CHUNG-MU CITY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUV",
    AIRPORTNAME: null,
    CITYCODE: "QUV",
    CITYNAME: "AAPPILATTOQ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUW",
    AIRPORTNAME: null,
    CITYCODE: "QUW",
    CITYNAME: "AMMASSIVIK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUX",
    AIRPORTNAME: null,
    CITYCODE: "QUX",
    CITYNAME: "CAUDEBEC EN CAUX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUY",
    AIRPORTNAME: null,
    CITYCODE: "QUY",
    CITYNAME: "WYTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QUZ",
    AIRPORTNAME: " ",
    CITYCODE: "QUZ",
    CITYNAME: "PUERTO DE LA LUZ",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05d3\u05d4 \u05dc\u05d4 \u05dc\u05d5\u05d6",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "QVA",
    AIRPORTNAME: " ",
    CITYCODE: "QVA",
    CITYNAME: "VARESE",
    CITYNAME_HEB: "\u05d5\u05d0\u05e8\u05d0\u05d6\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QVB",
    AIRPORTNAME: null,
    CITYCODE: "QVB",
    CITYNAME: "UNIAO DA VITORIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVC",
    AIRPORTNAME: null,
    CITYCODE: "QVC",
    CITYNAME: "VICOSA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVD",
    AIRPORTNAME: null,
    CITYCODE: "QVD",
    CITYNAME: "SALO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BGZ",
    AIRPORTNAME: "Braga",
    CITYCODE: "BGZ",
    CITYNAME: "BRAGA",
    CITYNAME_HEB: "\u05d1\u05e8\u05d0\u05d2\u05d4",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "QVE",
    AIRPORTNAME: null,
    CITYCODE: "QVE",
    CITYNAME: "FORSSA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVF",
    AIRPORTNAME: null,
    CITYCODE: "QVF",
    CITYNAME: "KARKKILA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVG",
    AIRPORTNAME: null,
    CITYCODE: "QVG",
    CITYNAME: "VILGENIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVH",
    AIRPORTNAME: null,
    CITYCODE: "QVH",
    CITYNAME: "VILA VELHA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVI",
    AIRPORTNAME: null,
    CITYCODE: "QVI",
    CITYNAME: "VALBONNE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVJ",
    AIRPORTNAME: " ",
    CITYCODE: "QVJ",
    CITYNAME: "VRAJDEBNA",
    CITYNAME_HEB: "\u05d5\u05e8\u05d0\u05d2\u05d1\u05e0\u05d4",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QVK",
    AIRPORTNAME: null,
    CITYCODE: "QVK",
    CITYNAME: "VALKEAKOSKI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVL",
    AIRPORTNAME: null,
    CITYCODE: "QVL",
    CITYNAME: "VICTORIA ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVM",
    AIRPORTNAME: null,
    CITYCODE: "QVM",
    CITYNAME: "HAMEENLINNA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVN",
    AIRPORTNAME: null,
    CITYCODE: "QVN",
    CITYNAME: "AVELLINO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BHA",
    AIRPORTNAME: "Bahia De Caraquez",
    CITYCODE: "BHA",
    CITYNAME: "BAHIA DE CARAQUEZ",
    CITYNAME_HEB: "BAHIA DE CARAQUEZ",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "QVO",
    AIRPORTNAME: null,
    CITYCODE: "QVO",
    CITYNAME: "HAVOEYSUND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVP",
    AIRPORTNAME: null,
    CITYCODE: "QVP",
    CITYNAME: "AVARE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVQ",
    AIRPORTNAME: null,
    CITYCODE: "QVQ",
    CITYNAME: "VERDEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVR",
    AIRPORTNAME: null,
    CITYCODE: "QVR",
    CITYNAME: "VOLTA REDONDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVS",
    AIRPORTNAME: null,
    CITYCODE: "QVS",
    CITYNAME: "TERVAKOSKI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVT",
    AIRPORTNAME: null,
    CITYCODE: "QVT",
    CITYNAME: "RIIHIMAKI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVU",
    AIRPORTNAME: null,
    CITYCODE: "QVU",
    CITYNAME: "VADUZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LI",
    COUNTRYNAME: "LIECHTENSTEIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVV",
    AIRPORTNAME: null,
    CITYCODE: "QVV",
    CITYNAME: "HEINOLA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVW",
    AIRPORTNAME: null,
    CITYCODE: "QVW",
    CITYNAME: "KOTKA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QVX",
    AIRPORTNAME: null,
    CITYCODE: "QVX",
    CITYNAME: "GHAZAOUET",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BHB",
    AIRPORTNAME: "Bar Harbour",
    CITYCODE: "BHB",
    CITYNAME: "BAR HARBOR",
    CITYNAME_HEB: "BAR HARBOR",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QVY",
    AIRPORTNAME: null,
    CITYCODE: "QVY",
    CITYNAME: "KOUVOLA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UTI",
    AIRPORTNAME: "Utti",
    CITYCODE: "QVY",
    CITYNAME: "KOUVOLA",
    CITYNAME_HEB: "KOUVOLA",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "QVZ",
    AIRPORTNAME: null,
    CITYCODE: "QVZ",
    CITYNAME: "HAMINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SSA",
    AIRPORTNAME: null,
    CITYCODE: "SSA",
    CITYNAME: "SALVADOR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SSC",
    AIRPORTNAME: null,
    CITYCODE: "SSC",
    CITYNAME: "SUMTER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SUM",
    AIRPORTNAME: "Municipal",
    CITYCODE: "SSC",
    CITYNAME: "SUMTER",
    CITYNAME_HEB: "SUMTER",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BKW",
    AIRPORTNAME: "Beckley",
    CITYCODE: "BKW",
    CITYNAME: "BECKLEY",
    CITYNAME_HEB: "BECKLEY",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "SSE",
    AIRPORTNAME: "Sholapur",
    CITYCODE: "SSE",
    CITYNAME: "SHOLAPUR",
    CITYNAME_HEB: "SHOLAPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "SSG",
    AIRPORTNAME: null,
    CITYCODE: "SSG",
    CITYNAME: "MALABO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GQ",
    COUNTRYNAME: "EQUATORIAL GUINEA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SSH",
    AIRPORTNAME: " ",
    CITYCODE: "SSH",
    CITYNAME: "SHARM EL SHEIK",
    CITYNAME_HEB: "\u05e9\u05d0\u05e8\u05dd \u05d0 \u05e9\u05d9\u05d9\u05d7",
    COUNTRYCODE: "EG",
    COUNTRYNAME: "EGYPT",
    COUNTRYNAME_HEB: "\u05de\u05e6\u05e8\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "SSJ",
    AIRPORTNAME: "Stokka",
    CITYCODE: "SSJ",
    CITYNAME: "SANDNESSJOEN",
    CITYNAME_HEB: "SANDNESSJOEN",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SSK",
    AIRPORTNAME: "Sturt Creek",
    CITYCODE: "SSK",
    CITYNAME: "STURT CREEK",
    CITYNAME_HEB: "STURT CREEK",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SSL",
    AIRPORTNAME: "Santa Rosalia",
    CITYCODE: "SSL",
    CITYNAME: "SANTA ROSALIA",
    CITYNAME_HEB: "SANTA ROSALIA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CIU",
    AIRPORTNAME: null,
    CITYCODE: "SSM",
    CITYNAME: "SAULT STE MARIE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BKX",
    AIRPORTNAME: "Brookings",
    CITYCODE: "BKX",
    CITYNAME: "BROOKINGS",
    CITYNAME_HEB: "BROOKINGS",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SSM",
    AIRPORTNAME: "Metropolitan Area",
    CITYCODE: "SSM",
    CITYNAME: "SAULT STE MARIE",
    CITYNAME_HEB: "SAULT STE MARIE",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SSO",
    AIRPORTNAME: null,
    CITYCODE: "SSO",
    CITYNAME: "SAO LOURENCO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SSP",
    AIRPORTNAME: "Silver Plains",
    CITYCODE: "SSP",
    CITYNAME: "SILVER PLAINS",
    CITYNAME_HEB: "SILVER PLAINS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SSQ",
    AIRPORTNAME: "La Sarre",
    CITYCODE: "SSQ",
    CITYNAME: "LA SARRE",
    CITYNAME_HEB: "LA SARRE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "SSS",
    AIRPORTNAME: "Siassi",
    CITYCODE: "SSS",
    CITYNAME: "SIASSI",
    CITYNAME_HEB: "SIASSI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SST",
    AIRPORTNAME: null,
    CITYCODE: "SST",
    CITYNAME: "SANTA TERESITA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SSU",
    AIRPORTNAME: "Greenbrier",
    CITYCODE: "SSU",
    CITYNAME: "WHITE SULPHUR",
    CITYNAME_HEB: "WHITE SULPHUR",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "SSV",
    AIRPORTNAME: "Siasi",
    CITYCODE: "SSV",
    CITYNAME: "SIASI",
    CITYNAME_HEB: "SIASI",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "BKY",
    AIRPORTNAME: "Kamenbe",
    CITYCODE: "BKY",
    CITYNAME: "BUKAVU",
    CITYNAME_HEB: "BUKAVU",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "SSW",
    AIRPORTNAME: "Stuart Island",
    CITYCODE: "SSW",
    CITYNAME: "STUART ISLAND",
    CITYNAME_HEB: "STUART ISLAND",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SSY",
    AIRPORTNAME: "M'Banza Congo",
    CITYCODE: "SSY",
    CITYNAME: "M'BANZA CONGO",
    CITYNAME_HEB: "M'BANZA CONGO",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "SSZ",
    AIRPORTNAME: null,
    CITYCODE: "SSZ",
    CITYNAME: "SANTOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "STB",
    AIRPORTNAME: "L Delicias",
    CITYCODE: "STB",
    CITYNAME: "SANTA BARBARA ED",
    CITYNAME_HEB: "SANTA BARBARA ED",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "STC",
    CITYNAME: "ST. CHRISTOPH",
    CITYNAME_HEB:
      "\u05e1\u05e0\u05d8 \u05db\u05e8\u05d9\u05e1\u05d8\u05d5\u05e3",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "STD",
    AIRPORTNAME: "Mayo Guerrero",
    CITYCODE: "STD",
    CITYNAME: "SANTO DOMINGO",
    CITYNAME_HEB: "SANTO DOMINGO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "BKZ",
    AIRPORTNAME: "Bukoba",
    CITYCODE: "BKZ",
    CITYNAME: "BUKOBA",
    CITYNAME_HEB: "BUKOBA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "STE",
    AIRPORTNAME: " ",
    CITYCODE: "STE",
    CITYNAME: "SANTA TECLA",
    CITYNAME_HEB: "\u05e1\u05e0\u05d8\u05d4 \u05d8\u05e7\u05dc\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "STF",
    AIRPORTNAME: "Stephen Island",
    CITYCODE: "STF",
    CITYNAME: "STEPHEN ISLAND",
    CITYNAME_HEB: "STEPHEN ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "STG",
    AIRPORTNAME: "St George Island",
    CITYCODE: "STG",
    CITYNAME: "SAINT GEORGE ISLAND",
    CITYNAME_HEB: "St George Island",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "STH",
    AIRPORTNAME: "Strathmore",
    CITYCODE: "STH",
    CITYNAME: "STRATHMORE",
    CITYNAME_HEB: "STRATHMORE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "STI",
    AIRPORTNAME: "Municipal",
    CITYCODE: "STI",
    CITYNAME: "SANTIAGO",
    CITYNAME_HEB: "\u05e1\u05e0\u05d8\u05d9\u05d0\u05d2\u05d5",
    COUNTRYCODE: "DO",
    COUNTRYNAME: "DOMINICAN REPUBLIC",
    COUNTRYNAME_HEB:
      "\u05d4\u05e8\u05e4\u05d5\u05d1\u05dc\u05d9\u05e7\u05d4 \u05d4\u05d3\u05d5\u05de\u05d9\u05e0\u05d9\u05e7\u05e0\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "STJ",
    AIRPORTNAME: "Rosecrans Memorial",
    CITYCODE: "STJ",
    CITYNAME: "SAINT JOSEPH",
    CITYNAME_HEB: "SAINT JOSEPH",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "STK",
    AIRPORTNAME: " ",
    CITYCODE: "STK",
    CITYNAME: "STADTKYLL",
    CITYNAME_HEB: "\u05e1\u05d8\u05d0\u05d3\u05e7\u05d9\u05d9\u05dc",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "STL",
    AIRPORTNAME: "Lambert-St Louis International",
    CITYCODE: "STL",
    CITYNAME: "SAINT LOUIS",
    CITYNAME_HEB:
      "\u05e1\u05e0\u05d8. \u05dc\u05d5\u05d0\u05d9\u05e1 - \u05de\u05d9\u05d6\u05d5\u05e8\u05d9",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "ADQ",
    AIRPORTNAME: "Kodiak Airport",
    CITYCODE: "ADQ",
    CITYNAME: "KODIAK",
    CITYNAME_HEB: "Kodiak Airport",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NEM",
    AIRPORTNAME: null,
    CITYCODE: "NEM",
    CITYNAME: "NEUM",
    CITYNAME_HEB: "\u05e0\u05d0\u05d5\u05dd",
    COUNTRYCODE: "BA",
    COUNTRYNAME: "BOSNIA-HERZEGOVINA",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e1\u05e0\u05d9\u05d4 \u05d4\u05e8\u05e6\u05d2\u05d5\u05d1\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "STM",
    AIRPORTNAME: " ",
    CITYCODE: "STM",
    CITYNAME: "ST. MARTIN",
    CITYNAME_HEB: "\u05e1\u05e0\u05d8 \u05de\u05e8\u05d8\u05d9\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "STO",
    AIRPORTNAME: null,
    CITYCODE: "STO",
    CITYNAME: "STOCKHOLM",
    CITYNAME_HEB: "\u05e9\u05d8\u05d5\u05e7\u05d4\u05d5\u05dc\u05dd",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ARN",
    AIRPORTNAME: "Arlanda",
    CITYCODE: "STO",
    CITYNAME: "STOCKHOLM",
    CITYNAME_HEB: "\u05e9\u05d8\u05d5\u05e7\u05d4\u05d5\u05dc\u05dd",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NYO",
    AIRPORTNAME: "Skavsta",
    CITYCODE: "STO",
    CITYNAME: "STOCKHOLM",
    CITYNAME_HEB: "\u05e9\u05d8\u05d5\u05e7\u05d4\u05d5\u05dc\u05dd",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XEV",
    AIRPORTNAME: " ",
    CITYCODE: "STO",
    CITYNAME: "STOCKHOLM",
    CITYNAME_HEB: "\u05e9\u05d8\u05d5\u05e7\u05d4\u05d5\u05dc\u05dd",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "STP",
    AIRPORTNAME: " ",
    CITYCODE: "STP",
    CITYNAME: "STEPANTSMINDA",
    CITYNAME_HEB:
      "\u05e1\u05d8\u05d0\u05e4\u05d0\u05e0\u05d8\u05e1\u05de\u05d9\u05e0\u05d3\u05d4",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "STQ",
    AIRPORTNAME: "St Marys",
    CITYCODE: "STQ",
    CITYNAME: "SAINT MARYS",
    CITYNAME_HEB: "SAINT MARYS",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "STR",
    AIRPORTNAME: "Echterdingen",
    CITYCODE: "STR",
    CITYNAME: "STUTTGART",
    CITYNAME_HEB: "\u05e9\u05d8\u05d5\u05d8\u05d2\u05e8\u05d8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BLB",
    AIRPORTNAME: "Balboa",
    CITYCODE: "BLB",
    CITYNAME: "BALBOA",
    CITYNAME_HEB: "BALBOA",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "STS",
    AIRPORTNAME: "Sonoma County",
    CITYCODE: "STS",
    CITYNAME: "SANTA ROSA",
    CITYNAME_HEB: "SANTA ROSA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SPB",
    AIRPORTNAME: "SPB",
    CITYCODE: "STT",
    CITYNAME: "SAINT THOMAS",
    CITYNAME_HEB: "SAINT THOMAS",
    COUNTRYCODE: "VI",
    COUNTRYNAME: "VIRGIN ISLANDS-UNITED ST",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05ea\u05d5\u05dc\u05d4 \u05d4\u05d0\u05de\u05e8\u05d9\u05e7\u05d0\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "STT",
    AIRPORTNAME: "H.S.Truman",
    CITYCODE: "STT",
    CITYNAME: "SAINT THOMAS",
    CITYNAME_HEB: "SAINT THOMAS",
    COUNTRYCODE: "VI",
    COUNTRYNAME: "VIRGIN ISLANDS-UNITED ST",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05ea\u05d5\u05dc\u05d4 \u05d4\u05d0\u05de\u05e8\u05d9\u05e7\u05d0\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "STU",
    AIRPORTNAME: "Santa Cruz",
    CITYCODE: "STU",
    CITYNAME: "SANTA CRUZ",
    CITYNAME_HEB: "\u05e1\u05e0\u05d8\u05d4 \u05e7\u05e8\u05d5\u05d6",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "STV",
    AIRPORTNAME: "Surat",
    CITYCODE: "STV",
    CITYNAME: "SURAT",
    CITYNAME_HEB: "SURAT",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "STW",
    AIRPORTNAME: "Stavropol",
    CITYCODE: "STW",
    CITYNAME: "STAVROPOL",
    CITYNAME_HEB: "STAVROPOL",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JCD",
    AIRPORTNAME: "Downtown Heliport",
    CITYCODE: "STX",
    CITYNAME: "SAINT CROIX ISL",
    CITYNAME_HEB: "SAINT CROIX ISL",
    COUNTRYCODE: "VI",
    COUNTRYNAME: "VIRGIN ISLANDS-UNITED ST",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05ea\u05d5\u05dc\u05d4 \u05d4\u05d0\u05de\u05e8\u05d9\u05e7\u05d0\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "STX",
    AIRPORTNAME: "Alex Hamilton",
    CITYCODE: "STX",
    CITYNAME: "SAINT CROIX ISL",
    CITYNAME_HEB: "SAINT CROIX ISL",
    COUNTRYCODE: "VI",
    COUNTRYNAME: "VIRGIN ISLANDS-UNITED ST",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05ea\u05d5\u05dc\u05d4 \u05d4\u05d0\u05de\u05e8\u05d9\u05e7\u05d0\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "BLC",
    AIRPORTNAME: "Bali",
    CITYCODE: "BLC",
    CITYNAME: "BALI",
    CITYNAME_HEB: "BALI",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "STY",
    AIRPORTNAME: "Salto",
    CITYCODE: "STY",
    CITYNAME: "SALTO",
    CITYNAME_HEB: "SALTO",
    COUNTRYCODE: "UY",
    COUNTRYNAME: "URUGUAY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e8\u05d5\u05d2\u05d0\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "STZ",
    AIRPORTNAME: null,
    CITYCODE: "STZ",
    CITYNAME: "SANTA TEREZINHA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SUA",
    AIRPORTNAME: "Witham Field",
    CITYCODE: "SUA",
    CITYNAME: "STUART",
    CITYNAME_HEB: "STUART",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SUB",
    AIRPORTNAME: "Juanda",
    CITYCODE: "SUB",
    CITYNAME: "SURABAYA",
    CITYNAME_HEB: "SURABAYA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SUC",
    AIRPORTNAME: "Schloredt",
    CITYCODE: "SUC",
    CITYNAME: "SUNDANCE",
    CITYNAME_HEB: "SUNDANCE",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SUD",
    AIRPORTNAME: "Stroud",
    CITYCODE: "SUD",
    CITYNAME: "STROUD",
    CITYNAME_HEB: "STROUD",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SUE",
    AIRPORTNAME: "Door County",
    CITYCODE: "SUE",
    CITYNAME: "STURGEON BAY",
    CITYNAME_HEB: "STURGEON BAY",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SUF",
    AIRPORTNAME: "S Eufemia",
    CITYCODE: "SUF",
    CITYNAME: "CALABRIA LAMEZIA TERME",
    CITYNAME_HEB:
      "\u05e7\u05dc\u05d1\u05e8\u05d9\u05d4 \u05dc\u05de\u05d6\u05d4 \u05d8\u05e8\u05de\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SUG",
    AIRPORTNAME: "Surigao",
    CITYCODE: "SUG",
    CITYNAME: "SURIGAO",
    CITYNAME_HEB: "SURIGAO",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "SUH",
    AIRPORTNAME: null,
    CITYCODE: "SUH",
    CITYNAME: "SUR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "OM",
    COUNTRYNAME: "OMAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BLD",
    AIRPORTNAME: "Boulder City",
    CITYCODE: "BLD",
    CITYNAME: "BLED",
    CITYNAME_HEB: "\u05d1\u05dc\u05d3",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SUI",
    AIRPORTNAME: "Babusheri",
    CITYCODE: "SUI",
    CITYNAME: "SUKHUMI",
    CITYNAME_HEB: "SUKHUMI",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SUJ",
    AIRPORTNAME: "Satu Mare",
    CITYCODE: "SUJ",
    CITYNAME: "SATU MARE",
    CITYNAME_HEB: "SATU MARE",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SUK",
    AIRPORTNAME: "Samchok",
    CITYCODE: "SUK",
    CITYNAME: "SAMCHEOK",
    CITYNAME_HEB: "SAMCHEOK",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "SUL",
    AIRPORTNAME: "Sui",
    CITYCODE: "SUL",
    CITYNAME: "SUI",
    CITYNAME_HEB: "SUI",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "SUN",
    AIRPORTNAME: "Hailey/Sun Valley Airport",
    CITYCODE: "SUN",
    CITYNAME: "HAILEY",
    CITYNAME_HEB: "HAILEY",
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d9\u05d3\u05d4\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XUN",
    AIRPORTNAME: null,
    CITYCODE: "SUN",
    CITYNAME: "HAILEY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SUO",
    AIRPORTNAME: "Sun River",
    CITYCODE: "SUO",
    CITYNAME: "SUN RIVER",
    CITYNAME_HEB: "SUN RIVER",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SUP",
    AIRPORTNAME: "Trunojoyo",
    CITYCODE: "SUP",
    CITYNAME: "SUMENEP",
    CITYNAME_HEB: "SUMENEP",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SUQ",
    AIRPORTNAME: "Sucua",
    CITYCODE: "SUQ",
    CITYNAME: "SUCUA",
    CITYNAME_HEB: "SUCUA",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "SUR",
    AIRPORTNAME: "Summer Beaver",
    CITYCODE: "SUR",
    CITYNAME: "SUMMER BEAVER",
    CITYNAME_HEB: "SUMMER BEAVER",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BLE",
    AIRPORTNAME: " ",
    CITYCODE: "BLE",
    CITYNAME: "BELGIUM",
    CITYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SUT",
    AIRPORTNAME: "Sumbawanga",
    CITYCODE: "SUT",
    CITYNAME: "SUMBAWANGA",
    CITYNAME_HEB: "SUMBAWANGA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SUU",
    AIRPORTNAME: "Travis AFB",
    CITYCODE: "SUU",
    CITYNAME: "FAIRFIELD",
    CITYNAME_HEB: "FAIRFIELD",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SUV",
    AIRPORTNAME: "Nausori",
    CITYCODE: "SUV",
    CITYNAME: "SUVA",
    CITYNAME_HEB: "SUVA",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "SUX",
    AIRPORTNAME: "Sioux Gateway",
    CITYCODE: "SUX",
    CITYNAME: "SIOUX CITY",
    CITYNAME_HEB: "SIOUX CITY",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SUY",
    AIRPORTNAME: "Sudureyri",
    CITYCODE: "SUY",
    CITYNAME: "SUDUREYRI",
    CITYNAME_HEB: "SUDUREYRI",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SUZ",
    AIRPORTNAME: "Suria",
    CITYCODE: "SUZ",
    CITYNAME: "SURIA",
    CITYNAME_HEB: "SURIA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SVA",
    AIRPORTNAME: "Savoonga",
    CITYCODE: "SVA",
    CITYNAME: "SAVOONGA",
    CITYNAME_HEB: "Savoonga",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SVB",
    AIRPORTNAME: "Sambava",
    CITYCODE: "SVB",
    CITYNAME: "SAMBAVA",
    CITYNAME_HEB: "SAMBAVA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "SVC",
    AIRPORTNAME: "Grant County",
    CITYCODE: "SVC",
    CITYNAME: "SILVER CITY",
    CITYNAME_HEB: "SILVER CITY",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SVD",
    AIRPORTNAME: null,
    CITYCODE: "SVD",
    CITYNAME: "SAINT VINCENT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "VC",
    COUNTRYNAME: "ST. VINCENT",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BLF",
    AIRPORTNAME: "Bluefield/Princeton Airport",
    CITYCODE: "BLF",
    CITYNAME: "BLUEFIELD",
    CITYNAME_HEB: "BLUEFIELD",
    COUNTRYCODE: "USWV",
    COUNTRYNAME: "WEST VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "SVE",
    AIRPORTNAME: "Susanville",
    CITYCODE: "SVE",
    CITYNAME: "SUSANVILLE",
    CITYNAME_HEB: "SUSANVILLE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SVF",
    AIRPORTNAME: "Save",
    CITYCODE: "SVF",
    CITYNAME: "SAVE",
    CITYNAME_HEB: "SAVE",
    COUNTRYCODE: "BJ",
    COUNTRYNAME: "BENIN",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SVG",
    AIRPORTNAME: "Sola",
    CITYCODE: "SVG",
    CITYNAME: "STAVANGER",
    CITYNAME_HEB: "\u05e1\u05d8\u05d5\u05d5\u05e0\u05d2\u05e8",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SVH",
    AIRPORTNAME: "Municipal",
    CITYCODE: "SVH",
    CITYNAME: "STATESVILLE",
    CITYNAME_HEB: "STATESVILLE",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SVI",
    AIRPORTNAME: "San Vicente",
    CITYCODE: "SVI",
    CITYNAME: "SAN VICENTE",
    CITYNAME_HEB: "SAN VICENTE",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SVJ",
    AIRPORTNAME: "Helle",
    CITYCODE: "SVJ",
    CITYNAME: "SVOLVAER",
    CITYNAME_HEB: "SVOLVAER",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SVK",
    AIRPORTNAME: "Silver Creek",
    CITYCODE: "SVK",
    CITYNAME: "SILVER CREEK",
    CITYNAME_HEB: "SILVER CREEK",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "SVL",
    AIRPORTNAME: "Savonlinna",
    CITYCODE: "SVL",
    CITYNAME: "SAVONLINNA",
    CITYNAME_HEB: "\u05e1\u05d0\u05d1\u05d5\u05e0\u05dc\u05d9\u05e0\u05d4",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SVM",
    AIRPORTNAME: "St Paul's Mission",
    CITYCODE: "SVM",
    CITYNAME: "SAINT PAUL'S MISSION",
    CITYNAME_HEB: "SAINT PAUL'S MISSION",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SVP",
    AIRPORTNAME: "Kuito",
    CITYCODE: "SVP",
    CITYNAME: "KUITO",
    CITYNAME_HEB: "KUITO",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "BLG",
    AIRPORTNAME: " ",
    CITYCODE: "BLG",
    CITYNAME: "BELGIRATE",
    CITYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05e8\u05d8\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SVQ",
    AIRPORTNAME: "Sevilla",
    CITYCODE: "SVQ",
    CITYNAME: "SEVILLA",
    CITYNAME_HEB: "\u05e1\u05d1\u05d9\u05dc\u05d9\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "SVR",
    AIRPORTNAME: "Svay Rieng",
    CITYCODE: "SVR",
    CITYNAME: "SVAY RIENG",
    CITYNAME_HEB: "SVAY RIENG",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SVS",
    AIRPORTNAME: "Stevens Village",
    CITYCODE: "SVS",
    CITYNAME: "STEVENS VILLAGE",
    CITYNAME_HEB: "Stevens Village",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SVT",
    AIRPORTNAME: "Savuti",
    CITYCODE: "SVT",
    CITYNAME: "SAVUTI",
    CITYNAME_HEB: "SAVUTI",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "SVU",
    AIRPORTNAME: "Savusavu",
    CITYCODE: "SVU",
    CITYNAME: "SAVUSAVU",
    CITYNAME_HEB: "SAVUSAVU",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "SVV",
    AIRPORTNAME: "San Salvador De",
    CITYCODE: "SVV",
    CITYNAME: "SAN SALVADOR DE",
    CITYNAME_HEB: "SAN SALVADOR DE",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "SVW",
    AIRPORTNAME: "Sparrevohn AFS",
    CITYCODE: "SVW",
    CITYNAME: "SPARREVOHN",
    CITYNAME_HEB: "Sparrevohn AFS",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SVX",
    AIRPORTNAME: "Ekaterinburg",
    CITYCODE: "SVX",
    CITYNAME: "EKATERINBURG",
    CITYNAME_HEB: "EKATERINBURG",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SVY",
    AIRPORTNAME: "Savo",
    CITYCODE: "SVY",
    CITYNAME: "SAVO",
    CITYNAME_HEB: "SAVO",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "SVZ",
    AIRPORTNAME: "San Antonio",
    CITYCODE: "SVZ",
    CITYNAME: "SAN ANTONIO",
    CITYNAME_HEB: "SAN ANTONIO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "BLH",
    AIRPORTNAME: "Blythe",
    CITYCODE: "BLH",
    CITYNAME: "BLYTHE",
    CITYNAME_HEB: "BLYTHE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SWA",
    AIRPORTNAME: "Shantou",
    CITYCODE: "SWA",
    CITYNAME: "SHANTOU",
    CITYNAME_HEB: "SHANTOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SWB",
    AIRPORTNAME: "Shaw River",
    CITYCODE: "SWB",
    CITYNAME: "SHAW RIVER",
    CITYNAME_HEB: "SHAW RIVER",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SWC",
    AIRPORTNAME: "Stawell",
    CITYCODE: "SWC",
    CITYNAME: "STAWELL",
    CITYNAME_HEB: "STAWELL",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SWD",
    AIRPORTNAME: "Seward",
    CITYCODE: "SWD",
    CITYNAME: "SEWARD-ALASKA",
    CITYNAME_HEB:
      "\u05e1\u05d9\u05d5\u05d5\u05e8\u05d3-\u05d0\u05dc\u05e1\u05e7\u05d4",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "SWE",
    AIRPORTNAME: " ",
    CITYCODE: "SWE",
    CITYNAME: "SWEDEN",
    CITYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SWG",
    AIRPORTNAME: "Satwag",
    CITYCODE: "SWG",
    CITYNAME: "SATWAG",
    CITYNAME_HEB: "SATWAG",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SWH",
    AIRPORTNAME: "Swan Hill",
    CITYCODE: "SWH",
    CITYNAME: "SWAN HILL",
    CITYNAME_HEB: "SWAN HILL",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SWI",
    AIRPORTNAME: "Swindon",
    CITYCODE: "SWI",
    CITYNAME: "SWINDON",
    CITYNAME_HEB: "SWINDON",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XWS",
    AIRPORTNAME: null,
    CITYCODE: "SWI",
    CITYNAME: "SWINDON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SWJ",
    AIRPORTNAME: "South West Bay",
    CITYCODE: "SWJ",
    CITYNAME: "SOUTH WEST BAY",
    CITYNAME_HEB: "SOUTH WEST BAY",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "BLI",
    AIRPORTNAME: "Bellingham",
    CITYCODE: "BLI",
    CITYNAME: "BELLINGHAM",
    CITYNAME_HEB: "BELLINGHAM",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SWL",
    AIRPORTNAME: "Spanish Wells",
    CITYCODE: "SWL",
    CITYNAME: "SPANISH WELLS",
    CITYNAME_HEB: "SPANISH WELLS",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "SWM",
    AIRPORTNAME: null,
    CITYCODE: "SWM",
    CITYNAME: "SUIA MISSU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SWN",
    AIRPORTNAME: "Sahiwal",
    CITYCODE: "SWN",
    CITYNAME: "SAHIWAL",
    CITYNAME_HEB: "SAHIWAL",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "SWO",
    AIRPORTNAME: "Searcy Fld",
    CITYCODE: "SWO",
    CITYNAME: "STILLWATER",
    CITYNAME_HEB: "STILLWATER",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SWP",
    AIRPORTNAME: "Swakopmund",
    CITYCODE: "SWP",
    CITYNAME: "SWAKOPMUND",
    CITYNAME_HEB: "SWAKOPMUND",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SWQ",
    AIRPORTNAME: "Brang Bidji",
    CITYCODE: "SWQ",
    CITYNAME: "SUMBAWA",
    CITYNAME_HEB: "SUMBAWA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SWR",
    AIRPORTNAME: "Silur",
    CITYCODE: "SWR",
    CITYNAME: "SILUR",
    CITYNAME_HEB: "SILUR",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SWS",
    AIRPORTNAME: "Fairwood Comm",
    CITYCODE: "SWS",
    CITYNAME: "SWANSEA",
    CITYNAME_HEB: "SWANSEA",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SWT",
    AIRPORTNAME: "Strzhewoi",
    CITYCODE: "SWT",
    CITYNAME: "STRZHEWOI",
    CITYNAME_HEB: "STRZHEWOI",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SWU",
    AIRPORTNAME: "Su Won Airport",
    CITYCODE: "SWU",
    CITYNAME: "SUWON",
    CITYNAME_HEB: "SUWON",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "BLJ",
    AIRPORTNAME: "Batna",
    CITYCODE: "BLJ",
    CITYNAME: "BATNA",
    CITYNAME_HEB: "BATNA",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SWV",
    AIRPORTNAME: "Shikarpur",
    CITYCODE: "SWV",
    CITYNAME: "SHIKARPUR",
    CITYNAME_HEB: "SHIKARPUR",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "SWW",
    AIRPORTNAME: "Sweetwater",
    CITYCODE: "SWW",
    CITYNAME: "SWEETWATER",
    CITYNAME_HEB: "SWEETWATER",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SWX",
    AIRPORTNAME: "Shakawe",
    CITYCODE: "SWX",
    CITYNAME: "SHAKAWE",
    CITYNAME_HEB: "SHAKAWE",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "SWY",
    AIRPORTNAME: "Sitiawan",
    CITYCODE: "SWY",
    CITYNAME: "SITIAWAN",
    CITYNAME_HEB: "SITIAWAN",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SXA",
    AIRPORTNAME: "Sialum",
    CITYCODE: "SXA",
    CITYNAME: "SIALUM",
    CITYNAME_HEB: "SIALUM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SXB",
    AIRPORTNAME: "Entzheim",
    CITYCODE: "SXB",
    CITYNAME: "STRASBOURG",
    CITYNAME_HEB: "\u05e9\u05d8\u05e8\u05e1\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "SXD",
    AIRPORTNAME: "Sophia Antipolis",
    CITYCODE: "SXD",
    CITYNAME: "SOPHIA ANTIPOLIS",
    CITYNAME_HEB: "SOPHIA ANTIPOLIS",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "SXE",
    AIRPORTNAME: "Sale",
    CITYCODE: "SXE",
    CITYNAME: "SALE",
    CITYNAME_HEB: "SALE",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SXG",
    AIRPORTNAME: "Senanga",
    CITYCODE: "SXG",
    CITYNAME: "SENANGA",
    CITYNAME_HEB: "SENANGA",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZTG",
    AIRPORTNAME: null,
    CITYCODE: "AAL",
    CITYNAME: "AALBORG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BLK",
    AIRPORTNAME: "Blackpool",
    CITYCODE: "BLK",
    CITYNAME: "BLACKPOOL",
    CITYNAME_HEB: "BLACKPOOL",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SXH",
    AIRPORTNAME: "Sehulea",
    CITYCODE: "SXH",
    CITYNAME: "SEHULEA",
    CITYNAME_HEB: "SEHULEA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SXJ",
    AIRPORTNAME: "Shanshan",
    CITYCODE: "SXJ",
    CITYNAME: "SHANSHAN",
    CITYNAME_HEB: "SHANSHAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SXK",
    AIRPORTNAME: "Saumlaki",
    CITYCODE: "SXK",
    CITYNAME: "SAUMLAKI",
    CITYNAME_HEB: "SAUMLAKI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SXL",
    AIRPORTNAME: "Collooney",
    CITYCODE: "SXL",
    CITYNAME: "SLIGO",
    CITYNAME_HEB: "\u05e1\u05dc\u05d9\u05d9\u05d2\u05d5",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SXM",
    AIRPORTNAME: null,
    CITYCODE: "SXM",
    CITYNAME: "SAINT MAARTEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SX",
    COUNTRYNAME: "SAINT MAARTEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SXN",
    AIRPORTNAME: "Suapan",
    CITYCODE: "SXN",
    CITYNAME: "SUAPAN",
    CITYNAME_HEB: "SUAPAN",
    COUNTRYCODE: "BW",
    COUNTRYNAME: "BOTSWANA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e4\u05e6\u05d5\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "SXO",
    AIRPORTNAME: null,
    CITYCODE: "SXO",
    CITYNAME: "SAO FELIX DO ARAG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SCU",
    AIRPORTNAME: "Antonio Maceo",
    CITYCODE: "SCU",
    CITYNAME: "SANTIAGO",
    CITYNAME_HEB: "\u05e1\u05e0\u05d8\u05d9\u05d0\u05d2\u05d5",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "SXP",
    AIRPORTNAME: "Sheldon SPB",
    CITYCODE: "SXP",
    CITYNAME: "SHELDON POINT",
    CITYNAME_HEB: "Sheldon SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SXQ",
    AIRPORTNAME: "Soldotna",
    CITYCODE: "SXQ",
    CITYNAME: "SOLDOTNA",
    CITYNAME_HEB: "Soldotna",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SXR",
    AIRPORTNAME: "Srinagar",
    CITYCODE: "SXR",
    CITYNAME: "SRINAGAR",
    CITYNAME_HEB: "SRINAGAR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "BLL",
    AIRPORTNAME: "Billund",
    CITYCODE: "BLL",
    CITYNAME: "BILLUND",
    CITYNAME_HEB: "BILLUND",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "SXS",
    AIRPORTNAME: "Sahabat 16",
    CITYCODE: "SXS",
    CITYNAME: "SAHABAT 16",
    CITYNAME_HEB: "SAHABAT 16",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SXT",
    AIRPORTNAME: "Taman Negara",
    CITYCODE: "SXT",
    CITYNAME: "TAMAN NEGARA",
    CITYNAME_HEB: "TAMAN NEGARA",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SXU",
    AIRPORTNAME: "Soddu",
    CITYCODE: "SXU",
    CITYNAME: "SODDU",
    CITYNAME_HEB: "SODDU",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SXV",
    AIRPORTNAME: "Salem",
    CITYCODE: "SXV",
    CITYNAME: "SALEM",
    CITYNAME_HEB: "SALEM",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "SXW",
    AIRPORTNAME: "Sauren",
    CITYCODE: "SXW",
    CITYNAME: "SAUREN",
    CITYNAME_HEB: "SAUREN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SXX",
    AIRPORTNAME: null,
    CITYCODE: "SXX",
    CITYNAME: "SAO FELIX DO XING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SXY",
    AIRPORTNAME: "Sidney",
    CITYCODE: "SXY",
    CITYNAME: "SIDNEY",
    CITYNAME_HEB: "SIDNEY (US)",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SXZ",
    AIRPORTNAME: "Siirt",
    CITYCODE: "SXZ",
    CITYNAME: "SIIRT",
    CITYNAME_HEB: "SIIRT",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SYA",
    AIRPORTNAME: "Shemya AFB",
    CITYCODE: "SYA",
    CITYNAME: "SHEMYA",
    CITYNAME_HEB: "Shemya AFB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SYB",
    AIRPORTNAME: "Seal Bay",
    CITYCODE: "SYB",
    CITYNAME: "SEAL BAY",
    CITYNAME_HEB: "Seal Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BLM",
    AIRPORTNAME: "Monmouth County",
    CITYCODE: "BLM",
    CITYNAME: "BELMAR",
    CITYNAME_HEB: "BELMAR",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "SYC",
    AIRPORTNAME: "Shiringayoc",
    CITYCODE: "SYC",
    CITYNAME: "SHIRINGAYOC",
    CITYNAME_HEB: "SHIRINGAYOC",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "SYD",
    AIRPORTNAME: "Kingsford Smith",
    CITYCODE: "SYD",
    CITYNAME: "SYDNEY",
    CITYNAME_HEB: "\u05e1\u05d9\u05d3\u05e0\u05d9",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "AUSTRLIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SYF",
    AIRPORTNAME: "Silva Bay",
    CITYCODE: "SYF",
    CITYNAME: "SILVA BAY",
    CITYNAME_HEB: "SILVA BAY",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "SYH",
    AIRPORTNAME: "Syangboche",
    CITYCODE: "SYH",
    CITYNAME: "SYANGBOCHE",
    CITYNAME_HEB: "SYANGBOCHE",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "SYI",
    AIRPORTNAME: "Bomar Field",
    CITYCODE: "SYI",
    CITYNAME: "SHELBYVILLE",
    CITYNAME_HEB: "SHELBYVILLE",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SYK",
    AIRPORTNAME: "Stykkisholmur",
    CITYCODE: "SYK",
    CITYNAME: "STYKKISHOLMUR",
    CITYNAME_HEB: "STYKKISHOLMUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SYL",
    AIRPORTNAME: "Roberts AAF",
    CITYCODE: "SYL",
    CITYNAME: "SAN MIGUEL",
    CITYNAME_HEB: "SAN MIGUEL",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BLN",
    AIRPORTNAME: "Benalla",
    CITYCODE: "BLN",
    CITYNAME: "BENALLA",
    CITYNAME_HEB: "BENALLA",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SYM",
    AIRPORTNAME: "Simao",
    CITYCODE: "SYM",
    CITYNAME: "SIMAO",
    CITYNAME_HEB: "SIMAO",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SYN",
    AIRPORTNAME: "Carleton",
    CITYCODE: "SYN",
    CITYNAME: "STANTON",
    CITYNAME_HEB: "STANTON",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SYO",
    AIRPORTNAME: "Shonai",
    CITYCODE: "SYO",
    CITYNAME: "SHONAI",
    CITYNAME_HEB: "SHONAI",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "SYP",
    AIRPORTNAME: "Santiago",
    CITYCODE: "SYP",
    CITYNAME: "SANTIAGO",
    CITYNAME_HEB: "SANTIAGO",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "SYR",
    AIRPORTNAME: "Hancock International",
    CITYCODE: "SYR",
    CITYNAME: "SYRACUSE",
    CITYNAME_HEB: "SYRACUSE",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZYQ",
    AIRPORTNAME: null,
    CITYCODE: "SYR",
    CITYNAME: "SYRACUSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SYS",
    AIRPORTNAME: "Yosu",
    CITYCODE: "SYS",
    CITYNAME: "SUNCHEON",
    CITYNAME_HEB: "SUNCHEON",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "SYT",
    AIRPORTNAME: "Charolais Bourgogne S.",
    CITYCODE: "SYT",
    CITYNAME: "SAINT YAN",
    CITYNAME_HEB: "SAINT YAN",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "SYU",
    AIRPORTNAME: "Warraber Island",
    CITYCODE: "SYU",
    CITYNAME: "SUE ISLAND",
    CITYNAME_HEB: "SUE ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SYV",
    AIRPORTNAME: "Sylvester",
    CITYCODE: "SYV",
    CITYNAME: "SYLVESTER",
    CITYNAME_HEB: "SYLVESTER",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "BLO",
    AIRPORTNAME: "Blonduos",
    CITYCODE: "BLO",
    CITYNAME: "BLONDUOS",
    CITYNAME_HEB: "BLONDUOS",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SYW",
    AIRPORTNAME: "Sehwen Sharif",
    CITYCODE: "SYW",
    CITYNAME: "SEHWEN SHARIF",
    CITYNAME_HEB: "SEHWEN SHARIF",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "SYX",
    AIRPORTNAME: "Sanya",
    CITYCODE: "SYX",
    CITYNAME: "SANYA",
    CITYNAME_HEB: "\u05e1\u05e0\u05d9\u05d4",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SYY",
    AIRPORTNAME: "Stornoway",
    CITYCODE: "SYY",
    CITYNAME: "STORNOWAY",
    CITYNAME_HEB: "STORNOWAY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SZA",
    AIRPORTNAME: "Soyo",
    CITYCODE: "SZA",
    CITYNAME: "SOYO",
    CITYNAME_HEB: "SOYO",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "SZC",
    AIRPORTNAME: "Guanacaste",
    CITYCODE: "SZC",
    CITYNAME: "SANTA CRUZ",
    CITYNAME_HEB: "SANTA CRUZ",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "SKW",
    AIRPORTNAME: "SKWENTNA",
    CITYCODE: "SKW",
    CITYNAME: "SKWENTNA",
    CITYNAME_HEB: "SKWENTNA",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SKX",
    AIRPORTNAME: "Saransk",
    CITYCODE: "SKX",
    CITYNAME: "SARANSK",
    CITYNAME_HEB: "SARANSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SKY",
    AIRPORTNAME: "Griffing Sandusky",
    CITYCODE: "SKY",
    CITYNAME: "SANDUSKY",
    CITYNAME_HEB: "SANDUSKY",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SKZ",
    AIRPORTNAME: "Sukkur",
    CITYCODE: "SKZ",
    CITYNAME: "SUKKUR",
    CITYNAME_HEB: "SUKKUR",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "SLA",
    AIRPORTNAME: null,
    CITYCODE: "SLA",
    CITYNAME: "SALTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SLB",
    AIRPORTNAME: "Municipal",
    CITYCODE: "SLB",
    CITYNAME: "STORM LAKE",
    CITYNAME_HEB: "STORM LAKE",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SLC",
    AIRPORTNAME: "International",
    CITYCODE: "SLC",
    CITYNAME: "SALT LAKE CITY",
    CITYNAME_HEB:
      "\u05e1\u05d5\u05dc\u05d8 \u05dc\u05d9\u05d9\u05e7 \u05e1\u05d9\u05d8\u05d9",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "SLD",
    AIRPORTNAME: "Sliac",
    CITYCODE: "SLD",
    CITYNAME: "SLIAC",
    CITYNAME_HEB: "\u05e1\u05dc\u05d9\u05d0\u05e7",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BKB",
    AIRPORTNAME: "Bikaner",
    CITYCODE: "BKB",
    CITYNAME: "BIKANER",
    CITYNAME_HEB: "BIKANER",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "SLE",
    AIRPORTNAME: "Mcnary Field",
    CITYCODE: "SLE",
    CITYNAME: "SALEM",
    CITYNAME_HEB: "SALEM",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SLG",
    AIRPORTNAME: "Smith Field",
    CITYCODE: "SLG",
    CITYNAME: "SILOAM SPRINGS",
    CITYNAME_HEB: "SILOAM SPRINGS",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SLH",
    AIRPORTNAME: "Sola",
    CITYCODE: "SLH",
    CITYNAME: "SOLA",
    CITYNAME_HEB: "SOLA",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "SLI",
    AIRPORTNAME: "Solwezi",
    CITYCODE: "SLI",
    CITYNAME: "SOLWEZI",
    CITYNAME_HEB: "SOLWEZI",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SLK",
    AIRPORTNAME: "Adirondack",
    CITYCODE: "SLK",
    CITYNAME: "SARANAC LAKE",
    CITYNAME_HEB: "SARANAC LAKE",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SLL",
    AIRPORTNAME: " ",
    CITYCODE: "SLL",
    CITYNAME: "SOLL",
    CITYNAME_HEB: "\u05e1\u05d5\u05dc",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SLM",
    AIRPORTNAME: "Matacan",
    CITYCODE: "SLM",
    CITYNAME: "SALAMANCA",
    CITYNAME_HEB: "SALAMANCA",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "SLN",
    AIRPORTNAME: "Salina",
    CITYCODE: "SLN",
    CITYNAME: "SALINA",
    CITYNAME_HEB: "SALINA",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SLO",
    AIRPORTNAME: "Leckrone",
    CITYCODE: "SLO",
    CITYNAME: "SALEM",
    CITYNAME_HEB: "SALEM",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SLP",
    AIRPORTNAME: "San Luis Potosi",
    CITYCODE: "SLP",
    CITYNAME: "SAN LUIS POTOSI",
    CITYNAME_HEB: "SAN LUIS POTOSI",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "BKC",
    AIRPORTNAME: "Buckland",
    CITYCODE: "BKC",
    CITYNAME: "BUCKLAND",
    CITYNAME_HEB: "Buckland",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SLQ",
    AIRPORTNAME: "Sleetmute",
    CITYCODE: "SLQ",
    CITYNAME: "SLEETMUTE",
    CITYNAME_HEB: "Sleetmute",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SLR",
    AIRPORTNAME: "Sulphur Springs",
    CITYCODE: "SLR",
    CITYNAME: "SULPHUR SPRINGS",
    CITYNAME_HEB: "SULPHUR SPRINGS",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SLS",
    AIRPORTNAME: "Silistra",
    CITYCODE: "SLS",
    CITYNAME: "SILISTRA",
    CITYNAME_HEB: "\u05e1\u05dc\u05d9\u05e1\u05d8\u05e8\u05d4",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SLT",
    AIRPORTNAME: "Salida",
    CITYCODE: "SLT",
    CITYNAME: "SALIDA",
    CITYNAME_HEB: "SALIDA",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SLU",
    AIRPORTNAME: null,
    CITYCODE: "SLU",
    CITYNAME: "SAINT LUCIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LC",
    COUNTRYNAME: "ST. LUCIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SLV",
    AIRPORTNAME: "Simla",
    CITYCODE: "SLV",
    CITYNAME: "SIMLA",
    CITYNAME_HEB: "SIMLA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "SLW",
    AIRPORTNAME: "Saltillo",
    CITYCODE: "SLW",
    CITYNAME: "SALTILLO",
    CITYNAME_HEB: "SALTILLO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "SLX",
    AIRPORTNAME: null,
    CITYCODE: "SLX",
    CITYNAME: "SALT CAY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TC",
    COUNTRYNAME: "TURKS AND CAICOS ISLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SLY",
    AIRPORTNAME: "Salehard",
    CITYCODE: "SLY",
    CITYNAME: "SALEHARD",
    CITYNAME_HEB: "SALEHARD",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ADO",
    AIRPORTNAME: "Andamooka",
    CITYCODE: "ADO",
    CITYNAME: "ANDAMOOKA",
    CITYNAME_HEB: "ANDAMOOKA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BKD",
    AIRPORTNAME: "Stephens County",
    CITYCODE: "BKD",
    CITYNAME: "BRECKENRIDGE",
    CITYNAME_HEB: "BRECKENRIDGE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SLZ",
    AIRPORTNAME: null,
    CITYCODE: "SLZ",
    CITYNAME: "SAO LUIZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "LMF",
    CITYNAME: "LIMONE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SMA",
    AIRPORTNAME: "Vila Do Porto",
    CITYCODE: "SMA",
    CITYNAME: "SANTA MARIA",
    CITYNAME_HEB: "SANTA MARIA",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "SMB",
    AIRPORTNAME: "Cerro Sombrero",
    CITYCODE: "SMB",
    CITYNAME: "CERRO SOMBRERO",
    CITYNAME_HEB: "CERRO SOMBRERO",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "SMC",
    AIRPORTNAME: "Santa Maria",
    CITYCODE: "SMC",
    CITYNAME: "SANTA MARIA",
    CITYNAME_HEB: "SANTA MARIA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SME",
    AIRPORTNAME: "Pulaski County",
    CITYCODE: "SME",
    CITYNAME: "SOMERSET",
    CITYNAME_HEB: "SOMERSET",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SMG",
    AIRPORTNAME: "Santa Maria",
    CITYCODE: "SMG",
    CITYNAME: "SANTA MARIA",
    CITYNAME_HEB: "SANTA MARIA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "SMH",
    AIRPORTNAME: "Sapmanga",
    CITYCODE: "SMH",
    CITYNAME: "SAPMANGA",
    CITYNAME_HEB: "SAPMANGA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SMI",
    AIRPORTNAME: "Samos",
    CITYCODE: "SMI",
    CITYNAME: "SAMOS",
    CITYNAME_HEB: "\u05e1\u05d0\u05de\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "SMJ",
    AIRPORTNAME: "Sim",
    CITYCODE: "SMJ",
    CITYNAME: "SIM",
    CITYNAME_HEB: "SIM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SMK",
    AIRPORTNAME: "St Michael",
    CITYCODE: "SMK",
    CITYNAME: "SAINT MICHAEL",
    CITYNAME_HEB: "St Michael",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BKE",
    AIRPORTNAME: "Baker",
    CITYCODE: "BKE",
    CITYNAME: "BAKER",
    CITYNAME_HEB: "BAKER",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SML",
    AIRPORTNAME: "Estate Airstrip",
    CITYCODE: "SML",
    CITYNAME: "STELLA MARIS",
    CITYNAME_HEB: "STELLA MARIS",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "SMM",
    AIRPORTNAME: "Semporna",
    CITYCODE: "SMM",
    CITYNAME: "SEMPORNA",
    CITYNAME_HEB: "SEMPORNA",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SMN",
    AIRPORTNAME: "Salmon",
    CITYCODE: "SMN",
    CITYNAME: "SALMON",
    CITYNAME_HEB: "SALMON",
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d9\u05d3\u05d4\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SMO",
    AIRPORTNAME: "Santa Monica",
    CITYCODE: "SMO",
    CITYNAME: "SANTA MONICA",
    CITYNAME_HEB: "SANTA MONICA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SMP",
    AIRPORTNAME: "Stockholm",
    CITYCODE: "SMP",
    CITYNAME: "STOCKHOLM",
    CITYNAME_HEB: "STOCKHOLM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SMQ",
    AIRPORTNAME: "Sampit",
    CITYCODE: "SMQ",
    CITYNAME: "SAMPIT",
    CITYNAME_HEB: "SAMPIT",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SMR",
    AIRPORTNAME: "Simon Bolivar",
    CITYCODE: "SMR",
    CITYNAME: "SANTA MARTA",
    CITYNAME_HEB: "SANTA MARTA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SMS",
    AIRPORTNAME: "Sainte Marie",
    CITYCODE: "SMS",
    CITYNAME: "SAINTE MARIE",
    CITYNAME_HEB: "SAINTE MARIE",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "SMT",
    AIRPORTNAME: "Sun Moon Lake",
    CITYCODE: "SMT",
    CITYNAME: "SUN MOON LAKE",
    CITYNAME_HEB:
      "\u05e1\u05d0\u05df \u05de\u05d5\u05df \u05dc\u05d9\u05d9\u05e7",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "SMU",
    AIRPORTNAME: "Sheep Mountain",
    CITYCODE: "SMU",
    CITYNAME: "SHEEP MOUNTAIN",
    CITYNAME_HEB: "Sheep Mountain",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BKF",
    AIRPORTNAME: "Brooks Lake",
    CITYCODE: "BKF",
    CITYNAME: "BROOKS LAKE",
    CITYNAME_HEB: "Brooks Lake",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SMV",
    AIRPORTNAME: "Samedan",
    CITYCODE: "SMV",
    CITYNAME: "SAINT MORITZ",
    CITYNAME_HEB: "\u05e1\u05e0\u05d8 \u05de\u05d5\u05e8\u05d9\u05e5",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "SMW",
    AIRPORTNAME: "Smara",
    CITYCODE: "SMW",
    CITYNAME: "SMARA",
    CITYNAME_HEB: "SMARA",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "SMX",
    AIRPORTNAME: "Public",
    CITYCODE: "SMX",
    CITYNAME: "SANTA MARIA",
    CITYNAME_HEB: "SANTA MARIA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SMY",
    AIRPORTNAME: "Simenti",
    CITYCODE: "SMY",
    CITYNAME: "SIMENTI",
    CITYNAME_HEB: "SIMENTI",
    COUNTRYCODE: "SN",
    COUNTRYNAME: "SENEGAL",
    COUNTRYNAME_HEB: "\u05e1\u05e0\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "SMZ",
    AIRPORTNAME: "Stoelmans Eiland",
    CITYCODE: "SMZ",
    CITYNAME: "STOELMANS EILAND",
    CITYNAME_HEB: "STOELMANS EILAND",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "SNA",
    AIRPORTNAME: " ",
    CITYCODE: "SNA",
    CITYNAME: "SANT ANNA",
    CITYNAME_HEB: "\u05e1\u05e0\u05d8 \u05d0\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SNB",
    AIRPORTNAME: "Snake Bay",
    CITYCODE: "SNB",
    CITYNAME: "SNAKE BAY",
    CITYNAME_HEB: "SNAKE BAY",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SNC",
    AIRPORTNAME: "Salinas",
    CITYCODE: "SNC",
    CITYNAME: "SALINAS",
    CITYNAME_HEB: "SALINAS",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "BKH",
    AIRPORTNAME: "Barking Sands",
    CITYCODE: "BKH",
    CITYNAME: "KEKAHA",
    CITYNAME_HEB: "KEKAHA",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SND",
    AIRPORTNAME: "Seno",
    CITYCODE: "SND",
    CITYNAME: "SENO",
    CITYNAME_HEB: "SENO",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "SNE",
    AIRPORTNAME: null,
    CITYCODE: "SNE",
    CITYNAME: "SAO NICOLAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CV",
    COUNTRYNAME: "CAPE VERDE-REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SNF",
    AIRPORTNAME: "San Felipe",
    CITYCODE: "SNF",
    CITYNAME: "SAN FELIPE",
    CITYNAME_HEB: "SAN FELIPE",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "SNG",
    AIRPORTNAME: "San Ignacio De Velasco",
    CITYCODE: "SNG",
    CITYNAME: "SAN IGNACIO DE VE",
    CITYNAME_HEB: "SAN IGNACIO DE VE",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SNH",
    AIRPORTNAME: "Stanthorpe",
    CITYCODE: "SNH",
    CITYNAME: "STANTHORPE",
    CITYNAME_HEB: "STANTHORPE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SNI",
    AIRPORTNAME: "R.E. Murray",
    CITYCODE: "SNI",
    CITYNAME: "SINOE",
    CITYNAME_HEB: "SINOE",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XSA",
    AIRPORTNAME: "AFC",
    CITYCODE: "SNI",
    CITYNAME: "SINOE",
    CITYNAME_HEB: "SINOE",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SNJ",
    AIRPORTNAME: "San Julian",
    CITYCODE: "SNJ",
    CITYNAME: "SAN JULIAN",
    CITYNAME_HEB: "SAN JULIAN",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "SNK",
    AIRPORTNAME: "Winston Field",
    CITYCODE: "SNK",
    CITYNAME: "SNYDER",
    CITYNAME_HEB: "SNYDER",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SNL",
    AIRPORTNAME: "Municipal",
    CITYCODE: "SNL",
    CITYNAME: "SHAWNEE",
    CITYNAME_HEB: "SHAWNEE",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BKI",
    AIRPORTNAME: "Kota Kinabalu",
    CITYCODE: "BKI",
    CITYNAME: "KOTA KINABALU",
    CITYNAME_HEB: "KOTA KINABALU",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SNM",
    AIRPORTNAME: "San Ignacio De M",
    CITYCODE: "SNM",
    CITYNAME: "SAN IGNACIO DE M",
    CITYNAME_HEB: "SAN IGNACIO DE M",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XHC",
    AIRPORTNAME: null,
    CITYCODE: "XHC",
    CITYNAME: "AKASLOMPOLO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XHD",
    AIRPORTNAME: null,
    CITYCODE: "XHD",
    CITYNAME: "RUKA",
    CITYNAME_HEB: "\u05e8\u05d5\u05e7\u05d4",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "XHF",
    AIRPORTNAME: null,
    CITYCODE: "XHF",
    CITYNAME: "HONEFOSS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRJ",
    AIRPORTNAME: " ",
    CITYCODE: "BRJ",
    CITYNAME: "BORJOMI",
    CITYNAME_HEB: "\u05d1\u05d5\u05e8\u05d2\u05d5\u05de\u05d9",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XHI",
    AIRPORTNAME: null,
    CITYCODE: "XHI",
    CITYNAME: "HERZOGENAURACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XHL",
    AIRPORTNAME: null,
    CITYCODE: "XHL",
    CITYNAME: "NELAUG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XHM",
    AIRPORTNAME: null,
    CITYCODE: "XHM",
    CITYNAME: "GEORGETOWN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XHP",
    AIRPORTNAME: null,
    CITYCODE: "XHP",
    CITYNAME: "HERITAGE PASS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XHR",
    AIRPORTNAME: null,
    CITYCODE: "XHR",
    CITYNAME: "TIMBO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XHS",
    AIRPORTNAME: null,
    CITYCODE: "XHS",
    CITYNAME: "CHEMAINUS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XHU",
    AIRPORTNAME: null,
    CITYCODE: "XHU",
    CITYNAME: "HUNTINGDON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XHY",
    AIRPORTNAME: null,
    CITYCODE: "XHY",
    CITYNAME: "HENDAYE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XIA",
    AIRPORTNAME: null,
    CITYCODE: "XIA",
    CITYNAME: "GUELPH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XIB",
    AIRPORTNAME: null,
    CITYCODE: "XIB",
    CITYNAME: "INGERSOLL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRK",
    AIRPORTNAME: "Bourke",
    CITYCODE: "BRK",
    CITYNAME: "BOURKE",
    CITYNAME_HEB: "BOURKE",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XIC",
    AIRPORTNAME: "Xichang",
    CITYCODE: "XIC",
    CITYNAME: "XICHANG",
    CITYNAME_HEB: "XICHANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XID",
    AIRPORTNAME: null,
    CITYCODE: "XID",
    CITYNAME: "MAXVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XIE",
    AIRPORTNAME: "Xienglom",
    CITYCODE: "XIE",
    CITYNAME: "XIENGLOM",
    CITYNAME_HEB: "XIENGLOM",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "XIF",
    AIRPORTNAME: null,
    CITYCODE: "XIF",
    CITYNAME: "NAPANEE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XIG",
    AIRPORTNAME: null,
    CITYCODE: "XIG",
    CITYNAME: "XINGUERA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XII",
    AIRPORTNAME: null,
    CITYCODE: "XII",
    CITYNAME: "PRESCOTT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XIL",
    AIRPORTNAME: "Xilinhot",
    CITYCODE: "XIL",
    CITYNAME: "XILINHOT",
    CITYNAME_HEB: "XILINHOT",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XIM",
    AIRPORTNAME: null,
    CITYCODE: "XIM",
    CITYNAME: "SAINT HYACINTHE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XIN",
    AIRPORTNAME: "Xingning",
    CITYCODE: "XIN",
    CITYNAME: "XINGNING",
    CITYNAME_HEB: "XINGNING",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XIO",
    AIRPORTNAME: null,
    CITYCODE: "XIO",
    CITYNAME: "SAINT MARYS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRL",
    AIRPORTNAME: "Burlington",
    CITYCODE: "BRL",
    CITYNAME: "BURLINGTON",
    CITYNAME_HEB: "BURLINGTON",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XIP",
    AIRPORTNAME: null,
    CITYCODE: "XIP",
    CITYNAME: "WOODSTOCK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XIQ",
    AIRPORTNAME: null,
    CITYCODE: "XIQ",
    CITYNAME: "ILIMANAQ HARBOUR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XIS",
    AIRPORTNAME: null,
    CITYCODE: "XIS",
    CITYNAME: "DIONISIO CERQUEIRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XJL",
    AIRPORTNAME: null,
    CITYCODE: "XJL",
    CITYNAME: "JOLIETTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XJQ",
    AIRPORTNAME: null,
    CITYCODE: "XJQ",
    CITYNAME: "JONQUIERE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XJZ",
    AIRPORTNAME: null,
    CITYCODE: "XJZ",
    CITYNAME: "SAINT JEAN DE LUZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "XKA",
    CITYNAME: "KAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKB",
    AIRPORTNAME: null,
    CITYCODE: "XKB",
    CITYNAME: "KONGSBERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKC",
    AIRPORTNAME: null,
    CITYCODE: "XKC",
    CITYNAME: "SANDNES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKD",
    AIRPORTNAME: null,
    CITYCODE: "XKD",
    CITYNAME: "HALDEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRM",
    AIRPORTNAME: "Barquisimeto",
    CITYCODE: "BRM",
    CITYNAME: "BARQUISIMETO",
    CITYNAME_HEB: "BARQUISIMETO",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "XKE",
    AIRPORTNAME: null,
    CITYCODE: "XKE",
    CITYNAME: "RENA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKF",
    AIRPORTNAME: null,
    CITYCODE: "XKF",
    CITYNAME: "FREDRIKSTAD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKG",
    AIRPORTNAME: null,
    CITYCODE: "XKG",
    CITYNAME: "GRONG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKH",
    AIRPORTNAME: "Xieng Khouang",
    CITYCODE: "XKH",
    CITYNAME: "XIENG KHOUANG",
    CITYNAME_HEB: "XIENG KHOUANG",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "XKI",
    AIRPORTNAME: null,
    CITYCODE: "XKI",
    CITYNAME: "LILLESTROM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKJ",
    AIRPORTNAME: null,
    CITYCODE: "XKJ",
    CITYNAME: "STEINKJER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKK",
    AIRPORTNAME: null,
    CITYCODE: "XKK",
    CITYNAME: "LARVIK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKM",
    AIRPORTNAME: null,
    CITYCODE: "XKM",
    CITYNAME: "MOSS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKN",
    AIRPORTNAME: null,
    CITYCODE: "XKN",
    CITYNAME: "FINSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKO",
    AIRPORTNAME: "Kemano",
    CITYCODE: "XKO",
    CITYNAME: "KEMANO",
    CITYNAME_HEB: "KEMANO",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BRN",
    AIRPORTNAME: "Belp",
    CITYCODE: "BRN",
    CITYNAME: "BERNE",
    CITYNAME_HEB: "\u05d1\u05e8\u05e0\u05d4",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "XKP",
    AIRPORTNAME: null,
    CITYCODE: "XKP",
    CITYNAME: "PORSGRUNN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKQ",
    AIRPORTNAME: null,
    CITYCODE: "XKQ",
    CITYNAME: "SARPSBORG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKS",
    AIRPORTNAME: "Kasabonika",
    CITYCODE: "XKS",
    CITYNAME: "KASABONIKA",
    CITYNAME_HEB: "KASABONIKA",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XKT",
    AIRPORTNAME: " ",
    CITYCODE: "XKT",
    CITYNAME: "KENNEDY TOWN",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XKU",
    AIRPORTNAME: " ",
    CITYCODE: "XKU",
    CITYNAME: "KUSADASI",
    CITYNAME_HEB: "\u05e7\u05d5\u05e9\u05d3\u05d0\u05e1\u05d9",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XKV",
    AIRPORTNAME: null,
    CITYCODE: "XKV",
    CITYNAME: "SACKVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CANB",
    COUNTRYNAME: "NEW BRUNSWICK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKW",
    AIRPORTNAME: null,
    CITYCODE: "XKW",
    CITYNAME: "TONSBERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XKY",
    AIRPORTNAME: "Kaya",
    CITYCODE: "XKY",
    CITYNAME: "KAYA",
    CITYNAME_HEB: "KAYA",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "XKZ",
    AIRPORTNAME: null,
    CITYCODE: "XKZ",
    CITYNAME: "VINSTRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XLA",
    AIRPORTNAME: null,
    CITYCODE: "XLA",
    CITYNAME: "LA BASTIDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZDJ",
    AIRPORTNAME: " ",
    CITYCODE: "BRN",
    CITYNAME: "BERNE",
    CITYNAME_HEB: "\u05d1\u05e8\u05e0\u05d4",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "XLB",
    AIRPORTNAME: "Lac Brochet",
    CITYCODE: "XLB",
    CITYNAME: "LAC BROCHET",
    CITYNAME_HEB: "LAC BROCHET",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XLD",
    AIRPORTNAME: null,
    CITYCODE: "XLD",
    CITYNAME: "LANDERNEAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XLE",
    AIRPORTNAME: " ",
    CITYCODE: "XLE",
    CITYNAME: "MARILLEVA",
    CITYNAME_HEB: "\u05de\u05e8\u05d9\u05dc\u05d1\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XLF",
    AIRPORTNAME: "Leaf Bay",
    CITYCODE: "XLF",
    CITYNAME: "LEAF BAY",
    CITYNAME_HEB: "LEAF BAY",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XLG",
    AIRPORTNAME: null,
    CITYCODE: "XLG",
    CITYNAME: "LOGNES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XLH",
    AIRPORTNAME: null,
    CITYCODE: "XLH",
    CITYNAME: "LUCHON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XLI",
    AIRPORTNAME: null,
    CITYCODE: "XLI",
    CITYNAME: "SAINT LOUIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XLL",
    AIRPORTNAME: null,
    CITYCODE: "XLL",
    CITYNAME: "LONS LE SAUNIER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XLM",
    AIRPORTNAME: null,
    CITYCODE: "XLM",
    CITYNAME: "SAINT LAMBERT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRO",
    AIRPORTNAME: "South Padre Is. International",
    CITYCODE: "BRO",
    CITYNAME: "BROWNSVILLE",
    CITYNAME_HEB: "BROWNSVILLE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XLN",
    AIRPORTNAME: null,
    CITYCODE: "XLN",
    CITYNAME: "LAON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XLO",
    AIRPORTNAME: "Long Xuyen",
    CITYCODE: "XLO",
    CITYNAME: "LONG XUYEN",
    CITYNAME_HEB: "LONG XUYEN",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "XLP",
    AIRPORTNAME: null,
    CITYCODE: "XLP",
    CITYNAME: "MATAPEDIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XLQ",
    AIRPORTNAME: null,
    CITYCODE: "XLQ",
    CITYNAME: "GUILDWOOD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XLR",
    AIRPORTNAME: null,
    CITYCODE: "XLR",
    CITYNAME: "LIBOURNE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XLS",
    AIRPORTNAME: "St Louis",
    CITYCODE: "XLS",
    CITYNAME: "SAINT LOUIS",
    CITYNAME_HEB: "SAINT LOUIS",
    COUNTRYCODE: "SN",
    COUNTRYNAME: "SENEGAL",
    COUNTRYNAME_HEB: "\u05e1\u05e0\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "XLT",
    AIRPORTNAME: null,
    CITYCODE: "XLT",
    CITYNAME: "AX LES THERMES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XLU",
    AIRPORTNAME: "Leo",
    CITYCODE: "XLU",
    CITYNAME: "LEO",
    CITYNAME_HEB: "LEO",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "XLV",
    AIRPORTNAME: null,
    CITYCODE: "XLV",
    CITYNAME: "NIAGARA FALLS",
    CITYNAME_HEB:
      "\u05de\u05e4\u05dc\u05d9 \u05e0\u05d9\u05d0\u05d2\u05e8\u05d4",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XLW",
    AIRPORTNAME: "Lemwerder",
    CITYCODE: "XLW",
    CITYNAME: "LEMWERDER",
    CITYNAME_HEB: "LEMWERDER",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BRP",
    AIRPORTNAME: "Biaru",
    CITYCODE: "BRP",
    CITYNAME: "BIARU",
    CITYNAME_HEB: "BIARU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "XLX",
    AIRPORTNAME: null,
    CITYCODE: "XLX",
    CITYNAME: "LISIEUX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XLY",
    AIRPORTNAME: null,
    CITYCODE: "XLY",
    CITYNAME: "ALDERSHOT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XLZ",
    AIRPORTNAME: null,
    CITYCODE: "XLZ",
    CITYNAME: "TRURO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CANS",
    COUNTRYNAME: "NOVA SCOTIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XMA",
    AIRPORTNAME: "Maramag",
    CITYCODE: "XMA",
    CITYNAME: "MARAMAG",
    CITYNAME_HEB: "MARAMAG",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "XMB",
    AIRPORTNAME: "M'Bahiakro",
    CITYCODE: "XMB",
    CITYNAME: "M'BAHIAKRO",
    CITYNAME_HEB: "M'BAHIAKRO",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "XMC",
    AIRPORTNAME: "Mallacoota",
    CITYCODE: "XMC",
    CITYNAME: "MALLACOOTA",
    CITYNAME_HEB: "MALLACOOTA",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XMD",
    AIRPORTNAME: "Madison",
    CITYCODE: "XMD",
    CITYNAME: "MADISON",
    CITYNAME_HEB: "MADISON",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XME",
    AIRPORTNAME: null,
    CITYCODE: "XME",
    CITYNAME: "MAUBEUGE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XMF",
    AIRPORTNAME: null,
    CITYCODE: "XMF",
    CITYNAME: "MONTBELIARD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XMG",
    AIRPORTNAME: "Mahendranagar",
    CITYCODE: "XMG",
    CITYNAME: "MAHENDRANAGAR",
    CITYNAME_HEB: "MAHENDRANAGAR",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "AEL",
    AIRPORTNAME: null,
    CITYCODE: "AEL",
    CITYNAME: "ALBERT LEA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRQ",
    AIRPORTNAME: "Turany",
    CITYCODE: "BRQ",
    CITYNAME: "BRNO",
    CITYNAME_HEB: "\u05d1\u05e8\u05e0\u05d5",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XMH",
    AIRPORTNAME: "Manihi",
    CITYCODE: "XMH",
    CITYNAME: "MANIHI",
    CITYNAME_HEB: "MANIHI",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "XMI",
    AIRPORTNAME: "Masasi",
    CITYCODE: "XMI",
    CITYNAME: "MASASI",
    CITYNAME_HEB: "MASASI",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XMJ",
    AIRPORTNAME: null,
    CITYCODE: "XMJ",
    CITYNAME: "MONT DE MARSAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XMK",
    AIRPORTNAME: null,
    CITYCODE: "XMK",
    CITYNAME: "MIKOLAJKI",
    CITYNAME_HEB: "\u05de\u05d9\u05e7\u05d5\u05dc\u05d9\u05d0\u05e7\u05d9",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XML",
    AIRPORTNAME: "Minlaton",
    CITYCODE: "XML",
    CITYNAME: "MINLATON",
    CITYNAME_HEB: "MINLATON",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XMM",
    AIRPORTNAME: null,
    CITYCODE: "XMM",
    CITYNAME: "MONACO MONTE CARLO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "MC",
    COUNTRYNAME: "MONACO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XMN",
    AIRPORTNAME: "Xiamen",
    CITYCODE: "XMN",
    CITYNAME: "XIAMEN",
    CITYNAME_HEB: "\u05e7\u05e1\u05d9\u05de\u05df",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XMO",
    AIRPORTNAME: null,
    CITYCODE: "XMO",
    CITYNAME: "MODANE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XMP",
    AIRPORTNAME: "Macmillan Pass",
    CITYCODE: "XMP",
    CITYNAME: "MACMILLAN PASS",
    CITYNAME_HEB: "MACMILLAN PASS",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XMQ",
    AIRPORTNAME: " ",
    CITYCODE: "XMQ",
    CITYNAME: "MORZINE",
    CITYNAME_HEB: "\u05de\u05d5\u05e8\u05d6\u05d9\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "XMR",
    AIRPORTNAME: null,
    CITYCODE: "XMR",
    CITYNAME: "MARMANDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XMS",
    AIRPORTNAME: "Macas",
    CITYCODE: "XMS",
    CITYNAME: "MACAS",
    CITYNAME_HEB: "MACAS",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "XMT",
    AIRPORTNAME: null,
    CITYCODE: "XMT",
    CITYNAME: "MENTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XMU",
    AIRPORTNAME: null,
    CITYCODE: "XMU",
    CITYNAME: "MOULIN SUR ALLIER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XMV",
    AIRPORTNAME: null,
    CITYCODE: "XMV",
    CITYNAME: "PICHANAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XMW",
    AIRPORTNAME: null,
    CITYCODE: "XMW",
    CITYNAME: "MONTAUBAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XMX",
    AIRPORTNAME: null,
    CITYCODE: "XMX",
    CITYNAME: "LEDESMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XMY",
    AIRPORTNAME: "Yam Island",
    CITYCODE: "XMY",
    CITYNAME: "YAM ISLAND",
    CITYNAME_HEB: "YAM ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XMZ",
    AIRPORTNAME: null,
    CITYCODE: "XMZ",
    CITYNAME: "MACCLESFIELD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "XNA",
    CITYNAME: "MOENA",
    CITYNAME_HEB: "\u05de\u05d5\u05d0\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BRR",
    AIRPORTNAME: "North Bay",
    CITYCODE: "BRR",
    CITYNAME: "BARRA",
    CITYNAME_HEB: "BARRA",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XND",
    AIRPORTNAME: null,
    CITYCODE: "XND",
    CITYNAME: "DRAMMEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XNG",
    AIRPORTNAME: "Quang Ngai",
    CITYCODE: "XNG",
    CITYNAME: "QUANG NGAI",
    CITYNAME_HEB: "QUANG NGAI",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "XNN",
    AIRPORTNAME: "Xining",
    CITYCODE: "XNN",
    CITYNAME: "XINING",
    CITYNAME_HEB: "XINING",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XNO",
    AIRPORTNAME: null,
    CITYCODE: "XNO",
    CITYNAME: "NORTHALLERTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XNR",
    AIRPORTNAME: null,
    CITYCODE: "XNR",
    CITYNAME: "AABENRAA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XNS",
    AIRPORTNAME: null,
    CITYCODE: "XNS",
    CITYNAME: "ANSAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XNT",
    AIRPORTNAME: "Xingtai",
    CITYCODE: "XNT",
    CITYNAME: "XINGTAI",
    CITYNAME_HEB: "XINGTAI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XNU",
    AIRPORTNAME: "Nouna",
    CITYCODE: "XNU",
    CITYNAME: "NOUNA",
    CITYNAME_HEB: "NOUNA",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "QWD",
    AIRPORTNAME: null,
    CITYCODE: "QWD",
    CITYNAME: "MITTENWALD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWI",
    AIRPORTNAME: null,
    CITYCODE: "QWI",
    CITYNAME: "SCHLESWIG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWJ",
    AIRPORTNAME: null,
    CITYCODE: "QWJ",
    CITYNAME: "AMERICANA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWK",
    AIRPORTNAME: null,
    CITYCODE: "QWK",
    CITYNAME: "WLOCLAWEK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWL",
    AIRPORTNAME: null,
    CITYCODE: "QWL",
    CITYNAME: "CRACKENBACK VILL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWM",
    AIRPORTNAME: null,
    CITYCODE: "QWM",
    CITYNAME: "LONGMONT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BHC",
    AIRPORTNAME: "Bhurban Heliport",
    CITYCODE: "BHC",
    CITYNAME: "BHURBAN",
    CITYNAME_HEB: "BHURBAN",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "QWN",
    AIRPORTNAME: null,
    CITYCODE: "QWN",
    CITYNAME: "ASTORGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWO",
    AIRPORTNAME: null,
    CITYCODE: "QWO",
    CITYNAME: "HOLSTEBRO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWP",
    AIRPORTNAME: null,
    CITYCODE: "QWP",
    CITYNAME: "WINTER PARK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWQ",
    AIRPORTNAME: null,
    CITYCODE: "QWQ",
    CITYNAME: "STRUER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWR",
    AIRPORTNAME: null,
    CITYCODE: "QWR",
    CITYNAME: "DONAUWOERTH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWS",
    AIRPORTNAME: null,
    CITYCODE: "QWS",
    CITYNAME: "NOWY TARG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWT",
    AIRPORTNAME: null,
    CITYCODE: "QWT",
    CITYNAME: "TALAVERA DE LA REINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWU",
    AIRPORTNAME: null,
    CITYCODE: "QWU",
    CITYNAME: "WUERZBURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWV",
    AIRPORTNAME: null,
    CITYCODE: "QWV",
    CITYNAME: "VALJEVO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QWW",
    AIRPORTNAME: null,
    CITYCODE: "QWW",
    CITYNAME: "NAVALMORAL DE LA MATA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BHE",
    AIRPORTNAME: "Blenheim",
    CITYCODE: "BHE",
    CITYNAME: "BLENHEIM",
    CITYNAME_HEB: "BLENHEIM",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "QWX",
    AIRPORTNAME: null,
    CITYCODE: "QWX",
    CITYNAME: "MERIDA",
    CITYNAME_HEB: "\u05de\u05d3\u05e8\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "QXB",
    AIRPORTNAME: " ",
    CITYCODE: "QXB",
    CITYNAME: "AIX EN PROVENCE",
    CITYNAME_HEB: "\u05e4\u05e8\u05d5\u05d1\u05d0\u05e0\u05e1",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "QXC",
    AIRPORTNAME: null,
    CITYCODE: "QXC",
    CITYNAME: "CAXIAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QXD",
    AIRPORTNAME: null,
    CITYCODE: "QXD",
    CITYNAME: "CACHOEIRO ITAPEMI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QXE",
    AIRPORTNAME: null,
    CITYCODE: "QXE",
    CITYNAME: "SORA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QXF",
    AIRPORTNAME: null,
    CITYCODE: "QXF",
    CITYNAME: "VESTBJERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QXI",
    AIRPORTNAME: null,
    CITYCODE: "QXI",
    CITYNAME: "LOVIISA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QXJ",
    AIRPORTNAME: null,
    CITYCODE: "QXJ",
    CITYNAME: "PORVOO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QXK",
    AIRPORTNAME: null,
    CITYCODE: "QXK",
    CITYNAME: "SAINT GENIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BHF",
    AIRPORTNAME: "Bahia Cupica",
    CITYCODE: "BHF",
    CITYNAME: "BAHIA CUPICA",
    CITYNAME_HEB: "BAHIA CUPICA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QXP",
    AIRPORTNAME: " ",
    CITYCODE: "QXP",
    CITYNAME: "STRUGA",
    CITYNAME_HEB: "\u05e1\u05d8\u05e8\u05d5\u05d2\u05d4",
    COUNTRYCODE: "MK",
    COUNTRYNAME: "MACEDONIA",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05d3\u05d5\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QXQ",
    AIRPORTNAME: null,
    CITYCODE: "QXQ",
    CITYNAME: "STALOWA WOLA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QXR",
    AIRPORTNAME: null,
    CITYCODE: "QXR",
    CITYNAME: "RADOM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QXV",
    AIRPORTNAME: null,
    CITYCODE: "QXV",
    CITYNAME: "SVENDBORG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QXW",
    AIRPORTNAME: null,
    CITYCODE: "QXW",
    CITYNAME: "ALFENAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QXZ",
    AIRPORTNAME: null,
    CITYCODE: "QXZ",
    CITYNAME: "WOERGL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYA",
    AIRPORTNAME: null,
    CITYCODE: "QYA",
    CITYNAME: "ANYANG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYB",
    AIRPORTNAME: null,
    CITYCODE: "QYB",
    CITYNAME: "YABA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BHG",
    AIRPORTNAME: "Brus Laguna",
    CITYCODE: "BHG",
    CITYNAME: "BRUS LAGUNA",
    CITYNAME_HEB: "BRUS LAGUNA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "QYC",
    AIRPORTNAME: null,
    CITYCODE: "QYC",
    CITYNAME: "DRACHTEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYD",
    AIRPORTNAME: null,
    CITYCODE: "QYD",
    CITYNAME: "GDYNIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYF",
    AIRPORTNAME: null,
    CITYCODE: "QYF",
    CITYNAME: "RAILWAYS ZONE F",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYG",
    AIRPORTNAME: null,
    CITYCODE: "QYG",
    CITYNAME: "RAILWAY GERMANY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYH",
    AIRPORTNAME: null,
    CITYCODE: "QYH",
    CITYNAME: "HENGELO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYI",
    AIRPORTNAME: null,
    CITYCODE: "QYI",
    CITYNAME: "HILVERSUM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYJ",
    AIRPORTNAME: null,
    CITYCODE: "QYJ",
    CITYNAME: "RAILWAYS ZONE J",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYK",
    AIRPORTNAME: null,
    CITYCODE: "QYK",
    CITYNAME: "GOYANG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYL",
    AIRPORTNAME: null,
    CITYCODE: "QYL",
    CITYNAME: "ALMELO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AAL",
    AIRPORTNAME: "Aalborg",
    CITYCODE: "AAL",
    CITYNAME: "AALBORG",
    CITYNAME_HEB: "\u05d0\u05dc\u05d0\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "ADH",
    AIRPORTNAME: "Aldan",
    CITYCODE: "ADH",
    CITYNAME: "ALDAN",
    CITYNAME_HEB: "ALDAN",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BHI",
    AIRPORTNAME: " ",
    CITYCODE: "BHI",
    CITYNAME: "BAHIA BLANCA",
    CITYNAME_HEB:
      "\u05d1\u05d0\u05d9\u05d9\u05d4 \u05d1\u05dc\u05e0\u05e7\u05d4",
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05d2\u05e0\u05d8\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "QYM",
    AIRPORTNAME: null,
    CITYCODE: "QYM",
    CITYNAME: "AMERSFOORT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYN",
    AIRPORTNAME: null,
    CITYCODE: "QYN",
    CITYNAME: "BYRON BAY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AU",
    COUNTRYNAME: "AUSTRALIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYO",
    AIRPORTNAME: null,
    CITYCODE: "QYO",
    CITYNAME: "OLSZTYN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYP",
    AIRPORTNAME: null,
    CITYCODE: "QYP",
    CITYNAME: "APELDOORN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYQ",
    AIRPORTNAME: null,
    CITYCODE: "QYQ",
    CITYNAME: "SULSTED",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYR",
    AIRPORTNAME: null,
    CITYCODE: "QYR",
    CITYNAME: "TROYES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYT",
    AIRPORTNAME: null,
    CITYCODE: "QYT",
    CITYNAME: "PATERSWOLDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYV",
    AIRPORTNAME: null,
    CITYCODE: "QYV",
    CITYNAME: "DEVENTER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYX",
    AIRPORTNAME: null,
    CITYCODE: "QYX",
    CITYNAME: "UPPSALA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QYY",
    AIRPORTNAME: null,
    CITYCODE: "QYY",
    CITYNAME: "BIALYSTOK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BHJ",
    AIRPORTNAME: "Rudra Mata",
    CITYCODE: "BHJ",
    CITYNAME: "BHUJ",
    CITYNAME_HEB: "BHUJ",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "QYZ",
    AIRPORTNAME: null,
    CITYCODE: "QYZ",
    CITYNAME: "HEERENVEEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZA",
    AIRPORTNAME: null,
    CITYCODE: "QZA",
    CITYNAME: "ZARQA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JO",
    COUNTRYNAME: "JORDAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZB",
    AIRPORTNAME: " ",
    CITYCODE: "QZB",
    CITYNAME: "ZERMATT",
    CITYNAME_HEB: "\u05d6\u05e8\u05de\u05d0\u05d8",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "QZC",
    AIRPORTNAME: null,
    CITYCODE: "QZC",
    CITYNAME: "SMIGGIN HOLES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZD",
    AIRPORTNAME: null,
    CITYCODE: "QZD",
    CITYNAME: "SZEGED",
    CITYNAME_HEB: null,
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZE",
    AIRPORTNAME: null,
    CITYCODE: "QZE",
    CITYNAME: "MONT LOUIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZF",
    AIRPORTNAME: null,
    CITYCODE: "QZF",
    CITYNAME: "FONT ROMEU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZG",
    AIRPORTNAME: null,
    CITYCODE: "QZG",
    CITYNAME: "LA LLAGONE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZH",
    AIRPORTNAME: null,
    CITYCODE: "QZH",
    CITYNAME: "LES ANGLES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZI",
    AIRPORTNAME: null,
    CITYCODE: "QZI",
    CITYNAME: "TIZI OUZOU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BHK",
    AIRPORTNAME: "Bukhara",
    CITYCODE: "BHK",
    CITYNAME: "BUKHARA",
    CITYNAME_HEB: "\u05d1\u05d5\u05db\u05e8\u05d4",
    COUNTRYCODE: "UZ",
    COUNTRYNAME: "UZBEKISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d6\u05d1\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "QZJ",
    AIRPORTNAME: null,
    CITYCODE: "QZJ",
    CITYNAME: "LOIMAA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZK",
    AIRPORTNAME: null,
    CITYCODE: "QZK",
    CITYNAME: "MANTSALA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZL",
    AIRPORTNAME: null,
    CITYCODE: "QZL",
    CITYNAME: "ZLITEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZM",
    AIRPORTNAME: null,
    CITYCODE: "QZM",
    CITYNAME: "BULLOCKS FLAT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZN",
    AIRPORTNAME: null,
    CITYCODE: "QZN",
    CITYNAME: "RELIZANE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZO",
    AIRPORTNAME: " ",
    CITYCODE: "QZO",
    CITYNAME: "AREZZO",
    CITYNAME_HEB: "\u05d0\u05e8\u05d0\u05d6\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QZP",
    AIRPORTNAME: null,
    CITYCODE: "QZP",
    CITYNAME: "GC APOLLO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZQ",
    AIRPORTNAME: null,
    CITYCODE: "QZQ",
    CITYNAME: "ZAHLEH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LB",
    COUNTRYNAME: "LEBANON",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZR",
    AIRPORTNAME: null,
    CITYCODE: "QZR",
    CITYNAME: "APRILIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZS",
    AIRPORTNAME: null,
    CITYCODE: "QZS",
    CITYNAME: "SOEROEYA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BHL",
    AIRPORTNAME: "Bahia Angeles",
    CITYCODE: "BHL",
    CITYNAME: "BAHIA ANGELES",
    CITYNAME_HEB: "BAHIA ANGELES",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "QZT",
    AIRPORTNAME: null,
    CITYCODE: "QZT",
    CITYNAME: "ZAWIA TOWN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZU",
    AIRPORTNAME: null,
    CITYCODE: "QZU",
    CITYNAME: "RAUMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "QZV",
    AIRPORTNAME: null,
    CITYCODE: "QZV",
    CITYNAME: "ROISSY EN FRANCE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RAA",
    AIRPORTNAME: "Rakanda",
    CITYCODE: "RAA",
    CITYNAME: "RAKANDA",
    CITYNAME_HEB: "RAKANDA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "RAB",
    AIRPORTNAME: "Tokua Airport",
    CITYCODE: "RAB",
    CITYNAME: "RABAUL",
    CITYNAME_HEB: "RABAUL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "RAC",
    AIRPORTNAME: "Horlick",
    CITYCODE: "RAC",
    CITYNAME: "RACINE",
    CITYNAME_HEB: "RACINE",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RAG",
    AIRPORTNAME: "Raglan",
    CITYCODE: "RAG",
    CITYNAME: "RAGLAN",
    CITYNAME_HEB: "RAGLAN",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BHM",
    AIRPORTNAME: "Birmingham",
    CITYCODE: "BHM",
    CITYNAME: "BIRMINGHAM",
    CITYNAME_HEB: "Birmingham",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RAI",
    AIRPORTNAME: "Francisco Mendes Airport",
    CITYCODE: "RAI",
    CITYNAME: "PRAIA",
    CITYNAME_HEB: "PRAIA",
    COUNTRYCODE: "CV",
    COUNTRYNAME: "CAPE VERDE-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05d9\u05e4 \u05d5\u05e8\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "RAJ",
    AIRPORTNAME: "Rajkot Civil Airport",
    CITYCODE: "RAJ",
    CITYNAME: "RAJKOT",
    CITYNAME_HEB: "RAJKOT",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "RAK",
    AIRPORTNAME: "Menara Airport",
    CITYCODE: "RAK",
    CITYNAME: "MARRAKECH",
    CITYNAME_HEB: "\u05de\u05e8\u05e7\u05e9",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "JRD",
    AIRPORTNAME: null,
    CITYCODE: "RAL",
    CITYNAME: "RIVERSIDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RAL",
    AIRPORTNAME: "Riverside Municipal",
    CITYCODE: "RAL",
    CITYNAME: "RIVERSIDE",
    CITYNAME_HEB: "RIVERSIDE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RIR",
    AIRPORTNAME: null,
    CITYCODE: "RAL",
    CITYNAME: "RIVERSIDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RAM",
    AIRPORTNAME: "Ramingining",
    CITYCODE: "RAM",
    CITYNAME: "RAMINGINING",
    CITYNAME_HEB: "RAMINGINING",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "RAM",
    CITYNAME: "Ramat Gan",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RAN",
    AIRPORTNAME: "La Spreta Airport",
    CITYCODE: "RAN",
    CITYNAME: "RAVENNA",
    CITYNAME_HEB: "\u05e8\u05d5\u05d5\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BHN",
    AIRPORTNAME: "Beihan",
    CITYCODE: "BHN",
    CITYNAME: "BEIHAN",
    CITYNAME_HEB: "BEIHAN",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "RAO",
    AIRPORTNAME: null,
    CITYCODE: "RAO",
    CITYNAME: "RIBEIRAO PRETO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RAP",
    AIRPORTNAME: "Regional Airport",
    CITYCODE: "RAP",
    CITYNAME: "RAPID CITY",
    CITYNAME_HEB: "RAPID CITY",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RAQ",
    AIRPORTNAME: "Sugimanuru",
    CITYCODE: "RAQ",
    CITYNAME: "RAHA",
    CITYNAME_HEB: "RAHA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RAR",
    AIRPORTNAME: "Rarotonga",
    CITYCODE: "RAR",
    CITYNAME: "RAROTONGA",
    CITYNAME_HEB: "RAROTONGA",
    COUNTRYCODE: "CK",
    COUNTRYNAME: "COOK ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e7\u05d5\u05e7",
  },
  {
    AIRPORTCODE: "RAT",
    AIRPORTNAME: "Raduzhnyi",
    CITYCODE: "RAT",
    CITYNAME: "RADUZHNYI",
    CITYNAME_HEB: "RADUZHNYI",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RAU",
    AIRPORTNAME: "Rangpur",
    CITYCODE: "RAU",
    CITYNAME: "RANGPUR",
    CITYNAME_HEB: "RANGPUR",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "RAV",
    AIRPORTNAME: "Cravo Norte",
    CITYCODE: "RAV",
    CITYNAME: "CRAVO NORTE",
    CITYNAME_HEB: "CRAVO NORTE",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RAW",
    AIRPORTNAME: "Arawa",
    CITYCODE: "RAW",
    CITYNAME: "ARAWA",
    CITYNAME_HEB: "ARAWA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "RAX",
    AIRPORTNAME: "Oram",
    CITYCODE: "RAX",
    CITYNAME: "ORAM",
    CITYNAME_HEB: "ORAM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BHO",
    AIRPORTNAME: "Bhopal",
    CITYCODE: "BHO",
    CITYNAME: "BHOPAL",
    CITYNAME_HEB: "BHOPAL",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "RAY",
    AIRPORTNAME: "Rothesay Heliport",
    CITYCODE: "RAY",
    CITYNAME: "ROTHESAY",
    CITYNAME_HEB: "ROTHESAY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RAZ",
    AIRPORTNAME: "Rawala Kot",
    CITYCODE: "RAZ",
    CITYNAME: "RAWALA KOT",
    CITYNAME_HEB: "RAWALA KOT",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "RBA",
    AIRPORTNAME: "Sale Airport",
    CITYCODE: "RBA",
    CITYNAME: "RABAT",
    CITYNAME_HEB: "\u05e8\u05d0\u05d1\u05d8",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "RBB",
    AIRPORTNAME: null,
    CITYCODE: "RBB",
    CITYNAME: "BORBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RBC",
    AIRPORTNAME: "Robinvale",
    CITYCODE: "RBC",
    CITYNAME: "ROBINVALE",
    CITYNAME_HEB: "ROBINVALE",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RBE",
    AIRPORTNAME: "Ratanakiri",
    CITYCODE: "RBE",
    CITYNAME: "RATANAKIRI",
    CITYNAME_HEB: "RATANAKIRI",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RBF",
    AIRPORTNAME: "Big Bear City Airport",
    CITYCODE: "RBF",
    CITYNAME: "BIG BEAR",
    CITYNAME_HEB: "BIG BEAR",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RBG",
    AIRPORTNAME: "Municipal Airport",
    CITYCODE: "RBG",
    CITYNAME: "ROSEBURG",
    CITYNAME_HEB: "ROSEBURG",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RBH",
    AIRPORTNAME: "Brooks Lodge",
    CITYCODE: "RBH",
    CITYNAME: "BROOKS LODGE",
    CITYNAME_HEB: "Brooks Lodge",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RBI",
    AIRPORTNAME: "Rabi",
    CITYCODE: "RBI",
    CITYNAME: "RABI",
    CITYNAME_HEB: "RABI",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "BHP",
    AIRPORTNAME: "Bhojpur",
    CITYCODE: "BHP",
    CITYNAME: "BHOJPUR",
    CITYNAME_HEB: "BHOJPUR",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "RBJ",
    AIRPORTNAME: "Rebun",
    CITYCODE: "RBJ",
    CITYNAME: "REBUN",
    CITYNAME_HEB: "REBUN",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "RBK",
    AIRPORTNAME: "French Valley",
    CITYCODE: "RBK",
    CITYNAME: "RANCHO",
    CITYNAME_HEB: "RANCHO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RBL",
    AIRPORTNAME: "Red Bluff Fss",
    CITYCODE: "RBL",
    CITYNAME: "RED BLUFF",
    CITYNAME_HEB: "RED BLUFF",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RBM",
    AIRPORTNAME: "Wallmuhle",
    CITYCODE: "RBM",
    CITYNAME: "STRAUBING",
    CITYNAME_HEB: "STRAUBING",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RBN",
    AIRPORTNAME: "Fort Jefferson",
    CITYCODE: "RBN",
    CITYNAME: "FORT JEFFERSON",
    CITYNAME_HEB: "FORT JEFFERSON",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RBO",
    AIRPORTNAME: "Robore",
    CITYCODE: "RBO",
    CITYNAME: "ROBORE",
    CITYNAME_HEB: "ROBORE",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RBP",
    AIRPORTNAME: "Rabaraba",
    CITYCODE: "RBP",
    CITYNAME: "RABARABA",
    CITYNAME_HEB: "RABARABA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "RBQ",
    AIRPORTNAME: "Rurrenabaque",
    CITYCODE: "RBQ",
    CITYNAME: "RURRENABAQUE",
    CITYNAME_HEB: "RURRENABAQUE",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RBR",
    AIRPORTNAME: null,
    CITYCODE: "RBR",
    CITYNAME: "RIO BRANCO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RBS",
    AIRPORTNAME: "Orbost",
    CITYCODE: "RBS",
    CITYNAME: "ORBOST",
    CITYNAME_HEB: "ORBOST",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BHQ",
    AIRPORTNAME: "Broken Hill",
    CITYCODE: "BHQ",
    CITYNAME: "BROKEN HILL",
    CITYNAME_HEB: "BROKEN HILL",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RBT",
    AIRPORTNAME: "Marsabit",
    CITYCODE: "RBT",
    CITYNAME: "MARSABIT",
    CITYNAME_HEB: "MARSABIT",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RBU",
    AIRPORTNAME: "Roebourne",
    CITYCODE: "RBU",
    CITYNAME: "ROEBOURNE",
    CITYNAME_HEB: "ROEBOURNE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RBV",
    AIRPORTNAME: "Ramata",
    CITYCODE: "RBV",
    CITYNAME: "RAMATA",
    CITYNAME_HEB: "RAMATA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "RBW",
    AIRPORTNAME: "Municipal Airport",
    CITYCODE: "RBW",
    CITYNAME: "WALTERBORO",
    CITYNAME_HEB: "WALTERBORO",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RBY",
    AIRPORTNAME: "Ruby",
    CITYCODE: "RBY",
    CITYNAME: "RUBY",
    CITYNAME_HEB: "Ruby",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RCB",
    AIRPORTNAME: "Richards Bay",
    CITYCODE: "RCB",
    CITYNAME: "RICHARDS BAY",
    CITYNAME_HEB: "RICHARDS BAY",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "RCE",
    AIRPORTNAME: "Roche Harbor",
    CITYCODE: "RCE",
    CITYNAME: "ROCHE HARBOR",
    CITYNAME_HEB: "ROCHE HARBOR",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RCH",
    AIRPORTNAME: "Riohacha",
    CITYCODE: "RCH",
    CITYNAME: "RIOHACHA",
    CITYNAME_HEB: "RIOHACHA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RCK",
    AIRPORTNAME: "Coffield",
    CITYCODE: "RCK",
    CITYNAME: "ROCKDALE",
    CITYNAME_HEB: "ROCKDALE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RCL",
    AIRPORTNAME: "Redcliffe",
    CITYCODE: "RCL",
    CITYNAME: "REDCLIFFE",
    CITYNAME_HEB: "REDCLIFFE",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "BHR",
    AIRPORTNAME: "Bharatpur",
    CITYCODE: "BHR",
    CITYNAME: "BHARATPUR",
    CITYNAME_HEB: "BHARATPUR",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "RCM",
    AIRPORTNAME: "Richmond",
    CITYCODE: "RCM",
    CITYNAME: "RICHMOND",
    CITYNAME_HEB: "RICHMOND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RCN",
    AIRPORTNAME: "American River",
    CITYCODE: "RCN",
    CITYNAME: "AMERICAN RIVER",
    CITYNAME_HEB: "AMERICAN RIVER",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RCO",
    AIRPORTNAME: "Saint Agnant",
    CITYCODE: "RCO",
    CITYNAME: "ROCHEFORT",
    CITYNAME_HEB: "ROCHEFORT",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "RCQ",
    AIRPORTNAME: null,
    CITYCODE: "RCQ",
    CITYNAME: "RECONQUISTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RCR",
    AIRPORTNAME: "Fulton County",
    CITYCODE: "RCR",
    CITYNAME: "ROCHESTER",
    CITYNAME_HEB: "ROCHESTER",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RCS",
    AIRPORTNAME: "Rochester",
    CITYCODE: "RCS",
    CITYNAME: "ROCHESTER",
    CITYNAME_HEB: "ROCHESTER",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RCT",
    AIRPORTNAME: "Miller Field",
    CITYCODE: "RCT",
    CITYNAME: "REED CITY",
    CITYNAME_HEB: "REED CITY",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RCU",
    AIRPORTNAME: null,
    CITYCODE: "RCU",
    CITYNAME: "RIO CUARTO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RCY",
    AIRPORTNAME: "Rum Cay",
    CITYCODE: "RCY",
    CITYNAME: "RUM CAY",
    CITYNAME_HEB: "RUM CAY",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "RDA",
    AIRPORTNAME: "Rockhampton Downs",
    CITYCODE: "RDA",
    CITYNAME: "ROCKHAMPTON DOWNS",
    CITYNAME_HEB: "ROCKHAMPTON DOWNS",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ADI",
    AIRPORTNAME: "Arandis",
    CITYCODE: "ADI",
    CITYNAME: "ARANDIS",
    CITYNAME_HEB: "ARANDIS",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BHS",
    AIRPORTNAME: "Raglan",
    CITYCODE: "BHS",
    CITYNAME: "BATHURST",
    CITYNAME_HEB: "BATHURST",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RDB",
    AIRPORTNAME: "Red Dog",
    CITYCODE: "RDB",
    CITYNAME: "RED DOG",
    CITYNAME_HEB: "Red Dog",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RDC",
    AIRPORTNAME: null,
    CITYCODE: "RDC",
    CITYNAME: "REDENCAO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RDD",
    AIRPORTNAME: "Redding",
    CITYCODE: "RDD",
    CITYNAME: "REDDING",
    CITYNAME_HEB: "REDDING",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RDE",
    AIRPORTNAME: "Merdey",
    CITYCODE: "RDE",
    CITYNAME: "MERDEY",
    CITYNAME_HEB: "MERDEY",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RDG",
    AIRPORTNAME: "Municipal/Spaatz Fld",
    CITYCODE: "RDG",
    CITYNAME: "READING",
    CITYNAME_HEB: "READING",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RDM",
    AIRPORTNAME: "Roberts Field",
    CITYCODE: "RDM",
    CITYNAME: "REDMOND",
    CITYNAME_HEB: "REDMOND",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RDR",
    AIRPORTNAME: "Grand Forks AFB",
    CITYCODE: "RDR",
    CITYNAME: "RED RIVER",
    CITYNAME_HEB: "RED RIVER",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RDS",
    AIRPORTNAME: null,
    CITYCODE: "RDS",
    CITYNAME: "RINCON DE LOS SAUCES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RDT",
    AIRPORTNAME: " ",
    CITYCODE: "RDT",
    CITYNAME: "RADSTADT",
    CITYNAME_HEB: "\u05e8\u05d0\u05d3\u05e1\u05d8\u05d0\u05d3",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RDU",
    AIRPORTNAME: "Durham/Raleigh Airport",
    CITYCODE: "RDU",
    CITYNAME: "RALEIGH DURHAM",
    CITYNAME_HEB: "RALEIGH DURHAM",
    COUNTRYCODE: "USNC",
    COUNTRYNAME: "NORTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e6\u05e4\u05d5\u05df \u05e7\u05e8\u05d5\u05dc\u05d9\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BHT",
    AIRPORTNAME: "Brighton Downs",
    CITYCODE: "BHT",
    CITYNAME: "BRIGHTON DOWNS",
    CITYNAME_HEB: "BRIGHTON DOWNS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RDV",
    AIRPORTNAME: "Red Devil",
    CITYCODE: "RDV",
    CITYNAME: "RED DEVIL",
    CITYNAME_HEB: "Red Devil",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RDZ",
    AIRPORTNAME: "Marcillac",
    CITYCODE: "RDZ",
    CITYNAME: "RODEZ",
    CITYNAME_HEB: "RODEZ",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "REA",
    AIRPORTNAME: "Reao",
    CITYCODE: "REA",
    CITYNAME: "REAO",
    CITYNAME_HEB: "REAO",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "REB",
    AIRPORTNAME: "Rechlin",
    CITYCODE: "REB",
    CITYNAME: "RECHLIN DE",
    CITYNAME_HEB: "RECHLIN DE",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "REC",
    AIRPORTNAME: null,
    CITYCODE: "REC",
    CITYNAME: "RECIFE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RED",
    AIRPORTNAME: "Mifflin County",
    CITYCODE: "RED",
    CITYNAME: "REEDSVILLE",
    CITYNAME_HEB: "REEDSVILLE",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "REG",
    AIRPORTNAME: "Tito Menniti",
    CITYCODE: "REG",
    CITYNAME: "REGGIO CALABRIA",
    CITYNAME_HEB: "\u05e7\u05dc\u05d1\u05e8\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "REH",
    AIRPORTNAME: "Rehoboth Beach",
    CITYCODE: "REH",
    CITYNAME: "REHOBOTH BEACH",
    CITYNAME_HEB: "REHOBOTH BEACH",
    COUNTRYCODE: "USDE",
    COUNTRYNAME: "DELAWARE",
    COUNTRYNAME_HEB:
      '\u05d3\u05dc\u05d5\u05d5\u05e8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "REI",
    AIRPORTNAME: "Regina",
    CITYCODE: "REI",
    CITYNAME: "REGINA",
    CITYNAME_HEB: "REGINA",
    COUNTRYCODE: "GF",
    COUNTRYNAME: "FRENCH GUIANA",
    COUNTRYNAME_HEB:
      "\u05d2\u05d9\u05e0\u05d0\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "REK",
    AIRPORTNAME: null,
    CITYCODE: "REK",
    CITYNAME: "REYKJAVIK",
    CITYNAME_HEB: "\u05e8\u05e7\u05d5\u05d9\u05d0\u05e7",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BHU",
    AIRPORTNAME: "Bhavnagar",
    CITYCODE: "BHU",
    CITYNAME: "BHAVNAGAR",
    CITYNAME_HEB: "BHAVNAGAR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "REL",
    AIRPORTNAME: null,
    CITYCODE: "REL",
    CITYNAME: "TRELEW",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "REN",
    AIRPORTNAME: "Orenburg",
    CITYCODE: "REN",
    CITYNAME: "ORENBURG",
    CITYNAME_HEB: "ORENBURG",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "REP",
    AIRPORTNAME: "Siem Reap",
    CITYCODE: "REP",
    CITYNAME: "SIEM REAP",
    CITYNAME_HEB: "\u05e1\u05d9\u05d0\u05dd \u05e8\u05d0\u05e4",
    COUNTRYCODE: "KH",
    COUNTRYNAME: "CAMBODIA",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05d1\u05d5\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SAN",
    AIRPORTNAME: "Lindberg Field S.Diego",
    CITYCODE: "SAN",
    CITYNAME: "SAN DIEGO",
    CITYNAME_HEB:
      "\u05e1\u05d0\u05df \u05d3\u05d9\u05d9\u05d2\u05d5 - \u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "MYF",
    AIRPORTNAME: null,
    CITYCODE: "SAN",
    CITYNAME: "SAN DIEGO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NKX",
    AIRPORTNAME: null,
    CITYCODE: "SAN",
    CITYNAME: "SAN DIEGO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NZY",
    AIRPORTNAME: null,
    CITYCODE: "SAN",
    CITYNAME: "SAN DIEGO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BIR",
    AIRPORTNAME: "Biratnagar",
    CITYCODE: "BIR",
    CITYNAME: "BIRATNAGAR",
    CITYNAME_HEB: "BIRATNAGAR",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "SDM",
    AIRPORTNAME: null,
    CITYCODE: "SAN",
    CITYNAME: "SAN DIEGO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SEE",
    AIRPORTNAME: null,
    CITYCODE: "SAN",
    CITYNAME: "SAN DIEGO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SAO",
    AIRPORTNAME: " ",
    CITYCODE: "SAO",
    CITYNAME: "SAO PAULO",
    CITYNAME_HEB: "\u05e1\u05d0\u05d5 \u05e4\u05d0\u05d5\u05dc\u05d5",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "SAP",
    AIRPORTNAME: "Ramon Villeda Morales",
    CITYCODE: "SAP",
    CITYNAME: "SAN PEDRO SULA",
    CITYNAME_HEB: "SAN PEDRO SULA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "SAQ",
    AIRPORTNAME: "San Andros",
    CITYCODE: "SAQ",
    CITYNAME: "SAN ANDROS",
    CITYNAME_HEB: "SAN ANDROS",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "SAR",
    AIRPORTNAME: " ",
    CITYCODE: "SAR",
    CITYNAME: "SARDINIA",
    CITYNAME_HEB: "\u05e1\u05e8\u05d3\u05d9\u05e0\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BIS",
    AIRPORTNAME: "Bismarck",
    CITYCODE: "BIS",
    CITYNAME: "BISMARCK",
    CITYNAME_HEB: "BISMARCK",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SAS",
    AIRPORTNAME: " ",
    CITYCODE: "SAS",
    CITYNAME: "SANSICARIO",
    CITYNAME_HEB: "\u05e1\u05e0\u05e1\u05d9\u05e7\u05d0\u05e8\u05d9\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SAT",
    AIRPORTNAME: "San Antonio International",
    CITYCODE: "SAT",
    CITYNAME: "SAN ANTONIO",
    CITYNAME_HEB:
      "\u05e1\u05d0\u05df \u05d0\u05e0\u05d8\u05d5\u05e0\u05d9\u05d5",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SKF",
    AIRPORTNAME: null,
    CITYCODE: "SAT",
    CITYNAME: "SAN ANTONIO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RND",
    AIRPORTNAME: null,
    CITYCODE: "SAT",
    CITYNAME: "SAN ANTONIO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SSF",
    AIRPORTNAME: null,
    CITYCODE: "SAT",
    CITYNAME: "SAN ANTONIO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SAU",
    AIRPORTNAME: "Sawu",
    CITYCODE: "SAU",
    CITYNAME: "SAWU",
    CITYNAME_HEB: "SAWU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SAV",
    AIRPORTNAME: " ",
    CITYCODE: "SAV",
    CITYNAME: "SAVONA",
    CITYNAME_HEB: "\u05e1\u05d0\u05d1\u05d5\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SAW",
    AIRPORTNAME: "Sabiha Gokcen International",
    CITYCODE: "IST",
    CITYNAME: "ISTANBUL",
    CITYNAME_HEB: "\u05d0\u05d9\u05e1\u05d8\u05e0\u05d1\u05d5\u05dc",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BIT",
    AIRPORTNAME: "Baitadi",
    CITYCODE: "BIT",
    CITYNAME: "BAITADI",
    CITYNAME_HEB: "BAITADI",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "SAX",
    AIRPORTNAME: "Sambu",
    CITYCODE: "SAX",
    CITYNAME: "SAMBU",
    CITYNAME_HEB: "SAMBU",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "SAY",
    AIRPORTNAME: "Siena",
    CITYCODE: "SAY",
    CITYNAME: "SIENA",
    CITYNAME_HEB: "\u05e1\u05d9\u05d9\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SAZ",
    AIRPORTNAME: " ",
    CITYCODE: "SAZ",
    CITYNAME: "SALZKAMMERGUT",
    CITYNAME_HEB:
      "\u05d6\u05d0\u05dc\u05e6\u05e7\u05de\u05e8\u05d2\u05d5\u05d8",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SBA",
    AIRPORTNAME: "Municipal Airport",
    CITYCODE: "SBA",
    CITYNAME: "SANTA BARBARA",
    CITYNAME_HEB: "SANTA BARBARA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SZN",
    AIRPORTNAME: "Santa Cruz Island",
    CITYCODE: "SBA",
    CITYNAME: "SANTA BARBARA",
    CITYNAME_HEB: "SANTA BARBARA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SBB",
    AIRPORTNAME: "Santa Barbara Ba",
    CITYCODE: "SBB",
    CITYNAME: "SANTA BARBARA BA",
    CITYNAME_HEB: "SANTA BARBARA BA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "SBC",
    AIRPORTNAME: "Selbang",
    CITYCODE: "SBC",
    CITYNAME: "SELBANG",
    CITYNAME_HEB: "SELBANG",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SBE",
    AIRPORTNAME: "Suabi",
    CITYCODE: "SBE",
    CITYNAME: "SUABI",
    CITYNAME_HEB: "SUABI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SBF",
    AIRPORTNAME: null,
    CITYCODE: "SBF",
    CITYNAME: "SARDEH BAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SBG",
    AIRPORTNAME: "Sabang",
    CITYCODE: "SBG",
    CITYNAME: "SABANG",
    CITYNAME_HEB: "SABANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ADM",
    AIRPORTNAME: "Ardmore Municipal Airport",
    CITYCODE: "ADM",
    CITYNAME: "ARDMORE",
    CITYNAME_HEB: "Ardmore Municipal Airport",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BIU",
    AIRPORTNAME: "Bildudalur",
    CITYCODE: "BIU",
    CITYNAME: "BILDUDALUR",
    CITYNAME_HEB: "BILDUDALUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SBH",
    AIRPORTNAME: " ",
    CITYCODE: "SBH",
    CITYNAME: "SASBACHWALDEN",
    CITYNAME_HEB:
      "\u05e1\u05d0\u05e1\u05d1\u05d0\u05db\u05d5\u05d5\u05dc\u05d3\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SBI",
    AIRPORTNAME: "Sambailo",
    CITYCODE: "SBI",
    CITYNAME: "KOUNDARA",
    CITYNAME_HEB: "KOUNDARA",
    COUNTRYCODE: "GN",
    COUNTRYNAME: "GUINEA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SBJ",
    AIRPORTNAME: null,
    CITYCODE: "SBJ",
    CITYNAME: "SAO MATEUS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SBK",
    AIRPORTNAME: "Tremuson",
    CITYCODE: "SBK",
    CITYNAME: "SAINT BRIEUC",
    CITYNAME_HEB: "SAINT BRIEUC",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "SBL",
    AIRPORTNAME: "Yacuma",
    CITYCODE: "SBL",
    CITYNAME: "SANTA ANA",
    CITYNAME_HEB: "SANTA ANA",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SBM",
    AIRPORTNAME: "Memorial",
    CITYCODE: "SBM",
    CITYNAME: "SHEBOYGAN",
    CITYNAME_HEB: "SHEBOYGAN",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SBN",
    AIRPORTNAME: "South Bend Regional",
    CITYCODE: "SBN",
    CITYNAME: "SOUTH BEND",
    CITYNAME_HEB: "SOUTH BEND",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SBO",
    AIRPORTNAME: "Salina",
    CITYCODE: "SBO",
    CITYNAME: "SALINA",
    CITYNAME_HEB: "SALINA",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SBQ",
    AIRPORTNAME: "Sibi",
    CITYCODE: "SBQ",
    CITYNAME: "SIBI",
    CITYNAME_HEB: "SIBI",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "SBR",
    AIRPORTNAME: "Saibai Island",
    CITYCODE: "SBR",
    CITYNAME: "SAIBAI ISLAND",
    CITYNAME_HEB: "SAIBAI ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BIV",
    AIRPORTNAME: null,
    CITYCODE: "BIV",
    CITYNAME: "BRIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SBS",
    AIRPORTNAME: "Steamboat Springs",
    CITYCODE: "SBS",
    CITYNAME: "STEAMBOAT SPRINGS",
    CITYNAME_HEB: "STEAMBOAT SPRINGS",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SBD",
    AIRPORTNAME: "Norton AFB",
    CITYCODE: "SBT",
    CITYNAME: "SAN BERNARDINO",
    CITYNAME_HEB: "SAN BERNARDINO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SBT",
    AIRPORTNAME: "Tri-City",
    CITYCODE: "SBT",
    CITYNAME: "SAN BERNARDINO",
    CITYNAME_HEB: "SAN BERNARDINO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SBU",
    AIRPORTNAME: "Springbok",
    CITYCODE: "SBU",
    CITYNAME: "SPRINGBOK",
    CITYNAME_HEB: "SPRINGBOK",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "SBV",
    AIRPORTNAME: "Sabah",
    CITYCODE: "SBV",
    CITYNAME: "SABAH",
    CITYNAME_HEB: "SABAH",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SBW",
    AIRPORTNAME: "Sibu",
    CITYCODE: "SBW",
    CITYNAME: "SIBU",
    CITYNAME_HEB: "SIBU",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SBX",
    AIRPORTNAME: "Shelby",
    CITYCODE: "SBX",
    CITYNAME: "SHELBY",
    CITYNAME_HEB: "SHELBY",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SBY",
    AIRPORTNAME: "Wicomico Regional",
    CITYCODE: "SBY",
    CITYNAME: "SALISBURY",
    CITYNAME_HEB: "SALISBURY",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SBZ",
    AIRPORTNAME: "Sibiu",
    CITYCODE: "SBZ",
    CITYNAME: "SIBIU",
    CITYNAME_HEB: "\u05e1\u05d9\u05d1\u05d9\u05d0\u05d5",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SCA",
    AIRPORTNAME: "Santa Catalina",
    CITYCODE: "SCA",
    CITYNAME: "SANTA CATALINA",
    CITYNAME_HEB: "SANTA CATALINA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BIW",
    AIRPORTNAME: "Billiluna",
    CITYCODE: "BIW",
    CITYNAME: "BILLILUNA",
    CITYNAME_HEB: "BILLILUNA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SCB",
    AIRPORTNAME: "State",
    CITYCODE: "SCB",
    CITYNAME: "SCRIBNER",
    CITYNAME_HEB: "SCRIBNER",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SCC",
    AIRPORTNAME: "Prudhoe Bay/Deadhorse",
    CITYCODE: "SCC",
    CITYNAME: "PRUDHOE BAY DEADH",
    CITYNAME_HEB: "Prudhoe Bay/Deadhorse",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SCD",
    AIRPORTNAME: "Sulaco",
    CITYCODE: "SCD",
    CITYNAME: "SULACO",
    CITYNAME_HEB: "SULACO",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "SCE",
    AIRPORTNAME: null,
    CITYCODE: "SCE",
    CITYNAME: "SACILE",
    CITYNAME_HEB: "\u05e1\u05d0\u05e7\u05d9\u05d0\u05dc\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SCG",
    AIRPORTNAME: "Spring Creek",
    CITYCODE: "SCG",
    CITYNAME: "SPRING CREEK",
    CITYNAME_HEB: "SPRING CREEK",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SCI",
    AIRPORTNAME: "San Cristobal",
    CITYCODE: "SCI",
    CITYNAME: "SAN CRISTOBAL",
    CITYNAME_HEB:
      "\u05e1\u05df \u05e7\u05e8\u05d9\u05e1\u05d8\u05d5\u05d1\u05dc",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "SCJ",
    AIRPORTNAME: "Smith Cove",
    CITYCODE: "SCJ",
    CITYNAME: "SMITH COVE",
    CITYNAME_HEB: "Smith Cove",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BIX",
    AIRPORTNAME: "Keesler AFB",
    CITYCODE: "BIX",
    CITYNAME: "BILOXI",
    CITYNAME_HEB: "BILOXI",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SCK",
    AIRPORTNAME: "Stockton",
    CITYCODE: "SCK",
    CITYNAME: "STOCKTON",
    CITYNAME_HEB: "STOCKTON",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SCL",
    AIRPORTNAME: "Arturo Merino Benitez",
    CITYCODE: "SCL",
    CITYNAME: "SANTIAGO",
    CITYNAME_HEB: "\u05e1\u05d0\u05e0\u05d8\u05d9\u05d0\u05d2\u05d5",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "SCM",
    AIRPORTNAME: "Scammon Bay",
    CITYCODE: "SCM",
    CITYNAME: "SCAMMON BAY",
    CITYNAME_HEB: "Scammon Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "QFZ",
    AIRPORTNAME: null,
    CITYCODE: "SCN",
    CITYNAME: "SAARBRUCKEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SCN",
    AIRPORTNAME: "Ensheim",
    CITYCODE: "SCN",
    CITYNAME: "SAARBRUCKEN",
    CITYNAME_HEB: "SAARBRUCKEN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZQW",
    AIRPORTNAME: null,
    CITYCODE: "SCN",
    CITYNAME: "SAARBRUCKEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SCO",
    AIRPORTNAME: "Aktau",
    CITYCODE: "SCO",
    CITYNAME: "AKTAU",
    CITYNAME_HEB: "AKTAU",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "SCP",
    AIRPORTNAME: "St Crepin",
    CITYCODE: "SCP",
    CITYNAME: "SAINT CREPIN",
    CITYNAME_HEB: "SAINT CREPIN",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "SCQ",
    AIRPORTNAME: "Santiago De Compostela",
    CITYCODE: "SCQ",
    CITYNAME: "SANTIAGO",
    CITYNAME_HEB: "\u05e1\u05e0\u05d8\u05d9\u05d0\u05d2\u05d5",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "BIY",
    AIRPORTNAME: "Bisho",
    CITYCODE: "BIY",
    CITYNAME: "BISHO",
    CITYNAME_HEB: "BISHO",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "SCT",
    AIRPORTNAME: "Socotra",
    CITYCODE: "SCT",
    CITYNAME: "SOCOTRA",
    CITYNAME_HEB: "SOCOTRA",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "SCV",
    AIRPORTNAME: "Salcea",
    CITYCODE: "SCV",
    CITYNAME: "SUCEAVA",
    CITYNAME_HEB: "SUCEAVA",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SCW",
    AIRPORTNAME: "Syktyvkar",
    CITYCODE: "SCW",
    CITYNAME: "SYKTYVKAR",
    CITYNAME_HEB: "SYKTYVKAR",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SCX",
    AIRPORTNAME: "Salina Cruz",
    CITYCODE: "SCX",
    CITYNAME: "SALINA CRUZ",
    CITYNAME_HEB: "SALINA CRUZ",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "SCY",
    AIRPORTNAME: "San Cristobal Airport",
    CITYCODE: "SCY",
    CITYNAME: "SAN CRISTOBAL",
    CITYNAME_HEB:
      "\u05e1\u05df \u05e7\u05e8\u05d9\u05e1\u05d8\u05d5\u05d1\u05dc",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "SCZ",
    AIRPORTNAME: "Santa Cruz Is",
    CITYCODE: "SCZ",
    CITYNAME: "SANTA CRUZ IS",
    CITYNAME_HEB: "SANTA CRUZ ISLAND",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "SDB",
    AIRPORTNAME: "Langebaanweg",
    CITYCODE: "SDB",
    CITYNAME: "SALDANHA BAY",
    CITYNAME_HEB: "SALDANHA BAY",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "SDC",
    AIRPORTNAME: "Sandcreek",
    CITYCODE: "SDC",
    CITYNAME: "SANDCREEK",
    CITYNAME_HEB: "SANDCREEK",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "BIZ",
    AIRPORTNAME: "Bimin",
    CITYCODE: "BIZ",
    CITYNAME: "BIMIN",
    CITYNAME_HEB: "BIMIN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SDD",
    AIRPORTNAME: "Lubango",
    CITYCODE: "SDD",
    CITYNAME: "LUBANGO",
    CITYNAME_HEB: "LUBANGO",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "SDE",
    AIRPORTNAME: null,
    CITYCODE: "SDE",
    CITYNAME: "SANTIAGO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LJC",
    AIRPORTNAME: null,
    CITYCODE: "SDF",
    CITYNAME: "LOUISVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LOU",
    AIRPORTNAME: null,
    CITYCODE: "SDF",
    CITYNAME: "LOUISVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SDF",
    AIRPORTNAME: "Standiford Field",
    CITYCODE: "SDF",
    CITYNAME: "LOUISVILLE",
    CITYNAME_HEB: "\u05dc\u05d5\u05d0\u05d9\u05e1\u05d5\u05d9\u05dc",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SDH",
    AIRPORTNAME: "Santa Rosa Copan",
    CITYCODE: "SDH",
    CITYNAME: "SANTA ROSA COPAN",
    CITYNAME_HEB: "SANTA ROSA COPAN",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "SDI",
    AIRPORTNAME: "Saidor",
    CITYCODE: "SDI",
    CITYNAME: "SAIDOR",
    CITYNAME_HEB: "SAIDOR",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SDJ",
    AIRPORTNAME: "Sendai",
    CITYCODE: "SDJ",
    CITYNAME: "SENDAI",
    CITYNAME_HEB: "SENDAI",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "SDK",
    AIRPORTNAME: " ",
    CITYCODE: "SDK",
    CITYNAME: "SANDANSKI",
    CITYNAME_HEB: "\u05e1\u05d0\u05e0\u05d3\u05e0\u05e1\u05e7\u05d9",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BJA",
    AIRPORTNAME: "Bejaia",
    CITYCODE: "BJA",
    CITYNAME: "BEJAIA",
    CITYNAME_HEB: "BEJAIA",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SDL",
    AIRPORTNAME: "Sundsvall/Harnosand",
    CITYCODE: "SDL",
    CITYNAME: "SUNDSVALL",
    CITYNAME_HEB: "SUNDSVALL",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XXZ",
    AIRPORTNAME: null,
    CITYCODE: "SDL",
    CITYNAME: "SUNDSVALL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SDN",
    AIRPORTNAME: " ",
    CITYCODE: "SDN",
    CITYNAME: "SOLDEN",
    CITYNAME_HEB: "\u05e1\u05d5\u05dc\u05d3\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SDO",
    AIRPORTNAME: "Ryotsu Sado",
    CITYCODE: "SDO",
    CITYNAME: "RYOTSU SADO IS",
    CITYNAME_HEB: "RYOTSU SADO IS",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "SDP",
    AIRPORTNAME: "Sand Point",
    CITYCODE: "SDP",
    CITYNAME: "SAND POINT",
    CITYNAME_HEB: "Sand Point",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SDQ",
    AIRPORTNAME: "Las Americas",
    CITYCODE: "SDQ",
    CITYNAME: "SANTO DOMINGO",
    CITYNAME_HEB:
      "\u05e1\u05e0\u05d8\u05d5 \u05d3\u05d5\u05de\u05d9\u05e0\u05d2\u05d5",
    COUNTRYCODE: "DO",
    COUNTRYNAME: "DOMINICAN REPUBLIC",
    COUNTRYNAME_HEB:
      "\u05d4\u05e8\u05e4\u05d5\u05d1\u05dc\u05d9\u05e7\u05d4 \u05d4\u05d3\u05d5\u05de\u05d9\u05e0\u05d9\u05e7\u05e0\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "SDR",
    AIRPORTNAME: "Santander",
    CITYCODE: "SDR",
    CITYNAME: "SANTANDER",
    CITYNAME_HEB: "\u05e1\u05d0\u05e0\u05d8\u05e0\u05d3\u05e8",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "SDS",
    AIRPORTNAME: "Sado Shima",
    CITYCODE: "SDS",
    CITYNAME: "SADO SHIMA",
    CITYNAME_HEB: "SADO SHIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "BJC",
    AIRPORTNAME: "Jeffco",
    CITYCODE: "BJC",
    CITYNAME: "BROOMFIELD",
    CITYNAME_HEB: "BROOMFIELD",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SDT",
    AIRPORTNAME: "Saidu Sharif",
    CITYCODE: "SDT",
    CITYNAME: "SAIDU SHARIF",
    CITYNAME_HEB: "SAIDU SHARIF",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "SDW",
    AIRPORTNAME: "Sandwip",
    CITYCODE: "SDW",
    CITYNAME: "SANDWIP",
    CITYNAME_HEB: "SANDWIP",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "SDX",
    AIRPORTNAME: "Sedona",
    CITYCODE: "SDX",
    CITYNAME: "SEDONA",
    CITYNAME_HEB: "SEDONA",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SDY",
    AIRPORTNAME: "Richland Municipal",
    CITYCODE: "SDY",
    CITYNAME: "SIDNEY",
    CITYNAME_HEB: "SIDNEY (US)",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "LSI",
    AIRPORTNAME: "Sumburgh",
    CITYCODE: "SDZ",
    CITYNAME: "SHETLAND ISLANDS",
    CITYNAME_HEB: "SHETLAND ISLANDS",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LWK",
    AIRPORTNAME: "Lerwick/Tingwall",
    CITYCODE: "SDZ",
    CITYNAME: "SHETLAND ISLANDS",
    CITYNAME_HEB: "SHETLAND ISLANDS",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SCS",
    AIRPORTNAME: "Scatsta",
    CITYCODE: "SDZ",
    CITYNAME: "SHETLAND ISLANDS",
    CITYNAME_HEB: "SHETLAND ISLANDS",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SDZ",
    AIRPORTNAME: "Metropolitan Area",
    CITYCODE: "SDZ",
    CITYNAME: "SHETLAND ISLANDS",
    CITYNAME_HEB: "SHETLAND ISLANDS",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SEA",
    AIRPORTNAME: "Seattle/Tacoma International",
    CITYCODE: "SEA",
    CITYNAME: "SEATTLE",
    CITYNAME_HEB: "\u05e1\u05d9\u05d0\u05d8\u05dc",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "BJD",
    AIRPORTNAME: "Bakkafjordur",
    CITYCODE: "BJD",
    CITYNAME: "BAKKAFJORDUR",
    CITYNAME_HEB: "BAKKAFJORDUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SEB",
    AIRPORTNAME: "Sebha",
    CITYCODE: "SEB",
    CITYNAME: "SEBHA",
    CITYNAME_HEB: "SEBHA",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "SEC",
    AIRPORTNAME: "Serre Chevalier",
    CITYCODE: "SEC",
    CITYNAME: "SERRE CHEVALIER",
    CITYNAME_HEB: "\u05e1\u05e8 \u05e9\u05d1\u05dc\u05d9\u05d9\u05d4",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "SED",
    AIRPORTNAME: null,
    CITYCODE: "SED",
    CITYNAME: "SEDOM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "SEE",
    CITYNAME: "SEEFELD ",
    CITYNAME_HEB: "\u05d6\u05d9\u05e4\u05dc\u05d3",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SEF",
    AIRPORTNAME: "Air Terminal",
    CITYCODE: "SEF",
    CITYNAME: "SEBRING",
    CITYNAME_HEB: "SEBRING",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SEG",
    AIRPORTNAME: "Penn Valley",
    CITYCODE: "SEG",
    CITYNAME: "SELINSGROVE",
    CITYNAME_HEB: "SELINSGROVE",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SEH",
    AIRPORTNAME: "Senggeh",
    CITYCODE: "SEH",
    CITYNAME: "SENGGEH",
    CITYNAME_HEB: "SENGGEH",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SEI",
    AIRPORTNAME: "Senhor Do Bonfim",
    CITYCODE: "SEI",
    CITYNAME: "SENHOR DO BONFIM",
    CITYNAME_HEB: "SENHOR DO BONFIM",
    COUNTRYCODE: "BRBA",
    COUNTRYNAME: "BAHIA",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "SEJ",
    AIRPORTNAME: "Seydisfjordur",
    CITYCODE: "SEJ",
    CITYNAME: "SEYDISFJORDUR",
    CITYNAME_HEB: "SEYDISFJORDUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "BJF",
    AIRPORTNAME: "Batsfjord",
    CITYCODE: "BJF",
    CITYNAME: "BATSFJORD",
    CITYNAME_HEB: "BATSFJORD",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SEK",
    AIRPORTNAME: "Ksar Es Souk",
    CITYCODE: "SEK",
    CITYNAME: "KSAR ES SOUK",
    CITYNAME_HEB: "KSAR ES SOUK",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "ICN",
    AIRPORTNAME: "Incheon International Airport",
    CITYCODE: "SEL",
    CITYNAME: "SEOUL",
    CITYNAME_HEB: "\u05e1\u05d9\u05d0\u05d5\u05dc",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "SEL",
    AIRPORTNAME: null,
    CITYCODE: "SEL",
    CITYNAME: "SEOUL",
    CITYNAME_HEB: "\u05e1\u05d9\u05d0\u05d5\u05dc",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "GMP",
    AIRPORTNAME: "Gimpo International",
    CITYCODE: "SEL",
    CITYNAME: "SEOUL",
    CITYNAME_HEB: "\u05e1\u05d9\u05d0\u05d5\u05dc",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "SSN",
    AIRPORTNAME: " ",
    CITYCODE: "SEL",
    CITYNAME: "SEOUL",
    CITYNAME_HEB: "\u05e1\u05d9\u05d0\u05d5\u05dc",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "SEN",
    AIRPORTNAME: "Municipal",
    CITYCODE: "SEN",
    CITYNAME: "SOUTHEND",
    CITYNAME_HEB: "SOUTHEND",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SEO",
    AIRPORTNAME: "Seguela",
    CITYCODE: "SEO",
    CITYNAME: "SEGUELA",
    CITYNAME_HEB: "SEGUELA",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "SEP",
    AIRPORTNAME: "Clark Field",
    CITYCODE: "SEP",
    CITYNAME: "STEPHENVILLE",
    CITYNAME_HEB: "STEPHENVILLE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SEQ",
    AIRPORTNAME: "Sungai Pakning",
    CITYCODE: "SEQ",
    CITYNAME: "SUNGAI PAKNING",
    CITYNAME_HEB: "SUNGAI PAKNING",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SER",
    AIRPORTNAME: "Freeman Municipal",
    CITYCODE: "SER",
    CITYNAME: "SEYMOUR",
    CITYNAME_HEB: "SEYMOUR",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AHD",
    AIRPORTNAME: "Ardmore Downtown",
    CITYCODE: "ADM",
    CITYNAME: "ARDMORE",
    CITYNAME_HEB: "Ardmore Downtown",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BJG",
    AIRPORTNAME: "Bolaang",
    CITYCODE: "BJG",
    CITYNAME: "BOLAANG",
    CITYNAME_HEB: "BOLAANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SEM",
    AIRPORTNAME: "Craig AFB",
    CITYCODE: "SES",
    CITYNAME: "SELMA",
    CITYNAME_HEB: "Selma",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SES",
    AIRPORTNAME: "Selfield",
    CITYCODE: "SES",
    CITYNAME: "SELMA",
    CITYNAME_HEB: "Selma",
    COUNTRYCODE: "USAL",
    COUNTRYNAME: "ALABAMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05d1\u05d0\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SET",
    AIRPORTNAME: "San Esteban",
    CITYCODE: "SET",
    CITYNAME: "SAN ESTEBAN",
    CITYNAME_HEB: "SAN ESTEBAN",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "SEU",
    AIRPORTNAME: "Seronera",
    CITYCODE: "SEU",
    CITYNAME: "SERONERA",
    CITYNAME_HEB: "SERONERA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SEV",
    AIRPORTNAME: "Severodoneck",
    CITYCODE: "SEV",
    CITYNAME: "SEVERODONECK",
    CITYNAME_HEB: "SEVERODONECK",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "SEX",
    AIRPORTNAME: "Sembach",
    CITYCODE: "SEX",
    CITYNAME: "SEMBACH",
    CITYNAME_HEB: "SEMBACH",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SEY",
    AIRPORTNAME: " ",
    CITYCODE: "SEY",
    CITYNAME: "SEYCHELLES ISLANDS",
    CITYNAME_HEB: "\u05e1\u05d9\u05d9\u05e9\u05dc",
    COUNTRYCODE: "SC",
    COUNTRYNAME: "SEYCHELLES ISLANDS",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05d9\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "SEZ",
    AIRPORTNAME: "Seychelles International",
    CITYCODE: "SEZ",
    CITYNAME: "MAHE ISLAND",
    CITYNAME_HEB: "\u05de\u05d0\u05d4\u05d4 \u05e1\u05d9\u05d9\u05e9\u05dc",
    COUNTRYCODE: "SC",
    COUNTRYNAME: "SEYCHELLES ISLANDS",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05d9\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "SFA",
    AIRPORTNAME: "Sfax El Maou",
    CITYCODE: "SFA",
    CITYNAME: "SFAX",
    CITYNAME_HEB: "SFAX",
    COUNTRYCODE: "TN",
    COUNTRYNAME: "TUNISIA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d9\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SFC",
    AIRPORTNAME: "St Francois",
    CITYCODE: "SFC",
    CITYNAME: "SAINT FRANCOIS",
    CITYNAME_HEB: "SAINT FRANCOIS",
    COUNTRYCODE: "GP",
    COUNTRYNAME: "GUADELOUPE",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d2\u05d5\u05d5\u05d3\u05d0\u05dc\u05d5\u05e4",
  },
  {
    AIRPORTCODE: "BJH",
    AIRPORTNAME: "Bajhang",
    CITYCODE: "BJH",
    CITYNAME: "BAJHANG",
    CITYNAME_HEB: "BAJHANG",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "SFD",
    AIRPORTNAME: "Las Flecheras",
    CITYCODE: "SFD",
    CITYNAME: "SAN FERNANDO DE APURE",
    CITYNAME_HEB: "SAN FERNANDO DE APURE",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "SFE",
    CITYNAME: "SAN FELICE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CCE",
    AIRPORTNAME: "Grand Case",
    CITYCODE: "SFG",
    CITYNAME: "SAINT MARTIN",
    CITYNAME_HEB: "SAINT MARTIN",
    COUNTRYCODE: "GP",
    COUNTRYNAME: "GUADELOUPE",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d2\u05d5\u05d5\u05d3\u05d0\u05dc\u05d5\u05e4",
  },
  {
    AIRPORTCODE: "MSB",
    AIRPORTNAME: "Marigot SPB",
    CITYCODE: "SFG",
    CITYNAME: "SAINT MARTIN",
    CITYNAME_HEB: "SAINT MARTIN",
    COUNTRYCODE: "GP",
    COUNTRYNAME: "GUADELOUPE",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d2\u05d5\u05d5\u05d3\u05d0\u05dc\u05d5\u05e4",
  },
  {
    AIRPORTCODE: "SFG",
    AIRPORTNAME: "Esperance",
    CITYCODE: "SFG",
    CITYNAME: "SAINT MARTIN",
    CITYNAME_HEB: "SAINT MARTIN",
    COUNTRYCODE: "GP",
    COUNTRYNAME: "GUADELOUPE",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d2\u05d5\u05d5\u05d3\u05d0\u05dc\u05d5\u05e4",
  },
  {
    AIRPORTCODE: "SFH",
    AIRPORTNAME: "San Felipe",
    CITYCODE: "SFH",
    CITYNAME: "SAN FELIPE",
    CITYNAME_HEB: "SAN FELIPE",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "SFI",
    AIRPORTNAME: "Safi",
    CITYCODE: "SFI",
    CITYNAME: "SAFI",
    CITYNAME_HEB: "SAFI",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "SFJ",
    AIRPORTNAME: "Kangerlussuaq",
    CITYCODE: "SFJ",
    CITYNAME: "KANGERLUSSUAQ",
    CITYNAME_HEB: "KANGERLUSSUAQ",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SFK",
    AIRPORTNAME: "Soure",
    CITYCODE: "SFK",
    CITYNAME: "SOURE",
    CITYNAME_HEB: "SOURE",
    COUNTRYCODE: "BRPA",
    COUNTRYNAME: "PARA",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "SFL",
    AIRPORTNAME: null,
    CITYCODE: "SFL",
    CITYNAME: "SAO FILIPE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CV",
    COUNTRYNAME: "CAPE VERDE-REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BJI",
    AIRPORTNAME: "Bemidji",
    CITYCODE: "BJI",
    CITYNAME: "BEMIDJI",
    CITYNAME_HEB: "BEMIDJI",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SFM",
    AIRPORTNAME: "Sanford",
    CITYCODE: "SFM",
    CITYNAME: "SANFORD",
    CITYNAME_HEB: "SANFORD",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SFO",
    AIRPORTNAME: "International",
    CITYCODE: "SFO",
    CITYNAME: "SAN FRANCISCO",
    CITYNAME_HEB:
      "\u05e1\u05df \u05e4\u05e8\u05e0\u05e1\u05d9\u05e1\u05e7\u05d5",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "SFP",
    AIRPORTNAME: "Surfers Paradise",
    CITYCODE: "SFP",
    CITYNAME: "SURFERS PARADISE",
    CITYNAME_HEB: "SURFERS PARADISE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SFQ",
    AIRPORTNAME: "Sanliurfa",
    CITYCODE: "SFQ",
    CITYNAME: "SANLIURFA",
    CITYNAME_HEB: "SANLIURFA",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SFR",
    AIRPORTNAME: "San Fernando",
    CITYCODE: "SFR",
    CITYNAME: "SAN FERNANDO",
    CITYNAME_HEB: "SAN FERNANDO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SFS",
    AIRPORTNAME: "Subic Bay International Airportt",
    CITYCODE: "SFS",
    CITYNAME: "SUBIC BAY",
    CITYNAME_HEB: "SUBIC BAY",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "SFT",
    AIRPORTNAME: "Skelleftea",
    CITYCODE: "SFT",
    CITYNAME: "SKELLEFTEA",
    CITYNAME_HEB: "SKELLEFTEA",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BJJ",
    AIRPORTNAME: "Wayne County",
    CITYCODE: "BJJ",
    CITYNAME: "WOOSTER",
    CITYNAME_HEB: "WOOSTER",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SFU",
    AIRPORTNAME: "Safia",
    CITYCODE: "SFU",
    CITYNAME: "SAFIA",
    CITYNAME_HEB: "SAFIA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SFV",
    AIRPORTNAME: null,
    CITYCODE: "SFV",
    CITYNAME: "SANTA FE DO SUL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SFW",
    AIRPORTNAME: "Santa Fe",
    CITYCODE: "SFW",
    CITYNAME: "SANTA FE",
    CITYNAME_HEB: "SANTA FE",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "SFX",
    AIRPORTNAME: "San Felix",
    CITYCODE: "SFX",
    CITYNAME: "SAN FELIX",
    CITYNAME_HEB: "SAN FELIX",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "SFY",
    AIRPORTNAME: "Metropolitan Area",
    CITYCODE: "SFY",
    CITYNAME: "SPRINGFIELD",
    CITYNAME_HEB:
      "\u05e1\u05e4\u05e8\u05d9\u05e0\u05d2\u05e4\u05d9\u05dc\u05d3",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "ZSF",
    AIRPORTNAME: null,
    CITYCODE: "SFY",
    CITYNAME: "SPRINGFIELD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SFZ",
    AIRPORTNAME: "North Central",
    CITYCODE: "SFZ",
    CITYNAME: "SMITHFIELD",
    CITYNAME_HEB: "SMITHFIELD",
    COUNTRYCODE: "USRI",
    COUNTRYNAME: "RHODE ISLAND",
    COUNTRYNAME_HEB:
      '\u05e8\u05d5\u05d3 \u05d0\u05d9\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SGA",
    AIRPORTNAME: null,
    CITYCODE: "SGA",
    CITYNAME: "SHEGHNAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SGB",
    AIRPORTNAME: "Singaua",
    CITYCODE: "SGB",
    CITYNAME: "SINGAUA",
    CITYNAME_HEB: "SINGAUA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BJK",
    AIRPORTNAME: "Benjina",
    CITYCODE: "BJK",
    CITYNAME: "BENJINA",
    CITYNAME_HEB: "BENJINA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SGC",
    AIRPORTNAME: "Surgut",
    CITYCODE: "SGC",
    CITYNAME: "SURGUT",
    CITYNAME_HEB: "SURGUT",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "QSG",
    AIRPORTNAME: "Sonderborg",
    CITYCODE: "SGD",
    CITYNAME: "SONDERBORG",
    CITYNAME_HEB: "SONDERBORG",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "SGD",
    AIRPORTNAME: "Sonderborg",
    CITYCODE: "SGD",
    CITYNAME: "SONDERBORG",
    CITYNAME_HEB: "SONDERBORG",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "SGE",
    AIRPORTNAME: "Siegerland Airport",
    CITYCODE: "SGE",
    CITYNAME: "SIEGEN",
    CITYNAME_HEB: "SIEGEN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SGF",
    AIRPORTNAME: "Springfield-Branson Rg",
    CITYCODE: "SGF",
    CITYNAME: "SPRINGFIELD",
    CITYNAME_HEB: "SPRINGFIELD",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SGG",
    AIRPORTNAME: "Simanggang",
    CITYCODE: "SGG",
    CITYNAME: "SIMANGGANG",
    CITYNAME_HEB: "SIMANGGANG",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SGH",
    AIRPORTNAME: "Springfield",
    CITYCODE: "SGH",
    CITYNAME: "SPRINGFIELD",
    CITYNAME_HEB: "SPRINGFIELD",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SGI",
    AIRPORTNAME: " ",
    CITYCODE: "SGI",
    CITYNAME: "SAN GIMIGNANO",
    CITYNAME_HEB:
      "\u05e1\u05d0\u05df \u05d2\u05d9\u05de\u05d5\u05e0\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SGJ",
    AIRPORTNAME: "Sagarai",
    CITYCODE: "SGJ",
    CITYNAME: "SAGARAI",
    CITYNAME_HEB: "SAGARAI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BJL",
    AIRPORTNAME: "Yundum International",
    CITYCODE: "BJL",
    CITYNAME: "BANJUL",
    CITYNAME_HEB: "\u05d1\u05e0\u05d2\u05d5\u05dc",
    COUNTRYCODE: "GM",
    COUNTRYNAME: "GAMBIA",
    COUNTRYNAME_HEB: "\u05d2\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SGK",
    AIRPORTNAME: "Sangapi",
    CITYCODE: "SGK",
    CITYNAME: "SANGAPI",
    CITYNAME_HEB: "SANGAPI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SGM",
    AIRPORTNAME: "San Ignacio",
    CITYCODE: "SGM",
    CITYNAME: "SAN IGNACIO",
    CITYNAME_HEB: "SAN IGNACIO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "SGN",
    AIRPORTNAME: "Ho Chi Minh City",
    CITYCODE: "SGN",
    CITYNAME: "HO CHI MINH CITY",
    CITYNAME_HEB: "\u05e1\u05d9\u05d9\u05d2\u05d5\u05df",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "SGO",
    AIRPORTNAME: "St George",
    CITYCODE: "SGO",
    CITYNAME: "SAINT GEORGE",
    CITYNAME_HEB: "SAINT GEORGE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SGP",
    AIRPORTNAME: "Shay Gap",
    CITYCODE: "SGP",
    CITYNAME: "SHAY GAP",
    CITYNAME_HEB: "SHAY GAP",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SGQ",
    AIRPORTNAME: "Sanggata",
    CITYCODE: "SGQ",
    CITYNAME: "SANGGATA",
    CITYNAME_HEB: "SANGGATA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SGR",
    AIRPORTNAME: "Sugar Land Municipal",
    CITYCODE: "SGR",
    CITYNAME: "SUGAR LAND",
    CITYNAME_HEB: "SUGAR LAND",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SGS",
    AIRPORTNAME: "Sanga Sanga",
    CITYCODE: "SGS",
    CITYNAME: "SANGA SANGA",
    CITYNAME_HEB: "SANGA SANGA",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "SGU",
    AIRPORTNAME: "Municipal",
    CITYCODE: "SGU",
    CITYNAME: "SAINT GEORGE zion park",
    CITYNAME_HEB: "SAINT GEORGE zion park",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BJM",
    AIRPORTNAME: "International",
    CITYCODE: "BJM",
    CITYNAME: "BUJUMBURA",
    CITYNAME_HEB: "\u05d1\u05d5\u05d2\u05d5\u05de\u05d1\u05d5\u05e8\u05d4",
    COUNTRYCODE: "BI",
    COUNTRYNAME: "BURUNDI",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05d5\u05e0\u05d3\u05d9",
  },
  {
    AIRPORTCODE: "SGV",
    AIRPORTNAME: null,
    CITYCODE: "SGV",
    CITYNAME: "SIERRA GRANDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SGW",
    AIRPORTNAME: "Saginaw Bay",
    CITYCODE: "SGW",
    CITYNAME: "SAGINAW BAY",
    CITYNAME_HEB: "Saginaw Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SGX",
    AIRPORTNAME: "Songea",
    CITYCODE: "SGX",
    CITYNAME: "SONGEA",
    CITYNAME_HEB: "SONGEA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SGY",
    AIRPORTNAME: "SKAGWAY",
    CITYCODE: "SGY",
    CITYNAME: "SKAGWAY",
    CITYNAME_HEB: "SKAGWAY",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SGZ",
    AIRPORTNAME: "Songkhla",
    CITYCODE: "SGZ",
    CITYNAME: "SONGKHLA",
    CITYNAME_HEB: "SONGKHLA",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SHA",
    AIRPORTNAME: "Hongqiao",
    CITYCODE: "SHA",
    CITYNAME: "SHANGHAI",
    CITYNAME_HEB: "\u05e9\u05e0\u05d7\u05d0\u05d9",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SHB",
    AIRPORTNAME: "Nakashibetsu",
    CITYCODE: "SHB",
    CITYNAME: "NAKASHIBETSU",
    CITYNAME_HEB: "NAKASHIBETSU",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "SHC",
    AIRPORTNAME: "Indaselassie",
    CITYCODE: "SHC",
    CITYNAME: "INDASELASSIE",
    CITYNAME_HEB: "INDASELASSIE",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SHD",
    AIRPORTNAME: "Shenandoah Valley",
    CITYCODE: "SHD",
    CITYNAME: "STAUNTON",
    CITYNAME_HEB: "STAUNTON",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "BJN",
    AIRPORTNAME: "Bajone",
    CITYCODE: "BJN",
    CITYNAME: "BAJONE",
    CITYNAME_HEB: "\u05d1\u05d0\u05d2\u05d5\u05e0\u05d4",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "SHE",
    AIRPORTNAME: "Shenyang",
    CITYCODE: "SHE",
    CITYNAME: "SHENYANG",
    CITYNAME_HEB: "SHENYANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SHF",
    AIRPORTNAME: "Shanhaiguan",
    CITYCODE: "SHF",
    CITYNAME: "SHANHAIGUAN",
    CITYNAME_HEB: "SHANHAIGUAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SHG",
    AIRPORTNAME: "Shungnak",
    CITYCODE: "SHG",
    CITYNAME: "SHUNGNAK",
    CITYNAME_HEB: "Shungnak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SHH",
    AIRPORTNAME: "Shishmaref",
    CITYCODE: "SHH",
    CITYNAME: "SHISHMAREF",
    CITYNAME_HEB: "Shishmaref",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SHI",
    AIRPORTNAME: " ",
    CITYCODE: "SHI",
    CITYNAME: "SIGHNAGHI",
    CITYNAME_HEB: "\u05e1\u05d9\u05d2\u05d0\u05e0\u05d2\u05d9",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SHJ",
    AIRPORTNAME: null,
    CITYCODE: "SHJ",
    CITYNAME: "SHARJAH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AE",
    COUNTRYNAME: "UNITED ARAB EMIRATES",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SHK",
    AIRPORTNAME: "Sehonghong",
    CITYCODE: "SHK",
    CITYNAME: "SEHONGHONG",
    CITYNAME_HEB: "SEHONGHONG",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "SHL",
    AIRPORTNAME: "Shillong",
    CITYCODE: "SHL",
    CITYNAME: "SHILLONG",
    CITYNAME_HEB: "SHILLONG",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "SHM",
    AIRPORTNAME: "Shirahama",
    CITYCODE: "SHM",
    CITYNAME: "SHIRAHAMA",
    CITYNAME_HEB: "SHIRAHAMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "SHN",
    AIRPORTNAME: "Sanderson Field",
    CITYCODE: "SHN",
    CITYNAME: "SHELTON",
    CITYNAME_HEB: "SHELTON",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BJO",
    AIRPORTNAME: "Bermejo",
    CITYCODE: "BJO",
    CITYNAME: "BERMEJO",
    CITYNAME_HEB: "BERMEJO",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SHO",
    AIRPORTNAME: "Solak",
    CITYCODE: "SHO",
    CITYNAME: "SOKCHO",
    CITYNAME_HEB: "SOKCHO",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "SHP",
    AIRPORTNAME: "Qinhuangdao",
    CITYCODE: "SHP",
    CITYNAME: "QINHUANGDAO",
    CITYNAME_HEB: "QINHUANGDAO",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SHQ",
    AIRPORTNAME: "Southport",
    CITYCODE: "SHQ",
    CITYNAME: "SOUTHPORT",
    CITYNAME_HEB: "SOUTHPORT",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SHR",
    AIRPORTNAME: "Sheridan",
    CITYCODE: "SHR",
    CITYNAME: "SHERIDAN",
    CITYNAME_HEB: "SHERIDAN",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SHS",
    AIRPORTNAME: "Shashi",
    CITYCODE: "SHS",
    CITYNAME: "SHASHI",
    CITYNAME_HEB: "SHASHI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SHT",
    AIRPORTNAME: "Shepparton",
    CITYCODE: "SHT",
    CITYNAME: "SHEPPARTON",
    CITYNAME_HEB: "SHEPPARTON",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SHU",
    AIRPORTNAME: "Smith Point",
    CITYCODE: "SHU",
    CITYNAME: "SMITH POINT",
    CITYNAME_HEB: "SMITH POINT",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DTN",
    AIRPORTNAME: null,
    CITYCODE: "SHV",
    CITYNAME: "SHREVEPORT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SHV",
    AIRPORTNAME: "Regional",
    CITYCODE: "SHV",
    CITYNAME: "SHREVEPORT",
    CITYNAME_HEB: "SHREVEPORT",
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB:
      '\u05dc\u05d5\u05d0\u05d9\u05d6\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BJP",
    AIRPORTNAME: null,
    CITYCODE: "BJP",
    CITYNAME: "BRAGANCA PAULISTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SHX",
    AIRPORTNAME: "Shageluk",
    CITYCODE: "SHX",
    CITYNAME: "SHAGELUK",
    CITYNAME_HEB: "Shageluk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SHY",
    AIRPORTNAME: "Shinyanga",
    CITYCODE: "SHY",
    CITYNAME: "SHINYANGA",
    CITYNAME_HEB: "SHINYANGA",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SHZ",
    AIRPORTNAME: "Seshutes",
    CITYCODE: "SHZ",
    CITYNAME: "SESHUTES",
    CITYNAME_HEB: "SESHUTES",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "SIA",
    AIRPORTNAME: "Xiguan",
    CITYCODE: "SIA",
    CITYNAME: "XI AN XIGUAN",
    CITYNAME_HEB: "XI AN XIGUAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XIY",
    AIRPORTNAME: "Xianyang",
    CITYCODE: "SIA",
    CITYNAME: "XI AN XIGUAN",
    CITYNAME_HEB: "XI AN XIGUAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SIB",
    AIRPORTNAME: "Sibiti",
    CITYCODE: "SIB",
    CITYNAME: "SIBITI",
    CITYNAME_HEB: "SIBITI",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "SID",
    AIRPORTNAME: " ",
    CITYCODE: "SID",
    CITYNAME: "SINAY",
    CITYNAME_HEB: "\u05e1\u05d9\u05e0\u05d9",
    COUNTRYCODE: "EG",
    COUNTRYNAME: "EGYPT",
    COUNTRYNAME_HEB: "\u05de\u05e6\u05e8\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "SIE",
    AIRPORTNAME: "Sines",
    CITYCODE: "SIE",
    CITYNAME: "SINES",
    CITYNAME_HEB: "SINES",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "SIF",
    AIRPORTNAME: "Simara",
    CITYCODE: "SIF",
    CITYNAME: "SIMARA",
    CITYNAME_HEB: "SIMARA",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "ADN",
    AIRPORTNAME: "Andes",
    CITYCODE: "ADN",
    CITYNAME: "ANDES",
    CITYNAME_HEB: "ANDES",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BJR",
    AIRPORTNAME: "Bahar Dar",
    CITYCODE: "BJR",
    CITYNAME: "BAHAR DAR",
    CITYNAME_HEB: "\u05d1\u05d4\u05e8 \u05d3\u05e8",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SIH",
    AIRPORTNAME: "Silgadi Doti",
    CITYCODE: "SIH",
    CITYNAME: "SILGADI DOTI",
    CITYNAME_HEB: "SILGADI DOTI",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "SII",
    AIRPORTNAME: "Sidi Ifni",
    CITYCODE: "SII",
    CITYNAME: "SIDI IFNI",
    CITYNAME_HEB: "SIDI IFNI",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "SIJ",
    AIRPORTNAME: "Siglufjordur",
    CITYCODE: "SIJ",
    CITYNAME: "SIGLUFJORDUR",
    CITYNAME_HEB: "SIGLUFJORDUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SIK",
    AIRPORTNAME: "Memorial",
    CITYCODE: "SIK",
    CITYNAME: "SIKESTON",
    CITYNAME_HEB: "SIKESTON",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SIL",
    AIRPORTNAME: "Sila",
    CITYCODE: "SIL",
    CITYNAME: "SILOUHETTE ISALND",
    CITYNAME_HEB: "\u05e1\u05d9\u05dc\u05d5\u05d0\u05d8\u05d4",
    COUNTRYCODE: "SC",
    COUNTRYNAME: "SEYCHELLES ISLANDS",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05d9\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "SIM",
    AIRPORTNAME: "Simbai",
    CITYCODE: "SIM",
    CITYNAME: "SIMBAI",
    CITYNAME_HEB: "SIMBAI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SIN",
    AIRPORTNAME: "Changi",
    CITYCODE: "SIN",
    CITYNAME: "SINGAPORE",
    CITYNAME_HEB: "\u05e1\u05d9\u05e0\u05d2\u05e4\u05d5\u05e8",
    COUNTRYCODE: "SG",
    COUNTRYNAME: "SINGAPORE",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05e0\u05d2\u05e4\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "SIO",
    AIRPORTNAME: null,
    CITYCODE: "SIO",
    CITYNAME: "SMITHTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUTS",
    COUNTRYNAME: "TASMANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PEK",
    AIRPORTNAME: " ",
    CITYCODE: "BJS",
    CITYNAME: "BEIJING",
    CITYNAME_HEB: "\u05d1\u05d9\u05d9\u05d2\u05d9\u05e0\u05d2",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SIP",
    AIRPORTNAME: "Simferopol",
    CITYCODE: "SIP",
    CITYNAME: "SIMFEROPOL",
    CITYNAME_HEB: "\u05e1\u05d9\u05de\u05e4\u05e8\u05d5\u05e4\u05d5\u05dc",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "SIQ",
    AIRPORTNAME: "Dabo",
    CITYCODE: "SIQ",
    CITYNAME: "SINGKEP",
    CITYNAME_HEB: "SINGKEP",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SIR",
    AIRPORTNAME: "Sion",
    CITYCODE: "SIR",
    CITYNAME: "SION",
    CITYNAME_HEB: "\u05e1\u05d9\u05d5\u05df",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "SIS",
    AIRPORTNAME: "Sishen",
    CITYCODE: "SIS",
    CITYNAME: "SISHEN",
    CITYNAME_HEB: "SISHEN",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "SIT",
    AIRPORTNAME: " ",
    CITYCODE: "SIT",
    CITYNAME: "SOUTH ITALY",
    CITYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SIU",
    AIRPORTNAME: "Siuna",
    CITYCODE: "SIU",
    CITYNAME: "SIUNA",
    CITYNAME_HEB: "SIUNA",
    COUNTRYCODE: "NI",
    COUNTRYNAME: "NICARAGUA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05e7\u05e8\u05d0\u05d2\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SIV",
    AIRPORTNAME: "County",
    CITYCODE: "SIV",
    CITYNAME: "SULLIVAN",
    CITYNAME_HEB: "SULLIVAN",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SIW",
    AIRPORTNAME: "Sibisa",
    CITYCODE: "SIW",
    CITYNAME: "SIBISA",
    CITYNAME_HEB: "SIBISA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SIX",
    AIRPORTNAME: "Singleton",
    CITYCODE: "SIX",
    CITYNAME: "SINGLETON",
    CITYNAME_HEB: "SINGLETON",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SIY",
    AIRPORTNAME: "Siskiyou County",
    CITYCODE: "SIY",
    CITYNAME: "MONTAGUE",
    CITYNAME_HEB: "MONTAGUE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BJS",
    AIRPORTNAME: "Capital International",
    CITYCODE: "BJS",
    CITYNAME: "BEIJING",
    CITYNAME_HEB: "\u05d1\u05d9\u05d9\u05d2\u05d9\u05e0\u05d2",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SIZ",
    AIRPORTNAME: "Sissano",
    CITYCODE: "SIZ",
    CITYNAME: "SISSANO",
    CITYNAME_HEB: "SISSANO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SJA",
    AIRPORTNAME: "San Juan",
    CITYCODE: "SJA",
    CITYNAME: "SAN JUAN",
    CITYNAME_HEB: "SAN JUAN",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "SJB",
    AIRPORTNAME: "San Joaquin",
    CITYCODE: "SJB",
    CITYNAME: "SAN JOAQUIN",
    CITYNAME_HEB: "SAN JOAQUIN",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SJC",
    AIRPORTNAME: "International",
    CITYCODE: "SJC",
    CITYNAME: "SAN JOSE",
    CITYNAME_HEB: "SAN JOSE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RHV",
    AIRPORTNAME: null,
    CITYCODE: "SJC",
    CITYNAME: "SAN JOSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZJO",
    AIRPORTNAME: null,
    CITYCODE: "SJC",
    CITYNAME: "SAN JOSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SJD",
    AIRPORTNAME: "Los Cabos",
    CITYCODE: "SJD",
    CITYNAME: "SAN JOSE CABO",
    CITYNAME_HEB: "SAN JOSE CABO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "SJE",
    AIRPORTNAME: "San Jose Del Gua",
    CITYCODE: "SJE",
    CITYNAME: "SAN JOSE DEL GUA",
    CITYNAME_HEB: "SAN JOSE DEL GUA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SJF",
    AIRPORTNAME: "St John Island",
    CITYCODE: "SJF",
    CITYNAME: "SAINT JOHN ISLAND",
    CITYNAME_HEB: "SAINT JOHN ISLAND",
    COUNTRYCODE: "VI",
    COUNTRYNAME: "VIRGIN ISLANDS-UNITED ST",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05ea\u05d5\u05dc\u05d4 \u05d4\u05d0\u05de\u05e8\u05d9\u05e7\u05d0\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "SJG",
    AIRPORTNAME: "San Pedro Jagua",
    CITYCODE: "SJG",
    CITYNAME: "SAN PEDRO JAGUA",
    CITYNAME_HEB: "SAN PEDRO JAGUA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SJH",
    AIRPORTNAME: "San Juan Del Cesar",
    CITYCODE: "SJH",
    CITYNAME: "SAN JUAN DEL CESA",
    CITYNAME_HEB: "SAN JUAN DEL CESA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SFN",
    AIRPORTNAME: null,
    CITYCODE: "SFN",
    CITYNAME: "SANTA FE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SJI",
    AIRPORTNAME: "Mcguire Fld",
    CITYCODE: "SJI",
    CITYNAME: "SAN JOSE",
    CITYNAME_HEB: "SAN JOSE",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "SJJ",
    AIRPORTNAME: "Butmir",
    CITYCODE: "SJJ",
    CITYNAME: "SARAJEVO",
    CITYNAME_HEB: "\u05e1\u05e8\u05d9\u05d9\u05d1\u05d5",
    COUNTRYCODE: "BA",
    COUNTRYNAME: "BOSNIA-HERZEGOVINA",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e1\u05e0\u05d9\u05d4 \u05d4\u05e8\u05e6\u05d2\u05d5\u05d1\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "SJK",
    AIRPORTNAME: null,
    CITYCODE: "SJK",
    CITYNAME: "SAO JOSE DOS CAMPOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SJL",
    AIRPORTNAME: null,
    CITYCODE: "SJL",
    CITYNAME: "SAO GABRIEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SJM",
    AIRPORTNAME: "San Juan",
    CITYCODE: "SJM",
    CITYNAME: "SAN JUAN",
    CITYNAME_HEB: "\u05e1\u05d0\u05d5 \u05d7\u05d5\u05d0\u05df",
    COUNTRYCODE: "DO",
    COUNTRYNAME: "DOMINICAN REPUBLIC",
    COUNTRYNAME_HEB:
      "\u05d4\u05e8\u05e4\u05d5\u05d1\u05dc\u05d9\u05e7\u05d4 \u05d4\u05d3\u05d5\u05de\u05d9\u05e0\u05d9\u05e7\u05e0\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "SJN",
    AIRPORTNAME: "Municipal",
    CITYCODE: "SJN",
    CITYNAME: "SAINT JOHNS",
    CITYNAME_HEB: "SAINT JOHNS",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SJO",
    AIRPORTNAME: "Juan Santamaria International",
    CITYCODE: "SJO",
    CITYNAME: "SAN JOSE",
    CITYNAME_HEB: "\u05e1\u05d0\u05df \u05d7\u05d5\u05d6\u05d4",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "SYQ",
    AIRPORTNAME: " ",
    CITYCODE: "SJO",
    CITYNAME: "SAN JOSE",
    CITYNAME_HEB: "\u05e1\u05d0\u05df \u05d7\u05d5\u05d6\u05d4",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "POX",
    AIRPORTNAME: " ",
    CITYCODE: "POX",
    CITYNAME: "PODEBRADY",
    CITYNAME_HEB: "\u05e4\u05d5\u05d3\u05d1\u05e8\u05d3\u05d9",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YAW",
    AIRPORTNAME: " ",
    CITYCODE: "YAW",
    CITYNAME: "JACHYMOV",
    CITYNAME_HEB: "\u05d9\u05d7\u05d9\u05de\u05d5\u05d1",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SJP",
    AIRPORTNAME: null,
    CITYCODE: "SJP",
    CITYNAME: "SAO JOSE DO RIO PRETO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BJU",
    AIRPORTNAME: "Bajura Airport",
    CITYCODE: "BJU",
    CITYNAME: "BAJURA",
    CITYNAME_HEB: "BAJURA",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "SJQ",
    AIRPORTNAME: "Sesheke",
    CITYCODE: "SJQ",
    CITYNAME: "SESHEKE",
    CITYNAME_HEB: "SESHEKE",
    COUNTRYCODE: "ZM",
    COUNTRYNAME: "ZAMBIA",
    COUNTRYNAME_HEB: "\u05d6\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SJR",
    AIRPORTNAME: "San Juan D Ur",
    CITYCODE: "SJR",
    CITYNAME: "SAN JUAN D UR",
    CITYNAME_HEB: "SAN JUAN D UR",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SJS",
    AIRPORTNAME: "San Jose",
    CITYCODE: "SJS",
    CITYNAME: "SAN JOSE",
    CITYNAME_HEB: "\u05e1\u05d0\u05df \u05d7\u05d5\u05d6\u05d4",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GOF",
    AIRPORTNAME: null,
    CITYCODE: "SJT",
    CITYNAME: "SAN ANGELO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SJT",
    AIRPORTNAME: "Mathis Field",
    CITYCODE: "SJT",
    CITYNAME: "SAN ANGELO",
    CITYNAME_HEB: "SAN ANGELO",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SJU",
    AIRPORTNAME: "Luis Munoz Marin International",
    CITYCODE: "SJU",
    CITYNAME: "SAN JUAN",
    CITYNAME_HEB: "\u05e1\u05d0\u05df \u05d7\u05d5\u05d0\u05df",
    COUNTRYCODE: "PR",
    COUNTRYNAME: "PUERTO RICO",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e8\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "SIG",
    AIRPORTNAME: null,
    CITYCODE: "SJU",
    CITYNAME: "SAN JUAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PR",
    COUNTRYNAME: "PUERTO RICO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SJV",
    AIRPORTNAME: "San Javier",
    CITYCODE: "SJV",
    CITYNAME: "SAN JAVIER",
    CITYNAME_HEB: "SAN JAVIER",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SJW",
    AIRPORTNAME: "Daguocun",
    CITYCODE: "SJW",
    CITYNAME: "SHIJIAZHUANG",
    CITYNAME_HEB: "SHIJIAZHUANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SJX",
    AIRPORTNAME: "Sartaneja",
    CITYCODE: "SJX",
    CITYNAME: "SARTANEJA",
    CITYNAME_HEB: "SARTANEJA",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "BJW",
    AIRPORTNAME: "Bajawa",
    CITYCODE: "BJW",
    CITYNAME: "BAJAWA",
    CITYNAME_HEB: "BAJAWA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SJY",
    AIRPORTNAME: "Ilmajoki",
    CITYCODE: "SJY",
    CITYNAME: "SEINAJOKI",
    CITYNAME_HEB: "SEINAJOKI",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "SJZ",
    AIRPORTNAME: "Sao Jorge Island",
    CITYCODE: "SJZ",
    CITYNAME: "SAO JORGE ISLAND",
    CITYNAME_HEB: "SAO JORGE ISLAND",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "FMM",
    AIRPORTNAME: "Memmingen",
    CITYCODE: "FMM",
    CITYNAME: "MEMMINGEN",
    CITYNAME_HEB: "\u05de\u05de\u05d9\u05e0\u05d2\u05d4\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SKB",
    AIRPORTNAME: null,
    CITYCODE: "SKB",
    CITYNAME: "SAINT KITTS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KN",
    COUNTRYNAME: "ST. KITTS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SKC",
    AIRPORTNAME: "Suki",
    CITYCODE: "SKC",
    CITYNAME: "SUKI",
    CITYNAME_HEB: "SUKI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "SKD",
    AIRPORTNAME: "Samarkand",
    CITYCODE: "SKD",
    CITYNAME: "SAMARKAND",
    CITYNAME_HEB: "SAMARKAND",
    COUNTRYCODE: "UZ",
    COUNTRYNAME: "UZBEKISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d6\u05d1\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "SKE",
    AIRPORTNAME: "Skien",
    CITYCODE: "SKE",
    CITYNAME: "SKIEN",
    CITYNAME_HEB: "SKIEN",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SKG",
    AIRPORTNAME: "Makedonia Airport",
    CITYCODE: "SKG",
    CITYNAME: "THESSALONIKI",
    CITYNAME_HEB: "\u05e1\u05dc\u05d5\u05e0\u05d9\u05e7\u05d9",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "SKH",
    AIRPORTNAME: "Surkhet",
    CITYCODE: "SKH",
    CITYNAME: "SURKHET",
    CITYNAME_HEB: "SURKHET",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "SKI",
    AIRPORTNAME: "Skikda",
    CITYCODE: "SKI",
    CITYNAME: "SKIKDA",
    CITYNAME_HEB: "SKIKDA",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SKJ",
    AIRPORTNAME: "Sitkinak Cgs",
    CITYCODE: "SKJ",
    CITYNAME: "SITKINAK ISLAND",
    CITYNAME_HEB: "Sitkinak Cgs",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BJX",
    AIRPORTNAME: "Del Bajio",
    CITYCODE: "BJX",
    CITYNAME: "LEON",
    CITYNAME_HEB: "LEON",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "SKK",
    AIRPORTNAME: "Shaktoolik",
    CITYCODE: "SKK",
    CITYNAME: "SHAKTOOLIK",
    CITYNAME_HEB: "Shaktoolik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "SKL",
    AIRPORTNAME: "Broadford",
    CITYCODE: "SKL",
    CITYNAME: "ISLE OF SKYE",
    CITYNAME_HEB: "ISLE OF SKYE",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SKM",
    AIRPORTNAME: "Skeldon",
    CITYCODE: "SKM",
    CITYNAME: "SKELDON",
    CITYNAME_HEB: "SKELDON",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "SKN",
    AIRPORTNAME: "Skagen",
    CITYCODE: "SKN",
    CITYNAME: "STOKMARKNES",
    CITYNAME_HEB: "STOKMARKNES",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SKO",
    AIRPORTNAME: "Sokoto",
    CITYCODE: "SKO",
    CITYNAME: "SOKOTO",
    CITYNAME_HEB: "SOKOTO",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SKP",
    AIRPORTNAME: "Skopje",
    CITYCODE: "SKP",
    CITYNAME: "SKOPJE",
    CITYNAME_HEB: "\u05e1\u05e7\u05d5\u05e4\u05d9\u05d4",
    COUNTRYCODE: "MK",
    COUNTRYNAME: "MACEDONIA",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05d3\u05d5\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SKQ",
    AIRPORTNAME: "Sekakes",
    CITYCODE: "SKQ",
    CITYNAME: "SEKAKES",
    CITYNAME_HEB: "SEKAKES",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "SKR",
    AIRPORTNAME: "Shakiso",
    CITYCODE: "SKR",
    CITYNAME: "SHAKISO",
    CITYNAME_HEB: "SHAKISO",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SKS",
    AIRPORTNAME: "Vojens",
    CITYCODE: "SKS",
    CITYNAME: "VOJENS",
    CITYNAME_HEB: "VOJENS",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "XJE",
    AIRPORTNAME: null,
    CITYCODE: "SKS",
    CITYNAME: "VOJENS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BJZ",
    AIRPORTNAME: "Talaveral La Real",
    CITYCODE: "BJZ",
    CITYNAME: "BADAJOZ",
    CITYNAME_HEB: "BADAJOZ",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "SKT",
    AIRPORTNAME: "Sialkot",
    CITYCODE: "SKT",
    CITYNAME: "SIALKOT",
    CITYNAME_HEB: "SIALKOT",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "SKU",
    AIRPORTNAME: "Skiros",
    CITYCODE: "SKU",
    CITYNAME: "SKIROS",
    CITYNAME_HEB: "\u05e1\u05e7\u05d9\u05e8\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "YOJ",
    AIRPORTNAME: "Footner Lake",
    CITYCODE: "YOJ",
    CITYNAME: "HIGH LEVEL",
    CITYNAME_HEB: "Footner Lake",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YOK",
    AIRPORTNAME: "Yokohama",
    CITYCODE: "YOK",
    CITYNAME: "YOKOHAMA",
    CITYNAME_HEB: "\u05d9\u05d5\u05e7\u05d5\u05d4\u05de\u05d4",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "BTM",
    AIRPORTNAME: "Butte",
    CITYCODE: "BTM",
    CITYNAME: "BUTTE",
    CITYNAME_HEB: "BUTTE",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YOL",
    AIRPORTNAME: "Yola",
    CITYCODE: "YOL",
    CITYNAME: "YOLA",
    CITYNAME_HEB: "YOLA",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YOO",
    AIRPORTNAME: "Oshawa",
    CITYCODE: "YOO",
    CITYNAME: "OSHAWA",
    CITYNAME_HEB: "OSHAWA",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YOP",
    AIRPORTNAME: "Rainbow Lake",
    CITYCODE: "YOP",
    CITYNAME: "RAINBOW LAKE",
    CITYNAME_HEB: "Rainbow Lake",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YOS",
    AIRPORTNAME: "Billy Bishop Regional",
    CITYCODE: "YOS",
    CITYNAME: "OWEN SOUND",
    CITYNAME_HEB: "OWEN SOUND",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YOT",
    AIRPORTNAME: null,
    CITYCODE: "YOT",
    CITYNAME: "YOTVATA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YOW",
    AIRPORTNAME: "International",
    CITYCODE: "YOW",
    CITYNAME: "OTTAWA",
    CITYNAME_HEB: "\u05d0\u05d5\u05d8\u05d0\u05d5\u05d5\u05d4",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XDS",
    AIRPORTNAME: null,
    CITYCODE: "YOW",
    CITYNAME: "OTTAWA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGE",
    AIRPORTNAME: null,
    CITYCODE: "YOW",
    CITYNAME: "OTTAWA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XHG",
    AIRPORTNAME: null,
    CITYCODE: "YOW",
    CITYNAME: "OTTAWA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YRO",
    AIRPORTNAME: null,
    CITYCODE: "YOW",
    CITYNAME: "OTTAWA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AEX",
    AIRPORTNAME: null,
    CITYCODE: "AEX",
    CITYNAME: "ALEXANDRIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BTN",
    AIRPORTNAME: "Bennettsville",
    CITYCODE: "BTN",
    CITYNAME: "BENNETTSVILLE",
    CITYNAME_HEB:
      "\u05d1\u05e0\u05d8\u05e1\u05d5\u05d5\u05d9\u05dc - \u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
    COUNTRYCODE: "US",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "YOY",
    AIRPORTNAME: "Valcartier",
    CITYCODE: "YOY",
    CITYNAME: "VALCARTIER",
    CITYNAME_HEB: "VALCARTIER",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPA",
    AIRPORTNAME: "Prince Albert",
    CITYCODE: "YPA",
    CITYNAME: "PRINCE ALBERT",
    CITYNAME_HEB: "PRINCE ALBERT",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPB",
    AIRPORTNAME: "Port Alberni",
    CITYCODE: "YPB",
    CITYNAME: "PORT ALBERNI",
    CITYNAME_HEB: "PORT ALBERNI",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPC",
    AIRPORTNAME: "Paulatuk",
    CITYCODE: "YPC",
    CITYNAME: "PAULATUK",
    CITYNAME_HEB: "PAULATUK",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPD",
    AIRPORTNAME: "Parry Sound",
    CITYCODE: "YPD",
    CITYNAME: "PARRY SOUND",
    CITYNAME_HEB: "PARRY SOUND",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPE",
    AIRPORTNAME: "Peace River",
    CITYCODE: "YPE",
    CITYNAME: "PEACE RIVER",
    CITYNAME_HEB: "Peace River",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPF",
    AIRPORTNAME: "Esquimalt",
    CITYCODE: "YPF",
    CITYNAME: "ESQUIMALT",
    CITYNAME_HEB: "ESQUIMALT",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPG",
    AIRPORTNAME: "Portage La Prairie",
    CITYCODE: "YPG",
    CITYNAME: "PORTAGE LA PRAIRIE",
    CITYNAME_HEB: "PORTAGE LA PRAIRIE",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPH",
    AIRPORTNAME: "Inukjuak",
    CITYCODE: "YPH",
    CITYNAME: "INUKJUAK",
    CITYNAME_HEB: "INUKJUAK",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPI",
    AIRPORTNAME: "Port Simpson",
    CITYCODE: "YPI",
    CITYNAME: "PORT SIMPSON",
    CITYNAME_HEB: "PORT SIMPSON",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTO",
    AIRPORTNAME: "Botopasie",
    CITYCODE: "BTO",
    CITYNAME: "BOTOPASIE",
    CITYNAME_HEB: "BOTOPASIE",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "YPJ",
    AIRPORTNAME: "Aupaluk",
    CITYCODE: "YPJ",
    CITYNAME: "AUPALUK",
    CITYNAME_HEB: "AUPALUK",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPL",
    AIRPORTNAME: "Pickle Lake",
    CITYCODE: "YPL",
    CITYNAME: "PICKLE LAKE",
    CITYNAME_HEB: "PICKLE LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPM",
    AIRPORTNAME: "Pikangikum",
    CITYCODE: "YPM",
    CITYNAME: "PIKANGIKUM",
    CITYNAME_HEB: "PIKANGIKUM",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPN",
    AIRPORTNAME: "Port Menier",
    CITYCODE: "YPN",
    CITYNAME: "PORT MENIER",
    CITYNAME_HEB: "PORT MENIER",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPO",
    AIRPORTNAME: "Peawanuck",
    CITYCODE: "YPO",
    CITYNAME: "PEAWANUCK",
    CITYNAME_HEB: "PEAWANUCK",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPP",
    AIRPORTNAME: "Pine Point",
    CITYCODE: "YPP",
    CITYNAME: "PINE POINT",
    CITYNAME_HEB: "PINE POINT",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPQ",
    AIRPORTNAME: "Peterborough",
    CITYCODE: "YPQ",
    CITYNAME: "PETERBOROUGH",
    CITYNAME_HEB: "PETERBOROUGH",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XDW",
    AIRPORTNAME: null,
    CITYCODE: "YPR",
    CITYNAME: "PRINCE RUPERT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YPR",
    AIRPORTNAME: "Digby Island",
    CITYCODE: "YPR",
    CITYNAME: "PRINCE RUPERT",
    CITYNAME_HEB: "PRINCE RUPERT",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZSW",
    AIRPORTNAME: "Seal Cove",
    CITYCODE: "YPR",
    CITYNAME: "PRINCE RUPERT",
    CITYNAME_HEB: "PRINCE RUPERT",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTP",
    AIRPORTNAME: "Graham Field",
    CITYCODE: "BTP",
    CITYNAME: "BUTLER",
    CITYNAME_HEB: "BUTLER",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YPS",
    AIRPORTNAME: "Port Hawkesbury",
    CITYCODE: "YPS",
    CITYNAME: "PORT HAWKESBURY",
    CITYNAME_HEB: "PORT HAWKESBURY",
    COUNTRYCODE: "CANS",
    COUNTRYNAME: "NOVA SCOTIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPT",
    AIRPORTNAME: "Pender Harbor",
    CITYCODE: "YPT",
    CITYNAME: "PENDER HARBOR",
    CITYNAME_HEB: "PENDER HARBOR",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPW",
    AIRPORTNAME: "Powell River",
    CITYCODE: "YPW",
    CITYNAME: "POWELL RIVER",
    CITYNAME_HEB: "POWELL RIVER",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPX",
    AIRPORTNAME: "Puvirnituq",
    CITYCODE: "YPX",
    CITYNAME: "PUVIMITUG",
    CITYNAME_HEB: "PUVIMITUG",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPY",
    AIRPORTNAME: "Fort Chipewyan",
    CITYCODE: "YPY",
    CITYNAME: "FORT CHIPEWYAN",
    CITYNAME_HEB: "Fort Chipewyan",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YPZ",
    AIRPORTNAME: "Burns Lake",
    CITYCODE: "YPZ",
    CITYNAME: "BURNS LAKE",
    CITYNAME_HEB: "BURNS LAKE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQA",
    AIRPORTNAME: "Muskoka",
    CITYCODE: "YQA",
    CITYNAME: "MUSKOKA",
    CITYNAME_HEB: "MUSKOKA",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQB",
    AIRPORTNAME: " ",
    CITYCODE: "YQB",
    CITYNAME: "QUEBEC",
    CITYNAME_HEB: "\u05e7\u05d5\u05d5\u05d9\u05d1\u05e7",
    COUNTRYCODE: "CA",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTQ",
    AIRPORTNAME: "Butare",
    CITYCODE: "BTQ",
    CITYNAME: "BUTARE",
    CITYNAME_HEB: "BUTARE",
    COUNTRYCODE: "RW",
    COUNTRYNAME: "RWANDA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05d0\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQC",
    AIRPORTNAME: "Quaqtaq",
    CITYCODE: "YQC",
    CITYNAME: "QUAQTAQ",
    CITYNAME_HEB: "QUAQTAQ",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XDZ",
    AIRPORTNAME: null,
    CITYCODE: "YQD",
    CITYNAME: "THE PAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YQD",
    AIRPORTNAME: "The Pas",
    CITYCODE: "YQD",
    CITYNAME: "THE PAS",
    CITYNAME_HEB: "THE PAS",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQE",
    AIRPORTNAME: "Kimberley",
    CITYCODE: "YQE",
    CITYNAME: "KIMBERLEY",
    CITYNAME_HEB: "KIMBERLEY",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQF",
    AIRPORTNAME: "Red Deer",
    CITYCODE: "YQF",
    CITYNAME: "RED DEER",
    CITYNAME_HEB: "Red Deer",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XEC",
    AIRPORTNAME: null,
    CITYCODE: "YQG",
    CITYNAME: "WINDSOR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YQG",
    AIRPORTNAME: "Windsor",
    CITYCODE: "YQG",
    CITYNAME: "WINDSOR",
    CITYNAME_HEB: "WINDSOR",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQH",
    AIRPORTNAME: "Watson Lake",
    CITYCODE: "YQH",
    CITYNAME: "WATSON LAKE",
    CITYNAME_HEB: "WATSON LAKE",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTR",
    AIRPORTNAME: "Metropolitan Airport",
    CITYCODE: "BTR",
    CITYNAME: "BATON ROUGE",
    CITYNAME_HEB: "BATON ROUGE",
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB:
      '\u05dc\u05d5\u05d0\u05d9\u05d6\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YQI",
    AIRPORTNAME: "Yarmouth",
    CITYCODE: "YQI",
    CITYNAME: "YARMOUTH",
    CITYNAME_HEB: "YARMOUTH",
    COUNTRYCODE: "CANS",
    COUNTRYNAME: "NOVA SCOTIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQK",
    AIRPORTNAME: "Kenora",
    CITYCODE: "YQK",
    CITYNAME: "KENORA",
    CITYNAME_HEB: "KENORA",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQL",
    AIRPORTNAME: "Lethbridge",
    CITYCODE: "YQL",
    CITYNAME: "LETHBRIDGE",
    CITYNAME_HEB: "Lethbridge",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XDP",
    AIRPORTNAME: null,
    CITYCODE: "YQM",
    CITYNAME: "MONCTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CANB",
    COUNTRYNAME: "NEW BRUNSWICK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YQM",
    AIRPORTNAME: "Metropolitan Area",
    CITYCODE: "YQM",
    CITYNAME: "MONCTON",
    CITYNAME_HEB: "MONCTON",
    COUNTRYCODE: "CANB",
    COUNTRYNAME: "NEW BRUNSWICK",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQN",
    AIRPORTNAME: "Nakina",
    CITYCODE: "YQN",
    CITYNAME: "NAKINA",
    CITYNAME_HEB: "NAKINA",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQQ",
    AIRPORTNAME: "Comox",
    CITYCODE: "YQQ",
    CITYNAME: "COMOX",
    CITYNAME_HEB: "COMOX",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQR",
    AIRPORTNAME: "Regina",
    CITYCODE: "YQR",
    CITYNAME: "REGINA",
    CITYNAME_HEB: "REGINA",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQS",
    AIRPORTNAME: "Pembroke Area Mncpl",
    CITYCODE: "YQS",
    CITYNAME: "SAINT THOMAS",
    CITYNAME_HEB: "SAINT THOMAS",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQT",
    AIRPORTNAME: "Thunder Bay",
    CITYCODE: "YQT",
    CITYNAME: "THUNDER BAY",
    CITYNAME_HEB: "THUNDER BAY",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "EBR",
    AIRPORTNAME: null,
    CITYCODE: "BTR",
    CITYNAME: "BATON ROUGE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YQU",
    AIRPORTNAME: "Grande Prairie",
    CITYCODE: "YQU",
    CITYNAME: "GRANDE PRAIRIE",
    CITYNAME_HEB: "Grande Prairie",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQV",
    AIRPORTNAME: "Yorkton",
    CITYCODE: "YQV",
    CITYNAME: "YORKTON",
    CITYNAME_HEB: "YORKTON",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQW",
    AIRPORTNAME: "North Battleford",
    CITYCODE: "YQW",
    CITYNAME: "NORTH BATTLEFORD",
    CITYNAME_HEB: "NORTH BATTLEFORD",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQX",
    AIRPORTNAME: "Gander",
    CITYCODE: "YQX",
    CITYNAME: "GANDER",
    CITYNAME_HEB: "GANDER",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQY",
    AIRPORTNAME: "Sydney",
    CITYCODE: "YQY",
    CITYNAME: "SYDNEY",
    CITYNAME_HEB: "SYDNEY (CA)",
    COUNTRYCODE: "CANS",
    COUNTRYNAME: "NOVA SCOTIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YQZ",
    AIRPORTNAME: "Quesnel",
    CITYCODE: "YQZ",
    CITYNAME: "QUESNEL",
    CITYNAME_HEB: "QUESNEL",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRA",
    AIRPORTNAME: "Rae Lakes",
    CITYCODE: "YRA",
    CITYNAME: "RAE LAKES",
    CITYNAME_HEB: "RAE LAKES",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRB",
    AIRPORTNAME: "Resolute",
    CITYCODE: "YRB",
    CITYNAME: "RESOLUTE",
    CITYNAME_HEB: "RESOLUTE",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRD",
    AIRPORTNAME: "Dean River",
    CITYCODE: "YRD",
    CITYNAME: "DEAN RIVER",
    CITYNAME_HEB: "DEAN RIVER",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRE",
    AIRPORTNAME: "Resolution Island",
    CITYCODE: "YRE",
    CITYNAME: "RESOLUTION ISLAND",
    CITYNAME_HEB: "RESOLUTION ISLAND",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTS",
    AIRPORTNAME: "Ivanka",
    CITYCODE: "BTS",
    CITYNAME: "BRATISLAVA",
    CITYNAME_HEB: "\u05d1\u05e8\u05d8\u05d9\u05e1\u05dc\u05d1\u05d4",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YRF",
    AIRPORTNAME: "Cartwright",
    CITYCODE: "YRF",
    CITYNAME: "CARTWRIGHT",
    CITYNAME_HEB: "CARTWRIGHT",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRG",
    AIRPORTNAME: "Rigolet",
    CITYCODE: "YRG",
    CITYNAME: "RIGOLET",
    CITYNAME_HEB: "RIGOLET",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRI",
    AIRPORTNAME: "Riviere Du Loup",
    CITYCODE: "YRI",
    CITYNAME: "RIVIERE DU LOUP",
    CITYNAME_HEB: "RIVIERE DU LOUP",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRJ",
    AIRPORTNAME: "Roberval",
    CITYCODE: "YRJ",
    CITYNAME: "ROBERVAL",
    CITYNAME_HEB: "ROBERVAL",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "TRH",
    AIRPORTNAME: "Trona",
    CITYCODE: "TRH",
    CITYNAME: "TRONA",
    CITYNAME_HEB: "TRONA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BNE",
    AIRPORTNAME: "International",
    CITYCODE: "BNE",
    CITYNAME: "BRISBANE",
    CITYNAME_HEB: "\u05d1\u05e8\u05d9\u05d6\u05d1\u05df",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TRJ",
    AIRPORTNAME: "Tarakbits",
    CITYCODE: "TRJ",
    CITYNAME: "TARAKBITS",
    CITYNAME_HEB: "TARAKBITS",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TRK",
    AIRPORTNAME: " ",
    CITYCODE: "TRK",
    CITYNAME: "TAROKO",
    CITYNAME_HEB: "\u05d8\u05e8\u05d5\u05e7\u05d5",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "TRL",
    AIRPORTNAME: "Terrell Field",
    CITYCODE: "TRL",
    CITYNAME: "TERRELL",
    CITYNAME_HEB: "TERRELL",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TRM",
    AIRPORTNAME: "Thermal",
    CITYCODE: "TRM",
    CITYNAME: "THERMAL",
    CITYNAME_HEB: "THERMAL",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TRN",
    AIRPORTNAME: "Citta Di Torino",
    CITYCODE: "TRN",
    CITYNAME: "TURIN",
    CITYNAME_HEB: "\u05d8\u05d5\u05e8\u05d9\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TRO",
    AIRPORTNAME: "Taree",
    CITYCODE: "TRO",
    CITYNAME: "TAREE",
    CITYNAME_HEB: "TAREE",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TRP",
    AIRPORTNAME: "Coast Guard Heliport",
    CITYCODE: "TRP",
    CITYNAME: "TREE POINT",
    CITYNAME_HEB: "Coast Guard Heliport",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TRQ",
    AIRPORTNAME: null,
    CITYCODE: "TRQ",
    CITYNAME: "TARAUACA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BNF",
    AIRPORTNAME: "Baranof-Warm Spring Bay SPB",
    CITYCODE: "BNF",
    CITYNAME: "BARANOF",
    CITYNAME_HEB: "Baranof-Warm Spring Bay SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TRR",
    AIRPORTNAME: "China Bay",
    CITYCODE: "TRR",
    CITYNAME: "TRINCOMALEE",
    CITYNAME_HEB: "TRINCOMALEE",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "TRS",
    AIRPORTNAME: "Dei Legionari",
    CITYCODE: "TRS",
    CITYNAME: "TRIESTE",
    CITYNAME_HEB: "\u05d8\u05e8\u05d9\u05d9\u05e1\u05d8",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TRT",
    AIRPORTNAME: " ",
    CITYCODE: "TRT",
    CITYNAME: "TRENCIANSKE TEPLICE",
    CITYNAME_HEB:
      "\u05d8\u05e8\u05e0\u05e6\u05d9\u05d0\u05e1\u05e7\u05d4 \u05d8\u05e4\u05dc\u05d9\u05e6\u05d4",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TRU",
    AIRPORTNAME: "Trujillo",
    CITYCODE: "TRU",
    CITYNAME: "TRUJILLO",
    CITYNAME_HEB: "TRUJILLO",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "TRV",
    AIRPORTNAME: "International",
    CITYCODE: "TRV",
    CITYNAME: "THIRUVANANTHAPURAM",
    CITYNAME_HEB:
      "\u05d8\u05e8\u05d9\u05d5\u05d5\u05e0\u05d3\u05e8\u05d5\u05dd",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "TRW",
    AIRPORTNAME: "Bonriki",
    CITYCODE: "TRW",
    CITYNAME: "TARAWA",
    CITYNAME_HEB: "TARAWA",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "TRX",
    AIRPORTNAME: "Memorial",
    CITYCODE: "TRX",
    CITYNAME: "TRENTON",
    CITYNAME_HEB: "TRENTON",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TRY",
    AIRPORTNAME: null,
    CITYCODE: "TRY",
    CITYNAME: "TRIKALA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TRZ",
    AIRPORTNAME: "Civil",
    CITYCODE: "TRZ",
    CITYNAME: "TIRUCHIRAPALLY",
    CITYNAME_HEB: "TIRUCHIRAPALLY",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "TSB",
    AIRPORTNAME: "Tsumeb",
    CITYCODE: "TSB",
    CITYNAME: "TSUMEB",
    CITYNAME_HEB: "TSUMEB",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BNG",
    AIRPORTNAME: "Banning",
    CITYCODE: "BNG",
    CITYNAME: "BANNING",
    CITYNAME_HEB: "BANNING",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TSC",
    AIRPORTNAME: "Taisha",
    CITYCODE: "TSC",
    CITYNAME: "TAISHA",
    CITYNAME_HEB: "TAISHA",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "TSD",
    AIRPORTNAME: "Tshipise",
    CITYCODE: "TSD",
    CITYNAME: "TSHIPISE",
    CITYNAME_HEB: "TSHIPISE",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "TSE",
    AIRPORTNAME: "Astana",
    CITYCODE: "TSE",
    CITYNAME: "ASTANA",
    CITYNAME_HEB: "\u05d0\u05e1\u05d8\u05e0\u05d4",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "TSG",
    AIRPORTNAME: "TANACROSS",
    CITYCODE: "TSG",
    CITYNAME: "TANACROSS",
    CITYNAME_HEB: "TANACROSS",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TSH",
    AIRPORTNAME: "Tshikapa",
    CITYCODE: "TSH",
    CITYNAME: "TSHIKAPA",
    CITYNAME_HEB: "TSHIKAPA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "TSI",
    AIRPORTNAME: "Tsili Tsili",
    CITYCODE: "TSI",
    CITYNAME: "TSILI TSILI",
    CITYNAME_HEB: "TSILI TSILI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TSJ",
    AIRPORTNAME: "Tsushima",
    CITYCODE: "TSJ",
    CITYNAME: "TSUSHIMA",
    CITYNAME_HEB: "TSUSHIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "TSK",
    AIRPORTNAME: " ",
    CITYCODE: "TSK",
    CITYNAME: "TSKALTUBO",
    CITYNAME_HEB: "\u05d8\u05e1\u05e7\u05d0\u05dc\u05d8\u05d5\u05d1\u05d5",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TSL",
    AIRPORTNAME: "Tamuin",
    CITYCODE: "TSL",
    CITYNAME: "TAMUIN",
    CITYNAME_HEB: "TAMUIN",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "TSM",
    AIRPORTNAME: "Taos",
    CITYCODE: "TSM",
    CITYNAME: "TAOS",
    CITYNAME_HEB: "TAOS",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BNI",
    AIRPORTNAME: "Benin City",
    CITYCODE: "BNI",
    CITYNAME: "BENIN CITY",
    CITYNAME_HEB: "BENIN CITY",
    COUNTRYCODE: "NG",
    COUNTRYNAME: "NIGERIA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TSN",
    AIRPORTNAME: "Tianjin",
    CITYCODE: "TSN",
    CITYNAME: "TIANJIN",
    CITYNAME_HEB: "TIANJIN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "TSP",
    AIRPORTNAME: "Kern County",
    CITYCODE: "TSP",
    CITYNAME: "TEHACHAPI",
    CITYNAME_HEB: "TEHACHAPI",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TSQ",
    AIRPORTNAME: null,
    CITYCODE: "TSQ",
    CITYNAME: "TORRES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TSR",
    AIRPORTNAME: "Timisoara",
    CITYCODE: "TSR",
    CITYNAME: "TIMISOARA",
    CITYNAME_HEB: "\u05d8\u05d9\u05de\u05d9\u05e9\u05d5\u05d0\u05e8\u05d4",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TST",
    AIRPORTNAME: "Trang",
    CITYCODE: "TST",
    CITYNAME: "TRANG",
    CITYNAME_HEB: "TRANG",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "TSU",
    AIRPORTNAME: "Tabiteuea South",
    CITYCODE: "TSU",
    CITYNAME: "TABITEUEA SOUTH",
    CITYNAME_HEB: "TABITEUEA SOUTH",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "TSV",
    AIRPORTNAME: "Townsville",
    CITYCODE: "TSV",
    CITYNAME: "TOWNSVILLE",
    CITYNAME_HEB: "TOWNSVILLE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TSW",
    AIRPORTNAME: "Tsewi",
    CITYCODE: "TSW",
    CITYNAME: "TSEWI",
    CITYNAME_HEB: "TSEWI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TSX",
    AIRPORTNAME: "Tanjung Santan",
    CITYCODE: "TSX",
    CITYNAME: "TANJUNG SANTAN",
    CITYNAME_HEB: "TANJUNG SANTAN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TSY",
    AIRPORTNAME: "Tasikmalaya",
    CITYCODE: "TSY",
    CITYNAME: "TASIKMALAYA",
    CITYNAME_HEB: "TASIKMALAYA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BNJ",
    AIRPORTNAME: " ",
    CITYCODE: "BNJ",
    CITYNAME: "BONN",
    CITYNAME_HEB: "\u05d1\u05d5\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TSZ",
    AIRPORTNAME: "Tsetserleg",
    CITYCODE: "TSZ",
    CITYNAME: "TSETSERLEG",
    CITYNAME_HEB: "TSETSERLEG",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TTA",
    AIRPORTNAME: "Tan Tan",
    CITYCODE: "TTA",
    CITYNAME: "TAN TAN",
    CITYNAME_HEB: "TAN TAN",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "TTB",
    AIRPORTNAME: "Arbatax",
    CITYCODE: "TTB",
    CITYNAME: "TORTOLI",
    CITYNAME_HEB: "TORTOLI",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TTC",
    AIRPORTNAME: "Taltal",
    CITYCODE: "TTC",
    CITYNAME: "TALTAL",
    CITYNAME_HEB: "TALTAL",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "TTD",
    AIRPORTNAME: "Troutdale",
    CITYCODE: "TTD",
    CITYNAME: "TROUTDALE",
    CITYNAME_HEB: "TROUTDALE",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TTE",
    AIRPORTNAME: "Babullah",
    CITYCODE: "TTE",
    CITYNAME: "TERNATE",
    CITYNAME_HEB: "TERNATE",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TTG",
    AIRPORTNAME: null,
    CITYCODE: "TTG",
    CITYNAME: "TARTAGAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TTH",
    AIRPORTNAME: null,
    CITYCODE: "TTH",
    CITYNAME: "THUMRAIT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "OM",
    COUNTRYNAME: "OMAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TTI",
    AIRPORTNAME: "Tetiaroa Is",
    CITYCODE: "TTI",
    CITYNAME: "TETIAROA IS",
    CITYNAME_HEB: "TETIAROA IS",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "TTJ",
    AIRPORTNAME: "Tottori",
    CITYCODE: "TTJ",
    CITYNAME: "TOTTORI",
    CITYNAME_HEB: "TOTTORI",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "BNK",
    AIRPORTNAME: "Ballina",
    CITYCODE: "BNK",
    CITYNAME: "BALLINA",
    CITYNAME_HEB: "BALLINA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TTK",
    AIRPORTNAME: "Tottenham Hale Station",
    CITYCODE: "TTK",
    CITYNAME: "TOTTENHAM HALE ST",
    CITYNAME_HEB: "TOTTENHAM HALE ST",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TTL",
    AIRPORTNAME: "Turtle Island",
    CITYCODE: "TTL",
    CITYNAME: "TURTLE ISLAND",
    CITYNAME_HEB: "TURTLE ISLAND",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "TTM",
    AIRPORTNAME: "Tablon De Tamara",
    CITYCODE: "TTM",
    CITYNAME: "TABLON DE TAMARA",
    CITYNAME_HEB: "TABLON DE TAMARA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TTO",
    AIRPORTNAME: "Municipal",
    CITYCODE: "TTO",
    CITYNAME: "BRITTON",
    CITYNAME_HEB: "BRITTON",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TTQ",
    AIRPORTNAME: "Tortuquero",
    CITYCODE: "TTQ",
    CITYNAME: "TORTUQUERO",
    CITYNAME_HEB: "TORTUQUERO",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "TTR",
    AIRPORTNAME: "Tana Toraja",
    CITYCODE: "TTR",
    CITYNAME: "TANA TORAJA",
    CITYNAME_HEB: "TANA TORAJA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TTS",
    AIRPORTNAME: "Tsaratanana",
    CITYCODE: "TTS",
    CITYNAME: "TSARATANANA",
    CITYNAME_HEB: "TSARATANANA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "TTT",
    AIRPORTNAME: "Taitung",
    CITYCODE: "TTT",
    CITYNAME: "TAITUNG",
    CITYNAME_HEB: "TAITUNG",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "TTU",
    AIRPORTNAME: "Sania Ramel",
    CITYCODE: "TTU",
    CITYNAME: "TETUAN",
    CITYNAME_HEB: "TETUAN",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "TUA",
    AIRPORTNAME: "Tulcan",
    CITYCODE: "TUA",
    CITYNAME: "TULCAN",
    CITYNAME_HEB: "TULCAN",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "BNL",
    AIRPORTNAME: "Barnwell County",
    CITYCODE: "BNL",
    CITYNAME: "BARNWELL",
    CITYNAME_HEB: "BARNWELL",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TUB",
    AIRPORTNAME: "Tubuai",
    CITYCODE: "TUB",
    CITYNAME: "TUBUAI",
    CITYNAME_HEB: "TUBUAI",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "TUC",
    AIRPORTNAME: null,
    CITYCODE: "TUC",
    CITYNAME: "TUCUMAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TUD",
    AIRPORTNAME: "Tambacounda",
    CITYCODE: "TUD",
    CITYNAME: "TAMBACOUNDA",
    CITYNAME_HEB: "TAMBACOUNDA",
    COUNTRYCODE: "SN",
    COUNTRYNAME: "SENEGAL",
    COUNTRYNAME_HEB: "\u05e1\u05e0\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "TUE",
    AIRPORTNAME: "Tupile",
    CITYCODE: "TUE",
    CITYNAME: "TUPILE",
    CITYNAME_HEB: "TUPILE",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "TUF",
    AIRPORTNAME: "St Symphorien",
    CITYCODE: "TUF",
    CITYNAME: "TOURS",
    CITYNAME_HEB: "\u05d8\u05d5\u05e8\u05e1",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "TUG",
    AIRPORTNAME: "Tuguegarao",
    CITYCODE: "TUG",
    CITYNAME: "TUGUEGARAO",
    CITYNAME_HEB: "TUGUEGARAO",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "TUJ",
    AIRPORTNAME: "Tum",
    CITYCODE: "TUJ",
    CITYNAME: "TUM",
    CITYNAME_HEB: "TUM",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TUK",
    AIRPORTNAME: "Turbat",
    CITYCODE: "TUK",
    CITYNAME: "TURBAT",
    CITYNAME_HEB: "TURBAT",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "BNM",
    AIRPORTNAME: "Bodinumu",
    CITYCODE: "BNM",
    CITYNAME: "BODINUMU",
    CITYNAME_HEB: "BODINUMU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TUL",
    AIRPORTNAME: "International",
    CITYCODE: "TUL",
    CITYNAME: "TULSA",
    CITYNAME_HEB: "\u05d8\u05d5\u05dc\u05e1\u05d4",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RVS",
    AIRPORTNAME: null,
    CITYCODE: "TUL",
    CITYNAME: "TULSA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TUM",
    AIRPORTNAME: "Tumut",
    CITYCODE: "TUM",
    CITYNAME: "TUMUT",
    CITYNAME_HEB: "TUMUT",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TUN",
    AIRPORTNAME: "Carthage",
    CITYCODE: "TUN",
    CITYNAME: "TUNIS",
    CITYNAME_HEB: "\u05d8\u05d5\u05e0\u05d9\u05e1",
    COUNTRYCODE: "TN",
    COUNTRYNAME: "TUNISIA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d9\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TUO",
    AIRPORTNAME: "Taupo",
    CITYCODE: "TUO",
    CITYNAME: "TAUPO",
    CITYNAME_HEB: "TAUPO",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "TUP",
    AIRPORTNAME: "Lemons Municipal",
    CITYCODE: "TUP",
    CITYNAME: "TUPELO",
    CITYNAME_HEB: "TUPELO",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TUQ",
    AIRPORTNAME: "Tougan",
    CITYCODE: "TUQ",
    CITYNAME: "TOUGAN",
    CITYNAME_HEB: "TOUGAN",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "TUR",
    AIRPORTNAME: null,
    CITYCODE: "TUR",
    CITYNAME: "TUCURUI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TUS",
    AIRPORTNAME: "International",
    CITYCODE: "TUS",
    CITYNAME: "TUCSON",
    CITYNAME_HEB: "\u05d8\u05d5\u05e1\u05d5\u05df",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AVW",
    AIRPORTNAME: null,
    CITYCODE: "TUS",
    CITYNAME: "TUCSON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ADW",
    AIRPORTNAME: null,
    CITYCODE: "ADW",
    CITYNAME: "CAMP SPRINGS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BNN",
    AIRPORTNAME: "Bronnoy",
    CITYCODE: "BNN",
    CITYNAME: "BRONNOYSUND",
    CITYNAME_HEB: "BRONNOYSUND",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DMA",
    AIRPORTNAME: null,
    CITYCODE: "TUS",
    CITYNAME: "TUCSON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TUT",
    AIRPORTNAME: " ",
    CITYCODE: "TUT",
    CITYNAME: "TURCIANSKE TEPLICE",
    CITYNAME_HEB:
      "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d0\u05e0\u05e6\u05d4 \u05d8\u05e4\u05dc\u05d9\u05e6\u05d4",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TUV",
    AIRPORTNAME: "Tucupita",
    CITYCODE: "TUV",
    CITYNAME: "TUCUPITA",
    CITYNAME_HEB: "TUCUPITA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "TUW",
    AIRPORTNAME: "Tubala",
    CITYCODE: "TUW",
    CITYNAME: "TUBALA",
    CITYNAME_HEB: "TUBALA",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "TUX",
    AIRPORTNAME: "Tumbler Ridge",
    CITYCODE: "TUX",
    CITYNAME: "TUMBLER RIDGE",
    CITYNAME_HEB: "TUMBLER RIDGE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "TUY",
    AIRPORTNAME: "Tulum",
    CITYCODE: "TUY",
    CITYNAME: "TULUM",
    CITYNAME_HEB: "TULUM",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "TUZ",
    AIRPORTNAME: null,
    CITYCODE: "TUZ",
    CITYNAME: "TUCUMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "TVA",
    AIRPORTNAME: "Morafenobe",
    CITYCODE: "TVA",
    CITYNAME: "MORAFENOBE",
    CITYNAME_HEB: "MORAFENOBE",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "TVC",
    AIRPORTNAME: "Traverse City",
    CITYCODE: "TVC",
    CITYNAME: "TRAVERSE CITY",
    CITYNAME_HEB: "TRAVERSE CITY",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TVF",
    AIRPORTNAME: "Regional",
    CITYCODE: "TVF",
    CITYNAME: "THIEF RIVER FALLS",
    CITYNAME_HEB: "THIEF RIVER FALLS",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BNO",
    AIRPORTNAME: " ",
    CITYCODE: "BNO",
    CITYNAME: "BRUSNO",
    CITYNAME_HEB: "\u05d1\u05e8\u05d5\u05e1\u05e0\u05d5",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TVI",
    AIRPORTNAME: "Municipal",
    CITYCODE: "TVI",
    CITYNAME: "THOMASVILLE",
    CITYNAME_HEB: "THOMASVILLE",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "TVL",
    AIRPORTNAME: "South Lake Tahoe",
    CITYCODE: "TVL",
    CITYNAME: "LAKE TAHOE SOUTH",
    CITYNAME_HEB: "LAKE TAHOE SOUTH",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TVU",
    AIRPORTNAME: "Matei",
    CITYCODE: "TVU",
    CITYNAME: "TAVEUNI",
    CITYNAME_HEB: "TAVEUNI",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "TVY",
    AIRPORTNAME: "Dawe",
    CITYCODE: "TVY",
    CITYNAME: "DAWE",
    CITYNAME_HEB: "DAWE",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "TWA",
    AIRPORTNAME: "Twin Hills",
    CITYCODE: "TWA",
    CITYNAME: "TWIN HILLS",
    CITYNAME_HEB: "Twin Hills",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TWB",
    AIRPORTNAME: "Toowoomba",
    CITYCODE: "TWB",
    CITYNAME: "TOOWOOMBA",
    CITYNAME_HEB: "TOOWOOMBA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TWD",
    AIRPORTNAME: "Port Townsend",
    CITYCODE: "TWD",
    CITYNAME: "PORT TOWNSEND",
    CITYNAME_HEB: "PORT TOWNSEND",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TWE",
    AIRPORTNAME: "Taylor",
    CITYCODE: "TWE",
    CITYNAME: "TAYLOR",
    CITYNAME_HEB: "Taylor",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TWF",
    AIRPORTNAME: "City County",
    CITYCODE: "TWF",
    CITYNAME: "TWIN FALLS",
    CITYNAME_HEB: "TWIN FALLS",
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d9\u05d3\u05d4\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TWN",
    AIRPORTNAME: "Tewantin",
    CITYCODE: "TWN",
    CITYNAME: "TEWANTIN",
    CITYNAME_HEB: "TEWANTIN",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BNP",
    AIRPORTNAME: "Bannu",
    CITYCODE: "BNP",
    CITYNAME: "BANNU",
    CITYNAME_HEB: "BANNU",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "TWP",
    AIRPORTNAME: "Torwood",
    CITYCODE: "TWP",
    CITYNAME: "TORWOOD",
    CITYNAME_HEB: "TORWOOD",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TWT",
    AIRPORTNAME: "Tawitawi",
    CITYCODE: "TWT",
    CITYNAME: "TAWITAWI",
    CITYNAME_HEB: "TAWITAWI",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "TWU",
    AIRPORTNAME: "Tawau",
    CITYCODE: "TWU",
    CITYNAME: "TAWAU",
    CITYNAME_HEB: "TAWAU",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TWY",
    AIRPORTNAME: "Tawa",
    CITYCODE: "TWY",
    CITYNAME: "TAWA",
    CITYNAME_HEB: "TAWA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TXF",
    AIRPORTNAME: null,
    CITYCODE: "TXF",
    CITYNAME: "TEIXEIRA FREITAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RMQ",
    AIRPORTNAME: "Taichung",
    CITYCODE: "RMQ",
    CITYNAME: "TAICHUNG",
    CITYNAME_HEB: "TAICHUNG",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "TXK",
    AIRPORTNAME: "Municipal",
    CITYCODE: "TXK",
    CITYNAME: "TEXARKANA",
    CITYNAME_HEB: "TEXARKANA",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TXM",
    AIRPORTNAME: "Teminabuan",
    CITYCODE: "TXM",
    CITYNAME: "TEMINABUAN",
    CITYNAME_HEB: "TEMINABUAN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TXN",
    AIRPORTNAME: "Tunxi",
    CITYCODE: "TXN",
    CITYNAME: "TUNXI",
    CITYNAME_HEB: "TUNXI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "TXR",
    AIRPORTNAME: "Tanbar",
    CITYCODE: "TXR",
    CITYNAME: "TANBAR",
    CITYNAME_HEB: "TANBAR",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BNQ",
    AIRPORTNAME: "Baganga",
    CITYCODE: "BNQ",
    CITYNAME: "BAGANGA",
    CITYNAME_HEB: "BAGANGA",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "TXU",
    AIRPORTNAME: "Tabou",
    CITYCODE: "TXU",
    CITYNAME: "TABOU",
    CITYNAME_HEB: "TABOU",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "TYA",
    AIRPORTNAME: "Tula",
    CITYCODE: "TYA",
    CITYNAME: "TULA",
    CITYNAME_HEB: "TULA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TYB",
    AIRPORTNAME: "Tibooburra",
    CITYCODE: "TYB",
    CITYNAME: "TIBOOBURRA",
    CITYNAME_HEB: "TIBOOBURRA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TYD",
    AIRPORTNAME: "Tynda",
    CITYCODE: "TYD",
    CITYNAME: "TYNDA",
    CITYNAME_HEB: "TYNDA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TYE",
    AIRPORTNAME: "Tyonek",
    CITYCODE: "TYE",
    CITYNAME: "TYONEK",
    CITYNAME_HEB: "Tyonek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TYF",
    AIRPORTNAME: "Torsby Airport",
    CITYCODE: "TYF",
    CITYNAME: "TORSBY",
    CITYNAME_HEB: "TORSBY",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TYG",
    AIRPORTNAME: "Thylungra",
    CITYCODE: "TYG",
    CITYNAME: "THYLUNGRA",
    CITYNAME_HEB: "THYLUNGRA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TYL",
    AIRPORTNAME: "Talara",
    CITYCODE: "TYL",
    CITYNAME: "TALARA",
    CITYNAME_HEB: "TALARA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "TYM",
    AIRPORTNAME: "Staniel Cay",
    CITYCODE: "TYM",
    CITYNAME: "STANIEL CAY",
    CITYNAME_HEB: "STANIEL CAY",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "TYN",
    AIRPORTNAME: "Taiyuan",
    CITYCODE: "TYN",
    CITYNAME: "TAIYUAN",
    CITYNAME_HEB: "TAIYUAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "BNR",
    AIRPORTNAME: "Banfora",
    CITYCODE: "BNR",
    CITYNAME: "BANFORA",
    CITYNAME_HEB: "BANFORA",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "NRT",
    AIRPORTNAME: "Narita",
    CITYCODE: "TYO",
    CITYNAME: "TOKYO",
    CITYNAME_HEB: "\u05d8\u05d5\u05e7\u05d9\u05d5",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "TYO",
    AIRPORTNAME: null,
    CITYCODE: "TYO",
    CITYNAME: "TOKYO",
    CITYNAME_HEB: "\u05d8\u05d5\u05e7\u05d9\u05d5",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "HND",
    AIRPORTNAME: "Haneda",
    CITYCODE: "TYO",
    CITYNAME: "TOKYO",
    CITYNAME_HEB: "\u05d8\u05d5\u05e7\u05d9\u05d5",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "OKO",
    AIRPORTNAME: " ",
    CITYCODE: "TYO",
    CITYNAME: "TOKYO",
    CITYNAME_HEB: "\u05d8\u05d5\u05e7\u05d9\u05d5",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "TYP",
    AIRPORTNAME: "Tobermorey",
    CITYCODE: "TYP",
    CITYNAME: "TOBERMOREY",
    CITYNAME_HEB: "TOBERMOREY",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TYR",
    AIRPORTNAME: "Pounds Field",
    CITYCODE: "TYR",
    CITYNAME: "TYLER",
    CITYNAME_HEB: "TYLER",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TYS",
    AIRPORTNAME: "Mc Ghee Tyson",
    CITYCODE: "TYS",
    CITYNAME: "KNOXVILLE",
    CITYNAME_HEB: "KNOXVILLE",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TYT",
    AIRPORTNAME: "Treinta-y-Tres",
    CITYCODE: "TYT",
    CITYNAME: "TREINTA Y TRES",
    CITYNAME_HEB: "TREINTA Y TRES",
    COUNTRYCODE: "UY",
    COUNTRYNAME: "URUGUAY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e8\u05d5\u05d2\u05d0\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "TYZ",
    AIRPORTNAME: "Taylor",
    CITYCODE: "TYZ",
    CITYNAME: "TAYLOR",
    CITYNAME_HEB: "TAYLOR",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "TZL",
    AIRPORTNAME: "Tuzla International",
    CITYCODE: "TZL",
    CITYNAME: "TUZLA",
    CITYNAME_HEB: "\u05d8\u05d5\u05d6\u05dc\u05d4",
    COUNTRYCODE: "BA",
    COUNTRYNAME: "BOSNIA-HERZEGOVINA",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e1\u05e0\u05d9\u05d4 \u05d4\u05e8\u05e6\u05d2\u05d5\u05d1\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "BNS",
    AIRPORTNAME: "Barinas",
    CITYCODE: "BNS",
    CITYNAME: "BARINAS",
    CITYNAME_HEB: "BARINAS",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "TZM",
    AIRPORTNAME: "Tizimin",
    CITYCODE: "TZM",
    CITYNAME: "TIZIMIN",
    CITYNAME_HEB: "TIZIMIN",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "TZN",
    AIRPORTNAME: "South Andros",
    CITYCODE: "TZN",
    CITYNAME: "SOUTH ANDROS",
    CITYNAME_HEB: "SOUTH ANDROS",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "TZX",
    AIRPORTNAME: "Trabzon",
    CITYCODE: "TZX",
    CITYNAME: "TRABZON",
    CITYNAME_HEB: "\u05d8\u05e8\u05d1\u05d6\u05d5\u05df",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UAC",
    AIRPORTNAME: "San Luis Rio Colorado",
    CITYCODE: "UAC",
    CITYNAME: "SAN LUIS RIO COLORADO",
    CITYNAME_HEB: "SAN LUIS RIO COLORADO",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "UAE",
    AIRPORTNAME: "Mount Aue",
    CITYCODE: "UAE",
    CITYNAME: "MOUNT AUE",
    CITYNAME_HEB: "MOUNT AUE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "UAH",
    AIRPORTNAME: "Ua Huka",
    CITYCODE: "UAH",
    CITYNAME: "UA HUKA",
    CITYNAME_HEB: "UA HUKA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "UAI",
    AIRPORTNAME: "Suai",
    CITYCODE: "UAI",
    CITYNAME: "SUAI",
    CITYNAME_HEB: "SUAI",
    COUNTRYCODE: "TP",
    COUNTRYNAME: "EAST TIMOR",
    COUNTRYNAME_HEB:
      "\u05d8\u05d9\u05de\u05d5\u05e8 \u05d4\u05de\u05d6\u05e8\u05d7\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "UAK",
    AIRPORTNAME: "Narsarsuaq",
    CITYCODE: "UAK",
    CITYNAME: "NARSARSUAQ",
    CITYNAME_HEB: "NARSARSUAQ",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "UAL",
    AIRPORTNAME: "Luau",
    CITYCODE: "UAL",
    CITYNAME: "LUAU",
    CITYNAME_HEB: "LUAU",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "UAP",
    AIRPORTNAME: "Ua Pou",
    CITYCODE: "UAP",
    CITYNAME: "UA POU",
    CITYNAME_HEB: "UA POU",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "BNT",
    AIRPORTNAME: "Bundi",
    CITYCODE: "BNT",
    CITYNAME: "BUNDI",
    CITYNAME_HEB: "BUNDI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "UAQ",
    AIRPORTNAME: null,
    CITYCODE: "UAQ",
    CITYNAME: "SAN JUAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UAS",
    AIRPORTNAME: "Samburu",
    CITYCODE: "UAS",
    CITYNAME: "SAMBURU",
    CITYNAME_HEB: "SAMBURU",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UAX",
    AIRPORTNAME: "Uaxactun",
    CITYCODE: "UAX",
    CITYNAME: "UAXACTUN",
    CITYNAME_HEB: "UAXACTUN",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "UBA",
    AIRPORTNAME: null,
    CITYCODE: "UBA",
    CITYNAME: "UBERABA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UBB",
    AIRPORTNAME: "Mabuiag Island",
    CITYCODE: "UBB",
    CITYNAME: "MABUIAG ISLAND",
    CITYNAME_HEB: "MABUIAG ISLAND",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UBI",
    AIRPORTNAME: "Buin",
    CITYCODE: "UBI",
    CITYNAME: "BUIN",
    CITYNAME_HEB: "BUIN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "UBJ",
    AIRPORTNAME: "Ube",
    CITYCODE: "UBJ",
    CITYNAME: "UBE",
    CITYNAME_HEB: "UBE",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "UBP",
    AIRPORTNAME: "Muang Ubon",
    CITYCODE: "UBP",
    CITYNAME: "UBON RATCHATHANI",
    CITYNAME_HEB: "UBON RATCHATHANI",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "UBR",
    AIRPORTNAME: "Ubrub",
    CITYCODE: "UBR",
    CITYNAME: "UBRUB",
    CITYNAME_HEB: "UBRUB",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BNU",
    AIRPORTNAME: null,
    CITYCODE: "BNU",
    CITYNAME: "BLUMENAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UBT",
    AIRPORTNAME: null,
    CITYCODE: "UBT",
    CITYNAME: "UBATUBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UBU",
    AIRPORTNAME: "Kalumburu",
    CITYCODE: "UBU",
    CITYNAME: "KALUMBURU",
    CITYNAME_HEB: "KALUMBURU",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RER",
    AIRPORTNAME: "Base Aerea Del Sur",
    CITYCODE: "RER",
    CITYNAME: "RETALHULEU",
    CITYNAME_HEB: "RETALHULEU",
    COUNTRYCODE: "GT",
    COUNTRYNAME: "GUATEMALA",
    COUNTRYNAME_HEB: "\u05d2\u05d5\u05d5\u05d8\u05d0\u05de\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "RES",
    AIRPORTNAME: null,
    CITYCODE: "RES",
    CITYNAME: "RESISTENCIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RET",
    AIRPORTNAME: "Stolport",
    CITYCODE: "RET",
    CITYNAME: "ROST",
    CITYNAME_HEB: "ROST",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "REU",
    AIRPORTNAME: "Reus",
    CITYCODE: "REU",
    CITYNAME: "REUS",
    CITYNAME_HEB: "REUS",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "BHV",
    AIRPORTNAME: "Bahawalpur",
    CITYCODE: "BHV",
    CITYNAME: "BAHAWALPUR",
    CITYNAME_HEB: "BAHAWALPUR",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "REW",
    AIRPORTNAME: "Rewa",
    CITYCODE: "REW",
    CITYNAME: "REWA",
    CITYNAME_HEB: "REWA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "REX",
    AIRPORTNAME: "Gen. Lucio Blanco",
    CITYCODE: "REX",
    CITYNAME: "REYNOSA",
    CITYNAME_HEB: "REYNOSA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "REY",
    AIRPORTNAME: "Reyes",
    CITYCODE: "REY",
    CITYNAME: "REYES",
    CITYNAME_HEB: "REYES",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "REZ",
    AIRPORTNAME: null,
    CITYCODE: "REZ",
    CITYNAME: "RESENDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RFA",
    AIRPORTNAME: null,
    CITYCODE: "RFA",
    CITYNAME: "RAFAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RFD",
    AIRPORTNAME: "Greater Rockford",
    CITYCODE: "RFD",
    CITYNAME: "ROCKFORD",
    CITYNAME_HEB: "ROCKFORD",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RMC",
    AIRPORTNAME: "Machesney",
    CITYCODE: "RFD",
    CITYNAME: "ROCKFORD",
    CITYNAME_HEB: "ROCKFORD",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZRF",
    AIRPORTNAME: null,
    CITYCODE: "RFD",
    CITYNAME: "ROCKFORD",
    CITYNAME_HEB: "ROCKFORD",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZRK",
    AIRPORTNAME: null,
    CITYCODE: "RFD",
    CITYNAME: "ROCKFORD",
    CITYNAME_HEB: "ROCKFORD",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RFG",
    AIRPORTNAME: "Rooke Field",
    CITYCODE: "RFG",
    CITYNAME: "REFUGIO",
    CITYNAME_HEB: "REFUGIO",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BHX",
    AIRPORTNAME: "International",
    CITYCODE: "BHX",
    CITYNAME: "BIRMINGHAM",
    CITYNAME_HEB: "\u05d1\u05e8\u05de\u05d9\u05e0\u05d2\u05d4\u05d0\u05dd",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VDU",
    AIRPORTNAME: "Tom O'Connor Oilfield",
    CITYCODE: "RFG",
    CITYNAME: "REFUGIO",
    CITYNAME_HEB: "REFUGIO",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RFK",
    AIRPORTNAME: "Rollang Field",
    CITYCODE: "RFK",
    CITYNAME: "ANGUILLA",
    CITYNAME_HEB: "ANGUILLA",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RFN",
    AIRPORTNAME: "Raufarhofn",
    CITYCODE: "RFN",
    CITYNAME: "RAUFARHOFN",
    CITYNAME_HEB: "RAUFARHOFN",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "RFP",
    AIRPORTNAME: "Raiatea",
    CITYCODE: "RFP",
    CITYNAME: "RAIATEA",
    CITYNAME_HEB: "RAIATEA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "RFR",
    AIRPORTNAME: "Rio Frio",
    CITYCODE: "RFR",
    CITYNAME: "RIO FRIO",
    CITYNAME_HEB: "RIO FRIO",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "RFS",
    AIRPORTNAME: "Rosita",
    CITYCODE: "RFS",
    CITYNAME: "ROSITA",
    CITYNAME_HEB: "ROSITA",
    COUNTRYCODE: "NI",
    COUNTRYNAME: "NICARAGUA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05e7\u05e8\u05d0\u05d2\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "RGA",
    AIRPORTNAME: null,
    CITYCODE: "RGA",
    CITYNAME: "RIO GRANDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RGE",
    AIRPORTNAME: "Porgera",
    CITYCODE: "RGE",
    CITYNAME: "PORGERA",
    CITYNAME_HEB: "PORGERA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "RGH",
    AIRPORTNAME: "Balurghat",
    CITYCODE: "RGH",
    CITYNAME: "BALURGHAT",
    CITYNAME_HEB: "BALURGHAT",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "RGI",
    AIRPORTNAME: "Rangiroa",
    CITYCODE: "RGI",
    CITYNAME: "RANGIROA",
    CITYNAME_HEB: "RANGIROA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "QQN",
    AIRPORTNAME: null,
    CITYCODE: "BHX",
    CITYNAME: "BIRMINGHAM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RGL",
    AIRPORTNAME: null,
    CITYCODE: "RGL",
    CITYNAME: "RIO GALLEGOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RGN",
    AIRPORTNAME: "Mingaladon",
    CITYCODE: "RGN",
    CITYNAME: "YANGON",
    CITYNAME_HEB: "\u05d9\u05e0\u05d2\u05d5\u05df",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "RGR",
    AIRPORTNAME: "Ranger Municipal",
    CITYCODE: "RGR",
    CITYNAME: "RANGER",
    CITYNAME_HEB: "RANGER",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RGT",
    AIRPORTNAME: "Japura",
    CITYCODE: "RGT",
    CITYNAME: "RENGAT",
    CITYNAME_HEB: "RENGAT",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RHA",
    AIRPORTNAME: "Reykholar",
    CITYCODE: "RHA",
    CITYNAME: "REYKHOLAR",
    CITYNAME_HEB: "REYKHOLAR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "RHD",
    AIRPORTNAME: null,
    CITYCODE: "RHD",
    CITYNAME: "RIO HONDO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RHE",
    AIRPORTNAME: "Reims",
    CITYCODE: "RHE",
    CITYNAME: "REIMS",
    CITYNAME_HEB: "REIMS",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "RHG",
    AIRPORTNAME: "Ruhengeri",
    CITYCODE: "RHG",
    CITYNAME: "RUHENGERI",
    CITYNAME_HEB: "RUHENGERI",
    COUNTRYCODE: "RW",
    COUNTRYNAME: "RWANDA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05d0\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "RHI",
    AIRPORTNAME: "Oneida County",
    CITYCODE: "RHI",
    CITYNAME: "RHINELANDER",
    CITYNAME_HEB: "RHINELANDER",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RHL",
    AIRPORTNAME: "Roy Hill",
    CITYCODE: "RHL",
    CITYNAME: "ROY HILL",
    CITYNAME_HEB: "ROY HILL",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BHY",
    AIRPORTNAME: "Beihai",
    CITYCODE: "BHY",
    CITYNAME: "BEIHAI",
    CITYNAME_HEB: "BEIHAI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "RHO",
    AIRPORTNAME: "Diagoras Airport",
    CITYCODE: "RHO",
    CITYNAME: "RHODES",
    CITYNAME_HEB: "\u05e8\u05d5\u05d3\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "RHP",
    AIRPORTNAME: "Ramechhap",
    CITYCODE: "RHP",
    CITYNAME: "RAMECHHAP",
    CITYNAME_HEB: "RAMECHHAP",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "RIA",
    AIRPORTNAME: null,
    CITYCODE: "RIA",
    CITYNAME: "SANTA MARIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RIB",
    AIRPORTNAME: "Gen Buech",
    CITYCODE: "RIB",
    CITYNAME: "RIBERALTA",
    CITYNAME_HEB: "RIBERALTA",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RIC",
    AIRPORTNAME: "International (Byrd Field)",
    CITYCODE: "RIC",
    CITYNAME: "RICHMOND",
    CITYNAME_HEB: "\u05e8\u05d9\u05e6'\u05de\u05d5\u05e0\u05d3",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "ZRD",
    AIRPORTNAME: null,
    CITYCODE: "RIC",
    CITYNAME: "RICHMOND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RID",
    AIRPORTNAME: "Richmond",
    CITYCODE: "RID",
    CITYNAME: "RICHMOND",
    CITYNAME_HEB: "\u05e8\u05d9\u05e6'\u05de\u05d5\u05e0\u05d3",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RIE",
    AIRPORTNAME: "Rice Lake",
    CITYCODE: "RIE",
    CITYNAME: "RICE LAKE",
    CITYNAME_HEB: "RICE LAKE",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RIF",
    AIRPORTNAME: "Reynolds",
    CITYCODE: "RIF",
    CITYNAME: "RICHFIELD",
    CITYNAME_HEB: "RICHFIELD",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RIG",
    AIRPORTNAME: null,
    CITYCODE: "RIG",
    CITYNAME: "RIO GRANDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BHZ",
    AIRPORTNAME: null,
    CITYCODE: "BHZ",
    CITYNAME: "BELO HORIZONTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RIJ",
    AIRPORTNAME: "Rioja",
    CITYCODE: "RIJ",
    CITYNAME: "RIOJA",
    CITYNAME_HEB: "RIOJA",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "RIK",
    AIRPORTNAME: "Carrillo",
    CITYCODE: "RIK",
    CITYNAME: "CARILLO",
    CITYNAME_HEB: "CARILLO",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "RIL",
    AIRPORTNAME: "Garfield County",
    CITYCODE: "RIL",
    CITYNAME: "RIFLE",
    CITYNAME_HEB: "RIFLE",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RIM",
    AIRPORTNAME: "Rodriguez De Men",
    CITYCODE: "RIM",
    CITYNAME: "RODRIGUEZ DE MEN",
    CITYNAME_HEB: "RODRIGUEZ DE MEN",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "RIN",
    AIRPORTNAME: "Ringi Cove",
    CITYCODE: "RIN",
    CITYNAME: "RINGI COVE",
    CITYNAME_HEB: "RINGI COVE",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "RIO",
    AIRPORTNAME: " ",
    CITYCODE: "RIO",
    CITYNAME: "RIO DE JANEIRO",
    CITYNAME_HEB: "\u05e8\u05d9\u05d5 \u05d3\u05d4 \u05d6\u05e0\u05e8\u05d5",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "GIG",
    AIRPORTNAME: " ",
    CITYCODE: "RIO",
    CITYNAME: "RIO DE JANEIRO",
    CITYNAME_HEB: "\u05e8\u05d9\u05d5 \u05d3\u05d4 \u05d6\u05e0\u05e8\u05d5",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "SDU",
    AIRPORTNAME: " ",
    CITYCODE: "RIO",
    CITYNAME: "RIO DE JANEIRO",
    CITYNAME_HEB: "\u05e8\u05d9\u05d5 \u05d3\u05d4 \u05d6\u05e0\u05e8\u05d5",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "RIS",
    AIRPORTNAME: "Rishiri",
    CITYCODE: "RIS",
    CITYNAME: "RISHIRI",
    CITYNAME_HEB: "RISHIRI",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "RIT",
    AIRPORTNAME: "Rio Tigre",
    CITYCODE: "RIT",
    CITYNAME: "RIO TIGRE",
    CITYNAME_HEB: "RIO TIGRE",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "RIW",
    AIRPORTNAME: "Riverton",
    CITYCODE: "RIW",
    CITYNAME: "RIVERTON",
    CITYNAME_HEB: "RIVERTON",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RIX",
    AIRPORTNAME: "Riga",
    CITYCODE: "RIX",
    CITYNAME: "RIGA",
    CITYNAME_HEB: "\u05e8\u05d9\u05d2\u05d4",
    COUNTRYCODE: "LV",
    COUNTRYNAME: "LATVIA",
    COUNTRYNAME_HEB: "\u05dc\u05d8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RIY",
    AIRPORTNAME: "Riyan Mukalla",
    CITYCODE: "RIY",
    CITYNAME: "RIYAN MUKALLA",
    CITYNAME_HEB: "RIYAN MUKALLA",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "RIZ",
    AIRPORTNAME: "Rio Alzucar",
    CITYCODE: "RIZ",
    CITYNAME: "RIO ALZUCAR",
    CITYNAME_HEB: "RIO ALZUCAR",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "RJA",
    AIRPORTNAME: "Rajahmundry",
    CITYCODE: "RJA",
    CITYNAME: "RAJAHMUNDRY",
    CITYNAME_HEB: "RAJAHMUNDRY",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "RJB",
    AIRPORTNAME: "Rajbiraj",
    CITYCODE: "RJB",
    CITYNAME: "RAJBIRAJ",
    CITYNAME_HEB: "RAJBIRAJ",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "RJH",
    AIRPORTNAME: "Rajshahi",
    CITYCODE: "RJH",
    CITYNAME: "RAJSHAHI",
    CITYNAME_HEB: "RAJSHAHI",
    COUNTRYCODE: "BD",
    COUNTRYNAME: "BANGLADESH",
    COUNTRYNAME_HEB: "\u05d1\u05e0\u05d2\u05dc\u05d3\u05e9",
  },
  {
    AIRPORTCODE: "RJI",
    AIRPORTNAME: "Rajouri",
    CITYCODE: "RJI",
    CITYNAME: "RAJOURI",
    CITYNAME_HEB: "RAJOURI",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "RJK",
    AIRPORTNAME: "Rijeka",
    CITYCODE: "RJK",
    CITYNAME: "RIJEKA",
    CITYNAME_HEB: "\u05e8\u05d9\u05d0\u05e7\u05d4",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PLU",
    AIRPORTNAME: null,
    CITYCODE: "BHZ",
    CITYNAME: "BELO HORIZONTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RKC",
    AIRPORTNAME: "Yreka",
    CITYCODE: "RKC",
    CITYNAME: "YREKA",
    CITYNAME_HEB: "YREKA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RKD",
    AIRPORTNAME: "Knox County Regional",
    CITYCODE: "RKD",
    CITYNAME: "ROCKLAND",
    CITYNAME_HEB: "ROCKLAND",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RKH",
    AIRPORTNAME: "Rock Hill",
    CITYCODE: "RKH",
    CITYNAME: "ROCK HILL",
    CITYNAME_HEB: "ROCK HILL",
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB:
      '\u05e7\u05e8\u05dc\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RKI",
    AIRPORTNAME: "Rokot",
    CITYCODE: "RKI",
    CITYNAME: "ROKOT",
    CITYNAME_HEB: "ROKOT",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RKO",
    AIRPORTNAME: "Sipora",
    CITYCODE: "RKO",
    CITYNAME: "SIPORA",
    CITYNAME_HEB: "SIPORA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RKP",
    AIRPORTNAME: "Aransas County",
    CITYCODE: "RKP",
    CITYNAME: "ROCKPORT",
    CITYNAME_HEB: "ROCKPORT",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RKR",
    AIRPORTNAME: "Robert S Kerr",
    CITYCODE: "RKR",
    CITYNAME: "POTEAU",
    CITYNAME_HEB: "POTEAU",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RKS",
    AIRPORTNAME: "Sweetwater County",
    CITYCODE: "RKS",
    CITYNAME: "ROCK SPRINGS",
    CITYNAME_HEB: "ROCK SPRINGS",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RKT",
    AIRPORTNAME: null,
    CITYCODE: "RKT",
    CITYNAME: "RAS AL KHAIMAH",
    CITYNAME_HEB: "\u05e8\u05d0\u05e1 \u05d0\u05dc-\u05d7\u05d9\u05de\u05d4",
    COUNTRYCODE: "AE",
    COUNTRYNAME: "UNITED ARAB EMIRATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d7\u05d5\u05d3 \u05d4\u05d0\u05de\u05d9\u05e8\u05d5\u05d9\u05d5\u05ea",
  },
  {
    AIRPORTCODE: "RKU",
    AIRPORTNAME: "Kairuku",
    CITYCODE: "RKU",
    CITYNAME: "YULE ISLAND",
    CITYNAME_HEB: "YULE ISLAND",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ADK",
    AIRPORTNAME: "Adak Island NS",
    CITYCODE: "ADK",
    CITYNAME: "ADAK ISLAND",
    CITYNAME_HEB: "Adak Island NS",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BIA",
    AIRPORTNAME: "Poretta",
    CITYCODE: "BIA",
    CITYNAME: "BASTIA",
    CITYNAME_HEB: "\u05d1\u05d0\u05e1\u05d8\u05d9\u05d4",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "RKW",
    AIRPORTNAME: "Municipal",
    CITYCODE: "RKW",
    CITYNAME: "ROCKWOOD",
    CITYNAME_HEB: "ROCKWOOD",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RKY",
    AIRPORTNAME: "Rokeby",
    CITYCODE: "RKY",
    CITYNAME: "ROKEBY",
    CITYNAME_HEB: "ROKEBY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RLA",
    AIRPORTNAME: "National",
    CITYCODE: "RLA",
    CITYNAME: "ROLLA",
    CITYNAME_HEB: "ROLLA",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RLD",
    AIRPORTNAME: "Richland",
    CITYCODE: "RLD",
    CITYNAME: "RICHLAND",
    CITYNAME_HEB: "RICHLAND",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RLG",
    AIRPORTNAME: "Laage",
    CITYCODE: "RLG",
    CITYNAME: "ROSTOCK LAAGE",
    CITYNAME_HEB: "ROSTOCK LAAGE",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RLP",
    AIRPORTNAME: "Rosella Plains",
    CITYCODE: "RLP",
    CITYNAME: "ROSELLA PLAINS",
    CITYNAME_HEB: "ROSELLA PLAINS",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RLT",
    AIRPORTNAME: null,
    CITYCODE: "RLT",
    CITYNAME: "ARLIT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NE",
    COUNTRYNAME: "NIGER",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RLU",
    AIRPORTNAME: "Bornite Upper",
    CITYCODE: "RLU",
    CITYNAME: "BORNITE",
    CITYNAME_HEB: "Bornite Upper",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RMA",
    AIRPORTNAME: "Roma",
    CITYCODE: "RMA",
    CITYNAME: "ROMA",
    CITYNAME_HEB: "ROMA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RMB",
    AIRPORTNAME: null,
    CITYCODE: "RMB",
    CITYNAME: "BURAIMI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "OM",
    COUNTRYNAME: "OMAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BIB",
    AIRPORTNAME: "Baidoa",
    CITYCODE: "BIB",
    CITYNAME: "BAIDOA",
    CITYNAME_HEB: "BAIDOA",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RMD",
    AIRPORTNAME: "Ramagundam",
    CITYCODE: "RMD",
    CITYNAME: "RAMAGUNDAM",
    CITYNAME_HEB: "RAMAGUNDAM",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "RMI",
    AIRPORTNAME: "Miramare",
    CITYCODE: "RMI",
    CITYNAME: "RIMINI",
    CITYNAME_HEB: "\u05e8\u05de\u05d9\u05e0\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RMK",
    AIRPORTNAME: "Renmark",
    CITYCODE: "RMK",
    CITYNAME: "RENMARK",
    CITYNAME_HEB: "RENMARK",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RMN",
    AIRPORTNAME: "Rumginae",
    CITYCODE: "RMN",
    CITYNAME: "RUMGINAE",
    CITYNAME_HEB: "RUMGINAE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "RMP",
    AIRPORTNAME: " ",
    CITYCODE: "RMP",
    CITYNAME: "ROOMPOT",
    CITYNAME_HEB: "\u05e8\u05d5\u05de\u05e4\u05d5\u05d8",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "RMS",
    AIRPORTNAME: "Ramstein",
    CITYCODE: "RMS",
    CITYNAME: "RAMSTEIN",
    CITYNAME_HEB: "RAMSTEIN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RNA",
    AIRPORTNAME: "Ulawa Airport",
    CITYCODE: "RNA",
    CITYNAME: "ARONA",
    CITYNAME_HEB: "ARONA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "RNB",
    AIRPORTNAME: "Kallinge",
    CITYCODE: "RNB",
    CITYNAME: "RONNEBY",
    CITYNAME_HEB: "RONNEBY",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BIC",
    AIRPORTNAME: "Big Creek",
    CITYCODE: "BIC",
    CITYNAME: "BIG CREEK",
    CITYNAME_HEB: "Big Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XWK",
    AIRPORTNAME: null,
    CITYCODE: "RNB",
    CITYNAME: "RONNEBY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XXY",
    AIRPORTNAME: null,
    CITYCODE: "RNB",
    CITYNAME: "RONNEBY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RNC",
    AIRPORTNAME: "Warren County",
    CITYCODE: "RNC",
    CITYNAME: "MCMINNVILLE",
    CITYNAME_HEB: "MCMINNVILLE",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RNE",
    AIRPORTNAME: "Renaison",
    CITYCODE: "RNE",
    CITYNAME: "ROANNE",
    CITYNAME_HEB: "ROANNE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "RNG",
    AIRPORTNAME: "Rangely",
    CITYCODE: "RNG",
    CITYNAME: "RANGELY",
    CITYNAME_HEB: "RANGELY",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RNH",
    AIRPORTNAME: "Municipal",
    CITYCODE: "RNH",
    CITYNAME: "NEW RICHMOND",
    CITYNAME_HEB: "NEW RICHMOND",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RNI",
    AIRPORTNAME: "Corn Island",
    CITYCODE: "RNI",
    CITYNAME: "CORN ISLAND",
    CITYNAME_HEB: "CORN ISLAND",
    COUNTRYCODE: "NI",
    COUNTRYNAME: "NICARAGUA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05e7\u05e8\u05d0\u05d2\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "RNJ",
    AIRPORTNAME: "Yoronjima",
    CITYCODE: "RNJ",
    CITYNAME: "YORONJIMA",
    CITYNAME_HEB: "YORONJIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "RNL",
    AIRPORTNAME: "Rennell",
    CITYCODE: "RNL",
    CITYNAME: "RENNELL",
    CITYNAME_HEB: "RENNELL",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "RNN",
    AIRPORTNAME: "Bornholm",
    CITYCODE: "RNN",
    CITYNAME: "BORNHOLM",
    CITYNAME_HEB: "\u05d1\u05d5\u05e8\u05e0\u05d4\u05d5\u05dc\u05dd",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "BID",
    AIRPORTNAME: "Block Island",
    CITYCODE: "BID",
    CITYNAME: "BLOCK ISLAND",
    CITYNAME_HEB: "BLOCK ISLAND",
    COUNTRYCODE: "USRI",
    COUNTRYNAME: "RHODE ISLAND",
    COUNTRYNAME_HEB:
      '\u05e8\u05d5\u05d3 \u05d0\u05d9\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RNO",
    AIRPORTNAME: "Reno/Tahoe International Airport",
    CITYCODE: "RNO",
    CITYNAME: "RENO",
    CITYNAME_HEB: "RENO",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RNP",
    AIRPORTNAME: "Rongelap Island",
    CITYCODE: "RNP",
    CITYNAME: "RONGELAP ISLAND",
    CITYNAME_HEB: "RONGELAP ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "RNR",
    AIRPORTNAME: "Robinson River",
    CITYCODE: "RNR",
    CITYNAME: "ROBINSON RIVER",
    CITYNAME_HEB: "ROBINSON RIVER",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "RNS",
    AIRPORTNAME: "St Jacques",
    CITYCODE: "RNS",
    CITYNAME: "RENNES",
    CITYNAME_HEB: "RENNES",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "ZFJ",
    AIRPORTNAME: null,
    CITYCODE: "RNS",
    CITYNAME: "RENNES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RNT",
    AIRPORTNAME: "Renton",
    CITYCODE: "RNT",
    CITYNAME: "RENTON",
    CITYNAME_HEB: "RENTON",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RNU",
    AIRPORTNAME: "Ranau",
    CITYCODE: "RNU",
    CITYNAME: "RANAU",
    CITYNAME_HEB: "RANAU",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RNZ",
    AIRPORTNAME: "Rensselaer",
    CITYCODE: "RNZ",
    CITYNAME: "RENSSELAER",
    CITYNAME_HEB: "RENSSELAER",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ROA",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ROA",
    CITYNAME: "ROANOKE",
    CITYNAME_HEB: "ROANOKE",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "ROC",
    AIRPORTNAME: "Monroe County",
    CITYCODE: "ROC",
    CITYNAME: "ROCHESTER",
    CITYNAME_HEB: "ROCHESTER",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BIE",
    AIRPORTNAME: "Beatrice",
    CITYCODE: "BIE",
    CITYNAME: "BEATRICE",
    CITYNAME_HEB: "BEATRICE",
    COUNTRYCODE: "USNE",
    COUNTRYNAME: "NEBRASKA",
    COUNTRYNAME_HEB:
      '\u05e0\u05d1\u05e8\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ZTE",
    AIRPORTNAME: null,
    CITYCODE: "ROC",
    CITYNAME: "ROCHESTER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ROD",
    AIRPORTNAME: "Robertson",
    CITYCODE: "ROD",
    CITYNAME: "ROBERTSON",
    CITYNAME_HEB: "ROBERTSON",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "ROG",
    AIRPORTNAME: "Rogers",
    CITYCODE: "ROG",
    CITYNAME: "ROGERS",
    CITYNAME_HEB: "ROGERS",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ROH",
    AIRPORTNAME: "Robinhood",
    CITYCODE: "ROH",
    CITYNAME: "ROBINHOOD",
    CITYNAME_HEB: "ROBINHOOD",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ROI",
    AIRPORTNAME: "Roi Et Airport",
    CITYCODE: "ROI",
    CITYNAME: "ROI ET (MUANG)",
    CITYNAME_HEB: "ROI ET (MUANG)",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ROK",
    AIRPORTNAME: "Rockhampton",
    CITYCODE: "ROK",
    CITYNAME: "ROCKHAMPTON",
    CITYNAME_HEB: "ROCKHAMPTON",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ROL",
    AIRPORTNAME: "Roosevelt",
    CITYCODE: "ROL",
    CITYNAME: "ROOSEVELT",
    CITYNAME_HEB: "ROOSEVELT",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "CIA",
    AIRPORTNAME: "Ciampino",
    CITYCODE: "ROM",
    CITYNAME: "ROME",
    CITYNAME_HEB: "\u05e8\u05d5\u05de\u05d0",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FCO",
    AIRPORTNAME: "Fiumicino",
    CITYCODE: "ROM",
    CITYNAME: "ROME",
    CITYNAME_HEB: "\u05e8\u05d5\u05de\u05d0",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ROM",
    AIRPORTNAME: null,
    CITYCODE: "ROM",
    CITYNAME: "ROME",
    CITYNAME_HEB: "\u05e8\u05d5\u05de\u05d0",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BIG",
    AIRPORTNAME: "Big Delta Intermediate Field",
    CITYCODE: "BIG",
    CITYNAME: "BIG DELTA",
    CITYNAME_HEB: "Big Delta Intermediate Field",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RON",
    AIRPORTNAME: "Rondon",
    CITYCODE: "RON",
    CITYNAME: "RONDON",
    CITYNAME_HEB: "RONDON",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ROO",
    AIRPORTNAME: null,
    CITYCODE: "ROO",
    CITYNAME: "RONDONOPOLIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ROP",
    AIRPORTNAME: null,
    CITYCODE: "ROP",
    CITYNAME: "ROTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "MP",
    COUNTRYNAME: "NORTHERN MARIANA ISLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ROR",
    AIRPORTNAME: null,
    CITYCODE: "ROR",
    CITYNAME: "KOROR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PW",
    COUNTRYNAME: "PALAU ISLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ROS",
    AIRPORTNAME: null,
    CITYCODE: "ROS",
    CITYNAME: "ROSARIO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ROT",
    AIRPORTNAME: "Rotorua",
    CITYCODE: "ROT",
    CITYNAME: "ROTORUA",
    CITYNAME_HEB: "\u05e8\u05d5\u05d8\u05e8\u05d5\u05d0\u05d4",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ROU",
    AIRPORTNAME: "Rousse",
    CITYCODE: "ROU",
    CITYNAME: "ROUSSE",
    CITYNAME_HEB: "ROUSSE",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ROV",
    AIRPORTNAME: "Rostov",
    CITYCODE: "ROV",
    CITYNAME: "ROSTOV",
    CITYNAME_HEB: "\u05e8\u05d5\u05e1\u05d8\u05d5\u05d1",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ROW",
    AIRPORTNAME: "Industrial",
    CITYCODE: "ROW",
    CITYNAME: "ROSWELL",
    CITYNAME_HEB: "ROSWELL",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ROX",
    AIRPORTNAME: "Municipal",
    CITYCODE: "ROX",
    CITYNAME: "ROSEAU",
    CITYNAME_HEB: "ROSEAU",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BIH",
    AIRPORTNAME: "Bishop",
    CITYCODE: "BIH",
    CITYNAME: "BISHOP",
    CITYNAME_HEB: "BISHOP",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ROY",
    AIRPORTNAME: null,
    CITYCODE: "ROY",
    CITYNAME: "RIO MAYO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RPA",
    AIRPORTNAME: "Rolpa",
    CITYCODE: "RPA",
    CITYNAME: "ROLPA",
    CITYNAME_HEB: "ROLPA",
    COUNTRYCODE: "NP",
    COUNTRYNAME: "NEPAL",
    COUNTRYNAME_HEB: "\u05e0\u05e4\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "RPB",
    AIRPORTNAME: "Roper Bar",
    CITYCODE: "RPB",
    CITYNAME: "ROPER BAR",
    CITYNAME_HEB: "ROPER BAR",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RPM",
    AIRPORTNAME: "Ngukurr",
    CITYCODE: "RPM",
    CITYNAME: "NGUKURR",
    CITYNAME_HEB: "NGUKURR",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RPN",
    AIRPORTNAME: null,
    CITYCODE: "RPN",
    CITYNAME: "ROSH PINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RPR",
    AIRPORTNAME: "Raipur",
    CITYCODE: "RPR",
    CITYNAME: "RAIPUR",
    CITYNAME_HEB: "RAIPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "RPV",
    AIRPORTNAME: "Roper Valley",
    CITYCODE: "RPV",
    CITYNAME: "ROPER VALLEY",
    CITYNAME_HEB: "ROPER VALLEY",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RPX",
    AIRPORTNAME: "Roundup",
    CITYCODE: "RPX",
    CITYNAME: "ROUNDUP",
    CITYNAME_HEB: "ROUNDUP",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RRE",
    AIRPORTNAME: "Marree",
    CITYCODE: "RRE",
    CITYNAME: "MARREE",
    CITYNAME_HEB: "MARREE",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RRG",
    AIRPORTNAME: "Rodrigues Is",
    CITYCODE: "RRG",
    CITYNAME: "RODRIGUES IS",
    CITYNAME_HEB: "RODRIGUES IS",
    COUNTRYCODE: "MU",
    COUNTRYNAME: "MAURITIUS ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d0\u05d5\u05e8\u05d9\u05e6\u05d9\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "BII",
    AIRPORTNAME: null,
    CITYCODE: "BII",
    CITYNAME: "BRITISH ISLES AND ICELAND",
    CITYNAME_HEB:
      "\u05d4\u05d0\u05d9\u05d9\u05dd \u05d4\u05d1\u05e8\u05d9\u05d8\u05d9\u05d9\u05dd \u05d5\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3 ",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RRI",
    AIRPORTNAME: "Barora",
    CITYCODE: "RRI",
    CITYNAME: "BARORA",
    CITYNAME_HEB: "BARORA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "RRK",
    AIRPORTNAME: "Rourkela",
    CITYCODE: "RRK",
    CITYNAME: "ROURKELA",
    CITYNAME_HEB: "ROURKELA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "RRL",
    AIRPORTNAME: "Municipal",
    CITYCODE: "RRL",
    CITYNAME: "MERRILL",
    CITYNAME_HEB: "MERRILL",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RRM",
    AIRPORTNAME: "Marromeu",
    CITYCODE: "RRM",
    CITYNAME: "MARROMEU",
    CITYNAME_HEB: "MARROMEU",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "RRN",
    AIRPORTNAME: null,
    CITYCODE: "RRN",
    CITYNAME: "SERRA NORTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RRO",
    AIRPORTNAME: "Sorrento",
    CITYCODE: "RRO",
    CITYNAME: "SORRENTO",
    CITYNAME_HEB: "\u05e1\u05d5\u05e8\u05e0\u05d8\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RRS",
    AIRPORTNAME: "Roros",
    CITYCODE: "RRS",
    CITYNAME: "ROROS",
    CITYNAME_HEB: "ROROS",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RRT",
    AIRPORTNAME: "Warroad",
    CITYCODE: "RRT",
    CITYNAME: "WARROAD",
    CITYNAME_HEB: "WARROAD",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RRV",
    AIRPORTNAME: "Robinson River",
    CITYCODE: "RRV",
    CITYNAME: "ROBINSON RIVER",
    CITYNAME_HEB: "ROBINSON RIVER",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RSA",
    AIRPORTNAME: null,
    CITYCODE: "RSA",
    CITYNAME: "SANTA ROSA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BIJ",
    AIRPORTNAME: "Biliau",
    CITYCODE: "BIJ",
    CITYNAME: "BILIAU",
    CITYNAME_HEB: "BILIAU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "RSB",
    AIRPORTNAME: "Roseberth",
    CITYCODE: "RSB",
    CITYNAME: "ROSEBERTH",
    CITYNAME_HEB: "ROSEBERTH",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RSD",
    AIRPORTNAME: "S Eleuthera",
    CITYCODE: "RSD",
    CITYNAME: "ROCK SOUND",
    CITYNAME_HEB: "ROCK SOUND",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "RSG",
    AIRPORTNAME: null,
    CITYCODE: "RSG",
    CITYNAME: "SERRA PELADA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "RSH",
    AIRPORTNAME: "Russian SPB",
    CITYCODE: "RSH",
    CITYNAME: "RUSSIAN MISSION",
    CITYNAME_HEB: "Russian SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RSI",
    AIRPORTNAME: "Rio Sidra",
    CITYCODE: "RSI",
    CITYNAME: "RIO SIDRA",
    CITYNAME_HEB: "RIO SIDRA",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "RSJ",
    AIRPORTNAME: "Rosario SPB",
    CITYCODE: "RSJ",
    CITYNAME: "ROSARIO",
    CITYNAME_HEB: "ROSARIO",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RSK",
    AIRPORTNAME: "Ransiki",
    CITYCODE: "RSK",
    CITYNAME: "RANSIKI",
    CITYNAME_HEB: "RANSIKI",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RSL",
    AIRPORTNAME: "Russell",
    CITYCODE: "RSL",
    CITYNAME: "RUSSELL",
    CITYNAME_HEB: "RUSSELL",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RSN",
    AIRPORTNAME: "Ruston",
    CITYCODE: "RSN",
    CITYNAME: "RUSTON",
    CITYNAME_HEB: "RUSTON",
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB:
      '\u05dc\u05d5\u05d0\u05d9\u05d6\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "RSP",
    AIRPORTNAME: "Raspberry Strait",
    CITYCODE: "RSP",
    CITYNAME: "RASPBERRY STRAIT",
    CITYNAME_HEB: "Raspberry Strait",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BIK",
    AIRPORTNAME: "Mokmer",
    CITYCODE: "BIK",
    CITYNAME: "BIAK",
    CITYNAME_HEB: "BIAK",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RSS",
    AIRPORTNAME: "Roseires",
    CITYCODE: "RSS",
    CITYNAME: "ROSEIRES",
    CITYNAME_HEB: "ROSEIRES",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "JRC",
    AIRPORTNAME: null,
    CITYCODE: "RST",
    CITYNAME: "ROCHESTER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YGR",
    AIRPORTNAME: "Iles De La Madeleine",
    CITYCODE: "YGR",
    CITYNAME: "ILES DE LA MADELE",
    CITYNAME_HEB: "ILES DE LA MADELE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGS",
    AIRPORTNAME: "Germansen",
    CITYCODE: "YGS",
    CITYNAME: "GERMANSEN",
    CITYNAME_HEB: "GERMANSEN",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGT",
    AIRPORTNAME: "Igloolik",
    CITYCODE: "YGT",
    CITYNAME: "IGLOOLIK",
    CITYNAME_HEB: "IGLOOLIK",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGV",
    AIRPORTNAME: "Havre St Pierre",
    CITYCODE: "YGV",
    CITYNAME: "HAVRE ST PIERRE",
    CITYNAME_HEB: "HAVRE ST PIERRE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGW",
    AIRPORTNAME: "Kuujjuarapik",
    CITYCODE: "YGW",
    CITYNAME: "KUUJJUARAPIK",
    CITYNAME_HEB: "KUUJJUARAPIK",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGX",
    AIRPORTNAME: "Gillam",
    CITYCODE: "YGX",
    CITYNAME: "GILLAM",
    CITYNAME_HEB: "GILLAM",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGY",
    AIRPORTNAME: "Deception",
    CITYCODE: "YGY",
    CITYNAME: "DECEPTION",
    CITYNAME_HEB: "DECEPTION",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BSZ",
    AIRPORTNAME: "Bartletts",
    CITYCODE: "BSZ",
    CITYNAME: "BARTLETTS",
    CITYNAME_HEB: "Bartletts",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YGZ",
    AIRPORTNAME: "Grise Fiord",
    CITYCODE: "YGZ",
    CITYNAME: "GRISE FIORD",
    CITYNAME_HEB: "GRISE FIORD",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHA",
    AIRPORTNAME: "Port Hope Simpson",
    CITYCODE: "YHA",
    CITYNAME: "PORT HOPE SIMPSON",
    CITYNAME_HEB: "PORT HOPE SIMPSON",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHB",
    AIRPORTNAME: "Hudson Bay",
    CITYCODE: "YHB",
    CITYNAME: "HUDSON BAY",
    CITYNAME_HEB: "HUDSON BAY",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHC",
    AIRPORTNAME: "Hakai Pass",
    CITYCODE: "YHC",
    CITYNAME: "HAKAI PASS",
    CITYNAME_HEB: "HAKAI PASS",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHD",
    AIRPORTNAME: "Municipal",
    CITYCODE: "YHD",
    CITYNAME: "DRYDEN",
    CITYNAME_HEB: "DRYDEN",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHE",
    AIRPORTNAME: "Hope",
    CITYCODE: "YHE",
    CITYNAME: "HOPE",
    CITYNAME_HEB: "HOPE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHF",
    AIRPORTNAME: "Hearst",
    CITYCODE: "YHF",
    CITYNAME: "HEARST",
    CITYNAME_HEB: "HEARST",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHG",
    AIRPORTNAME: "Charlottetown",
    CITYCODE: "YHG",
    CITYNAME: "CHARLOTTETOWN",
    CITYNAME_HEB: "CHARLOTTETOWN",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHI",
    AIRPORTNAME: "Holman",
    CITYCODE: "YHI",
    CITYNAME: "HOLMAN",
    CITYNAME_HEB: "HOLMAN",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHK",
    AIRPORTNAME: "Gjoa Haven",
    CITYCODE: "YHK",
    CITYNAME: "GJOA HAVEN",
    CITYNAME_HEB: "GJOA HAVEN",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTA",
    AIRPORTNAME: "Bertoua",
    CITYCODE: "BTA",
    CITYNAME: "BERTOUA",
    CITYNAME_HEB: "BERTOUA",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "YHM",
    AIRPORTNAME: "Hamilton",
    CITYCODE: "YHM",
    CITYNAME: "HAMILTON",
    CITYNAME_HEB: "HAMILTON",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHN",
    AIRPORTNAME: "Hornepayne",
    CITYCODE: "YHN",
    CITYNAME: "HORNEPAYNE",
    CITYNAME_HEB: "HORNEPAYNE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHO",
    AIRPORTNAME: "Hopedale",
    CITYCODE: "YHO",
    CITYNAME: "HOPEDALE",
    CITYNAME_HEB: "HOPEDALE",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHP",
    AIRPORTNAME: "Poplar Hill",
    CITYCODE: "YHP",
    CITYNAME: "POPLAR HILL",
    CITYNAME_HEB: "POPLAR HILL",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHR",
    AIRPORTNAME: "Chevery",
    CITYCODE: "YHR",
    CITYNAME: "CHEVERY",
    CITYNAME_HEB: "CHEVERY",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHS",
    AIRPORTNAME: "Sechelt",
    CITYCODE: "YHS",
    CITYNAME: "SECHELT",
    CITYNAME_HEB: "SECHELT",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHT",
    AIRPORTNAME: "Haines Junction",
    CITYCODE: "YHT",
    CITYNAME: "HAINES JUNCTION",
    CITYNAME_HEB: "HAINES JUNCTION",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHY",
    AIRPORTNAME: "Hay River",
    CITYCODE: "YHY",
    CITYNAME: "HAY RIVER",
    CITYNAME_HEB: "HAY RIVER",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHZ",
    AIRPORTNAME: "Halifax International",
    CITYCODE: "YHZ",
    CITYNAME: "HALIFAX",
    CITYNAME_HEB: "\u05d4\u05dc\u05d9\u05e4\u05e7\u05e1",
    COUNTRYCODE: "CA",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XDG",
    AIRPORTNAME: null,
    CITYCODE: "YHZ",
    CITYNAME: "HALIFAX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CANS",
    COUNTRYNAME: "NOVA SCOTIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BTB",
    AIRPORTNAME: "Betou",
    CITYCODE: "BTB",
    CITYNAME: "BETOU",
    CITYNAME_HEB: "BETOU",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "YWF",
    AIRPORTNAME: null,
    CITYCODE: "YHZ",
    CITYNAME: "HALIFAX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CANS",
    COUNTRYNAME: "NOVA SCOTIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YIB",
    AIRPORTNAME: "Atikokan",
    CITYCODE: "YIB",
    CITYNAME: "ATIKOKAN",
    CITYNAME_HEB: "ATIKOKAN",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YIF",
    AIRPORTNAME: "Pakuashipi",
    CITYCODE: "YIF",
    CITYNAME: "PAKUASHIPI",
    CITYNAME_HEB: "PAKUASHIPI",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YIG",
    AIRPORTNAME: "Big Bay Marina",
    CITYCODE: "YIG",
    CITYNAME: "BIG BAY MARINA",
    CITYNAME_HEB: "BIG BAY MARINA",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YIH",
    AIRPORTNAME: "Yichang",
    CITYCODE: "YIH",
    CITYNAME: "YICHANG",
    CITYNAME_HEB: "YICHANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "YIK",
    AIRPORTNAME: "Ivujivik",
    CITYCODE: "YIK",
    CITYNAME: "IVUJIVIK",
    CITYNAME_HEB: "IVUJIVIK",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YIN",
    AIRPORTNAME: "Yining",
    CITYCODE: "YIN",
    CITYNAME: "YINING",
    CITYNAME_HEB: "YINING",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "YIO",
    AIRPORTNAME: "Pond Inlet",
    CITYCODE: "YIO",
    CITYNAME: "POND INLET",
    CITYNAME_HEB: "POND INLET",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YIV",
    AIRPORTNAME: "Island Lake Airport",
    CITYCODE: "YIV",
    CITYNAME: "ISLAND LK GARDEN",
    CITYNAME_HEB: "ISLAND LK GARDEN",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTC",
    AIRPORTNAME: "Batticaloa",
    CITYCODE: "BTC",
    CITYNAME: "BATTICALOA",
    CITYNAME_HEB: "BATTICALOA",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "YIW",
    AIRPORTNAME: "Yiwu",
    CITYCODE: "YIW",
    CITYNAME: "YIWU",
    CITYNAME_HEB: "YIWU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "YJA",
    AIRPORTNAME: "JASPER",
    CITYCODE: "YJA",
    CITYNAME: "JASPER",
    CITYNAME_HEB: "\u05d2\u05d0\u05e1\u05e4\u05e8",
    COUNTRYCODE: "CA",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YJF",
    AIRPORTNAME: "Fort Liard",
    CITYCODE: "YJF",
    CITYNAME: "FORT LIARD",
    CITYNAME_HEB: "FORT LIARD",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YJN",
    AIRPORTNAME: "St Jean",
    CITYCODE: "YJN",
    CITYNAME: "SAINT JEAN",
    CITYNAME_HEB: "SAINT JEAN",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YJO",
    AIRPORTNAME: "Johnny Mountain",
    CITYCODE: "YJO",
    CITYNAME: "JOHNNY MOUNTAIN",
    CITYNAME_HEB: "JOHNNY MOUNTAIN",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YJP",
    AIRPORTNAME: "Jasper-Hinton",
    CITYCODE: "YJP",
    CITYNAME: "JASPER HINTON",
    CITYNAME_HEB: "Jasper-Hinton",
    COUNTRYCODE: "CA",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YJT",
    AIRPORTNAME: "Stephenville",
    CITYCODE: "YJT",
    CITYNAME: "STEPHENVILLE",
    CITYNAME_HEB: "STEPHENVILLE",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YKA",
    AIRPORTNAME: "Kamloops",
    CITYCODE: "YKA",
    CITYNAME: "KAMLOOPS",
    CITYNAME_HEB: "\u05e7\u05de\u05dc\u05d5\u05e4\u05e1",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YKC",
    AIRPORTNAME: "Collins Bay",
    CITYCODE: "YKC",
    CITYNAME: "COLLINS BAY",
    CITYNAME_HEB: "COLLINS BAY",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "AET",
    AIRPORTNAME: null,
    CITYCODE: "AET",
    CITYNAME: "ALLAKAKET",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BTD",
    AIRPORTNAME: "Brunette Downs",
    CITYCODE: "BTD",
    CITYNAME: "BRUNETTE DOWNS",
    CITYNAME_HEB: "BRUNETTE DOWNS",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YKD",
    AIRPORTNAME: "Township Airport",
    CITYCODE: "YKD",
    CITYNAME: "KINCARDINE",
    CITYNAME_HEB: "KINCARDINE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YKE",
    AIRPORTNAME: "Knee Lake",
    CITYCODE: "YKE",
    CITYNAME: "KNEE LAKE",
    CITYNAME_HEB: "KNEE LAKE",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YKF",
    AIRPORTNAME: "Kitchener-Waterloo Reg",
    CITYCODE: "YKF",
    CITYNAME: "KITCHENER",
    CITYNAME_HEB: "KITCHENER",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YKG",
    AIRPORTNAME: "Kangirsuk",
    CITYCODE: "YKG",
    CITYNAME: "KANGIRSUK",
    CITYNAME_HEB: "KANGIRSUK",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YKI",
    AIRPORTNAME: "Kennosao Lake",
    CITYCODE: "YKI",
    CITYNAME: "KENNOSAO LAKE",
    CITYNAME_HEB: "KENNOSAO LAKE",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YKJ",
    AIRPORTNAME: "Key Lake",
    CITYCODE: "YKJ",
    CITYNAME: "KEY LAKE",
    CITYNAME_HEB: "KEY LAKE",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YKK",
    AIRPORTNAME: "Kitkatla",
    CITYCODE: "YKK",
    CITYNAME: "KITKATIA",
    CITYNAME_HEB: "KITKATIA",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YKL",
    AIRPORTNAME: "Schefferville",
    CITYCODE: "YKL",
    CITYNAME: "SCHEFFERVILLE",
    CITYNAME_HEB: "SCHEFFERVILLE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "FCT",
    AIRPORTNAME: "Firing Center AAF",
    CITYCODE: "YKM",
    CITYNAME: "YAKIMA",
    CITYNAME_HEB: "YAKIMA",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YKM",
    AIRPORTNAME: "Yakima Air Terminal",
    CITYCODE: "YKM",
    CITYNAME: "YAKIMA",
    CITYNAME_HEB: "YAKIMA",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BTE",
    AIRPORTNAME: "Bonthe",
    CITYCODE: "BTE",
    CITYNAME: "BONTHE",
    CITYNAME_HEB: "BONTHE",
    COUNTRYCODE: "SL",
    COUNTRYNAME: "SIERRA LEONE",
    COUNTRYNAME_HEB:
      "\u05e1\u05d9\u05d9\u05e8\u05d4 \u05dc\u05d9\u05d0\u05d5\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "YKN",
    AIRPORTNAME: "Chan Gurney",
    CITYCODE: "YKN",
    CITYNAME: "YANKTON",
    CITYNAME_HEB: "YANKTON",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YKQ",
    AIRPORTNAME: "Waskaganish",
    CITYCODE: "YKQ",
    CITYNAME: "WASKAGANISH",
    CITYNAME_HEB: "WASKAGANISH",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YKS",
    AIRPORTNAME: "Yakutsk",
    CITYCODE: "YKS",
    CITYNAME: "YAKUTSK",
    CITYNAME_HEB: "YAKUTSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YKT",
    AIRPORTNAME: "Klemtu",
    CITYCODE: "YKT",
    CITYNAME: "KLEMTU",
    CITYNAME_HEB: "KLEMTU",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YKU",
    AIRPORTNAME: "Chisasibi",
    CITYCODE: "YKU",
    CITYNAME: "CHISASIBI",
    CITYNAME_HEB: "CHISASIBI",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YKX",
    AIRPORTNAME: "Kirkland Lake",
    CITYCODE: "YKX",
    CITYNAME: "KIRKLAND LAKE",
    CITYNAME_HEB: "KIRKLAND LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YKY",
    AIRPORTNAME: "Kindersley",
    CITYCODE: "YKY",
    CITYNAME: "KINDERSLEY",
    CITYNAME_HEB: "KINDERSLEY",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLA",
    AIRPORTNAME: "Langara",
    CITYCODE: "YLA",
    CITYNAME: "LANGARA",
    CITYNAME_HEB: "LANGARA",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLB",
    AIRPORTNAME: "Lac Biche",
    CITYCODE: "YLB",
    CITYNAME: "LAC BICHE",
    CITYNAME_HEB: "Lac Biche",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLC",
    AIRPORTNAME: "Kimmirut",
    CITYCODE: "YLC",
    CITYNAME: "KIMMIRUT LAKE HARBOUR",
    CITYNAME_HEB: "KIMMIRUT LAKE HARBOUR",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTF",
    AIRPORTNAME: "Salt Lake Skypark",
    CITYCODE: "BTF",
    CITYNAME: "BOUNTIFUL",
    CITYNAME_HEB: "BOUNTIFUL",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YLD",
    AIRPORTNAME: "Chapleau",
    CITYCODE: "YLD",
    CITYNAME: "CHAPLEAU",
    CITYNAME_HEB: "CHAPLEAU",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLE",
    AIRPORTNAME: "Wha Ti",
    CITYCODE: "YLE",
    CITYNAME: "WHA TI LAC LA MATRE",
    CITYNAME_HEB: "WHA TI LAC LA MATRE",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLF",
    AIRPORTNAME: "LaForges",
    CITYCODE: "YLF",
    CITYNAME: "LAFORGES",
    CITYNAME_HEB: "LAFORGES",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLG",
    AIRPORTNAME: "Yalgoo",
    CITYCODE: "YLG",
    CITYNAME: "YALGOO",
    CITYNAME_HEB: "YALGOO",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YLH",
    AIRPORTNAME: "Lansdowne House",
    CITYCODE: "YLH",
    CITYNAME: "LANSDOWNE HOUSE",
    CITYNAME_HEB: "LANSDOWNE HOUSE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLI",
    AIRPORTNAME: "Ylivieska",
    CITYCODE: "YLI",
    CITYNAME: "YLIVIESKA",
    CITYNAME_HEB: "YLIVIESKA",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "YLJ",
    AIRPORTNAME: "Meadow Lake",
    CITYCODE: "YLJ",
    CITYNAME: "MEADOW LAKE",
    CITYNAME_HEB: "MEADOW LAKE",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLL",
    AIRPORTNAME: "Lloydminster",
    CITYCODE: "YLL",
    CITYNAME: "LLOYDMINSTER",
    CITYNAME_HEB: "Lloydminster",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLM",
    AIRPORTNAME: "Clinton Creek",
    CITYCODE: "YLM",
    CITYNAME: "CLINTON CREEK",
    CITYNAME_HEB: "CLINTON CREEK",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLN",
    AIRPORTNAME: "Yilan",
    CITYCODE: "YLN",
    CITYNAME: "YILAN",
    CITYNAME_HEB: "YILAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "BTG",
    AIRPORTNAME: null,
    CITYCODE: "BTG",
    CITYNAME: "BATANGAFO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YLO",
    AIRPORTNAME: "Shilo",
    CITYCODE: "YLO",
    CITYNAME: "SHILO",
    CITYNAME_HEB: "SHILO",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLP",
    AIRPORTNAME: "Mingan",
    CITYCODE: "YLP",
    CITYNAME: "MINGAN",
    CITYNAME_HEB: "MINGAN",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLQ",
    AIRPORTNAME: "La Tuque",
    CITYCODE: "YLQ",
    CITYNAME: "LA TUQUE",
    CITYNAME_HEB: "LA TUQUE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLR",
    AIRPORTNAME: "Leaf Rapids",
    CITYCODE: "YLR",
    CITYNAME: "LEAF RAPIDS",
    CITYNAME_HEB: "LEAF RAPIDS",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLS",
    AIRPORTNAME: "Lebel-Sur-Quevillon",
    CITYCODE: "YLS",
    CITYNAME: "LEBEL SUR QUEVILLON",
    CITYNAME_HEB: "LEBEL SUR QUEVILLON",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLT",
    AIRPORTNAME: "Alert",
    CITYCODE: "YLT",
    CITYNAME: "ALERT",
    CITYNAME_HEB: "ALERT",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLW",
    AIRPORTNAME: "Kelowna",
    CITYCODE: "YLW",
    CITYNAME: "KELOWNA",
    CITYNAME_HEB: "KELOWNA",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLX",
    AIRPORTNAME: "Long Point",
    CITYCODE: "YLX",
    CITYNAME: "LONG POINT",
    CITYNAME_HEB: "LONG POINT",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YLY",
    AIRPORTNAME: null,
    CITYCODE: "YLY",
    CITYNAME: "LYTTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YMA",
    AIRPORTNAME: "Mayo",
    CITYCODE: "YMA",
    CITYNAME: "MAYO",
    CITYNAME_HEB: "MAYO",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTH",
    AIRPORTNAME: "Hang Nadim",
    CITYCODE: "BTH",
    CITYNAME: "BATU BESAR",
    CITYNAME_HEB: "BATU BESAR",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YMB",
    AIRPORTNAME: "Merritt",
    CITYCODE: "YMB",
    CITYNAME: "MERRITT",
    CITYNAME_HEB: "MERRITT",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YMC",
    AIRPORTNAME: "Maricourt Airstrip",
    CITYCODE: "YMC",
    CITYNAME: "MARICOURT AIRSTRIP",
    CITYNAME_HEB: "MARICOURT AIRSTRIP",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YMD",
    AIRPORTNAME: "Mould Bay",
    CITYCODE: "YMD",
    CITYNAME: "MOULD BAY",
    CITYNAME_HEB: "MOULD BAY",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YME",
    AIRPORTNAME: "Matane",
    CITYCODE: "YME",
    CITYNAME: "MATANE",
    CITYNAME_HEB: "MATANE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YMF",
    AIRPORTNAME: "Montagne Harbor",
    CITYCODE: "YMF",
    CITYNAME: "MONTAGNE HARBOR",
    CITYNAME_HEB: "MONTAGNE HARBOR",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YMG",
    AIRPORTNAME: "Manitouwadge",
    CITYCODE: "YMG",
    CITYNAME: "MANITOUWADGE",
    CITYNAME_HEB: "MANITOUWADGE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YMH",
    AIRPORTNAME: "Mary's Harbour",
    CITYCODE: "YMH",
    CITYNAME: "MARY'S HARBOUR",
    CITYNAME_HEB: "MARY'S HARBOUR",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YMI",
    AIRPORTNAME: "Minaki",
    CITYCODE: "YMI",
    CITYNAME: "MINAKI",
    CITYNAME_HEB: "MINAKI",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YMJ",
    AIRPORTNAME: "Moose Jaw",
    CITYCODE: "YMJ",
    CITYNAME: "MOOSE JAW",
    CITYNAME_HEB: "MOOSE JAW",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YML",
    AIRPORTNAME: "Charlevoix",
    CITYCODE: "YML",
    CITYNAME: "MURRAY BAY",
    CITYNAME_HEB: "MURRAY BAY",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTI",
    AIRPORTNAME: "Barter Island",
    CITYCODE: "BTI",
    CITYNAME: "BARTER ISLAND",
    CITYNAME_HEB: "Barter Island",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YMM",
    AIRPORTNAME: "Fort Mcmurray",
    CITYCODE: "YMM",
    CITYNAME: "FORT MCMURRAY",
    CITYNAME_HEB: "Fort Mcmurray",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YMN",
    AIRPORTNAME: "Makkovik",
    CITYCODE: "YMN",
    CITYNAME: "MAKKOVIK",
    CITYNAME_HEB: "MAKKOVIK",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YMO",
    AIRPORTNAME: "Moosonee",
    CITYCODE: "YMO",
    CITYNAME: "MOOSONEE",
    CITYNAME_HEB: "MOOSONEE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YMP",
    AIRPORTNAME: "Port McNeil",
    CITYCODE: "YMP",
    CITYNAME: "PORT MCNEIL",
    CITYNAME_HEB: "PORT MCNEIL",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YMQ",
    AIRPORTNAME: " ",
    CITYCODE: "YMQ",
    CITYNAME: "MONTREAL",
    CITYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e8\u05d9\u05d0\u05d5\u05dc",
    COUNTRYCODE: "CA",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YUL",
    AIRPORTNAME: "Dorval",
    CITYCODE: "YMQ",
    CITYNAME: "MONTREAL",
    CITYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e8\u05d9\u05d0\u05d5\u05dc",
    COUNTRYCODE: "CA",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTJ",
    AIRPORTNAME: "Blang Bintang",
    CITYCODE: "BTJ",
    CITYNAME: "BANDA ACEH",
    CITYNAME_HEB: "BANDA ACEH",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YMR",
    AIRPORTNAME: "Merry Island",
    CITYCODE: "YMR",
    CITYNAME: "MERRY ISLAND",
    CITYNAME_HEB: "MERRY ISLAND",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YMS",
    AIRPORTNAME: "Yurimaguas",
    CITYCODE: "YMS",
    CITYNAME: "YURIMAGUAS",
    CITYNAME_HEB: "YURIMAGUAS",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "YMT",
    AIRPORTNAME: "Chibougamau",
    CITYCODE: "YMT",
    CITYNAME: "CHIBOUGAMAU",
    CITYNAME_HEB: "CHIBOUGAMAU",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YMW",
    AIRPORTNAME: "Maniwaki",
    CITYCODE: "YMW",
    CITYNAME: "MANIWAKI",
    CITYNAME_HEB: "MANIWAKI",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YNA",
    AIRPORTNAME: "Natashquan",
    CITYCODE: "YNA",
    CITYNAME: "NATASHQUAN",
    CITYNAME_HEB: "NATASHQUAN",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YNC",
    AIRPORTNAME: "Wemindji",
    CITYCODE: "YNC",
    CITYNAME: "WEMINDJI",
    CITYNAME_HEB: "WEMINDJI",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YND",
    AIRPORTNAME: "Gatineau",
    CITYCODE: "YND",
    CITYNAME: "GATINEAU",
    CITYNAME_HEB: "GATINEAU",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YNE",
    AIRPORTNAME: "Norway House",
    CITYCODE: "YNE",
    CITYNAME: "NORWAY HOUSE",
    CITYNAME_HEB: "NORWAY HOUSE",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YNF",
    AIRPORTNAME: "Deer Lake/Stephenville",
    CITYCODE: "YNF",
    CITYNAME: "CORNER BROOK",
    CITYNAME_HEB: "CORNER BROOK",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YNG",
    AIRPORTNAME: "Youngstown",
    CITYCODE: "YNG",
    CITYNAME: "YOUNGSTOWN WARREN",
    CITYNAME_HEB: "YOUNGSTOWN WARREN",
    COUNTRYCODE: "USOH",
    COUNTRYNAME: "OHIO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05d4\u05d9\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BTK",
    AIRPORTNAME: "Bratsk",
    CITYCODE: "BTK",
    CITYNAME: "BRATSK",
    CITYNAME_HEB: "BRATSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YNH",
    AIRPORTNAME: "Hudson Hope",
    CITYCODE: "YNH",
    CITYNAME: "HUDSON HOPE",
    CITYNAME_HEB: "HUDSON HOPE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YNI",
    AIRPORTNAME: "Nitchequon",
    CITYCODE: "YNI",
    CITYNAME: "NITCHEQUON",
    CITYNAME_HEB: "NITCHEQUON",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YNJ",
    AIRPORTNAME: "Yanji",
    CITYCODE: "YNJ",
    CITYNAME: "YANJI",
    CITYNAME_HEB: "YANJI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "YNK",
    AIRPORTNAME: "Nootka Sound",
    CITYCODE: "YNK",
    CITYNAME: "NOOTKA SOUND",
    CITYNAME_HEB: "NOOTKA SOUND",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YNL",
    AIRPORTNAME: "Points North Landing",
    CITYCODE: "YNL",
    CITYNAME: "POINTS NORTH LAND",
    CITYNAME_HEB: "POINTS NORTH LAND",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YNM",
    AIRPORTNAME: "Matagami",
    CITYCODE: "YNM",
    CITYNAME: "MATAGAMI",
    CITYNAME_HEB: "MATAGAMI",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YNN",
    AIRPORTNAME: "Yandi",
    CITYCODE: "YNN",
    CITYNAME: "YANDICOOGINA",
    CITYNAME_HEB: "YANDICOOGINA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YNO",
    AIRPORTNAME: "North Spirit Lake",
    CITYCODE: "YNO",
    CITYNAME: "NORTH SPIRIT LAKE",
    CITYNAME_HEB: "NORTH SPIRIT LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YNR",
    AIRPORTNAME: "Arnes",
    CITYCODE: "YNR",
    CITYNAME: "ARNES",
    CITYNAME_HEB: "ARNES",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YNS",
    AIRPORTNAME: "Nemiscau",
    CITYCODE: "YNS",
    CITYNAME: "NEMISCAU",
    CITYNAME_HEB: "NEMISCAU",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTL",
    AIRPORTNAME: "WK Kellogg Regional",
    CITYCODE: "BTL",
    CITYNAME: "BATTLE CREEK",
    CITYNAME_HEB: "BATTLE CREEK",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YNT",
    AIRPORTNAME: "Laishan",
    CITYCODE: "YNT",
    CITYNAME: "YANTAI",
    CITYNAME_HEB: "YANTAI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "YNZ",
    AIRPORTNAME: "Yancheng",
    CITYCODE: "YNZ",
    CITYNAME: "YANCHENG",
    CITYNAME_HEB: "YANCHENG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "YOA",
    AIRPORTNAME: "Ekati",
    CITYCODE: "YOA",
    CITYNAME: "EKATI",
    CITYNAME_HEB: "EKATI",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YOC",
    AIRPORTNAME: "Old Crow",
    CITYCODE: "YOC",
    CITYNAME: "OLD CROW",
    CITYNAME_HEB: "OLD CROW",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YOD",
    AIRPORTNAME: "Cold Lake",
    CITYCODE: "YOD",
    CITYNAME: "COLD LAKE",
    CITYNAME_HEB: "Cold Lake",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YOE",
    AIRPORTNAME: "Falher",
    CITYCODE: "YOE",
    CITYNAME: "FALHER",
    CITYNAME_HEB: "Falher",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YOG",
    AIRPORTNAME: "Ogoki",
    CITYCODE: "YOG",
    CITYNAME: "OGOKI",
    CITYNAME_HEB: "OGOKI",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YOH",
    AIRPORTNAME: "Oxford House",
    CITYCODE: "YOH",
    CITYNAME: "OXFORD HOUSE",
    CITYNAME_HEB: "OXFORD HOUSE",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDL",
    AIRPORTNAME: "Dease Lake",
    CITYCODE: "YDL",
    CITYNAME: "DEASE LAKE",
    CITYNAME_HEB: "DEASE LAKE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDN",
    AIRPORTNAME: "Dauphin",
    CITYCODE: "YDN",
    CITYNAME: "DAUPHIN",
    CITYNAME_HEB: "DAUPHIN",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDO",
    AIRPORTNAME: "St Methode",
    CITYCODE: "YDO",
    CITYNAME: "DOLBEAU",
    CITYNAME_HEB: "DOLBEAU",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDP",
    AIRPORTNAME: "Nain",
    CITYCODE: "YDP",
    CITYNAME: "NAIN",
    CITYNAME_HEB: "NAIN",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDQ",
    AIRPORTNAME: "Dawson Creek",
    CITYCODE: "YDQ",
    CITYNAME: "DAWSON CREEK",
    CITYNAME_HEB: "DAWSON CREEK",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "AES",
    AIRPORTNAME: null,
    CITYCODE: "AES",
    CITYNAME: "AALESUND",
    CITYNAME_HEB: "\u05d0\u05dc\u05e1\u05d5\u05e0\u05d3",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BST",
    AIRPORTNAME: null,
    CITYCODE: "BST",
    CITYNAME: "BOST",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YDR",
    AIRPORTNAME: "Broadview",
    CITYCODE: "YDR",
    CITYNAME: "BROADVIEW",
    CITYNAME_HEB: "BROADVIEW",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDS",
    AIRPORTNAME: "Desolation Sound",
    CITYCODE: "YDS",
    CITYNAME: "DESOLATION SOUND",
    CITYNAME_HEB: "DESOLATION SOUND",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDU",
    AIRPORTNAME: "Kasba Lake",
    CITYCODE: "YDU",
    CITYNAME: "KASBA LAKE",
    CITYNAME_HEB: "KASBA LAKE",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDV",
    AIRPORTNAME: "Bloodvein",
    CITYCODE: "YDV",
    CITYNAME: "BLOODVEIN",
    CITYNAME_HEB: "BLOODVEIN",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDW",
    AIRPORTNAME: "Obre Lake",
    CITYCODE: "YDW",
    CITYNAME: "OBRE LAKE",
    CITYNAME_HEB: "OBRE LAKE",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDX",
    AIRPORTNAME: "Doc Creek",
    CITYCODE: "YDX",
    CITYNAME: "DOC CREEK",
    CITYNAME_HEB: "DOC CREEK",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YEA",
    AIRPORTNAME: null,
    CITYCODE: "YEA",
    CITYNAME: "EDMONTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XZL",
    AIRPORTNAME: null,
    CITYCODE: "YEA",
    CITYNAME: "EDMONTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YED",
    AIRPORTNAME: null,
    CITYCODE: "YEA",
    CITYNAME: "EDMONTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YEG",
    AIRPORTNAME: "EDMONTON",
    CITYCODE: "YEA",
    CITYNAME: "EDMONTON",
    CITYNAME_HEB: "\u05d0\u05d3\u05de\u05d9\u05e0\u05d8\u05d5\u05df",
    COUNTRYCODE: "CA",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BSU",
    AIRPORTNAME: "Basankusu",
    CITYCODE: "BSU",
    CITYNAME: "BASANKUSU",
    CITYNAME_HEB: "BASANKUSU",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "YXD",
    AIRPORTNAME: null,
    CITYCODE: "YEA",
    CITYNAME: "EDMONTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YEC",
    AIRPORTNAME: null,
    CITYCODE: "YEC",
    CITYNAME: "YECHEON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YEI",
    AIRPORTNAME: "Yenisehir",
    CITYCODE: "YEI",
    CITYNAME: "BURSA",
    CITYNAME_HEB: "\u05d1\u05d5\u05e8\u05e1\u05d4",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YEK",
    AIRPORTNAME: "Arviat",
    CITYCODE: "YEK",
    CITYNAME: "ARVIAT",
    CITYNAME_HEB: "ARVIAT",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YEL",
    AIRPORTNAME: "Elliot Lake",
    CITYCODE: "YEL",
    CITYNAME: "ELLIOT LAKE",
    CITYNAME_HEB: "ELLIOT LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YEM",
    AIRPORTNAME: "East Manitoulin",
    CITYCODE: "YEM",
    CITYNAME: "MANITOWANING",
    CITYNAME_HEB: "MANITOWANING",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YEN",
    AIRPORTNAME: "Estevan",
    CITYCODE: "YEN",
    CITYNAME: "ESTEVAN",
    CITYNAME_HEB: "ESTEVAN",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YEO",
    AIRPORTNAME: "Yeovilton",
    CITYCODE: "YEO",
    CITYNAME: "YEOVILTON",
    CITYNAME_HEB: "YEOVILTON",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YEP",
    AIRPORTNAME: "Estevan Point",
    CITYCODE: "YEP",
    CITYNAME: "ESTEVAN POINT",
    CITYNAME_HEB: "ESTEVAN POINT",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YEQ",
    AIRPORTNAME: "Yenkis",
    CITYCODE: "YEQ",
    CITYNAME: "YENKIS",
    CITYNAME_HEB: "YENKIS",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BSV",
    AIRPORTNAME: "Besakoa",
    CITYCODE: "BSV",
    CITYNAME: "BESAKOA",
    CITYNAME_HEB: "BESAKOA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "YER",
    AIRPORTNAME: "Fort Severn",
    CITYCODE: "YER",
    CITYNAME: "FORT SEVERN",
    CITYNAME_HEB: "FORT SEVERN",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YET",
    AIRPORTNAME: "Edson",
    CITYCODE: "YET",
    CITYNAME: "EDSON",
    CITYNAME_HEB: "Edson",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YEU",
    AIRPORTNAME: "Eureka",
    CITYCODE: "YEU",
    CITYNAME: "EUREKA",
    CITYNAME_HEB: "EUREKA",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YEV",
    AIRPORTNAME: "Inuvik/Mike Zubko",
    CITYCODE: "YEV",
    CITYNAME: "INUVIK",
    CITYNAME_HEB: "INUVIK",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YEY",
    AIRPORTNAME: "Amos",
    CITYCODE: "YEY",
    CITYNAME: "AMOS",
    CITYNAME_HEB: "AMOS",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YFA",
    AIRPORTNAME: "Fort Albany",
    CITYCODE: "YFA",
    CITYNAME: "FORT ALBANY",
    CITYNAME_HEB: "FORT ALBANY",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YFB",
    AIRPORTNAME: "Iqaluit",
    CITYCODE: "YFB",
    CITYNAME: "IQALUIT",
    CITYNAME_HEB: "IQALUIT",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YFC",
    AIRPORTNAME: "Fredericton",
    CITYCODE: "YFC",
    CITYNAME: "FREDERICTON",
    CITYNAME_HEB: "FREDERICTON",
    COUNTRYCODE: "CANB",
    COUNTRYNAME: "NEW BRUNSWICK",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YFE",
    AIRPORTNAME: "Forestville",
    CITYCODE: "YFE",
    CITYNAME: "FORESTVILLE",
    CITYNAME_HEB: "FORESTVILLE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YFG",
    AIRPORTNAME: "Fontanges",
    CITYCODE: "YFG",
    CITYNAME: "FONTANGES",
    CITYNAME_HEB: "FONTANGES",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BSW",
    AIRPORTNAME: "Boswell Bay",
    CITYCODE: "BSW",
    CITYNAME: "BOSWELL BAY",
    CITYNAME_HEB: "Boswell Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YFH",
    AIRPORTNAME: "Fort Hope",
    CITYCODE: "YFH",
    CITYNAME: "FORT HOPE",
    CITYNAME_HEB: "FORT HOPE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YFJ",
    AIRPORTNAME: "Snare Lake",
    CITYCODE: "YFJ",
    CITYNAME: "SNARE LAKE",
    CITYNAME_HEB: "SNARE LAKE",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YFL",
    AIRPORTNAME: "Fort Reliance",
    CITYCODE: "YFL",
    CITYNAME: "FORT RELIANCE",
    CITYNAME_HEB: "FORT RELIANCE",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YFO",
    AIRPORTNAME: "Flin Flon",
    CITYCODE: "YFO",
    CITYNAME: "FLIN FLON",
    CITYNAME_HEB: "FLIN FLON",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YFR",
    AIRPORTNAME: "Fort Resolution",
    CITYCODE: "YFR",
    CITYNAME: "FORT RESOLUTION",
    CITYNAME_HEB: "FORT RESOLUTION",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YFS",
    AIRPORTNAME: "Fort Simpson",
    CITYCODE: "YFS",
    CITYNAME: "FORT SIMPSON",
    CITYNAME_HEB: "FORT SIMPSON",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YFX",
    AIRPORTNAME: "St Lewis",
    CITYCODE: "YFX",
    CITYNAME: "FOX HARBOUR",
    CITYNAME_HEB: "FOX HARBOUR",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGA",
    AIRPORTNAME: "Gagnon",
    CITYCODE: "YGA",
    CITYNAME: "GAGNON",
    CITYNAME_HEB: "GAGNON",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGB",
    AIRPORTNAME: "Gillies Bay",
    CITYCODE: "YGB",
    CITYNAME: "GILLIES BAY",
    CITYNAME_HEB: "GILLIES BAY",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGC",
    AIRPORTNAME: "Grande Cache",
    CITYCODE: "YGC",
    CITYNAME: "GRANDE CACHE",
    CITYNAME_HEB: "Grande Cache",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BSX",
    AIRPORTNAME: "Bassein",
    CITYCODE: "BSX",
    CITYNAME: "BASSEIN",
    CITYNAME_HEB: "BASSEIN",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "YGE",
    AIRPORTNAME: "Gorge Harbor",
    CITYCODE: "YGE",
    CITYNAME: "GORGE HARBOR",
    CITYNAME_HEB: "GORGE HARBOR",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGG",
    AIRPORTNAME: "Ganges Harbor",
    CITYCODE: "YGG",
    CITYNAME: "GANGES HARBOR",
    CITYNAME_HEB: "GANGES HARBOR",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGH",
    AIRPORTNAME: "Fort Good Hope",
    CITYCODE: "YGH",
    CITYNAME: "FORT GOOD HOPE",
    CITYNAME_HEB: "FORT GOOD HOPE",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGJ",
    AIRPORTNAME: "Miho",
    CITYCODE: "YGJ",
    CITYNAME: "YONAGO",
    CITYNAME_HEB: "YONAGO",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "YGK",
    AIRPORTNAME: "Kingston",
    CITYCODE: "YGK",
    CITYNAME: "KINGSTON",
    CITYNAME_HEB: "KINGSTON",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XEG",
    AIRPORTNAME: null,
    CITYCODE: "YGK",
    CITYNAME: "KINGSTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YGL",
    AIRPORTNAME: "La Grande",
    CITYCODE: "YGL",
    CITYNAME: "LA GRANDE",
    CITYNAME_HEB: "LA GRANDE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGM",
    AIRPORTNAME: "Gimli",
    CITYCODE: "YGM",
    CITYNAME: "GIMLI",
    CITYNAME_HEB: "GIMLI",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGN",
    AIRPORTNAME: "Greenway Sound",
    CITYCODE: "YGN",
    CITYNAME: "GREENWAY SOUND",
    CITYNAME_HEB: "GREENWAY SOUND",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGO",
    AIRPORTNAME: "Gods Narrows",
    CITYCODE: "YGO",
    CITYNAME: "GODS NARROWS",
    CITYNAME_HEB: "GODS NARROWS",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BSY",
    AIRPORTNAME: "Bardera",
    CITYCODE: "BSY",
    CITYNAME: "BARDERA",
    CITYNAME_HEB: "BARDERA",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XDD",
    AIRPORTNAME: null,
    CITYCODE: "YGP",
    CITYNAME: "GASPE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YGP",
    AIRPORTNAME: "Metropolitan Area",
    CITYCODE: "YGP",
    CITYNAME: "GASPE",
    CITYNAME_HEB: "GASPE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YGQ",
    AIRPORTNAME: "Geraldton",
    CITYCODE: "YGQ",
    CITYNAME: "GERALDTON",
    CITYNAME_HEB: "GERALDTON",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "WEF",
    AIRPORTNAME: "Weifang",
    CITYCODE: "WEF",
    CITYNAME: "WEIFANG",
    CITYNAME_HEB: "WEIFANG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "WEH",
    AIRPORTNAME: "Weihai",
    CITYCODE: "WEH",
    CITYNAME: "WEIHAI",
    CITYNAME_HEB: "WEIHAI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "BPG",
    CITYNAME: "BAD PETERSTAL GRIESBACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "WEI",
    AIRPORTNAME: " ",
    CITYCODE: "WEI",
    CITYNAME: "WEISBADEN",
    CITYNAME_HEB: "\u05d5\u05d9\u05d6\u05d1\u05d0\u05d3\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WEL",
    AIRPORTNAME: "Welkom",
    CITYCODE: "WEL",
    CITYNAME: "WELKOM",
    CITYNAME_HEB: "WELKOM",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "WEM",
    AIRPORTNAME: "West Malling",
    CITYCODE: "WEM",
    CITYNAME: "WEST MALLING",
    CITYNAME_HEB: "WEST MALLING",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WEP",
    AIRPORTNAME: "Weam",
    CITYCODE: "WEP",
    CITYNAME: "WEAM",
    CITYNAME_HEB: "WEAM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WES",
    AIRPORTNAME: null,
    CITYCODE: "WES",
    CITYNAME: "WEST AND NORTH EUROPE",
    CITYNAME_HEB:
      "\u05de\u05e2\u05e8\u05d1 \u05d5\u05e6\u05e4\u05d5\u05df \u05d0\u05d9\u05e8\u05d5\u05e4\u05d4",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WET",
    AIRPORTNAME: "Wagethe",
    CITYCODE: "WET",
    CITYNAME: "WAGETHE",
    CITYNAME_HEB: "WAGETHE",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WEW",
    AIRPORTNAME: "Wee Waa",
    CITYCODE: "WEW",
    CITYNAME: "WEE WAA",
    CITYNAME_HEB: "WEE WAA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WEX",
    AIRPORTNAME: "Castlebridge",
    CITYCODE: "WEX",
    CITYNAME: "WEXFORD",
    CITYNAME_HEB: "WEXFORD",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WFD",
    AIRPORTNAME: "Woodford",
    CITYCODE: "WFD",
    CITYNAME: "WOODFORD",
    CITYNAME_HEB: "WOODFORD",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WFI",
    AIRPORTNAME: "Fianarantsoa",
    CITYCODE: "WFI",
    CITYNAME: "FIANARANTSOA",
    CITYNAME_HEB: "FIANARANTSOA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "AAM",
    AIRPORTNAME: "Mala Mala",
    CITYCODE: "AAM",
    CITYNAME: "MALA MALA",
    CITYNAME_HEB: "MALA MALA",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "AEA",
    AIRPORTNAME: "Abemama Atoll",
    CITYCODE: "AEA",
    CITYNAME: "ABEMAMA ATOLL",
    CITYNAME_HEB: "ABEMAMA ATOLL",
    COUNTRYCODE: "KI",
    COUNTRYNAME: "KIRIBATI",
    COUNTRYNAME_HEB: "\u05e7\u05d9\u05e8\u05d9\u05d1\u05d8\u05d9",
  },
  {
    AIRPORTCODE: "BPH",
    AIRPORTNAME: "Bislig",
    CITYCODE: "BPH",
    CITYNAME: "BISLIG",
    CITYNAME_HEB: "BISLIG",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "WFK",
    AIRPORTNAME: "Frenchville",
    CITYCODE: "WFK",
    CITYNAME: "FRENCHVILLE",
    CITYNAME_HEB: "FRENCHVILLE",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WGA",
    AIRPORTNAME: "Forrest Hill",
    CITYCODE: "WGA",
    CITYNAME: "WAGGA WAGGA",
    CITYNAME_HEB: "WAGGA WAGGA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WGB",
    AIRPORTNAME: "Bahawalnagar",
    CITYCODE: "WGB",
    CITYNAME: "BAHAWALNAGAR",
    CITYNAME_HEB: "BAHAWALNAGAR",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "WGC",
    AIRPORTNAME: "Warrangal",
    CITYCODE: "WGC",
    CITYNAME: "WARRANGAL",
    CITYNAME_HEB: "WARRANGAL",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "WGE",
    AIRPORTNAME: "Walgett",
    CITYCODE: "WGE",
    CITYNAME: "WALGETT",
    CITYNAME_HEB: "WALGETT",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WGL",
    AIRPORTNAME: null,
    CITYCODE: "WGL",
    CITYNAME: "ISLE BALTRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "WGN",
    AIRPORTNAME: "Waitangi",
    CITYCODE: "WGN",
    CITYNAME: "WAITANGI",
    CITYNAME_HEB: "WAITANGI",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WGO",
    AIRPORTNAME: "Municipal",
    CITYCODE: "WGO",
    CITYNAME: "WINCHESTER",
    CITYNAME_HEB: "WINCHESTER",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "WGP",
    AIRPORTNAME: "Waingapu",
    CITYCODE: "WGP",
    CITYNAME: "WAINGAPU",
    CITYNAME_HEB: "WAINGAPU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WGT",
    AIRPORTNAME: "Wangaratta",
    CITYCODE: "WGT",
    CITYNAME: "WANGARATTA",
    CITYNAME_HEB: "WANGARATTA",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BPI",
    AIRPORTNAME: "Big Piney-Marbleton",
    CITYCODE: "BPI",
    CITYNAME: "BIG PINEY",
    CITYNAME_HEB: "BIG PINEY",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WGU",
    AIRPORTNAME: "Wagau",
    CITYCODE: "WGU",
    CITYNAME: "WAGAU",
    CITYNAME_HEB: "WAGAU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WGY",
    AIRPORTNAME: "Wagny",
    CITYCODE: "WGY",
    CITYNAME: "WAGNY",
    CITYNAME_HEB: "WAGNY",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "WHD",
    AIRPORTNAME: "HYDER",
    CITYCODE: "WHD",
    CITYNAME: "HYDER",
    CITYNAME_HEB: "HYDER",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WHF",
    AIRPORTNAME: "Wadi Halfa",
    CITYCODE: "WHF",
    CITYNAME: "WADI HALFA",
    CITYNAME_HEB: "WADI HALFA",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "WHK",
    AIRPORTNAME: "Whakatane",
    CITYCODE: "WHK",
    CITYNAME: "WHAKATANE",
    CITYNAME_HEB: "WHAKATANE",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WHL",
    AIRPORTNAME: "Welshpool",
    CITYCODE: "WHL",
    CITYNAME: "WELSHPOOL",
    CITYNAME_HEB: "WELSHPOOL",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WHO",
    AIRPORTNAME: "Franz Josef",
    CITYCODE: "WHO",
    CITYNAME: "FRANZ JOSEF",
    CITYNAME_HEB: "FRANZ JOSEF",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WHS",
    AIRPORTNAME: "Whalsay",
    CITYCODE: "WHS",
    CITYNAME: "WHALSAY",
    CITYNAME_HEB: "WHALSAY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WHT",
    AIRPORTNAME: "Wharton",
    CITYCODE: "WHT",
    CITYNAME: "WHARTON",
    CITYNAME_HEB: "WHARTON",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WHU",
    AIRPORTNAME: "Wuhu",
    CITYCODE: "WHU",
    CITYNAME: "WUHU",
    CITYNAME_HEB: "WUHU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "BPK",
    AIRPORTNAME: "Biangabip",
    CITYCODE: "BPK",
    CITYNAME: "BIANGABIP",
    CITYNAME_HEB: "BIANGABIP",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WIC",
    AIRPORTNAME: "Wick",
    CITYCODE: "WIC",
    CITYNAME: "WICK",
    CITYNAME_HEB: "WICK",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WID",
    AIRPORTNAME: "Wildenrath",
    CITYCODE: "WID",
    CITYNAME: "WILDENRATH",
    CITYNAME_HEB: "WILDENRATH",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WIK",
    AIRPORTNAME: "Surfdale",
    CITYCODE: "WIK",
    CITYNAME: "SURFDALE",
    CITYNAME_HEB: "SURFDALE",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WIN",
    AIRPORTNAME: "Winton",
    CITYCODE: "WIN",
    CITYNAME: "WINTON",
    CITYNAME_HEB: "WINTON",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WIO",
    AIRPORTNAME: "Wilcannia",
    CITYCODE: "WIO",
    CITYNAME: "WILCANNIA",
    CITYNAME_HEB: "WILCANNIA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WIR",
    AIRPORTNAME: "Wairoa",
    CITYCODE: "WIR",
    CITYNAME: "WAIROA",
    CITYNAME_HEB: "WAIROA",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WIT",
    AIRPORTNAME: "Wittenoom",
    CITYCODE: "WIT",
    CITYNAME: "WITTENOOM",
    CITYNAME_HEB: "WITTENOOM",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WIU",
    AIRPORTNAME: "Witu",
    CITYCODE: "WIU",
    CITYNAME: "WITU",
    CITYNAME_HEB: "WITU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WJA",
    AIRPORTNAME: "Woja",
    CITYCODE: "WJA",
    CITYNAME: "WOJA",
    CITYNAME_HEB: "WOJA",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "RZH",
    AIRPORTNAME: "Quartz Hill",
    CITYCODE: "WJF",
    CITYNAME: "LANCASTER",
    CITYNAME_HEB: "LANCASTER",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BPN",
    AIRPORTNAME: "Sepingan",
    CITYCODE: "BPN",
    CITYNAME: "BALIKPAPAN",
    CITYNAME_HEB: "BALIKPAPAN",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WJF",
    AIRPORTNAME: "William J Fox",
    CITYCODE: "WJF",
    CITYNAME: "LANCASTER",
    CITYNAME_HEB: "LANCASTER",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WJR",
    AIRPORTNAME: "Wajir",
    CITYCODE: "WJR",
    CITYNAME: "WAJIR",
    CITYNAME_HEB: "WAJIR",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WJU",
    AIRPORTNAME: "WonJu",
    CITYCODE: "WJU",
    CITYNAME: "WON JU",
    CITYNAME_HEB: "\u05d5\u05d5\u05e0\u05d2\u05d5",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "WKA",
    AIRPORTNAME: "Wanaka",
    CITYCODE: "WKA",
    CITYNAME: "WANAKA",
    CITYNAME_HEB: "WANAKA",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WKB",
    AIRPORTNAME: "Warracknabeal",
    CITYCODE: "WKB",
    CITYNAME: "WARRACKNABEAL",
    CITYNAME_HEB: "WARRACKNABEAL",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WKI",
    AIRPORTNAME: "Hwange",
    CITYCODE: "WKI",
    CITYNAME: "HWANGE",
    CITYNAME_HEB: "HWANGE",
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: "\u05d6\u05d9\u05de\u05d1\u05d1\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WKJ",
    AIRPORTNAME: "Hokkaido",
    CITYCODE: "WKJ",
    CITYNAME: "WAKKANAI",
    CITYNAME_HEB: "WAKKANAI",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "WKK",
    AIRPORTNAME: "Aleknagik",
    CITYCODE: "WKK",
    CITYNAME: "ALEKNAGIK",
    CITYNAME_HEB: "Aleknagik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WKL",
    AIRPORTNAME: "Waikoloa Airport",
    CITYCODE: "WKL",
    CITYNAME: "WAIKOLOA",
    CITYNAME_HEB: "WAIKOLOA",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WKM",
    AIRPORTNAME: null,
    CITYCODE: "WKM",
    CITYNAME: "WANKIE RHODESIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BPS",
    AIRPORTNAME: null,
    CITYCODE: "BPS",
    CITYNAME: "PORTO SEGURO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "WKN",
    AIRPORTNAME: "Wakunai",
    CITYCODE: "WKN",
    CITYNAME: "WAKUNAI",
    CITYNAME_HEB: "WAKUNAI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WKR",
    AIRPORTNAME: "Walker's Cay",
    CITYCODE: "WKR",
    CITYNAME: "WALKER'S CAY",
    CITYNAME_HEB: "WALKER'S CAY",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "WLA",
    AIRPORTNAME: "Wallal",
    CITYCODE: "WLA",
    CITYNAME: "WALLAL",
    CITYNAME_HEB: "WALLAL",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WLB",
    AIRPORTNAME: "Labouchere Bay",
    CITYCODE: "WLB",
    CITYNAME: "LABOUCHERE BAY",
    CITYNAME_HEB: "Labouchere Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WLC",
    AIRPORTNAME: "Walcha",
    CITYCODE: "WLC",
    CITYNAME: "WALCHA",
    CITYNAME_HEB: "WALCHA",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WLD",
    AIRPORTNAME: "Arkansas City",
    CITYCODE: "WLD",
    CITYNAME: "WINFIELD",
    CITYNAME_HEB: "WINFIELD",
    COUNTRYCODE: "USKS",
    COUNTRYNAME: "KANSAS",
    COUNTRYNAME_HEB: '\u05e7\u05e0\u05d6\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WLG",
    AIRPORTNAME: "International",
    CITYCODE: "WLG",
    CITYNAME: "WELLINGTON",
    CITYNAME_HEB: "\u05d5\u05d9\u05dc\u05d9\u05e0\u05d2\u05d8\u05d5\u05df",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WLH",
    AIRPORTNAME: "Walaha",
    CITYCODE: "WLH",
    CITYNAME: "WALAHA",
    CITYNAME_HEB: "WALAHA",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "WLK",
    AIRPORTNAME: "Selawik",
    CITYCODE: "WLK",
    CITYNAME: "SELAWIK",
    CITYNAME_HEB: "Selawik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WLL",
    AIRPORTNAME: "Wollogorang",
    CITYCODE: "WLL",
    CITYNAME: "WOLLOGORANG",
    CITYNAME_HEB: "WOLLOGORANG",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BMT",
    AIRPORTNAME: null,
    CITYCODE: "BPT",
    CITYNAME: "BEAUMONT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "WLM",
    AIRPORTNAME: "Waltham",
    CITYCODE: "WLM",
    CITYNAME: "WALTHAM",
    CITYNAME_HEB: "WALTHAM",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "WLN",
    AIRPORTNAME: "Little Naukati",
    CITYCODE: "WLN",
    CITYNAME: "LITTLE NAUKATI",
    CITYNAME_HEB: "Little Naukati",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WLO",
    AIRPORTNAME: "Waterloo",
    CITYCODE: "WLO",
    CITYNAME: "WATERLOO",
    CITYNAME_HEB: "WATERLOO",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WLR",
    AIRPORTNAME: "Loring",
    CITYCODE: "WLR",
    CITYNAME: "LORING",
    CITYNAME_HEB: "Loring",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WLS",
    AIRPORTNAME: null,
    CITYCODE: "WLS",
    CITYNAME: "WALLIS ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "WF",
    COUNTRYNAME: "WALLIS AND FUTUNA ISLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "WLW",
    AIRPORTNAME: "Glenn County",
    CITYCODE: "WLW",
    CITYNAME: "WILLOWS",
    CITYNAME_HEB: "WILLOWS",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WMA",
    AIRPORTNAME: "Mandritsara",
    CITYCODE: "WMA",
    CITYNAME: "MANDRITSARA",
    CITYNAME_HEB: "MANDRITSARA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WMB",
    AIRPORTNAME: "Warrnambool",
    CITYCODE: "WMB",
    CITYNAME: "WARRNAMBOOL",
    CITYNAME_HEB: "WARRNAMBOOL",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WMC",
    AIRPORTNAME: "Winnemucca",
    CITYCODE: "WMC",
    CITYNAME: "WINNEMUCCA",
    CITYNAME_HEB: "WINNEMUCCA",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WMD",
    AIRPORTNAME: "Mandabe",
    CITYCODE: "WMD",
    CITYNAME: "MANDABE",
    CITYNAME_HEB: "MANDABE",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "BPT",
    AIRPORTNAME: "Beaumont Airport",
    CITYCODE: "BPT",
    CITYNAME: "BEAUMONT",
    CITYNAME_HEB: "BEAUMONT",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WME",
    AIRPORTNAME: "Mount Keith",
    CITYCODE: "WME",
    CITYNAME: "MOUNT KEITH",
    CITYNAME_HEB: "MOUNT KEITH",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WMH",
    AIRPORTNAME: "Mountain Home",
    CITYCODE: "WMH",
    CITYNAME: "MOUNTAIN HOME",
    CITYNAME_HEB: "MOUNTAIN HOME",
    COUNTRYCODE: "USAR",
    COUNTRYNAME: "ARKANSAS",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05e7\u05e0\u05e1\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WMK",
    AIRPORTNAME: "MEYERS CHUCK",
    CITYCODE: "WMK",
    CITYNAME: "MEYERS CHUCK",
    CITYNAME_HEB: "MEYERS CHUCK",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WML",
    AIRPORTNAME: "Malaimbandy",
    CITYCODE: "WML",
    CITYNAME: "MALAIMBANDY",
    CITYNAME_HEB: "MALAIMBANDY",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WMN",
    AIRPORTNAME: "Maroantsetra",
    CITYCODE: "WMN",
    CITYNAME: "MAROANTSETRA",
    CITYNAME_HEB: "MAROANTSETRA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WMO",
    AIRPORTNAME: "White Mountain",
    CITYCODE: "WMO",
    CITYNAME: "WHITE MOUNTAIN",
    CITYNAME_HEB: "White Mountain",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WMP",
    AIRPORTNAME: "Mampikony",
    CITYCODE: "WMP",
    CITYNAME: "MAMPIKONY",
    CITYNAME_HEB: "MAMPIKONY",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WMR",
    AIRPORTNAME: "Mananara",
    CITYCODE: "WMR",
    CITYNAME: "MANANARA",
    CITYNAME_HEB: "MANANARA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WMV",
    AIRPORTNAME: "Madirovalo",
    CITYCODE: "WMV",
    CITYNAME: "MADIROVALO",
    CITYNAME_HEB: "MADIROVALO",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WMX",
    AIRPORTNAME: "Wamena",
    CITYCODE: "WMX",
    CITYNAME: "WAMENA",
    CITYNAME_HEB: "WAMENA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BPU",
    AIRPORTNAME: "Beppu",
    CITYCODE: "BPU",
    CITYNAME: "BEPPU",
    CITYNAME_HEB: "BEPPU",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "WNA",
    AIRPORTNAME: "Napakiak SPB",
    CITYCODE: "WNA",
    CITYNAME: "NAPAKIAK",
    CITYNAME_HEB: "Napakiak SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WNC",
    AIRPORTNAME: "Nichen Cove SPB",
    CITYCODE: "WNC",
    CITYNAME: "TUXEKAN ISL",
    CITYNAME_HEB: "Nichen Cove SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WND",
    AIRPORTNAME: "Windarra",
    CITYCODE: "WND",
    CITYNAME: "WINDARRA",
    CITYNAME_HEB: "WINDARRA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WNE",
    AIRPORTNAME: "Wora Na Ye",
    CITYCODE: "WNE",
    CITYNAME: "WORA NA YE",
    CITYNAME_HEB: "WORA NA YE",
    COUNTRYCODE: "GA",
    COUNTRYNAME: "GABON",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d1\u05d5\u05df",
  },
  {
    AIRPORTCODE: "WNN",
    AIRPORTNAME: "Wunnummin Lake",
    CITYCODE: "WNN",
    CITYNAME: "WUNNUMMIN LAKE",
    CITYNAME_HEB: "WUNNUMMIN LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "WNP",
    AIRPORTNAME: "Naga",
    CITYCODE: "WNP",
    CITYNAME: "NAGA",
    CITYNAME_HEB: "NAGA",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "WNR",
    AIRPORTNAME: "Windorah",
    CITYCODE: "WNR",
    CITYNAME: "WINDORAH",
    CITYNAME_HEB: "WINDORAH",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WNS",
    AIRPORTNAME: "Nawabshah",
    CITYCODE: "WNS",
    CITYNAME: "NAWABSHAH",
    CITYNAME_HEB: "NAWABSHAH",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "WNU",
    AIRPORTNAME: "Wanuma",
    CITYCODE: "WNU",
    CITYNAME: "WANUMA",
    CITYNAME_HEB: "WANUMA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WNZ",
    AIRPORTNAME: "Wenzhou",
    CITYCODE: "WNZ",
    CITYNAME: "WENZHOU",
    CITYNAME_HEB: "WENZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "BPX",
    AIRPORTNAME: "Bangda",
    CITYCODE: "BPX",
    CITYNAME: "BANGDA",
    CITYNAME_HEB: "BANGDA",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "WOA",
    AIRPORTNAME: "Wonenara",
    CITYCODE: "WOA",
    CITYNAME: "WONENARA",
    CITYNAME_HEB: "WONENARA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WOB",
    AIRPORTNAME: "Woodbridge RAF",
    CITYCODE: "WOB",
    CITYNAME: "SUTTONHEATH",
    CITYNAME_HEB: "SUTTONHEATH",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WOD",
    AIRPORTNAME: "Wood River",
    CITYCODE: "WOD",
    CITYNAME: "WOOD RIVER",
    CITYNAME_HEB: "Wood River",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WOE",
    AIRPORTNAME: "Woensdrecht AB",
    CITYCODE: "WOE",
    CITYNAME: "WOENSDRECHT",
    CITYNAME_HEB: "WOENSDRECHT",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WOG",
    AIRPORTNAME: "Woodgreen",
    CITYCODE: "WOG",
    CITYNAME: "WOODGREEN",
    CITYNAME_HEB: "WOODGREEN",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WOI",
    AIRPORTNAME: "Wologissi",
    CITYCODE: "WOI",
    CITYNAME: "WOLOGISSI",
    CITYNAME_HEB: "WOLOGISSI",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WOK",
    AIRPORTNAME: "Wonken",
    CITYCODE: "WOK",
    CITYNAME: "WONKEN",
    CITYNAME_HEB: "WONKEN",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "WOL",
    AIRPORTNAME: "Wollongong",
    CITYCODE: "WOL",
    CITYNAME: "WOLLONGONG",
    CITYNAME_HEB: "WOLLONGONG",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WON",
    AIRPORTNAME: "Wondoola",
    CITYCODE: "WON",
    CITYNAME: "WONDOOLA",
    CITYNAME_HEB: "WONDOOLA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WOO",
    AIRPORTNAME: "Woodchopper",
    CITYCODE: "WOO",
    CITYNAME: "WOODCHOPPER",
    CITYNAME_HEB: "Woodchopper",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BPY",
    AIRPORTNAME: "Besalampy",
    CITYCODE: "BPY",
    CITYNAME: "BESALAMPY",
    CITYNAME_HEB: "BESALAMPY",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WOR",
    AIRPORTNAME: "Moramba",
    CITYCODE: "WOR",
    CITYNAME: "ANKOREFO",
    CITYNAME_HEB: "ANKOREFO",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WOT",
    AIRPORTNAME: "Wonan",
    CITYCODE: "WOT",
    CITYNAME: "WONAN",
    CITYNAME_HEB: "WONAN",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "WOW",
    AIRPORTNAME: "Willow",
    CITYCODE: "WOW",
    CITYNAME: "WILLOW",
    CITYNAME_HEB: "Willow",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WPA",
    AIRPORTNAME: "Puerto Aisen",
    CITYCODE: "WPA",
    CITYNAME: "PUERTO AISEN",
    CITYNAME_HEB: "PUERTO AISEN",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "WPB",
    AIRPORTNAME: "Port Berge",
    CITYCODE: "WPB",
    CITYNAME: "PORT BERGE",
    CITYNAME_HEB: "PORT BERGE",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WPC",
    AIRPORTNAME: "Pincher Creek",
    CITYCODE: "WPC",
    CITYNAME: "PINCHER CREEK",
    CITYNAME_HEB: "Pincher Creek",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "WPK",
    AIRPORTNAME: "Wrotham Park",
    CITYCODE: "WPK",
    CITYNAME: "WROTHAM PARK",
    CITYNAME_HEB: "WROTHAM PARK",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WPL",
    AIRPORTNAME: "Powell Lake",
    CITYCODE: "WPL",
    CITYNAME: "POWELL LAKE",
    CITYNAME_HEB: "POWELL LAKE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "WPM",
    AIRPORTNAME: "Wipim",
    CITYCODE: "WPM",
    CITYNAME: "WIPIM",
    CITYNAME_HEB: "WIPIM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WPO",
    AIRPORTNAME: "North Fork Valley",
    CITYCODE: "WPO",
    CITYNAME: "PAONIA",
    CITYNAME_HEB: "PAONIA",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "AED",
    AIRPORTNAME: "Aleneva",
    CITYCODE: "AED",
    CITYNAME: "ALENEVA",
    CITYNAME_HEB: "Aleneva",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BQA",
    AIRPORTNAME: "Baler",
    CITYCODE: "BQA",
    CITYNAME: "BALER",
    CITYNAME_HEB: "BALER",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "WPR",
    AIRPORTNAME: "Porvenir",
    CITYCODE: "WPR",
    CITYNAME: "PORVENIR",
    CITYNAME_HEB: "PORVENIR",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "WPU",
    AIRPORTNAME: "Puerto Williams",
    CITYCODE: "WPU",
    CITYNAME: "PUERTO WILLIAMS",
    CITYNAME_HEB: "PUERTO WILLIAMS",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "WRA",
    AIRPORTNAME: "Warder",
    CITYCODE: "WRA",
    CITYNAME: "WARDER",
    CITYNAME_HEB: "WARDER",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WRE",
    AIRPORTNAME: "Whangarei",
    CITYCODE: "WRE",
    CITYNAME: "WHANGAREI",
    CITYNAME_HEB: "WHANGAREI",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WRG",
    AIRPORTNAME: "Wrangell SPB",
    CITYCODE: "WRG",
    CITYNAME: "WRANGELL",
    CITYNAME_HEB: "Wrangell SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WRH",
    AIRPORTNAME: "Wrench Creek",
    CITYCODE: "WRH",
    CITYNAME: "WRENCH CREEK",
    CITYNAME_HEB: "Wrench Creek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WRI",
    AIRPORTNAME: "Mc Guire AFB",
    CITYCODE: "WRI",
    CITYNAME: "FORT DIX",
    CITYNAME_HEB: "FORT DIX",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "WRL",
    AIRPORTNAME: "Worland",
    CITYCODE: "WRL",
    CITYNAME: "WORLAND",
    CITYNAME_HEB: "WORLAND",
    COUNTRYCODE: "USWY",
    COUNTRYNAME: "WYOMING",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05d5\u05de\u05d9\u05e0\u05d2 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WRO",
    AIRPORTNAME: "Strachowice",
    CITYCODE: "WRO",
    CITYNAME: "WROCLAW",
    CITYNAME_HEB: "\u05d5\u05e8\u05d5\u05e6\u05dc\u05d1",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "WRW",
    AIRPORTNAME: "Warrawagine",
    CITYCODE: "WRW",
    CITYNAME: "WARRAWAGINE",
    CITYNAME_HEB: "WARRAWAGINE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BQB",
    AIRPORTNAME: "Bussellton Airport",
    CITYCODE: "BQB",
    CITYNAME: "BUSSELLTON",
    CITYNAME_HEB: "BUSSELLTON",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WRY",
    AIRPORTNAME: "Westray",
    CITYCODE: "WRY",
    CITYNAME: "WESTRAY",
    CITYNAME_HEB: "WESTRAY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WSA",
    AIRPORTNAME: "Wasua",
    CITYCODE: "WSA",
    CITYNAME: "WASUA",
    CITYNAME_HEB: "WASUA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WSB",
    AIRPORTNAME: "STEAMBOAT BAY",
    CITYCODE: "WSB",
    CITYNAME: "STEAMBOAT BAY",
    CITYNAME_HEB: "STEAMBOAT BAY",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WSD",
    AIRPORTNAME: "Condron AAF",
    CITYCODE: "WSD",
    CITYNAME: "WHITE SANDS",
    CITYNAME_HEB: "WHITE SANDS",
    COUNTRYCODE: "USNM",
    COUNTRYNAME: "NEW MEXICO",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05de\u05e7\u05e1\u05d9\u05e7\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WSE",
    AIRPORTNAME: null,
    CITYCODE: "WSE",
    CITYNAME: "SANTA CECILIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "WSF",
    AIRPORTNAME: "Sarichef",
    CITYCODE: "WSF",
    CITYNAME: "SARICHEF",
    CITYNAME_HEB: "Sarichef",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WSG",
    AIRPORTNAME: "County",
    CITYCODE: "WSG",
    CITYNAME: "WASHINGTON",
    CITYNAME_HEB: "WASHINGTON",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WSH",
    AIRPORTNAME: "Brookhaven",
    CITYCODE: "WSH",
    CITYNAME: "SHIRLEY",
    CITYNAME_HEB: "SHIRLEY",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WSJ",
    AIRPORTNAME: "San Juan SPB",
    CITYCODE: "WSJ",
    CITYNAME: "SAN JUAN",
    CITYNAME_HEB: "San Juan SPB",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WSM",
    AIRPORTNAME: "Wiseman",
    CITYCODE: "WSM",
    CITYNAME: "WISEMAN",
    CITYNAME_HEB: "Wiseman",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BQE",
    AIRPORTNAME: null,
    CITYCODE: "BQE",
    CITYNAME: "BUBAQUE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GW",
    COUNTRYNAME: "GUINEA BISSAU",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "WSN",
    AIRPORTNAME: "South Naknek",
    CITYCODE: "WSN",
    CITYNAME: "SOUTH NAKNEK",
    CITYNAME_HEB: "South Naknek",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WSO",
    AIRPORTNAME: "Washabo",
    CITYCODE: "WSO",
    CITYNAME: "WASHABO",
    CITYNAME_HEB: "WASHABO",
    COUNTRYCODE: "SR",
    COUNTRYNAME: "SURINAME",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05e8\u05d9\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "WSP",
    AIRPORTNAME: "Waspam",
    CITYCODE: "WSP",
    CITYNAME: "WASPAM",
    CITYNAME_HEB: "WASPAM",
    COUNTRYCODE: "NI",
    COUNTRYNAME: "NICARAGUA",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05e7\u05e8\u05d0\u05d2\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WSR",
    AIRPORTNAME: "Wasior",
    CITYCODE: "WSR",
    CITYNAME: "WASIOR",
    CITYNAME_HEB: "WASIOR",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WST",
    AIRPORTNAME: "Westerly State",
    CITYCODE: "WST",
    CITYNAME: "WESTERLY",
    CITYNAME_HEB: "WESTERLY",
    COUNTRYCODE: "USRI",
    COUNTRYNAME: "RHODE ISLAND",
    COUNTRYNAME_HEB:
      '\u05e8\u05d5\u05d3 \u05d0\u05d9\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WSU",
    AIRPORTNAME: "Wasu",
    CITYCODE: "WSU",
    CITYNAME: "WASU",
    CITYNAME_HEB: "WASU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WSX",
    AIRPORTNAME: "Westsound",
    CITYCODE: "WSX",
    CITYNAME: "WESTSOUND",
    CITYNAME_HEB: "WESTSOUND",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WSY",
    AIRPORTNAME: "Whitsunday Airstrip",
    CITYCODE: "WSY",
    CITYNAME: "AIRLIE BEACH",
    CITYNAME_HEB: "AIRLIE BEACH",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WSZ",
    AIRPORTNAME: "Westport",
    CITYCODE: "WSZ",
    CITYNAME: "WESTPORT",
    CITYNAME_HEB: "WESTPORT",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WTA",
    AIRPORTNAME: "Tambohorano",
    CITYCODE: "WTA",
    CITYNAME: "TAMBOHORANO",
    CITYNAME_HEB: "TAMBOHORANO",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "BQI",
    AIRPORTNAME: "Bagani",
    CITYCODE: "BQI",
    CITYNAME: "BAGANI",
    CITYNAME_HEB: "BAGANI",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WTD",
    AIRPORTNAME: "West End",
    CITYCODE: "WTD",
    CITYNAME: "WEST END",
    CITYNAME_HEB: "WEST END",
    COUNTRYCODE: "BS",
    COUNTRYNAME: "BAHAMAS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05d4\u05d0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "WTE",
    AIRPORTNAME: "Wotje Island",
    CITYCODE: "WTE",
    CITYNAME: "WOTJE ISLAND",
    CITYNAME_HEB: "WOTJE ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "WTK",
    AIRPORTNAME: "Noatak",
    CITYCODE: "WTK",
    CITYNAME: "NOATAK",
    CITYNAME_HEB: "Noatak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WTL",
    AIRPORTNAME: "Tuntutuliak",
    CITYCODE: "WTL",
    CITYNAME: "TUNTUTULIAK",
    CITYNAME_HEB: "Tuntutuliak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WTN",
    AIRPORTNAME: "RAF Station",
    CITYCODE: "WTN",
    CITYNAME: "WADDINGTON",
    CITYNAME_HEB: "WADDINGTON",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WTO",
    AIRPORTNAME: "Wotho Island",
    CITYCODE: "WTO",
    CITYNAME: "WOTHO ISLAND",
    CITYNAME_HEB: "WOTHO ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "WTP",
    AIRPORTNAME: "Woitape",
    CITYCODE: "WTP",
    CITYNAME: "WOITAPE",
    CITYNAME_HEB: "WOITAPE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WTR",
    AIRPORTNAME: "White River",
    CITYCODE: "WTR",
    CITYNAME: "WHITE RIVER",
    CITYNAME_HEB: "WHITE RIVER",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WTS",
    AIRPORTNAME: "Tsiroanomandidy",
    CITYCODE: "WTS",
    CITYNAME: "TSIROANOMANDIDY",
    CITYNAME_HEB: "TSIROANOMANDIDY",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WTT",
    AIRPORTNAME: "Wantoat",
    CITYCODE: "WTT",
    CITYNAME: "WANTOAT",
    CITYNAME_HEB: "WANTOAT",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BQL",
    AIRPORTNAME: "Boulia",
    CITYCODE: "BQL",
    CITYNAME: "BOULIA",
    CITYNAME_HEB: "BOULIA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WTZ",
    AIRPORTNAME: "Whitianga",
    CITYCODE: "WTZ",
    CITYNAME: "WHITIANGA",
    CITYNAME_HEB: "WHITIANGA",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WUD",
    AIRPORTNAME: "Wudinna",
    CITYCODE: "WUD",
    CITYNAME: "WUDINNA",
    CITYNAME_HEB: "WUDINNA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WUG",
    AIRPORTNAME: "Wau",
    CITYCODE: "WUG",
    CITYNAME: "WAU",
    CITYNAME_HEB: "WAU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WUH",
    AIRPORTNAME: "Wuhan",
    CITYCODE: "WUH",
    CITYNAME: "WUHAN",
    CITYNAME_HEB: "WUHAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "WUM",
    AIRPORTNAME: "Wasum",
    CITYCODE: "WUM",
    CITYNAME: "WASUM",
    CITYNAME_HEB: "WASUM",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WUN",
    AIRPORTNAME: "Wiluna",
    CITYCODE: "WUN",
    CITYNAME: "WILUNA",
    CITYNAME_HEB: "WILUNA",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WUS",
    AIRPORTNAME: "Wuyishan",
    CITYCODE: "WUS",
    CITYNAME: "WUYISHAN",
    CITYNAME_HEB: "WUYISHAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "WUU",
    AIRPORTNAME: "Wau",
    CITYCODE: "WUU",
    CITYNAME: "WAU",
    CITYNAME_HEB: "WAU",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "WUV",
    AIRPORTNAME: "Wuvulu Is",
    CITYCODE: "WUV",
    CITYNAME: "WUVULU IS",
    CITYNAME_HEB: "WUVULU IS",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WUX",
    AIRPORTNAME: "Wuxi",
    CITYCODE: "WUX",
    CITYNAME: "WUXI",
    CITYNAME_HEB: "WUXI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "BQN",
    AIRPORTNAME: "Borinquen",
    CITYCODE: "BQN",
    CITYNAME: "AGUADILLA",
    CITYNAME_HEB: "AGUADILLA",
    COUNTRYCODE: "PR",
    COUNTRYNAME: "PUERTO RICO",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e8\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "WUZ",
    AIRPORTNAME: "Changzhoudao",
    CITYCODE: "WUZ",
    CITYNAME: "WUZHOU",
    CITYNAME_HEB: "WUZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "WVB",
    AIRPORTNAME: "Rooikop",
    CITYCODE: "WVB",
    CITYNAME: "WALVIS BAY",
    CITYNAME_HEB: "WALVIS BAY",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WVI",
    AIRPORTNAME: "Watsonville",
    CITYCODE: "WVI",
    CITYNAME: "WATSONVILLE",
    CITYNAME_HEB: "WATSONVILLE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WVK",
    AIRPORTNAME: "Manakara",
    CITYCODE: "WVK",
    CITYNAME: "MANAKARA",
    CITYNAME_HEB: "MANAKARA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WVL",
    AIRPORTNAME: "Robert Lafleur",
    CITYCODE: "WVL",
    CITYNAME: "WATERVILLE",
    CITYNAME_HEB: "WATERVILLE",
    COUNTRYCODE: "USME",
    COUNTRYNAME: "MAINE",
    COUNTRYNAME_HEB: '\u05de\u05d9\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WVN",
    AIRPORTNAME: "Wilhelmshaven",
    CITYCODE: "WVN",
    CITYNAME: "WILHELMSHAVEN",
    CITYNAME_HEB: "WILHELMSHAVEN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WWA",
    AIRPORTNAME: "Wasilla",
    CITYCODE: "WWA",
    CITYNAME: "WASILLA",
    CITYNAME_HEB: "Wasilla",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WWD",
    AIRPORTNAME: "Cape May County",
    CITYCODE: "WWD",
    CITYNAME: "WILDWOOD",
    CITYNAME_HEB: "WILDWOOD",
    COUNTRYCODE: "USNJ",
    COUNTRYNAME: "NEW JERSEY",
    COUNTRYNAME_HEB:
      "\u05e0\u05d9\u05d5 \u05d2'\u05e8\u05d6\u05d9 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "WWI",
    AIRPORTNAME: "Woodie Woodie",
    CITYCODE: "WWI",
    CITYNAME: "WOODIE WOODIE",
    CITYNAME_HEB: "WOODIE WOODIE",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WWK",
    AIRPORTNAME: "Boram",
    CITYCODE: "WWK",
    CITYNAME: "WEWAK",
    CITYNAME_HEB: "WEWAK",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BQO",
    AIRPORTNAME: "Bouna",
    CITYCODE: "BQO",
    CITYNAME: "BOUNA",
    CITYNAME_HEB: "BOUNA",
    COUNTRYCODE: "CI",
    COUNTRYNAME: "COTE D IVOIRE",
    COUNTRYNAME_HEB: "\u05d7\u05d5\u05e3 \u05d4\u05e9\u05e0\u05d4\u05d1",
  },
  {
    AIRPORTCODE: "WWP",
    AIRPORTNAME: "Whale Pass",
    CITYCODE: "WWP",
    CITYNAME: "WHALE PASS",
    CITYNAME_HEB: "Whale Pass",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WWR",
    AIRPORTNAME: "West Woodward",
    CITYCODE: "WWR",
    CITYNAME: "WOODWARD",
    CITYNAME_HEB: "WOODWARD",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WWS",
    AIRPORTNAME: "USAF Heliport",
    CITYCODE: "WWS",
    CITYNAME: "WILDWOOD",
    CITYNAME_HEB: "USAF Heliport",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WWT",
    AIRPORTNAME: "Newtok",
    CITYCODE: "WWT",
    CITYNAME: "NEWTOK",
    CITYNAME_HEB: "Newtok",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WWY",
    AIRPORTNAME: "West Wyalong",
    CITYCODE: "WWY",
    CITYNAME: "WEST WYALONG",
    CITYNAME_HEB: "WEST WYALONG",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WXF",
    AIRPORTNAME: "Wether Fld RAF",
    CITYCODE: "WXF",
    CITYNAME: "BRAINTREE",
    CITYNAME_HEB: "BRAINTREE",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WXN",
    AIRPORTNAME: "Wanxian",
    CITYCODE: "WXN",
    CITYNAME: "WANXIAN",
    CITYNAME_HEB: "WANXIAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "WYA",
    AIRPORTNAME: "Whyalla",
    CITYCODE: "WYA",
    CITYNAME: "WHYALLA",
    CITYNAME_HEB: "WHYALLA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WYB",
    AIRPORTNAME: "YES BAY",
    CITYCODE: "WYB",
    CITYNAME: "YES BAY",
    CITYNAME_HEB: "YES BAY",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WYE",
    AIRPORTNAME: "Yengema",
    CITYCODE: "WYE",
    CITYNAME: "YENGEMA",
    CITYNAME_HEB: "YENGEMA",
    COUNTRYCODE: "SL",
    COUNTRYNAME: "SIERRA LEONE",
    COUNTRYNAME_HEB:
      "\u05e1\u05d9\u05d9\u05e8\u05d4 \u05dc\u05d9\u05d0\u05d5\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "BQQ",
    AIRPORTNAME: null,
    CITYCODE: "BQQ",
    CITYNAME: "BARRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "WYN",
    AIRPORTNAME: "Wyndham",
    CITYCODE: "WYN",
    CITYNAME: "WYNDHAM",
    CITYNAME_HEB: "WYNDHAM",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WYS",
    AIRPORTNAME: "Yellowstone",
    CITYCODE: "WYS",
    CITYNAME: "WEST YELLOWSTONE",
    CITYNAME_HEB: "WEST YELLOWSTONE",
    COUNTRYCODE: "USMT",
    COUNTRYNAME: "MONTANA",
    COUNTRYNAME_HEB:
      '\u05de\u05d5\u05e0\u05d8\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XAB",
    AIRPORTNAME: null,
    CITYCODE: "XAB",
    CITYNAME: "ABBEVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XAC",
    AIRPORTNAME: null,
    CITYCODE: "XAC",
    CITYNAME: "ARCACHON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XAG",
    AIRPORTNAME: null,
    CITYCODE: "XAG",
    CITYNAME: "AGDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XAH",
    AIRPORTNAME: null,
    CITYCODE: "XAH",
    CITYNAME: "SILKEBORG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XAI",
    AIRPORTNAME: " ",
    CITYCODE: "XAI",
    CITYNAME: "AIX LES BAINS",
    CITYNAME_HEB: "\u05d0\u05e7\u05e1 \u05dc\u05d4 \u05d1\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "XAJ",
    AIRPORTNAME: null,
    CITYCODE: "XAJ",
    CITYNAME: "HIRTSHALS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XAK",
    AIRPORTNAME: null,
    CITYCODE: "XAK",
    CITYNAME: "HERNING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XAL",
    AIRPORTNAME: "Alamos",
    CITYCODE: "XAL",
    CITYNAME: "ALAMOS",
    CITYNAME_HEB: "ALAMOS",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "BQS",
    AIRPORTNAME: "Blagoveschensk",
    CITYCODE: "BQS",
    CITYNAME: "BLAGOVESCHENSK",
    CITYNAME_HEB: "BLAGOVESCHENSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XAM",
    AIRPORTNAME: " ",
    CITYCODE: "XAM",
    CITYNAME: "AMBOISE",
    CITYNAME_HEB: "\u05d0\u05de\u05d1\u05d5\u05e1\u05d9",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "XAN",
    AIRPORTNAME: null,
    CITYCODE: "XAN",
    CITYNAME: "ALENCON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XAP",
    AIRPORTNAME: null,
    CITYCODE: "XAP",
    CITYNAME: "CHAPECO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XAQ",
    AIRPORTNAME: null,
    CITYCODE: "XAQ",
    CITYNAME: "BROENDERSLEV",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XAR",
    AIRPORTNAME: "Aribinda",
    CITYCODE: "XAR",
    CITYNAME: "ARIBINDA",
    CITYNAME_HEB: "ARIBINDA",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "XAS",
    AIRPORTNAME: null,
    CITYCODE: "XAS",
    CITYNAME: "ALES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XAT",
    AIRPORTNAME: " ",
    CITYCODE: "XAT",
    CITYNAME: "ANTIBES",
    CITYNAME_HEB: "\u05d0\u05e0\u05d8\u05d9\u05d1\u05e1",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "XAU",
    AIRPORTNAME: "Saul",
    CITYCODE: "XAU",
    CITYNAME: "SAUL",
    CITYNAME_HEB: "SAUL",
    COUNTRYCODE: "GF",
    COUNTRYNAME: "FRENCH GUIANA",
    COUNTRYNAME_HEB:
      "\u05d2\u05d9\u05e0\u05d0\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "XAV",
    AIRPORTNAME: null,
    CITYCODE: "XAV",
    CITYNAME: "ALBERTVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XAW",
    AIRPORTNAME: null,
    CITYCODE: "XAW",
    CITYNAME: "CAPREOL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BQT",
    AIRPORTNAME: "Brest",
    CITYCODE: "BQT",
    CITYNAME: "BREST",
    CITYNAME_HEB: "BREST",
    COUNTRYCODE: "BY",
    COUNTRYNAME: "BELARUS",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d0\u05e8\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "XAY",
    AIRPORTNAME: "Xayabury",
    CITYCODE: "XAY",
    CITYNAME: "XAYABURY",
    CITYNAME_HEB: "XAYABURY",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "XBA",
    AIRPORTNAME: null,
    CITYCODE: "XBA",
    CITYNAME: "BAREGES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBB",
    AIRPORTNAME: "Blubber Bay",
    CITYCODE: "XBB",
    CITYNAME: "BLUBBER BAY",
    CITYNAME_HEB: "BLUBBER BAY",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XBC",
    AIRPORTNAME: null,
    CITYCODE: "XBC",
    CITYNAME: "BRIANCON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBD",
    AIRPORTNAME: null,
    CITYCODE: "XBD",
    CITYNAME: "BAR LE DUC",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBE",
    AIRPORTNAME: "Bearskin Lake",
    CITYCODE: "XBE",
    CITYNAME: "BEARSKIN LAKE",
    CITYNAME_HEB: "BEARSKIN LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XBF",
    AIRPORTNAME: null,
    CITYCODE: "XBF",
    CITYNAME: "BELLEGARDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBG",
    AIRPORTNAME: " ",
    CITYCODE: "XBG",
    CITYNAME: "BAD GASTEIN",
    CITYNAME_HEB:
      "\u05d1\u05d0\u05d3 \u05d2\u05d0\u05e1\u05d8\u05d9\u05d9\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AEG",
    AIRPORTNAME: null,
    CITYCODE: "AEG",
    CITYNAME: "ADRIATIC/ AEGEAN SEA",
    CITYNAME_HEB:
      "\u05d4\u05d9\u05dd \u05d4\u05d0\u05d2\u05d0\u05d9/\u05d0\u05d3\u05e8\u05d9\u05d0\u05d8\u05d9",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BQU",
    AIRPORTNAME: null,
    CITYCODE: "BQU",
    CITYNAME: "PORT ELIZABETH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "VC",
    COUNTRYNAME: "ST. VINCENT",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBH",
    AIRPORTNAME: null,
    CITYCODE: "XBH",
    CITYNAME: "BETHUNE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBI",
    AIRPORTNAME: null,
    CITYCODE: "XBI",
    CITYNAME: "BOURG D'OISANS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBK",
    AIRPORTNAME: null,
    CITYCODE: "XBK",
    CITYNAME: "BOURG EN BRESSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBL",
    AIRPORTNAME: "Buno Bedelle",
    CITYCODE: "XBL",
    CITYNAME: "BUNO BEDELLE",
    CITYNAME_HEB: "BUNO BEDELLE",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XBM",
    AIRPORTNAME: null,
    CITYCODE: "XBM",
    CITYNAME: "BEAULIEU SUR MER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBN",
    AIRPORTNAME: "Biniguni",
    CITYCODE: "XBN",
    CITYNAME: "BINIGUNI",
    CITYNAME_HEB: "BINIGUNI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "XBO",
    AIRPORTNAME: "Boulsa",
    CITYCODE: "XBO",
    CITYNAME: "BOULSA",
    CITYNAME_HEB: "BOULSA",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "XBP",
    AIRPORTNAME: null,
    CITYCODE: "XBP",
    CITYNAME: "LA BAULE LES PINS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBQ",
    AIRPORTNAME: null,
    CITYCODE: "XBQ",
    CITYNAME: "BLOIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBR",
    AIRPORTNAME: "Brockville",
    CITYCODE: "XBR",
    CITYNAME: "BROCKVILLE",
    CITYNAME_HEB: "BROCKVILLE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BQW",
    AIRPORTNAME: "Balgo Hills",
    CITYCODE: "BQW",
    CITYNAME: "BALGO HILLS",
    CITYNAME_HEB: "BALGO HILLS",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XBS",
    AIRPORTNAME: null,
    CITYCODE: "XBS",
    CITYNAME: "BOULOGNE SUR MER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBT",
    AIRPORTNAME: null,
    CITYCODE: "XBT",
    CITYNAME: "BOULOGNE BILL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBU",
    AIRPORTNAME: null,
    CITYCODE: "XBU",
    CITYNAME: "BANYULS SUR MER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBV",
    AIRPORTNAME: null,
    CITYCODE: "XBV",
    CITYNAME: "BEAUNE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBW",
    AIRPORTNAME: "Killineq",
    CITYCODE: "XBW",
    CITYNAME: "KILLINEQ",
    CITYNAME_HEB: "KILLINEQ",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XBX",
    AIRPORTNAME: null,
    CITYCODE: "XBX",
    CITYNAME: "BERNAY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBY",
    AIRPORTNAME: null,
    CITYCODE: "XBY",
    CITYNAME: "BAYONNE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XBZ",
    AIRPORTNAME: null,
    CITYCODE: "XBZ",
    CITYNAME: "BANDOL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCA",
    AIRPORTNAME: null,
    CITYCODE: "XCA",
    CITYNAME: "CAMPBELLTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CANB",
    COUNTRYNAME: "NEW BRUNSWICK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCB",
    AIRPORTNAME: null,
    CITYCODE: "XCB",
    CITYNAME: "CAMBRAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRA",
    AIRPORTNAME: " ",
    CITYCODE: "BRA",
    CITYNAME: "COSTA BRAVA",
    CITYNAME_HEB:
      "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05d1\u05e8\u05d5\u05d5\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "XCC",
    AIRPORTNAME: null,
    CITYCODE: "XCC",
    CITYNAME: "CREUSOT MONTCEAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCD",
    AIRPORTNAME: null,
    CITYCODE: "XCD",
    CITYNAME: "CHALON SUR SAONE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCE",
    AIRPORTNAME: null,
    CITYCODE: "XCE",
    CITYNAME: "CERBERE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCF",
    AIRPORTNAME: null,
    CITYCODE: "XCF",
    CITYNAME: "CHAMONIX MONT BLANC",
    CITYNAME_HEB: "\u05e9\u05de\u05d5\u05e0\u05d9",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "XCG",
    AIRPORTNAME: null,
    CITYCODE: "XCG",
    CITYNAME: "CAGNES SUR MER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCH",
    AIRPORTNAME: null,
    CITYCODE: "XCH",
    CITYNAME: "CHRISTMAS ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CX",
    COUNTRYNAME: "CHRISTMAS ISLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UCA",
    AIRPORTNAME: "Oneida County",
    CITYCODE: "UCA",
    CITYNAME: "UTICA",
    CITYNAME_HEB: "UTICA",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UCC",
    AIRPORTNAME: "Yucca Flat",
    CITYCODE: "UCC",
    CITYNAME: "YUCCA FLAT",
    CITYNAME_HEB: "YUCCA FLAT",
    COUNTRYCODE: "USNV",
    COUNTRYNAME: "NEVADA",
    COUNTRYNAME_HEB: '\u05e0\u05d1\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UCE",
    AIRPORTNAME: "Eunice",
    CITYCODE: "UCE",
    CITYNAME: "EUNICE",
    CITYNAME_HEB: "EUNICE",
    COUNTRYCODE: "USLA",
    COUNTRYNAME: "LOUISIANA",
    COUNTRYNAME_HEB:
      '\u05dc\u05d5\u05d0\u05d9\u05d6\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UCK",
    AIRPORTNAME: "Lutsk",
    CITYCODE: "UCK",
    CITYNAME: "LUTSK",
    CITYNAME_HEB: "LUTSK",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "UCN",
    AIRPORTNAME: "Buchanan",
    CITYCODE: "UCN",
    CITYNAME: "BUCHANAN",
    CITYNAME_HEB: "BUCHANAN",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BNV",
    AIRPORTNAME: "Boana",
    CITYCODE: "BNV",
    CITYNAME: "BOANA",
    CITYNAME_HEB: "BOANA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "UCT",
    AIRPORTNAME: "Ukhta",
    CITYCODE: "UCT",
    CITYNAME: "UKHTA",
    CITYNAME_HEB: "UKHTA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "UIS",
    CITYNAME: "HELLEVOETSLUIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UCY",
    AIRPORTNAME: "Everett-Stewart",
    CITYCODE: "UCY",
    CITYNAME: "UNION CITY",
    CITYNAME_HEB: "UNION CITY",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UDA",
    AIRPORTNAME: "Undarra",
    CITYCODE: "UDA",
    CITYNAME: "UNDARRA",
    CITYNAME_HEB: "UNDARRA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UDE",
    AIRPORTNAME: "Volkel",
    CITYCODE: "UDE",
    CITYNAME: "UDEN",
    CITYNAME_HEB: "UDEN",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "UDI",
    AIRPORTNAME: null,
    CITYCODE: "UDI",
    CITYNAME: "UBERLANDIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UDJ",
    AIRPORTNAME: "Uzhgorod",
    CITYCODE: "UDJ",
    CITYNAME: "UZHGOROD",
    CITYNAME_HEB: "UZHGOROD",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "UDN",
    AIRPORTNAME: "Airfield",
    CITYCODE: "UDN",
    CITYNAME: "UDINE",
    CITYNAME_HEB: "\u05d0\u05d5\u05d3\u05d9\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UDO",
    AIRPORTNAME: "Udomxay",
    CITYCODE: "UDO",
    CITYNAME: "UDOMXAY",
    CITYNAME_HEB: "UDOMXAY",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "UDR",
    AIRPORTNAME: "Dabok",
    CITYCODE: "UDR",
    CITYNAME: "UDAIPUR",
    CITYNAME_HEB: "UDAIPUR",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "UEE",
    AIRPORTNAME: null,
    CITYCODE: "UEE",
    CITYNAME: "QUEENSTOWN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AUTS",
    COUNTRYNAME: "TASMANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BNW",
    AIRPORTNAME: " ",
    CITYCODE: "BNW",
    CITYNAME: "BANAWI",
    CITYNAME_HEB: "\u05d1\u05d0\u05e0\u05d0\u05d5\u05d5\u05d9",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "UEL",
    AIRPORTNAME: "Quelimane",
    CITYCODE: "UEL",
    CITYNAME: "QUELIMANE",
    CITYNAME_HEB: "QUELIMANE",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "UEO",
    AIRPORTNAME: "Kumejima",
    CITYCODE: "UEO",
    CITYNAME: "KUMEJIMA",
    CITYNAME_HEB: "KUMEJIMA",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "UES",
    AIRPORTNAME: "Waukesha",
    CITYCODE: "UES",
    CITYNAME: "WAUKESHA",
    CITYNAME_HEB: "WAUKESHA",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UET",
    AIRPORTNAME: "Quetta",
    CITYCODE: "UET",
    CITYNAME: "QUETTA",
    CITYNAME_HEB: "QUETTA",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "UFA",
    AIRPORTNAME: "Ufa",
    CITYCODE: "UFA",
    CITYNAME: "UFA",
    CITYNAME_HEB: "UFA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UGA",
    AIRPORTNAME: "Bulgan",
    CITYCODE: "UGA",
    CITYNAME: "BULGAN",
    CITYNAME_HEB: "BULGAN",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UGC",
    AIRPORTNAME: "Urgench",
    CITYCODE: "UGC",
    CITYNAME: "URGENCH",
    CITYNAME_HEB: "URGENCH",
    COUNTRYCODE: "UZ",
    COUNTRYNAME: "UZBEKISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d6\u05d1\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "UGI",
    AIRPORTNAME: "Uganik",
    CITYCODE: "UGI",
    CITYNAME: "UGANIK",
    CITYNAME_HEB: "Uganik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UGL",
    AIRPORTNAME: null,
    CITYCODE: "UGL",
    CITYNAME: "SUGARLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UGN",
    AIRPORTNAME: "Memorial",
    CITYCODE: "UGN",
    CITYNAME: "WAUKEGAN",
    CITYNAME_HEB: "WAUKEGAN",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "NSF",
    AIRPORTNAME: "Camp Springs Andrews NAF",
    CITYCODE: "ADW",
    CITYNAME: "CAMP SPRINGS",
    CITYNAME_HEB: "Camp Springs Andrews NAF",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BNX",
    AIRPORTNAME: "Banja Luka",
    CITYCODE: "BNX",
    CITYNAME: "BANJA LUKA",
    CITYNAME_HEB: "\u05d1\u05e0\u05d7\u05d4 \u05dc\u05d5\u05e7\u05d4",
    COUNTRYCODE: "BA",
    COUNTRYNAME: "BOSNIA-HERZEGOVINA",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e1\u05e0\u05d9\u05d4 \u05d4\u05e8\u05e6\u05d2\u05d5\u05d1\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "UGO",
    AIRPORTNAME: "Uige",
    CITYCODE: "UGO",
    CITYNAME: "UIGE",
    CITYNAME_HEB: "UIGE",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "UGS",
    AIRPORTNAME: "Ugashik",
    CITYCODE: "UGS",
    CITYNAME: "UGASHIK",
    CITYNAME_HEB: "Ugashik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UGU",
    AIRPORTNAME: "Zugapa",
    CITYCODE: "UGU",
    CITYNAME: "ZUGAPA INDONESIA",
    CITYNAME_HEB: "ZUGAPA INDONESIA",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UHE",
    AIRPORTNAME: "Uherske Hradiste",
    CITYCODE: "UHE",
    CITYNAME: "UHERSKE HRADISTE",
    CITYNAME_HEB:
      "\u05d0\u05d5\u05e8\u05e1\u05e7\u05d4 \u05d4\u05e8\u05d3\u05d9\u05e1\u05d8",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UHF",
    AIRPORTNAME: "RAF Station",
    CITYCODE: "UHF",
    CITYNAME: "UPPER HEYFORD",
    CITYNAME_HEB: "UPPER HEYFORD",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UIB",
    AIRPORTNAME: "Quibdo",
    CITYCODE: "UIB",
    CITYNAME: "QUIBDO",
    CITYNAME_HEB: "QUIBDO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UIH",
    AIRPORTNAME: "Qui Nhon",
    CITYCODE: "UIH",
    CITYNAME: "QUI NHON",
    CITYNAME_HEB: "QUI NHON",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "UII",
    AIRPORTNAME: "Utila",
    CITYCODE: "UII",
    CITYNAME: "UTILA",
    CITYNAME_HEB: "UTILA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "UIK",
    AIRPORTNAME: "Ust-Ilimsk",
    CITYCODE: "UIK",
    CITYNAME: "UST ILIMSK",
    CITYNAME_HEB: "UST ILIMSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UIL",
    AIRPORTNAME: "Quillayute State",
    CITYCODE: "UIL",
    CITYNAME: "QUILLAYUTE",
    CITYNAME_HEB: "QUILLAYUTE",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BNY",
    AIRPORTNAME: "Bellona",
    CITYCODE: "BNY",
    CITYNAME: "BELLONA",
    CITYNAME_HEB: "BELLONA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "UIN",
    AIRPORTNAME: "Municipal",
    CITYCODE: "UIN",
    CITYNAME: "QUINCY",
    CITYNAME_HEB: "QUINCY",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UIO",
    AIRPORTNAME: "Mariscal Sucre Airport",
    CITYCODE: "UIO",
    CITYNAME: "QUITO",
    CITYNAME_HEB: "\u05e7\u05d9\u05d8\u05d5",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "UIP",
    AIRPORTNAME: "Pluguffan",
    CITYCODE: "UIP",
    CITYNAME: "QUIMPER",
    CITYNAME_HEB: "QUIMPER",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "UIQ",
    AIRPORTNAME: "Quine Hill",
    CITYCODE: "UIQ",
    CITYNAME: "QUINE HILL",
    CITYNAME_HEB: "QUINE HILL",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "UIR",
    AIRPORTNAME: "Quirindi",
    CITYCODE: "UIR",
    CITYNAME: "QUIRINDI",
    CITYNAME_HEB: "QUIRINDI",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UIT",
    AIRPORTNAME: "Jaluit Island",
    CITYCODE: "UIT",
    CITYNAME: "JALUIT ISLAND",
    CITYNAME_HEB: "JALUIT ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "UIZ",
    AIRPORTNAME: "Berz-Macomb",
    CITYCODE: "UIZ",
    CITYNAME: "UTICA",
    CITYNAME_HEB: "UTICA",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UJE",
    AIRPORTNAME: "Ujae Island",
    CITYCODE: "UJE",
    CITYNAME: "UJAE ISLAND",
    CITYNAME_HEB: "UJAE ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "UKA",
    AIRPORTNAME: "Ukunda",
    CITYCODE: "UKA",
    CITYNAME: "UKUNDA",
    CITYNAME_HEB: "UKUNDA",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UKB",
    AIRPORTNAME: "Kobe",
    CITYCODE: "UKB",
    CITYNAME: "KOBE",
    CITYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "BNZ",
    AIRPORTNAME: "Banz",
    CITYCODE: "BNZ",
    CITYNAME: "BANZ",
    CITYNAME_HEB: "BANZ",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "UKI",
    AIRPORTNAME: "Ukiah",
    CITYCODE: "UKI",
    CITYNAME: "UKIAH",
    CITYNAME_HEB: "UKIAH",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UKK",
    AIRPORTNAME: "Ust-Kamenogorsk",
    CITYCODE: "UKK",
    CITYNAME: "UST KAMENOGORSK",
    CITYNAME_HEB: "UST KAMENOGORSK",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "UKN",
    AIRPORTNAME: "Municipal",
    CITYCODE: "UKN",
    CITYNAME: "WAUKON",
    CITYNAME_HEB: "WAUKON",
    COUNTRYCODE: "USIA",
    COUNTRYNAME: "IOWA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d5\u05d5\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UKR",
    AIRPORTNAME: "Mukeiras",
    CITYCODE: "UKR",
    CITYNAME: "MUKEIRAS",
    CITYNAME_HEB: "MUKEIRAS",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "UKT",
    AIRPORTNAME: "Upper Bucks",
    CITYCODE: "UKT",
    CITYNAME: "QUAKERTOWN",
    CITYNAME_HEB: "QUAKERTOWN",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UKU",
    AIRPORTNAME: "Nuku",
    CITYCODE: "UKU",
    CITYNAME: "NUKU",
    CITYNAME_HEB: "NUKU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "UKX",
    AIRPORTNAME: "Ust-Kut",
    CITYCODE: "UKX",
    CITYNAME: "UST KUT",
    CITYNAME_HEB: "UST KUT",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UKY",
    AIRPORTNAME: "Kyoto",
    CITYCODE: "UKY",
    CITYNAME: "KYOTO",
    CITYNAME_HEB: "\u05e7\u05d9\u05d5\u05d8\u05d5",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "ULA",
    AIRPORTNAME: null,
    CITYCODE: "ULA",
    CITYNAME: "SAN JULIAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ULB",
    AIRPORTNAME: "Ulei",
    CITYCODE: "ULB",
    CITYNAME: "ULEI",
    CITYNAME_HEB: "ULEI",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "BOA",
    AIRPORTNAME: "Boma",
    CITYCODE: "BOA",
    CITYNAME: "BOMA",
    CITYNAME_HEB: "BOMA",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "ULD",
    AIRPORTNAME: "Ulundi",
    CITYCODE: "ULD",
    CITYNAME: "ULUNDI",
    CITYNAME_HEB: "ULUNDI",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "ULE",
    AIRPORTNAME: "Sule",
    CITYCODE: "ULE",
    CITYNAME: "SULE",
    CITYNAME_HEB: "SULE",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "ULG",
    AIRPORTNAME: "Ulgit",
    CITYCODE: "ULG",
    CITYNAME: "ULGIT",
    CITYNAME_HEB: "ULGIT",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ULI",
    AIRPORTNAME: "Ulithi",
    CITYCODE: "ULI",
    CITYNAME: "ULITHI",
    CITYNAME_HEB: "ULITHI",
    COUNTRYCODE: "FM",
    COUNTRYNAME: "MICRONESIA",
    COUNTRYNAME_HEB: "\u05de\u05d9\u05e7\u05e8\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ULL",
    AIRPORTNAME: "Mull",
    CITYCODE: "ULL",
    CITYNAME: "MULL",
    CITYNAME_HEB: "MULL",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ULM",
    AIRPORTNAME: "New Ulm",
    CITYCODE: "ULM",
    CITYNAME: "NEW ULM",
    CITYNAME_HEB: "NEW ULM",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "ULN",
    AIRPORTNAME: "Buyant Uhaa",
    CITYCODE: "ULN",
    CITYNAME: "ULAANBAATAR",
    CITYNAME_HEB: "\u05d0\u05d5\u05dc\u05df \u05d1\u05d0\u05d8\u05d5\u05e8",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ULO",
    AIRPORTNAME: "Ulaangom",
    CITYCODE: "ULO",
    CITYNAME: "ULAANGOM",
    CITYNAME_HEB: "ULAANGOM",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ULP",
    AIRPORTNAME: "Quilpie",
    CITYCODE: "ULP",
    CITYNAME: "QUILPIE",
    CITYNAME_HEB: "QUILPIE",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ULQ",
    AIRPORTNAME: "Farfan",
    CITYCODE: "ULQ",
    CITYNAME: "TULUA",
    CITYNAME_HEB: "TULUA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BOB",
    AIRPORTNAME: "Motu-mute",
    CITYCODE: "BOB",
    CITYNAME: "BORA BORA",
    CITYNAME_HEB: "BORA BORA",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "ULS",
    AIRPORTNAME: "Mulatos",
    CITYCODE: "ULS",
    CITYNAME: "MULATOS",
    CITYNAME_HEB: "MULATOS",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ULU",
    AIRPORTNAME: "Gulu",
    CITYCODE: "ULU",
    CITYNAME: "GULU",
    CITYNAME_HEB: "GULU",
    COUNTRYCODE: "UG",
    COUNTRYNAME: "UGANDA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ULX",
    AIRPORTNAME: "Ulusaba",
    CITYCODE: "ULX",
    CITYNAME: "ULUSABA",
    CITYNAME_HEB: "ULUSABA",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "ULY",
    AIRPORTNAME: "Ulyanovsk",
    CITYCODE: "ULY",
    CITYNAME: "ULYANOVSK",
    CITYNAME_HEB: "ULYANOVSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ULZ",
    AIRPORTNAME: "Uliastai",
    CITYCODE: "ULZ",
    CITYNAME: "ULIASTAI",
    CITYNAME_HEB: "ULIASTAI",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UMA",
    AIRPORTNAME: "Punta De Maisi",
    CITYCODE: "UMA",
    CITYNAME: "PUNTA DE MAISI",
    CITYNAME_HEB: "PUNTA DE MAISI",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "UMC",
    AIRPORTNAME: "Umba",
    CITYCODE: "UMC",
    CITYNAME: "UMBA",
    CITYNAME_HEB: "UMBA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "UMD",
    AIRPORTNAME: "Uummannaq",
    CITYCODE: "UMD",
    CITYNAME: "UUMMANNAQ",
    CITYNAME_HEB: "UUMMANNAQ",
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "XOA",
    AIRPORTNAME: null,
    CITYCODE: "XOA",
    CITYNAME: "AALBORG LIMO Z6",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRS",
    AIRPORTNAME: "Bristol",
    CITYCODE: "BRS",
    CITYNAME: "BRISTOL",
    CITYNAME_HEB: "\u05d1\u05e8\u05d9\u05e1\u05d8\u05d5\u05dc",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XOB",
    AIRPORTNAME: null,
    CITYCODE: "XOB",
    CITYNAME: "BRYNE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XOD",
    AIRPORTNAME: null,
    CITYCODE: "XOD",
    CITYNAME: "OPPDAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XOE",
    AIRPORTNAME: null,
    CITYCODE: "XOE",
    CITYNAME: "SAO JOSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XOG",
    AIRPORTNAME: null,
    CITYCODE: "XOG",
    CITYNAME: "ORANGE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XOK",
    AIRPORTNAME: null,
    CITYCODE: "XOK",
    CITYNAME: "OAKVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XOL",
    AIRPORTNAME: null,
    CITYCODE: "XOL",
    CITYNAME: "MYRDAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XON",
    AIRPORTNAME: null,
    CITYCODE: "XON",
    CITYNAME: "CARLETON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XOQ",
    AIRPORTNAME: null,
    CITYCODE: "XOQ",
    CITYNAME: "SIRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XOR",
    AIRPORTNAME: null,
    CITYCODE: "XOR",
    CITYNAME: "OTTA",
    CITYNAME_HEB: "\u05d0\u05d5\u05d8\u05d4",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d1\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XOS",
    AIRPORTNAME: null,
    CITYCODE: "XOS",
    CITYNAME: "MOSCONI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "BRT",
    CITYNAME: "BRENTONICO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XOT",
    AIRPORTNAME: null,
    CITYCODE: "XOT",
    CITYNAME: "MARIGOT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GP",
    COUNTRYNAME: "GUADELOUPE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XOX",
    AIRPORTNAME: null,
    CITYCODE: "XOX",
    CITYNAME: "CHEONAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XOY",
    AIRPORTNAME: null,
    CITYCODE: "XOY",
    CITYNAME: "AULNOYE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XPA",
    AIRPORTNAME: "Pama",
    CITYCODE: "XPA",
    CITYNAME: "PAMA",
    CITYNAME_HEB: "PAMA",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "XPB",
    AIRPORTNAME: null,
    CITYCODE: "XPB",
    CITYNAME: "PARKSVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XPC",
    AIRPORTNAME: null,
    CITYCODE: "XPC",
    CITYNAME: "PORT CANAVERAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XPD",
    AIRPORTNAME: null,
    CITYCODE: "XPD",
    CITYNAME: "SAN PEDRO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XPF",
    AIRPORTNAME: null,
    CITYCODE: "XPF",
    CITYNAME: "PENRITH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XPH",
    AIRPORTNAME: null,
    CITYCODE: "XPH",
    CITYNAME: "PORT HOPE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XPK",
    AIRPORTNAME: "Pukatawagan",
    CITYCODE: "XPK",
    CITYNAME: "PUKATAWAGAN",
    CITYNAME_HEB: "PUKATAWAGAN",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BRU",
    AIRPORTNAME: "BRUSSELS",
    CITYCODE: "BRU",
    CITYNAME: "BRUSSELS",
    CITYNAME_HEB: "\u05d1\u05e8\u05d9\u05e1\u05dc",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XPL",
    AIRPORTNAME: "Palmerola Air Base",
    CITYCODE: "XPL",
    CITYNAME: "COMAYAGUA",
    CITYNAME_HEB: "COMAYAGUA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "XPM",
    AIRPORTNAME: null,
    CITYCODE: "XPM",
    CITYNAME: "PONTORSON MT ST MICHEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XPN",
    AIRPORTNAME: null,
    CITYCODE: "XPN",
    CITYNAME: "BRAMPTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XPP",
    AIRPORTNAME: "Poplar River",
    CITYCODE: "XPP",
    CITYNAME: "POPLAR RIVER",
    CITYNAME_HEB: "POPLAR RIVER",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XPR",
    AIRPORTNAME: "Pine Ridge",
    CITYCODE: "XPR",
    CITYNAME: "PINE RIDGE",
    CITYNAME_HEB: "PINE RIDGE",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XPS",
    AIRPORTNAME: " ",
    CITYCODE: "XPS",
    CITYNAME: "PAS DE LA CASA",
    CITYNAME_HEB:
      "\u05e4\u05d0\u05e1 \u05d3\u05d4 \u05dc\u05d4 \u05e7\u05d0\u05e1\u05d4",
    COUNTRYCODE: "AD",
    COUNTRYNAME: "ANDORRA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d3\u05d5\u05e8\u05d4",
  },
  {
    AIRPORTCODE: "XPT",
    AIRPORTNAME: null,
    CITYCODE: "XPT",
    CITYNAME: "PRESTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XPU",
    AIRPORTNAME: "West Kuparuk",
    CITYCODE: "XPU",
    CITYNAME: "WEST KUPARUK",
    CITYNAME_HEB: "West Kuparuk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XPV",
    AIRPORTNAME: null,
    CITYCODE: "XPV",
    CITYNAME: "PORT VENDRES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CRL",
    AIRPORTNAME: "Charleroi",
    CITYCODE: "BRU",
    CITYNAME: "BRUSSELS",
    CITYNAME_HEB: "\u05d1\u05e8\u05d9\u05e1\u05dc",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XPW",
    AIRPORTNAME: null,
    CITYCODE: "XPW",
    CITYNAME: "DIDCOT PARKWAY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XPX",
    AIRPORTNAME: null,
    CITYCODE: "XPX",
    CITYNAME: "POINT AUX TREMBLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XPY",
    AIRPORTNAME: null,
    CITYCODE: "XPY",
    CITYNAME: "PYHA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XQB",
    AIRPORTNAME: null,
    CITYCODE: "XQB",
    CITYNAME: "BASINGSTOKE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XQD",
    AIRPORTNAME: null,
    CITYCODE: "XQD",
    CITYNAME: "BEDFORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XQG",
    AIRPORTNAME: null,
    CITYCODE: "XQG",
    CITYNAME: "BERWICK U TWEED",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XQL",
    AIRPORTNAME: null,
    CITYCODE: "XQL",
    CITYNAME: "LANCASTER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XQM",
    AIRPORTNAME: null,
    CITYCODE: "XQM",
    CITYNAME: "MARKET HARBOROUGH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XQP",
    AIRPORTNAME: "Quepos",
    CITYCODE: "XQP",
    CITYNAME: "QUEPOS",
    CITYNAME_HEB: "QUEPOS",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "XQS",
    AIRPORTNAME: null,
    CITYCODE: "XQS",
    CITYNAME: "SOTKAMO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XQT",
    AIRPORTNAME: null,
    CITYCODE: "XQT",
    CITYNAME: "LICHFIELD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XQU",
    AIRPORTNAME: "Qualicum",
    CITYCODE: "XQU",
    CITYNAME: "QUALICUM",
    CITYNAME_HEB: "QUALICUM",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XQW",
    AIRPORTNAME: null,
    CITYCODE: "XQW",
    CITYNAME: "MOTHERWELL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XQY",
    AIRPORTNAME: null,
    CITYCODE: "XQY",
    CITYNAME: "SAINT QUENTIN EN YVE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XRA",
    AIRPORTNAME: null,
    CITYCODE: "XRA",
    CITYNAME: "GRAASTEN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XRB",
    AIRPORTNAME: null,
    CITYCODE: "XRB",
    CITYNAME: "ARARANGUA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XRC",
    AIRPORTNAME: null,
    CITYCODE: "XRC",
    CITYNAME: "RUNCORN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XRD",
    AIRPORTNAME: null,
    CITYCODE: "XRD",
    CITYNAME: "EGERSUND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XRE",
    AIRPORTNAME: null,
    CITYCODE: "XRE",
    CITYNAME: "READING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XRG",
    AIRPORTNAME: null,
    CITYCODE: "XRG",
    CITYNAME: "RUGELEY TV",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRV",
    AIRPORTNAME: "Bremerhaven",
    CITYCODE: "BRV",
    CITYNAME: "BREMERHAVEN",
    CITYNAME_HEB: "BREMERHAVEN",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XRI",
    AIRPORTNAME: null,
    CITYCODE: "XRI",
    CITYNAME: "RIOM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XRM",
    AIRPORTNAME: null,
    CITYCODE: "XRM",
    CITYNAME: "ARMENTIERES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XRN",
    AIRPORTNAME: null,
    CITYCODE: "XRN",
    CITYNAME: "REDON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XRO",
    AIRPORTNAME: null,
    CITYCODE: "XRO",
    CITYNAME: "LA ROCHE SUR YON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XRP",
    AIRPORTNAME: null,
    CITYCODE: "XRP",
    CITYNAME: "RIVIERE A PIERRE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XRR",
    AIRPORTNAME: "Ross River",
    CITYCODE: "XRR",
    CITYNAME: "ROSS RIVER",
    CITYNAME_HEB: "ROSS RIVER",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XRS",
    AIRPORTNAME: " ",
    CITYCODE: "XRS",
    CITYNAME: "LES ARCS",
    CITYNAME_HEB: "\u05dc\u05e1 \u05d0\u05e8\u05e7",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "XRT",
    AIRPORTNAME: null,
    CITYCODE: "XRT",
    CITYNAME: "RAMBOUILLET",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XRU",
    AIRPORTNAME: null,
    CITYCODE: "XRU",
    CITYNAME: "RUGBY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XRX",
    AIRPORTNAME: null,
    CITYCODE: "XRX",
    CITYNAME: "ROUBAIX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRW",
    AIRPORTNAME: "Barrow Metropolitan Area",
    CITYCODE: "BRW",
    CITYNAME: "BARROW",
    CITYNAME_HEB: "Barrow Metropolitan Area",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XRY",
    AIRPORTNAME: "JEREZ DE LA FRONTERA",
    CITYCODE: "XRY",
    CITYNAME: "JEREZ DE LA FRONTERA",
    CITYNAME_HEB: "JEREZ DE LA FRONTERA",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "XSB",
    AIRPORTNAME: null,
    CITYCODE: "XSB",
    CITYNAME: "SAINT MALO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XSC",
    AIRPORTNAME: null,
    CITYCODE: "XSC",
    CITYNAME: "SOUTH CAICOS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TC",
    COUNTRYNAME: "TURKS AND CAICOS ISLANDS",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XSE",
    AIRPORTNAME: "Sebba",
    CITYCODE: "XSE",
    CITYNAME: "SEBBA",
    CITYNAME_HEB: "SEBBA",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "XSF",
    AIRPORTNAME: null,
    CITYCODE: "XSF",
    CITYNAME: "SENS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XSG",
    AIRPORTNAME: null,
    CITYCODE: "XSG",
    CITYNAME: "SAINT OMER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XSI",
    AIRPORTNAME: "South Indian Lake",
    CITYCODE: "XSI",
    CITYNAME: "SOUTH INDIAN LAKE",
    CITYNAME_HEB: "SOUTH INDIAN LAKE",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XSJ",
    AIRPORTNAME: null,
    CITYCODE: "XSJ",
    CITYNAME: "SAINT QUENTIN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XSK",
    AIRPORTNAME: null,
    CITYCODE: "XSK",
    CITYNAME: "SAINT RAPHAEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XSL",
    AIRPORTNAME: " ",
    CITYCODE: "XSL",
    CITYNAME: "SARLAT",
    CITYNAME_HEB: "\u05e1\u05e8\u05dc\u05d8",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "AEO",
    AIRPORTNAME: null,
    CITYCODE: "AEO",
    CITYNAME: "AIOUN EL ATROUSS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "MR",
    COUNTRYNAME: "MAURITANIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PBA",
    AIRPORTNAME: "Point Barrow",
    CITYCODE: "BRW",
    CITYNAME: "BARROW",
    CITYNAME_HEB: "Point Barrow",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XSM",
    AIRPORTNAME: "St Marys",
    CITYCODE: "XSM",
    CITYNAME: "SAINT MARYS",
    CITYNAME_HEB: "SAINT MARYS",
    COUNTRYCODE: "USMD",
    COUNTRYNAME: "MARYLAND",
    COUNTRYNAME_HEB:
      '\u05de\u05e8\u05d9\u05dc\u05e0\u05d3 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XSN",
    AIRPORTNAME: null,
    CITYCODE: "XSN",
    CITYNAME: "SALLANCHES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XSO",
    AIRPORTNAME: "Siocon",
    CITYCODE: "XSO",
    CITYNAME: "SIOCON",
    CITYNAME_HEB: "SIOCON",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "XSQ",
    AIRPORTNAME: null,
    CITYCODE: "XSQ",
    CITYNAME: "SELESTAT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XSR",
    AIRPORTNAME: " ",
    CITYCODE: "XSR",
    CITYNAME: "SALISBURY",
    CITYNAME_HEB: "\u05e1\u05d5\u05dc\u05e1\u05d1\u05e8\u05d9",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XSS",
    AIRPORTNAME: null,
    CITYCODE: "XSS",
    CITYNAME: "SOISSONS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XST",
    AIRPORTNAME: null,
    CITYCODE: "XST",
    CITYNAME: "SAINTES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XSU",
    AIRPORTNAME: null,
    CITYCODE: "XSU",
    CITYNAME: "SOUNIO",
    CITYNAME_HEB: "\u05e1\u05d5\u05e0\u05d9\u05d5",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "XSV",
    AIRPORTNAME: null,
    CITYCODE: "XSV",
    CITYNAME: "SENLIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XSW",
    AIRPORTNAME: null,
    CITYCODE: "XSW",
    CITYNAME: "SEDAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRX",
    AIRPORTNAME: "Barahona",
    CITYCODE: "BRX",
    CITYNAME: "BARAHONA",
    CITYNAME_HEB: "BARAHONA",
    COUNTRYCODE: "DO",
    COUNTRYNAME: "DOMINICAN REPUBLIC",
    COUNTRYNAME_HEB:
      "\u05d4\u05e8\u05e4\u05d5\u05d1\u05dc\u05d9\u05e7\u05d4 \u05d4\u05d3\u05d5\u05de\u05d9\u05e0\u05d9\u05e7\u05e0\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "XSX",
    AIRPORTNAME: null,
    CITYCODE: "XSX",
    CITYNAME: "SECLIN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XSY",
    AIRPORTNAME: null,
    CITYCODE: "XSY",
    CITYNAME: "SETE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XSZ",
    AIRPORTNAME: null,
    CITYCODE: "XSZ",
    CITYNAME: "SETUBAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XTB",
    AIRPORTNAME: null,
    CITYCODE: "XTB",
    CITYNAME: "TARBES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XTC",
    AIRPORTNAME: null,
    CITYCODE: "XTC",
    CITYNAME: "SAINT CLAUDE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XTE",
    AIRPORTNAME: null,
    CITYCODE: "XTE",
    CITYNAME: "LA TOUR DE CAROL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XTG",
    AIRPORTNAME: "Thargomindah",
    CITYCODE: "XTG",
    CITYNAME: "THARGOMINDAH",
    CITYNAME_HEB: "THARGOMINDAH",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XTH",
    AIRPORTNAME: null,
    CITYCODE: "XTH",
    CITYNAME: "THIONVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XTK",
    AIRPORTNAME: null,
    CITYCODE: "XTK",
    CITYNAME: "THIRSK",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRY",
    AIRPORTNAME: "Samuels Field",
    CITYCODE: "BRY",
    CITYNAME: "BARDSTOWN",
    CITYNAME_HEB: "BARDSTOWN",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XTL",
    AIRPORTNAME: "Tadoule Lake",
    CITYCODE: "XTL",
    CITYNAME: "TADOULE LAKE",
    CITYNAME_HEB: "TADOULE LAKE",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XTN",
    AIRPORTNAME: null,
    CITYCODE: "XTN",
    CITYNAME: "TOURCOING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XTO",
    AIRPORTNAME: "Taroom",
    CITYCODE: "XTO",
    CITYNAME: "TAROOM",
    CITYNAME_HEB: "TAROOM",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XTR",
    AIRPORTNAME: "Tara",
    CITYCODE: "XTR",
    CITYNAME: "TARA",
    CITYNAME_HEB: "TARA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XTS",
    AIRPORTNAME: null,
    CITYCODE: "XTS",
    CITYNAME: "THONON LES BAINS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XTU",
    AIRPORTNAME: null,
    CITYCODE: "XTU",
    CITYNAME: "TULLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XTY",
    AIRPORTNAME: null,
    CITYCODE: "XTY",
    CITYNAME: "STRATHROY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XUY",
    AIRPORTNAME: null,
    CITYCODE: "XUY",
    CITYNAME: "AURAY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XUZ",
    AIRPORTNAME: "Xuzhou",
    CITYCODE: "XUZ",
    CITYNAME: "XUZHOU",
    CITYNAME_HEB: "XUZHOU",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XVA",
    AIRPORTNAME: null,
    CITYCODE: "XVA",
    CITYNAME: "STOCKPORT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRZ",
    AIRPORTNAME: " ",
    CITYCODE: "BRZ",
    CITYNAME: "BRAZIL",
    CITYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "XVB",
    AIRPORTNAME: null,
    CITYCODE: "XVB",
    CITYNAME: "STAFFORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVC",
    AIRPORTNAME: null,
    CITYCODE: "XVC",
    CITYNAME: "CREWE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVD",
    AIRPORTNAME: null,
    CITYCODE: "XVD",
    CITYNAME: "VENDOME",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVE",
    AIRPORTNAME: null,
    CITYCODE: "XVE",
    CITYNAME: "VERSAILLES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVF",
    AIRPORTNAME: null,
    CITYCODE: "XVF",
    CITYNAME: "VILLEFRANCHE SUR SAONE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVG",
    AIRPORTNAME: null,
    CITYCODE: "XVG",
    CITYNAME: "DARLINGTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVH",
    AIRPORTNAME: null,
    CITYCODE: "XVH",
    CITYNAME: "PETERBOROUGH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVI",
    AIRPORTNAME: null,
    CITYCODE: "XVI",
    CITYNAME: "VIENNE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVJ",
    AIRPORTNAME: null,
    CITYCODE: "XVJ",
    CITYNAME: "STEVENAGE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVK",
    AIRPORTNAME: null,
    CITYCODE: "XVK",
    CITYNAME: "VOSS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BSA",
    AIRPORTNAME: "Bossaso",
    CITYCODE: "BSA",
    CITYNAME: "BOSSASO",
    CITYNAME_HEB: "BOSSASO",
    COUNTRYCODE: "SO",
    COUNTRYNAME: "SOMALIA",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05de\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XVL",
    AIRPORTNAME: "Vinh Long",
    CITYCODE: "XVL",
    CITYNAME: "VINH LONG",
    CITYNAME_HEB: "VINH LONG",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "XVM",
    AIRPORTNAME: null,
    CITYCODE: "XVM",
    CITYNAME: "VUOKATTI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVN",
    AIRPORTNAME: null,
    CITYCODE: "XVN",
    CITYNAME: "VERDUN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVO",
    AIRPORTNAME: null,
    CITYCODE: "XVO",
    CITYNAME: "VESOUL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVP",
    AIRPORTNAME: null,
    CITYCODE: "XVP",
    CITYNAME: "VILLEPINTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVR",
    AIRPORTNAME: null,
    CITYCODE: "XVR",
    CITYNAME: "VALLOIRE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVS",
    AIRPORTNAME: null,
    CITYCODE: "XVS",
    CITYNAME: "VALENCIENNES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVT",
    AIRPORTNAME: null,
    CITYCODE: "XVT",
    CITYNAME: "VITRE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ICH",
    AIRPORTNAME: null,
    CITYCODE: "ICH",
    CITYNAME: "CARINTHIA",
    CITYNAME_HEB: "\u05e7\u05e8\u05d9\u05e0\u05ea\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XVV",
    AIRPORTNAME: null,
    CITYCODE: "XVV",
    CITYNAME: "BELLEVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BSB",
    AIRPORTNAME: " ",
    CITYCODE: "BSB",
    CITYNAME: "BRASILIA",
    CITYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc\u05d9\u05d4",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "XVW",
    AIRPORTNAME: null,
    CITYCODE: "XVW",
    CITYNAME: "WOLVERHAMPTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XVZ",
    AIRPORTNAME: null,
    CITYCODE: "XVZ",
    CITYNAME: "VIERZON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XWA",
    AIRPORTNAME: null,
    CITYCODE: "XWA",
    CITYNAME: "WATFORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XWB",
    AIRPORTNAME: null,
    CITYCODE: "XWB",
    CITYNAME: "STIRLING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XWD",
    AIRPORTNAME: null,
    CITYCODE: "XWD",
    CITYNAME: "WAKEFIELD WESTGATE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XWE",
    AIRPORTNAME: " ",
    CITYCODE: "XWE",
    CITYNAME: "WESTERN EUROPE CRUIS",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XWH",
    AIRPORTNAME: null,
    CITYCODE: "XWH",
    CITYNAME: "STOKE ON TRENT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XWI",
    AIRPORTNAME: null,
    CITYCODE: "XWI",
    CITYNAME: "WIGAN NW",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XWM",
    AIRPORTNAME: null,
    CITYCODE: "XWM",
    CITYNAME: "HALLSBERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XWN",
    AIRPORTNAME: null,
    CITYCODE: "XWN",
    CITYNAME: "WARRINGTON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BSC",
    AIRPORTNAME: "Bahia Solano",
    CITYCODE: "BSC",
    CITYNAME: "BAHIA SOLANO",
    CITYNAME_HEB: "BAHIA SOLANO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XWO",
    AIRPORTNAME: null,
    CITYCODE: "XWO",
    CITYNAME: "WOKING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XWP",
    AIRPORTNAME: "Hassleholm",
    CITYCODE: "XWP",
    CITYNAME: "HASSLEHOLM",
    CITYNAME_HEB: "HASSLEHOLM",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XWQ",
    AIRPORTNAME: "Enkoping",
    CITYCODE: "XWQ",
    CITYNAME: "ENKOPING",
    CITYNAME_HEB: "ENKOPING",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XWV",
    AIRPORTNAME: null,
    CITYCODE: "XWV",
    CITYNAME: "VARBERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XWX",
    AIRPORTNAME: null,
    CITYCODE: "XWX",
    CITYNAME: "NASSJO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XWY",
    AIRPORTNAME: null,
    CITYCODE: "XWY",
    CITYNAME: "WYOMING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XWZ",
    AIRPORTNAME: "Nykoping",
    CITYCODE: "XWZ",
    CITYNAME: "NYKOPING",
    CITYNAME_HEB: "\u05e0\u05d9\u05e9\u05d5\u05e4\u05d9\u05e0\u05d2",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XXA",
    AIRPORTNAME: "Alvesta",
    CITYCODE: "XXA",
    CITYNAME: "ALVESTA",
    CITYNAME_HEB: "ALVESTA",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XXB",
    AIRPORTNAME: null,
    CITYCODE: "XXB",
    CITYNAME: "BHADOHI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XXC",
    AIRPORTNAME: null,
    CITYCODE: "XXC",
    CITYNAME: "BO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BSD",
    AIRPORTNAME: "Baoshan",
    CITYCODE: "BSD",
    CITYNAME: "BAOSHAN",
    CITYNAME_HEB: "BAOSHAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XXD",
    AIRPORTNAME: null,
    CITYCODE: "XXD",
    CITYNAME: "DEGERFORS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XXH",
    AIRPORTNAME: null,
    CITYCODE: "XXH",
    CITYNAME: "HELICOPTER SCENIC",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XXI",
    AIRPORTNAME: null,
    CITYCODE: "XXI",
    CITYNAME: "KOPING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XXK",
    AIRPORTNAME: "Katrineholm",
    CITYCODE: "XXK",
    CITYNAME: "KATRINEHOLM",
    CITYNAME_HEB: "KATRINEHOLM",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XXL",
    AIRPORTNAME: " ",
    CITYCODE: "XXL",
    CITYNAME: "LILLEHAMMER",
    CITYNAME_HEB: "\u05dc\u05d9\u05dc\u05d4\u05d0\u05de\u05e8",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XXM",
    AIRPORTNAME: null,
    CITYCODE: "XXM",
    CITYNAME: "MJOLBY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XXN",
    AIRPORTNAME: null,
    CITYCODE: "XXN",
    CITYNAME: "KIL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XXO",
    AIRPORTNAME: null,
    CITYCODE: "XXO",
    CITYNAME: "LEKSAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XXP",
    AIRPORTNAME: " ",
    CITYCODE: "XXP",
    CITYNAME: "POTSDAM",
    CITYNAME_HEB: "\u05e4\u05d5\u05d8\u05e1\u05d3\u05dd",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BSE",
    AIRPORTNAME: "Sematan",
    CITYCODE: "BSE",
    CITYNAME: "SEMATAN",
    CITYNAME_HEB: "SEMATAN",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XXS",
    AIRPORTNAME: null,
    CITYCODE: "XXS",
    CITYNAME: "SCENIC FLIGHT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XXT",
    AIRPORTNAME: null,
    CITYCODE: "XXT",
    CITYNAME: "ARBOGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XXU",
    AIRPORTNAME: "Hedemora",
    CITYCODE: "XXU",
    CITYNAME: "HEDEMORA",
    CITYNAME_HEB: "HEDEMORA",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XXV",
    AIRPORTNAME: null,
    CITYCODE: "XXV",
    CITYNAME: "KUMLA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XYA",
    AIRPORTNAME: "Yandina",
    CITYCODE: "XYA",
    CITYNAME: "YANDINA",
    CITYNAME_HEB: "YANDINA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "XYC",
    AIRPORTNAME: null,
    CITYCODE: "XYC",
    CITYNAME: "HERRLJUNGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XYE",
    AIRPORTNAME: "Ye",
    CITYCODE: "XYE",
    CITYNAME: "YE",
    CITYNAME_HEB: "YE",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "XYF",
    AIRPORTNAME: null,
    CITYCODE: "XYF",
    CITYNAME: "FALKOPING",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XYI",
    AIRPORTNAME: "Flen",
    CITYCODE: "XYI",
    CITYNAME: "FLEN",
    CITYNAME_HEB: "FLEN",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BSF",
    AIRPORTNAME: "Bradshaw AAF",
    CITYCODE: "BSF",
    CITYNAME: "POHAKULOA",
    CITYNAME_HEB: "POHAKULOA",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XYM",
    AIRPORTNAME: null,
    CITYCODE: "XYM",
    CITYNAME: "FALKENBERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XYN",
    AIRPORTNAME: null,
    CITYCODE: "XYN",
    CITYNAME: "KRISTINEHAMN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XYO",
    AIRPORTNAME: "Karlshamn",
    CITYCODE: "XYO",
    CITYNAME: "KARLSHAMN",
    CITYNAME_HEB: "KARLSHAMN",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XYP",
    AIRPORTNAME: "Avesta Krylbo",
    CITYCODE: "XYP",
    CITYNAME: "AVESTA KRYLBO",
    CITYNAME_HEB: "AVESTA KRYLBO",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XYR",
    AIRPORTNAME: "Yellow River",
    CITYCODE: "XYR",
    CITYNAME: "YELLOW RIVER",
    CITYNAME_HEB: "YELLOW RIVER",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "XYU",
    AIRPORTNAME: "Solvesborg",
    CITYCODE: "XYU",
    CITYNAME: "SOLVESBORG",
    CITYNAME_HEB: "SOLVESBORG",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XYX",
    AIRPORTNAME: "Sala",
    CITYCODE: "XYX",
    CITYNAME: "SALA",
    CITYNAME_HEB: "SALA",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XYY",
    AIRPORTNAME: null,
    CITYCODE: "XYY",
    CITYNAME: "ARVIKA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XYZ",
    AIRPORTNAME: null,
    CITYCODE: "XYZ",
    CITYNAME: "HARNOSAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XZA",
    AIRPORTNAME: "Zabre",
    CITYCODE: "XZA",
    CITYNAME: "ZABRE",
    CITYNAME_HEB: "ZABRE",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "BSG",
    AIRPORTNAME: null,
    CITYCODE: "BSG",
    CITYNAME: "BATA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GQ",
    COUNTRYNAME: "EQUATORIAL GUINEA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XZB",
    AIRPORTNAME: null,
    CITYCODE: "XZB",
    CITYNAME: "CASSELMAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XZC",
    AIRPORTNAME: null,
    CITYCODE: "XZC",
    CITYNAME: "GLENCOE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XZK",
    AIRPORTNAME: null,
    CITYCODE: "XZK",
    CITYNAME: "AMHERST",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CANS",
    COUNTRYNAME: "NOVA SCOTIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XZP",
    AIRPORTNAME: null,
    CITYCODE: "XZP",
    CITYNAME: "PASS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XZR",
    AIRPORTNAME: null,
    CITYCODE: "XZR",
    CITYNAME: "RAIL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XZY",
    AIRPORTNAME: " ",
    CITYCODE: "XZY",
    CITYNAME: "ALZEY",
    CITYNAME_HEB: "\u05d0\u05dc\u05d6\u05d0\u05d9",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XZZ",
    AIRPORTNAME: " ",
    CITYCODE: "XZZ",
    CITYNAME: "ZELL IM ZILLERTAL",
    CITYNAME_HEB:
      "\u05e6\u05dc \u05d0\u05de \u05d6\u05d9\u05dc\u05e8\u05d8\u05d0\u05dc",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YAA",
    AIRPORTNAME: "Anahim Lake",
    CITYCODE: "YAA",
    CITYNAME: "ANAHIM LAKE",
    CITYNAME_HEB: "ANAHIM LAKE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAB",
    AIRPORTNAME: "Arctic Bay",
    CITYCODE: "YAB",
    CITYNAME: "ARCTIC BAY",
    CITYNAME_HEB: "ARCTIC BAY",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAC",
    AIRPORTNAME: "Cat Lake",
    CITYCODE: "YAC",
    CITYNAME: "CAT LAKE",
    CITYNAME_HEB: "CAT LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BSH",
    AIRPORTNAME: "Brighton",
    CITYCODE: "BSH",
    CITYNAME: "BRIGHTON",
    CITYNAME_HEB: "\u05d1\u05e8\u05d9\u05d9\u05d8\u05d5\u05df",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YAD",
    AIRPORTNAME: "Moose Lake",
    CITYCODE: "YAD",
    CITYNAME: "MOOSE LAKE",
    CITYNAME_HEB: "MOOSE LAKE",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAE",
    AIRPORTNAME: "Alta Lake",
    CITYCODE: "YAE",
    CITYNAME: "ALTA LAKE",
    CITYNAME_HEB: "ALTA LAKE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAF",
    AIRPORTNAME: "Asbestos Hill",
    CITYCODE: "YAF",
    CITYNAME: "ASBESTOS HILL",
    CITYNAME_HEB: "ASBESTOS HILL",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAG",
    AIRPORTNAME: "Municipal",
    CITYCODE: "YAG",
    CITYNAME: "FORT FRANCES",
    CITYNAME_HEB: "FORT FRANCES",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAH",
    AIRPORTNAME: "Lagrande 4",
    CITYCODE: "YAH",
    CITYNAME: "LAGRANDE 4",
    CITYNAME_HEB: "LAGRANDE 4",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAI",
    AIRPORTNAME: "Chillan",
    CITYCODE: "YAI",
    CITYNAME: "CHILLAN",
    CITYNAME_HEB: "CHILLAN",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "YAJ",
    AIRPORTNAME: "Lyall Harbour",
    CITYCODE: "YAJ",
    CITYNAME: "LYALL HARBOUR",
    CITYNAME_HEB: "LYALL HARBOUR",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAK",
    AIRPORTNAME: "Yakutat",
    CITYCODE: "YAK",
    CITYNAME: "YAKUTAT",
    CITYNAME_HEB: "Yakutat",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "LLT",
    CITYNAME: "YALTA",
    CITYNAME_HEB: "\u05d9\u05d0\u05dc\u05d8\u05d4",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "YAM",
    AIRPORTNAME: "Sault Ste Marie",
    CITYCODE: "YAM",
    CITYNAME: "SAULT STE MARIE",
    CITYNAME_HEB: "SAULT STE MARIE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BSI",
    AIRPORTNAME: "Blairsville",
    CITYCODE: "BSI",
    CITYNAME: "BLAIRSVILLE",
    CITYNAME_HEB: "BLAIRSVILLE",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YAN",
    AIRPORTNAME: "Yangambi",
    CITYCODE: "YAN",
    CITYNAME: "YANGAMBI",
    CITYNAME_HEB: "YANGAMBI",
    COUNTRYCODE: "CD",
    COUNTRYNAME: "CONGO THE DEMOCRATIC REP",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "NSI",
    AIRPORTNAME: "Nsimalen",
    CITYCODE: "YAO",
    CITYNAME: "YAOUNDE",
    CITYNAME_HEB: "YAOUNDE",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "YAO",
    AIRPORTNAME: "Yaounde Airport",
    CITYCODE: "YAO",
    CITYNAME: "YAOUNDE",
    CITYNAME_HEB: "\u05d9\u05d5\u05d0\u05e0\u05d3\u05d4",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "YAP",
    AIRPORTNAME: "Yap",
    CITYCODE: "YAP",
    CITYNAME: "YAP",
    CITYNAME_HEB: "YAP",
    COUNTRYCODE: "FM",
    COUNTRYNAME: "MICRONESIA",
    COUNTRYNAME_HEB: "\u05de\u05d9\u05e7\u05e8\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YAQ",
    AIRPORTNAME: "Maple Bay",
    CITYCODE: "YAQ",
    CITYNAME: "MAPLE BAY",
    CITYNAME_HEB: "MAPLE BAY",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAR",
    AIRPORTNAME: "Lagrande 3",
    CITYCODE: "YAR",
    CITYNAME: "LAGRANDE 3",
    CITYNAME_HEB: "LAGRANDE 3",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAS",
    AIRPORTNAME: "Yasawa Island",
    CITYCODE: "YAS",
    CITYNAME: "YASAWA",
    CITYNAME_HEB: "YASAWA",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "YAT",
    AIRPORTNAME: "Attawapiskat",
    CITYCODE: "YAT",
    CITYNAME: "ATTAWAPISKAT",
    CITYNAME_HEB: "ATTAWAPISKAT",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAU",
    AIRPORTNAME: "Kattiniq/Donaldson Lak",
    CITYCODE: "YAU",
    CITYNAME: "KATTINIQ",
    CITYNAME_HEB: "KATTINIQ",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAV",
    AIRPORTNAME: "Miners Bay",
    CITYCODE: "YAV",
    CITYNAME: "MINERS BAY",
    CITYNAME_HEB: "MINERS BAY",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BSJ",
    AIRPORTNAME: "Bairnsdale",
    CITYCODE: "BSJ",
    CITYNAME: "BAIRNSDALE",
    CITYNAME_HEB: "BAIRNSDALE",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YAX",
    AIRPORTNAME: "Angling Lake",
    CITYCODE: "YAX",
    CITYNAME: "ANGLING LAKE",
    CITYNAME_HEB: "ANGLING LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAY",
    AIRPORTNAME: "St Anthony",
    CITYCODE: "YAY",
    CITYNAME: "SAINT ANTHONY",
    CITYNAME_HEB: "SAINT ANTHONY",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YAZ",
    AIRPORTNAME: "Tofino Airport",
    CITYCODE: "YAZ",
    CITYNAME: "TOFINO",
    CITYNAME_HEB: "TOFINO",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTP",
    AIRPORTNAME: "Seaplane Base",
    CITYCODE: "YAZ",
    CITYNAME: "TOFINO",
    CITYNAME_HEB: "TOFINO",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBA",
    AIRPORTNAME: "Banff",
    CITYCODE: "YBA",
    CITYNAME: "BANFF",
    CITYNAME_HEB: "\u05d1\u05d0\u05e0\u05e3",
    COUNTRYCODE: "CA",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBB",
    AIRPORTNAME: "Kugaaruk",
    CITYCODE: "YBB",
    CITYNAME: "PELLY BAY",
    CITYNAME_HEB: "PELLY BAY",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBC",
    AIRPORTNAME: "Baie Comeau",
    CITYCODE: "YBC",
    CITYNAME: "BAIE COMEAU",
    CITYNAME_HEB: "BAIE COMEAU",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBD",
    AIRPORTNAME: "New Westminster",
    CITYCODE: "YBD",
    CITYNAME: "NEW WESTMINISTER",
    CITYNAME_HEB: "NEW WESTMINISTER",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBE",
    AIRPORTNAME: "Uranium City",
    CITYCODE: "YBE",
    CITYNAME: "URANIUM CITY",
    CITYNAME_HEB: "URANIUM CITY",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBF",
    AIRPORTNAME: "Bamfield",
    CITYCODE: "YBF",
    CITYNAME: "BAMFIELD",
    CITYNAME_HEB: "BAMFIELD",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BSK",
    AIRPORTNAME: "Biskra",
    CITYCODE: "BSK",
    CITYNAME: "BISKRA",
    CITYNAME_HEB: "BISKRA",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YBG",
    AIRPORTNAME: "Bagotville",
    CITYCODE: "YBG",
    CITYNAME: "BAGOTVILLE",
    CITYNAME_HEB: "BAGOTVILLE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBH",
    AIRPORTNAME: "Bull Harbour",
    CITYCODE: "YBH",
    CITYNAME: "BULL HARBOUR",
    CITYNAME_HEB: "BULL HARBOUR",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBI",
    AIRPORTNAME: "Black Tickle",
    CITYCODE: "YBI",
    CITYNAME: "BLACK TICKLE",
    CITYNAME_HEB: "BLACK TICKLE",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBJ",
    AIRPORTNAME: "Baie Johan Beetz",
    CITYCODE: "YBJ",
    CITYNAME: "BAIE JOHAN BEETZ",
    CITYNAME_HEB: "BAIE JOHAN BEETZ",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBK",
    AIRPORTNAME: "Baker Lake",
    CITYCODE: "YBK",
    CITYNAME: "BAKER LAKE",
    CITYNAME_HEB: "BAKER LAKE",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBL",
    AIRPORTNAME: "Campbell River",
    CITYCODE: "YBL",
    CITYNAME: "CAMPBELL RIVER",
    CITYNAME_HEB: "CAMPBELL RIVER",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YHH",
    AIRPORTNAME: null,
    CITYCODE: "YBL",
    CITYNAME: "CAMPBELL RIVER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YBM",
    AIRPORTNAME: "Bronson Creek",
    CITYCODE: "YBM",
    CITYNAME: "BRONSON CREEK",
    CITYNAME_HEB: "BRONSON CREEK",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBN",
    AIRPORTNAME: "Borden",
    CITYCODE: "YBN",
    CITYNAME: "BORDEN",
    CITYNAME_HEB: "BORDEN",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBO",
    AIRPORTNAME: "Bobquinn Lake",
    CITYCODE: "YBO",
    CITYNAME: "BOBQUINN LAKE",
    CITYNAME_HEB: "BOBQUINN LAKE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BSN",
    AIRPORTNAME: null,
    CITYCODE: "BSN",
    CITYNAME: "BOSSANGOA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YBP",
    AIRPORTNAME: "Yibin",
    CITYCODE: "YBP",
    CITYNAME: "YIBIN",
    CITYNAME_HEB: "YIBIN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "YBQ",
    AIRPORTNAME: "Telegraph Harbour",
    CITYCODE: "YBQ",
    CITYNAME: "TELEGRAPH HARBOUR",
    CITYNAME_HEB: "TELEGRAPH HARBOUR",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBR",
    AIRPORTNAME: "Brandon",
    CITYCODE: "YBR",
    CITYNAME: "BRANDON",
    CITYNAME_HEB: "BRANDON",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBS",
    AIRPORTNAME: "Musselwhite",
    CITYCODE: "YBS",
    CITYNAME: "OPAPAMISKA LAKE",
    CITYNAME_HEB: "OPAPAMISKA LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBT",
    AIRPORTNAME: "Brochet",
    CITYCODE: "YBT",
    CITYNAME: "BROCHET",
    CITYNAME_HEB: "BROCHET",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBV",
    AIRPORTNAME: "Berens River",
    CITYCODE: "YBV",
    CITYNAME: "BERENS RIVER",
    CITYNAME_HEB: "BERENS RIVER",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBW",
    AIRPORTNAME: "Bedwell Harbor",
    CITYCODE: "YBW",
    CITYNAME: "BEDWELL HARBOR",
    CITYNAME_HEB: "BEDWELL HARBOR",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBX",
    AIRPORTNAME: "Blanc Sablon",
    CITYCODE: "YBX",
    CITYNAME: "BLANC SABLON",
    CITYNAME_HEB: "BLANC SABLON",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YBY",
    AIRPORTNAME: "Bonnyville",
    CITYCODE: "YBY",
    CITYNAME: "BONNYVILLE",
    CITYNAME_HEB: "Bonnyville",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCA",
    AIRPORTNAME: "Courtenay",
    CITYCODE: "YCA",
    CITYNAME: "COURTENAY",
    CITYNAME_HEB: "COURTENAY",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BSO",
    AIRPORTNAME: "Basco",
    CITYCODE: "BSO",
    CITYNAME: "BASCO",
    CITYNAME_HEB: "BASCO",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "YCB",
    AIRPORTNAME: "Cambridge Bay",
    CITYCODE: "YCB",
    CITYNAME: "CAMBRIDGE BAY",
    CITYNAME_HEB: "CAMBRIDGE BAY",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCC",
    AIRPORTNAME: "Regional",
    CITYCODE: "YCC",
    CITYNAME: "CORNWALL",
    CITYNAME_HEB: "CORNWALL",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCD",
    AIRPORTNAME: "Nanaimo Airport",
    CITYCODE: "YCD",
    CITYNAME: "NANAIMO",
    CITYNAME_HEB: "NANAIMO",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "ZNA",
    AIRPORTNAME: null,
    CITYCODE: "YCD",
    CITYNAME: "NANAIMO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YCE",
    AIRPORTNAME: "Centralia",
    CITYCODE: "YCE",
    CITYNAME: "CENTRALIA",
    CITYNAME_HEB: "CENTRALIA",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCF",
    AIRPORTNAME: "Cortes Bay",
    CITYCODE: "YCF",
    CITYNAME: "CORTES BAY",
    CITYNAME_HEB: "CORTES BAY",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCG",
    AIRPORTNAME: "Castlegar",
    CITYCODE: "YCG",
    CITYNAME: "CASTLEGAR",
    CITYNAME_HEB: "CASTLEGAR",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCH",
    AIRPORTNAME: "Miramichi",
    CITYCODE: "YCH",
    CITYNAME: "MIRAMICHI",
    CITYNAME_HEB: "MIRAMICHI",
    COUNTRYCODE: "CANB",
    COUNTRYNAME: "NEW BRUNSWICK",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCI",
    AIRPORTNAME: "Caribou Island",
    CITYCODE: "YCI",
    CITYNAME: "CARIBOU ISLAND",
    CITYNAME_HEB: "CARIBOU ISLAND",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCJ",
    AIRPORTNAME: "Cape St James",
    CITYCODE: "YCJ",
    CITYNAME: "CAPE ST JAMES",
    CITYNAME_HEB: "CAPE ST JAMES",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BSP",
    AIRPORTNAME: "Bensbach",
    CITYCODE: "BSP",
    CITYNAME: "BENSBACH",
    CITYNAME_HEB: "BENSBACH",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "YCK",
    AIRPORTNAME: "Colville Lake",
    CITYCODE: "YCK",
    CITYNAME: "COLVILLE LAKE",
    CITYNAME_HEB: "COLVILLE LAKE",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCL",
    AIRPORTNAME: "Charlo",
    CITYCODE: "YCL",
    CITYNAME: "CHARLO",
    CITYNAME_HEB: "CHARLO",
    COUNTRYCODE: "CANB",
    COUNTRYNAME: "NEW BRUNSWICK",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCM",
    AIRPORTNAME: "St Catharines",
    CITYCODE: "YCM",
    CITYNAME: "SAINT CATHARINES",
    CITYNAME_HEB: "SAINT CATHARINES",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCN",
    AIRPORTNAME: "Cochrane",
    CITYCODE: "YCN",
    CITYNAME: "COCHRANE",
    CITYNAME_HEB: "COCHRANE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCO",
    AIRPORTNAME: "Kugluktuk",
    CITYCODE: "YCO",
    CITYNAME: "KUGLUKTUK COPPERMINE",
    CITYNAME_HEB: "KUGLUKTUK COPPERMINE",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCP",
    AIRPORTNAME: "Co-op Point",
    CITYCODE: "YCP",
    CITYNAME: "CO OP POINT",
    CITYNAME_HEB: "CO OP POINT",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCQ",
    AIRPORTNAME: "Chetwynd",
    CITYCODE: "YCQ",
    CITYNAME: "CHETWYND",
    CITYNAME_HEB: "CHETWYND",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCR",
    AIRPORTNAME: "Cross Lake",
    CITYCODE: "YCR",
    CITYNAME: "CROSS LAKE",
    CITYNAME_HEB: "CROSS LAKE",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCS",
    AIRPORTNAME: "Chesterfield Inlet",
    CITYCODE: "YCS",
    CITYNAME: "CHESTERFIELD INLE",
    CITYNAME_HEB: "CHESTERFIELD INLE",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCT",
    AIRPORTNAME: "Coronation",
    CITYCODE: "YCT",
    CITYNAME: "CORONATION",
    CITYNAME_HEB: "Coronation",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BSQ",
    AIRPORTNAME: "Bisbee Municipal Airport",
    CITYCODE: "BSQ",
    CITYNAME: "BISBEE",
    CITYNAME_HEB: "BISBEE",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YCV",
    AIRPORTNAME: "Cartierville",
    CITYCODE: "YCV",
    CITYNAME: "CARTIERVILLE",
    CITYNAME_HEB: "CARTIERVILLE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCW",
    AIRPORTNAME: "Chilliwack",
    CITYCODE: "YCW",
    CITYNAME: "CHILLIWACK",
    CITYNAME_HEB: "CHILLIWACK",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCX",
    AIRPORTNAME: "Gagetown",
    CITYCODE: "YCX",
    CITYNAME: "GAGETOWN",
    CITYNAME_HEB: "GAGETOWN",
    COUNTRYCODE: "CANB",
    COUNTRYNAME: "NEW BRUNSWICK",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCY",
    AIRPORTNAME: "Clyde River",
    CITYCODE: "YCY",
    CITYNAME: "CLYDE RIVER",
    CITYNAME_HEB: "CLYDE RIVER",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YCZ",
    AIRPORTNAME: "Fairmount Springs",
    CITYCODE: "YCZ",
    CITYNAME: "FAIRMOUNT SPRINGS",
    CITYNAME_HEB: "FAIRMOUNT SPRINGS",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDA",
    AIRPORTNAME: "Dawson City",
    CITYCODE: "YDA",
    CITYNAME: "DAWSON CITY",
    CITYNAME_HEB: "DAWSON CITY",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDB",
    AIRPORTNAME: "Burwash Landings",
    CITYCODE: "YDB",
    CITYNAME: "BURWASH LANDINGS",
    CITYNAME_HEB: "BURWASH LANDINGS",
    COUNTRYCODE: "CAYT",
    COUNTRYNAME: "YUKON TERRITORY",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDC",
    AIRPORTNAME: "DRAYTON VALLEY",
    CITYCODE: "YDC",
    CITYNAME: "DRAYTON VALLEY",
    CITYNAME_HEB: "DRAYTON VALLEY",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDE",
    AIRPORTNAME: "Paradise River",
    CITYCODE: "YDE",
    CITYNAME: "PARADISE RIVER",
    CITYNAME_HEB: "PARADISE RIVER",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDF",
    AIRPORTNAME: "Deer Lake",
    CITYCODE: "YDF",
    CITYNAME: "DEER LAKE",
    CITYNAME_HEB: "DEER LAKE",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDG",
    AIRPORTNAME: "Digby",
    CITYCODE: "YDG",
    CITYNAME: "DIGBY",
    CITYNAME_HEB: "DIGBY",
    COUNTRYCODE: "CANS",
    COUNTRYNAME: "NOVA SCOTIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDH",
    AIRPORTNAME: "Daniels Harbour",
    CITYCODE: "YDH",
    CITYNAME: "DANIELS HARBOUR",
    CITYNAME_HEB: "DANIELS HARBOUR",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDI",
    AIRPORTNAME: "Davis Inlet",
    CITYCODE: "YDI",
    CITYNAME: "DAVIS INLET",
    CITYNAME_HEB: "DAVIS INLET",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDJ",
    AIRPORTNAME: "Hatchet Lake",
    CITYCODE: "YDJ",
    CITYNAME: "HATCHET LAKE",
    CITYNAME_HEB: "HATCHET LAKE",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YDK",
    AIRPORTNAME: "Main Duck Island",
    CITYCODE: "YDK",
    CITYNAME: "MAIN DUCK ISLAND",
    CITYNAME_HEB: "MAIN DUCK ISLAND",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "THR",
    AIRPORTNAME: " ",
    CITYCODE: "THR",
    CITYNAME: "LASCO",
    CITYNAME_HEB: "\u05dc\u05d0\u05e9\u05e7\u05d5",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LLR",
    AIRPORTNAME: null,
    CITYCODE: "LLR",
    CITYNAME: "GALLE",
    CITYNAME_HEB: "\u05d2\u05d0\u05dc\u05d4",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "BVJ",
    AIRPORTNAME: null,
    CITYCODE: "BVJ",
    CITYNAME: "BALVANYOS",
    CITYNAME_HEB: "\u05d1\u05dc\u05d5\u05d5\u05d0\u05e0\u05d9\u05d5\u05e1",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IAD",
    AIRPORTNAME: "WASHINGTON DULLES INTERNATIONAL AIRPORT",
    CITYCODE: "IAD",
    CITYNAME: "DULLES",
    CITYNAME_HEB: "\u05d3\u05d5\u05dc\u05e1",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "ERJ",
    AIRPORTNAME: null,
    CITYCODE: "ERJ",
    CITYNAME: "ERDING",
    CITYNAME_HEB: "\u05d0\u05e8\u05d3\u05d9\u05e0\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HTE",
    AIRPORTNAME: null,
    CITYCODE: "HTE",
    CITYNAME: "HATTIGNY",
    CITYNAME_HEB: "\u05d0\u05d8\u05d9\u05e0\u05d9-\u05e6\u05e8\u05e4\u05ea",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "CEN",
    AIRPORTNAME: null,
    CITYCODE: "CEN",
    CITYNAME: "CENTRAL AMERICA",
    CITYNAME_HEB:
      "\u05de\u05e8\u05db\u05d6 \u05d0\u05de\u05e8\u05d9\u05e7\u05d4",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LXB",
    AIRPORTNAME: null,
    CITYCODE: "LXB",
    CITYNAME: "LATIN AMERICA",
    CITYNAME_HEB:
      "\u05d0\u05de\u05e8\u05d9\u05e7\u05d4 \u05d4\u05dc\u05d8\u05d9\u05e0\u05d9\u05ea",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BYE",
    AIRPORTNAME: null,
    CITYCODE: "BYE",
    CITYNAME: "BANSKA BYSTRICA",
    CITYNAME_HEB:
      "\u05d1\u05d0\u05e0\u05e1\u05e7\u05d4 \u05d1\u05d9\u05e1\u05d8\u05e8\u05d9\u05e7\u05d4",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BZS",
    AIRPORTNAME: null,
    CITYCODE: "BZS",
    CITYNAME: "BREZZICE",
    CITYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05e6\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HKI",
    AIRPORTNAME: "HOKKAIDO",
    CITYCODE: "HKI",
    CITYNAME: "HOKKAIDO",
    CITYNAME_HEB: "\u05d4\u05d5\u05e7\u05d0\u05d9\u05d3\u05d5",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "YON",
    AIRPORTNAME:
      "\u05d8\u05e1\u05dc\u05e7\u05d4 \u05e7\u05e0\u05d9\u05d5\u05df",
    CITYCODE: "YON",
    CITYNAME: "TSALKA CANYON",
    CITYNAME_HEB:
      "\u05d8\u05e1\u05dc\u05e7\u05d4 \u05e7\u05e0\u05d9\u05d5\u05df",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WMI",
    AIRPORTNAME: "MODLIN MAZOVIA AIRPORT",
    CITYCODE: "WMI",
    CITYNAME: "MODLIN",
    CITYNAME_HEB: "\u05de\u05d5\u05d3\u05dc\u05d9\u05df",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ZZG",
    AIRPORTNAME: null,
    CITYCODE: "ZZG",
    CITYNAME: "KAZANLAK",
    CITYNAME_HEB: "\u05e7\u05d0\u05d6\u05d0\u05e0\u05dc\u05e7",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GPR",
    AIRPORTNAME: null,
    CITYCODE: "GPR",
    CITYNAME: "PORTARIA",
    CITYNAME_HEB: "\u05e4\u05e8\u05d5\u05d8\u05e8\u05d9\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "CVP",
    AIRPORTNAME: null,
    CITYCODE: "CVP",
    CITYNAME: "COVILHA",
    CITYNAME_HEB: "\u05e7\u05d5\u05d1\u05d9\u05dc\u05d4",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTOGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "GPM",
    AIRPORTNAME: null,
    CITYCODE: "GPM",
    CITYNAME: "TOMAR",
    CITYNAME_HEB: "\u05d8\u05d5\u05de\u05d0\u05e8",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTOGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "LVT",
    AIRPORTNAME: null,
    CITYCODE: "LVT",
    CITYNAME: "LATVIA",
    CITYNAME_HEB: "\u05dc\u05d8\u05d1\u05d9\u05d4",
    COUNTRYCODE: "LV",
    COUNTRYNAME: "LATVIA",
    COUNTRYNAME_HEB: "\u05dc\u05d8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PDD",
    AIRPORTNAME: null,
    CITYCODE: "PDD",
    CITYNAME: "POPRAD",
    CITYNAME_HEB: "\u05e4\u05d5\u05e4\u05e8\u05d0\u05d3",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BKL",
    AIRPORTNAME: null,
    CITYCODE: "BKL",
    CITYNAME: "BAD KLEINKIRCHHEIM",
    CITYNAME_HEB:
      "\u05d1\u05d0\u05d3 \u05e7\u05dc\u05d9\u05d9\u05e0\u05e7\u05d9\u05e8\u05e9\u05d4\u05d9\u05d9\u05dd",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VRJ",
    AIRPORTNAME: " ",
    CITYCODE: "VRJ",
    CITYNAME: "VRNJACKA BANJA",
    CITYNAME_HEB:
      "\u05d5\u05e8\u05e0\u05e6\u05e7\u05d4 \u05d1\u05d0\u05e0\u05d4",
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZBA",
    AIRPORTNAME: null,
    CITYCODE: "ZBA",
    CITYNAME: "BAD TOLZ",
    CITYNAME_HEB: "\u05d1\u05d0\u05d3 \u05d8\u05d5\u05dc\u05e5",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "RIR",
    CITYNAME: "SHUMEN",
    CITYNAME_HEB: "\u05e9\u05d5\u05de\u05d0\u05df",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "VLW",
    CITYNAME: "SIGULDA",
    CITYNAME_HEB: "\u05e1\u05d9\u05d2\u05d5\u05dc\u05d3\u05d4",
    COUNTRYCODE: "LV",
    COUNTRYNAME: "LATVIA",
    COUNTRYNAME_HEB: "\u05dc\u05d8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "PBA",
    CITYNAME: "BAR",
    CITYNAME_HEB: "\u05d1\u05e8",
    COUNTRYCODE: "ME",
    COUNTRYNAME: "MONTENEGRO",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "BJT",
    AIRPORTNAME: null,
    CITYCODE: "BJT",
    CITYNAME: "BENTOTA",
    CITYNAME_HEB: "\u05d1\u05e0\u05d8\u05d5\u05d8\u05d4",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: "AFOURER",
    CITYCODE: "REO",
    CITYNAME: "AFOURER",
    CITYNAME_HEB: "\u05d0\u05e4\u05d5\u05d0\u05d5\u05e8\u05e8",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "AER",
    AIRPORTNAME: null,
    CITYCODE: "AER",
    CITYNAME: "SOCHI",
    CITYNAME_HEB: "\u05e1\u05d5\u05e6\u05d9",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NYA",
    AIRPORTNAME: "BANYA",
    CITYCODE: "NYA",
    CITYNAME: "BANYA",
    CITYNAME_HEB: "\u05d1\u05e0\u05d9\u05d4",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PCF",
    AIRPORTNAME: null,
    CITYCODE: "PCF",
    CITYNAME: "PENICHE",
    CITYNAME_HEB: "\u05e4\u05e0\u05d9\u05e9",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTOGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "HAX",
    CITYNAME: "CHALKIDA",
    CITYNAME_HEB: "\u05d7\u05d0\u05dc\u05e7\u05d9\u05d3\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "EEC",
    CITYNAME: "SEORAKSAN",
    CITYNAME_HEB: "\u05e1\u05d0\u05d5\u05e8\u05e7\u05e1\u05d0\u05df",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "GYC",
    CITYNAME: "GYEONGJU",
    CITYNAME_HEB: "\u05e7\u05d5\u05d0\u05e0\u05d2\u05d5",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NFT",
    AIRPORTNAME: null,
    CITYCODE: "NFT",
    CITYNAME: "NAFTALAN",
    CITYNAME_HEB: "\u05e0\u05e4\u05d8\u05dc\u05d0\u05df",
    COUNTRYCODE: "AZ",
    COUNTRYNAME: "AZERBAIJAN",
    COUNTRYNAME_HEB: "\u05d0\u05d6\u05e8\u05d1\u05d9\u05d9\u05d2\u05df",
  },
  {
    AIRPORTCODE: "MXA",
    AIRPORTNAME: null,
    CITYCODE: "MXA",
    CITYNAME: "MONTAFON",
    CITYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e4\u05d5\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ITF",
    AIRPORTNAME: null,
    CITYCODE: "ITF",
    CITYNAME: "ITALIAN RIVIERA",
    CITYNAME_HEB:
      "\u05d4\u05e8\u05d9\u05d1\u05d9\u05d9\u05e8\u05d4 \u05d4\u05d0\u05d9\u05d8\u05dc\u05e7\u05d9\u05ea",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OZO",
    AIRPORTNAME: "OZORA",
    CITYCODE: "OZO",
    CITYNAME: "OZORA",
    CITYNAME_HEB: "\u05d0\u05d5\u05d6\u05d5\u05e8\u05d4",
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VNB",
    AIRPORTNAME: null,
    CITYCODE: "VNB",
    CITYNAME: "SAVANNAH",
    CITYNAME_HEB: "\u05e1\u05d5\u05d5\u05d0\u05e0\u05d4",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SAM",
    AIRPORTNAME: null,
    CITYCODE: "SAM",
    CITYNAME: "SAMORIN",
    CITYNAME_HEB: "\u05e1\u05de\u05d5\u05e8\u05d9\u05df",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LDG",
    AIRPORTNAME: null,
    CITYCODE: "LDG",
    CITYNAME: "GARDA LAKE",
    CITYNAME_HEB: "\u05d0\u05d2\u05dd \u05d2\u05d0\u05e8\u05d3\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BKV",
    AIRPORTNAME: "BUKOVEL",
    CITYCODE: "BKV",
    CITYNAME: "BUKOVEL",
    CITYNAME_HEB: "\u05d1\u05d5\u05e7\u05d5\u05d1\u05dc",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "MMI",
    AIRPORTNAME: null,
    CITYCODE: "MMI",
    CITYNAME: "MAMAIA",
    CITYNAME_HEB: "\u05de\u05d0\u05de\u05d0\u05d9\u05d4",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AJE",
    AIRPORTNAME: null,
    CITYCODE: "AJE",
    CITYNAME: "ARANDJELOVAC",
    CITYNAME_HEB: "\u05d0\u05e8\u05e0\u05d3\u05dc\u05d5\u05d1\u05e5",
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "TTP",
    CITYNAME: "BUSTENI",
    CITYNAME_HEB: "\u05d1\u05d5\u05e1\u05d8\u05e0\u05d9",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XAF",
    AIRPORTNAME: null,
    CITYCODE: "XAF",
    CITYNAME: "UNITED ARAB EMIRATES",
    CITYNAME_HEB:
      "\u05d0\u05d9\u05d7\u05d5\u05d3 \u05d4\u05d0\u05de\u05d9\u05e8\u05d5\u05d9\u05d5\u05ea",
    COUNTRYCODE: "AE",
    COUNTRYNAME: "UNITED ARAB EMIRATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d7\u05d5\u05d3 \u05d4\u05d0\u05de\u05d9\u05e8\u05d5\u05d9\u05d5\u05ea",
  },
  {
    AIRPORTCODE: "RYT",
    AIRPORTNAME: null,
    CITYCODE: "RYT",
    CITYNAME: "TURKEY",
    CITYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "VVB",
    CITYNAME: "SOLVANG",
    CITYNAME_HEB: "\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "ZLK",
    AIRPORTNAME: null,
    CITYCODE: "ZLK",
    CITYNAME: "ZHELEZNOVODSK",
    CITYNAME_HEB:
      "\u05d6\u05dc\u05d6\u05e0\u05d5\u05d1\u05d5\u05d3\u05e1\u05e7",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HRF",
    AIRPORTNAME: null,
    CITYCODE: "HRF",
    CITYNAME: "HARVEST CAYE",
    CITYNAME_HEB: "\u05d4\u05e8\u05d1\u05e1\u05d8 \u05e7\u05d9\u05d9",
    COUNTRYCODE: "BZ",
    COUNTRYNAME: "BELIZE",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d9\u05d6",
  },
  {
    AIRPORTCODE: "LVN",
    AIRPORTNAME: null,
    CITYCODE: "LVN",
    CITYNAME: "LIVORNO",
    CITYNAME_HEB: "\u05dc\u05d9\u05d1\u05d5\u05e8\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XUS",
    AIRPORTNAME: null,
    CITYCODE: "XUS",
    CITYNAME: "CABO SAN LUCAS",
    CITYNAME_HEB:
      "\u05e7\u05d0\u05d1\u05d5 \u05e1\u05d0\u05df \u05dc\u05d5\u05e7\u05d0\u05e1",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "IEZ",
    AIRPORTNAME: null,
    CITYCODE: "IEZ",
    CITYNAME: "ALBA DI CANAZEI",
    CITYNAME_HEB:
      "\u05d0\u05dc\u05d1\u05d4 \u05d3\u05d9 \u05e7\u05d0\u05e0\u05d6\u05d9\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "EOV",
    CITYNAME: "AVIEMORE",
    CITYNAME_HEB: "\u05d0\u05d1\u05d9\u05de\u05d5\u05e8",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FPB",
    AIRPORTNAME: null,
    CITYCODE: "FPB",
    CITYNAME: "PROTARAS",
    CITYNAME_HEB: "\u05e4\u05e8\u05d5\u05d8\u05d0\u05e8\u05e1",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "KYG",
    AIRPORTNAME: " ",
    CITYCODE: "KYG",
    CITYNAME: "KYRGYZSTAN",
    CITYNAME_HEB:
      "\u05e7\u05d9\u05e8\u05d2\u05d9\u05e1\u05d9\u05e1\u05d8\u05d0\u05df",
    COUNTRYCODE: "KG",
    COUNTRYNAME: "KYRGYZSTAN",
    COUNTRYNAME_HEB:
      "\u05e7\u05d9\u05e8\u05d2\u05d9\u05e1\u05d9\u05e1\u05d8\u05d0\u05df",
  },
  {
    AIRPORTCODE: "ICD",
    AIRPORTNAME: null,
    CITYCODE: "ICD",
    CITYNAME: "ICELAND",
    CITYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BYO",
    AIRPORTNAME: null,
    CITYCODE: "BYO",
    CITYNAME: "BAY OF ICLEND",
    CITYNAME_HEB: "\u05de\u05e4\u05e8\u05e5 \u05d4\u05d0\u05d9\u05d9\u05dd",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "KGS",
    AIRPORTNAME: null,
    CITYCODE: "KGS",
    CITYNAME: "KOS",
    CITYNAME_HEB: "\u05e7\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "ZFS",
    AIRPORTNAME: null,
    CITYCODE: "ZFS",
    CITYNAME: "FLIMS",
    CITYNAME_HEB: "\u05e4\u05dc\u05d9\u05de\u05e1",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "OOB",
    AIRPORTNAME: "OOB",
    CITYCODE: "OOB",
    CITYNAME: "SASEBO",
    CITYNAME_HEB: "\u05e1\u05e1\u05d1\u05d5",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "MMR",
    AIRPORTNAME: "MMR",
    CITYCODE: "MMR",
    CITYNAME: "GAMAGORI",
    CITYNAME_HEB: "\u05d2\u05de\u05d0\u05d2\u05d5\u05e8\u05d9",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "GLB",
    AIRPORTNAME: null,
    CITYCODE: "GLB",
    CITYNAME: "GOLEM",
    CITYNAME_HEB:
      "\u05d2\u05d5\u05dc\u05dd-\u05d0\u05dc\u05d1\u05e0\u05d9\u05d4",
    COUNTRYCODE: "AL",
    COUNTRYNAME: "ALBANIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DUL",
    AIRPORTNAME: null,
    CITYCODE: "DUL",
    CITYNAME: "DURRES",
    CITYNAME_HEB: "\u05d3\u05d5\u05e8\u05e1",
    COUNTRYCODE: "AL",
    COUNTRYNAME: "ALBANIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AWI",
    AIRPORTNAME: "AMWAJ ISLANDS",
    CITYCODE: "AWI",
    CITYNAME: "AMWAJ ISLANDS",
    CITYNAME_HEB: "\u05d0\u05d9\u05d9 \u05d0\u05de\u05d5\u05d5\u05d0\u05d2",
    COUNTRYCODE: "BH",
    COUNTRYNAME: "BAHRAIN",
    COUNTRYNAME_HEB: "\u05d1\u05d7\u05e8\u05d9\u05d9\u05df",
  },
  {
    AIRPORTCODE: "RKV",
    AIRPORTNAME: "Reykjavik Domestic",
    CITYCODE: "REK",
    CITYNAME: "REYKJAVIK",
    CITYNAME_HEB: "\u05e8\u05e7\u05d5\u05d9\u05d0\u05e7",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HDJ",
    AIRPORTNAME: " ",
    CITYCODE: "HDJ",
    CITYNAME: "HAJDUSZOBOSZLO",
    CITYNAME_HEB:
      "\u05d4\u05d9\u05d9\u05d3\u05d5\u05e1\u05d5\u05d1\u05d5\u05e1\u05dc\u05d5",
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "EEB",
    AIRPORTNAME: null,
    CITYCODE: "EEB",
    CITYNAME: "EERBEEK",
    CITYNAME_HEB: "\u05d0\u05d9\u05e8\u05d1\u05d9\u05e7",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "YSC",
    AIRPORTNAME: "Sherbrooke",
    CITYCODE: "YSC",
    CITYNAME: "SHERBROOKE",
    CITYNAME_HEB: "SHERBROOKE",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSD",
    AIRPORTNAME: "Suffield",
    CITYCODE: "YSD",
    CITYNAME: "SUFFIELD",
    CITYNAME_HEB: "Suffield",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSE",
    AIRPORTNAME: "Squamish",
    CITYCODE: "YSE",
    CITYNAME: "SQUAMISH",
    CITYNAME_HEB: "SQUAMISH",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSF",
    AIRPORTNAME: "Stony Rapids",
    CITYCODE: "YSF",
    CITYNAME: "STONY RAPIDS",
    CITYNAME_HEB: "STONY RAPIDS",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSG",
    AIRPORTNAME: "Lutselke",
    CITYCODE: "YSG",
    CITYNAME: "LUTSEL KE SNOWDRIFT",
    CITYNAME_HEB: "LUTSEL KE SNOWDRIFT",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTT",
    AIRPORTNAME: "Bettles",
    CITYCODE: "BTT",
    CITYNAME: "BETTLES",
    CITYNAME_HEB: "Bettles",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "YSH",
    AIRPORTNAME: "Smith Falls",
    CITYCODE: "YSH",
    CITYNAME: "SMITH FALLS",
    CITYNAME_HEB: "SMITH FALLS",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSI",
    AIRPORTNAME: "Sans Souci",
    CITYCODE: "YSI",
    CITYNAME: "SANS SOUCI",
    CITYNAME_HEB: "SANS SOUCI",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSJ",
    AIRPORTNAME: "Saint John",
    CITYCODE: "YSJ",
    CITYNAME: "SAINT JOHN",
    CITYNAME_HEB: "SAINT JOHN",
    COUNTRYCODE: "CANB",
    COUNTRYNAME: "NEW BRUNSWICK",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSK",
    AIRPORTNAME: "Sanikiluaq",
    CITYCODE: "YSK",
    CITYNAME: "SANIKILUAQ",
    CITYNAME_HEB: "SANIKILUAQ",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSL",
    AIRPORTNAME: "Edmunston",
    CITYCODE: "YSL",
    CITYNAME: "EDMUNSTON",
    CITYNAME_HEB: "EDMUNSTON",
    COUNTRYCODE: "CANB",
    COUNTRYNAME: "NEW BRUNSWICK",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSM",
    AIRPORTNAME: "Fort Smith",
    CITYCODE: "YSM",
    CITYNAME: "FORT SMITH",
    CITYNAME_HEB: "FORT SMITH",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSN",
    AIRPORTNAME: "Salmon Arm",
    CITYCODE: "YSN",
    CITYNAME: "SALMON ARM",
    CITYNAME_HEB: "SALMON ARM",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSO",
    AIRPORTNAME: "Postville",
    CITYCODE: "YSO",
    CITYNAME: "POSTVILLE",
    CITYNAME_HEB: "POSTVILLE",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSP",
    AIRPORTNAME: "Marathon",
    CITYCODE: "YSP",
    CITYNAME: "MARATHON",
    CITYNAME_HEB: "MARATHON",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSQ",
    AIRPORTNAME: "Spring Island",
    CITYCODE: "YSQ",
    CITYNAME: "SPRING ISLAND",
    CITYNAME_HEB: "SPRING ISLAND",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BTU",
    AIRPORTNAME: "Bintulu",
    CITYCODE: "BTU",
    CITYNAME: "BINTULU",
    CITYNAME_HEB: "BINTULU",
    COUNTRYCODE: "MY",
    COUNTRYNAME: "MALAYSIA",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "YSR",
    AIRPORTNAME: "Nanisivik",
    CITYCODE: "YSR",
    CITYNAME: "NANISIVIK",
    CITYNAME_HEB: "NANISIVIK",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSS",
    AIRPORTNAME: "Slate Island",
    CITYCODE: "YSS",
    CITYNAME: "SLATE ISLAND",
    CITYNAME_HEB: "SLATE ISLAND",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YST",
    AIRPORTNAME: "Ste Therese Point",
    CITYCODE: "YST",
    CITYNAME: "SAINTE THERESE POINT",
    CITYNAME_HEB: "SAINTE THERESE POINT",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSU",
    AIRPORTNAME: "Summerside",
    CITYCODE: "YSU",
    CITYNAME: "SUMMERSIDE",
    CITYNAME_HEB: "SUMMERSIDE",
    COUNTRYCODE: "CAPE",
    COUNTRYNAME: "PRINCE EDWARD ISLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSV",
    AIRPORTNAME: "Saglek",
    CITYCODE: "YSV",
    CITYNAME: "SAGLEK",
    CITYNAME_HEB: "SAGLEK",
    COUNTRYCODE: "CANF",
    COUNTRYNAME: "NEWFOUNDLAND",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YZG",
    AIRPORTNAME: null,
    CITYCODE: "YSW",
    CITYNAME: "SALLUIT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YSX",
    AIRPORTNAME: "Shearwater",
    CITYCODE: "YSX",
    CITYNAME: "SHEARWATER",
    CITYNAME_HEB: "SHEARWATER",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSY",
    AIRPORTNAME: "Sachs Harbour",
    CITYCODE: "YSY",
    CITYNAME: "SACHS HARBOUR",
    CITYNAME_HEB: "SACHS HARBOUR",
    COUNTRYCODE: "CANT",
    COUNTRYNAME: "NORTHWEST TERRITORIES",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSZ",
    AIRPORTNAME: "Squirrel Cove",
    CITYCODE: "YSZ",
    CITYNAME: "SQUIRREL COVE",
    CITYNAME_HEB: "SQUIRREL COVE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YTA",
    AIRPORTNAME: "Pembroke and Area Airport",
    CITYCODE: "YTA",
    CITYNAME: "PEMBROKE",
    CITYNAME_HEB: "PEMBROKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "DNA",
    AIRPORTNAME: " ",
    CITYCODE: "DNA",
    CITYNAME: "DONAUESCHINGEN",
    CITYNAME_HEB: "\u05d3\u05d5\u05e0\u05d0\u05e9\u05d2\u05d9\u05d9\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OLY",
    AIRPORTNAME: " ",
    CITYCODE: "OLY",
    CITYNAME: "OLYMPIA",
    CITYNAME_HEB: "\u05d0\u05d5\u05dc\u05d9\u05de\u05e4\u05d9\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "GOF",
    CITYNAME: "GOTZENS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XNE",
    AIRPORTNAME: " ",
    CITYCODE: "XNE",
    CITYNAME: "NORTHERN EUROPE CRUISE",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "NFD",
    CITYNAME: "LANGENFELD",
    CITYNAME_HEB: "\u05dc\u05d2\u05e0\u05e4\u05dc\u05d3",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ERL",
    AIRPORTNAME: " ",
    CITYCODE: "ERL",
    CITYNAME: "GERLOS",
    CITYNAME_HEB: "\u05d2\u05d0\u05e8\u05dc\u05d5\u05e1",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BDU",
    AIRPORTNAME: " ",
    CITYCODE: "BDU",
    CITYNAME: "BAD DURRHEIM",
    CITYNAME_HEB: " \u05d1\u05d3 \u05d3\u05d5\u05e8\u05d7\u05d9\u05d9\u05dd",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: " \u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TIK",
    AIRPORTNAME: " ",
    CITYCODE: "TIK",
    CITYNAME: "SANT VEIT IM PONGAU",
    CITYNAME_HEB:
      "\u05e1\u05d0\u05e0\u05d8 \u05d5\u05d9\u05d8 \u05d0\u05d9\u05de\u05e4\u05d5\u05e0\u05d2\u05d0\u05d5",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RAS",
    AIRPORTNAME: " ",
    CITYCODE: "RAS",
    CITYNAME: "SARVAR",
    CITYNAME_HEB: "\u05e1\u05d0\u05e8\u05d1\u05d0\u05e8",
    COUNTRYCODE: "HU",
    COUNTRYNAME: "HUNGARY",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LET",
    AIRPORTNAME: " ",
    CITYCODE: "LET",
    CITYNAME: "LES 3 VALLEES RESORT",
    CITYNAME_HEB:
      "\u05dc\u05d4 \u05d8\u05e8\u05d9 \u05d5\u05d5\u05d0\u05dc\u05d4 \u05e8\u05d9\u05d6\u05d5\u05e8\u05d8",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "DER",
    AIRPORTNAME: " ",
    CITYCODE: "DER",
    CITYNAME: "DEIDESHEIM",
    CITYNAME_HEB: "\u05d3\u05d0\u05d3\u05d9\u05e9\u05d9\u05d9\u05dd",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AAY",
    AIRPORTNAME: " ",
    CITYCODE: "AAY",
    CITYNAME: "AALEN",
    CITYNAME_HEB: "\u05d0\u05d0\u05dc\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KMX",
    AIRPORTNAME: " ",
    CITYCODE: "KMX",
    CITYNAME: "KALAMPAKA",
    CITYNAME_HEB: "\u05e7\u05d0\u05dc\u05de\u05e4\u05d0\u05e7\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "TDS",
    AIRPORTNAME: " ",
    CITYCODE: "TDS",
    CITYNAME: "TRODOS",
    CITYNAME_HEB: "\u05d8\u05e8\u05d5\u05d3\u05d5\u05e1",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "EVI",
    AIRPORTNAME: " ",
    CITYCODE: "EVI",
    CITYNAME: "EVIA ISLAND",
    CITYNAME_HEB: "\u05d0\u05d5\u05d5\u05d9\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "SSD",
    CITYNAME: "SAUERLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "FUY",
    CITYNAME: "FURNARI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "SEM",
    CITYNAME: "SERFAUS-FISS-LADIS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "SUW",
    CITYNAME: "STUBAITAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZAQ",
    AIRPORTNAME: " ",
    CITYCODE: "ZAQ",
    CITYNAME: "ZAMS",
    CITYNAME_HEB: "\u05e6\u05d0\u05de\u05e1",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FLX",
    AIRPORTNAME: " ",
    CITYCODE: "FLX",
    CITYNAME: "FULPMES",
    CITYNAME_HEB: "\u05e4\u05d5\u05dc\u05e4\u05de\u05d0\u05e1",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OBG",
    AIRPORTNAME: " ",
    CITYCODE: "OBG",
    CITYNAME: "OBERGURGL",
    CITYNAME_HEB: "\u05d0\u05d5\u05d1\u05e8\u05d0\u05d5\u05d2\u05dc",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KIK",
    AIRPORTNAME: " ",
    CITYCODE: "KIK",
    CITYNAME: "KIRCHDORF",
    CITYNAME_HEB: "\u05e7\u05d9\u05e8\u05e9\u05d3\u05d5\u05e8\u05e3",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "GOW",
    CITYNAME: "GOSAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CNF",
    AIRPORTNAME: " ",
    CITYCODE: "CNF",
    CITYNAME: "CANILO",
    CITYNAME_HEB: "\u05e7\u05d0\u05e0\u05d9\u05dc\u05d5",
    COUNTRYCODE: "AD",
    COUNTRYNAME: "ANDORRA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d3\u05d5\u05e8\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "LVY",
    CITYNAME: "LOIRE VALLEY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "SLJ",
    AIRPORTNAME: " ",
    CITYCODE: "SLJ",
    CITYNAME: "SLOGEN",
    CITYNAME_HEB: "\u05e1\u05d5\u05dc\u05d2\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BDL",
    AIRPORTNAME: " ",
    CITYCODE: "BDL",
    CITYNAME: "BAD REICHENHALL",
    CITYNAME_HEB:
      "\u05d1\u05d0\u05d3 \u05e8\u05d9\u05d9\u05db\u05e0\u05d0\u05dc",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "OBH",
    CITYNAME: "OBERHARMERSBACH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VEB",
    AIRPORTNAME: " ",
    CITYCODE: "VEB",
    CITYNAME: "VERBANIA",
    CITYNAME_HEB: "\u05d5\u05d0\u05e8\u05d1\u05d0\u05e0\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LOP",
    AIRPORTNAME: " ",
    CITYCODE: "LOP",
    CITYNAME: "LOMAZZO",
    CITYNAME_HEB: "\u05dc\u05d5\u05de\u05d0\u05d6\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BZF",
    AIRPORTNAME: " ",
    CITYCODE: "BZF",
    CITYNAME: "BARZANO",
    CITYNAME_HEB: "\u05d1\u05e8\u05d0\u05d6\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TRI",
    AIRPORTNAME: " ",
    CITYCODE: "TRI",
    CITYNAME: "TRIBERG",
    CITYNAME_HEB: " \u05d8\u05e8\u05d9\u05d1\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: " \u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DUE",
    AIRPORTNAME: " ",
    CITYCODE: "DUE",
    CITYNAME: "DURBACH",
    CITYNAME_HEB: "\u05d3\u05d5\u05e8\u05d1\u05da",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NDD",
    AIRPORTNAME: " ",
    CITYCODE: "NDD",
    CITYNAME: "NORDLINGEN",
    CITYNAME_HEB: "\u05e0\u05d5\u05e8\u05d3\u05dc\u05d9\u05e0\u05d2\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JAE",
    AIRPORTNAME: " ",
    CITYCODE: "JAE",
    CITYNAME: "JANOSIK",
    CITYNAME_HEB: "\u05d2\u05e0\u05d5\u05e1\u05d9\u05e7",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LOJ",
    AIRPORTNAME: " ",
    CITYCODE: "LOJ",
    CITYNAME: "LOMNICA",
    CITYNAME_HEB: "\u05dc\u05d5\u05de\u05e0\u05d9\u05e6\u05d4",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DEV",
    AIRPORTNAME: " ",
    CITYCODE: "DEV",
    CITYNAME: "DEVIN",
    CITYNAME_HEB: "\u05d3\u05d0\u05d1\u05d9\u05df",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BOC",
    AIRPORTNAME: "Bocas Del Toro",
    CITYCODE: "BOC",
    CITYNAME: "BOCAS DEL TORO",
    CITYNAME_HEB: "BOCAS DEL TORO",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "UME",
    AIRPORTNAME: "Umea",
    CITYCODE: "UME",
    CITYNAME: "UMEA",
    CITYNAME_HEB: "UMEA",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UMI",
    AIRPORTNAME: "Quincemil",
    CITYCODE: "UMI",
    CITYNAME: "QUINCEMIL",
    CITYNAME_HEB: "QUINCEMIL",
    COUNTRYCODE: "PE",
    COUNTRYNAME: "PERU",
    COUNTRYNAME_HEB: "\u05e4\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "UMM",
    AIRPORTNAME: "Summit",
    CITYCODE: "UMM",
    CITYNAME: "SUMMIT",
    CITYNAME_HEB: "Summit",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UMR",
    AIRPORTNAME: "Woomera",
    CITYCODE: "UMR",
    CITYNAME: "WOOMERA",
    CITYNAME_HEB: "WOOMERA",
    COUNTRYCODE: "AUSA",
    COUNTRYNAME: "SOUTH AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UMT",
    AIRPORTNAME: "Umiat",
    CITYCODE: "UMT",
    CITYNAME: "UMIAT",
    CITYNAME_HEB: "Umiat",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UMU",
    AIRPORTNAME: null,
    CITYCODE: "UMU",
    CITYNAME: "UMUARAMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UMY",
    AIRPORTNAME: "Sumy",
    CITYCODE: "UMY",
    CITYNAME: "SUMY",
    CITYNAME_HEB: "SUMY",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "UNA",
    AIRPORTNAME: null,
    CITYCODE: "UNA",
    CITYNAME: "UNA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UNC",
    AIRPORTNAME: "Unguia",
    CITYCODE: "UNC",
    CITYNAME: "UNGUIA",
    CITYNAME_HEB: "UNGUIA",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UND",
    AIRPORTNAME: null,
    CITYCODE: "UND",
    CITYNAME: "KUNDUZ",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BOD",
    AIRPORTNAME: "Bordeaux Airport",
    CITYCODE: "BOD",
    CITYNAME: "BORDEAUX",
    CITYNAME_HEB: "\u05d1\u05d5\u05e8\u05d3\u05d5",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "UNE",
    AIRPORTNAME: "Qachas Nek",
    CITYCODE: "UNE",
    CITYNAME: "QACHAS NEK",
    CITYNAME_HEB: "QACHAS NEK",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "UNG",
    AIRPORTNAME: "Kiunga",
    CITYCODE: "UNG",
    CITYNAME: "KIUNGA",
    CITYNAME_HEB: "KIUNGA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "UNI",
    AIRPORTNAME: null,
    CITYCODE: "UNI",
    CITYNAME: "UNION ISLAND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "VC",
    COUNTRYNAME: "ST. VINCENT",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UNK",
    AIRPORTNAME: "Unalakleet",
    CITYCODE: "UNK",
    CITYNAME: "UNALAKLEET",
    CITYNAME_HEB: "Unalakleet",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UNN",
    AIRPORTNAME: "Ranong",
    CITYCODE: "UNN",
    CITYNAME: "RANONG",
    CITYNAME_HEB: "RANONG",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "UNR",
    AIRPORTNAME: "Underkhaan",
    CITYCODE: "UNR",
    CITYNAME: "UNDERKHAAN",
    CITYNAME_HEB: "UNDERKHAAN",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UNT",
    AIRPORTNAME: "Baltasound",
    CITYCODE: "UNT",
    CITYNAME: "UNST SHETLAND IS",
    CITYNAME_HEB: "UNST SHETLAND IS",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UNU",
    AIRPORTNAME: "Dodge County",
    CITYCODE: "UNU",
    CITYNAME: "JUNEAU",
    CITYNAME_HEB: "JUNEAU",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UOL",
    AIRPORTNAME: "Buol",
    CITYCODE: "UOL",
    CITYNAME: "BUOL",
    CITYNAME_HEB: "BUOL",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UON",
    AIRPORTNAME: "Muong Sai",
    CITYCODE: "UON",
    CITYNAME: "MUONG SAI",
    CITYNAME_HEB: "MUONG SAI",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "ZFQ",
    AIRPORTNAME: null,
    CITYCODE: "BOD",
    CITYNAME: "BORDEAUX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UOS",
    AIRPORTNAME: "Franklin County",
    CITYCODE: "UOS",
    CITYNAME: "SEWANEE",
    CITYNAME_HEB: "SEWANEE",
    COUNTRYCODE: "USTN",
    COUNTRYNAME: "TENNESSEE",
    COUNTRYNAME_HEB: '\u05d8\u05e0\u05e1\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UOX",
    AIRPORTNAME: "University-Oxford",
    CITYCODE: "UOX",
    CITYNAME: "OXFORD",
    CITYNAME_HEB: "OXFORD",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UPA",
    AIRPORTNAME: "Punta Alegre",
    CITYCODE: "UPA",
    CITYNAME: "PUNTA ALEGRE",
    CITYNAME_HEB: "PUNTA ALEGRE",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "UPC",
    AIRPORTNAME: "Puerto La Cruz",
    CITYCODE: "UPC",
    CITYNAME: "PUERTO LA CRUZ",
    CITYNAME_HEB: "PUERTO LA CRUZ",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "UPF",
    AIRPORTNAME: "Pforzheim",
    CITYCODE: "UPF",
    CITYNAME: "PFORZHEIM",
    CITYNAME_HEB: "PFORZHEIM",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UPG",
    AIRPORTNAME: "Hasanudin",
    CITYCODE: "UPG",
    CITYNAME: "UJUNG PANDANG",
    CITYNAME_HEB: "UJUNG PANDANG",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UPL",
    AIRPORTNAME: "Upala",
    CITYCODE: "UPL",
    CITYNAME: "UPALA",
    CITYNAME_HEB: "UPALA",
    COUNTRYCODE: "CR",
    COUNTRYNAME: "COSTA RICA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "UPN",
    AIRPORTNAME: "Uruapan",
    CITYCODE: "UPN",
    CITYNAME: "URUAPAN",
    CITYNAME_HEB: "URUAPAN",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "UPP",
    AIRPORTNAME: "Upolu Point",
    CITYCODE: "UPP",
    CITYNAME: "UPOLU POINT",
    CITYNAME_HEB: "UPOLU POINT",
    COUNTRYCODE: "USHI",
    COUNTRYNAME: "HAWAII",
    COUNTRYNAME_HEB:
      '\u05d4\u05d5\u05d5\u05d0\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UPR",
    AIRPORTNAME: "Upiara",
    CITYCODE: "UPR",
    CITYNAME: "UPIARA",
    CITYNAME_HEB: "UPIARA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BOE",
    AIRPORTNAME: "Boundji",
    CITYCODE: "BOE",
    CITYNAME: "BOUNDJI",
    CITYNAME_HEB: "BOUNDJI",
    COUNTRYCODE: "CG",
    COUNTRYNAME: "CONGO BRAZZAVILLE",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05e0\u05d2\u05d5",
  },
  {
    AIRPORTCODE: "UPV",
    AIRPORTNAME: "Upavon",
    CITYCODE: "UPV",
    CITYNAME: "UPAVON",
    CITYNAME_HEB: "UPAVON",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UQE",
    AIRPORTNAME: "Queen",
    CITYCODE: "UQE",
    CITYNAME: "QUEEN",
    CITYNAME_HEB: "Queen",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "URA",
    AIRPORTNAME: "Uralsk",
    CITYCODE: "URA",
    CITYNAME: "URALSK",
    CITYNAME_HEB: "URALSK",
    COUNTRYCODE: "KZ",
    COUNTRYNAME: "KAZAKHSTAN",
    COUNTRYNAME_HEB: "\u05e7\u05d6\u05d0\u05d7\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "URB",
    AIRPORTNAME: null,
    CITYCODE: "URB",
    CITYNAME: "URUBUPUNGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "URC",
    AIRPORTNAME: "Urumqi",
    CITYCODE: "URC",
    CITYNAME: "URUMQI",
    CITYNAME_HEB: "URUMQI",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "URD",
    AIRPORTNAME: null,
    CITYCODE: "URD",
    CITYNAME: "BURG FEUERSTEIN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "URE",
    AIRPORTNAME: "Kuressaare",
    CITYCODE: "URE",
    CITYNAME: "KURESSAARE",
    CITYNAME_HEB: "KURESSAARE",
    COUNTRYCODE: "EE",
    COUNTRYNAME: "ESTONIA",
    COUNTRYNAME_HEB: "\u05d0\u05e1\u05d8\u05d5\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "URG",
    AIRPORTNAME: null,
    CITYCODE: "URG",
    CITYNAME: "URUGUAIANA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "URI",
    AIRPORTNAME: "Uribe",
    CITYCODE: "URI",
    CITYNAME: "URIBE",
    CITYNAME_HEB: "URIBE",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "URJ",
    AIRPORTNAME: "Uraj",
    CITYCODE: "URJ",
    CITYNAME: "URAJ",
    CITYNAME_HEB: "URAJ",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BOG",
    AIRPORTNAME: "Eldorado",
    CITYCODE: "BOG",
    CITYNAME: "BOGOTA",
    CITYNAME_HEB: "\u05d1\u05d5\u05d2\u05d5\u05d8\u05d4",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "URM",
    AIRPORTNAME: "Uriman",
    CITYCODE: "URM",
    CITYNAME: "URIMAN",
    CITYNAME_HEB: "URIMAN",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "URN",
    AIRPORTNAME: null,
    CITYCODE: "URN",
    CITYNAME: "URGOON",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "URO",
    AIRPORTNAME: "Boos",
    CITYCODE: "URO",
    CITYNAME: "ROUEN",
    CITYNAME_HEB: "ROUEN",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "URR",
    AIRPORTNAME: "Urrao",
    CITYCODE: "URR",
    CITYNAME: "URRAO",
    CITYNAME_HEB: "URRAO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "URS",
    AIRPORTNAME: "Kursk",
    CITYCODE: "URS",
    CITYNAME: "KURSK",
    CITYNAME_HEB: "KURSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "URT",
    AIRPORTNAME: "Surat Thani",
    CITYCODE: "URT",
    CITYNAME: "SURAT THANI",
    CITYNAME_HEB: "\u05e1\u05d5\u05e8\u05d0\u05d8 \u05ea\u05d0\u05e0\u05d9",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "URU",
    AIRPORTNAME: "Uroubi",
    CITYCODE: "URU",
    CITYNAME: "UROUBI",
    CITYNAME_HEB: "UROUBI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "URZ",
    AIRPORTNAME: null,
    CITYCODE: "URZ",
    CITYNAME: "URUZGAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AF",
    COUNTRYNAME: "AFGHANISTAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "USC",
    AIRPORTNAME: null,
    CITYCODE: "USC",
    CITYNAME: "UNION S CAROLINA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USSC",
    COUNTRYNAME: "SOUTH CAROLINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "USH",
    AIRPORTNAME: null,
    CITYCODE: "USH",
    CITYNAME: "USHUAIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ADX",
    AIRPORTNAME: "Leuchars",
    CITYCODE: "ADX",
    CITYNAME: "SAINT ANDREWS",
    CITYNAME_HEB: "SAINT ANDREWS",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BOH",
    AIRPORTNAME: "International",
    CITYCODE: "BOH",
    CITYNAME: "BOURNEMOUTH",
    CITYNAME_HEB: "BOURNEMOUTH",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "USI",
    AIRPORTNAME: "Mabaruma",
    CITYCODE: "USI",
    CITYNAME: "MABARUMA",
    CITYNAME_HEB: "MABARUMA",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "USK",
    AIRPORTNAME: "Usinsk",
    CITYCODE: "USK",
    CITYNAME: "USINSK",
    CITYNAME_HEB: "USINSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "USL",
    AIRPORTNAME: "Useless Loop",
    CITYCODE: "USL",
    CITYNAME: "USELESS LOOP",
    CITYNAME_HEB: "USELESS LOOP",
    COUNTRYCODE: "AUWA",
    COUNTRYNAME: "WESTERN AUSTRALIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "USM",
    AIRPORTNAME: "Koh Samui",
    CITYCODE: "USM",
    CITYNAME: "KOH SAMUI",
    CITYNAME_HEB: "\u05e7\u05d5 \u05e1\u05d0\u05de\u05d5\u05d9",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "USN",
    AIRPORTNAME: "Ulsan",
    CITYCODE: "USN",
    CITYNAME: "ULSAN",
    CITYNAME_HEB: "ULSAN",
    COUNTRYCODE: "KR",
    COUNTRYNAME: "KOREA REPUBLIC OF",
    COUNTRYNAME_HEB:
      "\u05e7\u05d5\u05e8\u05d9\u05d0\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "USO",
    AIRPORTNAME: "Usino",
    CITYCODE: "USO",
    CITYNAME: "USINO",
    CITYNAME_HEB: "USINO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "USQ",
    AIRPORTNAME: "Usak",
    CITYCODE: "USQ",
    CITYNAME: "USAK",
    CITYNAME_HEB: "USAK",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "USS",
    AIRPORTNAME: "Sancti Spiritus",
    CITYCODE: "USS",
    CITYNAME: "SANCTI SPIRITUS",
    CITYNAME_HEB: "SANCTI SPIRITUS",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "UST",
    AIRPORTNAME: "St Augustine",
    CITYCODE: "UST",
    CITYNAME: "SAINT AUGUSTINE",
    CITYNAME_HEB: "SAINT AUGUSTINE",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "USU",
    AIRPORTNAME: "Busuanga",
    CITYCODE: "USU",
    CITYNAME: "BUSUANGA",
    CITYNAME_HEB: "BUSUANGA",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "BOI",
    AIRPORTNAME: "Air Terminal Gowen Field",
    CITYCODE: "BOI",
    CITYNAME: "BOISE",
    CITYNAME_HEB: "BOISE",
    COUNTRYCODE: "USID",
    COUNTRYNAME: "IDAHO",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05d9\u05d3\u05d4\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UTA",
    AIRPORTNAME: "Mutare",
    CITYCODE: "UTA",
    CITYNAME: "MUTARE",
    CITYNAME_HEB: "MUTARE",
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: "\u05d6\u05d9\u05de\u05d1\u05d1\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "UTB",
    AIRPORTNAME: "Muttaburra",
    CITYCODE: "UTB",
    CITYNAME: "MUTTABURRA",
    CITYNAME_HEB: "MUTTABURRA",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UTC",
    AIRPORTNAME: "Soesterberg",
    CITYCODE: "UTC",
    CITYNAME: "UTRECHT",
    CITYNAME_HEB: "\u05d0\u05d5\u05d8\u05e8\u05db\u05d8",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "UTD",
    AIRPORTNAME: "Nutwood Downs",
    CITYCODE: "UTD",
    CITYNAME: "NUTWOOD DOWNS",
    CITYNAME_HEB: "NUTWOOD DOWNS",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UTE",
    AIRPORTNAME: "Butterworth",
    CITYCODE: "UTE",
    CITYNAME: "BUTTERWORTH",
    CITYNAME_HEB: "BUTTERWORTH",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "UTG",
    AIRPORTNAME: "Quthing",
    CITYCODE: "UTG",
    CITYNAME: "QUTHING",
    CITYNAME_HEB: "QUTHING",
    COUNTRYCODE: "LS",
    COUNTRYNAME: "LESOTHO",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05e1\u05d5\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "UTH",
    AIRPORTNAME: "Udon Thani",
    CITYCODE: "UTH",
    CITYNAME: "UDON THANI",
    CITYNAME_HEB: "UDON THANI",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "UTK",
    AIRPORTNAME: "Utirik Island",
    CITYCODE: "UTK",
    CITYNAME: "UTIRIK ISLAND",
    CITYNAME_HEB: "UTIRIK ISLAND",
    COUNTRYCODE: "MH",
    COUNTRYNAME: "MARSHALL ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05de\u05e8\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "UTL",
    AIRPORTNAME: "Torremolinos",
    CITYCODE: "UTL",
    CITYNAME: "TORREMOLINOS",
    CITYNAME_HEB:
      "\u05d8\u05d5\u05e8\u05de\u05d5\u05dc\u05d9\u05e0\u05d5\u05e1",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "UTN",
    AIRPORTNAME: "Upington",
    CITYCODE: "UTN",
    CITYNAME: "UPINGTON",
    CITYNAME_HEB: "UPINGTON",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "BOJ",
    AIRPORTNAME: "Bourgas",
    CITYCODE: "BOJ",
    CITYNAME: "BOURGAS",
    CITYNAME_HEB: "\u05d1\u05d5\u05e8\u05d2\u05e1",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UTO",
    AIRPORTNAME: "UTOPIA CREEK",
    CITYCODE: "UTO",
    CITYNAME: "UTOPIA CREEK",
    CITYNAME_HEB: "UTOPIA CREEK",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UTP",
    AIRPORTNAME: "Utapao",
    CITYCODE: "UTP",
    CITYNAME: "UTAPAO",
    CITYNAME_HEB: "UTAPAO",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "UTR",
    AIRPORTNAME: "Uttaradit",
    CITYCODE: "UTR",
    CITYNAME: "UTTARADIT",
    CITYNAME_HEB: "UTTARADIT",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "UTT",
    AIRPORTNAME: "Umtata",
    CITYCODE: "UTT",
    CITYNAME: "UMTATA",
    CITYNAME_HEB: "UMTATA",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "LJF",
    AIRPORTNAME: " ",
    CITYCODE: "LJF",
    CITYNAME: "LUHACOVICE",
    CITYNAME_HEB: "\u05dc\u05d5\u05d7\u05d0\u05e7\u05d5\u05d1\u05d9\u05e5",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UTU",
    AIRPORTNAME: "Ustupo",
    CITYCODE: "UTU",
    CITYNAME: "USTUPO",
    CITYNAME_HEB: "USTUPO",
    COUNTRYCODE: "PA",
    COUNTRYNAME: "PANAMA",
    COUNTRYNAME_HEB: "\u05e4\u05e0\u05de\u05d4",
  },
  {
    AIRPORTCODE: "UTW",
    AIRPORTNAME: "Queenstown",
    CITYCODE: "UTW",
    CITYNAME: "QUEENSTOWN",
    CITYNAME_HEB: "QUEENSTOWN",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "UUA",
    AIRPORTNAME: "Bugulma",
    CITYCODE: "UUA",
    CITYNAME: "BUGULMA",
    CITYNAME_HEB: "BUGULMA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UUD",
    AIRPORTNAME: "Ulan-Ude",
    CITYCODE: "UUD",
    CITYNAME: "ULAN UDE",
    CITYNAME_HEB: "ULAN UDE",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UUK",
    AIRPORTNAME: "Kuparuk",
    CITYCODE: "UUK",
    CITYNAME: "KUPARUK",
    CITYNAME_HEB: "Kuparuk",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UUN",
    AIRPORTNAME: "Baruun-Urt",
    CITYCODE: "UUN",
    CITYNAME: "BARUUN URT",
    CITYNAME_HEB: "BARUUN URT",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BOK",
    AIRPORTNAME: "Brookings State",
    CITYCODE: "BOK",
    CITYNAME: "BROOKINGS",
    CITYNAME_HEB: "BROOKINGS",
    COUNTRYCODE: "USOR",
    COUNTRYNAME: "OREGON",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e8\u05d5\u05d2\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UUS",
    AIRPORTNAME: "Yuzhno-Sakhalinsk",
    CITYCODE: "UUS",
    CITYNAME: "YUZHNO SAKHALINSK",
    CITYNAME_HEB: "YUZHNO SAKHALINSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UUU",
    AIRPORTNAME: "Manumu",
    CITYCODE: "UUU",
    CITYNAME: "MANUMU",
    CITYNAME_HEB: "MANUMU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "UVA",
    AIRPORTNAME: "Garner Fld",
    CITYCODE: "UVA",
    CITYNAME: "UVALDE",
    CITYNAME_HEB: "UVALDE",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "UVE",
    AIRPORTNAME: "Ouvea",
    CITYCODE: "UVE",
    CITYNAME: "OUVEA",
    CITYNAME_HEB: "OUVEA",
    COUNTRYCODE: "NC",
    COUNTRYNAME: "NEW CALEDONIA",
    COUNTRYNAME_HEB:
      "\u05e7\u05dc\u05d3\u05d5\u05e0\u05d9\u05d4 \u05d4\u05d7\u05d3\u05e9\u05d4",
  },
  {
    AIRPORTCODE: "UVO",
    AIRPORTNAME: "Uvol",
    CITYCODE: "UVO",
    CITYNAME: "UVOL",
    CITYNAME_HEB: "UVOL",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "UWA",
    AIRPORTNAME: "Ware",
    CITYCODE: "UWA",
    CITYNAME: "WARE",
    CITYNAME_HEB: "WARE",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "UWP",
    AIRPORTNAME: null,
    CITYCODE: "UWP",
    CITYNAME: "WUPPERTAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "UYL",
    AIRPORTNAME: "Nyala",
    CITYCODE: "UYL",
    CITYNAME: "NYALA",
    CITYNAME_HEB: "NYALA",
    COUNTRYCODE: "SD",
    COUNTRYNAME: "SUDAN",
    COUNTRYNAME_HEB: "\u05e1\u05d5\u05d3\u05df",
  },
  {
    AIRPORTCODE: "BOL",
    AIRPORTNAME: "Bally Kelly",
    CITYCODE: "BOL",
    CITYNAME: "BALLY KELLY",
    CITYNAME_HEB: "BALLY KELLY",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UYN",
    AIRPORTNAME: "Yulin",
    CITYCODE: "UYN",
    CITYNAME: "YULIN",
    CITYNAME_HEB: "YULIN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "UZC",
    AIRPORTNAME: null,
    CITYCODE: "UZC",
    CITYNAME: "UZICE",
    CITYNAME_HEB: "\u05d0\u05d5\u05d6\u05d9\u05e6\u05d4",
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ECD",
    AIRPORTNAME: null,
    CITYCODE: "ECD",
    CITYNAME: "ECUADOR",
    CITYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
    COUNTRYCODE: "EC",
    COUNTRYNAME: "ECUADOR",
    COUNTRYNAME_HEB: "\u05d0\u05e7\u05d5\u05d5\u05d3\u05d5\u05e8",
  },
  {
    AIRPORTCODE: "YES",
    AIRPORTNAME: null,
    CITYCODE: "YES",
    CITYNAME: "YESSENTUKI",
    CITYNAME_HEB: "\u05d9\u05d0\u05e1\u05e0\u05d8\u05d5\u05e7\u05d9",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GRU",
    AIRPORTNAME: "Guarulhos",
    CITYCODE: "SAO",
    CITYNAME: "SAO PAULO",
    CITYNAME_HEB: "\u05e1\u05d0\u05d5 \u05e4\u05d0\u05d5\u05dc\u05d5",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "ECU",
    AIRPORTNAME: null,
    CITYCODE: "ECU",
    CITYNAME: "EAST COAST USA",
    CITYNAME_HEB:
      "\u05d4\u05d7\u05d5\u05e3 \u05d4\u05de\u05d6\u05e8\u05d7\u05d9 \u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "AVA",
    CITYNAME: "AVALLON",
    CITYNAME_HEB: "\u05d0\u05d5\u05d5\u05d0\u05dc\u05d5\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "UZU",
    AIRPORTNAME: null,
    CITYCODE: "UZU",
    CITYNAME: "CURUZU CUATIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VAA",
    AIRPORTNAME: "Vaasa",
    CITYCODE: "VAA",
    CITYNAME: "VAASA",
    CITYNAME_HEB: "VAASA",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "VAB",
    AIRPORTNAME: "Yavarate",
    CITYCODE: "VAB",
    CITYNAME: "YAVARATE",
    CITYNAME_HEB: "YAVARATE",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VAC",
    AIRPORTNAME: "Varrelbusch",
    CITYCODE: "VAC",
    CITYNAME: "VARRELBUSCH",
    CITYNAME_HEB: "VARRELBUSCH",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VAF",
    AIRPORTNAME: "Chabeuil",
    CITYCODE: "VAF",
    CITYNAME: "VALENCE",
    CITYNAME_HEB: "VALENCE",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "VAG",
    AIRPORTNAME: null,
    CITYCODE: "VAG",
    CITYNAME: "VARGINHA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VAH",
    AIRPORTNAME: "Vallegrande",
    CITYCODE: "VAH",
    CITYNAME: "VALLEGRANDE",
    CITYNAME_HEB: "VALLEGRANDE",
    COUNTRYCODE: "BO",
    COUNTRYNAME: "BOLIVIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VAI",
    AIRPORTNAME: "Vanimo",
    CITYCODE: "VAI",
    CITYNAME: "VANIMO",
    CITYNAME_HEB: "VANIMO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BOM",
    AIRPORTNAME: "Chhatrapati Shivaji",
    CITYCODE: "BOM",
    CITYNAME: "MUMBAI",
    CITYNAME_HEB: "\u05de\u05d5\u05de\u05d1\u05d0\u05d9",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "VAK",
    AIRPORTNAME: "Chevak",
    CITYCODE: "VAK",
    CITYNAME: "CHEVAK",
    CITYNAME_HEB: "Chevak",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VTH",
    AIRPORTNAME: " ",
    CITYCODE: "VTH",
    CITYNAME: "VAL THORENS",
    CITYNAME_HEB: "\u05d5\u05d0\u05dc \u05d8\u05d5\u05e8\u05df",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "VAN",
    AIRPORTNAME: "Van",
    CITYCODE: "VAN",
    CITYNAME: "VAN",
    CITYNAME_HEB: "VAN",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VAO",
    AIRPORTNAME: "Suavanao Airstrip",
    CITYCODE: "VAO",
    CITYNAME: "SUAVANAO",
    CITYNAME_HEB: "SUAVANAO",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "VAP",
    AIRPORTNAME: "Valparaiso",
    CITYCODE: "VAP",
    CITYNAME: "VALPARAISO",
    CITYNAME_HEB: "VALPARAISO",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "VAR",
    AIRPORTNAME: "Varna",
    CITYCODE: "VAR",
    CITYNAME: "VARNA",
    CITYNAME_HEB: "\u05d5\u05e8\u05e0\u05d4",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VAS",
    AIRPORTNAME: "Sivas",
    CITYCODE: "VAS",
    CITYNAME: "SIVAS",
    CITYNAME_HEB: "SIVAS",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VAT",
    AIRPORTNAME: "Vatomandry",
    CITYCODE: "VAT",
    CITYNAME: "VATOMANDRY",
    CITYNAME_HEB: "VATOMANDRY",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "VAU",
    AIRPORTNAME: "Vatukoula",
    CITYCODE: "VAU",
    CITYNAME: "VATUKOULA",
    CITYNAME_HEB: "VATUKOULA",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "VAV",
    AIRPORTNAME: "Lupepau'u",
    CITYCODE: "VAV",
    CITYNAME: "VAVA'U",
    CITYNAME_HEB: "VAVA'U",
    COUNTRYCODE: "TO",
    COUNTRYNAME: "TONGA",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e0\u05d2\u05d4",
  },
  {
    AIRPORTCODE: "BON",
    AIRPORTNAME: " ",
    CITYCODE: "BON",
    CITYNAME: "BOSNIA",
    CITYNAME_HEB: "\u05d1\u05d5\u05e1\u05e0\u05d9\u05d4",
    COUNTRYCODE: "BA",
    COUNTRYNAME: "BOSNIA-HERZEGOVINA",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e1\u05e0\u05d9\u05d4 \u05d4\u05e8\u05e6\u05d2\u05d5\u05d1\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "VAW",
    AIRPORTNAME: "Vardoe",
    CITYCODE: "VAW",
    CITYNAME: "VARDOE",
    CITYNAME_HEB: "VARDOE",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VAZ",
    AIRPORTNAME: "Val D",
    CITYCODE: "VAZ",
    CITYNAME: "VAL DISERE",
    CITYNAME_HEB: "\u05d5\u05d0\u05dc \u05d3\u05d9\u05d6\u05d0\u05e8",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "VBV",
    AIRPORTNAME: "Vanuabalavu",
    CITYCODE: "VBV",
    CITYNAME: "VANUABALAVU",
    CITYNAME_HEB: "VANUABALAVU",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "VBY",
    AIRPORTNAME: "Visby",
    CITYCODE: "VBY",
    CITYNAME: "VISBY",
    CITYNAME_HEB: "\u05d5\u05d9\u05e1\u05d1\u05d9",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VCA",
    AIRPORTNAME: "Can Tho",
    CITYCODE: "VCA",
    CITYNAME: "CAN THO",
    CITYNAME_HEB: "\u05e7\u05d0\u05e0\u05d8\u05d7\u05d5",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "VCB",
    AIRPORTNAME: "View Cove",
    CITYCODE: "VCB",
    CITYNAME: "VIEW COVE",
    CITYNAME_HEB: "View Cove",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VCC",
    AIRPORTNAME: "Limbe",
    CITYCODE: "VCC",
    CITYNAME: "LIMBE",
    CITYNAME_HEB: "LIMBE",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "VCD",
    AIRPORTNAME: "Victoria River Downs",
    CITYCODE: "VCD",
    CITYNAME: "VICTORIA RIVER DO",
    CITYNAME_HEB: "VICTORIA RIVER DO",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BOO",
    AIRPORTNAME: "Bodo",
    CITYCODE: "BOO",
    CITYNAME: "BODO",
    CITYNAME_HEB: "BODO",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VCF",
    AIRPORTNAME: null,
    CITYCODE: "VCF",
    CITYNAME: "VALCHETA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VCH",
    AIRPORTNAME: "Vichadero",
    CITYCODE: "VCH",
    CITYNAME: "VICHADERO",
    CITYNAME_HEB: "VICHADERO",
    COUNTRYCODE: "UY",
    COUNTRYNAME: "URUGUAY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e8\u05d5\u05d2\u05d0\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "VCR",
    AIRPORTNAME: "Carora",
    CITYCODE: "VCR",
    CITYNAME: "CARORA",
    CITYNAME_HEB: "CARORA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "VCT",
    AIRPORTNAME: "County-Foster",
    CITYCODE: "VCT",
    CITYNAME: "VICTORIA",
    CITYNAME_HEB: "VICTORIA",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VCV",
    AIRPORTNAME: "George AFB",
    CITYCODE: "VCV",
    CITYNAME: "VICTORVILLE",
    CITYNAME_HEB: "VICTORVILLE",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VDA",
    AIRPORTNAME: null,
    CITYCODE: "VDA",
    CITYNAME: "OVDA",
    CITYNAME_HEB: "\u05e2\u05d5\u05d1\u05d3\u05d4",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "VDB",
    AIRPORTNAME: "Valdres",
    CITYCODE: "VDB",
    CITYNAME: "FAGERNES",
    CITYNAME_HEB: "FAGERNES",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VDC",
    AIRPORTNAME: null,
    CITYCODE: "VDC",
    CITYNAME: "VITORIA DA CONQUISTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VDE",
    AIRPORTNAME: "Hierro",
    CITYCODE: "VDE",
    CITYNAME: "VALVERDE",
    CITYNAME_HEB: "VALVERDE",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "VDI",
    AIRPORTNAME: "Municipal",
    CITYCODE: "VDI",
    CITYNAME: "VIDALIA",
    CITYNAME_HEB: "VIDALIA",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "BOP",
    AIRPORTNAME: " ",
    CITYCODE: "BOP",
    CITYNAME: "BOPPARD",
    CITYNAME_HEB: "\u05d1\u05d5\u05e4\u05d0\u05e8\u05d3",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VDM",
    AIRPORTNAME: null,
    CITYCODE: "VDM",
    CITYNAME: "VIEDMA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VDP",
    AIRPORTNAME: "Valle De Pascua",
    CITYCODE: "VDP",
    CITYNAME: "VALLE DE PASCUA",
    CITYNAME_HEB: "VALLE DE PASCUA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "VDR",
    AIRPORTNAME: null,
    CITYCODE: "VDR",
    CITYNAME: "VILLA DOLORES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VDS",
    AIRPORTNAME: "Vadso",
    CITYCODE: "VDS",
    CITYNAME: "VADSO",
    CITYNAME_HEB: "VADSO",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VDZ",
    AIRPORTNAME: "VALDEZ",
    CITYCODE: "VDZ",
    CITYNAME: "VALDEZ-ALASKA",
    CITYNAME_HEB:
      "\u05d5\u05d5\u05d0\u05dc\u05d3\u05d6-\u05d0\u05dc\u05e1\u05e7\u05d4",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "VEE",
    AIRPORTNAME: "Venetie",
    CITYCODE: "VEE",
    CITYNAME: "VENETIE",
    CITYNAME_HEB: "Venetie",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VEG",
    AIRPORTNAME: "Maikwak",
    CITYCODE: "VEG",
    CITYNAME: "MAIKWAK",
    CITYNAME_HEB: "MAIKWAK",
    COUNTRYCODE: "GY",
    COUNTRYNAME: "GUYANA",
    COUNTRYNAME_HEB: "\u05d2\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "VEJ",
    AIRPORTNAME: "vejle",
    CITYCODE: "VEJ",
    CITYNAME: "VEJLE",
    CITYNAME_HEB: "VEJLE",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "XVX",
    AIRPORTNAME: null,
    CITYCODE: "VEJ",
    CITYNAME: "VEJLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VEL",
    AIRPORTNAME: "Vernal",
    CITYCODE: "VEL",
    CITYNAME: "VERNAL",
    CITYNAME_HEB: "VERNAL",
    COUNTRYCODE: "USUT",
    COUNTRYNAME: "UTAH",
    COUNTRYNAME_HEB: '\u05d9\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BOQ",
    AIRPORTNAME: "Boku",
    CITYCODE: "BOQ",
    CITYNAME: "BOKU",
    CITYNAME_HEB: "BOKU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "VER",
    AIRPORTNAME: "Las Bajadas",
    CITYCODE: "VER",
    CITYNAME: "VERACRUZ",
    CITYNAME_HEB: "VERACRUZ",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "VEV",
    AIRPORTNAME: "Barakoma",
    CITYCODE: "VEV",
    CITYNAME: "BARAKOMA",
    CITYNAME_HEB: "BARAKOMA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "VEX",
    AIRPORTNAME: "Municipal",
    CITYCODE: "VEX",
    CITYNAME: "TIOGA",
    CITYNAME_HEB: "TIOGA",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VEY",
    AIRPORTNAME: "Vestmannaeyjar",
    CITYCODE: "VEY",
    CITYNAME: "VESTMANNAEYJAR",
    CITYNAME_HEB: "VESTMANNAEYJAR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "VFA",
    AIRPORTNAME: "Victoria Falls",
    CITYCODE: "VFA",
    CITYNAME: "VICTORIA FALLS",
    CITYNAME_HEB:
      "\u05de\u05e4\u05dc\u05d9 \u05d5\u05d9\u05e7\u05d8\u05d5\u05e8\u05d9\u05d4",
    COUNTRYCODE: "ZW",
    COUNTRYNAME: "ZIMBABWE",
    COUNTRYNAME_HEB: "\u05d6\u05d9\u05de\u05d1\u05d1\u05d5\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "VGA",
    AIRPORTNAME: "Vijayawada",
    CITYCODE: "VGA",
    CITYNAME: "VIJAYAWADA",
    CITYNAME_HEB: "VIJAYAWADA",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "VGD",
    AIRPORTNAME: "Vologda",
    CITYCODE: "VGD",
    CITYNAME: "VOLOGDA",
    CITYNAME_HEB: "VOLOGDA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VGG",
    AIRPORTNAME: "Vangrieng",
    CITYCODE: "VGG",
    CITYNAME: "VANGRIENG",
    CITYNAME_HEB: "VANGRIENG",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "VGO",
    AIRPORTNAME: "Vigo",
    CITYCODE: "VGO",
    CITYNAME: "VIGO",
    CITYNAME_HEB: "\u05d5\u05d9\u05d2\u05d5",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "VGS",
    AIRPORTNAME: null,
    CITYCODE: "VGS",
    CITYNAME: "GENERAL VILLEGAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ADY",
    AIRPORTNAME: "Alldays",
    CITYCODE: "ADY",
    CITYNAME: "ALLDAYS",
    CITYNAME_HEB: "ALLDAYS",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "BOR",
    AIRPORTNAME: " ",
    CITYCODE: "BOR",
    CITYNAME: "BOROVETS",
    CITYNAME_HEB: "\u05d1\u05d5\u05e8\u05d5\u05d1\u05e5",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VGZ",
    AIRPORTNAME: "Villagarzon",
    CITYCODE: "VGZ",
    CITYNAME: "VILLAGARZON",
    CITYNAME_HEB: "VILLAGARZON",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VHC",
    AIRPORTNAME: "Saurimo",
    CITYCODE: "VHC",
    CITYNAME: "SAURIMO",
    CITYNAME_HEB: "SAURIMO",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "VHM",
    AIRPORTNAME: "Vilhelmina",
    CITYCODE: "VHM",
    CITYNAME: "VILHELMINA",
    CITYNAME_HEB: "VILHELMINA",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VHN",
    AIRPORTNAME: "Culberson County",
    CITYCODE: "VHN",
    CITYNAME: "VAN HORN",
    CITYNAME_HEB: "VAN HORN",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VHY",
    AIRPORTNAME: "Charmeil",
    CITYCODE: "VHY",
    CITYNAME: "VICHY",
    CITYNAME_HEB: "VICHY",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "VHZ",
    AIRPORTNAME: "Vahitahi",
    CITYCODE: "VHZ",
    CITYNAME: "VAHITAHI",
    CITYNAME_HEB: "VAHITAHI",
    COUNTRYCODE: "PF",
    COUNTRYNAME: "FRENCH POLYNESIA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05d9\u05e0\u05d9\u05d6\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "VIA",
    AIRPORTNAME: null,
    CITYCODE: "VIA",
    CITYNAME: "VIDEIRA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VIB",
    AIRPORTNAME: "Villa Constitucion",
    CITYCODE: "VIB",
    CITYNAME: "VILLA CONSTITUCION",
    CITYNAME_HEB: "VILLA CONSTITUCION",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "VIC",
    AIRPORTNAME: "Vicenza",
    CITYCODE: "VIC",
    CITYNAME: "VICENZA",
    CITYNAME_HEB: "\u05d5\u05d9\u05e6\u05d6\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VID",
    AIRPORTNAME: "Vidin",
    CITYCODE: "VID",
    CITYNAME: "VIDIN",
    CITYNAME_HEB: "\u05d5\u05d9\u05d3\u05d9\u05df",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BOS",
    AIRPORTNAME: "Logan International",
    CITYCODE: "BOS",
    CITYNAME: "BOSTON",
    CITYNAME_HEB: "\u05d1\u05d5\u05e1\u05d8\u05d5\u05df",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "VIE",
    AIRPORTNAME: "Wien-Schwechat International",
    CITYCODE: "VIE",
    CITYNAME: "VIENNA",
    CITYNAME_HEB: "\u05d5\u05d9\u05e0\u05d4",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VIF",
    AIRPORTNAME: "Vieste",
    CITYCODE: "VIF",
    CITYNAME: "VIESTE",
    CITYNAME_HEB: "VIESTE",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VIG",
    AIRPORTNAME: "El Vigia",
    CITYCODE: "VIG",
    CITYNAME: "EL VIGIA",
    CITYNAME_HEB: "EL VIGIA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "VIH",
    AIRPORTNAME: "Rolla National",
    CITYCODE: "VIH",
    CITYNAME: "VICHY",
    CITYNAME_HEB: "VICHY",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VII",
    AIRPORTNAME: "Vinh City",
    CITYCODE: "VII",
    CITYNAME: "VINH CITY",
    CITYNAME_HEB: "VINH CITY",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "VIJ",
    AIRPORTNAME: "Virgin Gorda",
    CITYCODE: "VIJ",
    CITYNAME: "VIRGIN GORDA",
    CITYNAME_HEB: "VIRGIN GORDA",
    COUNTRYCODE: "VG",
    COUNTRYNAME: "VIRGIN ISLANDS-BRITISH",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d9 \u05d4\u05d1\u05ea\u05d5\u05dc\u05d4 \u05d4\u05d1\u05e8\u05d9\u05d8\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "VIK",
    AIRPORTNAME: "Airstrip",
    CITYCODE: "VIK",
    CITYNAME: "KAVIK",
    CITYNAME_HEB: "Airstrip",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VIL",
    AIRPORTNAME: "Dakhla",
    CITYCODE: "VIL",
    CITYNAME: "DAKHLA",
    CITYNAME_HEB: "DAKHLA",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "VIN",
    AIRPORTNAME: "Vinnica",
    CITYCODE: "VIN",
    CITYNAME: "VINNICA",
    CITYNAME_HEB: "\u05d5\u05d9\u05e0\u05d9\u05e6\u05d4",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "GIU",
    AIRPORTNAME: null,
    CITYCODE: "GIU",
    CITYNAME: "SIGIRIYA",
    CITYNAME_HEB: "\u05e1\u05d9\u05d2\u05d9\u05e8\u05d9\u05d4",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "KDK",
    AIRPORTNAME: " ",
    CITYCODE: "KDK",
    CITYNAME: "KATWOUDE",
    CITYNAME_HEB: "\u05e7\u05d0\u05d8\u05d5\u05d5\u05d3",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "GKI",
    AIRPORTNAME: " ",
    CITYCODE: "GKI",
    CITYNAME: "KATERINI",
    CITYNAME_HEB: "\u05e7\u05d8\u05e8\u05d9\u05e0\u05d9",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "FJO",
    AIRPORTNAME: null,
    CITYCODE: "FJO",
    CITYNAME: "NORWEGIAN FJORDS",
    CITYNAME_HEB:
      "\u05d4\u05e4\u05d9\u05d5\u05e8\u05d3\u05d9\u05dd \u05d4\u05e0\u05d5\u05e8\u05d1\u05d2\u05d9\u05dd",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BXF",
    AIRPORTNAME: null,
    CITYCODE: "BXF",
    CITYNAME: "BAILE OLANESTI",
    CITYNAME_HEB:
      "\u05d1\u05d9\u05d9\u05dc\u05d4 \u05d0\u05d5\u05dc\u05e0\u05e1\u05d8\u05d9",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HGX",
    AIRPORTNAME: null,
    CITYCODE: "HGX",
    CITYNAME: "HUANGLONGXI",
    CITYNAME_HEB:
      "\u05d7\u05d5\u05d0\u05e0\u05d2\u05dc\u05d5\u05e0\u05d2\u05e9\u05d9",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "VIQ",
    AIRPORTNAME: "Viqueque",
    CITYCODE: "VIQ",
    CITYNAME: "VIQUEQUE",
    CITYNAME_HEB: "VIQUEQUE",
    COUNTRYCODE: "TP",
    COUNTRYNAME: "EAST TIMOR",
    COUNTRYNAME_HEB:
      "\u05d8\u05d9\u05de\u05d5\u05e8 \u05d4\u05de\u05d6\u05e8\u05d7\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "VIS",
    AIRPORTNAME: "Visalia",
    CITYCODE: "VIS",
    CITYNAME: "VISALIA",
    CITYNAME_HEB: "VISALIA",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VIT",
    AIRPORTNAME: "Vitoria",
    CITYCODE: "VIT",
    CITYNAME: "VITORIA",
    CITYNAME_HEB: "\u05d5\u05d9\u05d8\u05d5\u05e8\u05d9\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "VIU",
    AIRPORTNAME: "Viru Harbour Airstrip",
    CITYCODE: "VIU",
    CITYNAME: "VIRU",
    CITYNAME_HEB: "VIRU",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "VIV",
    AIRPORTNAME: "Vivigani",
    CITYCODE: "VIV",
    CITYNAME: "VIVIGANI",
    CITYNAME_HEB: "VIVIGANI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "VIX",
    AIRPORTNAME: null,
    CITYCODE: "VIX",
    CITYNAME: "VITORIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VIZ",
    AIRPORTNAME: null,
    CITYCODE: "VIZ",
    CITYNAME: "VIZAG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VJB",
    AIRPORTNAME: "Xai Xai",
    CITYCODE: "VJB",
    CITYNAME: "XAI XAI",
    CITYNAME_HEB: "XAI XAI",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "VJI",
    AIRPORTNAME: "Virginia Highlands",
    CITYCODE: "VJI",
    CITYNAME: "ABINGDON",
    CITYNAME_HEB: "ABINGDON",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "VJQ",
    AIRPORTNAME: "Gurue",
    CITYCODE: "VJQ",
    CITYNAME: "GURUE",
    CITYNAME_HEB: "GURUE",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "VKB",
    AIRPORTNAME: " ",
    CITYCODE: "VKB",
    CITYNAME: "MERANO",
    CITYNAME_HEB: "\u05de\u05e8\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VKG",
    AIRPORTNAME: "Rach Gia",
    CITYCODE: "VKG",
    CITYNAME: "RACH GIA",
    CITYNAME_HEB: "RACH GIA",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "VKS",
    AIRPORTNAME: "Vicksburg",
    CITYCODE: "VKS",
    CITYNAME: "VICKSBURG",
    CITYNAME_HEB: "VICKSBURG",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VKT",
    AIRPORTNAME: "Vorkuta",
    CITYCODE: "VKT",
    CITYNAME: "VORKUTA",
    CITYNAME_HEB: "VORKUTA",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VKW",
    AIRPORTNAME: "West Kavik",
    CITYCODE: "VKW",
    CITYNAME: "WEST KAVIK",
    CITYNAME_HEB: "West Kavik",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VLA",
    AIRPORTNAME: "Vandalia",
    CITYCODE: "VLA",
    CITYNAME: "VANDALIA",
    CITYNAME_HEB: "VANDALIA",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VLC",
    AIRPORTNAME: "Valencia",
    CITYCODE: "VLC",
    CITYNAME: "VALENCIA",
    CITYNAME_HEB: "\u05d5\u05dc\u05e0\u05e1\u05d9\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "VAD",
    AIRPORTNAME: null,
    CITYCODE: "VLD",
    CITYNAME: "VALDOSTA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VLD",
    AIRPORTNAME: "Regional",
    CITYCODE: "VLD",
    CITYNAME: "VALDOSTA",
    CITYNAME_HEB: "VALDOSTA",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "VLE",
    AIRPORTNAME: "J t Robidoux",
    CITYCODE: "VLE",
    CITYNAME: "VALLE",
    CITYNAME_HEB: "VALLE",
    COUNTRYCODE: "USAZ",
    COUNTRYNAME: "ARIZONA",
    COUNTRYNAME_HEB:
      '\u05d0\u05e8\u05d9\u05d6\u05d5\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VLF",
    AIRPORTNAME: null,
    CITYCODE: "VLF",
    CITYNAME: "NEWCASTLE UNDER LYME",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VLG",
    AIRPORTNAME: null,
    CITYCODE: "VLG",
    CITYNAME: "VILLA GESELL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VLI",
    AIRPORTNAME: "Bauerfield",
    CITYCODE: "VLI",
    CITYNAME: "PORT VILA",
    CITYNAME_HEB: "PORT VILA",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "VLK",
    AIRPORTNAME: "Volgodonsk",
    CITYCODE: "VLK",
    CITYNAME: "VOLGODONSK",
    CITYNAME_HEB: "VOLGODONSK",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VLL",
    AIRPORTNAME: "Valladolid Airport",
    CITYCODE: "VLL",
    CITYNAME: "VALLADOLID",
    CITYNAME_HEB: "VALLADOLID",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "VLM",
    AIRPORTNAME: null,
    CITYCODE: "VLM",
    CITYNAME: "VALMOREL LA BELLE",
    CITYNAME_HEB:
      "\u05d5\u05d0\u05dc\u05de\u05d5\u05e8 \u05dc\u05d4 \u05d1\u05dc\u05d4",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VLN",
    AIRPORTNAME: "Valencia",
    CITYCODE: "VLN",
    CITYNAME: "VALENCIA",
    CITYNAME_HEB: "VALENCIA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "VLO",
    AIRPORTNAME: "Stolport",
    CITYCODE: "VLO",
    CITYNAME: "VALLEJO",
    CITYNAME_HEB: "VALLEJO",
    COUNTRYCODE: "USCA",
    COUNTRYNAME: "CALIFORNIA",
    COUNTRYNAME_HEB:
      '\u05e7\u05dc\u05d9\u05e4\u05d5\u05e8\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VLP",
    AIRPORTNAME: null,
    CITYCODE: "VLP",
    CITYNAME: "VILA RICA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VLR",
    AIRPORTNAME: "Vallenar",
    CITYCODE: "VLR",
    CITYNAME: "VALLENAR",
    CITYNAME_HEB: "VALLENAR",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "VLS",
    AIRPORTNAME: "Valesdir",
    CITYCODE: "VLS",
    CITYNAME: "VALESDIR",
    CITYNAME_HEB: "VALESDIR",
    COUNTRYCODE: "VU",
    COUNTRYNAME: "VANUATU",
    COUNTRYNAME_HEB: "\u05d5\u05d0\u05e0\u05d5\u05d0\u05d8\u05d5",
  },
  {
    AIRPORTCODE: "VLU",
    AIRPORTNAME: "Velikiye Luki",
    CITYCODE: "VLU",
    CITYNAME: "VELIKIYE LUKI",
    CITYNAME_HEB: "VELIKIYE LUKI",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VLV",
    AIRPORTNAME: "Carvajal",
    CITYCODE: "VLV",
    CITYNAME: "VALERA",
    CITYNAME_HEB: "VALERA",
    COUNTRYCODE: "VE",
    COUNTRYNAME: "VENEZUELA",
    COUNTRYNAME_HEB: "\u05d5\u05e0\u05e6\u05d5\u05d0\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "VME",
    AIRPORTNAME: null,
    CITYCODE: "VME",
    CITYNAME: "VILLA MERCEDES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VMI",
    AIRPORTNAME: "INC",
    CITYCODE: "VMI",
    CITYNAME: "VALLEMI",
    CITYNAME_HEB: "VALLEMI",
    COUNTRYCODE: "PY",
    COUNTRYNAME: "PARAGUAY",
    COUNTRYNAME_HEB: "\u05e4\u05d0\u05e8\u05d0\u05d2\u05d5\u05d5\u05d9",
  },
  {
    AIRPORTCODE: "VMU",
    AIRPORTNAME: "Baimuru",
    CITYCODE: "VMU",
    CITYNAME: "BAIMURU",
    CITYNAME_HEB: "BAIMURU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "VNA",
    AIRPORTNAME: "Saravane",
    CITYCODE: "VNA",
    CITYNAME: "SARAVANE",
    CITYNAME_HEB: "SARAVANE",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "VNC",
    AIRPORTNAME: "Venice",
    CITYCODE: "VNC",
    CITYNAME: "VENICE",
    CITYNAME_HEB: "VENICE",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VND",
    AIRPORTNAME: "Vangaindrano",
    CITYCODE: "VND",
    CITYNAME: "VANGAINDRANO",
    CITYNAME_HEB: "VANGAINDRANO",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "VNE",
    AIRPORTNAME: "Meucon",
    CITYCODE: "VNE",
    CITYNAME: "VANNES",
    CITYNAME_HEB: "VANNES",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "BOT",
    AIRPORTNAME: "Boset",
    CITYCODE: "BOT",
    CITYNAME: "BOSET",
    CITYNAME_HEB: "BOSET",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "VNG",
    AIRPORTNAME: "Viengxay",
    CITYCODE: "VNG",
    CITYNAME: "VIENGXAY",
    CITYNAME_HEB: "VIENGXAY",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAO PEOPLE'S DEM REPUBLI",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "VNO",
    AIRPORTNAME: "Vilnius",
    CITYCODE: "VNO",
    CITYNAME: "VILNIUS",
    CITYNAME_HEB: "\u05d5\u05d9\u05dc\u05e0\u05d4",
    COUNTRYCODE: "LT",
    COUNTRYNAME: "LITHUANIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d8\u05d0",
  },
  {
    AIRPORTCODE: "VNR",
    AIRPORTNAME: "Vanrook",
    CITYCODE: "VNR",
    CITYNAME: "VANROOK",
    CITYNAME_HEB: "VANROOK",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VNS",
    AIRPORTNAME: "Varanasi",
    CITYCODE: "VNS",
    CITYNAME: "VARANASI",
    CITYNAME_HEB: "\u05d5\u05e8\u05e0\u05d0\u05e1\u05d9",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "VNX",
    AIRPORTNAME: "Vilanculos",
    CITYCODE: "VNX",
    CITYNAME: "VILANCULOS",
    CITYNAME_HEB: "VILANCULOS",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "VOG",
    AIRPORTNAME: "Volgograd",
    CITYCODE: "VOG",
    CITYNAME: "VOLGOGRAD",
    CITYNAME_HEB: "VOLGOGRAD",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VOH",
    AIRPORTNAME: "Vohemar",
    CITYCODE: "VOH",
    CITYNAME: "VOHEMAR",
    CITYNAME_HEB: "VOHEMAR",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "VOI",
    AIRPORTNAME: "Voinjama",
    CITYCODE: "VOI",
    CITYNAME: "VOINJAMA",
    CITYNAME_HEB: "VOINJAMA",
    COUNTRYCODE: "LR",
    COUNTRYNAME: "LIBERIA",
    COUNTRYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VOK",
    AIRPORTNAME: "Volk Field",
    CITYCODE: "VOK",
    CITYNAME: "CAMP DOUGLAS",
    CITYNAME_HEB: "CAMP DOUGLAS",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VOL",
    AIRPORTNAME: "Nea Anchialos",
    CITYCODE: "VOL",
    CITYNAME: "VOLOS",
    CITYNAME_HEB: "\u05d5\u05d5\u05dc\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "BOU",
    AIRPORTNAME: "Bourges",
    CITYCODE: "BOU",
    CITYNAME: "BOURGES",
    CITYNAME_HEB: "BOURGES",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "VOT",
    AIRPORTNAME: null,
    CITYCODE: "VOT",
    CITYNAME: "VOTUPORANGA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VOZ",
    AIRPORTNAME: "Voronezh",
    CITYCODE: "VOZ",
    CITYNAME: "VORONEZH",
    CITYNAME_HEB: "VORONEZH",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VPE",
    AIRPORTNAME: "Ongiva",
    CITYCODE: "VPE",
    CITYNAME: "ONGIVA",
    CITYNAME_HEB: "ONGIVA",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "VPN",
    AIRPORTNAME: "Vopnafjordur",
    CITYCODE: "VPN",
    CITYNAME: "VOPNAFJORDUR",
    CITYNAME_HEB: "VOPNAFJORDUR",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "EGI",
    AIRPORTNAME: "Duke Field",
    CITYCODE: "VPS",
    CITYNAME: "VALPARAISO",
    CITYNAME_HEB: "VALPARAISO",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VPS",
    AIRPORTNAME: "Ft. Walton Beach",
    CITYCODE: "VPS",
    CITYNAME: "VALPARAISO",
    CITYNAME_HEB: "VALPARAISO",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VPY",
    AIRPORTNAME: "Chimoio",
    CITYCODE: "VPY",
    CITYNAME: "CHIMOIO",
    CITYNAME_HEB: "CHIMOIO",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "VPZ",
    AIRPORTNAME: "Porter County",
    CITYCODE: "VPZ",
    CITYNAME: "VALPARAISO",
    CITYNAME_HEB: "VALPARAISO",
    COUNTRYCODE: "USIN",
    COUNTRYNAME: "INDIANA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05e0\u05d3\u05d9\u05d0\u05e0\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VQS",
    AIRPORTNAME: "Vieques",
    CITYCODE: "VQS",
    CITYNAME: "VIEQUES",
    CITYNAME_HEB: "VIEQUES",
    COUNTRYCODE: "PR",
    COUNTRYNAME: "PUERTO RICO",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e8\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "VRA",
    AIRPORTNAME: "Juan Gualberto Gomez",
    CITYCODE: "VRA",
    CITYNAME: "VARADERO",
    CITYNAME_HEB: "\u05d5\u05d0\u05e8\u05d3\u05e8\u05d5",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "BOV",
    AIRPORTNAME: "Boang",
    CITYCODE: "BOV",
    CITYNAME: "BOANG",
    CITYNAME_HEB: "BOANG",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "VRB",
    AIRPORTNAME: "Municipal",
    CITYCODE: "VRB",
    CITYNAME: "VERO BEACH",
    CITYNAME_HEB: "VERO BEACH",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VRC",
    AIRPORTNAME: "Virac",
    CITYCODE: "VRC",
    CITYNAME: "VIRAC",
    CITYNAME_HEB: "VIRAC",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "VRE",
    AIRPORTNAME: "Vredendal",
    CITYCODE: "VRE",
    CITYNAME: "VREDENDAL",
    CITYNAME_HEB: "VREDENDAL",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "VRK",
    AIRPORTNAME: "Varkaus",
    CITYCODE: "VRK",
    CITYNAME: "VARKAUS",
    CITYNAME_HEB: "VARKAUS",
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05e0\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "VRL",
    AIRPORTNAME: "Vila Real",
    CITYCODE: "VRL",
    CITYNAME: "VILA REAL",
    CITYNAME_HEB: "VILA REAL",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "VBS",
    AIRPORTNAME: "Montichiari",
    CITYCODE: "VBS",
    CITYNAME: "BRESCIA",
    CITYNAME_HEB: "\u05d1\u05e8\u05e9\u05d9\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VRN",
    AIRPORTNAME: "Verona",
    CITYCODE: "VRN",
    CITYNAME: "VERONA",
    CITYNAME_HEB: "\u05d5\u05e8\u05d5\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VRS",
    AIRPORTNAME: "Versailles",
    CITYCODE: "VRS",
    CITYNAME: "VERSAILLES",
    CITYNAME_HEB: "VERSAILLES",
    COUNTRYCODE: "USMO",
    COUNTRYNAME: "MISSOURI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05d6\u05d5\u05e8\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VRU",
    AIRPORTNAME: "Vryburg",
    CITYCODE: "VRU",
    CITYNAME: "VRYBURG",
    CITYNAME_HEB: "VRYBURG",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "BOW",
    AIRPORTNAME: "Bartow",
    CITYCODE: "BOW",
    CITYNAME: "BARTOW",
    CITYNAME_HEB: "BARTOW",
    COUNTRYCODE: "USFL",
    COUNTRYNAME: "FLORIDA",
    COUNTRYNAME_HEB:
      '\u05e4\u05dc\u05d5\u05e8\u05d9\u05d3\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VRY",
    AIRPORTNAME: "Stolport",
    CITYCODE: "VRY",
    CITYNAME: "VAEROY",
    CITYNAME_HEB: "VAEROY",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VSA",
    AIRPORTNAME: "Capitan Carlos Perez",
    CITYCODE: "VSA",
    CITYNAME: "VILLAHERMOSA",
    CITYNAME_HEB: "VILLAHERMOSA",
    COUNTRYCODE: "MX",
    COUNTRYNAME: "MEXICO",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05e1\u05d9\u05e7\u05d5",
  },
  {
    AIRPORTCODE: "VSE",
    AIRPORTNAME: "Viseu",
    CITYCODE: "VSE",
    CITYNAME: "VISEU",
    CITYNAME_HEB: "VISEU",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "VSF",
    AIRPORTNAME: "State",
    CITYCODE: "VSF",
    CITYNAME: "SPRINGFIELD",
    CITYNAME_HEB: "SPRINGFIELD",
    COUNTRYCODE: "USVT",
    COUNTRYNAME: "VERMONT",
    COUNTRYNAME_HEB:
      '\u05d5\u05d5\u05e8\u05de\u05d5\u05e0\u05d8 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VSG",
    AIRPORTNAME: "Lugansk",
    CITYCODE: "VSG",
    CITYNAME: "LUGANSK",
    CITYNAME_HEB: "LUGANSK",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "VSO",
    AIRPORTNAME: "Phuoclong",
    CITYCODE: "VSO",
    CITYNAME: "PHUOCLONG",
    CITYNAME_HEB: "PHUOCLONG",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "VST",
    AIRPORTNAME: "Hasslo",
    CITYCODE: "VST",
    CITYNAME: "VASTERAS",
    CITYNAME_HEB: "VASTERAS",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VTA",
    AIRPORTNAME: "Victoria",
    CITYCODE: "VTA",
    CITYNAME: "VICTORIA",
    CITYNAME_HEB: "VICTORIA",
    COUNTRYCODE: "HN",
    COUNTRYNAME: "HONDURAS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05e0\u05d3\u05d5\u05e8\u05e1",
  },
  {
    AIRPORTCODE: "VTB",
    AIRPORTNAME: "Vitebsk",
    CITYCODE: "VTB",
    CITYNAME: "VITEBSK",
    CITYNAME_HEB: "VITEBSK",
    COUNTRYCODE: "BY",
    COUNTRYNAME: "BELARUS",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d0\u05e8\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "BWV",
    AIRPORTNAME: " ",
    CITYCODE: "BWV",
    CITYNAME: "BERUWELA",
    CITYNAME_HEB: "\u05d1\u05e8\u05d5\u05d5\u05d0\u05dc\u05d4",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "LRP",
    AIRPORTNAME: " ",
    CITYCODE: "LRP",
    CITYNAME: "LIBEREC",
    CITYNAME_HEB: "\u05dc\u05d9\u05d1\u05e8\u05da",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BZQ",
    AIRPORTNAME: null,
    CITYCODE: "BZQ",
    CITYNAME: "BUSKO ZDROJ",
    CITYNAME_HEB:
      "\u05d1\u05d5\u05e1\u05e7\u05d5-\u05d6\u05d3\u05e8\u05d5\u05d9",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "TJE",
    AIRPORTNAME: null,
    CITYCODE: "TJE",
    CITYNAME: "TREBINJE",
    CITYNAME_HEB: "\u05d8\u05e8\u05d1\u05d9\u05e0\u05d9\u05d9\u05d4",
    COUNTRYCODE: "BA",
    COUNTRYNAME: "BOSNIA-HERZEGOVINA",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e1\u05e0\u05d9\u05d4 \u05d4\u05e8\u05e6\u05d2\u05d5\u05d1\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "LUZ",
    AIRPORTNAME: null,
    CITYCODE: "LUZ",
    CITYNAME: "LUBLIN",
    CITYNAME_HEB: "\u05dc\u05d5\u05d1\u05dc\u05d9\u05df",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "RZV",
    AIRPORTNAME: "Rize-Artvin Airport",
    CITYCODE: "RZV",
    CITYNAME: "Rize",
    CITYNAME_HEB: null,
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VTE",
    AIRPORTNAME: "Wattay",
    CITYCODE: "VTE",
    CITYNAME: "VIENTIANE",
    CITYNAME_HEB: "\u05d5\u05d9\u05d9\u05e0\u05d8\u05d9\u05d0\u05df",
    COUNTRYCODE: "LA",
    COUNTRYNAME: "LAOS",
    COUNTRYNAME_HEB: "\u05dc\u05d0\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "ADZ",
    AIRPORTNAME: "Sesquicentenario International Airport",
    CITYCODE: "ADZ",
    CITYNAME: "SAN ANDRES ISLAND",
    CITYNAME_HEB: "SAN ANDRES ISLAND",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BOX",
    AIRPORTNAME: "Borroloola",
    CITYCODE: "BOX",
    CITYNAME: "BORROLOOLA",
    CITYNAME_HEB: "BORROLOOLA",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VTF",
    AIRPORTNAME: "Vatulele",
    CITYCODE: "VTF",
    CITYNAME: "VATULELE",
    CITYNAME_HEB: "VATULELE",
    COUNTRYCODE: "FJ",
    COUNTRYNAME: "FIJI",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e4\u05d9\u05d2'\u05d9",
  },
  {
    AIRPORTCODE: "VTG",
    AIRPORTNAME: "Vung Tau",
    CITYCODE: "VTG",
    CITYNAME: "VUNG TAU",
    CITYNAME_HEB: "VUNG TAU",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "VTL",
    AIRPORTNAME: "Vittel",
    CITYCODE: "VTL",
    CITYNAME: "VITTEL",
    CITYNAME_HEB: "VITTEL",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "VTN",
    AIRPORTNAME: " ",
    CITYCODE: "VTN",
    CITYNAME: "VIETNAM",
    CITYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "VTU",
    AIRPORTNAME: "Las Tunas",
    CITYCODE: "VTU",
    CITYNAME: "LAS TUNAS",
    CITYNAME_HEB: "LAS TUNAS",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "VTZ",
    AIRPORTNAME: "Vishakhapatnam",
    CITYCODE: "VTZ",
    CITYNAME: "VISHAKHAPATNAM",
    CITYNAME_HEB: "VISHAKHAPATNAM",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "VUP",
    AIRPORTNAME: "Valledupar",
    CITYCODE: "VUP",
    CITYNAME: "VALLEDUPAR",
    CITYNAME_HEB: "VALLEDUPAR",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VUS",
    AIRPORTNAME: "Velikij Ustyug",
    CITYCODE: "VUS",
    CITYNAME: "VELIKIJ USTYUG",
    CITYNAME_HEB: "VELIKIJ USTYUG",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VVB",
    AIRPORTNAME: "Mahanoro",
    CITYCODE: "VVB",
    CITYNAME: "MAHANORO",
    CITYNAME_HEB: "MAHANORO",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "VVC",
    AIRPORTNAME: "La Vanguardia",
    CITYCODE: "VVC",
    CITYNAME: "VILLAVICENCIO",
    CITYNAME_HEB: "VILLAVICENCIO",
    COUNTRYCODE: "CO",
    COUNTRYNAME: "COLOMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05dc\u05d5\u05de\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BOY",
    AIRPORTNAME: "Borgo",
    CITYCODE: "BOY",
    CITYNAME: "BOBO DIOULASSO",
    CITYNAME_HEB: "BOBO DIOULASSO",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "VVE",
    AIRPORTNAME: null,
    CITYCODE: "VVE",
    CITYNAME: "CITY NAME TEST THREE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VVK",
    AIRPORTNAME: "Vastervik",
    CITYCODE: "VVK",
    CITYNAME: "VASTERVIK",
    CITYNAME_HEB: "VASTERVIK",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VVO",
    AIRPORTNAME: "Vladivostok",
    CITYCODE: "VVO",
    CITYNAME: "VLADIVOSTOK",
    CITYNAME_HEB: "\u05d5\u05dc\u05d3\u05d9\u05d1\u05e1\u05d8\u05d5\u05e7",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VVZ",
    AIRPORTNAME: "Illizi",
    CITYCODE: "VVZ",
    CITYNAME: "ILLIZI",
    CITYNAME_HEB: "ILLIZI",
    COUNTRYCODE: "DZ",
    COUNTRYNAME: "ALGERIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d2'\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VWY",
    AIRPORTNAME: null,
    CITYCODE: "VWY",
    CITYNAME: "NILGRIS INDIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VXC",
    AIRPORTNAME: "Lichinga",
    CITYCODE: "VXC",
    CITYNAME: "LICHINGA",
    CITYNAME_HEB: "LICHINGA",
    COUNTRYCODE: "MZ",
    COUNTRYNAME: "MOZAMBIQUE",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05d6\u05de\u05d1\u05d9\u05e7",
  },
  {
    AIRPORTCODE: "VXE",
    AIRPORTNAME: null,
    CITYCODE: "VXE",
    CITYNAME: "SAO VICENTE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CV",
    COUNTRYNAME: "CAPE VERDE-REPUBLIC OF",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "VXO",
    AIRPORTNAME: "Vaxjo",
    CITYCODE: "VXO",
    CITYNAME: "VAXJO",
    CITYNAME_HEB: "VAXJO",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VYD",
    AIRPORTNAME: "Vryheid",
    CITYCODE: "VYD",
    CITYNAME: "VRYHEID",
    CITYNAME_HEB: "VRYHEID",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "VYS",
    AIRPORTNAME: "Illinois Valley Regnl",
    CITYCODE: "VYS",
    CITYNAME: "PERU",
    CITYNAME_HEB: "PERU",
    COUNTRYCODE: "USIL",
    COUNTRYNAME: "ILLINOIS",
    COUNTRYNAME_HEB:
      '\u05d0\u05d9\u05dc\u05d9\u05e0\u05d5\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BOZ",
    AIRPORTNAME: null,
    CITYCODE: "BOZ",
    CITYNAME: "BOZOUM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CF",
    COUNTRYNAME: "CENTRAL AFRICAN REPUBLIC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "WAA",
    AIRPORTNAME: "Wales",
    CITYCODE: "WAA",
    CITYNAME: "WALES",
    CITYNAME_HEB: "Wales",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WAB",
    AIRPORTNAME: "Wabag",
    CITYCODE: "WAB",
    CITYNAME: "WABAG",
    CITYNAME_HEB: "WABAG",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WAC",
    AIRPORTNAME: "Waca",
    CITYCODE: "WAC",
    CITYNAME: "WACA",
    CITYNAME_HEB: "WACA",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WAD",
    AIRPORTNAME: "Andriamena",
    CITYCODE: "WAD",
    CITYNAME: "ANDRIAMENA",
    CITYNAME_HEB: "ANDRIAMENA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WAF",
    AIRPORTNAME: "Wana",
    CITYCODE: "WAF",
    CITYNAME: "WANA",
    CITYNAME_HEB: "WANA",
    COUNTRYCODE: "PK",
    COUNTRYNAME: "PAKISTAN",
    COUNTRYNAME_HEB: "\u05e4\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "WAG",
    AIRPORTNAME: "Wanganui",
    CITYCODE: "WAG",
    CITYNAME: "WANGANUI",
    CITYNAME_HEB: "WANGANUI",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WAH",
    AIRPORTNAME: "Wahpeton",
    CITYCODE: "WAH",
    CITYNAME: "WAHPETON",
    CITYNAME_HEB: "WAHPETON",
    COUNTRYCODE: "USND",
    COUNTRYNAME: "NORTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05e6\u05e4\u05d5\u05e0\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WAI",
    AIRPORTNAME: "Antsohihy",
    CITYCODE: "WAI",
    CITYNAME: "ANTSOHIHY",
    CITYNAME_HEB: "ANTSOHIHY",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WAJ",
    AIRPORTNAME: "Wawoi Falls",
    CITYCODE: "WAJ",
    CITYNAME: "WAWOI FALLS",
    CITYNAME_HEB: "WAWOI FALLS",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BPA",
    AIRPORTNAME: "Grumman",
    CITYCODE: "BPA",
    CITYNAME: "BETHPAGE",
    CITYNAME_HEB: "BETHPAGE",
    COUNTRYCODE: "USNY",
    COUNTRYNAME: "NEW YORK",
    COUNTRYNAME_HEB:
      '\u05e0\u05d9\u05d5 \u05d9\u05d5\u05e8\u05e7 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WAK",
    AIRPORTNAME: "Ankazoabo",
    CITYCODE: "WAK",
    CITYNAME: "ANKAZOABO",
    CITYNAME_HEB: "ANKAZOABO",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WAL",
    AIRPORTNAME: "Wallops Flight Center",
    CITYCODE: "WAL",
    CITYNAME: "CHINCOTEAGUE",
    CITYNAME_HEB: "CHINCOTEAGUE",
    COUNTRYCODE: "USVA",
    COUNTRYNAME: "VIRGINIA",
    COUNTRYNAME_HEB:
      "\u05d5\u05d9\u05e8\u05d2'\u05d9\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "WAM",
    AIRPORTNAME: "Ambatondrazaka",
    CITYCODE: "WAM",
    CITYNAME: "AMBATONDRAZAKA",
    CITYNAME_HEB: "AMBATONDRAZAKA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WAN",
    AIRPORTNAME: "Waverney",
    CITYCODE: "WAN",
    CITYNAME: "WAVERNEY",
    CITYNAME_HEB: "WAVERNEY",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WAO",
    AIRPORTNAME: "Wabo",
    CITYCODE: "WAO",
    CITYNAME: "WABO",
    CITYNAME_HEB: "WABO",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WAP",
    AIRPORTNAME: "Alto Palena",
    CITYCODE: "WAP",
    CITYNAME: "ALTO PALENA",
    CITYNAME_HEB: "ALTO PALENA",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "WAQ",
    AIRPORTNAME: "Antsalova",
    CITYCODE: "WAQ",
    CITYNAME: "ANTSALOVA",
    CITYNAME_HEB: "ANTSALOVA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WAR",
    AIRPORTNAME: "Waris",
    CITYCODE: "WAR",
    CITYNAME: "WARIS",
    CITYNAME_HEB: "WARIS",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WAS",
    AIRPORTNAME: " ",
    CITYCODE: "WAS",
    CITYNAME: "WASHINGTON",
    CITYNAME_HEB:
      "\u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df \u05d3\u05d9 \u05e1\u05d9",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "BPB",
    AIRPORTNAME: "Boridi",
    CITYCODE: "BPB",
    CITYNAME: "BORIDI",
    CITYNAME_HEB: "BORIDI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BPC",
    AIRPORTNAME: "Bamenda",
    CITYCODE: "BPC",
    CITYNAME: "BAMENDA",
    CITYNAME_HEB: "BAMENDA",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "WAT",
    AIRPORTNAME: "Waterford",
    CITYCODE: "WAT",
    CITYNAME: "WATERFORD",
    CITYNAME_HEB: "WATERFORD",
    COUNTRYCODE: "IE",
    COUNTRYNAME: "IRELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e8\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WAU",
    AIRPORTNAME: "Wauchope",
    CITYCODE: "WAU",
    CITYNAME: "WAUCHOPE",
    CITYNAME_HEB: "WAUCHOPE",
    COUNTRYCODE: "AUNS",
    COUNTRYNAME: "NEW SOUTH WALES",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WAV",
    AIRPORTNAME: "Kalkgurung",
    CITYCODE: "WAV",
    CITYNAME: "WAVE HILL",
    CITYNAME_HEB: "WAVE HILL",
    COUNTRYCODE: "AUNT",
    COUNTRYNAME: "NORTHERN TERRITORY",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WAW",
    AIRPORTNAME: "Okecie",
    CITYCODE: "WAW",
    CITYNAME: "WARSAW",
    CITYNAME_HEB: "\u05d5\u05e8\u05e9\u05d4",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "WAX",
    AIRPORTNAME: "Zwara",
    CITYCODE: "WAX",
    CITYNAME: "ZWARA",
    CITYNAME_HEB: "ZWARA",
    COUNTRYCODE: "LY",
    COUNTRYNAME: "LIBYAN ARAB JAMAHIRIYA",
    COUNTRYNAME_HEB: "\u05dc\u05d5\u05d1",
  },
  {
    AIRPORTCODE: "WAY",
    AIRPORTNAME: "Green County",
    CITYCODE: "WAY",
    CITYNAME: "WAYNESBURG",
    CITYNAME_HEB: "WAYNESBURG",
    COUNTRYCODE: "USPA",
    COUNTRYNAME: "PENNSYLVANIA",
    COUNTRYNAME_HEB:
      '\u05e4\u05e0\u05e1\u05d9\u05dc\u05d1\u05e0\u05d9\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WAZ",
    AIRPORTNAME: "Warwick",
    CITYCODE: "WAZ",
    CITYNAME: "WARWICK",
    CITYNAME_HEB: "WARWICK",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WBA",
    AIRPORTNAME: "Wahai",
    CITYCODE: "WBA",
    CITYNAME: "WAHAI MALUKU",
    CITYNAME_HEB: "WAHAI MALUKU",
    COUNTRYCODE: "ID",
    COUNTRYNAME: "INDONESIA",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e0\u05d3\u05d5\u05e0\u05d6\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WBB",
    AIRPORTNAME: "Stebbins",
    CITYCODE: "WBB",
    CITYNAME: "STEBBINS",
    CITYNAME_HEB: "Stebbins",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WBC",
    AIRPORTNAME: "Wapolu",
    CITYCODE: "WBC",
    CITYNAME: "WAPOLU",
    CITYNAME_HEB: "WAPOLU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BPD",
    AIRPORTNAME: "Bapi",
    CITYCODE: "BPD",
    CITYNAME: "BAPI",
    CITYNAME_HEB: "BAPI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WBD",
    AIRPORTNAME: "Befandriana",
    CITYCODE: "WBD",
    CITYNAME: "BEFANDRIANA",
    CITYNAME_HEB: "BEFANDRIANA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WBE",
    AIRPORTNAME: "Bealanana",
    CITYCODE: "WBE",
    CITYNAME: "BEALANANA",
    CITYNAME_HEB: "BEALANANA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WBG",
    AIRPORTNAME: "Schleswig-Jagel",
    CITYCODE: "WBG",
    CITYNAME: "SCHLESWIG JAGEL",
    CITYNAME_HEB: "SCHLESWIG JAGEL",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WBM",
    AIRPORTNAME: "Wapenamanda",
    CITYCODE: "WBM",
    CITYNAME: "WAPENAMANDA",
    CITYNAME_HEB: "WAPENAMANDA",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "WBN",
    AIRPORTNAME: "Cummings Park",
    CITYCODE: "WBN",
    CITYNAME: "WOBURN",
    CITYNAME_HEB: "WOBURN",
    COUNTRYCODE: "USMA",
    COUNTRYNAME: "MASSACHUSETTS",
    COUNTRYNAME_HEB:
      "\u05de\u05e1\u05e6'\u05d5\u05e1\u05d8\u05e1 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "WBO",
    AIRPORTNAME: "Beroroha",
    CITYCODE: "WBO",
    CITYNAME: "BEROROHA",
    CITYNAME_HEB: "BEROROHA",
    COUNTRYCODE: "MG",
    COUNTRYNAME: "MADAGASCAR ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05d3\u05d2\u05e1\u05e7\u05e8",
  },
  {
    AIRPORTCODE: "WBQ",
    AIRPORTNAME: "Beaver",
    CITYCODE: "WBQ",
    CITYNAME: "BEAVER",
    CITYNAME_HEB: "Beaver",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WBR",
    AIRPORTNAME: "Big Rapids",
    CITYCODE: "WBR",
    CITYNAME: "BIG RAPIDS",
    CITYNAME_HEB: "BIG RAPIDS",
    COUNTRYCODE: "USMI",
    COUNTRYNAME: "MICHIGAN",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e9\u05d9\u05d2\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WBI",
    AIRPORTNAME: null,
    CITYCODE: "WBU",
    CITYNAME: "BOULDER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BPE",
    AIRPORTNAME: "Bagan",
    CITYCODE: "BPE",
    CITYNAME: "BAGAN",
    CITYNAME_HEB: "\u05d1\u05d2\u05d0\u05df",
    COUNTRYCODE: "MM",
    COUNTRYNAME: "MYANMAR",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05e8\u05de\u05d4",
  },
  {
    AIRPORTCODE: "WBU",
    AIRPORTNAME: "Municipal",
    CITYCODE: "WBU",
    CITYNAME: "BOULDER",
    CITYNAME_HEB: "BOULDER",
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB:
      '\u05e7\u05d5\u05dc\u05d5\u05e8\u05d3\u05d5 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WHH",
    AIRPORTNAME: null,
    CITYCODE: "WBU",
    CITYNAME: "BOULDER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XHH",
    AIRPORTNAME: null,
    CITYCODE: "WBU",
    CITYNAME: "BOULDER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "USCO",
    COUNTRYNAME: "COLORADO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "WCA",
    AIRPORTNAME: "Gamboa",
    CITYCODE: "WCA",
    CITYNAME: "CASTRO",
    CITYNAME_HEB: "CASTRO",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "WCH",
    AIRPORTNAME: "Chaiten",
    CITYCODE: "WCH",
    CITYNAME: "CHAITEN",
    CITYNAME_HEB: "CHAITEN",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6'\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "WCR",
    AIRPORTNAME: "Chandalar",
    CITYCODE: "WCR",
    CITYNAME: "CHANDALAR",
    CITYNAME_HEB: "Chandalar",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WDA",
    AIRPORTNAME: "Wadi Ain",
    CITYCODE: "WDA",
    CITYNAME: "WADI AIN",
    CITYNAME_HEB: "WADI AIN",
    COUNTRYCODE: "YE",
    COUNTRYNAME: "YEMEN ARAB REPUBLIC",
    COUNTRYNAME_HEB: "\u05ea\u05d9\u05de\u05df",
  },
  {
    AIRPORTCODE: "WDB",
    AIRPORTNAME: "Deep Bay",
    CITYCODE: "WDB",
    CITYNAME: "DEEP BAY",
    CITYNAME_HEB: "Deep Bay",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "END",
    AIRPORTNAME: "Vance AFB",
    CITYCODE: "WDG",
    CITYNAME: "ENID",
    CITYNAME_HEB: "ENID",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WDG",
    AIRPORTNAME: "Enid Woodring Mun.",
    CITYCODE: "WDG",
    CITYNAME: "ENID",
    CITYNAME_HEB: "ENID",
    COUNTRYCODE: "USOK",
    COUNTRYNAME: "OKLAHOMA",
    COUNTRYNAME_HEB:
      '\u05d0\u05d5\u05e7\u05dc\u05d4\u05d5\u05de\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "BPF",
    AIRPORTNAME: "Batuna Aerodrome",
    CITYCODE: "BPF",
    CITYNAME: "BATUNA",
    CITYNAME_HEB: "BATUNA",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
  {
    AIRPORTCODE: "ERS",
    AIRPORTNAME: null,
    CITYCODE: "WDH",
    CITYNAME: "WINDHOEK",
    CITYNAME_HEB: null,
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "KVO",
    AIRPORTNAME: null,
    CITYCODE: "KVO",
    CITYNAME: "KISLOVODSK",
    CITYNAME_HEB:
      "\u05e7\u05d9\u05e1\u05dc\u05d5\u05d1\u05d5\u05d3\u05e1\u05e7",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BBL",
    AIRPORTNAME: null,
    CITYCODE: "BBL",
    CITYNAME: "BLACHIK",
    CITYNAME_HEB: "\u05d1\u05dc\u05e6\u05d9\u05e7",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RGB",
    AIRPORTNAME: null,
    CITYCODE: "RGB",
    CITYNAME: "GALATI",
    CITYNAME_HEB: "\u05d2\u05dc\u05d0\u05d8\u05d9",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PKX",
    AIRPORTNAME: "DaxingDaxing Intl.",
    CITYCODE: "BJS",
    CITYNAME: "BEIJING",
    CITYNAME_HEB: "\u05d1\u05d9\u05d9\u05d2\u05d9\u05e0\u05d2",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "WDH",
    AIRPORTNAME: "Hosea Kutako International",
    CITYCODE: "WDH",
    CITYNAME: "WINDHOEK",
    CITYNAME_HEB: "\u05d5\u05d9\u05e0\u05d3 \u05d4\u05d5\u05e7",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZWH",
    AIRPORTNAME: null,
    CITYCODE: "WDH",
    CITYNAME: "WINDHOEK",
    CITYNAME_HEB: null,
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "WDI",
    AIRPORTNAME: "Wondai",
    CITYCODE: "WDI",
    CITYNAME: "WONDAI",
    CITYNAME_HEB: "WONDAI",
    COUNTRYCODE: "AUQL",
    COUNTRYNAME: "QUEENSLAND",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WDN",
    AIRPORTNAME: "Waldron Island",
    CITYCODE: "WDN",
    CITYNAME: "WALDRON ISLAND",
    CITYNAME_HEB: "WALDRON ISLAND",
    COUNTRYCODE: "USWA",
    COUNTRYNAME: "WASHINGTON",
    COUNTRYNAME_HEB:
      '\u05de\u05d3\u05d9\u05e0\u05ea \u05d5\u05d5\u05e9\u05d9\u05e0\u05d2\u05d8\u05d5\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WDR",
    AIRPORTNAME: "Winder",
    CITYCODE: "WDR",
    CITYNAME: "WINDER",
    CITYNAME_HEB: "WINDER",
    COUNTRYCODE: "USGA",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB:
      "\u05d2'\u05d5\u05e8\u05d2\u05d9\u05d4 (\u05d0\u05e8\u05d4\"\u05d1)",
  },
  {
    AIRPORTCODE: "WEA",
    AIRPORTNAME: "Parker County",
    CITYCODE: "WEA",
    CITYNAME: "WEATHERFORD",
    CITYNAME_HEB: "WEATHERFORD",
    COUNTRYCODE: "USTX",
    COUNTRYNAME: "TEXAS",
    COUNTRYNAME_HEB: '\u05d8\u05e7\u05e1\u05e1 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "WED",
    AIRPORTNAME: "Wedau",
    CITYCODE: "WED",
    CITYNAME: "WEDAU",
    CITYNAME_HEB: "WEDAU",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "TSA",
    AIRPORTNAME: " ",
    CITYCODE: "TSA",
    CITYNAME: "TERME SMARJESKE TOPLICE",
    CITYNAME_HEB:
      "\u05d8\u05e8\u05de\u05d4 \u05e1\u05de\u05d0\u05e8\u05d2\u05e1\u05e7\u05d4 \u05d8\u05d5\u05e4\u05dc\u05d9\u05e6\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JOC",
    AIRPORTNAME: null,
    CITYCODE: "JOC",
    CITYNAME: "JOCHBERG",
    CITYNAME_HEB: "\u05d2\u05d5\u05db\u05d1\u05e8\u05d2",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DTB",
    AIRPORTNAME: " ",
    CITYCODE: "DTB",
    CITYNAME: "DOLENJSKE TOPLICE THERMAL",
    CITYNAME_HEB:
      "\u05d3\u05d5\u05dc\u05e0\u05e6\u05e7\u05d4 \u05d8\u05d5\u05e4\u05dc\u05d9\u05e6\u05d4 \u05d8\u05d0\u05e8\u05de\u05dc",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "ZDJ",
    CITYNAME: "POLANICA ZDROJ",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05dc\u05e0\u05e6\u05d9\u05d4 \u05d6\u05d3\u05e8\u05d5\u05d2",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "ILD",
    AIRPORTNAME: " ",
    CITYCODE: "ILD",
    CITYNAME: "LLEIDA",
    CITYNAME_HEB: "\u05dc\u05e8\u05d9\u05d3\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "FRF",
    CITYNAME: "FREUDENSTADT",
    CITYNAME_HEB: "\u05e4\u05e8\u05d5\u05d3\u05e0\u05e1\u05d8\u05d0\u05d8",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HEC",
    AIRPORTNAME: null,
    CITYCODE: "HEC",
    CITYNAME: "HERRENBERG",
    CITYNAME_HEB: "\u05d4\u05e8\u05d9\u05e0\u05d1\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NOW",
    AIRPORTNAME: " ",
    CITYCODE: "NOW",
    CITYNAME: "NORWAY",
    CITYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VOA",
    AIRPORTNAME: null,
    CITYCODE: "VOA",
    CITYNAME: "VOLKLINGEN",
    CITYNAME_HEB: "\u05d5\u05dc\u05e7\u05d9\u05e0\u05d2\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MRX",
    AIRPORTNAME: null,
    CITYCODE: "MRX",
    CITYNAME: "MARCON",
    CITYNAME_HEB: "\u05de\u05e8\u05e7\u05d5\u05df",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SSR",
    AIRPORTNAME: " ",
    CITYCODE: "SSR",
    CITYNAME: "RUSSIA",
    CITYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "AKH",
    AIRPORTNAME: " ",
    CITYCODE: "AKH",
    CITYNAME: "AKHALITSIKHE",
    CITYNAME_HEB: "\u05d0\u05e7\u05dc\u05d9\u05d8\u05d9\u05e9\u05e7\u05d4",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MDA",
    AIRPORTNAME: null,
    CITYCODE: "MDA",
    CITYNAME: "MARINA DI ASCEA",
    CITYNAME_HEB:
      "\u05de\u05e8\u05d9\u05e0\u05d4 \u05d3\u05d9 \u05d0\u05e1\u05e7\u05d0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CUB",
    AIRPORTNAME: " ",
    CITYCODE: "CUB",
    CITYNAME: "CUBA",
    CITYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
    COUNTRYCODE: "CU",
    COUNTRYNAME: "CUBA",
    COUNTRYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "MUL",
    AIRPORTNAME: " ",
    CITYCODE: "BSL",
    CITYNAME: "BASEL MULHOUSE",
    CITYNAME_HEB: "\u05d1\u05d0\u05d6\u05dc",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "LAR",
    AIRPORTNAME: null,
    CITYCODE: "LAR",
    CITYNAME: "LARISSA",
    CITYNAME_HEB: "\u05dc\u05d0\u05e8\u05d9\u05e1\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "ICE",
    AIRPORTNAME: " ",
    CITYCODE: "ICE",
    CITYNAME: "ICELAND",
    CITYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "LUK",
    AIRPORTNAME: " ",
    CITYCODE: "LUK",
    CITYNAME: "LEUKERBAD",
    CITYNAME_HEB: "\u05dc\u05d5\u05e7\u05e8\u05d1\u05d3",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "AGB",
    CITYNAME: "SANT AGATA",
    CITYNAME_HEB: "\u05e1\u05e0\u05d8\u05d4 \u05d0\u05d2\u05d0\u05ea\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VOR",
    AIRPORTNAME: " ",
    CITYCODE: "VOR",
    CITYNAME: "CASTELVORTURNO",
    CITYNAME_HEB:
      "\u05e7\u05d0\u05e1\u05d8\u05dc\u05d5\u05e8\u05d8\u05e8\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ISX",
    AIRPORTNAME: null,
    CITYCODE: "ISX",
    CITYNAME: "ISOLAVERDE CHIOGGIA",
    CITYNAME_HEB:
      "\u05d0\u05d9\u05e1\u05d5\u05dc\u05d1\u05d3\u05e8\u05d4 \u05d7\u05d9\u05d5\u05d2\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CPZ",
    AIRPORTNAME: " ",
    CITYCODE: "CPZ",
    CITYNAME: "PRATTELN",
    CITYNAME_HEB: "\u05e4\u05e8\u05d8\u05dc\u05df",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "BNH",
    AIRPORTNAME: " ",
    CITYCODE: "BNH",
    CITYNAME: "BRIENZ",
    CITYNAME_HEB: "\u05d1\u05e8\u05d9\u05d9\u05e0\u05e5",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "ETO",
    AIRPORTNAME: null,
    CITYCODE: "ETO",
    CITYNAME: "ETHIOPIA",
    CITYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NZL",
    AIRPORTNAME: null,
    CITYCODE: "NZL",
    CITYNAME: "NEW ZEALAND",
    CITYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
    COUNTRYCODE: "NZ",
    COUNTRYNAME: "NEW ZEALAND",
    COUNTRYNAME_HEB: "\u05e0\u05d9\u05d5 \u05d6\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "RAD",
    AIRPORTNAME: " ",
    CITYCODE: "RAD",
    CITYNAME: "RADENCI",
    CITYNAME_HEB: "\u05e8\u05d0\u05d3\u05e0\u05e1\u05d9",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PIB",
    AIRPORTNAME: null,
    CITYCODE: "PIB",
    CITYNAME: "PISSOURI",
    CITYNAME_HEB: "\u05e4\u05d9\u05e1\u05d5\u05e8\u05d9",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "BMA",
    AIRPORTNAME: " ",
    CITYCODE: "BMA",
    CITYNAME: "BRAAMT",
    CITYNAME_HEB: "\u05d1\u05e8\u05d0\u05de\u05d8",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "REJ",
    AIRPORTNAME: " ",
    CITYCODE: "REJ",
    CITYNAME: "REUVER",
    CITYNAME_HEB: "\u05e8\u05d0\u05d5\u05d1\u05e8",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "HAH",
    AIRPORTNAME: " ",
    CITYCODE: "HAH",
    CITYNAME: "HAYINGEN",
    CITYNAME_HEB: "\u05d4\u05d9\u05d9\u05e0\u05d9\u05d2\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KYC",
    AIRPORTNAME: null,
    CITYCODE: "KYC",
    CITYNAME: "KYUSTENDIL",
    CITYNAME_HEB: "\u05e7\u05d9\u05d5\u05e1\u05d8\u05e0\u05d3\u05d9\u05dc",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GRF",
    AIRPORTNAME: " ",
    CITYCODE: "GRF",
    CITYNAME: "ALGARVE",
    CITYNAME_HEB: "\u05d0\u05dc\u05d2\u05d0\u05e8\u05d1\u05d4",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTUGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "SSB",
    AIRPORTNAME: " ",
    CITYCODE: "SSB",
    CITYNAME: "SALOU",
    CITYNAME_HEB: "\u05e1\u05d0\u05dc\u05d0\u05d5",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "EGU",
    AIRPORTNAME: null,
    CITYCODE: "EGU",
    CITYNAME: "EGUISHEIM",
    CITYNAME_HEB: "\u05d0\u05d2\u05d5\u05d5\u05d9\u05e9\u05d9\u05dd",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "YTO",
    AIRPORTNAME: " ",
    CITYCODE: "YTO",
    CITYNAME: "TORONTO",
    CITYNAME_HEB: "\u05d8\u05d5\u05e8\u05d5\u05e0\u05d8\u05d5",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "CANADA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "UMG",
    AIRPORTNAME: " ",
    CITYCODE: "UMG",
    CITYNAME: "UMAG",
    CITYNAME_HEB: "\u05d0\u05d5\u05de\u05d0\u05d2",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "BAB",
    CITYNAME: "BAIA DOMIZIA",
    CITYNAME_HEB:
      "\u05d1\u05d0\u05d9\u05d4 \u05d3\u05d5\u05de\u05d9\u05d6\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PSQ",
    AIRPORTNAME: " ",
    CITYCODE: "PSQ",
    CITYNAME: "PUGLIA",
    CITYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CSH",
    AIRPORTNAME: " ",
    CITYCODE: "CSH",
    CITYNAME: "CASTELLAMMARE DI STABIA",
    CITYNAME_HEB:
      "\u05e7\u05d0\u05e1\u05d8\u05dc\u05de\u05e8\u05d4 \u05d3\u05d9 \u05e1\u05d8\u05d0\u05d1\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ORL",
    AIRPORTNAME: " ",
    CITYCODE: "ORL",
    CITYNAME: "ORLANDO",
    CITYNAME_HEB: "\u05d0\u05d5\u05e8\u05dc\u05e0\u05d3\u05d5",
    COUNTRYCODE: "USA",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "KRG",
    AIRPORTNAME: null,
    CITYCODE: "KRG",
    CITYNAME: "KRANKA GORA",
    CITYNAME_HEB:
      "\u05e7\u05e8\u05d0\u05e0\u05e7\u05d4 \u05d2\u05d5\u05e8\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BEC",
    AIRPORTNAME: " ",
    CITYCODE: "BEC",
    CITYNAME: "BELOHRAD",
    CITYNAME_HEB: "\u05d1\u05d0\u05dc\u05d5\u05d0\u05e8\u05d3",
    COUNTRYCODE: "CZ",
    COUNTRYNAME: "CZECH REPUBLIC",
    COUNTRYNAME_HEB: "\u05e6\u05db\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PZD",
    AIRPORTNAME: null,
    CITYCODE: "PZD",
    CITYNAME: "PREDAZZO",
    CITYNAME_HEB: "\u05e4\u05e8\u05d3\u05d0\u05d6\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HHT",
    AIRPORTNAME: " ",
    CITYCODE: "HHT",
    CITYNAME: "HULSHORTS",
    CITYNAME_HEB: "\u05d4\u05d5\u05dc\u05e9\u05d5\u05e8\u05d8\u05e1",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "UID",
    AIRPORTNAME: " ",
    CITYCODE: "UID",
    CITYNAME: "IJMUIDEN",
    CITYNAME_HEB: "\u05d0\u05d9\u05d2\u05de\u05d5\u05d9\u05d9\u05d3\u05df",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "DUU",
    AIRPORTNAME: " ",
    CITYCODE: "DUU",
    CITYNAME: "DURBUY",
    CITYNAME_HEB: "\u05d3\u05d5\u05e8\u05d1\u05d9\u05d9",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MLH",
    AIRPORTNAME: null,
    CITYCODE: "MLH",
    CITYNAME: "MULHOUSE",
    CITYNAME_HEB: "\u05de\u05d5\u05dc\u05d0\u05d4\u05d5\u05e1",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "EMR",
    AIRPORTNAME: null,
    CITYCODE: "EMR",
    CITYNAME: "EMILIA ROMAGNA",
    CITYNAME_HEB:
      "\u05d0\u05de\u05d9\u05dc\u05d9\u05d4 \u05e8\u05d5\u05de\u05d0\u05e0\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WLE",
    AIRPORTNAME: null,
    CITYCODE: "WLE",
    CITYNAME: "WALLDORF",
    CITYNAME_HEB: "\u05d5\u05d5\u05dc\u05d3\u05d5\u05e8\u05e3",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VSD",
    AIRPORTNAME: null,
    CITYCODE: "VSD",
    CITYNAME: "VSENDORF",
    CITYNAME_HEB: "\u05d5\u05d5\u05e1\u05e0\u05d3\u05d5\u05e8\u05e3",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HGS",
    AIRPORTNAME: " ",
    CITYCODE: "HGS",
    CITYNAME: "HONG SA",
    CITYNAME_HEB: "\u05d4\u05d5\u05e0\u05d2 \u05e1\u05d4",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "GDR",
    AIRPORTNAME: null,
    CITYCODE: "GDR",
    CITYNAME: "GUARDA",
    CITYNAME_HEB: "\u05d2\u05d5\u05d0\u05e8\u05d3\u05d4",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTOGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "OLC",
    AIRPORTNAME: null,
    CITYCODE: "OLC",
    CITYNAME: "ONET LE CHATEAU",
    CITYNAME_HEB:
      "\u05d0\u05d5\u05e0\u05d8 \u05dc\u05d4 \u05e9\u05d0\u05d8\u05d5",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "LYD",
    AIRPORTNAME: null,
    CITYCODE: "LYD",
    CITYNAME: "LES EYZIES DE TAYAC",
    CITYNAME_HEB:
      "\u05dc\u05d4 \u05d0\u05d9\u05d9\u05d6 \u05d3\u05d4 \u05d8\u05d9\u05d9\u05d0\u05e7",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "BAK",
    AIRPORTNAME: "BAKU",
    CITYCODE: "BAK",
    CITYNAME: "BAKU",
    CITYNAME_HEB: "\u05d1\u05d0\u05e7\u05d5",
    COUNTRYCODE: "AZ",
    COUNTRYNAME: "AZERBAIJAN",
    COUNTRYNAME_HEB: "\u05d0\u05d6\u05e8\u05d1\u05d9\u05d9\u05d2\u05df",
  },
  {
    AIRPORTCODE: "HEF",
    AIRPORTNAME: " ",
    CITYCODE: "HEF",
    CITYNAME: "HOEVELAKEN",
    CITYNAME_HEB: "\u05d4\u05d5\u05d1\u05dc\u05e7\u05df",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "DML",
    AIRPORTNAME: " ",
    CITYCODE: "DML",
    CITYNAME: "DAMBULLA",
    CITYNAME_HEB: "\u05d3\u05de\u05d1\u05d5\u05dc\u05d4",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "KEF",
    AIRPORTNAME: " ",
    CITYCODE: "KEF",
    CITYNAME: "KEFLAVIK",
    CITYNAME_HEB: "\u05e7\u05d9\u05d9\u05e4\u05dc\u05d0\u05d5\u05d9\u05e7",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "WNH",
    AIRPORTNAME: " ",
    CITYCODE: "WNH",
    CITYNAME: "WEINHEIM",
    CITYNAME_HEB: "\u05d5\u05d5\u05d9\u05e0\u05d4\u05d9\u05d9\u05dd",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XUM",
    AIRPORTNAME: " ",
    CITYCODE: "XUM",
    CITYNAME: "TURMI",
    CITYNAME_HEB: "\u05d8\u05d5\u05e8\u05de\u05d9",
    COUNTRYCODE: "ET",
    COUNTRYNAME: "ETHIOPIA",
    COUNTRYNAME_HEB: "\u05d0\u05ea\u05d9\u05d5\u05e4\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GIA",
    AIRPORTNAME: null,
    CITYCODE: "GIA",
    CITYNAME: "GIARDINI NAXOS",
    CITYNAME_HEB:
      "\u05d2\u05d9\u05d0\u05e8\u05d3\u05d9\u05e0\u05d9 \u05e0\u05e7\u05e1\u05d5\u05e1",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SSI",
    AIRPORTNAME: null,
    CITYCODE: "SSI",
    CITYNAME: "SANT ALESSIO SICULO",
    CITYNAME_HEB:
      "\u05e1\u05d0\u05e0\u05d8 \u05d0\u05dc\u05e1\u05d9\u05d5 \u05e1\u05d9\u05e6\u05dc\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RCA",
    AIRPORTNAME: null,
    CITYCODE: "RCA",
    CITYNAME: "ROCCALUMERA",
    CITYNAME_HEB: "\u05e8\u05d5\u05e7\u05d4\u05dc\u05d5\u05de\u05e8\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "FIU",
    AIRPORTNAME: " ",
    CITYCODE: "FIU",
    CITYNAME: "FIUGGI",
    CITYNAME_HEB: "\u05e4\u05d9\u05d0\u05d9\u05d5\u05d2\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HKO",
    AIRPORTNAME: null,
    CITYCODE: "HKO",
    CITYNAME: "HAKONE",
    CITYNAME_HEB: "\u05d4\u05d0\u05e7\u05d5\u05df",
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: "\u05d9\u05e4\u05df",
  },
  {
    AIRPORTCODE: "BVA",
    AIRPORTNAME: " ",
    CITYCODE: "BVA",
    CITYNAME: "BUKOVICKA BANJA",
    CITYNAME_HEB:
      "\u05d1\u05d5\u05e7\u05d5\u05d1\u05d9\u05e6\u05e7\u05d4 \u05d1\u05d0\u05e0\u05d4",
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NMI",
    AIRPORTNAME: " ",
    CITYCODE: "NMI",
    CITYNAME: "NAMIBIA",
    CITYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
    COUNTRYCODE: null,
    COUNTRYNAME: "NAMIBIA",
    COUNTRYNAME_HEB: "\u05e0\u05de\u05d9\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "REE",
    AIRPORTNAME: null,
    CITYCODE: "REE",
    CITYNAME: "RENNINGEN",
    CITYNAME_HEB: "\u05e8\u05d9\u05e0\u05d9\u05e0\u05d2\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PJH",
    AIRPORTNAME: " ",
    CITYCODE: "PJH",
    CITYNAME: "PHILIPPINES",
    CITYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "TCZ",
    AIRPORTNAME: null,
    CITYCODE: "TCZ",
    CITYNAME: "PODCETRTEK",
    CITYNAME_HEB: "\u05e4\u05d5\u05d3\u05e6\u05d8\u05e8\u05d8\u05e7",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HPL",
    AIRPORTNAME: " ",
    CITYCODE: "HPL",
    CITYNAME: "ALLEPPEY",
    CITYNAME_HEB: "\u05d0\u05dc\u05d0\u05e4\u05d9",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "HPK",
    AIRPORTNAME: null,
    CITYCODE: "HPK",
    CITYNAME: "THEKKADI",
    CITYNAME_HEB: "\u05d8\u05d4\u05e7\u05d0\u05d3\u05d9",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "TSO",
    AIRPORTNAME: " ",
    CITYCODE: "TSO",
    CITYNAME: "TERME SNOVIK",
    CITYNAME_HEB:
      "\u05d8\u05e8\u05de\u05d4 \u05e1\u05e0\u05d5\u05d1\u05d9\u05e7",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CVD",
    AIRPORTNAME: null,
    CITYCODE: "CVD",
    CITYNAME: "CIVITAVECCHIA",
    CITYNAME_HEB:
      "\u05e6\u05d9\u05d5\u05d5\u05d9\u05d8\u05d5\u05d5\u05e7\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TNZ",
    AIRPORTNAME: null,
    CITYCODE: "TNZ",
    CITYNAME: "TANZANIA",
    CITYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
    COUNTRYCODE: "TZ",
    COUNTRYNAME: "TANZANIA",
    COUNTRYNAME_HEB: "\u05d8\u05e0\u05d6\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZTO",
    AIRPORTNAME: null,
    CITYCODE: "ZTO",
    CITYNAME: "ZATOKA",
    CITYNAME_HEB: "\u05d6\u05d8\u05d5\u05e7\u05d4",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "HDR",
    AIRPORTNAME: " ",
    CITYCODE: "HDR",
    CITYNAME: "DE RIJP",
    CITYNAME_HEB: "\u05d3\u05d4 \u05e8\u05d9\u05e4",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "AZE",
    AIRPORTNAME: null,
    CITYCODE: "AZE",
    CITYNAME: "AZERBAIJAN",
    CITYNAME_HEB: "\u05d0\u05d6\u05e8\u05d1\u05d9\u05d9\u05d2\u05df",
    COUNTRYCODE: "AZ",
    COUNTRYNAME: "AZERBAIJAN",
    COUNTRYNAME_HEB: "\u05d0\u05d6\u05e8\u05d1\u05d9\u05d9\u05d2\u05df",
  },
  {
    AIRPORTCODE: "GYD",
    AIRPORTNAME: "BAKU",
    CITYCODE: "BAK",
    CITYNAME: "BAKU",
    CITYNAME_HEB: "\u05d1\u05d0\u05e7\u05d5",
    COUNTRYCODE: "AZ",
    COUNTRYNAME: "AZERBAIJAN",
    COUNTRYNAME_HEB: "\u05d0\u05d6\u05e8\u05d1\u05d9\u05d9\u05d2\u05df",
  },
  {
    AIRPORTCODE: "PMF",
    AIRPORTNAME: null,
    CITYCODE: "PMF",
    CITYNAME: "PARMA",
    CITYNAME_HEB: "\u05e4\u05d0\u05e8\u05de\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BJE",
    AIRPORTNAME: " ",
    CITYCODE: "BJE",
    CITYNAME: "BOHINJSKO JEZERO",
    CITYNAME_HEB: "\u05d0\u05d2\u05dd \u05d1\u05d5\u05d4\u05d9\u05df",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WIS",
    AIRPORTNAME: " ",
    CITYCODE: "WIS",
    CITYNAME: "WILDERNESS",
    CITYNAME_HEB: "\u05d5\u05d9\u05dc\u05d3\u05e8\u05e0\u05e1",
    COUNTRYCODE: "ZA",
    COUNTRYNAME: "SOUTH AFRICA",
    COUNTRYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05e4\u05e8\u05d9\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "EMB",
    AIRPORTNAME: null,
    CITYCODE: "EMB",
    CITYNAME: "EMPORIA BRAVA",
    CITYNAME_HEB:
      "\u05d0\u05d9\u05de\u05e4\u05d5\u05e8\u05d4 \u05d1\u05e8\u05d5\u05d5\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "UMN",
    AIRPORTNAME: " ",
    CITYCODE: "UMN",
    CITYNAME: "UMAN",
    CITYNAME_HEB: "\u05d0\u05d5\u05de\u05df",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "TNT",
    AIRPORTNAME: null,
    CITYCODE: "TNT",
    CITYNAME: "TRANCOSO",
    CITYNAME_HEB: "\u05d8\u05e8\u05d0\u05e0\u05e7\u05d5\u05e1\u05d5",
    COUNTRYCODE: "PT",
    COUNTRYNAME: "PORTOGAL",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5\u05d2\u05dc",
  },
  {
    AIRPORTCODE: "CHA",
    AIRPORTNAME: " ",
    CITYCODE: "CHA",
    CITYNAME: "CHARLEROI",
    CITYNAME_HEB: "\u05e9\u05e8\u05dc\u05e8\u05d5\u05d0\u05d4",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TIV",
    AIRPORTNAME: " ",
    CITYCODE: "TIV",
    CITYNAME: "TIVAT",
    CITYNAME_HEB:
      "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5 \u05d8\u05d9\u05d5\u05d5\u05d8",
    COUNTRYCODE: "ME",
    COUNTRYNAME: "MONTENEGRO",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "XAE",
    AIRPORTNAME: " ",
    CITYCODE: "XAE",
    CITYNAME: "XIAN",
    CITYNAME_HEB: "\u05e7\u05e1\u05d9\u05d0\u05df",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "YNB",
    AIRPORTNAME: " ",
    CITYCODE: "YNB",
    CITYNAME: "YANGSHUO",
    CITYNAME_HEB: "\u05d9\u05d0\u05e0\u05d2\u05e1\u05d5",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "PWA",
    AIRPORTNAME: " ",
    CITYCODE: "PWA",
    CITYNAME: "PALAWAN ISLAND",
    CITYNAME_HEB: "\u05e4\u05d0\u05dc\u05d5\u05d5\u05df",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "RHN",
    AIRPORTNAME: " ",
    CITYCODE: "RHN",
    CITYNAME: "RHEINSBERG",
    CITYNAME_HEB: "\u05e8\u05d9\u05d9\u05e0\u05e1\u05d1\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "WKS",
    AIRPORTNAME: " ",
    CITYCODE: "WKS",
    CITYNAME: "WAIKKAL",
    CITYNAME_HEB: "\u05d5\u05d9\u05e7\u05d0\u05e7\u05dc",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "GIE",
    AIRPORTNAME: " ",
    CITYCODE: "GIE",
    CITYNAME: "GIRITALE",
    CITYNAME_HEB: "\u05d2\u05d9\u05e8\u05d9\u05d8\u05dc",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "MNG",
    AIRPORTNAME: null,
    CITYCODE: "MNG",
    CITYNAME: "MONGOLIA",
    CITYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
    COUNTRYCODE: "MN",
    COUNTRYNAME: "MONGOLIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d2\u05d5\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "INR",
    AIRPORTNAME: null,
    CITYCODE: "INR",
    CITYNAME: "INDIA",
    CITYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "MAL",
    AIRPORTNAME: " ",
    CITYCODE: "MAL",
    CITYNAME: "MALDIVES",
    CITYNAME_HEB: "\u05de\u05dc\u05d3\u05d9\u05d1\u05d9\u05d9\u05dd",
    COUNTRYCODE: "MV",
    COUNTRYNAME: "MALDIVES ISLAND",
    COUNTRYNAME_HEB: "\u05de\u05dc\u05d3\u05d9\u05d1\u05d9\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "NID",
    AIRPORTNAME: null,
    CITYCODE: "NID",
    CITYNAME: "NIEDERNHAUSEN",
    CITYNAME_HEB:
      "\u05e0\u05d9\u05d9\u05d3\u05e8\u05e0\u05d4\u05d0\u05d5\u05e1\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RIV",
    AIRPORTNAME: null,
    CITYCODE: "RIV",
    CITYNAME: "FRANCE RIVIERA",
    CITYNAME_HEB:
      "\u05d4\u05e8\u05d9\u05d1\u05d9\u05d9\u05e8\u05d4 \u05d4\u05e6\u05e8\u05e4\u05ea\u05d9\u05ea",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "TPD",
    AIRPORTNAME: " ",
    CITYCODE: "TPD",
    CITYNAME: "TOPLICE",
    CITYNAME_HEB: "\u05d8\u05d5\u05e4\u05dc\u05d9\u05e6\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "OTE",
    AIRPORTNAME: " ",
    CITYCODE: "OTE",
    CITYNAME: "TERME OLIMIA",
    CITYNAME_HEB:
      "\u05d8\u05e8\u05de\u05d4 \u05d0\u05d5\u05dc\u05d9\u05de\u05d9\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CEF",
    AIRPORTNAME: null,
    CITYCODE: "CEF",
    CITYNAME: "CEFALU",
    CITYNAME_HEB: "\u05e7\u05d0\u05e4\u05d0\u05dc\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DEK",
    AIRPORTNAME: " ",
    CITYCODE: "DEK",
    CITYNAME: "DENMARK",
    CITYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
    COUNTRYCODE: "DK",
    COUNTRYNAME: "DENMARK",
    COUNTRYNAME_HEB: "\u05d3\u05e0\u05de\u05e8\u05e7",
  },
  {
    AIRPORTCODE: "TGD",
    AIRPORTNAME: " ",
    CITYCODE: "TGD",
    CITYNAME: "PODGORICA",
    CITYNAME_HEB:
      "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5 \u05e4\u05d5\u05d3\u05d2\u05d5\u05e8\u05d9\u05e6\u05d4",
    COUNTRYCODE: "ME",
    COUNTRYNAME: "MONTENEGRO",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "PKI",
    AIRPORTNAME: null,
    CITYCODE: "PKI",
    CITYNAME: "PRACHUAP KHIRI KHAN",
    CITYNAME_HEB:
      "\u05e4\u05e8\u05e9\u05d5\u05e4 \u05e7\u05d9\u05e8\u05d9 \u05e7\u05d0\u05d4\u05df",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "NUA",
    AIRPORTNAME: " ",
    CITYCODE: "NUA",
    CITYNAME: "NUWARA ELIYA",
    CITYNAME_HEB: "\u05e0\u05d5\u05d0\u05e8\u05d4 \u05d0\u05dc\u05d9\u05d4",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "NEO",
    AIRPORTNAME: " ",
    CITYCODE: "NEO",
    CITYNAME: "NEGOMBO",
    CITYNAME_HEB: "\u05e0\u05d2\u05d5\u05de\u05d1\u05d5",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "ZTU",
    AIRPORTNAME: " ",
    CITYCODE: "ZTU",
    CITYNAME: "KALUTARA",
    CITYNAME_HEB: "\u05e7\u05dc\u05d5\u05d8\u05d0\u05e8\u05d4",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "TDP",
    AIRPORTNAME: null,
    CITYCODE: "TDP",
    CITYNAME: "TORRES DEL PAINE",
    CITYNAME_HEB:
      "\u05d8\u05d5\u05e8\u05e1 \u05d3\u05dc \u05e4\u05d9\u05d9\u05e0\u05d4",
    COUNTRYCODE: "CL",
    COUNTRYNAME: "CHILE",
    COUNTRYNAME_HEB: "\u05e6\u05d9\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "GNH",
    AIRPORTNAME: null,
    CITYCODE: "GNH",
    CITYNAME: "SNAGOV",
    CITYNAME_HEB: "\u05e1\u05e0\u05d0\u05d2\u05d5\u05d1",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZTC",
    AIRPORTNAME: " ",
    CITYCODE: "ZTC",
    CITYNAME: "STRBSKE PLESO",
    CITYNAME_HEB:
      "\u05e1\u05d8\u05e8\u05d1\u05e1\u05e7\u05d4 \u05e4\u05dc\u05d0\u05e1\u05d5",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UZB",
    AIRPORTNAME: " ",
    CITYCODE: "UZB",
    CITYNAME: "UZBEKISTAN",
    CITYNAME_HEB: "\u05d0\u05d5\u05d6\u05d1\u05e7\u05d9\u05e1\u05d8\u05df",
    COUNTRYCODE: "UZ",
    COUNTRYNAME: "UZBEKISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d6\u05d1\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: "CIB",
    AIRPORTNAME: null,
    CITYCODE: "CIB",
    CITYNAME: "COSTINESTI",
    CITYNAME_HEB: "\u05e7\u05d5\u05e1\u05d8\u05d9\u05e0\u05e9",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PZF",
    AIRPORTNAME: null,
    CITYCODE: "PZF",
    CITYNAME: "POZZA DI FASSA",
    CITYNAME_HEB:
      "\u05e4\u05d5\u05d6\u05d4 \u05d3\u05d9 \u05e4\u05d0\u05e1\u05e1\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALT",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IEA",
    AIRPORTNAME: null,
    CITYCODE: "IEA",
    CITYNAME: "PIERIA",
    CITYNAME_HEB: "\u05e4\u05d9\u05d0\u05e8\u05d9\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GRECEE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "YYO",
    AIRPORTNAME: null,
    CITYCODE: "YYO",
    CITYNAME: "JAFFA",
    CITYNAME_HEB: "\u05d9\u05e4\u05d5",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "PII",
    AIRPORTNAME: "\u05e4\u05e7\u05d9\u05e2\u05d9\u05df",
    CITYCODE: "PII",
    CITYNAME: "PEKIIN",
    CITYNAME_HEB: "\u05e4\u05e7\u05d9\u05e2\u05d9\u05df",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "JUS",
    AIRPORTNAME: "\u05d2\u05d5\u05dc\u05d9\u05e1",
    CITYCODE: "JUS",
    CITYNAME: "JULIS",
    CITYNAME_HEB: "\u05d2\u05d5\u05dc\u05d9\u05e1",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ROF",
    AIRPORTNAME: "\u05e9\u05d3\u05e8\u05d5\u05ea",
    CITYCODE: "ROF",
    CITYNAME: "SDEROT",
    CITYNAME_HEB: "\u05e9\u05d3\u05e8\u05d5\u05ea",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZUA",
    AIRPORTNAME: null,
    CITYCODE: "ZUA",
    CITYNAME: "MUSANZE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "RW",
    COUNTRYNAME: "RWANDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AEU",
    AIRPORTNAME: "PIRAEUS",
    CITYCODE: "AEU",
    CITYNAME: "PIRAEUS",
    CITYNAME_HEB: "\u05e4\u05d9\u05e8\u05d0\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "NTF",
    AIRPORTNAME: null,
    CITYCODE: "NTF",
    CITYNAME: "NYAGATARE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "RW",
    COUNTRYNAME: "RWANDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "CVA",
    AIRPORTNAME: null,
    CITYCODE: "CVA",
    CITYNAME: "CHAKVI",
    CITYNAME_HEB: "\u05d7\u05d0\u05e7\u05d1\u05d9",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ORI",
    AIRPORTNAME: null,
    CITYCODE: "ORI",
    CITYNAME: "CORINTHOS",
    CITYNAME_HEB: "\u05e7\u05d5\u05e8\u05d9\u05e0\u05d8\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "PEO",
    AIRPORTNAME: null,
    CITYCODE: "PEO",
    CITYNAME: "PETROVAC",
    CITYNAME_HEB: "\u05e4\u05d8\u05e8\u05d5\u05d1\u05d0\u05e7",
    COUNTRYCODE: "ME",
    COUNTRYNAME: "MONTENEGRO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "PIY",
    AIRPORTNAME: null,
    CITYCODE: "PIY",
    CITYNAME: "PINZOLO",
    CITYNAME_HEB: "\u05e4\u05d9\u05e0\u05d6\u05d5\u05dc\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ICF",
    AIRPORTNAME: null,
    CITYCODE: "ICF",
    CITYNAME: "BECICI",
    CITYNAME_HEB: "\u05d1\u05e6\u05d9\u05e6\u05d9",
    COUNTRYCODE: "ME",
    COUNTRYNAME: "MONTENEGRO",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "REM",
    AIRPORTNAME: null,
    CITYCODE: "REM",
    CITYNAME: "RIMSKE TERME",
    CITYNAME_HEB:
      "\u05e8\u05d9\u05de\u05e1\u05e7\u05d4 \u05ea\u05e8\u05de\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DIF",
    AIRPORTNAME: null,
    CITYCODE: "DIF",
    CITYNAME: "VIGO DI FASSA",
    CITYNAME_HEB:
      "\u05d5\u05d9\u05d2\u05d5 \u05d3\u05d9 \u05e4\u05d0\u05e1\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "LMW",
    AIRPORTNAME: null,
    CITYCODE: "LMW",
    CITYNAME: "LOUISVILLE",
    CITYNAME_HEB: "\u05dc\u05d5\u05d0\u05d9\u05d5\u05d5\u05d9\u05dc",
    COUNTRYCODE: "USMS",
    COUNTRYNAME: "MISSISSIPI",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e1\u05e1\u05d9\u05e4\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "OUC",
    AIRPORTNAME: null,
    CITYCODE: "OUC",
    CITYNAME: "BATOURI",
    CITYNAME_HEB: "\u05d1\u05d0\u05d8\u05d5\u05e8\u05d9",
    COUNTRYCODE: "CM",
    COUNTRYNAME: "CAMEROON-REPUBLIC OF",
    COUNTRYNAME_HEB: "\u05e7\u05de\u05e8\u05d5\u05df",
  },
  {
    AIRPORTCODE: "RPC",
    AIRPORTNAME: null,
    CITYCODE: "RPC",
    CITYNAME: "\u05e4\u05d9\u05d9\u05e8",
    CITYNAME_HEB: "PIERRE",
    COUNTRYCODE: "USSD",
    COUNTRYNAME: "SOUTH DAKOTA",
    COUNTRYNAME_HEB:
      '\u05d3\u05e7\u05d5\u05d8\u05d4 \u05d4\u05d3\u05e8\u05d5\u05de\u05d9\u05ea (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "MED",
    AIRPORTNAME: null,
    CITYCODE: "MED",
    CITYNAME: "MEDULIN",
    CITYNAME_HEB: "\u05de\u05d3\u05d5\u05dc\u05d9\u05df",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NIH",
    AIRPORTNAME: " ",
    CITYCODE: "NIA",
    CITYNAME: "Slanic-moldova",
    CITYNAME_HEB: "\u05e1\u05dc\u05e0\u05d9\u05e7",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05dc\u05d3\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "WIE",
    AIRPORTNAME: null,
    CITYCODE: "WIE",
    CITYNAME: "WIESELBURG",
    CITYNAME_HEB: "\u05d5\u05d9\u05e1\u05dc\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XPO",
    AIRPORTNAME: null,
    CITYCODE: "XPO",
    CITYNAME: "PONGAU",
    CITYNAME_HEB: "\u05e4\u05d5\u05e0\u05d2\u05d0\u05d5",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "JUC",
    AIRPORTNAME: " ",
    CITYCODE: "JUC",
    CITYNAME: "JURMALA",
    CITYNAME_HEB: "\u05d2\u05d5\u05e8\u05de\u05dc\u05d4",
    COUNTRYCODE: "LV",
    COUNTRYNAME: "LATVIA",
    COUNTRYNAME_HEB: "\u05dc\u05d8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GPE",
    AIRPORTNAME: null,
    CITYCODE: "GPE",
    CITYNAME: "PELION",
    CITYNAME_HEB: "\u05e4\u05d0\u05dc\u05d9\u05d5\u05df",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "AEM",
    AIRPORTNAME: null,
    CITYCODE: "AEM",
    CITYNAME: "ARTA TERME",
    CITYNAME_HEB: "\u05d0\u05e8\u05d8\u05d0 \u05d8\u05e8\u05de\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RTF",
    AIRPORTNAME: null,
    CITYCODE: "RTF",
    CITYNAME: "RAJECKE TEPLICE",
    CITYNAME_HEB:
      "\u05e8\u05d9\u05e7\u05d4 \u05d8\u05e4\u05dc\u05d9\u05e6\u05d4",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NBO",
    AIRPORTNAME: null,
    CITYCODE: "NBO",
    CITYNAME: "NAROBI",
    CITYNAME_HEB: "\u05e0\u05d9\u05d9\u05e8\u05d5\u05d1\u05d9",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NTH",
    AIRPORTNAME: null,
    CITYCODE: "NTH",
    CITYNAME: "SANTA CRUZ DE TENERIFE",
    CITYNAME_HEB:
      "\u05e1\u05e0\u05d8\u05d4 \u05e7\u05e8\u05d5\u05d6 \u05d3\u05d4 \u05d8\u05e0\u05e8\u05d9\u05e3",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "TGC",
    AIRPORTNAME: " ",
    CITYCODE: "TGC",
    CITYNAME: "TOGGENBURG",
    CITYNAME_HEB: "\u05d8\u05d5\u05d2\u05e0\u05d1\u05d5\u05e8\u05d2",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "DWR",
    AIRPORTNAME: " ",
    CITYCODE: "DWR",
    CITYNAME: "DWARFLAND",
    CITYNAME_HEB: "\u05d3\u05d5\u05d5\u05d0\u05e8\u05e4\u05dc\u05e0\u05d3",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "DAW",
    AIRPORTNAME: null,
    CITYCODE: "DAW",
    CITYNAME: "SANDANE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "FED",
    AIRPORTNAME: null,
    CITYCODE: "FED",
    CITYNAME: "FELDBERG",
    CITYNAME_HEB: "\u05e4\u05dc\u05d3\u05d1\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "FCU",
    CITYNAME: "CHEFCHAOUEN",
    CITYNAME_HEB: "\u05e6\u05e4\u05e6\u05d0\u05d5\u05df",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "ESJ",
    CITYNAME: "COSTA DEL SOL",
    CITYNAME_HEB:
      "\u05e7\u05d5\u05e1\u05d8\u05d4 \u05d3\u05dc \u05e1\u05d5\u05dc",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "JAJ",
    CITYNAME: "DILIJAN",
    CITYNAME_HEB: "\u05d3\u05d9\u05dc\u05d9\u05d2\u05d0\u05df",
    COUNTRYCODE: "AM",
    COUNTRYNAME: "ARMENIA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RSC",
    AIRPORTNAME: null,
    CITYCODE: "RSC",
    CITYNAME: "RISHIKESH",
    CITYNAME_HEB: "\u05e8\u05d9\u05e9\u05e7\u05e9",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "CDX",
    AIRPORTNAME: null,
    CITYCODE: "CDX",
    CITYNAME: "CADIS",
    CITYNAME_HEB: "\u05e7\u05d0\u05d3\u05d9\u05e1",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "RRA",
    AIRPORTNAME: null,
    CITYCODE: "RRA",
    CITYNAME: "RONDA",
    CITYNAME_HEB: "\u05e8\u05d5\u05e0\u05d3\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "RMO",
    AIRPORTNAME: null,
    CITYCODE: "KIV",
    CITYNAME: "CHISINAU",
    CITYNAME_HEB: "\u05e7\u05d9\u05e9\u05d9\u05e0\u05d1",
    COUNTRYCODE: "MD",
    COUNTRYNAME: "MOLDOVA",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05dc\u05d3\u05d5\u05d1\u05d4",
  },
  {
    AIRPORTCODE: "UGD",
    AIRPORTNAME: null,
    CITYCODE: "UGD",
    CITYNAME: "UGANDA",
    CITYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
    COUNTRYCODE: "UG",
    COUNTRYNAME: "UGANDA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d2\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "TWI",
    AIRPORTNAME: " ",
    CITYCODE: "TWI",
    CITYNAME: "TAIWAN",
    CITYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
    COUNTRYCODE: "TW",
    COUNTRYNAME: "TAIWAN",
    COUNTRYNAME_HEB: "\u05d8\u05d9\u05d5\u05d5\u05d0\u05df",
  },
  {
    AIRPORTCODE: "KYJ",
    AIRPORTNAME: " ",
    CITYCODE: "KYJ",
    CITYNAME: "KENYA",
    CITYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
    COUNTRYCODE: "KE",
    COUNTRYNAME: "KENYA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "IKC",
    AIRPORTNAME: null,
    CITYCODE: "IKC",
    CITYNAME: "KASTROSIKIA",
    CITYNAME_HEB:
      "\u05e7\u05e1\u05d8\u05e8\u05d5\u05e1\u05d9\u05e7\u05d9\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "EFN",
    AIRPORTNAME: null,
    CITYCODE: "EFN",
    CITYNAME: "EFORIE NORD",
    CITYNAME_HEB:
      "\u05d0\u05e4\u05d5\u05e8\u05d9\u05d4 \u05e0\u05d5\u05e8\u05d3",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KPZ",
    AIRPORTNAME: null,
    CITYCODE: "KPZ",
    CITYNAME: "KARPACZ",
    CITYNAME_HEB: "\u05e7\u05e8\u05e4\u05e6",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "SWZ",
    AIRPORTNAME: null,
    CITYCODE: "SWZ",
    CITYNAME: "SWIERADOW ZDROJ",
    CITYNAME_HEB:
      "\u05e9\u05d5\u05d9\u05e8\u05d0\u05d3\u05d5\u05d1 \u05d6\u05d3\u05e8\u05d0\u05d5\u05d2",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "KZT",
    CITYNAME: "Katzrin",
    CITYNAME_HEB: "\u05e7\u05e6\u05e8\u05d9\u05df",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: " ",
    CITYCODE: "ZFT",
    CITYNAME: "Zefat",
    CITYNAME_HEB: "\u05e6\u05e4\u05ea",
    COUNTRYCODE: "IL",
    COUNTRYNAME: "ISRAEL",
    COUNTRYNAME_HEB: "\u05d9\u05e9\u05e8\u05d0\u05dc",
  },
  {
    AIRPORTCODE: "GGV",
    AIRPORTNAME: "GENEVA A/P FRENCH SI",
    CITYCODE: "GGV",
    CITYNAME: "GENEVA A/P FRENCH SI",
    CITYNAME_HEB: "GENEVA A/P FRENCH SI",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "IGI",
    AIRPORTNAME: null,
    CITYCODE: "IGI",
    CITYNAME: "KINIGI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "RW",
    COUNTRYNAME: "RWANDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "EKG",
    AIRPORTNAME: null,
    CITYCODE: "EKG",
    CITYNAME: "AKAGERA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "RW",
    COUNTRYNAME: "RWANDA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "HOJ",
    AIRPORTNAME: null,
    CITYCODE: "HOJ",
    CITYNAME: "DIERSSEN BAARSCHOT",
    CITYNAME_HEB:
      "\u05d3\u05d9\u05d0\u05e8\u05e1\u05d0\u05df \u05d1\u05d0\u05e8\u05e9\u05db\u05d5\u05ea",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "AZH",
    CITYNAME: "GABALA",
    CITYNAME_HEB: "\u05e7\u05d1\u05d0\u05dc\u05d4",
    COUNTRYCODE: "AZ",
    COUNTRYNAME: "AZERBAIJAN",
    COUNTRYNAME_HEB: "\u05d0\u05d6\u05e8\u05d1\u05d9\u05d9\u05d2\u05df",
  },
  {
    AIRPORTCODE: "LNA",
    AIRPORTNAME: null,
    CITYCODE: "LNA",
    CITYNAME: "LOANO",
    CITYNAME_HEB: "\u05dc\u05d5\u05d0\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ISY",
    AIRPORTNAME: null,
    CITYCODE: "ISY",
    CITYNAME: "SAN REMO",
    CITYNAME_HEB: "\u05e1\u05d0\u05df \u05e8\u05de\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LVV",
    AIRPORTNAME: null,
    CITYCODE: "LVV",
    CITYNAME: "AVILA",
    CITYNAME_HEB: "\u05d0\u05d5\u05d5\u05d9\u05dc\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "TSF",
    AIRPORTNAME: null,
    CITYCODE: "TSF",
    CITYNAME: "TREVISO",
    CITYNAME_HEB: "\u05d8\u05e8\u05d5\u05d5\u05d9\u05e1\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SYE",
    AIRPORTNAME: null,
    CITYCODE: "SYE",
    CITYNAME: "STEYR",
    CITYNAME_HEB: "\u05e1\u05d8\u05d0\u05d9\u05d9\u05e8",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VET",
    AIRPORTNAME: " ",
    CITYCODE: "VET",
    CITYNAME: "VELIKO TARNOVO",
    CITYNAME_HEB:
      "\u05d5\u05dc\u05d9\u05e7\u05d5 \u05d8\u05d0\u05e8\u05e0\u05d5\u05d1\u05d5",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SXC",
    AIRPORTNAME: null,
    CITYCODE: "SXC",
    CITYNAME: "SOUTH AMERICA",
    CITYNAME_HEB:
      "\u05d3\u05e8\u05d5\u05dd \u05d0\u05de\u05e8\u05d9\u05e7\u05d4",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NNH",
    AIRPORTNAME: null,
    CITYCODE: "NNH",
    CITYNAME: "ANKARAN",
    CITYNAME_HEB: "\u05d0\u05e0\u05e7\u05e8\u05df",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VRD",
    AIRPORTNAME: " ",
    CITYCODE: "VRD",
    CITYNAME: "VRDNIK",
    CITYNAME_HEB: "\u05d5\u05e8\u05d3\u05e0\u05d9\u05e7",
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ICB",
    AIRPORTNAME: null,
    CITYCODE: "ICB",
    CITYNAME: "CORATO",
    CITYNAME_HEB: "\u05e7\u05d5\u05e8\u05d0\u05d8\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CUH",
    AIRPORTNAME: null,
    CITYCODE: "CUH",
    CITYNAME: "CUBA",
    CITYNAME_HEB: "\u05e7\u05d5\u05d1\u05d4",
    COUNTRYCODE: "CB",
    COUNTRYNAME: "CRUISE",
    COUNTRYNAME_HEB:
      "\u05e9\u05d9\u05d9\u05d8 \u05d1\u05d0\u05d5\u05e0\u05d9\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RVG",
    AIRPORTNAME: null,
    CITYCODE: "RVG",
    CITYNAME: "ROVIGO",
    CITYNAME_HEB: " \u05e8\u05d5\u05d1\u05d9\u05d2\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XAA",
    AIRPORTNAME: null,
    CITYCODE: "XAA",
    CITYNAME: "ALEXANDRIA",
    CITYNAME_HEB:
      "\u05d0\u05dc\u05db\u05e1\u05e0\u05d3\u05e8\u05d9\u05d9\u05d4",
    COUNTRYCODE: "EG",
    COUNTRYNAME: "EGYPT",
    COUNTRYNAME_HEB: "\u05de\u05e6\u05e8\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "XCI",
    AIRPORTNAME: null,
    CITYCODE: "XCI",
    CITYNAME: "CHAMBORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCK",
    AIRPORTNAME: null,
    CITYCODE: "XCK",
    CITYNAME: "SAINT DIE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCL",
    AIRPORTNAME: "Cluff Lake",
    CITYCODE: "XCL",
    CITYNAME: "CLUFF LAKE",
    CITYNAME_HEB: "CLUFF LAKE",
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XCM",
    AIRPORTNAME: "Chatham",
    CITYCODE: "XCM",
    CITYNAME: "CHATHAM",
    CITYNAME_HEB: "CHATHAM",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BRB",
    AIRPORTNAME: null,
    CITYCODE: "BRB",
    CITYNAME: "BARREIRINHAS",
    CITYNAME_HEB: "\u05d1\u05e8\u05d9\u05e8\u05e0\u05d0\u05e1",
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d6\u05d9\u05dc",
  },
  {
    AIRPORTCODE: "XCN",
    AIRPORTNAME: "Coron",
    CITYCODE: "XCN",
    CITYNAME: "CORON",
    CITYNAME_HEB: "CORON",
    COUNTRYCODE: "PH",
    COUNTRYNAME: "PHILIPPINES",
    COUNTRYNAME_HEB: "\u05e4\u05d9\u05dc\u05d9\u05e4\u05d9\u05e0\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "XCO",
    AIRPORTNAME: "Colac",
    CITYCODE: "XCO",
    CITYNAME: "COLAC",
    CITYNAME_HEB: "COLAC",
    COUNTRYCODE: "AUVI",
    COUNTRYNAME: "VICTORIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XCP",
    AIRPORTNAME: null,
    CITYCODE: "XCP",
    CITYNAME: "COMPIEGNE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCQ",
    AIRPORTNAME: null,
    CITYCODE: "XCQ",
    CITYNAME: "CHAMROUSSE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCR",
    AIRPORTNAME: null,
    CITYCODE: "XCR",
    CITYNAME: "CHALONS EN CHAMPAGNE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCS",
    AIRPORTNAME: null,
    CITYCODE: "XCS",
    CITYNAME: "CAUSSADE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCT",
    AIRPORTNAME: null,
    CITYCODE: "XCT",
    CITYNAME: "LA CIOTAT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCU",
    AIRPORTNAME: null,
    CITYCODE: "XCU",
    CITYNAME: "COLLIOURE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCV",
    AIRPORTNAME: null,
    CITYCODE: "XCV",
    CITYNAME: "CHANTILLY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCW",
    AIRPORTNAME: null,
    CITYCODE: "XCW",
    CITYNAME: "CHAUMONT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRC",
    AIRPORTNAME: " ",
    CITYCODE: "BRC",
    CITYNAME: "SAN CARLOS DE BARILOCHE",
    CITYNAME_HEB:
      "\u05e1\u05d0\u05df \u05e7\u05e8\u05dc\u05d5\u05e1 \u05d3\u05d4 \u05d1\u05e8\u05d9\u05dc\u05d5\u05e6\u05d4",
    COUNTRYCODE: "AR",
    COUNTRYNAME: "ARGENTINA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05d2\u05e0\u05d8\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "XCX",
    AIRPORTNAME: null,
    CITYCODE: "XCX",
    CITYNAME: "CHATELLERAULT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCY",
    AIRPORTNAME: null,
    CITYCODE: "XCY",
    CITYNAME: "CHATEAU THIERRY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XCZ",
    AIRPORTNAME: null,
    CITYCODE: "XCZ",
    CITYNAME: "CHARLEVILLE MEZIE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XDA",
    AIRPORTNAME: " ",
    CITYCODE: "XDA",
    CITYNAME: "DAX",
    CITYNAME_HEB: "\u05d3\u05d0\u05e7\u05e1",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "XDC",
    AIRPORTNAME: null,
    CITYCODE: "XDC",
    CITYNAME: "DIVES CABOURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XDE",
    AIRPORTNAME: "Diebougou",
    CITYCODE: "XDE",
    CITYNAME: "DIEBOUGOU",
    CITYNAME_HEB: "DIEBOUGOU",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "XDI",
    AIRPORTNAME: null,
    CITYCODE: "XDI",
    CITYNAME: "DIGNE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XDJ",
    AIRPORTNAME: "Djibo",
    CITYCODE: "XDJ",
    CITYNAME: "DJIBO",
    CITYNAME_HEB: "DJIBO",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "XDK",
    AIRPORTNAME: null,
    CITYCODE: "XDK",
    CITYNAME: "DUNKERQUE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XDL",
    AIRPORTNAME: null,
    CITYCODE: "XDL",
    CITYNAME: "CHANDLER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRD",
    AIRPORTNAME: "Crow Wing County",
    CITYCODE: "BRD",
    CITYNAME: "BRAINERD",
    CITYNAME_HEB: "BRAINERD",
    COUNTRYCODE: "USMN",
    COUNTRYNAME: "MINNESOTA",
    COUNTRYNAME_HEB:
      '\u05de\u05d9\u05e0\u05d9\u05e1\u05d5\u05d8\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XDM",
    AIRPORTNAME: null,
    CITYCODE: "XDM",
    CITYNAME: "DRUMMONDVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XDN",
    AIRPORTNAME: null,
    CITYCODE: "XDN",
    CITYNAME: "DOUAI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XDO",
    AIRPORTNAME: null,
    CITYCODE: "XDO",
    CITYNAME: "GRANDE RIVIERE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XDR",
    AIRPORTNAME: null,
    CITYCODE: "XDR",
    CITYNAME: "DREUX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XDU",
    AIRPORTNAME: null,
    CITYCODE: "XDU",
    CITYNAME: "HERVEY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XEB",
    AIRPORTNAME: null,
    CITYCODE: "XEB",
    CITYNAME: "EVIAN LES BAINS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XEE",
    AIRPORTNAME: null,
    CITYCODE: "XEE",
    CITYNAME: "LAC EDOUARD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XEH",
    AIRPORTNAME: null,
    CITYCODE: "XEH",
    CITYNAME: "LADYSMITH",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XEI",
    AIRPORTNAME: null,
    CITYCODE: "XEI",
    CITYNAME: "TSUKUBA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "JP",
    COUNTRYNAME: "JAPAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XEJ",
    AIRPORTNAME: null,
    CITYCODE: "XEJ",
    CITYNAME: "LANGFORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRE",
    AIRPORTNAME: "Bremen",
    CITYCODE: "BRE",
    CITYNAME: "BREMEN",
    CITYNAME_HEB: "\u05d1\u05e8\u05de\u05df",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XEK",
    AIRPORTNAME: null,
    CITYCODE: "XEK",
    CITYNAME: "MELVILLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CASK",
    COUNTRYNAME: "SASKATCHEWAN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XEL",
    AIRPORTNAME: null,
    CITYCODE: "XEL",
    CITYNAME: "NEW CARLISLE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XEM",
    AIRPORTNAME: null,
    CITYCODE: "XEM",
    CITYNAME: "NEW RICHMOND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XEN",
    AIRPORTNAME: "Xingcheng",
    CITYCODE: "XEN",
    CITYNAME: "XINGCHENG",
    CITYNAME_HEB: "XINGCHENG",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA -EXCL HONG KONG SA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XEO",
    AIRPORTNAME: null,
    CITYCODE: "XEO",
    CITYNAME: "OQATSUT HARBOUR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XEP",
    AIRPORTNAME: null,
    CITYCODE: "XEP",
    CITYNAME: "EPERNAY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XEQ",
    AIRPORTNAME: null,
    CITYCODE: "XEQ",
    CITYNAME: "TASIUASAQ HARBOUR",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GL",
    COUNTRYNAME: "GREENLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XES",
    AIRPORTNAME: "Municipal",
    CITYCODE: "XES",
    CITYNAME: "LAKE GENEVA",
    CITYNAME_HEB: "LAKE GENEVA",
    COUNTRYCODE: "USWI",
    COUNTRYNAME: "WISCONSIN",
    COUNTRYNAME_HEB:
      '\u05d5\u05d9\u05e1\u05e7\u05d5\u05e0\u05e1\u05d9\u05df (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XEU",
    AIRPORTNAME: null,
    CITYCODE: "XEU",
    CITYNAME: "ARLANDA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XEW",
    AIRPORTNAME: null,
    CITYCODE: "XEW",
    CITYNAME: "FLEMINGSBERG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRF",
    AIRPORTNAME: "Bradford",
    CITYCODE: "BRF",
    CITYNAME: "BRADFORD",
    CITYNAME_HEB: "\u05d1\u05d3\u05e4\u05d5\u05e8\u05d3",
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: "\u05d1\u05e8\u05d9\u05d8\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XFA",
    AIRPORTNAME: null,
    CITYCODE: "XFA",
    CITYNAME: "LILLE FLANDRES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFB",
    AIRPORTNAME: null,
    CITYCODE: "XFB",
    CITYNAME: "FONTAINEBLEAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFC",
    AIRPORTNAME: null,
    CITYCODE: "XFC",
    CITYNAME: "FREDERICTON JUNC",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CANB",
    COUNTRYNAME: "NEW BRUNSWICK",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFD",
    AIRPORTNAME: null,
    CITYCODE: "XFD",
    CITYNAME: "STRATFORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFE",
    AIRPORTNAME: null,
    CITYCODE: "XFE",
    CITYNAME: "PARENT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFG",
    AIRPORTNAME: null,
    CITYCODE: "XFG",
    CITYNAME: "PERCE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFH",
    AIRPORTNAME: " ",
    CITYCODE: "XFH",
    CITYNAME: "STRANGNAS",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: "\u05e9\u05d1\u05d3\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XFI",
    AIRPORTNAME: null,
    CITYCODE: "XFI",
    CITYNAME: "PORT DANIEL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFK",
    AIRPORTNAME: null,
    CITYCODE: "XFK",
    CITYNAME: "SENNETERRE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "ZFC",
    AIRPORTNAME: null,
    CITYCODE: "BRF",
    CITYNAME: "BRADFORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFL",
    AIRPORTNAME: null,
    CITYCODE: "XFL",
    CITYNAME: "SHAWINIGAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFM",
    AIRPORTNAME: null,
    CITYCODE: "XFM",
    CITYNAME: "SHAWNIGAN",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFN",
    AIRPORTNAME: "Xiangfan",
    CITYCODE: "XFN",
    CITYNAME: "XIANGFAN",
    CITYNAME_HEB: "XIANGFAN",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XFO",
    AIRPORTNAME: null,
    CITYCODE: "XFO",
    CITYNAME: "TASCHEREAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFQ",
    AIRPORTNAME: null,
    CITYCODE: "XFQ",
    CITYNAME: "WEYMONT",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFS",
    AIRPORTNAME: null,
    CITYCODE: "XFS",
    CITYNAME: "ALEXANDRIA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFT",
    AIRPORTNAME: " ",
    CITYCODE: "XFT",
    CITYNAME: "FOTAN",
    CITYNAME_HEB: " ",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "XFU",
    AIRPORTNAME: null,
    CITYCODE: "XFU",
    CITYNAME: "TIERP",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFV",
    AIRPORTNAME: null,
    CITYCODE: "XFV",
    CITYNAME: "BRANTFORD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XFW",
    AIRPORTNAME: "Finkenwerder",
    CITYCODE: "XFW",
    CITYNAME: "FINKENWERDER",
    CITYNAME_HEB: "FINKENWERDER",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BRG",
    AIRPORTNAME: "Municipal",
    CITYCODE: "BRG",
    CITYNAME: "WHITESBURG",
    CITYNAME_HEB: "WHITESBURG",
    COUNTRYCODE: "USKY",
    COUNTRYNAME: "KENTUCKY",
    COUNTRYNAME_HEB:
      '\u05e7\u05e0\u05d8\u05e7\u05d9 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "XFX",
    AIRPORTNAME: null,
    CITYCODE: "XFX",
    CITYNAME: "FOIX",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGA",
    AIRPORTNAME: "Gaoua",
    CITYCODE: "XGA",
    CITYNAME: "GAOUA",
    CITYNAME_HEB: "GAOUA",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "XGC",
    AIRPORTNAME: null,
    CITYCODE: "XGC",
    CITYNAME: "LUND",
    CITYNAME_HEB: null,
    COUNTRYCODE: "SE",
    COUNTRYNAME: "SWEDEN",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGD",
    AIRPORTNAME: null,
    CITYCODE: "XGD",
    CITYNAME: "ARENDAL",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGF",
    AIRPORTNAME: null,
    CITYCODE: "XGF",
    CITYNAME: "SAINT GERVAIS LE FAY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGG",
    AIRPORTNAME: "Gorom-Gorom",
    CITYCODE: "XGG",
    CITYNAME: "GOROM GOROM",
    CITYNAME_HEB: "GOROM GOROM",
    COUNTRYCODE: "BF",
    COUNTRYNAME: "BURKINO FASO",
    COUNTRYNAME_HEB:
      "\u05d1\u05d5\u05e8\u05e7\u05d9\u05e0\u05d0\u05d5 \u05e4\u05d0\u05e1\u05d5",
  },
  {
    AIRPORTCODE: "XGH",
    AIRPORTNAME: null,
    CITYCODE: "XGH",
    CITYNAME: "FLAM",
    CITYNAME_HEB: "\u05e4\u05dc\u05d0\u05dd",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d5\u05d5\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XGI",
    AIRPORTNAME: null,
    CITYCODE: "XGI",
    CITYNAME: "ANDALSNES",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGJ",
    AIRPORTNAME: null,
    CITYCODE: "XGJ",
    CITYNAME: "COBOURG",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGK",
    AIRPORTNAME: null,
    CITYCODE: "XGK",
    CITYNAME: "COTEAU",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "AEK",
    AIRPORTNAME: "Aseki",
    CITYCODE: "AEK",
    CITYNAME: "ASEKI",
    CITYNAME_HEB: "ASEKI",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "BRH",
    AIRPORTNAME: "Brahman",
    CITYCODE: "BRH",
    CITYNAME: "BRAHMAN",
    CITYNAME_HEB: "BRAHMAN",
    COUNTRYCODE: "PG",
    COUNTRYNAME: "PAPUA NEW GUINEA",
    COUNTRYNAME_HEB:
      "\u05e4\u05d0\u05e4\u05d5\u05d0\u05d4 \u05e0\u05d9\u05d5 \u05d2\u05d9\u05e0\u05d0\u05d4",
  },
  {
    AIRPORTCODE: "XGL",
    AIRPORTNAME: null,
    CITYCODE: "XGL",
    CITYNAME: "GRANVILLE LAKE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGM",
    AIRPORTNAME: null,
    CITYCODE: "XGM",
    CITYNAME: "GRANTHAM",
    CITYNAME_HEB: null,
    COUNTRYCODE: "GB",
    COUNTRYNAME: "UNITED KINGDOM",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGN",
    AIRPORTNAME: "Xangongo",
    CITYCODE: "XGN",
    CITYNAME: "XANGONGO",
    CITYNAME_HEB: "XANGONGO",
    COUNTRYCODE: "AO",
    COUNTRYNAME: "ANGOLA",
    COUNTRYNAME_HEB: "\u05d0\u05e0\u05d2\u05d5\u05dc\u05d4",
  },
  {
    AIRPORTCODE: "XGO",
    AIRPORTNAME: null,
    CITYCODE: "XGO",
    CITYNAME: "SANTIAGO",
    CITYNAME_HEB: null,
    COUNTRYCODE: "BR",
    COUNTRYNAME: "BRAZIL",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGP",
    AIRPORTNAME: null,
    CITYCODE: "XGP",
    CITYNAME: "DOMBAS",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGQ",
    AIRPORTNAME: null,
    CITYCODE: "XGQ",
    CITYNAME: "SAARISELKA",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGR",
    AIRPORTNAME: "Kangiqsualujjuaq",
    CITYCODE: "XGR",
    CITYNAME: "KANGIQSUALUJJUAQ",
    CITYNAME_HEB: "KANGIQSUALUJJUAQ",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XGS",
    AIRPORTNAME: null,
    CITYCODE: "XGS",
    CITYNAME: "GJERSTAD",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGT",
    AIRPORTNAME: null,
    CITYCODE: "XGT",
    CITYNAME: "GUERET",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGU",
    AIRPORTNAME: null,
    CITYCODE: "XGU",
    CITYNAME: "ASKER",
    CITYNAME_HEB: null,
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "BRI",
    AIRPORTNAME: "Palese",
    CITYCODE: "BRI",
    CITYNAME: "BARI",
    CITYNAME_HEB: "\u05d1\u05d0\u05e8\u05d9",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "XGV",
    AIRPORTNAME: null,
    CITYCODE: "XGV",
    CITYNAME: "SAINT GILLES CROIX D",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGW",
    AIRPORTNAME: null,
    CITYCODE: "XGW",
    CITYNAME: "GANANOQUE",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGX",
    AIRPORTNAME: null,
    CITYCODE: "XGX",
    CITYNAME: "LEVI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGY",
    AIRPORTNAME: null,
    CITYCODE: "XGY",
    CITYNAME: "GRIMSBY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XGZ",
    AIRPORTNAME: null,
    CITYCODE: "XGZ",
    CITYNAME: "BREGENZ",
    CITYNAME_HEB: "\u05d1\u05e8\u05d2\u05e0\u05e5",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "XHA",
    AIRPORTNAME: null,
    CITYCODE: "XHA",
    CITYNAME: "YLLASJARVI",
    CITYNAME_HEB: null,
    COUNTRYCODE: "FI",
    COUNTRYNAME: "FINLAND",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YRL",
    AIRPORTNAME: "Red Lake",
    CITYCODE: "YRL",
    CITYNAME: "RED LAKE",
    CITYNAME_HEB: "RED LAKE",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRM",
    AIRPORTNAME: "Rocky Mountain House",
    CITYCODE: "YRM",
    CITYNAME: "ROCKY MOUNTAIN HO",
    CITYNAME_HEB: "Rocky Mountain House",
    COUNTRYCODE: "CAAB",
    COUNTRYNAME: "ALBERTA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRN",
    AIRPORTNAME: "Rivers Inlet",
    CITYCODE: "YRN",
    CITYNAME: "RIVERS INLET",
    CITYNAME_HEB: "RIVERS INLET",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRQ",
    AIRPORTNAME: "Trois-Rivieres",
    CITYCODE: "YRQ",
    CITYNAME: "TROIS RIVIERES",
    CITYNAME_HEB: "TROIS RIVIERES",
    COUNTRYCODE: "CAQC",
    COUNTRYNAME: "QUEBEC",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRR",
    AIRPORTNAME: "Stuart Island",
    CITYCODE: "YRR",
    CITYNAME: "STUART ISLAND",
    CITYNAME_HEB: "STUART ISLAND",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRS",
    AIRPORTNAME: "Red Sucker Lake",
    CITYCODE: "YRS",
    CITYNAME: "RED SUCKER LAKE",
    CITYNAME_HEB: "RED SUCKER LAKE",
    COUNTRYCODE: "CAMB",
    COUNTRYNAME: "MANITOBA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRT",
    AIRPORTNAME: "Rankin Inlet",
    CITYCODE: "YRT",
    CITYNAME: "RANKIN INLET",
    CITYNAME_HEB: "RANKIN INLET",
    COUNTRYCODE: "CANU",
    COUNTRYNAME: "NUNAVUT",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YRV",
    AIRPORTNAME: "Revelstoke",
    CITYCODE: "YRV",
    CITYNAME: "REVELSTOKE",
    CITYNAME_HEB: "REVELSTOKE",
    COUNTRYCODE: "CABC",
    COUNTRYNAME: "BRITISH COLUMBIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "YSA",
    AIRPORTNAME: "Sable Island",
    CITYCODE: "YSA",
    CITYNAME: "SABLE ISLAND",
    CITYNAME_HEB: "SABLE ISLAND",
    COUNTRYCODE: "CANS",
    COUNTRYNAME: "NOVA SCOTIA",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "XDY",
    AIRPORTNAME: null,
    CITYCODE: "YSB",
    CITYNAME: "SUDBURY",
    CITYNAME_HEB: null,
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: null,
  },
  {
    AIRPORTCODE: "YSB",
    AIRPORTNAME: "Sudbury",
    CITYCODE: "YSB",
    CITYNAME: "SUDBURY",
    CITYNAME_HEB: "SUDBURY",
    COUNTRYCODE: "CAON",
    COUNTRYNAME: "ONTARIO",
    COUNTRYNAME_HEB: "\u05e7\u05e0\u05d3\u05d4",
  },
  {
    AIRPORTCODE: "BJB",
    AIRPORTNAME: null,
    CITYCODE: "BJB",
    CITYNAME: "BOJNICE",
    CITYNAME_HEB: "\u05d1\u05d5\u05d9\u05e0\u05d9\u05e6\u05d4",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "DDA",
    AIRPORTNAME: null,
    CITYCODE: "DDA",
    CITYNAME: "DIDIM",
    CITYNAME_HEB: "\u05d3\u05d9\u05d3\u05d9\u05dd",
    COUNTRYCODE: "TR",
    COUNTRYNAME: "TURKEY",
    COUNTRYNAME_HEB: "\u05d8\u05d5\u05e8\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GSK",
    AIRPORTNAME: null,
    CITYCODE: "GSK",
    CITYNAME: "SKOPELOS",
    CITYNAME_HEB: "\u05e1\u05e7\u05d5\u05e4\u05dc\u05d5\u05e1",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "RNF",
    AIRPORTNAME: null,
    CITYCODE: "RNF",
    CITYNAME: "SAN SEVERINO",
    CITYNAME_HEB: "\u05e1\u05df \u05e1\u05d1\u05e8\u05d9\u05e0\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "SPA",
    AIRPORTNAME: " ",
    CITYCODE: "SPA",
    CITYNAME: "SAPA",
    CITYNAME_HEB: "\u05e1\u05d0\u05e4\u05d0",
    COUNTRYCODE: "VN",
    COUNTRYNAME: "VIETNAM",
    COUNTRYNAME_HEB: "\u05d5\u05d9\u05d9\u05d8\u05e0\u05d0\u05dd",
  },
  {
    AIRPORTCODE: "TTN",
    AIRPORTNAME: " ",
    CITYCODE: "TTN",
    CITYNAME: "TERME TOPOLISCA",
    CITYNAME_HEB:
      "\u05d8\u05e8\u05de\u05d4 \u05d8\u05d5\u05e4\u05d5\u05dc\u05d9\u05e1\u05e7\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RKA",
    AIRPORTNAME: null,
    CITYCODE: "RKA",
    CITYNAME: "RIMSKE TOPLICE",
    CITYNAME_HEB:
      "\u05e8\u05d9\u05de\u05e1\u05e7\u05d4 \u05d8\u05d5\u05e4\u05dc\u05d9\u05e6\u05d4",
    COUNTRYCODE: "SI",
    COUNTRYNAME: "SLOVENIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "TGK",
    AIRPORTNAME: null,
    CITYCODE: "TGK",
    CITYNAME: "TROGIR",
    CITYNAME_HEB: "\u05d8\u05e8\u05d5\u05d2\u05d9\u05e8",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UAA",
    AIRPORTNAME: null,
    CITYCODE: "UAA",
    CITYNAME: "UKRAINE",
    CITYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d9\u05d0\u05e0\u05d4",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d0\u05d9\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "VGC",
    AIRPORTNAME: null,
    CITYCODE: "VGC",
    CITYNAME: "VELICA GORICA",
    CITYNAME_HEB:
      "\u05d5\u05dc\u05d9\u05e7\u05d4 \u05d2\u05d5\u05e8\u05d9\u05e7\u05d4",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MMT",
    AIRPORTNAME: null,
    CITYCODE: "MMT",
    CITYNAME: "MANAMA",
    CITYNAME_HEB: "\u05de\u05e0\u05d0\u05de\u05d4",
    COUNTRYCODE: "BH",
    COUNTRYNAME: "BAHRAIN",
    COUNTRYNAME_HEB: "\u05d1\u05d7\u05e8\u05d9\u05d9\u05df",
  },
  {
    AIRPORTCODE: "NBY",
    AIRPORTNAME: null,
    CITYCODE: "NBY",
    CITYNAME: "LARSEN BAY",
    CITYNAME_HEB: "LARSEN BAY",
    COUNTRYCODE: "USAK",
    COUNTRYNAME: "ALASKA",
    COUNTRYNAME_HEB:
      '\u05d0\u05dc\u05e1\u05e7\u05d4 (\u05d0\u05e8\u05d4"\u05d1)',
  },
  {
    AIRPORTCODE: "VCE",
    AIRPORTNAME: "Venice Marco Polo Airport",
    CITYCODE: "VCE",
    CITYNAME: "VENICE",
    CITYNAME_HEB: "\u05d5\u05e0\u05e6\u05d9\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RSE",
    AIRPORTNAME: null,
    CITYCODE: "RSE",
    CITYNAME: "SERBIA",
    CITYNAME_HEB: "\u05e1\u05e8\u05d1\u05d9\u05d4",
    COUNTRYCODE: "RS",
    COUNTRYNAME: "SERBIA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BZJ",
    AIRPORTNAME: null,
    CITYCODE: "BZJ",
    CITYNAME: "BIALKA TATRZANSKA",
    CITYNAME_HEB:
      "\u05d1\u05d9\u05d0\u05dc\u05e7\u05d4 \u05d8\u05d0\u05d8\u05e8\u05d6\u05d0\u05e0\u05e1\u05e7\u05d4",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: "BKG",
    AIRPORTNAME: null,
    CITYCODE: "BKG",
    CITYNAME: "BANKYA",
    CITYNAME_HEB: "\u05d1\u05e0\u05e7\u05d9\u05d4",
    COUNTRYCODE: "BG",
    COUNTRYNAME: "BULGARIA",
    COUNTRYNAME_HEB: "\u05d1\u05d5\u05dc\u05d2\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MHR",
    AIRPORTNAME: null,
    CITYCODE: "MHR",
    CITYNAME: "MONCHENGLADBACH",
    CITYNAME_HEB:
      "\u05de\u05e0\u05e9\u05e0\u05d2\u05dc\u05d3\u05d1\u05d0\u05da",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KKQ",
    AIRPORTNAME: null,
    CITYCODE: "KKQ",
    CITYNAME: "KALABAKA",
    CITYNAME_HEB: "\u05e7\u05dc\u05d0\u05d1\u05d0\u05e7\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "PID",
    AIRPORTNAME: null,
    CITYCODE: "PID",
    CITYNAME: "PINDAYA",
    CITYNAME_HEB: "\u05e4\u05d9\u05e0\u05d3\u05d0\u05d9\u05d4",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "NYB",
    AIRPORTNAME: null,
    CITYCODE: "NYB",
    CITYNAME: "BAGAN",
    CITYNAME_HEB: "\u05d1\u05d0\u05d2\u05d0\u05df",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "PGJ",
    AIRPORTNAME: null,
    CITYCODE: "PGJ",
    CITYNAME: "PRAGELATO",
    CITYNAME_HEB: "\u05e4\u05e8\u05d2\u05dc\u05d0\u05d8\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PVG",
    AIRPORTNAME: null,
    CITYCODE: "SHA",
    CITYNAME: "SHANGHAI",
    CITYNAME_HEB: "\u05e9\u05e0\u05d7\u05d0\u05d9",
    COUNTRYCODE: "CN",
    COUNTRYNAME: "CHINA",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "DWC",
    AIRPORTNAME: "AL MAKTOUM INTERNATIONAL AIRPORT",
    CITYCODE: "DXB",
    CITYNAME: "DUBAI",
    CITYNAME_HEB: "\u05d3\u05d5\u05d1\u05d0\u05d9",
    COUNTRYCODE: "AE",
    COUNTRYNAME: "UNITED ARAB EMIRATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05d9\u05d7\u05d5\u05d3 \u05d4\u05d0\u05de\u05d9\u05e8\u05d5\u05d9\u05d5\u05ea",
  },
  {
    AIRPORTCODE: "HLO",
    AIRPORTNAME: null,
    CITYCODE: "HLO",
    CITYNAME: "HALLSTATT",
    CITYNAME_HEB: "\u05d4\u05d0\u05dc\u05e9\u05d8\u05d0\u05d8",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NOH",
    AIRPORTNAME: null,
    CITYCODE: "NOH",
    CITYNAME: "NOLA",
    CITYNAME_HEB: "\u05e0\u05d5\u05dc\u05d4",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "KBS",
    AIRPORTNAME: null,
    CITYCODE: "KBS",
    CITYNAME: "KOBULETI",
    CITYNAME_HEB: "\u05db\u05d5\u05d1\u05d5\u05dc\u05ea\u05d9",
    COUNTRYCODE: "GE",
    COUNTRYNAME: "GEORGIA",
    COUNTRYNAME_HEB: "\u05d2\u05d0\u05d5\u05e8\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "MUH",
    AIRPORTNAME: null,
    CITYCODE: "MUH",
    CITYNAME: "BAD BLUMAU",
    CITYNAME_HEB: "\u05d1\u05d0\u05d3 \u05d1\u05dc\u05d5\u05de\u05d0\u05d5",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LCT",
    AIRPORTNAME: " ",
    CITYCODE: "LCT",
    CITYNAME: "LATCHI",
    CITYNAME_HEB: "\u05dc\u05d0\u05d8\u05e1\u05d9",
    COUNTRYCODE: "CY",
    COUNTRYNAME: "CYPRUS",
    COUNTRYNAME_HEB: "\u05e7\u05e4\u05e8\u05d9\u05e1\u05d9\u05df",
  },
  {
    AIRPORTCODE: "YDM",
    AIRPORTNAME: null,
    CITYCODE: "YDM",
    CITYNAME: "KANDY",
    CITYNAME_HEB: "\u05e7\u05d0\u05e0\u05d3\u05d9",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "UWT",
    AIRPORTNAME: null,
    CITYCODE: "UWT",
    CITYNAME: "UNAWATUNA",
    CITYNAME_HEB: "\u05d0\u05d5\u05e0\u05d5\u05d5\u05d8\u05d5\u05e0\u05d4",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "GOX",
    AIRPORTNAME: "Mopa",
    CITYCODE: "GOI",
    CITYNAME: "GOA",
    CITYNAME_HEB: "\u05d2\u05d5\u05d0\u05d4",
    COUNTRYCODE: "IN",
    COUNTRYNAME: "INDIA",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05d3\u05d5",
  },
  {
    AIRPORTCODE: "ORD",
    AIRPORTNAME: "O'Hare International",
    CITYCODE: "ORD",
    CITYNAME: "CHICAGO",
    CITYNAME_HEB: "\u05e9\u05d9\u05e7\u05d2\u05d5",
    COUNTRYCODE: "US",
    COUNTRYNAME: "UNITED STATES",
    COUNTRYNAME_HEB:
      "\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea",
  },
  {
    AIRPORTCODE: "HBE",
    AIRPORTNAME: " ",
    CITYCODE: "HBE",
    CITYNAME: "HABARANA",
    CITYNAME_HEB: "\u05d4\u05d1\u05d0\u05e8\u05e0\u05d4",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "TDC",
    AIRPORTNAME: null,
    CITYCODE: "TDC",
    CITYNAME: "TUSNAD",
    CITYNAME_HEB: "\u05d8\u05d5\u05e1\u05e0\u05d0\u05d3",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "PGP",
    AIRPORTNAME: null,
    CITYCODE: "PGP",
    CITYNAME: "PYATIGORSK",
    CITYNAME_HEB:
      "\u05e4\u05d9\u05d0\u05d8\u05d9\u05d2\u05d5\u05e8\u05e1\u05e7",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "RZB",
    AIRPORTNAME: "PREVEZA",
    CITYCODE: "RZB",
    CITYNAME: "PREVEZA",
    CITYNAME_HEB: "\u05e4\u05e8\u05d1\u05d6\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "MER",
    CITYNAME: "ALMERE",
    CITYNAME_HEB: "\u05d0\u05dc\u05de\u05e8\u05d4",
    COUNTRYCODE: "NL",
    COUNTRYNAME: "NETHERLANDS",
    COUNTRYNAME_HEB: "\u05d4\u05d5\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "ZIA",
    AIRPORTNAME: "Zhukovskiy",
    CITYCODE: "MOW",
    CITYNAME: "MOSCOW",
    CITYNAME_HEB: "\u05de\u05d5\u05e1\u05e7\u05d1\u05d4",
    COUNTRYCODE: "RU",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VDO",
    AIRPORTNAME: null,
    CITYCODE: "VDO",
    CITYNAME: "VATRA DORNEI",
    CITYNAME_HEB:
      "\u05d5\u05d0\u05d8\u05e8\u05d4 \u05d3\u05d5\u05e8\u05e0\u05d9\u05d9",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "BGE",
    AIRPORTNAME: null,
    CITYCODE: "BGE",
    CITYNAME: "BAD GOISERN",
    CITYNAME_HEB: "\u05d1\u05d0\u05d3 \u05d2\u05d5\u05d9\u05d6\u05e8\u05df",
    COUNTRYCODE: "AT",
    COUNTRYNAME: "AUSTRIA",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e1\u05d8\u05e8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZGG",
    AIRPORTNAME: null,
    CITYCODE: "ZGG",
    CITYNAME: "ZAGOROCHORIA",
    CITYNAME_HEB: "\u05d6\u05d2\u05d5\u05e8\u05d9\u05d4",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "VSL",
    AIRPORTNAME: null,
    CITYCODE: "VSL",
    CITYNAME: "VILLARS",
    CITYNAME_HEB: "\u05d5\u05d9\u05dc\u05d0\u05e8",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "WER",
    AIRPORTNAME: null,
    CITYCODE: "WER",
    CITYNAME: "WERCHTER",
    CITYNAME_HEB: "\u05d5\u05e8\u05db\u05d8\u05e8",
    COUNTRYCODE: "BE",
    COUNTRYNAME: "BELGIUM",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "NEP",
    AIRPORTNAME: null,
    CITYCODE: "NEP",
    CITYNAME: "NEPTUN",
    CITYNAME_HEB: "\u05e0\u05e4\u05d8\u05d5\u05df",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GVO",
    AIRPORTNAME: null,
    CITYCODE: "GVO",
    CITYNAME: "VOULIGAMENI",
    CITYNAME_HEB: "\u05d5\u05d5\u05dc\u05d9\u05d2\u05d0\u05de\u05e0\u05d9",
    COUNTRYCODE: "GR",
    COUNTRYNAME: "GREECE",
    COUNTRYNAME_HEB: "\u05d9\u05d5\u05d5\u05df",
  },
  {
    AIRPORTCODE: "TTF",
    AIRPORTNAME: null,
    CITYCODE: "TTF",
    CITYNAME: "TERME TUHELJ",
    CITYNAME_HEB: "\u05d8\u05e8\u05de\u05d4 \u05d8\u05d5\u05d4\u05d9\u05d9",
    COUNTRYCODE: "HR",
    COUNTRYNAME: "CROATIA",
    COUNTRYNAME_HEB: "\u05e7\u05e8\u05d5\u05d0\u05d8\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "LXX",
    AIRPORTNAME: null,
    CITYCODE: "LXX",
    CITYNAME: "LAAX",
    CITYNAME_HEB: "\u05dc\u05d0\u05e7\u05e1",
    COUNTRYCODE: "CH",
    COUNTRYNAME: "SWITZERLAND",
    COUNTRYNAME_HEB: "\u05e9\u05d5\u05d5\u05d9\u05e5",
  },
  {
    AIRPORTCODE: "LDL",
    AIRPORTNAME: "LA DIGUE",
    CITYCODE: "LDL",
    CITYNAME: "LA DIGUE",
    CITYNAME_HEB: "\u05dc\u05d4 \u05d3\u05d9\u05d2",
    COUNTRYCODE: "SC",
    COUNTRYNAME: "SEYCHELLES ISLANDS",
    COUNTRYNAME_HEB: "\u05e1\u05d9\u05d9\u05e9\u05dc",
  },
  {
    AIRPORTCODE: "WEB",
    AIRPORTNAME: null,
    CITYCODE: "WEB",
    CITYNAME: "WELIGAMA",
    CITYNAME_HEB: "\u05d5\u05d5\u05dc\u05d9\u05d2\u05de\u05d4",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "CZD",
    AIRPORTNAME: " ",
    CITYCODE: "CZD",
    CITYNAME: "CIEPLICE ZDROJ",
    CITYNAME_HEB:
      "\u05e1\u05d9\u05e4\u05dc\u05d9\u05e6 \u05d6\u05d3\u05e8\u05d5",
    COUNTRYCODE: "PL",
    COUNTRYNAME: "POLAND",
    COUNTRYNAME_HEB: "\u05e4\u05d5\u05dc\u05d9\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "OUP",
    CITYNAME: "ERFOUD",
    CITYNAME_HEB: "\u05d0\u05e8\u05e4\u05d5\u05d3",
    COUNTRYCODE: "MA",
    COUNTRYNAME: "MOROCCO",
    COUNTRYNAME_HEB: "\u05de\u05e8\u05d5\u05e7\u05d5",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "ZYG",
    CITYNAME: "GJOVIK",
    CITYNAME_HEB: "\u05d2\u05d9\u05d5\u05d1\u05d9\u05e7",
    COUNTRYCODE: "NO",
    COUNTRYNAME: "NORWAY",
    COUNTRYNAME_HEB: "\u05e0\u05d5\u05e8\u05d1\u05d2\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "IRH",
    CITYNAME: "GORIS",
    CITYNAME_HEB: "\u05d2\u05d5\u05e8\u05d9\u05e1",
    COUNTRYCODE: "AM",
    COUNTRYNAME: "ARMENIA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "LWN",
    CITYNAME: "GYUMRI",
    CITYNAME_HEB: "\u05d2\u05d9\u05d5\u05de\u05e8\u05d9",
    COUNTRYCODE: "AM",
    COUNTRYNAME: "ARMENIA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "ZII",
    AIRPORTNAME: " ",
    CITYCODE: "ZII",
    CITYNAME: "ZHDANOVICHI",
    CITYNAME_HEB: "\u05d6\u05d0\u05d3\u05e0\u05d5\u05d1\u05d9\u05e6\u05d9",
    COUNTRYCODE: "BY",
    COUNTRYNAME: "BELARUS",
    COUNTRYNAME_HEB: "\u05d1\u05dc\u05d0\u05e8\u05d5\u05e1",
  },
  {
    AIRPORTCODE: "VLH",
    AIRPORTNAME: null,
    CITYCODE: "VLH",
    CITYNAME: "VALLANDRY",
    CITYNAME_HEB: "\u05d5\u05dc\u05e0\u05d3\u05e8\u05d9",
    COUNTRYCODE: "FR",
    COUNTRYNAME: "FRANCE",
    COUNTRYNAME_HEB: "\u05e6\u05e8\u05e4\u05ea",
  },
  {
    AIRPORTCODE: "FNF",
    AIRPORTNAME: null,
    CITYCODE: "FNF",
    CITYNAME: "FURSTENBERG",
    CITYNAME_HEB: "\u05e4\u05d5\u05e8\u05e1\u05d8\u05e0\u05d1\u05e8\u05d2",
    COUNTRYCODE: "DE",
    COUNTRYNAME: "GERMANY",
    COUNTRYNAME_HEB: "\u05d2\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "CDT",
    AIRPORTNAME: "CASTELLON AIRPORT",
    CITYCODE: "CDT",
    CITYNAME: "CASTELLON DE LA PLANA",
    CITYNAME_HEB:
      "\u05e7\u05d0\u05e1\u05d8\u05d9\u05d5\u05df \u05d3\u05d4 \u05dc\u05d4 \u05e4\u05dc\u05d0\u05e0\u05d4",
    COUNTRYCODE: "ES",
    COUNTRYNAME: "SPAIN",
    COUNTRYNAME_HEB: "\u05e1\u05e4\u05e8\u05d3",
  },
  {
    AIRPORTCODE: "GNC",
    AIRPORTNAME: null,
    CITYCODE: "GNC",
    CITYNAME: "GARNI",
    CITYNAME_HEB: "\u05d2\u05d0\u05e8\u05e0\u05d9",
    COUNTRYCODE: "AM",
    COUNTRYNAME: "ARMENIA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "VEA",
    AIRPORTNAME: null,
    CITYCODE: "VEA",
    CITYNAME: "ALAVERDI",
    CITYNAME_HEB: "\u05d0\u05dc\u05d0\u05d5\u05d5\u05e8\u05d3\u05d9",
    COUNTRYCODE: "AM",
    COUNTRYNAME: "ARMENIA",
    COUNTRYNAME_HEB: "\u05d0\u05e8\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "GRD",
    AIRPORTNAME: null,
    CITYCODE: "GRD",
    CITYNAME: "GRADO",
    CITYNAME_HEB: "\u05d2\u05e8\u05d0\u05d3\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "UZT",
    CITYNAME: "KHIVA",
    CITYNAME_HEB: "\u05d7\u05d9\u05d5\u05d5\u05d4",
    COUNTRYCODE: "UZ",
    COUNTRYNAME: "UZBEKISTAN",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05d6\u05d1\u05e7\u05d9\u05e1\u05d8\u05df",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "UBE",
    CITYNAME: "KIRKJUBAEJARKLAUSTUR",
    CITYNAME_HEB:
      "\u05e7\u05d9\u05e8\u05e7\u05d9\u05d5\u05d1\u05d0\u05d9\u05d9\u05e8\u05e7\u05dc\u05d0\u05d5\u05e1\u05d8\u05e8",
    COUNTRYCODE: "IS",
    COUNTRYNAME: "ICELAND",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05e1\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "NKO",
    CITYNAME: "KOLASIN",
    CITYNAME_HEB: "\u05e7\u05d5\u05dc\u05d0\u05e9\u05d9\u05df",
    COUNTRYCODE: "ME",
    COUNTRYNAME: "MONTENEGRO",
    COUNTRYNAME_HEB: "\u05de\u05d5\u05e0\u05d8\u05e0\u05d2\u05e8\u05d5",
  },
  {
    AIRPORTCODE: "OHD",
    AIRPORTNAME: null,
    CITYCODE: "OHD",
    CITYNAME: "OHRID",
    CITYNAME_HEB: "\u05d0\u05d5\u05d7\u05e8\u05d9\u05d3",
    COUNTRYCODE: "MK",
    COUNTRYNAME: "MACEDONIA",
    COUNTRYNAME_HEB: "\u05de\u05e7\u05d3\u05d5\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "VRT",
    CITYNAME: "ROVERETO",
    CITYNAME_HEB: "\u05e8\u05d5\u05d1\u05e8\u05d8\u05d5",
    COUNTRYCODE: " IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "ITY",
    CITYNAME: "SHKODER",
    CITYNAME_HEB: "\u05e9\u05e7\u05d5\u05d3\u05e8\u05d4",
    COUNTRYCODE: "AL",
    COUNTRYNAME: "ALBANIA",
    COUNTRYNAME_HEB: "\u05d0\u05dc\u05d1\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: null,
    AIRPORTNAME: null,
    CITYCODE: "NIW",
    CITYNAME: "TIMISUL DE SUS",
    CITYNAME_HEB:
      "\u05d8\u05d9\u05de\u05d9\u05e1\u05d5 \u05d3\u05d4 \u05e1\u05d5\u05e1",
    COUNTRYCODE: "RO",
    COUNTRYNAME: "ROMANIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05de\u05e0\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "THD",
    AIRPORTNAME: null,
    CITYCODE: "THD",
    CITYNAME: "TERCHOVA",
    CITYNAME_HEB: "\u05d8\u05e8\u05e6\u05d5\u05d1\u05d4",
    COUNTRYCODE: "SK",
    COUNTRYNAME: "SLOVAKIA",
    COUNTRYNAME_HEB: "\u05e1\u05dc\u05d5\u05d1\u05e7\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UKV",
    AIRPORTNAME: null,
    CITYCODE: "UKV",
    CITYNAME: "KOBLEVE",
    CITYNAME_HEB: "\u05e7\u05d5\u05d1\u05dc\u05d1",
    COUNTRYCODE: "UA",
    COUNTRYNAME: "UKRAINE",
    COUNTRYNAME_HEB: "\u05d0\u05d5\u05e7\u05e8\u05d9\u05d0\u05e0\u05d4",
  },
  {
    AIRPORTCODE: "KDW",
    AIRPORTNAME: null,
    CITYCODE: "KDW",
    CITYNAME: "KISLOVODSK",
    CITYNAME_HEB:
      "\u05e7\u05d9\u05e1\u05dc\u05d5\u05d1\u05d5\u05d3\u05e1\u05e7",
    COUNTRYCODE: "RS",
    COUNTRYNAME: "RUSSIA",
    COUNTRYNAME_HEB: "\u05e8\u05d5\u05e1\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "UWB",
    AIRPORTNAME: null,
    CITYCODE: "UWB",
    CITYNAME: "NUWEIBA",
    CITYNAME_HEB: "\u05e0\u05d5\u05d0\u05d9\u05d1\u05d4",
    COUNTRYCODE: "EG",
    COUNTRYNAME: "EGYPT",
    COUNTRYNAME_HEB: "\u05de\u05e6\u05e8\u05d9\u05dd",
  },
  {
    AIRPORTCODE: "OTB",
    AIRPORTNAME: null,
    CITYCODE: "OTB",
    CITYNAME: "PORTO CERVO",
    CITYNAME_HEB: "\u05e4\u05d5\u05e8\u05d8\u05d5 \u05e6\u05e8\u05d1\u05d5",
    COUNTRYCODE: "IT",
    COUNTRYNAME: "ITALY",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d8\u05dc\u05d9\u05d4",
  },
  {
    AIRPORTCODE: "HIK",
    AIRPORTNAME: null,
    CITYCODE: "HIK",
    CITYNAME: "HIKKADUWA",
    CITYNAME_HEB: "\u05d4\u05d9\u05e7\u05d3\u05d5\u05d0\u05d4",
    COUNTRYCODE: "LK",
    COUNTRYNAME: "SRI LANKA",
    COUNTRYNAME_HEB: "\u05e1\u05e8\u05d9 \u05dc\u05e0\u05e7\u05d4",
  },
  {
    AIRPORTCODE: "BTZ",
    AIRPORTNAME: null,
    CITYCODE: "BTZ",
    CITYNAME: "YALA",
    CITYNAME_HEB: "\u05d9\u05d0\u05dc\u05d4",
    COUNTRYCODE: "TH",
    COUNTRYNAME: "THAILAND",
    COUNTRYNAME_HEB: "\u05ea\u05d0\u05d9\u05dc\u05e0\u05d3",
  },
  {
    AIRPORTCODE: "FEI",
    AIRPORTNAME: null,
    CITYCODE: "FEI",
    CITYNAME: "FERA ISLAND",
    CITYNAME_HEB: "\u05d0\u05d9 \u05e4\u05d0\u05e8\u05d4 ",
    COUNTRYCODE: "SB",
    COUNTRYNAME: "SOLOMON ISLANDS",
    COUNTRYNAME_HEB: "\u05d0\u05d9\u05d9 \u05e9\u05dc\u05de\u05d4",
  },
];

export default LDS_AUTOCMPLETE_CITIES;
