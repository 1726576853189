import React, { useState, useEffect } from "react";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./hotelobject.css";

// Components
import StarRating from "../../../StarRating/starrating";
import Pricing from "../Pricing/pricing";

// Icons
import WhatsAppIcon from "../../../../assets/images/icons/whatsapp.png";
import EmailIcon from "../../../../assets/images/icons/email.png";
import NoBeds from "../../../../assets/images/icons/bed.png";

// Contexts
import { SubTitle, Title } from "../../../Text";

const HotelObject = ({ hotelsItem, searchPhrase, searchSession }) => {
  // Translator
  const { t } = useTranslation();
  // States
  const [isZoom, setIsZoom] = useState(false);
  const [totalRooms, setTotalRooms] = useState("");
  const [totalAdults, setTotalAdults] = useState("");
  const [totalKids, setTotalKids] = useState("");
  const [numberOfNights, setNumberOfNights] = useState("");
  const currentClient = localStorage.getItem("agent-saved-client-info")
    ? JSON.parse(localStorage.getItem("agent-saved-client-info"))
    : null;

  // --- Functions

  useEffect(() => {
    extractDataFromSearch();
  }, []);

  // Zoom in the picture
  const hotelImageZoom = () => {
    setIsZoom(!isZoom);
  };

  const extractDataFromSearch = () => {
    const { rooms } = searchPhrase;

    if (rooms && rooms.length > 0) {
      let totalAdults = 0;
      let totalKids = 0;
      let totalRooms = rooms.length;

      rooms.forEach((room) => {
        totalAdults += room.AdultsCount;
        if (room.KidsAges && room.KidsAges.length > 0) {
          totalKids += room.KidsAges.length;
        }
      });

      // Get number of nights
      const checkInParts = searchPhrase.checkIn.split("/");
      const checkOutParts = searchPhrase.checkOut.split("/");

      // Create Date objects using parts in the format: year, month - 1, day
      const checkInDate = new Date(
        checkInParts[2],
        checkInParts[1] - 1,
        checkInParts[0]
      );
      const checkOutDate = new Date(
        checkOutParts[2],
        checkOutParts[1] - 1,
        checkOutParts[0]
      );

      // Calculate the time difference in milliseconds
      const timeDifference = checkOutDate - checkInDate;

      // Convert milliseconds to days
      const numberOfNights = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      setTotalRooms(totalRooms);
      setTotalAdults(totalAdults);
      setTotalKids(totalKids);
      setNumberOfNights(numberOfNights);
    }
  };

  return (
    <>
      <div id={hotelsItem.ID} className="hotelObject-wrapper">
        {/* Hotel Info */}
        <div className="hotelObject-info-wrapper">
          {/* Hotel Images */}
          {hotelsItem?.DefaultImage?.FullSize ? (
            <div
              className={`${
                isZoom
                  ? "hotelObject-display-image-zoom-wrapper"
                  : "hotelObject-display-image-wrapper"
              }`}
            >
              <img
                alt={hotelsItem.DisplayName}
                onClick={hotelImageZoom}
                className={`${
                  isZoom
                    ? "hotelObject-display-image-zoom"
                    : "hotelObject-display-image"
                }`}
                src={hotelsItem.DefaultImage.FullSize}
              ></img>
            </div>
          ) : (
            <div className="hotelObject-nobedimage-wrapper-wrapper">
              <div className="hotelObject-nobedimage-wrapper">
                <img
                  className="roomPlacer-nobedimage-img"
                  src={NoBeds}
                  alt="No hotel images"
                ></img>
                <label className="roomPlacer-nobedimage-title">
                  {t("hotelprofile.nohotelimages")}
                </label>
              </div>
            </div>
          )}

          {/* Hotel Description */}
          <div
            className={`hotelObject-description-wrapper ${
              document.documentElement.dir === "ltr"
                ? "direction-rtl"
                : "direction-ltr"
            }`}
          >
            {/* Description */}
            <div className="hotelObject-description">
              <div className="hotelObject-title-wrapper">
                {/* Star Rating */}
                <StarRating rating={hotelsItem.StarRating} />

                <Title className="hotelObject-title tw-text-sm lg:tw-text-2xl">
                  {hotelsItem.DisplayName}
                </Title>
                <SubTitle className="hotelObject-sub-title">
                  {hotelsItem?.Address}
                </SubTitle>
                <SubTitle className="hotelObject-distance">
                  {hotelsItem?.distance?.toFixed(2)} ק"מ מהיעד
                </SubTitle>
              </div>

              <div
                className={`${
                  document.documentElement.dir === "rtl"
                    ? "direction-rtl"
                    : "direction-ltr"
                }`}
              >
                <span className="hotelObject-text">
                  {numberOfNights} {t("hotelsearchbar.nights")} {totalRooms}{" "}
                  {t("hotelsearchbar.rooms")} {totalAdults}{" "}
                  {t("hotelsearchbar.adults")}{" "}
                  {totalKids > 0
                    ? `${totalKids} ${t("hotelsearchbar.kids")}`
                    : ""}
                </span>
              </div>
            </div>

            {/* Share */}
            <div className="hotelObject-share">
              {/* <label className='hotelObject-share-text'>{t('hotelsearchbar.share')} </label> */}

              {/* Share options */}
              <div className="hotelObject-icon-wrapper">
                <a
                  href={`https://wa.me/${currentClient.phone}`}
                  target="_blank"
                >
                  <img
                    className="hotelObject-icon"
                    src={WhatsAppIcon}
                    alt="Whatsapp Icon"
                  ></img>
                </a>

                <a href={`mailto:${currentClient.email}`}>
                  <img
                    className="hotelObject-icon"
                    src={EmailIcon}
                    alt="Email Icon"
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Pricing */}
        <Pricing
          item={hotelsItem}
          searchPhrase={searchPhrase}
          searchSession={searchSession}
        />
      </div>
    </>
  );
};

export default HotelObject;
